export const FILE_NUMBER_REQUEST = "FILE_NUMBER_REQUEST";
export const FILE_NUMBER_FAILURE = "FILE_NUMBER_FAILURE";
export const FILE_NUMBER_SUCCESS = "FILE_NUMBER_SUCCESS";

export const VOLUME_REQUEST = "VOLUME_REQUEST";
export const VOLUME_FAILURE = "VOLUME_FAILURE";
export const VOLUME_SUCCESS = "VOLUME_SUCCESS";

export const ACTIVE_MANUFACTURER_REQUEST = "ACTIVE_MANUFACTURER_REQUEST";
export const ACTIVE_MANUFACTURER_FAILURE = "ACTIVE_MANUFACTURER_FAILURE";
export const ACTIVE_MANUFACTURER_SUCCESS = "ACTIVE_MANUFACTURER_SUCCESS";
export const ACTIVE_MANUFACTURER_CHANGE_PAGE =
  "ACTIVE_MANUFACTURER_CHANGE_PAGE";
export const ACTIVE_MANUFACTURER_SORT = "ACTIVE_MANUFACTURER_SORT";

export const CHANGE_FILE_NUMBER = "CHANGE_FILE_NUMBER";
export const CHANGE_VOLUME = "CHANGE_VOLUME";
export const FILTER_ITEM_SELECT = "FILTER_ITEM_SELECT";
export const FILTER_ITEM_SELECT_ALL = "FILTER_ITEM_SELECT_ALL";

export const SELECT_FILE_VOLUME = "SELECT_FILE_VOLUME";
export const SELECT_ALL_FILE_VOLUMES = "SELECT_ALL_FILE_VOLUMES";

export const CHECK_CUSTOMER_LABEL_REQUEST = "CHECK_CUSTOMER_LABEL_REQUEST";
export const CHECK_CUSTOMER_LABEL_FAILURE = "CHECK_CUSTOMER_LABEL_FAILURE";
export const CHECK_CUSTOMER_LABEL_SUCCESS = "CHECK_CUSTOMER_LABEL_SUCCESS";

export const CHECK_IFVS_IF_UP_REQUEST = "CHECK_IFVS_IF_UP_REQUEST";
export const CHECK_IFVS_IF_UP_FAILURE = "CHECK_IFVS_IF_UP_FAILURE";
export const CHECK_IFVS_IF_UP_SUCCESS = "CHECK_IFVS_IF_UP_SUCCESS";

export const CHECK_SERVICE_CONTRACT_REQUEST = "CHECK_SERVICE_CONTRACT_REQUEST";
export const CHECK_SERVICE_CONTRACT_FAILURE = "CHECK_SERVICE_CONTRACT_FAILURE";
export const CHECK_SERVICE_CONTRACT_SUCCESS = "CHECK_SERVICE_CONTRACT_SUCCESS";
export const CLEAN_ALL_FILTERS = "CLEAN_ALL_FILTERS";
export const CLEAR_SELECTION = "CLEAR_SELECTION";
