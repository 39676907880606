import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import {
  Button,
  Container,
  Form,
  Grid,
  Header,
  Icon,
  Input,
  Modal,
  Segment,
  Message,
  Tab,
  Divider,
  Table
} from "semantic-ui-react";
import { CustomHeader, CustomLabel } from "../../components/labels/labels";
import OrderWorkflow from "./label-order-workflow";
import CombinationLabelOrderItem from "./combination-label-order-item";
import CustomerInformation from "../../views/label-order-cart/cart/customer-information";
import LabelOrderInfo from "./label-order-info";
import LabelOrderComments from "./label-order-comments";
import FooterHomeBlock from "../../components/footer/footer-home-block";
import { getCustomerInfo } from "../../actions/labelOrderDetails/getCustomerInfo";
import {
  getLabelOrder,
  renderPdf,
  updateOracleNumber,
  updateOracleNumberReset
} from "../../actions/labelOrderDetails/getLabelOrder";
import { initialize } from "../../actions/labelOrderDetails/initializeLabelOrderDetails";
import { emailCopy } from "../../actions/labelOrderDetails/emailCopy";
import { getCustomerDetails } from "../../views/manufacturer/standard/label-cart/label-cart.action";
import HelpMeMessage from "../help-me/common/help-message";
import Role from "../../enums/role";
import { Redirect } from "react-router-dom";
import { Common } from "../../common/common";
import * as helpMeActions from "../help-me/help-me.action";
import { CustomHeaderLabel } from "../../components/labels/labels";
import * as commonActions from "../../views/user/common/common.action";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2
});

class LabelOrderDetails extends Component {
  state = {
    emailAddress: null,
    isUpdateIsViewedSuccess: false,
    oracleNumber: null
  };

  orderRequestNumber = null;
  labelOrderType = null;
  customerId = localStorage.getItem("customerId");
  componentDidMount() {
    this.getUrlParams();
    this.props.initialize();
    this.props.getLabelOrder(this.orderRequestNumber);
    this.props.getActivePage("Order History");
  }

  componentDidUpdate() {
    //Update help me message isViewed flag
    const { isUpdateIsViewedSuccess } = this.state;
    const { data } = this.props;
    if (isUpdateIsViewedSuccess === false && data && data.length) {
      this.setState({ isUpdateIsViewedSuccess: true });
      var postData = {
        orderId: data[0].id // same id for multi
      };
      this.props.updateIsViewedMessage(postData);
    }
  }

  getUrlParams = () => {
    this.orderRequestNumber = this.props.match.params.orderRequestNumber;
    this.labelOrderType = this.props.match.params.labelOrderType;
  };

  // print() {
  //   this.htmlBody = this.htmlBody.replace(
  //     '<div id="label-order-details" style="width: 60%; padding: 20px;"></div>',
  //     document.getElementById("label-order-details").outerHTML
  //   );
  //   var data = {
  //     orderNumber: this.orderRequestNumber,
  //     htmlBody: this.htmlBody
  //   };

  //   this.props.renderPdf(data);
  // }

  handleEmailCopy = (fileNumber) => {
    this.props.emailCopy({
      requestNumber: this.orderRequestNumber,
      labelOrderType: this.labelOrderType,
      emailAddress: this.state.emailAddress,
      fileNumber: fileNumber
    });
  };

  handleUpdateOracleNumber = (oracleNumber) => {
    this.props.updateOracleNumber({
      requestNumber: this.orderRequestNumber,
      oracleNumber: oracleNumber
    });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleGetLabelOrder = (e) => {
    this.props.getLabelOrder(this.orderRequestNumber);
  };

  render() {
    const {
      data,
      isEmailCopySuccess,
      isLoadingEmailCopy,
      htmlData,
      loading,
      isSuccessUpdateOracleNumber,
      isLoadingUpdateOracleNumber
    } = this.props;

    const translations = Common.getTranslations() || {};
    let userId = Common.getUserId();
    let userRole = Common.getUserRole();
    let userPsn =
      userRole === Role.applicant || userRole === Role.manufacturer
        ? Common.getPartySiteNumber()
        : null;
    let isAllowed = false;
    // accessible by label center and who created the order only
    // if (data) {
    //   if ((data[0] || {}).createdById != parseInt(userId))
    //     if (userRole != Role.labelCenter) return <Redirect to="/home" />;
    // }
    if (data) {
      if (userRole === Role.applicant || userRole === Role.manufacturer) {
        if (
          data[0].createdById != userId &&
          data[0].partySiteNumber != userPsn
        ) {
          isAllowed = false;
        } else {
          isAllowed = true;
        }
      } else {
        isAllowed = true;
      }
    }
    if (htmlData) {
      const byteCharacters = atob(htmlData);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);

      var blob = new Blob([byteArray], { type: "application/pdf" });
      var url = window.URL.createObjectURL(blob);
      var a = document.createElement("a");
      a.style = "display: none";
      a.href = url;
      a.download = `${this.orderRequestNumber}.pdf`;
      a.click();
      window.URL.revokeObjectURL(url);
    }

    if (isSuccessUpdateOracleNumber) {
      this.props.getLabelOrder(this.orderRequestNumber);
      this.props.updateOracleNumberReset();
    }

    const { emailAddress, oracleNumber } = this.state;
    const custDetails = data ? data.customerDetails : null;
    const hasItems = data && data.length > 0;
    const panes = [];

    if (data && data.length > 0) {
      for (let index = 0; index < data.length; index++) {
        const formData = data[index];
        const uniqueFileNum = [
          ...new Set(formData.items.map((item) => item.fileNumber))
        ];

        //console.log(uniqueFileNum);
        const pane = {
          name: formData.oracleNumber
            ? formData.oracleNumber
            : formData.billToAccountNo,
          menuItem: formData.oracleNumber
            ? formData.oracleNumber
            : formData.billToAccountNo,
          render: () => (
            <Tab.Pane key={index}>
              {" "}
              {hasItems && !loading && (
                <Fragment>
                  <div id="workflow-information">
                    <OrderWorkflow
                      data={formData}
                      handleChange={this.handleChange}
                      isEmailCopySuccess={isEmailCopySuccess}
                      emailAddress={emailAddress}
                      isLoadingEmailCopy={isLoadingEmailCopy}
                      handleEmailCopy={this.handleEmailCopy}
                      isLoadingUpdateOracleNumber={isLoadingUpdateOracleNumber}
                      isSuccessUpdateOracleNumber={isSuccessUpdateOracleNumber}
                      handleUpdateOracleNumber={this.handleUpdateOracleNumber}
                      oracleNumber={oracleNumber}
                    />
                  </div>

                  {/* <div id="customer-information">
                    <CustomerInformation
                      data={formData}
                      model={formData.customerDetails}
                    />
                  </div> */}
                  <div>
                    <LabelOrderInfo data={formData} labelOrderData={formData} />
                  </div>
                  {/* <div className="page-break"></div> */}
                  {uniqueFileNum.map((item, idx) => {
                    return (
                      <CombinationLabelOrderItem
                        key={idx}
                        data={formData.items.filter(
                          (x) => x.fileNumber == item
                        )}
                        fileNumber={item}
                        orderDetails={formData}
                      />
                    );
                  })}
                  <Divider hidden />

                  <Grid style={{ padding: "0px 25px 0px 25px" }}>
                    <Grid.Row>
                      <Grid.Column>
                        <div
                          style={{
                            border: "1px solid #DCDEE1"
                          }}
                        ></div>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  <Divider hidden />

                  <div>
                    <HelpMeMessage
                      data={formData.helpMes}
                      orderDetails={formData}
                      getLabelOrder={this.handleGetLabelOrder}
                    />
                  </div>
                </Fragment>
              )}
              {!hasItems && !loading && (
                <Fragment>
                  <CustomerInformation
                    data={formData}
                    model={this.props.customerInfo}
                    loadingCustomerInfo={this.props.loadingCustomerInfo}
                  />
                  <Segment textAlign="center">
                    <Header>No record found.</Header>
                  </Segment>
                </Fragment>
              )}
            </Tab.Pane>
          )
        };

        panes.push(pane);
      }
    }
    return (
      <Fragment>
        <div id="label-order-details">
          <Container fluid style={{ padding: "20px", color: "#7F7F7F" }}>
            <Form loading={loading}>
              <Grid>
                <Grid.Column width={5} className="">
                  <label style={{ fontSize: "17px" }}>
                    {translations["LexCartOrderNo."]}:{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {this.orderRequestNumber}
                    </span>
                  </label>
                </Grid.Column>
                <Grid.Column
                  width={11}
                  className="print-hidden"
                  textAlign={"right"}
                ></Grid.Column>
              </Grid>
              <Divider hidden />
              {isAllowed && !loading && <Tab panes={panes}></Tab>}
              {!isAllowed && !loading && (
                <Table>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>
                        <CustomLabel message="NO RECORD FOUND." />
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              )}
            </Form>
            <FooterHomeBlock />
          </Container>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  hasError: state.labelOrderDetails.hasError,
  message: state.labelOrderDetails.message,
  loading: state.labelOrderDetails.loading,
  loadingCheckout: state.labelOrderDetails.loadingCheckout,
  checkoutSuccess: state.labelOrderDetails.checkoutSuccess,
  checkoutFailed: state.labelOrderDetails.checkoutFailed,
  isConfirmingCheckout: state.labelOrderDetails.isConfirmingCheckout,
  data: state.labelOrderDetails.data,
  loadingCustomerInfo: state.labelOrderDetails.loadingCustomerInfo,
  messageTimeout: state.labelOrderDetails.messageTimeout,
  order: state.labelOrderDetails.order,
  customerInfo: state.labelOrderDetails.customerInfo,
  totalNetPrice: state.labelOrderDetails.totalNetPrice,
  isLoadingEmailCopy: state.labelOrderDetails.isLoadingEmailCopy,
  isEmailCopySuccess: state.labelOrderDetails.isEmailCopySuccess,
  customerDetails: state.labelCart.customerDetails,
  htmlData: state.labelOrderDetails.htmlData,
  helpMe: state.helpMe,
  isLoadingUpdateOracleNumber:
    state.labelOrderDetails.isLoadingUpdateOracleNumber,
  isSuccessUpdateOracleNumber:
    state.labelOrderDetails.isSuccessUpdateOracleNumber
});

const mapDispatchToProps = (dispatch) => ({
  getCustomerInfo: (customerId) => dispatch(getCustomerInfo(customerId)),
  getLabelOrder: (orderRequestNumber) =>
    dispatch(getLabelOrder(orderRequestNumber)),
  initialize: () => dispatch(initialize()),
  emailCopy: (data) => dispatch(emailCopy(data)),
  getCustomerDetails: (partySiteNumber) =>
    dispatch(getCustomerDetails(partySiteNumber)),
  renderPdf: (data) => dispatch(renderPdf(data)),
  updateIsViewedMessage: (data) =>
    dispatch(helpMeActions.updateIsViewedMessage(data)),
  updateOracleNumber: (data) => dispatch(updateOracleNumber(data)),
  updateOracleNumberReset: () => dispatch(updateOracleNumberReset()),
  getActivePage: (page) => dispatch(commonActions.getActivePage(page))
});

export default React.memo(
  connect(mapStateToProps, mapDispatchToProps)(LabelOrderDetails)
);
