export const COMBINATION_GET_EXISTING_LABEL_ORDER_SUCCESS =
  'COMBINATION_GET_EXISTING_LABEL_ORDER_SUCCESS';
export const COMBINATION_GET_EXISTING_LABEL_ORDER_REQUEST =
  'COMBINATION_GET_EXISTING_LABEL_ORDER_REQUEST';
export const COMBINATION_GET_EXISTING_LABEL_ORDER_FAILURE =
  'COMBINATION_GET_EXISTING_LABEL_ORDER_FAILURE';
export const COMBINATION_GET_EXISTING_LABEL_ORDER_SORT =
  'COMBINATION_GET_EXISTING_LABEL_ORDER_SORT';
export const COMBINATION_GET_EXISTING_LABEL_ORDER_PAGINATE =
  'COMBINATION_GET_EXISTING_LABEL_ORDER_PAGINATE';

export const COMBINATION_UPDATE_FILTER = 'COMBINATION_UPDATE_FILTER';

export const COMBINATION_EXISTING_LABEL_ORDER_SELECT =
  'COMBINATION_EXISTING_LABEL_ORDER_SELECT';
export const COMBINATION_EXISTING_LABEL_ORDER_SELECT_ALL =
  'COMBINATION_EXISTING_LABEL_ORDER_SELECT_ALL';

export const COMBINATION_EXISTING_LABEL_INITIALIZE =
  'COMBINATION_EXISTING_LABEL_INITIALIZE';

export const COMBINATION_ADD_EXISTING_LABEL_ORDER_TO_CART_SUCCESS =
  'COMBINATION_ADD_EXISTING_LABEL_ORDER_TO_CART_SUCCESS';
export const COMBINATION_ADD_EXISTING_LABEL_ORDER_TO_CART_REQUEST =
  'COMBINATION_ADD_EXISTING_LABEL_ORDER_TO_CART_REQUEST';
export const COMBINATION_ADD_EXISTING_LABEL_ORDER_TO_CART_FAILURE =
  'COMBINATION_ADD_EXISTING_LABEL_ORDER_TO_CART_FAILURE';

export const GET_COMBINATION_LABELS_BY_ITEM_NUMBER =
  'GET_COMBINATION_LABELS_BY_ITEM_NUMBER';

export const GET_COMBINATION_LABELS_BY_PART_NUMBER =
  'GET_COMBINATION_LABELS_BY_PART_NUMBER';

export const UPDATE_SELECTED_LABEL = 'UPDATE_SELECTED_LABEL';
export const UPDATE_COMBINATION_LABELS_FORM = 'UPDATE_COMBINATION_LABELS_FORM';
export const PUSH_SELECTED_LABEL = 'PUSH_SELECTED_LABEL';
export const REMOVE_SELECTED_LABEL = 'REMOVE_SELECTED_LABEL';
export const UPDATE_ITEM_LIST = 'UPDATE_ITEM_LIST';
export const UPDATE_ATTACHMENT = 'UPDATE_ATTACHMENT';

export const SET_PRODUCT_COVERED_REQUEST = 'SET_PRODUCT_COVERED_REQUEST';
export const SET_PRODUCT_COVERED_SUCCESS = 'SET_PRODUCT_COVERED_SUCCESS';
export const SET_PRODUCT_COVERED_FAILURE = 'SET_PRODUCT_COVERED_FAILURE';

export const ITEM_NUMBER_OPTIONS_RESET = 'ITEM_NUMBER_OPTIONS_RESET';

export const LISTEE_FILE_NUMBER_REQUEST = 'LISTEE_FILE_NUMBER_REQUEST';
export const LISTEE_FILE_NUMBER_SUCCESS = 'LISTEE_FILE_NUMBER_SUCCESS';
export const LISTEE_FILE_NUMBER_FAILURE = 'LISTEE_FILE_NUMBER_FAILURE';

export const CANCEL_NEW_LINE = 'CANCEL_NEW_LINE';

export const EXISTING_COMBINATION_BY_CART_ID_REQUEST =
  'EXISTING_COMBINATION_BY_CART_ID_REQUEST';
export const EXISTING_COMBINATION_BY_CART_ID_SUCCESS =
  'EXISTING_COMBINATION_BY_CART_ID_SUCCESS';
export const EXISTING_COMBINATION_BY_CART_ID_FAILURE =
  'EXISTING_COMBINATION_BY_CART_ID_FAILURE';

export const SCAN_FILE_REQUEST = 'SCAN_FILE_REQUEST';
export const SCAN_FILE_SUCCESS = 'SCAN_FILE_SUCCESS';
export const SCAN_FILE_FAILURE = 'SCAN_FILE_FAILURE';

export const GET_HOLOGRAMCCNS_REQUEST = 'GET_HOLOGRAMCCNS_REQUEST';
export const GET_HOLOGRAMCCNS_SUCCESS = 'GET_HOLOGRAMCCNS_SUCCESS';
export const GET_HOLOGRAMCCNS_FAILED = 'GET_HOLOGRAMCCNS_FAILED';

export const GET_LABEL_SUPPLIER_PRINTER_EMAIL_REQUEST =
  'GET_LABEL_SUPPLIER_PRINTER_EMAIL_REQUEST';
export const GET_LABEL_SUPPLIER_PRINTER_EMAIL_SUCCESS =
  'GET_LABEL_SUPPLIER_PRINTER_EMAIL_SUCCESS';
export const GET_LABEL_SUPPLIER_PRINTER_EMAIL_FAILED =
  'GET_LABEL_SUPPLIER_PRINTER_EMAIL_FAILED';
