import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import {
  Button,
  Confirm,
  Container,
  Dimmer,
  Grid,
  Header,
  Modal,
  Segment
} from "semantic-ui-react";
import CustomerInformation from "../cart/customer-information";
import AdditionalCommentsReview from "./additional-comments-review";
import FooterHomeBlock from "../../../components/footer/footer-home-block";
import CombinationLabelOrderItemReview from "./combination-label-order-item-review";
import LabelOrderCartInfoReview from "./label-order-cart-info-review";
import {
  checkOutLabelOrderCart,
  confirmCheckoutRequest,
  confirmCheckoutAccept,
  confirmCheckoutReject,
  getLabelOrderCart,
  getCustomerInfo,
  initialize,
  removeCheckOutMessage
} from "./label-order-cart-review.action";
import { getCustomerDetails } from "../../../views/manufacturer/standard/label-cart/label-cart.action";
import TermsAndConditions from "../../manufacturer/standard/label-cart/terms-and-conditions";
import { Common } from "../../../common";
import { toast } from "react-semantic-toasts";

// const formatter = new Intl.NumberFormat("en-US", {
//   style: "currency",
//   currency: "USD",
//   minimumFractionDigits: 2
// });

class LabelOrderCartReview extends Component {
  customerId = localStorage.getItem("customerId");

  state = {
    isCheckAgree: false,
    isLoading: "",
    isFinished: false
  };

  componentDidMount() {
    this.props.getCustomerInfo(this.customerId);
    this.props.getLabelOrderCart();
    this.props.getCustomerDetails(Common.getPartySiteNumber());
  }

  handleConfirm = () => {
    this.props.confirmCheckoutAccept();
    this.setState({ openTac: true });
  };

  handleCancel = () => {
    this.props.confirmCheckoutReject();
  };

  onClickAgreeChecbox = () =>
    this.setState((prevState) => ({ isCheckAgree: !prevState.isCheckAgree }));

  handleSubmit = () => {
    this.setState({ isLoading: "loading" });

    this.props.checkOutLabelOrderCart().then((s) => {
      if (s != null) {
        setTimeout(() => {
          toast({
            title: "",
            description: (
              <p>
                {Common.getTranslations()["LexPlaceOrderSuccessAlert"].replace(
                  "{0}",
                  this.props.orderConfirmationNumber
                )}
              </p>
            ),
            time: 10000,
            size: "small",
            icon: "check",
            color: "green"
          });
        }, 100);
        this.setState({ openTac: false, isLoading: "", isFinished: true });
      } else {
        setTimeout(() => {
          toast({
            title: "",
            description: (
              <p>
                {
                  "Something went wrong during check-out of order. Please try again."
                }
              </p>
            ),
            time: 10000,
            size: "small",
            icon: "",
            color: "red"
          });
        }, 100);
      }

      window.scrollTo(0, 0);
    });

    setTimeout(
      () => this.props.removeCheckOutMessage(),
      this.props.messageTimeout
    );
  };

  render() {
    const { openTac, isCheckAgree, isLoading, isFinished } = this.state;
    const { data, customerInfo, loadingCheckout, customerDetails } = this.props;
    const hasCartItems = data && data.cartItems && data.cartItems.length > 0;
    const custDetails = customerDetails.data;
    const shipToDetails = customerDetails.data
      ? customerDetails.data.shipToAddress
      : null;
    if (this.props.checkoutSuccess) {
      localStorage.setItem("cartLabelOrderType", null);
    }
    const translations = Common.getTranslations() || {};

    return false ? (
      <Redirect
        to={`/home/label-order-details/${this.props.orderConfirmationNumber}`}
      />
    ) : (
      <Fragment>
        <div style={{ width: "60%", padding: "20px" }}>
          <Dimmer.Dimmable as={Container} dimmed={loadingCheckout} fluid>
            <CustomerInformation model={custDetails} />
            {hasCartItems && (
              <Fragment>
                <LabelOrderCartInfoReview
                  data={this.props.order}
                  customerBillToAddresses={custDetails}
                  customerShipToAddresses={shipToDetails}
                />
                {data.cartItems.map((item, idx) => {
                  return (
                    <CombinationLabelOrderItemReview key={idx} data={item} />
                  );
                })}
                <AdditionalCommentsReview data={this.props.order} />
                {!isFinished ? (
                  <Grid>
                    <Grid.Column width={8}>
                      <Button
                        basic
                        primary
                        as={Link}
                        to="/home/generic-label-order-cart"
                      >
                        Edit
                      </Button>
                    </Grid.Column>
                    <Grid.Column style={{ textAlign: "right" }} width={8}>
                      {/* <CustomHeaderLabel message="Net total: " />
                    <Label key="big" size="big" style={{ marginRight: "10px" }}>
                      <span style={{ color: "#00518a" }}>
                        USD {formatter.format(this.props.totalNetPrice)}
                      </span>
                    </Label> */}
                      <span>
                        <Button
                          primary
                          onClick={(e) => this.props.confirmCheckoutRequest()}
                        >
                          {translations["LexCheckout"]}
                        </Button>
                      </span>
                    </Grid.Column>
                  </Grid>
                ) : null}
              </Fragment>
            )}
            {!hasCartItems && (
              <Segment textAlign="center">
                <Header>No items added on cart</Header>
              </Segment>
            )}
            <FooterHomeBlock />
            <Confirm
              size="mini"
              open={this.props.isConfirmingCheckout}
              header="Checkout order"
              content="Are you sure you want to continue?"
              onCancel={this.handleCancel}
              onConfirm={this.handleConfirm}
            />
            <Modal open={openTac}>
              <Modal.Header className="ui center aligned">
                Terms and Conditions
              </Modal.Header>
              <Modal.Content scrolling>
                <TermsAndConditions
                  onClickAgreeChecbox={this.onClickAgreeChecbox}
                  isCheckAgree={isCheckAgree}
                />
              </Modal.Content>
              <Modal.Actions>
                {isCheckAgree ? (
                  <Button
                    primary
                    className={isLoading}
                    onClick={this.handleSubmit.bind(this)}
                  >
                    {translations["LexSubmit"]}
                  </Button>
                ) : null}
                <Button
                  primary
                  basic
                  onClick={() =>
                    this.setState({ openTac: false, isCheckAgree: false })
                  }
                >
                  {translations["LexCancel"]}
                </Button>
              </Modal.Actions>
            </Modal>
          </Dimmer.Dimmable>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  hasError: state.labelOrderCartReview.hasError,
  orderConfirmationNumber: state.labelOrderCartReview.orderConfirmationNumber,
  message: state.labelOrderCartReview.message,
  loading: state.labelOrderCartReview.loading,
  loadingCheckout: state.labelOrderCartReview.loadingCheckout,
  checkoutSuccess: state.labelOrderCartReview.checkoutSuccess,
  checkoutFailed: state.labelOrderCartReview.checkoutFailed,
  isConfirmingCheckout: state.labelOrderCartReview.isConfirmingCheckout,
  data: state.labelOrderCartReview.data,
  loadingCustomerInfo: state.labelOrderCartReview.loadingCustomerInfo,
  messageTimeout: state.labelOrderCartReview.messageTimeout,
  order: state.labelOrderCartReview.order,
  customerInfo: state.labelOrderCartReview.customerInfo,
  totalNetPrice: state.labelOrderCartReview.totalNetPrice,
  customerDetails: state.labelCart.customerDetails
});

const mapDispatchToProps = (dispatch) => ({
  checkOutLabelOrderCart: () => dispatch(checkOutLabelOrderCart()),
  confirmCheckoutRequest: () => dispatch(confirmCheckoutRequest()),
  confirmCheckoutAccept: () => dispatch(confirmCheckoutAccept()),
  confirmCheckoutReject: () => dispatch(confirmCheckoutReject()),
  getCustomerInfo: (customerId) => dispatch(getCustomerInfo(customerId)),
  getLabelOrderCart: () => dispatch(getLabelOrderCart()),
  initialize: () => dispatch(initialize()),
  removeCheckOutMessage: () => dispatch(removeCheckOutMessage()),
  getCustomerDetails: (partySiteNumber) =>
    dispatch(getCustomerDetails(partySiteNumber))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LabelOrderCartReview);
