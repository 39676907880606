export const loginCard = {
  width: "20%",
  marginTop: "5%",
  marginBottom: "9%",
  display: "flex",
  minHeight: "54.2vh",
  flexDirection: "column"
};

export const registerCard = {
  width: "25%",
  marginTop: "5%",
  marginBottom: "14%",
  display: "flex",
  minHeight: "50vh",
  flexDirection: "column"
};

export const registerSuccessCard = {
  width: "25%",
  marginTop: "5%",
  marginBottom: "9%",
  display: "flex",
  minHeight: "54.2vh",
  flexDirection: "column"
};

export const dashboardSelectionCard = {
  margin: "5% auto 13% auto",
  display: "flex",
  minHeight: "46vh",
  flexDirection: "column"
};

export const resetPasswordCard = {
  width: "25%",
  marginTop: "5%",
  marginBottom: "9%",
  display: "flex",
  minHeight: "54.2vh",
  flexDirection: "column"
};

export const resendVerificationEmailCard = {
  width: "50%",
  marginTop: "5%",
  marginBottom: "9%",
  display: "flex",
  minHeight: "54.2vh",
  flexDirection: "column"
};

export const verifyEmailCard = {
  width: "40%",
  marginTop: "5%",
  marginBottom: "9%",
  display: "flex",
  minHeight: "54.2vh",
  flexDirection: "column"
};
