import { validate } from "../../validator/validator";

import {
  required,
  email,
  mustMatch,
  minLength,
  maxLength,
  numberOnly,
  phoneNumber,
  requiredCheckbox,
  requiredCheckboxWithNull
} from "../../validator/error-message";

export const ADrules = [];
export const ASrules = [];
export const FErules = [];

export const ADADrules = [
  // validate("legalCompanyName", "Legal Company Name", required),
  // validate("addressLine1", "Address Line 1", required),
  // validate("addressLine2", "Address Line 2", required),
  // validate("addressLine3", "Address Line 3", required),
  // validate("city", "City", required),
  // validate("state", "State", required),
  // validate("country", "Country", required),
  // validate("province", "Province", required),
  // validate("postalCode", "Postal Code", required),
  validate("taxpayerId", "Taxpayer ID # (TIN/VAT)"),
  validate("contactName", "Contact Name", required),
  validate("phoneNumber", "Phone No", required, phoneNumber),
  // validate("mobileNumber", "Cell No", phoneNumber),
  validate("emailAddress", "Email Address", required, email)
];

export const ADABTLDrules = [
  validate("legalCompanyName", "Legal Company Name", required),
  validate("addressLine1", "Address Line 1", required),
  // validate("addressLine2", "Address Line 2", required),
  // validate("addressLine3", "Address Line 3", required),
  validate("city", "City", required),
  validate("state", "State", required),
  validate("country", "Country", required),
  // validate("province", "Province", required),
  validate("postalCode", "Postal Code", required),
  validate("taxpayerId", "Taxpayer ID # (TIN/VAT)"),
  validate("contactName", "Contact Name", required),
  validate("phoneNumber", "Phone No", required, phoneNumber),
  // validate("mobileNumber", "Cell No", phoneNumber),
  validate("emailAddress", "Email Address", required, email)
];

export const ADQADrules = [
  validate("currencyCode", "Billing Currency", required),
  // validate("isInvoiceDeliveryRequested", "Invoice Delivery", required),
  validate("billingContactName", "Billing Contact Name", required),
  validate("billingEmailAddress", "Billing Contact Email", required, email)
];

export const SDRules = [];

export const SDPSLSrules = [
  validate("residenceName", "Business/Residence Name", required),
  validate("addressLine1", "Address Line 1", required),
  // validate("addressLine2", "Address Line 2", required),
  // validate("addressLine3", "Address Line 3", required),
  validate("city", "City", required),
  validate("state", "State", required),
  validate("country", "Country", required),
  // validate("province", "Province", required),
  validate("postalCode", "Postal Code", required),
  validate("siteContactName", "Contact Name", required),
  validate("siteContactOfficeNumber", "Phone No", required, phoneNumber),
  validate("siteContactMobileNumber", "Mobile No", phoneNumber),
  validate("siteContactEmailAddress", "Email", email)
];

export const IsEquipmentInstalledRules = [
  validate("showPrelimLocation", "Is equipment already installed", required)
];

export const SDFISSrules = [
  validate("residenceName", "Business/Residence Name", required),
  validate("addressLine1", "Address Line 1", required),
  // validate("addressLine2", "Address Line 2", required),
  // validate("addressLine3", "Address Line 3", required),
  validate("city", "City", required),
  validate("state", "State", required),
  validate("country", "Country", required),
  // validate("province", "Province", required),
  validate("postalCode", "Postal Code", required),
  validate("siteContactName", "Contact Name", required),
  validate("siteContactOfficeNumber", "Phone No", required, phoneNumber),
  validate("siteContactMobileNumber", "Mobile No", phoneNumber),
  validate("siteContactEmailAddress", "Email", email)
];

export const SDAHJDrules = [
  validate("jurisdictionName", "Name of Jurisdiction"),
  validate("addressLine1", "Address Line 1"),
  validate("addressLine2", "Address Line 2"),
  validate("addressLine3", "Address Line 3"),
  validate("city", "City"),
  validate("state", "State"),
  validate("country", "Country"),
  validate("province", "Province"),
  validate("postalCode", "Postal Code"),
  validate("inspectorFirstName", "First Name of Inspector"),
  validate("inspectorLastName", "Last Name of Inspector"),
  validate("inspectorName", "Inspector Name"),
  validate("inspectorOfficeNumber", "Inspector Office Number", phoneNumber),
  validate("inspectorMobileNumber", "Inspector Cell Number", phoneNumber),
  validate("inspectorEmailAddress", "Inspector Email Address", email)
];

export const SDFSLSrules = [
  validate("residenceName", "Business/Residence Name", required),
  validate("addressLine1", "Address Line 1", required),
  // validate("addressLine2", "Address Line 2", required),
  // validate("addressLine3", "Address Line 3", required),
  validate("city", "City", required),
  validate("state", "State", required),
  validate("country", "Country", required),
  // validate("province", "Province", required),
  validate("postalCode", "Postal Code", required)
];

export const PDrules = [
  // validate("rfqNumber", "RFQ #", required, numberOnly),
  // validate("cancelReasonCode", "Cancel Reason Code", required),
  // validate("scopeChangeRequest", "Scope Change Request", required),
  // validate("requestStatus", "Status", required),
  // validate("returnFormTo", "Return Form To", required)
];

export const RDrules = [];

export const RDPSLSrules = [
  validate("residenceName", "Business/Residence Name", required),
  validate("addressLine1", "Address Line 1", required),
  // validate("addressLine2", "Address Line 2", required),
  // validate("addressLine3", "Address Line 3", required),
  validate("city", "City", required),
  validate("state", "State", required),
  validate("country", "Country", required),
  // validate("province", "Province", required),
  validate("postalCode", "Postal Code", required),
  validate("siteContactName", "Site Contact Name", required),
  validate(
    "siteContactOfficeNumber",
    "Primary Site Contact Tel #",
    required,
    phoneNumber
  )
  // validate("siteContactMobileNumber","Secondary Site Contact Tel #",required,phoneNumber)
  // validate("siteContactEmailAddress", "Site Contact Email", required, email)
  // validate("preferredStartTime", "Preferred Start time", required, numberOnly)
];

export const RDFSLSrules = [
  validate("residenceName", "Business/Residence Name", required),
  validate("addressLine1", "Address Line 1", required),
  // validate("addressLine2", "Address Line 2", required),
  // validate("addressLine3", "Address Line 3", required),
  validate("city", "City", required),
  validate("state", "State", required),
  validate("country", "Country", required),
  // validate("province", "Province", required),
  validate("postalCode", "Postal Code", required),
  validate("siteContactName", "Site Contact Name", required),
  validate(
    "siteContactOfficeNumber",
    "Site Contact Office Tel",
    required,
    phoneNumber
  ),
  validate("siteContactMobileNumber", "Site Contact Cell", phoneNumber),
  validate("siteContactEmailAddress", "Site Contact Email", email)
  // validate("preferredStartTime", "Preferred Start time", required)
];

export const RDAHJDrules = [
  validate("jurisdictionName", "Name of Jurisdiction"),
  validate("addressLine1", "Address Line 1"),
  validate("addressLine2", "Address Line 2"),
  validate("addressLine3", "Address Line 3"),
  validate("city", "City"),
  validate("state", "State"),
  validate("country", "Country"),
  validate("province", "Province"),
  validate("postalCode", "Postal Code"),
  validate("inspectorFirstName", "First Name of Inspector"),
  validate("inspectorLastName", "Last Name of Inspector"),
  validate("inspectorName", "Inspector Name"),
  validate("inspectorOfficeNumber", "Inspector Office Number", phoneNumber),
  validate("inspectorMobileNumber", "Inspector Cell Number", phoneNumber),
  validate("inspectorEmailAddress", "Inspector Email Address", email)
];

export const SDOtherDetailsRules = [
  validate("isReadyToScheduleNow", "Ready to schedule", requiredCheckbox)
];

export const FEGSQRules = [
  [
    validate("serviceProductId", "Project Category", required),
    "serviceProductId"
  ],
  [
    validate("serviceSubProductId", "Equipment Type", required),
    "serviceSubProductId"
  ],
  [validate("quantity", "Quantity", required), "quantity"],
  [
    validate(
      "panelboardOver200",
      "Is the Panelboard Main breaker rated over 200A",
      requiredCheckbox
    ),
    "panelboardOver200"
  ],
  [
    validate(
      "switchboardOver600",
      "Is the Switchboard/Switchgear rated over 600 VAC",
      requiredCheckbox
    ),
    "switchboardOver600"
  ],
  [validate("propertyType", "Property Type", requiredCheckbox), "propertyType"],
  [
    validate(
      "isInstalledSameLocation",
      "Will all units be installed at the same location",
      requiredCheckbox
    ),
    "isInstalledSameLocation"
  ],
  [
    validate(
      "isEvaluatedSameVisit",
      "Can all of the units be evaluated during the same day/visit",
      requiredCheckboxWithNull
    ),
    "isEvaluatedSameVisit"
  ],
  [
    validate(
      "isExistingUlEquipment",
      "Is there an existing UL label(s) on the equipment",
      requiredCheckbox
    ),
    "isExistingUlEquipment"
  ],
  [
    validate(
      "isInvolvedLitigation",
      "Is product involved in current or past litigation",
      requiredCheckbox
    ),
    "isInvolvedLitigation"
  ],
  // [
  //   validate("litigationDescription", "Describe", required),
  //   "litigationDescription"
  // ],
  [validate("totalPriceByPh", "Total Price", required), "totalPriceByPh"],
  [validate("scope", "Scope", maxLength(150)), "scope"]
];
