import PropTypes from "prop-types";
import { connect } from "react-redux";
import React, { Component, Fragment } from "react";
import {
  Grid,
  Form,
  Dropdown,
  Input,
  Checkbox,
  Icon,
  Popup,
  TextArea,
  Divider,
  Button,
  Label
} from "semantic-ui-react";

import { Common } from "../../../../common";
import Property from "../../../../enums/property";
import { FEGSQRules } from "../../validation-rules";
import ServiceType from "../../../../enums/service-type";
import {
  validate,
  getError,
  validateField
} from "../../../../validator/validator";
import {
  required,
  maxLengthNumberInput
} from "../../../../validator/error-message";

import SiteDetails from "../common/site-details/site-details";

import * as actions from "./project-details.action";

class ProjectDetails extends Component {
  state = {
    serviceProduct: {},
    serviceSubProduct: {},
    serviceProductSelected: "AESI-LST",
    exceededSizeLimit: false
  };

  componentDidMount() {
    const me = this;

    this.props.getServiceProducts(ServiceType.fe).then((s) => {
      if (s.isSuccessful && s.data.length) {
        me.props.getServiceSubProducts(s.data[0].id);
        me.updateFormObject({
          serviceProductId: s.data[0].id,
          serviceProductName: s.data[0].name,
          serviceProductCode: s.data[0].code,
          tabIndex: me.props.index,
          validationErrors: {}
        });
        setTimeout(() => me.props.isLoading(me.props.index), 700);
      }
    });
  }

  getDropdownList(data) {
    var list = [];
    data.map(function (v) {
      var tmp = {};
      tmp["key"] = v.id;
      tmp["id"] = v.id;
      tmp["code"] = v.code;
      tmp["value"] = v.code;
      tmp["text"] = v.name;

      list.push(tmp);
    });
    return list;
  }

  handleChange = (e) => {
    const me = this;
    const {
      target: { value, name }
    } = e;

    if (name == "quantity") {
      setTimeout(() => {
        me.props.checkManualPricingTrigger(value, name, me.props.index);
      }, 200);

      if (maxLengthNumberInput(e, 15)) return false;
    }

    this.updateForm(name, value);
  };

  handleChangeRadio = (e, s) => {
    this.updateForm(s.name, s.value);

    setTimeout(() => {
      if (
        s.name == "isInvolvedLitigation" ||
        s.name == "switchboardOver600" ||
        s.name == "isEvaluatedSameVisit" ||
        s.name == "isExistingUlEquipment"
      ) {
        this.props.checkManualPricingTrigger(s.value, s.name, this.props.index);
      }
    }, 100);
  };

  updateForm(name, value) {
    const {
      index,
      updateForm,
      projectDetails: { forms },
      getFormByTabIndex
    } = this.props;
    const form = getFormByTabIndex(index, forms);

    let newForm = Object.assign({}, form, {
      [name]: value
    });

    const newRules = actions.getValidation(newForm, FEGSQRules);
    const newState = validateField(newRules, form, name, value);

    updateForm(index, newState, forms);
  }

  updateFormObject(obj) {
    const {
      index,
      updateForm,
      projectDetails: { forms },
      getFormByTabIndex
    } = this.props;
    const form = getFormByTabIndex(index, forms) || {};

    const newForm = Object.assign({}, form, {
      ...obj,
      tabIndex: (form || {}).tabIndex || index
    });

    const newRules = actions.getValidation(newForm, FEGSQRules);
    let newState = newForm;

    if (form && form.validationErrors)
      Object.keys(obj).forEach((s) => {
        newState = Object.assign({}, newState, {
          ...validateField(newRules, form, s, obj[s])
        });
      });

    updateForm(index, newState, forms);
  }

  onChangeServiceProduct(e) {
    this.updateFormObject({
      serviceProductId: parseInt(e.currentTarget.id),
      serviceProductName: e.currentTarget.innerText,
      serviceProductCode: e.currentTarget.getAttribute("code")
    });
  }

  onChangeServiceSubProduct(e) {
    const subCode = e.currentTarget.getAttribute("code");
    const isPnl = subCode === "PNL";

    this.updateFormObject({
      serviceSubProductId: parseInt(e.currentTarget.id),
      serviceSubProductName: e.currentTarget.innerText,
      serviceSubProductCode: subCode,
      isPanelboard: isPnl,
      isSwitchboard: !isPnl,
      propertyType: isPnl ? Property.residential : Property.commercial
    });

    setTimeout(() => {
      this.props.checkManualPricingTrigger(
        e.currentTarget.innerText,
        "equipmentType",
        this.props.index
      );
    }, 100);
  }

  // updateFormUpload(file) {
  //   const {
  //     index,
  //     updateForm,
  //     projectDetails: { forms },
  //     getFormByTabIndex,
  //   } = this.props;
  //   const form = getFormByTabIndex(index, forms);
  //   const idx = (form.files || []).findIndex(
  //     (s) => (s || {}).name === file.name
  //   );
  //   //const idx = (form.files || []).findIndex((s) => s.name === file.name);
  //   if (idx > -1) form.files[idx] = file;
  //   else form.files.push(file);

  //   updateForm(index, form, forms);
  // }

  handleFileChange = (event) => {
    const { exceededSizeLimit } = this.state;

    if (event.target.files.length > 0) {
      const file = event.target.files[0];

      if (file.size > 4294967296) {
        this.setState({ exceededSizeLimit: true });
      } else {
        this.setState({ exceededSizeLimit: false });

        var fileReader = new FileReader();
        var that = this;

        //this.updateFormUpload(file);
        this.updateForm("fileName", file.name);

        fileReader.onload = function (fileLoadedEvent) {
          var srcData = fileLoadedEvent.target.result;
          that.updateForm("artworkLink", srcData);
        };
        fileReader.readAsDataURL(file);
      }
    } else this.setState({ file: null });
  };

  getErrorFor(field) {
    const { index, getFormByTabIndex, projectDetails } = this.props;

    const { forms } = projectDetails || {};
    const form = getFormByTabIndex(index, forms);

    return form ? getError(field, form, form) : "";
  }

  getErrorForChk(field) {
    const msg = this.getErrorFor(field);

    if (!msg || (msg && !msg.length)) return null;

    return (
      <React.Fragment>
        <br />
        <div className="ui pointing above prompt label chk-rad-error">
          {msg}
        </div>
      </React.Fragment>
    );
  }

  getErrorForChkClass(field) {
    const msg = this.getErrorFor(field);

    return msg && msg.length ? "error" : "";
  }

  render() {
    const {
      index,
      checkManualPricingTrigger,
      getFormByTabIndex,
      orderNumber,
      createdForUserId
    } = this.props;

    const { serviceProductSelected, exceededSizeLimit } = this.state;
    const {
      serviceProduct,
      serviceSubProduct,
      forms,
      siteDetails
    } = this.props.projectDetails;

    const { attachment } = this.props.fieldEvaluation.form;

    const form = getFormByTabIndex(index, forms) || {};
    const siteDetailForm = siteDetails[index] || {};

    let isManualPricing = (form || {}).isManualPricing === true;
    let isPHEdit = Common.isPhEditing(isManualPricing);

    return (
      <Fragment>
        <Form>
          <Grid>
            <Grid.Row columns={2}>
              <Grid.Column>
                <Form.Field>
                  <label>* Project Category</label>
                  <Dropdown
                    compact
                    selection
                    placeholder="Select Project Category"
                    options={
                      serviceProduct.data
                        ? this.getDropdownList(serviceProduct.data)
                        : null
                    }
                    //onChange={this.onChangeServiceProduct.bind(this)}
                    value={serviceProductSelected}
                    selectOnBlur={false}
                    name="serviceProductId"
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>

            {serviceProduct.data && serviceProduct.data.length > 0 ? (
              <Grid.Row columns={4}>
                <Grid.Column>
                  <Form.Field
                    className={`rad-chk-field ${this.getErrorForChkClass(
                      "serviceSubProductId"
                    )}`}
                  >
                    <label>* Equipment Type</label>
                    <Dropdown
                      selection
                      placeholder="Select Equipment Type"
                      options={
                        ((serviceSubProduct || {}).data || []).length
                          ? this.getDropdownList(serviceSubProduct.data)
                          : null
                      }
                      onChange={this.onChangeServiceSubProduct.bind(this)}
                      selectOnBlur={false}
                      value={form.serviceSubProductCode || ""}
                      name="serviceSubProductId"
                    />
                    {this.getErrorForChk("serviceSubProductId")}
                  </Form.Field>
                </Grid.Column>

                <Grid.Column>
                  <Form.Field
                    control={Input}
                    label="* # of Units (ea)"
                    min="1"
                    max="15"
                    type="number"
                    name="quantity"
                    onChange={this.handleChange}
                    value={form.quantity || ""}
                    error={this.getErrorFor("quantity")}
                  ></Form.Field>
                </Grid.Column>

                {/* PH edit if manual pricing */}
                {isPHEdit ? (
                  <Grid.Column>
                    <Form.Field
                      control={Input}
                      label="* Total Price - USD"
                      type="number"
                      name="totalPriceByPh"
                      onChange={this.handleChange}
                      value={form.totalPriceByPh || ""}
                      error={this.getErrorFor("totalPriceByPh")}
                    ></Form.Field>
                  </Grid.Column>
                ) : null}
              </Grid.Row>
            ) : null}

            {form.isPanelboard ? (
              <Grid.Row columns={2}>
                <Grid.Column>
                  <Form.Field
                    className={`rad-chk-field ${this.getErrorForChkClass(
                      "panelboardOver200"
                    )}`}
                  >
                    * Is the Panelboard Main breaker rated over 200A?
                  </Form.Field>
                  <Form.Field
                    className={this.getErrorForChkClass("panelboardOver200")}
                  >
                    <Checkbox
                      radio
                      label="Yes"
                      name="panelboardOver200"
                      value={true}
                      checked={form.panelboardOver200 === true}
                      onChange={this.handleChangeRadio}
                    />
                    &nbsp;&nbsp;&nbsp;
                    <Checkbox
                      radio
                      label="No"
                      name="panelboardOver200"
                      value={false}
                      checked={form.panelboardOver200 === false}
                      onChange={this.handleChangeRadio}
                    />
                    {this.getErrorForChk("panelboardOver200")}
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
            ) : null}

            {form.isSwitchboard ? (
              <Grid.Row columns={2}>
                <Grid.Column>
                  <Form.Field
                    className={`rad-chk-field ${this.getErrorForChkClass(
                      "switchboardOver600"
                    )}`}
                  >
                    * Is the Switchboard/Switchgear rated over 600 VAC?
                  </Form.Field>
                  <Form.Field
                    className={this.getErrorForChkClass("switchboardOver600")}
                  >
                    <Checkbox
                      radio
                      label="Yes"
                      name="switchboardOver600"
                      value={true}
                      checked={form.switchboardOver600 === true}
                      onChange={this.handleChangeRadio.bind(this)}
                    />
                    &nbsp;&nbsp;&nbsp;
                    <Checkbox
                      radio
                      label="No"
                      name="switchboardOver600"
                      value={false}
                      checked={form.switchboardOver600 === false}
                      onChange={this.handleChangeRadio.bind(this)}
                    />
                    {this.getErrorForChk("switchboardOver600")}
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
            ) : null}

            {form.isPanelboard || form.isSwitchboard ? (
              <Grid.Row columns={2}>
                <Grid.Column>
                  <Form.Field
                    className={`rad-chk-field ${this.getErrorForChkClass(
                      "propertyType"
                    )}`}
                  >
                    * Property Type
                  </Form.Field>
                  <Form.Field
                    className={`inline-flex ${this.getErrorForChkClass(
                      "propertyType"
                    )}`}
                  >
                    <Checkbox
                      radio
                      label="Residential"
                      name="propertyType"
                      value={Property.residential}
                      checked={form.propertyType == Property.residential}
                      onChange={this.handleChangeRadio}
                    />
                    {this.getErrorForChk("propertyType")}
                    <Popup
                      trigger={<Icon name="info circle" />}
                      content="Single Family Residence"
                      size="mini"
                    />
                    &nbsp;&nbsp;&nbsp;
                    <Checkbox
                      radio
                      label="Commercial"
                      name="propertyType"
                      value={Property.commercial}
                      checked={form.propertyType == Property.commercial}
                      onChange={this.handleChangeRadio}
                    />
                    <Popup
                      trigger={<Icon name="info circle" />}
                      content="Commercial Building, Multi-Family or Apartments"
                      size="mini"
                    />
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
            ) : null}

            {form.quantity && parseInt(form.quantity) > 1 ? (
              <Grid.Row>
                <Grid.Column columns={2}>
                  <Form.Field
                    className={`rad-chk-field ${this.getErrorForChkClass(
                      "isEvaluatedSameVisit"
                    )}`}
                  >
                    * Can all of the units be evaluated during the same
                    day/visit?
                  </Form.Field>
                  <Form.Field
                    className={this.getErrorForChkClass("isEvaluatedSameVisit")}
                  >
                    <Checkbox
                      radio
                      label="Yes"
                      name="isEvaluatedSameVisit"
                      value={true}
                      checked={form.isEvaluatedSameVisit === true}
                      onChange={this.handleChangeRadio.bind(this)}
                    />
                    &nbsp;&nbsp;&nbsp;
                    <Checkbox
                      radio
                      label="No"
                      name="isEvaluatedSameVisit"
                      value={false}
                      checked={form.isEvaluatedSameVisit === false}
                      onChange={this.handleChangeRadio.bind(this)}
                    />
                    {this.getErrorForChk("isEvaluatedSameVisit")}
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
            ) : null}

            <Grid.Row>
              <Grid.Column>
                <Form.Field
                  className={`rad-chk-field ${this.getErrorForChkClass(
                    "isExistingUlEquipment"
                  )}`}
                >
                  * Is there an existing UL label(s) on the equipment?
                </Form.Field>
                <Form.Field
                  className={this.getErrorForChkClass("isExistingUlEquipment")}
                >
                  <Checkbox
                    radio
                    label="Yes"
                    name="isExistingUlEquipment"
                    value={true}
                    checked={form.isExistingUlEquipment === true}
                    onChange={this.handleChangeRadio}
                  />
                  &nbsp;&nbsp;&nbsp;
                  <Checkbox
                    radio
                    label="No"
                    name="isExistingUlEquipment"
                    value={false}
                    checked={form.isExistingUlEquipment === false}
                    onChange={this.handleChangeRadio}
                  />
                  {this.getErrorForChk("isExistingUlEquipment")}
                </Form.Field>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column columns={2}>
                <Form.Field
                  className={`rad-chk-field ${this.getErrorForChkClass(
                    "isInvolvedLitigation"
                  )}`}
                >
                  * Is product involved in current or past litigation?
                </Form.Field>
                <Form.Field
                  className={this.getErrorForChkClass("isInvolvedLitigation")}
                >
                  <Checkbox
                    radio
                    label="Yes"
                    name="isInvolvedLitigation"
                    value={true}
                    checked={form.isInvolvedLitigation === true}
                    onChange={this.handleChangeRadio.bind(this)}
                  />
                  &nbsp;&nbsp;&nbsp;
                  <Checkbox
                    radio
                    label="No"
                    name="isInvolvedLitigation"
                    value={false}
                    checked={form.isInvolvedLitigation === false}
                    onChange={this.handleChangeRadio.bind(this)}
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>

            {form.isInvolvedLitigation ? (
              <Grid.Row columns={2}>
                <Grid.Column>
                  <label>Describe</label>
                  <TextArea
                    placeholder="Litigation Description"
                    name="litigationDescription"
                    onChange={this.handleChange}
                    value={form.litigationDescription || ""}
                    maxLength="1000"
                    autoComplete="off"
                    className="margin-top-5"
                  />
                </Grid.Column>
              </Grid.Row>
            ) : null}

            <Grid.Row columns={2}>
              <Grid.Column>
                <label>
                  Additional Information relevant to your project you want us to
                  know
                </label>
                &nbsp;
                <Popup
                  trigger={<Icon name="info circle" />}
                  content="Permit #, reason for Field Evaluation, etc"
                  size="mini"
                />
                <TextArea
                  placeholder="Additional Information relevant to your project you want us to know"
                  name="additionalInfo"
                  onChange={this.handleChange}
                  value={form.additionalInfo || ""}
                  autoComplete="off"
                  className="margin-top-5"
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
              <Grid.Column>
                <Form.Field>
                  <label>Upload Technical Documents</label>
                  <div style={{ float: "left", marginRight: "15px" }}>
                    {/* <Button
                      primary
                      as="label"
                      htmlFor="file"
                      type="button"
                      style={{ border: "none" }}
                      class="ui primary button"
                    >
                      Choose File
                    </Button> */}
                    <input
                      type="file"
                      id="file"
                      name="file"
                      style={{ display: "none" }}
                      onChange={this.handleFileChange}
                      ref={(fileInput) => (this.fileInput = fileInput)}
                    />
                    <button
                      onClick={() => this.fileInput.click()}
                      className="ui primary button"
                    >
                      Choose File
                    </button>
                  </div>
                  <div style={{ clear: "both" }}></div>
                  <Divider hidden fitted />
                  <div hidden={!exceededSizeLimit}>
                    <Label basic color="red" pointing>
                      File should not exceed more than 4GB.
                    </Label>
                  </div>
                  <div style={{ float: "left" }} hidden={!form.fileName}>
                    <div style={{ position: "relative" }}>
                      <Icon name="file" size={"huge"}></Icon>
                    </div>

                    {form.fileName || ""}
                  </div>
                  <div style={{ clear: "both" }}>
                    <a href={attachment && attachment.filePath} target="_blank">
                      {attachment && attachment.originalFileName}
                    </a>
                  </div>
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
        <SiteDetails
          index={index}
          siteDetailForm={siteDetailForm}
          handleChangeTabIndex={this.props.handleChangeTabIndex}
          checkManualPricingTrigger={checkManualPricingTrigger}
          orderNumber={orderNumber}
          createdForUserId={createdForUserId}
        />
      </Fragment>
    );
  }
}

ProjectDetails.propTypes = {
  index: PropTypes.number.isRequired,
  checkManualPricingTrigger: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  return {
    fieldService: state.fieldService,
    projectDetails: state.feProjectDetails,
    fieldEvaluation: state.fieldEvaluation
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getServiceProducts: (serviceType) =>
      dispatch(actions.getServiceProducts(serviceType)),
    getServiceSubProducts: (serviceProductId) =>
      dispatch(actions.getServiceSubProducts(serviceProductId)),
    updateForm: (idx, form, forms) =>
      dispatch(actions.updateForm(idx, form, forms)),
    getFormByTabIndex: (idx, forms) => actions.getFormByTabIndex(idx, forms)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDetails);
