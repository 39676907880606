export const GET_USERS_REQUEST = "GET_USERS_REQUEST";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAILURE = "GET_USERS_FAILURE";

export const GET_ROLES_REQUEST = "GET_ROLES_REQUEST";
export const GET_ROLES_SUCCESS = "GET_ROLES_SUCCESS";
export const GET_ROLES_FAILURE = "GET_ROLES_FAILURE";

export const GET_LEGAL_ENTITIES_REQUEST = "GET_LEGAL_ENTITIES_REQUEST";
export const GET_LEGAL_ENTITIES_SUCCESS = "GET_LEGAL_ENTITIES_SUCCESS";
export const GET_LEGAL_ENTITIES_FAILURE = "GET_LEGAL_ENTITIES_FAILURE";

export const CHANGE_ROLE_REQUEST = "CHANGE_ROLE_REQUEST";
export const CHANGE_ROLE_SUCCESS = "CHANGE_ROLE_SUCCESS";
export const CHANGE_ROLE_FAILURE = "CHANGE_ROLE_FAILURE";

export const CHANGE_LEGAL_ENTITIES_REQUEST = "CHANGE_LEGAL_ENTITIES_REQUEST";
export const CHANGE_LEGAL_ENTITIES_SUCCESS = "CHANGE_LEGAL_ENTITIES_SUCCESS";
export const CHANGE_LEGAL_ENTITIES_FAILURE = "CHANGE_LEGAL_ENTITIES_FAILURE";

export const SUSPEND_USER_REQUEST = "SUSPEND_USER_REQUEST";
export const SUSPEND_USER_SUCCESS = "SUSPEND_USER_SUCCESS";
export const SUSPEND_USER_FAILURE = "SUSPEND_USER_FAILURE";

export const LIST_NOTE_REQUEST = "LIST_NOTE_REQUEST";
export const LIST_NOTE_SUCCESS = "LIST_NOTE_SUCCESS";
export const LIST_NOTE_FAILURE = "LIST_NOTE_FAILURE";

export const ADD_NOTE_SUCCESS = "ADD_NOTE_SUCCESS";
export const ADD_NOTE_FAILURE = "ADD_NOTE_FAILURE";

export const GET_ROLES_BY_USER_FILTER_REQUEST =
  "GET_ROLES_BY_USER_FILTER_REQUEST";
export const GET_ROLES_BY_USER_FILTER_FAILURE =
  "GET_ROLES_BY_USER_FILTER_FAILURE";
export const GET_ROLES_BY_USER_FILTER_SUCCESS =
  "GET_ROLES_BY_USER_FILTER_SUCCESS";

export const SET_RA_USER_FILTER_REQUEST = "SET_RA_USER_FILTER_REQUEST";
export const SET_RA_USER_FILTER_FAILURE = "SET_RA_USER_FILTER_FAILURE";
export const SET_RA_USER_FILTER_SUCCESS = "SET_RA_USER_FILTER_SUCCESS";

export const GET_RA_USER_FILTER_REQUEST = "GET_RA_USER_FILTER_REQUEST";
export const GET_RA_USER_FILTER_FAILURE = "GET_RA_USER_FILTER_FAILURE";
export const GET_RA_USER_FILTER_SUCCESS = "GET_RA_USER_FILTER_SUCCESS";

export const GET_FO_EMAILS_REQUEST = "GET_FO_EMAILS_REQUEST";
export const GET_FO_EMAILS_FAILURE = "GET_FO_EMAILS_FAILURE";
export const GET_FO_EMAILS_SUCCESS = "GET_FO_EMAILS_SUCCESS";

export const GET_FO_FIRSTNAMES_REQUEST = "GET_FO_FIRSTNAMES_REQUEST";
export const GET_FO_FIRSTNAMES_FAILURE = "GET_FO_FIRSTNAMES_FAILURE";
export const GET_FO_FIRSTNAMES_SUCCESS = "GET_FO_FIRSTNAMES_SUCCESS";

export const GET_FO_LASTNAMES_REQUEST = "GET_FO_LASTNAMES_REQUEST";
export const GET_FO_LASTNAMES_FAILURE = "GET_FO_LASTNAMES_FAILURE";
export const GET_FO_LASTNAMES_SUCCESS = "GET_FO_LASTNAMES_SUCCESS";

export const GET_FO_ROLES_REQUEST = "GET_FO_ROLES_REQUEST";
export const GET_FO_ROLES_FAILURE = "GET_FO_ROLES_FAILURE";
export const GET_FO_ROLES_SUCCESS = "GET_FO_ROLES_SUCCESS";

export const GET_FO_LEGALENTITIES_REQUEST = "GET_FO_LEGALENTITIES_REQUEST";
export const GET_FO_LEGALENTITIES_FAILURE = "GET_FO_LEGALENTITIES_FAILURE";
export const GET_FO_LEGALENTITIES_SUCCESS = "GET_FO_LEGALENTITIES_SUCCESS";

export const GET_FO_PSN_REQUEST = "GET_FO_PSN_REQUEST";
export const GET_FO_PSN_FAILURE = "GET_FO_PSN_FAILURE";
export const GET_FO_PSN_SUCCESS = "GET_FO_PSN_SUCCESS";

export const ROLE_FILTEROPTION_RESET = "ROLE_FILTEROPTION_RESET";

export const ROLE_ASSIGNMENT_UPDATE = "ROLE_ASSIGNMENT_UPDATE";

export const ROLE_ASSIGNMENT_FORM = "ROLE_ASSIGNMENT_FORM";
export const ROLE_ASSIGNMENT_CLEAR = "ROLE_ASSIGNMENT_CLEAR";

export const CLAIMS_DETAILS_REQUEST = "CLAIMS_DETAILS_REQUEST";
export const CLAIMS_DETAILS_SUCCESS = "CLAIMS_DETAILS_SUCCESS";
export const CLAIMS_DETAILS_FAILURE = "CLAIMS_DETAILS_FAILURE";
export const CLEAR_CLAIMS_DETAILS = "CLEAR_CLAIMS_DETAILS";

export const AUTHORIZE_ND_LABEL_REQUEST = "AUTHORIZE_ND_LABEL_REQUEST";
export const AUTHORIZE_ND_LABEL_SUCCESS = "AUTHORIZE_ND_LABEL_SUCCESS";
export const AUTHORIZE_ND_LABEL_FAILURE = "AUTHORIZE_ND_LABEL_FAILURE";
