import React, { Component, Fragment } from 'react';
import {
  Container,
  Button,
  Icon,
  Grid,
  Dimmer,
  Loader,
  Pagination,
  Message,
  Table,
  Segment,
  Modal,
  Form,
} from 'semantic-ui-react';
import FooterHomeBlock from '../../components/footer/footer-home-block';
import {
  getOrdersByUser,
  getOrders,
  selectAll,
  changePageByUser,
  getOrdersByUserFilter,
  getUserFilter,
  filterOptionReset,
  setUserFilter,
  updateData,
  uploadLabelOrderAttachment,
  getLabelOrderAttachment,
  deleteLabelOrderAttachment,
  sendAttachmentMessage,
  updateBubbleStatus,
  exportOrderList,
  exportOrderListByUser,
  select,
  clearExportSelection,
  createReportRequest,
  getReportLink,
  closeReportRequestDialog,
  closeReportLinkDialog,
  closeCannotCopyDialog,
  closeCopyNotCompliedDialog,
  openCannotCopyDialog,
  openCopyNotCompliedDialog,
  openExistingItemInCartDialog,
  closeExistingItemInCartDialog,
  checkCartAndOrderDetails,
  manualRepushOrder,
  openAutoSwitchPsnRoleDialog,
  closeAutoSwitchPsnRoleDialog,
  clearFilter,
  disableClearFilter,
} from './view-orders.action';
import { connect } from 'react-redux';
import OrderItem from './order-item';
import LabelFilterOption from './common/label-filter-option';
import _ from 'lodash';
import LabelColumnOption from './common/label-column-option';
import { Common } from '../../common';
import Role from '../../enums/role';
import { toast } from 'react-semantic-toasts';
import * as commonActions from '../../views/user/common/common.action';
import { Redirect } from 'react-router-dom';
import { copyOrderCheck } from '../../enums/copy-order-check';
import '../../assets/css/styles.css';
import { scanFile } from '../../views/order/combination/existing-combination-labels.action';
import * as userActions from '../../views/user/common/common.action';
import {
  getNotifications,
  updateNotificationView,
} from '../../views/dashboard/dashboard.action';
import {
  CustomContainerHeader,
  CustomHeaderLabelRelaxed,
} from '../../components/labels/labels';
import { viewOrdersRoleColumns } from './view-orders.role-columns';
const translations = Common.getTranslations() || {};
const userRole = Common.getUserRole();

const customStyles = {
  GridButtonStyle: {
    border: '1px solid #2185d0',
    color: '#2185d0',
    background: 'white',
    marginBottom: '10px',
  },
  ExportButtonStyle: {
    marginLeft: '10px',
  },
  LabelStyle: {
    verticalAlign: 'top',
    fontSize: '14px',
    marginLeft: '5px',
  },
  CheckboxStyle: {
    border: '3px solid black',
    padding: '0px',
    height: '23px',
    width: '23px',
  },
  SidebarStyle: {
    width: '70%',
    fontSize: '12px',
  },
  ContainerStyle: {
    padding: '20px',
  },
  TableDivStyle: {
    overflow: 'auto',
  },
  ColumnPreferenceStyle: {
    fontSize: '16px',
    color: '##2185d0',
    verticalAlign: 'top',
    fontWeight: 'bold',
    textDecoration: 'underline',
  },
  GridOverflowX: {
    overflowX: 'auto',
  },
  ReportLinkStyle: {
    fontSize: '14px',
    textDecoration: 'underline',
  },
};

// Note: changes must always be reflected to LabelOrderColumnOptions enum in backend

class ViewOrders extends Component {
  state = {
    open: false,
    column: null,
    columnOptions: [
      {
        key: 'orderStatus',
        value: 0,
        text: translations['LexOrderStatus'],
        visible: 1,
        sort: 0,
        checked: 1,
        disabled: 0,
      },
      {
        key: 'reOrder',
        value: 0,
        text: translations['LexReOrder'],
        sort: 0,
        visible: 1,
        checked: 1,
        disabled: 0,
      },
      {
        key: 'labelCenter',
        value: 0,
        text: translations['LexLabelCenter'],
        visible: 1,
        sort: 0,
        checked: 1,
        disabled: 0,
      },
      {
        key: 'cartOrderNo',
        value: 0,
        text: translations['LexCartOrderNumber'],
        visible: 1,
        sort: 0,
        checked: 1,
        disabled: 0,
      },
      {
        key: 'statusUpdateDateFormatted',
        value: 0,
        text: translations['LexCartOrderDate'],
        visible: 1,
        sort: 0,
        checked: 1,
        disabled: 0,
      },
      {
        key: 'orderNo',
        value: 0,
        text: translations['LexULOrderNumber'],
        visible: 1,
        sort: 0,
        checked: 1,
        disabled: 0,
      },
      {
        key: 'oracleOrderDate',
        value: 0,
        text: translations['LexULOrderDate'],
        visible: 1,
        sort: 0,
        checked: 1,
        disabled: 0,
      },
      {
        key: 'customerPONo',
        value: 0,
        text: translations['LexCustomerPONo.'],
        visible: 1,
        sort: 0,
        checked: 1,
        disabled: 0,
      },
      {
        key: 'customerContactName',
        value: 0,
        text: translations['LexCustomerContactName'],
        visible: 1,
        sort: 0,
        checked: 1,
        disabled: 0,
      },
      {
        key: 'applicantAccountNo',
        value: 0,
        text: translations['LexApplicantAccountNumber'],
        visible: 1,
        sort: 0,
        checked: 1,
        disabled: 0,
      },
      {
        key: 'applicantCompanyName',
        value: 0,
        text: translations['LexApplicantCompanyName'],
        visible: 1,
        sort: 0,
        checked: 1,
        disabled: 0,
      },
      {
        key: 'applicantAddress',
        value: 0,
        text: translations['LexApplicantAddress'],
        visible: 1,
        sort: 0,
        checked: 1,
        disabled: 0,
      },
      {
        key: 'manufacturerAccountNumber',
        value: 0,
        text: translations['LexManufacturerAccountNumber'],
        visible: 1,
        sort: 0,
        checked: 1,
        disabled: 0,
      },
      {
        key: 'manufacturerCompanyName',
        value: 0,
        text: translations['LexManufacturerCompanyName'],
        visible: 1,
        sort: 0,
        checked: 1,
        disabled: 0,
      },
      {
        key: 'manufacturerAddress',
        value: 0,
        text: translations['LexManufacturerAddress'],
        visible: 1,
        sort: 0,
        checked: 1,
        disabled: 0,
      },
      {
        key: 'shipToPartySiteNo',
        value: 0,
        text: 'Manufacturer Party Site No',
        visible: 1,
        sort: 0,
        checked: 1,
        disabled: 0,
      },
      {
        key: 'fileNo',
        value: 0,
        text: translations['LexFileNumber'],
        visible: 1,
        sort: 0,
        checked: 1,
        disabled: 0,
      },
      {
        key: 'labelType',
        value: 0,
        text: translations['LexLabelType'],
        visible: 1,
        sort: 0,
        checked: 1,
        disabled: 0,
      },
      {
        key: 'repushOrder',
        value: 0,
        text: 'Re-push Order',
        visible: 1,
        sort: 0,
        checked: 1,
        disabled: 0,
      },
      {
        key: 'emailSent',
        value: 0,
        text: 'Email Sent',
        visible: 1,
        sort: 0,
        checked: 1,
        disabled: 0,
      },
      {
        key: 'vendorLetter',
        value: 0,
        text: translations['LexVendorLetter'],
        visible: 1,
        sort: 0,
        checked: 1,
        disabled: 0,
      },
      {
        key: 'vendorLetterSentToCustomer',
        value: 0,
        text: 'Is Vendor Letter Sent To Customer',
        visible: 1,
        sort: 0,
        checked: 1,
        disabled: 0,
      },
      {
        key: 'vendorLetterSentToSupplier',
        value: 0,
        text: 'Is Vendor Letter Sent To Supplier',
        visible: 1,
        sort: 0,
        checked: 1,
        disabled: 0,
      },
      {
        key: 'letterDate',
        value: 0,
        text: 'Letter Date',
        visible: 1,
        sort: 0,
        checked: 1,
        disabled: 0,
      },
      {
        key: 'icon',
        value: 0,
        text: translations['LexAttachment'],
        visible: 1,
        sort: 0,
        checked: 1,
        disabled: 0,
      },
      {
        key: 'copyDetail',
        value: 0,
        text: 'Re-order',
        visible: 1,
        sort: 0,
        checked: 1,
        disabled: 0,
      },
      {
        key: 'labelAgent',
        value: 0,
        text: translations['LexLabelAgent'],
        visible: 1,
        sort: 0,
        checked: 1,
        disabled: 0,
      },
    ],
    direction: null,
    startDate: new Date(),
    endDate: new Date(),
    isFilterVisible: false,
    isColumnOptVisible: false,
    activeIndex: 0,
    filterData: null,
    columnSortable: false,
    isUpdateData: false,
    openFileUpload: false,
    labelOrderId: 0,
    labelOrderType: 0,
    createdById: 0,
    requestNo: 0,
    currentPage: 1,
    showStatusModel: false,
    bubblStatusValue: '',
    labelOrderInfoId: 0,
    reportAlert: [],
    isRedirectToCopyLink: false,
    copyLink: '',
    isCustomerOrAdmin: false,
  };

  componentDidMount() {
    const userRoleId = Common.getUserRole();

    this.configureColumns();

    this.props.getUserFilter();
    this.props.getActivePage('Order History');
    this.setState({ isRedirectToCopyLink: false });
    this.setState({ copyLink: '' });

    if (
      userRoleId === Role.applicant ||
      userRoleId === Role.manufacturer ||
      userRoleId === Role.printer ||
      userRoleId === Role.labelCenter ||
      userRoleId === Role.labelManager
    ) {
      this.setState({ isCustomerOrAdmin: true });
    }

    this.props.getNotifications(this.props.currentPage, 10000);
  }

  componentWillUnmount() {
    const roleId = userRole;
    const isAdmin =
      roleId === Role.labelCenter ||
      roleId === Role.labelManager ||
      roleId === Role.pricingManager ||
      roleId === Role.fieldEngineerRO ||
      roleId === Role.printer;
    if (isAdmin) {
      const userId = JSON.parse(Common.getUserId());
      this.props.updateNotificationView(userId);
    }
  }

  configureColumns = () => {
    const userRole = Common.getUserRole();
    const roleColumns = viewOrdersRoleColumns.find(
      v => v.roleId === userRole
    ).columns;
    const { columnOptions } = this.state;
    let counter = 1;

    columnOptions.forEach(c => {
      c.value = counter;
      counter++;
    });

    const filteredColumnOptions = columnOptions.filter(c =>
      roleColumns.some(r => r === c.key)
    );

    filteredColumnOptions.find(f => f.key === 'orderStatus').text =
      userRole === Role.labelCenter
        ? 'Oracle ' + translations['LexOrderStatus']
        : translations['LexOrderStatus'];

    this.setState({ columnOptions: filteredColumnOptions });
  };

  getOrdersByUser = () => {
    this.props.getOrdersByUser(this.props.currentPage, this.props.pageSize);
  };

  getOrders = () => {
    this.props.getOrders(this.props.currentPage, this.props.pageSize);
  };

  onSearch() {
    const { startDate, endDate } = this.state;

    this.props.getOrdersByUser(
      this.props.currentPage,
      this.props.pageSize,
      startDate.toISOString(),
      endDate.toISOString()
    );

    this.setState({ open: false });
  }

  handleToggleFilter = () => {
    const { isFilterVisible } = this.state;

    const _isFilterVisible = !isFilterVisible;
    this.setState({
      isFilterVisible: _isFilterVisible,
      isColumnOptVisible: false,
    });
  };

  handleToggleColumn = () => {
    const { isColumnOptVisible } = this.state;

    const _isColumnOptVisible = !isColumnOptVisible;

    this.setState({
      isColumnOptVisible: _isColumnOptVisible,
      isFilterVisible: false,
    });
  };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  handleSort = clickedColumn => () => {
    const { column, direction, columnSortable } = this.state;
    const { orders } = this.props;

    if (columnSortable === true) {
      if (column !== clickedColumn) {
        this.setState({
          column: clickedColumn,
          direction: 'ascending',
        });

        const newData2 = _.sortBy(orders, [clickedColumn]);
        this.props.updateData(newData2);
        return;
      }

      const newData = orders.reverse();
      this.props.updateData(newData);
      this.setState({
        direction: direction === 'ascending' ? 'descending' : 'ascending',
      });
    }
  };

  handleRemoveColumn = columnKey => {
    const { columnOptions } = this.state;
    const selectedColumn = columnOptions.filter(i => i.key === columnKey);
    selectedColumn[0].checked = 0;

    const newState = { ...this.selectedColumn };
    this.setState(newState);
  };

  handleAddColumn = () => {
    const { columnOptions } = this.state;
    const selectedColumn = columnOptions.filter(i => i.selected === true);
    if (selectedColumn.length > 0) selectedColumn[0].checked = 1;

    const newState = { ...this.selectedColumn };

    this.setState(newState);
  };

  handleApplyColumnOptions = () => {
    const { columnOptions } = this.state;

    const _columnOptions = columnOptions.map(i => {
      i.visible = i.checked;
      return i;
    });

    const newState = { ..._columnOptions };

    this.setState(newState);

    const _columnOptionsFiltered = columnOptions.filter(i => i.visible === 1);

    const _activeColumns = _columnOptionsFiltered.map(i => i.value);

    const selectedColumns = _activeColumns.join('|');

    const data = {
      isColumnOptions: true,
      columnOptions: selectedColumns,
    };

    this.props.setUserFilter(data);
    this.setState({ isColumnOptVisible: false });
  };

  handleClearColumnOptions = () => {
    const { columnOptions } = this.state;

    const _columnOptions = columnOptions.forEach(i => {
      i.visible = 1;
      i.checked = 1;
    });

    const newState = { ..._columnOptions };

    this.setState(newState);

    const _columnOptionsFiltered = columnOptions.filter(i => i.visible === 1);

    const _activeColumns = _columnOptionsFiltered.map(i => i.value);

    const selectedColumns = _activeColumns.join('|');

    const data = {
      isColumnOptions: true,
      columnOptions: selectedColumns,
    };

    this.props.setUserFilter(data);

    this.setState({ isColumnOptVisible: false });

    this.getOrdersByUser();
  };

  handleDdColumnChange = (event, { value }) => {
    const { columnOptions } = this.state;

    // clear all selected column
    const resetColumn = columnOptions.forEach(column => {
      column.selected = false;
    });
    const newResetState = { ...resetColumn };
    this.setState(newResetState);

    // add selected column
    const selectedColumn = columnOptions.filter(i => i.value === value);
    selectedColumn[0].selected = true;
    const newAddedState = { ...this.selectedColumn };
    this.setState(newAddedState);
  };

  handleColumnSortChange = e => {
    let { columnSortable } = this.state;

    columnSortable = !columnSortable;

    this.setState({ columnSortable: columnSortable });
  };

  handlePageChange = filterData => {
    this.checkAndApplyFilterData(filterData);
  };

  handleExpandMultipleOrders = orderId => {
    const { orders } = this.props;
    const { isUpdateData } = this.props;

    const newData =
      orders &&
      orders.map(item => {
        if (item.id === orderId) {
          item.isExpandMultiple = !item.isExpandMultiple;
        }

        return item;
      });

    this.setState({ isUpdateData: !isUpdateData });
    this.props.updateData(newData);
  };

  handleMangeDocument = (
    labelOrderId,
    createdById,
    requestNo,
    labelOrderType
  ) => {
    const { getLabelOrderAttachment } = this.props;

    getLabelOrderAttachment(labelOrderId, labelOrderType);
    this.setState({ labelOrderId: labelOrderId });
    this.setState({ labelOrderType: labelOrderType });
    this.setState({ createdById: createdById });
    this.setState({ requestNo: requestNo });
    this.setState({ openFileUpload: true });
  };

  handleBubbleModal = (isPopUp, labelOrderInfoId) => {
    this.setState({ showStatusModel: isPopUp });
    this.setState({ labelOrderInfoId: labelOrderInfoId });
  };

  handleManualRepush = labelOrderId => {
    this.props.manualRepushOrder(labelOrderId).then(() => {
      if (!this.props.isManualRepushFailed) {
        // this.getOrders();
        setTimeout(() => {
          toast({
            title: '',
            description: (
              <p>
                {
                  'Manual re-push successful. This will take few minutes for push Oracle Order'
                }
              </p>
            ),
            time: 10000,
            size: 'small',
            icon: 'check',
            color: 'green',
          });
        }, 100);
      } else {
        setTimeout(() => {
          toast({
            title: '',
            description: <p>{'Manual re-push failed'}</p>,
            time: 10000,
            size: 'small',
            icon: 'check',
            color: 'red',
          });
        }, 100);
      }
    });
  };

  handleSelectedStatus = (e, { value }) => {
    this.setState({ bubblStatusValue: value });
  };

  handleUpdateStatus = () => {
    const { bubblStatusValue, labelOrderInfoId } = this.state;

    if (bubblStatusValue) {
      this.setState({ showStatusModel: false });

      const data = {
        labelOrderInfoId: labelOrderInfoId,
        orderStatus: bubblStatusValue,
      };
      this.props.updateBubbleStatus(data).then(s => {
        if (s.isSuccessful) {
          this.getOrders();
          setTimeout(() => {
            toast({
              title: '',
              description: <p>{'Order status update successful'}</p>,
              time: 10000,
              size: 'small',
              icon: 'check',
              color: 'green',
            });
          }, 100);
        } else {
          setTimeout(() => {
            toast({
              title: '',
              description: <p>{'Order status update failed'}</p>,
              time: 10000,
              size: 'small',
              icon: 'check',
              color: 'red',
            });
          }, 100);
        }
      });
    } else {
      setTimeout(() => {
        toast({
          title: '',
          description: <p>{'Please select order status to update'}</p>,
          time: 10000,
          size: 'small',
          icon: 'check',
          color: 'red',
        });
      }, 100);
    }
  };

  handleUploadFile = event => {
    const { uploadLabelOrderAttachment, getLabelOrderAttachment } = this.props;
    const { labelOrderType } = this.state;
    const fileTypeErrMsg =
      labelOrderType === 1
        ? Common.getTranslations()['LexAcceptableDocTypeV2']
        : 'Only PDF, JPG, JPEG. Word, Excel document types are accepted.';

    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const labelOrderId = event.target.name;
      const extension = file.name.split('.').pop()?.toLowerCase();
      const hasCorrectExtension =
        labelOrderType === 1
          ? extension === 'docx' ||
            extension === 'doc' ||
            extension === 'pdf' ||
            extension === 'jpg' ||
            extension === 'txt'
          : extension === 'pdf' ||
            extension === 'jpg' ||
            extension === 'jpeg' ||
            extension === 'doc' ||
            extension === 'docx' ||
            extension === 'xls' ||
            extension === 'xlsx' ||
            extension === 'txt';

      if (hasCorrectExtension) {
        const fileReader = new FileReader();
        const that = this;

        fileReader.onload = function (fileLoadedEvent) {
          const srcData = fileLoadedEvent.target.result;
          let data;

          that.props
            .scanFile({
              fileDetails: srcData,
            })
            .then(result => {
              if (result.isSuccessful) {
                if (file.size <= 10485760) {
                  data = {
                    LabelOrderId: labelOrderId || 0,
                    FileName: file.name,
                    ArtWorkLink: srcData,
                  };
                  uploadLabelOrderAttachment(data).then(s => {
                    if (s.isSuccessful) {
                      getLabelOrderAttachment(labelOrderId, labelOrderType);
                    }
                  });
                } else {
                  setTimeout(() => {
                    toast({
                      title: '',
                      description: (
                        <p>{Common.getTranslations()['LexMaxFileSize']}</p>
                      ),
                      time: 10000,
                      size: 'small',
                      icon: 'exclamation',
                      color: 'red',
                    });
                  }, 100);
                }
              } else {
                setTimeout(() => {
                  toast({
                    title: '',
                    description: (
                      <p>
                        Virus or malware has been detected, this file cannot be
                        uploaded.
                      </p>
                    ),
                    time: 10000,
                    size: 'small',
                    icon: 'exclamation',
                    color: 'red',
                  });
                }, 100);
              }
            });
        };
        fileReader.readAsDataURL(file);
      } else {
        setTimeout(() => {
          toast({
            title: '',
            description: <p>{fileTypeErrMsg}</p>,
            time: 10000,
            size: 'small',
            icon: 'exclamation',
            color: 'red',
          });
        }, 100);
      }
    }
  };

  handleCloseMangeDocument = (cartNo, createdById, isAdmin) => {
    const { filterData } = this.state;

    this.checkAndApplyFilterData(filterData);
    this.setState({ openFileUpload: false });
  };

  handleDeleteAttachment = (
    attachmentId,
    labelOrderId,
    labelOrderType,
    isCombinationArtwork
  ) => {
    const { deleteLabelOrderAttachment, getLabelOrderAttachment } = this.props;

    if (attachmentId) {
      deleteLabelOrderAttachment(
        attachmentId,
        labelOrderType,
        isCombinationArtwork
      ).then(s => {
        if (s.isSuccessful) {
          getLabelOrderAttachment(labelOrderId, labelOrderType);
        }
      });
    }
  };

  handleExportToExcel = data => {
    if (data) {
      const byteCharacters = atob(data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);

      const blob = new Blob([byteArray], {
        // type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style = 'display: none';
      a.href = url;
      a.download = `Order List.csv`;
      a.click();
      window.URL.revokeObjectURL(url);
    }
  };

  handleGetDataToExport = () => {
    const { selectedOrderNumbers } = this.props;
    if (userRole) {
      if (
        userRole === Role.labelCenter ||
        userRole === Role.fieldEngineerRO ||
        userRole === Role.labelManager
      ) {
        this.props
          .exportOrderList(selectedOrderNumbers)
          .then(result => this.handleExportToExcel(result));
      } else {
        this.props
          .exportOrderListByUser(selectedOrderNumbers)
          .then(result => this.handleExportToExcel(result));
      }
    }
  };

  handleChangeSelection = (data, isChecked) => {
    this.props.select(data, isChecked);
  };

  handleExportOrders = () => {
    const { columnOptions, filterData } = this.state;
    let selectedColumns;
    if (columnOptions) {
      selectedColumns = columnOptions
        .filter(i => i.visible === 1)
        .map(i => i.value)
        .join('|');
    }

    const data = {
      filterValues: filterData,
      columnPreferences: selectedColumns,
    };
    // create report request and show pop up message
    this.props.createReportRequest(data);

    // set timer to get updates
    // setTimeout(() => this.props.getReportLink(), 180000);
  };

  handleCloseReportRequestDialog = () => {
    this.props.closeReportRequestDialog();
  };

  handleCloseReportLinkDialog = () => {
    this.props.closeReportLinkDialog();
  };

  checkAndApplyFilterData = filterData => {
    let roleId = userRole;

    if (
      filterData &&
      (((filterData || {}).statuses || []).length ||
        ((filterData || {}).filterDate || []).length ||
        ((filterData || {}).fileNumbers || []).length ||
        ((filterData || {}).applicants || []).length ||
        ((filterData || {}).manufacturers || []).length ||
        ((filterData || {}).oracleOrderNumbers || []).length ||
        ((filterData || {}).requestNumbers || []).length ||
        ((filterData || {}).labelCenters || []).length ||
        ((filterData || {}).applicantAccountNos || []).length ||
        ((filterData || {}).manufacturerAccountNos || []).length ||
        ((filterData || {}).orderStatus || []).length ||
        ((filterData || {}).customerContactName || []).length ||
        ((filterData || {}).customerPoNumber || []).length ||
        ((filterData || {}).orderFilterDate || []).length ||
        ((filterData || {}).applicantAddress || []).length ||
        ((filterData || {}).manufacturerAddress || []).length ||
        ((filterData || {}).manufacturerPsn || []).length ||
        ((filterData || {}).labelAgents || []).length ||
        filterData.labelOrderType ||
        filterData.isExpandMultipleOrders ||
        filterData.isRepushOrders ||
        filterData.isCopied ||
        !_.isEmpty(filterData.emailSent) ||
        !_.isEmpty(filterData.vendorLetterSentToCustomer) ||
        !_.isEmpty(filterData.vendorLetterSentToSupplier))
    ) {
      this.props.getOrdersByUserFilter(filterData);
      this.props.filterOptionReset();
    } else {
      if (
        roleId === Role.applicant ||
        roleId === Role.manufacturer ||
        roleId === Role.printer
      ) {
        this.props.getOrdersByUser(filterData.currentPage, filterData.pageSize);
      } else {
        this.props.getOrders(filterData.currentPage, filterData.pageSize);
      }
    }
    this.setState({ currentPage: filterData.currentPage });
  };

  handleCopyOrder = data => {
    if (data != null) {
      let link = '';
      const userRoleId = userRole;
      if (data.labelOrderType === 1) {
        link = `/home/copy-order/${data.labelOrderCartId}/true/${data.labelOrderType}/${data.fileNumber}/${data.manufacturerAccountNumber}`;
      } else {
        link = `/home/combination-copy-order/${data.labelOrderCartId}/true/${data.labelOrderType}/${data.fileNumber}`;
      }
      this.setState({ copyLink: link });
      this.props.checkCartAndOrderDetails(userRoleId, data.id).then(result => {
        if (result.isSuccessful) {
          this.handleCopyOrderCheckResults(result.data, userRoleId, data);
        }
      });
    }
  };

  handleCopyOrderCheckResults = (result, userRoleId, data) => {
    const currentUserPsn = Common.getPartySiteNumber();
    if (result === copyOrderCheck.hasExistingCart) {
      this.props.openExistingItemInCartDialog();
    } else if (data.psn === '' || data.userRoleId === 0) {
      this.props.openCannotCopyDialog();
    } else if (result === copyOrderCheck.hasBadData) {
      this.props.openCannotCopyDialog();
    } else if (result === copyOrderCheck.hasHasMissingFileVolCcn) {
      toast({
        title: '',
        description: (
          <p>{Common.getTranslations()['LexCopyFileVolCCNInactive']}</p>
        ),
        time: 10000,
        size: 'small',
        icon: 'check',
        color: 'red',
      });
    } else if (currentUserPsn !== data.psn || userRoleId !== data.userRoleId) {
      this.props.openAutoSwitchPsnRoleDialog({
        psn: data.psn,
        userRoleId: data.userRoleId,
      });
    } else {
      this.setState({ isRedirectToCopyLink: true });
    }
  };

  handleCloseCannotCopyDialog = () => {
    this.props.closeCannotCopyDialog();
  };

  handleCloseCopyNotCompliedDialog = () => {
    this.props.closeCopyNotCompliedDialog();
  };

  handleCloseExistingItemInCartDialog = () => {
    this.props.closeExistingItemInCartDialog();
  };

  handleAutoSwitchPsnRole = () => {
    const { autoSwitchRoleData } = this.props;

    if (userRole !== autoSwitchRoleData.userRoleId) {
      this.props.updateUserRole({ userRole: autoSwitchRoleData.userRoleId });
    }

    if (Common.getPartySiteNumber() !== autoSwitchRoleData.psn) {
      this.props.updateUserPsn({
        partySiteNumber: autoSwitchRoleData.psn,
        psnCountry: '',
        isUpdateAutomatic: true,
      });
    }
    this.props.closeAutoSwitchPsnRoleDialog();
    this.setState({ isRedirectToCopyLink: true });
  };

  checkAndSplit = filterValue => {
    if (typeof filterValue === 'string') {
      return filterValue !== null && filterValue !== ''
        ? filterValue.split('|')
        : [];
    } else if (typeof filterValue === 'boolean') {
      return filterValue || false;
    } else if (typeof filterValue === 'number') {
      return filterValue !== null ? filterValue.toString().split('|') : [];
    } else {
      return [];
    }
  };

  render() {
    const {
      orders,
      attachments,
      uploading,
      isShowReportRequestModal,
      isShowExportLink,
      showCannotCopyModal,
      showCopyNotCompliedModal,
      showExistingItemInCartModal,
      showAutoSwitchPsnRoleDialog,
      isClearFilterDisabled,
    } = this.props;
    const {
      column,
      columnOptions,
      direction,
      isFilterVisible,
      isColumnOptVisible,
      activeIndex,
      filterData,
      columnSortable,
      openFileUpload,
      labelOrderId,
      labelOrderType,
      createdById,
      requestNo,
      showStatusModel,
      bubblStatusValue,
      isRedirectToCopyLink,
      copyLink,
    } = this.state;
    let fullName = Common.getFullName();
    let disableClearFilter = true;

    const {
      filter,
      isGetUserFilterSuccess,
      isSaveUserFilterSuccess,
      getUserFilterLoading,
    } = this.props.viewOrders;

    const translations = Common.getTranslations() || {};

    if (isSaveUserFilterSuccess) {
      this.props.getUserFilter();
      this.props.filterOptionReset();
    }

    if (!_.isEmpty(filter) && isGetUserFilterSuccess) {
      const filterDetails = {};

      Object.keys(filter).forEach(property => {
        filterDetails[property] = this.checkAndSplit(filter[property]);
      });

      const _columnOptions = filterDetails['columnOptions'];

      // Remap column option
      if (_columnOptions && _columnOptions.length > 0) {
        columnOptions &&
          columnOptions.forEach(column => {
            column.checked = 0;
            if (
              _columnOptions &&
              _columnOptions.includes(column.value.toString())
            )
              column.checked = 1;
            column.visible = column.checked ? 1 : 0;
          });
      }

      const userId =
        userRole === Role.labelCenter ||
        userRole === Role.fieldEngineerRO ||
        userRole === Role.labelManager
          ? null
          : Common.getUserId();

      const _filterData = {
        ...filterDetails,
        userId: userId,
        currentPage: 1,
        pageSize: this.props.pageSize,
      };

      const keysToOmit = [
        'columnOptions',
        'currentPage',
        'id',
        'pageSize',
        'userId',
      ];

      Object.keys(_filterData).forEach(property => {
        if (keysToOmit.includes(property)) {
          return;
        }
        let hasNoFilter = true;
        if (_filterData[property]) {
          if (typeof _filterData[property] === 'string')
            hasNoFilter = _filterData[property] === 'All';
          else if (typeof _filterData[property] === 'boolean')
            hasNoFilter = !_filterData[property];
          else if (typeof _filterData[property] === 'object')
            hasNoFilter =
              !_filterData[property].length ||
              (_filterData[property].length === 1 &&
                (_filterData[property][0] === 'All' ||
                  _filterData[property][0] === '0'));
        }

        if (!hasNoFilter) disableClearFilter = hasNoFilter;
      });

      this.props.disableClearFilter(disableClearFilter);

      this.props.getOrdersByUserFilter(_filterData);

      this.setState({ filterData: _filterData });

      this.props.filterOptionReset();

      const _reportAlert =
        Common.getTranslations()['LexExportReadyAlert'].split('{0}');

      this.setState({ reportAlert: _reportAlert });
    }

    if (isRedirectToCopyLink) {
      return <Redirect to={copyLink} />;
    }

    return (
      <Fragment>
        <div className="div-container">
          <Grid>
            <Grid.Row className="label-header">
              <Grid.Column>
                <CustomContainerHeader message={'Hello ' + fullName + '!'} />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="label-desc">
              <Grid.Column>
                <CustomHeaderLabelRelaxed
                  message={translations['LexWelcome']}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <LabelFilterOption
            activeIndex={activeIndex}
            isFilterVisible={isFilterVisible}
            filter={filter}
            isGetUserFilterSuccess={isGetUserFilterSuccess}
            handleClick={this.handleClick}
            handleToggleFilter={this.handleToggleFilter}
          />
          <LabelColumnOption
            activeIndex={activeIndex}
            columnOptions={columnOptions}
            isColumnOptVisible={isColumnOptVisible}
            columnSortable={columnSortable}
            handleClick={this.handleClick}
            handleToggleColumn={this.handleToggleColumn}
            handleRemoveColumn={this.handleRemoveColumn}
            handleAddColumn={this.handleAddColumn}
            handleColumnSortChange={this.handleColumnSortChange}
            handleDdColumnChange={this.handleDdColumnChange}
            handleApplyColumnOptions={this.handleApplyColumnOptions}
            handleClearColumnOptions={this.handleClearColumnOptions}
          />
          <Container fluid style={customStyles.ContainerStyle}>
            <Dimmer active={this.props.loading || getUserFilterLoading}>
              <Loader>Loading</Loader>
            </Dimmer>
            <div className="top-table-selection">
              <Grid>
                <Grid.Row columns={2}>
                  <Grid.Column
                    width={5}
                    verticalAlign="bottom"
                    textAlign="left"
                  >
                    <Button
                      as="a"
                      compact
                      style={customStyles.GridButtonStyle}
                      onClick={this.handleToggleColumn}
                    >
                      {translations['LexColumnPreference']}
                    </Button>
                  </Grid.Column>
                  <Grid.Column width={11} textAlign="right">
                    <Button
                      style={customStyles.GridButtonStyle}
                      icon="filter"
                      compact
                      onClick={this.handleToggleFilter}
                    ></Button>
                    <Button
                      as="a"
                      style={_.merge(
                        customStyles.GridButtonStyle,
                        customStyles.ExportButtonStyle
                      )}
                      compact
                      onClick={this.props.clearFilter}
                      disabled={isClearFilterDisabled}
                    >
                      <Icon name="remove" />
                      {'Clear Filter'}
                    </Button>
                    <Button
                      as="a"
                      style={_.merge(
                        customStyles.GridButtonStyle,
                        customStyles.ExportButtonStyle
                      )}
                      compact
                      disabled={!this.props.isExportEnabled}
                      onClick={this.handleExportOrders}
                    >
                      <Icon name="list" />
                      {translations['LexExportOrders']}
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <Modal open={isShowReportRequestModal} size="tiny">
                <Modal.Header className="ui center aligned">
                  Report Request Created
                </Modal.Header>
                <Modal.Content scrolling>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column>
                        <Form>
                          <label>
                            {translations['LexOrderExportAlertCustomerLC']}
                          </label>
                        </Form>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Modal.Content>
                <Modal.Actions>
                  <Button primary onClick={this.handleCloseReportRequestDialog}>
                    Close
                  </Button>
                </Modal.Actions>
              </Modal>
              <Modal open={isShowExportLink} size="tiny">
                <Modal.Header className="ui center aligned">
                  Report Link Available
                </Modal.Header>
                <Modal.Content scrolling>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column>
                        <Form>
                          <p>
                            {this.state.reportAlert[0]}
                            <label>
                              <a
                                style={customStyles.ReportLinkStyle}
                                href={this.props.reportLinkGenerated}
                              >
                                link
                              </a>
                            </label>
                            {this.state.reportAlert[1]}
                          </p>
                        </Form>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Modal.Content>
                <Modal.Actions>
                  <Button primary onClick={this.handleCloseReportLinkDialog}>
                    Close
                  </Button>
                </Modal.Actions>
              </Modal>
              <Modal open={showCannotCopyModal} size="tiny">
                <Modal.Header className="ui center aligned"></Modal.Header>
                <Modal.Content scrolling>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column>
                        <Form>
                          <label>{translations['LexCannotCopyAlert']}</label>
                        </Form>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Modal.Content>
                <Modal.Actions>
                  <Button primary onClick={this.handleCloseCannotCopyDialog}>
                    Close
                  </Button>
                </Modal.Actions>
              </Modal>
              <Modal open={showCopyNotCompliedModal} size="tiny">
                <Modal.Header className="ui center aligned"></Modal.Header>
                <Modal.Content scrolling>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column>
                        <Form>
                          <label>
                            {translations['LexCopynotCompliedAlert']}
                          </label>
                        </Form>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Modal.Content>
                <Modal.Actions>
                  <Button
                    primary
                    onClick={this.handleCloseCopyNotCompliedDialog}
                  >
                    Close
                  </Button>
                </Modal.Actions>
              </Modal>

              <Modal open={showAutoSwitchPsnRoleDialog} size="tiny">
                <Modal.Header className="ui center aligned"></Modal.Header>
                <Modal.Content scrolling>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column>
                        <Form>
                          <label>
                            {/* {translations["LexCopynotCompliedAlert"]} */}
                            {`The selected order is not associated with your current login PSN/Role, system will switch to proper PSN/Role.`}
                          </label>
                        </Form>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Modal.Content>
                <Modal.Actions>
                  <Button primary onClick={this.handleAutoSwitchPsnRole}>
                    Close
                  </Button>
                </Modal.Actions>
              </Modal>
              <Modal open={showExistingItemInCartModal} size="tiny">
                <Modal.Header className="ui center aligned"></Modal.Header>
                <Modal.Content scrolling>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column>
                        <Form>
                          <label>
                            "You have label item(s) in the cart, please submit
                            or delete the existing cart first."
                          </label>
                        </Form>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Modal.Content>
                <Modal.Actions>
                  <Button
                    primary
                    onClick={this.handleCloseExistingItemInCartDialog}
                  >
                    Close
                  </Button>
                </Modal.Actions>
              </Modal>
            </div>
            <Segment fluid style={customStyles.GridOverflowX}>
              <Table striped structured celled sortable={columnSortable}>
                <Table.Header>
                  <Table.Row textAlign="center">
                    <Table.HeaderCell></Table.HeaderCell>
                    {columnOptions &&
                      columnOptions.map(
                        (_column, idx) =>
                          _column.visible === 1 &&
                          _column.disabled === 0 && (
                            <Table.HeaderCell
                              key={idx}
                              sorted={column === _column.key ? direction : null}
                              onClick={this.handleSort(_column.key)}
                            >
                              {_column.text}
                            </Table.HeaderCell>
                          )
                      )}
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {orders &&
                    orders.map((data, idx) => (
                      <OrderItem
                        key={idx}
                        data={data}
                        columnOptions={columnOptions}
                        handleExpandMultipleOrders={
                          this.handleExpandMultipleOrders
                        }
                        handleMangeDocument={this.handleMangeDocument}
                        handleUploadFile={this.handleUploadFile}
                        handleCloseMangeDocument={this.handleCloseMangeDocument}
                        handleDeleteAttachment={this.handleDeleteAttachment}
                        openFileUpload={openFileUpload}
                        attachments={attachments}
                        labelOrderId={labelOrderId}
                        labelOrderType={labelOrderType}
                        createdById={createdById}
                        requestNo={requestNo}
                        uploading={uploading}
                        showStatusModel={showStatusModel}
                        handleBubbleModal={this.handleBubbleModal}
                        bubblStatusValue={bubblStatusValue}
                        handleSelectedStatus={this.handleSelectedStatus}
                        handleUpdateStatus={this.handleUpdateStatus}
                        handleChangeSelection={this.handleChangeSelection}
                        handleCopyOrder={this.handleCopyOrder}
                        handleManualRepushOrder={this.handleManualRepush}
                      />
                    ))}
                </Table.Body>
                <Table.Footer>
                  <Table.Row>
                    <Table.HeaderCell colSpan="30">
                      {orders && this.props.totalPages > 0 ? (
                        <div
                          className={
                            this.state.isCustomerOrAdmin
                              ? 'footer-container-left'
                              : 'footer-container-right'
                          }
                        >
                          <Pagination
                            id="footer-pagination"
                            activePage={this.state.currentPage}
                            totalPages={this.props.totalPages}
                            boundaryRange={2}
                            onPageChange={(e, { activePage }) => {
                              const newfilterData = {
                                ...filterData,
                                currentPage: activePage,
                                pageSize: this.props.pageSize,
                              };
                              this.handlePageChange(newfilterData);
                            }}
                          />
                        </div>
                      ) : (
                        <Message>No Order Found</Message>
                      )}
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Footer>
              </Table>
            </Segment>
            <FooterHomeBlock />
          </Container>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  viewOrders: state.viewOrders,
  orders: state.viewOrders.orders,
  currentPage: state.viewOrders.currentPage,
  pageSize: state.viewOrders.pageSize,
  totalPages: state.viewOrders.totalPages,
  isSelectedAll: state.viewOrders.isSelectedAll,
  isFiltersExpanded: state.viewOrders.isFiltersExpanded,
  isExportEnabled: state.viewOrders.isExportEnabled,
  loading: state.viewOrders.loading,
  attachments: state.viewOrders.attachments,
  deleting: state.viewOrders.deleting,
  uploading: state.viewOrders.uploading,
  selectedOrderNumbers: state.viewOrders.selectedOrderNumbers,
  isShowReportRequestModal: state.viewOrders.isShowReportRequestModal,
  isShowExportLink: state.viewOrders.isShowExportLink,
  reportLinkGenerated: state.viewOrders.reportLinkGenerated,
  showCannotCopyModal: state.viewOrders.showCannotCopyModal,
  showCopyNotCompliedModal: state.viewOrders.showCopyNotCompliedModal,
  showExistingItemInCartModal: state.viewOrders.showExistingItemInCartModal,
  isManualRepushFailed: state.viewOrders.isManualRepushFailed,
  showAutoSwitchPsnRoleDialog: state.viewOrders.showAutoSwitchPsnRoleDialog,
  autoSwitchRoleData: state.viewOrders.autoSwitchRoleData,
  isClearFilterDisabled: state.viewOrders.isClearFilterDisabled,
});

const mapDispatchToProps = dispatch => ({
  changePageByUser: (activePage, pageSize) =>
    dispatch(changePageByUser(activePage, pageSize)),
  getOrdersByUser: (currentPage, pageSize, startDate, endDate) =>
    dispatch(getOrdersByUser(currentPage, pageSize, startDate, endDate)),
  getOrders: (currentPage, pageSize) =>
    dispatch(getOrders(currentPage, pageSize)),

  selectAll: isSelectedAll => dispatch(selectAll(isSelectedAll)),
  getUserFilter: () => dispatch(getUserFilter()),
  getOrdersByUserFilter: data => dispatch(getOrdersByUserFilter(data)),
  filterOptionReset: () => dispatch(filterOptionReset()),
  setUserFilter: data => dispatch(setUserFilter(data)),
  updateData: result => dispatch(updateData(result)),
  uploadLabelOrderAttachment: data =>
    dispatch(uploadLabelOrderAttachment(data)),
  deleteLabelOrderAttachment: (
    attachmentId,
    labelOrderType,
    isCombinationArtwork
  ) =>
    dispatch(
      deleteLabelOrderAttachment(
        attachmentId,
        labelOrderType,
        isCombinationArtwork
      )
    ),
  getLabelOrderAttachment: (labelOrderId, labelOrderType) =>
    dispatch(getLabelOrderAttachment(labelOrderId, labelOrderType)),
  sendAttachmentMessage: data => dispatch(sendAttachmentMessage(data)),
  updateBubbleStatus: data => dispatch(updateBubbleStatus(data)),
  exportOrderList: selectedOrderNumbers =>
    dispatch(exportOrderList(selectedOrderNumbers)),
  exportOrderListByUser: selectedOrderNumbers =>
    dispatch(exportOrderListByUser(selectedOrderNumbers)),
  clearExportSelection: () => dispatch(clearExportSelection()),
  select: (data, isChecked) => dispatch(select(data, isChecked)),
  getActivePage: page => dispatch(commonActions.getActivePage(page)),
  createReportRequest: data => dispatch(createReportRequest(data)),
  getReportLink: () => dispatch(getReportLink()),
  closeReportRequestDialog: () => dispatch(closeReportRequestDialog()),
  closeReportLinkDialog: () => dispatch(closeReportLinkDialog()),
  closeCannotCopyDialog: () => dispatch(closeCannotCopyDialog()),
  closeCopyNotCompliedDialog: () => dispatch(closeCopyNotCompliedDialog()),
  openCannotCopyDialog: () => dispatch(openCannotCopyDialog()),
  openCopyNotCompliedDialog: () => dispatch(openCopyNotCompliedDialog()),
  checkCartAndOrderDetails: (userRole, labelOrderId) =>
    dispatch(checkCartAndOrderDetails(userRole, labelOrderId)),
  openExistingItemInCartDialog: () => dispatch(openExistingItemInCartDialog()),
  closeExistingItemInCartDialog: () =>
    dispatch(closeExistingItemInCartDialog()),
  manualRepushOrder: data => dispatch(manualRepushOrder(data)),
  scanFile: data => dispatch(scanFile(data)),
  openAutoSwitchPsnRoleDialog: data =>
    dispatch(openAutoSwitchPsnRoleDialog(data)),
  closeAutoSwitchPsnRoleDialog: () => dispatch(closeAutoSwitchPsnRoleDialog()),
  updateUserPsn: model => dispatch(userActions.updateUserPsn(model)),
  updateUserRole: userRoleModel =>
    dispatch(userActions.updateUserRole(userRoleModel)),
  clearFilter: () => dispatch(clearFilter()),
  disableClearFilter: toggle => dispatch(disableClearFilter(toggle)),
  getNotifications: (currentPage, pageSize) =>
    dispatch(getNotifications(currentPage, pageSize)),
  updateNotificationView: userId => dispatch(updateNotificationView(userId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewOrders);
