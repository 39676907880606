import React, { Component } from "react";

class NewQuote extends Component {
  state = {};
  render() {
    return <div>NewQuote</div>;
  }
}

export default NewQuote;
