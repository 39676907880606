export const LABEL_ORDER_DETAILS_GET_CUSTOMER_INFO_REQUEST =
  "LABEL_ORDER_DETAILS_GET_CUSTOMER_INFO_REQUEST";
export const LABEL_ORDER_DETAILS_GET_CUSTOMER_INFO_SUCCESS =
  "LABEL_ORDER_DETAILS_GET_CUSTOMER_INFO_SUCCESS";
export const LABEL_ORDER_DETAILS_GET_CUSTOMER_INFO_FAILURE =
  "LABEL_ORDER_DETAILS_GET_CUSTOMER_INFO_FAILURE";

export const LABEL_ORDER_DETAILS_GET_LABEL_ORDER_REQUEST =
  "LABEL_ORDER_DETAILS_GET_LABEL_ORDER_REQUEST";
export const LABEL_ORDER_DETAILS_GET_LABEL_ORDER_SUCCESS =
  "LABEL_ORDER_DETAILS_GET_LABEL_ORDER_SUCCESS";
export const LABEL_ORDER_DETAILS_GET_LABEL_ORDER_FAILURE =
  "LABEL_ORDER_DETAILS_GET_LABEL_ORDER_FAILURE";

export const LABEL_ORDER_DETAILS_INITIALIZE = "LABEL_ORDER_DETAILS_INITIALIZE";

export const EMAIL_COPY_REQUEST = "EMAIL_COPY_REQUEST";
export const EMAIL_COPY_SUCCESS = "EMAIL_COPY_SUCCESS";
export const EMAIL_COPY_FAILURE = "EMAIL_COPY_FAILURE";

export const RENDER_PDF_REQUEST = "RENDER_PDF_REQUEST";
export const RENDER_PDF_SUCCESS = "RENDER_PDF_SUCCESS";
export const RENDER_PDF_FAILURE = "RENDER_PDF_FAILURE";

export const UPDATE_ORACLE_NUMBER_REQUEST = "UPDATE_ORACLE_NUMBER_REQUEST";
export const UPDATE_ORACLE_NUMBER_SUCCESS = "UPDATE_ORACLE_NUMBER_SUCCESS";
export const UPDATE_ORACLE_NUMBER_FAILURE = "UPDATE_ORACLE_NUMBER_FAILURE";
export const UPDATE_ORACLE_NUMBER_RESET = "UPDATE_ORACLE_NUMBER_RESET";
