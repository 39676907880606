import React, { Component, Fragment } from "react";
import {
  Segment,
  Form,
  Input,
  Grid,
  Button,
  Checkbox,
  Divider
} from "semantic-ui-react";
import {
  CustomHeaderLabel,
  CustomHeaderBold
} from "../../../components/labels/labels";
import * as actions from "./quote-sites.action";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import ManualPricingModal from "./common/manual-pricing/modal-message";

class QuoteSites extends Component {
  state = {
    numberOfSites: 1,
    isRedirect: false,
    isManualPricingModal: false
  };
  componentDidMount() {
    this.props.clearQuoteSites();
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    this.setState({ isRedirect: false });
    this.props.updateSitesCount(e.target.value);
    localStorage.setItem("sitesCount", e.target.value);
  };

  handleRedirect = () => {
    this.setState({ isRedirect: true });
  };

  onChangeEvaluatedSameVisitRadioGroup(e, { value }) {
    localStorage.setItem("isSameDayVisit", value);
    this.props.updateSameDayVisit(value);
    this.setState({ isManualPricingModal: !value });
  }

  handleIsManualPricingModalClose = () => {
    this.setState({ isManualPricingModal: false });
  };

  render() {
    const { numberOfSites, isRedirect, isManualPricingModal } = this.state;

    const { isSameDayVisit } = this.props.quoteSites;
    const isDisabled = numberOfSites > 1 && isSameDayVisit === null;

    if (isRedirect) {
      if (numberOfSites == 1) return <Redirect to="/home/new-quote" />;
      else return <Redirect to="/home/new-quote-details/" />;
    }

    return (
      <Fragment>
        <div style={{ width: "80%", padding: "20px" }}>
          <CustomHeaderBold message="Quote Sites" />

          <Segment>
            <Grid>
              <Grid.Row columns={5}>
                <Grid.Column>
                  <Form>
                    <Form.Field
                      id="form-input-control-lcn"
                      control={Input}
                      label="Number of sites"
                      placeholder="Choose No. of sites"
                      name="numberOfSites"
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                      value={numberOfSites}
                      autoComplete="off"
                      type="number"
                      min="1"
                      max="4"
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                  </Form>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            {numberOfSites > 1 && (
              <Grid>
                <Grid.Row>
                  <Grid.Column>
                    <Form.Field>
                      * Can all of the sites be evaluated during the same
                      day/visit?
                    </Form.Field>
                    <Form.Field>
                      <Checkbox
                        radio
                        label="Yes"
                        name="isEvaluatedSameVisit"
                        value={true}
                        checked={isSameDayVisit === true}
                        onChange={this.onChangeEvaluatedSameVisitRadioGroup.bind(
                          this
                        )}
                      />
                      &nbsp;&nbsp;&nbsp;
                      <Checkbox
                        radio
                        label="No"
                        name="isEvaluatedSameVisit"
                        value={false}
                        checked={isSameDayVisit === false}
                        onChange={this.onChangeEvaluatedSameVisitRadioGroup.bind(
                          this
                        )}
                      />
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            )}
            <Divider hidden />

            <Button primary disabled={isDisabled} onClick={this.handleRedirect}>
              Next Step
            </Button>
          </Segment>

          {/* MANUAL PRICING MESSAGE */}
          <ManualPricingModal
            isManualPricingModal={isManualPricingModal}
            handleIsManualPricingModalClose={
              this.handleIsManualPricingModalClose
            }
          />
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  quoteSites: state.quoteSites
});

const mapDispatchToProps = (dispatch) => ({
  updateSitesCount: (count) => dispatch(actions.updateSitesCount(count)),
  updateSameDayVisit: (toggle) => dispatch(actions.updateSameDayVisit(toggle)),
  clearQuoteSites: () => dispatch(actions.clearQuoteSites())
});

export default connect(mapStateToProps, mapDispatchToProps)(QuoteSites);
