import * as types from "./field-evaluation.type";
import api from "../../services/api";
import { ADD_ORDER } from "./product-details/fes/product-details.type";

export const feOrderResult = (obj, type) => {
  return {
    type: type,
    data: obj,
  };
};

export const doCreateFEOrder = (model) => {
  return async (dispatch) => {
    dispatch(feOrderResult(null, types.CREATE_FE_ORDER_REQUEST));
    return await api.FieldEvaluation.createFEOrder(model)
      .then((result) => {
        dispatch(feOrderResult(result, types.CREATE_FE_ORDER_SUCCESS));
        return result;
      })
      .catch((result) => {
        dispatch(
          feOrderResult(
            result.response && result.response.data,
            types.CREATE_FE_ORDER_FAILURE
          )
        );
      });
  };
};

export const doUpdateFEOrder = (model) => {
  return async (dispatch) => {
    dispatch(feOrderResult(null, types.QUOTE_SAVE_CHANGES_REQUEST));
    return await api.FieldEvaluation.updateFEOrder(model)
      .then((result) => {
        dispatch(feOrderResult(result, types.QUOTE_SAVE_CHANGES_SUCCESS));
        return result;
      })
      .catch((result) => {
        dispatch(
          feOrderResult(
            result.response && result.response.data,
            types.QUOTE_SAVE_CHANGES_FAILURE
          )
        );
      });
  };
};

export const getOracleOrderDetails = (orderNumber) => {
  return async (dispatch) => {
    dispatch(feOrderResult(null, types.ORACLE_ORDER_DETAILS_REQUEST));
    await api.FieldEvaluation.getOracleOrderDetails(orderNumber)
      .then((result) => {
        dispatch(feOrderResult(result, types.ORACLE_ORDER_DETAILS_SUCCESS));
      })
      .catch((result) => {
        dispatch(
          feOrderResult(
            result.response.data,
            types.ORACLE_ORDER_DETAILS_FAILURE
          )
        );
      });
  };
};

export const getOrderDetails = (orderNumber) => {
  return async (dispatch) => {
    dispatch(feOrderResult(null, types.FE_ORDER_DETAILS_REQUEST));
    return await api.FieldEvaluation.getOrderDetails(orderNumber)
      .then((result) => {
        dispatch(feOrderResult(result, types.FE_ORDER_DETAILS_SUCCESS));
        return result;
      })
      .catch((result) => {
        dispatch(
          feOrderResult(
            result.response && result.response.data,
            types.FE_ORDER_DETAILS_FAILURE
          )
        );
      });
  };
};

export const addFesOrder = (result) => {
  return (dispatch) => dispatch(feOrderResult(result, ADD_ORDER));
};

export const resetOracleOrderDetails = () => (dispatch) =>
  dispatch(feOrderResult({}, types.ORACLE_ORDER_DETAILS_RESET));

export const resetOrderDetails = () => (dispatch) =>
  dispatch(feOrderResult({}, types.FE_ORDER_DETAILS_RESET));

export const updateForm = (result) => {
  return (dispatch) => dispatch(feOrderResult(result, types.FORM));
};

export const clearFEOrder = () => (dispatch) =>
  dispatch(feOrderResult({}, types.CLEAR));

export const getProjectCountries = (locationType) => {
  return async (dispatch) => {
    let type = null;
    switch (locationType) {
      case 1:
        type = types.GET_COUNTRIES_PRELIM_PROJECT_DETAILS_REQUEST;
        break;
      case 2:
        type = types.GET_COUNTRIES_FINAL_PROJECT_DETAILS_REQUEST;
        break;
      case 3:
        type = types.GET_STATES_AHJ_REQUEST;
        break;
    }
    dispatch(feOrderResult(null, type));

    await api.FieldEvaluation.getCountries()
      .then((result) => {
        let type = null;
        switch (locationType) {
          case 1:
            type = types.GET_COUNTRIES_PRELIM_PROJECT_DETAILS_SUCCESS;
            break;
          case 2:
            type = types.GET_COUNTRIES_FINAL_PROJECT_DETAILS_SUCCESS;
            break;
          case 3:
            type = types.GET_STATES_AHJ_SUCCESS;
            break;
        }
        dispatch(feOrderResult(result, type));
      })
      .catch((result) => {
        let type = null;
        switch (locationType) {
          case 1:
            type = types.GET_COUNTRIES_PRELIM_PROJECT_DETAILS_FAILURE;
            break;
          case 2:
            type = types.GET_COUNTRIES_FINAL_PROJECT_DETAILS_FAILURE;
            break;
          case 3:
            type = types.GET_STATES_AHJ_FAILURE;
            break;
        }
        dispatch(feOrderResult(result, type));
      });
  };
};

export const getProjectStates = (countryCode, locationType) => {
  return async (dispatch) => {
    let type = null;
    switch (locationType) {
      case 1:
        type = types.GET_STATES_PRELIM_PROJECT_DETAILS_REQUEST;
        break;
      case 2:
        type = types.GET_STATES_FINAL_PROJECT_DETAILS_REQUEST;
        break;
      case 3:
        type = types.GET_STATES_AHJ_REQUEST;
        break;
    }
    dispatch(feOrderResult(null, type));

    await api.FieldEvaluation.getStates(countryCode)
      .then((result) => {
        let type = null;
        switch (locationType) {
          case 1:
            type = types.GET_STATES_PRELIM_PROJECT_DETAILS_SUCCESS;
            break;
          case 2:
            type = types.GET_STATES_FINAL_PROJECT_DETAILS_SUCCESS;
            break;
          case 3:
            type = types.GET_STATES_AHJ_SUCCESS;
            break;
        }
        dispatch(feOrderResult(result, type));
      })
      .catch((result) => {
        let type = null;
        switch (locationType) {
          case 1:
            type = types.GET_STATES_PRELIM_PROJECT_DETAILS_FAILURE;
            break;
          case 2:
            type = types.GET_STATES_FINAL_PROJECT_DETAILS_FAILURE;
            break;
          case 3:
            type = types.GET_STATES_AHJ_FAILURE;
            break;
        }
        dispatch(feOrderResult(result, type));
      });
  };
};

export const getProjectCities = (stateCode, locationType) => {
  return async (dispatch) => {
    let type = null;
    switch (locationType) {
      case 1:
        type = types.GET_CITIES_PRELIM_PROJECT_DETAILS_REQUEST;
        break;
      case 2:
        type = types.GET_CITIES_FINAL_PROJECT_DETAILS_REQUEST;
        break;
      case 3:
        type = types.GET_CITIES_AHJ_REQUEST;
        break;
    }
    dispatch(feOrderResult(null, type));

    await api.FieldEvaluation.getCities(stateCode)
      .then((result) => {
        let type = null;
        switch (locationType) {
          case 1:
            type = types.GET_CITIES_PRELIM_PROJECT_DETAILS_SUCCESS;
            break;
          case 2:
            type = types.GET_CITIES_FINAL_PROJECT_DETAILS_SUCCESS;
            break;
          case 3:
            type = types.GET_CITIES_AHJ_SUCCESS;
            break;
        }
        dispatch(feOrderResult(result, type));
      })
      .catch((result) => {
        let type = null;
        switch (locationType) {
          case 1:
            type = types.GET_CITIES_PRELIM_PROJECT_DETAILS_FAILURE;
            break;
          case 2:
            type = types.GET_CITIES_FINAL_PROJECT_DETAILS_FAILURE;
            break;
          case 3:
            type = types.GET_CITIES_AHJ_FAILURE;
            break;
        }
        dispatch(feOrderResult(result, type));
      });
  };
};

export const getEngineerCountries = (locationType) => {
  return async (dispatch) => {
    let type = null;
    switch (locationType) {
      case 1:
        type = types.GET_COUNTRIES_PRELIM_ENGR_DETAILS_REQUEST;
        break;
      case 2:
        type = types.GET_COUNTRIES_FINAL_ENGR_DETAILS_REQUEST;
        break;
      case 3:
        type = types.GET_COUNTRIES_AHJ_REQUEST;
        break;
    }
    dispatch(feOrderResult(null, type));

    await api.FieldEvaluation.getCountries()
      .then((result) => {
        let type = null;
        switch (locationType) {
          case 1:
            type = types.GET_COUNTRIES_PRELIM_ENGR_DETAILS_SUCCESS;
            break;
          case 2:
            type = types.GET_COUNTRIES_FINAL_ENGR_DETAILS_SUCCESS;
            break;
          case 3:
            type = types.GET_COUNTRIES_AHJ_SUCCESS;
            break;
        }
        dispatch(feOrderResult(result, type));
      })
      .catch((result) => {
        let type = null;
        switch (locationType) {
          case 1:
            type = types.GET_COUNTRIES_PRELIM_ENGR_DETAILS_FAILURE;
            break;
          case 2:
            type = types.GET_COUNTRIES_FINAL_ENGR_DETAILS_FAILURE;
            break;
          case 3:
            type = types.GET_COUNTRIES_AHJ_FAILURE;
            break;
        }
        dispatch(feOrderResult(result, type));
      });
  };
};

export const getEngineerStates = (countryCode, locationType) => {
  return async (dispatch) => {
    let type = null;
    switch (locationType) {
      case 1:
        type = types.GET_STATES_PRELIM_ENGR_DETAILS_REQUEST;
        break;
      case 2:
        type = types.GET_STATES_FINAL_ENGR_DETAILS_REQUEST;
        break;
      case 3:
        type = types.GET_COUNTRIES_AHJ_REQUEST;
        break;
    }
    dispatch(feOrderResult(null, type));

    await api.FieldEvaluation.getStates(countryCode)
      .then((result) => {
        let type = null;
        switch (locationType) {
          case 1:
            type = types.GET_STATES_PRELIM_ENGR_DETAILS_SUCCESS;
            break;
          case 2:
            type = types.GET_STATES_FINAL_ENGR_DETAILS_SUCCESS;
            break;
          case 3:
            type = types.GET_COUNTRIES_AHJ_SUCCESS;
            break;
        }
        dispatch(feOrderResult(result, type));
      })
      .catch((result) => {
        let type = null;
        switch (locationType) {
          case 1:
            type = types.GET_STATES_PRELIM_ENGR_DETAILS_FAILURE;
            break;
          case 2:
            type = types.GET_STATES_FINAL_ENGR_DETAILS_FAILURE;
            break;
          case 3:
            type = types.GET_COUNTRIES_AHJ_FAILURE;
            break;
        }
        dispatch(feOrderResult(result, type));
      });
  };
};

export const getEngineerCities = (stateCode, locationType) => {
  return async (dispatch) => {
    let type = null;
    switch (locationType) {
      case 1:
        type = types.GET_CITIES_PRELIM_ENGR_DETAILS_REQUEST;
        break;
      case 2:
        type = types.GET_CITIES_FINAL_ENGR_DETAILS_REQUEST;
        break;
      case 3:
        type = types.GET_COUNTRIES_AHJ_REQUEST;
        break;
    }
    dispatch(feOrderResult(null, type));

    await api.FieldEvaluation.getCities(stateCode)
      .then((result) => {
        let type = null;
        switch (locationType) {
          case 1:
            type = types.GET_CITIES_PRELIM_ENGR_DETAILS_SUCCESS;
            break;
          case 2:
            type = types.GET_CITIES_FINAL_ENGR_DETAILS_SUCCESS;
            break;
          case 3:
            type = types.GET_COUNTRIES_AHJ_SUCCESS;
            break;
        }
        dispatch(feOrderResult(result, type));
      })
      .catch((result) => {
        let type = null;
        switch (locationType) {
          case 1:
            type = types.GET_CITIES_PRELIM_ENGR_DETAILS_FAILURE;
            break;
          case 2:
            type = types.GET_CITIES_FINAL_ENGR_DETAILS_FAILURE;
            break;
          case 3:
            type = types.GET_COUNTRIES_AHJ_FAILURE;
            break;
        }
        dispatch(feOrderResult(result, type));
      });
  };
};

export const getAhjCountries = () => {
  return async (dispatch) => {
    dispatch(feOrderResult(null, types.GET_COUNTRIES_AHJ_REQUEST));

    await api.FieldEvaluation.getCountries()
      .then((result) => {
        dispatch(feOrderResult(result, types.GET_COUNTRIES_AHJ_SUCCESS));
      })
      .catch((result) => {
        dispatch(feOrderResult(result, types.GET_COUNTRIES_AHJ_FAILURE));
      });
  };
};

export const getAhjStates = (countryCode) => {
  return async (dispatch) => {
    dispatch(feOrderResult(null, types.GET_STATES_AHJ_REQUEST));

    await api.FieldEvaluation.getStates(countryCode)
      .then((result) => {
        dispatch(feOrderResult(result, types.GET_STATES_AHJ_SUCCESS));
      })
      .catch((result) => {
        dispatch(feOrderResult(result, types.GET_STATES_AHJ_FAILURE));
      });
  };
};

export const getAhjCities = (stateCode) => {
  return async (dispatch) => {
    dispatch(feOrderResult(null, types.GET_CITIES_AHJ_REQUEST));

    await api.FieldEvaluation.getCities(stateCode)
      .then((result) => {
        dispatch(feOrderResult(result, types.GET_CITIES_AHJ_SUCCESS));
      })
      .catch((result) => {
        dispatch(feOrderResult(result, types.GET_CITIES_AHJ_FAILURE));
      });
  };
};

export const updatePpeSelections = (selectedPpe, isChecked) => {
  return (dispatch) =>
    dispatch(
      feOrderResult(
        { ppe: selectedPpe, checked: isChecked },
        types.UPDATE_PPE_SELECTION
      )
    );
};

export const updateTrainingSelections = (
  selectedTraining,
  isChecked,
  isTrainingTwoHours
) => {
  return (dispatch) =>
    dispatch(
      feOrderResult(
        {
          training: selectedTraining,
          checked: isChecked,
          trainingTwoHours: isTrainingTwoHours,
        },
        types.UPDATE_TRAINING_SELECTION
      )
    );
};

export const fillPrelimSiteDetails = (checked, accountDetails) => {
  return (dispatch) =>
    dispatch(
      feOrderResult(
        { checked: checked, accountDetails: accountDetails },
        types.FILL_PRELIM_SITE_DETAILS
      )
    );
};

export const fillFinalSiteDetails = (checked, accountDetails) => {
  return (dispatch) =>
    dispatch(
      feOrderResult(
        { checked: checked, accountDetails: accountDetails },
        types.FILL_FINAL_SITE_DETAILS
      )
    );
};

export const fillSchedulingDetails = (checked, accountDetails) => {
  return (dispatch) =>
    dispatch(
      feOrderResult(
        { checked: checked, accountDetails: accountDetails },
        types.FILL_SCHEDULING_DETAILS
      )
    );
};

export const getAppointments = (data) => {
  return async (dispatch) => {
    dispatch(feOrderResult(null, types.GET_APPOINTMENTS_REQUEST));
    await api.FieldEvaluation.getAppointments(data)
      .then((result) => {
        dispatch(feOrderResult(result, types.GET_APPOINTMENTS_SUCCESS));
      })
      .catch((result) => {
        dispatch(
          feOrderResult(
            result.response && result.response.data,
            types.GET_APPOINTMENTS_FAILURE
          )
        );
      });
  };
};

export const updateIsSuccess = () => {
  return async (dispatch) => {
    dispatch(feOrderResult(null, types.UPDATE_ISSUCCESS));
  };
};

export const uploadAttachment = (data) => {
  return async (dispatch) => {
    dispatch(feOrderResult(null, types.UPLOAD_ATTACHMENT_REQUEST));
    await api.FieldEvaluation.uploadAttachment(data)
      .then((result) => {
        dispatch(feOrderResult(result, types.UPLOAD_ATTACHMENT_SUCCESS));
      })
      .catch((result) => {
        dispatch(
          feOrderResult(
            result.response && result.response.data,
            types.UPLOAD_ATTACHMENT_FAILURE
          )
        );
      });
  };
};

export const uploadMultiSiteAttachment = (data) => {
  return async (dispatch) => {
    dispatch(feOrderResult(null, types.UPLOAD_MULTI_SITE_ATTACHMENT_REQUEST));
    await api.FieldEvaluation.uploadMultiSiteAttachment(data)
      .then((result) => {
        dispatch(
          feOrderResult(result, types.UPLOAD_MULTI_SITE_ATTACHMENT_SUCCESS)
        );
      })
      .catch((result) => {
        dispatch(
          feOrderResult(
            result.response && result.response.data,
            types.UPLOAD_MULTI_SITE_ATTACHMENT_FAILURE
          )
        );
      });
  };
};
