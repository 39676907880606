import { UPDATE_MENU } from "./sidebar-menu.type";

const initialState = {
  navRoutes: [],
  otherRoutes: []
};

const sidebarReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_MENU:
      const updateMenuState = { ...state };

      updateMenuState.navRoutes = action.payload.data.navRoutes;
      updateMenuState.otherRoutes = action.payload.data.otherRoutes;
      return updateMenuState;

    default:
      return state;
  }
};

export default sidebarReducer;
