import React from "react";
import styled from "styled-components";
import { Label } from "semantic-ui-react";

const CustomTitleStyle = styled.p`
  font-weight: bold;
  // color: gray;
`;

const CustomTitleLabelStyle = styled.label`
  font-weight: bold;
  color: gray;
`;

const CustomHeaderStyle = styled.p`
  font-size: 17px;
`;
const CustomContainerHeaderStyle = styled.p`
  font-size: 24px;
`;

const CustomHeaderLabelStyle = styled.label`
  font-size: 17px;
`;

const CustomHeaderLabelStyle2 = styled.label`
  font-size: 17px;
  font-weight: bold;
  color: gray;
`;

const CustomHeaderLabelRelaxedStyle = styled.label`
  font-size: 15px;
`;

const LabelBoldStyle = styled.label`
  font-weight: bold;
`;

const CustomHeaderBoldStyle = styled.p`
  font-size: 17px;
  font-weight: bold;
  line-height: 1;
`;

const CustomHeaderInlineBoldStyle = styled.p`
  font-size: 17px;
  font-weight: bold;
  line-height: 1;
  display: inline-block;
`;

const CustomHeaderInlineBoldRelaxedStyle = styled.p`
  font-size: 15px;
  font-weight: bold;
  line-height: 1;
  display: inline-block;
`;

const CustomLabelStyle = styled.label`
  color: #a0a2a4;
  margin-right: 5px;
`;

const CustomLabelStyle2 = styled.label`
  color: #969696;
  font-size: 14px;
  font-weight: bold;
`;

const CustomSubLabelStyle = styled.label`
  color: #a0a2a4;
  font-size: 10px;
`;

const CustomSubLabelUpperStyle = styled.label`
  color: #a0a2a4;
  font-size: 10px;
  text-transform: uppercase;
  margin-right: 5px;
`;

const SubLabelStyle = styled.label`
  font-size: 10px;
`;

const SubTextStyle = styled.p`
  font-size: 10px;
`;

const SubTextItalicStyle = styled.p`
  font-size: 10px;
  font-style: italic;
`;

export const CustomTitle = ({ message }) => {
  return <CustomTitleStyle>{message}</CustomTitleStyle>;
};

export const CustomTitleLabel = ({ message }) => {
  return <CustomTitleLabelStyle>{message}</CustomTitleLabelStyle>;
};

export const CustomHeaderLabel = ({ message }) => {
  return <CustomHeaderLabelStyle>{message}</CustomHeaderLabelStyle>;
};

export const CustomHeaderLabel2 = ({ message }) => {
  return <CustomHeaderLabelStyle2>{message}</CustomHeaderLabelStyle2>;
};

export const CustomHeaderLabelRelaxed = ({ message }) => {
  return (
    <CustomHeaderLabelRelaxedStyle>{message}</CustomHeaderLabelRelaxedStyle>
  );
};

export const CustomHeader = ({ message }) => {
  return <CustomHeaderStyle>{message}</CustomHeaderStyle>;
};

export const CustomHeaderBold = ({ message }) => {
  return <CustomHeaderBoldStyle>{message}</CustomHeaderBoldStyle>;
};

export const CustomHeaderInlineBold = ({ message }) => {
  return <CustomHeaderInlineBoldStyle>{message}</CustomHeaderInlineBoldStyle>;
};

export const CustomHeaderInlineBoldRelaxed = ({ message }) => {
  return (
    <CustomHeaderInlineBoldRelaxedStyle>
      {message}
    </CustomHeaderInlineBoldRelaxedStyle>
  );
};

export const CustomLabel = ({ message }) => {
  return <CustomLabelStyle>{message}</CustomLabelStyle>;
};

export const CustomLabel2 = ({ message }) => {
  return <CustomLabelStyle2>{message}</CustomLabelStyle2>;
};

export const SubText = ({ message }) => {
  return <SubTextStyle>{message}</SubTextStyle>;
};

export const SubTextItalic = ({ message }) => {
  return <SubTextItalicStyle>{message}</SubTextItalicStyle>;
};

export const LabelBold = ({ message }) => {
  return <LabelBoldStyle>{message}</LabelBoldStyle>;
};

export const CustomSubLabel = ({ message }) => {
  return <CustomSubLabelStyle>{message}</CustomSubLabelStyle>;
};

export const SubLabel = ({ message }) => {
  return <SubLabelStyle>{message}</SubLabelStyle>;
};

export const CustomSubLabelUpper = ({ message }) => {
  return <CustomSubLabelUpperStyle>{message}</CustomSubLabelUpperStyle>;
};
export const CustomContainerHeader = ({ message }) => {
  return <CustomContainerHeaderStyle>{message}</CustomContainerHeaderStyle>;
};

export const RequiredFieldMessage = ({ isError }) => {
  return (
    <Label
      basic
      color="red"
      pointing="left"
      style={isError ? { display: "inline-block" } : { display: "none" }}
    >
      This field is required.
    </Label>
  );
};
