export const FORM = "FORM";
export const CLEAR = "CLEAR";
export const ADD_ORDER = "ADD_ORDER";
export const CLEAR_ORDER = "CLEAR_ORDER";
export const REMOVE_ORDER = "REMOVE_ORDER";
export const UPDATE_ORDER = "UPDATE_ORDER";

export const AREA_REQUEST = "AREA_REQUEST";
export const AREA_SUCCESS = "AREA_SUCCESS";
export const AREA_FAILURE = "AREA_FAILURE";

export const SERVICE_AREA_REQUEST = "SERVICE_AREA_REQUEST";
export const SERVICE_AREA_SUCCESS = "SERVICE_AREA_SUCCESS";
export const SERVICE_AREA_FAILURE = "SERVICE_AREA_FAILURE";
export const SERVICE_AREA_CLEAR = "SERVICE_AREA_CLEAR";

export const SERVICE_PRODUCT_REQUEST = "SERVICE_PRODUCT_REQUEST";
export const SERVICE_PRODUCT_SUCCESS = "SERVICE_PRODUCT_SUCCESS";
export const SERVICE_PRODUCT_FAILURE = "SERVICE_PRODUCT_FAILURE";
export const SERVICE_PRODUCT_CLEAR = "SERVICE_PRODUCT_CLEAR";

export const SERVICE_SUB_PRODUCT_REQUEST = "SERVICE_SUB_PRODUCT_REQUEST";
export const SERVICE_SUB_PRODUCT_SUCCESS = "SERVICE_SUB_PRODUCT_SUCCESS";
export const SERVICE_SUB_PRODUCT_FAILURE = "SERVICE_SUB_PRODUCT_FAILURE";
export const SERVICE_SUB_PRODUCT_CLEAR = "SERVICE_SUB_PRODUCT_CLEAR";

export const SERVICE_PRODUCT_PRICE_REQUEST = "SERVICE_PRODUCT_PRICE_REQUEST";
export const SERVICE_PRODUCT_PRICE_SUCCESS = "SERVICE_PRODUCT_PRICE_SUCCESS";
export const SERVICE_PRODUCT_PRICE_FAILURE = "SERVICE_PRODUCT_PRICE_FAILURE";
export const SERVICE_PRODUCT_PRICE_CLEAR = "SERVICE_PRODUCT_PRICE_CLEAR";
