const cancellationDefault = `<p>
    If a decision is reached to cancel the project before completion
    and after it has commenced, and/or if the schedule is changed
    with less than three (3) business days' notice, cancellation
    fees will apply.
</p>
<p>
    Unless an extended final evaluation schedule is prearranged, it
    is expected the client will complete the final evaluation within
    90 days after UL issues a preliminary findings report. After 90
    days with no final schedule time frame set, the project will be
    closed and invoiced. Exceptions to this shall be agreed to in
    writing.
</p>`;

const cancellationValue = (data, fromViewOrderData) => {
  let cancellationValue = "";
  if (data && data.form) {
    // saved data from product item or from form changes
    if (data.form.cancellation) {
      cancellationValue = data.form.cancellation;
    } else {
      // Global/Fixed assumptions cancellation per sub product
      let cancellation = data.serviceSubProduct.data
        ? data.serviceSubProduct.data.filter(
            (s) => s.id == data.form.serviceSubProductId
          )
        : "";
      cancellationValue = cancellation.length
        ? cancellation[0].assumption.cancellation
        : "";
    }
  }

  if (fromViewOrderData) {
    if (fromViewOrderData.length > 0)
      cancellationValue = fromViewOrderData[0].cancellation;
    return cancellationValue;
  }

  return cancellationValue ? `${cancellationValue}` : cancellationDefault;
};

export default cancellationValue;
