const cancellationDefault = `<p>
    If a decision is reached to cancel the project before completion
    and after it has commenced, and/or if the schedule is changed
    with less than three (3) business days' notice, cancellation
    fees will apply.
</p>
<p>
    Unless an extended final evaluation schedule is prearranged, it
    is expected the client will complete the final evaluation within
    90 days after UL issues a preliminary findings report. After 90
    days with no final schedule time frame set, the project will be
    closed and invoiced. Exceptions to this shall be agreed to in
    writing.
</p>`;

const cancellationValue = (data, fromViewOrderData) => {
  let cancellationValue = "";
  // refer to index 0 only
  const idx = data.forms.findIndex((d) => d.tabIndex === 0);
  if (idx != -1) {
    if (data.forms && data.forms[idx].cancellation) {
      cancellationValue = data.forms[idx].cancellation;
    }
    // Global/Fixed assumption per sub product
    else {
      let cancellation = data.serviceSubProduct.data;
      cancellationValue = cancellation
        ? cancellation.length
          ? cancellation[0].assumption.cancellation
          : ""
        : "";
    }
  }

  return cancellationValue ? `${cancellationValue}` : cancellationDefault;
};

export default cancellationValue;
