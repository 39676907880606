export const NOTIFICATIONS_REQUEST = "NOTIFICATIONS_REQUEST";
export const NOTIFICATIONS_FAILURE = "NOTIFICATIONS_FAILURE";
export const NOTIFICATIONS_SUCCESS = "NOTIFICATIONS_SUCCESS";

export const UPDATE_NOTIFICATIONS_REQUEST = "UPDATE_NOTIFICATIONS_REQUEST";
export const UPDATE_NOTIFICATIONS_FAILURE = "UPDATE_NOTIFICATIONS_FAILURE";
export const UPDATE_NOTIFICATIONS_SUCCESS = "UPDATE_NOTIFICATIONS_SUCCESS";

export const CART_ITEMS_REQUEST = "CART_ITEMS_REQUEST";
export const CART_ITEMS_FAILURE = "CART_ITEMS_FAILURE";
export const CART_ITEMS_SUCCESS = "CART_ITEMS_SUCCESS";

export const ORDER_CHANGE_PAGE = "ORDER_CHANGE_PAGE";

export const UPDATE_NOTIF_DATA = "UPDATE_NOTIF_DATA";
