import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Table, Label, Button } from "semantic-ui-react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as actions from "./admin-search.action";
import { renderDisc, GetStatusCode } from "../../helpers/component-helper";

class AdminSearchResultItem extends Component {
  // componentWillUnmount() {
  //   this.props.clear();
  // }

  render() {
    const { data, onCancel } = this.props;
    let status =
      data.labelOrderStatus == 4 ? data.labelOrderStatus : data.status;
    return (
      <Table.Row>
        <Table.Cell>
          {renderDisc(status)}
          {GetStatusCode(status)}
        </Table.Cell>
        <Table.Cell>{data.psn}</Table.Cell>
        <Table.Cell>{data.manufacturerAccountNumber}</Table.Cell>
        <Table.Cell>{data.orderConfirmationNumber}</Table.Cell>
        <Table.Cell>
          {data.oracleNumber
            ? data.oracleNumber
            : // : data.labelOrderType == 1
              // ? "UL Order fail"
              data.oracleMessage}
        </Table.Cell>
        <Table.Cell>{data.fileNumber}</Table.Cell>
        <Table.Cell>
          {/* <Link
            to={
              "/home/label-order-details/" +
              data.orderConfirmationNumber +
              "/" +
              data.labelOrderType
            }
          >
            <Label color="blue">View Details</Label>
          </Link> */}
          <Button
            color="blue"
            compact
            as={Link}
            to={`/home/label-order-details/${data.orderConfirmationNumber}/${data.labelOrderType}`}
          >
            View Details
          </Button>
        </Table.Cell>
      </Table.Row>
    );
  }
}

AdminSearchResultItem.propTypes = {
  data: PropTypes.object.isRequired
};

const mapDispatchToProps = (dispatch) => {
  return {
    clear: () => dispatch(actions.clearAdminSearch())
  };
};

export default connect(null, mapDispatchToProps)(AdminSearchResultItem);
