import * as types from "./role-assignment.type";
import { GetBUType } from "../../helpers/component-helper";

let initialState = {
  data: {
    currentPage: 1,
    pageSize: 10,
    totalPages: 1
  },
  roles: {},
  legalEntities: {},
  result: null,
  notes: {},
  filter: {},
  isGetUserFilterSuccess: false,
  isSaveUserFilterSuccess: false,
  foEmails: {},
  foEmailsOption: [],
  foFirstNames: {},
  foFirstNamesOption: [],
  foLastNames: {},
  foLastNamesOption: [],
  foRoles: {},
  foRolesOption: [],
  foLegalEntities: {},
  foLegalEntitiesOption: [],
  foPartySiteNumbers: {},
  foPartySiteNumbersOption: [],
  form: {
    showErrors: false,
    validationErrors: {}
  },
  claimsDetails: {}
};

const roleAssignmentReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
    case types.GET_USERS_REQUEST:
    case types.GET_USERS_SUCCESS:
    case types.GET_USERS_FAILURE:
      var results = Object.assign({}, state, {
        data: action.data || initialState.data
      });

      // results.data = results.data.map((item) => {
      //   item.legalEntity = `[${GetBUType(item.buType)}] - ${item.legalEntity}`;
      // });
      return results;
    case types.GET_ROLES_REQUEST:
    case types.GET_ROLES_SUCCESS:
    case types.GET_ROLES_FAILURE:
      return Object.assign({}, state, {
        roles: action.data || initialState.roles
      });
    case types.GET_LEGAL_ENTITIES_REQUEST:
    case types.GET_LEGAL_ENTITIES_SUCCESS:
    case types.GET_LEGAL_ENTITIES_FAILURE:
      return Object.assign({}, state, {
        legalEntities: action.data || initialState.legalEntities
      });
    case types.CHANGE_ROLE_REQUEST:
      return Object.assign({}, state, {
        data: Object.assign({}, state.data, {
          data: action.data || initialState.data
        })
      });
    case types.CHANGE_ROLE_SUCCESS:
    case types.CHANGE_ROLE_FAILURE:
      return Object.assign({}, state, {
        result: action.data || initialState.result
      });
    case types.CHANGE_LEGAL_ENTITIES_REQUEST:
      return Object.assign({}, state, {
        data: Object.assign({}, state.data, {
          data: action.data || initialState.data
        })
      });
    case types.CHANGE_LEGAL_ENTITIES_SUCCESS:
    case types.CHANGE_LEGAL_ENTITIES_FAILURE:
      return Object.assign({}, state, {
        result: action.data || initialState.result
      });
    case types.SUSPEND_USER_SUCCESS:
    case types.SUSPEND_USER_FAILURE:
      return Object.assign({}, state, {
        result: action.data || initialState.result
      });
    case types.ADD_NOTE_SUCCESS:
    case types.ADD_NOTE_FAILURE:
      return Object.assign({}, state, {
        result: action.data || initialState.result
      });
    case types.LIST_NOTE_SUCCESS:
    case types.LIST_NOTE_FAILURE:
      return Object.assign({}, state, {
        notes: action.data || initialState.result
      });

    case types.GET_FO_EMAILS_REQUEST:
    case types.GET_FO_EMAILS_SUCCESS:
    case types.GET_FO_EMAILS_FAILURE:
      return Object.assign({}, state, {
        foEmails: action.data || initialState.foEmails,
        foEmailsOption:
          action.data &&
          action.data.data.map((i) => {
            return {
              key: i,
              value: i,
              title: i
            };
          })
      });

    case types.GET_FO_FIRSTNAMES_REQUEST:
    case types.GET_FO_FIRSTNAMES_SUCCESS:
    case types.GET_FO_FIRSTNAMES_FAILURE:
      return Object.assign({}, state, {
        foFirstNames: action.data || initialState.foFirstNames,
        foFirstNamesOption:
          action.data &&
          action.data.data.map((i) => {
            return {
              key: i,
              value: i,
              title: i
            };
          })
      });

    case types.GET_FO_LASTNAMES_REQUEST:
    case types.GET_FO_LASTNAMES_SUCCESS:
    case types.GET_FO_LASTNAMES_FAILURE:
      return Object.assign({}, state, {
        foLastNames: action.data || initialState.foLastNames,
        foLastNamesOption:
          action.data &&
          action.data.data.map((i) => {
            return {
              key: i,
              value: i,
              title: i
            };
          })
      });

    case types.GET_FO_ROLES_REQUEST:
    case types.GET_FO_ROLES_SUCCESS:
    case types.GET_FO_ROLES_FAILURE:
      return Object.assign({}, state, {
        foRoles: action.data || initialState.foRoles,
        foRolesOption:
          action.data &&
          action.data.data.map((i) => {
            return {
              key: i,
              value: i,
              text: i
            };
          })
      });

    case types.GET_FO_LEGALENTITIES_REQUEST:
    case types.GET_FO_LEGALENTITIES_SUCCESS:
    case types.GET_FO_LEGALENTITIES_FAILURE:
      return Object.assign({}, state, {
        foLegalEntities: action.data || initialState.foLegalEntities,
        foLegalEntitiesOption:
          action.data &&
          action.data.data.map((i) => {
            return {
              key: i,
              value: i,
              text: i
            };
          })
      });

    case types.GET_FO_PSN_REQUEST:
    case types.GET_FO_PSN_SUCCESS:
    case types.GET_FO_PSN_FAILURE:
      return Object.assign({}, state, {
        foPartySiteNumbers: action.data || initialState.foPartySiteNumbers,
        foPartySiteNumbersOption:
          action.data &&
          action.data.data.map((i) => {
            return {
              key: i,
              value: i,
              title: i
            };
          })
      });

    case types.SET_RA_USER_FILTER_REQUEST:
      return Object.assign({}, state, {
        data: Object.assign({}, state.data, {
          data: action.data || initialState.data
        })
      });
    case types.SET_RA_USER_FILTER_SUCCESS:
    case types.SET_RA_USER_FILTER_FAILURE:
      return Object.assign({}, state, {
        result: action.data || initialState.result,
        isSaveUserFilterSuccess: action.data?.isSuccessful
      });

    case types.GET_RA_USER_FILTER_REQUEST:
    case types.GET_RA_USER_FILTER_SUCCESS:
    case types.GET_RA_USER_FILTER_FAILURE:
      return Object.assign({}, state, {
        filter: action.data?.data || initialState.filter,
        isGetUserFilterSuccess: action.data?.isSuccessful
      });

    case types.GET_ROLES_BY_USER_FILTER_REQUEST:
    case types.GET_ROLES_BY_USER_FILTER_SUCCESS:
    case types.GET_ROLES_BY_USER_FILTER_FAILURE:
      return Object.assign({}, state, {
        data: action.data || initialState.data
      });

    case types.ROLE_FILTEROPTION_RESET:
      return Object.assign({}, state, {
        isGetUserFilterSuccess: false,
        isSaveUserFilterSuccess: false
      });

    case types.ROLE_ASSIGNMENT_UPDATE:
      return Object.assign({}, state, {
        data: { ...state.data, data: action.data } || initialState.data
      });
    case types.ROLE_ASSIGNMENT_FORM:
      return Object.assign({}, state, {
        form: action.data || initialState.result,
        hasError: true,
        loading: false
      });

    case types.ROLE_ASSIGNMENT_CLEAR:
      var result = Object.assign({}, state, {
        form: {
          showErrors: false,
          validationErrors: {}
        },
        isGetUserFilterSuccess: false,
        isSaveUserFilterSuccess: false,
        hasError: true,
        loading: false
      });
      return result;
    case types.CLAIMS_DETAILS_SUCCESS:
    case types.CLAIMS_DETAILS_FAILURE:
      return Object.assign({}, state, {
        claimsDetails: action.data || initialState.result
      });
    case types.CLEAR_CLAIMS_DETAILS:
      return Object.assign({}, state, {
        claimsDetails: {}
      });
    case types.AUTHORIZE_ND_LABEL_SUCCESS:
    case types.AUTHORIZE_ND_LABEL_FAILURE:
      return Object.assign({}, state, {
        result: action.data || initialState.result
      });
  }
};

export default roleAssignmentReducer;
