import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Container,
  Grid,
  Segment,
  Table,
  Pagination,
  Message,
  Sidebar,
  Button
} from "semantic-ui-react";

import Role from "../../enums/role";
import ToastHelper from "../../helpers/toast.helper";
import { GetBUType } from "../../helpers/component-helper";
import { CustomHeader } from "../../components/labels/labels";

import NoteModal from "./note-modal";
import SuspendedModal from "./suspended-modal";
import ViewClaimsModal from "./view-claims-modal";
import RoleAssignmentTableItem from "./role-assignment-table-item";

import * as actions from "./role-assignment.action";
import RoleAssignmentFilterOption from "./common/role-assignment-filter-option";
import { Common } from "../../common";
import RoleAssignmentColumnOption from "./common/role-assignment-column-option";
import * as commonActions from "../../views/user/common/common.action";
import AuthorizeNDLabelModal from "./authorize-ndlabel-modal";

const CustomButtonStyle = {
  border: "1px solid #2185d0",
  color: "#2185d0",
  background: "white"
};

const columnOptions = [
  {
    key: "partySiteNumbers",
    value: 1,
    text: "Party Site Number",
    visible: 1,
    sort: 0,
    checked: 1,
    disabled: 0
  },
  {
    key: "email",
    value: 2,
    text: "Email",
    visible: 1,
    sort: 0,
    checked: 1,
    disabled: 0
  },
  {
    key: "firstName",
    value: 3,
    text: "First Name",
    visible: 1,
    sort: 0,
    checked: 1,
    disabled: 0
  },
  {
    key: "lastName",
    value: 4,
    text: "Last Name",
    visible: 1,
    sort: 0,
    checked: 1,
    disabled: 0
  },
  {
    key: "role",
    value: 5,
    text: "Role",
    visible: 1,
    sort: 0,
    checked: 1,
    disabled: 0
  },
  {
    key: "legalEntity",
    value: 6,
    text: "Legal Entity",
    visible: 1,
    sort: 0,
    checked: 1,
    disabled: 0
  },
  // {
  //   key: "isVerified",
  //   value: 7,
  //   text: "Is Verified",
  //   visible: 1,
  //   sort: 0,
  //   checked: 1,
  //   disabled: 0
  // },
  {
    key: "createdDateTime",
    value: 8,
    text: "Created Date & Time",
    visible: 1,
    sort: 0,
    checked: 1,
    disabled: 0
  },
  {
    key: "suspendDeactivate",
    value: 9,
    text: "Suspend/Deactivate",
    visible: 1,
    sort: 0,
    checked: 1,
    disabled: 0
  }
  // {
  //   key: "authorizeNDLabel",
  //   value: 10,
  //   text: "Authorize ND Label",
  //   visible: 1,
  //   sort: 0,
  //   checked: 1,
  //   disabled: 0
  // }
];

class RoleAssignment extends Component {
  state = {
    roleList: [],
    legalEntityList: [],
    isShowSuspendedModal: false,
    isShowAuthorizeNDLabelModal: false,
    selectedItem: {},
    suspendLoading: "",
    authorizeNDLabelLoading: "",
    isShowNoteModal: false,
    isSaveNote: false,
    column: null,
    columnOptions: columnOptions,
    direction: null,
    isFilterVisible: false,
    isColumnOptVisible: false,
    activeIndex: 0,
    filterData: null,
    columnSortable: false,
    isShowViewClaimsModal: false,
    claimsType: 0 // Get claims via 1 - PSN, 2 - Email
  };

  componentDidMount() {
    this.props.getAllUsers(
      this.props.roleAssignment.data.currentPage,
      this.props.roleAssignment.data.pageSize
    );
    this.props.getRoles().then((s) => {
      let roles = this.populateRoleDropdown(s);
      this.setState({ roleList: roles });
    });
    this.props.getLegalEntities().then((s) => {
      let legalEntites = this.populateLegalEntityDropdown(s.data);
      this.setState({ legalEntityList: legalEntites });
    });

    this.props.getUserFilter();
    this.props.getActivePage("Role Assignment");
  }

  populateRoleDropdown(roles) {
    var list = [];
    roles.map(function (v) {
      var tmp = {};
      tmp["key"] = v.id;
      tmp["id"] = v.id;
      tmp["value"] = v.name;
      tmp["text"] = v.name;

      list.push(tmp);
    });
    return list;
  }

  populateLegalEntityDropdown(legalEntities) {
    var list = [];
    legalEntities.map(function (v) {
      var tmp = {};
      tmp["key"] = v.id;
      tmp["id"] = v.id;
      tmp["value"] = v.legalEntities;
      if (v.buType !== 0) {
        tmp["value"] = v.legalEntities + ` - [${GetBUType(v.buType)}]`;
        tmp["text"] = v.legalEntities + ` - [${GetBUType(v.buType)}]`;
      } else {
        tmp["value"] = v.legalEntities;
        tmp["text"] = v.legalEntities;
      }
      list.push(tmp);
    });
    return list;
  }

  onSave(item) {
    const { data } = this.props.roleAssignment;
    const idx = _.findIndex(data.data, { id: item.id });
    data.data[idx].isLoading = "loading";
    this.props.doEditRole(data.data);

    const params = {
      userId: item.id,
      userRoleId: item.userRoleId || item.roleId // saving w/o changing role
    };

    this.props.doChangeRole(params).then((s) => {
      if (s.isSuccessful) {
        data.data[idx].roleId = item.userRoleId || item.roleId;
        data.data[idx].role = item.userRole || item.role;

        setTimeout(() => {
          ToastHelper.success({
            title: "Successfully Saved",
            description: <p>Role has been changed.</p>
          });
        }, 100);
      } else {
        setTimeout(() => {
          ToastHelper.error({
            title: "Error",
            description: s.message
          });
        }, 100);
      }
      data.data[idx].isEdit = false;
      data.data[idx].isLoading = "";
      this.props.doEditRole(data.data);
    });
  }

  onSaveLegalEntity(item) {
    const { data } = this.props.roleAssignment;
    const idx = _.findIndex(data.data, { id: item.id });
    data.data[idx].isLoading = "loading";
    this.props.doEditRole(data.data);

    const params = {
      userId: item.id,
      userLegalEntityId: item.legalEntityId || item.legalEntityId // saving w/o changing role
    };

    this.props.doChangeLegalEntity(params).then((s) => {
      if (s.isSuccessful) {
        data.data[idx].legalEntityId = item.legalEntityId || item.legalEntityId;
        // data.data[idx].legalEntity = item.legalEntity || item.legalEntity;

        this.props.getAllUsers(
          this.props.roleAssignment.data.currentPage,
          this.props.roleAssignment.data.pageSize
        );

        setTimeout(() => {
          ToastHelper.success({
            title: "Successfully Saved",
            description: <p>Legal entity has been changed.</p>
          });
        }, 100);
      } else {
        setTimeout(() => {
          ToastHelper.error({
            title: "Error",
            description: s.message
          });
        }, 100);
      }
      data.data[idx].isEditLegalEntity = false;
      data.data[idx].isLoading = "";
      this.props.doEditRole(data.data);
    });
  }

  onEdit(item, isEdit) {
    const { data } = this.props.roleAssignment;
    const idx = _.findIndex(data.data, { id: item.id });
    if (idx < 0) return;

    data.data[idx].isEdit = isEdit;
    this.props.doEditRole(data.data);
  }

  onEditLegalEntity(item, isEdit) {
    const { data } = this.props.roleAssignment;
    const idx = _.findIndex(data.data, { id: item.id });
    if (idx < 0) return;

    data.data[idx].isEditLegalEntity = isEdit;
    this.props.doEditRole(data.data);
  }

  onToggleSuspend(item) {
    this.handleSuspendedModal(true);
    this.setState({ selectedItem: item });
  }

  handleSuspendedModal(isShow) {
    this.setState({ isShowSuspendedModal: isShow });
  }

  onConfirmSuspend(item) {
    const { data } = this.props.roleAssignment;
    const idx = _.findIndex(data.data, { id: item.id });
    this.setState({ suspendLoading: "loading" });

    const params = {
      userId: item.id,
      isSuspend: !item.isSuspended
    };

    this.props.doSuspendUser(params).then((s) => {
      if (s.isSuccessful) {
        data.data[idx].isSuspended = !item.isSuspended;

        setTimeout(() => {
          ToastHelper.success({
            title: "Successfully Saved",
            description: (
              <p>
                The user <b>{item.email}</b> access has been{" "}
                {item.isSuspended ? "removed" : "restored"} from the system.
              </p>
            )
          });
        }, 100);
      } else {
        setTimeout(() => {
          ToastHelper.error({
            title: "Error",
            description: s.message
          });
        }, 100);
      }

      this.props.doEditRole(data.data);
      this.setState({ suspendLoading: "" });
      this.handleSuspendedModal(false);
    });
  }

  onToggleAuthorizeNDLabel(item) {
    this.handleAuthorizeNDLabelModal(true);
    this.setState({ selectedItem: item });
  }

  handleAuthorizeNDLabelModal(isShow) {
    this.setState({ isShowAuthorizeNDLabelModal: isShow });
  }

  onConfirmAuthorizeNDLabel(item) {
    const { data } = this.props.roleAssignment;
    const idx = _.findIndex(data.data, { id: item.id });
    this.setState({ authorizeNDLabelLoading: "loading" });

    const params = {
      userId: item.id,
      isAuthorizedNDLabel: !item.isAuthorizedNDLabel
    };

    this.props.authorizeNDLabel(params).then((s) => {
      if (s.isSuccessful) {
        data.data[idx].isAuthorizedNDLabel = !item.isAuthorizedNDLabel;

        setTimeout(() => {
          ToastHelper.success({
            title: "Successfully Saved",
            description: (
              <p>
                The user <b>{item.email}</b>
                {item.isAuthorizedNDLabel ? " has been" : " has not been"}{" "}
                authorized to order non-denominational labels.
              </p>
            )
          });
        }, 100);
      } else {
        setTimeout(() => {
          ToastHelper.error({
            title: "Error",
            description: s.message
          });
        }, 100);
      }

      this.props.doEditRole(data.data);
      this.setState({ authorizeNDLabelLoading: "" });
      this.handleAuthorizeNDLabelModal(false);
    });
  }
  handleNoteModal(isShow) {
    this.setState({ isShowNoteModal: isShow });
    this.setState({ isSaveNote: true });
  }

  onViewNote(item) {
    this.handleNoteModal(true);
    this.setState({ selectedItem: item });
  }

  handleViewClaimsModal(isShow) {
    this.setState({ isShowViewClaimsModal: isShow });
  }

  onViewClaims(item, claimsType) {
    this.handleViewClaimsModal(true);
    this.setState({ selectedItem: item, claimsType: claimsType });
  }

  handleToggleFilter = () => {
    const { isFilterVisible } = this.state;

    var _isFilterVisible = !isFilterVisible;
    this.setState({
      isFilterVisible: _isFilterVisible,
      isColumnOptVisible: false
    });
  };

  handleToggleColumn = () => {
    const { isColumnOptVisible } = this.state;

    var _isColumnOptVisible = !isColumnOptVisible;

    this.setState({
      isColumnOptVisible: _isColumnOptVisible,
      isFilterVisible: false
    });
  };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  handleSort = (clickedColumn) => () => {
    const { column, direction, columnSortable } = this.state;
    const { data } = this.props.roleAssignment;

    if (columnSortable === true) {
      if (column !== clickedColumn) {
        this.setState({
          column: clickedColumn,
          direction: "ascending"
        });

        const newData2 = _.sortBy(data.data, [clickedColumn]);
        this.props.updateData(newData2);
        return;
      }

      const newData = data.data.reverse();
      this.props.updateData(newData);
      this.setState({
        direction: direction === "ascending" ? "descending" : "ascending"
      });
    }
  };

  handleRemoveColumn = (columnKey) => {
    var { columnOptions } = this.state;
    var selectedColumn = columnOptions.filter((i) => i.key == columnKey);
    selectedColumn[0].checked = 0;

    const newState = { ...this.selectedColumn };
    this.setState(newState);
  };

  handleAddColumn = () => {
    var { columnOptions } = this.state;
    var selectedColumn = columnOptions.filter((i) => i.selected == true);
    if (selectedColumn.length > 0) selectedColumn[0].checked = 1;

    const newState = { ...this.selectedColumn };

    this.setState(newState);
  };

  handleApplyColumnOptions = () => {
    var { columnOptions } = this.state;

    var _columnOptions = columnOptions.map((i) => {
      i.visible = i.checked;
      return i;
    });

    const newState = { ..._columnOptions };

    this.setState(newState);

    var _columnOptions = columnOptions.filter((i) => i.visible == 1);

    var _activeColumns = _columnOptions.map((i) => i.value);

    var selectedColumns = _activeColumns.join("|");

    var data = {
      isColumnOptions: true,
      columnOptions: selectedColumns
    };

    this.props.setUserFilter(data);

    this.setState({ isColumnOptVisible: false });
  };

  handleClearColumnOptions = () => {
    var { columnOptions } = this.state;

    var _columnOptions = columnOptions.map((i) => {
      i.visible = 1;
      i.checked = 1;
    });

    const newState = { ..._columnOptions };

    this.setState(newState);

    var _columnOptions = columnOptions.filter((i) => i.visible == 1);

    var _activeColumns = _columnOptions.map((i) => i.value);

    var selectedColumns = _activeColumns.join("|");

    var data = {
      isColumnOptions: true,
      columnOptions: selectedColumns
    };

    this.props.setUserFilter(data);

    this.setState({ isColumnOptVisible: false });
  };

  handleDdColumnChange = (event, { value }) => {
    var { columnOptions } = this.state;

    // clear all selected column
    var resetColumn = columnOptions.map((column, idx) => {
      column.selected = false;
    });
    const newResetState = { ...this.resetColumn };
    this.setState(newResetState);

    // add selected column
    var selectedColumn = columnOptions.filter((i) => i.value == value);
    selectedColumn[0].selected = true;
    const newAddedState = { ...this.selectedColumn };
    this.setState(newAddedState);
  };

  handleColumnSortChange = (e) => {
    var { columnSortable } = this.state;

    columnSortable = !columnSortable;

    this.setState({ columnSortable: columnSortable });
  };

  handlePageChange = (filterData) => {
    if (
      filterData &&
      (((filterData || {}).partySiteNumbers || []).length > 0 ||
        ((filterData || {}).filterDate || []).length > 0 ||
        ((filterData || {}).userEmails || []).length > 0 ||
        ((filterData || {}).userFirstNames || []).length > 0 ||
        ((filterData || {}).userLastNames || []).length > 0 ||
        ((filterData || {}).userRoles || []).length > 0 ||
        ((filterData || {}).legalEntities || []).length > 0 ||
        (filterData || {}).suspended === true)
    ) {
      this.props.getUsersByUserFilter(filterData);
      this.props.filterOptionReset();
    } else {
      this.props.getAllUsers(filterData.currentPage, filterData.pageSize);
    }
  };

  render() {
    const { data } = this.props.roleAssignment;
    const {
      legalEntityList,
      isShowSuspendedModal,
      isShowAuthorizeNDLabelModal,
      selectedItem,
      suspendLoading,
      authorizeNDLabelLoading,
      isShowNoteModal,
      isSaveNote,
      column,
      columnOptions,
      direction,
      isFilterVisible,
      isColumnOptVisible,
      activeIndex,
      filterData,
      columnSortable,
      isShowViewClaimsModal,
      claimsType
    } = this.state;

    const { filter, isGetUserFilterSuccess, isSaveUserFilterSuccess } =
      this.props.roleAssignment;

    if (isSaveNote) {
      this.props.getAllUsers(
        this.props.roleAssignment.data.currentPage,
        this.props.roleAssignment.data.pageSize
      );
      this.setState({ isSaveNote: false });
    }

    if (isSaveUserFilterSuccess) {
      this.props.getUserFilter();
      this.props.filterOptionReset();
    }

    if (!_.isEmpty(filter) && isGetUserFilterSuccess) {
      var _suspended = filter.suspended !== null ? filter.suspended : null;
      var _filterDate = filter.filterDate ? filter.filterDate.split("|") : [];
      var _userEmails = filter.userEmails ? filter.userEmails.split("|") : [];
      var _userFirstNames = filter.userFirstNames
        ? filter.userFirstNames.split("|")
        : [];
      var _userLastNames = filter.userLastNames
        ? filter.userLastNames.split("|")
        : [];
      var _userRoles = filter.userRoles ? filter.userRoles.split("|") : [];
      var _legalEntities = filter.legalEntities
        ? filter.legalEntities.split("|")
        : [];
      var _partySiteNumbers = filter.partySiteNumbers
        ? filter.partySiteNumbers.split("|")
        : [];
      var _columnOptions = filter.columnOptions
        ? filter.columnOptions.split("|")
        : [];

      //Remap column option
      if (_columnOptions && _columnOptions.length > 0) {
        columnOptions &&
          columnOptions.map((column) => {
            column.checked = 0;
            if (
              _columnOptions &&
              _columnOptions.includes(column.value.toString())
            )
              column.checked = 1;
            column.visible = column.checked == true ? 1 : 0;
          });
      }

      var userRole = Common.getUserRole();

      var userId = userRole === Role.labelCenter ? null : Common.getUserRole();

      var _filterData = {
        userId: userId,
        partySiteNumbers: _partySiteNumbers,
        filterDate: _filterDate,
        userEmails: _userEmails,
        userFirstNames: _userFirstNames,
        userLastNames: _userLastNames,
        userRoles: _userRoles,
        legalEntities: _legalEntities,
        suspended: _suspended,
        currentPage: this.props.roleAssignment.data.currentPage,
        pageSize: this.props.roleAssignment.data.pageSize
      };

      this.props.getUsersByUserFilter(_filterData);
      this.setState({ filterData: _filterData });
      this.props.filterOptionReset();
    }

    return (
      <div className="div-container">
        <RoleAssignmentFilterOption
          activeIndex={activeIndex}
          isFilterVisible={isFilterVisible}
          filter={filter}
          isGetUserFilterSuccess={isGetUserFilterSuccess}
          handleClick={this.handleClick}
          handleToggleFilter={this.handleToggleFilter}
        />

        <RoleAssignmentColumnOption
          activeIndex={activeIndex}
          columnOptions={columnOptions}
          isColumnOptVisible={isColumnOptVisible}
          columnSortable={columnSortable}
          handleClick={this.handleClick}
          handleToggleColumn={this.handleToggleColumn}
          handleRemoveColumn={this.handleRemoveColumn}
          handleAddColumn={this.handleAddColumn}
          handleColumnSortChange={this.handleColumnSortChange}
          handleDdColumnChange={this.handleDdColumnChange}
          handleApplyColumnOptions={this.handleApplyColumnOptions}
          handleClearColumnOptions={this.handleClearColumnOptions}
        />

        <Container fluid>
          <Grid>
            <Grid.Row columns={3}>
              <Grid.Column width={4}></Grid.Column>
              <Grid.Column width={6}></Grid.Column>
              <Grid.Column width={6} textAlign="right">
                <Button
                  style={CustomButtonStyle}
                  icon="filter"
                  compact
                  onClick={this.handleToggleFilter}
                ></Button>
                <Button
                  style={CustomButtonStyle}
                  content="Column Options"
                  compact
                  onClick={this.handleToggleColumn}
                ></Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Segment fluid>
            <Table striped celled sortable={columnSortable}>
              <Table.Header>
                <Table.Row>
                  {columnOptions &&
                    columnOptions.map(
                      (_column, idx) =>
                        _column.visible === 1 &&
                        _column.disabled === 0 && (
                          <Table.HeaderCell
                            key={idx}
                            sorted={column === _column.key ? direction : null}
                            onClick={this.handleSort(_column.key)}
                          >
                            {_column.text}
                          </Table.HeaderCell>
                        )
                    )}
                  <Table.HeaderCell>Remarks</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {data.data &&
                  data.data.map((d, idx) => (
                    <RoleAssignmentTableItem
                      key={idx}
                      data={d}
                      columnOptions={columnOptions}
                      roles={this.state.roleList}
                      legalEntities={legalEntityList}
                      onSaving={this.onSave.bind(this)}
                      onSaveLegalEntity={this.onSaveLegalEntity.bind(this)}
                      onEditing={this.onEdit.bind(this)}
                      onEditLegalEntity={this.onEditLegalEntity.bind(this)}
                      onSuspending={this.onToggleSuspend.bind(this)}
                      onToggleAuthorizeNDLabel={this.onToggleAuthorizeNDLabel.bind(
                        this
                      )}
                      onViewNote={this.onViewNote.bind(this)}
                      onViewClaims={this.onViewClaims.bind(this)}
                    />
                  ))}
              </Table.Body>
              <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell colSpan="20">
                    {data && data.totalPages > 0 ? (
                      <div className={"footer-container-left"}>
                        <Pagination
                          id="footer-pagination"
                          activePage={data.currentPage}
                          totalPages={data.totalPages}
                          boundaryRange={2}
                          onPageChange={(e, { activePage }) => {
                            var newfilterData = {
                              ...filterData,
                              currentPage: activePage,
                              pageSize: data.pageSize
                            };

                            this.handlePageChange(newfilterData);
                          }}
                        />
                      </div>
                    ) : (
                      <Message>No User Found</Message>
                    )}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          </Segment>
        </Container>

        {isShowSuspendedModal ? (
          <SuspendedModal
            isSaveNote={isSaveNote}
            isShowSuspendedModal={isShowSuspendedModal}
            handleSuspendedModal={this.handleSuspendedModal.bind(this)}
            onConfirmSuspend={this.onConfirmSuspend.bind(this)}
            suspendLoading={suspendLoading}
            data={selectedItem}
          />
        ) : null}

        {isShowAuthorizeNDLabelModal ? (
          <AuthorizeNDLabelModal
            isShowAuthorizeNDLabelModal={isShowAuthorizeNDLabelModal}
            handleAuthorizeNDLabelModal={this.handleAuthorizeNDLabelModal.bind(
              this
            )}
            onConfirmAuthorizeNDLabel={this.onConfirmAuthorizeNDLabel.bind(
              this
            )}
            authorizeNDLabelLoading={authorizeNDLabelLoading}
            data={selectedItem}
          />
        ) : null}

        {isShowNoteModal ? (
          <NoteModal
            isShowNoteModal={isShowNoteModal}
            handleNoteModal={this.handleNoteModal.bind(this)}
            data={selectedItem}
          />
        ) : null}

        {isShowViewClaimsModal ? (
          <ViewClaimsModal
            isShowViewClaimsModal={isShowViewClaimsModal}
            handleViewClaimsModal={this.handleViewClaimsModal.bind(this)}
            data={selectedItem}
            claimsType={claimsType}
          />
        ) : null}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    roleAssignment: state.roleAssignment
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllUsers: (currentPage, pageSize) =>
      dispatch(actions.getAllUsers(currentPage, pageSize)),
    getRoles: () => dispatch(actions.getRoles()),
    doEditRole: (params) => dispatch(actions.doEditRole(params)),
    doChangeRole: (params) => dispatch(actions.doChangeRole(params)),
    getLegalEntities: () => dispatch(actions.getLegalEntities()),
    doChangeLegalEntity: (params) =>
      dispatch(actions.doChangeLegalEntity(params)),
    doSuspendUser: (params) => dispatch(actions.doSuspendUser(params)),
    getUserFilter: () => dispatch(actions.getUserFilter()),
    setUserFilter: (data) => dispatch(actions.setUserFilter(data)),
    getUsersByUserFilter: (data) =>
      dispatch(actions.getUsersByUserFilter(data)),
    filterOptionReset: () => dispatch(actions.filterOptionReset()),
    updateData: (result) => dispatch(actions.updateData(result)),
    getActivePage: (page) => dispatch(commonActions.getActivePage(page)),
    authorizeNDLabel: (params) => dispatch(actions.authorizeNDLabel(params))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RoleAssignment);
