import React, { Component, Fragment } from "react";
import FooterBlock from "../../components/footer/footer-block";
import HeaderBlock from "../../components/header/header-block";
import { Card, Image, Container, Button, Radio } from "semantic-ui-react";
import { Redirect } from "react-router-dom";
import { dashboardSelectionCard } from "../../assets/js/common";
import imageUrlFieldEvaluation from "../../assets/img/field-evaluation.jpg";

class DashboardSelection extends Component {
  state = {
    hasSelected: false
  };
  handleChange = (e, { value }) => this.setState({ value });

  handleSelect = () => {
    // localStorage.setItem("selection", "field");
    // this.setState({ hasSelected: true });
    this.props.history.push("/home/");
  };

  render() {
    const { hasSelected } = this.state;
    document.getElementById("root").style.backgroundColor = "#0b3c61";

    if (hasSelected) return <Redirect to="/home/dashboard" />;

    return (
      <Fragment>
        <HeaderBlock />
        <Container style={dashboardSelectionCard}>
          <Card.Group centered>
            <Card>
              <Image
                src={imageUrlFieldEvaluation}
                wrapped
                ui={false}
                style={{ height: "220px" }}
              />
              <Card.Content>
                <Card.Header>Request field service</Card.Header>
                <Card.Description>
                  Request field service visits and check the status of current
                  field servicing.
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                <div className="ui">
                  <Radio
                    label="Set as default"
                    name="radioGroup"
                    value="field"
                    checked={this.state.value === "field"}
                    onChange={this.handleChange}
                    style={{ marginRight: "25%" }}
                  />
                  <Button primary onClick={this.handleSelect}>
                    Select
                  </Button>
                </div>
              </Card.Content>
            </Card>
            <Card>
              <Image
                src={imageUrlFieldEvaluation}
                wrapped
                ui={false}
                style={{ height: "220px" }}
              />
              <Card.Content>
                <Card.Header>Order labels</Card.Header>
                <Card.Description>
                  Order product labels and check the status of current label
                  orders.
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                <div className="ui">
                  <Radio
                    label="Set as default"
                    name="radioGroup"
                    value="order"
                    checked={this.state.value === "order"}
                    onChange={this.handleChange}
                    style={{ marginRight: "25%" }}
                  />
                  <Button primary onClick={this.handleSelect}>
                    Select
                  </Button>
                </div>
              </Card.Content>
            </Card>
          </Card.Group>
        </Container>
        <FooterBlock />
      </Fragment>
    );
  }
}

export default DashboardSelection;
