import {
  NOTIFICATIONS_FAILURE,
  NOTIFICATIONS_REQUEST,
  NOTIFICATIONS_SUCCESS,
  UPDATE_NOTIFICATIONS_FAILURE,
  UPDATE_NOTIFICATIONS_REQUEST,
  UPDATE_NOTIFICATIONS_SUCCESS,
  CART_ITEMS_REQUEST,
  CART_ITEMS_SUCCESS,
  CART_ITEMS_FAILURE,
  ORDER_CHANGE_PAGE,
  UPDATE_NOTIF_DATA
} from "./dashboard.type";
import _ from "lodash";

const initialState = {
  hasError: false,
  message: "",
  loading: false,
  data: null,
  isSuccessUpdateNotification: false,
  currentPage: 1,
  pageSize: 5,
  totalPages: 1
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case NOTIFICATIONS_REQUEST:
      return {
        ...state,
        hasError: false,
        loading: true
      };
    case NOTIFICATIONS_SUCCESS:
      var result = {
        ...state,
        hasError: false,
        data: action.payload,
        newNotifications:
          action.payload &&
          action.payload.data.filter((a) => !a.isViewed).length,
        totalPages: action.payload.totalPages,
        loading: false
      };

      var groupOrders = _(result.data.data)
        .groupBy((item) => item.requestNumber)
        .sortBy((group) => group.oracleNumber)
        .value();

      var newOrders = groupOrders.map((i) => {
        i[0].childItems = i.slice(1);
        return i[0];
      });

      result.data.data = newOrders;

      return result;
    case NOTIFICATIONS_FAILURE:
      return {
        ...state,
        message: action.payload,
        hasError: true,
        loading: false
      };
    case UPDATE_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        hasError: false,
        loading: true
      };
    case UPDATE_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        hasError: false,
        isSuccessUpdateNotification: action.payload.isSuccessful,
        loading: false
      };
    case UPDATE_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        message: action.payload,
        hasError: true,
        loading: false
      };
    case CART_ITEMS_REQUEST:
      return {
        ...state,
        hasError: false,
        loading: true
      };
    case CART_ITEMS_SUCCESS:
      return {
        ...state,
        hasError: false,
        cartData: action.payload,
        loading: false
      };
    case CART_ITEMS_FAILURE:
      return {
        ...state,
        message: action.payload,
        hasError: true,
        loading: false
      };
    case ORDER_CHANGE_PAGE:
      return {
        ...state,
        currentPage: action.payload.currentPage
      };
    case UPDATE_NOTIF_DATA:
      return {
        ...state,
        data: { data: action.payload || initialState.data }
      };
    default:
      return state;
  }
};

export default dashboardReducer;
