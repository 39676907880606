export const FORM = "FORM";

export const FE_ORDER_DRAFT_REQUEST = "FE_ORDER_DRAFT_REQUEST";
export const FE_ORDER_DRAFT_FAILURE = "FE_ORDER_DRAFT_FAILURE";
export const FE_ORDER_DRAFT_SUCCESS = "FE_ORDER_DRAFT_SUCCESS";

export const FE_ORDER_DRAFT_BY_FILTER_REQUEST =
  "FE_ORDER_DRAFT_BY_FILTER_REQUEST";
export const FE_ORDER_DRAFT_BY_FILTER_FAILURE =
  "FE_ORDER_DRAFT_BY_FILTER_FAILURE";
export const FE_ORDER_DRAFT_BY_FILTER_SUCCESS =
  "FE_ORDER_DRAFT_BY_FILTER_SUCCESS";

export const FE_ORDER_HISTORY_BY_FILTER_BY_USER_REQUEST =
  "FE_ORDER_HISTORY_BY_FILTER_BY_USER_REQUEST";
export const FE_ORDER_HISTORY_BY_FILTER_BY_USER_FAILURE =
  "FE_ORDER_HISTORY_BY_FILTER_BY_USER_FAILURE";
export const FE_ORDER_HISTORY_BY_FILTER_BY_USER_SUCCESS =
  "FE_ORDER_HISTORY_BY_FILTER_BY_USER_SUCCESS";

export const FE_ORDER_DELETE_REQUEST = "FE_ORDER_DELETE_REQUEST";
export const FE_ORDER_DELETE_FAILURE = "FE_ORDER_DELETE_FAILURE";
export const FE_ORDER_DELETE_SUCCESS = "FE_ORDER_DELETE_SUCCESS";

export const FE_ORDER_CHANGE_PAGE = "ORDER_CHANGE_PAGE";
