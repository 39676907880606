import Applicant from './applicant/applicant-service';
import Auth from './auth/auth-service';
import Customer from './customer/customer-service';
import Dashboard from './dashboard/dashboard-service';
import LabelOrder from './labelOrder/label-order-service';
import LabelOrderCart from './label-order-cart/label-order-cart-service';
import LabelOrderCartItem from './labelOrderCartItem/label-order-cart-item-service';

import LabelCenter from './label-center/label-center-service';
import LabelSupplier from './label-supplier/label-supplier-service';
import Manufacturer from './manufacturer/manufacturer-service';
import Order from './order/order-service';
import UnitOfMeasurement from './unit-of-measurement/unit-of-measurement-service';
import User from './user/user-service';
import PartySiteNumber from './party-site-number/party-site-number-service';
import FieldEvaluation from './field-evaluation/field-evaluation-services';
import Role from './role/role-service';
import HelpMe from './help-me/help-me-service';
import HealthDashboard from './health-dashboard/health-dashboard-service';

const services = {
  Applicant,
  Auth,
  Customer,
  Dashboard,
  LabelCenter,
  LabelOrder,
  LabelOrderCart,
  LabelOrderCartItem,
  LabelSupplier,
  Manufacturer,
  Order,
  UnitOfMeasurement,
  User,
  PartySiteNumber,
  FieldEvaluation,
  Role,
  HelpMe,
  HealthDashboard,
};

export default services;
