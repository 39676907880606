import React, { Component } from "react";
import PropTypes from "prop-types";
import { Table } from "semantic-ui-react";

class Assumption extends Component {
  render() {
    const orders = this.props.orders;
    return (
      <Table attached>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell textAlign="center">ASSUMPTIONS</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell verticalAlign="top">
              <p>
                This quote is for the fire equipment services requested. Please
                note that the pricing provided is an estimate only, based upon
                the quantities you selected. The final invoice will reflect the
                actual testing conducted.
              </p>
              <p>
                All travel time and expenses are included in this quotation.
              </p>

              {/* TODO: Change this once added other products */}
              {orders && orders.length > 0 ? (
                orders.filter(o => o.productCategoryCode == "AIS").length ? (
                  <span>
                    <p>
                      <b>Aerial Inspection</b>
                    </p>
                    <ul>
                      <li>
                        UL offers an Annual Aerial Inspection Service, with
                        optional Non-Destructive Tests (NDT). All inspections
                        and tests are conducted in accordance with NFPA Standard
                        1911; “Standard for the Inspection, Maintenance, Testing
                        and Retirement of In-Service Automotive Emergency
                        Vehicles.”
                      </li>
                      <li>
                        The Annual Aerial Inspection Service includes, but is
                        not limited to, a load test, all operational tests,
                        water tests, and the hydraulic fluid analysis.
                      </li>
                      <li>
                        Non-Destructive Tests (NDT) when conducted in
                        conjunction with the Annual Aerial Inspection includes:
                        ultrasonic, magnetic particle, visual and dye-penetrant
                        inspection where applicable.
                      </li>
                      <li>
                        A level area with electricity and water should be
                        available for the inspection. UL PERSONNEL ARE NOT
                        AUTHORIZED TO OPERATE FIRE DEPARTMENT AERIALS. A trained
                        and qualified operator MUST be available during the
                        aerial testing.
                      </li>
                    </ul>
                  </span>
                ) : null
              ) : null}

              {orders && orders.length > 0 ? (
                orders.filter(o => o.productCategoryCode == "GLIS").length ? (
                  <span>
                    <p>
                      <b>Ground Ladder Inspection</b>
                    </p>
                    <ul>
                      <li>
                        Ground ladders are tested in accordance with NFPA
                        Standard 1932; “Standard on Use, Maintenance, and
                        Service Testing of In-Service Fire Department Ground
                        Ladders.”
                      </li>
                      <li>
                        UL Field Engineers are equipped to test up to 500 feet
                        of ground ladders per day.
                      </li>
                    </ul>
                  </span>
                ) : null
              ) : null}

              <p>
                <b>Report/Certificate</b>
              </p>
              <p>
                After completion of our work, a copy of our inspection and test
                Report will be provided to you. If the equipment is found to
                comply with the applicable requirements, UL will also issue a
                Certificate. Although a Certificate is not required by the
                aforementioned NFPA Standards, this service is provided by UL.
                If non-compliant items are found during an inspection, a
                re-inspection would be required in order to obtain a
                Certificate.
              </p>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  }
}

Assumption.propTypes = {
  orders: PropTypes.array.isRequired
};

export default Assumption;
