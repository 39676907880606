import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import * as actions from "./register.action";
import { Redirect } from "react-router-dom";

import {
  Button,
  Form,
  Container,
  Card,
  Header,
  Input,
  Checkbox,
  Message,
} from "semantic-ui-react";

import {
  validate,
  getError,
  validateField,
  createValidation,
} from "../../validator/validator";

import "../../assets/css/login.css";
import { registerCard } from "../../assets/js/common";
import HeaderBlock from "../../components/header/header-block";
import FooterBlock from "../../components/footer/footer-block";

import {
  required,
  email,
  mustMatch,
  minLength,
  maxLength,
  numberOnly,
  lettersOnly,
} from "../../validator/error-message";

import _ from "lodash";

const rules = [
  validate("firstName", "First Name", required, lettersOnly),
  validate("lastName", "Last Name", required, lettersOnly),
  validate("email", "Email", required, email),
  validate(
    "partySiteNumber",
    "Party Site Number",
    required,
    numberOnly,
    minLength(6),
    maxLength(10)
  ),
  validate("password", "Password", required),
  validate(
    "confirmPassword",
    "Confirm Password",
    required,
    mustMatch("password", "Password")
  ),
  validate("agreeToTerms", "Agree To Terms"),
];

class Register extends Component {
  handleChange = (e) => {
    let { form } = this.props.register;
    const newState = validateField(rules, form, e.target.name, e.target.value);
    this.props.form(newState);

    if (e.target.name === "partySiteNumber") {
      form.psnLoading = true;
      this.props.getPartySiteNumberDetails(e.target.value);
    }
  };

  handleCheck = (e) => {
    let { form } = this.props.register;
    const newState = validateField(
      rules,
      form,
      e.target.name,
      e.target.checked
    );

    this.props.form(this.checkSubmit(newState));
  };

  checkSubmit = (newState) => {
    const canSubmit = newState.agreeToTerms ? true : false;

    newState.canSubmit = canSubmit;

    return newState;
  };

  handleRegister = () => {
    const { form } = this.props.register;

    form.validationErrors = createValidation(form, rules);
    //console.log(form);
    if (Object.IsEmpty(form.validationErrors)) form.loading = true;
    return this.props.doRegister(
      _.omit(form, ["showErrors", "validationErrors"])
    );

    form.showErrors = true;
    this.props.form(form);

    return false;
  };

  getErrorFor(field) {
    const { form, data } = this.props.register;
    let errors = getError(field, form, data);
    return errors;
  }

  getPartySiteNumberDetails = (partySiteNumber) => {
    this.props.getPartySiteNumberDetails(partySiteNumber);
  };

  componentWillUnmount() {
    this.props.clear();
  }

  render() {
    document.getElementById("root").style.backgroundColor = "#0b3c61";

    let hasServerError = false;

    const { form, data } = this.props.register;

    if (data && data.errors) {
      hasServerError = true;
      form.loading = false;
    }

    return data.isSuccessful ? (
      <Redirect to="/" />
    ) : form.userId ? (
      <Redirect to="/" />
    ) : (
      <Fragment>
        <HeaderBlock />
        <Container style={registerCard}>
          <Card fluid>
            <Card.Content>
              <h2>Register</h2>
              <Header>
                Please fill out the form below to request access to our system.
              </Header>
              {hasServerError ? (
                <Message negative visible={hasServerError}>
                  <Message.Header>Registration failed</Message.Header>
                  {/* {data.data.map(error => (
                    <div>{error}</div>
                  ))} */}
                </Message>
              ) : (
                ""
              )}

              <Form
                size="large"
                style={{ marginTop: "20px" }}
                loading={form.loading || false}
              >
                <Form.Group widths="equal">
                  <Form.Field
                    id="form-input-control-psn"
                    control={Input}
                    label="* Party Site Number (PSN)"
                    placeholder="PSN"
                    name="partySiteNumber"
                    onChange={this.handleChange}
                    value={form.partySiteNumber || ""}
                    error={this.getErrorFor("partySiteNumber")}
                    autoComplete="off"
                    loading={form.psnLoading}
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field
                    fluid
                    id="form-input-control-first-name"
                    control={Input}
                    label="* First name"
                    name="firstName"
                    onChange={this.handleChange}
                    value={form.firstName || ""}
                    placeholder="First name"
                    autoComplete="off"
                    error={this.getErrorFor("firstName")}
                  />
                  <Form.Field
                    fluid
                    id="form-input-control-last-name"
                    control={Input}
                    label="* Last name"
                    placeholder="Last name"
                    name="lastName"
                    onChange={this.handleChange}
                    value={form.lastName || ""}
                    error={this.getErrorFor("lastName")}
                    autoComplete="off"
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field
                    id="form-input-control-email-address"
                    control={Input}
                    label="* Email Address"
                    placeholder="Email Address"
                    name="email"
                    type="Email"
                    onChange={this.handleChange}
                    value={form.email || ""}
                    error={this.getErrorFor("email")}
                    autoComplete="off"
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field
                    id="form-input-control-password"
                    control={Input}
                    label="* Password"
                    placeholder="Password"
                    name="password"
                    onChange={this.handleChange}
                    value={form.password || ""}
                    type="password"
                    error={this.getErrorFor("password")}
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field
                    id="form-input-control-confirm-password"
                    control={Input}
                    label="* Confirm Password"
                    placeholder="Confirm Password"
                    name="confirmPassword"
                    onChange={this.handleChange}
                    value={form.confirmPassword || ""}
                    error={this.getErrorFor("confirmPassword")}
                    type="password"
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Checkbox
                    id="form-checkbox-control-agree-to-terms"
                    name="agreeToTerms"
                    control={Checkbox}
                    checked={form.agreeToTerms || false}
                    onChange={this.handleCheck}
                    label={{ children: "I agree to terms and services" }}
                  />
                </Form.Group>
                <Container textAlign="right">
                  <Button
                    type="button"
                    primary
                    onClick={this.handleRegister}
                    disabled={!form.canSubmit}
                    className="primary"
                  >
                    Request Access
                  </Button>
                </Container>
              </Form>
            </Card.Content>
          </Card>
        </Container>
        <FooterBlock />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    register: state.register,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doRegister: (param) => dispatch(actions.doRegister(param)),
    getPartySiteNumberDetails: (partySiteNumber) =>
      dispatch(actions.getPartySiteNumberDetails(partySiteNumber)),
    form: (result) => dispatch(actions.updateForm(result)),
    clear: () => dispatch(actions.clearRegister()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);
