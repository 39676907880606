export const CART_ITEMS_REQUEST = "CART_ITEMS_REQUEST";
export const CART_ITEMS_FAILURE = "CART_ITEMS_FAILURE";
export const CART_ITEMS_SUCCESS = "CART_ITEMS_SUCCESS";
export const MANUFACTURER_STANDARD_CART_ITEMS_SELECT_LABEL =
  "MANUFACTURER_STANDARD_CART_ITEMS_SELECT_LABEL";
export const MANUFACTURER_STANDARD_CART_ITEMS_SET_QUANTITY =
  "MANUFACTURER_STANDARD_CART_ITEMS_SET_QUANTITY";
export const MANUFACTURER_STANDARD_CART_ITEMS_REMOVE =
  "MANUFACTURER_STANDARD_CART_ITEMS_REMOVE";

export const MANUFACTURER_STANDARD_CART_UPDATE_MODEL =
  "MANUFACTURER_STANDARD_CART_UPDATE_MODEL";

export const MANUFACTURER_STANDARD_UPDATE_CART_SUCCESS =
  "MANUFACTURER_STANDARD_UPDATE_CART_SUCCESS";
export const MANUFACTURER_STANDARD_UPDATE_CART_REQUEST =
  "MANUFACTURER_STANDARD_UPDATE_CART_REQUEST";
export const MANUFACTURER_STANDARD_UPDATE_CART_FAILURE =
  "MANUFACTURER_STANDARD_UPDATE_CART_FAILURE";
export const MANUFACTURER_STANDARD_UPDATE_CART_RESET =
  "MANUFACTURER_STANDARD_UPDATE_CART_RESET";

export const MANUFACTURER_STANDARD_DELETE_CART_SUCCESS =
  "MANUFACTURER_STANDARD_DELETE_CART_SUCCESS";
export const MANUFACTURER_STANDARD_DELETE_CART_REQUEST =
  "MANUFACTURER_STANDARD_DELETE_CART_REQUEST";
export const MANUFACTURER_STANDARD_DELETE_CART_FAILURE =
  "MANUFACTURER_STANDARD_DELETE_CART_FAILURE";
export const MANUFACTURER_STANDARD_DELETE_CART_RESET =
  "MANUFACTURER_STANDARD_DELETE_CART_RESET";

export const CREATE_ORDER_REQUEST = "CREATE_ORDER_REQUEST";
export const CREATE_ORDER_FAILURE = "CREATE_ORDER_FAILURE";
export const CREATE_ORDER_SUCCESS = "CREATE_ORDER_SUCCESS";

export const CANCEL_ORDER_REQUEST = "CANCEL_ORDER_REQUEST";
export const CANCEL_ORDER_FAILURE = "CANCEL_ORDER_FAILURE";
export const CANCEL_ORDER_SUCCESS = "CANCEL_ORDER_SUCCESS";
export const CANCEL_ORDER_RESET = "CANCEL_ORDER_RESET";

export const RESET_CART_ITEMS_FORM = "RESET_CART_ITEMS_FORM";

export const FORM = "FORM";
export const CLEAR_FORM = "CLEAR_FORM";

export const SHIPPING_OPTIONS_REQUEST = "SHIPPING_OPTIONS_REQUEST";
export const SHIPPING_OPTIONS_FAILURE = "SHIPPING_OPTIONS_FAILURE";
export const SHIPPING_OPTIONS_SUCCESS = "SHIPPING_OPTIONS_SUCCESS";

export const SET_SHIPPING_OPTION = "SET_SHIPPING_OPTION";

export const CUSTOMER_DETAILS_REQUEST = "CUSTOMER_DETAILS_REQUEST";
export const CUSTOMER_DETAILS_FAILURE = "CUSTOMER_DETAILS_FAILURE";
export const CUSTOMER_DETAILS_SUCCESS = "CUSTOMER_DETAILS_SUCCESS";

export const GET_CUSTOMER_RELATIONS_REQUEST = "GET_CUSTOMER_RELATIONS_REQUEST";
export const GET_CUSTOMER_RELATIONS_SUCCESS = "GET_CUSTOMER_RELATIONS_SUCCESS";
export const GET_CUSTOMER_RELATIONS_FAILURE = "GET_CUSTOMER_RELATIONS_FAILURE";

export const APPLICANT_CONTACT_REQUEST = "APPLICANT_CONTACT_REQUEST";
export const APPLICANT_CONTACT_SUCCESS = "APPLICANT_CONTACT_SUCCESS";
export const APPLICANT_CONTACT_FAILURE = "APPLICANT_CONTACT_FAILURE";

export const UPDATE_CART_ITEMS_LIST = "UPDATE_CART_ITEMS_LIST";
export const UPDATE_SHIPPING_OPTIONS = "UPDATE_SHIPPING_OPTIONS";

export const LABEL_CENTER_COUNTRY_REQUEST = "LABEL_CENTER_COUNTRY_REQUEST";
export const LABEL_CENTER_COUNTRY_FAILURE = "LABEL_CENTER_COUNTRY_FAILURE";
export const LABEL_CENTER_COUNTRY_SUCCESS = "LABEL_CENTER_COUNTRY_SUCCESS";

export const UPDATE_FORMS = "UPDATE_FORMS";

export const UPDATE_LABEL_ORDER_TYPE = "UPDATE_LABEL_ORDER_TYPE";

export const GET_EXCHANGE_RATE_REQUEST = "GET_EXCHANGE_RATE_REQUEST";
export const GET_EXCHANGE_RATE_SUCCESS = "GET_EXCHANGE_RATE_SUCCESS";
export const GET_EXCHANGE_RATE_FAILURE = "GET_EXCHANGE_RATE_FAILURE";

export const UPDATE_REVIEW_CART = "UPDATE_REVIEW_CART";

export const GET_CUSTOMER_ECM_API_STATUS_REQUEST =
  "GET_CUSTOMER_ECM_API_STATUS_REQUEST";
export const GET_CUSTOMER_ECM_API_STATUS_SUCCESS =
  "GET_CUSTOMER_ECM_API_STATUS_SUCCESS";
export const GET_CUSTOMER_ECM_API_STATUS_FAILURE =
  "GET_CUSTOMER_ECM_API_STATUS_FAILURE";

export const UPLOAD_LABEL_ORDER_ATTACHMENT_TEMP_REQUEST =
  "UPLOAD_LABEL_ORDER_ATTACHMENT_TEMP_REQUEST";
export const UPLOAD_LABEL_ORDER_ATTACHMENT_TEMP_FAILURE =
  "UPLOAD_LABEL_ORDER_ATTACHMENT_TEMP_FAILURE";
export const UPLOAD_LABEL_ORDER_ATTACHMENT_TEMP_SUCCESS =
  "UPLOAD_LABEL_ORDER_ATTACHMENT_TEMP_SUCCESS";

export const GET_LABEL_ORDER_ATTACHMENT_TEMP_REQUEST =
  "GET_LABEL_ORDER_ATTACHMENT_TEMP_REQUEST";
export const GET_LABEL_ORDER_ATTACHMENT_TEMP_FAILURE =
  "GET_LABEL_ORDER_ATTACHMENT_TEMP_FAILURE";
export const GET_LABEL_ORDER_ATTACHMENT_TEMP_SUCCESS =
  "GET_LABEL_ORDER_ATTACHMENT_TEMP_SUCCESS";

export const DELETE_LABEL_ORDER_ATTACHMENT_TEMP_REQUEST =
  "DELETE_LABEL_ORDER_ATTACHMENT_TEMP_REQUEST";
export const DELETE_LABEL_ORDER_ATTACHMENT_TEMP_FAILURE =
  "DELETE_LABEL_ORDER_ATTACHMENT_TEMP_FAILURE";
export const DELETE_LABEL_ORDER_ATTACHMENT_TEMP_SUCCESS =
  "DELETE_LABEL_ORDER_ATTACHMENT_TEMP_SUCCESS";

export const GET_EXISTING_PO_NUMBER_REQUEST = "GET_EXISTING_PO_NUMBER_REQUEST";
export const GET_EXISTING_PO_NUMBER_FAILURE = "GET_EXISTING_PO_NUMBER_FAILURE";
export const GET_EXISTING_PO_NUMBER_SUCCESS = "GET_EXISTING_PO_NUMBER_SUCCESS";

export const DELETE_CURRENT_TAB = "DELETE_CURRENT_TAB";
export const UPDATE_BILL_TO_DATA = "UPDATE_BILL_TO_DATA";

export const GET_CREDIT_PROFILE_REQUEST = "GET_CREDIT_PROFILE_REQUEST";
export const GET_CREDIT_PROFILE_FAILURE = "GET_CREDIT_PROFILE_FAILURE";
export const GET_CREDIT_PROFILE_SUCCESS = "GET_CREDIT_PROFILE_SUCCESS";

export const CHECK_SERVICE_CONTRACT_REQUEST = "CHECK_SERVICE_CONTRACT_REQUEST";
export const CHECK_SERVICE_CONTRACT_FAILURE = "CHECK_SERVICE_CONTRACT_FAILURE";
export const CHECK_SERVICE_CONTRACT_SUCCESS = "CHECK_SERVICE_CONTRACT_SUCCESS";

export const CHECK_FILEVOLCCN_REQEUST = "CHECK_FILEVOLCCN_REQEUST";
export const CHECK_FILEVOLCCN_SUCCESS = "CHECK_FILEVOLCCN_SUCCESS";
export const CHECK_FILEVOLCCN_FAILED = "CHECK_FILEVOLCCN_FAILED";
