import React, { Component, Fragment } from "react";
import {
  Accordion,
  Divider,
  Form,
  Grid,
  Icon,
  Segment,
  TextArea
} from "semantic-ui-react";
import {
  CustomHeaderLabel,
  CustomHeaderBold
} from "../../../components/labels/labels";

class AdditionalComments extends Component {
  state = {
    activeIndex: 0
  };

  toggleDisplay = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  render() {
    const { activeIndex } = this.state;
    const { data, form, changeOrderCartValue, getErrorFor } = this.props;
    return (
      <Fragment>
        <Segment>
          <Accordion>
            <Accordion.Title
              active={activeIndex === 0}
              index={0}
              onClick={this.toggleDisplay}
            >
              <Grid columns={2}>
                <Grid.Column width={5}>
                  <Icon name="dropdown" />
                  <CustomHeaderLabel message="Additional Details" />
                </Grid.Column>
                <Grid.Column width={5}></Grid.Column>
              </Grid>
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 0}>
              <Divider />

              <Grid columns={2} stackable>
                <Grid.Row stretched>
                  <Grid.Column width={5} style={{ textAlign: "justify" }}>
                    <Grid>
                      <Grid.Column width={16}>
                        <i>Include additional comments to the order.</i>
                      </Grid.Column>
                    </Grid>
                  </Grid.Column>

                  <Grid.Column width={11}>
                    <Form>
                      <Grid>
                        <Grid.Row>
                          <Grid.Column width={14}>
                            <Form.Field
                              control={TextArea}
                              fluid
                              label="Additional comments"
                              placeholder="Additional comments"
                              name="comments"
                              value={form.comments || ""}
                              onChange={changeOrderCartValue}
                              maxLength="256"
                              error={getErrorFor("comments")}
                            />

                            {/* <CustomHeaderBold message="Additional comments" />

                            <Form.TextArea
                              placeholder="Additional comments"
                              name="instructions"
                              value={data.comments || ""}
                              onChange={(e, properties) => {
                                changeOrderCartValue(
                                  "comments",
                                  properties.value
                                );
                              }}
                              maxLength="256"
                              error={getErrorFor("comments")}
                            /> */}
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Form>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Accordion.Content>
          </Accordion>
        </Segment>
      </Fragment>
    );
  }
}

export default AdditionalComments;
