import React, { Fragment } from "react";
import { List } from "semantic-ui-react";
const FooterHomeBlock = () => {
  let year = new Date().getFullYear();
  return (
    <Fragment>
      <div style={{ textAlign: "right", marginTop: "7%" }}>
        <p>
          UL and the UL logo are trademarks of UL LLC &copy; {year}. All rights
          reserved.
        </p>
        <List horizontal link>
          <List.Item>
            <a
              href="https://www.ul.com/customer-resources/online-policies/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Online Policies
            </a>
          </List.Item>
          <List.Item>
            <a
              href="https://www.ul.com/customer-resources/online-policies/about-cookies/"
              target="_blank"
              rel="noopener noreferrer"
            >
              About Cookies
            </a>
          </List.Item>
          <List.Item>
            <a
              href="https://app-de.onetrust.com/app/#/webform/e348820f-6ffc-48f0-aa9f-2d80a79304fc"
              target="_blank"
              rel="noopener noreferrer"
            >
              Data Subject Access Request Portal
            </a>
          </List.Item>
        </List>
      </div>
    </Fragment>
  );
};

export default FooterHomeBlock;
