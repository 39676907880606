import Role from '../../enums/role';

export const viewOrdersRoleColumns = [
  {
    roleId: Role.applicant,
    columns: [
      'orderStatus',
      'reOrder',
      'cartOrderNo',
      'statusUpdateDateFormatted',
      'orderNo',
      'oracleOrderDate',
      'customerPONo',
      'customerContactName',
      'manufacturerAccountNumber',
      'manufacturerCompanyName',
      'manufacturerAddress',
      'fileNo',
      'labelType',
      'vendorLetter',
      'icon',
      'labelAgent',
    ],
  },
  {
    roleId: Role.manufacturer,
    columns: [
      'orderStatus',
      'reOrder',
      'cartOrderNo',
      'statusUpdateDateFormatted',
      'orderNo',
      'oracleOrderDate',
      'customerPONo',
      'customerContactName',
      'applicantAccountNo',
      'applicantCompanyName',
      'applicantAddress',
      'fileNo',
      'labelType',
      'vendorLetter',
      'icon',
      'labelAgent',
    ],
  },
  {
    roleId: Role.labelCenter,
    columns: [
      'orderStatus',
      'labelCenter',
      'cartOrderNo',
      'statusUpdateDateFormatted',
      'orderNo',
      'oracleOrderDate',
      'customerPONo',
      'customerContactName',
      'applicantAccountNo',
      'applicantCompanyName',
      'applicantAddress',
      'manufacturerAccountNumber',
      'manufacturerCompanyName',
      'manufacturerAddress',
      'shipToPartySiteNo',
      'fileNo',
      'labelType',
      'repushOrder',
      'emailSent',
      'vendorLetter',
      'vendorLetterSentToCustomer',
      'vendorLetterSentToSupplier',
      'letterDate',
      'icon',
      'labelAgent',
      'copyDetail',
    ],
  },
  {
    roleId: Role.labelManager,
    columns: [
      'orderStatus',
      'labelCenter',
      'cartOrderNo',
      'statusUpdateDateFormatted',
      'orderNo',
      'oracleOrderDate',
      'customerPONo',
      'customerContactName',
      'applicantAccountNo',
      'applicantCompanyName',
      'applicantAddress',
      'manufacturerAccountNumber',
      'manufacturerCompanyName',
      'manufacturerAddress',
      'shipToPartySiteNo',
      'fileNo',
      'labelType',
      'repushOrder',
      'emailSent',
      'vendorLetter',
      'vendorLetterSentToCustomer',
      'vendorLetterSentToSupplier',
      'letterDate',
      'icon',
      'labelAgent',
      'copyDetail',
    ],
  },
  {
    roleId: Role.fieldEngineerRO,
    columns: [
      'orderStatus',
      'labelCenter',
      'cartOrderNo',
      'statusUpdateDateFormatted',
      'orderNo',
      'oracleOrderDate',
      'customerPONo',
      'customerContactName',
      'applicantAccountNo',
      'applicantCompanyName',
      'applicantAddress',
      'manufacturerAccountNumber',
      'manufacturerCompanyName',
      'manufacturerAddress',
      'shipToPartySiteNo',
      'fileNo',
      'labelType',
      'repushOrder',
      'vendorLetter',
      'icon',
      'labelAgent',
    ],
  },
  {
    roleId: Role.printer,
    columns: [
      'orderStatus',
      'cartOrderNo',
      'statusUpdateDateFormatted',
      'orderNo',
      'oracleOrderDate',
      'customerPONo',
      'customerContactName',
      'applicantAccountNo',
      'applicantCompanyName',
      'applicantAddress',
      'manufacturerAccountNumber',
      'manufacturerCompanyName',
      'manufacturerAddress',
      'fileNo',
      'icon',
      'labelAgent',
    ],
  },
];
