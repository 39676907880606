export const FORM = "FORM";

export const CREATE_FE_ORDER_REQUEST = "CREATE_FE_ORDER_REQUEST";
export const CREATE_FE_ORDER_FAILURE = "CREATE_FE_ORDER_FAILURE";
export const CREATE_FE_ORDER_SUCCESS = "CREATE_FE_ORDER_SUCCESS";

export const ORACLE_ORDER_DETAILS_REQUEST = "ORACLE_ORDER_DETAILS_REQUEST";
export const ORACLE_ORDER_DETAILS_FAILURE = "ORACLE_ORDER_DETAILS_FAILURE";
export const ORACLE_ORDER_DETAILS_SUCCESS = "ORACLE_ORDER_DETAILS_SUCCESS";
export const ORACLE_ORDER_DETAILS_RESET = "ORACLE_ORDER_DETAILS_RESET";

export const FE_ORDER_DETAILS_REQUEST = "FE_ORDER_DETAILS_REQUEST";
export const FE_ORDER_DETAILS_FAILURE = "FE_ORDER_DETAILS_FAILURE";
export const FE_ORDER_DETAILS_SUCCESS = "FE_ORDER_DETAILS_SUCCESS";
export const FE_ORDER_DETAILS_RESET = "FE_ORDER_DETAILS_RESET";

export const QUOTE_SAVE_CHANGES_REQUEST = "QUOTE_SAVE_CHANGES_REQUEST";
export const QUOTE_SAVE_CHANGES_FAILURE = "QUOTE_SAVE_CHANGES_FAILURE";
export const QUOTE_SAVE_CHANGES_SUCCESS = "QUOTE_SAVE_CHANGES_SUCCESS";

export const GET_COUNTRIES_PRELIM_PROJECT_DETAILS_REQUEST =
  "GET_COUNTRIES_PRELIM_PROJECT_DETAILS_REQUEST";
export const GET_COUNTRIES_PRELIM_PROJECT_DETAILS_FAILURE =
  "GET_COUNTRIES_PRELIM_PROJECT_DETAILS_FAILURE";
export const GET_COUNTRIES_PRELIM_PROJECT_DETAILS_SUCCESS =
  "GET_COUNTRIES_PRELIM_PROJECT_DETAILS_SUCCESS";

export const GET_CITIES_PRELIM_PROJECT_DETAILS_REQUEST =
  "GET_CITIES_PRELIM_PROJECT_DETAILS_REQUEST";
export const GET_CITIES_PRELIM_PROJECT_DETAILS_FAILURE =
  "GET_CITIES_PRELIM_PROJECT_DETAILS_FAILURE";
export const GET_CITIES_PRELIM_PROJECT_DETAILS_SUCCESS =
  "GET_CITIES_PRELIM_PROJECT_DETAILS_SUCCESS";

export const GET_STATES_PRELIM_PROJECT_DETAILS_REQUEST =
  "GET_STATES_PRELIM_PROJECT_DETAILS_REQUEST";
export const GET_STATES_PRELIM_PROJECT_DETAILS_FAILURE =
  "GET_STATES_PRELIM_PROJECT_DETAILS_FAILURE";
export const GET_STATES_PRELIM_PROJECT_DETAILS_SUCCESS =
  "GET_STATES_PRELIM_PROJECT_DETAILS_SUCCESS";

export const GET_COUNTRIES_FINAL_PROJECT_DETAILS_REQUEST =
  "GET_COUNTRIES_FINAL_PROJECT_DETAILS_REQUEST";
export const GET_COUNTRIES_FINAL_PROJECT_DETAILS_FAILURE =
  "GET_COUNTRIES_FINAL_PROJECT_DETAILS_FAILURE";
export const GET_COUNTRIES_FINAL_PROJECT_DETAILS_SUCCESS =
  "GET_COUNTRIES_FINAL_PROJECT_DETAILS_SUCCESS";

export const GET_CITIES_FINAL_PROJECT_DETAILS_REQUEST =
  "GET_CITIES_FINAL_PROJECT_DETAILS_REQUEST";
export const GET_CITIES_FINAL_PROJECT_DETAILS_FAILURE =
  "GET_CITIES_FINAL_PROJECT_DETAILS_FAILURE";
export const GET_CITIES_FINAL_PROJECT_DETAILS_SUCCESS =
  "GET_CITIES_FINAL_PROJECT_DETAILS_SUCCESS";

export const GET_STATES_FINAL_PROJECT_DETAILS_REQUEST =
  "GET_STATES_FINAL_PROJECT_DETAILS_REQUEST";
export const GET_STATES_FINAL_PROJECT_DETAILS_FAILURE =
  "GET_STATES_FINAL_PROJECT_DETAILS_FAILURE";
export const GET_STATES_FINAL_PROJECT_DETAILS_SUCCESS =
  "GET_STATES_FINAL_PROJECT_DETAILS_SUCCESS";

export const GET_COUNTRIES_PRELIM_ENGR_DETAILS_REQUEST =
  "GET_COUNTRIES_PRELIM_ENGR_DETAILS_REQUEST";
export const GET_COUNTRIES_PRELIM_ENGR_DETAILS_FAILURE =
  "GET_COUNTRIES_PRELIM_ENGR_DETAILS_FAILURE";
export const GET_COUNTRIES_PRELIM_ENGR_DETAILS_SUCCESS =
  "GET_COUNTRIES_PRELIM_ENGR_DETAILS_SUCCESS";

export const GET_CITIES_PRELIM_ENGR_DETAILS_REQUEST =
  "GET_CITIES_PRELIM_ENGR_DETAILS_REQUEST";
export const GET_CITIES_PRELIM_ENGR_DETAILS_FAILURE =
  "GET_CITIES_PRELIM_ENGR_DETAILS_FAILURE";
export const GET_CITIES_PRELIM_ENGR_DETAILS_SUCCESS =
  "GET_CITIES_PRELIM_ENGR_DETAILS_SUCCESS";

export const GET_STATES_PRELIM_ENGR_DETAILS_REQUEST =
  "GET_STATES_PRELIM_ENGR_DETAILS_REQUEST";
export const GET_STATES_PRELIM_ENGR_DETAILS_FAILURE =
  "GET_STATES_PRELIM_ENGR_DETAILS_FAILURE";
export const GET_STATES_PRELIM_ENGR_DETAILS_SUCCESS =
  "GET_STATES_PRELIM_ENGR_DETAILS_SUCCESS";

export const GET_COUNTRIES_FINAL_ENGR_DETAILS_REQUEST =
  "GET_COUNTRIES_FINAL_ENGR_DETAILS_REQUEST";
export const GET_COUNTRIES_FINAL_ENGR_DETAILS_FAILURE =
  "GET_COUNTRIES_FINAL_ENGR_DETAILS_FAILURE";
export const GET_COUNTRIES_FINAL_ENGR_DETAILS_SUCCESS =
  "GET_COUNTRIES_FINAL_ENGR_DETAILS_SUCCESS";

export const GET_CITIES_FINAL_ENGR_DETAILS_REQUEST =
  "GET_CITIES_FINAL_ENGR_DETAILS_REQUEST";
export const GET_CITIES_FINAL_ENGR_DETAILS_FAILURE =
  "GET_CITIES_FINAL_ENGR_DETAILS_FAILURE";
export const GET_CITIES_FINAL_ENGR_DETAILS_SUCCESS =
  "GET_CITIES_FINAL_ENGR_DETAILS_SUCCESS";

export const GET_STATES_FINAL_ENGR_DETAILS_REQUEST =
  "GET_STATES_FINAL_ENGR_DETAILS_REQUEST";
export const GET_STATES_FINAL_ENGR_DETAILS_FAILURE =
  "GET_STATES_FINAL_ENGR_DETAILS_FAILURE";
export const GET_STATES_FINAL_ENGR_DETAILS_SUCCESS =
  "GET_STATES_FINAL_ENGR_DETAILS_SUCCESS";

export const GET_COUNTRIES_AHJ_REQUEST = "GET_COUNTRIES_AHJ_REQUEST";
export const GET_COUNTRIES_AHJ_FAILURE = "GET_COUNTRIES_AHJ_FAILURE";
export const GET_COUNTRIES_AHJ_SUCCESS = "GET_COUNTRIES_AHJ_SUCCESS";

export const GET_CITIES_AHJ_REQUEST = "GET_CITIES_AHJ_REQUEST";
export const GET_CITIES_AHJ_FAILURE = "GET_CITIES_AHJ_FAILURE";
export const GET_CITIES_AHJ_SUCCESS = "GET_CITIES_AHJ_SUCCESS";

export const GET_STATES_AHJ_REQUEST = "GET_STATES_AHJ_REQUEST";
export const GET_STATES_AHJ_FAILURE = "GET_STATES_AHJ_FAILURE";
export const GET_STATES_AHJ_SUCCESS = "GET_STATES_AHJ_SUCCESS";

export const CLEAR = "CLEAR";

export const UPDATE_PPE_SELECTION = "UPDATE_PPE_SELECTION";
export const UPDATE_TRAINING_SELECTION = "UPDATE_TRAINING_SELECTION";
export const FILL_PRELIM_SITE_DETAILS = "FILL_PRELIM_SITE_DETAILS";
export const FILL_FINAL_SITE_DETAILS = "FILL_FINAL_SITE_DETAILS";
export const FILL_SCHEDULING_DETAILS = "FILL_SCHEDULING_DETAILS";

export const GET_APPOINTMENTS_REQUEST = "GET_APPOINTMENTS_REQUEST";
export const GET_APPOINTMENTS_FAILURE = "GET_APPOINTMENTS_FAILURE";
export const GET_APPOINTMENTS_SUCCESS = "GET_APPOINTMENTS_SUCCESS";

export const UPDATE_ISSUCCESS = "UPDATE_ISSUCCESS";

export const UPLOAD_ATTACHMENT_REQUEST = "UPLOAD_ATTACHMENT_REQUEST";
export const UPLOAD_ATTACHMENT_FAILURE = "UPLOAD_ATTACHMENT_FAILURE";
export const UPLOAD_ATTACHMENT_SUCCESS = "UPLOAD_ATTACHMENT_SUCCESS";

export const UPLOAD_MULTI_SITE_ATTACHMENT_REQUEST =
  "UPLOAD_MULTI_SITE_ATTACHMENT_REQUEST";
export const UPLOAD_MULTI_SITE_ATTACHMENT_FAILURE =
  "UPLOAD_MULTI_SITE_ATTACHMENT_FAILURE";
export const UPLOAD_MULTI_SITE_ATTACHMENT_SUCCESS =
  "UPLOAD_MULTI_SITE_ATTACHMENT_SUCCESS";
