export const LABEL_ORDER_CART_REVIEW_CHECK_OUT_REQUEST =
  "LABEL_ORDER_CART_REVIEW_CHECK_OUT_REQUEST";
export const LABEL_ORDER_CART_REVIEW_CHECK_OUT_SUCCESS =
  "LABEL_ORDER_CART_REVIEW_CHECK_OUT_SUCCESS";
export const LABEL_ORDER_CART_REVIEW_CHECK_OUT_FAILURE =
  "LABEL_ORDER_CART_REVIEW_CHECK_OUT_FAILURE";

export const LABEL_ORDER_CART_REVIEW_CONFIRM_CHECK_OUT_REQUEST =
  "LABEL_ORDER_CART_REVIEW_CONFIRM_CHECK_OUT_REQUEST";
export const LABEL_ORDER_CART_REVIEW_CONFIRM_CHECK_OUT_ACCEPT =
  "LABEL_ORDER_CART_REVIEW_CONFIRM_CHECK_OUT_ACCEPT";
export const LABEL_ORDER_CART_REVIEW_CONFIRM_CHECK_OUT_REJECT =
  "LABEL_ORDER_CART_REVIEW_CONFIRM_CHECK_OUT_REJECT";

export const LABEL_ORDER_CART_REVIEW_GET_CUSTOMER_INFO_REQUEST =
  "LABEL_ORDER_CART_REVIEW_GET_CUSTOMER_INFO_REQUEST";
export const LABEL_ORDER_CART_REVIEW_GET_CUSTOMER_INFO_SUCCESS =
  "LABEL_ORDER_CART_REVIEW_GET_CUSTOMER_INFO_SUCCESS";
export const LABEL_ORDER_CART_REVIEW_GET_CUSTOMER_INFO_FAILURE =
  "LABEL_ORDER_CART_REVIEW_GET_CUSTOMER_INFO_FAILURE";

export const LABEL_ORDER_CART_REVIEW_GET_LABEL_ORDER_CART_REQUEST =
  "LABEL_ORDER_CART_REVIEW_GET_LABEL_ORDER_CART_REQUEST";
export const LABEL_ORDER_CART_REVIEW_GET_LABEL_ORDER_CART_SUCCESS =
  "LABEL_ORDER_CART_REVIEW_GET_LABEL_ORDER_CART_SUCCESS";
export const LABEL_ORDER_CART_REVIEW_GET_LABEL_ORDER_CART_FAILURE =
  "LABEL_ORDER_CART_REVIEW_GET_LABEL_ORDER_CART_FAILURE";

export const LABEL_ORDER_CART_REVIEW_INITIALIZE =
  "LABEL_ORDER_CART_REVIEW_INITIALIZE";

export const LABEL_ORDER_CART_REVIEW_REMOVE_CHECK_OUT_MESSAGE =
  "LABEL_ORDER_CART_REVIEW_REMOVE_CHECK_OUT_MESSAGE";