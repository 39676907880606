import * as types from "./new-quote-details.type";
import api from "../../../../services/api";

const dispatchRequest = (obj, type) => {
  return {
    data: obj,
    type: type
  };
};

export const createFieldServiceOrder = (data) => {
  return async (dispatch) => {
    dispatch(dispatchRequest(null, types.CREATE_FIELD_SERVICE_REQUEST));

    return await api.FieldEvaluation.createFieldServiceOrder(data)
      .then((result) => {
        dispatch(dispatchRequest(result, types.CREATE_FIELD_SERVICE_SUCCESS));
        return result;
      })
      .catch((result) => {
        dispatch(dispatchRequest(result, types.CREATE_FIELD_SERVICE_FAILURE));
      });
  };
};

export const updateFieldServiceOrder = (data) => {
  return async (dispatch) => {
    dispatch(dispatchRequest(null, types.UPDATE_FIELD_SERVICE_REQUEST));

    return await api.FieldEvaluation.updateFieldServiceOrder(data)
      .then((result) => {
        dispatch(dispatchRequest(result, types.UPDATE_FIELD_SERVICE_SUCCESS));
        return result;
      })
      .catch((result) => {
        dispatch(dispatchRequest(result, types.UPDATE_FIELD_SERVICE_FAILURE));
      });
  };
};

export const updateOrderType = (data) => {
  return async (dispatch) => {
    dispatch(dispatchRequest(null, types.FE_UPDATE_ORDER_TYPE_REQUEST));
    return await api.FieldEvaluation.updateOrderType(data)
      .then((result) => {
        dispatch(dispatchRequest(result, types.FE_UPDATE_ORDER_TYPE_SUCCESS));
        return result;
      })
      .catch((result) => {
        dispatch(
          dispatchRequest(
            result.response && result.response.data,
            types.FE_UPDATE_ORDER_TYPE_FAILURE
          )
        );
      });
  };
};

export const getOrderDetails = (orderNumber) => {
  return async (dispatch) => {
    dispatch(dispatchRequest(null, types.QUOTE_DETAILS_REQUEST));
    return await api.FieldEvaluation.getOrderDetails(orderNumber)
      .then((result) => {
        dispatch(dispatchRequest(result, types.QUOTE_DETAILS_SUCCESS));
        return result;
      })
      .catch((result) => {
        dispatch(
          dispatchRequest(
            result.response && result.response.data,
            types.QUOTE_DETAILS_FAILURE
          )
        );
      });
  };
};

export const renderDynamicForm = (count, isSameDayVisit) => {
  return async (dispatch) => {
    dispatch(
      dispatchRequest(
        { count: count, isSameDayVisit: isSameDayVisit },
        types.RENDER_DYNAMIC_FORM
      )
    );
  };
};

export const updateForm = (result) => {
  return (dispatch) => dispatch(dispatchRequest(result, types.FORM));
};

export const clearFEOrder = () => (dispatch) =>
  dispatch(dispatchRequest({}, types.CLEAR));
