import * as types from "./cancel-order.type";
import api from "../../../services/api";

export const cancelOrderResult = (obj, type) => {
  return {
    data: obj,
    type: type
  };
};

export const getInProgressOrders = (currentPage, pageSize, requestNumber) => {
  return async (dispatch) => {
    await api.Order.getInProgressOrders(currentPage, pageSize, requestNumber)
      .then((result) => {
        dispatch(cancelOrderResult(result, types.CANCEL_ORDER_REQUEST));
      })
      .catch((result) => {
        dispatch(cancelOrderResult(result, types.CANCEL_ORDER_FAILURE));
      });
  };
};

export const doCancelOrder = (param) => {
  return async (dispatch) => {
    return await api.Order.cancelInProgressOrder(param)
      .then((result) => {
        dispatch(cancelOrderResult(result, types.CANCEL_ORDER_PROCESS));
        return result;
      })
      .catch((result) => {
        dispatch(cancelOrderResult(result, types.CANCEL_ORDER_FAILURE));
      });
  };
};

export const doCancelOrderRequesting = (param) => {
  return (dispatch) =>
    dispatch(cancelOrderResult(param, types.CANCEL_ORDER_PROCESS_REQUEST));
};

export const notificationCount = () => {
  return (dispatch) =>
    dispatch(cancelOrderResult({}, types.NOTIFICATION_COUNT));
};

export const clearCancelOrder = () => (dispatch) =>
  dispatch(cancelOrderResult({}, types.CLEAR));

export const updateData = (result) => {
  return (dispatch) =>
    dispatch({ type: types.CANCEL_ORDER_UPDATE, payload: result });
};

export const doGetItem = (orderId, fileNumber) => {
  return async (dispatch) => {
    return await api.Order.getOrderItem(orderId, fileNumber)
    .then((result) => {
      dispatch(cancelOrderResult(result, types.GET_ITEM_SUCCESS));
      return result;
    })
    .catch((result) => {
      dispatch(cancelOrderResult(result, types.GET_ITEM_FAILURE));
    });
  };
};

export const doClearItemDetails = () => (dispatch) =>
  dispatch(cancelOrderResult(null, types.CLEAR_ITEM_DETAILS));

export const doCancelOrderItem = (param) => {
  return async (dispatch) => {
    return await api.Order.cancelOrderItem(param)
      .then((result) => {
        dispatch(cancelOrderResult(result, types.CANCEL_ORDER_ITEM_SUCCESS));
        return result;
      })
      .catch((result) => {
        dispatch(cancelOrderResult(result, types.CANCEL_ORDER_ITEM_FAILURE));
      });
  };
};
