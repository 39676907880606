import React, { Component, Fragment } from "react";
import {
  Accordion,
  Divider,
  Dropdown,
  Form,
  Grid,
  Icon,
  Input,
  Segment
} from "semantic-ui-react";
import { CustomHeaderLabel } from "../../../components/labels/labels";

class LabelOrderProduction extends Component {
  state = {
    activeIndex: 0
  };

  toggleDisplay = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  render() {
    const { activeIndex } = this.state;
    const {
      data,
      form,
      getErrorFor,
      accountNumber,
      accountNumberShipTo,
      IsAccntEmpty,
      isAccntShiptToEmpty,
      isLoadingAccountNUmbers
    } = this.props;
    const {
      labelCenters,
      loadingLabelCenters,
      labelSuppliers,
      loadingLabelSuppliers,
      changeOrderCartValue,
      changeOrderCartDropdownValue,
      loadingAccountNumbersOptions,
      loadingAccountNumbersShipToOptions,
      changeAccountNumberValue,
      changeAccountNumShipTOValue,
      customerInformationsOptions,
      customerInformationsShipToOptions,
      isCompanyEnable,
      isCompanyShipToEnable,
      isAddressEnable,
      isAddressShipToEnable
    } = this.props;
    console.log(accountNumber);
    return (
      <Fragment>
        <Segment>
          <Accordion>
            <Accordion.Title
              active={activeIndex === 0}
              index={0}
              onClick={this.toggleDisplay}
            >
              <Grid columns={2}>
                <Grid.Column width={5}>
                  <Icon name="dropdown" />
                  <CustomHeaderLabel message="Label Production Options" />
                </Grid.Column>
                <Grid.Column width={5}></Grid.Column>
              </Grid>
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 0}>
              <Divider />

              <Grid columns={2} stackable>
                <Grid.Row stretched>
                  <Grid.Column width={5} style={{ textAlign: "justify" }}>
                    <Grid>
                      <Grid.Column width={16}>
                        <i>
                          Adjust your preferences for how the labels are
                          produced. If you have questions, please contact us.
                        </i>
                      </Grid.Column>
                    </Grid>
                  </Grid.Column>

                  <Grid.Column width={11}>
                    <Form>
                      <Grid>
                        <Grid.Row>
                          <Grid.Column width={14}>
                            <Form.Field
                              control={Input}
                              fluid
                              label="* Customer Purchase Order Number"
                              placeholder="PO#"
                              name="customerPoNumber"
                              onChange={changeOrderCartValue}
                              value={form.customerPoNumber || ""}
                              error={getErrorFor("customerPoNumber")}
                              autoComplete="off"
                            />
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <Grid.Column width={14}>
                            <Form.Field
                              control={Dropdown}
                              label="* UL Solutions Label Center"
                              placeholder="Choose from available options"
                              name="labelCenterId"
                              fluid
                              selection
                              loading={loadingLabelCenters}
                              options={labelCenters || []}
                              disabled={
                                !labelCenters || labelCenters.length === 0
                              }
                              value={form.labelCenterId}
                              onChange={(e, properties) => {
                                changeOrderCartDropdownValue(e, properties);
                              }}
                              error={getErrorFor("labelCenterId")}
                              autoComplete="off"
                              selectOnBlur={false}
                            />
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <Grid.Column width={14}>
                            <Form.Field>
                              <label>
                                Instructions for the label center (optional)
                              </label>
                            </Form.Field>

                            <Form.TextArea
                              placeholder="Instructions"
                              name="instructions"
                              value={form.instructions || ""}
                              onChange={changeOrderCartValue}
                            />
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <Grid.Column width={14}>
                            <Form.Field
                              control={Dropdown}
                              label="* Authorized Label Supplier"
                              placeholder="Choose Label Supplier"
                              name="labelSupplierId"
                              fluid
                              selection
                              search={true}
                              loading={loadingLabelSuppliers}
                              options={labelSuppliers || []}
                              disabled={
                                !labelSuppliers || labelSuppliers.length === 0
                              }
                              value={form.labelSupplierId}
                              onChange={(e, properties) => {
                                changeOrderCartDropdownValue(e, properties);
                              }}
                              error={getErrorFor("labelSupplierId")}
                              autoComplete="off"
                              selectOnBlur={false}
                            />
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <Grid.Column width={14}>
                            <span hidden={IsAccntEmpty}>
                              <Form.Field
                                control={Dropdown}
                                compact
                                selection
                                label="* Bill To Account No"
                                placeholder="Select Bill To Account No"
                                name="accntNumber"
                                disabled={isLoadingAccountNUmbers}
                                options={loadingAccountNumbersOptions}
                                onChange={(e, properties) => {
                                  changeOrderCartDropdownValue(e, properties);
                                  changeAccountNumberValue(properties);
                                }}
                                value={form.billToAccountNo}
                                error={getErrorFor("billToAccountNo")}
                                selectOnBlur={false}
                              />
                            </span>

                            <span hidden={!IsAccntEmpty}>
                              <Form.Field
                                control={Input}
                                fluid
                                label="* Bill To Account No"
                                placeholder="Bill To Account No"
                                name="billToAccountNo"
                                onChange={changeOrderCartValue}
                                value={form.billToAccountNo || ""}
                                error={getErrorFor("billToAccountNo")}
                                autoComplete="off"
                              />
                            </span>
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <Grid.Column width={14}>
                            <Form.Field
                              control={Input}
                              fluid
                              readOnly={!isCompanyEnable}
                              label="* Bill To Company"
                              placeholder="Bill To Company"
                              name="billToCompany"
                              onChange={changeOrderCartValue}
                              value={form.billToCompany || ""}
                              error={getErrorFor("billToCompany")}
                              autoComplete="off"
                            />
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <Grid.Column width={14}>
                            <Divider fitted hidden />
                            <Form.Field
                              control={Input}
                              fluid
                              readOnly={!isAddressEnable}
                              label="* Bill To Address"
                              placeholder="Bill To Address"
                              name="billToAddress"
                              onChange={changeOrderCartValue}
                              value={form.billToAddress || ""}
                              error={getErrorFor("billToAddress")}
                              autoComplete="off"
                            />
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <Grid.Column width={14}>
                            <Divider fitted hidden />
                            <span hidden={!IsAccntEmpty}>
                              <Form.Field
                                control={Input}
                                fluid
                                label="* Bill To Contact Name"
                                placeholder="Bill To Contact Name"
                                name="billToContactName"
                                onChange={changeOrderCartValue}
                                value={form.billToContactName || ""}
                                error={getErrorFor("billToContactName")}
                                autoComplete="off"
                              />
                            </span>
                            <span hidden={IsAccntEmpty}>
                              <Form.Field
                                control={Dropdown}
                                compact
                                selection
                                label="* Bill To Contact Name"
                                disabled={!form.billToAccountNo}
                                name="billToContactName"
                                placeholder="Bill To Contact Name"
                                options={customerInformationsOptions}
                                onChange={(e, properties) => {
                                  changeOrderCartDropdownValue(e, properties);
                                  // this.handleCustomerInfoDorpDownChange(
                                  //   properties.value
                                  // );
                                }}
                                value={form.billToContactName || ""}
                                error={getErrorFor("billToContactName")}
                                selectOnBlur={false}
                              />
                            </span>
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <Grid.Column width={14}>
                            <span hidden={isAccntShiptToEmpty}>
                              <Form.Field
                                control={Dropdown}
                                compact
                                selection
                                disabled={isLoadingAccountNUmbers}
                                label="* Manufacturer Account Number"
                                placeholder="Select Manufacturer Account Number"
                                name="manufacturerAccountNumber"
                                options={loadingAccountNumbersShipToOptions}
                                onChange={(e, properties) => {
                                  changeOrderCartDropdownValue(e, properties);
                                  changeAccountNumShipTOValue(properties);
                                }}
                                value={form.manufacturerAccountNumber}
                                error={getErrorFor("manufacturerAccountNumber")}
                                selectOnBlur={false}
                              />
                            </span>

                            <span hidden={!isAccntShiptToEmpty}>
                              <Form.Field
                                control={Input}
                                fluid
                                label="* Manufacturer Account Number"
                                placeholder="Manufacturer Account Number"
                                name="manufacturerAccountNumber"
                                onChange={changeOrderCartValue}
                                value={form.manufacturerAccountNumber || ""}
                                error={getErrorFor("manufacturerAccountNumber")}
                                autoComplete="off"
                              />
                            </span>
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <Grid.Column width={14}>
                            <Divider fitted hidden />
                            <Form.Field
                              control={Input}
                              fluid
                              readOnly={!isCompanyShipToEnable}
                              label="* Manufacturer Name"
                              placeholder="Manufacturer Name"
                              name="manufacturerName"
                              onChange={changeOrderCartValue}
                              value={form.manufacturerName || ""}
                              error={getErrorFor("manufacturerName")}
                              autoComplete="off"
                            />
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <Grid.Column width={14}>
                            <Divider fitted hidden />
                            <Form.Field
                              control={Input}
                              fluid
                              readOnly={!isAddressShipToEnable}
                              label="* Manufacturer Address"
                              placeholder="Manufacturer Address"
                              name="manufacturerAddress"
                              onChange={changeOrderCartValue}
                              value={form.manufacturerAddress || ""}
                              error={getErrorFor("manufacturerAddress")}
                              autoComplete="off"
                            />
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                          <Grid.Column width={14}>
                            <span hidden={!isAccntShiptToEmpty}>
                              <Form.Field
                                control={Input}
                                fluid
                                label="* Manufacturer Contact Name"
                                placeholder="Manufacturer Contact Name"
                                name="shipToContactName"
                                onChange={changeOrderCartValue}
                                value={form.shipToContactName || ""}
                                error={getErrorFor("shipToContactName")}
                                autoComplete="off"
                              />
                            </span>
                            <span hidden={isAccntShiptToEmpty}>
                              <Form.Field
                                control={Dropdown}
                                compact
                                selection
                                label="* Manufacturer Contact Name"
                                placeholder="Select Manufacturer Contact Name"
                                name="shipToContactName"
                                disabled={!form.manufacturerAccountNumber}
                                options={customerInformationsShipToOptions}
                                onChange={(e, properties) => {
                                  changeOrderCartDropdownValue(e, properties);
                                }}
                                value={form.shipToContactName || ""}
                                error={getErrorFor("shipToContactName")}
                                selectOnBlur={false}
                              />
                            </span>
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <Grid.Column width={14}>
                            <Divider fitted hidden />
                            <Form.Field
                              control={Input}
                              fluid
                              label="* Customer Email Address"
                              placeholder="Customer Email Address"
                              name="customerEmailAddress"
                              onChange={changeOrderCartValue}
                              value={form.customerEmailAddress || ""}
                              error={getErrorFor("customerEmailAddress")}
                              autoComplete="off"
                            />
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <Grid.Column width={14}>
                            <Divider fitted hidden />
                            <Form.Field
                              control={Input}
                              fluid
                              label="* Customer Phone Number"
                              placeholder="Customer Phone Number"
                              name="customerPhoneNumber"
                              onChange={changeOrderCartValue}
                              value={form.customerPhoneNumber || ""}
                              error={getErrorFor("customerPhoneNumber")}
                              autoComplete="off"
                            />
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <Grid.Column width={14}>
                            <Form.Field
                              control={Input}
                              fluid
                              label="Broker Information (Optional)"
                              placeholder="Broker Information"
                              name="brokerInformation"
                              onChange={changeOrderCartValue}
                              value={form.brokerInformation || ""}
                              error={getErrorFor("brokerInformation")}
                              autoComplete="off"
                            />
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Form>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Accordion.Content>
          </Accordion>
        </Segment>
      </Fragment>
    );
  }
}

export default LabelOrderProduction;
