import * as types from "./fe-admin-search.type";
import api from "../../../services/api";

const searchResult = (obj, type) => {
  return {
    type: type,
    data: obj
  };
};

export const getAllFeOrders = (
  currentPage,
  pageSize,
  accountNumber,
  requestNumber
) => {
  return async (dispatch) => {
    dispatch(searchResult(null, types.FE_ADMIN_SEARCH_REQUEST));
    await api.FieldEvaluation.getAllFeOrders(
      currentPage,
      pageSize,
      accountNumber,
      requestNumber
    )
      .then((result) => {
        dispatch(searchResult(result, types.FE_ADMIN_SEARCH_SUCCESS));
      })
      .catch((result) => {
        dispatch(
          searchResult(
            result.response && result.response.data,
            types.FE_ADMIN_SEARCH_FAILURE
          )
        );
      });
  };
};

export const getAllFeOrdersAdminSearch = (
  currentPage,
  pageSize,
  accountNumber,
  requestNumber,
  IsAdminSearch
) => {
  return async (dispatch) => {
    dispatch(searchResult(null, types.FE_ADMIN_SEARCH_REQUEST));
    await api.FieldEvaluation.getAllFeOrdersAdminsSearch(
      currentPage,
      pageSize,
      accountNumber,
      requestNumber,
      IsAdminSearch
    )
      .then((result) => {
        dispatch(searchResult(result, types.FE_ADMIN_SEARCH_SUCCESS));
      })
      .catch((result) => {
        dispatch(
          searchResult(
            result.response && result.response.data,
            types.FE_ADMIN_SEARCH_FAILURE
          )
        );
      });
  };
};
