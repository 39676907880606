import * as types from './existing-combination-labels.type';

const initialState = {
  hasError: false,
  hasMessage: false,
  message: '',
  loading: false,
  addToCartLoading: false,
  hasErrorAddToCart: false,
  data: null,
  total: 0,
  sortColumn: null,
  sortOrder: null,
  currentPage: 1,
  pageSize: 10000,
  totalPages: 1,
  itemNumber: null,
  description: null,
  articleNumber: null,
  isSelectedAll: false,
  itemNumberOptions: [],
  addItemForm: {
    showErrors: false,
    validationErrors: {},
  },
  form: {
    showErrors: false,
    validationErrors: {},
  },
  forms: [],
  itemList: [],
  listeeFileNumbers: [],
  isHologramCcnFetched: false,
  hologramCcns: [],
  labelSupplierPrinterEmails: [],
};

const existingCombinationLabelReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.COMBINATION_EXISTING_LABEL_INITIALIZE:
      return {
        ...state,
        loading: false,
        hasError: false,
        hasMessage: false,
        addItemForm: {
          showErrors: false,
          validationErrors: {},
        },
        form: {
          showErrors: false,
          validationErrors: {},
        },
        forms: [],
        itemList: [
          {
            id: 1,
            itemNumber: null,
            itemNumberOptions: [],
            labelDescription: null,
            quantity: null,
            listeeFileNumber: null,
            listeeFileNumbers: [],
            customerPartNumber: null,
            showErrors: false,
            validationErrors: {},
          },
        ],
      };
    case types.COMBINATION_ADD_EXISTING_LABEL_ORDER_TO_CART_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
        hasMessage: false,
      };
    case types.COMBINATION_ADD_EXISTING_LABEL_ORDER_TO_CART_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        hasMessage: true,
      };
    case types.COMBINATION_ADD_EXISTING_LABEL_ORDER_TO_CART_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
      };
    case types.COMBINATION_GET_EXISTING_LABEL_ORDER_REQUEST:
      return {
        ...state,
        hasError: false,
        loading: true,
      };
    case types.COMBINATION_GET_EXISTING_LABEL_ORDER_SUCCESS:
      let newStateOrderSuccess = {
        ...state,
        hasError: false,
        data: action.payload.response.data,
        totalPages: action.payload.response.totalPages,
        total: action.payload.response.total,
        loading: false,
      };

      if (action.payload.data.isCopied) {
        newStateOrderSuccess.itemList.forEach(listItem => {
          listItem.itemNumberOptions =
            action.payload.response.data &&
            action.payload.response.data.map(item => {
              return {
                key: item.id,
                text: item.itemNumber,
                value: item.itemNumber,
              };
            });
        });
      } else {
        var result = newStateOrderSuccess.itemList.find(
          d => d.id == action.payload.data.id
        );

        result.itemNumberOptions =
          action.payload.response.data &&
          action.payload.response.data.map(item => {
            return {
              key: item.id,
              text: item.itemNumber,
              value: item.itemNumber,
            };
          });

        newStateOrderSuccess.itemList.filter(
          d => d.id == action.payload.data.id
        )[0] = result;

        action.payload.data.fileNumber.indexOf(',') !== -1
          ? action.payload.data.fileNumber.split(',').map(item => {
              return newStateOrderSuccess.forms.push({
                showErrors: false,
                validationErrors: {},
                itemList: [
                  {
                    id: 1,
                    itemNumber: null,
                    itemNumberOptions: [],
                    labelDescription: null,
                    quantity: null,
                    listeeFileNumber: null,
                    listeeFileNumbers: [],
                    customerPartNumber: null,
                    showErrors: false,
                    validationErrors: {},
                  },
                ],
              });
            })
          : [action.payload.data.fileNumber].map(item => {
              return newStateOrderSuccess.forms.push({
                showErrors: false,
                validationErrors: {},
                itemList: [
                  {
                    id: 1,
                    itemNumber: null,
                    itemNumberOptions: [],
                    labelDescription: null,
                    quantity: null,
                    listeeFileNumber: null,
                    listeeFileNumbers: [],
                    customerPartNumber: null,
                    showErrors: false,
                    validationErrors: {},
                  },
                ],
              });
            });
      }

      return newStateOrderSuccess;
    case types.COMBINATION_GET_EXISTING_LABEL_ORDER_FAILURE:
      return {
        ...state,
        message: action.payload,
        hasError: true,
        loading: false,
      };
    case types.GET_COMBINATION_LABELS_BY_ITEM_NUMBER:
      var newStateFilter = {
        ...state,
      };

      var form =
        newStateFilter.forms && newStateFilter.forms.length > 0
          ? newStateFilter.forms[action.payload.formIndex] || {}
          : {};

      var result = newStateFilter.data.find(
        d => d.itemNumber == action.payload.itemNumber
      );

      var item = form.itemList.find(i => i.id == action.payload.id);

      item.itemNumber = result.itemNumber;
      item.labelDescription = result.labelDescription;
      item.customerPartNumber = result.customerPartNumber;
      item.showErrors = false;
      item.validationErrors = {};

      item.fileNumber = action.payload.fileNumber;
      item.volume = action.payload.volume;
      item.ccn = action.payload.ccn;
      item.labelAccount = action.payload.labelAccount;

      form.itemList.filter(i => i.id == action.payload.id)[0] = item;

      var newForms = [];

      newStateFilter.forms.forEach(f => {
        newForms.push(f);
      });

      newStateFilter.forms = newForms;

      return newStateFilter;
    case types.COMBINATION_GET_EXISTING_LABEL_ORDER_SORT:
      return {
        ...state,
        sortColumn: action.payload.sortColumn,
        sortOrder: action.payload.sortOrder,
      };
    case types.COMBINATION_GET_EXISTING_LABEL_ORDER_PAGINATE:
      return {
        ...state,
        currentPage: action.payload.currentPage,
        pageSize: action.payload.pageSize,
      };
    case types.COMBINATION_UPDATE_FILTER:
      const newState = {
        ...state,
      };

      newState[action.payload.name] = action.payload.value;
      return newState;
    case types.COMBINATION_EXISTING_LABEL_ORDER_SELECT_ALL:
      return {
        ...state,
      };
    case types.COMBINATION_EXISTING_LABEL_ORDER_SELECT:
      const selectNewState = { ...state };

      return selectNewState;
    case types.UPDATE_SELECTED_LABEL:
      return Object.assign({}, state, {
        forms: action.payload,
      });
    case types.UPDATE_COMBINATION_LABELS_FORM:
      return Object.assign({}, state, {
        form: action.payload,
      });
    case types.PUSH_SELECTED_LABEL:
      var stateAddedLabel = Object.assign({}, state, {});
      var newForm = stateAddedLabel.forms[action.payload.formIndex];

      newForm.itemList.push({
        id: newForm.itemList.length + 1,
        itemNumber: null,
        labelDescription: null,
        quantity: null,
        listeeFileNumber: null,
        listeeFileNumbers: newForm.itemList[0].listeeFileNumbers,
        itemNumberOptions: newForm.itemList[0].itemNumberOptions,
        customerPartNumber: null,
        showErrors: false,
        validationErrors: {},
      });
      return stateAddedLabel;
    case types.REMOVE_SELECTED_LABEL:
      let stateUpdatedLabels = Object.assign({}, state, {
        forms: action.payload.newForms,
      });

      if (
        stateUpdatedLabels.forms[action.payload.formIndex].itemList.length == 0
      ) {
        stateUpdatedLabels.forms[action.payload.formIndex].itemList.push({
          id: 1,
          itemNumber: null,
          itemNumberOptions: [],
          labelDescription: null,
          quantity: null,
          listeeFileNumber: null,
          listeeFileNumbers: stateUpdatedLabels.listeeFileNumbers.map(item => {
            return {
              key: item.id.toString(),
              text: item.listeeFileNo,
              value: item.listeeFileNo,
            };
          }),
          customerPartNumber: null,
          showErrors: false,
          validationErrors: {},
        });
      } else {
        stateUpdatedLabels.forms[action.payload.formIndex].itemList.forEach(
          (item, idx) => {
            item.id = idx + 1;
          }
        );
      }

      return stateUpdatedLabels;
    case types.UPDATE_ITEM_LIST:
      const { id, value, name } = action.payload;
      const newItemList = state.itemList.map(item => {
        if (item.id !== id) return item;

        const updatedState = { ...item };
        updatedState[name] = value;

        return updatedState;
      });

      return {
        ...state,
        itemList: newItemList,
      };
    case types.UPDATE_ATTACHMENT:
      var addedState = { ...state };
      let attachmentForm = addedState.forms[action.payload.formIndex];

      var item = attachmentForm.itemList.find(i => i.id == action.payload.id);
      item[action.payload.name] = action.payload.value;
      attachmentForm.itemList.filter(i => i.id == action.payload.id)[0] = item;

      return addedState;
    case types.SET_PRODUCT_COVERED_SUCCESS:
      return Object.assign({}, state, {
        selectedProductCovered: action.payload.result
          .map((item, index) => {
            return item.productCovered;
          })
          .join('/'),
      });
    case types.ITEM_NUMBER_OPTIONS_RESET:
      var stateOptions = { ...state };

      // stateOptions.itemNumberOptions = [];

      return stateOptions;
    case types.LISTEE_FILE_NUMBER_REQUEST:
      return {
        ...state,
        hasError: false,
        listeeFileNumbers: [],
      };
    case types.LISTEE_FILE_NUMBER_SUCCESS:
      let newStateListeeSuccess = {
        ...state,
        hasError: false,
      };

      newStateListeeSuccess.listeeFileNumbers = action.payload.result;

      if (action.payload.data.isCopied) {
        newStateListeeSuccess.itemList.forEach(listItem => {
          listItem.listeeFileNumbers =
            action.payload.result &&
            action.payload.result.map(item => {
              return {
                key: item.id.toString(),
                text: item.listeeFileNo,
                value: item.listeeFileNo,
              };
            });
        });
      } else {
        var result = newStateListeeSuccess.itemList.find(
          d => d.id == action.payload.data.id
        );

        result.listeeFileNumbers =
          action.payload.result &&
          action.payload.result.map(item => {
            return {
              key: item.id.toString(),
              text: item.listeeFileNo,
              value: item.listeeFileNo,
            };
          });

        newStateListeeSuccess.itemList.filter(
          d => d.id == action.payload.data.id
        )[0] = result;
      }

      return newStateListeeSuccess;
    case types.LISTEE_FILE_NUMBER_FAILURE:
      return {
        ...state,
        listeeFileNumbers: [],
        hasError: true,
      };
    case types.CANCEL_NEW_LINE:
      let newStateCancelLine = {
        ...state,
        hasError: false,
      };

      let formCancelLine = newStateCancelLine.forms[action.payload.formIndex];

      formCancelLine.itemList = formCancelLine.itemList.slice(
        0,
        formCancelLine.itemList.length - 1
      );

      return newStateCancelLine;
    case types.EXISTING_COMBINATION_BY_CART_ID_REQUEST:
      var stateRequest = Object.assign({}, state, {});

      stateRequest.itemList = [];
      return stateRequest;
    case types.EXISTING_COMBINATION_BY_CART_ID_SUCCESS:
      var stateRequestSuccess = Object.assign({}, state, {});

      action.payload.result.fileNumbers.indexOf(',') !== -1
        ? action.payload.result.fileNumbers.split(',').map(fileNumber => {
            var items =
              action.payload.result.combinationLabelOrderDetails.filter(
                c => c.fileNumber == fileNumber
              );
            var itemList = [];

            items.forEach((item, index) => {
              itemList.push({
                id: index + 1,
                itemNumber: item.itemNumber,
                labelDescription: item.description,
                quantity: item.quantity,
                listeeFileNumber: item.listeeFileNumber,
                listeeFileNumbers: [],
                itemNumberOptions: [],
                customerPartNumber: item.customerPartNumber,
                showErrors: false,
                validationErrors: {},
                fileName: item.fileName,
                existingLabelOrderCartItemId: item.labelOrderCartItemId,
                fileNumber: item.fileNumber,
                ccn: item.ccn,
                labelAccount: item.labelAccount,
                volume: item.volume,
              });
            });

            return stateRequestSuccess.forms.push({
              showErrors: false,
              validationErrors: {},
              itemList: itemList,
            });
          })
        : [action.payload.result.fileNumbers].map(fileNumber => {
            var items =
              action.payload.result.combinationLabelOrderDetails.filter(
                c => c.fileNumber == fileNumber
              );
            var itemList = [];

            items.forEach((item, index) => {
              itemList.push({
                id: index + 1,
                itemNumber: item.itemNumber,
                labelDescription: item.description,
                quantity: item.quantity,
                listeeFileNumber: item.listeeFileNumber,
                listeeFileNumbers: [],
                itemNumberOptions: [],
                customerPartNumber: item.customerPartNumber,
                showErrors: false,
                validationErrors: {},
                fileName: item.fileName,
                existingLabelOrderCartItemId: item.labelOrderCartItemId,
                fileNumber: item.fileNumber,
                ccn: item.ccn,
                labelAccount: item.labelAccount,
                volume: item.volume,
              });
            });

            return stateRequestSuccess.forms.push({
              showErrors: false,
              validationErrors: {},
              itemList: itemList,
            });
          });

      stateRequestSuccess.form = {
        labelSupplierAccountNumber:
          action.payload.result.labelSupplierAccountNumber,
        labelSupplierAddress: action.payload.result.labelSupplierAddress,
        labelSupplierEmailAddress:
          action.payload.result.labelSupplierEmailAddress,
        labelSupplierFileNumber: action.payload.result.labelSupplierFileNumber,
        labelSupplierId: action.payload.result.labelSupplierId,
        labelSupplierName: action.payload.result.labelSupplierName,
        showErrors: false,
        validationErrors: {},
      };

      return stateRequestSuccess;
    case types.EXISTING_COMBINATION_BY_CART_ID_FAILURE:
      var stateRequestFailure = Object.assign({}, state, {});

      stateRequestFailure.itemList = [];
      return stateRequestFailure;
    case types.GET_HOLOGRAMCCNS_REQUEST:
      return {
        ...state,
        isHologramCcnFetched: false,
      };
    case types.GET_HOLOGRAMCCNS_SUCCESS:
      return {
        ...state,
        isHologramCcnFetched: true,
        hologramCcns: action.payload.result,
      };

    case types.GET_HOLOGRAMCCNS_FAILED:
      return {
        ...state,
        isHologramCcnFetched: false,
      };

    case types.GET_LABEL_SUPPLIER_PRINTER_EMAIL_REQUEST:
      return {
        ...state,
      };
    case types.GET_LABEL_SUPPLIER_PRINTER_EMAIL_SUCCESS:
      var supplierEmailResult = {
        ...state,

        labelSupplierPrinterEmails: action.payload.result
          ? action.payload.result.map(item => {
              return {
                key: item.id,
                text: item.emailAddress,
                value: item.emailAddress,
              };
            })
          : null,
        form: {
          ...state.form,
          labelSupplierEmailAddress:
            action.payload.result.length == 1
              ? action.payload.result[0].emailAddress
              : state.form && state.form.labelSupplierEmailAddress
              ? state.form.labelSupplierEmailAddress
              : null,
        },
      };
      return supplierEmailResult;

    case types.GET_LABEL_SUPPLIER_PRINTER_EMAIL_FAILED:
      return {
        ...state,
      };
    case types.GET_COMBINATION_LABELS_BY_PART_NUMBER:
      let newStateFilterPartNumber = {
        ...state,
      };

      let formItem =
        newStateFilterPartNumber.forms &&
        newStateFilterPartNumber.forms.length > 0
          ? newStateFilterPartNumber.forms[action.payload.formIndex] || {}
          : {};

      let resultPartNumber = newStateFilterPartNumber.data.find(
        d => d.customerPartNumber == action.payload.customerPartNumber
      );

      let listItem = formItem.itemList.find(i => i.id == action.payload.id);

      listItem.itemNumber = resultPartNumber.itemNumber;
      listItem.labelDescription = resultPartNumber.labelDescription;
      listItem.customerPartNumber = resultPartNumber.customerPartNumber;
      listItem.showErrors = false;
      listItem.validationErrors = {};

      listItem.fileNumber = action.payload.fileNumber;
      listItem.volume = action.payload.volume;
      listItem.ccn = action.payload.ccn;
      listItem.labelAccount = action.payload.labelAccount;

      formItem.itemList.filter(i => i.id == action.payload.id)[0] = listItem;

      var newFormsPartNumber = [];

      newStateFilterPartNumber.forms.forEach(f => {
        newFormsPartNumber.push(f);
      });

      newStateFilterPartNumber.forms = newFormsPartNumber;

      return newStateFilterPartNumber;
    default:
      return state;
  }
};

export default existingCombinationLabelReducer;
