import React, { Component, Fragment } from 'react';
import { Label, Table } from 'semantic-ui-react';
import _ from 'lodash';
import { uploadTemplate } from '../../enums/upload-type';

class PriceManagementItem extends Component {
  state = { isHide: false };

  getMapColumn = column => {
    var selectedCol = '';
    switch (column) {
      case 'Label Acct':
        selectedCol = 'labelAccount';
        break;
      case 'CCN':
        selectedCol = 'ccn';
        break;
      case 'Label Description':
        selectedCol = 'description';
        break;
      case 'Item Number':
        selectedCol = 'itemNumber';
        break;
      case 'Label Order Package':
        selectedCol = 'labelOrderPackage';
        break;
      case 'Min. Order Qty':
        selectedCol = 'minOrderQuantity';
        break;
      case 'ORACLE UOM':
        selectedCol = 'oracleUnitOfMeasurementName';
        break;
      case 'Unit Price':
        selectedCol = 'price';
        break;
      case 'UOM':
        selectedCol = 'unitOfMeasurementName';
        break;
      default:
        break;
    }

    return selectedCol;
  };

  isEquivalent = (a, b) => {
    // Create arrays of property names
    var aProps = Object.getOwnPropertyNames(a);
    var bProps = Object.getOwnPropertyNames(b);

    // If number of properties is different,
    // objects are not equivalent
    if (aProps.length !== bProps.length) {
      return false;
    }

    for (var i = 0; i < aProps.length; i++) {
      var propName = aProps[i];

      // If values of same property are not equal,
      // objects are not equivalent
      // Skip unit price and price uom temporarily
      var skipCols = ['Unit Price', 'UOM'];
      if (!skipCols.includes(propName)) {
        if (a[propName].toString().trim() !== b[propName].toString().trim()) {
          return false;
        }
      }
    }

    // If we made it this far, objects
    // are considered equivalent
    return true;
  };

  render() {
    const { item, cols, filter } = this.props;

    //check if no changes
    var isVisible = false;
    if (item?.childItem && item?.childItem.length > 0) {
      isVisible = this.isEquivalent(
        _.omit(item, ['childItem', 'Status']),
        item.childItem[0]
      );
    }

    return (
      <Fragment>
        {isVisible === false &&
          (filter == null || filter === 0 || filter === 1 || filter === 3) && (
            <Table.Row>
              {cols &&
                cols.map((_column, idx) => {
                  return (
                    <Table.Cell
                      key={idx}
                      style={
                        item[_column] &&
                        item[_column].toString().trim() !==
                          (item.childItem &&
                            item.childItem.length > 0 &&
                            item.childItem[0][_column].toString().trim())
                          ? !item['Status']
                            ? { color: 'black' }
                            : item['Status'] === 'Active'
                            ? { color: 'green' }
                            : item['Status'] === 'New'
                            ? { color: 'orange' }
                            : { color: 'red' }
                          : {}
                      }
                    >
                      {item[_column]}
                    </Table.Cell>
                  );
                })}
              {(this.props.uploadType !==
                uploadTemplate.authorizedLabelSupplierEmailTemplate ||
                this.props.uploadType !==
                  uploadTemplate.combinationLabelEligibleCcnsTemplate) ?? (
                <Table.Cell
                  style={item['Status'] === 'Active' ? {} : { color: 'gray' }}
                >
                  <Label
                    style={
                      item['Status'] === 'Active'
                        ? { backgroundColor: 'green', color: 'white' }
                        : item['Status'] === 'New'
                        ? { backgroundColor: 'green', color: 'white' }
                        : { color: 'gray' }
                    }
                  >
                    {!item['Status']
                      ? ''
                      : item['Status'] === 'Active'
                      ? 'New'
                      : item['Status'] === 'New'
                      ? 'NEW'
                      : 'TO BE REMOVED'}
                  </Label>
                </Table.Cell>
              )}
            </Table.Row>
          )}

        {item &&
          item.childItem &&
          (filter === null || filter === 0 || filter === 2) &&
          item.childItem.map((childItem, idx) => {
            return (
              <Table.Row key={idx} active>
                {cols &&
                  cols.map((_column, idx) => {
                    return (
                      <Table.Cell
                        key={idx}
                        style={
                          item[_column] &&
                          item[_column].toString().trim() !==
                            item.childItem[0][_column].toString().trim()
                            ? { color: 'red' }
                            : {}
                        }
                      >
                        {childItem[_column]}
                      </Table.Cell>
                    );
                  })}

                <Table.Cell>
                  <Label color="gray">ACTIVE</Label>
                </Table.Cell>
              </Table.Row>
            );
          })}
      </Fragment>
    );
  }
}

export default PriceManagementItem;
