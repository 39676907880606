import React, { Component } from "react";
import {
  Form,
  Grid,
  Dropdown,
  Divider,
  Checkbox,
  Button,
  Table
} from "semantic-ui-react";
import _ from "lodash";
import { CustomLabel } from "../../../../components/labels/labels";
import { connect } from "react-redux";
import ProductDetailsItem from "./product-details-item";
import * as actions from "./product-details.action";
import { toast } from "react-semantic-toasts";
import ServiceType from "../../../../enums/service-type";

const productCategory = [
  {
    id: 1,
    code: "ISA",
    name: "In-service Apparatus"
  }
];

class ProductDetails extends Component {
  state = {
    productType: null,
    showPricing: "true",
    // remove default state value on phase 2, default value is Z1
    area: { id: 1, name: "Zone", code: "ZONE" },
    serviceArea: { id: 1, name: "Zone 1", code: "ZONE1" },
    serviceProduct: {},
    serviceSubProduct: {},
    serviceProductPrice: {},
    serviceSelected: "ISA"
  };

  componentDidMount() {
    // this.props.getAreas(); for P1 Z1 only
    this.props.getServiceProducts(ServiceType.fes);
  }

  getDropdownList(data) {
    var list = [];
    data.map(function (v) {
      var tmp = {};
      tmp["key"] = v.id;
      tmp["id"] = v.id;
      tmp["code"] = v.code;
      tmp["value"] = v.code;
      tmp["text"] = v.name;

      list.push(tmp);
    });
    return list;
  }

  getPriceDropdownList(data) {
    var list = [];
    data.map(function (v) {
      var tmp = {};
      tmp["key"] = v.id;
      tmp["id"] = v.id;
      tmp["value"] = v.price;
      tmp["text"] = `USD ${v.price} ${
        Object.IsEmpty(v.description) ? "" : v.description
      }`;
      tmp["desc"] = v.description;
      tmp["rem"] = Object.IsEmpty(v.remarks) ? "" : v.remarks;

      list.push(tmp);
    });
    return list;
  }

  onChangeArea(e) {
    this.setState({
      area: {
        id: parseInt(e.currentTarget.id),
        name: e.currentTarget.innerText,
        code: e.currentTarget.getAttribute("code")
      }
    });
    this.props.getServiceAreas(e.currentTarget.id);
    this.props.clearServiceAreas();
    this.props.clearServiceProducts();
    this.props.clearServiceSubProducts();
    this.props.clearServiceProductPrices();
  }

  onChangeServiceArea(e) {
    this.setState({
      serviceArea: {
        id: parseInt(e.currentTarget.id),
        name: e.currentTarget.innerText,
        code: e.currentTarget.getAttribute("code")
      }
    });
    this.props.getServiceProducts(ServiceType.fes);
    this.props.clearServiceProducts();
    this.props.clearServiceSubProducts();
    this.props.clearServiceProductPrices();
  }

  onChangeServiceProduct(e) {
    this.setState({
      serviceProduct: {
        id: parseInt(e.currentTarget.id),
        name: e.currentTarget.innerText,
        code: e.currentTarget.getAttribute("code")
      }
    });
    this.props.getServiceSubProducts(e.currentTarget.id);
    this.props.clearServiceSubProducts();
    this.props.clearServiceProductPrices();
  }

  onChangeServiceSubProduct(e) {
    this.setState({
      serviceSubProduct: {
        id: parseInt(e.currentTarget.id),
        name: e.currentTarget.innerText,
        code: e.currentTarget.getAttribute("code")
      },
      serviceProductPrice: {},
      value: ""
    });
    const { id } = this.state.serviceArea;
    this.props.getServiceProductPrices(id, e.currentTarget.id);
  }

  onChangePriceRadioGroup(e) {
    const value = e.currentTarget.children[0].value;
    this.setState({ showPricing: value });

    //this.props.clearServiceAreas();
    //this.props.clearServiceProducts();
    this.props.clearServiceSubProducts();
    this.props.clearServiceProductPrices();
    this.props.clearOrders();
    this.setState({ value: "" });
  }

  onChangeCheckboxRadioGroup(e) {
    const { data } = this.props.productDetails.serviceProductPrice;
    const value = e.currentTarget.children[0].value;
    const selectedPrice = value == "true" ? 1 : 0; // for index of selected radio button
    // const price =
    //   data.length == 1
    //     ? `USD ${data[0].price} ${
    //         Object.IsEmpty(data[0].description) ? "" : data[0].description
    //       }`
    //     : `USD ${data[selectedPrice].price} ${
    //         Object.IsEmpty(data[selectedPrice].description)
    //           ? ""
    //           : data[selectedPrice].description
    //       }`;

    let price = "";
    let desc = "";
    let minFee = 0;

    if (data.length == 1) {
      // GL
      price = data[0].price;
      desc = Object.IsEmpty(data[0].description) ? "" : data[0].description;
      minFee = data[0].minimumFee;
    } else {
      // Aerial - with radiobutton
      price = data[selectedPrice].price;
      desc = Object.IsEmpty(data[selectedPrice].description)
        ? ""
        : data[selectedPrice].description;
      minFee = data[selectedPrice].minimumFee;
    }

    this.setState({
      value: value,
      serviceProductPrice: {
        id: `${data[selectedPrice].id}`,
        price: price,
        desc: desc,
        remarks: `${
          Object.IsEmpty(data[selectedPrice].remarks)
            ? ""
            : data[selectedPrice].remarks
        }`,
        minimumFee: minFee
      }
    });
  }

  handleChangeProductType = (e, { name, value }) => {
    this.setState({
      productType: value,
      productTypeName: e.currentTarget.innerText
    });
  };

  onClickAddOrder() {
    const {
      serviceProduct,
      serviceSubProduct,
      serviceProductPrice
    } = this.state;

    const price = this.props.productDetails.serviceProductPrice;
    const { orders } = this.props.productDetails;

    const hasGLIndex = orders.findIndex((o) => o.serviceProductCode === "GL");

    if (hasGLIndex > -1 && serviceSubProduct.code === "GL") {
      setTimeout(() => {
        toast({
          type: "warning",
          title: "Warning",
          description:
            "Ground Ladder item already exists, please update Quantity field",
          time: 10000,
          size: "small",
          icon: "info",
          color: "yellow"
        });
      }, 100);
      return false;
    }

    let currentPriceId = 0;
    let currentPrice = "";
    let priceDesc = "";
    let isMinimumFee = false;
    let total = 0;
    let minFee = 0;

    if (typeof serviceProductPrice.price === "undefined") {
      // Ground Ladder
      const hasAerialIndex = orders.findIndex(
        (o) => o.serviceProductCode === "AIS"
      );

      minFee = price.data[0].minimumFee;
      currentPriceId = parseInt(price.data[0].id);
      currentPrice = price.data[0].price;
      priceDesc = Object.IsEmpty(price.data[0].description)
        ? ""
        : price.data[0].description;
      total = hasAerialIndex > -1 ? minFee : currentPrice;
      isMinimumFee = hasAerialIndex > -1 ? true : false;
    } else {
      // Aerial
      currentPriceId = parseInt(serviceProductPrice.id);
      currentPrice = serviceProductPrice.price;
      priceDesc = serviceProductPrice.desc;
      total = serviceProductPrice.price; // qty default to 1, total same as currentPrice
      minFee = serviceProductPrice.minimumFee;

      // Aerial with GL added. Update GL price, min fee will not apply
      if (hasGLIndex > -1) {
        let gl = orders[hasGLIndex];
        gl.total = gl.price * gl.quantity;
        gl.isMinimumFee = false;

        isMinimumFee = false; // min fee will not apply

        this.props.updateOrder(hasGLIndex);
      }
    }

    let me = {
      productCategoryId: serviceProduct.id,
      productCategory: serviceProduct.name,
      productCategoryCode: serviceProduct.code,
      serviceProductId: serviceSubProduct.id,
      serviceProduct: serviceSubProduct.name,
      serviceProductCode: serviceSubProduct.code,
      priceId: currentPriceId,
      price: currentPrice,
      desc: priceDesc,
      quantity: 1,
      total: total,
      isMinimumFee: isMinimumFee,
      areAerialsBrontoSchwingMetz: Object.IsEmpty(this.state.value)
        ? false
        : this.state.value == "true"
        ? true
        : false,
      hasExistingULLabel: false, // ??
      requestedScheduleUnixTime: Date.now(), // ??
      isProductInvolvedInLitigation: false, // ??
      others: {},
      minimumFee: minFee
    };

    this.props.addOrder(me);
  }

  onClickRemoveItem(idx) {
    this.props.removeOrder(idx);
    this.handleChangeQuantity();
  }

  handleChangeQuantity() {
    // Once item has been removed, update the price
    const { orders } = this.props.productDetails;

    if (!(orders || []).length) return;

    orders.forEach((o, i) => {
      if (o.productCategoryCode === "AIS") return;

      o.total = o.price * parseInt(o.quantity);

      if (o.serviceProductCode === "GL") {
        const hasAIS = orders.filter((s) => s.productCategoryCode === "AIS")
          .length;

        if (hasAIS) {
          o.total = o.total > o.minimumFee ? o.total : o.minimumFee;
          o.isMinimumFee = o.total > o.minimumFee ? false : true;
        }
      }

      this.props.updateOrder(i);
    });
  }

  onChangeQuantity(idx, e) {
    const { orders } = this.props.productDetails;

    // TODO: Get max quantity from database
    // AIS-parent, both AA & AA-NDT
    if (orders[idx].productCategoryCode == "AIS")
      if (e.target.value > 30) return false;

    orders[idx].quantity = e.target.value;

    if (orders[idx].serviceProductCode == "GL") {
      // GL-child, apply min fee if price less than $565
      var hasAerial = orders.filter((o) => o.productCategoryCode == "AIS")
        .length;

      if (hasAerial > 0) {
        orders[idx].total = orders[idx].price * e.target.value;
        orders[idx].isMinimumFee = false;
      } else {
        var price = orders[idx].price * e.target.value;
        orders[idx].total =
          price > orders[idx].minimumFee ? price : orders[idx].minimumFee;
        orders[idx].isMinimumFee =
          price > orders[idx].minimumFee ? false : true;
      }
    } else {
      // Aerial
      orders[idx].total = orders[idx].price * e.target.value;
    }

    // removing item/truck in 'others' object
    const keyItem = Object.keys(orders[idx].others);
    if (keyItem.length > e.target.value) {
      var toRemove = keyItem.length - e.target.value;

      for (let i = 0; i < toRemove; i++) {
        var x = keyItem[i + parseInt(e.target.value)];
        delete orders[idx].others[x];
      }
    }

    this.props.updateOrder(idx);
  }

  render() {
    const { productType, value, serviceSelected } = this.state;
    const {
      orders,
      area,
      serviceArea,
      serviceProduct,
      serviceSubProduct,
      serviceProductPrice
    } = this.props.productDetails;

    return (
      <div>
        <CustomLabel message="PRODUCT DETAILS" />
        <Divider fitted />
        <Divider hidden />
        <Grid>
          <Grid.Row columns={5}>
            {/*<Grid.Column>
                  <Form.Field>
                    <label>Area</label>
                    <Dropdown
                      compact
                      selection
                      placeholder="Select Area"
                      options={
                        area.data ? this.getDropdownList(area.data) : null
                      }
                      onChange={this.onChangeArea.bind(this)}
                    />
                  </Form.Field>
                </Grid.Column>
                {serviceArea.data && serviceArea.data.length > 0 ? (
                  <Grid.Column>
                    <Form.Field>
                      <label>Service Area</label>
                      <Dropdown
                        compact
                        selection
                        placeholder="Select Service Area"
                        options={
                          serviceArea.data
                            ? this.getDropdownList(serviceArea.data)
                            : null
                        }
                        onChange={this.onChangeServiceArea.bind(this)}
                      />
                    </Form.Field>
                  </Grid.Column>
                ) : null} */}

            <Grid.Column>
              <Form.Field>
                <label>* Product Category</label>
                <Dropdown
                  compact
                  selection
                  placeholder="Select Product Category"
                  options={this.getDropdownList(productCategory)}
                  selectOnBlur={false}
                  defaultValue={serviceSelected}
                />
              </Form.Field>
            </Grid.Column>

            {serviceProduct.data && serviceProduct.data.length > 0 ? (
              <Grid.Column>
                <Form.Field>
                  <label>* Equipment Type</label>
                  <Dropdown
                    compact
                    selection
                    placeholder="Select Equipment Type"
                    options={
                      serviceProduct.data
                        ? this.getDropdownList(serviceProduct.data)
                        : null
                    }
                    onChange={this.onChangeServiceProduct.bind(this)}
                    selectOnBlur={false}
                  />
                </Form.Field>
              </Grid.Column>
            ) : null}
            {serviceSubProduct.data && serviceSubProduct.data.length > 0 ? (
              <Grid.Column>
                <Form.Field>
                  <label>* Service Product</label>
                  <Dropdown
                    compact
                    selection
                    placeholder="Select Product"
                    options={
                      serviceSubProduct.data
                        ? this.getDropdownList(serviceSubProduct.data)
                        : null
                    }
                    onChange={this.onChangeServiceSubProduct.bind(this)}
                    selectOnBlur={false}
                  />
                </Form.Field>
              </Grid.Column>
            ) : null}
            {serviceProductPrice.data ? (
              serviceProductPrice.data.length > 0 ? (
                (this.state.serviceSubProduct.code == "AA" &&
                  serviceProductPrice.data.length > 1) ||
                (this.state.serviceSubProduct.code == "AA-NDT" &&
                  serviceProductPrice.data.length > 1) ? (
                  <Grid.Column>
                    <Form.Field>
                      * Are any aerials Bronto, Schwing or Metz?
                    </Form.Field>
                    <Form.Field>
                      <Checkbox
                        radio
                        label="Yes"
                        name="checkboxRadioGroup"
                        value="true"
                        checked={value === "true"}
                        onChange={this.onChangeCheckboxRadioGroup.bind(this)}
                      />
                      &nbsp;&nbsp;&nbsp;
                      <Checkbox
                        radio
                        label="No"
                        name="checkboxRadioGroup"
                        value="false"
                        checked={value === "false"}
                        onChange={this.onChangeCheckboxRadioGroup.bind(this)}
                      />
                    </Form.Field>
                  </Grid.Column>
                ) : null
              ) : (
                "N/A"
              )
            ) : null}
            {(value && this.state.serviceProduct.code === "AIS") ||
            (this.state.serviceProduct.code !== "AIS" &&
              serviceProductPrice.data &&
              serviceProductPrice.data.length > 0) ? (
              <Grid.Column>
                <Form.Field>
                  <label>&nbsp;</label>
                  <Button primary onClick={this.onClickAddOrder.bind(this)}>
                    Add order
                  </Button>
                </Form.Field>
              </Grid.Column>
            ) : null}
          </Grid.Row>

          {orders.length > 0 ? (
            <Grid.Row>
              <Grid.Column>
                <Table celled>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Product Category</Table.HeaderCell>
                      <Table.HeaderCell>Service Product</Table.HeaderCell>
                      <Table.HeaderCell>Quantity</Table.HeaderCell>
                      <Table.HeaderCell>Others</Table.HeaderCell>
                      <Table.HeaderCell> </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {orders.map((order, idx) => (
                      <ProductDetailsItem
                        key={idx}
                        item={order}
                        idx={idx}
                        onRemoveItem={this.onClickRemoveItem.bind(this, idx)}
                        onChangeQuantity={this.onChangeQuantity.bind(this)}
                      />
                    ))}
                  </Table.Body>
                </Table>
              </Grid.Column>
            </Grid.Row>
          ) : null}
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    productDetails: state.fesProductDetails
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    form: (result) => dispatch(actions.updateForm(result)),
    addOrder: (param) => dispatch(actions.addOrder(param)),
    updateOrder: (idx) => dispatch(actions.updateOrder(idx)),
    removeOrder: (idx) => dispatch(actions.removeOrder(idx)),
    getAreas: () => dispatch(actions.getAreas()),
    getServiceAreas: (areaId) => dispatch(actions.getServiceAreas(areaId)),
    getServiceProducts: (serviceType) =>
      dispatch(actions.getServiceProducts(serviceType)),
    getServiceSubProducts: (serviceProductId) =>
      dispatch(actions.getServiceSubProducts(serviceProductId)),
    getServiceProductPrices: (serviceAreaId, serviceSubProductId) =>
      dispatch(
        actions.getServiceProductPrices(serviceAreaId, serviceSubProductId)
      ),
    clearServiceAreas: () => dispatch(actions.clearServiceAreas()),
    clearServiceProducts: () => dispatch(actions.clearServiceProducts()),
    clearServiceSubProducts: () => dispatch(actions.clearServiceSubProducts()),
    clearServiceProductPrices: () =>
      dispatch(actions.clearServiceProductPrices()),
    clearOrders: () => dispatch(actions.clearOrder())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetails);
