import * as types from "./change-password.type";

let initialState = {
  data: [],
  form: {
    showErrors: false,
    validationErrors: {}
  }
};

const changePasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
    case types.CHANGE_PASSWORD_REQUEST:
    case types.CHANGE_PASSWORD_SUCCESS:
    case types.CHANGE_PASSWORD_FAILURE:
      return Object.assign({}, state, {
        data: action.data || initialState.data
      });
    case types.FORM:
      return Object.assign({}, state, {
        form: action.data || initialState.form
      });
    case types.CLEAR:
      return Object.assign({}, state, {
        data: initialState.data,
        form: { showErrors: false, validationErrors: {} }
      });
  }
};

export default changePasswordReducer;
