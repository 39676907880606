import { toast } from "react-semantic-toasts";

const defaultSetting = {
  type: "",
  title: "",
  description: "",
  time: 10000,
  size: "small",
  icon: "check",
  color: "green"
};

const getConfig = (config) => Object.assign({}, defaultSetting, config);
const success = (config) => {
  toast(
    Object.assign({}, getConfig(config), {
      type: "success",
      size: "small",
      icon: "check",
      color: "green"
    })
  );
};

const error = (config) => {
  toast(
    Object.assign({}, getConfig(config), {
      type: "error",
      size: "small",
      icon: "delete",
      color: "red"
    })
  );
};

export default {
  success,
  error
};
