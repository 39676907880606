import merge from "deepmerge";
import * as types from "./project-details.types";

let initialState = {
  data: [],
  area: {},
  serviceArea: {},
  serviceProduct: {},
  serviceSubProduct: {},
  forms: [],
  siteDetails: []
};

const projectDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
    case types.UPDATE_FORMS:
      return Object.assign({}, state, {
        forms: action.data
      });

    case types.FE_SERVICE_PRODUCT_REQUEST:
    case types.FE_SERVICE_PRODUCT_SUCCESS:
    case types.FE_SERVICE_PRODUCT_FAILURE:
      return Object.assign({}, state, {
        serviceProduct: action.data || initialState.serviceProduct
      });

    case types.FE_SERVICE_SUB_PRODUCT_REQUEST:
    case types.FE_SERVICE_SUB_PRODUCT_SUCCESS:
    case types.FE_SERVICE_SUB_PRODUCT_FAILURE:
      return Object.assign({}, state, {
        serviceSubProduct: action.data || initialState.serviceSubProduct
      });

    case types.FE_PRODUCT_PRICE_REQUEST:
    case types.FE_PRODUCT_PRICE_SUCCESS:
    case types.FE_PRODUCT_PRICE_FAILURE:
      const formIdx = (state.forms || []).findIndex(
        (s) => (s || {}).tabIndex === action.data.index
      );
      state.forms[formIdx] = action.data.result;

      return Object.assign({}, state, {
        forms: state.forms
      });

    case types.CLEAR_GSQ:
      return initialState;

    // ** SITE DETAILS

    case types.UPDATE_SITE_FORMS:
      return Object.assign({}, state, {
        siteDetails: action.data
      });

    case types.COUNTRIES_PRELIM_SITE_REQUEST:
    case types.COUNTRIES_PRELIM_SITE_SUCCESS:
    case types.COUNTRIES_PRELIM_SITE_FAILURE:
      return Object.assign({}, state, {
        countryOptionsPrelimSiteDetails:
          (action.data &&
            action.data.map((c) => {
              return {
                key: c.countryCode,
                value: c.countryCode,
                text: c.countryName
              };
            })) ||
          initialState.countryOptionsPrelimSiteDetails
      });

    case types.STATES_PRELIM_SITE_REQUEST:
    case types.STATES_PRELIM_SITE_FAILURE:
    case types.STATES_PRELIM_SITE_SUCCESS:
      return Object.assign({}, state, {
        stateOptionsPrelimSiteDetails:
          (action.data &&
            action.data.map((c) => {
              return {
                key: c.stateCode,
                value: c.stateCode,
                text: c.stateName
              };
            })) ||
          initialState.stateOptionsPrelimSiteDetails
      });

    case types.CITIES_PRELIM_SITE_REQUEST:
    case types.CITIES_PRELIM_SITE_FAILURE:
    case types.CITIES_PRELIM_SITE_SUCCESS:
      return Object.assign({}, state, {
        cityOptionsPrelimSiteDetails:
          (action.data &&
            action.data.map((c) => {
              return {
                key: c.cityName,
                value: c.cityName,
                text: c.cityName
              };
            })) ||
          initialState.cityOptionsPrelimSiteDetails
      });

    case types.COUNTRIES_FINAL_SITE_REQUEST:
    case types.COUNTRIES_FINAL_SITE_FAILURE:
    case types.COUNTRIES_FINAL_SITE_SUCCESS:
      return Object.assign({}, state, {
        countryOptionsFinalSiteDetails:
          (action.data &&
            action.data.map((c) => {
              return {
                key: c.countryCode,
                value: c.countryCode,
                text: c.countryName
              };
            })) ||
          initialState.countryOptionsFinalSiteDetails
      });

    case types.STATES_FINAL_SITE_REQUEST:
    case types.STATES_FINAL_SITE_SUCCESS:
    case types.STATES_FINAL_SITE_FAILURE:
      return Object.assign({}, state, {
        stateOptionsFinalSiteDetails:
          (action.data &&
            action.data.map((c) => {
              return {
                key: c.stateCode,
                value: c.stateCode,
                text: c.stateName
              };
            })) ||
          initialState.stateOptionsFinalSiteDetails
      });

    case types.CITIES_FINAL_SITE_REQUEST:
    case types.CITIES_FINAL_SITE_SUCCESS:
    case types.CITIES_FINAL_SITE_FAILURE:
      return Object.assign({}, state, {
        cityOptionsFinalSiteDetails:
          (action.data &&
            action.data.map((c) => {
              return {
                key: c.cityName,
                value: c.cityName,
                text: c.cityName
              };
            })) ||
          initialState.cityOptionsFinalSiteDetails
      });

    case types.COUNTRIES_AHJ_SITE_REQUEST:
    case types.COUNTRIES_AHJ_SITE_SUCCESS:
    case types.COUNTRIES_AHJ_SITE_FAILURE:
      return Object.assign({}, state, {
        countryOptionsAhjSiteDetails:
          (action.data &&
            action.data.map((c) => {
              return {
                key: c.countryCode,
                value: c.countryCode,
                text: c.countryName
              };
            })) ||
          initialState.countryOptionsAhjSiteDetails
      });

    case types.STATES_AHJ_SITE_REQUEST:
    case types.STATES_AHJ_SITE_SUCCESS:
    case types.STATES_AHJ_SITE_FAILURE:
      return Object.assign({}, state, {
        stateOptionsAhjSiteDetails:
          (action.data &&
            action.data.map((c) => {
              return {
                key: c.stateCode,
                value: c.stateCode,
                text: c.stateName
              };
            })) ||
          initialState.stateOptionsAhjSiteDetails
      });

    case types.CITIES_AHJ_SITE_REQUEST:
    case types.CITIES_AHJ_SITE_SUCCESS:
    case types.CITIES_AHJ_SITE_FAILURE:
      return Object.assign({}, state, {
        cityOptionsAhjSiteDetails:
          (action.data &&
            action.data.map((c) => {
              return {
                key: c.cityName,
                value: c.cityName,
                text: c.cityName
              };
            })) ||
          initialState.cityOptionsAhjSiteDetails
      });
    case types.RENDER_DYNAMIC_SITE_FORM:
      var result = Object.assign({}, state, {
        data: action.data || initialState.data
      });

      var siteCount = action.data;
      result.siteDetails = [];
      for (let i = 0; i < siteCount; i++) {
        var siteForm = {
          prelim: {
            data: {},
            showErrors: false,
            validationErrors: {}
          },
          final: {
            data: {},
            showErrors: false,
            validationErrors: {}
          },
          ahj: {
            data: {},
            showErrors: false,
            validationErrors: {}
          },
          others: {
            specialNotesList: [],
            data: {},
            showErrors: false,
            validationErrors: {}
          }
        };
        result.siteDetails.push(siteForm);
      }
      result.isSuccessRenderForm = true;

      return result;
    case types.RENDER_PRODUCT_DETAILS:
      var result = Object.assign({}, state, {});

      var siteCount = action.data.count;
      var quote = action.data.data;

      result.forms = [];
      for (let i = 0; i < siteCount; i++) {
        var product = quote.products.filter((a) => a.tabIndex == i)[0];
        var productItem = quote.productItems.filter(
          (a) => a.fieldEvaluationProductId == product.id
        )[0];

        var formItem = {
          id: productItem.id,
          serviceProductName: productItem.productCategory,
          serviceProductCode: productItem.productCategoryCode,
          serviceSubProductId: productItem.serviceSubProductId,
          serviceSubProductCode: productItem.serviceSubProductCode,
          serviceSubProductName: productItem.serviceSubProductName,
          isExistingUlEquipment: productItem.isExistingUlEquipment,
          isInvolvedLitigation: productItem.isInvolvedLitigation,
          additionalInfo: productItem.additionalInfo,
          litigationDescription: productItem.litigationDescription,
          quantity: productItem.quantity,
          isEvaluatedSameVisit: productItem.isEvaluatedSameVisit || null,
          isManualPricing: productItem.isManualPricing,
          assumptions: productItem.assumptions,
          cancellation: productItem.cancellation,
          totalPriceByPh: productItem.totalPriceByPh || null,
          requirementsInspection: productItem.requirementsInspection,
          propertyType: productItem.propertyType,
          tabIndex: i,
          validationErrors: {},
          scope: productItem.scope
        };

        if (formItem.serviceSubProductCode === "PNL") {
          formItem.isPanelboard = true;
          formItem.panelboardOver200 = productItem.isPanelboardOver200;
        } else {
          formItem.isSwitchboard = true;
          formItem.switchboardOver600 = productItem.isSwitchboardOver600;
        }

        result.forms.push(formItem);
      }

      return result;
    case types.RENDER_SITE_DETAILS:
      var result = Object.assign({}, state, {});

      var siteCount = action.data.count;
      var quote = action.data.data;

      result.siteDetails = [];
      for (let i = 0; i < siteCount; i++) {
        var acceptance = quote.quoteAcceptances.filter(
          (f) => f.tabIndex == i
        )[0];

        var siteForm = {
          prelim: quote.sites.filter(
            (f) => f.siteType === 1 && f.tabIndex == i
          )[0],
          final: quote.sites.filter(
            (f) => f.siteType === 2 && f.tabIndex == i
          )[0],
          ahj: quote.authorityJurisdictions.filter(
            (f) => f.baseType === 2 && f.tabIndex == i
          )[0],
          others: {
            isReadyToScheduleNow: acceptance.isReadyToScheduleNow,
            customerReferenceNumber: acceptance.customerReferenceNumber,
            data: {},
            showErrors: false,
            validationErrors: {},
            specialNotesList:
              (
                (quote.products || []).find((s) => (s || {}).tabIndex === i) ||
                {}
              ).specialNotes || []
            // (quote.products.find((s) => s.tabIndex == i) || {})
            //   .specialNotes || []
          }
        };

        if (siteForm.prelim) {
          siteForm.prelim.data = {};
          siteForm.prelim.showErrors = false;
          siteForm.prelim.validationErrors = {};
        }

        if (siteForm.final) {
          siteForm.final.data = {};
          siteForm.final.showErrors = false;
          siteForm.final.validationErrors = {};
          // siteForm.others.showPrelimLocation = "true";
        }

        if (siteForm.ahj) {
          siteForm.ahj.data = {};
          siteForm.ahj.showErrors = false;
          siteForm.ahj.validationErrors = {};
        }

        if (siteForm.others) {
          siteForm.others.showPrelimLocation =
            siteForm?.prelim?.residenceName == null ? "true" : "false";
        }
        console.log(siteForm.others);
        result.siteDetails.push(siteForm);
      }
      result.isSuccessRenderForm = true;

      return result;
  }
};

export default projectDetailsReducer;
