import _ from "lodash";
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Dimmer,
  Modal,
  Loader,
  Grid,
  Button,
  Table,
  TextArea,
  Form
} from "semantic-ui-react";

import ToastHelper from "../../../helpers/toast.helper";
import ItemTable from "./item-table-modal";

import * as actions from "./cancel-order.action";
import Status from "../../../enums/status";

class ItemModal extends Component {
  state = { isLoading: true, reasonModal: false, comment: "", id: null };

  componentDidMount() {
    this.props.doClearItemDetails();
    const { data, handleViewItemModal } = this.props;

    this.props.doGetItem(data.id, data.fileNumber).then((res) => {
      if (res && (res != null || res != "")) {
        if (res.isSuccessful) {
          this.setState({ isLoading: false });
          return;
        }
      }

      setTimeout(() => {
        ToastHelper.error({
          title: "Error",
          description: "Something went wrong"
        });
      }, 100);
      this.setState({ isLoading: false });
      handleViewItemModal(false);
    });
  }

  onChange(e) {
    this.setState({ comment: e.target.value });
  }

  handleReasonModal(id) {
    this.setState({ reasonModal: true, id });
  }

  onCancelItem() {
    const { id, comment } = this.state;

    if (comment.trim() === "") {
      setTimeout(() => {
        ToastHelper.error({
          title: "Error",
          description: "Cancel Reason is required."
        });
      }, 100);
      return false;
    }

    const {
      cancelOrder: { itemDetails },
      data
    } = this.props;

    let idx = _.findIndex(itemDetails.data, { id });
    itemDetails.data[idx].isLoading = "loading";

    this.props.doCancelOrderItem({ id, comment }).then((res) => {
      if (res && (res != null || res != "")) {
        if (res.isSuccessful) {
          this.setState({ reasonModal: false });
          itemDetails.data[idx].isLoading = "";
          itemDetails.data[idx].status = Status.cancelled;

          let allItemsCancelled = itemDetails.data.some(
            (s) => s["status"] !== 4
          );

          // if all items cancelled disabled sub-parent button
          if (!allItemsCancelled) {
            data.isDisable = true;
            data.statusName = res.message;
          }

          // disable parent button
          if (res.data) {
            data.isDisable = true;
            data.statusName = res.message;
          }
          return;
        }
      }

      setTimeout(() => {
        ToastHelper.error({
          title: "Error",
          description: "Something went wrong"
        });
      }, 100);
      itemDetails.data[idx].isLoading = "";
    });
  }

  render() {
    const {
      isShowItemModal,
      handleViewItemModal,
      cancelOrder: { itemDetails }
    } = this.props;

    const { reasonModal, isLoading } = this.state;

    return (
      <Fragment>
        <Modal open={isShowItemModal} size="large">
          <Dimmer active={isLoading} inverted>
            <Loader />
          </Dimmer>
          <Modal.Header></Modal.Header>
          <Modal.Content scrolling>
            <Modal.Description>
              <Table striped celled selectable>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Status</Table.HeaderCell>
                    <Table.HeaderCell>CCN</Table.HeaderCell>
                    <Table.HeaderCell>File Number</Table.HeaderCell>
                    <Table.HeaderCell>Item Number</Table.HeaderCell>
                    <Table.HeaderCell>Label Description</Table.HeaderCell>
                    <Table.HeaderCell>Quantity</Table.HeaderCell>
                    <Table.HeaderCell>Net Price</Table.HeaderCell>
                    <Table.HeaderCell>UOM</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {itemDetails && (itemDetails || {}).data
                    ? itemDetails.data.map((d, i) => {
                        return (
                          <ItemTable
                            key={i}
                            item={d}
                            handleReasonModal={this.handleReasonModal.bind(
                              this
                            )}
                          />
                        );
                      })
                    : null}
                </Table.Body>
              </Table>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={handleViewItemModal.bind(this, false)}>
              Close
            </Button>
          </Modal.Actions>
        </Modal>

        <Modal open={reasonModal} size="tiny">
          <Modal.Header className="ui center aligned">
            Confirm Cancellation
          </Modal.Header>
          <Modal.Content scrolling>
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <Form>
                    <label>Cancellation Reason</label>
                    <TextArea
                      name="comments"
                      autoComplete="off"
                      maxLength="256"
                      onChange={this.onChange.bind(this)}
                    />
                  </Form>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Modal.Content>
          <Modal.Actions>
            <Button
              primary
              className={isLoading}
              onClick={this.onCancelItem.bind(this)}
            >
              Confirm
            </Button>
            <Button
              color="red"
              onClick={() => this.setState({ reasonModal: false })}
            >
              Close
            </Button>
          </Modal.Actions>
        </Modal>
      </Fragment>
    );
  }
}

ItemModal.propTypes = {
  isShowItemModal: PropTypes.bool.isRequired,
  handleViewItemModal: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  cancelOrder: state.cancelOrder
});

const mapDispatchToProps = (dispatch) => {
  return {
    doGetItem: (orderId, fileNumber) =>
      dispatch(actions.doGetItem(orderId, fileNumber)),
    doClearItemDetails: () => dispatch(actions.doClearItemDetails()),
    doCancelOrderItem: (param) => dispatch(actions.doCancelOrderItem(param))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ItemModal);
