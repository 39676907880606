import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Grid,
  Form,
  Checkbox,
  Divider,
  Dropdown,
  Input,
  TextArea,
  Popup,
  Icon,
  Button,
  Label
} from "semantic-ui-react";
import * as actions from "./product-details.action";
import ServiceType from "../../../../enums/service-type";
import Property from "../../../../enums/property";
import {
  validate,
  getError,
  validateField
} from "../../../../validator/validator";
import {
  required,
  maxLengthNumberInput
} from "../../../../validator/error-message";
import { FEGSQRules } from "../../validation-rules";
import Role from "../../../../enums/role";
import { Common } from "../../../../common";

const rules = [validate("quantity", "Quantity", required)];

// If there's no db data, it'll use this
const productType = [
  {
    id: 1,
    code: "PNL-1",
    name: "Panelboard"
  },
  {
    id: 2,
    code: "SWBOARD-SWGEAR-1",
    name: "Switchboard/Switchgear"
  }
];

class FeProductDetails extends Component {
  state = {
    scheduleDate: new Date(),
    serviceProduct: {},
    serviceSubProduct: {},
    serviceProductSelected: "AESI-LST",
    exceededSizeLimit: false
  };

  componentDidMount() {
    this.props.getServiceProducts(ServiceType.fe).then((s) => {
      if (s.isSuccessful) {
        this.props.getServiceSubProducts(s.data[0].id);
        this.updateForm("serviceProductId", s.data[0].id);
        this.updateForm("serviceProductName", s.data[0].name);
        this.updateForm("serviceProductCode", s.data[0].code);
      }
    });
  }

  getDropdownList(data) {
    var list = [];
    data.map(function (v) {
      var tmp = {};
      tmp["key"] = v.id;
      tmp["id"] = v.id;
      tmp["code"] = v.code;
      tmp["value"] = v.code;
      tmp["text"] = v.name;

      list.push(tmp);
    });
    return list;
  }

  // TODO: Make this dynamic
  updateForm(name, value) {
    let { form } = this.props.productDetails;

    // NOTE: way para malagay agad ung value sa form state
    //       para ma filter ang validation
    let newForm = Object.assign({}, form, {
      [name]: value
    });

    // NOTE: filter rules by ...
    const newRules = actions.getValidation(newForm, FEGSQRules);
    const newState = validateField(newRules, form, name, value);

    //const newState = validateField(rules, form, name, value);
    this.props.form(newState);
  }

  // moved checkManualPricingTrigger to order-details parent component
  handleChange = (e) => {
    if (e.target.name == "quantity") {
      this.props.checkManualPricingTrigger(e.target.value, e.target.name);
      if (maxLengthNumberInput(e, 15)) return false;
    }
    this.updateForm(e.target.name, e.target.value);
  };

  onChangeExistingUlRadioGroup(e, { value }) {
    this.updateForm("isExistingUlEquipment", value);
  }

  onChangeLitigationRadioGroup(e, { value }) {
    this.updateForm("isInvolvedLitigation", value);
    this.props.checkManualPricingTrigger(value, "isInvolvedLitigation");
  }

  onChangeOver600RadioGroup(e, { value }) {
    this.updateForm("switchboardOver600", value);
    this.props.checkManualPricingTrigger(value, "switchboardOver600");
  }

  onChangeOver200RadioGroup(e, { value }) {
    this.updateForm("panelboardOver200", value);
  }

  onChangePropertyTypeRadioGroup(e, { value }) {
    this.updateForm("propertyType", value);
  }

  onChangeInstalledSameLocationRadioGroup(e, { value }) {
    this.updateForm("isInstalledSameLocation", value);
    this.props.checkManualPricingTrigger(value, "isInstalledSameLocation");
  }

  onChangeEvaluatedSameVisitRadioGroup(e, { value }) {
    this.updateForm("isEvaluatedSameVisit", value);
    this.props.checkManualPricingTrigger(value, "isEvaluatedSameVisit");
  }

  onChangeServiceSubProduct(e) {
    this.updateForm("serviceSubProductId", parseInt(e.currentTarget.id));
    this.updateForm("serviceSubProductName", e.currentTarget.innerText);
    this.updateForm(
      "serviceSubProductCode",
      e.currentTarget.getAttribute("code")
    );

    const isPnl = e.currentTarget.getAttribute("code") === "PNL";
    this.updateForm("isPanelboard", isPnl);
    this.updateForm("isSwitchboard", !isPnl);
    this.updateForm(
      "propertyType",
      isPnl ? Property.residential : Property.commercial
    );

    this.props.checkManualPricingTrigger(
      e.currentTarget.innerText,
      "equipmentType"
    );
  }

  onChangeScheduleDate = (date) => {
    this.setState({ scheduleDate: date });
  };

  getErrorFor(field) {
    const { form } = this.props.productDetails;

    return getError(field, form, form);
  }

  getErrorForChk(field) {
    const msg = this.getErrorFor(field);

    if (!msg || (msg && !msg.length)) return null;

    return (
      <React.Fragment>
        <br />
        <div className="ui pointing above prompt label chk-rad-error">
          {msg}
        </div>
      </React.Fragment>
    );
  }

  getErrorForChkClass(field) {
    const msg = this.getErrorFor(field);

    return msg && msg.length ? "error" : "";
  }

  handleFileChange = (event) => {
    const { exceededSizeLimit } = this.state;

    if (event.target.files.length > 0) {
      const file = event.target.files[0];

      if (file.size > 4294967296) {
        this.setState({ exceededSizeLimit: true });
      } else {
        this.setState({ exceededSizeLimit: false });

        var fileReader = new FileReader();
        var that = this;
        this.updateForm("fileName", file.name);
        this.updateForm("file", file);

        fileReader.onload = function (fileLoadedEvent) {
          var srcData = fileLoadedEvent.target.result;

          that.updateForm("artworkLink", srcData);
        };

        fileReader.readAsDataURL(file);
      }
    } else this.setState({ file: null });
  };

  render() {
    const { serviceProductSelected, exceededSizeLimit } = this.state;
    const { attachment } = this.props.fieldEvaluation.form;

    const {
      serviceProduct,
      serviceSubProduct,
      form
    } = this.props.productDetails;
    const { accountDetails } = this.props;

    let isPHEdit = Common.isPhEditing(form.isManualPricing);

    return (
      <div>
        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Form.Field>
                <label>* Project Category</label>
                <Dropdown
                  compact
                  selection
                  placeholder="Select Project Category"
                  options={
                    serviceProduct.data
                      ? this.getDropdownList(serviceProduct.data)
                      : null
                  }
                  //defaultValue={serviceProductSelected}
                  value={serviceProductSelected}
                  selectOnBlur={false}
                  name="serviceProductId"
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>

          {serviceProduct.data && serviceProduct.data.length > 0 ? (
            <Grid.Row columns={4}>
              <Grid.Column>
                <Form.Field
                  className={`rad-chk-field ${this.getErrorForChkClass(
                    "serviceSubProductId"
                  )}`}
                >
                  <label>* Equipment Type</label>
                  <Dropdown
                    compact
                    selection
                    placeholder="Select Equipment Type"
                    options={
                      ((serviceSubProduct || {}).data || []).length
                        ? this.getDropdownList(serviceSubProduct.data)
                        : this.getDropdownList(productType)
                    }
                    onChange={this.onChangeServiceSubProduct.bind(this)}
                    selectOnBlur={false}
                    // defaultValue={form.serviceSubProductCode}
                    value={form.serviceSubProductCode}
                    name="serviceSubProductId"
                  />
                  {this.getErrorForChk("serviceSubProductId")}
                </Form.Field>
              </Grid.Column>
              <Grid.Column>
                <Form.Field
                  id="form-input-control-quantity"
                  control={Input}
                  label="* # of Units (ea)"
                  min="1"
                  max="15"
                  type="number"
                  name="quantity"
                  onChange={this.handleChange}
                  value={form.quantity || ""}
                  error={this.getErrorFor("quantity")}
                />
              </Grid.Column>

              {/* PH edit if manual pricing */}
              {isPHEdit ? (
                <Grid.Column>
                  <Form.Field
                    control={Input}
                    label="Total Price - USD"
                    type="number"
                    name="totalPriceByPh"
                    onChange={this.handleChange}
                    value={form.totalPriceByPh || ""}
                    error={this.getErrorFor("totalPriceByPh")}
                  />
                </Grid.Column>
              ) : null}
            </Grid.Row>
          ) : null}

          {form.isPanelboard ? (
            <Grid.Row columns={3}>
              <Grid.Column>
                <Form.Field
                  className={`rad-chk-field ${this.getErrorForChkClass(
                    "panelboardOver200"
                  )}`}
                >
                  * Is the Panelboard Main breaker rated over 200A?
                </Form.Field>
                <Form.Field
                  className={this.getErrorForChkClass("panelboardOver200")}
                >
                  <Checkbox
                    radio
                    label="Yes"
                    name="panelboardOver200"
                    value={true}
                    checked={form.panelboardOver200 === true}
                    onChange={this.onChangeOver200RadioGroup.bind(this)}
                  />
                  &nbsp;&nbsp;&nbsp;
                  <Checkbox
                    radio
                    label="No"
                    name="panelboardOver200"
                    value={false}
                    checked={form.panelboardOver200 === false}
                    onChange={this.onChangeOver200RadioGroup.bind(this)}
                  />
                  {this.getErrorForChk("panelboardOver200")}
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
          ) : null}

          {form.isSwitchboard ? (
            <Grid.Row columns={2}>
              <Grid.Column>
                <Form.Field
                  className={`rad-chk-field ${this.getErrorForChkClass(
                    "switchboardOver600"
                  )}`}
                >
                  * Is the Switchboard/Switchgear rated over 600 VAC?
                </Form.Field>
                <Form.Field
                  className={this.getErrorForChkClass("switchboardOver600")}
                >
                  <Checkbox
                    radio
                    label="Yes"
                    name="switchboardOver600"
                    value={true}
                    checked={form.switchboardOver600 === true}
                    onChange={this.onChangeOver600RadioGroup.bind(this)}
                  />
                  &nbsp;&nbsp;&nbsp;
                  <Checkbox
                    radio
                    label="No"
                    name="switchboardOver600"
                    value={false}
                    checked={form.switchboardOver600 === false}
                    onChange={this.onChangeOver600RadioGroup.bind(this)}
                  />
                  {this.getErrorForChk("switchboardOver600")}
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
          ) : null}

          {form.isPanelboard || form.isSwitchboard ? (
            <Grid.Row columns={7}>
              <Grid.Column>
                <Form.Field
                  className={`rad-chk-field ${this.getErrorForChkClass(
                    "propertyType"
                  )}`}
                >
                  * Property Type
                </Form.Field>
                <Form.Field
                  className={`inline-flex ${this.getErrorForChkClass(
                    "propertyType"
                  )}`}
                >
                  <Checkbox
                    radio
                    label="Residential"
                    name="propertyType"
                    value={Property.residential}
                    checked={form.propertyType == Property.residential}
                    onChange={this.onChangePropertyTypeRadioGroup.bind(this)}
                  />
                  {this.getErrorForChk("propertyType")}
                  <Popup
                    trigger={<Icon name="info circle" />}
                    content="Single Family Residence"
                    size="mini"
                  />
                  &nbsp;&nbsp;&nbsp;
                  <Checkbox
                    radio
                    label="Commercial"
                    name="propertyType"
                    value={Property.commercial}
                    checked={form.propertyType == Property.commercial}
                    onChange={this.onChangePropertyTypeRadioGroup.bind(this)}
                  />
                  <Popup
                    trigger={<Icon name="info circle" />}
                    content="Commercial Building, Multi-Family or Apartments"
                    size="mini"
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
          ) : null}
        </Grid>

        {form.quantity ? (
          parseInt(form.quantity) > 1 ? (
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <Form.Field
                    className={`rad-chk-field ${this.getErrorForChkClass(
                      "isInstalledSameLocation"
                    )}`}
                  >
                    * Will all units be installed at the same location?
                  </Form.Field>
                  <Form.Field
                    className={this.getErrorForChkClass(
                      "isInstalledSameLocation"
                    )}
                  >
                    <Checkbox
                      radio
                      label="Yes"
                      name="isInstalledSameLocation"
                      value={true}
                      checked={form.isInstalledSameLocation === true}
                      onChange={this.onChangeInstalledSameLocationRadioGroup.bind(
                        this
                      )}
                    />
                    &nbsp;&nbsp;&nbsp;
                    <Checkbox
                      radio
                      label="No"
                      name="isInstalledSameLocation"
                      value={false}
                      checked={form.isInstalledSameLocation === false}
                      onChange={this.onChangeInstalledSameLocationRadioGroup.bind(
                        this
                      )}
                    />
                    {this.getErrorForChk("isInstalledSameLocation")}
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          ) : null
        ) : null}

        {form.isInstalledSameLocation && parseInt(form.quantity) > 1 ? (
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Form.Field
                  className={`rad-chk-field ${this.getErrorForChkClass(
                    "isEvaluatedSameVisit"
                  )}`}
                >
                  * Can all of the units be evaluated during the same day/visit?
                </Form.Field>
                <Form.Field
                  className={this.getErrorForChkClass("isEvaluatedSameVisit")}
                >
                  <Checkbox
                    radio
                    label="Yes"
                    name="isEvaluatedSameVisit"
                    value={true}
                    checked={form.isEvaluatedSameVisit === true}
                    onChange={this.onChangeEvaluatedSameVisitRadioGroup.bind(
                      this
                    )}
                  />
                  &nbsp;&nbsp;&nbsp;
                  <Checkbox
                    radio
                    label="No"
                    name="isEvaluatedSameVisit"
                    value={false}
                    checked={form.isEvaluatedSameVisit === false}
                    onChange={this.onChangeEvaluatedSameVisitRadioGroup.bind(
                      this
                    )}
                  />
                  {this.getErrorForChk("isEvaluatedSameVisit")}
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        ) : null}
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Form.Field
                className={`rad-chk-field ${this.getErrorForChkClass(
                  "isExistingUlEquipment"
                )}`}
              >
                * Is there an existing UL label(s) on the equipment?
              </Form.Field>
              <Form.Field
                className={this.getErrorForChkClass("isExistingUlEquipment")}
              >
                <Checkbox
                  radio
                  label="Yes"
                  name="isExistingUlEquipment"
                  value={true}
                  checked={form.isExistingUlEquipment === true}
                  onChange={this.onChangeExistingUlRadioGroup.bind(this)}
                />
                &nbsp;&nbsp;&nbsp;
                <Checkbox
                  radio
                  label="No"
                  name="isExistingUlEquipment"
                  value={false}
                  checked={form.isExistingUlEquipment === false}
                  onChange={this.onChangeExistingUlRadioGroup.bind(this)}
                />
                {this.getErrorForChk("isExistingUlEquipment")}
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Grid>
          <Grid.Row columns={3}>
            <Grid.Column>
              <Form.Field
                className={`rad-chk-field ${this.getErrorForChkClass(
                  "isInvolvedLitigation"
                )}`}
              >
                * Is product involved in current or past litigation?
              </Form.Field>
              <Form.Field
                className={this.getErrorForChkClass("isInvolvedLitigation")}
              >
                <Checkbox
                  radio
                  label="Yes"
                  name="isInvolvedLitigation"
                  value={true}
                  checked={form.isInvolvedLitigation === true}
                  onChange={this.onChangeLitigationRadioGroup.bind(this)}
                />
                &nbsp;&nbsp;&nbsp;
                <Checkbox
                  radio
                  label="No"
                  name="isInvolvedLitigation"
                  value={false}
                  checked={form.isInvolvedLitigation === false}
                  onChange={this.onChangeLitigationRadioGroup.bind(this)}
                />
                {this.getErrorForChk("isInvolvedLitigation")}
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        {form.isInvolvedLitigation ? (
          <Grid>
            <Grid.Row columns={2}>
              <Grid.Column>
                <label>Describe</label>
                <TextArea
                  placeholder="Litigation Description"
                  name="litigationDescription"
                  onChange={this.handleChange}
                  value={form.litigationDescription || ""}
                  maxLength="1000"
                  autoComplete="off"
                  className="margin-top-5"
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        ) : null}
        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column>
              <label>
                Additional Information relevant to your project you want us to
                know
              </label>
              &nbsp;
              <Popup
                trigger={<Icon name="info circle" />}
                content="Permit #, reason for Field Evaluation, etc"
                size="mini"
              />
              <TextArea
                placeholder="Additional Information relevant to your project you want us to know"
                name="additionalInfo"
                onChange={this.handleChange}
                value={form.additionalInfo || ""}
                autoComplete="off"
                className="margin-top-5"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Grid>
          <Grid.Row columns={4}>
            <Grid.Column>
              <Form.Field>
                <label>Upload Technical Documents</label>
                <div style={{ float: "left", marginRight: "15px" }}>
                  <Button
                    primary
                    as="label"
                    htmlFor="file"
                    type="button"
                    style={{ border: "none" }}
                  >
                    Choose File
                  </Button>
                  <input
                    type="file"
                    id="file"
                    name="file"
                    style={{ display: "none" }}
                    onChange={this.handleFileChange}
                  />
                </div>
                <div style={{ clear: "both" }}></div>
                <Divider hidden fitted />
                <div hidden={!exceededSizeLimit}>
                  <Label basic color="red" pointing>
                    File should not exceed more than 4GB.
                  </Label>
                </div>
                <div style={{ float: "left" }} hidden={!form.fileName}>
                  <div style={{ position: "relative" }}>
                    <Icon name="file" size={"huge"}></Icon>
                  </div>

                  {form.fileName || ""}
                </div>
                <div style={{ clear: "both" }}>
                  <a
                    href={
                      attachment &&
                      attachment.length > 0 &&
                      attachment[0].filePath
                    }
                    target="_blank"
                  >
                    {attachment &&
                      attachment.length > 0 &&
                      attachment[0].originalFileName}
                  </a>
                </div>
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    productDetails: state.feProductDetails,
    fieldEvaluation: state.fieldEvaluation,
    accountDetails: state.accountSettings
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    form: (result) => dispatch(actions.updateForm()),
    getAreas: () => dispatch(actions.getAreas()),
    getServiceAreas: (areaId) => dispatch(actions.getServiceAreas(areaId)),
    getServiceProducts: (serviceType) =>
      dispatch(actions.getServiceProducts(serviceType)),
    getServiceSubProducts: (serviceProductId) =>
      dispatch(actions.getServiceSubProducts(serviceProductId)),
    clearServiceAreas: () => dispatch(actions.clearServiceAreas()),
    clearServiceProducts: () => dispatch(actions.clearServiceProducts()),
    clearServiceSubProducts: () => dispatch(actions.clearServiceSubProducts())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FeProductDetails);
