import React from "react";
import { Segment, List, Grid } from "semantic-ui-react";

const FooterBlock = () => {
  let year = new Date().getFullYear();

  return (
    <Segment className="com-footer-container" basic>
      <Grid verticalAlign="middle" className="print-hidden">
        <Grid.Column>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            className="logo"
          >
            <title>UL Logo</title>
            <path d="M10,20C4.5,20,0,15.5,0,10C0,4.5,4.5,0,10,0c5.5,0,10,4.5,10,10C20,15.5,15.5,20,10,20 M10,18.1c4.5,0,8.1-3.6,8.1-8.1 c0-4.5-3.6-8.1-8.1-8.1c-4.5,0-8.1,3.6-8.1,8.1C1.9,14.5,5.5,18.1,10,18.1" />{" "}
            <path d="M5.1,4.6H7v5.9c0,0.4,0.1,0.7,0.6,0.7c0.5,0,0.6-0.4,0.6-0.8V4.6h1.8v5.7c0,1.3-0.6,2.4-2.5,2.4c-1.6,0-2.5-0.7-2.5-2.4 V4.6" />{" "}
            <polyline points="10.8,15.4 10.8,7.5 12.7,7.5 12.7,13.8 14.9,13.8 14.9,15.4 10.8,15.4" />
          </svg>
        </Grid.Column>
        <Grid.Column width={8}>
          <label className="label">
            UL and the UL logo are trademarks of UL LLC &copy; {year}. All
            rights reserved.
          </label>
        </Grid.Column>
        <Grid.Column width={6} className="actionlink">
          <List horizontal link style={{ fontWeight: "bold" }}>
            <List.Item>
              <a
                href="https://www.ul.com/customer-resources/online-policies/"
                target="_blank"
                rel="noopener noreferrer"
                className="item"
              >
                Online Policies
              </a>
            </List.Item>
            <List.Item>
              <a
                href="https://www.ul.com/customer-resources/online-policies/about-cookies/"
                target="_blank"
                rel="noopener noreferrer"
                className="item"
              >
                About Cookies
              </a>
            </List.Item>
            <List.Item>
              <a
                href="https://app-de.onetrust.com/app/#/webform/e348820f-6ffc-48f0-aa9f-2d80a79304fc"
                target="_blank"
                rel="noopener noreferrer"
                className="item"
              >
                Data Subject Access Request Portal
              </a>
            </List.Item>
          </List>
        </Grid.Column>
      </Grid>
    </Segment>
  );
};

export default FooterBlock;
