import {
  ACTIVE_MANUFACTURER_REQUEST,
  ACTIVE_MANUFACTURER_SUCCESS,
  ACTIVE_MANUFACTURER_FAILURE,
  ACTIVE_MANUFACTURER_CHANGE_PAGE,
  ACTIVE_MANUFACTURER_SORT,
  FILE_NUMBER_REQUEST,
  FILE_NUMBER_SUCCESS,
  FILE_NUMBER_FAILURE,
  VOLUME_REQUEST,
  VOLUME_SUCCESS,
  VOLUME_FAILURE,
  CHANGE_FILE_NUMBER,
  CHANGE_VOLUME,
  FILTER_ITEM_SELECT,
  FILTER_ITEM_SELECT_ALL,
  SELECT_FILE_VOLUME,
  SELECT_ALL_FILE_VOLUMES,
  CHECK_CUSTOMER_LABEL_REQUEST,
  CHECK_CUSTOMER_LABEL_FAILURE,
  CHECK_CUSTOMER_LABEL_SUCCESS,
  CHECK_IFVS_IF_UP_SUCCESS,
  CHECK_IFVS_IF_UP_REQUEST,
  CHECK_IFVS_IF_UP_FAILURE,
  CHECK_SERVICE_CONTRACT_REQUEST,
  CHECK_SERVICE_CONTRACT_FAILURE,
  CHECK_SERVICE_CONTRACT_SUCCESS,
  CLEAN_ALL_FILTERS,
  CLEAR_SELECTION
} from "./applicant-dashboard.type";
import api from "../../../services/api";

const activeManufacturerRequest = () => {
  return { type: ACTIVE_MANUFACTURER_REQUEST };
};

const activeManufacturerFailure = (error) => {
  return (dispatch) =>
    dispatch({ type: ACTIVE_MANUFACTURER_FAILURE, payload: error });
};

const activeManufacturerSuccess = (response, firstload) => {
  return (dispatch) =>
    dispatch({
      type: ACTIVE_MANUFACTURER_SUCCESS,
      payload: response,
      firstload: firstload
    });
};

const volumeRequest = () => {
  return { type: VOLUME_REQUEST };
};

const volumeFailure = (error) => {
  return (dispatch) => dispatch({ type: VOLUME_FAILURE, payload: error });
};

const volumeSuccess = (response) => {
  return (dispatch) => dispatch({ type: VOLUME_SUCCESS, payload: response });
};

const fileNumberRequest = () => {
  return { type: FILE_NUMBER_REQUEST };
};

const fileNumberFailure = (error) => {
  return (dispatch) => dispatch({ type: FILE_NUMBER_FAILURE, payload: error });
};

const fileNumberSuccess = (response) => {
  return (dispatch) =>
    dispatch({ type: FILE_NUMBER_SUCCESS, payload: response });
};

const checkCustomerRequest = () => {
  return { type: CHECK_CUSTOMER_LABEL_REQUEST };
};

const checkCustomerFailure = (error) => {
  return (dispatch) =>
    dispatch({ type: CHECK_CUSTOMER_LABEL_FAILURE, payload: error });
};

const checkCustomerSuccess = (response) => {
  return (dispatch) =>
    dispatch({ type: CHECK_CUSTOMER_LABEL_SUCCESS, payload: response });
};

const checkIfvsIfUpSuccess = (response) => {
  return (dispatch) =>
    dispatch({ type: CHECK_IFVS_IF_UP_SUCCESS, payload: response });
};

const checkIfvsIfUpRequest = () => {
  return { type: CHECK_IFVS_IF_UP_REQUEST };
};

const checkIfvsIfUpFailure = (error) => {
  return (dispatch) =>
    dispatch({ type: CHECK_IFVS_IF_UP_FAILURE, payload: error });
};

const checkServiceContractUpSuccess = (response) => {
  return (dispatch) =>
    dispatch({ type: CHECK_SERVICE_CONTRACT_SUCCESS, payload: response });
};

const checkServiceContractUpRequest = () => {
  return { type: CHECK_SERVICE_CONTRACT_REQUEST };
};

const checkServiceContractUpFailure = (error) => {
  return (dispatch) =>
    dispatch({ type: CHECK_SERVICE_CONTRACT_FAILURE, payload: error });
};

const dispatchRequest = (obj, type) => {
  return {
    data: obj,
    type: type
  };
};

export const changePage = (
  sortColumn,
  sortOrder,
  activePage,
  pageSize,
  filters,
  hasFilter,
  isFilterInitial,

  labelOrderType
) => {
  return async (dispatch) => {
    dispatch({
      type: ACTIVE_MANUFACTURER_CHANGE_PAGE,
      payload: { activePage }
    });
    dispatch(activeManufacturerRequest());
    await api.Applicant.getManufacturers(
      sortColumn,
      sortOrder,
      activePage,
      pageSize,
      filters,
      hasFilter,
      isFilterInitial,
      labelOrderType
    )
      .then((result) => {
        dispatch(activeManufacturerSuccess(result));
      })
      .catch((result) => {
        let errors = [];
        for (let prop in result.errors) {
          errors = result.errors[prop].map((e) => e);
        }
        dispatch(activeManufacturerFailure(errors));
      });
  };
};

export const sort = (
  sortColumn,
  sortOrder,
  activePage,
  pageSize,
  fileNumber,
  volume,
  labelOrderType
) => {
  return async (dispatch) => {
    dispatch({
      type: ACTIVE_MANUFACTURER_SORT,
      payload: { sortColumn, sortOrder }
    });
    dispatch(activeManufacturerRequest());
    await api.Applicant.getManufacturers(
      activePage,
      pageSize,
      fileNumber,
      volume,
      sortColumn,
      sortOrder,
      labelOrderType
    )
      .then((result) => {
        dispatch(activeManufacturerSuccess(result));
      })
      .catch((result) => {
        let errors = [];
        for (let prop in result.errors) {
          errors = result.errors[prop].map((e) => e);
        }
        dispatch(activeManufacturerFailure(errors));
      });
  };
};

export const changeFileNumber = (value, text, pageSize, labelOrderType) => {
  return async (dispatch) => {
    dispatch({ type: CHANGE_FILE_NUMBER, payload: value });
    dispatch(getVolumes(text, labelOrderType));
    dispatch(changePage(1, pageSize, text, null));
  };
};

export const changeVolume = (value, text, fileNumber, pageSize) => {
  return async (dispatch) => {
    dispatch({ type: CHANGE_VOLUME, payload: value });
    dispatch(changePage(1, pageSize, fileNumber, text));
  };
};

export const getVolumes = (fileNumber, labelOrderType) => {
  return async (dispatch) => {
    dispatch(volumeRequest());
    await api.Applicant.getVolumes(fileNumber, labelOrderType)
      .then((result) => {
        dispatch(volumeSuccess(result));
      })
      .catch((result) => {
        let errors = [];
        for (let prop in result.errors) {
          errors = result.errors[prop].map((e) => e);
        }
        dispatch(volumeFailure(errors));
      });
  };
};

export const getFileNumbers = (labelOrderType) => {
  return async (dispatch) => {
    dispatch(fileNumberRequest());
    await api.Applicant.getFileNumbers(labelOrderType)
      .then((result) => {
        dispatch(fileNumberSuccess(result));
        return result;
      })
      .catch((result) => {
        let errors = [];
        for (let prop in result.errors) {
          errors = result.errors[prop].map((e) => e);
        }
        dispatch(fileNumberFailure(errors));
      });
  };
};

export const getActiveManufacturers = (
  sortColumn,
  sortOrder,
  currentPage,
  pageSize,
  filters,
  hasFilter,
  isFilterInitial,
  labelOrderType,
  isFirstLoad
) => {
  return async (dispatch) => {
    dispatch({
      type: ACTIVE_MANUFACTURER_CHANGE_PAGE,
      payload: { currentPage }
    });
    dispatch(activeManufacturerRequest());
    return await api.Applicant.getManufacturers({
      sortColumn,
      sortOrder,
      currentPage,
      pageSize,
      filters,
      hasFilter,
      isFilterInitial,
      labelOrderType,
      isFirstLoad
    })
      .then((result) => {
        dispatch(activeManufacturerSuccess(result, isFirstLoad));
        return result;
      })
      .catch((result) => {
        let errors = [];
        for (let prop in result.errors) {
          errors = result.errors[prop].map((e) => e);
        }
        dispatch(activeManufacturerFailure(errors));
      });
  };
};

export const selectFilterItem = (data, isSelected, activeFilterIndex) => {
  return async (dispatch) => {
    dispatch(
      dispatchRequest(
        { data, isSelected, activeFilterIndex },
        FILTER_ITEM_SELECT
      )
    );
  };
};

export const selectAllFilters = (isSelected, activeFilterIndex) => {
  return async (dispatch) => {
    dispatch(
      dispatchRequest({ isSelected, activeFilterIndex }, FILTER_ITEM_SELECT_ALL)
    );
  };
};

export const selectFileVolume = (data, isSelected) => {
  return async (dispatch) => {
    dispatch(dispatchRequest({ data, isSelected }, SELECT_FILE_VOLUME));
  };
};

export const selectAllFileVolumes = (isSelectedAll) => {
  return async (dispatch) => {
    dispatch(dispatchRequest({ isSelectedAll }, SELECT_ALL_FILE_VOLUMES));
  };
};

export const cleanAllFilters = () => {
  return async (dispatch) => {
    dispatch(dispatchRequest({}, CLEAN_ALL_FILTERS));
  };
};

export const checkCustomerPilotIfExist = (
  fileNumber,
  manufacturerAccountNumber
) => {
  return async (dispatch) => {
    dispatch(checkCustomerRequest());
    const result = await api.Applicant.checkCustomerPilotIfExist(
      fileNumber,
      manufacturerAccountNumber
    );

    if (result) {
      dispatch(checkCustomerSuccess());
    } else {
      dispatch(checkCustomerFailure());
    }
    return result;
  };
};

export const checkIfvsIfUp = (labelOrderType) => {
  return async (dispatch) => {
    dispatch(checkIfvsIfUpRequest());
    const result = await api.Applicant.checkIfvsIfUp(labelOrderType);

    if (result) {
      dispatch(checkIfvsIfUpSuccess(result));
    } else {
      dispatch(checkIfvsIfUpFailure(result));
    }
    return result;
  };
};

export const checkIfServiceContractUp = () => {
  return async (dispatch) => {
    dispatch(checkServiceContractUpRequest());
    const result = await api.Applicant.checkIfServiceContractUp();

    if (result) {
      dispatch(checkServiceContractUpSuccess(result));
    } else {
      dispatch(checkServiceContractUpFailure(result));
    }
    return result;
  };
};

export const clearSelection = () => {
  return (dispatch) => dispatch({ type: CLEAR_SELECTION });
};
