import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Redirect, Link } from "react-router-dom";
import { Button, Segment, Container, Card } from "semantic-ui-react";
import { registerSuccessCard } from "../../assets/js/common";

import HeaderBlock from "../../components/header/header-block";
import FooterBlock from "../../components/footer/footer-block";

import { CustomTitleLabel } from "../../components/labels/labels";

class RegisterSuccess extends Component {
  render() {
    document.getElementById("root").style.backgroundColor = "#0b3c61";

    if (this.props.userId) {
      return <Redirect to="/dashboard-selection" />;
    }

    return (
      <Fragment>
        <HeaderBlock />
        <Container style={registerSuccessCard}>
          <Card fluid>
            <Card.Content>
              <h2>Registration request sent</h2>
              <CustomTitleLabel
                message="Thank you for requesting access. Our team at UL is reviewing
                your request and will send you an email to finish adding you to
                our secure system."
              />
            </Card.Content>
            <Segment textAlign="right">
              <Button as={Link} to="/" basic color="blue">
                Return to login page
              </Button>
            </Segment>
          </Card>
        </Container>
        <FooterBlock />
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  userId: state.auth.userId
});

export default connect(mapStateToProps)(RegisterSuccess);
