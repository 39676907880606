import React, { Component } from "react";
import { Header, Icon, Image, Menu, Segment, Sidebar } from "semantic-ui-react";

import { connect } from "react-redux";

class SideBarFilterOptions extends Component {
  state = {};

  handleToggle = () => {};

  render() {
    const { isVisible } = this.props;

    return (
      <Sidebar.Pushable as={Segment}>
        <Sidebar
          as={Menu}
          animation="overlay"
          icon="labeled"
          vertical
          visible={isVisible}
          width="wide"
        >
          <Menu.Item as="a">
            <Icon name="home" />
            Home
          </Menu.Item>
          <Menu.Item as="a">
            <Icon name="gamepad" />
            Games
          </Menu.Item>
          <Menu.Item as="a">
            <Icon name="camera" />
            Channels
          </Menu.Item>
        </Sidebar>

        <Sidebar.Pusher></Sidebar.Pusher>
      </Sidebar.Pushable>
    );
  }
}

export default SideBarFilterOptions;
