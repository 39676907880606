import * as types from "./notifications.type";
import api from "../../../services/api";

export const notificationsResult = (obj, type) => {
  return {
    data: obj,
    type: type
  };
};

export const getNotifications = () => {
  return async dispatch => {
    dispatch(notificationsResult(null, types.FE_NOTIFICATIONS_REQUEST));

    await api.FieldEvaluation.getNotifications()
      .then(result => {
        dispatch(notificationsResult(result, types.FE_NOTIFICATIONS_SUCCESS));
      })
      .catch(result => {
        dispatch(notificationsResult(result, types.FE_NOTIFICATIONS_FAILURE));
      });
  };
};
