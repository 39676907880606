import * as types from "./field-evaluation.type";
import * as productDetails from "./product-details/product-details.reducer";
import _ from "lodash";
import update from "immutability-helper";
import { unixToLocaleDate } from "./field-evaluation-helper";

let initialState = {
  data: [],
  isCheckoutSuccess: false,
  isLoadingGetAppointments: false,
  requestNumber: null,
  isSuccessGetCountries: false,
  isSuccessGetStates: false,
  isSuccessGetCities: false,
  isSuccessGetOrderDetails: false,
  countryOptionsPrelimProjectDetails: [],
  countryOptionsFinalProjectDetails: [],
  stateOptionsPrelimProjectDetails: [],
  stateOptionsFinalProjectDetails: [],
  cityOptionsPrelimProjectDetails: [],
  cityOptionsFinalProjectDetails: [],
  countryOptionsPrelimEngineerDetails: [],
  countryOptionsFinalEngineerDetails: [],
  stateOptionsPrelimEngineerDetails: [],
  stateOptionsFinalEngineerDetails: [],
  cityOptionsPrelimEngineerDetails: [],
  cityOptionsFinalEngineerDetails: [],
  countryOptionsAhjDetails: [],
  stateOptionsAhjDetails: [],
  cityOptionsAhjDetails: [],
  appointments: [],
  form: {
    otherDetails: {
      isReadyToScheduleNow: false,
      isCopyOrder: false,
      isFromOffered: false,
      showErrors: false,
      validationErrors: {}
    },
    accountSettings: {
      data: {},
      showErrors: false,
      validationErrors: {}
    },
    orderDetails: {
      data: {},
      showErrors: false,
      validationErrors: {}
    },
    applicantDetails: {
      applicantDetails: {
        data: null,
        locationType: 1,
        showErrors: false,
        validationErrors: {}
      },
      applicantBillToLocationDetails: {
        data: {},
        locationType: 2,
        showErrors: false,
        validationErrors: {}
      },
      quoteAcceptanceDetails: {
        data: {},
        showErrors: false,
        validationErrors: {}
      },
      isSameAsAbove: false,
      showErrors: false,
      validationErrors: {}
    },
    siteDetails: {
      data: {},
      preliminarySiteLocationSection: {
        data: [],
        siteType: 1,
        showErrors: false,
        validationErrors: {}
      },
      finalInstallationSiteSection: {
        data: [],
        siteType: 2,
        showErrors: false,
        validationErrors: {}
      },
      authorityHavingJurisdictionDetails: {
        data: null,
        showErrors: false,
        validationErrors: {}
      },
      scheduleNow: "false",
      showPrelimLocation: null,
      prelimSameAsApplicant: false,
      finalSameAsApplicant: false,
      isFinalSameAsApplicant: false,
      isSiteDetails: true,
      showErrors: false,
      validationErrors: {}
    },
    productDetails: {
      data: [],
      initialState: {
        orders: [],
        form: {
          showErrors: false,
          validationErrors: {}
        }
      },
      showErrors: false,
      validationErrors: {}
    },
    reservationDetails: {
      preliminarySiteLocationSection: {
        data: null,
        siteType: 1,
        showErrors: false,
        validationErrors: {},
        ppeSelections: "",
        trainingSelections: ""
      },
      finalSiteLocationSection: {
        data: null,
        siteType: 2,
        showErrors: false,
        validationErrors: {}
      },
      authorityHavingJurisdictionDetails: {
        data: null,
        showErrors: false,
        validationErrors: {}
      },
      ppeSelections: [
        {
          value: 1,
          text: "Safety Glasses",
          checked: false
        },
        {
          value: 2,
          text: "Fall Protection / Restraint Harness",
          checked: false
        },
        {
          value: 3,
          text: "Hard Hat",
          checked: false
        },
        {
          value: 4,
          text: "Hearing Protection",
          checked: false
        },
        {
          value: 5,
          text: "Protective Clothing",
          checked: false
        },
        {
          value: 6,
          text: "Face Shield",
          checked: false
        },
        {
          value: 7,
          text: "Protective Gloves",
          checked: false
        },
        {
          value: 8,
          text: "Hard Toe Shoes",
          checked: false
        },
        {
          value: 9,
          text: "High Visibility Vest",
          checked: false
        },
        {
          value: 10,
          text: "Breathing Respirator",
          checked: false
        }
      ],
      trainingSelections: [
        {
          value: 1,
          text: "On-site Training",
          checked: false,
          trainingTwoHours: null
        },
        {
          value: 2,
          text: "OSHA 10",
          checked: false
        },
        {
          value: 3,
          text: "Other",
          checked: false
        }
      ],
      isSiteDetails: false,
      showErrors: false,
      validationErrors: {}
    },
    attachment: null
  }
};

const fieldEvaluationReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
    case types.CREATE_FE_ORDER_REQUEST:
      var result = Object.assign({}, state, {
        data: action.data || initialState.data
      });

      result.data.isLoading = true;

      return result;
    case types.CREATE_FE_ORDER_SUCCESS:
      return Object.assign({}, state, {
        data: action.data || initialState.data,
        requestNumber: action.data.number,
        isCheckoutSuccess: true
      });
    case types.CREATE_FE_ORDER_FAILURE:
      return Object.assign({}, state, {
        data: action.data || initialState.data,
        isCheckoutSuccess: false
      });
    case types.ORACLE_ORDER_DETAILS_REQUEST:
      var result = Object.assign({}, state, {
        data: action.data || initialState.data
      });

      result.data.isLoading = true;

      return result;
    case types.ORACLE_ORDER_DETAILS_FAILURE:
    case types.ORACLE_ORDER_DETAILS_SUCCESS:
      var result = Object.assign({}, state, {
        data: action.data || initialState.data
      });

      result.data.isLoading = false;

      return result;
    case types.ORACLE_ORDER_DETAILS_RESET:
      var result = Object.assign({}, state, {
        data: initialState.data
      });

      result.data.isLoading = false;

      return result;
    case types.FE_ORDER_DETAILS_REQUEST:
      var result = Object.assign({}, state, {
        data: action.data || initialState.data
      });

      result.data.isLoading = true;

      return result;
    case types.FE_ORDER_DETAILS_FAILURE:
      var result = Object.assign({}, state, {
        data: action.data || initialState.data
      });
      return result;
    case types.FE_ORDER_DETAILS_SUCCESS:
      var result = Object.assign({}, state, {
        data: initialState.data,
        form: initialState.form
      });
      var scheduleUnixTime = null;

      result.form.otherDetails.isReadyToScheduleNow =
        action.data.data.orderDetails.isReadyToScheduleNow;

      result.form.orderDetails = action.data.data.orderDetails;
      result.form.orderDetails.showErrors = false;
      result.form.orderDetails.validationErrors = {};

      result.form.otherDetails.customerReferenceNumber =
        action.data.data.orderDetails.clientReferenceNumber;

      result.form.applicantDetails.applicantDetails = action.data.data.applicants.filter(
        (f) => f.locationType === 1
      )[0];

      if (result.form.applicantDetails.applicantDetails) {
        result.form.applicantDetails.applicantDetails.showErrors = false;
        result.form.applicantDetails.applicantDetails.validationErrors = {};
      }

      result.form.applicantDetails.applicantBillToLocationDetails =
        action.data.data.applicants.length > 1
          ? action.data.data.applicants.filter((f) => f.locationType === 2)[0]
          : result.form.applicantDetails.applicantBillToLocationDetails;

      if (result.form.applicantDetails.applicantBillToLocationDetails) {
        result.form.applicantDetails.applicantBillToLocationDetails.showErrors = false;
        result.form.applicantDetails.applicantBillToLocationDetails.validationErrors = {};
      }

      result.form.applicantDetails.quoteAcceptanceDetails =
        action.data.data.quoteAcceptances[0];

      if (result.form.applicantDetails.quoteAcceptanceDetails) {
        result.form.applicantDetails.quoteAcceptanceDetails.showErrors = false;
        result.form.applicantDetails.quoteAcceptanceDetails.validationErrors = {};
      }

      result.form.reservationDetails.preliminarySiteLocationSection = action.data.data.engineers.filter(
        (f) => f.siteType === 1
      )[0];

      scheduleUnixTime =
        result.form.reservationDetails.preliminarySiteLocationSection
          .scheduleUnixTime;
      result.form.reservationDetails.preliminarySiteLocationSection.scheduleDate = scheduleUnixTime
        ? new Date(scheduleUnixTime * 1000)
        : null;

      if (result.form.reservationDetails.preliminarySiteLocationSection) {
        result.form.siteDetails.scheduleNow = "true";
        result.form.reservationDetails.preliminarySiteLocationSection.showErrors = false;
        result.form.reservationDetails.preliminarySiteLocationSection.validationErrors = {};
      }

      result.form.reservationDetails.finalSiteLocationSection =
        action.data.data.engineers.length > 1
          ? action.data.data.engineers.filter((f) => f.siteType === 2)[0]
          : result.form.reservationDetails.finalSiteLocationSection;

      if (result.form.reservationDetails.finalSiteLocationSection) {
        result.form.reservationDetails.finalSiteLocationSection.showErrors = false;
        result.form.reservationDetails.finalSiteLocationSection.validationErrors = {};
      } else {
        result.form.reservationDetails.finalSiteLocationSection = {};
      }

      result.form.siteDetails.preliminarySiteLocationSection = action.data.data.sites.filter(
        (f) => f.siteType === 1
      )[0];

      if (result.form.siteDetails.preliminarySiteLocationSection) {
        result.form.siteDetails.preliminarySiteLocationSection.showErrors = false;
        result.form.siteDetails.preliminarySiteLocationSection.validationErrors = {};
      }

      result.form.siteDetails.finalInstallationSiteSection =
        action.data.data.sites.length > 1
          ? action.data.data.sites.filter((f) => f.siteType === 2)[0]
          : result.form.siteDetails.finalInstallationSiteSection;

      if (result.form.siteDetails.finalInstallationSiteSection) {
        result.form.siteDetails.finalInstallationSiteSection.showErrors = false;
        result.form.siteDetails.finalInstallationSiteSection.validationErrors = {};
      }

      if (result.form.siteDetails.preliminarySiteLocationSection?.residenceName)
        result.form.siteDetails.showPrelimLocation = "false";
      else result.form.siteDetails.showPrelimLocation = "true";

      // result.form.reservationDetails.authorityHavingJurisdictionDetails =
      //   action.data.data.authorityJurisdictions;

      // if (result.form.reservationDetails.authorityHavingJurisdictionDetails) {
      //   result.form.reservationDetails.authorityHavingJurisdictionDetails.showErrors = false;
      //   result.form.reservationDetails.authorityHavingJurisdictionDetails.validationErrors = {};
      // }

      result.form.siteDetails.authorityHavingJurisdictionDetails = action.data.data.authorityJurisdictions.filter(
        (f) => f.baseType === 1
      )[0];

      if (result.form.siteDetails.authorityHavingJurisdictionDetails) {
        result.form.siteDetails.authorityHavingJurisdictionDetails.showErrors = false;
        result.form.siteDetails.authorityHavingJurisdictionDetails.validationErrors = {};
      }

      result.form.reservationDetails.authorityHavingJurisdictionDetails = action.data.data.authorityJurisdictions.filter(
        (f) => f.baseType === 2
      )[0];

      if (result.form.reservationDetails.authorityHavingJurisdictionDetails) {
        result.form.reservationDetails.authorityHavingJurisdictionDetails.showErrors = false;
        result.form.reservationDetails.authorityHavingJurisdictionDetails.validationErrors = {};
      }

      result.form.attachment = action.data.data.attachments;

      result.form.productDetails.initialState.orders =
        action.data.data.productItems.length > 0
          ? action.data.data.productItems
          : result.form.productDetails.initialState.orders;
      if (result.form.productDetails.initialState.orders) {
        result.form.productDetails.initialState.orders.map((p) => {
          var temp = {};
          if (p.others) {
            p.others.map((o) => {
              if (o) {
                temp = update(temp, {
                  $merge: _.set({}, o.code, o.info)
                });
              }
            });
            p.others = temp;
          }
        });
      }

      result.form.productDetails.initialState.form.showErrors = false;
      result.form.productDetails.initialState.form.validationErrors = {};

      result.isSuccessGetOrderDetails = true;
      return result;
    case types.UPDATE_ISSUCCESS:
      var result = Object.assign({}, state, {
        data: initialState.data,
        form: initialState.form
      });

      result.isSuccessGetOrderDetails = false;

      return result;
    case types.FE_ORDER_DETAILS_RESET:
      var result = Object.assign({}, state, {
        data: initialState.data
      });

      result.data.isLoading = false;

      return result;
    case types.FORM:
      var result = Object.assign({}, state, {
        form: action.data || initialState.form
      });
      return result;
    case types.CLEAR:
      var result = Object.assign({}, state, {
        data: initialState.data,
        form: initialState.form
      });

      result.form.orderDetails = {
        data: {},
        showErrors: false,
        validationErrors: {}
      };

      result.form.otherDetails.isReadyToScheduleNow = null;
      result.form.otherDetails.customerReferenceNumber = null;
      result.form.otherDetails.isCopyOrder = false;
      result.form.otherDetails = {
        isReadyToScheduleNow: false,
        showErrors: false,
        validationErrors: {}
      };
      result.form.siteDetails.showPrelimLocation = null;

      result.form.siteDetails.finalInstallationSiteSection = {
        data: [],
        showErrors: false,
        validationErrors: {}
      };

      result.form.reservationDetails.preliminarySiteLocationSection = {
        data: [],
        showErrors: false,
        validationErrors: {}
      };

      result.form.reservationDetails.ppeSelections.forEach((item) => {
        item.checked = false;
      });

      result.form.reservationDetails.trainingSelections.forEach((item) => {
        item.checked = false;
        item.trainingTwoHours = null;
      });

      result.form.productDetails = {
        data: [],
        initialState: {
          orders: [],
          form: {
            showErrors: false,
            validationErrors: {}
          }
        },
        showErrors: false,
        validationErrors: {}
      };

      result.isCheckoutSuccess = false;
      result.requestNumber = null;
      result.isSuccessGetCountries = false;
      result.isSuccessGetStates = false;
      result.isSuccessGetCities = false;
      result.form.attachment = null;

      return result;
    case types.GET_COUNTRIES_PRELIM_PROJECT_DETAILS_REQUEST:
      return {
        ...state,
        isSuccessGetCountries: false,
        countryOptionsPrelimProjectDetails: []
      };
    case types.GET_COUNTRIES_PRELIM_PROJECT_DETAILS_SUCCESS:
      return {
        ...state,
        isSuccessGetCountries: true,
        countryOptionsPrelimProjectDetails: action.data.map((c) => {
          return {
            key: c.countryCode,
            value: c.countryCode,
            text: c.countryName
          };
        })
      };
    case types.GET_COUNTRIES_PRELIM_PROJECT_DETAILS_FAILURE:
      return {
        ...state,
        isSuccessGetCountries: false,
        countryOptionsPrelimProjectDetails: []
      };
    case types.GET_CITIES_PRELIM_PROJECT_DETAILS_REQUEST:
      return {
        ...state,
        isSuccessGetCities: false,
        cityOptionsPrelimProjectDetails: [],
        cityOptionsPrelimEngineerDetails: []
      };
    case types.GET_CITIES_PRELIM_PROJECT_DETAILS_SUCCESS:
      return {
        ...state,
        isSuccessGetCities: true,
        cityOptionsPrelimProjectDetails: action.data.map((c) => {
          return {
            key: c.cityName,
            value: c.cityName,
            text: c.cityName
          };
        }),
        cityOptionsPrelimEngineerDetails: action.data.map((c) => {
          return {
            key: c.cityName,
            value: c.cityName,
            text: c.cityName
          };
        })
      };
    case types.GET_CITIES_PRELIM_PROJECT_DETAILS_FAILURE:
      return {
        ...state,
        isSuccessGetCities: false,
        cityOptionsPrelimProjectDetails: [],
        cityOptionsPrelimEngineerDetails: []
      };
    case types.GET_STATES_PRELIM_PROJECT_DETAILS_REQUEST:
      return {
        ...state,
        isSuccessGetStates: false,
        stateOptionsPrelimProjectDetails: [],
        stateOptionsPrelimEngineerDetails: []
      };
    case types.GET_STATES_PRELIM_PROJECT_DETAILS_SUCCESS:
      return {
        ...state,
        isSuccessGetStates: true,
        stateOptionsPrelimProjectDetails: action.data.map((c) => {
          return {
            key: c.stateCode,
            value: c.stateCode,
            text: c.stateName
          };
        }),
        stateOptionsPrelimEngineerDetails: action.data.map((c) => {
          return {
            key: c.stateCode,
            value: c.stateCode,
            text: c.stateName
          };
        })
      };
    case types.GET_STATES_PRELIM_PROJECT_DETAILS_FAILURE:
      return {
        ...state,
        isSuccessGetStates: false,
        stateOptionsPrelimProjectDetails: [],
        stateOptionsPrelimEngineerDetails: []
      };
    case types.GET_COUNTRIES_FINAL_PROJECT_DETAILS_REQUEST:
      return {
        ...state,
        isSuccessGetCountries: false,
        countryOptionsFinalProjectDetails: []
      };
    case types.GET_COUNTRIES_FINAL_PROJECT_DETAILS_SUCCESS:
      return {
        ...state,
        isSuccessGetCountries: true,
        countryOptionsFinalProjectDetails: action.data.map((c) => {
          return {
            key: c.countryCode,
            value: c.countryCode,
            text: c.countryName
          };
        })
      };
    case types.GET_COUNTRIES_FINAL_PROJECT_DETAILS_FAILURE:
      return {
        ...state,
        isSuccessGetCountries: false,
        countryOptionsFinalProjectDetails: []
      };
    case types.GET_CITIES_FINAL_PROJECT_DETAILS_REQUEST:
      return {
        ...state,
        isSuccessGetCities: false,
        cityOptionsFinalProjectDetails: []
      };
    case types.GET_CITIES_FINAL_PROJECT_DETAILS_SUCCESS:
      return {
        ...state,
        isSuccessGetCities: true,
        cityOptionsFinalProjectDetails: action.data.map((c) => {
          return {
            key: c.cityName,
            value: c.cityName,
            text: c.cityName
          };
        })
      };
    case types.GET_CITIES_FINAL_PROJECT_DETAILS_FAILURE:
      return {
        ...state,
        isSuccessGetCities: false,
        cityOptionsFinalProjectDetails: []
      };
    case types.GET_STATES_FINAL_PROJECT_DETAILS_REQUEST:
      return {
        ...state,
        isSuccessGetStates: false,
        stateOptionsFinalProjectDetails: []
      };
    case types.GET_STATES_FINAL_PROJECT_DETAILS_SUCCESS:
      return {
        ...state,
        isSuccessGetStates: true,
        stateOptionsFinalProjectDetails: action.data.map((c) => {
          return {
            key: c.stateCode,
            value: c.stateCode,
            text: c.stateName
          };
        })
      };
    case types.GET_STATES_FINAL_PROJECT_DETAILS_FAILURE:
      return {
        ...state,
        isSuccessGetStates: false,
        stateOptionsFinalProjectDetails: []
      };
    case types.GET_COUNTRIES_PRELIM_ENGR_DETAILS_REQUEST:
      return {
        ...state,
        isSuccessGetCountries: false,
        countryOptionsPrelimEngineerDetails: []
      };
    case types.GET_COUNTRIES_PRELIM_ENGR_DETAILS_SUCCESS:
      return {
        ...state,
        isSuccessGetCountries: true,
        countryOptionsPrelimEngineerDetails: action.data.map((c) => {
          return {
            key: c.countryCode,
            value: c.countryCode,
            text: c.countryName
          };
        })
      };
    case types.GET_COUNTRIES_PRELIM_ENGR_DETAILS_FAILURE:
      return {
        ...state,
        isSuccessGetCountries: false,
        countryOptionsPrelimEngineerDetails: []
      };
    case types.GET_CITIES_PRELIM_ENGR_DETAILS_REQUEST:
      return {
        ...state,
        isSuccessGetCities: false,
        cityOptionsPrelimEngineerDetails: []
      };
    case types.GET_CITIES_PRELIM_ENGR_DETAILS_SUCCESS:
      return {
        ...state,
        isSuccessGetCities: true,
        cityOptionsPrelimEngineerDetails: action.data.map((c) => {
          return {
            key: c.cityName,
            value: c.cityName,
            text: c.cityName
          };
        })
      };
    case types.GET_CITIES_PRELIM_ENGR_DETAILS_FAILURE:
      return {
        ...state,
        isSuccessGetCities: false,
        cityOptionsPrelimEngineerDetails: []
      };
    case types.GET_STATES_PRELIM_ENGR_DETAILS_REQUEST:
      return {
        ...state,
        isSuccessGetStates: false,
        stateOptionsPrelimEngineerDetails: []
      };
    case types.GET_STATES_PRELIM_ENGR_DETAILS_SUCCESS:
      return {
        ...state,
        isSuccessGetStates: true,
        stateOptionsPrelimEngineerDetails: action.data.map((c) => {
          return {
            key: c.stateCode,
            value: c.stateCode,
            text: c.stateName
          };
        })
      };
    case types.GET_STATES_PRELIM_ENGR_DETAILS_FAILURE:
      return {
        ...state,
        isSuccessGetStates: false,
        stateOptionsPrelimEngineerDetails: []
      };
    case types.GET_COUNTRIES_FINAL_ENGR_DETAILS_REQUEST:
      return {
        ...state,
        isSuccessGetCountries: false,
        countryOptionsFinalEngineerDetails: []
      };
    case types.GET_COUNTRIES_FINAL_ENGR_DETAILS_SUCCESS:
      return {
        ...state,
        isSuccessGetCountries: true,
        countryOptionsFinalEngineerDetails: action.data.map((c) => {
          return {
            key: c.countryCode,
            value: c.countryCode,
            text: c.countryName
          };
        })
      };
    case types.GET_COUNTRIES_FINAL_ENGR_DETAILS_FAILURE:
      return {
        ...state,
        isSuccessGetCountries: false,
        countryOptionsFinalEngineerDetails: []
      };
    case types.GET_CITIES_FINAL_ENGR_DETAILS_REQUEST:
      return {
        ...state,
        isSuccessGetCities: false,
        cityOptionsFinalEngineerDetails: []
      };
    case types.GET_CITIES_FINAL_ENGR_DETAILS_SUCCESS:
      return {
        ...state,
        isSuccessGetCities: true,
        cityOptionsFinalEngineerDetails: action.data.map((c) => {
          return {
            key: c.cityName,
            value: c.cityName,
            text: c.cityName
          };
        })
      };
    case types.GET_CITIES_FINAL_ENGR_DETAILS_FAILURE:
      return {
        ...state,
        isSuccessGetCities: false,
        cityOptionsFinalEngineerDetails: []
      };
    case types.GET_STATES_FINAL_ENGR_DETAILS_REQUEST:
      return {
        ...state,
        isSuccessGetStates: false,
        stateOptionsFinalEngineerDetails: []
      };
    case types.GET_STATES_FINAL_ENGR_DETAILS_SUCCESS:
      return {
        ...state,
        isSuccessGetStates: true,
        stateOptionsFinalEngineerDetails: action.data.map((c) => {
          return {
            key: c.stateCode,
            value: c.stateCode,
            text: c.stateName
          };
        })
      };
    case types.GET_STATES_FINAL_ENGR_DETAILS_FAILURE:
      return {
        ...state,
        isSuccessGetStates: false,
        stateOptionsFinalEngineerDetails: []
      };
    case types.GET_COUNTRIES_AHJ_REQUEST:
      return {
        ...state,
        isSuccessGetCountries: false,
        countryOptionsAhjDetails: []
      };
    case types.GET_COUNTRIES_AHJ_SUCCESS:
      return {
        ...state,
        isSuccessGetCountries: true,
        countryOptionsAhjDetails: action.data.map((c) => {
          return {
            key: c.countryCode,
            value: c.countryCode,
            text: c.countryName
          };
        })
      };
    case types.GET_COUNTRIES_AHJ_FAILURE:
      return {
        ...state,
        isSuccessGetCountries: false,
        countryOptionsAhjDetails: []
      };
    case types.GET_CITIES_AHJ_REQUEST:
      return {
        ...state,
        isSuccessGetCities: false,
        cityOptionsAhjDetails: []
      };
    case types.GET_CITIES_AHJ_SUCCESS:
      return {
        ...state,
        isSuccessGetCities: true,
        cityOptionsAhjDetails: action.data.map((c) => {
          return {
            key: c.cityName,
            value: c.cityName,
            text: c.cityName
          };
        })
      };
    case types.GET_CITIES_AHJ_FAILURE:
      return {
        ...state,
        isSuccessGetCities: false,
        cityOptionsAhjDetails: []
      };
    case types.GET_STATES_AHJ_REQUEST:
      return {
        ...state,
        isSuccessGetStates: false,
        stateOptionsAhjDetails: []
      };
    case types.GET_STATES_AHJ_SUCCESS:
      return {
        ...state,
        isSuccessGetStates: true,
        stateOptionsAhjDetails: action.data.map((c) => {
          return {
            key: c.stateCode,
            value: c.stateCode,
            text: c.stateName
          };
        })
      };
    case types.GET_STATES_AHJ_FAILURE:
      return {
        ...state,
        isSuccessGetStates: false,
        stateOptionsAhjDetails: []
      };
    case types.UPDATE_PPE_SELECTION:
      var result = Object.assign({}, state, {
        data: initialState.data,
        form: initialState.form
      });

      var ppeSelections = result.form.reservationDetails.ppeSelections;

      ppeSelections.forEach((ppe) => {
        if (ppe.value === action.data.ppe) {
          ppe.checked = action.data.checked;
        }
      });

      result.form.reservationDetails.ppeSelections = ppeSelections;
      var checkedPpeIds = ppeSelections
        .filter((p) => p.checked)
        .map((ppe) => {
          return ppe.value;
        });
      result.form.reservationDetails.preliminarySiteLocationSection.ppeSelections = checkedPpeIds.join(
        "|"
      );

      return result;
    case types.UPDATE_TRAINING_SELECTION:
      var result = Object.assign({}, state, {
        data: initialState.data,
        form: initialState.form
      });

      var trainingSelections =
        result.form.reservationDetails.trainingSelections;

      trainingSelections.forEach((item) => {
        if (item.value === action.data.training) {
          item.checked = action.data.checked;
          item.trainingTwoHours = action.data.trainingTwoHours;
        }
      });

      result.form.reservationDetails.trainingSelections = trainingSelections;

      var checkedTrainingIds = trainingSelections
        .filter((p) => p.checked)
        .map((item) => {
          return item.value;
        });
      result.form.reservationDetails.preliminarySiteLocationSection.trainingSelections = checkedTrainingIds.join(
        "|"
      );
      // index 0 for onsite training is 2 hrs
      result.form.reservationDetails.preliminarySiteLocationSection.isTrainingTwoHours = trainingSelections[0]
        .checked
        ? trainingSelections[0].trainingTwoHours
        : null;

      return result;
    case types.FILL_PRELIM_SITE_DETAILS:
      var result = Object.assign({}, state, {
        data: initialState.data,
        form: initialState.form
      });

      var prelimDetails =
        result.form.siteDetails.preliminarySiteLocationSection;
      var accountDetails = action.data.accountDetails;

      if (action.data.checked == "true" && accountDetails) {
        prelimDetails.residenceName = accountDetails.residenceName;
        prelimDetails.addressLine1 = accountDetails.addressLine1;
        prelimDetails.country = accountDetails.country;
        prelimDetails.state = accountDetails.state;
        prelimDetails.city = accountDetails.city;
        prelimDetails.postalCode = accountDetails.postalCode;
        prelimDetails.siteContactName = accountDetails.contactName;
        prelimDetails.siteContactOfficeNumber = accountDetails.phoneNumber;
        prelimDetails.siteContactMobileNumber = accountDetails.mobileNumber;
        prelimDetails.siteContactEmailAddress = accountDetails.emailAddress;
      } else {
        prelimDetails.residenceName = null;
        prelimDetails.addressLine1 = null;
        prelimDetails.country = null;
        prelimDetails.state = null;
        prelimDetails.city = null;
        prelimDetails.postalCode = null;
        prelimDetails.siteContactName = null;
        prelimDetails.siteContactOfficeNumber = null;
        prelimDetails.siteContactMobileNumber = null;
        prelimDetails.siteContactEmailAddress = null;
      }

      return result;
    case types.FILL_FINAL_SITE_DETAILS:
      var result = Object.assign({}, state, {
        data: initialState.data,
        form: initialState.form
      });

      var finalDetails = result.form.siteDetails.finalInstallationSiteSection;
      var accountDetails = action.data.accountDetails;

      if (action.data.checked == "true" && accountDetails) {
        finalDetails.residenceName = accountDetails.residenceName;
        finalDetails.addressLine1 = accountDetails.addressLine1;
        finalDetails.country = accountDetails.countryCode;
        finalDetails.state = accountDetails.state;
        finalDetails.city = accountDetails.city;
        finalDetails.postalCode = accountDetails.postalCode;
        finalDetails.siteContactName = accountDetails.contactName;
        finalDetails.siteContactOfficeNumber = accountDetails.phoneNumber;
        finalDetails.siteContactMobileNumber = accountDetails.mobileNumber;
        finalDetails.siteContactEmailAddress = accountDetails.emailAddress;
      } else {
        finalDetails.residenceName = null;
        finalDetails.addressLine1 = null;
        finalDetails.country = null;
        finalDetails.state = null;
        finalDetails.city = null;
        finalDetails.postalCode = null;
        finalDetails.siteContactName = null;
        finalDetails.siteContactOfficeNumber = null;
        finalDetails.siteContactMobileNumber = null;
        finalDetails.siteContactEmailAddress = null;
      }

      return result;
    case types.FILL_SCHEDULING_DETAILS:
      var result = Object.assign({}, state, {
        data: initialState.data,
        form: initialState.form
      });

      var prelim =
        result.form.reservationDetails.preliminarySiteLocationSection;
      var final = result.form.reservationDetails.finalSiteLocationSection;
      var ahj =
        result.form.reservationDetails.authorityHavingJurisdictionDetails;

      var accountDetails = action.data.accountDetails;

      if (accountDetails && accountDetails.prelim) {
        if (action.data.checked) {
          prelim.residenceName = accountDetails.prelim.residenceName;
          prelim.addressLine1 = accountDetails.prelim.addressLine1;
          prelim.addressLine2 = accountDetails.prelim.addressLine2;
          prelim.addressLine3 = accountDetails.prelim.addressLine3;
          prelim.country = accountDetails.prelim.country;
          prelim.state = accountDetails.prelim.state;
          prelim.city = accountDetails.prelim.city;
          prelim.postalCode = accountDetails.prelim.postalCode;
          prelim.siteContactName = accountDetails.prelim.siteContactName;
          prelim.siteContactOfficeNumber =
            accountDetails.prelim.siteContactOfficeNumber;
          prelim.siteContactMobileNumber =
            accountDetails.prelim.siteContactMobileNumber;
          prelim.siteContactEmailAddress =
            accountDetails.prelim.siteContactEmailAddress;
        } else {
          prelim.residenceName = null;
          prelim.addressLine1 = null;
          prelim.addressLine2 = null;
          prelim.addressLine3 = null;
          prelim.country = null;
          prelim.state = null;
          prelim.city = null;
          prelim.postalCode = null;
          prelim.siteContactName = null;
          prelim.siteContactOfficeNumber = null;
          prelim.siteContactMobileNumber = null;
          prelim.siteContactEmailAddress = null;
        }
      }

      if (action.data.checked && accountDetails && accountDetails.final) {
        final.residenceName = accountDetails.final.residenceName;
        final.addressLine1 = accountDetails.final.addressLine1;
        final.addressLine2 = accountDetails.final.addressLine2;
        final.addressLine3 = accountDetails.final.addressLine3;
        final.country = accountDetails.final.country;
        final.state = accountDetails.final.state;
        final.city = accountDetails.final.city;
        final.postalCode = accountDetails.final.postalCode;
        final.siteContactName = accountDetails.final.siteContactName;
        final.siteContactOfficeNumber =
          accountDetails.final.siteContactOfficeNumber;
        final.siteContactMobileNumber =
          accountDetails.final.siteContactMobileNumber;
        final.siteContactEmailAddress =
          accountDetails.final.siteContactEmailAddress;
      } else {
        final.residenceName = null;
        final.addressLine1 = null;
        final.addressLine2 = null;
        final.addressLine3 = null;
        final.country = null;
        final.state = null;
        final.city = null;
        final.postalCode = null;
        final.siteContactName = null;
        final.siteContactOfficeNumber = null;
        final.siteContactMobileNumber = null;
        final.siteContactEmailAddress = null;
      }

      if (action.data.checked && accountDetails && accountDetails.ahj) {
        ahj.jurisdictionName = accountDetails.ahj.jurisdictionName;
        ahj.addressLine1 = accountDetails.ahj.addressLine1;
        ahj.addressLine2 = accountDetails.ahj.addressLine2;
        ahj.addressLine3 = accountDetails.ahj.addressLine3;
        ahj.country = accountDetails.ahj.country;
        ahj.state = accountDetails.ahj.state;
        ahj.city = accountDetails.ahj.city;
        ahj.postalCode = accountDetails.ahj.postalCode;
        ahj.inspectorName = accountDetails.ahj.inspectorName;
        ahj.inspectorOfficeNumber = accountDetails.ahj.inspectorOfficeNumber;
        ahj.inspectorMobileNumber = accountDetails.ahj.inspectorMobileNumber;
        ahj.inspectorEmailAddress = accountDetails.ahj.inspectorEmailAddress;
        ahj.permitNo = accountDetails.ahj.permitNo;
      } else {
        ahj.residenceName = null;
        ahj.addressLine1 = null;
        ahj.addressLine2 = null;
        ahj.addressLine3 = null;
        ahj.country = null;
        ahj.state = null;
        ahj.city = null;
        ahj.postalCode = null;
        ahj.inspectorFirstName = null;
        ahj.inspectorLastName = null;
        ahj.inspectorOfficeNumber = null;
        ahj.inspectorMobileNumber = null;
        ahj.inspectorEmailAddress = null;
        ahj.permitNo = null;
      }

      return result;
    case types.GET_APPOINTMENTS_REQUEST:
      var result = Object.assign({}, state, {
        appointments: [],
        isLoadingGetAppointments: true
      });

      return result;
    case types.GET_APPOINTMENTS_SUCCESS:
      var result = Object.assign({}, state, {
        appointments: action.data || initialState.appointments,
        isLoadingGetAppointments: false
      });

      return result;
    case types.GET_APPOINTMENTS_FAILURE:
      var result = Object.assign({}, state, {
        appointments: action.data || initialState.appointments,
        isLoadingGetAppointments: false
      });

      return result;
  }
};

export default fieldEvaluationReducer;
