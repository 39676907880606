import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import * as actions from './combination-label-search.action';
import {
  Button,
  Dropdown,
  Form,
  Grid,
  Icon,
  Input,
  Pagination,
  Segment,
  Table,
} from 'semantic-ui-react';
import { CustomHeader } from '../../components/labels/labels';
import { Common } from '../../common';
import Role from '../../enums/role';
import * as manufacturerActions from '../../views/manufacturer/standard/file-volumes/file-volumes.action';
import * as applicantActions from '../../views/applicant/standard/applicant-dashboard.action';
import * as cartActions from '../../views/manufacturer/standard/label-cart/label-cart.action';
import {
  createValidation,
  getError,
  validate,
  validateField,
} from '../../validator/validator';
import CsvDownload from 'react-json-to-csv';
import * as commonActions from '../../views/user/common/common.action';
import { required } from '../../validator/error-message';

let rules = [];
class CombinationLabelSearch extends Component {
  state = {
    showErrors: false,
  };
  componentDidMount() {
    this.initializeData();
    this.props.getActivePage('Combination Label Search');
    this.initializeValidations();
  }

  initializeValidations = () => {
    const translationsValidation = Common.getTranslations() || {};
    rules = [
      validate(
        'applicantPartySiteNumber',
        translationsValidation['LexApplicantPartySiteNumberisRequired'],
        required
      ),
      validate(
        'applicantAccountNumber',
        translationsValidation['LexApplicantAccountNumberisRequired'],
        required
      ),
      validate(
        'manufacturerPartySiteNumber',
        translationsValidation['LexManufacturerPartySiteNumberisRequired'],
        required
      ),
      validate(
        'manufacturerAccountNumber',
        translationsValidation['LexManufacturerAccountNumberisRequired'],
        required
      ),
      validate(
        'fileNumber',
        translationsValidation['LexFileNumberisRequired'],
        required
      ),
    ];
  };

  initializeData = async () => {
    await this.props.resetSearch();
    let roleId = Common.getUserRole();
    let psn = Common.getPartySiteNumber();
    let { form } = this.props.combinationLabelSearch;

    if (roleId === Role.manufacturer) {
      const { filters, isFilterInitial } = this.props.manufacturerData;

      const newForm = validateField(
        [],
        form,
        'manufacturerPartySiteNumber',
        psn
      );

      this.props.updateForm(newForm);

      this.handleGetCustomerDetails(psn, 'manufacturerAccountNumber');

      this.props.getFileNumbers(
        null,
        2,
        1,
        100,
        filters,
        false,
        isFilterInitial,
        2,
        true
      );
    } else if (roleId === Role.applicant) {
      const { filters, isFilterInitial } = this.props.applicantData;

      const newForm = validateField([], form, 'applicantPartySiteNumber', psn);

      this.props.updateForm(newForm);

      this.handleGetCustomerDetails(psn, 'applicantAccountNumber');

      this.props.getActiveManufacturers(
        null,
        2,
        1,
        100,
        filters,
        false,
        isFilterInitial,
        2,
        false
      );
    }
  };

  handleGetCustomerDetails = async (psn, field) => {
    let { form } = this.props.combinationLabelSearch;

    const customerDetails = await this.props.getCustomerDetails(psn);

    if (customerDetails) {
      const newForm = validateField(
        [],
        form,
        field,
        customerDetails?.data?.accountNumber
      );

      this.props.updateForm(newForm);
    }
  };

  handleChange = e => {
    let { form } = this.props.combinationLabelSearch;

    const newForm = validateField([], form, e.target.name, e.target.value);

    this.props.updateForm(newForm);
  };

  handleDropdownChange = (e, properties) => {
    let { form } = this.props.combinationLabelSearch;
    const { manufacturers } = this.props.applicantData;
    const { data: applicants } = this.props.manufacturerData;

    let newForm = validateField(rules, form, properties.name, properties.value);
    this.props.updateForm(newForm);

    if (properties.name === 'fileNumber') {
      this.props.getExistingCombinationLabels(
        properties.value,
        '',
        form.applicantAccountNumber,
        form.manufacturerAccountNumber,
        1,
        50000,
        1,
        false
      );
    }

    if (properties.name === 'manufacturerPartySiteNumber') {
      let manufacturerAccountNumber = manufacturers?.filter(
        m => m.partySiteNumber === properties.value
      )?.[0].manufacturerAccount;

      this.handleUpdateFields(
        'manufacturerAccountNumber',
        manufacturerAccountNumber
      );
    }

    if (properties.name === 'manufacturerAccountNumber') {
      let manufacturerPartySiteNumber = manufacturers?.filter(
        m => m.manufacturerAccount === properties.value
      )?.[0].partySiteNumber;

      this.handleUpdateFields(
        'manufacturerPartySiteNumber',
        manufacturerPartySiteNumber
      );
    }

    if (properties.name === 'applicantPartySiteNumber') {
      let applicantAccountNumber = applicants?.filter(
        m => m.partySiteNumber === properties.value
      )?.[0].applicantAccount;

      this.handleUpdateFields('applicantAccountNumber', applicantAccountNumber);
    }

    if (properties.name === 'applicantAccountNumber') {
      let applicantPartySiteNumber = applicants?.filter(
        m => m.applicantAccount === properties.value
      )?.[0].partySiteNumber;

      this.handleUpdateFields(
        'applicantPartySiteNumber',
        applicantPartySiteNumber
      );
    }
  };

  handleUpdateFields = (fieldName, fieldValue) => {
    let { form } = this.props.combinationLabelSearch;

    const newForm = validateField(rules, form, fieldName, fieldValue);
    this.props.updateForm(newForm);
    this.handleFillFileNumbers();
  };

  handleFillFileNumbers = () => {
    const role = Common.getUserRole();
    const { manufacturers } = this.props.applicantData;
    const { data: applicants } = this.props.manufacturerData;
    const { form } = this.props.combinationLabelSearch;
    const {
      manufacturerPartySiteNumber,
      manufacturerAccountNumber,
      applicantAccountNumber,
      applicantPartySiteNumber,
    } = form;
    let fileNumbers = [];

    if (role === Role.applicant) {
      fileNumbers = manufacturers
        .filter(
          m =>
            m.manufacturerAccount === manufacturerAccountNumber &&
            m.partySiteNumber === manufacturerPartySiteNumber
        )
        .map(m => {
          return m.fileNumber;
        });
    } else {
      fileNumbers = applicants
        .filter(
          m =>
            m.applicantAccount === applicantAccountNumber &&
            m.partySiteNumber === applicantPartySiteNumber
        )
        .map(m => {
          return m.fileNumber;
        });
    }

    fileNumbers = [...new Set(fileNumbers)];

    this.props.updateFileNumberList(fileNumbers);
  };

  getErrorFor(field) {
    const { form } = this.props.combinationLabelSearch;
    let errors = getError(field, form, form);
    return errors;
  }

  handleSearch = () => {
    const { form, currentPage, pageSize } = this.props.combinationLabelSearch;
    const {
      manufacturerAccountNumber,
      applicantAccountNumber,
      fileNumber,
      itemNumber,
      customerPartNo,
    } = form;

    form.validationErrors = createValidation(form, rules);

    if (Object.IsEmpty(form.validationErrors)) {
      this.props.getCombinationLabelsByFilter(
        applicantAccountNumber,
        manufacturerAccountNumber,
        fileNumber,
        itemNumber,
        customerPartNo,
        currentPage,
        pageSize,
        false
      );
    } else {
      this.setState({ showErrors: true });
    }
  };

  handleResetSearch = async () => {
    await this.props.resetSearch();
    this.initializeData();
  };

  handlePageChangeLabels = (currentPage, pageSize) => {
    const { form } = this.props.combinationLabelSearch;
    const {
      applicantAccountNumber,
      manufacturerAccountNumber,
      fileNumber,
      itemNumber,
      customerPartNo,
    } = form;

    this.props.getCombinationLabelsByFilter(
      applicantAccountNumber,
      manufacturerAccountNumber,
      fileNumber,
      itemNumber,
      customerPartNo,
      currentPage,
      pageSize,
      false
    );
  };

  handleExportResults = async () => {
    const { form, combinationLabels } = this.props.combinationLabelSearch;
    const {
      applicantAccountNumber,
      manufacturerAccountNumber,
      fileNumber,
      itemNumber,
      customerPartNo,
    } = form;

    form.validationErrors = createValidation(form, rules);

    if (Object.IsEmpty(form.validationErrors) && !!combinationLabels.length) {
      var result = await this.props.getCombinationLabelsByFilter(
        applicantAccountNumber,
        manufacturerAccountNumber,
        fileNumber,
        itemNumber,
        customerPartNo,
        1,
        1000000,
        true
      );

      if (result) this.exportResultRef.click();
    } else {
      this.setState({ showErrors: true });
    }
  };

  render() {
    let roleId = Common.getUserRole();
    const { applicantPsns, applicantAccountNumbers } =
      this.props.manufacturerData;
    const { manufacturerPsns, manufacturerAccountNumbers } =
      this.props.applicantData;
    const {
      form,
      itemNumbers,
      customerPartNumbers,
      loading,
      combinationLabels,
      combinationLabelsForExport,
      currentPage,
      totalPages,
      pageSize,
      isDirty,
      fileNumberList,
    } = this.props.combinationLabelSearch;

    const translations = Common.getTranslations() || {};

    return (
      <Fragment>
        <div className="div-container">
          <Segment>
            <CustomHeader
              message={translations['LexSearchCombolabelitemInformation']}
            />
            <Form>
              <Grid>
                <Grid.Row columns={6}>
                  <Grid.Column>
                    {roleId === Role.applicant && (
                      <Form.Field
                        control={Input}
                        label={translations['LexApplicantPartySiteNumber']}
                        placeholder={
                          translations['LexApplicantPartySiteNumber']
                        }
                        name="applicantPartySiteNumber"
                        value={form.applicantPartySiteNumber}
                        autoComplete="off"
                        readOnly={true}
                      />
                    )}
                    {roleId === Role.manufacturer && (
                      <Form.Field
                        control={Dropdown}
                        compact
                        fluid
                        selection
                        selectOnBlur={false}
                        label={translations['LexApplicantPartySiteNumber']}
                        placeholder={
                          translations['LexApplicantPartySiteNumber']
                        }
                        name="applicantPartySiteNumber"
                        options={
                          applicantPsns &&
                          applicantPsns.map(item => {
                            return {
                              key: item.partySiteNumber,
                              text: item.partySiteNumber,
                              value: item.partySiteNumber,
                              content: `${item.partySiteNumber} - ${item.applicantName}`,
                            };
                          })
                        }
                        onChange={(e, properties) => {
                          this.handleDropdownChange(e, properties);
                        }}
                        autoComplete="off"
                        value={form.applicantPartySiteNumber || ''}
                        error={this.getErrorFor('applicantPartySiteNumber')}
                      />
                    )}
                  </Grid.Column>
                  <Grid.Column>
                    {roleId === Role.applicant && (
                      <Form.Field
                        control={Input}
                        label={translations['LexApplicantAccountNumber']}
                        placeholder={translations['LexApplicantAccountNumber']}
                        name="applicantAccountNumber"
                        onChange={e => {
                          this.handleChange(e);
                        }}
                        value={form.applicantAccountNumber}
                        autoComplete="off"
                        readOnly={true}
                      />
                    )}
                    {roleId === Role.manufacturer && (
                      <Form.Field
                        control={Dropdown}
                        compact
                        fluid
                        selection
                        selectOnBlur={false}
                        label={translations['LexApplicantAccountNumber']}
                        placeholder={translations['LexApplicantAccountNumber']}
                        name="applicantAccountNumber"
                        options={
                          applicantAccountNumbers &&
                          applicantAccountNumbers.map(item => {
                            return {
                              key: item.applicantAccount,
                              text: item.applicantAccount,
                              value: item.applicantAccount,
                              content: `${item.applicantAccount} - ${item.applicantName}`,
                            };
                          })
                        }
                        onChange={(e, properties) => {
                          this.handleDropdownChange(e, properties);
                        }}
                        autoComplete="off"
                        value={form.applicantAccountNumber || ''}
                        error={this.getErrorFor('applicantAccountNumber')}
                      />
                    )}
                  </Grid.Column>
                  <Grid.Column>
                    {roleId === Role.manufacturer && (
                      <Form.Field
                        control={Input}
                        label={translations['LexManufacturerPartySiteNumber']}
                        placeholder={
                          translations['LexManufacturerPartySiteNumber']
                        }
                        name="manufacturerPartySiteNumber"
                        onChange={e => {
                          this.handleChange(e);
                        }}
                        value={form.manufacturerPartySiteNumber}
                        autoComplete="off"
                        readOnly={true}
                      />
                    )}
                    {roleId === Role.applicant && (
                      <Form.Field
                        control={Dropdown}
                        compact
                        fluid
                        selection
                        selectOnBlur={false}
                        label={translations['LexManufacturerPartySiteNumber']}
                        placeholder={
                          translations['LexManufacturerPartySiteNumber']
                        }
                        name="manufacturerPartySiteNumber"
                        options={
                          manufacturerPsns &&
                          manufacturerPsns.map(item => {
                            return {
                              key: item.partySiteNumber,
                              text: item.partySiteNumber,
                              value: item.partySiteNumber,
                              content: `${item.partySiteNumber} - ${item.manufacturerName}`,
                            };
                          })
                        }
                        onChange={(e, properties) => {
                          this.handleDropdownChange(e, properties);
                        }}
                        autoComplete="off"
                        value={form.manufacturerPartySiteNumber || ''}
                        error={this.getErrorFor('manufacturerPartySiteNumber')}
                      />
                    )}
                  </Grid.Column>
                  <Grid.Column>
                    {roleId === Role.manufacturer && (
                      <Form.Field
                        control={Input}
                        label={translations['LexManufacturerAccountNumber']}
                        placeholder={
                          translations['LexManufacturerAccountNumber']
                        }
                        name="manufacturerAccountNumber"
                        onChange={e => {}}
                        value={form.manufacturerAccountNumber}
                        autoComplete="off"
                        readOnly={true}
                      />
                    )}
                    {roleId === Role.applicant && (
                      <Form.Field
                        control={Dropdown}
                        compact
                        fluid
                        selection
                        selectOnBlur={false}
                        label={translations['LexManufacturerAccountNumber']}
                        placeholder={
                          translations['LexManufacturerAccountNumber']
                        }
                        name="manufacturerAccountNumber"
                        options={
                          manufacturerAccountNumbers &&
                          manufacturerAccountNumbers.map(item => {
                            return {
                              key: item.manufacturerAccount,
                              text: item.manufacturerAccount,
                              value: item.manufacturerAccount,
                              content: `${item.manufacturerAccount} - ${item.manufacturerName}`,
                            };
                          })
                        }
                        onChange={(e, properties) => {
                          this.handleDropdownChange(e, properties);
                        }}
                        value={form.manufacturerAccountNumber || ''}
                        error={this.getErrorFor('manufacturerAccountNumber')}
                      />
                    )}
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={6}>
                  <Grid.Column>
                    <Form.Field
                      control={Dropdown}
                      compact
                      fluid
                      selection
                      selectOnBlur={false}
                      label={translations['LexFileNumber']}
                      placeholder={translations['LexFileNumber']}
                      name="fileNumber"
                      options={
                        fileNumberList &&
                        fileNumberList.map(item => {
                          return {
                            key: item,
                            text: item,
                            value: item,
                          };
                        })
                      }
                      onChange={(e, properties) => {
                        this.handleDropdownChange(e, properties);
                      }}
                      value={form.fileNumber || ''}
                      error={this.getErrorFor('fileNumber')}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Field
                      control={Dropdown}
                      compact
                      fluid
                      selection
                      selectOnBlur={false}
                      label={translations['LexCustPartNo']}
                      placeholder={translations['LexCustPartNo']}
                      name="customerPartNo"
                      options={
                        customerPartNumbers &&
                        customerPartNumbers.map(item => {
                          return {
                            key: item.text,
                            text: item.text,
                            value: item.text,
                          };
                        })
                      }
                      onChange={(e, properties) => {
                        this.handleDropdownChange(e, properties);
                      }}
                      value={form.customerPartNo || ''}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Field
                      control={Dropdown}
                      compact
                      fluid
                      selection
                      selectOnBlur={false}
                      label={translations['LexULItemNumber']}
                      placeholder={translations['LexULItemNumber']}
                      name="itemNumber"
                      options={
                        itemNumbers &&
                        itemNumbers.map(item => {
                          return {
                            key: item.text,
                            text: item.text,
                            value: item.text,
                          };
                        })
                      }
                      onChange={(e, properties) => {
                        this.handleDropdownChange(e, properties);
                      }}
                      value={form.itemNumber || ''}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Field
                      control={Input}
                      label={translations['LexPurchaseOrderNumber']}
                      placeholder={translations['LexPurchaseOrderNumber']}
                      name="purchaseOrderNumber"
                      value={form.purchaseOrderNumber}
                      autoComplete="off"
                      readOnly={true}
                      disabled
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <Button
                      primary
                      onClick={this.handleSearch}
                      loading={loading}
                      disabled={loading}
                    >
                      {translations['LexSearchItem']}
                    </Button>
                    <Button
                      onClick={this.handleResetSearch}
                      loading={loading}
                      disabled={loading}
                    >
                      {translations['LexResetSearch']}
                    </Button>
                    <CsvDownload
                      filename={`Combination-Labels-${new Date().toISOString()}.csv`}
                      data={combinationLabelsForExport}
                      style={{
                        display: 'none',
                      }}
                      delimiter=","
                    >
                      <input
                        type="button"
                        id="fileUploadAdd"
                        style={{ display: 'none' }}
                        ref={exportResultRef =>
                          (this.exportResultRef = exportResultRef)
                        }
                        value={'Export'}
                      />
                    </CsvDownload>
                    <Button
                      basic
                      secondary
                      onClick={this.handleExportResults}
                      loading={loading}
                      disabled={loading || !combinationLabels.length}
                    >
                      <Icon name="file excel" />{' '}
                      {translations['LexExportResult']}
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
            {!!combinationLabels.length && (
              <Table celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>
                      {translations['LexApplicantAccountNumber']}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {translations['LexManufacturerAccountNumber']}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {translations['LexFileNumber']}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {translations['LexULItemNumber']}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {translations['LexItemDescription']}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {translations['LexCustomerPartNo']}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {translations['LexItemStatus']}
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {combinationLabels.map(item => {
                    return (
                      <Table.Row>
                        <Table.Cell>{item.applicantAccountNumber}</Table.Cell>
                        <Table.Cell>
                          {item.manufacturerAccountNumber}
                        </Table.Cell>
                        <Table.Cell>{item.fileNumber}</Table.Cell>
                        <Table.Cell>{item.itemNumber}</Table.Cell>
                        <Table.Cell>{item.itemDescription}</Table.Cell>
                        <Table.Cell>{item.customerPartNo}</Table.Cell>
                        <Table.Cell>{item.status}</Table.Cell>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
                <Table.Footer>
                  <Table.Row>
                    <Table.HeaderCell colSpan="7">
                      {!!combinationLabels && totalPages > 0 && (
                        <Pagination
                          boundaryRange={2}
                          basic
                          activePage={currentPage}
                          totalPages={totalPages}
                          onPageChange={(e, { activePage: currentPage }) => {
                            this.handlePageChangeLabels(currentPage, pageSize);
                          }}
                        />
                      )}
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Footer>
              </Table>
            )}

            {!combinationLabels.length && isDirty && (
              <Table celled>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell style={{ textAlign: 'center' }}>
                      {translations['LexComboNoMatch']}
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            )}
          </Segment>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  combinationLabelSearch: state.combinationLabelSearch,
  manufacturerData: state.fileVolumes,
  applicantData: state.applicantDashboard,
  labelCart: state.labelCart,
  existingCombinationLabels: state.existingCombinationLabels,
});

const mapDispatchToProps = dispatch => ({
  getCombinationLabelsByFilter: (
    applicantAccountNumber,
    manufacturerAccountNumber,
    fileNumber,
    itemNumber,
    customerPartNo,
    currentPage,
    pageSize,
    isForExport
  ) =>
    dispatch(
      actions.getCombinationLabelsByFilter(
        applicantAccountNumber,
        manufacturerAccountNumber,
        fileNumber,
        itemNumber,
        customerPartNo,
        currentPage,
        pageSize,
        isForExport
      )
    ),
  updateForm: form => dispatch(actions.updateForm(form)),
  getFileNumbers: (
    sortColumn,
    sortOrder,
    currentPage,
    pageSize,
    filters,
    hasFilter,
    isFilterInitial,
    labelOrderType,
    isFirstLoad
  ) =>
    dispatch(
      manufacturerActions.getFileNumbers(
        sortColumn,
        sortOrder,
        currentPage,
        pageSize,
        filters,
        hasFilter,
        isFilterInitial,
        labelOrderType,
        isFirstLoad
      )
    ),
  getCustomerDetails: partySiteNumber =>
    dispatch(cartActions.getCustomerDetails(partySiteNumber)),
  getActiveManufacturers: (
    sortColumn,
    sortOrder,
    currentPage,
    pageSize,
    filters,
    hasFilter,
    isFilterInitial,
    labelOrderType,
    isFirstLoad
  ) =>
    dispatch(
      applicantActions.getActiveManufacturers(
        sortColumn,
        sortOrder,
        currentPage,
        pageSize,
        filters,
        hasFilter,
        isFilterInitial,
        labelOrderType,
        isFirstLoad
      )
    ),
  getExistingCombinationLabels: (
    fileNumber,
    itemNumber,
    applicantAccount,
    manufacturerAccount,
    currentPage,
    pageSize,
    id,
    isCopied
  ) =>
    dispatch(
      actions.getExistingCombinationLabels(
        fileNumber,
        itemNumber,
        applicantAccount,
        manufacturerAccount,
        currentPage,
        pageSize,
        id,
        isCopied
      )
    ),
  resetSearch: () => dispatch(actions.resetSearch()),
  getActivePage: page => dispatch(commonActions.getActivePage(page)),
  updateFileNumberList: data => dispatch(actions.updateFileNumberList(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CombinationLabelSearch);
