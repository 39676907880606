export const FORM = "FORM";
export const CLEAR = "CLEAR";

export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const REGISTER_FAILURE = "REGISTER_FAILURE";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";

export const PARTYSITENUMBER_DETAILS_REQUEST = "PARTYSITENUMBER_DETAILS_REQUEST";
export const PARTYSITENUMBER_DETAILS_FAILURE =
  "PARTYSITENUMBER_DETAILS_FAILURE";
export const PARTYSITENUMBER_DETAILS_SUCCESS =
  "PARTYSITENUMBER_DETAILS_SUCCESS";
