import React, { Component, Fragment } from "react";
import CustomerInformation from "./customer-information";
import LabelDetails from "./label-details";
import LabelProduction from "./label-production";
import AdditionalComments from "./additional-comments";
import OrderPricing from "./order-pricing";
import { CustomHeader } from "../../components/labels/labels";
import FooterHomeBlock from "../../components/footer/footer-home-block";
import { Form, Message } from "semantic-ui-react";
import { connect } from "react-redux";

class OrderMain extends Component {
  state = {
    purchaseOrderNumber: "",
    partNumber: "",
    labelType: "",
    additionalDetails: "",
    denomination: "",
    labelCenter: "",
    instructions: "",
    labelSupplier: "",
    fieldErrors: {
      purchaseOrderNumberError: false,
      partNumberError: false,
      labelTypeError: false,
      additionalDetailsError: false,
      denomination: false,
      labelCenterError: false,
      instructionsError: false,
      labelSupplierError: false
    },
    isLoading: false,
    isError: true,
    isSuccess: false
  };

  handleChange = (name, value) => {
    let { fieldErrors } = this.state;

    fieldErrors[`${name}Error`] = value === "";

    this.setState({
      [name]: value,
      fieldErrors
    });
  };

  handleAddToCart = () => {
    var data = {
      userId: 2,
      customerPoNumber: this.state.purchaseOrderNumber,
      ccn: this.state.partNumber
    };
  };

  isValid() {
    const {
      purchaseOrderNumber
      // partNumber,
      // labelType,
      // additionalDetails,
      // denomination,
      // labelCenter,
      // instructions,
      // labelSupplier
    } = this.state;

    return (
      purchaseOrderNumber.length > 0
      // &&
      // partNumber.length > 0 &&
      // labelType.length > 0 &&
      // additionalDetails.length > 0 &&
      // denomination.length > 0 &&
      // labelCenter.length > 0 &&
      // instructions.length > 0 &&
      // labelSupplier.length > 0
    );
  }

  render() {
    const isValid = this.isValid();
    const { isLoading, isSuccess } = this.props;

    return (
      <Fragment>
        <div style={{ width: "60%", padding: "20px" }}>
          <Form loading={isLoading}>
            <CustomHeader message="Start combination label new order" />
            <CustomerInformation />
            <LabelDetails
              onHandleChange={this.handleChange}
              fieldErrors={this.state.fieldErrors}
            />
            <LabelProduction
              onHandleChange={this.handleChange}
              fieldErrors={this.state.fieldErrors}
            />
            {/* <AdditionalComments /> */}
            <OrderPricing
              isValid={isValid}
              onAddToCart={this.handleAddToCart}
            />
          </Form>
          <Message
            success
            hidden={!isSuccess}
            header="Order successfully created."
            content="you may view your orders or create another one."
          />
          <FooterHomeBlock />
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  isSuccess: state.labelCart.createOrderResult,
  isLoading: state.labelCart.isLoadingCreateOrder
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(OrderMain);
