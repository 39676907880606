import { uploadTemplate } from '../../enums/upload-type';
import * as types from './price-management.type';

let initialState = {
  data: [], //paginated data
  origData: [], //all data combined from import file and labels from database
  uploadData: [], //data from import file
  backupData: [], //backup data from original
  loading: false,
  loadingUpload: false,
  isSuccess: false,
  currentPage: 1,
  pageSize: 10,
  totalPages: 0,
  labels: [],
  standardLabelsFiltered: [],
  nonDenominationalAccounts: [],
  orderCourier: [],
  mapLabels: [],
  origLabels: [],
  form: {
    filter: null,
    showErrors: false,
    validationErrors: {},
  },
  changeRelationshipForm: {
    showErrors: false,
    validationErrors: {},
  },
  combinationItemNumbers: [],
  noFilter: true,
  singleRelationShipForm: {
    showErrors: false,
    validationErrors: {},
  },
  createLabelSupplierEmailForm: {
    showErrors: false,
    validationErrors: {},
  },
  csvData: [],
  labelSupplierEmails: [],
  labelSupplierEmailsFiltered: [],
  uploadedSupplierEmails: [],

  combinationAllowedCcns: [],

  uploadedCombinationAllowedCcns: [],
  selectedItems: [],
  selectedItemNumbers: [],
  isSelectedAll: false,
  isRemoveSuccess: false,

  currencyHandlingFees: [],
};

const getSelectedItems = itemNumbers => {
  const result = [];

  itemNumbers
    .filter(d => d.checked)
    .forEach(item => {
      result.push(item.Id);
    });

  return result;
};

const priceManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;

    case types.GET_LABELS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.GET_LABELS_SUCCESS:
    case types.GET_LABELS_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        labels: action.data || initialState.labels,
        standardLabelsFiltered: action.data || initialState.labels,
        isSuccess: true,
      });

    case types.GET_COMBINATION_LABELS_REQUEST:
      return {
        ...state,
        loading: true,
        isLoadingViewActiveLabels: true,
      };
    case types.GET_COMBINATION_LABELS_SUCCESS:
    case types.GET_COMBINATION_LABELS_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        labels: action.data || initialState.labels,
        isSuccess: true,
        isLoadingViewActiveLabels: false,
      });

    case types.UPDATE_LABELS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.UPDATE_LABELS_SUCCESS:
    case types.UPDATE_LABELS_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        isSuccess: action.data.isSuccessful,
      });

    case types.UPDATE_COMBINATIONS_LABELS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.UPDATE_COMBINATIONS_LABELS_SUCCESS:
    case types.UPDATE_COMBINATIONS_LABELS_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        isSuccess: action.data.isSuccessful,
      });

    case types.UPDATE_CUSTOMER_COMBINATIONS_LABEL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.UPDATE_CUSTOMER_COMBINATIONS_LABEL_SUCCESS:
    case types.UPDATE_CUSTOMER_COMBINATIONS_LABEL_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        isSuccess: action.data.isSuccessful,
      });

    case types.UPDATE_LABELS_PRICE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.UPDATE_LABELS_PRICE_SUCCESS:
    case types.UPDATE_LABELS_PRICE_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        isSuccess: action.data.isSuccessful,
      });
    case types.VIEW_ACTIVE_LABELS_REQUEST:
      return {
        ...state,
      };
    case types.VIEW_ACTIVE_LABELS:
      const stateViewActiveLabels = Object.assign({}, state, {});

      let labels;
      if (
        action.data.uploadType ===
        uploadTemplate.combinationLabelEligibleCcnsTemplate
      ) {
        labels = action.data.combinationAllowedCcns.map(item => {
          return {
            CCN: item.ccn,
            'Service Type': item.serviceType,
            Status: item.statusDisplay,
          };
        });
      } else if (
        action.data.uploadType ===
        uploadTemplate.authorizedLabelSupplierEmailTemplate
      ) {
        labels = action.data.labelSupplierEmailsFiltered.map(item => {
          return {
            'Printer File Number': item.printerFileNumber,
            'Printer PSN': item.printerPSN,
            'Email Address': item.emailAddress,
            'Email Status': item.emailStatus,
            'Updated By': item.updatedByDisplay,
          };
        });
      } else if (
        action.data.uploadType ===
        uploadTemplate.standardLabelManagementTemplate
      ) {
        labels = action.data.standardLabelsFiltered.map(item => {
          return {
            'Label Acct': item.labelAccount,
            CCN: item.ccn,
            'Label Description': item.description,
            'Item Number': item.itemNumber,
            'Label Order Package': item.labelOrderPackage,
            'Min. Order Qty': item.minOrderQuantity,
            'ORACLE UOM': item.oracleUnitOfMeasurementName,
            'Image URL': item.imageUrl,
          };
        });
      } else if (
        action.data.uploadType === uploadTemplate.authorizedNdLabelTemplate
      ) {
        labels = action.data.nonDenominationalAccounts.map(item => {
          return {
            'Manufacturer Account#': item.manufacturerAccount,
            'Manufacturer Customer Name': item.manufacturerName,
            Status: item.status,
          };
        });
      } else if (
        action.data.uploadType ===
        uploadTemplate.standardLabelHandlingFeeTemplate
      ) {
        labels = action.data.currencyHandlingFees.map(item => {
          return {
            Currency: item.currency,
            'Handling Fee': item.handlingFee,
            'Updated By': item.updatedBy,
            'Updated Date': item.updatedDate,
          };
        });
      } else {
        labels = action.data.labels.map(item => {
          return {
            Id: item.id ? item.id : item['Id'],
            'Label Acct': item.labelAccount
              ? item.labelAccount
              : item['Label Acct'],
            CCN: item.ccn ? item.ccn : item['CCN'],
            'Label Description': item.description
              ? item.description
              : item['Label Description'],
            'Item Number': item.itemNumber
              ? item.itemNumber
              : item['Item Number'],
            'Image URL': item.imageUrl ? item.imageUrl : item['Image URL'],
            'Label Order Package': item.labelOrderPackage
              ? item.labelOrderPackage
              : item['Label Order Package'],
            'Min. Order Qty': item.minOrderQuantity
              ? item.minOrderQuantity
              : item['Min. Order Qty'],
            'ORACLE UOM': item.oracleUnitOfMeasurementName
              ? item.oracleUnitOfMeasurementName
              : item['ORACLE UOM'],
            'Unit Price': item.price ? item.price : item['Unit Price'],
            UOM: item.unitOfMeasurementName
              ? item.unitOfMeasurementName
              : item['UOM'],
            'Item Description': item.itemDescription
              ? item.itemDescription
              : item['Item Description'],
            'Customer Part No.': item.customerPartNo
              ? item.customerPartNo
              : item['Customer Part No.'],
            'Oracle Status': item.oracleStatus
              ? item.oracleStatus === 0
                ? ''
                : item.oracleStatus === 1
                ? 'Active'
                : 'InActive'
              : item['Oracle Status'] === 0
              ? ''
              : item['Oracle Status'] === 1
              ? 'Active'
              : 'InActive',
            'Appl Account#': item.applicantAccountNumber
              ? item.applicantAccountNumber
              : item['Appl Account#'],
            'Mfr Account#': item.manufacturerAccountNumber
              ? item.manufacturerAccountNumber
              : item['Mfr Account#'],
            'Basic File#': item.fileNumber
              ? item.fileNumber
              : item['Basic File#'],
            'Updated By': item.updatedByDisplay
              ? item.updatedByDisplay
              : item['Updated By'],
            'Updated Date': item.updatedDate
              ? item.updatedDate
              : item['Updated Date'],
            Status: item.oracleStatus
              ? item.oracleStatus === 0
                ? 'Active'
                : 'InActive'
              : 'Active',
            checked: false,
            // ["Status"]: "Active"
          };
        });

        if (
          stateViewActiveLabels.selectedItems &&
          stateViewActiveLabels.selectedItems.length > 0
        ) {
          labels = labels.filter(
            c => stateViewActiveLabels.selectedItems.indexOf(c.Id) === -1
          );
        }
      }

      const _indexOfLastLabels =
        initialState.currentPage * initialState.pageSize;
      const _indexOfFirstLabels = _indexOfLastLabels - initialState.pageSize;
      const _totalPagesLabels = Math.round(
        labels.length / initialState.pageSize
      );
      const _currentDataLables = labels.slice(
        _indexOfFirstLabels,
        _indexOfLastLabels
      );

      return Object.assign({}, state, {
        mapLabels: _currentDataLables || initialState.data,
        origLabels: labels,
        filteredLabels: labels,
        currentPage: initialState.currentPage,
        totalPages: _totalPagesLabels,
        noFilter: true,
      });

    case types.LABELS_CHANGE_PAGE:
      const _indexOfLastLabelsCP =
        action.data.currentPage * initialState.pageSize;
      const _indexOfFirstLabelsCP =
        _indexOfLastLabelsCP - initialState.pageSize;
      const _totalPagesLabelsCP = Math.round(
        action.data.data.length / initialState.pageSize
      );
      const _currentDataLabelsCP = action.data.data.slice(
        _indexOfFirstLabelsCP,
        _indexOfLastLabelsCP
      );

      return Object.assign({}, state, {
        mapLabels: _currentDataLabelsCP || initialState.data,
        origLabels: action.data.data,
        currentPage: action.data.currentPage,
        totalPages: _totalPagesLabelsCP,
      });

    case types.APPLY_FILTER:
      let filteredData = [];

      switch (action.data.filter) {
        case 1:
          if (
            action.data.uploadType ===
            uploadTemplate.combinationLabelItemNumbersTemplate
          ) {
            filteredData = action.data.data.forEach(i => {
              if (i['Status'] === 'New') return i;
            });
          } else {
            filteredData = action.data.data.forEach(i => {
              if (i['Status'] === 'Active') return i;
            });
          }
          break;
        case 2:
          filteredData = action.data.data.forEach(i => {
            if (i['Status'] === 'Active') return i;
          });
          break;
        case 3:
          filteredData = action.data.data.forEach(i => {
            if (i['Status'] === 'Removed') return i;
          });
          break;
        default:
          filteredData = action.data.data;
      }

      const _indexOfLastFilter =
        initialState.currentPage * initialState.pageSize;
      const _indexOfFirstFilter = _indexOfLastFilter - initialState.pageSize;
      const _totalPagesFilter = Math.round(
        filteredData.length / initialState.pageSize
      );
      const _currentDataFilter = filteredData.slice(
        _indexOfFirstFilter,
        _indexOfLastFilter
      );

      return Object.assign({}, state, {
        data: _currentDataFilter || initialState.data,
        origData: filteredData,
        currentPage: initialState.currentPage,
        totalPages: _totalPagesFilter,
      });

    case types.CHANGE_PAGE:
      const _indexOfLast = action.data.currentPage * initialState.pageSize;
      const _indexOfFirst = _indexOfLast - initialState.pageSize;
      const _totalPages = Math.round(
        action.data.data.length / initialState.pageSize
      );
      const _currentData = action.data.data.slice(_indexOfFirst, _indexOfLast);

      return Object.assign({}, state, {
        data: _currentData || initialState.data,
        origData: action.data.data,
        currentPage: action.data.currentPage,
        totalPages: _totalPages,
      });

    case types.LABEL_MNGMNT_DATA:
      action.data.data.forEach(item => {
        const selectedLabel =
          action.data.labels.length > 0 &&
          action.data.labels.filter(
            i => i.itemNumber === item['Item Number'].toString()
          );

        if (selectedLabel.length > 0) {
          const newSelectedLabel = [];

          newSelectedLabel.push({
            'Label Acct': selectedLabel[0].labelAccount,
            CCN: selectedLabel[0].ccn,
            'Label Description': selectedLabel[0].description,
            'Item Number': selectedLabel[0].itemNumber,
            'Label Order Package': selectedLabel[0].labelOrderPackage,
            'Min. Order Qty': selectedLabel[0].minOrderQuantity,
            'ORACLE UOM': selectedLabel[0].oracleUnitOfMeasurementName,
            'Unit Price': selectedLabel[0].price ? selectedLabel[0].price : 0,
            UOM: selectedLabel[0].oracleUnitOfMeasurementName,
            'Oracle Order Number': '',
            'Tracking number': '',
            'Courier Name': '',
          });
          item['Status'] = 'Active';
          item.childItem = newSelectedLabel;
        } else {
          item['Status'] = 'New';
        }
      });

      const removeLabels = [];

      action.data.labels.forEach(item => {
        const selItem = action.data.data.some(
          i => i['Item Number'].toString() === item.itemNumber.toString()
        );

        if (!selItem) removeLabels.push(item);
      });

      const newRemoveLabels = removeLabels?.map(item => {
        return {
          'Label Acct': item.labelAccount,
          CCN: item.ccn,
          'Label Description': item.description,
          'Item Number': item.itemNumber,
          'Label Order Package': item.labelOrderPackage,
          'Min. Order Qty': item.minOrderQuantity,
          'ORACLE UOM': item.oracleUnitOfMeasurementName,
          'Unit Price': item.price,
          'Price UOM': item.oracleUnitOfMeasurementName,
          Status: 'Removed',
          'Oracle Order Number': '',
          'Tracking number': '',
          'Courier Name': '',
        };
      });

      const concatData = action.data.data.concat(newRemoveLabels);

      const indexOfLast = initialState.currentPage * initialState.pageSize;
      const indexOfFirst = indexOfLast - initialState.pageSize;
      const totalPages = Math.round(concatData.length / initialState.pageSize);
      const currentData = concatData.slice(indexOfFirst, indexOfLast);

      return Object.assign({}, state, {
        data: currentData || initialState.data,
        origData: concatData,
        backupData: concatData,
        uploadData: action.data.data,
        currentPage: indexOfFirst,
        totalPages: totalPages,
      });

    case types.PRICE_MNGMNT_DATA:
      action.data.data.forEach(item => {
        const selectedPrice =
          action.data.labels.length > 0 &&
          action.data.labels.filter(
            i => i.itemNumber === item['Product Value'].toString()
          );

        if (selectedPrice.length > 0) {
          const newSelectedPrice = [];

          newSelectedPrice.push({
            'Product Value': selectedPrice[0].itemNumber,
            'Product Description': selectedPrice[0].description,
            UOM: selectedPrice[0].unitOfMeasurementName
              ? selectedPrice[0].unitOfMeasurementName
              : '',
            Value: selectedPrice[0].price,
            'Start Date': '',
          });

          item['Value'] = item['Value'] === '' ? '0' : item['Value'];
          item['Status'] = 'Active';
          item.childItem = newSelectedPrice;
        } else {
          item['Status'] = 'New';
          item['Value'] = item['Value'] === '' ? '0' : item['Value'];
        }
      });

      const priceManagementLabels = [];

      action.data.labels.forEach(item => {
        const priceItem = action.data.data.some(
          i => i['Product Value'].toString() === item.itemNumber.toString()
        );

        if (!priceItem) priceManagementLabels.push(item);
      });

      const newPriceManagementLabels = priceManagementLabels.map(item => {
        return {
          'Product Value': item.itemNumber,
          'Product Description': item.description,
          UOM: item.oracleUnitOfMeasurementName,
          Value: item.price,
          Status: 'Removed',
        };
      });

      const priceManagementConcatData = action.data.data.concat(
        newPriceManagementLabels
      );

      const indexOfLastPrice = initialState.currentPage * initialState.pageSize;
      const indexOfFirstPrice = indexOfLastPrice - initialState.pageSize;
      const totalPagesPrice = Math.round(
        action.data.data.length / initialState.pageSize
      );
      const currentDataPrice = action.data.data.slice(
        indexOfFirstPrice,
        indexOfLastPrice
      );

      return Object.assign({}, state, {
        data: currentDataPrice || initialState.data,
        origData: action.data.data,
        backupData: action.data.data,
        uploadData: priceManagementConcatData,
        currentPage: indexOfFirst,
        totalPages: totalPagesPrice,
      });

    case types.COMBINATION_LABEL_MNGMNT_DATA:
      action.data.data.forEach(item => {
        const selectedLabel =
          action.data.labels.length > 0 &&
          action.data.labels.filter(
            i =>
              i.itemNumber === item['Item Number'].toString() &&
              i.applicantAccountNumber === item['Appl Account#'].toString() &&
              i.manufacturerAccountNumber === item['Mfr Account#'].toString() &&
              i.fileNumber === item['Basic File#'].toString()
          );

        if (selectedLabel.length > 0) {
          const newSelectedLabel = [];

          newSelectedLabel.push({
            'Appl Account#': selectedLabel[0].applicantAccountNumber,
            'Mfr Account#': selectedLabel[0].manufacturerAccountNumber,
            'Basic File#': selectedLabel[0].fileNumber,
            'Item Number': selectedLabel[0].itemNumber,
            'Item Description': selectedLabel[0].description,
            'Customer Part No.': selectedLabel[0].customerPartNumber,
          });
          item['Status'] = 'Active';
          item.childItem = newSelectedLabel;
        } else {
          item['Status'] = 'New';
          item.childItem = newSelectedLabel;
        }
      });

      const newRemoveCombiLabels = [];
      const combinationLabelConcatData =
        action.data.data.concat(newRemoveCombiLabels);

      const indexOfLastCombi = initialState.currentPage * initialState.pageSize;
      const indexOfFirstCombi = indexOfLastCombi - initialState.pageSize;
      const totalPagesCombi = Math.round(
        combinationLabelConcatData.length / initialState.pageSize
      );
      const currentDataCombi = combinationLabelConcatData.slice(
        indexOfFirst,
        indexOfLastCombi
      );

      return Object.assign({}, state, {
        data: currentDataCombi || initialState.data,
        origData: action.data.data,
        backupData: action.data.data,
        uploadData: combinationLabelConcatData,
        currentPage: indexOfFirstCombi,
        totalPages: totalPagesCombi,
      });
    case types.COURIER_FOR_UPDATE_DATA:
      const newSelectedLabel = [];
      action.data.data.forEach(item => {
        newSelectedLabel.push({
          'Oracle Order Number': item['Oracle Order Number'].toString(),
          'Tracking number': item['Tracking number'].toString(),
          'Courier Name': item['Courier Name'].toString(),
          Status: 'Active',
        });
      });

      return Object.assign({}, state, {
        // data: currentDataCourier || initialState.data,
        data: newSelectedLabel || initialState.data,
        origData: action.data.data,
        // backupData: action.data.data,
        uploadData: action.data.data,
        // currentPage: indexOfFirstCourier,
        // totalPages: totalPagesCourier,
      });

    case types.COURIER_MANAGEMENT_DATA:
      const newSelectedCourier = [];
      action.data.data.forEach(item => {
        const selectedCourier =
          action.data.orderCourier.length > 0 &&
          action.data.orderCourier.filter(
            i => i.orderNumber === item['Oracle Order Number'].toString()
          );

        if (selectedCourier.length > 0) {
          newSelectedCourier.push({
            'Oracle Order Number': item['Oracle Order Number'].toString(),
            'Tracking number': item['Tracking number'].toString(),
            'Courier Name': item['Courier Name'].toString(),
            Status: 'Active',
          });
        }
      });

      return Object.assign({}, state, {
        data: newSelectedCourier || initialState.data,
        origData: action.data.data,
      });
    case types.PRICE_MNGMNT_FORM:
      return Object.assign({}, state, {
        form: action.data || initialState.form,
      });

    case types.UPDATE_CHANGE_RELATIONSHIP:
      return Object.assign({}, state, {
        changeRelationshipForm:
          action.data || initialState.changeRelationshipForm,
      });
    case types.PRICE_MNGMNT_CLEAR:
      return Object.assign({}, state, {
        isSuccess: false,
        loading: false,
        form: {
          showErrors: false,
          validationErrors: {},
        },
      });

    case types.UPDATE_CHANGE_RELATIONSHIP_CLEAR:
      return Object.assign({}, state, {
        isSuccess: false,
        loading: false,
        changeRelationshipForm: {
          showErrors: false,
          validationErrors: {},
        },
      });
    //can use on filter
    case types.LABEL_SUPPLIER_EMAIL_TEMPLATES_FILTER:
      const origData = [];

      if (
        action.data.selectedPrinterFileNumbers &&
        action.data.selectedPrinterFileNumbers.length > 0
      ) {
        action.data.selectedPrinterFileNumbers.forEach(item => {
          const datas = state.labelSupplierEmails.filter(
            i => i.printerFileNumber === item.trim()
          );

          datas.forEach(data => {
            origData.push(data);
          });
        });
      }

      if (
        action.data.selectedPrinterPSNs &&
        action.data.selectedPrinterPSNs.length > 0
      ) {
        action.data.selectedPrinterPSNs.forEach(item => {
          const datas = state.labelSupplierEmails.filter(
            i => i.printerPSN === item.trim()
          );

          datas.forEach(data => {
            origData.push(data);
          });
        });
      }

      if (
        action.data.selectedEmailAddresses &&
        action.data.selectedEmailAddresses.length > 0
      ) {
        action.data.selectedEmailAddresses.forEach(item => {
          const datas = state.labelSupplierEmails.filter(
            i => i.emailAddress === item.trim()
          );

          datas.forEach(data => {
            origData.push(data);
          });
        });
      }

      let totalPagesLabels = 0;
      if (origData.length > 0) {
        totalPagesLabels = Math.round(origData.length / initialState.pageSize);
      } else {
        totalPagesLabels = 0;
      }

      return Object.assign({}, state, {
        labelSupplierEmailsFiltered: origData.length > 0 ? origData : [],
        mapLabels: origData.map(item => {
          return {
            'Printer File Number': item.printerFileNumber,
            'Printer PSN': item.printerPSN,
            'Email Address': item.emailAddress,
            'Email Status': item.emailStatus,
            'Updated By': item.updatedByDisplay,
          };
        }),
        currentPage: initialState.currentPage,
        totalPages: totalPagesLabels,
        noFilter: false,
      });

    case types.GET_COMBINATION_LABELS_FILTER:
      let origDataGetCombinationLabelsFilter = [];

      if (
        action.data.selectedItemNos &&
        action.data.selectedItemNos.length > 0
      ) {
        action.data.selectedItemNos.forEach(item => {
          const datas = state.origLabels.filter(
            i => i['Item Number'] === item.trim()
          );

          datas.forEach(data => {
            origDataGetCombinationLabelsFilter.push(data);
          });
        });
      }

      if (
        action.data.selectedApplicantAccntNos &&
        action.data.selectedApplicantAccntNos.length > 0
      ) {
        if (
          origDataGetCombinationLabelsFilter &&
          origDataGetCombinationLabelsFilter.length > 0
        ) {
          action.data.selectedApplicantAccntNos.forEach(item => {
            const datas = origDataGetCombinationLabelsFilter.filter(
              i => i['Appl Account#'] === item.trim()
            );

            origDataGetCombinationLabelsFilter = datas;
          });
        } else {
          action.data.selectedApplicantAccntNos.forEach(item => {
            const datas = state.origLabels.filter(
              i => i['Appl Account#'] === item.trim()
            );

            datas.forEach(data => {
              origDataGetCombinationLabelsFilter.push(data);
            });
          });
        }
      }

      if (
        action.data.selectedManufacturerAccntNos &&
        action.data.selectedManufacturerAccntNos.length > 0
      ) {
        if (
          origDataGetCombinationLabelsFilter &&
          origDataGetCombinationLabelsFilter.length > 0
        ) {
          action.data.selectedManufacturerAccntNos.forEach(item => {
            const datas = origDataGetCombinationLabelsFilter.filter(
              i => i['Mfr Account#'] === item.trim()
            );

            origDataGetCombinationLabelsFilter = datas;
          });
        } else {
          action.data.selectedManufacturerAccntNos.forEach(item => {
            const datas = state.origLabels.filter(
              i => i['Mfr Account#'] === item.trim()
            );

            datas.forEach(data => {
              origDataGetCombinationLabelsFilter.push(data);
            });
          });
        }
      }

      if (
        action.data.selectedFileNos &&
        action.data.selectedFileNos.length > 0
      ) {
        if (
          origDataGetCombinationLabelsFilter &&
          origDataGetCombinationLabelsFilter.length > 0
        ) {
          action.data.selectedFileNos.forEach(item => {
            const datas = origDataGetCombinationLabelsFilter.filter(
              i => i['Basic File#'] === item.trim()
            );

            origDataGetCombinationLabelsFilter = datas;
          });
        } else {
          action.data.selectedFileNos.forEach(item => {
            const datas = state.origLabels.filter(
              i => i['Basic File#'] === item.trim()
            );

            datas.forEach(data => {
              origDataGetCombinationLabelsFilter.push(data);
            });
          });
        }
      }

      if (
        action.data.selectedCustomerPartNos &&
        action.data.selectedCustomerPartNos.length > 0
      ) {
        if (
          origDataGetCombinationLabelsFilter &&
          origDataGetCombinationLabelsFilter.length > 0
        ) {
          action.data.selectedCustomerPartNos.forEach(item => {
            const datas = origDataGetCombinationLabelsFilter.filter(
              i => i['Customer Part No.'] === item.trim()
            );

            origDataGetCombinationLabelsFilter = datas;
          });
        } else {
          action.data.selectedCustomerPartNos.forEach(item => {
            const datas = state.origLabels.filter(
              i => i['Customer Part No.'] === item.trim()
            );

            datas.forEach(data => {
              origDataGetCombinationLabelsFilter.push(data);
            });
          });
        }
      }

      let totalPagesCombinationLabelsFilter = 0;
      if (origDataGetCombinationLabelsFilter.length > 0) {
        totalPagesCombinationLabelsFilter = Math.round(
          origDataGetCombinationLabelsFilter.length / initialState.pageSize
        );
      } else {
        totalPagesCombinationLabelsFilter = Math.round(
          state.origLabels.length / initialState.pageSize
        );
      }

      const indexOfLastLabels =
        initialState.currentPage * initialState.pageSize;
      const indexOfFirstLabels = indexOfLastLabels - initialState.pageSize;

      return Object.assign({}, state, {
        mapLabels:
          origDataGetCombinationLabelsFilter.length > 0
            ? origDataGetCombinationLabelsFilter.slice(
                indexOfFirstLabels,
                indexOfLastLabels
              )
            : [],
        filteredLabels: origDataGetCombinationLabelsFilter
          ? origDataGetCombinationLabelsFilter
          : [],
        currentPage: initialState.currentPage,
        totalPages: totalPagesCombinationLabelsFilter,
        noFilter: false,
      });
    case types.CLEAR_COMBINATION_LABELS_FILTER:
      const stateClearFilter = Object.assign({}, state, {});

      const combinationLabels = [];

      stateClearFilter.labels.map(l =>
        combinationLabels.push({
          'Appl Account#': l.applicantAccountNumber,
          'Mfr Account#': l.manufacturerAccountNumber,
          'Basic File#': l.fileNumber,
          'Item Number': l.itemNumber,
          'Item Description': l.itemDescription,
          'Customer Part No.': l.customerPartNo,
          'Updated By': l.updatedByDisplay,
          'Updated Date': l.updatedDate,
          Status: l.oracleStatus
            ? l.oracleStatus === 0
              ? 'Active'
              : 'InActive'
            : 'Active',
        })
      );

      state.origLabels = combinationLabels;

      const totalPagesLabelsClearFilter = Math.round(
        state.origLabels.length / initialState.pageSize
      );

      const indexOfLastLabelsClearFIlter =
        initialState.currentPage * initialState.pageSize;
      const indexOfFirstLabelsClearFIlter =
        indexOfLastLabelsClearFIlter - initialState.pageSize;
      let currentDataLablesClearFIlter = state.origLabels.slice(
        indexOfFirstLabelsClearFIlter,
        indexOfLastLabelsClearFIlter
      );

      if (
        stateClearFilter.selectedItems &&
        stateClearFilter.selectedItems.length > 0
      ) {
        currentDataLablesClearFIlter = currentDataLablesClearFIlter.filter(
          c => stateClearFilter.selectedItems.indexOf(c.Id) === -1
        );
      }

      return Object.assign({}, state, {
        mapLabels: currentDataLablesClearFIlter,
        filteredLabels: state.origLabels,
        currentPage: initialState.currentPage,
        totalPages: totalPagesLabelsClearFilter,
        noFilter: true,
      });

    case types.CLEAR_LABEL_SUPPLIER_EMAIL_FILTER:
      const totalPagesLabelsClearFilterLabelSupplierEmail = Math.round(
        state.labelSupplierEmails.length / initialState.pageSize
      );

      const indexOfLastLabelsClearFIlterSupplierEmail =
        initialState.currentPage * initialState.pageSize;
      const indexOfFirstLabelsClearFIlterSupplierEmail =
        indexOfLastLabelsClearFIlterSupplierEmail - initialState.pageSize;
      const currentDataLablesClearFIlterSupplierEmail =
        state.labelSupplierEmails.slice(
          indexOfFirstLabelsClearFIlterSupplierEmail,
          indexOfLastLabelsClearFIlterSupplierEmail
        );

      return Object.assign({}, state, {
        mapLabels: currentDataLablesClearFIlterSupplierEmail.map(item => {
          return {
            'Printer File Number': item.printerFileNumber,
            'Printer PSN': item.printerPSN,
            'Email Address': item.emailAddress,
            'Email Status': item.emailStatus,
            'Updated By': item.updatedByDisplay,
          };
        }),
        labelSupplierEmailsFiltered: state.labelSupplierEmails,
        currentPage: initialState.currentPage,
        totalPages: totalPagesLabelsClearFilterLabelSupplierEmail,
        noFilter: true,
      });

    case types.GET_COURIER_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        orderCourier: action.data || initialState.labels,
        isSuccess: true,
      });

    case types.GET_COURIER_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        orderCourier: action.data,
        isSuccess: true,
      });
    case types.NON_DENOMINATIONAL_LABELS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.NON_DENOMINATIONAL_LABELS_SUCCESS:
    case types.NON_DENOMINATIONAL_LABELS_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        nonDenominationalAccounts:
          action.data || initialState.nonDenominationalAccounts,
        isSuccess: true,
      });
    case types.LOAD_NON_DENOMINATIONAL_ACCOUNTS:
      const nonDenominationalAccounts = [];
      action.data.data.forEach(item => {
        nonDenominationalAccounts.push({
          'Manufacturer Account#': item['Manufacturer Account#'].toString(),
          'Manufacturer Customer Name':
            item['Manufacturer Customer Name'].toString(),
          Status: item['Status'].toString(),
        });
      });

      return Object.assign({}, state, {
        data: nonDenominationalAccounts || initialState.data,
        origData: action.data.data,
        uploadData: action.data.data,
      });

    case types.UPDATE_SINGLE_RELATIONSHIP_CLEAR:
      return Object.assign({}, state, {
        isSuccess: false,
        loading: false,
        singleRelationShipForm: {
          showErrors: false,
          validationErrors: {},
        },
      });
    case types.UPDATE_SINGLE_RELATIONSHIP:
      return Object.assign({}, state, {
        singleRelationShipForm:
          action.data || initialState.singleRelationShipForm,
      });

    case types.ADD_SINGLE_CUSTOMER_COMBINATIONS_LABEL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.ADD_SINGLE_CUSTOMER_COMBINATIONS_LABEL_SUCCESS:
    case types.ADD_SINGLE_CUSTOMER_COMBINATIONS_LABEL_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        isSuccess: action.data.isSuccessful,
      });

    case types.REFRESH_CSV_DATA: {
      return Object.assign({}, state, {
        csvData: action.data,
      });
    }
    case types.LABEL_SUPPLIER_EMAIL_TEMPLATES_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        isLoadingViewActiveLabels: true,
      });

    case types.LABEL_SUPPLIER_EMAIL_TEMPLATES_FAILED:
      return Object.assign({}, state, {
        loading: false,
        isLoadingViewActiveLabels: false,
      });

    case types.LABEL_SUPPLIER_EMAIL_TEMPLATES_SUCCESS:
      return Object.assign({}, state, {
        labelSupplierEmails: action.data,
        labelSupplierEmailsFiltered: action.data,
        loading: false,
        isLoadingViewActiveLabels: false,
      });

    case types.LABEL_SUPPLIER_EMAIL_TEMPLATE_MNGMT_DATA:
      const uploadedSupplierEmails = action.data.data;

      const indexOfLastLabelSupplierMgngData =
        initialState.currentPage * initialState.pageSize;
      const indexOfFirsLabelSupplierMgngData =
        indexOfLastLabelSupplierMgngData - initialState.pageSize;
      const totalPagesLabelSupplierMgngData = Math.round(
        uploadedSupplierEmails.length / initialState.pageSize
      );

      const _currentDataLabelSupplierEmail = uploadedSupplierEmails.slice(
        indexOfFirsLabelSupplierMgngData,
        indexOfLastLabelSupplierMgngData
      );

      return Object.assign({}, state, {
        data: _currentDataLabelSupplierEmail,
        origData: action.data.data,
        backupData: action.data.data,
        uploadData: uploadedSupplierEmails,
        currentPage: initialState.currentPage,
        totalPages: totalPagesLabelSupplierMgngData,
        noFilter: true,
      });

    case types.LABEL_SUPPLIER_EMAIL_TEMPLATE_UPLOAD_REQUEST:
      return Object.assign({}, state, {
        loading: true,
      });

    case types.LABEL_SUPPLIER_EMAIL_TEMPLATE_UPLOAD_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        isSuccess: action.data.isSuccessful,
      });

    case types.LABEL_SUPPLIER_EMAIL_TEMPLATE_UPLOAD_FAILED:
      return Object.assign({}, state, {
        loading: false,
        isSuccess: action.data.isSuccessful,
      });

    case types.COMBINATION_ALLOWED_CCN_TEMPLATES_REQUEST:
      return Object.assign({}, state, {
        loading: true,
      });

    case types.COMBINATION_ALLOWED_CCN_TEMPLATES_FAILED:
      return Object.assign({}, state, {
        loading: false,
      });

    case types.COMBINATION_ALLOWED_CCN_TEMPLATES_SUCCESS:
      return Object.assign({}, state, {
        combinationAllowedCcns: action.data,
        loading: false,
      });

    case types.COMBINATION_ALLOWED_CCN_TEMPLATE_UPLOAD_REQUEST:
      return Object.assign({}, state, {
        loading: true,
      });

    case types.COMBINATION_ALLOWED_CCN_TEMPLATE_UPLOAD_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        isSuccess: action.data.isSuccessful,
      });

    case types.COMBINATION_ALLOWED_CCN_TEMPLATE_UPLOAD_FAILED:
      return Object.assign({}, state, {
        loading: false,
        isSuccess: action.data.isSuccessful,
      });

    case types.COMBINATION_ALLOWED_CCN_TEMPLATE_MNGMT_DATA:
      const uploadedCombiAllowedCcnData = action.data.data;

      const indexOfLastCombiAllowedCcnData =
        initialState.currentPage * initialState.pageSize;
      const indexOfFirsCombiAllowedCcnData =
        indexOfLastCombiAllowedCcnData - initialState.pageSize;
      const totalPagesCombiAllowedCcnData = Math.round(
        uploadedCombiAllowedCcnData.length / initialState.pageSize
      );

      const _currentDataCombiAllowedCcnData = uploadedCombiAllowedCcnData.slice(
        indexOfFirsCombiAllowedCcnData,
        indexOfLastCombiAllowedCcnData
      );

      return Object.assign({}, state, {
        data: _currentDataCombiAllowedCcnData,
        origData: action.data.data,
        backupData: action.data.data,
        uploadData: uploadedCombiAllowedCcnData,
        currentPage: initialState.currentPage,
        totalPages: totalPagesCombiAllowedCcnData,
        noFilter: true,
      });

    case types.SELECT_ITEM_NUMBER:
      const stateItemNumbers = Object.assign({}, state, {});

      const itemNumber = stateItemNumbers.mapLabels.find(
        m => m.Id === action.data.id
      );

      if (itemNumber) {
        itemNumber.checked = action.data.checked;
      }

      stateItemNumbers.mapLabels.filter(m => m.Id === action.data.id)[0] =
        itemNumber;

      return {
        ...state,
        mapLabels: stateItemNumbers.mapLabels,
        selectedItems: getSelectedItems(stateItemNumbers.mapLabels),
        selectedItemNumbers: getSelectedItems(stateItemNumbers.mapLabels),
      };

    case types.SELECT_ALL_ITEMS:
      const stateAllItemNumbers = Object.assign({}, state, {});

      const newLabels = [];

      stateAllItemNumbers.mapLabels.forEach(item =>
        newLabels.push({
          ...item,
          checked: action.data.checked,
        })
      );

      return {
        ...state,
        mapLabels: newLabels,
        selectedItems: getSelectedItems(newLabels),
        selectedItemNumbers: getSelectedItems(newLabels),

        isSelectedAll: action.data.checked,
      };
    case types.REMOVE_COMBINATION_LABELS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case types.REMOVE_COMBINATION_LABELS_SUCCESS:
    case types.REMOVE_COMBINATION_LABELS_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        isRemoveSuccess: action.data.isSuccessful,
        selectedItemNumbers: [],
      });
    case types.REMOVE_LABELS_RESET:
      return Object.assign({}, state, {
        isRemoveSuccess: false,
      });
    case types.CREATE_CURRENCY_HANDLING_FEE_REQUEST:
      return Object.assign({}, state, {
        loading: true,
      });

    case types.CREATE_CURRENCY_HANDLING_FEE_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        isSuccess: action.data.isSuccessful,
      });

    case types.CREATE_CURRENCY_HANDLING_FEE_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        isSuccess: action.data.isSuccessful,
      });

    case types.GET_CURRENCY_HANDLING_FEE_REQUEST:
      return Object.assign({}, state, {
        isLoadingViewActiveLabels: true,
      });

    case types.GET_CURRENCY_HANDLING_FEE_FAILURE:
      return Object.assign({}, state, {
        isLoadingViewActiveLabels: false,
      });

    case types.GET_CURRENCY_HANDLING_FEE_SUCCESS:
      return Object.assign({}, state, {
        currencyHandlingFees: action.data,
        isLoadingViewActiveLabels: false,
      });

    case types.LOAD_CURRENCY_HANDLING_FEE:
      const newHandlingFees = [];
      action.data.data.forEach(item => {
        newHandlingFees.push({
          Currency: item['Currency'].toString(),
          'Handling Fee': item['Handling Fee'].toString(),
        });
      });

      return Object.assign({}, state, {
        data: newHandlingFees || initialState.data,
        origData: action.data.data,
        uploadData: action.data.data,
      });

    case types.GET_CONFIGURATION_SETTINGS_REQUEST:
      return Object.assign({}, state, {
        configurationSettings: null,
        isLoadingConfigurationSettings: true,
      });

    case types.GET_CONFIGURATION_SETTINGS_SUCCESS:
      return Object.assign({}, state, {
        configurationSettings: action.data,
        isLoadingConfigurationSettings: false,
      });

    case types.GET_CONFIGURATION_SETTINGS_FAILURE:
      return Object.assign({}, state, {
        configurationSettings: null,
        isLoadingConfigurationSettings: false,
      });

    case types.UPDATE_CONFIGURATION_SETTINGS_REQUEST:
      return Object.assign({}, state, {
        isLoadingConfigurationSettings: true,
        isSuccessUpdateConfigurationSettings: false,
      });

    case types.UPDATE_CONFIGURATION_SETTINGS_SUCCESS:
      return Object.assign({}, state, {
        isLoadingConfigurationSettings: false,
        isSuccessUpdateConfigurationSettings: true,
      });

    case types.UPDATE_CONFIGURATION_SETTINGS_FAILURE:
      return Object.assign({}, state, {
        isLoadingConfigurationSettings: false,
        isSuccessUpdateConfigurationSettings: false,
      });

    case types.CREATE_LABEL_SUPPLIER_EMAIL_REQUEST:
      return Object.assign({}, state, {
        loading: true,
      });

    case types.CREATE_LABEL_SUPPLIER_EMAIL_SUCCESS:
    case types.CREATE_LABEL_SUPPLIER_EMAIL_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        isSuccessCreateLabelSupplierEmail: action.data.isSuccessful,
      });

    case types.CLEAR_LABEL_SUPPLIER_EMAIL_FORM:
      return Object.assign({}, state, {
        isSuccess: false,
        loading: false,
        createLabelSupplierEmailForm: {
          showErrors: false,
          validationErrors: {},
        },
      });
    case types.UPDATE_LABEL_SUPPLIER_EMAIL_FORM:
      return Object.assign({}, state, {
        createLabelSupplierEmailForm:
          action.data || initialState.createLabelSupplierEmailForm,
      });

    case types.UPDATE_LABEL_ITEM_REQUEST:
      return Object.assign({}, state, {
        loading: true,
      });

    case types.UPDATE_LABEL_ITEM_SUCCESS:
    case types.UPDATE_LABEL_ITEM_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        isSuccessUpdateLabelItem: action.data.isSuccessful,
      });

    case types.GET_LABEL_ITEMS_REQUEST:
      return Object.assign({}, state, {
        loading: true,
      });

    case types.GET_LABEL_ITEMS_SUCCESS:
    case types.GET_LABEL_ITEMS_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        labelItems: action.data.data,
        labelItemsTotalPages: action.data.totalPages,
        labelItemsCurrentPage: action.data.currentPage,
        labelItemsPageSize: action.data.pageSize,
      });
    case types.STANDARD_LABEL_TEMPLATES_FILTER:
      const origDataStandardLabels = [];

      if (
        action.data.selectedStandardItemNumbers &&
        action.data.selectedStandardItemNumbers.length > 0
      ) {
        action.data.selectedStandardItemNumbers.forEach(item => {
          const datas = state.labels.filter(i => i.itemNumber === item.trim());

          datas.forEach(data => {
            origDataStandardLabels.push(data);
          });
        });
      }

      if (
        action.data.selectedStandardCcns &&
        action.data.selectedStandardCcns.length > 0
      ) {
        action.data.selectedStandardCcns.forEach(item => {
          const datas = state.labels.filter(i => i.ccn === item.trim());

          datas.forEach(data => {
            origDataStandardLabels.push(data);
          });
        });
      }

      let totalPagesStandardLabels = 0;
      if (origDataStandardLabels.length > 0) {
        totalPagesStandardLabels = Math.round(
          origDataStandardLabels.length / initialState.pageSize
        );
      } else {
        totalPagesStandardLabels = 0;
      }

      return Object.assign({}, state, {
        standardLabelsFiltered:
          origDataStandardLabels.length > 0 ? origDataStandardLabels : [],
        mapLabels: origDataStandardLabels.map(item => {
          return {
            'Label Acct': item.labelAccount,
            CCN: item.ccn,
            'Label Description': item.description,
            'Item Number': item.itemNumber,
            'Label Order Package': item.labelOrderPackage,
            'Min. Order Qty': item.minOrderQuantity,
            'ORACLE UOM': item.oracleUnitOfMeasurementName,
            'Image URL': item.imageUrl,
          };
        }),
        currentPage: initialState.currentPage,
        totalPages: totalPagesStandardLabels,
        noFilter: false,
      });
    case types.CLEAR_STANDARD_LABEL_TEMPLATES_FILTER:
      const totalPagesClearStandardLabelsFilter = Math.round(
        state.labels.length / initialState.pageSize
      );

      const indexOfLastLabelsClearStandardLabelsFilter =
        initialState.currentPage * initialState.pageSize;
      const indexOfFirstLabelsClearStandardLabelsFilter =
        indexOfLastLabelsClearStandardLabelsFilter - initialState.pageSize;
      const currentDataLabelsClearStandardLabelsFilter = state.labels.slice(
        indexOfFirstLabelsClearStandardLabelsFilter,
        indexOfLastLabelsClearStandardLabelsFilter
      );

      return Object.assign({}, state, {
        mapLabels: currentDataLabelsClearStandardLabelsFilter.map(item => {
          return {
            'Label Acct': item.labelAccount,
            CCN: item.ccn,
            'Label Description': item.description,
            'Item Number': item.itemNumber,
            'Label Order Package': item.labelOrderPackage,
            'Min. Order Qty': item.minOrderQuantity,
            'ORACLE UOM': item.oracleUnitOfMeasurementName,
            'Image URL': item.imageUrl,
          };
        }),
        standardLabelsFiltered: state.labels,
        currentPage: initialState.currentPage,
        totalPages: totalPagesClearStandardLabelsFilter,
        noFilter: true,
      });
  }
};

export default priceManagementReducer;
