import React, { Component } from "react";
import { Button, Modal, Header } from "semantic-ui-react";

class ManualPricingModal extends Component {
  render() {
    const {
      isManualPricingModal,
      handleIsManualPricingModalClose
    } = this.props;
    return (
      <Modal
        open={isManualPricingModal}
        size="mini"
        onClose={handleIsManualPricingModalClose}
      >
        <Modal.Header>
          <Header icon="info circle" textAlign="center" />
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <p>
              Please contact us at{" "}
              <a href="tel:1-877-854-3577">1-877-854-3577</a> ext 2 or{" "}
              <a href="mailto:field@ul.com">field@ul.com</a> and a Project
              Handler will assist with your request
            </p>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button primary basic onClick={handleIsManualPricingModalClose}>
            OK
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default ManualPricingModal;
