import * as types from './common.type';
import api from '../../../services/api';

export const updatePsnResult = (obj, type) => {
  return {
    type: type,
    data: obj,
  };
};

export const updateUserRoleResult = (obj, type) => {
  return {
    type: type,
    data: obj,
  };
};

export const updateUserPsn = model => {
  return async dispatch => {
    dispatch(updatePsnResult(null, types.UPDATE_USER_PSN_REQUEST));

    await api.User.updateUserPsn(model)
      .then(result => {
        result.isUpdateAutomatic = model.isUpdateAutomatic
          ? model.isUpdateAutomatic
          : false;
        dispatch(updatePsnResult(result, types.UPDATE_USER_PSN_SUCCESS));

        // if (!result.data.isDualRole) {
        //   window.location.reload();
        // }

        return result;
      })
      .catch(result => {
        dispatch(
          updatePsnResult(result.response.data, types.UPDATE_USER_PSN_FAILURE)
        );
      });
  };
};

export const updateUserReset = () => {
  return async dispatch => {
    dispatch(updatePsnResult(null, types.UPDATE_USER_PSN_RESET));
  };
};

export const updateUserRole = userRoleModel => {
  return async dispatch => {
    dispatch(updateUserRoleResult(null, types.UPDATE_USER_ROLE_REQUEST));

    return await api.User.updateUserRole(userRoleModel)
      .then(result => {
        dispatch(updateUserRoleResult(result, types.UPDATE_USER_ROLE_SUCCESS));
        return result;
      })
      .catch(result => {
        dispatch(
          updateUserRoleResult(result.data, types.UPDATE_USER_ROLE_FAILURE)
        );
      });
  };
};

export const updateUserRoleReset = () => {
  return async dispatch => {
    dispatch(updateUserRoleResult(null, types.UPDATE_USER_ROLE_RESET));
  };
};

export const getPsnAssignments = psnList => {
  return async dispatch => {
    dispatch(updatePsnResult(null, types.PSN_ASSIGNMENTS_REQUEST));

    await api.PartySiteNumber.getPsnAssignments(psnList)
      .then(result => {
        dispatch(updatePsnResult(result, types.PSN_ASSIGNMENTS_SUCCESS));
        return result;
      })
      .catch(result => {
        dispatch(
          updatePsnResult(result.response.data, types.PSN_ASSIGNMENTS_FAILURE)
        );
      });
  };
};

export const getActivePage = page => {
  return async dispatch => {
    dispatch(updateUserRoleResult(page, types.GET_ACTIVE_PAGE));
  };
};

export const setFilterOptionColumns = roleId => {
  return async dispatch => {
    dispatch(updateUserRoleResult(roleId, types.SET_FILTER_OPTION_COLUMNS));
  };
};
