import React, { Component } from "react";
import { Table } from "semantic-ui-react";
import { renderDisc, serviceTypeDisp } from "../../../helpers/component-helper";
import { Link } from "react-router-dom";
import { GetStatusCode } from "../field-evaluation-helper";

class FeAdminSearchItem extends Component {
  render() {
    const { data } = this.props;
    return (
      <Table.Row>
        <Table.Cell>{data.assignedToName}</Table.Cell>
        <Table.Cell textAlign="center">
          {renderDisc(data.status)}
          {GetStatusCode(data.statusName)}
        </Table.Cell>
        <Table.Cell>
          <Link to={`/home/preview-order-details/${data.orderNumber}`}>
            {data.orderNumber}
          </Link>
        </Table.Cell>
        <Table.Cell>{data.accountNumber}</Table.Cell>
        <Table.Cell>{data.orderSubmittedDate}</Table.Cell>
        <Table.Cell>{data.clientReferenceNumber}</Table.Cell>
        <Table.Cell>
          {data.addressLine1} {data.city} {data.state} {data.country}{" "}
          {data.postalCode}
        </Table.Cell>
        <Table.Cell>
          {data.serviceSubProductName
            ? `Field Evaluation of (${data.productQuantity}) ${
                data.isExistingUlEquipment ? "UL Listed" : ""
              } ${
                data.serviceSubProductCode == "PNL"
                  ? data.serviceSubProductName
                  : data.serviceSubProductName.substr(
                      0,
                      data.serviceSubProductName.lastIndexOf("/")
                    )
              }, Modified for ${data.serviceProductName}, Single Site Visit`
            : null}
        </Table.Cell>
        <Table.Cell>{data.orderExpiredDate}</Table.Cell>
        <Table.Cell>{data.lastOrderActivityDate}</Table.Cell>
      </Table.Row>
    );
  }
}

export default FeAdminSearchItem;
