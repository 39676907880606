import React, { Component } from "react";
import {
  Table,
  Button,
  Icon,
  Dropdown,
  Checkbox,
  Popup,
  Tab
} from "semantic-ui-react";
import PropTypes from "prop-types";
import Role from "../../enums/role";

class RoleAssignmentTableItem extends Component {
  onLoadDefaultRoleValue(id) {
    const { roles } = this.props;
    var role = roles.filter((r) => r.id == id);
    return role.length > 0 ? role[0].value : roles[0].value;
  }

  onLoadDefaultLegalEntityValue(id) {
    const { legalEntities } = this.props;

    // if user legal entity 0 assign to No Legal Entity
    if (id === 0) id = 8;

    var legalEntity = legalEntities.filter((r) => r.id == id);
    return legalEntity.length > 0
      ? legalEntity[0].value
      : legalEntities[0].value;
  }

  onChangeDropdown(id, e) {
    const { data } = this.props;
    data.userRoleId = parseInt(e.currentTarget.id);
    data.userRole = e.currentTarget.children[0].innerText;
  }

  onChangeDropdownLegalEntity(id, e) {
    const { data } = this.props;
    data.legalEntityId = parseInt(e.currentTarget.id);
    data.legalEntity = e.currentTarget.children[0].innerText;
  }

  onClickSave() {
    const { data } = this.props;
    this.props.onSaving(data);
  }

  onClickSaveLegalEntity() {
    const { data } = this.props;
    this.props.onSaveLegalEntity(data);
  }

  onClickEdit(isEdit) {
    const { data } = this.props;
    this.props.onEditing(data, isEdit);
  }

  onClickEditLegalEntity(isEditLegalEntity) {
    const { data } = this.props;
    this.props.onEditLegalEntity(data, isEditLegalEntity);
  }

  onToggleSuspend() {
    const { data } = this.props;
    this.props.onSuspending(data);
  }

  onToggleAuthorizeNDLabel() {
    const { data } = this.props;
    this.props.onToggleAuthorizeNDLabel(data);
  }

  onViewNote() {
    const { data } = this.props;
    this.props.onViewNote(data);
  }

  onViewClaims(claimsType) {
    const { data } = this.props;
    this.props.onViewClaims(data, claimsType);
  }

  render() {
    const { data, roles, legalEntities, columnOptions } = this.props;
    let isDisabled = data.isSuspended ? "disabled" : "";

    return (
      <Table.Row>
        {columnOptions &&
          columnOptions.map((column, idx) => {
            if (
              column &&
              column.key === "partySiteNumbers" &&
              column.visible === 1 &&
              column.disabled === 0
            ) {
              return (
                <Table.Cell key={idx} className="list-td">
                  <div className="fixed-table">
                    <div className="ellipsis-preview">
                      <span>
                        {data.partySiteNumber}
                        {data.partySiteNumber ? (
                          <Popup
                            trigger={
                              <Button
                                compact
                                circular
                                icon="eye"
                                size="mini"
                                floated="right"
                                onClick={this.onViewClaims.bind(this, 1)}
                              />
                            }
                            content="Display data"
                          />
                        ) : null}
                      </span>
                    </div>
                  </div>
                </Table.Cell>
              );
            } else if (
              column &&
              column.key === "email" &&
              column.visible === 1 &&
              column.disabled === 0
            ) {
              return (
                <Table.Cell key={idx} className="list-td">
                  <div className="fixed-table">
                    <div className="ellipsis-preview">
                      <span>
                        {data.email}
                        {data.email ? (
                          <Popup
                            trigger={
                              <Button
                                compact
                                circular
                                icon="eye"
                                size="mini"
                                floated="right"
                                onClick={this.onViewClaims.bind(this, 2)}
                              />
                            }
                            content="Display data"
                          />
                        ) : null}
                      </span>
                    </div>
                  </div>
                </Table.Cell>
              );
            } else if (
              column &&
              column.key === "firstName" &&
              column.visible === 1 &&
              column.disabled === 0
            ) {
              return (
                <Table.Cell key={idx} className="list-td">
                  <div className="fixed-table">
                    <div className="ellipsis-preview">{data.firstName}</div>
                  </div>
                </Table.Cell>
              );
            } else if (
              column &&
              column.key === "lastName" &&
              column.visible === 1 &&
              column.disabled === 0
            ) {
              return (
                <Table.Cell key={idx} className="list-td">
                  <div className="fixed-table">
                    <div className="ellipsis-preview">{data.lastName}</div>
                  </div>
                </Table.Cell>
              );
            } else if (
              column &&
              column.key === "role" &&
              column.visible === 1 &&
              column.disabled === 0
            ) {
              return (
                <Table.Cell key={idx} className="list-td">
                  <div className="fixed-table">
                    <div>
                      {data.isEdit ? (
                        <span>
                          <Dropdown
                            inline
                            options={roles}
                            defaultValue={this.onLoadDefaultRoleValue(
                              data.roleId
                            )}
                            onChange={this.onChangeDropdown.bind(this, data.id)}
                          />
                          <Button.Group compact size="mini" floated="right">
                            <Button
                              animated="fade"
                              onClick={this.onClickEdit.bind(this, false)}
                            >
                              <Button.Content visible>Cancel</Button.Content>
                              <Button.Content hidden>
                                <Icon name="cancel"></Icon>
                              </Button.Content>
                            </Button>
                            <Button.Or />
                            <Button
                              primary
                              animated="fade"
                              className={data.isLoading}
                              onClick={this.onClickSave.bind(this)}
                            >
                              <Button.Content visible>Save</Button.Content>
                              <Button.Content hidden>
                                <Icon name="save"></Icon>
                              </Button.Content>
                            </Button>
                          </Button.Group>
                        </span>
                      ) : (
                        <span>
                          {data.role}
                          <Button
                            compact
                            circular
                            icon="edit"
                            size="mini"
                            floated="right"
                            onClick={this.onClickEdit.bind(this, true)}
                            disabled={isDisabled}
                          />
                        </span>
                      )}
                    </div>
                  </div>
                </Table.Cell>
              );
            } else if (
              column &&
              column.key === "legalEntity" &&
              column.visible === 1 &&
              column.disabled === 0
            ) {
              return (
                <Table.Cell key={idx} className="list-td">
                  <div className="fixed-table">
                    <div>
                      {data.isEditLegalEntity ? (
                        <span>
                          <Dropdown
                            inline
                            options={legalEntities}
                            defaultValue={this.onLoadDefaultLegalEntityValue(
                              data.legalEntityId
                            )}
                            onChange={this.onChangeDropdownLegalEntity.bind(
                              this,
                              data.id
                            )}
                          />
                          <Button.Group compact size="mini" floated="right">
                            <Button
                              animated="fade"
                              onClick={this.onClickEditLegalEntity.bind(
                                this,
                                false
                              )}
                            >
                              <Button.Content visible>Cancel</Button.Content>
                              <Button.Content hidden>
                                <Icon name="cancel"></Icon>
                              </Button.Content>
                            </Button>
                            <Button.Or />
                            <Button
                              primary
                              animated="fade"
                              className={data.isLoading}
                              onClick={this.onClickSaveLegalEntity.bind(this)}
                            >
                              <Button.Content visible>Save</Button.Content>
                              <Button.Content hidden>
                                <Icon name="save"></Icon>
                              </Button.Content>
                            </Button>
                          </Button.Group>
                        </span>
                      ) : (
                        <span>
                          {data.legalEntity &&
                            (data.buType !== 0
                              ? data.legalEntity
                              : "No Legal Entity")}
                          <Button
                            compact
                            circular
                            icon="edit"
                            size="mini"
                            floated="right"
                            onClick={this.onClickEditLegalEntity.bind(
                              this,
                              true
                            )}
                            disabled={isDisabled}
                          />
                        </span>
                      )}
                    </div>
                  </div>
                </Table.Cell>
              );
            } else if (
              column &&
              column.key === "createdDateTime" &&
              column.visible === 1 &&
              column.disabled === 0
            ) {
              return (
                <Table.Cell key={idx} className="list-td">
                  <div className="fixed-table">
                    <div className="ellipsis-preview">
                      {data.createdDatetime}
                    </div>
                  </div>
                </Table.Cell>
              );
            } else if (
              column &&
              column.key === "suspendDeactivate" &&
              column.visible === 1 &&
              column.disabled === 0
            ) {
              return (
                <Table.Cell key={idx} className="list-td">
                  <div className="fixed-table">
                    <div className="ellipsis-preview">
                      <Checkbox
                        toggle
                        onChange={this.onToggleSuspend.bind(this)}
                        checked={data.isSuspended}
                      />
                    </div>
                  </div>
                </Table.Cell>
              );
            } else if (
              column &&
              column.key === "authorizeNDLabel" &&
              column.visible === 1 &&
              column.disabled === 0
            ) {
              return data.roleId == Role.applicant ||
                data.roleId == Role.manufacturer ? (
                <Table.Cell key={idx} className="list-td">
                  <div className="fixed-table">
                    <div className="ellipsis-preview">
                      <Checkbox
                        toggle
                        onChange={this.onToggleAuthorizeNDLabel.bind(this)}
                        checked={data.isAuthorizedNDLabel}
                      />
                    </div>
                  </div>
                </Table.Cell>
              ) : (
                <Table.Cell></Table.Cell>
              );
            }
          })}
        <Table.Cell>
          <Button
            size="mini"
            icon
            labelPosition="left"
            onClick={this.onViewNote.bind(this)}
            color={data.remarks.length > 0 ? "orange" : "primary"}
          >
            <Icon name="sticky note outline" />
            View
          </Button>
        </Table.Cell>
      </Table.Row>
    );
  }
}

RoleAssignmentTableItem.propTypes = {
  data: PropTypes.object.isRequired,
  roles: PropTypes.array.isRequired,
  legalEntities: PropTypes.array.isRequired
};

export default RoleAssignmentTableItem;
