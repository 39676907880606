import * as types from "./callback.type";
import api from "../../services/api";

const dispatchResult = (type, payload) => {
  return { type: type, payload: payload };
};

export const validateAuthenticationCode = (
  authenticationCode,
  codeVerifier
) => {
  return async (dispatch) => {
    dispatch(dispatchResult(types.VALIDATE_AUTHENTICATION_CODE_REQUEST));

    await api.Auth.validateAuthenticationCode(authenticationCode, codeVerifier)
      .then((result) => {
        if (result)
          //console.log("actionResult:" + result);
          dispatch(
            dispatchResult(types.VALIDATE_AUTHENTICATION_CODE_SUCCESS, result)
          );
      })
      .catch((error) => {
        dispatch(
          dispatchResult(types.VALIDATE_AUTHENTICATION_CODE_FAILURE, error)
        );
      });
  };
};
