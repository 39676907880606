import { requests } from '../request-factory';

const healthDashboardService = {
  getApiStatus: (day, week, orderStatusDay, authWeek) => {
    return requests.get(
      `dashboard/health-dashboard-status?filters=${JSON.stringify({
        day: day,
        week: week,
        orderStatusDay: orderStatusDay,
        AuthLetterWeek: authWeek,
      })}`
    );
  },
};

export default healthDashboardService;
