import * as types from './common.type';
import { filterOptionColumns } from '../../view-orders/common/label-filter-option.columns';
let initialState = {
  data: [],
  psnAssignments: [],
  isSuccessUpdatePsn: false,
  isLoadingUpdatePsn: false,
  partySiteNumber: null,
  psnCountry: null,
  isSuccessUpdateRole: false,
  form: {
    showErrors: false,
    validationErrors: {},
  },
  activePage: '',
  translations: null,
  filterOptions: [],
};

const commonUserReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
    case types.UPDATE_USER_PSN_REQUEST:
      const updateResultRequest = Object.assign({}, state, {
        data: action.data || initialState.data,
      });
      updateResultRequest.isSuccessUpdatePsn = false;
      updateResultRequest.partySiteNumber = null;
      updateResultRequest.isDualRole = false;
      updateResultRequest.isLoadingUpdatePsn = true;

      return updateResultRequest;
    case types.UPDATE_USER_PSN_SUCCESS:
      const updateResultSuccess = Object.assign({}, state, {
        data: action.data || initialState.data,
      });
      updateResultSuccess.isSuccessUpdatePsn = action.data.isSuccessful;
      updateResultSuccess.partySiteNumber = action.data.data.partySiteNumber;
      updateResultSuccess.isDualRole = action.data.data.isDualRole;
      updateResultSuccess.roleId = action.data.data.roleId;
      updateResultSuccess.isLoadingUpdatePsn = false;
      updateResultSuccess.psnCountry = action.data.data.psnCountry;
      updateResultSuccess.isUpdateAutomatic = action.data.isUpdateAutomatic;
      return updateResultSuccess;
    case types.UPDATE_USER_PSN_FAILURE:
      const updateResultFailure = Object.assign({}, state, {
        data: action.data || initialState.data,
      });
      updateResultFailure.isSuccessUpdatePsn = action.data.isSuccessful;
      updateResultFailure.partySiteNumber = action.data.data;
      updateResultFailure.isDualRole = false;
      updateResultFailure.isLoadingUpdatePsn = false;

      return updateResultFailure;
    case types.UPDATE_USER_PSN_RESET:
      const resetResult = Object.assign({}, state, {
        data: action.data || initialState.data,
      });
      resetResult.isSuccessUpdatePsn = false;
      return resetResult;
    case types.UPDATE_USER_ROLE_REQUEST:
      const updateRoleRequest = Object.assign({}, state, {
        data: action.data || initialState.data,
      });
      updateRoleRequest.isSuccessUpdateRole = false;
      return updateRoleRequest;
    case types.UPDATE_USER_ROLE_SUCCESS:
      const updateRoleResult = Object.assign({}, state, {
        data: action.data || initialState.data,
      });
      updateRoleResult.isSuccessUpdateRole = action.data.isSuccessful;
      return updateRoleResult;
    case types.UPDATE_USER_ROLE_FAILURE:
      const updateRoleFailure = Object.assign({}, state, {
        data: action.data || initialState.data,
      });
      updateRoleFailure.isSuccessUpdateRole = false;
      return updateRoleFailure;
    case types.UPDATE_USER_ROLE_RESET:
      const userRoleReset = Object.assign({}, state, {
        data: action.data || initialState.data,
      });
      userRoleReset.isSuccessUpdateRole = false;
      return userRoleReset;
    case types.PSN_ASSIGNMENTS_REQUEST:
      return Object.assign({}, state, {
        psnAssignments: [],
        isSuccessGetPsns: false,
      });
    case types.PSN_ASSIGNMENTS_SUCCESS:
      return Object.assign({}, state, {
        psnAssignments: action.data || initialState.data,
        isSuccessGetPsns: true,
      });
    case types.PSN_ASSIGNMENTS_FAILURE:
      return Object.assign({}, state, {
        psnAssignments: [],
        isSuccessGetPsns: false,
      });
    case types.GET_ACTIVE_PAGE:
      return Object.assign({}, state, {
        activePage: action.data || initialState.data,
      });
    case types.SET_FILTER_OPTION_COLUMNS:
      const filterOptions = filterOptionColumns(action.data);
      return Object.assign({}, state, {
        filterOptions,
      });
  }
};

export default commonUserReducer;
