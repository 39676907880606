import {
  CHANGE_LANGUAGE_REQUEST,
  CHANGE_LANGUAGE_SUCCESS,
  // CHANGE_LANGUAGE_FAILURE,
  LANGUAGE_OPTIONS
} from "../../constants/language";

const changeLanguageRequest = (value, text) => {
  return { payload: { value, text }, type: CHANGE_LANGUAGE_REQUEST };
};

// const changeLanguageFailure = errors => {
//   return { type: CHANGE_LANGUAGE_FAILURE, payload: errors };
// };

const changeLanguageSuccess = (value, text) => {
  return { type: CHANGE_LANGUAGE_SUCCESS, payload: { value, text } };
};

export const changeLanguage = value => {
  return async dispatch => {
    const filtered = LANGUAGE_OPTIONS.filter(x => x.value === value);
    const text = filtered.length > 0 ? filtered[0].text : "Select Language";
    dispatch(changeLanguageRequest(value, text));
    dispatch(changeLanguageSuccess(value, text));
  };
};
