import { connect } from "react-redux";
import React, { Component } from "react";
import { Table } from "semantic-ui-react";

import CancellationValue from "./cancellation-value";

class Cancellation extends Component {
  createMarkup(string) {
    return { __html: string };
  }

  render() {
    const { projectDetails } = this.props;
    let cancellation = CancellationValue(projectDetails);
    return (
      <Table attached>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell textAlign="center">
              PROJECT CANCELLATION
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <span
                dangerouslySetInnerHTML={this.createMarkup(cancellation)}
              ></span>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    projectDetails: state.feProjectDetails
  };
};

export default connect(mapStateToProps, null)(Cancellation);
