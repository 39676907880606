import { requests } from '../request-factory';

const roleService = {
  getRoles: () => requests.get('role'),
  changeRole: params => requests.post('role', params),
  getLegalEntities: () => requests.get('legal-entity'),
  changeLegalEntity: params => requests.post('legal-entity', params),
  getFOEmails: () => requests.get('role/list/emails'),
  getFOFirstNames: () => requests.get('role/list/firstnames'),
  getFOLastNames: () => requests.get('role/list/lastnames'),
  getFOLegalEntities: () => requests.get('role/list/legalentities'),
  getFOPartySiteNumbers: () => requests.get('role/list/partysitenumbers'),
  getFORoles: () => requests.get('role/list/roles'),
  getRolesByUserFilter: data => requests.post('role/byuserfilter', data),
  setUserFilter: data => requests.post('role/setfilteroption', data),
  getUserFilter: () => requests.get(`role/getfilteroption`),
};

export default roleService;
