import * as types from "./role-assignment.type";
import api from "../../services/api";

export const roleAssignmentResult = (obj, type) => {
  return {
    data: obj,
    type: type
  };
};

export const getAllUsers = (currentPage, pageSize) => {
  return async (dispatch) => {
    return await api.User.getAllUsers(currentPage, pageSize)
      .then((result) => {
        dispatch(roleAssignmentResult(result, types.GET_USERS_SUCCESS));
        return result;
      })
      .catch((result) => {
        dispatch(
          roleAssignmentResult(result.response.data, types.GET_USERS_FAILURE)
        );
      });
  };
};

export const getUsersByUserFilter = (data) => {
  return async (dispatch) => {
    await api.Role.getRolesByUserFilter(data)
      .then((result) => {
        dispatch(
          roleAssignmentResult(result, types.GET_ROLES_BY_USER_FILTER_SUCCESS)
        );
      })
      .catch((result) => {
        roleAssignmentResult(
          result.response.data,
          types.GET_ROLES_BY_USER_FILTER_FAILURE
        );
      });
  };
};

export const getRoles = () => {
  return async (dispatch) => {
    return await api.Role.getRoles()
      .then((result) => {
        dispatch(roleAssignmentResult(result, types.GET_ROLES_SUCCESS));
        return result;
      })
      .catch((result) => {
        dispatch(
          roleAssignmentResult(result.response.data, types.GET_ROLES_FAILURE)
        );
      });
  };
};

export const getLegalEntities = () => {
  return async (dispatch) => {
    return await api.Role.getLegalEntities()
      .then((result) => {
        dispatch(
          roleAssignmentResult(result, types.GET_LEGAL_ENTITIES_SUCCESS)
        );
        return result;
      })
      .catch((result) => {
        dispatch(
          roleAssignmentResult(
            result.response.data,
            types.GET_LEGAL_ENTITIES_FAILURE
          )
        );
      });
  };
};

export const doChangeRole = (params) => {
  return async (dispatch) => {
    return await api.Role.changeRole(params)
      .then((result) => {
        dispatch(roleAssignmentResult(result, types.CHANGE_ROLE_SUCCESS));
        return result;
      })
      .catch((result) => {
        dispatch(
          roleAssignmentResult(result.response.data, types.CHANGE_ROLE_FAILURE)
        );
      });
  };
};

export const doChangeLegalEntity = (params) => {
  return async (dispatch) => {
    return await api.Role.changeLegalEntity(params)
      .then((result) => {
        dispatch(
          roleAssignmentResult(result, types.CHANGE_LEGAL_ENTITIES_SUCCESS)
        );
        return result;
      })
      .catch((result) => {
        dispatch(
          roleAssignmentResult(
            result.response.data,
            types.CHANGE_LEGAL_ENTITIES_FAILURE
          )
        );
      });
  };
};

export const doEditRole = (params) => {
  return (dispatch) =>
    dispatch(roleAssignmentResult(params, types.CHANGE_ROLE_REQUEST));
};

export const doSuspendUser = (params) => {
  return async (dispatch) => {
    return await api.User.suspendUser(params)
      .then((result) => {
        dispatch(roleAssignmentResult(result, types.SUSPEND_USER_SUCCESS));
        return result;
      })
      .catch((result) => {
        dispatch(
          roleAssignmentResult(result.response.data, types.SUSPEND_USER_FAILURE)
        );
      });
  };
};

export const doGetNotes = (userId) => {
  return async (dispatch) => {
    return await api.User.getAccountNotes(userId)
      .then((result) => {
        dispatch(roleAssignmentResult(result, types.LIST_NOTE_SUCCESS));
        return result;
      })
      .catch((result) => {
        dispatch(
          roleAssignmentResult(result.response.data, types.LIST_NOTE_FAILURE)
        );
      });
  };
};

export const doAddNote = (params) => {
  return async (dispatch) => {
    return await api.User.addNote(params)
      .then((result) => {
        dispatch(roleAssignmentResult(result, types.ADD_NOTE_SUCCESS));
        return result;
      })
      .catch((result) => {
        dispatch(
          roleAssignmentResult(result.response.data, types.ADD_NOTE_FAILURE)
        );
      });
  };
};

export const setUserFilter = (params) => {
  return async (dispatch) => {
    return await api.Role.setUserFilter(params)
      .then((result) => {
        dispatch(
          roleAssignmentResult(result, types.SET_RA_USER_FILTER_SUCCESS)
        );
        return result;
      })
      .catch((result) => {
        dispatch(
          roleAssignmentResult(
            result.response.data,
            types.SET_RA_USER_FILTER_FAILURE
          )
        );
      });
  };
};

export const getUserFilter = () => {
  return async (dispatch) => {
    dispatch(roleAssignmentResult(null, types.GET_RA_USER_FILTER_REQUEST));
    await api.Role.getUserFilter()
      .then((result) => {
        dispatch(
          roleAssignmentResult(result, types.GET_RA_USER_FILTER_SUCCESS)
        );
      })
      .catch((result) => {
        dispatch(
          roleAssignmentResult(
            result.response.data,
            types.GET_RA_USER_FILTER_FAILURE
          )
        );
      });
  };
};

export const getFOEmails = () => {
  return async (dispatch) => {
    return await api.Role.getFOEmails()
      .then((result) => {
        dispatch(roleAssignmentResult(result, types.GET_FO_EMAILS_SUCCESS));
        return result;
      })
      .catch((result) => {
        dispatch(
          roleAssignmentResult(
            result.response.data,
            types.GET_FO_EMAILS_FAILURE
          )
        );
      });
  };
};

export const getFOFirstNames = () => {
  return async (dispatch) => {
    return await api.Role.getFOFirstNames()
      .then((result) => {
        dispatch(roleAssignmentResult(result, types.GET_FO_FIRSTNAMES_SUCCESS));
        return result;
      })
      .catch((result) => {
        dispatch(
          roleAssignmentResult(
            result.response.data,
            types.GET_FO_FIRSTNAMES_FAILURE
          )
        );
      });
  };
};

export const getFOLastNames = () => {
  return async (dispatch) => {
    return await api.Role.getFOLastNames()
      .then((result) => {
        dispatch(roleAssignmentResult(result, types.GET_FO_LASTNAMES_SUCCESS));
        return result;
      })
      .catch((result) => {
        dispatch(
          roleAssignmentResult(
            result.response.data,
            types.GET_FO_LASTNAMES_FAILURE
          )
        );
      });
  };
};

export const getFOLegalEntities = () => {
  return async (dispatch) => {
    return await api.Role.getFOLegalEntities()
      .then((result) => {
        dispatch(
          roleAssignmentResult(result, types.GET_FO_LEGALENTITIES_SUCCESS)
        );
        return result;
      })
      .catch((result) => {
        dispatch(
          roleAssignmentResult(
            result.response.data,
            types.GET_FO_LEGALENTITIES_FAILURE
          )
        );
      });
  };
};

export const getFOPartySiteNumbers = () => {
  return async (dispatch) => {
    return await api.Role.getFOPartySiteNumbers()
      .then((result) => {
        dispatch(roleAssignmentResult(result, types.GET_FO_PSN_SUCCESS));
        return result;
      })
      .catch((result) => {
        dispatch(
          roleAssignmentResult(result.response.data, types.GET_FO_PSN_FAILURE)
        );
      });
  };
};

export const getFORoles = () => {
  return async (dispatch) => {
    return await api.Role.getFORoles()
      .then((result) => {
        dispatch(roleAssignmentResult(result, types.GET_FO_ROLES_SUCCESS));
        return result;
      })
      .catch((result) => {
        dispatch(
          roleAssignmentResult(result.response.data, types.GET_FO_ROLES_FAILURE)
        );
      });
  };
};

export const filterOptionReset = () => {
  return (dispatch) =>
    dispatch(roleAssignmentResult(null, types.ROLE_FILTEROPTION_RESET));
};

export const updateData = (result) => {
  return (dispatch) =>
    dispatch(roleAssignmentResult(result, types.ROLE_ASSIGNMENT_UPDATE));
};

export const updateForm = (result) => {
  return (dispatch) =>
    dispatch(roleAssignmentResult(result, types.ROLE_ASSIGNMENT_FORM));
};

export const doGetUserClaimsByEmail = (email) => {
  return async (dispatch) => {
    return await api.User.getUserClaimsByEmail(email)
      .then((result) => {
        dispatch(roleAssignmentResult(result, types.CLAIMS_DETAILS_SUCCESS));
        return result;
      })
      .catch((result) => {
        dispatch(
          roleAssignmentResult(
            result.response.data,
            types.CLAIMS_DETAILS_FAILURE
          )
        );
      });
  };
};

export const doGetUserClaimsByPsn = (psn) => {
  return async (dispatch) => {
    return await api.User.getUserClaimsByPsn(psn)
      .then((result) => {
        dispatch(roleAssignmentResult(result, types.CLAIMS_DETAILS_SUCCESS));
        return result;
      })
      .catch((result) => {
        dispatch(
          roleAssignmentResult(
            result.response.data,
            types.CLAIMS_DETAILS_FAILURE
          )
        );
      });
  };
};

export const doClearClaimsDetails = () => (dispatch) =>
  dispatch(roleAssignmentResult(null, types.CLEAR_CLAIMS_DETAILS));

export const clearForm = () => (dispatch) =>
  dispatch(roleAssignmentResult(null, types.ROLE_ASSIGNMENT_CLEAR));

export const authorizeNDLabel = (params) => {
  return async (dispatch) => {
    return await api.User.authorizeNDLabel(params)
      .then((result) => {
        dispatch(
          roleAssignmentResult(result, types.AUTHORIZE_ND_LABEL_SUCCESS)
        );
        return result;
      })
      .catch((result) => {
        dispatch(
          roleAssignmentResult(
            result.response.data,
            types.AUTHORIZE_ND_LABEL_FAILURE
          )
        );
      });
  };
};
