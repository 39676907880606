import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
import * as actions from "./admin-search.action";
import {
  Container,
  Segment,
  Table,
  Grid,
  Pagination,
  Message,
  Button
} from "semantic-ui-react";
import { CustomHeader } from "../../components/labels/labels";
import AdminSearchResultItem from "./admin-search-result-item";
import * as cancelOrderActions from "../order/cancel-order/cancel-order.action";
import { toast } from "react-semantic-toasts";

class AdminSearchResult extends Component {
  state = { column: "", direction: "descending" };

  // componentWillUnmount() {
  //   this.props.clear();
  // }

  onClickNewSearch() {
    this.props.clear();
  }

  onCancel(item) {
    debugger;
    if (!item.orderId) return;

    const params = { orderId: item.orderId };

    this.props.doCancelOrder(params).then((s) => {
      if (s.isSuccessful) {
        this.props.clear();
        setTimeout(() => {
          toast({
            title: "Success!",
            description: <p>Order has been cancelled.</p>,
            time: 5000,
            size: "small",
            icon: "check"
          });
        }, 100);
      } else {
        setTimeout(() => {
          toast({
            type: "error",
            title: "Error!",
            description: "Something went wrong.",
            time: 5000,
            size: "small",
            icon: "delete"
          });
        }, 100);
      }

      // this.props.doCancelOrderRequesting(data.data);
      // this.props.notificationCount();
    });
  }

  getSortOrder(sortColumn) {
    const { column, direction } = this.state;
    if (column !== sortColumn) {
      this.setState({ column: sortColumn });
    } else {
      if (direction === "ascending") {
        this.setState({ direction: "descending" });
        return "descending";
      } else {
        this.setState({ direction: "ascending" });
        return "ascending";
      }
    }
  }

  handleSort(clickedColumn) {
    let { data, form, searchType } = this.props;
    const { direction } = this.state;

    let sort = this.getSortOrder(clickedColumn);

    this.props.doSearch(
      form.psn,
      form.accountNumber,
      form.orderConfirmationNumber,
      form.oracleOrderNumber,
      clickedColumn,
      direction ? sort : direction,
      data.currentPage,
      data.pageSize,
      form.searchTerm,
      searchType
    );
  }

  render() {
    const { data, form, searchType } = this.props;
    const { column, direction } = this.state;
    return (
      <div className="container-wrapper">
        <Container fluid>
          <Grid>
            <Grid.Row>
              <Grid.Column width={8}>
                <CustomHeader message="Global Order Search Results" />
              </Grid.Column>
              <Grid.Column width={8}>
                <Button
                  basic
                  color="blue"
                  floated="right"
                  onClick={this.onClickNewSearch.bind(this)}
                >
                  New Search
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Segment>
            <Table striped celled selectable sortable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell
                    sorted={column == "Status" ? direction : null}
                    onClick={this.handleSort.bind(this, "Status")}
                  >
                    Order Status
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={column == "PSN" ? direction : null}
                    onClick={this.handleSort.bind(this, "PSN")}
                  >
                    PSN
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={
                      column == "ManufacturerAccountNumber" ? direction : null
                    }
                    onClick={this.handleSort.bind(
                      this,
                      "ManufacturerAccountNumber"
                    )}
                  >
                    Manufacturer Account #
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={
                      column == "OrderConfirmationNumber" ? direction : null
                    }
                    onClick={this.handleSort.bind(
                      this,
                      "OrderConfirmationNumber"
                    )}
                  >
                    Order Confirmation #
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={column == "OracleNumber" ? direction : null}
                    onClick={this.handleSort.bind(this, "OracleNumber")}
                  >
                    Oracle Sales #
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={column == "FileNumber" ? direction : null}
                    onClick={this.handleSort.bind(this, "FileNumber")}
                  >
                    File Number
                  </Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {data.data &&
                  data.data.map((data, idx) => (
                    <AdminSearchResultItem
                      key={idx}
                      data={data}
                      onCancel={this.onCancel.bind(this)}
                    />
                  ))}
              </Table.Body>
            </Table>
            {data && data.totalPages > 0 ? (
              <Pagination
                activePage={data.currentPage}
                totalPages={data.totalPages}
                onPageChange={(e, { activePage }) => {
                  this.props.doSearch(
                    form.psn,
                    form.accountNumber,
                    form.orderConfirmationNumber,
                    form.oracleOrderNumber,
                    data.sortColumn,
                    data.sortOrder,
                    activePage,
                    data.pageSize,
                    form.searchTerm,
                    searchType
                  );
                }}
              />
            ) : (
              <Message>No Order Found</Message>
            )}
          </Segment>
        </Container>
      </div>
    );
  }
}

AdminSearchResult.propTypes = {
  data: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  cancelOrder: state.cancelOrder
});

const mapDispatchToProps = (dispatch) => {
  return {
    doSearch: (
      pSN,
      accountNumber,
      orderConfirmationNumber,
      oracleOrderNumber,
      sortColumn,
      sortOrder,
      currentPage,
      pageSize,
      searchTerm,
      searchType
    ) =>
      dispatch(
        actions.doSearch(
          pSN,
          accountNumber,
          orderConfirmationNumber,
          oracleOrderNumber,
          sortColumn,
          sortOrder,
          currentPage,
          pageSize,
          searchTerm,
          searchType
        )
      ),
    clear: () => dispatch(actions.clearAdminSearch()),
    doCancelOrder: (param) => dispatch(cancelOrderActions.doCancelOrder(param))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminSearchResult);
