import * as types from "./verify-email.type";

let initialState = {
  data: [],
  validateTokenData: {},
  resendTokenData: {},
  tokenDetailsData: {},
  form: {
    loading: false,
    message: "",
    showErrors: false,
    validationErrors: {}
  }
};

const verifyEmailReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
    case types.USER_VALIDATE_TOKEN_REQUEST:
    case types.USER_VALIDATE_TOKEN_SUCCESS:
    case types.USER_VALIDATE_TOKEN_FAILURE:
        return Object.assign({}, state, {
          validateTokenData: action.data || initialState.validateTokenData
        });
    case types.USER_RESEND_TOKEN_REQUEST:
    case types.USER_RESEND_TOKEN_SUCCESS:
    case types.USER_RESEND_TOKEN_FAILURE:
        return Object.assign({}, state, {
          resendTokenData: action.data || initialState.resendTokenData
        });
    case types.USER_TOKEN_DETAILS_REQUEST:
    case types.USER_TOKEN_DETAILS_SUCCESS:
    case types.USER_TOKEN_DETAILS_FAILURE:
        return Object.assign({}, state, {
          tokenDetailsData: action.data || initialState.datatokenDetailsData
        });
    case types.USER_REQUEST:
    case types.USER_SUCCESS:
    case types.USER_FAILURE:
      return Object.assign({}, state, {
        data: action.data || initialState.data
      });
    case types.CLEAR:
        return Object.assign({}, state, {
          data: initialState.data,
          validateTokenData: initialState.validateTokenData,
          resendTokenData: initialState.resendTokenData,
          tokenDetailsData: initialState.tokenDetailsData,
          form: { showErrors: false, validationErrors: {} }
        });
    case types.FORM:
      return Object.assign({}, state, {
        form: action.data || initialState.form
      });
    case types.LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        
      })
  }
};

export default verifyEmailReducer;
