import * as types from './combination-label-search.type';

const initialState = {
  hasError: false,
  message: '',
  loading: false,
  currentPage: 1,
  pageSize: 10,
  total: 0,
  totalPages: 1,
  form: {
    showErrors: false,
    validationErrors: {},
  },
  combinationLabels: [],
  combinationLabelsForExport: [],
  isDirty: false,
  fileNumberList: [],
};

const combinationLabelSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.COMBINATION_LABEL_SEARCH_REQUEST:
      return {
        ...state,
        hasError: false,
        loading: true,
      };
    case types.COMBINATION_LABEL_SEARCH_SUCCESS:
      const { currentPage, pageSize, total, totalPages } = action.payload;

      return Object.assign({}, state, {
        ...state,
        hasError: false,
        combinationLabels: action.payload.data,
        currentPage,
        pageSize,
        total,
        totalPages,
        loading: false,
        isDirty: true,
      });
    case types.COMBINATION_LABEL_SEARCH_FAILURE:
      return {
        ...state,
        message: action.payload,
        hasError: true,
        loading: false,
        isDirty: true,
      };
    case types.COMBINATION_LABEL_SEARCH_FOR_EXPORT_REQUEST:
      return {
        ...state,
        hasError: false,
        loading: true,
        combinationLabelsForExport: [],
      };
    case types.COMBINATION_LABEL_SEARCH_FOR_EXPORT_SUCCESS:
      let exportDetails = action.payload.data?.map(d => {
        return {
          ['Applicant Account Number']: d.applicantAccountNumber,
          ['Manufacturer Account Number']: d.manufacturerAccountNumber,
          ['File Number']: d.fileNumber,
          ['Item Number']: d.itemNumber,
          ['Item Description']: d.itemDescription,
          ['Customer Part No.']: d.customerPartNo,
          ['Item Status']: d.status,
        };
      });
      return Object.assign({}, state, {
        ...state,
        hasError: false,
        combinationLabelsForExport: exportDetails,
        loading: false,
      });
    case types.COMBINATION_LABEL_SEARCH_FOR_EXPORT_FAILURE:
      return {
        ...state,
        combinationLabelsForExport: [],
        hasError: true,
        loading: false,
      };
    case types.UPDATE_COMBINATION_LABEL_SEARCH_FORM:
      return Object.assign({}, state, {
        form: action.payload,
      });
    case types.GET_COMBINATION_LABELS_BY_FILTER_REQUEST:
      return {
        ...state,
        hasError: false,
        loading: true,
      };
    case types.GET_COMBINATION_LABELS_BY_FILTER_SUCCESS:
      return Object.assign({}, state, {
        hasError: false,
        data: action.payload.data,
        totalPages: action.payload.totalPages,
        total: action.payload.total,
        loading: false,
        itemNumbers:
          action.payload.data &&
          action.payload.data
            .filter(
              (row, index, self) =>
                index === self.findIndex(r => r.itemNumber === row.itemNumber)
            )
            .map(item => {
              return {
                key: item.id,
                text: item.itemNumber,
                value: item.itemNumber,
              };
            }),
        customerPartNumbers:
          action.payload.data &&
          action.payload.data
            .filter(
              (row, index, self) =>
                index ===
                  self.findIndex(
                    r => r.customerPartNumber === row.customerPartNumber
                  ) && row.customerPartNumber !== 'NULL'
            )
            .map(item => {
              return {
                key: item.id,
                text: item.customerPartNumber,
                value: item.customerPartNumber,
              };
            }),
      });

    case types.GET_COMBINATION_LABELS_BY_FILTER_FAILURE:
      return {
        ...state,
        hasError: true,
        loading: false,
      };
    case types.COMBINATION_LABEL_SEARCH_RESET:
      return Object.assign({}, state, {
        form: {
          showErrors: false,
          validationErrors: {},
        },
        combinationLabels: [],
        combinationLabelsForExport: [],
        isDirty: false,
      });
    case types.UPDATE_FILE_NUMBER_LIST:
      return Object.assign({}, state, {
        fileNumberList: action.payload,
      });
    default:
      return state;
  }
};

export default combinationLabelSearchReducer;
