export const LABELS_REQUEST = "LABELS_REQUEST";
export const LABELS_FAILURE = "LABELS_FAILURE";
export const LABELS_SUCCESS = "LABELS_SUCCESS";

export const SELECT_LABEL = "SELECT_LABEL";
export const TOGGLE_ALL_LABELS = "TOGGLE_ALL_LABELS";
export const SET_QUANTITY = "SET_QUANTITY";
export const SET_UNIT_OF_MEASUREMENT = "SET_UNIT_OF_MEASUREMENT";

export const RESET_LABEL_ORDER_FORM = "RESET_LABEL_ORDER_FORM";

export const MANUFACTURER_STANDARD_LABEL_ITEMS_REMOVE =
  "MANUFACTURER_STANDARD_LABEL_ITEMS_REMOVE";

export const ADD_TO_CART_RESET = "ADD_TO_CART_RESET";
export const ADD_TO_CART_REQUEST = "ADD_TO_CART_REQUEST";
export const ADD_TO_CART_FAILURE = "ADD_TO_CART_FAILURE";
export const ADD_TO_CART_SUCCESS = "ADD_TO_CART_SUCCESS";
