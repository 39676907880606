import * as types from "./auth.type";
import { Common } from "../../common";

const loggedInUserId = Common.getUserId();
const localUserId = +loggedInUserId ? JSON.parse(Common.getUserId()) : null;

const initialState = {
  hasError: false,
  message: null,
  authenticatedOnLogin: localUserId ? true : false,
  userId: localUserId ? localUserId : "",
  loading: false,
  isLogoutSuccess: false,
  rolePages: []
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOGOUT_SUCCESS:
      return {
        ...state,
        isLogoutSuccess: action.payload.isSuccessful,
        data: action.payload,
        userId: ""
      };
    case types.EXTERNAL_LOGOUT_SUCCESS:
      return {
        ...state,
        isLogoutSuccess: action.payload,
        userId: ""
      };
    case types.ROLEPAGES_SUCCESS:
      return {
        ...state,
        rolePages: action.payload
      };
    case types.VALIDATE_LOGIN_REQUEST:
      console.log("VALIDATE_LOGIN_REQUEST");
      return {
        ...state
      };
    case types.VALIDATE_LOGIN_SUCCESS:
      console.log("VALIDATE_LOGIN_SUCCESS");
      return {
        ...state
      };
    case types.VALIDATE_LOGIN_FAILURE:
      console.log("VALIDATE_LOGIN_FAILURE");
      return {
        ...state
      };
    default:
      return state;
  }
};

export default authReducer;
