import React, { Component, Fragment } from "react";
import { Accordion, Icon, Divider, Segment, Grid } from "semantic-ui-react";
import { CustomHeaderLabel } from "../../../components/labels/labels";
import { CustomHeaderBold } from "../../../components/labels/labels";

class LabelCustomerInformation extends Component {
  state = {
    activeIndex: 0
  };

  toggleHelp = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  render() {
    //console.log(this.props);
    const { activeIndex } = this.state;
    const { model } = this.props;
    return (
      <Fragment>
        <Segment>
          <Accordion>
            <Accordion.Title
              active={activeIndex === 0}
              index={0}
              onClick={this.toggleHelp}
            >
              <Icon name="dropdown" />
              <CustomHeaderLabel message="Customer information" />
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 0}>
              <Divider />

              <Grid columns={3}>
                <Grid.Column width={5} style={{ textAlign: "justify" }}>
                  <i>
                    Please review the information we have on record for your
                    account, to make sure our service contract information is up
                    to date.
                  </i>
                  <Divider hidden />
                  <i>
                    If you see any issues, please contact us to update our
                    records:
                  </i>
                  <Divider hidden />
                  <p style={{ fontSize: "10px", fontWeight: "700" }}>
                    CONTACT US
                  </p>
                  <p style={{ fontSize: "12px", color: "#00518a" }}>
                    +1.847.272.8800
                  </p>
                  <p style={{ fontSize: "12px", color: "#00518a" }}>
                    CustomerExperienceCenter@ul.com
                  </p>
                </Grid.Column>
                <Grid.Column width={5}>
                  <CustomHeaderBold message="Contact Information" />
                  <label>{model.name || "Ellen Rockett"}</label>
                  <Divider hidden fitted />
                  <label>+1.847.272.8800</label>
                  <Divider hidden fitted />
                  <label>{model.email || "Ellen Rockett"}</label>
                  <Divider hidden />
                  <label>333 Pfingsten Rd</label>
                  <Divider hidden fitted />
                  <label>Northbrook IL 60062</label>
                  <Divider hidden fitted />
                  <label>USA</label>
                </Grid.Column>
                <Grid.Column width={6}>
                  <CustomHeaderBold message="CCN" />
                  <label>**** **** **** 1234</label>
                  <Divider hidden />
                  <CustomHeaderBold message="Applicant Name" />
                  <label>Creative Services Label Orderers</label>
                  <Divider hidden />
                  <CustomHeaderBold message="Billing address" />
                  <label>1 Nishinokyo-Shimoai-cho</label>
                  <Divider hidden fitted />
                  <label>Kyoto</label>
                  <Divider hidden fitted />
                  <label>Kyoto Prefecture, 604-8436</label>
                  <Divider hidden fitted />
                  <label>Japan</label>

                  <Divider hidden />
                  <CustomHeaderBold message="File number" />
                  <label>9045167211</label>

                  <Divider hidden />
                  <CustomHeaderBold message="Account number" />
                  <label>2034226190</label>

                  <Divider hidden />
                  <CustomHeaderBold message="Party site number" />
                  <label>8088215442</label>
                </Grid.Column>
              </Grid>
            </Accordion.Content>
          </Accordion>
        </Segment>
      </Fragment>
    );
  }
}

export default LabelCustomerInformation;
