import React, { Component } from "react";
import {
  Form,
  Grid,
  Dropdown,
  Divider,
  Checkbox,
  Input,
  GridColumn,
  Table,
  Button,
  Modal,
  Popup,
  Icon,
  TextArea
} from "semantic-ui-react";
import {
  CustomLabel,
  CustomHeaderLabel,
  CustomHeaderBold,
  LabelBold
} from "../../components/labels/labels";
import * as actions from "./field-evaluation.action";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import { setHours, setMinutes, getDay } from "date-fns";
import { Common } from "../../common/common";
import Role from "../../enums/role";
import siteDetails from "./site-details";
import PrelimSiteLocation from "./components/fe/prelim-site-location";
import AHJDetails from "./components/fe/ahj-details";
import FinalSiteLocation from "./components/fe/final-site-location";

class ReservationDetails extends Component {
  state = {
    scheduleDate: new Date(),
    openAppointments: false,
    selectedEngineer: null,
    isSuccessGetSiteDetails: false
  };

  handleGetEngineerStates = (countryCode, locationType) => {
    this.props.getEngineerStates(countryCode, locationType);
  };

  handleGetEngineerCities = (stateCode, locationType) => {
    this.props.getEngineerCities(stateCode, locationType);
  };

  handleGetAhjStates = (countryCode) => {
    this.props.getAhjStates(countryCode);
  };

  handleGetAhjCities = (stateCode) => {
    this.props.getAhjCities(stateCode);
  };

  onChangeScheduleDate = (date) => {
    this.setState({ scheduleDate: date });
  };

  handleCheckboxChange = (cbName, cbValue) => {};

  handleSelectPpe = (ppe, checked) => {
    this.props.updatePpeSelections(ppe, checked);
  };

  handleSelectTraining = (training, checked, isTrainingTwoHours) => {
    this.props.updateTrainingSelections(training, checked, isTrainingTwoHours);
    if (isTrainingTwoHours != undefined)
      this.props.checkManualPricingTrigger(
        isTrainingTwoHours,
        "trainingTwoHours"
      );
  };

  handleOpenAppointments = (toggle) => {
    this.setState({ openAppointments: toggle });
    var { form } = this.props.fieldEvaluation;
    var scheduledDate =
      form.reservationDetails.preliminarySiteLocationSection.scheduleDate;

    var appointmentRequest = {
      envelope: {
        body: {
          extendedGetAppointmentsEx2: {
            task: {
              callID: "11434rewrewr-41",
              number: 0,
              earlyStart: "2020-03-20T00:50:00.476Z",
              dueDate: scheduledDate.toISOString(),
              lateStart: "2020-03-23T16:37:00.476Z",
              priority: 0,
              status: "Open",
              customer: "",
              region: "NA East",
              district: "New York",
              postcode: "11222",
              openDate: "2020-03-20T20:30:00.477Z",
              taskType: "30014613-30025099",
              duration: 3600,
              numberOfRequiredEngineers: 1,
              street: "196 Dupont St",
              city: "Brooklyn",
              state: "NY",
              countryID: "US",
              ulDivision: "North America",
              ulTimeZone: "Eastern Standard Time"
            },
            schedulePolicy: "Test AB",
            profile: "AM-PM",
            period: {
              start: "2020-03-20T00:00:00.477Z",
              finish: scheduledDate.toISOString()
            },
            excludeCurrentAppointment: true,
            gradeAppointments: true,
            suggestCandidateResources: true
          }
        }
      }
    };

    if (toggle) this.props.getAppointments(appointmentRequest);
  };
  handleSelectEngineer = (engineer) => {
    this.setState({ selectedEngineer: engineer });
    this.setState({ openAppointments: false });
  };

  render() {
    let { reservationDetails, siteDetails } = this.props.form;

    let {
      countryOptionsPrelimEngineerDetails,
      countryOptionsFinalEngineerDetails,
      stateOptionsPrelimEngineerDetails,
      stateOptionsFinalEngineerDetails,
      cityOptionsPrelimEngineerDetails,
      cityOptionsFinalEngineerDetails,
      countryOptionsAhjDetails,
      stateOptionsAhjDetails,
      cityOptionsAhjDetails,
      appointments,
      isLoadingGetAppointments
    } = this.props.fieldEvaluation;
    let {
      openAppointments,
      selectedEngineer,
      isSuccessGetSiteDetails
    } = this.state;

    let { preview } = this.props;

    let preliminarySiteDetails = null;
    let finalSiteDetails = null;
    let jurisdictionDetails = null;
    let ppeSelections = null;
    let trainingSelections = null;

    if (reservationDetails) {
      preliminarySiteDetails =
        reservationDetails.preliminarySiteLocationSection;
      finalSiteDetails = reservationDetails.finalSiteLocationSection;
      jurisdictionDetails =
        reservationDetails.authorityHavingJurisdictionDetails;
      ppeSelections = reservationDetails.ppeSelections;
      trainingSelections = reservationDetails.trainingSelections;

      var _trainingSelections = null;
      var _ppeSelections = null;

      if (finalSiteDetails.fieldEvaluationOrderId && !isSuccessGetSiteDetails) {
        this.handleGetEngineerStates(finalSiteDetails.country, 2);
        this.handleGetEngineerCities(finalSiteDetails.state, 2);

        this.setState({ isSuccessGetSiteDetails: true });
      }

      // if (
      //   jurisdictionDetails.fieldEvaluationOrderId &&
      //   !isSuccessGetSiteDetails
      // ) {
      //   this.handleGetEngineerStates(jurisdictionDetails.country, 3);
      //   this.handleGetEngineerCities(jurisdictionDetails.state, 3);
      // }

      if (
        reservationDetails.preliminarySiteLocationSection &&
        reservationDetails.preliminarySiteLocationSection.trainingSelections
      ) {
        _trainingSelections = reservationDetails.preliminarySiteLocationSection.trainingSelections.split(
          "|"
        );
      }

      if (
        reservationDetails.preliminarySiteLocationSection &&
        reservationDetails.preliminarySiteLocationSection.ppeSelections
      ) {
        _ppeSelections = reservationDetails.preliminarySiteLocationSection.ppeSelections.split(
          "|"
        );
      }

      if (reservationDetails.preliminarySiteLocationSection) {
        trainingSelections &&
          trainingSelections.map((training) => {
            if (
              _trainingSelections &&
              _trainingSelections.includes(training.value.toString())
            )
              training.checked = true;
            // 1 value: On-site Training, add value on trainingTwoHours
            training.trainingTwoHours =
              training.value == 1
                ? reservationDetails.preliminarySiteLocationSection
                    .isTrainingTwoHours
                : null;
          });

        ppeSelections &&
          ppeSelections.map((ppe) => {
            if (_ppeSelections && _ppeSelections.includes(ppe.value.toString()))
              ppe.checked = true;
          });
      }
    }

    const handleColor = (time) => {
      let roleId = Common.getUserRole();
      let newTime = time;

      if (preliminarySiteDetails.scheduleDate)
        newTime = preliminarySiteDetails.scheduleDate;

      // Business hours: 6:00 AM - 5:30 PM
      const res = roleId == Role.fieldEngineer || roleId == Role.projectHandler;
      const isPrevTime = res
        ? time.getHours() < 6 || time.getHours() > 17
          ? "custom-previous-time"
          : ""
        : "";

      return isPrevTime;
    };

    let roleId = Common.getUserRole();

    return (
      <Form>
        <Grid>
          <Grid.Row columns={3}>
            <Grid.Column>
              <Form.Field>Preferred Start Date & Time of Inspection</Form.Field>
              <DatePicker
                onChange={(date) => {
                  this.props.handleDropdownChange(
                    "scheduleDate",
                    date,
                    "RD-PSLS"
                  );
                }}
                selected={preliminarySiteDetails.scheduleDate || ""}
                error={this.props.getErrorFor("scheduleUnixTime", "RD-PSLS")}
                name="scheduleDate"
                minDate={new Date()}
                showDisabledMonthNavigation
                showTimeSelect
                timeFormat="hh:mm aa"
                timeIntervals={30}
                timeCaption="Time"
                dateFormat="MM/dd/yyyy h:mm aa"
                minTime={
                  roleId == Role.fieldEngineer || roleId == Role.projectHandler
                    ? setHours(setMinutes(new Date(), 0), 0)
                    : setHours(setMinutes(new Date(), 0), 6)
                }
                maxTime={setHours(setMinutes(new Date(), 30), 23)}
                timeClassName={handleColor.bind(this)}
                autoComplete="off"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider hidden />
        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column width="5">
              <Form.Field>
                Reserve An Engineer{" "}
                <Popup
                  trigger={<Icon name="info circle" />}
                  content="This function will be available soon."
                  size="mini"
                />
              </Form.Field>
              <Button
                primary
                onClick={this.handleOpenAppointments.bind(this, true)}
                disabled
              >
                Select An Engineer
              </Button>
            </Grid.Column>
            <Grid.Column verticalAlign="bottom" textAlign="left" width="7">
              {selectedEngineer && (
                <Table basic="very">
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>{selectedEngineer.engineerName}</Table.Cell>
                      <Table.Cell>
                        {selectedEngineer.appointmentStart}
                      </Table.Cell>
                      <Table.Cell>{selectedEngineer.location}</Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>

        {roleId == Role.fireEquipment && preliminarySiteDetails && (
          <span>
            <Divider hidden />
            <CustomLabel message="INSPECTION SITE LOCATION" />
            <Divider fitted />
            <Divider hidden />
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <span hidden={preview}>
                    <Form.Field
                      id="form-input-control-lcn"
                      control={Input}
                      label="* Business/Residence Name"
                      placeholder="Enter business/residence name"
                      name="residenceName"
                      onChange={(e) => {
                        this.props.handleChange(e, "RD-PSLS");
                      }}
                      value={preliminarySiteDetails.residenceName || ""}
                      error={this.props.getErrorFor("residenceName", "RD-PSLS")}
                      autoComplete="off"
                    />
                  </span>
                  <span hidden={!preview}>
                    <label>
                      <b>* Business/Residence Name</b>
                    </label>
                    <label className="custom-label">
                      {preliminarySiteDetails.residenceName
                        ? preliminarySiteDetails.residenceName
                        : ""}
                    </label>
                  </span>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row columns={3}>
                <Grid.Column>
                  <span hidden={preview}>
                    <Form.Field
                      id="form-input-control-lcn"
                      control={Input}
                      label="* Address Line 1"
                      placeholder="Enter address line 1"
                      name="addressLine1"
                      onChange={(e) => {
                        this.props.handleChange(e, "RD-PSLS");
                      }}
                      value={preliminarySiteDetails.addressLine1 || ""}
                      error={this.props.getErrorFor("addressLine1", "RD-PSLS")}
                      autoComplete="off"
                    />
                  </span>
                  <span hidden={!preview}>
                    <label>
                      <b>* Address Line 1</b>
                    </label>
                    <label className="custom-label">
                      {preliminarySiteDetails.addressLine1
                        ? preliminarySiteDetails.addressLine1
                        : ""}
                    </label>
                  </span>
                </Grid.Column>
                <Grid.Column>
                  <span hidden={preview}>
                    <Form.Field
                      id="form-input-control-lcn"
                      control={Input}
                      label="Address Line 2"
                      placeholder="Enter address line 2"
                      name="addressLine2"
                      onChange={(e) => {
                        this.props.handleChange(e, "RD-PSLS");
                      }}
                      value={preliminarySiteDetails.addressLine2 || ""}
                      error={this.props.getErrorFor("addressLine2", "RD-PSLS")}
                      autoComplete="off"
                    />
                  </span>
                  <span hidden={!preview}>
                    <label>
                      <b>Address Line 2</b>
                    </label>
                    <label className="custom-label">
                      {preliminarySiteDetails.addressLine2
                        ? preliminarySiteDetails.addressLine2
                        : ""}
                    </label>
                  </span>
                </Grid.Column>
                <Grid.Column>
                  <span hidden={preview}>
                    <Form.Field
                      id="form-input-control-lcn"
                      control={Input}
                      label="Address Line 3"
                      placeholder="Enter address line 3"
                      name="addressLine3"
                      onChange={(e) => {
                        this.props.handleChange(e, "RD-PSLS");
                      }}
                      value={preliminarySiteDetails.addressLine3 || ""}
                      error={this.props.getErrorFor("addressLine3", "RD-PSLS")}
                      autoComplete="off"
                    />
                  </span>
                  <span hidden={!preview}>
                    <label>
                      <b>Address Line 3</b>
                    </label>
                    <label className="custom-label">
                      {preliminarySiteDetails.addressLine3
                        ? preliminarySiteDetails.addressLine3
                        : ""}
                    </label>
                  </span>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row columns={4}>
                <Grid.Column>
                  <span hidden={preview}>
                    <Form.Field
                      control={Dropdown}
                      compact
                      selection
                      label="* Country"
                      placeholder="Select Country"
                      options={countryOptionsPrelimEngineerDetails}
                      onChange={(e, properties) => {
                        this.props.handleDropdownChange(
                          "country",
                          properties.value,
                          "RD-PSLS"
                        );
                        this.handleGetEngineerStates(properties.value, 1);
                      }}
                      value={preliminarySiteDetails.country || ""}
                      error={this.props.getErrorFor("country", "RD-PSLS")}
                    />
                  </span>
                  <span hidden={!preview}>
                    <label>
                      <b>* Country</b>
                    </label>
                    <label className="custom-label">
                      {preliminarySiteDetails.country
                        ? preliminarySiteDetails.country
                        : ""}
                    </label>
                  </span>
                </Grid.Column>
                <Grid.Column>
                  <span hidden={preview}>
                    <Form.Field
                      control={Dropdown}
                      compact
                      selection
                      label="* State"
                      placeholder="Select State"
                      options={stateOptionsPrelimEngineerDetails}
                      onChange={(e, properties) => {
                        this.props.handleDropdownChange(
                          "state",
                          properties.value,
                          "RD-PSLS"
                        );
                        this.handleGetEngineerCities(properties.value, 1);
                      }}
                      value={preliminarySiteDetails.state || ""}
                      error={this.props.getErrorFor("state", "RD-PSLS")}
                    />
                  </span>
                  <span hidden={!preview}>
                    <label>
                      <b>* State</b>
                    </label>
                    <label className="custom-label">
                      {preliminarySiteDetails.state
                        ? preliminarySiteDetails.state
                        : ""}
                    </label>
                  </span>
                </Grid.Column>
                <Grid.Column>
                  <span hidden={preview}>
                    <Form.Field
                      control={Dropdown}
                      compact
                      selection
                      label="* City"
                      placeholder="Select City"
                      options={cityOptionsPrelimEngineerDetails}
                      onChange={(e, properties) => {
                        this.props.handleDropdownChange(
                          "city",
                          properties.value,
                          "RD-PSLS"
                        );
                      }}
                      value={preliminarySiteDetails.city || ""}
                      error={this.props.getErrorFor("city", "RD-PSLS")}
                    />
                  </span>
                  <span hidden={!preview}>
                    <label>
                      <b>* City</b>
                    </label>
                    <label className="custom-label">
                      {preliminarySiteDetails.city
                        ? preliminarySiteDetails.city
                        : ""}
                    </label>
                  </span>
                </Grid.Column>

                {/* <Grid.Column>
                <span hidden={preview}>
                  <Form.Field
                    id="form-input-control-lcn"
                    control={Input}
                    label="* Province"
                    placeholder="Enter Province"
                    name="province"
                    onChange={e => {
                      this.props.handleChange(e, "RD-PSLS");
                    }}
                    value={preliminarySiteDetails.province || ""}
                    error={this.props.getErrorFor("province", "RD-PSLS")}
                    autoComplete="off"
                  />
                </span>
                <span hidden={!preview}>
                  <label>
                    <b>* Province</b>
                  </label>
                  <label className="custom-label">
                    {preliminarySiteDetails.province
                      ? preliminarySiteDetails.province
                      : ""}
                  </label>
                </span>
              </Grid.Column> */}
                <Grid.Column>
                  <span hidden={preview}>
                    <Form.Field
                      id="form-input-control-lcn"
                      control={Input}
                      label="* Postal Code"
                      placeholder="Enter postal code"
                      name="postalCode"
                      onChange={(e) => {
                        this.props.handleChange(e, "RD-PSLS");
                      }}
                      value={preliminarySiteDetails.postalCode || ""}
                      error={this.props.getErrorFor("postalCode", "RD-PSLS")}
                      autoComplete="off"
                    />
                  </span>
                  <span hidden={!preview}>
                    <label>
                      <b>* Postal Code</b>
                    </label>
                    <label className="custom-label">
                      {preliminarySiteDetails.postalCode
                        ? preliminarySiteDetails.postalCode
                        : ""}
                    </label>
                  </span>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={3}>
                <Grid.Column>
                  <span hidden={preview}>
                    <Form.Field
                      id="form-input-control-lcn"
                      control={Input}
                      label="* Site Contact Name"
                      placeholder="Enter site contact name"
                      name="siteContactName"
                      onChange={(e) => {
                        this.props.handleChange(e, "RD-PSLS");
                      }}
                      value={preliminarySiteDetails.siteContactName || ""}
                      error={this.props.getErrorFor(
                        "siteContactName",
                        "RD-PSLS"
                      )}
                      autoComplete="off"
                    />
                  </span>
                  <span hidden={!preview}>
                    <label>
                      <b>* Site Contact Name</b>
                    </label>
                    <label className="custom-label">
                      {preliminarySiteDetails.siteContactName
                        ? preliminarySiteDetails.siteContactName
                        : ""}
                    </label>
                  </span>
                </Grid.Column>
                <Grid.Column>
                  <span hidden={preview}>
                    <Form.Field
                      id="form-input-control-lcn"
                      control={Input}
                      label="* Primary Site Contact Tel #"
                      placeholder="Enter Primary Site Contact Tel #"
                      name="siteContactOfficeNumber"
                      onChange={(e) => {
                        this.props.handleChange(e, "RD-PSLS");
                      }}
                      value={
                        preliminarySiteDetails.siteContactOfficeNumber || ""
                      }
                      error={this.props.getErrorFor(
                        "siteContactOfficeNumber",
                        "RD-PSLS"
                      )}
                      autoComplete="off"
                    />
                  </span>
                  <span hidden={!preview}>
                    <label>
                      <b>* Primary Site Contact Tel #</b>
                    </label>
                    <label className="custom-label">
                      {preliminarySiteDetails.siteContactOfficeNumber
                        ? preliminarySiteDetails.siteContactOfficeNumber
                        : ""}
                    </label>
                  </span>
                </Grid.Column>
                <Grid.Column>
                  <span hidden={preview}>
                    <Form.Field
                      id="form-input-control-lcn"
                      control={Input}
                      label="Secondary Site Contact Tel #"
                      placeholder="Enter Secondary Site Contact Tel #"
                      name="siteContactMobileNumber"
                      onChange={(e) => {
                        this.props.handleChange(e, "RD-PSLS");
                      }}
                      value={
                        preliminarySiteDetails.siteContactMobileNumber || ""
                      }
                      error={this.props.getErrorFor(
                        "siteContactMobileNumber",
                        "RD-PSLS"
                      )}
                      autoComplete="off"
                    />
                  </span>
                  <span hidden={!preview}>
                    <label>
                      <b>Secondary Site Contact Tel #</b>
                    </label>
                    <label className="custom-label">
                      {preliminarySiteDetails.siteContactMobileNumber
                        ? preliminarySiteDetails.siteContactMobileNumber
                        : ""}
                    </label>
                  </span>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <span hidden={preview}>
                    <Form.Field
                      id="form-input-control-lcn"
                      control={Input}
                      label="Site Contact Email"
                      placeholder="Enter site contact email"
                      name="siteContactEmailAddress"
                      onChange={(e) => {
                        this.props.handleChange(e, "RD-PSLS");
                      }}
                      value={
                        preliminarySiteDetails.siteContactEmailAddress || ""
                      }
                      error={this.props.getErrorFor(
                        "siteContactEmailAddress",
                        "RD-PSLS"
                      )}
                      autoComplete="off"
                    />
                  </span>
                  <span hidden={!preview}>
                    <label>
                      <b>Site Contact Email</b>
                    </label>
                    <label className="custom-label">
                      {preliminarySiteDetails.siteContactEmailAddress
                        ? preliminarySiteDetails.siteContactEmailAddress
                        : ""}
                    </label>
                  </span>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <CustomLabel message="SITE SPECIFIC REQUIREMENTS" />
                  <Divider fitted />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column>
                  {trainingSelections &&
                    trainingSelections.map((training) => {
                      return (
                        <span>
                          <Divider fitted hidden />
                          <Checkbox
                            label={training.text}
                            checked={training.checked}
                            onChange={(e, { checked }) => {
                              this.handleSelectTraining(
                                training.value,
                                checked
                              );
                            }}
                          />
                        </span>
                      );
                    })}

                  <Divider fitted hidden />
                  <Form.Field
                    id="form-input-control-lcn"
                    control={Input}
                    placeholder="Enter details"
                    name="otherTrainingDetails"
                    onChange={(e) => {
                      this.props.handleChange(e, "RD-PSLS");
                    }}
                    value={preliminarySiteDetails.otherTrainingDetails || ""}
                    error={this.props.getErrorFor(
                      "otherTrainingDetails",
                      "RD-PSLS"
                    )}
                    autoComplete="off"
                    width="8"
                  />
                </Grid.Column>
                <Grid.Column>
                  <Popup
                    content="i.e. background check, NDA, etc."
                    trigger={
                      <label>
                        Provide any security steps necessary for the engineer to
                        access the site
                      </label>
                      // <Icon
                      //   style={{ marginLeft: "0px" }}
                      //   circular
                      //   size="small"
                      //   name="question"
                      // />
                    }
                  />

                  <Form.Field
                    id="form-input-control-lcn"
                    control={Input}
                    // label="Provide any security steps necessary for the engineer to access the site"
                    placeholder="Enter details"
                    name="securityStepDetail"
                    onChange={(e) => {
                      this.props.handleChange(e, "RD-PSLS");
                    }}
                    value={preliminarySiteDetails.securityStepDetail || ""}
                    error={this.props.getErrorFor(
                      "securityStepDetail",
                      "RD-PSLS"
                    )}
                    autoComplete="off"
                    style={{ marginTop: "5px" }}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <LabelBold
                    message={"Select all required PPE from the list below"}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns="2">
                <GridColumn>
                  <table>
                    <tbody>
                      {ppeSelections &&
                        ppeSelections
                          .filter((p) => p.value <= 5)
                          .map((ppe) => {
                            return (
                              <tr>
                                <td>
                                  <Checkbox
                                    label={ppe.text}
                                    checked={ppe.checked}
                                    onChange={(e, { checked }) => {
                                      this.handleSelectPpe(ppe.value, checked);
                                    }}
                                  />
                                </td>
                              </tr>
                            );
                          })}
                    </tbody>
                  </table>
                </GridColumn>
                <GridColumn>
                  <table>
                    <tbody>
                      {ppeSelections &&
                        ppeSelections
                          .filter((p) => p.value >= 6)
                          .map((ppe) => {
                            return (
                              <tr>
                                <td>
                                  <Checkbox
                                    label={ppe.text}
                                    checked={ppe.checked}
                                    onChange={(e, { checked }) => {
                                      this.handleSelectPpe(ppe.value, checked);
                                    }}
                                  />
                                </td>
                              </tr>
                            );
                          })}
                    </tbody>
                  </table>
                </GridColumn>
              </Grid.Row>
            </Grid>
          </span>
        )}

        {(roleId == Role.fieldEngineer || roleId == Role.projectHandler) && (
          <span>
            {siteDetails.showPrelimLocation == "false" &&
              preliminarySiteDetails && (
                <span>
                  <PrelimSiteLocation
                    reservationDetails={reservationDetails}
                    prelimDetails={
                      reservationDetails.preliminarySiteLocationSection
                    }
                    countryOptions={countryOptionsPrelimEngineerDetails}
                    stateOptions={stateOptionsPrelimEngineerDetails}
                    cityOptions={cityOptionsPrelimEngineerDetails}
                    getErrorFor={this.props.getErrorFor}
                    handleCheckboxChange={this.props.handleCheckboxChange}
                    handleChange={this.props.handleChange}
                    handleDropdownChange={this.props.handleDropdownChange}
                    handleGetProjectStates={this.handleGetEngineerStates}
                    handleGetProjectCities={this.handleGetEngineerCities}
                    formType="RD-PSLS"
                  />
                </span>
              )}

            <FinalSiteLocation
              reservationDetails={reservationDetails}
              finalDetails={reservationDetails.finalSiteLocationSection}
              countryOptions={countryOptionsFinalEngineerDetails}
              stateOptions={stateOptionsFinalEngineerDetails}
              cityOptions={cityOptionsFinalEngineerDetails}
              getErrorFor={this.props.getErrorFor}
              handleCheckboxChange={this.props.handleCheckboxChange}
              handleChange={this.props.handleChange}
              handleDropdownChange={this.props.handleDropdownChange}
              handleFillSiteDetails={this.handleFillFinalSiteDetails}
              handleGetProjectStates={this.handleGetEngineerStates}
              handleGetProjectCities={this.handleGetEngineerCities}
              formType="RD-FSLS"
            />

            <AHJDetails
              reservationDetails={reservationDetails}
              ahjDetails={reservationDetails.authorityHavingJurisdictionDetails}
              countryOptions={countryOptionsAhjDetails}
              stateOptions={stateOptionsAhjDetails}
              cityOptions={cityOptionsAhjDetails}
              getErrorFor={this.props.getErrorFor}
              handleCheckboxChange={this.props.handleCheckboxChange}
              handleChange={this.props.handleChange}
              handleDropdownChange={this.props.handleDropdownChange}
              handleGetProjectStates={this.handleGetEngineerStates}
              handleGetProjectCities={this.handleGetEngineerCities}
              formType="RD-AHJD"
            />

            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <CustomLabel message="SITE SPECIFIC REQUIREMENTS" />
                  <Divider fitted />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={3}>
                <Grid.Column>
                  {trainingSelections &&
                    trainingSelections.map((training) => {
                      return (
                        <React.Fragment>
                          <span>
                            <Divider fitted hidden />
                            <Checkbox
                              label={training.text}
                              checked={training.checked}
                              onChange={(e, { checked }) => {
                                this.handleSelectTraining(
                                  training.value,
                                  checked
                                );
                              }}
                            />
                          </span>
                          {/* index 0 is on-site training */}
                          {training.checked && training.value === 1 ? (
                            <div className="margin-left">
                              <Form.Field>
                                Will the training take more than 2 hours?
                              </Form.Field>
                              <Form.Field>
                                <Checkbox
                                  radio
                                  label="Yes"
                                  name="isTrainingTwoHours"
                                  value={true}
                                  checked={training.trainingTwoHours === true}
                                  onChange={(e, { value }) => {
                                    this.handleSelectTraining(
                                      training.value,
                                      training.checked,
                                      value
                                    );
                                  }}
                                />
                                &nbsp;&nbsp;&nbsp;
                                <Checkbox
                                  radio
                                  label="No"
                                  name="isTrainingTwoHours"
                                  value={false}
                                  checked={training.trainingTwoHours === false}
                                  onChange={(e, { value }) => {
                                    this.handleSelectTraining(
                                      training.value,
                                      training.checked,
                                      value
                                    );
                                  }}
                                />
                              </Form.Field>
                            </div>
                          ) : null}
                          {/* index 2 is others */}
                          {training.checked && training.value === 3 ? (
                            <div>
                              <Divider fitted hidden />
                              <Form.Field
                                id="form-input-control-lcn"
                                control={Input}
                                placeholder="Enter details"
                                name="otherTrainingDetails"
                                onChange={(e) => {
                                  this.props.handleChange(e, "RD-PSLS");
                                }}
                                value={
                                  preliminarySiteDetails.otherTrainingDetails ||
                                  ""
                                }
                                error={this.props.getErrorFor(
                                  "otherTrainingDetails",
                                  "RD-PSLS"
                                )}
                                autoComplete="off"
                                width="8"
                              />
                            </div>
                          ) : null}
                        </React.Fragment>
                      );
                    })}
                </Grid.Column>
                <Grid.Column>
                  <label>
                    Provide any security steps necessary for the engineer to
                    access the site
                  </label>
                  &nbsp;
                  <Popup
                    trigger={<Icon name="info circle" />}
                    content="i.e. background check, NDA, etc."
                    size="mini"
                  />
                  <TextArea
                    id="form-input-control-lcn"
                    placeholder="Enter details"
                    name="securityStepDetail"
                    onChange={(e) => {
                      this.props.handleChange(e, "RD-PSLS");
                    }}
                    value={preliminarySiteDetails.securityStepDetail || ""}
                    error={this.props.getErrorFor(
                      "securityStepDetail",
                      "RD-PSLS"
                    )}
                    autoComplete="off"
                    className="margin-top-5"
                  />
                </Grid.Column>
                <Grid.Column>
                  <label>
                    Provide any other site specific requirements we need to be
                    made aware of
                  </label>
                  &nbsp;
                  <Popup
                    trigger={<Icon name="info circle" />}
                    content=" i.e. enviornmental requirments, such as clean room), parking requirements, etc"
                    size="mini"
                  />
                  <TextArea
                    id="form-input-control-lcn"
                    placeholder="Enter details"
                    name="otherSiteRequirements"
                    onChange={(e) => {
                      this.props.handleChange(e, "RD-PSLS");
                    }}
                    value={preliminarySiteDetails.otherSiteRequirements || ""}
                    error={this.props.getErrorFor(
                      "otherSiteRequirements",
                      "RD-PSLS"
                    )}
                    autoComplete="off"
                    className="margin-top-5"
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <LabelBold
                    message={"Select all required PPE from the list below"}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns="2">
                <GridColumn>
                  <table>
                    <tbody>
                      {ppeSelections &&
                        ppeSelections
                          .filter((p) => p.value <= 5)
                          .map((ppe) => {
                            return (
                              <tr>
                                <td>
                                  <Checkbox
                                    label={ppe.text}
                                    checked={ppe.checked}
                                    onChange={(e, { checked }) => {
                                      this.handleSelectPpe(ppe.value, checked);
                                    }}
                                  />
                                </td>
                              </tr>
                            );
                          })}
                    </tbody>
                  </table>
                </GridColumn>
                <GridColumn>
                  <table>
                    <tbody>
                      {ppeSelections &&
                        ppeSelections
                          .filter((p) => p.value >= 6)
                          .map((ppe) => {
                            return (
                              <tr>
                                <td>
                                  <Checkbox
                                    label={ppe.text}
                                    checked={ppe.checked}
                                    onChange={(e, { checked }) => {
                                      this.handleSelectPpe(ppe.value, checked);
                                    }}
                                  />
                                </td>
                              </tr>
                            );
                          })}
                    </tbody>
                  </table>
                </GridColumn>
              </Grid.Row>
            </Grid>
          </span>
        )}

        <Modal size="small" open={openAppointments}>
          <Modal.Header className="center">Select An Engineer</Modal.Header>
          <Modal.Content scrolling>
            <Form loading={isLoadingGetAppointments}>
              <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell></Table.HeaderCell>
                    <Table.HeaderCell>Engineer</Table.HeaderCell>
                    <Table.HeaderCell>Appointment Start</Table.HeaderCell>
                    <Table.HeaderCell>Location</Table.HeaderCell>
                    <Table.HeaderCell>Travel Time</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {appointments &&
                    appointments.map((item, idx) => {
                      return (
                        <Table.Row key={idx}>
                          <Table.Cell>
                            <Button
                              primary
                              onClick={this.handleSelectEngineer.bind(
                                this,
                                item
                              )}
                            >
                              Select
                            </Button>{" "}
                          </Table.Cell>
                          <Table.Cell>{item.engineerName}</Table.Cell>
                          <Table.Cell>{item.appointmentStart}</Table.Cell>
                          <Table.Cell>{item.location}</Table.Cell>
                          <Table.Cell>{item.travelHours}</Table.Cell>
                        </Table.Row>
                      );
                    })}
                  {appointments && appointments.length === 0 && (
                    <Table.Row>
                      <Table.Cell colSpan={5} textAlign="center">
                        <CustomLabel message="NO DATA FOUND." />
                      </Table.Cell>
                    </Table.Row>
                  )}
                </Table.Body>
              </Table>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button
              negative
              onClick={this.handleOpenAppointments.bind(this, false)}
            >
              Cancel
            </Button>
          </Modal.Actions>
        </Modal>
      </Form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    fieldEvaluation: state.fieldEvaluation
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getEngineerStates: (countryCode, locationType) =>
      dispatch(actions.getEngineerStates(countryCode, locationType)),
    getEngineerCities: (stateCode, locationType) =>
      dispatch(actions.getEngineerCities(stateCode, locationType)),
    getAhjStates: (countryCode) => dispatch(actions.getAhjStates(countryCode)),
    getAhjCities: (stateCode) => dispatch(actions.getAhjCities(stateCode)),
    updatePpeSelections: (selectedPpe, isChecked) =>
      dispatch(actions.updatePpeSelections(selectedPpe, isChecked)),
    updateTrainingSelections: (
      selectedTraining,
      isChecked,
      isTrainingTwoHours
    ) =>
      dispatch(
        actions.updateTrainingSelections(
          selectedTraining,
          isChecked,
          isTrainingTwoHours
        )
      ),
    getAppointments: (data) => dispatch(actions.getAppointments(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReservationDetails);
