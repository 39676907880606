import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { verifyEmailCard } from "../../../assets/js/common";
import imageUrl from "../../../assets/img/ul-logo.png";
import { Link } from "react-router-dom";
import { Container, Header, Image, Card, Button, Message } from "semantic-ui-react";
import { CustomTitleLabel } from "../../../components/labels/labels";

import * as actions from "./verify-email.action";
import _ from "lodash";

class VerifyEmail extends Component {

    componentDidMount() {
        let token = this.props.match.params.token;
        this.props.doValidateToken(escape(token));
        this.props.getTokenDetails(escape(token));
    }

    componentWillUnmount() {
        this.props.clear();
       }


    handleResendToken = () => {

        const { tokenDetailsData } = this.props.verifyEmail;

        if (tokenDetailsData && tokenDetailsData.data) {
            const { form } = this.props.user;
            form.userId = tokenDetailsData.data.id;
            form.loading = true;
            form.message = "Resending.. Please wait.."
            return this.props.doResendEmailVerToken(
              _.omit(form)
            );
        }
      }


    render() {

        const { form, validateTokenData, resendTokenData } = this.props.verifyEmail;

        let isSuccessful = false;

        if (validateTokenData && validateTokenData.isSuccessful){
            form.message = "Congratulation! Your email is successfully verified!";
            isSuccessful = true;
        }
        else {
            form.message = validateTokenData.message;
            isSuccessful = false;
        }


        if (!_.isEmpty(resendTokenData)) {
            if (resendTokenData.isSuccessful) {
                form.message = "We sent you an verification link. Check your email and click on the link to verify.";
                isSuccessful = true;
            }
            else {
                form.message = resendTokenData.message;
                isSuccessful = false;
            }     
        }

        return (
            <Fragment>
                <Container style={verifyEmailCard}>
                    <Card fluid>
                        <Card.Content>
                        <Card.Header>
                        <Header as="h3">
                            <Image src={imageUrl} size="small" as={Link} to="/" />
                            Email Verification
                        </Header>
                        </Card.Header>
                        <br/>   
                        <Message
                            success
                            hidden={!isSuccessful}
                            header=""
                            content={form.message}
                        />    
                         <Message
                            error
                            hidden={isSuccessful}
                            header=""
                            content={form.message}
                        />                       
           
                        </Card.Content>
                        <Card.Content extra textAlign="right">
                        { validateTokenData && validateTokenData.isSuccessful ?
                            <Button as={Link} to="/" basic color="blue">
                                Click here to login your account.
                            </Button>
                        :
                            resendTokenData && !resendTokenData.isSuccessful ?
                            <Button loading={form.loading} onClick={this.handleResendToken.bind(this)} color="blue">
                            Resend Verify Email
                            </Button> : ''
                        }

                        </Card.Content>
                    </Card>
                </Container>
            </Fragment>
        );
    }

    
}

const mapStateToProps = state => {
    return {
        verifyEmail: state.user.verifyEmail
    };
  };
  
  const mapDispatchToProps = dispatch => ({
    doResendEmailVerToken: param => dispatch(actions.doResendEmailVerToken(param)),
    doValidateToken: token => dispatch(actions.doValidateToken(token)),
    getTokenDetails: token => dispatch(actions.getTokenDetails(token)),
    form: result => dispatch(actions.updateForm(result)),
    clear: () => dispatch(actions.clearUser())
  });

export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmail);