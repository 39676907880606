import React, { Component, Fragment } from "react";
import {
  Container,
  Button,
  Grid,
  Divider,
  Pagination,
  Message,
  Input,
  Dropdown,
  Dimmer,
  Loader,
  Form,
  Table,
  Icon,
  Popup,
  Menu,
  Sidebar,
  Accordion,
  List,
  Modal,
  Transition,
  Radio,
  Checkbox,
  Segment
} from "semantic-ui-react";
import {
  CustomTitle,
  CustomHeader,
  CustomHeaderInlineBold,
  CustomHeaderLabel,
  CustomLabel,
  CustomTitleLabel
} from "../../../components/labels/labels";
import FooterHomeBlock from "../../../components/footer/footer-home-block";
import OrderItem from "./order-item";
import * as actions from "./view-history.action";
import * as vodActions from "../view-order-details/view-order-details.action";
import * as fActions from "../field-evaluation.action";
import * as feActions from "../product-details/fe/product-details.action";
import * as multiActions from "../field-service/new-quote-details/new-quote-details.action";
import * as schedActions from "../field-service/scheduling-details/scheduling-details.action";
import * as projectActions from "../field-service/project-details/project-details.action";
import {
  doCancelOrder,
  clearCancelOrder,
  resetCancelOrder
} from "../cancel-order/cancel-order.action";
import { connect } from "react-redux";
import { validateField } from "../../../validator/validator";
import { renderDisc } from "../../../helpers/component-helper";
import { GetStatusCode } from "../field-evaluation-helper";
import { Link } from "react-router-dom";

import ToastHelper from "../../../helpers/toast.helper";

import _ from "lodash";
import TermsAndConditions from "../../manufacturer/standard/label-cart/terms-and-conditions";
import Role from "../../../enums/role";

// FES
import FesPrice from "../product-details/fes/price";
import FesAssumption from "../product-details/fes/assumption";
import FesTermsAndConditions from "../product-details/fes/t-and-c";
import FeServiceTerms from "../product-details/fes/service-terms";
import Acceptance from "../product-details/fe/acceptance";

import FeSummary from "../product-details/fe/summary";
import FeSummaryMulti from "../../field-evaluation/field-service/common/review-summary/fe-summary";

import QuoteHeader from "../product-details/quote-header";
import { renderQuotationBody } from "../../../helpers/component-helper";
import { Common } from "../../../common/common";
import { getAccountSettings } from "../account-settings/account-settings.action";
import CancellationCode from "../cancel-order/cancellation-code";
import FilterSidebar from "../components/filter-sidebar";
import ColumnOptions from "../components/column-options";

const rules = [];

const labelOptions = [
  {
    key: "OrderNumber",
    value: "OrderNumber",
    text: "Order #"
  },
  { key: "ApplicantName", value: "ApplicantName", text: "Applicant Name" },
  {
    key: "AccountNumber",
    value: "AccountNumber",
    text: "Account #"
  },
  { key: "ContactName", value: "ContactName", text: "Contact Name" }
];

const statusOptions = [
  {
    key: "Draft",
    value: "1",
    text: "Draft",
    checked: false
  },
  {
    key: "Pending UL Assessment",
    value: "7",
    text: "Pending UL Assessment",
    checked: false
  },
  {
    key: "Offered",
    value: "9",
    text: "Offered",
    checked: false
  },
  {
    key: "Accepted",
    value: "6",
    text: "Accepted",
    checked: false
  },
  {
    key: "Cancelled",
    value: "4",
    text: "Cancelled",
    checked: false
  }
];

const dateRangeOptions = [
  {
    key: "30",
    value: "10",
    text: "30 Days",
    checked: false
  },
  {
    key: "60",
    value: "20",
    text: "60 Days",
    checked: false
  },
  {
    key: "90",
    value: "30",
    text: "90 Days",
    checked: false
  }
  // {
  //   key: "0",
  //   value: "0",
  //   text: "Custom",
  //   checked: false,
  // },
];

const columnOptions = [
  {
    key: "projectHandler",
    value: 1,
    text: "Project Handler",
    visible: 1,
    sort: 0,
    checked: 1
  },
  {
    key: "statusName",
    value: 2,
    text: "Status",
    visible: 1,
    sort: 0,
    checked: 1
  },
  {
    key: "quoteNumber",
    value: 3,
    text: "Quote",
    visible: 1,
    sort: 0,
    checked: 1
  },
  {
    key: "oracleNumber",
    value: 4,
    text: "Order",
    visible: 1,
    sort: 0,
    checked: 1
  },
  {
    key: "customerReferenceNumber",
    value: 5,
    text: "Cust Ref",
    visible: 1,
    sort: 0,
    checked: 1
  },
  {
    key: "customerPurchaseNumber",
    value: 6,
    text: "Cust PO",
    visible: 1,
    sort: 0,
    checked: 1
  },
  {
    key: "applicantLegalName",
    value: 7,
    text: "Appl. Name",
    visible: 1,
    sort: 0,
    checked: 1
  },
  {
    key: "inspectionSiteLocation",
    value: 8,
    text: "Inspection Site Location",
    visible: 1,
    sort: 0,
    checked: 1
  },
  {
    key: "scope",
    value: 9,
    text: "Scope",
    visible: 1,
    sort: 0,
    checked: 1
  },
  // {
  //   key: "serviceType",
  //   value: "serviceType",
  //   text: "Service Type",
  //   visible: 1,
  //   sort: 0,
  //   checked: false,
  // },
  {
    key: "orderSubmittedDate",
    value: 10,
    text: "Creation date",
    visible: 1,
    sort: 0,
    checked: 1
  },
  {
    key: "expirationDate",
    value: 11,
    text: "Exp. Date",
    visible: 1,
    sort: 0,
    checked: 1
  },
  {
    key: "quoteAcceptDate",
    value: 12,
    text: "Accept Date",
    visible: 1,
    sort: 0,
    checked: 1
  },
  {
    key: "orderLastUpdatedDate",
    value: 13,
    text: "Last Date",
    visible: 1,
    sort: 0,
    checked: 1
  }
];

class ViewHistory extends Component {
  state = {
    column: null,
    columnOptions: columnOptions,
    statusOptions: statusOptions,
    dateRangeOptions: dateRangeOptions,
    columnSortable: false,
    direction: null,
    isFilterVisible: false,
    isColumnOptVisible: false,
    activeIndex: 0,
    openSummaryModal: false,
    isOpenCancelModal: false,
    isCheckAgree: false,
    feOrderId: 0,
    postFilter: null,
    isOpenProjectHandlerModal: false,
    isOpenUnAssignProjectHandlerModal: false,
    projectHandlerName: null,
    isShowCancellationCodeModal: false,
    filterDate: null,
    filterDateType: null,
    selectedProjectHandlers: [],
    selectedApplicants: [],
    selectedCities: [],
    selectedStates: [],
    isExpandMultiSite: false,
    isUnassignQuoteOnly: false,
    openSummaryModalMulti: false
  };

  htmlBody = renderQuotationBody();

  handleRenderHtmlBody = (html) => {
    html = html.replace('class="container-wrapper" ', "");

    html = html.replace(
      '<div id="quotation"></div>',
      document.getElementById("quotation").outerHTML
    );

    html = html.replace(
      '<div id="quotation">',
      '<div style="padding: 20px;" id="quotation">'
    );

    html = html.replace(
      document.getElementById("checkbox-section").outerHTML,
      '<div id="checkbox-section"></div>'
    );
    html = html.replace(
      document.getElementById("acceptance-section").outerHTML,
      '<div id="acceptance-section"></div>'
    );

    return html;
  };

  componentDidMount() {
    var { columnOptions } = this.state;

    let roleId = Common.getUserRole();
    this.props.getAccountSettings();
    this.props.getProjectHandlers();
    this.props.getApplicants();
    // this.props.getCities();
    // this.props.getStates();
    this.props.getProjectCountries(1);
    this.props.getFilterOptions();

    if (roleId == Role.fieldEngineer || roleId == Role.projectHandler) {
      var column = columnOptions.filter(
        (i) => i.key == "inspectionSiteLocation"
      );
      if (column.length > 0) {
        column[0].key = "installationSite";
        column[0].value = 8;
        column[0].text = "Install. Site";

        this.setState(...column);
      }
    }

    if (roleId == Role.fieldEngineer) {
      var column = columnOptions.filter((i) => i.key == "applicantLegalName");

      if (column.length > 0) column[0].visible = 0;

      this.setState(...column);
    }

    //remove service type for FE and FES
    if (roleId == Role.fieldEngineer || roleId == Role.fireEquipment) {
      var _columnOptions = columnOptions.filter((i) => i.key != "serviceType");
      this.setState({ columnOptions: _columnOptions });
    }

    if (roleId == Role.projectHandler)
      this.props.getAllFEOrders(this.props.currentPage, this.props.pageSize);
    else this.props.getFEOrders(this.props.currentPage, this.props.pageSize);
  }

  handleChange = (dpName, dpValue) => {
    let { form } = this.props.viewHistory;
    validateField(rules, form, dpName, dpValue);
    const newState = { ...this.props.viewHistory.form };
    this.props.form(newState);
  };

  handleDropdownChange = (dpName, dpValue) => {
    const { form } = this.props.viewHistory;
    validateField(rules, form, dpName, dpValue);
    const newState = { ...this.props.viewHistory.form };
    this.props.form(newState);
  };

  handleSearch = () => {
    let { form, currentPage, pageSize } = this.props.viewHistory;
    let status = form.status;
    let roleId = Common.getUserRole();
    if (status) {
      this.props.getFEOrdersByFilter(status, currentPage, pageSize);
    } else {
      if (roleId == Role.projectHandler)
        this.props.getAllFEOrders(this.props.currentPage, this.props.pageSize);
      else this.props.getFEOrders(this.props.currentPage, this.props.pageSize);
    }
  };

  handleApplyFilter = () => {
    let { form, currentPage, pageSize } = this.props.viewHistory;
    let {
      statusOptions,
      postFilter,
      dateRangeOptions,
      filterDateType,
      selectedProjectHandlers,
      selectedApplicants,
      selectedStates,
      isExpandMultiSite,
      isUnassignQuoteOnly
    } = this.state;

    let selectedStatus = statusOptions.filter((i) => i.checked == true);
    var status = selectedStatus.map(function (status) {
      return status.value;
    });

    var statuses = status.join("|");
    var projectHandlers = selectedProjectHandlers.join("|");
    var applicants = selectedApplicants.join("|");
    var states = selectedStates.join("|");

    var _dataRange = null;
    var selectedDateRange = dateRangeOptions.filter((i) => i.checked == true);

    if (selectedDateRange.length > 0) _dataRange = selectedDateRange[0].value;

    var _filterDate = null;
    var _filterDateRange = null;
    var isCreationDate = filterDateType === "1" ? true : false;
    let now = new Date();
    switch (_dataRange) {
      case "10":
        _filterDate = new Date(now.setDate(now.getDate() - 10))
          .toISOString()
          .slice(0, 10);
        _filterDateRange = "10";
        break;
      case "20":
        _filterDate = new Date(now.setDate(now.getDate() - 20))
          .toISOString()
          .slice(0, 10);
        _filterDateRange = "20";
        break;
      case "30":
        _filterDate = new Date(now.setDate(now.getDate() - 30))
          .toISOString()
          .slice(0, 10);
        _filterDateRange = "30";
        break;
    }

    var data = {
      statuses: statuses,
      createdDates: _filterDate,
      expirationDates: "",
      programType: "",
      isCreationDate: isCreationDate,
      filterDateRange: _filterDateRange,
      projectHandlers: projectHandlers,
      applicants: applicants,
      cities: form.city,
      states: form.state,
      countries: form.country,
      isExpandMultiSite: isExpandMultiSite,
      isUnassignQuoteOnly: isUnassignQuoteOnly
    };

    this.props.setFilterOptions(data);

    // var _postFilter = { status, currentPage, pageSize };
    // this.setState({ postFilter: _postFilter });
    // if (_postFilter.status.length > 0) {
    //   var roleId = Common.getUserRole();
    //   if (roleId == Role.projectHandler)
    //     this.props.getFEOrdersByFilter(_postFilter, currentPage, pageSize);
    //   else
    //     this.props.getFEOrdersByFilterByUser(
    //       _postFilter,
    //       currentPage,
    //       pageSize
    //     );
    // } else {
    //   if (roleId == Role.projectHandler)
    //     this.props.getAllFEOrders(this.props.currentPage, this.props.pageSize);
    //   else this.props.getFEOrders(this.props.currentPage, this.props.pageSize);
    // }
  };

  handleStatusFilterChange = (properties) => {
    var { statusOptions } = this.state;
    var selectedStatus = statusOptions.filter(
      (i) => i.text == properties.label
    );
    selectedStatus[0].checked = selectedStatus[0].checked == 0 ? 1 : 0;

    const newState = { ...selectedStatus };

    this.setState(newState);
  };

  handleDateFilterChange = (properties) => {
    var { filterDate, filterDateType, dateRangeOptions } = this.state;

    switch (properties.name) {
      case "radioGroupDate":
        //reset all radio options
        dateRangeOptions.map((i) => (i.checked = false));
        const _newState = { ...dateRangeOptions };
        this.setState(_newState);

        var selectedDateRange = dateRangeOptions.filter(
          (i) => i.text == properties.label
        );
        selectedDateRange[0].checked =
          selectedDateRange[0].checked == false ? true : false;
        const newState = { ...selectedDateRange };
        this.setState(newState);
        break;
      case "radioGroupDateFilterType":
        this.setState({ filterDateType: properties.value });
        break;
    }
  };

  handleClearFilter = () => {
    var {
      statusOptions,
      postFilter,
      dateRangeOptions,
      selectedProjectHandlers,
      selectedApplicants,
      selectedStates,
      selectedCities,
      isExpandMultiSite,
      isUnassignQuoteOnly
    } = this.state;

    var statusOptions = statusOptions.map(function (status) {
      status.checked = 0;
      return status;
    });

    var dateRangeOptions = dateRangeOptions.map(function (dateRange) {
      dateRange.checked = 0;
      return dateRange;
    });

    postFilter = null;

    const newState = {
      ...statusOptions,
      dateRangeOptions,
      selectedProjectHandlers: [],
      selectedApplicants: [],
      selectedStates: [],
      selectedCities: [],
      postFilter,
      isExpandMultiSite: false,
      isUnassignQuoteOnly: false
    };

    this.setState(newState);
    this.props.clearForm();
  };

  handleSort = (clickedColumn) => () => {
    const { column, direction } = this.state;
    const { data } = this.props.viewHistory;

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        direction: "ascending"
      });

      const newData2 = _.sortBy(data, [clickedColumn]);
      this.props.updateData(newData2);
      return;
    }

    const newData = data.reverse();
    this.props.updateData(newData);
    this.setState({
      direction: direction === "ascending" ? "descending" : "ascending"
    });
  };

  handleToggleFilter = () => {
    const { isFilterVisible } = this.state;

    var _isFilterVisible = !isFilterVisible;
    this.setState({
      isFilterVisible: _isFilterVisible,
      isColumnOptVisible: false
    });
  };

  handleToggleColumn = () => {
    const { isColumnOptVisible } = this.state;

    var _isColumnOptVisible = !isColumnOptVisible;

    this.setState({
      isColumnOptVisible: _isColumnOptVisible,
      isFilterVisible: false
    });
  };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  handleConfirmCompleteOrder = (
    openSummaryModal,
    feOrderId,
    orderNumber,
    siteCount
  ) => {
    let {
      getOrderDetailsMulti,
      renderProductDetails,
      renderSiteDetails,
      renderSchedulingDetails
    } = this.props;

    if (siteCount > 1) {
      if (orderNumber)
        getOrderDetailsMulti(orderNumber).then((result) => {
          if (result) {
            let count = result.data.orderDetails.siteCount;

            renderProductDetails(count, result.data);
            renderSiteDetails(count, result.data);
            renderSchedulingDetails(count, result.data);

            // handle offered to accepted quote
            let { otherDetails } = this.props.fieldService.form;
            otherDetails.isFromOffered = true;
            const newState = { ...this.props.fieldService.form };
            this.props.form(newState);

            this.setState({ openSummaryModalMulti: openSummaryModal });
          }
        });
    } else {
      if (orderNumber)
        this.props.getOrderDetails(orderNumber).then((s) => {
          if (s) this.setState({ openSummaryModal: openSummaryModal });

          // handle offered to accepted quote
          let { otherDetails } = this.props.fieldEvaluation.form;
          otherDetails.isFromOffered = true;
          const newState = { ...this.props.fieldEvaluation.form };
          this.props.form(newState);
        });
    }

    this.setState({ feOrderId: feOrderId });
  };

  handleConfirmCancelOrder = (isOpenCancelModal) => {
    this.setState({
      isOpenCancelModal: isOpenCancelModal
    });
  };

  handleCancellationCodeModal = (isShow, feOrderId) => {
    this.setState({
      isShowCancellationCodeModal: isShow,
      feOrderId: feOrderId
    });
  };

  onClickAgreeChecbox = () =>
    this.setState((prevState) => ({ isCheckAgree: !prevState.isCheckAgree }));

  handleCompleteOrder = () => {
    var { feOrderId } = this.state;

    var data = {
      fieldEvaluationOrderId: feOrderId,
      orderType: 2,
      quotation: this.handleRenderHtmlBody(this.htmlBody)
    };

    this.setState({ isOpenTac: false });

    return this.props.updateOrderType(data).then((s) => {
      if (s) {
        if (s.number != "") {
          this.setState({
            // isSuccess: true,
            refereceNmber: s.number,
            isShowButton: false,
            isLoading: ""
          });

          setTimeout(() => {
            ToastHelper.success({
              description: (
                <p>
                  {`Thank you for your business. Your request number is ${s.number}.
                  A Project Handler will be in touch with you as soon as
                  possible.`}
                </p>
              )
            });
          }, 100);
        } else {
          setTimeout(() => {
            ToastHelper.error({
              title: "Something went wrong",
              description: s.message
            });
          }, 100);
        }
      } else {
        this.setState({ isLoading: "" });

        setTimeout(() => {
          ToastHelper.error({
            title: "Error",
            description: "Something went wrong"
          });
        }, 100);
      }
    });
  };

  handleCancelOrder = () => {
    var { feOrderId } = this.state;
    const { selectedCancellationCode } = this.props.cancelOrder;

    var data = {
      orderId: feOrderId,
      cancellationCodeId: selectedCancellationCode.id,
      cancellationOtherDesc: selectedCancellationCode.other
        ? selectedCancellationCode.other
        : null
    };

    this.props.doCancelOrder(data).then((s) => {
      if (s && s.isSuccessful) {
        let message = (
          <span>
            Quote # <b>{s.data.requestNumber}</b> has been cancelled
            <b>
              {" "}
              {s.data.cancellationCode.name}{" "}
              {s.data.cancellationOtherDesc
                ? " - " + s.data.cancellationOtherDesc
                : null}
            </b>
            . To reactivate: go to “View History” to copy and proceed with a new
            quote, or contact us at
            <a href="mailto:field@ul.com"> field@ul.com</a> or
            <a href="tel:877.854.3577"> 1-877-854-3577</a>, Ext 2
          </span>
        );

        setTimeout(() => {
          ToastHelper.success({
            description: <p>{message}</p>,
            time: 20000
          });
        }, 100);
      }
    });
    this.setState({
      isOpenCancelModal: false,
      isShowCancellationCodeModal: false
    });
  };

  handleConfirmAssignProjectHandler = () => {
    var { form } = this.props.viewHistory;
    var { feOrderId } = this.state;

    var data = {
      fieldEvaluationOrderId: feOrderId,
      projectHandlerId: form.projectHandler
    };

    this.props.assignProjectHandlers(data);

    this.setState({ isOpenProjectHandlerModal: false });
  };

  handleConfirmUnAssignProjectHandler = () => {
    var { feOrderId } = this.state;

    var data = {
      fieldEvaluationOrderId: feOrderId
    };

    this.props.unAssignProjectHandlers(data);

    this.setState({ isOpenUnAssignProjectHandlerModal: false });
  };

  handleAssignProjectHandler = (isOpenProjectHandlerModal, feOrderId) => {
    this.setState({
      isOpenProjectHandlerModal: isOpenProjectHandlerModal,
      feOrderId: feOrderId
    });
  };

  handleUnAssignProjectHandler = (
    isOpenUnAssignProjectHandlerModal,
    feOrderId,
    projectHandlerName
  ) => {
    this.setState({
      isOpenUnAssignProjectHandlerModal: isOpenUnAssignProjectHandlerModal,
      feOrderId: feOrderId,
      projectHandlerName: projectHandlerName
    });
  };

  handleRemoveColumn = (columnKey) => {
    var { columnOptions } = this.state;
    var selectedColumn = columnOptions.filter((i) => i.key == columnKey);
    selectedColumn[0].checked = 0;

    const newState = { ...this.selectedColumn };
    this.setState(newState);
  };

  handleAddColumn = () => {
    var { columnOptions } = this.state;
    var selectedColumn = columnOptions.filter((i) => i.selected == true);
    if (selectedColumn.length > 0) selectedColumn[0].checked = 1;

    const newState = { ...this.selectedColumn };

    this.setState(newState);
  };

  handleApplyColumnOptions = () => {
    var { columnOptions } = this.state;

    var _columnOptions = columnOptions.map((i) => {
      i.visible = i.checked == true ? 1 : 0;
    });

    const newState = { ..._columnOptions };

    this.setState(newState);

    var _columnOptions = columnOptions.filter((i) => i.visible == 1);

    var _activeColumns = _columnOptions.map((i) => i.value);

    var selectedColumns = _activeColumns.join("|");

    var data = {
      isColumnOptions: true,
      columnOptions: selectedColumns
    };

    this.props.setFilterOptions(data);
  };

  handleAddPHFilter = (projectHandler) => {
    var { selectedProjectHandlers } = this.state;

    var newSelectedPH = selectedProjectHandlers;
    if (projectHandler) {
      if (!newSelectedPH.includes(projectHandler))
        newSelectedPH.push(projectHandler);
    }

    this.setState({ selectedProjectHandlers: newSelectedPH });
  };

  handleRemovePHFilter = (projectHandler) => {
    var { selectedProjectHandlers } = this.state;
    var newSelectedPH = selectedProjectHandlers;
    if (projectHandler) {
      const index = newSelectedPH.indexOf(projectHandler);
      if (index > -1) {
        newSelectedPH.splice(index, 1);
      }
    }

    this.setState({ selectedProjectHandlers: newSelectedPH });
  };

  handleAddApplicantFilter = (applicant) => {
    var { selectedApplicants } = this.state;

    var newSelectedApp = selectedApplicants;
    if (applicant) {
      if (!newSelectedApp.includes(applicant)) newSelectedApp.push(applicant);
    }

    this.setState({ selectedApplicants: newSelectedApp });
  };

  handleRemoveApplicantFilter = (applicant) => {
    var { selectedApplicants } = this.state;
    var newSelectedApp = selectedApplicants;
    if (applicant) {
      const index = newSelectedApp.indexOf(applicant);
      if (index > -1) {
        newSelectedApp.splice(index, 1);
      }
    }

    this.setState({ selectedApplicants: newSelectedApp });
  };

  handleAddStateFilter = (state) => {
    var { selectedStates } = this.state;

    var newSelectedStates = selectedStates;
    if (state) {
      if (!newSelectedStates.includes(state)) newSelectedStates.push(state);
    }

    this.setState({ selectedStates: newSelectedStates });
  };

  handleRemoveStatesFilter = (state) => {
    var { selectedStates } = this.state;
    var newSelectedStates = selectedStates;
    if (state) {
      const index = newSelectedStates.indexOf(state);
      if (index > -1) {
        newSelectedStates.splice(index, 1);
      }
    }

    this.setState({ selectedStates: newSelectedStates });
  };

  handleAddCitiesFilter = (city) => {
    var { selectedCities } = this.state;

    var newSelectedCities = selectedCities;
    if (city) {
      if (!newSelectedCities.includes(city)) newSelectedCities.push(city);
    }

    this.setState({ selectedCities: newSelectedCities });
  };

  handleRemoveCitiesFilter = (city) => {
    var { selectedCities } = this.state;
    var newSelectedCities = selectedCities;
    if (city) {
      const index = newSelectedCities.indexOf(city);
      if (index > -1) {
        newSelectedCities.splice(index, 1);
      }
    }

    this.setState({ selectedCities: newSelectedCities });
  };

  handleDdColumnChange = (event, { value }) => {
    var { columnOptions } = this.state;

    // clear all selected column
    var resetColumn = columnOptions.map((column, idx) => {
      column.selected = false;
    });
    const newResetState = { ...this.resetColumn };
    this.setState(newResetState);

    // add selected column
    var selectedColumn = columnOptions.filter((i) => i.value == value);
    selectedColumn[0].selected = true;
    const newAddedState = { ...this.selectedColumn };
    this.setState(newAddedState);
  };

  handleColumnSortChange = (e) => {
    var { columnSortable } = this.state;

    columnSortable = !columnSortable;

    this.setState({ columnSortable: columnSortable });
  };

  onCloseSummaryModal = () => {
    this.setState({ openSummaryModal: false });
  };

  onCloseSummaryModalMulti = () => {
    this.setState({ openSummaryModalMulti: false });
  };

  handleGetProjectStates = (countryCode, locationType) => {
    this.props.getProjectStates(countryCode, locationType);
  };

  handleGetProjectCities = (stateCode, locationType) => {
    this.props.getProjectCities(stateCode, locationType);
  };

  handlePageChange = (postFilter) => {
    let roleId = Common.getUserRole();

    if (
      (postFilter && postFilter.status.length > 0) ||
      postFilter.projectHandlers.length > 0 ||
      postFilter.applicants.length > 0 ||
      postFilter.filterDate ||
      postFilter.country ||
      postFilter.state ||
      postFilter.city ||
      postFilter.isUnassignQuoteOnly === true
    ) {
      if (roleId == Role.projectHandler) {
        this.props.getFEOrdersByFilter(
          postFilter,
          postFilter.currentPage,
          this.props.pageSize
        );
      } else {
        this.props.getFEOrdersByFilterByUser(
          postFilter,
          postFilter.currentPage,
          this.props.pageSize
        );
      }
    } else {
      if (roleId == Role.projectHandler)
        this.props.changePageAllFEOrders(
          postFilter.currentPage,
          this.props.pageSize
        );
      else
        this.props.changePageFEOrders(
          postFilter.currentPage,
          this.props.pageSize
        );
    }
  };

  handleExpandItem = (feOrderId) => {
    const { data } = this.props.viewHistory;

    var newData =
      data &&
      data.map((item) => {
        if (item.id == feOrderId)
          item.isExpandMultiSite = !item.isExpandMultiSite;

        return item;
      });

    this.props.updateData(newData);
  };

  handleExpandMultiSite = (e) => {
    const { isExpandMultiSite } = this.state;

    this.setState({ isExpandMultiSite: e.checked });
  };

  handleShowUnassignQuoteOnly = (e) => {
    const { isUnassignQuoteOnly } = this.state;

    this.setState({ isUnassignQuoteOnly: e.checked });
  };

  render() {
    const {
      data,
      form,
      projectHandlers,
      applicants,
      cities,
      states,
      filterOptions,
      currentPage,
      pageSize
    } = this.props.viewHistory;

    const {
      column,
      columnOptions,
      direction,
      isFilterVisible,
      isColumnOptVisible,
      activeIndex,
      openSummaryModal,
      isOpenCancelModal,
      isCheckAgree,
      columnSortable,
      statusOptions,
      postFilter,
      isOpenProjectHandlerModal,
      isOpenUnAssignProjectHandlerModal,
      projectHandlerName,
      isShowCancellationCodeModal,
      feOrderId,
      filterDate,
      filterDateType,
      dateRangeOptions,
      selectedProjectHandlers,
      selectedApplicants,
      selectedStates,
      selectedCities,
      isExpandMultiSite,
      isUnassignQuoteOnly,
      openSummaryModalMulti
    } = this.state;

    const {
      orderDetails,
      productItems,
      otherDetails,
      siteDetails,
      reservationDetails,
      productDetails
    } = this.props.fieldEvaluation.form;

    let accountSettings = this.props.accountSettings;
    let primaryDetails = accountSettings
      ? accountSettings.applicantDetails.applicantDetails
      : {};
    let billToDetails = accountSettings
      ? accountSettings.applicantDetails.applicantBillToLocationDetails
      : {};

    const fe = this.props.feProductItem.form;

    const {
      isSuccessUpdateOrderType,
      cancelOrder,
      isSuccessAssignProjectHandler,
      isSuccessSetFilterOption,
      isSuccess
    } = this.props;
    const isSuccessCancelOrder = cancelOrder
      ? cancelOrder.cancelProcessResult.isSuccessful
      : false;

    let roleId = Common.getUserRole();

    if (isSuccessUpdateOrderType) {
      if (roleId == Role.projectHandler) {
        setTimeout(() => {
          this.props.getAllFEOrders(
            this.props.currentPage,
            this.props.pageSize
          );
          this.props.updateOrderTypeReset();
        }, 3000);
      } else {
        setTimeout(() => {
          this.props.getFEOrders(this.props.currentPage, this.props.pageSize);
          this.props.updateOrderTypeReset();
        }, 3000);
      }
    }

    if (isSuccessAssignProjectHandler || isOpenUnAssignProjectHandlerModal) {
      if (roleId == Role.projectHandler) {
        setTimeout(() => {
          this.props.getAllFEOrders(
            this.props.currentPage,
            this.props.pageSize
          );
          this.props.assignProjectHandlerReset();
        }, 3000);
      }
    }

    if (isSuccessCancelOrder) {
      if (roleId == Role.projectHandler) {
        setTimeout(() => {
          this.props.getAllFEOrders(
            this.props.currentPage,
            this.props.pageSize
          );
          this.props.resetCancelOrder();
        }, 3000);
      } else {
        setTimeout(() => {
          this.props.getFEOrders(this.props.currentPage, this.props.pageSize);
          this.props.resetCancelOrder();
        }, 3000);
      }
    }

    if (
      productDetails.initialState.orders.length > 0 &&
      this.props.fieldEvaluation.isSuccessGetOrderDetails
    ) {
      this.props.getProductDetails(productDetails.initialState.orders[0]);
      this.props.updateIsSuccess();
    }

    var _statuses = null;
    var _filterDate = null;
    var _isCreationDate = null;
    var _filterDateRange = null;
    var _projectHandlers = [];
    var _applicants = [];
    var _country = null;
    var _state = null;
    var _city = null;
    var _columnOptions = null;
    var _isExpandMultiSite = null;
    var _isUnassignQuoteOnly = null;
    if (!_.isEmpty(filterOptions) && isSuccessSetFilterOption) {
      _statuses = filterOptions.statuses && filterOptions.statuses.split("|");
      _filterDate = filterOptions.createdDates;
      _isCreationDate = filterOptions.isCreationDate == true ? "1" : "2";
      _filterDateRange = filterOptions.filterDateRange;
      _projectHandlers =
        filterOptions.projectHandlers &&
        filterOptions.projectHandlers.split("|");
      _applicants =
        filterOptions.applicants && filterOptions.applicants.split("|");
      // _states = filterOptions.states && filterOptions.states.split("|");
      _country = filterOptions.countries;
      _state = filterOptions.states;
      _city = filterOptions.cities;
      _columnOptions =
        filterOptions.columnOptions && filterOptions.columnOptions.split("|");
      _isExpandMultiSite = filterOptions.isExpandMultiSite;
      _isUnassignQuoteOnly = filterOptions.isUnassignQuoteOnly;

      if (_columnOptions) {
        columnOptions &&
          columnOptions.map((column) => {
            column.checked = 0;
            if (
              _columnOptions &&
              _columnOptions.includes(column.value.toString())
            )
              column.checked = 1;
            column.visible = column.checked == true ? 1 : 0;
          });
      }

      var selectedDateRange = dateRangeOptions.filter(
        (i) => i.value == filterOptions.filterDateRange
      );

      if (selectedDateRange.length > 0) selectedDateRange[0].checked = true;

      this.setState({ filterDateType: _isCreationDate });
      this.setState({ isExpandMultiSite: _isExpandMultiSite });
      this.setState({ isUnassignQuoteOnly: _isUnassignQuoteOnly });

      statusOptions &&
        statusOptions.map((status) => {
          if (_statuses && _statuses.includes(status.value.toString()))
            status.checked = true;
        });

      let selectedStatus = statusOptions.filter((i) => i.checked == true);
      var status = selectedStatus.map(function (status) {
        return status.value;
      });

      //clear project handlers filter
      this.setState({
        selectedProjectHandlers: [],
        selectedApplicants: []
      });

      _projectHandlers &&
        _projectHandlers.map((ph) => {
          if (!selectedProjectHandlers.includes(parseInt(ph, 10)))
            selectedProjectHandlers.push(parseInt(ph, 10));
        });

      this.setState({
        selectedProjectHandlers: selectedProjectHandlers
      });

      _applicants &&
        _applicants.map((ph) => {
          if (!selectedApplicants.includes(ph)) selectedApplicants.push(ph);
        });

      this.setState({ selectedApplicants: selectedApplicants });

      form.country = _country;
      form.state = _state;
      form.city = _city;
      const newState = { ...form };
      this.props.form(newState);

      this.handleGetProjectStates(form.country, 1);
      this.handleGetProjectCities(form.state, 1);

      var _postFilter = {
        status,
        filterDate: _filterDate,
        currentPage,
        pageSize,
        projectHandlers: selectedProjectHandlers,
        applicants: selectedApplicants,
        country: _country,
        state: _state,
        city: _city,
        isUnassignQuoteOnly: _isUnassignQuoteOnly
      };
      this.setState({ postFilter: _postFilter });
      if (
        (_postFilter && _postFilter.status.length > 0) ||
        _postFilter.projectHandlers.length > 0 ||
        _postFilter.applicants.length > 0 ||
        _postFilter.filterDate ||
        _postFilter.country ||
        _postFilter.state ||
        _postFilter.city ||
        _postFilter.isUnassignQuoteOnly === true
      ) {
        if (roleId == Role.projectHandler)
          this.props.getFEOrdersByFilter(_postFilter, currentPage, pageSize);
        else
          this.props.getFEOrdersByFilterByUser(
            _postFilter,
            currentPage,
            pageSize
          );
      } else {
        if (roleId == Role.projectHandler)
          this.props.getAllFEOrders(
            this.props.currentPage,
            this.props.pageSize
          );
        else
          this.props.getFEOrders(this.props.currentPage, this.props.pageSize);
      }

      this.props.filterOptionReset();
    }

    // MULTI SITES
    let { fieldService, projectDetails, schedulingDetails } = this.props;
    let siteDetailsMulti = projectDetails.siteDetails;
    let orderDetailsMulti = (fieldService.form || {}).orderDetails;

    return (
      <div>
        {roleId == Role.fieldEngineer &&
        (orderDetails.orderNumber || orderDetailsMulti) ? (
          openSummaryModal ? (
            <FeSummary
              openSummaryModal={openSummaryModal}
              onCloseSummaryModal={this.onCloseSummaryModal}
              primaryDetails={primaryDetails}
              billToDetails={billToDetails}
              reservationDetails={reservationDetails}
              siteDetails={siteDetails}
              otherDetails={otherDetails}
              accountSettings={accountSettings}
              fe={fe}
              isFromOffered={true}
            />
          ) : openSummaryModalMulti ? (
            <FeSummaryMulti
              openSummaryModal={openSummaryModalMulti}
              handleCloseSummaryModal={this.onCloseSummaryModalMulti}
              forms={projectDetails.forms}
              schedulingForms={schedulingDetails.schedulingForms}
              primaryDetails={primaryDetails}
              billToDetails={billToDetails}
              siteDetails={siteDetailsMulti}
              isFromOffered={true} // always true, accepting from offered
            />
          ) : null
        ) : null}
        <Modal open={isOpenCancelModal} size="mini">
          <Modal.Header className="ui center aligned">
            Confirm Cancellation
          </Modal.Header>
          <Modal.Content scrolling>
            Are you sure you want to cancel? This cannot be undone.
          </Modal.Content>
          <Modal.Actions>
            <Button
              primary
              onClick={() => this.setState({ isOpenCancelModal: false })}
            >
              No
            </Button>
            <Button secondary basic onClick={this.handleCancelOrder.bind(this)}>
              Yes
            </Button>
          </Modal.Actions>
        </Modal>
        <Modal open={isOpenProjectHandlerModal} size="mini">
          <Modal.Header className="ui left aligned">
            Assign Project Handler
          </Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Form.Field
                control={Dropdown}
                selection
                placeholder="Select project handler"
                options={projectHandlers}
                onChange={(e, properties) => {
                  this.handleDropdownChange("projectHandler", properties.value);
                }}
                value={form.projectHandler || ""}
              />
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              secondary
              basic
              onClick={() =>
                this.setState({ isOpenProjectHandlerModal: false })
              }
            >
              Cancel
            </Button>
            <Button
              primary
              onClick={this.handleConfirmAssignProjectHandler.bind(this)}
            >
              Assign
            </Button>
          </Modal.Actions>
        </Modal>
        <Modal open={isOpenUnAssignProjectHandlerModal} size="mini">
          <Modal.Header className="ui left aligned">
            Un-Assign Project Handler
          </Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <CustomTitleLabel message={projectHandlerName} />
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              secondary
              basic
              onClick={() =>
                this.setState({
                  isOpenUnAssignProjectHandlerModal: false
                })
              }
            >
              Cancel
            </Button>
            <Button
              primary
              onClick={this.handleConfirmUnAssignProjectHandler.bind(this)}
            >
              Un-Assign
            </Button>
          </Modal.Actions>
        </Modal>
        <Sidebar.Pushable>
          <FilterSidebar
            activeIndex={activeIndex}
            isFilterVisible={isFilterVisible}
            statusOptions={statusOptions}
            dateRangeOptions={dateRangeOptions}
            projectHandlers={projectHandlers}
            applicants={applicants}
            cities={cities}
            states={states}
            countryOptions={this.props.countryOptions}
            stateOptions={this.props.stateOptions}
            cityOptions={this.props.cityOptions}
            filterDate={filterDate}
            filterDateType={filterDateType}
            form={form}
            selectedProjectHandlers={selectedProjectHandlers}
            selectedApplicants={selectedApplicants}
            selectedCities={selectedCities}
            selectedStates={selectedStates}
            isExpandMultiSite={isExpandMultiSite}
            isUnassignQuoteOnly={isUnassignQuoteOnly}
            handleClick={this.handleClick}
            handleToggleFilter={this.handleToggleFilter}
            handleStatusFilterChange={this.handleStatusFilterChange}
            handleDateFilterChange={this.handleDateFilterChange}
            handleClearFilter={this.handleClearFilter}
            handleApplyFilter={this.handleApplyFilter}
            handleDropdownChange={this.handleDropdownChange}
            handleAddPHFilter={this.handleAddPHFilter}
            handleRemovePHFilter={this.handleRemovePHFilter}
            handleAddApplicantFilter={this.handleAddApplicantFilter}
            handleRemoveApplicantFilter={this.handleRemoveApplicantFilter}
            handleAddStateFilter={this.handleAddStateFilter}
            handleRemoveStatesFilter={this.handleRemoveStatesFilter}
            handleAddCitiesFilter={this.handleAddCitiesFilter}
            handleRemoveCitiesFilter={this.handleRemoveCitiesFilter}
            handleGetProjectStates={this.handleGetProjectStates}
            handleGetProjectCities={this.handleGetProjectCities}
            handleExpandMultiSite={this.handleExpandMultiSite}
            handleShowUnassignQuoteOnly={this.handleShowUnassignQuoteOnly}
          />
          <ColumnOptions
            activeIndex={activeIndex}
            columnOptions={columnOptions}
            isColumnOptVisible={isColumnOptVisible}
            columnSortable={columnSortable}
            handleToggleColumn={this.handleToggleColumn}
            handleClick={this.handleClick}
            handleRemoveColumn={this.handleRemoveColumn}
            handleAddColumn={this.handleAddColumn}
            handleColumnSortChange={this.handleColumnSortChange}
            handleDdColumnChange={this.handleDdColumnChange}
            handleApplyColumnOptions={this.handleApplyColumnOptions}
          />
          <Sidebar.Pusher>
            <div
              style={{
                width: "80%",
                height: "85%",
                padding: "20px",
                overflowX: "scroll"
              }}
            >
              <Container fluid>
                {/* <Dimmer active={this.props.loading} inverted>
                  <Loader inverted content="Loading" />
                </Dimmer> */}
                {roleId && roleId == Role.fireEquipment && (
                  <CustomHeaderLabel message="FIELD INSPECTION VIEW QUOTE/ORDERS" />
                )}
                {roleId && roleId == Role.fieldEngineer && (
                  <CustomHeaderLabel message="QUOTE/ORDER HISTORY" />
                )}
                {roleId && roleId == Role.projectHandler && (
                  <CustomHeaderLabel message="QUOTE/ORDER HISTORY" />
                )}
                <Divider hidden />

                <Grid>
                  <Grid.Row>
                    <Grid.Column textAlign="right">
                      <Button
                        color="blue"
                        icon="filter"
                        onClick={this.handleToggleFilter}
                      ></Button>
                      <Button
                        color="blue"
                        content="Column Options"
                        basic
                        onClick={this.handleToggleColumn}
                      ></Button>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <Divider />
                <Table striped structured celled sortable={columnSortable}>
                  <Table.Header>
                    <Table.Row textAlign="center">
                      {columnOptions &&
                        columnOptions.map(
                          (_column, idx) =>
                            _column.visible === 1 && (
                              <Table.HeaderCell
                                key={idx}
                                sorted={
                                  column === _column.key ? direction : null
                                }
                                onClick={this.handleSort(_column.key)}
                              >
                                {_column.text}
                              </Table.HeaderCell>
                            )
                        )}

                      <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {this.props.loading && this.props.loading === true ? (
                      <Table.Row>
                        <Table.HeaderCell colSpan="15">
                          <Segment>
                            <Divider hidden />
                            <Dimmer active inverted>
                              <Loader inverted size="mini">
                                Loading
                              </Loader>
                            </Dimmer>
                          </Segment>
                        </Table.HeaderCell>
                      </Table.Row>
                    ) : (
                      data &&
                      data.map((data, idx) => (
                        <OrderItem
                          key={idx}
                          data={data}
                          columnOptions={columnOptions}
                          handleConfirmCompleteOrder={
                            this.handleConfirmCompleteOrder
                          }
                          handleCancellationCodeModal={
                            this.handleCancellationCodeModal
                          }
                          handleAssignProjectHandler={
                            this.handleAssignProjectHandler
                          }
                          handleUnAssignProjectHandler={
                            this.handleUnAssignProjectHandler
                          }
                          handleExpandItem={this.handleExpandItem}
                        />
                      ))
                    )}
                  </Table.Body>
                  <Table.Footer>
                    <Table.Row>
                      <Table.HeaderCell colSpan="15">
                        {data && this.props.totalPages > 0 ? (
                          <Pagination
                            floated="right"
                            activePage={this.props.currentPage}
                            totalPages={this.props.totalPages}
                            onPageChange={(e, { activePage }) => {
                              var _postFilter = postFilter
                                ? {
                                    status: postFilter.status,
                                    currentPage: activePage,
                                    pageSize: postFilter.pageSize,
                                    projectHandlers: postFilter.projectHandlers,
                                    applicants: postFilter.applicants,
                                    cities: postFilter.cities,
                                    states: postFilter.states,
                                    isUnassignQuoteOnly:
                                      postFilter.isUnassignQuoteOnly
                                  }
                                : postFilter;

                              this.handlePageChange(_postFilter);
                            }}
                          />
                        ) : (
                          <Message>No Order Found</Message>
                        )}
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Footer>
                </Table>
                <FooterHomeBlock />
              </Container>
            </div>
          </Sidebar.Pusher>
        </Sidebar.Pushable>

        {isShowCancellationCodeModal ? (
          <CancellationCode
            orderId={feOrderId}
            isShowCancellationCodeModal={isShowCancellationCodeModal}
            handleCancellationCodeModal={this.handleCancellationCodeModal}
            handleConfirmCancelOrder={this.handleConfirmCancelOrder}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentPage: state.viewHistory.currentPage,
    pageSize: state.viewHistory.pageSize,
    totalPages: state.viewHistory.totalPages,
    loading: state.viewHistory.loading,
    viewHistory: state.viewHistory,
    isSuccessUpdateOrderType: state.viewHistory.isSuccessUpdateOrderType,
    isSuccessAssignProjectHandler:
      state.viewHistory.isSuccessAssignProjectHandler,
    isSuccessSetFilterOption: state.viewHistory.isSuccessSetFilterOption,
    isSuccess: state.viewHistory.isSuccess,
    cancelOrder: state.feCancelOrder,
    fieldEvaluation: state.fieldEvaluation,
    accountSettings: state.accountSettings.form,
    feProductItem: state.feProductDetails,
    countryOptions: state.fieldEvaluation.countryOptionsPrelimProjectDetails,
    stateOptions: state.fieldEvaluation.stateOptionsPrelimProjectDetails,
    cityOptions: state.fieldEvaluation.cityOptionsPrelimProjectDetails,
    fieldService: state.fieldService,
    projectDetails: state.feProjectDetails,
    schedulingDetails: state.schedulingDetails
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changePageFEOrders: (activePage, pageSize) =>
      dispatch(actions.changePageFEOrders(activePage, pageSize)),
    changePageAllFEOrders: (activePage, pageSize) =>
      dispatch(actions.changePageAllFEOrders(activePage, pageSize)),
    getFEOrders: (currentPage, pageSize) =>
      dispatch(actions.getFEOrders(currentPage, pageSize)),
    getAllFEOrders: (currentPage, pageSize) =>
      dispatch(actions.getAllFEOrders(currentPage, pageSize)),
    getFEOrdersByFilter: (filter, currentPage, pageSize) =>
      dispatch(actions.getFEOrdersByFilter(filter, currentPage, pageSize)),
    getFEOrdersByFilterByUser: (filter, currentPage, pageSize) =>
      dispatch(
        actions.getFEOrdersByFilterByUser(filter, currentPage, pageSize)
      ),
    form: (result) => dispatch(actions.updateForm(result)),
    updateOrderType: (data) => dispatch(actions.updateOrderType(data)),
    updateOrderTypeReset: () => dispatch(actions.updateOrderTypeReset()),
    doCancelOrder: (data) => dispatch(doCancelOrder(data)),
    resetCancelOrder: () => dispatch(resetCancelOrder()),
    getOrderDetails: (orderNumber) =>
      dispatch(fActions.getOrderDetails(orderNumber)),
    updateData: (result) => dispatch(actions.updateData(result)),
    getAccountSettings: () => dispatch(getAccountSettings()),
    getProductDetails: (orderDetails) =>
      dispatch(feActions.getProductDetails(orderDetails)),
    updateIsSuccess: () => dispatch(fActions.updateIsSuccess()),
    getProjectHandlers: () => dispatch(actions.getProjectHandlers()),
    assignProjectHandlers: (data) =>
      dispatch(actions.assignProjectHandlers(data)),
    assignProjectHandlerReset: () =>
      dispatch(actions.assignProjectHandlerReset()),
    unAssignProjectHandlers: (data) =>
      dispatch(actions.unAssignProjectHandlers(data)),
    setFilterOptions: (data) => dispatch(actions.setFilterOptions(data)),
    getFilterOptions: () => dispatch(actions.getFilterOptions()),
    filterOptionReset: () => dispatch(actions.filterOptionReset()),
    getApplicants: () => dispatch(actions.getApplicants()),
    getProjectCountries: (locationType) =>
      dispatch(fActions.getProjectCountries(locationType)),
    getProjectStates: (countryCode, locationType) =>
      dispatch(fActions.getProjectStates(countryCode, locationType)),
    getProjectCities: (stateCode, locationType) =>
      dispatch(fActions.getProjectCities(stateCode, locationType)),
    clearForm: () => dispatch(actions.clearVHForm()),
    getOrderDetailsMulti: (orderNumber) =>
      dispatch(multiActions.getOrderDetails(orderNumber)),
    renderProductDetails: (siteCount, data) =>
      dispatch(projectActions.renderProductDetails(siteCount, data)),
    renderSiteDetails: (siteCount, data) =>
      dispatch(projectActions.renderSiteDetails(siteCount, data)),
    renderSchedulingDetails: (siteCount, data) =>
      dispatch(schedActions.renderSchedulingDetails(siteCount, data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewHistory);
