import React, { Component } from "react";
import {
  Container,
  Grid,
  Segment,
  Checkbox,
  Form,
  Button,
  Table,
  Modal,
  Input,
  Image
} from "semantic-ui-react";
import { connect } from "react-redux";
import * as actions from "../field-evaluation.action";
import * as vhActions from "./../view-history/view-history.action";
import { createValidation } from "../../../validator/validator";
import {
  ADADrules,
  ADABTLDrules,
  ADQADrules,
  SDPSLSrules,
  SDFISSrules,
  PDrules,
  RDPSLSrules,
  RDFSLSrules,
  RDAHJDrules
} from "../validation-rules";
import _ from "lodash";
import Role from "../../../enums/role";
import { Common } from "../../../common";
import ServiceType from "../../../enums/service-type";
import Status from "../../../enums/status";
import ToastHelper from "../../../helpers/toast.helper";

// FE
import FeScope from "./fe/scope";
import FePrice from "./fe/price";
import FeAssumption from "./fe/assumption";
import FeCancellation from "./fe/cancellation";
import FeRequirements from "./fe/requirements";
import FeTermsAndConditions from "./fe/t-and-c";
import FeGlobalServicesAgreement from "./fe/global-services-agreement";
import Acceptance from "./fe/acceptance";

// FES
import FesPrice from "./fes/price";
import FesAssumption from "./fes/assumption";
import FesTermsAndConditions from "./fes/t-and-c";
import FeServiceTerms from "./fes/service-terms";

import { Redirect } from "react-router-dom";
import QuoteHeader from "./quote-header";
import { renderQuotationBody } from "../../../helpers/component-helper";

import ScopeValue from "./fe/scope-value";
import AssumptionValue from "./fe/assumption-value";
import CancellationValue from "./fe/cancellation-value";
import RequirementsValue from "./fe/requirements-value";

class Quotation extends Component {
  state = {
    isSuccess: false,
    refereceNmber: "",
    isShowButton: true,
    isLoading: "",
    isCheckAgree: false,
    openPoNumber: false,
    purchaseOrderNumber: null,
    isSuccess: false
  };

  htmlBody = renderQuotationBody();

  handleRenderScheduleDate = (date) => {
    if (date) {
      var date = new Date(date);
      var day = date.getDate();
      var month = date.getMonth() + 1;
      var year = date.getFullYear();
      var hour = date.getHours();
      var minute = date.getMinutes();
      var second = date.getSeconds();

      var time =
        month +
        "/" +
        day +
        "/" +
        year +
        " " +
        hour +
        ":" +
        minute +
        ":" +
        second;

      return time;
    }

    return null;
  };

  handleRenderHtmlBody = (html) => {
    html = html.replace(
      '<div class="container-wrapper" id="quotation"></div>',
      document.getElementById("quotation").outerHTML
    );

    html = html.replace(
      document.getElementById("checkbox-section").outerHTML,
      '<div id="checkbox-section"></div>'
    );
    html = html.replace(
      document.getElementById("acceptance-section").outerHTML,
      '<div id="acceptance-section"></div>'
    );
    html = html.replace(
      document.getElementById("accept-save-section").outerHTML,
      '<div id="accept-save-section"></div>'
    );

    return html;
  };

  handleRenderTask = () => {
    var task = {
      envelope: {
        body: {
          processTaskEx: {
            bookingTask: {
              callID: "LexConfirm-1",
              number: "0",
              earlyStart: "2020-03-20T00:50:00",
              dueDate: "2020-04-05T12:59:00",
              lateStart: "2020-03-23T16:37:00",
              priority: "0",
              status: "Open",
              customer: "",
              region: "NA East",
              district: "New York",
              postcode: "11222",
              openDate: "2020-03-20T20:30:00",
              taskType: "30014613-30025099",
              duration: "3600",
              numberOfRequiredEngineers: "1",
              street: "196 Dupont St",
              city: "Brooklyn",
              state: "NY",
              countryID: "US",
              ulDivision: "North America",
              ulTimeZone: "Eastern Standard Time",
              appointmentStart: "2020-04-01T13:00:00",
              appointmentFinish: "2020-04-01T18:00:00"
            },
            returnAssignment: "true",
            taskRequestedProperties: {
              item: ["CallID", "Status", "Number"]
            },
            assignmentRequestedProperties: {
              item: ["Start", "Finish"]
            },
            returnSchedulingError: "true"
          }
        }
      }
    };

    return task;
  };

  handleSubmit = (status) => {
    this.setState({ isLoading: "loading" });
    let {
      orderDetails,
      applicantDetails,
      siteDetails,
      productDetails,
      reservationDetails,
      otherDetails
    } = this.props.fieldEvaluation.form;

    let { isCopyOrder } = this.props.fieldEvaluation;

    let { orders } = this.props.fesProductItem;
    const { form, productPrice } = this.props.feProductItem;
    const { accountDetails } = this.props;

    let userRole = Common.getUserRole();

    if (form.isManualPricing && userRole != Role.projectHandler)
      status = Status.pendingAssessment; // FE Customer: Pending Assessment

    // TODO: When PH will create quote on behalf of user, change this
    let serviceType =
      userRole == Role.fieldEngineer || Role.projectHandler
        ? ServiceType.fe
        : ServiceType.fes;
    // FE: 1, FES: 2

    applicantDetails.applicantDetails.validationErrors = createValidation(
      applicantDetails.applicantDetails,
      ADADrules
    );

    applicantDetails.applicantBillToLocationDetails.validationErrors = createValidation(
      applicantDetails.applicantBillToLocationDetails,
      ADABTLDrules
    );

    applicantDetails.quoteAcceptanceDetails.validationErrors = createValidation(
      applicantDetails.quoteAcceptanceDetails,
      ADQADrules
    );

    siteDetails.preliminarySiteLocationSection.validationErrors = createValidation(
      siteDetails.preliminarySiteLocationSection,
      SDPSLSrules
    );

    siteDetails.finalInstallationSiteSection.validationErrors = createValidation(
      siteDetails.finalInstallationSiteSection,
      SDFISSrules
    );

    productDetails.validationErrors = createValidation(productDetails, PDrules);

    reservationDetails.preliminarySiteLocationSection.validationErrors = createValidation(
      reservationDetails.preliminarySiteLocationSection,
      RDPSLSrules
    );

    reservationDetails.finalSiteLocationSection.validationErrors = createValidation(
      reservationDetails.finalSiteLocationSection,
      RDFSLSrules
    );

    reservationDetails.authorityHavingJurisdictionDetails.validationErrors = createValidation(
      reservationDetails.authorityHavingJurisdictionDetails,
      RDAHJDrules
    );

    // Repopulate post data
    let applicantCollection = [];
    let siteCollection = [];
    let reservationCollection = [];
    let fesProductItems = [];
    let feProductItems = [];
    let ahjCollection = [];

    const _applicantDetails = applicantDetails.applicantDetails;
    const _applicantBillToLocationDetails =
      applicantDetails.applicantBillToLocationDetails;

    reservationDetails.preliminarySiteLocationSection.siteType = 1;
    reservationDetails.preliminarySiteLocationSection.scheduleUnixTime = reservationDetails
      .preliminarySiteLocationSection.scheduleDate
      ? reservationDetails.preliminarySiteLocationSection.scheduleDate.getTime() /
        1000
      : null;
    reservationDetails.finalSiteLocationSection.siteType = 2;

    // Primary
    // _applicantDetails.locationType = 1;
    applicantCollection.push(_applicantDetails);
    // Bill to
    // _applicantBillToLocationDetails.locationType = 2;
    applicantCollection.push(_applicantBillToLocationDetails);

    siteDetails.preliminarySiteLocationSection.siteType = 1;
    siteDetails.finalInstallationSiteSection.siteType = 2;

    siteDetails.authorityHavingJurisdictionDetails.baseType = 1;
    reservationDetails.authorityHavingJurisdictionDetails.baseType = 2;

    // Preliminary
    siteCollection.push(siteDetails.preliminarySiteLocationSection);
    // Final
    siteCollection.push(siteDetails.finalInstallationSiteSection);

    // Preliminary
    reservationCollection.push(
      reservationDetails.preliminarySiteLocationSection
    );
    // Final
    reservationCollection.push(reservationDetails.finalSiteLocationSection);

    // AHJ
    ahjCollection.push(siteDetails.authorityHavingJurisdictionDetails);
    ahjCollection.push(reservationDetails.authorityHavingJurisdictionDetails);

    // FES
    orders.map((o, idx) => {
      let me = {
        // productPriceId: o.priceId, // FE
        id: o.id,
        serviceProductPriceId: o.priceId, // FES
        quantity: parseInt(o.quantity),
        price: o.price,
        total: o.total,
        isMinimumFee: o.isMinimumFee,
        areAerialsBrontoSchwingMetz: o.areAerialsBrontoSchwingMetz,
        hasExistingULLabel: o.hasExistingULLabel,
        requestedScheduleUnixTime: o.requestedScheduleUnixTime,
        isProductInvolvedInLitigation: o.isProductInvolvedInLitigation,
        productItemOtherInfos: o.others
          ? Object.keys(o.others).map((me) => ({
              code: me,
              name: "Truck #", // For Aerial only, TODO: improve this
              info: o.others[me]
            }))
          : null
      };
      fesProductItems.push(me);
    });

    // FE
    // TODO: multiple
    let isPHEdit = Common.isPhEditing(form.isManualPricing);

    let feOrder = {
      id: form.id,
      serviceProductPriceId:
        productPrice && productPrice.data ? productPrice.data.id : null,
      quantity: parseInt(form.quantity),
      price: productPrice && productPrice.data ? productPrice.data.price : 0, // 0 - from PUA, total from PH
      total: isPHEdit ? form.totalPriceByPh : productPrice.data.totalPrice,
      isMinimumFee: false,
      areAerialsBrontoSchwingMetz: false,
      hasExistingULLabel: form.isExistingUlEquipment,
      isProductInvolvedInLitigation: form.isInvolvedLitigation,
      isMinimumFee: false,
      isManualPricing: form.isManualPricing,
      litigationDescription: form.isInvolvedLitigation
        ? form.litigationDescription
        : null,
      isInstalledSameLocation: form.isInstalledSameLocation,
      isEvaluatedSameVisit: form.isEvaluatedSameVisit,
      isPanelboardOver200: form.isPanelboard ? form.panelboardOver200 : null,
      isSwitchboardOver600: form.isSwitchboard ? form.switchboardOver600 : null,
      propertyType: form.propertyType,
      additionalInformation: form.additionalInfo,
      isBusinessHours: ((productPrice || {}).data || {}).isBusinessHours,
      assumptions: AssumptionValue(this.props.feProductItem, null, false),
      scope: ScopeValue(form),
      cancellation: CancellationValue(this.props.feProductItem),
      requirementsInspection: RequirementsValue(this.props.feProductItem)
    };
    feProductItems.push(feOrder);

    let copyOrderPostData = {};

    copyOrderPostData.applicants = applicantCollection;
    copyOrderPostData.quoteAcceptance = applicantDetails.quoteAcceptanceDetails;
    copyOrderPostData.sites = siteCollection;
    copyOrderPostData.products = productDetails;
    copyOrderPostData.productItems =
      serviceType == 1 ? feProductItems : fesProductItems;
    copyOrderPostData.engineers = reservationCollection;
    copyOrderPostData.authorityJurisdictions = ahjCollection;
    copyOrderPostData.status = status;
    copyOrderPostData.orderType = 1;
    copyOrderPostData.serviceType = serviceType;
    copyOrderPostData.customerReferenceNumber =
      otherDetails.customerReferenceNumber;
    copyOrderPostData.purchaseOrderNumber = isPHEdit
      ? otherDetails.purchaseOrderNumber
      : this.state.purchaseOrderNumber;
    copyOrderPostData.requestNumber = orderDetails.orderNumber;
    copyOrderPostData.isReadyToScheduleNow = otherDetails.isReadyToScheduleNow;
    copyOrderPostData.preferredScheduleDate = this.handleRenderScheduleDate(
      reservationDetails.preliminarySiteLocationSection.scheduleDate
    );
    copyOrderPostData.taskDetails = this.handleRenderTask();
    copyOrderPostData.createdForUserId =
      accountDetails.form.applicantDetails.applicantDetails.userId ||
      orderDetails.createdForUserId;
    if (isPHEdit) {
      copyOrderPostData.isEditedByPh = true;
    }
    // if (Object.IsEmpty(applicantDetails.applicantDetails.validationErrors)) {
    if (orderDetails.orderNumber) {
      if (otherDetails.isCopyOrder) {
        return this.props
          .doCreateFEOrder(
            _.omit(copyOrderPostData, ["showErrors", "validationErrors"])
          )
          .then((s) => {
            this.handleToastMessage(s);
          });
      } else {
        return this.props
          .doUpdateFEOrder(
            _.omit(copyOrderPostData, ["showErrors", "validationErrors"])
          )
          .then((s) => {
            this.handleToastMessage(s);
          });
      }
    } else {
      return this.props
        .doCreateFEOrder(
          _.omit(copyOrderPostData, ["showErrors", "validationErrors"])
        )
        .then((s) => {
          this.handleUploadAttachment(form.file, s);
          this.handleToastMessage(s);
        });
    }
    // }
  };

  handleAcceptOrderFromOffered = () => {
    this.setState({ isLoading: "loading" });
    var { orderDetails } = this.props.fieldEvaluation.form;

    var data = {
      fieldEvaluationOrderId: orderDetails.id,
      orderType: 2,
      quotation: this.handleRenderHtmlBody(this.htmlBody)
    };

    return this.props.updateOrderType(data).then((s) => {
      this.handleToastMessage(s);
    });
  };

  handleToastMessage(result) {
    if (result) {
      if (result.number != "") {
        this.setState({
          // isSuccess: true,
          refereceNmber: result.number,
          isShowButton: false,
          isLoading: "",
          isSuccess: true
        });

        setTimeout(() => {
          ToastHelper.success({
            description: (
              <p>
                {`Thank you for your business. Your quote reference number is ${result.number}.
                  A Project Handler will be in touch with you as soon as
                  possible.`}
              </p>
            )
          });
        }, 100);
      } else {
        setTimeout(() => {
          ToastHelper.error({
            title: "Something went wrong",
            description: result.message
          });
        }, 100);
      }
    } else {
      this.setState({
        isLoading: "",
        isSuccess: false
      });
      setTimeout(() => {
        ToastHelper.error({
          title: "Something went wrong",
          description: result.message
        });
      }, 100);
    }
  }

  onClickAgreeChecbox = () =>
    this.setState((prevState) => ({ isCheckAgree: !prevState.isCheckAgree }));

  handleTogglePoNumberModal = (isOpen) => {
    this.setState({ openPoNumber: isOpen });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleUploadAttachment(file, result) {
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("requestNumber", result.number);
      this.props.uploadAttachment(formData);
    }
  }

  componentDidMount() {
    // this.renderCanvas();
  }

  render() {
    const { form } = this.props.feProductItem;
    const { orders } = this.props.fesProductItem;
    const { otherDetails } = this.props.fieldEvaluation.form;

    const {
      isShowButton,
      isLoading,
      isCheckAgree,
      openPoNumber,
      purchaseOrderNumber,
      isSuccess
    } = this.state;
    const userRole = Common.getUserRole();

    if (isSuccess == true) {
      return userRole == Role.projectHandler ? (
        <Redirect to="/home/view-all-orders" />
      ) : (
        <Redirect to="/home/view-history" />
      );
    }

    return (
      <div className="container-wrapper" id="quotation">
        <Container fluid>
          <Segment>
            <QuoteHeader />
            <Table attached="top" basic="very">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell textAlign="center" colSpan="1">
                    <h2>QUOTATION</h2>
                    <p>
                      This Quotation is provided by or on behalf of UL LLC. We
                      are pleased to provide you with the following Quotation
                      and we will make every effort to exceed your expectations
                      as we fulfill this work.
                    </p>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
            </Table>

            {/* TODO: Change condition per service type not by role*/}
            {/* FE */}
            {userRole == Role.fieldEngineer ||
            userRole == Role.projectHandler ? (
              <span>
                <FeScope form={form} />
                <FePrice form={form} />
                <FeAssumption form={form} />
                <FeCancellation form={form} />
                <FeRequirements />
                <FeTermsAndConditions />
                <FeGlobalServicesAgreement />
              </span>
            ) : null}

            {/* FES */}
            {userRole == Role.fireEquipment ? (
              <span>
                <FesPrice orders={orders} />
                <FesAssumption orders={orders} />
                <FeServiceTerms />
                <FesTermsAndConditions />
              </span>
            ) : null}

            {userRole != Role.projectHandler ? (
              <div>
                <div id="checkbox-section">
                  <Table attached>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell textAlign="center">
                          <Checkbox
                            checked={isCheckAgree}
                            onChange={this.onClickAgreeChecbox.bind()}
                            label={
                              <label>
                                <b>
                                  I have read and accept the “Terms and
                                  Conditions” to
                                  {/* TODO: Change condition per service type not by role*/}
                                  {userRole == Role.fireEquipment
                                    ? " “Fire Equipment Services Terms For Inspection Of In-Service Automotive Fire Apparatus And Equipment Service Terms”"
                                    : " “Field Evaluations Services”"}
                                </b>
                              </label>
                            }
                          />
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                  </Table>
                </div>

                <div id="acceptance-section">
                  <Acceptance />
                </div>
              </div>
            ) : null}
            <br />
            <div id="accept-save-section">
              <Grid>
                <Grid.Row>
                  <Grid.Column>
                    <Form>
                      <Form.Field>
                        {isCheckAgree ? (
                          isShowButton ? (
                            <Button
                              className={isLoading}
                              primary
                              onClick={this.handleTogglePoNumberModal.bind(
                                this,
                                true
                              )}
                            >
                              Accept
                            </Button>
                          ) : null
                        ) : null}
                        {otherDetails.isFromOffered === false && (
                          <Button
                            className={isLoading}
                            primary
                            onClick={this.handleSubmit.bind(
                              this,
                              Status.offered
                            )}
                          >
                            {userRole != Role.projectHandler
                              ? "Save"
                              : "Offer Quote to Customer"}
                          </Button>
                        )}
                      </Form.Field>
                    </Form>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
          </Segment>
        </Container>
        <Modal open={openPoNumber} size="mini">
          <Modal.Header className="ui center aligned">
            Enter Purchase Order Number
          </Modal.Header>
          <Modal.Content scrolling>
            <table>
              <tbody>
                <tr>
                  <td>PO Number</td>
                  <td>
                    <Form.Field
                      control={Input}
                      placeholder="Enter PO Number"
                      name="purchaseOrderNumber"
                      onChange={this.handleChange}
                      value={purchaseOrderNumber || ""}
                      autoComplete="off"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </Modal.Content>
          <Modal.Actions>
            <Button
              primary
              onClick={() => {
                otherDetails.isFromOffered
                  ? this.handleAcceptOrderFromOffered()
                  : this.handleSubmit(Status.accepted);
                this.handleTogglePoNumberModal(false);
              }}
            >
              Submit
            </Button>

            <Button
              primary
              basic
              onClick={() => {
                this.handleTogglePoNumberModal(false);
              }}
            >
              Cancel
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    fieldEvaluation: state.fieldEvaluation,
    fesProductItem: state.fesProductDetails,
    feProductItem: state.feProductDetails,
    accountDetails: state.accountSettings
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doCreateFEOrder: (param) => dispatch(actions.doCreateFEOrder(param)),
    doUpdateFEOrder: (param) => dispatch(actions.doUpdateFEOrder(param)),
    uploadAttachment: (data) => dispatch(actions.uploadAttachment(data)),
    updateOrderType: (param) => dispatch(vhActions.updateOrderType(param))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Quotation);
