const Status = {
  draft: 1,
  inProgress: 2,
  processed: 3,
  cancelled: 4,
  requestToCancel: 5,
  accepted: 6,
  pendingAssessment: 7,
  quoted: 8,
  offered: 9,
  inProcess: 10,
  onHoldActionRequired: 11,
  //pendingCorrectiveAction: 12,
  //completed: 12,
};

export default Status;
