import React, { Component } from 'react';
import {
  Button,
  Grid,
  Divider,
  Icon,
  Menu,
  Sidebar,
  Accordion,
  List,
  Form,
  Radio,
  Dropdown,
  Input,
} from 'semantic-ui-react';
import { CustomHeaderInlineBold } from '../../../components/labels/labels';
import { connect } from 'react-redux';
import { validateField } from '../../../validator/validator';
import DatePicker from 'react-datepicker';
import {
  getListFileNumbers,
  clearForm,
  updateForm,
  getListApplicants,
  getListManufacturers,
  getListOracleNumbers,
  getListRequestNumbers,
  getCustomerPoNumber,
  getCustomerContactName,
  setUserFilter,
  getOrders,
  getListLabelCenters,
  getListApplicantAccountNos,
  getListManufacturerAccountNos,
  getListApplicantAddress,
  getListManufacturerAddress,
  getListManufacturerPsn,
  resetClearFilter,
} from '../view-orders.action';
import _ from 'lodash';
import { Common } from '../../../common/common';
import Role from '../../../enums/role';
import { controlKind } from './label-filter-option.columns';
import * as commonActions from '../../user/common/common.action';
const rules = [];

const blankFilterObject = {
  userId: [],
  status: [],
  filterDate: [],
  labelOrderType: [],
  fileNo: [],
  companyName: [],
  manufacturer: [],
  oracleOrderNo: [],
  requestNo: [],
  columnOptions: [],
  labelCenters: [],
  companyAccountNos: [],
  manufacturerAccountNos: [],
  isExpandMultipleOrders: false,
  orderStatus: [],
  customerPoNumber: [],
  customerContactName: [],
  orderFilterDate: [],
  manufacturerAddress: [],
  manufacturerPsn: [],
  applicantAddress: [],
  isRepushOrders: false,
  emailSent: [],
  labelSupplierFileNumber: [],
  labelSupplierCompanyName: [],
  labelSupplierPartySiteNumber: [],
  isCopied: false,
  labelAgents: [],
  vendorLetterSentToCustomer: [],
  vendorLetterSentToSupplier: [],
  id: [],
};

class LabelFilterOption extends Component {
  state = {
    startDate: new Date(),
    endDate: new Date(),
    orderStartDate: new Date(),
    orderEndDate: new Date(),
    filterObjects: {
      ...blankFilterObject,
    },
  };

  componentDidMount() {
    this.props.getListFileNumbers();
    this.props.getListLabelCenters();
    this.props.getListApplicantAddress();
    this.props.getListManufacturerAddress();
    this.props.getListManufacturerPsn();
    this.configureFilterOptionColumns();
  }

  configureFilterOptionColumns = () => {
    const { filterOptions } = this.props.shared;

    if (!filterOptions.length)
      this.props.setFilterOptionColumns(Common.getUserRole());
  };

  handleApplyFilter = () => {
    const { filterObjects } = this.state;
    const { form } = this.props.viewOrders;

    const selectedCreatedDates = [];
    const selectedOrderDates = [];
    const data = {};
    const userRole = Common.getUserRole();

    if (form.startDate && form.endDate) {
      selectedCreatedDates.push(new Date(form.startDate).toLocaleDateString());
      selectedCreatedDates.push(new Date(form.endDate).toLocaleDateString());
    }

    if (form.orderStartDate && form.orderEndDate) {
      selectedOrderDates.push(
        new Date(form.orderStartDate).toLocaleDateString()
      );
      selectedOrderDates.push(new Date(form.orderEndDate).toLocaleDateString());
    }

    Object.keys(filterObjects).forEach(property => {
      data[property] = this.checkAndJoin(filterObjects[property]);
    });

    data.currentPage = 1;
    data.pageSize = 10;
    data.filterDate = selectedCreatedDates.join('|');
    data.orderFilterDate = selectedOrderDates.join('|');

    if (userRole === Role.printer) {
      //force to combi
      data.labelOrderType = 2;
    }

    this.props.setUserFilter(data);
    this.props.handleToggleFilter();
  };

  handleClearFilter = isClearFilterToggled => {
    const { filterObjects: newfilterObjects } = this.state;
    const data = {};

    Object.keys(blankFilterObject).forEach(property => {
      data[property] = this.checkAndReset(blankFilterObject[property]);
    });

    Object.keys(newfilterObjects).forEach(property => {
      newfilterObjects[property] = this.checkAndResetFilterObject(
        newfilterObjects[property]
      );
    });

    data.currentPage = 1;
    data.pageSize = 10;

    this.props.setUserFilter(data);

    if (!isClearFilterToggled) this.props.handleToggleFilter();

    this.props.clearForm();

    this.setState({ filterObjects: newfilterObjects });
  };

  handleChange = (name, value) => {
    const { form } = this.props.viewOrders;
    validateField(rules, form, name, value);
    const newState = { ...this.props.viewOrders.form };
    this.props.updateForm(newState);
  };

  checkAndSplit = filterValue => {
    if (typeof filterValue === 'string') {
      return filterValue !== null && filterValue !== ''
        ? filterValue.split('|')
        : [];
    } else if (typeof filterValue === 'boolean') {
      return filterValue || false;
    } else if (typeof filterValue === 'number') {
      return filterValue !== null ? filterValue.toString().split('|') : [];
    } else {
      return [];
    }
  };

  checkAndReset = filterValue => {
    if (typeof filterValue === 'object') return '';
    else return false;
  };

  checkAndResetFilterObject = filterValue => {
    if (typeof filterValue === 'object') return [];
    else return false;
  };

  checkAndJoin = filterValue => {
    if (typeof filterValue === 'object') return filterValue.join('|');
    else return filterValue;
  };

  handleFilterChange = (key, value, isSingleSelection) => {
    this.setState(prevState => ({
      filterObjects: {
        ...prevState.filterObjects,
        [key]:
          typeof prevState.filterObjects[key] === 'object'
            ? isSingleSelection
              ? [value]
              : Array.from(new Set([...prevState.filterObjects[key], value]))
            : value,
      },
    }));
  };

  handleRemoveFilter = (key, value) => {
    this.setState(prevState => ({
      filterObjects: {
        ...prevState.filterObjects,
        [key]: prevState.filterObjects[key].filter(f => f !== value),
      },
    }));
  };

  render() {
    const {
      isFilterVisible,
      activeIndex,
      filter,
      isGetUserFilterSuccess,
      isClearFilterToggled,
      shared,
    } = this.props;

    const filterOptions = shared.filterOptions;

    const statefilterObjects = {
      ...this.state.filterObjects,
    };

    const { form } = this.props.viewOrders;

    if (isClearFilterToggled) {
      this.handleClearFilter(isClearFilterToggled);
      this.props.resetClearFilter();
    }

    if (!_.isEmpty(filter) && isGetUserFilterSuccess) {
      const filterDetailsFromDb = {};

      Object.keys(filter).forEach(property => {
        filterDetailsFromDb[property] = this.checkAndSplit(filter[property]);
      });

      Object.keys(filterDetailsFromDb).forEach(detailsProp => {
        if (typeof filterDetailsFromDb[detailsProp] === 'object')
          filterDetailsFromDb[detailsProp].forEach(item => {
            if (!statefilterObjects[detailsProp]?.includes(item))
              statefilterObjects[detailsProp]?.push(item);
          });
        else if (typeof filterDetailsFromDb[detailsProp] === 'boolean')
          statefilterObjects[detailsProp] =
            filterDetailsFromDb[detailsProp] || false;
        else return;
      });

      form.startDate =
        filterDetailsFromDb['filterDate']?.[0] &&
        new Date(Date.parse(filterDetailsFromDb['filterDate'][0]));
      form.endDate =
        filterDetailsFromDb['filterDate']?.[1] &&
        new Date(Date.parse(filterDetailsFromDb['filterDate']?.[1]));
      form.orderStartDate =
        filterDetailsFromDb['orderFilterDate']?.[0] &&
        new Date(Date.parse(filterDetailsFromDb['orderFilterDate']?.[0]));
      form.orderEndDate =
        filterDetailsFromDb['orderFilterDate']?.[1] &&
        new Date(Date.parse(filterDetailsFromDb['orderFilterDate']?.[1]));
    }

    return (
      <Sidebar
        as={Menu}
        animation="overlay"
        vertical
        direction="left"
        visible={isFilterVisible}
        width="wide"
      >
        <div style={{ padding: '20px' }}>
          <Grid columns={2}>
            <Grid.Column verticalAlign="middle">
              <CustomHeaderInlineBold message="Filters" />
            </Grid.Column>
            <Grid.Column textAlign="right">
              <Button
                basic
                icon="delete"
                onClick={this.props.handleToggleFilter}
              ></Button>
            </Grid.Column>
          </Grid>
          <Divider />
          <Accordion fluid>
            {filterOptions.map(columnItem => (
              <>
                <span hidden={columnItem.hidden}>
                  {columnItem.type === controlKind.dropdown && (
                    <>
                      <Accordion.Title
                        active={activeIndex === columnItem.index}
                        index={columnItem.index}
                        onClick={this.props.handleClick}
                        hidden={columnItem.hidden}
                      >
                        <Icon name="dropdown" />
                        {columnItem.title}
                      </Accordion.Title>
                      <Accordion.Content
                        active={activeIndex === columnItem.index}
                      >
                        {columnItem.tokenIds.map(tokenId => {
                          return (
                            <>
                              <List>
                                {statefilterObjects[tokenId] &&
                                  statefilterObjects[tokenId].map(
                                    (column, idx) => {
                                      return (
                                        column &&
                                        statefilterObjects[tokenId].includes(
                                          column
                                        ) && (
                                          <List.Item key={idx}>
                                            <Grid>
                                              <Grid.Row centered>
                                                <Input
                                                  value={
                                                    columnItem.hasMappingtoText
                                                      ? columnItem.source.find(
                                                          s =>
                                                            s.value === column
                                                        ).text
                                                      : column
                                                  }
                                                  disabled
                                                  style={
                                                    columnItem.customStyles
                                                      .FilterDisplayStyle
                                                  }
                                                />
                                                <Button
                                                  color="red"
                                                  basic
                                                  icon="delete"
                                                  onClick={() => {
                                                    this.handleRemoveFilter(
                                                      tokenId,
                                                      column
                                                    );
                                                  }}
                                                />
                                              </Grid.Row>
                                            </Grid>
                                          </List.Item>
                                        )
                                      );
                                    }
                                  )}
                                {statefilterObjects[tokenId] && (
                                  <List.Item>
                                    <Grid>
                                      <Grid.Row centered>
                                        <Form.Field
                                          control={Dropdown}
                                          selection
                                          search
                                          placeholder={
                                            columnItem.placeholder.find(
                                              p => p.tokenId === tokenId
                                            ).value
                                          }
                                          style={
                                            columnItem.customStyles
                                              .DropdownInputStyle
                                          }
                                          options={
                                            columnItem.isSourceFromProps
                                              ? this.props.viewOrders[
                                                  columnItem.source
                                                ]
                                              : columnItem.source
                                          }
                                          onChange={(e, properties) => {
                                            this.handleChange(
                                              tokenId,
                                              properties.value
                                            );
                                          }}
                                          selectOnBlur={false}
                                          value={form?.[tokenId] || ''}
                                        />
                                        <Button
                                          color="green"
                                          basic
                                          icon="add"
                                          style={
                                            columnItem.customStyles
                                              .FilterAddButtonStyle
                                          }
                                          onClick={() => {
                                            this.handleFilterChange(
                                              tokenId,
                                              form?.[tokenId],
                                              columnItem.isSingleSelection
                                            );
                                          }}
                                        />
                                      </Grid.Row>
                                    </Grid>
                                  </List.Item>
                                )}
                              </List>
                            </>
                          );
                        })}
                        {/* <List>
                        
                        
                      </List> */}
                      </Accordion.Content>
                    </>
                  )}
                  {columnItem.type === controlKind.textbox && (
                    <>
                      <Accordion.Title
                        active={activeIndex === columnItem.index}
                        index={columnItem.index}
                        onClick={this.props.handleClick}
                        hidden={columnItem.hidden}
                      >
                        <Icon name="dropdown" />
                        {columnItem.title}
                      </Accordion.Title>
                      <Accordion.Content
                        active={activeIndex === columnItem.index}
                      >
                        {columnItem.tokenIds.map(tokenId => {
                          return (
                            <>
                              <List>
                                {statefilterObjects[tokenId] &&
                                  statefilterObjects[tokenId].map(
                                    (column, idx) => {
                                      return (
                                        column &&
                                        statefilterObjects[tokenId].includes(
                                          column
                                        ) && (
                                          <List.Item key={idx}>
                                            <Grid>
                                              <Grid.Row centered>
                                                <Input
                                                  value={
                                                    columnItem.hasMappingtoText
                                                      ? columnItem.source.find(
                                                          s =>
                                                            s.value === column
                                                        ).text
                                                      : column
                                                  }
                                                  disabled
                                                  style={
                                                    columnItem.customStyles
                                                      .FilterDisplayStyle
                                                  }
                                                />
                                                <Button
                                                  color="red"
                                                  basic
                                                  icon="delete"
                                                  onClick={() => {
                                                    this.handleRemoveFilter(
                                                      tokenId,
                                                      column
                                                    );
                                                  }}
                                                />
                                              </Grid.Row>
                                            </Grid>
                                          </List.Item>
                                        )
                                      );
                                    }
                                  )}
                                {statefilterObjects[tokenId] && (
                                  <List.Item>
                                    <Grid>
                                      <Grid.Row centered>
                                        <Input
                                          placeholder={
                                            columnItem.placeholder.find(
                                              p => p.tokenId === tokenId
                                            ).value
                                          }
                                          style={
                                            columnItem.customStyles
                                              .DropdownInputStyle
                                          }
                                          options={
                                            columnItem.isSourceFromProps
                                              ? this.props.viewOrders[
                                                  columnItem.source
                                                ]
                                              : null
                                          }
                                          onChange={(e, properties) => {
                                            this.handleChange(
                                              tokenId,
                                              properties.value
                                            );
                                          }}
                                          value={form?.[tokenId] || ''}
                                        />
                                        <Button
                                          color="green"
                                          basic
                                          icon="add"
                                          style={
                                            columnItem.customStyles
                                              .FilterAddButtonStyle
                                          }
                                          onClick={() => {
                                            this.handleFilterChange(
                                              tokenId,
                                              form?.[tokenId],
                                              columnItem.isSingleSelection
                                            );
                                          }}
                                        />
                                      </Grid.Row>
                                    </Grid>
                                  </List.Item>
                                )}
                              </List>
                            </>
                          );
                        })}
                      </Accordion.Content>
                    </>
                  )}
                  {columnItem.type === controlKind.date && (
                    <>
                      <Accordion.Title
                        active={activeIndex === columnItem.index}
                        index={columnItem.index}
                        onClick={this.props.handleClick}
                        hidden={columnItem.hidden}
                      >
                        <Icon name="dropdown" />
                        {columnItem.title}
                      </Accordion.Title>
                      <Accordion.Content
                        active={activeIndex === columnItem.index}
                      >
                        <Form
                          style={columnItem.customStyles.DatePickerFormStyle}
                        >
                          <Form.Field>
                            <DatePicker
                              selected={form?.[columnItem.tokenIds[0]]}
                              onChange={date =>
                                this.handleChange(columnItem.tokenIds[0], date)
                              }
                              selectsStart
                              startDate={this.state[columnItem.tokenIds[0]]}
                              endDate={this.state[columnItem.tokenIds[1]]}
                              maxDate={form?.[columnItem.tokenIds[1]]}
                              dateFormat="MM/dd/yyyy"
                              placeholderText={
                                columnItem.placeholder.find(
                                  p => p.tokenId === columnItem.tokenIds[0]
                                ).value
                              }
                            />
                          </Form.Field>
                          <Form.Field>
                            <DatePicker
                              selected={form?.[columnItem.tokenIds[1]]}
                              onChange={date =>
                                this.handleChange(columnItem.tokenIds[1], date)
                              }
                              selectsEnd
                              startDate={this.state[columnItem.tokenIds[0]]}
                              endDate={this.state[columnItem.tokenIds[1]]}
                              minDate={form?.[columnItem.tokenIds[0]]}
                              dateFormat="MM/dd/yyyy"
                              placeholderText={
                                columnItem.placeholder.find(
                                  p => p.tokenId === columnItem.tokenIds[1]
                                ).value
                              }
                            />
                          </Form.Field>
                        </Form>
                      </Accordion.Content>
                    </>
                  )}
                  {columnItem.type === controlKind.checkbox && (
                    <>
                      <Accordion.Title
                        active={activeIndex === columnItem.index}
                        index={columnItem.index}
                        onClick={this.props.handleClick}
                        hidden={columnItem.hidden}
                      >
                        <Icon name="dropdown" />
                        {columnItem.title}
                      </Accordion.Title>
                      <Accordion.Content
                        active={activeIndex === columnItem.index}
                      >
                        {columnItem.tokenIds.map(tokenId => {
                          return (
                            <>
                              <Form.Field
                                control={Radio}
                                toggle
                                name={tokenId}
                                style={columnItem.customStyles.ToggleFieldStyle}
                                onChange={(e, properties) => {
                                  this.handleChange(
                                    tokenId,
                                    properties.checked
                                  );
                                  this.handleFilterChange(
                                    tokenId,
                                    properties.checked,
                                    true
                                  );
                                }}
                                checked={form?.tokenId}
                              />
                            </>
                          );
                        })}
                      </Accordion.Content>
                    </>
                  )}
                  {columnItem.type === controlKind.radio && (
                    <>
                      <Accordion.Title
                        active={activeIndex === columnItem.index}
                        index={columnItem.index}
                        onClick={this.props.handleClick}
                        hidden={columnItem.hidden}
                      >
                        <Icon name="dropdown" />
                        {columnItem.title}
                      </Accordion.Title>
                      <Accordion.Content
                        active={activeIndex === columnItem.index}
                      >
                        {columnItem.tokenIds.map(tokenId => {
                          return (
                            <>
                              <List>
                                {columnItem.source &&
                                  columnItem.source.map((item, idx) => {
                                    return (
                                      <List.Item key={idx}>
                                        <Grid>
                                          <Grid.Row>
                                            <Radio
                                              style={
                                                columnItem.customStyles
                                                  .RadioButtonFieldStyle
                                              }
                                              label={item.text}
                                              name={columnItem.radioButtonGroup}
                                              value={item.value}
                                              checked={
                                                item.value ===
                                                form?.labelOrderType
                                              }
                                              onClick={(e, properties) => {
                                                this.handleChange(
                                                  tokenId,
                                                  properties.value
                                                );
                                                this.handleFilterChange(
                                                  tokenId,
                                                  properties.value,
                                                  true
                                                );
                                              }}
                                            />
                                          </Grid.Row>
                                        </Grid>
                                      </List.Item>
                                    );
                                  })}
                              </List>
                            </>
                          );
                        })}
                      </Accordion.Content>
                    </>
                  )}
                </span>
              </>
            ))}
          </Accordion>
          <Divider />
          <Grid>
            <Grid.Column textAlign="right">
              <Button
                basic
                color="blue"
                content="Clear"
                onClick={this.handleClearFilter.bind(this, false)}
              ></Button>
              <Button
                basic
                color="green"
                content="Apply"
                onClick={this.handleApplyFilter}
              ></Button>
            </Grid.Column>
          </Grid>
        </div>
      </Sidebar>
    );
  }
}

const mapStateToProps = state => ({
  viewOrders: state.viewOrders,
  currentPage: state.viewOrders.currentPage,
  pageSize: state.viewOrders.pageSize,
  isClearFilterToggled: state.viewOrders.isClearFilterToggled,
  shared: state.user.common,
});

const mapDispatchToProps = dispatch => ({
  setUserFilter: data => dispatch(setUserFilter(data)),
  getOrders: (currentPage, pageSize, startDate, endDate) =>
    dispatch(getOrders(currentPage, pageSize, startDate, endDate)),
  getListFileNumbers: () => dispatch(getListFileNumbers()),
  getListApplicants: userId => dispatch(getListApplicants(userId)),
  getListManufacturers: userId => dispatch(getListManufacturers(userId)),
  getListOracleNumbers: userId => dispatch(getListOracleNumbers(userId)),
  getListRequestNumbers: userId => dispatch(getListRequestNumbers(userId)),
  getListLabelCenters: () => dispatch(getListLabelCenters()),
  getListApplicantAccountNos: userId =>
    dispatch(getListApplicantAccountNos(userId)),
  getListManufacturerAccountNos: userId =>
    dispatch(getListManufacturerAccountNos(userId)),
  updateForm: result => dispatch(updateForm(result)),
  clearForm: () => dispatch(clearForm()),
  getCustomerPoNumber: userId => dispatch(getCustomerPoNumber(userId)),
  getCustomerContactName: userId => dispatch(getCustomerContactName(userId)),
  getListApplicantAddress: () => dispatch(getListApplicantAddress()),
  getListManufacturerAddress: () => dispatch(getListManufacturerAddress()),
  getListManufacturerPsn: () => dispatch(getListManufacturerPsn()),
  resetClearFilter: () => dispatch(resetClearFilter()),
  setFilterOptionColumns: roleId =>
    dispatch(commonActions.setFilterOptionColumns(roleId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LabelFilterOption);
