import * as types from "./admin-page.type";
import api from "../../../services/api";

export const adminPageResult = (obj, type) => {
  return {
    type: type,
    data: obj,
  };
};

export const getUserPsns = (isGetUnread, labelCenter) => {
  return async (dispatch) => {
    dispatch(adminPageResult(null, types.GET_USER_PSN_REQUEST));
    await api.HelpMe.getUserPsns(isGetUnread, labelCenter)
      .then((result) => {
        dispatch(adminPageResult(result, types.GET_USER_PSN_SUCCESS));
      })
      .catch((result) => {
        dispatch(
          adminPageResult(
            result.response && result.response.data,
            types.GET_USER_PSN_FAILURE
          )
        );
      });
  };
};

export const getManufacturers = (isGetUnread, labelCenter) => {
  return async (dispatch) => {
    dispatch(adminPageResult(null, types.GET_MANUFACTURER_ACCOUNT_REQUEST));
    await api.HelpMe.getManufacturers(isGetUnread, labelCenter)
      .then((result) => {
        dispatch(
          adminPageResult(result, types.GET_MANUFACTURER_ACCOUNT_SUCCESS)
        );
      })
      .catch((result) => {
        dispatch(
          adminPageResult(
            result.response && result.response.data,
            types.GET_MANUFACTURER_ACCOUNT_FAILURE
          )
        );
      });
  };
};

export const getTotalRequests = (userId, labelCenter, isOutstandingRequest) => {
  return async (dispatch) => {
    dispatch(adminPageResult(null, types.GET_HELPME_TOTAL_REQUEST_REQUEST));
    await api.HelpMe.getTotalRequests(userId, labelCenter, isOutstandingRequest)
      .then((result) => {
        dispatch(
          adminPageResult(result, types.GET_HELPME_TOTAL_REQUEST_SUCCESS)
        );
      })
      .catch((result) => {
        dispatch(
          adminPageResult(
            result.response && result.response.data,
            types.GET_HELPME_TOTAL_REQUEST_FAILURE
          )
        );
      });
  };
};

export const updateForm = (result) => {
  return (dispatch) => dispatch(adminPageResult(result, types.ADMIN_PAGE_FORM));
};

export const clearForm = () => {
  return (dispatch) => dispatch(adminPageResult({}, types.ADMIN_PAGE_CLEAR));
};
