import React, { Component } from "react";
import PropTypes from "prop-types";
import { Table } from "semantic-ui-react";
import _ from "lodash";

class PriceItem extends Component {
  render() {
    const { item, idx, isQuotation } = this.props;
    let rowCount = _.keys(item.others).length + 1;

    return (
      <Table.Row>
        <Table.Cell rowSpan={rowCount}>{idx + 1}</Table.Cell>
        {/* TODO: Service, parent of service product */}
        <Table.Cell>In-service Apparatus</Table.Cell>
        <Table.Cell>
          {item.serviceProduct}
          {/* Aerial */}
          {item.areAerialsBrontoSchwingMetz ? " - Bronto, Schwing or Metz" : ""}
          {/* Ground Ladder */}
          {item.serviceProductCode == "GL" ? " - Qty (# of feet)" : ""}
          {/* Heat Sensor Label  */}
          {item.serviceProductCode == "HSL" ? " - Qty (ea)" : ""}
        </Table.Cell>
        <Table.Cell>{item.quantity}</Table.Cell>
        {isQuotation ? (
          <Table.Cell>USD {Number.format(item.total, 2)}</Table.Cell>
        ) : null}
      </Table.Row>
    );
  }
}

PriceItem.propTypes = {
  item: PropTypes.object.isRequired,
  idx: PropTypes.number.isRequired,
  isQuotation: PropTypes.bool.isRequired
};

export default PriceItem;
