import React, { Component } from "react";
import { Table, Label, Button, Input, Form } from "semantic-ui-react";
import { connect } from "react-redux";
import update from "immutability-helper";
import _ from "lodash";
import * as actions from "./product-details.action";

class ProductDetailsItem extends Component {
  onKeypressQuantity(idx, e) {
    e.target.value = e.target.value.replace(/[^0-9]/gi, "");
  }

  onChangeTruck(idx, i, e) {
    const { orders } = this.props.productDetails;

    update(orders[idx], {
      $merge: _.set(orders[idx].others, e.target.name, e.target.value)
    });

    this.props.updateOrder(idx);
  }

  render() {
    const { item, idx, onRemoveItem } = this.props;
    const { orders } = this.props.productDetails;

    return (
      <Table.Row>
        <Table.Cell>
          <Label ribbon>{item.productCategory}</Label>
        </Table.Cell>
        <Table.Cell>
          {item.serviceProduct}
          {/* Aerial */}
          {item.areAerialsBrontoSchwingMetz ? " - Bronto, Schwing or Metz" : ""}
          {/* Ground Ladder */}
          {item.serviceProductCode == "GL" ? " - Qty (# of feet)" : ""}
          {/* Heat Sensor Label  */}
          {item.serviceProductCode == "HSL" ? " - Qty (ea)" : ""}
        </Table.Cell>

        <Table.Cell>
          <Input
            min={item.productCategoryCode == "AIS" ? "1" : "0"}
            max={item.productCategoryCode == "AIS" ? "30" : "99999"}
            type="number"
            value={orders[idx].quantity || ""}
            onChange={e => this.props.onChangeQuantity(idx, e)}
            onKeyPress={this.onKeypressQuantity.bind(this, idx)}
          />
        </Table.Cell>
        {/* Improve this in the future when product will have different input */}
        {item.serviceProductCode == "GL" || item.serviceProductCode == "HSL" ? (
          <Table.Cell>{/* No Display */}</Table.Cell>
        ) : (
          <Table.Cell>
            {orders[idx].quantity > 0
              ? Array.apply(null, { length: orders[idx].quantity }).map(
                  (val, i) => {
                    const keyItem = Object.keys(orders[idx].others)[i];

                    return (
                      <Form.Field key={i}>
                        <Form.Input
                          type="text"
                          label="Truck #"
                          placeholder="Truck #"
                          name={`truck${i}`}
                          autoComplete="off"
                          onChange={this.onChangeTruck.bind(this, idx, i)}
                          value={
                            orders[idx].others
                              ? orders[idx].others[keyItem] || ""
                              : ""
                          }
                        ></Form.Input>
                      </Form.Field>
                    );
                  }
                )
              : null}
          </Table.Cell>
        )}

        <Table.Cell>
          <Button
            size="mini"
            circular
            color="red"
            icon="delete"
            onClick={onRemoveItem.bind(this, idx)}
          />
        </Table.Cell>
      </Table.Row>
    );
  }
}

const mapStateToProps = state => {
  return {
    productDetails: state.fesProductDetails
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateOrder: idx => dispatch(actions.updateOrder(idx))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetailsItem);
