import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import React, { Component, Fragment } from "react";
import { Container, Form, Grid, Segment, Button } from "semantic-ui-react";
import Role from "../../../enums/role";
import { Common } from "../../../common/common";
import HelpMessage from "../common/help-message";
import ToastHelper from "../../../helpers/toast.helper";
import CartOrderDropdown from "../common/cart-order-dropdown";
import * as actions from "../help-me.action";

class CustomerHelpMe extends Component {
  state = { isGetCartOrders: false, isCart: false };

  componentDidMount() {
    let showCart = this.props.match.params.cart;
    if (showCart != undefined) {
      const { isCart } = this.state;

      let userId = Common.getUserId();
      let { form } = this.props.helpMe;
      form.cartOrderNo = "cart";
      this.props.updateForm(form);

      this.props.getCartMessages(userId).then((s) => {
        if (s == null || s == "")
          setTimeout(() => {
            ToastHelper.error({
              title: "Error",
              description: "You don't have item on your cart yet"
            });
          }, 100);

        if ((s || {}).isSuccessful) {
          if (!isCart) this.setState({ isCart: !isCart });

          if (
            s.data.helpMes.filter(
              (a) => a.isViewed != true && a.createdById != userId
            ).length
          ) {
            this.props.updateIsViewedMessage({
              cartId: s.data.id
            });
          }
        }
      });
      return false;
    }
  }

  handleSetIsGetCartOrders = () => {
    this.setState({ isGetCartOrders: true });
  };

  handleSearch = () => {
    const { form } = this.props.helpMe;
    const { isCart } = this.state;
    let userId = Common.getUserId();

    if (form.cartOrderNo == "cart") {
      this.props.getCartMessages(userId).then((s) => {
        if (s == null || s == "")
          setTimeout(() => {
            ToastHelper.error({
              title: "Error",
              description: "You don't have item on your cart yet"
            });
          }, 100);

        if (s.isSuccessful) {
          if (!isCart) this.setState({ isCart: !isCart });

          if (
            s.data.helpMes.filter(
              (a) => a.isViewed != true && a.createdById != userId
            ).length
          ) {
            this.props.updateIsViewedMessage({
              cartId: s.data.id
            });
          }
        }
      });
      return false;
    }

    this.props.history.push(`/home/label-order-details/${form.cartOrderNo}/1`);
  };

  handleGetCartMessages = (e) => {
    let userId = Common.getUserId();
    const { form } = this.props.helpMe;
    const { isCart } = this.state;

    if (form.cartOrderNo == "cart") {
      this.props.getCartMessages(userId).then((s) => {
        if (s == null || s == "")
          setTimeout(() => {
            ToastHelper.error({
              title: "Error",
              description: "You don't have item on your cart yet"
            });
          }, 100);

        if (s.isSuccessful) {
          if (!isCart) this.setState({ isCart: !isCart });

          if (
            s.data.helpMes.filter(
              (a) => a.isViewed != true && a.createdById != userId
            ).length
          ) {
            this.props.updateIsViewedMessage({
              cartId: s.data.id
            });
          }
        }
      });
      return false;
    }
  };

  render() {
    const { isGetCartOrders, isCart } = this.state;
    const { data } = this.props.helpMe;
    const translations = Common.getTranslations() || {};

    if (Common.getUserRole() == Role.labelCenter) {
      return <Redirect to="/home/reply-to-help" />;
    }

    return (
      <Fragment>
        <div className="container-wrapper">
          <Container fluid>
            {/* <Grid>
              <Grid.Row>
                <Grid.Column width={7}>
                  <CustomHeader message="Help Me" />
                </Grid.Column>
              </Grid.Row>
            </Grid> */}
            <Segment>
              <Grid divided="vertically">
                <Grid.Row columns={2}>
                  <Grid.Column>
                    <Form>
                      <CartOrderDropdown
                        isGetCartOrders={isGetCartOrders}
                        userId={parseInt(Common.getUserId())}
                        handleSetIsGetCartOrders={this.handleSetIsGetCartOrders}
                      />
                      <Button
                        primary
                        floated="right"
                        onClick={this.handleSearch.bind(this)}
                      >
                        {translations["LexView"]}
                      </Button>
                    </Form>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              {isCart ? (
                <Grid>
                  <Grid.Row columns={2}>
                    <Grid.Column>
                      <Form>
                        <HelpMessage
                          data={data.data.helpMe}
                          orderDetails={data.data}
                          getLabelOrder={this.handleGetCartMessages}
                        />
                      </Form>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              ) : null}
            </Segment>
          </Container>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    helpMe: state.helpMe
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCartMessages: (userId) => dispatch(actions.getCartMessages(userId)),
    updateForm: (result) => dispatch(actions.updateForm(result)),
    updateIsViewedMessage: (data) =>
      dispatch(actions.updateIsViewedMessage(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomerHelpMe);
