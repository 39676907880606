export const COMBINATION_LABEL_SEARCH_REQUEST =
  'COMBINATION_LABEL_SEARCH_REQUEST';
export const COMBINATION_LABEL_SEARCH_FAILURE =
  'COMBINATION_LABEL_SEARCH_FAILURE';
export const COMBINATION_LABEL_SEARCH_SUCCESS =
  'COMBINATION_LABEL_SEARCH_SUCCESS';

export const UPDATE_COMBINATION_LABEL_SEARCH_FORM =
  'UPDATE_COMBINATION_LABEL_SEARCH_FORM';

export const GET_COMBINATION_LABELS_BY_FILTER_REQUEST =
  'GET_COMBINATION_LABELS_BY_FILTER_REQUEST';
export const GET_COMBINATION_LABELS_BY_FILTER_SUCCESS =
  'GET_COMBINATION_LABELS_BY_FILTER_SUCCESS';
export const GET_COMBINATION_LABELS_BY_FILTER_FAILURE =
  'GET_COMBINATION_LABELS_BY_FILTER_FAILURE';

export const COMBINATION_LABEL_SEARCH_FOR_EXPORT_REQUEST =
  'COMBINATION_LABEL_SEARCH_FOR_EXPORT_REQUEST';
export const COMBINATION_LABEL_SEARCH_FOR_EXPORT_FAILURE =
  'COMBINATION_LABEL_SEARCH_FOR_EXPORT_FAILURE';
export const COMBINATION_LABEL_SEARCH_FOR_EXPORT_SUCCESS =
  'COMBINATION_LABEL_SEARCH_FOR_EXPORT_SUCCESS';

export const COMBINATION_LABEL_SEARCH_RESET = 'COMBINATION_LABEL_SEARCH_RESET';
export const UPDATE_FILE_NUMBER_LIST = 'UPDATE_FILE_NUMBER_LIST';
