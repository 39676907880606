import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Container,
  Segment,
  Table,
  Grid,
  Pagination,
  Message,
  Dropdown
} from "semantic-ui-react";
import _ from "lodash";

import { CustomHeader } from "../../../components/labels/labels";
import * as actions from "./cancel-order.action";
import CancelOrderItem from "./cancel-order-item";

class CancelOrder extends Component {
  componentDidMount() {
    this.props.getRequestToCancelOrders(
      this.props.cancelOrder.data.currentPage,
      this.props.cancelOrder.data.pageSize
    );
  }

  onCancelOrder(item) {
    const { data } = this.props.cancelOrder;
    const model = { orderId: item.id };
    const idx = _.findIndex(data.data, { id: item.id });

    if (idx < 0) return;

    data.data[idx].isDisable = true;
    this.props.doCancelOrderRequesting(data.data);

    // this.props.doCancelOrderRequesting(data.data)
    //   .then(s => console.log(s));

    this.props.doCancelOrder(model).then(s => {
      if (!s) {
        data.data[idx].isDisable = false;

        return this.props.doCancelOrderRequesting(data.data);
      }

      data.data[idx].statusName = s.message;
      this.props.doCancelOrderRequesting(data.data);
      this.props.notificationCount();
    });
  }

  render() {
    const { data, cancelProcessResult } = this.props.cancelOrder;

    return (
      <div className="container-wrapper">
        <Container fluid>
          <Grid>
            <Grid.Row>
              <Grid.Column width={7}>
                <CustomHeader message="Cancel Order" />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <Dropdown
                  text="Filter Type"
                  floating
                  labeled
                  button
                  icon="filter"
                  className="icon primary"
                >
                  <Dropdown.Menu>
                    <Dropdown.Header icon="tags" content="Filter by type" />
                    <Dropdown.Divider />
                    <Dropdown.Item
                      text="Quote"
                      onClick={(e, {}) => {
                        //console.log("1");
                      }}
                    />
                    <Dropdown.Item
                      text="Order"
                      onClick={(e, {}) => {
                        //console.log("2");
                      }}
                    />
                  </Dropdown.Menu>
                </Dropdown>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Segment>
            <Table striped selectable compact>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Type</Table.HeaderCell>
                  <Table.HeaderCell>Status</Table.HeaderCell>
                  <Table.HeaderCell>Order Number</Table.HeaderCell>
                  <Table.HeaderCell>Order Date</Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {data.data &&
                  data.data.map((d, idx) => (
                    <CancelOrderItem
                      key={idx}
                      data={d}
                      cancelProcessResult={cancelProcessResult}
                      onCancelling={this.onCancelOrder.bind(this)}
                    />
                  ))}
              </Table.Body>
            </Table>
            {data && data.totalPages > 0 ? (
              <Pagination
                activePage={data.currentPage}
                totalPages={data.totalPages}
                onPageChange={(e, { activePage }) => {
                  this.props.getRequestToCancelOrders(
                    activePage,
                    data.pageSize
                  );
                }}
              />
            ) : (
              <Message>No Order Found</Message>
            )}
          </Segment>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  cancelOrder: state.feCancelOrder
});

const mapDispatchToProps = dispatch => {
  return {
    getRequestToCancelOrders: (currentPage, pageSize) =>
      dispatch(actions.getRequestToCancelOrders(currentPage, pageSize)),
    doCancelOrder: param => dispatch(actions.doCancelOrder(param)),
    doCancelOrderRequesting: param =>
      dispatch(actions.doCancelOrderRequesting(param)),
    clear: () => dispatch(actions.clearCancelOrder()),
    notificationCount: () => dispatch(actions.notificationCount())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CancelOrder);
