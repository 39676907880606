import {
  LABEL_ORDER_CART_CHANGE_VALUE,
  LABEL_ORDER_CART_CONFIRM_SAVE_ORDER_REQUEST,
  LABEL_ORDER_CART_CONFIRM_SAVE_ORDER_ACCEPT,
  LABEL_ORDER_CART_CONFIRM_SAVE_ORDER_REJECT,
  LABEL_ORDER_CART_GET_CUSTOMER_BILL_TO_ADDRESSES_REQUEST,
  LABEL_ORDER_CART_GET_CUSTOMER_BILL_TO_ADDRESSES_SUCCESS,
  LABEL_ORDER_CART_GET_CUSTOMER_BILL_TO_ADDRESSES_FAILURE,
  LABEL_ORDER_CART_GET_CUSTOMER_INFO_REQUEST,
  LABEL_ORDER_CART_GET_CUSTOMER_INFO_SUCCESS,
  LABEL_ORDER_CART_GET_CUSTOMER_INFO_FAILURE,
  LABEL_ORDER_CART_GET_CUSTOMER_SHIP_TO_ADDRESSES_REQUEST,
  LABEL_ORDER_CART_GET_CUSTOMER_SHIP_TO_ADDRESSES_SUCCESS,
  LABEL_ORDER_CART_GET_CUSTOMER_SHIP_TO_ADDRESSES_FAILURE,
  LABEL_ORDER_CART_GET_LABEL_CENTERS_REQUEST,
  LABEL_ORDER_CART_GET_LABEL_CENTERS_SUCCESS,
  LABEL_ORDER_CART_GET_LABEL_CENTERS_FAILURE,
  LABEL_ORDER_CART_GET_LABEL_ORDER_CART_REQUEST,
  LABEL_ORDER_CART_GET_LABEL_ORDER_CART_SUCCESS,
  LABEL_ORDER_CART_GET_LABEL_ORDER_CART_FAILURE,
  LABEL_ORDER_CART_GET_LABEL_SUPPLIERS_REQUEST,
  LABEL_ORDER_CART_GET_LABEL_SUPPLIERS_SUCCESS,
  LABEL_ORDER_CART_GET_LABEL_SUPPLIERS_FAILURE,
  LABEL_ORDER_CART_GET_UNITS_OF_MEASUREMENT_REQUEST,
  LABEL_ORDER_CART_GET_UNITS_OF_MEASUREMENT_SUCCESS,
  LABEL_ORDER_CART_GET_UNITS_OF_MEASUREMENT_FAILURE,
  LABEL_ORDER_CART_INITIALIZE,
  LABEL_ORDER_CART_ITEM_CHANGE_VALUE,
  LABEL_ORDER_CART_REMOVE_ITEM_REQUEST,
  LABEL_ORDER_CART_REMOVE_ITEM_SUCCESS,
  LABEL_ORDER_CART_REMOVE_ITEM_FAILURE,
  LABEL_ORDER_CART_REMOVE_REMOVE_ITEM_MESSAGE,
  LABEL_ORDER_CART_REMOVE_SAVE_ORDER_MESSAGE,
  LABEL_ORDER_CART_UPDATE_REQUEST,
  LABEL_ORDER_CART_UPDATE_SUCCESS,
  LABEL_ORDER_CART_UPDATE_FAILURE,
  LABEL_ORDER_CART_UPDATE_RESET,
  LABEL_ORDER_CART_ITEM_UPLOAD_ATTACHMENT_REQUEST,
  LABEL_ORDER_CART_ITEM_UPLOAD_ATTACHMENT_FAILURE,
  LABEL_ORDER_CART_ITEM_UPLOAD_ATTACHMENT_SUCCESS,
  FORM
} from "./label-order-cart.type";
import api from "../../../services/api";

export const changeOrderCartItemValue = (id, name, value) => {
  return {
    type: LABEL_ORDER_CART_ITEM_CHANGE_VALUE,
    payload: { id, value, name }
  };
};

export const changeOrderCartValue = (name, value) => {
  return {
    type: LABEL_ORDER_CART_CHANGE_VALUE,
    payload: { value, name }
  };
};

export const confirmSaveOrderRequest = () => {
  return { type: LABEL_ORDER_CART_CONFIRM_SAVE_ORDER_REQUEST };
};

export const confirmSaveOrderAccept = () => {
  return { type: LABEL_ORDER_CART_CONFIRM_SAVE_ORDER_ACCEPT };
};

export const confirmSaveOrderReject = () => {
  return { type: LABEL_ORDER_CART_CONFIRM_SAVE_ORDER_REJECT };
};

const getCustomerBillToAddressesRequest = () => {
  return { type: LABEL_ORDER_CART_GET_CUSTOMER_BILL_TO_ADDRESSES_REQUEST };
};

const getCustomerBillToAddressesFailure = (error) => {
  return (dispatch) =>
    dispatch({
      type: LABEL_ORDER_CART_GET_CUSTOMER_BILL_TO_ADDRESSES_FAILURE,
      payload: error
    });
};

const getCustomerBillToAddressesSuccess = (response) => {
  return (dispatch) =>
    dispatch({
      type: LABEL_ORDER_CART_GET_CUSTOMER_BILL_TO_ADDRESSES_SUCCESS,
      payload: response
    });
};

export const getCustomerBillToAddresses = (customerId) => {
  return async (dispatch) => {
    dispatch(getCustomerBillToAddressesRequest());
    const result = await api.Customer.getBillToAddresses(customerId);
    if (result) {
      dispatch(getCustomerBillToAddressesSuccess(result));
    } else {
      dispatch(getCustomerBillToAddressesFailure("Something went wrong."));
    }
  };
};

const getCustomerInfoRequest = () => {
  return { type: LABEL_ORDER_CART_GET_CUSTOMER_INFO_REQUEST };
};

const getCustomerInfoFailure = (error) => {
  return (dispatch) =>
    dispatch({
      type: LABEL_ORDER_CART_GET_CUSTOMER_INFO_FAILURE,
      payload: error
    });
};

const getCustomerInfoSuccess = (response) => {
  return (dispatch) =>
    dispatch({
      type: LABEL_ORDER_CART_GET_CUSTOMER_INFO_SUCCESS,
      payload: response
    });
};

export const getCustomerInfo = (customerId) => {
  return async (dispatch) => {
    dispatch(getCustomerInfoRequest());
    const result = await api.Customer.getInfo(customerId);
    if (result) {
      dispatch(getCustomerInfoSuccess(result));
    } else {
      dispatch(getCustomerInfoFailure("Something went wrong."));
    }
  };
};

const getCustomerShipToAddressesRequest = () => {
  return { type: LABEL_ORDER_CART_GET_CUSTOMER_SHIP_TO_ADDRESSES_REQUEST };
};

const getCustomerShipToAddressesFailure = (error) => {
  return (dispatch) =>
    dispatch({
      type: LABEL_ORDER_CART_GET_CUSTOMER_SHIP_TO_ADDRESSES_FAILURE,
      payload: error
    });
};

const getCustomerShipToAddressesSuccess = (response) => {
  return (dispatch) =>
    dispatch({
      type: LABEL_ORDER_CART_GET_CUSTOMER_SHIP_TO_ADDRESSES_SUCCESS,
      payload: response
    });
};

export const getCustomerShipToAddresses = (customerId) => {
  return async (dispatch) => {
    dispatch(getCustomerShipToAddressesRequest());
    const result = await api.Customer.getShipToAddresses(customerId);
    if (result) {
      dispatch(getCustomerShipToAddressesSuccess(result));
    } else {
      dispatch(getCustomerShipToAddressesFailure("Something went wrong."));
    }
  };
};

const getLabelCentersRequest = () => {
  return { type: LABEL_ORDER_CART_GET_LABEL_CENTERS_REQUEST };
};

const getLabelCentersFailure = (error) => {
  return (dispatch) =>
    dispatch({
      type: LABEL_ORDER_CART_GET_LABEL_CENTERS_FAILURE,
      payload: error
    });
};

const getLabelCentersSuccess = (response) => {
  return (dispatch) =>
    dispatch({
      type: LABEL_ORDER_CART_GET_LABEL_CENTERS_SUCCESS,
      payload: response
    });
};

export const getLabelCenters = () => {
  return async (dispatch) => {
    dispatch(getLabelCentersRequest());
    const result = await api.LabelCenter.getLabelCenters();
    if (result) {
      dispatch(getLabelCentersSuccess(result));
    } else {
      dispatch(getLabelCentersFailure("Something went wrong."));
    }
  };
};

const getLabelOrderCartRequest = () => {
  return { type: LABEL_ORDER_CART_GET_LABEL_ORDER_CART_REQUEST };
};

const getLabelOrderCartFailure = (error) => {
  return (dispatch) =>
    dispatch({
      type: LABEL_ORDER_CART_GET_LABEL_ORDER_CART_FAILURE,
      payload: error
    });
};

const getLabelOrderCartSuccess = (response) => {
  return (dispatch) =>
    dispatch({
      type: LABEL_ORDER_CART_GET_LABEL_ORDER_CART_SUCCESS,
      payload: response
    });
};

export const getLabelOrderCart = () => {
  return async (dispatch) => {
    dispatch(getLabelOrderCartRequest());
    const result = await api.LabelOrderCart.getLabelOrderCart();

    if (result) {
      localStorage.setItem("cartLabelOrderType", result.labelOrderType);
      dispatch(getLabelOrderCartSuccess(result));
    } else {
      localStorage.setItem("cartLabelOrderType", null);
      dispatch(getLabelOrderCartFailure("Something went wrong."));
    }
  };
};

const getLabelSuppliersRequest = () => {
  return { type: LABEL_ORDER_CART_GET_LABEL_SUPPLIERS_REQUEST };
};

const getLabelSuppliersFailure = (error) => {
  return (dispatch) =>
    dispatch({
      type: LABEL_ORDER_CART_GET_LABEL_SUPPLIERS_FAILURE,
      payload: error
    });
};

const getLabelSuppliersSuccess = (response) => {
  return (dispatch) =>
    dispatch({
      type: LABEL_ORDER_CART_GET_LABEL_SUPPLIERS_SUCCESS,
      payload: response
    });
};

export const getLabelSuppliers = (supplierDetails, currentPage, pageSize) => {
  return async (dispatch) => {
    dispatch(getLabelSuppliersRequest());
    const result = await api.LabelSupplier.getLabelSuppliers(
      supplierDetails,
      currentPage,
      pageSize
    );
    if (result) {
      dispatch(getLabelSuppliersSuccess(result));
    } else {
      dispatch(getLabelSuppliersFailure("Something went wrong."));
    }
  };
};

const getUnitsOfMeasurementRequest = () => {
  return { type: LABEL_ORDER_CART_GET_UNITS_OF_MEASUREMENT_REQUEST };
};

const getUnitsOfMeasurementFailure = (error) => {
  return (dispatch) =>
    dispatch({
      type: LABEL_ORDER_CART_GET_UNITS_OF_MEASUREMENT_FAILURE,
      payload: error
    });
};

const getUnitsOfMeasurementSuccess = (response) => {
  return (dispatch) =>
    dispatch({
      type: LABEL_ORDER_CART_GET_UNITS_OF_MEASUREMENT_SUCCESS,
      payload: response
    });
};

export const getUnitsOfMeasurement = () => {
  return async (dispatch) => {
    dispatch(getUnitsOfMeasurementRequest());
    const result = await api.UnitOfMeasurement.getUnitsOfMeasurement();
    if (result) {
      dispatch(getUnitsOfMeasurementSuccess(result));
    } else {
      dispatch(getUnitsOfMeasurementFailure("Something went wrong."));
    }
  };
};

export const initialize = () => {
  return { type: LABEL_ORDER_CART_INITIALIZE };
};

const removeLabelOrderCartItemRequest = () => {
  return { type: LABEL_ORDER_CART_REMOVE_ITEM_REQUEST };
};

const removeLabelOrderCartItemFailure = (error) => {
  return (dispatch) =>
    dispatch({
      type: LABEL_ORDER_CART_REMOVE_ITEM_FAILURE,
      payload: error
    });
};

const removeLabelOrderCartItemSuccess = (id) => {
  return (dispatch) =>
    dispatch({
      type: LABEL_ORDER_CART_REMOVE_ITEM_SUCCESS,
      payload: { id }
    });
};

export const removeLabelOrderCartItem = (id) => {
  return async (dispatch) => {
    dispatch(removeLabelOrderCartItemRequest());
    const result = await api.LabelOrderCart.removeLabelOrderCartItem(id);
    if (result) {
      dispatch(removeLabelOrderCartItemSuccess(id));
    } else {
      dispatch(removeLabelOrderCartItemFailure("Something went wrong."));
    }
  };
};

export const removeRemoveItemMessage = () => {
  return { type: LABEL_ORDER_CART_REMOVE_REMOVE_ITEM_MESSAGE };
};

export const removeSaveOrderMessage = () => {
  return { type: LABEL_ORDER_CART_REMOVE_SAVE_ORDER_MESSAGE };
};

const updateLabelOrderCartRequest = () => {
  return { type: LABEL_ORDER_CART_UPDATE_REQUEST };
};

const updateLabelOrderCartFailure = (error) => {
  return (dispatch) =>
    dispatch({
      type: LABEL_ORDER_CART_UPDATE_FAILURE,
      payload: error
    });
};

const updateLabelOrderCartSuccess = (response) => {
  return (dispatch) =>
    dispatch({
      type: LABEL_ORDER_CART_UPDATE_SUCCESS,
      payload: response
    });
};

const updateLabelOrderCartReset = () => {
  return { type: LABEL_ORDER_CART_UPDATE_RESET };
};

const uploadCartItemAttachmentRequest = () => {
  return { type: LABEL_ORDER_CART_ITEM_UPLOAD_ATTACHMENT_REQUEST };
};

const uploadCartItemAttachmentFailure = (error) => {
  return (dispatch) =>
    dispatch({
      type: LABEL_ORDER_CART_ITEM_UPLOAD_ATTACHMENT_FAILURE,
      payload: error
    });
};

const uploadCartItemAttachmentSuccess = (response) => {
  return (dispatch) =>
    dispatch({
      type: LABEL_ORDER_CART_ITEM_UPLOAD_ATTACHMENT_SUCCESS,
      payload: response
    });
};

export const updateLabelOrderCart = (data) => {
  return async (dispatch) => {
    dispatch(updateLabelOrderCartRequest());
    const result = await api.LabelOrderCart.updateLabelOrderCart(data);
    if (result) {
      dispatch(updateLabelOrderCartSuccess(result));
    } else {
      dispatch(updateLabelOrderCartFailure("Something went wrong."));
    }
  };
};

export const resetLabelOrderCart = () => {
  return async (dispatch) => {
    dispatch(updateLabelOrderCartReset());
  };
};

export const uploadCartItemAttachment = (formData) => {
  return async (dispatch) => {
    dispatch(uploadCartItemAttachmentRequest());
    const result = await api.LabelOrderCartItem.uploadAttachment(formData);
    if (result) {
      dispatch(uploadCartItemAttachmentSuccess(result));
    } else {
      dispatch(uploadCartItemAttachmentFailure("Something went wrong."));
    }
  };
};

export const updateForm = (result) => {
  return (dispatch) =>
    dispatch({
      type: FORM,
      payload: result
    });
};
