import * as types from "./view-history.types";
import api from "../../../services/api";

export const viewHistoryResult = (obj, type) => {
  return {
    type: type,
    data: obj
  };
};

export const getFEOrders = (currentPage, pageSize) => {
  return async (dispatch) => {
    dispatch(viewHistoryResult(null, types.FE_ORDER_HISTORY_REQUEST));
    await api.FieldEvaluation.getOrders(currentPage, pageSize)
      .then((result) => {
        dispatch(viewHistoryResult(result, types.FE_ORDER_HISTORY_SUCCESS));
      })
      .catch((result) => {
        dispatch(
          viewHistoryResult(
            result.response && result.response.data,
            types.FE_ORDER_HISTORY_FAILURE
          )
        );
      });
  };
};

export const getAllFEOrders = (currentPage, pageSize, isMyWork) => {
  return async (dispatch) => {
    dispatch(viewHistoryResult(null, types.FE_ORDER_HISTORY_REQUEST));
    await api.FieldEvaluation.getAllOrders(currentPage, pageSize, isMyWork)
      .then((result) => {
        dispatch(viewHistoryResult(result, types.FE_ORDER_HISTORY_SUCCESS));
      })
      .catch((result) => {
        dispatch(
          viewHistoryResult(
            result.response && result.response.data,
            types.FE_ORDER_HISTORY_FAILURE
          )
        );
      });
  };
};

export const getFEOrdersByFilter = (
  status,
  currentPage,
  pageSize,
  isMyWork
) => {
  return async (dispatch) => {
    dispatch(viewHistoryResult(null, types.FE_ORDER_HISTORY_BY_FILTER_REQUEST));

    status.isMyWork = isMyWork;

    await api.FieldEvaluation.getOrdersByFilter(status)
      .then((result) => {
        dispatch(
          viewHistoryResult(result, types.FE_ORDER_HISTORY_BY_FILTER_SUCCESS)
        );
      })
      .catch((result) => {
        dispatch(
          viewHistoryResult(
            result.response && result.response.data,
            types.FE_ORDER_HISTORY_BY_FILTER_FAILURE
          )
        );
      });
  };
};

export const getFEOrdersByFilterByUser = (status, currentPage, pageSize) => {
  return async (dispatch) => {
    dispatch(
      viewHistoryResult(null, types.FE_ORDER_HISTORY_BY_FILTER_BY_USER_REQUEST)
    );
    await api.FieldEvaluation.getOrdersByFilterByUser(
      status,
      currentPage,
      pageSize
    )
      .then((result) => {
        dispatch(
          viewHistoryResult(
            result,
            types.FE_ORDER_HISTORY_BY_FILTER_BY_USER_SUCCESS
          )
        );
      })
      .catch((result) => {
        dispatch(
          viewHistoryResult(
            result.response && result.response.data,
            types.FE_ORDER_HISTORY_BY_FILTER_BY_USER_FAILURE
          )
        );
      });
  };
};

export const changePageFEOrders = (activePage, pageSize) => {
  return async (dispatch) => {
    dispatch(
      viewHistoryResult({ activePage }, types.FE_ORDER_CHANGE_PAGE_REQUEST)
    );
    await api.FieldEvaluation.getOrders(activePage, pageSize)
      .then((result) => {
        dispatch(viewHistoryResult(result, types.FE_ORDER_CHANGE_PAGE_SUCCESS));
      })
      .catch((result) => {
        dispatch(
          viewHistoryResult(
            result.response && result.response.data,
            types.FE_ORDER_CHANGE_PAGE_FAILURE
          )
        );
      });
  };
};

export const getProjectHandlers = () => {
  return async (dispatch) => {
    dispatch(viewHistoryResult({}, types.FE_ORDER_GET_PROJECTHANDLER_REQUEST));
    await api.FieldEvaluation.getProjectHandlers()
      .then((result) => {
        dispatch(
          viewHistoryResult(result, types.FE_ORDER_GET_PROJECTHANDLER_SUCCESS)
        );
      })
      .catch((result) => {
        dispatch(
          viewHistoryResult(
            result.response && result.response.data,
            types.FE_ORDER_GET_PROJECTHANDLER_FAILURE
          )
        );
      });
  };
};

export const assignProjectHandlers = (data) => {
  return async (dispatch) => {
    dispatch(
      viewHistoryResult({}, types.FE_ORDER_ASSIGN_PROJECTHANDLER_REQUEST)
    );
    await api.FieldEvaluation.assignProjectHandlers(data)
      .then((result) => {
        dispatch(
          viewHistoryResult(
            result,
            types.FE_ORDER_ASSIGN_PROJECTHANDLER_SUCCESS
          )
        );
      })
      .catch((result) => {
        dispatch(
          viewHistoryResult(
            result.response && result.response.data,
            types.FE_ORDER_ASSIGN_PROJECTHANDLER_FAILURE
          )
        );
      });
  };
};

export const unAssignProjectHandlers = (data) => {
  return async (dispatch) => {
    dispatch(
      viewHistoryResult({}, types.FE_ORDER_UNASSIGN_PROJECTHANDLER_REQUEST)
    );
    await api.FieldEvaluation.unAssignProjectHandlers(data)
      .then((result) => {
        dispatch(
          viewHistoryResult(
            result,
            types.FE_ORDER_UNASSIGN_PROJECTHANDLER_SUCCESS
          )
        );
      })
      .catch((result) => {
        dispatch(
          viewHistoryResult(
            result.response && result.response.data,
            types.FE_ORDER_UNASSIGN_PROJECTHANDLER_FAILURE
          )
        );
      });
  };
};

export const setFilterOptions = (data) => {
  return async (dispatch) => {
    dispatch(viewHistoryResult({}, types.SET_FILTEROPTION_REQUEST));
    await api.FieldEvaluation.setFilterOptions(data)
      .then((result) => {
        dispatch(viewHistoryResult(result, types.SET_FILTEROPTION_SUCCESS));
      })
      .catch((result) => {
        dispatch(
          viewHistoryResult(
            result.response && result.response.data,
            types.SET_FILTEROPTION_FAILURE
          )
        );
      });
  };
};

export const getFilterOptions = () => {
  return async (dispatch) => {
    dispatch(viewHistoryResult({}, types.GET_FILTEROPTION_REQUEST));
    await api.FieldEvaluation.getFilterOptions()
      .then((result) => {
        dispatch(viewHistoryResult(result, types.GET_FILTEROPTION_SUCCESS));
      })
      .catch((result) => {
        dispatch(
          viewHistoryResult(
            result.response && result.response.data,
            types.GET_FILTEROPTION_FAILURE
          )
        );
      });
  };
};

export const changePageAllFEOrders = (activePage, pageSize, isMyWork) => {
  return async (dispatch) => {
    dispatch(
      viewHistoryResult({ activePage }, types.FE_ORDER_CHANGE_PAGE_REQUEST)
    );
    await api.FieldEvaluation.getAllOrders(activePage, pageSize, isMyWork)
      .then((result) => {
        dispatch(viewHistoryResult(result, types.FE_ORDER_CHANGE_PAGE_SUCCESS));
      })
      .catch((result) => {
        dispatch(
          viewHistoryResult(
            result.response && result.response.data,
            types.FE_ORDER_CHANGE_PAGE_FAILURE
          )
        );
      });
  };
};

export const updateForm = (result) => {
  return (dispatch) =>
    dispatch(viewHistoryResult(result, types.VIEW_HISTORY_FORM));
};

export const updateData = (result) => {
  return (dispatch) => dispatch(viewHistoryResult(result, types.DATA));
};

export const updateOrderType = (data) => {
  return async (dispatch) => {
    dispatch(viewHistoryResult(null, types.FE_UPDATE_ORDER_TYPE_REQUEST));
    return await api.FieldEvaluation.updateOrderType(data)
      .then((result) => {
        dispatch(viewHistoryResult(result, types.FE_UPDATE_ORDER_TYPE_SUCCESS));
        return result;
      })
      .catch((result) => {
        dispatch(
          viewHistoryResult(
            result.response && result.response.data,
            types.FE_UPDATE_ORDER_TYPE_FAILURE
          )
        );
      });
  };
};

export const getApplicants = () => {
  return async (dispatch) => {
    dispatch(viewHistoryResult({}, types.FE_ORDER_GET_APPLICANT_REQUEST));
    await api.FieldEvaluation.getApplicants()
      .then((result) => {
        dispatch(
          viewHistoryResult(result, types.FE_ORDER_GET_APPLICANT_SUCCESS)
        );
      })
      .catch((result) => {
        dispatch(
          viewHistoryResult(
            result.response && result.response.data,
            types.FE_ORDER_GET_APPLICANT_FAILURE
          )
        );
      });
  };
};

export const getStates = () => {
  return async (dispatch) => {
    dispatch(viewHistoryResult({}, types.FE_ORDER_GET_STATES_REQUEST));
    await api.FieldEvaluation.getStatesFilter()
      .then((result) => {
        dispatch(viewHistoryResult(result, types.FE_ORDER_GET_STATES_SUCCESS));
      })
      .catch((result) => {
        dispatch(
          viewHistoryResult(
            result.response && result.response.data,
            types.FE_ORDER_GET_STATES_FAILURE
          )
        );
      });
  };
};

export const getCities = () => {
  return async (dispatch) => {
    dispatch(viewHistoryResult({}, types.FE_ORDER_GET_CITIES_REQUEST));
    await api.FieldEvaluation.getCitiesFilter()
      .then((result) => {
        dispatch(viewHistoryResult(result, types.FE_ORDER_GET_CITIES_SUCCESS));
      })
      .catch((result) => {
        dispatch(
          viewHistoryResult(
            result.response && result.response.data,
            types.FE_ORDER_GET_CITIES_FAILURE
          )
        );
      });
  };
};

export const updateOrderTypeReset = (result) => {
  return (dispatch) =>
    dispatch(viewHistoryResult(result, types.FE_UPDATE_ORDER_TYPE_RESET));
};

export const assignProjectHandlerReset = (result) => {
  return (dispatch) =>
    dispatch(
      viewHistoryResult(result, types.FE_ORDER_ASSIGN_PROJECTHANDLER_RESET)
    );
};

export const filterOptionReset = (result) => {
  return (dispatch) =>
    dispatch(viewHistoryResult(result, types.FILTEROPTION_RESET));
};

export const clearVHForm = () => (dispatch) =>
  dispatch(viewHistoryResult({}, types.VIEW_HISTORY_CLEAR));
