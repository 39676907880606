export const CLEAR = "CLEAR";

export const CANCEL_ORDER_REQUEST = "CANCEL_ORDER_REQUEST";
export const CANCEL_ORDER_SUCCESS = "CANCEL_ORDER_SUCCESS";
export const CANCEL_ORDER_FAILURE = "CANCEL_ORDER_FAILURE";
export const CANCEL_ORDER_CHANGE_PAGE = "CANCEL_ORDER_CHANGE_PAGE";
export const CANCEL_ORDER_PROCESS_REQUEST = "CANCEL_ORDER_PROCESS_REQUEST";
export const CANCEL_ORDER_PROCESS = "CANCEL_ORDER_PROCESS";

export const NOTIFICATION_COUNT = "NOTIFICATION_COUNT";
export const CANCEL_ORDER_UPDATE = "CANCEL_ORDER_UPDATE";

export const GET_ITEM_REQUEST = "GET_ITEM_REQUEST";
export const GET_ITEM_SUCCESS = "GET_ITEM_SUCCESS";
export const GET_ITEM_FAILURE = "GET_ITEM_FAILURE";
export const CLEAR_ITEM_DETAILS = "CLEAR_ITEM_DETAILS";

export const CANCEL_ORDER_ITEM_REQUEST = "CANCEL_ORDER_ITEM_REQUEST";
export const CANCEL_ORDER_ITEM_SUCCESS = "CANCEL_ORDER_ITEM_SUCCESS";
export const CANCEL_ORDER_ITEM_FAILURE = "CANCEL_ORDER_ITEM_FAILURE";