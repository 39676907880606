import React, { Component, Fragment } from 'react';
import { Route, Switch, Link, withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { doLogout, getRolePages } from '../../views/auth/auth.action';
import { updateOrderPage } from '../../views/login/login.action';
import { getRequestToCancelOrders } from '../../views/field-evaluation/cancel-order/cancel-order.action';
import { getInProgressOrders } from '../../views/order/cancel-order/cancel-order.action';
import TermsAndConditions from '../../views/manufacturer/standard/label-cart/terms-and-conditions';
import { toast } from 'react-semantic-toasts';
import * as userActions from '../../views/user/common/common.action';
import * as sidebarActions from './sidebar-menu.action';

import {
  Icon,
  Menu,
  Segment,
  Sidebar,
  Button,
  Divider,
  Accordion,
  Label,
  Placeholder,
  Modal,
  Table,
  Form,
  Radio,
  Loader,
  Dimmer,
} from 'semantic-ui-react';
import routes from '../../nav/routes';
import ContactForm from '../contact-form/contact-form';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import NotFound from '../../components/error/404-not-found';
import '../../assets/css/lexington-revamp.css';
import Logout from '../logout/logout';
import Role from '../../enums/role';
import IdleTimer from 'react-idle-timer';
import { Common } from '../../common';
import * as labelOrderCartAction from '../../views/manufacturer/standard/label-cart/label-cart.action';
import * as actions from '../../views/field-evaluation/account-settings/account-settings.action';
import * as helpMeActions from '../../views/help-me/help-me.action';
import * as helathDashBoardActions from '../../views/health-dashboard/health-dashboard.action';
import * as commonActions from '../../views/user/common/common.action';
import jwtDecode from 'jwt-decode';
import jwtEncode from 'jwt-encode';
import IdentityProvider from '../../enums/identity-provider';
import * as applicantActions from '../../views/applicant/standard/applicant-dashboard.action';
import * as manufacturerActions from '../../views/manufacturer/standard/file-volumes/file-volumes.action';

const API_BASE = `${process.env.REACT_APP_API_BASE}`;
const SECURITY_KEY = `${process.env.REACT_APP_SECURITY_KEY}`;
const REACT_APP_COMBINATION_LABELS_SWITCH = `${process.env.REACT_APP_COMBINATION_LABELS_SWITCH}`;
const IDENTITY_PROVIDER = `${process.env.REACT_APP_IDENTITY_PROVIDER}`;

class SidebarMenu extends Component {
  state = {
    isLoggedOut: false,
    activeItem: 'home',
    activeIndex: 0,
    timeout: 1000 * 1800 * 1, // 30 mins timeout
    showModal: false,
    userLoggedIn: false,
    isTimedOut: false,
    navigate: false,
    isLoad: false,
    OverAllStat: '',
    showPsnModal: false,
    userRole: '',
    showRoleModel: false,
    handleRoleDialog: false,
    openConfirmation: false,
    isSwitchRole: false,
    isSwitchPsn: false,
    value: 'applicant',
    isFetchingMenu: false,
  };

  onAction = e => {
    console.log('user did something', e);
    this.setState({ isTimedOut: false });
  };

  onActive = e => {
    console.log('user is active', e);
    this.setState({ isTimedOut: false });
  };

  onIdle = e => {
    console.log('user is idle', e);
    const isTimedOut = this.state.isTimedOut;
    if (isTimedOut) {
      this.onLogout();
    } else {
      this.setState({ showModal: true });
      this.idleTimer.reset();
      this.setState({ isTimedOut: true });
    }
  };

  onLogout = () => {
    if (IDENTITY_PROVIDER == IdentityProvider.Iam) {
      localStorage.clear();
      var externalLogoutURL = `${API_BASE}auth/logout`;
      window.location.replace(externalLogoutURL);
      this.props.logout();
    } else if (IDENTITY_PROVIDER == IdentityProvider.B2c) {
      var b2cToken = Common.getB2cToken();
      localStorage.clear();

      Common.toggleLogout(b2cToken);
    }
  };

  onCloseModal = () => {
    this.setState({ showModal: false });
  };

  handleItemClick = (e, { name }) => {
    this.setState({ activeItem: name });
    if (name == 'help-me') {
      this.handleSelectPage('Help Me');
    } else {
      this.handleSelectPage('Dashboard');
    }
  };

  toggleHelp = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  handleLogout = () => {
    localStorage.removeItem('selection');
    this.props.logout();
  };

  handlePsnChecking = () => {
    if (Common.getToken()) {
      var psns = Common.getPsnList();
      this.props.getPsnAssignments(psns);
    }
  };

  togglePsnModal = toggle => {
    this.setState({ showPsnModal: toggle });
  };

  toggleRoleModal = toggle => {
    const { userRole, value } = this.state;
    var userRoleModel = {
      userRole: userRole ? userRole : value == 'applicant' ? 1 : 2,
    };

    if (userRoleModel.userRole != '') {
      this.props.updateUserRole(userRoleModel).then(result => {
        if (result.isSuccessful) {
          var appTokenDecoded = jwtDecode(localStorage.getItem('appToken'));

          appTokenDecoded.role = result.data;

          localStorage.setItem(
            'appToken',
            JSON.stringify(jwtEncode(appTokenDecoded, SECURITY_KEY))
          );
          this.props.updateOrderPage(result.data);
        }
      });
      this.setState({ showRoleModel: toggle });
    } else {
      setTimeout(() => {
        toast({
          title: '',
          description: <p>{`Please select user role`}</p>,
          time: 10000,
          size: 'small',
          icon: '',
          color: 'red',
        });
      }, 100);
    }
  };

  handleSelectPage = page => {
    //localStorage.setItem("activePage", page);
    this.props.getActivePage(page);
    if (page == 'Standard Label Order' || page == 'Combination Label Order') {
      this.setState({ activeItem: 'view-order-cart' });
    }
  };

  handleSelectPsn = item => {
    this.setState({ userRole: '' });
    var model = {
      partySiteNumber: item.partySiteNumber,
      psnCountry: item.country,
    };
    this.setState({ isFetchingMenu: true });
    this.props.updateUserPsn(model);
  };

  handleSelectRole = (e, { value }) => {
    this.setState({ value });

    var role = value == 'applicant' ? Role.applicant : Role.manufacturer;
    this.setState({ userRole: role });
  };

  handleSwitchRole = () => {
    const { standardLabelsCart } = this.props;

    if (standardLabelsCart.data && standardLabelsCart.data.length > 0) {
      this.setState({ openConfirmation: true });
      this.setState({ isSwitchRole: true });
    } else {
      this.setState({ showRoleModel: true });
    }
  };

  handleSwitchPsn = () => {
    this.props.cleanAllFiltersApplicant();
    this.props.cleanAllFiltersManufacturer();

    const { standardLabelsCart } = this.props;
    if (standardLabelsCart.data && standardLabelsCart.data.length > 0) {
      this.setState({ openConfirmation: true });
      this.setState({ isSwitchPsn: true });
    } else {
      this.setState({ showPsnModal: true });
    }
  };

  handleDeleteCartConfirmation = () => {
    const { standardLabelsCart } = this.props;
    const { isSwitchRole, isSwitchPsn } = this.state;

    standardLabelsCart.data.forEach(data => {
      this.props.cancelOrder(data).then(() => {
        var role = Common.getUserRole();
        this.setState({ openConfirmation: false });

        this.props.getCartItems().then(() => {
          if (isSwitchRole == true) {
            this.setState({ showRoleModel: true });
            this.setState({ isSwitchRole: false });
            this.setState({ userRole: role });
          } else if (isSwitchPsn) {
            this.setState({ showPsnModal: true });
            this.setState({ isSwitchPsn: false });
          }
        });
      });
    });
  };

  translateMenuName = (routeName, translations, originalRouteName) => {
    let translatedRoute = '';
    let roleId = Common.getUserRole();
    switch (routeName) {
      case 'home':
        translatedRoute = translations['LexNotifications'];
        break;
      case 'view-order-cart':
        translatedRoute = translations['LexViewOrderCart'];
        break;
      case 'view-orders':
        if (roleId == Role.applicant || roleId == Role.manufacturer) {
          translatedRoute = translations['LexOrderHistoryReOrder'];
        } else {
          translatedRoute = translations['LexViewOrderHistory'];
        }
        break;
      case 'help-me':
        translatedRoute = translations['LexHelpMe'];
        break;
      case 'combination-label-item-search':
        translatedRoute = translations['LexComboLabelItemSearch'];
        break;
      default:
        translatedRoute = originalRouteName;
        break;
    }
    return translatedRoute;
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.rolePages !== this.props.rolePages) {
      this.createMenu();
    }
    if (
      prevProps.updatePsndata.isSuccessUpdatePsn !==
      this.props.updatePsndata.isSuccessUpdatePsn
    ) {
      this.setState({ isFetchingMenu: true });
      this.props.getRolePages();
    }
  }

  componentDidMount() {
    //document.body.style.zoom = "100%";
    this.props.getRolePages();

    var roleId = Common.getUserRole();
    var userId = Common.getUserId();

    if (roleId == Role.fieldEngineer) {
      this.props.getAccountSettings();
    }

    this.props.getUnreadMessage(
      roleId == Role.labelCenter || roleId == Role.labelManager ? null : userId
    );

    if (this.props.rolePages.length > 0) {
      if (this.props.rolePages[0].roleId == Role.projectHandler) {
        this.props.getRequestToCancelOrdersCount();
      } else if (this.props.rolePages[0].roleId == Role.labelCenter) {
        this.props.getInProgressOrdersCount();
        // } else if (this.props.rolePages[0].roleId == Role.fieldEngineer) {
        //   this.props.getAccountSettings();
      }
    }
    this.props.getApiStatus(1);
    if (
      roleId == Role.applicant ||
      roleId == Role.manufacturer ||
      roleId == Role.printer
    ) {
      this.props.getCartItems().then(() => {
        this.handlePsnChecking();
      });
    }
  }

  createMenu = () => {
    let navRoutes = null;
    let otherRoutes = null;
    let sidebarRoutes = null;

    var roleId = Common.getUserRole();
    const translations = Common.getTranslations() || {};
    var isAdmin =
      roleId === Role.labelCenter ||
      roleId === Role.labelManager ||
      roleId === Role.pricingManager ||
      roleId === Role.fieldEngineerRO;

    navRoutes = routes.filter(r => r.sidebar);
    otherRoutes = routes.filter(r => !r.sidebar);
    sidebarRoutes = navRoutes;

    if (this.props.rolePages.length > 0) {
      sidebarRoutes.forEach(item => {
        var indexOf = this.props.rolePages
          .map(function (r) {
            return r.urlName;
          })
          .indexOf(item.name);

        if (indexOf === -1)
          navRoutes = navRoutes.filter(n => n.name !== item.name);
      });

      // orderPage = rolePages.filter(r => r.moduleId === 9)[0].url;
      // roleId = rolePages[0].roleId;

      if (!isAdmin) {
        navRoutes = navRoutes.filter(n => n.to !== '/home/');
        navRoutes.map(route => {
          route.value = this.translateMenuName(
            route.name,
            translations,
            route.value
          );
          return route;
        });
      }
    } else {
      navRoutes = [];
      sidebarRoutes = [];
      otherRoutes = [];
    }
    this.props.updateMenu({ navRoutes: navRoutes, otherRoutes: otherRoutes });
    this.setState({ activeItem: 'home', isFetchingMenu: false });

    // if (roleId == Role.printer) {
    //   this.props.history.push(`/home`);
    // }

    // let currentRoute = navRoutes.find(
    //   (x) => x.to.indexOf(window.location.pathname) >= 0
    // );

    // if (currentRoute != null) {
    //   this.props.history.push(currentRoute.to);
    //   this.setState({
    //     activeItem: currentRoute.name,
    //     isFetchingMenu: false
    //   });
    // } else {
    //   this.props.history.push(`/home`);
    //   this.setState({ activeItem: "home", isFetchingMenu: false });
    // }

    //browserHistory.push(`/home`);
  };

  render() {
    const {
      activeItem,
      activeIndex,
      openTerm,
      isLoad,
      OverAllStat,
      showRoleModel,
      handleRoleDialog,
      openConfirmation,
      isSwitchPsn,
    } = this.state;
    const {
      feCancelOrderNotificationCount,
      inProgressOrderToCancelNotificationCount,
      standardLabelsCart,
      combinationLabelsCart,
      accountSettings,
      helpMe,
      data,
      updatePsndata,
      orderPage,
      isSuccessGetRolePage,
      labelCart,
      newNotifications,
      rolePages,
    } = this.props;
    const { isSuccessGetPsns, psnAssignments } = updatePsndata;
    const translations = Common.getTranslations() || {};

    //this.createMenu();
    let hasCartItems = false;

    if (labelCart && labelCart.data && labelCart.data.length > 0) {
      if (labelCart.data[0].carts.length > 0) {
        hasCartItems = true;
      }
    }

    if (data && data.data && !isLoad) {
      var redStat = data.data.filter(i => i.statusResult == 2);
      var amber = data.data.filter(i => i.statusResult == 1);

      if (amber.length == 1) {
        this.setState({ OverAllStat: 'ui yellow empty circular label' });
      } else if (amber.length > 1) {
        this.setState({ OverAllStat: 'ui red empty circular label' });
      } else if (redStat.length > 0) {
        this.setState({ OverAllStat: 'ui red empty  circular label' });
      } else {
        this.setState({ OverAllStat: 'ui green empty circular label' });
      }
      this.setState({ isLoad: true });
    }

    var roleId = Common.getUserRole();
    var isAdmin =
      roleId === Role.labelCenter ||
      roleId === Role.labelManager ||
      roleId === Role.pricingManager ||
      roleId === Role.fieldEngineerRO ||
      roleId === Role.printer;
    let combinationCartItems = [];
    let cartCount = null;
    let hasAccountSettings = false;
    var psnList = psnAssignments;
    var multiPsn = psnList && psnList.length > 1 ? 'true' : 'false';
    let newNotificationsCount = 0;
    //var isDualRole = JSON.parse(localStorage.getItem("isDualRole"));

    var isDualRole = Common.getIsDualRole();

    if (isSuccessGetPsns && psnAssignments.length > 1 && !isSwitchPsn) {
      this.togglePsnModal(true);
      this.setState({ isSwitchPsn: true });
    }

    if (
      psnList &&
      psnList.length == 1 &&
      isDualRole.toLowerCase() == 'true' &&
      !handleRoleDialog &&
      (roleId == Role.applicant ||
        roleId == Role.manufacturer ||
        roleId == Role.printer) &&
      !hasCartItems
    ) {
      this.setState({
        showRoleModel: true,
        handleRoleDialog: true,
        userRole: 1,
      });
    }

    if (updatePsndata.isSuccessUpdatePsn) {
      this.setState({ showPsnModal: false });

      setTimeout(() => {
        toast({
          title: '',
          description: (
            <p>
              {Common.getTranslations()['LexPSNUpdateAlert'].replace(
                '{0}',
                updatePsndata.partySiteNumber
              )}
            </p>
          ),
          time: 10000,
          size: 'small',
          icon: 'check',
          color: 'green',
        });
      }, 100);

      var appTokenDecoded = jwtDecode(localStorage.getItem('appToken'));
      appTokenDecoded.certserialnumber = updatePsndata.partySiteNumber;
      appTokenDecoded.is_dual_role = updatePsndata.isDualRole.toString();
      localStorage.setItem(
        'appToken',
        JSON.stringify(jwtEncode(appTokenDecoded, SECURITY_KEY))
      );

      if (!updatePsndata.isUpdateAutomatic) {
        this.setState({
          showRoleModel: updatePsndata.isDualRole,
          userRole: updatePsndata.isDualRole ? 1 : '',
        });
      }

      if (!updatePsndata.isDualRole) {
        this.setState({ userRole: updatePsndata.roleId }, () => {
          this.toggleRoleModal(false);
        });
      }

      this.props.updateUserReset();
      this.props.getRolePages();
    }

    if (updatePsndata.isSuccessUpdateRole) {
      setTimeout(() => {
        toast({
          title: '',
          description: (
            <p>{Common.getTranslations()['LexUserRoleSavedSucessAlert']}</p>
          ),
          time: 10000,
          size: 'small',
          icon: 'check',
          color: 'green',
        });
      }, 100);

      var role = updatePsndata.data.data;
      var orderType = window.location.href.substring(
        window.location.href.length,
        window.location.href.length - 1
      );

      if (role == Role.applicant) {
        if (window.location.href.indexOf('order-standard-label') > -1) {
          return <Redirect to={`/home/applicant-dashboard/${orderType}`} />;
        }
      } else if (role == Role.manufacturer) {
        if (window.location.href.indexOf('applicant-dashboard') > -1) {
          return <Redirect to={`/home/order-standard-label/${orderType}`} />;
        }
      }

      this.props.updateUserRoleReset();
      this.props.getRolePages();
    }

    if (newNotifications) newNotificationsCount = newNotifications;

    if (
      standardLabelsCart &&
      standardLabelsCart.data &&
      standardLabelsCart.data.length > 0
    ) {
      var allItems = [];

      standardLabelsCart.data.forEach(data => {
        data.carts.forEach(cart => {
          cart.cartItems.forEach(cartItem => {
            allItems.push(cartItem);
          });
        });
      });

      cartCount = allItems.length;
    }
    if (combinationLabelsCart && combinationLabelsCart.data) {
      combinationCartItems = combinationLabelsCart.data.cartItems;
    }

    if (roleId == Role.fieldEngineer) {
      hasAccountSettings = accountSettings.hasAccountSettings;
    } else if (roleId == Role.projectHandler) {
      hasAccountSettings = true;
    }

    return (
      <Sidebar.Pushable className="com-sidebar-container">
        <Sidebar as={Menu} animation="uncover" vertical visible>
          <IdleTimer
            ref={ref => {
              this.idleTimer = ref;
            }}
            element={document}
            onActive={this.onActive}
            onIdle={this.onIdle}
            onAction={this.onAction}
            debounce={5000}
            timeout={this.state.timeout}
          />
          <Modal open={openConfirmation}>
            <Modal.Header className="ui center aligned">
              {translations['LexConfirmation']}
            </Modal.Header>
            <Modal.Content scrolling>
              {translations['LexYouhaveincompleteitems']}
            </Modal.Content>
            <Modal.Actions>
              <Button
                primary
                onClick={() => this.handleDeleteCartConfirmation()}
              >
                {translations['LexYES']}
              </Button>
              <Button
                primary
                basic
                onClick={() => this.setState({ openConfirmation: false })}
              >
                {translations['LexNO']}
              </Button>
            </Modal.Actions>
          </Modal>
          <Modal open={this.state.showModal} size="mini">
            <Modal.Header className="ui center aligned">
              You Have Been Idle!
            </Modal.Header>
            <Modal.Content scrolling>
              You will get timed out. You want to stay?
            </Modal.Content>
            <Modal.Actions>
              <Button secondary basic onClick={this.onLogout.bind(this)}>
                Logout
              </Button>
              <Button primary onClick={this.onCloseModal.bind(this)}>
                Stay
              </Button>
            </Modal.Actions>
          </Modal>
          <Modal open={openTerm}>
            <Modal.Header className="ui center aligned">
              Terms and Conditions
            </Modal.Header>
            <Modal.Content scrolling>
              <TermsAndConditions isHidden="true" />
            </Modal.Content>
            <Modal.Actions>
              <Button
                primary
                basic
                onClick={() => this.setState({ openTerm: false })}
              >
                Cancel
              </Button>
            </Modal.Actions>
          </Modal>
          <Modal open={this.state.showModal} size="mini">
            <Modal.Header className="ui center aligned">
              You Have Been Idle!
            </Modal.Header>
            <Modal.Content scrolling>
              You will get timed out. You want to stay?
            </Modal.Content>
            <Modal.Actions>
              <Button secondary basic onClick={this.onLogout.bind(this)}>
                Logout
              </Button>
              <Button primary onClick={this.onCloseModal.bind(this)}>
                Stay
              </Button>
            </Modal.Actions>
          </Modal>
          <Modal open={this.state.showPsnModal && !hasCartItems} size="small">
            <Modal.Header className="ui center aligned">
              {translations['LexMultiplePSNFound']}
            </Modal.Header>
            <Modal.Content scrolling>
              {translations['LexMultiplePSNFoundDesc']}
              <br />
              <Table striped celled selectable sortable>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Party Site Number</Table.HeaderCell>
                    <Table.HeaderCell>
                      {translations['LexCompanyName']}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {translations['LexAddress']}
                    </Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {psnList &&
                    psnList.map((item, idx) => {
                      return (
                        <Table.Row key={idx}>
                          <Table.Cell>{item.partySiteNumber}</Table.Cell>
                          <Table.Cell>{item.companyName}</Table.Cell>
                          <Table.Cell>{item.address}</Table.Cell>
                          <Table.Cell
                            style={{ width: '20%', textAlign: 'center' }}
                          >
                            <Button
                              primary
                              onClick={this.handleSelectPsn.bind(this, item)}
                              loading={updatePsndata.isLoadingUpdatePsn}
                            >
                              {translations['LexSelect']}
                            </Button>
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                </Table.Body>
              </Table>
            </Modal.Content>
            <Modal.Actions>
              <Button
                secondary
                basic
                onClick={this.togglePsnModal.bind(this, false)}
                loading={updatePsndata.isLoadingUpdatePsn}
              >
                Cancel
              </Button>
            </Modal.Actions>
          </Modal>
          <Modal open={showRoleModel} size="small">
            <Modal.Header className="ui center aligned">
              {translations['LexUserRole']}
            </Modal.Header>
            <Modal.Content scrolling>
              <Table striped celled selectable sortable>
                <Table.Header>
                  {translations['LexWouldyouliketoorderlabelsas']}
                </Table.Header>
                <Table.Body>
                  <br />
                  <Form.Field>
                    <Radio
                      label={translations['LexApplicant']}
                      name="radioGroup"
                      value="applicant"
                      checked={this.state.value === 'applicant'}
                      onChange={this.handleSelectRole}
                    />
                  </Form.Field>
                  <br />
                  <Form.Field>
                    <Radio
                      label={translations['LexManufacturer']}
                      name="radioGroup"
                      value="manufacturer"
                      checked={this.state.value === 'manufacturer'}
                      onChange={this.handleSelectRole}
                    />
                  </Form.Field>
                  <br />
                </Table.Body>
              </Table>
            </Modal.Content>
            <Modal.Actions>
              <Button
                secondary
                basic
                onClick={this.toggleRoleModal.bind(this, false)}
              >
                Ok
              </Button>
            </Modal.Actions>
          </Modal>
          <div className="button-layout-sidebar">
            {(roleId === Role.applicant || roleId === Role.manufacturer) && (
              <Segment basic>
                {/* <p className="header">LABEL ORDERING</p> */}
                <Button.Group
                  widths="5"
                  vertical
                  hidden={this.state.isFetchingMenu}
                >
                  <Button
                    onClick={() =>
                      this.handleSelectPage('Standard Label Order')
                    }
                    color="blue"
                    as={Link}
                    to={orderPage + '/1'}
                    style={{ borderRadius: '.28em' }}
                  >
                    {translations['LexOrderStandardLabels']}
                  </Button>
                  <Divider fitted hidden />
                  <Divider fitted hidden />
                  <Divider fitted hidden />
                  {REACT_APP_COMBINATION_LABELS_SWITCH == 1 && (
                    <Fragment>
                      <Button
                        onClick={() =>
                          this.handleSelectPage('Combination Label Order')
                        }
                        primary
                        as={Link}
                        to={orderPage + '/2'}
                        style={{ borderRadius: '.28em' }}
                      >
                        {translations['LexOrderCombinationLabels']}
                      </Button>
                      <Divider fitted />
                    </Fragment>
                  )}
                </Button.Group>
                <Divider fitted hidden />
                <Divider fitted hidden />
                <Divider fitted hidden />
                <div className="button-container">
                  {isDualRole.toLowerCase() == 'true' &&
                    (roleId == Role.applicant ||
                      roleId == Role.manufacturer) && (
                      <Button basic secondary onClick={this.handleSwitchRole}>
                        {translations['LexSwitchRole']}
                      </Button>
                    )}
                  {multiPsn.toLowerCase() == 'true' && (
                    <Button
                      className="sidebar-switch-button button-right"
                      basic
                      secondary
                      onClick={this.handleSwitchPsn}
                    >
                      {translations['LexSwitchPSN']}
                    </Button>
                  )}
                </div>
              </Segment>
            )}

            {roleId === Role.printer && (
              <Segment>
                <div className="button-container">
                  {multiPsn.toLowerCase() == 'true' && (
                    <Button
                      className="sidebar-switch-button button-right"
                      basic
                      secondary
                      onClick={this.handleSwitchPsn}
                    >
                      {translations['LexSwitchPSN']}
                    </Button>
                  )}
                </div>
              </Segment>
            )}
            {(roleId === Role.fireEquipment ||
              roleId === Role.fieldEngineer ||
              roleId === Role.projectHandler) && (
              <Segment basic>
                {roleId === Role.fireEquipment && (
                  <Segment basic>
                    <p className="header">FIRE EQUIPMENT SERVICES</p>
                    <Button.Group widths="5" vertical>
                      {/* <Button primary as={Link} to={"/home/search-quote"}>
                  Copy Quote/Order
                </Button>
                <Divider fitted />
                <Divider fitted /> */}
                      <Button primary as={Link} to={'/home/new-quote'}>
                        New Quote
                      </Button>
                    </Button.Group>
                  </Segment>
                )}
                {(roleId === Role.fieldEngineer ||
                  roleId === Role.projectHandler) && (
                  <Segment basic>
                    <p className="header">FIELD EVALUATION SERVICES</p>
                    <Button.Group widths="5" vertical>
                      {/* <Button primary as={Link} to={"/home/search-quote"}>
                  Copy Quote/Order
                </Button>
                <Divider fitted />
                <Divider fitted /> */}
                      {!hasAccountSettings && (
                        <Button as={Link} to={'/home/account-settings'}>
                          Setup Account Settings
                        </Button>
                      )}
                      {hasAccountSettings && (
                        <Button primary as={Link} to={'/home/new-quote'}>
                          New Quote
                        </Button>
                      )}
                    </Button.Group>
                  </Segment>
                )}
              </Segment>
            )}
          </div>
          <Divider fitted />
          {roleId === null ? (
            <Segment basic className="placeholder">
              <Placeholder>
                <Placeholder.Paragraph>
                  <Placeholder.Line />
                  <Placeholder.Line />
                  <Placeholder.Line />
                  <Placeholder.Line />
                  <Placeholder.Line />
                </Placeholder.Paragraph>
              </Placeholder>
            </Segment>
          ) : (
            isSuccessGetRolePage &&
            this.props.navRoutes &&
            this.props.navRoutes.map((route, idx) => {
              return (
                <Menu.Item
                  key={idx}
                  name={route.name}
                  as={Link}
                  to={route.to}
                  active={activeItem === route.name}
                  onClick={this.handleItemClick}
                  className={
                    activeItem === route.name ? 'active-item' : 'inactive-item'
                  }
                >
                  <Icon name={route.icon} className="item-icon" />
                  <Label
                    className={
                      (route.name === 'home' || route.name === 'view-orders') &&
                      newNotificationsCount &&
                      newNotificationsCount > 0 &&
                      isAdmin
                        ? 'has-notif'
                        : 'no-notif'
                    }
                  >
                    {isAdmin && newNotificationsCount}
                  </Label>
                  <Label
                    className={
                      route.name === 'view-order-cart' && cartCount > 0
                        ? 'has-notif'
                        : 'no-notif'
                    }
                  >
                    {cartCount}
                  </Label>
                  <Label
                    className={
                      route.name === 'fe-cancel-order' &&
                      feCancelOrderNotificationCount > 0
                        ? 'has-notif'
                        : 'no-notif'
                    }
                  >
                    {feCancelOrderNotificationCount}
                  </Label>
                  <Label
                    className={
                      route.name === 'cancel-order' &&
                      inProgressOrderToCancelNotificationCount > 0
                        ? 'has-notif'
                        : 'no-notif'
                    }
                  >
                    {inProgressOrderToCancelNotificationCount}
                  </Label>
                  <Label
                    className={
                      (route.name === 'help-me' ||
                        route.name === 'reply-to-help') &&
                      helpMe?.unreadMessageCount > 0
                        ? 'has-notif'
                        : 'no-notif'
                    }
                  >
                    {helpMe?.unreadMessageCount}
                  </Label>
                  {route.value}
                  <span
                    hidden={route.name != 'health-dashboard'}
                    style={{ float: 'right' }}
                  >
                    <a class={OverAllStat} style={{ paddingLeft: 10 }}></a>
                  </span>
                </Menu.Item>
              );
            })
          )}
          {/* <Menu.Item
              name="user"
              className="inactive-item"
              as={Link}
              to={"/home/user-profile"}
            >
              <Icon name="user circle" className="item-icon" />
              User
            </Menu.Item> */}
          <Divider className="com-sidebar-divider" fitted />
          <Menu.Item name="contact-us">
            <Accordion>
              <Accordion.Title
                active={activeIndex === 0}
                index={0}
                onClick={this.toggleHelp}
              >
                <Icon name="dropdown" />
                {isAdmin
                  ? 'NEED TO GET IN TOUCH?'
                  : translations['LexNEEDTOGETINTOUCH']}
              </Accordion.Title>
              <Accordion.Content active={activeIndex === 0}>
                <ContactForm />
              </Accordion.Content>
            </Accordion>
          </Menu.Item>
          {/* <Menu.Item name="logout" className="inactive-item" as={Link} to={"/home/logout"}>
              <Icon
                name="log out"
                className="item-icon"
              />
              Log out
            </Menu.Item> */}
          {(roleId === Role.applicant || roleId === Role.labelCenter) && (
            <Menu.Item>
              <a href="#" onClick={() => this.setState({ openTerm: true })}>
                Terms and Conditions
              </a>
            </Menu.Item>
          )}
          <Logout />
        </Sidebar>
        <Sidebar.Pusher className="pushers">
          {this.state.isFetchingMenu ? (
            <Dimmer active={true} inverted>
              <Loader inverted content="Loading" />
            </Dimmer>
          ) : (
            <Route
              render={({ location }) => (
                <TransitionGroup>
                  <CSSTransition
                    key={location.key}
                    timeout={300}
                    classNames="fade"
                  >
                    <Switch location={location}>
                      {this.props.navRoutes &&
                        this.props.navRoutes.map((route, idx) => {
                          return (
                            <Route
                              exact
                              key={idx}
                              path={route.to}
                              component={route.component}
                            />
                          );
                        })}
                      {this.props.otherRoutes &&
                        this.props.otherRoutes.map((route, idx) => {
                          return (
                            <Route
                              exact
                              key={idx}
                              path={route.to}
                              component={route.component}
                            />
                          );
                        })}

                      <Route path="*" component={NotFound}></Route>
                    </Switch>
                  </CSSTransition>
                </TransitionGroup>
              )}
            />
          )}
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    );
  }
}

const mapStateToProps = state => ({
  hasError: state.login.hasError,
  message: state.login.message,
  loading: state.login.loading,
  userId: state.login.userId,
  orderPage: state.login.orderPage,
  notifications: state.dashboard.data,
  newNotifications: state.dashboard.newNotifications,
  rolePages: state.login.rolePages,
  isSuccessGetRolePage: state.login.isSuccessGetRolePage,
  standardLabelsCart: state.labelCart,
  combinationLabelsCart: state.labelOrderCart,
  feCancelOrderNotificationCount: state.feCancelOrder.notificationCount,
  inProgressOrderToCancelNotificationCount: state.cancelOrder.notificationCount,
  accountDetails: state.accountSettings,
  labelCart: state.labelCart,
  accountSettings: state.accountSettings,
  helpMe: state.helpMe,
  data: state.healthDashBoard.data,
  updatePsndata: state.user.common,
  activePage: state.user.common.activePage,
  navRoutes: state.sidebar.navRoutes,
  otherRoutes: state.sidebar.otherRoutes,
});

const mapDispatchToProps = dispatch => ({
  getRolePages: () => dispatch(getRolePages()),
  logout: () => dispatch(doLogout()),
  getRequestToCancelOrdersCount: () => dispatch(getRequestToCancelOrders(1, 5)),
  getInProgressOrdersCount: () => dispatch(getInProgressOrders(1, 5)),
  getCartItems: () => dispatch(labelOrderCartAction.getCartItems()),
  getAccountSettings: () => dispatch(actions.getAccountSettings()),
  getUnreadMessage: userId => dispatch(helpMeActions.getUnreadMessage(userId)),
  getApiStatus: day => dispatch(helathDashBoardActions.getApiStatus(day)),
  updateUserPsn: model => dispatch(userActions.updateUserPsn(model)),
  updateUserReset: () => dispatch(userActions.updateUserReset()),
  updateUserRole: userRoleModel =>
    dispatch(userActions.updateUserRole(userRoleModel)),
  updateUserRoleReset: () => dispatch(userActions.updateUserRoleReset()),
  updateOrderPage: roleId => dispatch(updateOrderPage(roleId)),
  cancelOrder: data => dispatch(labelOrderCartAction.cancelOrder(data)),
  getPsnAssignments: psnList =>
    dispatch(commonActions.getPsnAssignments(psnList)),
  getActivePage: page => dispatch(commonActions.getActivePage(page)),
  cleanAllFiltersApplicant: () => dispatch(applicantActions.cleanAllFilters()),
  cleanAllFiltersManufacturer: () =>
    dispatch(manufacturerActions.cleanAllFilters()),
  updateMenu: data => dispatch(sidebarActions.updateMenu(data)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SidebarMenu)
);
