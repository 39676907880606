const requirementsDefault = `<ul>
	<li>
	  <b>Electrical Requirements:</b> Please note the equipment must
	  be de-energized to conduct the field evaluation. Further, if
	  the evaluation includes the main (service) disconnect or any
	  equipment located on the line side of the main disconnect on
	  service equipment, a utility shutdown of the equipment must be
	  arranged to complete the evaluation.
	</li>
	<br/>
	<li>
	  <b>Critical Component Requirements:</b> All critical
	  components (such as Insulation Piercing Connectors, Lugs,
	  Crimp Connectors, Terminal Blocks, Polaris Connectors, or
	  Breaker Taps) must be UL Certified and installed in accordance
	  with the installation instructions and if Recognized meet any
	  Conditions of Acceptability.
	</li>
	<br/>
	<li>
	  <b>Equipment Requirements:</b> A calibrated torque wrench that
	  bears a valid certification or sticker (within the last twelve
	  months) with manufacture name and serial number must be
	  available on site during the day of evaluation.
	</li>
	<br/>
	<li>
	  <b>Site Access Requirements:</b> Refer to the “Field
	  Evaluation Service Terms” Section for further details.
	</li>
	<br/>
	<li>
	  <b>Other:</b> Unless specifically identified in the scope of
	  work, the UL inspection will not include any (AE) panel(s) or
	  other (AE) source, DC conductor, DC disconnect, inverter,
	  raceways (AC or DC), AC disconnect, overcurrent protection of
	  (AE) system, grounding or bonding of (AE) components, (AE)
	  system, or raceways. These items are typically the
	  responsibility of the local inspection authority (AHJ) to
	  review.
	</li>
  </ul>`;

const requirementsValue = (data, fromViewOrderData) => {
  let requirementsValue = "";
  // refer to index 0 only
  const idx = data.forms.findIndex((d) => d.tabIndex === 0);
  if (idx != -1) {
    if (data.forms && data.forms[idx].requirements) {
      requirementsValue = data.forms[idx].requirements;
    }
    // Global/Fixed requirement per sub productelse {
    else {
      let requirement = data.serviceSubProduct.data;
      requirementsValue = requirement
        ? requirement.length
          ? requirement[0].assumption.details
          : ""
        : "";
    }
  }

  return requirementsValue ? requirementsValue : requirementsDefault;
};

export default requirementsValue;
