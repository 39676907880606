import React, { Component } from "react";
import { Table } from "semantic-ui-react";

class Acceptance extends Component {
  render() {
    return (
      <Table attached>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell textAlign="center">ACCEPTANCE</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell verticalAlign="top">
              <font color="red">
                By accepting this Quotation, you certify that you have read and
                agreed to the referenced terms and conditions; you are
                authorized to accept this Quotation on behalf of your company,
                and authorize us to commence work on the services described
                above.
              </font>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  }
}

export default Acceptance;
