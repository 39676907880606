import { requests } from '../request-factory';
import { mapDataToQueryString } from '../request-helpers';

const labelOrderService = {
  getLabelOrder: orderRequestNumber =>
    requests.get(`label-order?orderRequestNumber=${orderRequestNumber}`),
  renderPdf: data => requests.post(`label-order/render-pdf`, data),
  getLabels: () => requests.get(`label-order/labels`),
  updateLabels: data => requests.post(`label-order/update-labels`, data),
  updateCombinationLabels: data =>
    requests.post(`label-order/update-combination-labels`, data),
  updateCustomerCombinationLabel: data =>
    requests.post(`label-order/update-customer-combination-label`, data),

  addSingleCustomerCombinationLabel: data =>
    requests.post(`label-order/add-single-customer-combination-label`, data),
  updateLabelsPrice: data =>
    requests.post(`label-order/insert-labels-price`, data),
  getLabelsByCcn: ccn =>
    requests.get(
      `label-order/labels-by-ccn?${mapDataToQueryString({
        ccn,
      })}`
    ),
  getCombinationLabels: () => requests.get(`label-order/combination-labels`),
  removeCombinationLabels: data =>
    requests.post(`label-order/combination-labels/remove`, data),
  getCourierTracking: () => requests.get(`label-order/get-courier-tracking`),
  updateCourierTracking: data =>
    requests.post(`label-order/update-courier-tracking`, data),
  getPackingSlipByOracleNumber: oracleNumber => {
    return requests.get(
      `label-order/packing-slips?${mapDataToQueryString({
        oracleNumber,
      })}`
    );
  },
  getHologramCcns: ccn =>
    requests.get(
      `label-order/get-hologram-ccns
?${mapDataToQueryString({
        ccn,
      })}`
    ),
  getConfigurationSettings: () =>
    requests.get('label-order/configuration-setting'),
  updateConfigurationSettings: data =>
    requests.post(`label-order/configuration-setting`, data),
  updateLabelItem: data => requests.post(`label-order/label/update`, data),
  getLabelItems: (currentPage, pageSize, viewLtlItems, itemNumberSearch) =>
    requests.get(
      `label-order/label-items?${mapDataToQueryString({
        currentPage,
        pageSize,
        viewLtlItems,
        itemNumberSearch,
      })}`
    ),
};

export default labelOrderService;
