import React, { Component } from "react";
import { Table, Image, Button } from "semantic-ui-react";

import { GetStatusCode } from "../../../helpers/component-helper";
import Status from "../../../enums/status";

class ItemTable extends Component {
  render() {
    const { item, handleReasonModal } = this.props;

    return (
      <Table.Row>
        <Table.Cell>{GetStatusCode(item.status)}</Table.Cell>
        <Table.Cell>{item.ccn}</Table.Cell>
        <Table.Cell>{item.fileNumber}</Table.Cell>
        <Table.Cell>{item.itemNumber}</Table.Cell>
        <Table.Cell>{item.labelDescription}</Table.Cell>
        <Table.Cell>{item.quantity}</Table.Cell>
        <Table.Cell>{item.netPrice}</Table.Cell>
        <Table.Cell>{item.unitOfMeasurementName}</Table.Cell>
        <Table.Cell>
          <Image src={item.labelImageUrl} size="small" />
        </Table.Cell>
        {/* <Table.Cell>
          <Button
            primary
            onClick={handleReasonModal.bind(this, item.id)}
            disabled={item.status === Status.cancelled}
            className={item.isLoading}
          >
            Cancel
          </Button>
        </Table.Cell> */}
      </Table.Row>
    );
  }
}

export default ItemTable;
