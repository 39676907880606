import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Container,
  Segment,
  Table,
  Grid,
  Pagination,
  Message,
  Modal,
  Button,
  Form,
  Input,
  TextArea
} from "semantic-ui-react";
import { CustomHeader } from "../../../components/labels/labels";
import * as actions from "./cancel-order.action";
import CancelOrderItem from "./cancel-order-item";
import _ from "lodash";
import { toast } from "react-semantic-toasts";
import ToastHelper from "../../../helpers/toast.helper";
import * as commonActions from "../../../views/user/common/common.action";

import ItemModal from "./item-modal";

class CancelOrder extends Component {
  state = {
    toggleCancelModal: false,
    toggleCancelMessageModal: false,
    requestNumber: null,
    item: null,
    comments: null,
    isParent: false,
    isShowItemModal: false,
    selectedItem: {}
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSearch = () => {
    const { requestNumber } = this.state;
    this.props.getInProgressOrders(
      1,
      this.props.cancelOrder.data.pageSize,
      requestNumber
    );
  };

  updateButton = (parentIdx, childIdx, isLoading, isDisable, status) => {
    const { isParent } = this.state;
    const { data } = this.props.cancelOrder;

    if (isParent) {
      data.data[parentIdx].isLoading = isLoading ? "loading" : "";
      data.data[parentIdx].isDisable = isDisable;
      data.data[parentIdx].statusName = status;
      data.data[parentIdx].childItems.map((d) => {
        d.isLoading = isLoading ? "loading" : "";
        d.statusName = status;
        d.isDisable = isDisable;
      });
    } else {
      data.data[parentIdx].childItems[childIdx].isLoading = isLoading
        ? "loading"
        : "";
      data.data[parentIdx].childItems[childIdx].isDisable = isDisable;
      data.data[parentIdx].childItems[childIdx].statusName = status;
      data.data[parentIdx].childItems[childIdx].totalPrice = 0;
    }
  };

  componentDidMount() {
    this.props.getInProgressOrders(
      this.props.cancelOrder.data.currentPage,
      this.props.cancelOrder.data.pageSize
    );
    this.props.getActivePage("Cancel Order");
  }

  handleConfirmCancel = () => {
    const { item, comments, isParent } = this.state;
    const { data } = this.props.cancelOrder;

    let parentIdx = _.findIndex(data.data, { id: item.id });
    let childIdx = _.findIndex(_.find(data.data, { id: item.id }).childItems, {
      infoId: item.infoId
    });

    if (comments == null || comments.trim() === "") {
      setTimeout(() => {
        ToastHelper.error({
          title: "Error",
          description: "Cancel Reason is required."
        });
      }, 100);
      return false;
    } else {
      if (parentIdx < 0) return;

      let id = isParent
        ? data.data[parentIdx].id
        : data.data[parentIdx].childItems[childIdx].infoId;

      this.updateButton(parentIdx, childIdx, true, true);

      const params = { orderId: id, comments: comments, isParent: isParent };

      this.props.doCancelOrderRequesting(data.data);

      this.props.doCancelOrder(params).then((s) => {
        if (s.isSuccessful) {
          this.updateButton(parentIdx, childIdx, true, true, s.message);

          setTimeout(() => {
            toast({
              title: "Success!",
              description: <p>Order has been cancelled.</p>,
              time: 5000,
              size: "small",
              icon: "check"
            });
          }, 100);
        } else {
          setTimeout(() => {
            toast({
              type: "error",
              title: "Error!",
              description: "Something went wrong.",
              time: 5000,
              size: "small",
              icon: "delete"
            });
          }, 100);
        }

        this.updateButton(
          parentIdx,
          childIdx,
          false,
          s.isSuccessful,
          s.message
        );
        this.props.doCancelOrderRequesting(data.data);
        this.props.notificationCount();
      });

      this.setState({ toggleCancelModal: false });
    }
  };

  onCancelMessage(item, isParent) {
    this.setState({ toggleCancelMessageModal: true });
    this.setState({ item: item, isParent: isParent });
  }

  onCancel() {
    this.setState({ toggleCancelModal: true });
    this.setState({ toggleCancelMessageModal: false });
  }

  handleExpandMultipleOrders = (orderId) => {
    const { data } = this.props.cancelOrder;

    var newData =
      data.data &&
      data.data.map((item) => {
        if (item.id == orderId) item.isExpandMultiple = !item.isExpandMultiple;

        return item;
      });

    this.props.updateData(newData);
  };

  handleViewItemModal(isShowItemModal) {
    this.setState({ isShowItemModal });
  }

  onViewItemModal(selectedItem) {
    this.handleViewItemModal(true);
    this.setState({ selectedItem });
  }

  render() {
    const { data } = this.props.cancelOrder;
    const {
      toggleCancelModal,
      toggleCancelMessageModal,
      requestNumber,
      comments,
      isShowItemModal,
      selectedItem
    } = this.state;

    return (
      <div className="container-wrapper">
        <Container fluid>
          <Grid>
            <Grid.Row columns="3">
              <Grid.Column width="10"> </Grid.Column>
              <Grid.Column width="4">
                <Form>
                  <Form.Field
                    control={Input}
                    // label="Order Number"
                    placeholder="Enter Order Number"
                    name="requestNumber"
                    onChange={(e) => {
                      this.handleChange(e);
                    }}
                    value={requestNumber || ""}
                    autoComplete="off"
                  />
                </Form>
              </Grid.Column>
              <Grid.Column verticalAlign="bottom" width="2">
                <Button primary onClick={this.handleSearch}>
                  Search
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Segment>
            <Table striped celled selectable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Order Status</Table.HeaderCell>
                  <Table.HeaderCell>Confirmation Number</Table.HeaderCell>
                  <Table.HeaderCell>Oracle Order Number</Table.HeaderCell>
                  <Table.HeaderCell>Order Date</Table.HeaderCell>
                  <Table.HeaderCell>Number of Labels</Table.HeaderCell>
                  <Table.HeaderCell>Quantity</Table.HeaderCell>
                  <Table.HeaderCell>Price</Table.HeaderCell>
                  <Table.HeaderCell>Cancel Order</Table.HeaderCell>
                  {/* <Table.HeaderCell>Cancel Reason</Table.HeaderCell> */}
                  <Table.HeaderCell>Expand Order</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {data.data &&
                  data.data.map((data, idx) => (
                    <CancelOrderItem
                      key={idx}
                      data={data}
                      onCancelling={this.onCancelMessage.bind(this)}
                      handleExpandMultipleOrders={
                        this.handleExpandMultipleOrders
                      }
                      onViewItem={this.onViewItemModal.bind(this)}
                    />
                  ))}
              </Table.Body>
            </Table>
            {data && data.totalPages > 0 ? (
              <Pagination
                activePage={data.currentPage}
                totalPages={data.totalPages}
                onPageChange={(e, { activePage }) => {
                  this.props.getInProgressOrders(activePage, data.pageSize);
                }}
              />
            ) : (
              <Message>No Order Found</Message>
            )}
          </Segment>
          <Modal open={toggleCancelMessageModal} size="tiny">
            <Modal.Header className="ui center aligned">
              Confirm Cancellation
            </Modal.Header>
            <Modal.Content scrolling>
              <Grid>
                <Grid.Row>
                  <Grid.Column>
                    <Form>
                      <label>
                        Please make sure the sales order has been cancelled at
                        Oracle side.
                      </label>
                    </Form>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Modal.Content>
            <Modal.Actions>
              <Button primary onClick={this.onCancel.bind(this)}>
                Confirm
              </Button>
              <Button
                color="red"
                onClick={() =>
                  this.setState({ toggleCancelMessageModal: false })
                }
              >
                Close
              </Button>
            </Modal.Actions>
          </Modal>
          <Modal open={toggleCancelModal} size="tiny">
            <Modal.Header className="ui center aligned">
              Confirm Cancellation
            </Modal.Header>
            <Modal.Content scrolling>
              <Grid>
                <Grid.Row>
                  <Grid.Column>
                    <Form>
                      <label>Cancellation Reason</label>
                      <TextArea
                        name="comments"
                        autoComplete="off"
                        maxLength="256"
                        onChange={(e) => {
                          this.handleChange(e);
                        }}
                      />
                    </Form>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Modal.Content>
            <Modal.Actions>
              <Button primary onClick={this.handleConfirmCancel.bind(this)}>
                Confirm
              </Button>
              <Button
                color="red"
                onClick={() => this.setState({ toggleCancelModal: false })}
              >
                Close
              </Button>
            </Modal.Actions>
          </Modal>
        </Container>

        {isShowItemModal ? (
          <ItemModal
            isShowItemModal={isShowItemModal}
            handleViewItemModal={this.handleViewItemModal.bind(this)}
            data={selectedItem}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  cancelOrder: state.cancelOrder
});

const mapDispatchToProps = (dispatch) => {
  return {
    getInProgressOrders: (currentPage, pageSize, requestNumber) =>
      dispatch(
        actions.getInProgressOrders(currentPage, pageSize, requestNumber)
      ),
    doCancelOrderRequesting: (param) =>
      dispatch(actions.doCancelOrderRequesting(param)),
    doCancelOrder: (param) => dispatch(actions.doCancelOrder(param)),
    clear: () => dispatch(actions.clearCancelOrder()),
    notificationCount: () => dispatch(actions.notificationCount()),
    updateData: (result) => dispatch(actions.updateData(result)),
    getActivePage: (page) => dispatch(commonActions.getActivePage(page))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CancelOrder);
