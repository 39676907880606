import * as types from "./cancel-order.type";
import api from "../../../services/api";

export const cancelOrderResult = (obj, type) => {
  return {
    data: obj,
    type: type,
  };
};

export const getRequestToCancelOrders = (currentPage, pageSize) => {
  return async (dispatch) => {
    await api.FieldEvaluation.getRequestToCancelOrders(currentPage, pageSize)
      .then((result) => {
        dispatch(cancelOrderResult(result, types.LIST_CANCEL_ORDER_SUCCESS));
      })
      .catch((result) => {
        dispatch(cancelOrderResult(result, types.LIST_CANCEL_ORDER_FAILURE));
      });
  };
};

export const doCancelOrder = (param) => {
  return async (dispatch) => {
    return await api.FieldEvaluation.cancel(param)
      .then((result) => {
        dispatch(cancelOrderResult(result, types.CANCEL_ORDER_SUCCESS));

        return result;
      })
      .catch((result) => {
        dispatch(cancelOrderResult(result, types.CANCEL_ORDER_FAILURE));
      });
  };
};

export const doCancelOrderRequesting = (param) => {
  return (dispatch) =>
    dispatch(cancelOrderResult(param, types.CANCEL_ORDER_REQUEST));
};

// export const doCancelOrderRequesting = param => {
//   return async dispatch => await new Promise((resolve, reject) => {
//     resolve(dispatch(cancelOrderResult(param, types.CANCEL_ORDER_REQUEST)))
//   });
// }

export const doRequestToCancel = (param) => {
  return async (dispatch) => {
    await api.FieldEvaluation.requestToCancel(param)
      .then((result) => {
        dispatch(cancelOrderResult(result, types.REQUEST_TO_CANCEL_SUCCESS));
      })
      .catch((result) => {
        dispatch(cancelOrderResult(result, types.REQUEST_TO_CANCEL_FAILURE));
      });
  };
};

export const notificationCount = () => {
  return (dispatch) =>
    dispatch(cancelOrderResult({}, types.NOTIFICATION_COUNT));
};

export const clearCancelOrder = () => (dispatch) =>
  dispatch(cancelOrderResult({}, types.CLEAR));

export const resetCancelOrder = () => (dispatch) =>
  dispatch(cancelOrderResult({}, types.RESET));

export const getCancellationCode = () => {
  return async (dispatch) => {
    await api.FieldEvaluation.getCancellationCode()
      .then((result) => {
        dispatch(cancelOrderResult(result, types.CANCELLATION_CODE_SUCCESS));
      })
      .catch((result) => {
        dispatch(cancelOrderResult(result, types.CANCELLATION_CODE_FAILURE));
      });
  };
};

export const updateCancellationCode = (result) => {
  return (dispatch) =>
    dispatch(cancelOrderResult(result, types.CANCELLATION_CODE_UPDATE));
};
