import React, { Component, Fragment } from "react";
import {
  Form,
  Grid,
  Divider,
  Checkbox,
  Input,
  TextArea,
  GridColumn,
  Icon,
  Popup
} from "semantic-ui-react";
import {
  CustomLabel,
  LabelBold
} from "../../../../../components/labels/labels";

class SiteSpecificRequirements extends Component {
  state = {};

  handleSelectPpe = (ppe, checked, tabIndex) => {
    this.props.updatePpeSelections(ppe, checked, tabIndex);
  };

  handleSelectTraining = (training, checked, isTrainingTwoHours, tabIndex) => {
    this.props.updateTrainingSelections(
      training,
      checked,
      isTrainingTwoHours,
      tabIndex
    );

    // 1: On-site Training, manual pricing triggers on 1 only
    if (training === 1 && isTrainingTwoHours != undefined)
      this.props.checkManualPricingTrigger(
        isTrainingTwoHours,
        "trainingTwoHours",
        tabIndex
      );
  };

  render() {
    const { reservationDetails, tabIndex, formType } = this.props;
    let preliminarySiteDetails = null;

    let ppeSelections = reservationDetails.ppeSelections;
    let trainingSelections = reservationDetails.trainingSelections;

    if (reservationDetails) {
      preliminarySiteDetails =
        reservationDetails.preliminarySiteLocationSection;
    }

    return (
      <Fragment>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <CustomLabel message="SITE SPECIFIC REQUIREMENTS" />
              <Divider fitted />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={3}>
            <Grid.Column>
              {trainingSelections &&
                trainingSelections.map((training, idx) => {
                  return (
                    <React.Fragment key={idx}>
                      <span>
                        <Divider fitted hidden />
                        <Checkbox
                          label={training.text}
                          checked={training.checked}
                          onChange={(e, { checked }) => {
                            this.handleSelectTraining(
                              training.value,
                              checked,
                              false,
                              tabIndex
                            );
                          }}
                        />
                      </span>
                      {/* index 0 is on-site training */}
                      {training.checked && training.value === 1 ? (
                        <div className="margin-left">
                          <Form.Field>
                            Will the training take more than 2 hours?
                          </Form.Field>
                          <Form.Field>
                            <Checkbox
                              radio
                              label="Yes"
                              name="isTrainingTwoHours"
                              value={true}
                              checked={training.trainingTwoHours === true}
                              onChange={(e, { value }) => {
                                this.handleSelectTraining(
                                  training.value,
                                  training.checked,
                                  value,
                                  tabIndex
                                );
                              }}
                            />
                            &nbsp;&nbsp;&nbsp;
                            <Checkbox
                              radio
                              label="No"
                              name="isTrainingTwoHours"
                              value={false}
                              checked={training.trainingTwoHours === false}
                              onChange={(e, { value }) => {
                                this.handleSelectTraining(
                                  training.value,
                                  training.checked,
                                  value,
                                  tabIndex
                                );
                              }}
                            />
                          </Form.Field>
                        </div>
                      ) : null}
                      {/* index 2 is others */}
                      {training.checked && training.value === 3 ? (
                        <div>
                          <Divider fitted hidden />
                          <Form.Field
                            id="form-input-control-lcn"
                            control={Input}
                            placeholder="Enter details"
                            name="otherTrainingDetails"
                            onChange={(e) => {
                              this.props.handleChange(e, formType);
                            }}
                            value={
                              preliminarySiteDetails.otherTrainingDetails || ""
                            }
                            error={this.props.getErrorFor(
                              "otherTrainingDetails",
                              formType
                            )}
                            autoComplete="off"
                            width="8"
                          />
                        </div>
                      ) : null}
                    </React.Fragment>
                  );
                })}
            </Grid.Column>
            <Grid.Column>
              <label>
                Provide any security steps necessary for the engineer to access
                the site
              </label>
              &nbsp;
              <Popup
                trigger={<Icon name="info circle" />}
                content="i.e. background check, NDA, etc."
                size="mini"
              />
              <TextArea
                id="form-input-control-lcn"
                placeholder="Enter details"
                name="securityStepDetail"
                onChange={(e) => {
                  this.props.handleChange(e, formType);
                }}
                value={preliminarySiteDetails.securityStepDetail || ""}
                error={this.props.getErrorFor("securityStepDetail", formType)}
                autoComplete="off"
                className="margin-top-5"
              />
            </Grid.Column>
            <Grid.Column>
              <label>
                Provide any other site specific requirements we need to be made
                aware of
              </label>
              &nbsp;
              <Popup
                trigger={<Icon name="info circle" />}
                content=" i.e. enviornmental requirments, such as clean room), parking requirements, etc"
                size="mini"
              />
              <TextArea
                id="form-input-control-lcn"
                placeholder="Enter details"
                name="otherSiteRequirements"
                onChange={(e) => {
                  this.props.handleChange(e, formType);
                }}
                value={preliminarySiteDetails.otherSiteRequirements || ""}
                error={this.props.getErrorFor(
                  "otherSiteRequirements",
                  formType
                )}
                autoComplete="off"
                className="margin-top-5"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <LabelBold
                message={"Select all required PPE from the list below"}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns="2">
            <GridColumn>
              <table>
                <tbody>
                  {ppeSelections &&
                    ppeSelections
                      .filter((p) => p.value <= 5)
                      .map((ppe, idx) => {
                        return (
                          <tr key={idx}>
                            <td>
                              <Checkbox
                                label={ppe.text}
                                checked={ppe.checked}
                                onChange={(e, { checked }) => {
                                  this.handleSelectPpe(
                                    ppe.value,
                                    checked,
                                    tabIndex
                                  );
                                }}
                              />
                            </td>
                          </tr>
                        );
                      })}
                </tbody>
              </table>
            </GridColumn>
            <GridColumn>
              <table>
                <tbody>
                  {ppeSelections &&
                    ppeSelections
                      .filter((p) => p.value >= 6)
                      .map((ppe, idx) => {
                        return (
                          <tr key={idx}>
                            <td>
                              <Checkbox
                                label={ppe.text}
                                checked={ppe.checked}
                                onChange={(e, { checked }) => {
                                  this.handleSelectPpe(
                                    ppe.value,
                                    checked,
                                    tabIndex
                                  );
                                }}
                              />
                            </td>
                          </tr>
                        );
                      })}
                </tbody>
              </table>
            </GridColumn>
          </Grid.Row>
        </Grid>
      </Fragment>
    );
  }
}

export default SiteSpecificRequirements;
