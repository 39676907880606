import { connect } from "react-redux";
import React, { Component } from "react";
import { Table } from "semantic-ui-react";

import { Common } from "../../../../../common/common";

import AssumptionValue from "./assumption-value";

class Assumption extends Component {
  createMarkup(string) {
    return { __html: string };
  }

  render() {
    const {
      projectDetails,
      fieldService: { orderDetails }
    } = this.props;

    const evaluatedSameDay =
      orderDetails && orderDetails.orderNumber
        ? orderDetails.evaluatedSameDay
        : Common.getSameDayVisitFlag();

    let assumption = AssumptionValue(
      projectDetails,
      null,
      true,
      (orderDetails || {}).evaluatedSameDay === undefined,
      evaluatedSameDay
    );
    return (
      <Table attached>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell textAlign="center">
              QUOTE ASSUMPTIONS
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell verticalAlign="top">
              <span
                dangerouslySetInnerHTML={this.createMarkup(assumption)}
              ></span>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    projectDetails: state.feProjectDetails,
    fieldService: state.fieldService
  };
};

export default connect(mapStateToProps, null)(Assumption);
