import { combineReducers } from "redux";
import profile from "./profile/profile.reducer";
import changePassword from "./change-password/change-password.reducer";
import resetPassword from "../../views/reset-password/reset-password.reducer";
import verifyEmail from "./verify-email/verify-email.reducer";
import common from "./common/common.reducer";

export default combineReducers({
  profile,
  changePassword,
  resetPassword,
  verifyEmail,
  common
});
