import * as types from "./account-settings.type";

const initialState = {
  hasError: false,
  message: "",
  isLoading: false,
  user: null,
  isLoadingGetSettings: false,
  isSuccessGetSettings: false,
  isSuccessGetCountries: false,
  countryOptions: [],
  countryOptionsBillTo: [],
  accountNumbersOptions: [],
  customerRelationsData: [],
  accountNumbersShipToOptions: [],
  customerRelationsShipToData: [],
  customerRelationInformationsData: [],
  customerRelationInformationsOptions: [],
  customerRelationInformationsShiptToData: [],
  customerRelationInformationsShiptToOptions: [],
  applicantContactInformations: [],
  applicantContactInformationsOptions: [],
  isSuccessGetStates: false,
  stateOptions: [],
  stateOptionsBillTo: [],
  isSuccessGetCities: false,
  hasAccountSettings: false,
  cityOptions: [],
  cityOptionsBillTo: [],
  data: [],
  form: {
    applicantDetails: {
      applicantDetails: {
        data: {},
        locationType: 1,
        showErrors: false,
        validationErrors: {}
      },
      applicantBillToLocationDetails: {
        data: {},
        locationType: 2,
        showErrors: false,
        validationErrors: {}
      },
      isSameAsAbove: false,
      showErrors: false,
      validationErrors: {}
    }
  }
};

const accountSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CREATE_ACCOUNT_SETTINGS_REQUEST:
      return {
        ...state,
        isSuccess: false,
        isLoading: true
      };
    case types.CREATE_ACCOUNT_SETTINGS_SUCCESS:
      return {
        ...state,
        isSuccess: action.data.isSuccessful,
        isLoading: false,
        user: action.data.data
      };
    case types.CREATE_ACCOUNT_SETTINGS_FAILURE:
      return {
        ...state,
        isSuccess: false,
        isLoading: false
      };
    case types.GET_COUNTRIES_REQUEST:
      return {
        ...state,
        isSuccessGetCountries: false,
        countryOptions: []
      };
    case types.GET_BILLTO_COUNTRIES_REQUEST:
      return {
        ...state,
        isSuccessGetCountries: false,
        countryOptionsBillTo: []
      };
    case types.GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        isSuccessGetCountries: true,
        countryOptions: action.data.map((c) => {
          return {
            key: c.countryCode,
            value: c.countryCode,
            text: c.countryName
          };
        })
      };
    case types.GET_BILLTO_COUNTRIES_SUCCESS:
      return {
        ...state,
        isSuccessGetCountries: true,
        countryOptionsBillTo: action.data.map((c) => {
          return {
            key: c.countryCode,
            value: c.countryCode,
            text: c.countryName
          };
        })
      };
    case types.GET_COUNTRIES_FAILURE:
      return {
        ...state,
        isSuccessGetCountries: false,
        countryOptions: []
      };
    case types.GET_BILLTO_COUNTRIES_FAILURE:
      return {
        ...state,
        isSuccessGetCountries: false,
        countryOptionsBillTo: []
      };
    case types.GET_STATES_REQUEST:
      return {
        ...state,
        isSuccessGetStates: false,
        stateOptions: []
      };
    case types.GET_BILLTO_STATES_REQUEST:
      return {
        ...state,
        isSuccessGetStates: false,
        stateOptionsBillTo: []
      };
    case types.GET_STATES_SUCCESS:
      return {
        ...state,
        isSuccessGetStates: true,
        stateOptions: action.data.map((c) => {
          return {
            key: c.stateCode,
            value: c.stateCode,
            text: c.stateName
          };
        })
      };
    case types.GET_BILLTO_STATES_SUCCESS:
      return {
        ...state,
        isSuccessGetStates: true,
        stateOptionsBillTo: action.data.map((c) => {
          return {
            key: c.stateCode,
            value: c.stateCode,
            text: c.stateName
          };
        })
      };
    case types.GET_STATES_FAILURE:
      return {
        ...state,
        isSuccessGetStates: false,
        stateOptions: []
      };
    case types.GET_BILLTO_STATES_FAILURE:
      return {
        ...state,
        isSuccessGetStates: false,
        stateOptionsBillTo: []
      };
    case types.GET_CITIES_REQUEST:
      return {
        ...state,
        isSuccessGetCities: false,
        cityOptions: []
      };
    case types.GET_BILLTO_CITIES_REQUEST:
      return {
        ...state,
        isSuccessGetCities: false,
        cityOptionsBillTo: []
      };
    case types.GET_CITIES_SUCCESS:
      return {
        ...state,
        isSuccessGetCities: true,
        cityOptions: action.data.map((c) => {
          return {
            key: c.cityName,
            value: c.cityName,
            text: c.cityName
          };
        })
      };
    case types.GET_BILLTO_CITIES_SUCCESS:
      return {
        ...state,
        isSuccessGetCities: true,
        cityOptionsBillTo: action.data.map((c) => {
          return {
            key: c.cityName,
            value: c.cityName,
            text: c.cityName
          };
        })
      };
    case types.GET_CITIES_FAILURE:
      return {
        ...state,
        isSuccessGetCities: false,
        cityOptions: []
      };
    case types.GET_BILLTO_CITIES_FAILURE:
      return {
        ...state,
        isSuccessGetCities: false,
        cityOptionsBillTo: []
      };
    case types.GET_ACCOUNT_SETTINGS_REQUEST:
      return {
        ...state,
        isSuccessGetSettings: false,
        isLoadingGetSettings: true
      };
    case types.GET_ACCOUNT_SETTINGS_SUCCESS:
      var primarySettings = action.data.billToSettings.filter(
        (b) => b.locationType === 1
      );
      var billToSettings = action.data.billToSettings.filter(
        (b) =>
          b.locationType === 2 &&
          b.primaryEmailAddress == primarySettings[0].emailAddress
      );

      primarySettings = primarySettings.length > 0 ? primarySettings[0] : {};
      billToSettings =
        billToSettings.length > 0
          ? billToSettings[billToSettings.length - 1]
          : {};

      primarySettings.showErrors = false;
      primarySettings.validationErrors = {};

      billToSettings.showErrors = false;
      billToSettings.validationErrors = {};

      var result = Object.assign({}, state, {
        isSuccessGetSettings: true,
        isLoadingGetSettings: false,
        hasAccountSettings: action.data.hasAccountSettings,
        form: {
          accountSettings: {
            isSentByEmail: action.data.isSentByEmail,
            isReferenceNoUsed: action.data.isReferenceNoUsed,
            accountSettingsUserId: action.data.accountSettingsUserId,
            showErrors: false,
            validationErrors: {}
          },
          // primary: primarySettings,
          // billTo: billToSettings,
          applicantDetails: {
            applicantDetails: primarySettings,
            applicantBillToLocationDetails: billToSettings,
            isSameAsAbove: false,
            showErrors: false,
            validationErrors: {}
          }
        }
      });

      return result;
    case types.GET_ACCOUNT_SETTINGS_FAILURE:
      return {
        ...state,
        isSuccessGetSettings: false,
        isLoadingGetSettings: false
      };
    case types.UPDATE_ACCOUNT_SETTINGS_FORM:
      var newState = {
        ...state,
        form: {
          ...state.form
        }
      };
      return newState;
    case types.CREATE_ACCOUNT_SETTINGS_INITIALIZE:
      var origState = Object.assign({}, state, {
        isSuccess: false,
        isSuccessGetSettings: false,
        isLoading: false,
        form: {
          applicantDetails: {
            applicantDetails: {
              data: {},
              locationType: 1,
              showErrors: false,
              validationErrors: {}
            },
            applicantBillToLocationDetails: {
              data: {},
              locationType: 2,
              showErrors: false,
              validationErrors: {}
            },
            isSameAsAbove: false,
            showErrors: false,
            validationErrors: {}
          }
        }
      });
      return origState;
    case types.GET_CUSTOMER_RELATIONS_REQUEST:
      return {
        ...state,
        accountNumbersOptions: null,
        customerRelationsData: null,
        isLoading: true,
        isLoadingRelation: true
      };
    case types.GET_CUSTOMER_RELATIONS_SUCCESS:
      return {
        ...state,
        customerRelationsData: action.data,
        accountNumbersOptions: action.data.map((cr, idx) => {
          return {
            key: idx,
            value: cr.accountNumber,
            text: cr.accountNumber + " - " + cr.accountName
          };
        }),
        isLoading: false,
        isLoadingRelation: false
      };
    case types.GET_CUSTOMER_RELATIONS_FAILURE:
      return {
        ...state,
        accountNumbersOptions: null,
        customerRelationsData: null,
        isLoading: false,
        isLoadingRelation: false
      };
    case types.GET_CUSTOMER_RELATIONS_SHIPTO_REQUEST:
      return {
        ...state,
        accountNumbersShipToOptions: null,
        customerRelationsShipToData: null,
        isLoading: true
      };
    case types.GET_CUSTOMER_RELATIONS_SHIPTO_SUCCESS:
      return {
        ...state,
        customerRelationsShipToData: action.data,
        accountNumbersShipToOptions: action.data.map((cr, idx) => {
          return {
            key: idx,
            value: cr.accountNumber,
            text: cr.accountNumber
          };
        }),
        isLoading: false
      };
    case types.GET_CUSTOMER_RELATIONS_SHIPTO_FAILURE:
      return {
        ...state,
        accountNumbersShipToOptions: null,
        customerRelationsShipToData: null,
        isLoading: false
      };
    case types.GET_CUSTOMER_RELATION_INFORMATIONS_REQUEST:
      return {
        ...state,
        customerRelationInformationsOptions: null,
        customerRelationInformationsData: null,
        isLoading: true,
        isLoadingRelationInformation: true
      };
    case types.GET_CUSTOMER_RELATION_INFORMATIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoadingRelationInformation: false,
        customerRelationInformationsData: action.data,
        customerRelationInformationsOptions: action.data.map((cr, idx) => {
          return {
            key: idx,
            value: cr.contactName,
            text: cr.contactName
          };
        })
      };
    case types.GET_CUSTOMER_RELATION_INFORMATIONS_FAILURE:
      return {
        ...state,
        customerRelationInformationsOptions: null,
        customerRelationInformationsData: null,
        isLoading: false,
        isLoadingRelationInformation: false
      };
    case types.GET_CUSTOMER_RELATION_INFORMATIONS_REQUEST_SHIP_TO:
      return {
        ...state,
        customerRelationInformationsShiptToOptions: null,
        customerRelationInformationsShiptToData: null,
        isLoading: true
      };
    case types.GET_CUSTOMER_RELATION_INFORMATIONS_SUCCESS_SHIP_TO:
      return {
        ...state,
        isLoading: false,
        customerRelationInformationsShiptToData: action.data,
        customerRelationInformationsShiptToOptions: action.data.map(
          (cr, idx) => {
            return {
              key: idx,
              value: cr.contactName,
              text: cr.contactName
            };
          }
        )
      };
    case types.GET_CUSTOMER_RELATION_INFORMATIONS_FAILURE_SHIP_TO:
      return {
        ...state,
        customerRelationInformationsShiptToOptions: null,
        customerRelationInformationsShiptToData: null,
        isLoading: false
      };
    case types.RESET_CUSTOMER_RELATION_INFORMATIONS:
      return {
        ...state,
        customerRelationInformationsOptions: [],
        isLoading: false
      };
    case types.GET_APPLICANT_CONTACT_INFORMATIONS_REQUEST:
      return {
        ...state,
        applicantContactInformations: null,
        applicantContactInformationsOptions: null,
        isLoading: true
      };
    case types.GET_APPLICANT_CONTACT_INFORMATIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        applicantContactInformations: action.data,
        applicantContactInformationsOptions: action.data.map((cr, idx) => {
          return {
            key: idx,
            value: cr.contactName,
            text: cr.contactName
          };
        })
      };
    case types.GET_APPLICANT_CONTACT_INFORMATIONS_FAILURE:
      return {
        ...state,
        applicantContactInformations: null,
        applicantContactInformationsOptions: null,
        isLoading: false
      };
    case types.UPDATE_BILL_TO_ACCCOUNT_NUMBERS:
      return {
        ...state,
        accountNumbersOptions: action.data
      };
    default:
      return state;
  }
};

export default accountSettingsReducer;
