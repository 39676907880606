export const FORM = "FORM";
export const CLEAR = "CLEAR";

export const USER_REQUEST = "USER_REQUEST";
export const USER_FAILURE = "USER_FAILURE";
export const USER_SUCCESS = "USER_SUCCESS";

export const USER_VALIDATE_TOKEN_REQUEST = "USER_VALIDATE_TOKEN_REQUEST";
export const USER_VALIDATE_TOKEN_SUCCESS = "USER_VALIDATE_TOKEN_SUCCESS";
export const USER_VALIDATE_TOKEN_FAILURE = "USER_VALIDATE_TOKEN_FAILURE";

export const USER_RESEND_TOKEN_REQUEST = "USER_RESEND_TOKEN_REQUEST";
export const USER_RESEND_TOKEN_SUCCESS = "USER_RESEND_TOKEN_SUCCESS";
export const USER_RESEND_TOKEN_FAILURE = "USER_RESEND_TOKEN_FAILURE";

export const USER_TOKEN_DETAILS_REQUEST = "USER_TOKEN_DETAILS_REQUEST";
export const USER_TOKEN_DETAILS_SUCCESS = "USER_TOKEN_DETAILS_SUCCESS";
export const USER_TOKEN_DETAILS_FAILURE = "USER_TOKEN_DETAILS_FAILURE";

export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

