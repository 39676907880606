import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import {
  Container,
  Button,
  Table,
  Divider,
  Image,
  Form,
  Checkbox,
  Message,
  Grid,
  Label,
  Input,
  Dropdown,
  Tab,
  Menu,
  Icon,
  Segment
} from "semantic-ui-react";
import FooterHomeBlock from "../../../../components/footer/footer-home-block";
import { connect } from "react-redux";
import {
  CustomTitleLabel,
  SubLabel,
  SubTextItalic,
  CustomHeaderLabel,
  CustomLabel
} from "../../../../components/labels/labels";
import { Link } from "react-router-dom";
import {
  getLabels,
  selectLabel,
  selectAllLabels,
  setQuantity,
  resetLabelOrderForm,
  removeCartItems,
  addToCart,
  resetAddToCart,
  setUnitOfMeasurement
} from "../../../../views/manufacturer/standard/labels/labels.action";
import * as commonActions from "../../../../views/user/common/common.action";

import { getActiveManufacturers } from "../../../../views/applicant/standard/applicant-dashboard.action";
import { getFileNumbers } from "../../../../views/manufacturer/standard/file-volumes/file-volumes.action";
import { getUnitsOfMeasurement } from "../../../../views/label-order-cart/cart/label-order-cart.action";
import { updateLabelOrderCartType } from "../../../../views/manufacturer/standard/label-cart/label-cart.action";
import { toast } from "react-semantic-toasts";
import { Common } from "../../../../common";
import Role from "../../../../enums/role";
// import { textAlign } from "html2canvas/dist/types/css/property-descriptors/text-align";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2
});

class Labels extends Component {
  state = {
    cartStatus: -1,
    isLoadData: true,
    activeIndex: 0
  };

  // selectedLabels = [];
  fileNumber = null;
  ccn = null;
  ccnList = null;
  fileNumberList = null;
  labelOrderCartId = null;
  existingLabelOrderCartId = null;
  volume = null;
  volumeList = null;
  manufacturerId = null;
  isCopied = null;
  accountNumber = null;
  labelOrderType = null;
  roleId = Common.getUserRole();
  psnCountry = null;
  accountName = null;

  addToCart = () => {
    const { activeIndex } = this.state;
    const { data } = this.props;
    var fileNumbers =
      this.isCopied == "true"
        ? data.map((item) => {
            return item.fileNumber;
          })
        : this.fileNumberList.split(",").filter(this.onlyUnique);
    var length = fileNumbers.length;
    var activeFileNumber = fileNumbers[activeIndex];
    var hasSelectedLabel = data[
      this.isCopied == "true" ? activeIndex : 0
    ].labelDetails.filter(
      (y) => y.fileNumber == activeFileNumber && y.isSelected == true
    );

    if (hasSelectedLabel.length > 0) {
      if (activeIndex == length - 1) {
        var cartDetails = {
          labelOrderCartId: this.labelOrderCartId,
          existingLabelOrderCartId: this.existingLabelOrderCartId,
          fileNumber: this.fileNumber,
          labelOrderCartItems: this.props.selectedLabels,
          cartStatus: 2,
          totalPrintingFee: this.props.total,
          totalHandlingFee: this.props.totalHandlingFee,
          grantTotal: this.props.grantTotal,
          accountNumber: this.accountNumber,
          labelOrderType: 1,
          isCopied: this.isCopied
        };

        this.props.addToCart(cartDetails).then((result) => {
          if (!result.isSuccessful) {
            if (
              result.code == 500 &&
              result.message == "Duplicate Cart Error"
            ) {
              setTimeout(() => {
                toast({
                  title: "",
                  description: (
                    <p>
                      {
                        "You have an existing cart. Please checkout the cart first before placing new order."
                      }
                    </p>
                  ),
                  time: 10000,
                  size: "small",
                  icon: "",
                  color: "red"
                });
              }, 100);
            }
          }
        });
        this.setState({ cartStatus: 2 });
      } else {
        this.setState({ activeIndex: activeIndex + 1 });
      }
    } else {
      setTimeout(() => {
        toast({
          title: "",
          description: <p>{"Please choose a label"}</p>,
          time: 10000,
          size: "small",
          icon: "",
          color: "red"
        });
      }, 100);
    }
  };

  saveDraft = () => {
    var cartDetails = {
      labelOrderCartId: this.labelOrderCartId,
      fileNumber: this.fileNumber,
      labelOrderCartItems: this.props.selectedLabels,
      status: 1,
      cartStatus: 1
    };

    this.props.addToCart(cartDetails);

    this.setState({ cartStatus: 1 });
  };

  handleChangeQuantity = (e, cartItem) => {
    const re = /^[0-9\b]+$/;

    if (
      e.target.value % cartItem.minOrderQuantity != 0 ||
      e.target.value == 0
    ) {
      e.target.value = cartItem.quantity;
      setTimeout(() => {
        toast({
          title: "",
          description: (
            <p>
              {
                "Invalid quantity. Please refer to the label package description for min. order quantity."
              }
            </p>
          ),
          time: 10000,
          size: "small",
          icon: "",
          color: "red"
        });
      }, 100);
    }

    if (e.target.value !== "" || re.test(e.target.value)) {
      this.props.setQuantity(cartItem, e.target.value);
    }
  };

  handleChangeUnitOfMeasurement = (uom, cartItem) => {
    this.props.setUnitOfMeasurement(cartItem, uom);
  };

  removeSelections = () => {
    // var cartData = {
    //   cartItemIds: this.props.selectedLabels.map(s => {
    //     return s.id;
    //   })
    // };
    // if (cartData.cartItemIds.length > 0) {
    //   this.props.deleteCart(cartData);
    //   this.props.removeCartItems(cartData.cartItemIds);
    // }
  };

  getUrlParams = () => {
    this.ccn = this.props.match.params.ccn;
    this.ccnList = this.props.match.params.ccnList;
    this.fileNumberList = this.props.match.params.fileNumberList;
    this.fileNumber = this.props.match.params.fileNumber;
    this.isCopied = this.props.match.params.isCopied;
    this.accountNumber = this.props.match.params.accountNumber;
    this.labelOrderCartId = this.props.match.params.labelOrderCartId
      ? parseInt(this.props.match.params.labelOrderCartId)
      : 0;
    this.existingLabelOrderCartId = this.labelOrderCartId;
    this.volume = this.props.match.params.volume;
    this.volumeList = this.props.match.params.volumeList;
    this.manufacturerId = this.props.match.params.manufacturerId;
    this.labelOrderType = this.props.match.params.labelOrderType;
    this.psnCountry = this.props.psnCountry;
  };

  redirectPage() {}

  componentDidMount() {
    this.props.resetLabelOrderForm();
    this.props.getUnitsOfMeasurement();
    this.props.updateLabelOrderCartType(1);

    this.getUrlParams();

    this.props.getLabels(
      this.labelOrderCartId,
      this.ccn,
      this.manufacturerId,
      this.fileNumber,
      this.ccnList,
      this.fileNumberList,
      this.volume,
      this.volumeList,
      this.accountNumber
    );

    if (this.roleId === 1) {
      this.props
        .getActiveManufacturers(
          1,
          100,
          null,
          null,
          [],
          [],
          [],
          [],
          [],
          [],
          1,
          true
        )
        .then((result) => {
          this.accountName = result?.applicantFileVolumeDetails?.data?.filter(
            (a) => a.manufacturerAccount == this.accountNumber
          )[0]?.manufacturerName;
        });
    } else {
      this.props
        .getApplicantFileNumbers(null, 2, 1, 5, [], [], [], [], [], [], 1, true)
        .then((result) => {
          this.accountName = result?.fileVolumeDetails?.data?.filter(
            (a) => a.applicantAccount == this.accountNumber
          )[0]?.applicantName;
        });
    }

    this.props.getActivePage("Standard Label Order");
  }

  handleTabChange = (e, { activeIndex }) => {
    this.setState({ activeIndex });
  };

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  getColumnWidth(length) {
    // if (length == 1) return "86%";
    // else if (length == 2) return "76%";
    // else if (length == 3) return "67%";
    // else if (length == 4) return "57%";
    // else return "86%";
    var activeTabWidth = length * 200;
    return "calc(100% - " + activeTabWidth + "px )";
  }

  renderLabelOrderPackage = (labelOrderPackage) => {
    const translations = Common.getTranslations() || {};

    if (labelOrderPackage && labelOrderPackage != "EACH") {
      labelOrderPackage = labelOrderPackage
        .toUpperCase()
        .replace("RL", translations["LexRL"])
        .replace("LABELS", translations["LexLabels"].toUpperCase());
    }
    return labelOrderPackage;
  };

  render() {
    const {
      data,
      isLoading,
      isSelectedAll,
      total,
      totalHandlingFee,
      grantTotal,
      isSuccessAddToCart,
      unitsOfMeasurement,
      applicantDashboard,
      filevolumes
    } = this.props;

    const translations = Common.getTranslations() || {};

    let url = "";
    let roleName =
      this.roleId === 1
        ? translations["LexAddLabelsForManufacturer"]
        : translations["LexAddLabelsForApplicant"];

    const { cartStatus, isLoadData, activeIndex } = this.state;
    const panes = [];

    if (this.fileNumberList == null) {
      if (data && data.length > 0) {
        this.fileNumberList = data[0].fileNumber;
      }
    }

    if (this.fileNumberList) {
      var fileNummbers =
        this.isCopied == "true"
          ? data.map((item) => {
              return item.fileNumber;
            })
          : this.fileNumberList.split(",").filter(this.onlyUnique);

      for (let index = 0; index < fileNummbers.length; index++) {
        const element = fileNummbers[index];

        const pane = {
          name: `${translations["LexFileNo."]} ${element}`,
          menuItem: `${translations["LexFileNo."]} ${element}`,
          render: () => (
            <Tab.Pane key={index}>
              <Form loading={isLoading}>
                {data &&
                  data.length > 0 &&
                  data
                    .filter((item) =>
                      this.isCopied == "true"
                        ? item.fileNumber == element
                        : true
                    )
                    .map((item, idx) => {
                      {
                        return (
                          <Fragment>
                            {item.labelDetails &&
                              item.labelDetails
                                .filter((y) => y.fileNumber == element)
                                .map((cartItem, cartItemIndex) => {
                                  return (
                                    <Segment
                                      style={{
                                        backgroundColor: "#FAFAFA",
                                        border: "none",
                                        boxShadow: "none"
                                      }}
                                    >
                                      <table
                                        style={{
                                          backgroundColor: "transparent",
                                          border: "none",
                                          color: "#8D8D8D"
                                        }}
                                      >
                                        <Table.Body>
                                          <Table.Row key={cartItemIndex}>
                                            <Table.Cell
                                              style={{
                                                textAlign: "center",
                                                width: "5%"
                                              }}
                                            >
                                              <Checkbox
                                                style={{
                                                  border: "2px solid black",
                                                  padding: "0px",
                                                  height: "21px",
                                                  width: "21px"
                                                }}
                                                checked={cartItem.isSelected}
                                                onChange={(e, { checked }) => {
                                                  this.props.selectLabel(
                                                    cartItem,
                                                    checked
                                                  );
                                                }}
                                              />
                                            </Table.Cell>
                                            <Table.Cell
                                              style={{
                                                textAlign: "center",
                                                width: "12%"
                                              }}
                                            >
                                              <Image
                                                size="large"
                                                src={cartItem.imageUrl}
                                              />
                                            </Table.Cell>
                                            <Table.Cell
                                              collapsing
                                              style={{
                                                textAlign: "left",
                                                borderRight:
                                                  "1px solid #E5E5E5",
                                                width: "55%",
                                                paddingLeft: "20px"
                                              }}
                                            >
                                              <Table
                                                style={{
                                                  border: "none",
                                                  backgroundColor:
                                                    "transparent",
                                                  color: "#8D8D8D"
                                                }}
                                              >
                                                <Table.Row>
                                                  <Table.Cell
                                                    style={{
                                                      padding: "2px",
                                                      border: "none",
                                                      width: "20%"
                                                    }}
                                                  >
                                                    <b>{cartItem.ccn}</b>
                                                  </Table.Cell>
                                                  <Table.Cell
                                                    style={{
                                                      padding: "2px",
                                                      border: "none"
                                                    }}
                                                  >
                                                    {cartItem.labelAccount}
                                                  </Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                  <Table.Cell
                                                    style={{
                                                      padding: "2px",
                                                      border: "none"
                                                    }}
                                                  >
                                                    <b>
                                                      {
                                                        translations[
                                                          "LexULItemNumber"
                                                        ]
                                                      }
                                                    </b>
                                                  </Table.Cell>
                                                  <Table.Cell
                                                    style={{
                                                      padding: "2px",
                                                      border: "none",
                                                      color: "#80B0D2"
                                                    }}
                                                  >
                                                    <b>{cartItem.itemNumber}</b>
                                                  </Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                  <Table.Cell
                                                    style={{
                                                      padding: "2px",
                                                      border: "none"
                                                    }}
                                                  >
                                                    <b>
                                                      {
                                                        translations[
                                                          "LexItemDescription"
                                                        ]
                                                      }
                                                      :
                                                    </b>
                                                  </Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                  <Table.Cell
                                                    colSpan="2"
                                                    style={{
                                                      padding: "2px",
                                                      border: "none",
                                                      color: "#80B0D2"
                                                    }}
                                                  >
                                                    <div
                                                      style={{
                                                        whiteSpace: "normal"
                                                      }}
                                                    >
                                                      <b>
                                                        {cartItem.description}
                                                      </b>
                                                    </div>
                                                  </Table.Cell>
                                                </Table.Row>
                                              </Table>
                                            </Table.Cell>
                                            <Table.Cell
                                              collapsing
                                              style={{
                                                textAlign: "right",
                                                width: "100%",
                                                paddingLeft: "20px"
                                              }}
                                            >
                                              <table className="details-table">
                                                <tr>
                                                  <td
                                                    className="label-cell"
                                                    style={{
                                                      textAlign: "left",
                                                      width: "100%"
                                                    }}
                                                  >
                                                    <b>
                                                      {
                                                        translations[
                                                          "LexLabelPrice"
                                                        ]
                                                      }{" "}
                                                      (USD)
                                                    </b>
                                                  </td>
                                                  <td className="label-cell">
                                                    {" "}
                                                    {`${cartItem.newPrice.toFixed(
                                                      2
                                                    )}`}
                                                  </td>
                                                  <td></td>
                                                </tr>
                                                <tr>
                                                  <td
                                                    className="label-cell"
                                                    style={{
                                                      textAlign: "left",
                                                      verticalAlign: "middle"
                                                    }}
                                                  >
                                                    <b>
                                                      {
                                                        translations[
                                                          "LexQuantity"
                                                        ]
                                                      }
                                                    </b>
                                                  </td>
                                                  <td
                                                    className="label-cell"
                                                    style={{ width: "65%" }}
                                                  >
                                                    <input
                                                      disabled={
                                                        !cartItem.isSelected
                                                      }
                                                      placeholder="Quantity"
                                                      onChange={(e) => {
                                                        this.handleChangeQuantity(
                                                          e,
                                                          cartItem
                                                        );
                                                      }}
                                                      step={
                                                        cartItem.minOrderQuantity
                                                      }
                                                      min={
                                                        cartItem.minOrderQuantity
                                                      }
                                                      value={cartItem.quantity}
                                                      type="number"
                                                      style={{
                                                        width: "170px"
                                                      }}
                                                    />
                                                    <Divider hidden fitted />
                                                  </td>
                                                  <td
                                                    className="label-cell"
                                                    style={{
                                                      textAlign: "left",
                                                      verticalAlign: "middle"
                                                    }}
                                                  >
                                                    {cartItem.oracleUnitOfMeasurementName ==
                                                    "RL"
                                                      ? translations["LexRL"]
                                                      : translations["LexEa"]}
                                                  </td>
                                                </tr>
                                              </table>
                                              <table>
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      className="label-cell"
                                                      style={{
                                                        color: "#F6CD91"
                                                      }}
                                                    >
                                                      <Icon name="small exclamation circle" />
                                                    </td>
                                                    <td className="label-cell">
                                                      <SubLabel
                                                        message={
                                                          translations[
                                                            "LexLabelPackageDesc"
                                                          ]
                                                        }
                                                      />
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td></td>
                                                    <td
                                                      className="label-cell"
                                                      style={{
                                                        textAlign: "left"
                                                      }}
                                                    >
                                                      <b>
                                                        <SubLabel
                                                          message={this.renderLabelOrderPackage(
                                                            cartItem.labelOrderPackage
                                                          )}
                                                        />
                                                      </b>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                              {/* <Divider hidden fitted />
                                  <SubLabel message="UOM" /> */}
                                            </Table.Cell>
                                            <Table.Cell>
                                              {/* <Input
                                    disabled={!cartItem.isSelected}
                                    placeholder="Quantity"
                                    onChange={e => {
                                      if (isNaN(e.target.value)) {
                                        return;
                                      }
                                      this.props.setQuantity(
                                        cartItem,
                                        e.target.value
                                      );
                                    }}
                                    size="mini"
                                    maxLength="5"
                                    value={cartItem.quantity}
                                  /> */}
                                              {/* <Input
                                    disabled={!cartItem.isSelected}
                                    placeholder="Quantity"
                                    onChange={(e) => {
                                      this.handleChangeQuantity(e, cartItem);
                                    }}
                                    size="mini"
                                    maxLength="5"
                                    min="1"
                                    value={cartItem.quantity}
                                  /> */}

                                              {/* <Divider hidden fitted />
                                  <SubLabel
                                    message={cartItem.unitOfMeasurementName}
                                  /> */}
                                            </Table.Cell>
                                          </Table.Row>
                                        </Table.Body>
                                      </table>
                                    </Segment>
                                  );
                                })}
                          </Fragment>
                        );
                      }
                    })}
                {!data && (
                  <Table>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell>
                          <CustomLabel message="NO DATA FOUND." />
                        </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                )}
                {data && data.length === 0 && (
                  <Table>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell>
                          <CustomLabel message="NO DATA FOUND." />
                        </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                )}
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={11}>
                      {/* <span style={{ fontSize: "17px", fontWeight: "bold" }}>
                      Please note that costs may vary as a weekly fix rate is
                      issued and that UL reserves the right to increase prices
                      in the cost of our standard labels at any time.
                    </span>
                    <br />
                    <br />
                    <span style={{ fontSize: "17px", fontWeight: "bold" }}>
                      The invoice will be issued in local currency based on the
                      “Bill To” account in some countries.
                    </span>
                    <br />
                    <br />
                    <span style={{ fontSize: "17px", fontWeight: "bold" }}>
                      The fee amount shown on the label order form is the net
                      label printing fee & handling fee, not including shipping
                      fee. The amount is just for reference only, please refer
                      to the final amount on the invoice.
                    </span>
                    <br />
                    <br />
                    <span style={{ fontSize: "17px", fontWeight: "bold" }}>
                      Each label item is charged $35 for handling fee
                    </span>
                    <br />
                    <br />
                    <span style={{ fontSize: "17px", fontWeight: "bold" }}>
                      If applicable, the Production Volume Fee ( also known as
                      Service Charge) will be invoiced at month end
                    </span> */}
                    </Grid.Column>
                    <Grid.Column width={5} style={{ textAlign: "right" }}>
                      {/* <CustomHeaderLabel message="Total Label Printing Fee: " />
                    <Label key="big" size="big" style={{ marginRight: "10px" }}>
                      <span style={{ color: "#00518a" }}>
                        {total.toFixed(2)} USD
                      </span>
                    </Label>
                    <br /> <br />
                    <CustomHeaderLabel message="Total Label Handling Fee: " />
                    <Label key="big" size="big" style={{ marginRight: "10px" }}>
                      <span style={{ color: "#00518a" }}>
                        {totalHandlingFee.toFixed(2)} USD
                      </span>
                    </Label>
                    <br /> <br />
                    <CustomHeaderLabel message="Order Total (Before Shipping): " />
                    <Label key="big" size="big" style={{ marginRight: "10px" }}>
                      <span style={{ color: "#00518a" }}>
                        {grantTotal.toFixed(2)} USD
                      </span>
                    </Label> */}
                      <Divider hidden />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form>
            </Tab.Pane>
          )
        };
        panes.push(pane);
      }

      panes.push({
        menuItem: (
          <Menu.Item
            style={{
              width: this.getColumnWidth(fileNummbers.length)
            }}
            disabled
          >
            <table
              style={{
                width: "100%"
              }}
            >
              <tr>
                <td style={{ color: "#1b1c1d" }}>
                  <Checkbox
                    style={{
                      border: "2px solid black",
                      padding: "0px",
                      height: "23px",
                      width: "23px",
                      marginRight: "10px",
                      verticalAlign: "middle"
                    }}
                    checked={isSelectedAll}
                    onChange={(e, { checked }) => {
                      this.props.selectAllLabels(checked);
                    }}
                  />
                  {translations["LexSelectall"]}
                </td>
                <td style={{ width: "150px", textAlign: "right" }}>
                  {" "}
                  <Button
                    style={{
                      backgroundColor: "#ffffff",
                      color: "#1b1c1d",
                      border: "1px solid #1b1c1d"
                    }}
                    compact
                    as={Link}
                    to="/home/label-order-cart"
                  >
                    {translations["LexViewCart"]}
                  </Button>
                </td>
              </tr>
            </table>
          </Menu.Item>
        )
      });
    }

    if (this.labelOrderType === "2")
      return (
        <Redirect
          to={`/home/existing-combination-labels/${
            this.fileNumber
          }/${localStorage.getItem("customerId")}/${this.labelOrderCartId}`}
        />
      );
    else {
      if (this.isCopied) this.labelOrderCartId = 0;
    }

    if (this.roleId == Role.applicant) url = "/home/applicant-dashboard/1";
    else if (this.roleId == Role.manufacturer)
      url = "/home/order-standard-label/1";
    //console.log("isSuccessAddToCart " + isSuccessAddToCart);

    if (data == null && !isLoadData) {
      this.setState({ isLoadData: true });
    }

    if (data != null && isLoadData) {
      this.setState({ isLoadData: false });
      this.props.selectLabel(data);
    }

    if (isSuccessAddToCart) {
      this.props.resetAddToCart();
      localStorage.setItem("cartLabelOrderType", 1);
      this.props.updateLabelOrderCartType(1);
      toast({
        title: "",
        description: (
          <p>{Common.getTranslations()["LexItemAddedtoCartAlert"]}</p>
        ),
        time: 5000,
        size: "tiny",
        icon: "check",
        color: "green"
      });
      setTimeout(function () {}, 2000);
    }

    return isSuccessAddToCart ? (
      <Redirect to="/home/label-order-cart" />
    ) : (
      <Fragment>
        <div>
          <Container fluid style={{ padding: "20px" }}>
            <Grid>
              <Grid.Row>
                <Grid.Column width={11}>
                  <CustomTitleLabel
                    message={`${roleName} ${
                      this.accountName || ""
                    } (PSN-${Common.getPartySiteNumber()})`}
                  />
                  <Divider hidden fitted />
                  <Divider hidden fitted />
                  <Divider hidden fitted />
                  <Divider hidden fitted />
                  <Button
                    style={{
                      backgroundColor: "#ffffff",
                      color: "#1b1c1d",
                      border: "1px solid #1b1c1d"
                    }}
                    compact
                    as={Link}
                    to={url}
                  >
                    {translations["LexSwitchFile"]}
                  </Button>
                </Grid.Column>
                <Grid.Column width={5} style={{ textAlign: "right" }}>
                  <span hidden={!this.labelOrderCartId}>
                    <Button
                      basic
                      primary
                      compact
                      disabled={this.props.selectedLabels.length === 0}
                      onClick={this.removeSelections}
                    >
                      Remove Selections
                    </Button>
                  </span>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <div style={{ border: "1px solid #DCDEE1" }}></div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            {cartStatus === 2 && (
              <Message
                success
                hidden={!isSuccessAddToCart}
                header="Label/s successfully added to cart."
                content="you may go back to your other file numbers to add more labels."
              />
            )}
            {cartStatus === 1 && (
              <Message
                warning
                hidden={!isSuccessAddToCart}
                header="Label/s successfully saved as draft."
                content="you may go back to your other file numbers to add more labels."
              />
            )}
            <Divider hidden />
            <div>
              <Tab
                panes={panes}
                activeIndex={activeIndex}
                onTabChange={this.handleTabChange}
              ></Tab>
              <Divider hidden />

              <Grid>
                <Grid.Row>
                  <Grid.Column>
                    <div style={{ border: "1px solid #DCDEE1" }}></div>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column style={{ textAlign: "right" }}>
                    <Button
                      style={{
                        backgroundColor: "#ffffff",
                        color: "#1b1c1d",
                        border: "1px solid #1b1c1d"
                      }}
                      compact
                      as={Link}
                      to={url}
                    >
                      {translations["LexBack"]}
                    </Button>
                    <span style={{ marginRight: "10px" }}></span>
                    <Button
                      primary
                      onClick={this.addToCart}
                      disabled={grantTotal === 0 || isLoading}
                      loading={isLoading}
                    >
                      {`${translations["LexAddSelection"]} (${
                        this.props.selectedLabels.length || "0"
                      } ${translations["LexSelected"]})`}
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
            <FooterHomeBlock />
          </Container>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.labels.data,
  hasErrorGetLabels: state.labels.hasError,
  isSuccessAddToCart: state.labels.isSuccessAddToCart,
  isLoading: state.labels.loading,
  selectedLabels: state.labels.selectedLabels,
  isSelectedAll: state.labels.isSelectedAll,
  total: state.labels.total,
  totalHandlingFee: state.labels.totalHandlingFee,
  grantTotal: state.labels.grantTotal,
  unitsOfMeasurement: state.labelOrderCart.unitsOfMeasurement,
  labelOrderType: state.labelCart.labelOrderType,
  psnCountry: state.user.common.psnCountry,
  applicantDashboard: state.applicantDashboard,
  filevolumes: state.filevolumes
});

const mapDispatchToProps = (dispatch) => ({
  getLabels: (
    labelOrderCartId,
    ccn,
    manufacturerId,
    fileNumber,
    ccnList,
    fileNumberList,
    volume,
    volumeList,
    accountNumber
  ) =>
    dispatch(
      getLabels(
        labelOrderCartId,
        ccn,
        manufacturerId,
        fileNumber,
        ccnList,
        fileNumberList,
        volume,
        volumeList,
        accountNumber
      )
    ),
  addToCart: (cartData) => dispatch(addToCart(cartData)),
  resetAddToCart: () => dispatch(resetAddToCart()),
  selectLabel: (item, isSelected) => dispatch(selectLabel(item, isSelected)),
  selectAllLabels: (isSelectedAll) => dispatch(selectAllLabels(isSelectedAll)),
  setQuantity: (item, quantity) => dispatch(setQuantity(item, quantity)),
  setUnitOfMeasurement: (item, uom) =>
    dispatch(setUnitOfMeasurement(item, uom)),
  resetLabelOrderForm: () => dispatch(resetLabelOrderForm()),
  removeCartItems: (cartItemIds) => dispatch(removeCartItems(cartItemIds)),
  getUnitsOfMeasurement: () => dispatch(getUnitsOfMeasurement()),
  updateLabelOrderCartType: (data) => dispatch(updateLabelOrderCartType(data)),
  getApplicantFileNumbers: (
    sortColumn,
    sortOrder,
    currentPage,
    pageSize,
    selectedFileNumbers,
    selectedVolumes,
    selectedCcns,
    selectedApplicantNames,
    selectedApplicantAddress,
    selectedApplicantAccountNumbers,
    labelOrderType,
    isFirstLoad
  ) =>
    dispatch(
      getFileNumbers(
        sortColumn,
        sortOrder,
        currentPage,
        pageSize,
        selectedFileNumbers,
        selectedVolumes,
        selectedCcns,
        selectedApplicantNames,
        selectedApplicantAddress,
        selectedApplicantAccountNumbers,
        labelOrderType,
        isFirstLoad
      )
    ),
  getActiveManufacturers: (
    currentPage,
    pageSize,
    fileNumber,
    volume,
    selectedFileNumbers,
    selectedVolumes,
    selectedCcns,
    selectedAccounts,
    selectedNames,
    selectedAddress,
    labelOrderType,
    firstload
  ) =>
    dispatch(
      getActiveManufacturers(
        currentPage,
        pageSize,
        fileNumber,
        volume,
        selectedFileNumbers,
        selectedVolumes,
        selectedCcns,
        selectedAccounts,
        selectedNames,
        selectedAddress,
        labelOrderType,
        firstload
      )
    ),
  getActivePage: (page) => dispatch(commonActions.getActivePage(page))
});

export default connect(mapStateToProps, mapDispatchToProps)(Labels);
