import { connect } from "react-redux";
import React, { Component, Fragment } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import HeaderBlock from "../../components/header/header-block";
import { Button, Header, Icon, Segment } from "semantic-ui-react";
import { doLogout, doExternalLogout } from "../../views/auth/auth.action";
import { Common } from "../../common/common";
import IdentityProvider from "../../enums/identity-provider";

const IDENTITY_PROVIDER = `${process.env.REACT_APP_IDENTITY_PROVIDER}`;
const API_BASE = `${process.env.REACT_APP_API_BASE}`;

class RoleNotFoundPage extends Component {
  handleLogout = () => {
    if (IDENTITY_PROVIDER == IdentityProvider.Iam) {
      localStorage.clear();
      var externalLogoutURL = `${API_BASE}auth/logout`;
      window.location.replace(externalLogoutURL);
      this.props.logout();
    } else if (IDENTITY_PROVIDER == IdentityProvider.B2c) {
      var b2cToken = Common.getB2cToken();
      localStorage.clear();

      Common.toggleLogout(b2cToken);
    }
  };

  render() {
    const translations = Common.getTranslations() || {};
    const languageCode = Common.getLanguageCode() || "";
    return (
      <Fragment>
        <Router>
          <div style={{ width: "30%", padding: "20px", margin: "auto" }}>
            <Segment placeholder>
              <p
                hidden={
                  languageCode == "ja" ||
                  languageCode == "ko" ||
                  languageCode == "fr-CA"
                }
              >
                <b>
                  {/* If you can’t find your file number/s or the appropriate
                  information in the Label Service application, please contact
                  our customer service via email at{" "}
                  <a href="mailto:cec@ul.com">cec@ul.com</a> or your local */}
                  {translations["LexNotSeeFilesBanner"]
                    ?.split("cec@ul.com")
                    .map((line, i) => (
                      <span key={i}>
                        {line}{" "}
                        {i == 0 ? (
                          <a href="mailto:cec@ul.com">cec@ul.com</a>
                        ) : (
                          ""
                        )}
                      </span>
                    ))}
                  <a
                    href={
                      "https://marks.ul.com/about/ul-listing-and-classification-marks/labels/label-centers/"
                    }
                    target="_blank"
                  >
                    {" "}
                    {translations["LexLabelCenter"]}
                  </a>
                  . {translations["LexThankyou"]}.
                </b>
              </p>
              <p hidden={languageCode != "ja"}>
                <b>
                  {/* If you can’t find your file number/s or the appropriate
                  information in the Label Service application, please contact
                  our customer service via email at{" "}
                  <a href="mailto:cec@ul.com">cec@ul.com</a> or your local */}
                  {translations["LexNotSeeFilesBanner"]
                    ?.split("cec@ul.com")
                    .map((line, i) => (
                      <span key={i}>
                        {i == 0 ? (
                          <span>
                            {line} <a href="mailto:cec@ul.com">cec@ul.com</a>
                          </span>
                        ) : i == 1 ? (
                          line.split("ラベルセンター").map((line2, x) => (
                            <span key={x}>
                              {line2}{" "}
                              {x == 0 ? (
                                <a
                                  href={
                                    "https://marks.ul.com/about/ul-listing-and-classification-marks/labels/label-centers/"
                                  }
                                  target="_blank"
                                >
                                  {translations["LexLabelCenter"]}
                                </a>
                              ) : (
                                ""
                              )}
                            </span>
                          ))
                        ) : (
                          ""
                        )}
                      </span>
                    ))}
                  . {translations["LexThankyou"]}.
                </b>
              </p>
              <p hidden={languageCode != "ko"}>
                {translations["LexNotSeeFilesBanner"]
                  ?.split("\n\n")
                  .map((line, i) => (
                    <span key={i}>
                      {i == 0
                        ? line.split("라벨 센터").map((line2, x) => (
                            <span key={x}>
                              {line2}{" "}
                              {x == 0 ? (
                                <a
                                  href={
                                    "https://marks.ul.com/about/ul-listing-and-classification-marks/labels/label-centers/"
                                  }
                                  target="_blank"
                                >
                                  {translations["LexLabelCenter"]}
                                </a>
                              ) : (
                                ""
                              )}
                            </span>
                          ))
                        : ""}
                    </span>
                  ))}
              </p>
              <p hidden={languageCode != "fr-CA"}>
                {translations["LexNotSeeFilesBanner"]
                  ?.split("\n\n")
                  .map((line, i) => (
                    <span key={i}>
                      {i == 0
                        ? line.split("centre d’étiquetage").map((line2, x) => (
                            <span key={x}>
                              {line2}{" "}
                              {x == 0 ? (
                                <a
                                  href={
                                    "https://marks.ul.com/about/ul-listing-and-classification-marks/labels/label-centers/"
                                  }
                                  target="_blank"
                                >
                                  {translations["LexLabelCenter"]}
                                </a>
                              ) : (
                                ""
                              )}
                            </span>
                          ))
                        : ""}
                    </span>
                  ))}
              </p>
              <hr />
              <Button primary onClick={this.handleLogout}>
                Back to Login
              </Button>
            </Segment>
          </div>
        </Router>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLogoutSuccess: state.auth.isLogoutSuccess
  };
};

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(doLogout()),
  logoutExternal: () => dispatch(doExternalLogout())
});

export default connect(mapStateToProps, mapDispatchToProps)(RoleNotFoundPage);
