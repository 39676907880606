import React, { Component } from "react";
import { connect } from "react-redux";
import { Dropdown, Form } from "semantic-ui-react";
import { validateField } from "../../../validator/validator";
import * as actions from "../help-me.action";
import { Common } from "../../../common/common";

const rules = [];

class CartOrderDropdown extends Component {
  state = { isCartReset: false };

  handleDropdownChange = (dpName, dpValue) => {
    let { form } = this.props.helpMe;

    const newState = validateField(rules, form, dpName, dpValue);
    this.props.updateForm(newState);
  };

  render() {
    const { cartOrdersOption, form, loading } = this.props.helpMe;
    const {
      userId,
      manufacturerAccountNo,
      isGetCartOrders,
      isGetUnread,
      labelCenter
    } = this.props;
    const { isCartReset } = this.state;
    const translations = Common.getTranslations() || {};

    if (userId && isGetCartOrders === false) {
      this.props.getCartOrders(userId, null, isGetUnread, labelCenter);
      this.props.handleSetIsGetCartOrders();
    }

    if (manufacturerAccountNo && isGetCartOrders === false) {
      this.props.getCartOrders(
        null,
        manufacturerAccountNo,
        isGetUnread,
        labelCenter
      );
      this.props.handleSetIsGetCartOrders();
    }

    if (labelCenter && isGetCartOrders === false) {
      this.props.getCartOrders(userId, null, isGetUnread, labelCenter);
      this.props.handleSetIsGetCartOrders();
    }

    if (
      !userId &&
      !manufacturerAccountNo &&
      !labelCenter &&
      cartOrdersOption.length
    ) {
      this.props.clearForm();
    }

    if (!isCartReset && form) {
      form.cartOrderNo = null;
      this.setState({ isCartReset: true });
    }
    return (
      <Form.Group widths="equal">
        <Form.Field
          control={Dropdown}
          // disabled={isLoading}
          compact
          required
          selection
          search
          label={translations["LexCartOrderwithopenquestions"]}
          selectOnBlur={false}
          placeholder="Select Cart/Order Number"
          options={cartOrdersOption}
          loading={loading}
          onChange={(e, properties) => {
            this.handleDropdownChange("cartOrderNo", properties.value);
          }}
          value={(form && form.cartOrderNo) || ""}
          // error={this.getErrorFor("contactName", "AD-AD")}
        />
      </Form.Group>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.helpMe.loading,
    isSuccess: state.helpMe.isSuccess,
    helpMe: state.helpMe
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCartOrders: (userId, manufacturerAccountNo, isGetUnread, labelCenter) =>
      dispatch(
        actions.getCartOrders(
          userId,
          manufacturerAccountNo,
          isGetUnread,
          labelCenter
        )
      ),
    updateForm: (result) => dispatch(actions.updateForm(result)),
    clearForm: (result) => dispatch(actions.clearForm(result))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CartOrderDropdown);
