import React, { Component, Fragment } from "react";
import {
  Container,
  Button,
  Grid,
  Divider,
  Pagination,
  Message,
  Input,
  Dropdown,
  Dimmer,
  Loader,
  Form
} from "semantic-ui-react";
import {
  CustomTitle,
  CustomHeader,
  CustomHeaderLabel,
  CustomLabel
} from "../../../components/labels/labels";
import FooterHomeBlock from "../../../components/footer/footer-home-block";
import OrderItem from "./order-item";
import * as actions from "./view-draft.action";
import { connect } from "react-redux";

import { validateField } from "../../../validator/validator";

const rules = [];

const labelOptions = [
  {
    key: "OrderNumber",
    value: "OrderNumber",
    text: "Order #"
  },
  { key: "ApplicantName", value: "ApplicantName", text: "Applicant Name" },
  {
    key: "AccountNumber",
    value: "AccountNumber",
    text: "Account #"
  },
  { key: "ContactName", value: "ContactName", text: "Contact Name" }
];

class ViewDraft extends Component {
  componentDidMount() {
    let { currentPage, pageSize } = this.props.viewDraft;
    this.props.getFEOrdersByFilterByUser(1, currentPage, pageSize);
  }

  handleChange = (dpName, dpValue) => {
    let { form } = this.props.viewDraft;
    validateField(rules, form, dpName, dpValue);
    const newState = { ...this.props.viewDraft.form };
    this.props.form(newState);
  };

  handleSearch = () => {
    let { form, currentPage, pageSize } = this.props.viewDraft;
    let status = form.status;
    // if (status) this.props.getFEOrdersByFilter(status, currentPage, pageSize);
  };

  render() {
    const { data, form } = this.props.viewDraft;

    return (
      <div style={{ width: "80%", padding: "20px" }}>
        <Container fluid>
          <Dimmer active={this.props.loading} inverted>
            <Loader inverted content="Loading" />
          </Dimmer>
          <CustomHeaderLabel message="View Draft" />
          <Divider hidden />
          <Grid>
            <Grid.Row>
              <Grid.Column width={1} verticalAlign="middle">
                <CustomTitle message="Keywords" />
              </Grid.Column>
              <Grid.Column width={4} verticalAlign="middle">
                <Dropdown
                  compact
                  selection
                  placeholder="Choose filters"
                  options={labelOptions}
                  name="labelType"
                />
                <Input />
              </Grid.Column>
              <Grid.Column width={3}>
                <Button color="blue" onClick={this.handleSearch}>
                  Search Orders
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Divider />
          {data && data.map((data, idx) => <OrderItem key={idx} data={data} />)}

          {data && this.props.totalPages > 0 ? (
            <Pagination
              activePage={this.props.currentPage}
              totalPages={this.props.totalPages}
              onPageChange={(e, { activePage }) => {
                this.props.changePage(activePage, this.props.pageSize);
              }}
            />
          ) : (
            <Message>No Order Found</Message>
          )}
          <FooterHomeBlock />
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentPage: state.viewDraft.currentPage,
    pageSize: state.viewDraft.pageSize,
    totalPages: state.viewDraft.totalPages,
    loading: state.viewDraft.loading,
    viewDraft: state.viewDraft
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changePage: (activePage, pageSize) =>
      dispatch(actions.changePage(activePage, pageSize)),
    getFEOrdersByFilterByUser: (status, currentPage, pageSize) =>
      dispatch(
        actions.getFEOrdersByFilterByUser(status, currentPage, pageSize)
      ),
    form: result => dispatch(actions.updateForm(result))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewDraft);
