export const FE_AREA_REQUEST = "FE_AREA_REQUEST";
export const FE_AREA_SUCCESS = "FE_AREA_SUCCESS";
export const FE_AREA_FAILURE = "FE_AREA_FAILURE";

export const FE_SERVICE_AREA_REQUEST = "FE_SERVICE_AREA_REQUEST";
export const FE_SERVICE_AREA_SUCCESS = "FE_SERVICE_AREA_SUCCESS";
export const FE_SERVICE_AREA_FAILURE = "FE_SERVICE_AREA_FAILURE";
export const FE_SERVICE_AREA_CLEAR = "FE_SERVICE_AREA_CLEAR";

export const FE_SERVICE_PRODUCT_REQUEST = "FE_SERVICE_PRODUCT_REQUEST";
export const FE_SERVICE_PRODUCT_SUCCESS = "FE_SERVICE_PRODUCT_SUCCESS";
export const FE_SERVICE_PRODUCT_FAILURE = "FE_SERVICE_PRODUCT_FAILURE";
export const FE_SERVICE_PRODUCT_CLEAR = "FE_SERVICE_PRODUCT_CLEAR";

export const FE_SERVICE_SUB_PRODUCT_REQUEST = "FE_SERVICE_SUB_PRODUCT_REQUEST";
export const FE_SERVICE_SUB_PRODUCT_SUCCESS = "FE_SERVICE_SUB_PRODUCT_SUCCESS";
export const FE_SERVICE_SUB_PRODUCT_FAILURE = "FE_SERVICE_SUB_PRODUCT_FAILURE";
export const FE_SERVICE_SUB_PRODUCT_CLEAR = "FE_SERVICE_SUB_PRODUCT_CLEAR";

export const FE_PRODUCT_PRICE_REQUEST = "FE_PRODUCT_PRICE_REQUEST";
export const FE_PRODUCT_PRICE_SUCCESS = "FE_PRODUCT_PRICE_SUCCESS";
export const FE_PRODUCT_PRICE_FAILURE = "FE_PRODUCT_PRICE_FAILURE";
export const FE_PRODUCT_PRICE_CLEAR = "PRODUCT_PRICE_CLEAR";

export const COUNTRIES_PRELIM_SITE_REQUEST = "COUNTRIES_PRELIM_SITE_REQUEST";
export const COUNTRIES_PRELIM_SITE_FAILURE = "COUNTRIES_PRELIM_SITE_FAILURE";
export const COUNTRIES_PRELIM_SITE_SUCCESS = "COUNTRIES_PRELIM_SITE_SUCCESS";

export const CITIES_PRELIM_SITE_REQUEST = "CITIES_PRELIM_SITE_REQUEST";
export const CITIES_PRELIM_SITE_FAILURE = "CITIES_PRELIM_SITE_FAILURE";
export const CITIES_PRELIM_SITE_SUCCESS = "CITIES_PRELIM_SITE_SUCCESS";

export const STATES_PRELIM_SITE_REQUEST = "STATES_PRELIM_SITE_REQUEST";
export const STATES_PRELIM_SITE_FAILURE = "STATES_PRELIM_SITE_FAILURE";
export const STATES_PRELIM_SITE_SUCCESS = "STATES_PRELIM_SITE_SUCCESS";

export const COUNTRIES_FINAL_SITE_REQUEST = "COUNTRIES_FINAL_SITE_REQUEST";
export const COUNTRIES_FINAL_SITE_FAILURE = "COUNTRIES_FINAL_SITE_FAILURE";
export const COUNTRIES_FINAL_SITE_SUCCESS = "COUNTRIES_FINAL_SITE_SUCCESS";

export const CITIES_FINAL_SITE_REQUEST = "CITIES_FINAL_SITE_REQUEST";
export const CITIES_FINAL_SITE_FAILURE = "CITIES_FINAL_SITE_FAILURE";
export const CITIES_FINAL_SITE_SUCCESS = "CITIES_FINAL_SITE_SUCCESS";

export const STATES_FINAL_SITE_REQUEST = "STATES_FINAL_SITE_REQUEST";
export const STATES_FINAL_SITE_FAILURE = "STATES_FINAL_SITE_FAILURE";
export const STATES_FINAL_SITE_SUCCESS = "STATES_FINAL_SITE_SUCCESS";

export const COUNTRIES_AHJ_SITE_REQUEST = "COUNTRIES_AHJ_SITE_REQUEST";
export const COUNTRIES_AHJ_SITE_FAILURE = "COUNTRIES_AHJ_SITE_FAILURE";
export const COUNTRIES_AHJ_SITE_SUCCESS = "COUNTRIES_AHJ_SITE_SUCCESS";

export const CITIES_AHJ_SITE_REQUEST = "CITIES_AHJ_SITE_REQUEST";
export const CITIES_AHJ_SITE_FAILURE = "CITIES_AHJ_SITE_FAILURE";
export const CITIES_AHJ_SITE_SUCCESS = "CITIES_AHJ_SITE_SUCCESS";

export const STATES_AHJ_SITE_REQUEST = "STATES_AHJ_SITE_REQUEST";
export const STATES_AHJ_SITE_FAILURE = "STATES_AHJ_SITE_FAILURE";
export const STATES_AHJ_SITE_SUCCESS = "STATES_AHJ_SITE_SUCCESS";

export const UPDATE_FORMS = "UPDATE_FORMS";
export const UPDATE_SITE_FORMS = "UPDATE_SITE_FORMS";

export const RENDER_DYNAMIC_SITE_FORM = "RENDER_DYNAMIC_SITE_FORM";

export const CLEAR_GSQ = "CLEAR_GSQ";

export const RENDER_PRODUCT_DETAILS = "RENDER_PRODUCT_DETAILS";
export const RENDER_SITE_DETAILS = "RENDER_SITE_DETAILS";
