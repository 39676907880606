import React, { Component, Fragment } from "react";
import {
  Segment,
  Grid,
  Button,
  Divider,
  Table,
  GridColumn
} from "semantic-ui-react";
import { renderDisc } from "../../../helpers/component-helper";
import {
  CustomLabel,
  SubText,
  CustomSubLabel,
  CustomHeader,
  CustomHeaderBold,
  CustomHeaderLabel,
  CustomHeaderInlineBold
} from "../../../components/labels/labels";
import { GetStatusCode } from "../field-evaluation-helper";
import { serviceTypeDisp } from "../../../helpers/component-helper";
import QuoteHeader from "../product-details/quote-header";
import Status from "../../../enums/status";
import Role from "../../../enums/role";

class OrderDetails extends Component {
  state = {};
  render() {
    var { data, accountSettings, applicant } = this.props;

    var applicantDetails = applicant.applicantDetails;
    var billToDetails = applicant.applicantBillToLocationDetails;
    var orderNo = null;
    var roleId = localStorage.getItem("roles");
    if (roleId == Role.fieldEngineer) {
      orderNo = data.oracleNumber ? data.oracleNumber : "UL Order fail";
    } else if (roleId == Role.projectHandler) {
      orderNo = data.oracleNumber ? data.oracleNumber : data.oracleMessage;
    }
    return (
      <Fragment>
        <Segment>
          <Grid>
            <Grid.Column>
              <QuoteHeader />
            </Grid.Column>
          </Grid>
          <Grid>
            <Grid.Column width={5}>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <CustomLabel message="QUOTE #: " />
                      {"UL FE-" + data.orderNumber}
                      {` (${GetStatusCode(data.status)})`}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <CustomLabel message="ORDER #: " /> {orderNo}
                    </td>
                  </tr>
                  {data.status == Status.cancelled ? (
                    <tr>
                      <td>
                        <CustomLabel message="CANCELLATION CODE: " />
                        <span>
                          {data.cancellationName}
                          {data.cancellationCode === "OTHER"
                            ? ` - ${data.cancellationOther}`
                            : ""}
                        </span>
                      </td>
                    </tr>
                  ) : null}
                </tbody>
              </table>
            </Grid.Column>
            <Grid.Column width={3}>
              <Grid>
                <Grid.Row>
                  <CustomLabel message="NET TOTAL: " />
                  <b>
                    <font color="#2185d0">
                      {data.netTotal ? `USD ${data.netTotal}` : `USD 0.00`}
                    </font>
                  </b>
                  {/* {(data && data.orderNumber) || " -NOTHING PROVIDED- "} */}
                </Grid.Row>
                {/* <Grid.Row>
                  <CustomLabel message="SCOPE: " />
                  CA121344546
                  {(data && data.scope) || " -NOTHING PROVIDED- "}
                </Grid.Row> */}
              </Grid>
            </Grid.Column>
            <Grid.Column width={6}>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <CustomLabel message="QUOTE CREATION DATE" />
                    </td>
                    <td>{data.orderSubmittedDate}</td>
                  </tr>
                  <tr>
                    <td>
                      <CustomLabel message="QUOTE LAST UPDATED DATE" />
                    </td>
                    <td>{data.orderLastUpdatedDate}</td>
                  </tr>
                  <tr>
                    <td>
                      <CustomLabel message="QUOTE EXPIRES ON" />
                    </td>
                    <td>{data.orderExpiredDate}</td>
                  </tr>
                </tbody>
              </table>
              {/* <CustomSubLabel message="PRODUCT TYPE " />

              {(data && data.productType) || "-NOTHING PROVIDED-"}
              <br />
              <CustomSubLabel message="INSPECTION TYPE " />

              {(data && data.inspectionType) || "-NOTHING PROVIDED-"} */}
            </Grid.Column>
            <Grid.Column width={2}>
              <CustomLabel message="Version #: " /> 1
            </Grid.Column>
          </Grid>
          <Divider />

          <Grid>
            <Grid.Column width={4}>
              <p>
                To ensure our records remain accurate, please review the
                Applicant & Bill to Details to the right The “Applicant”, is the
                Company financially responsible for our service. The “Bill To”,
                is the preferred location for submittal of our invoice(s).
                Should you need to make changes, please contact us at{" "}
                <a href="tel:877.854.3577">877.854.3577</a>, prompt # 2 or{" "}
                <a href="mailto:field@ul.com">field@ul.com</a> and reference
                your UL account#. Thank you!
              </p>
            </Grid.Column>
            <Grid.Column width={6}>
              <Grid.Row>
                <Grid.Column>
                  <CustomHeaderBold message="Applicant Details" />
                  <table className="table-bordered" cellSpacing="0">
                    <tbody>
                      <tr>
                        <td>
                          <CustomLabel message="UL Account #" />
                        </td>
                        <td>
                          {applicantDetails && applicantDetails.accountNumber}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <CustomLabel message="Company Name" />
                        </td>
                        <td>
                          {applicantDetails &&
                            applicantDetails.legalCompanyName}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <CustomLabel message="Address" />
                        </td>
                        <td>
                          {(data && data.applicantDetails) ||
                            "-NOTHING PROVIDED-"}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <CustomLabel message="Contact Name" />
                        </td>
                        <td>
                          {applicantDetails && applicantDetails.contactName}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <CustomLabel message="Contact Details" />
                        </td>
                        <td>
                          <table className="no-border">
                            <tbody>
                              <tr>
                                <td>
                                  <b>T:</b>
                                </td>
                                <td>
                                  {applicantDetails &&
                                    applicantDetails.phoneNumber}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <b>M:</b>
                                </td>
                                <td>
                                  {applicantDetails &&
                                    applicantDetails.mobileNumber}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <b>E:</b>
                                </td>
                                <td>
                                  {applicantDetails &&
                                    applicantDetails.emailAddress}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Grid.Column>
                <Divider hidden />
                {/* <Grid.Column>
                  <CustomHeaderBold message="Client Reference #" />
                  {(data && data.clientReferenceNumber) || "-NOTHING PROVIDED-"}
                </Grid.Column> */}
                <Divider hidden />
                {/* <Grid.Column>
                  <CustomHeaderBold message="Quote #" />
                  {(data && data.orderNumber) || "-NOTHING PROVIDED-"}
                </Grid.Column> */}
                {/* <Grid.Column>
                  <CustomHeaderBold message="Purchase Order #" />
                  {(data && data.purchaseOrderNumber) || "-NOTHING PROVIDED-"}
                </Grid.Column> */}
              </Grid.Row>
            </Grid.Column>
            <Grid.Column width={6}>
              <Grid.Row>
                <Grid.Column>
                  <CustomHeaderBold message="Bill To Details" />
                  <table className="table-bordered" cellSpacing="0">
                    <tbody>
                      <tr>
                        <td>
                          <CustomLabel message="UL Account #" />
                        </td>
                        <td>N/A</td>
                      </tr>
                      <tr>
                        <td>
                          <CustomLabel message="Company Name" />
                        </td>
                        <td>
                          {billToDetails && billToDetails.legalCompanyName}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <CustomLabel message="Address" />
                        </td>
                        <td>
                          {billToDetails && billToDetails.addressLine1}
                          <br />
                          {billToDetails && billToDetails.addressLine2}
                          <br />
                          {billToDetails && billToDetails.addressLine3}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <CustomLabel message="Contact Name" />
                        </td>
                        <td>{billToDetails && billToDetails.contactName}</td>
                      </tr>
                      <tr>
                        <td>
                          <CustomLabel message="Contact Details" />
                        </td>
                        <td>
                          <table className="no-border">
                            <tbody>
                              <tr>
                                <td>
                                  <b>T:</b>
                                </td>
                                <td>
                                  {billToDetails && billToDetails.phoneNumber}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <b>M:</b>
                                </td>
                                <td>
                                  {billToDetails && billToDetails.mobileNumber}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <b>E:</b>
                                </td>
                                <td>
                                  {billToDetails && billToDetails.emailAddress}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Grid.Column>
                {/* <Divider hidden />
                <Grid.Column>
                  <CustomHeaderBold message="Purchase Order #" />
                  {(data && data.purchaseOrderNumber) || "-NOTHING PROVIDED-"}
                </Grid.Column>
                <Divider hidden /> */}
                {/* <Grid.Column>
                  <CustomHeaderBold message="Oracle Order #" />
                  {(data && serviceTypeDisp(data.serviceType)) ||
                    "-NOTHING PROVIDED-"}
                  N/A
                </Grid.Column> */}
              </Grid.Row>
            </Grid.Column>
          </Grid>
        </Segment>
      </Fragment>
    );
  }
}

export default OrderDetails;
