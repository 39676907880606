import {
  CHANGE_LANGUAGE_REQUEST,
  CHANGE_LANGUAGE_SUCCESS,
  CHANGE_LANGUAGE_FAILURE,
  LANGUAGE_OPTIONS
} from "../constants/language";

const initialState = {
  value: LANGUAGE_OPTIONS[0].value,
  text: LANGUAGE_OPTIONS[0].text
};

const languageReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_LANGUAGE_REQUEST:
      return {
        ...state,
        hasError: false,
        loading: true
      };
    case CHANGE_LANGUAGE_SUCCESS:
      return {
        ...state,
        hasError: false,
        isSuccessful: true,
        loading: false,
        text: action.payload.text,
        value: action.payload.value
      };
    case CHANGE_LANGUAGE_FAILURE:
      return {
        ...state,
        errors: action.payload,
        isSuccessful: false,
        hasError: true,
        loading: false
      };
    default:
      return state;
  }
};

export default languageReducer;
