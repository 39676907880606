import * as types from "./label-cart.type";
import api from "../../../../services/api";
import merge from "deepmerge";

const cartItemsRequest = () => {
  return { type: types.CART_ITEMS_REQUEST };
};

const cartItemsFailure = (error) => {
  return (dispatch) =>
    dispatch({ type: types.CART_ITEMS_FAILURE, payload: error });
};

const cartItemsSuccess = (response) => {
  return (dispatch) =>
    dispatch({ type: types.CART_ITEMS_SUCCESS, payload: response });
};

export const selectLabel = (data, isSelected, activeIndex) => {
  return (dispatch) =>
    dispatch({
      type: types.MANUFACTURER_STANDARD_CART_ITEMS_SELECT_LABEL,
      payload: { data, isSelected, activeIndex }
    });
};

export const setQuantity = (index, data, quantity) => {
  return (dispatch) =>
    dispatch({
      type: types.MANUFACTURER_STANDARD_CART_ITEMS_SET_QUANTITY,
      payload: { index, data, quantity }
    });
};

export const removeCartItem = (cartItemIds, activeIndex) => {
  return (dispatch) =>
    dispatch({
      type: types.MANUFACTURER_STANDARD_CART_ITEMS_REMOVE,
      payload: { cartItemIds, activeIndex }
    });
};

export const updateModel = (name, value) => {
  return {
    type: types.MANUFACTURER_STANDARD_CART_UPDATE_MODEL,
    payload: { name, value }
  };
};

export const getCartItems = (labelUserId) => {
  return async (dispatch) => {
    dispatch(cartItemsRequest());
    const result = await api.Manufacturer.getCartItems(labelUserId);

    if (result) {
      dispatch(cartItemsSuccess(result));
      return result;
    } else {
      dispatch(cartItemsFailure("Something went wrong."));
    }
  };
};

const updateCartRequest = () => {
  return { type: types.MANUFACTURER_STANDARD_UPDATE_CART_REQUEST };
};

const updateCartFailure = (error) => {
  return (dispatch) =>
    dispatch({
      type: types.MANUFACTURER_STANDARD_UPDATE_CART_FAILURE,
      payload: error
    });
};

const updateCartSuccess = (response) => {
  return (dispatch) =>
    dispatch({
      type: types.MANUFACTURER_STANDARD_UPDATE_CART_SUCCESS,
      payload: response
    });
};

export const resetUpdateCart = () => {
  return { type: types.MANUFACTURER_STANDARD_UPDATE_CART_RESET };
};

export const updateCart = (cartData) => {
  return async (dispatch) => {
    dispatch(updateCartRequest());

    const result = await api.Manufacturer.updateCart(cartData);

    if (result) {
      dispatch(updateCartSuccess(result));
    } else {
      dispatch(updateCartFailure("Something went wrong."));
    }
    return result;
  };
};

const deleteCartRequest = () => {
  return { type: types.MANUFACTURER_STANDARD_DELETE_CART_REQUEST };
};

const deleteCartFailure = (error) => {
  return (dispatch) =>
    dispatch({
      type: types.MANUFACTURER_STANDARD_DELETE_CART_FAILURE,
      payload: error
    });
};

const deleteCartSuccess = (response) => {
  return (dispatch) =>
    dispatch({
      type: types.MANUFACTURER_STANDARD_DELETE_CART_SUCCESS,
      payload: response
    });
};

export const resetDeleteCart = () => {
  return { type: types.MANUFACTURER_STANDARD_DELETE_CART_RESET };
};

export const deleteCart = (cartData) => {
  return async (dispatch) => {
    dispatch(deleteCartRequest());

    const result = await api.Manufacturer.deleteCart(cartData);

    if (result) {
      dispatch(deleteCartSuccess(result));
    } else {
      dispatch(deleteCartFailure("Something went wrong."));
    }
  };
};

const createOrderRequest = () => {
  return { type: types.CREATE_ORDER_REQUEST };
};

const createOrderFailure = (error) => {
  return (dispatch) =>
    dispatch({ type: types.CREATE_ORDER_FAILURE, payload: error });
};

const createOrderSuccess = (response) => {
  return (dispatch) =>
    dispatch({ type: types.CREATE_ORDER_SUCCESS, payload: response });
};

export const resetCartItemsForm = () => {
  return { type: types.RESET_CART_ITEMS_FORM };
};

export const createOrder = (data) => {
  return async (dispatch) => {
    dispatch(createOrderRequest());
    const result = await api.Order.createOrder(data);

    if (result) {
      dispatch(createOrderSuccess(result));
      return result;
    } else {
      dispatch(createOrderFailure("Something went wrong."));
    }
  };
};

const cancelOrderRequest = () => {
  return { type: types.CANCEL_ORDER_REQUEST };
};

const cancelOrderFailure = (error) => {
  return (dispatch) =>
    dispatch({ type: types.CANCEL_ORDER_FAILURE, payload: error });
};

const cancelOrderSuccess = (response) => {
  return (dispatch) =>
    dispatch({ type: types.CANCEL_ORDER_SUCCESS, payload: response });
};

export const resetCancelOrder = () => {
  return { type: types.CANCEL_ORDER_RESET };
};

export const cancelOrder = (cartData) => {
  return async (dispatch) => {
    dispatch(cancelOrderRequest());
    const result = await api.Order.cancelOrder(cartData);

    if (result) {
      dispatch(cancelOrderSuccess(result));
      return result;
    } else {
      dispatch(cancelOrderFailure("Something went wrong."));
    }
  };
};

export const updateForm = (result) => {
  return (dispatch) =>
    dispatch({
      type: types.FORM,
      payload: result
    });
};

export const updateTabForm = (idx, form, forms) => {
  const newForms = forms.slice(0);
  const index = getFormIndexByTabIndex(idx, forms);
  let newForm = getFormByTabIndex(idx, forms);

  if (!newForm) {
    newForms.push(form);
  } else {
    newForms[index] = merge(newForm, form);
  }

  return (dispatch) =>
    dispatch({
      type: types.UPDATE_FORMS,
      payload: newForms
    });
};

export const getFormIndexByTabIndex = (idx, forms) => {
  return (forms || []).findIndex((s) => (s || {}).tabIndex === idx);
};

export const getFormByTabIndex = (idx, forms) => {
  if (forms) {
    return forms[idx];
  }
};

export const clearForm = () => (dispatch) =>
  dispatch({ type: types.CLEAR_FORM, payload: {} });

const shippingOptionsRequest = () => {
  return { type: types.SHIPPING_OPTIONS_REQUEST };
};

const shippingOptionsFailure = (error) => {
  return (dispatch) =>
    dispatch({ type: types.SHIPPING_OPTIONS_FAILURE, payload: error });
};

const shippingOptionsSuccess = (response) => {
  return (dispatch) =>
    dispatch({ type: types.SHIPPING_OPTIONS_SUCCESS, payload: response });
};

export const getShippingOptions = (countryCode) => {
  return async (dispatch) => {
    dispatch(shippingOptionsRequest());
    const result = await api.Order.getShippingOptions(countryCode);

    if (result) {
      dispatch(shippingOptionsSuccess(result));
    } else {
      dispatch(shippingOptionsFailure("Something went wrong."));
    }
  };
};

export const setShippingOption = (optionId) => {
  return (dispatch) =>
    dispatch({ type: types.SET_SHIPPING_OPTION, payload: { optionId } });
};

const customerDetailsRequest = () => {
  return { type: types.CUSTOMER_DETAILS_REQUEST };
};

const customerDetailsFailure = (error) => {
  return (dispatch) =>
    dispatch({ type: types.CUSTOMER_DETAILS_FAILURE, payload: error });
};

const customerDetailsSuccess = (response) => {
  return (dispatch) =>
    dispatch({ type: types.CUSTOMER_DETAILS_SUCCESS, payload: response });
};

export const getCustomerDetails = (partySiteNumber) => {
  return async (dispatch) => {
    dispatch(customerDetailsRequest());
    const result = await api.Customer.getCustomerDetails(partySiteNumber);

    if (result) {
      dispatch(customerDetailsSuccess(result));
      return result;
    } else {
      dispatch(customerDetailsFailure("Something went wrong."));
    }
  };
};

export const updateShippingOptions = (labelCenter) => {
  return (dispatch) =>
    dispatch({ type: types.UPDATE_SHIPPING_OPTIONS, payload: labelCenter });
};

export const updateReviewCart = (data) => {
  return (dispatch) =>
    dispatch({ type: types.UPDATE_REVIEW_CART, payload: data });
};

export const getLabelCenterCountryDetails = (shipToCountry) => {
  return async (dispatch) => {
    dispatch(labelCenterCountryRequest());
    const result = await api.LabelCenter.getLabelCenterCountryDetails(
      shipToCountry
    );

    if (result) {
      dispatch(labelCenterCountryDetailsSuccess(result));
    } else {
      dispatch(labelCenterCountryDetailsFailure("Something went wrong."));
    }
  };
};

const labelCenterCountryRequest = () => {
  return { type: types.LABEL_CENTER_COUNTRY_REQUEST };
};

const labelCenterCountryDetailsFailure = (error) => {
  return (dispatch) =>
    dispatch({ type: types.LABEL_CENTER_COUNTRY_FAILURE, payload: error });
};

const labelCenterCountryDetailsSuccess = (response) => {
  return (dispatch) =>
    dispatch({ type: types.LABEL_CENTER_COUNTRY_SUCCESS, payload: response });
};

export const updateLabelOrderCartType = (data) => {
  return (dispatch) =>
    dispatch({ type: types.UPDATE_LABEL_ORDER_TYPE, payload: data });
};

const exchangeRateRequest = () => {
  return { type: types.GET_EXCHANGE_RATE_REQUEST };
};

const exchangeRateFailure = (error) => {
  return (dispatch) =>
    dispatch({ type: types.GET_EXCHANGE_RATE_FAILURE, payload: error });
};

const exchangeRateSuccess = (response, index) => {
  response.index = index;
  return (dispatch) =>
    dispatch({ type: types.GET_EXCHANGE_RATE_SUCCESS, payload: response });
};

export const getExchangeRate = (countryCode, index) => {
  return async (dispatch) => {
    dispatch(exchangeRateRequest());
    const result = await api.Order.getExchangeRate(countryCode);

    if (result) {
      dispatch(exchangeRateSuccess(result, index));
      result.index = index;
      return result;
    } else {
      dispatch(exchangeRateFailure("Something went wrong."));
    }
  };
};

export const getCustomerEcmApiStatus = () => {
  return async (dispatch) => {
    dispatch({ type: types.GET_CUSTOMER_ECM_API_STATUS_REQUEST });
    const result = await api.Order.getCustomerEcmApiStatus();

    if (result) {
      dispatch({ type: types.GET_CUSTOMER_ECM_API_STATUS_SUCCESS });
    } else {
      dispatch({ type: types.GET_CUSTOMER_ECM_API_STATUS_FAILURE });
    }
    return result;
  };
};

export const uploadLabelOrderTempAttachment = (data) => {
  return async (dispatch) => {
    dispatch({ type: types.UPLOAD_LABEL_ORDER_ATTACHMENT_TEMP_REQUEST });
    const result = await api.LabelOrderCart.uploadLabelOrderTempAttachment(
      data
    );
    if (result) {
      dispatch({
        type: types.UPLOAD_LABEL_ORDER_ATTACHMENT_TEMP_SUCCESS,
        payload: result
      });
      return result;
    } else {
      dispatch({
        type: types.UPLOAD_LABEL_ORDER_ATTACHMENT_TEMP_FAILURE,
        payload: "Something went wrong."
      });
    }
  };
};

export const getLabelOrderTempAttachment = (
  partySiteNumber,
  labelOrderType
) => {
  return async (dispatch) => {
    dispatch({ type: types.GET_LABEL_ORDER_ATTACHMENT_TEMP_REQUEST });
    await api.LabelOrderCart.getLabelOrderTempAttachment(
      partySiteNumber,
      labelOrderType
    )
      .then((result) => {
        dispatch({
          type: types.GET_LABEL_ORDER_ATTACHMENT_TEMP_SUCCESS,
          payload: result
        });
      })
      .catch((result) => {
        dispatch({
          type: types.GET_LABEL_ORDER_ATTACHMENT_TEMP_FAILURE,
          payload: result
        });
      });
  };
};

export const deleteLabelOrderTempAttachment = (attachmentId) => {
  return async (dispatch) => {
    dispatch({ type: types.DELETE_LABEL_ORDER_ATTACHMENT_TEMP_REQUEST });
    const result = await api.LabelOrderCart.deleteLabelOrderTempAttachment(
      attachmentId
    );
    if (result) {
      dispatch({
        type: types.DELETE_LABEL_ORDER_ATTACHMENT_TEMP_SUCCESS,
        payload: result
      });
      return result;
    } else {
      dispatch({
        type: types.DELETE_LABEL_ORDER_ATTACHMENT_TEMP_FAILURE,
        payload: "Something went wrong."
      });
    }
  };
};

export const getExistingPoNumber = (poNumber) => {
  return async (dispatch) => {
    dispatch({ type: types.GET_EXISTING_PO_NUMBER_REQUEST });

    const result = await api.Manufacturer.getExistingPoNumber(poNumber);

    if (result) {
      dispatch({ type: types.GET_EXISTING_PO_NUMBER_SUCCESS, payload: result });
    } else {
      dispatch({ type: types.GET_EXISTING_PO_NUMBER_FAILURE, payload: result });
    }
    return result;
    // await api.Manufacturer.getExistingPoNumber(poNumber)
    //   .then((result) => {
    //     dispatch({
    //       type: types.GET_EXISTING_PO_NUMBER_SUCCESS,
    //       payload: result,
    //     });
    //   })
    //   .catch((result) => {
    //     dispatch({
    //       type: types.GET_EXISTING_PO_NUMBER_FAILURE,
    //       payload: result,
    //     });
    //   });
  };
};

export const checkFileVolCcn = (cartData) => {
  return async (dispatch) => {
    dispatch({ type: types.CHECK_FILEVOLCCN_REQEUST });

    const result = await api.Manufacturer.checkFileVolCcn(cartData);

    if (result) {
      dispatch({ type: types.CHECK_FILEVOLCCN_SUCCESS, payload: result });
    } else {
      dispatch({ type: types.CHECK_FILEVOLCCN_FAILED, payload: result });
    }
    return result;
    // await api.Manufacturer.getExistingPoNumber(poNumber)
    //   .then((result) => {
    //     dispatch({
    //       type: types.GET_EXISTING_PO_NUMBER_SUCCESS,
    //       payload: result,
    //     });
    //   })
    //   .catch((result) => {
    //     dispatch({
    //       type: types.GET_EXISTING_PO_NUMBER_FAILURE,
    //       payload: result,
    //     });
    //   });
  };
};

export const deleteTab = (activeIndex) => {
  return (dispatch) =>
    dispatch({ type: types.DELETE_CURRENT_TAB, payload: activeIndex });
};

export const updateBillToData = (data) => {
  return (dispatch) =>
    dispatch({ type: types.UPDATE_BILL_TO_DATA, payload: data });
};

export const getCreditProfile = (
  applicantAccountNumbers,
  manufacturerAccountNumbers,
  billToAccountNumbers,
  orderAmounts
) => {
  return async (dispatch) => {
    dispatch({ type: types.GET_CREDIT_PROFILE_REQUEST });

    const result = await api.Order.getCreditProfile(
      applicantAccountNumbers,
      manufacturerAccountNumbers,
      billToAccountNumbers,
      orderAmounts
    );

    if (result) {
      dispatch({ type: types.GET_CREDIT_PROFILE_SUCCESS, payload: result });
    } else {
      dispatch({ type: types.GET_CREDIT_PROFILE_FAILURE, payload: result });
    }
    return result;
  };
};

export const checkIfServiceContractUp = () => {
  return async (dispatch) => {
    dispatch(checkServiceContractUpRequest());
    const result = await api.Applicant.checkIfServiceContractUp();

    if (result) {
      dispatch(checkServiceContractUpSuccess(result));
    } else {
      dispatch(checkServiceContractUpFailure(result));
    }
    return result;
  };
};

const checkServiceContractUpSuccess = (response) => {
  return (dispatch) =>
    dispatch({ type: types.CHECK_SERVICE_CONTRACT_SUCCESS, payload: response });
};

const checkServiceContractUpRequest = () => {
  return { type: types.CHECK_SERVICE_CONTRACT_REQUEST };
};

const checkServiceContractUpFailure = (error) => {
  return (dispatch) =>
    dispatch({ type: types.CHECK_SERVICE_CONTRACT_FAILURE, payload: error });
};
