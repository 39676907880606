import React, { Component } from "react";
import { Modal, Grid, Table, Header, Button } from "semantic-ui-react";
import PropTypes from "prop-types";
import { CustomTitle, CustomLabel } from "../../../../components/labels/labels";
import { Link } from "react-router-dom";
import PriceItem from "./price-item";

class FesSummary extends Component {
  state = {};
  render() {
    const {
      openSummaryModal,
      onCloseSummaryModal,
      primaryDetails,
      billToDetails,
      orders
    } = this.props;
    return (
      <Modal size="large" open={openSummaryModal} onClose={onCloseSummaryModal}>
        <Modal.Header className="center">Summary</Modal.Header>
        <Modal.Content scrolling>
          <Grid>
            <Grid.Row>
              <Grid.Column width={5}>
                <Header size="medium">Applicant Details</Header>
              </Grid.Column>
              <Grid.Column width={10}>
                <Table basic="very" size="small">
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell textAlign="left">
                        <CustomTitle message={"Legal Company Name:"} />
                      </Table.Cell>
                      <Table.Cell textAlign="left">
                        <CustomLabel
                          message={
                            primaryDetails && primaryDetails.legalCompanyName
                          }
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell textAlign="left" width="5">
                        <CustomTitle message={"Address Line 1:"} />
                      </Table.Cell>
                      <Table.Cell textAlign="left">
                        <CustomLabel
                          message={
                            primaryDetails && primaryDetails.addressLine1
                          }
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell textAlign="left" width="5">
                        <CustomTitle message={"Address Line 2:"} />
                      </Table.Cell>
                      <Table.Cell textAlign="left">
                        <CustomLabel
                          message={
                            primaryDetails && primaryDetails.addressLine2
                          }
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell textAlign="left" width="5">
                        <CustomTitle message={"Address Line 3:"} />
                      </Table.Cell>
                      <Table.Cell textAlign="left">
                        <CustomLabel
                          message={
                            primaryDetails && primaryDetails.addressLine3
                          }
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell textAlign="left" width="5">
                        <CustomTitle message={"City:"} />
                      </Table.Cell>
                      <Table.Cell textAlign="left">
                        <CustomLabel
                          message={primaryDetails && primaryDetails.city}
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell textAlign="left" width="5">
                        <CustomTitle message={"State:"} />
                      </Table.Cell>
                      <Table.Cell textAlign="left">
                        <CustomLabel
                          message={primaryDetails && primaryDetails.state}
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell textAlign="left" width="5">
                        <CustomTitle message={"Country:"} />
                      </Table.Cell>
                      <Table.Cell textAlign="left">
                        <CustomLabel
                          message={primaryDetails && primaryDetails.country}
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell textAlign="left" width="5">
                        <CustomTitle message={"Province:"} />
                      </Table.Cell>
                      <Table.Cell textAlign="left">
                        <CustomLabel
                          message={primaryDetails && primaryDetails.province}
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell textAlign="left" width="5">
                        <CustomTitle message={"Postal Code:"} />
                      </Table.Cell>
                      <Table.Cell textAlign="left">
                        <CustomLabel
                          message={primaryDetails && primaryDetails.postalCode}
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell textAlign="left" width="5">
                        <CustomTitle message={"Taxpayer ID # (TIN/VAT):"} />
                      </Table.Cell>
                      <Table.Cell textAlign="left">
                        <CustomLabel
                          message={primaryDetails && primaryDetails.taxpayerId}
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell textAlign="left" width="5">
                        <CustomTitle message={"Contact Name:"} />
                      </Table.Cell>
                      <Table.Cell textAlign="left">
                        <CustomLabel
                          message={primaryDetails && primaryDetails.contactName}
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell textAlign="left" width="5">
                        <CustomTitle message={"Phone No.:"} />
                      </Table.Cell>
                      <Table.Cell textAlign="left">
                        <CustomLabel
                          message={primaryDetails && primaryDetails.phoneNumber}
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell textAlign="left" width="5">
                        <CustomTitle message={"Cell No.:"} />
                      </Table.Cell>
                      <Table.Cell textAlign="left">
                        <CustomLabel
                          message={
                            primaryDetails && primaryDetails.mobileNumber
                          }
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell textAlign="left" width="5">
                        <CustomTitle message={"Email Address:"} />
                      </Table.Cell>
                      <Table.Cell textAlign="left">
                        <CustomLabel
                          message={
                            primaryDetails && primaryDetails.emailAddress
                          }
                        />
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={5}>
                <Header size="medium">
                  Applicant Bill To Location Details
                </Header>
              </Grid.Column>
              <Grid.Column width={10}>
                <Table basic="very" size="small">
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell textAlign="left">
                        <CustomTitle message={"Legal Company Name:"} />
                      </Table.Cell>
                      <Table.Cell textAlign="left">
                        <CustomLabel
                          message={
                            billToDetails && billToDetails.legalCompanyName
                          }
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell textAlign="left" width="5">
                        <CustomTitle message={"Address Line 1:"} />
                      </Table.Cell>
                      <Table.Cell textAlign="left">
                        <CustomLabel
                          message={billToDetails && billToDetails.addressLine1}
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell textAlign="left" width="5">
                        <CustomTitle message={"Address Line 2:"} />
                      </Table.Cell>
                      <Table.Cell textAlign="left">
                        <CustomLabel
                          message={billToDetails && billToDetails.addressLine2}
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell textAlign="left" width="5">
                        <CustomTitle message={"Address Line 3:"} />
                      </Table.Cell>
                      <Table.Cell textAlign="left">
                        <CustomLabel
                          message={billToDetails && billToDetails.addressLine3}
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell textAlign="left" width="5">
                        <CustomTitle message={"City:"} />
                      </Table.Cell>
                      <Table.Cell textAlign="left">
                        <CustomLabel
                          message={billToDetails && billToDetails.city}
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell textAlign="left" width="5">
                        <CustomTitle message={"State:"} />
                      </Table.Cell>
                      <Table.Cell textAlign="left">
                        <CustomLabel
                          message={billToDetails && billToDetails.state}
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell textAlign="left" width="5">
                        <CustomTitle message={"Country:"} />
                      </Table.Cell>
                      <Table.Cell textAlign="left">
                        <CustomLabel
                          message={billToDetails && billToDetails.country}
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell textAlign="left" width="5">
                        <CustomTitle message={"Province:"} />
                      </Table.Cell>
                      <Table.Cell textAlign="left">
                        <CustomLabel
                          message={billToDetails && billToDetails.province}
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell textAlign="left" width="5">
                        <CustomTitle message={"Postal Code:"} />
                      </Table.Cell>
                      <Table.Cell textAlign="left">
                        <CustomLabel
                          message={billToDetails && billToDetails.postalCode}
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell textAlign="left" width="5">
                        <CustomTitle message={"Taxpayer ID # (TIN/VAT):"} />
                      </Table.Cell>
                      <Table.Cell textAlign="left">
                        <CustomLabel
                          message={billToDetails && billToDetails.taxpayerId}
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell textAlign="left" width="5">
                        <CustomTitle message={"Contact Name:"} />
                      </Table.Cell>
                      <Table.Cell textAlign="left">
                        <CustomLabel
                          message={billToDetails && billToDetails.contactName}
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell textAlign="left" width="5">
                        <CustomTitle message={"Phone No.:"} />
                      </Table.Cell>
                      <Table.Cell textAlign="left">
                        <CustomLabel
                          message={billToDetails && billToDetails.phoneNumber}
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell textAlign="left" width="5">
                        <CustomTitle message={"Cell No.:"} />
                      </Table.Cell>
                      <Table.Cell textAlign="left">
                        <CustomLabel
                          message={billToDetails && billToDetails.mobileNumber}
                        />
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell textAlign="left" width="5">
                        <CustomTitle message={"Email Address:"} />
                      </Table.Cell>
                      <Table.Cell textAlign="left">
                        <CustomLabel
                          message={billToDetails && billToDetails.emailAddress}
                        />
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={5}>
                <Header size="medium">Project Details</Header>
              </Grid.Column>
              <Grid.Column width={10}>
                <Table celled size="small" structured>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>#</Table.HeaderCell>
                      <Table.HeaderCell>Product Category</Table.HeaderCell>
                      <Table.HeaderCell>Equipment Type</Table.HeaderCell>
                      <Table.HeaderCell>Quantity</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {orders && orders.length > 0
                      ? orders.map((order, idx) => {
                          return (
                            <React.Fragment key={idx}>
                              <PriceItem
                                item={order}
                                idx={idx}
                                isQuotation={false}
                              />
                              {Object.keys(order.others).map(o => {
                                return (
                                  <Table.Row key={o}>
                                    <Table.Cell textAlign="right">
                                      Truck #
                                    </Table.Cell>
                                    <Table.Cell>{order.others[o]}</Table.Cell>
                                    <Table.Cell></Table.Cell>
                                  </Table.Row>
                                );
                              })}
                            </React.Fragment>
                          );
                        })
                      : null}
                  </Table.Body>
                </Table>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button primary as={Link} to="/home/quotation">
            Continue
          </Button>
          <Button primary basic onClick={onCloseSummaryModal}>
            Edit
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

FesSummary.propTypes = {
  openSummaryModal: PropTypes.bool.isRequired,
  onCloseSummaryModal: PropTypes.func.isRequired,
  primaryDetails: PropTypes.object.isRequired,
  billToDetails: PropTypes.object.isRequired,
  orders: PropTypes.array.isRequired
};

export default FesSummary;
