import React, { Component } from "react";
import {
  Container,
  Divider,
  Grid,
  Input,
  Button,
  Form,
  Table,
  Segment,
  Dimmer,
  Loader
} from "semantic-ui-react";
import { connect } from "react-redux";

import {
  CustomHeaderLabel,
  CustomTitleLabel,
  CustomLabel
} from "../../components/labels/labels";
import { Link } from "react-router-dom";

import { renderDisc } from "../../helpers/component-helper";

import {
  getOracleOrderDetails,
  resetOracleOrderDetails
} from "./field-evaluation.action";

class SearchQuote extends Component {
  state = {};

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSearchOrderDetails = () => {
    const { orderNumber } = this.state;

    this.props.getOracleOrderDetails(orderNumber);
  };

  componentDidMount() {
    this.props.resetOracleOrderDetails();
  }

  render() {
    const { data, isLoading } = this.props;
    const { orderNumber } = this.state;

    return (
      <div style={{ width: "80%", padding: "20px" }}>
        <Container fluid>
          <Dimmer active={isLoading} inverted>
            <Loader inverted content="Loading" />
          </Dimmer>
          <CustomHeaderLabel message="Orders and Quotes" />
          <Divider hidden />
          <Grid>
            <Grid.Row>
              <Grid.Column width={10} verticalAlign="middle">
                <Form>
                  <Form.Field>
                    <Form.Input
                      fluid
                      label="Enter Order #"
                      placeholder="Enter Order #"
                      name="orderNumber"
                      onChange={this.handleChange}
                      value={orderNumber}
                    />
                  </Form.Field>
                  <Button
                    compact
                    basic
                    color="blue"
                    onClick={this.handleSearchOrderDetails}
                  >
                    Search Orders
                  </Button>
                </Form>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Divider />
          {data && data.isSuccessful && (
            <Segment>
              <Table basic="very" fixed compact="very">
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>
                      {renderDisc("")}
                      <CustomLabel message={data.data.status} />
                    </Table.Cell>
                    <Table.Cell>
                      <CustomLabel message="Oracle Order #" />
                      {data.data.id}
                    </Table.Cell>
                    <Table.Cell>
                      <CustomLabel message="Status updated as of: " />
                      {data.data.earliestShipTime} on
                      {data.data.earliestShipDate}
                    </Table.Cell>
                    <Table.Cell width={3}>
                      <Button
                        compact
                        color="blue"
                        as={Link}
                        to={`/home/view-order-details/${data.data.id}`}
                      >
                        Copy Order
                      </Button>
                      <Button
                        basic
                        compact
                        color="blue"
                        as={Link}
                        to={`/home/view-order-details/${data.data.id}`}
                      >
                        View Details
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Segment>
          )}
          {data && !data.isSuccessful && (
            <Table>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>
                    <CustomLabel message="NO DATA FOUND." />
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          )}
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  data: state.fieldEvaluation.data,
  isLoading: state.fieldEvaluation.data.isLoading
});

const mapDispatchToProps = dispatch => ({
  getOracleOrderDetails: orderNumber =>
    dispatch(getOracleOrderDetails(orderNumber)),
  resetOracleOrderDetails: () => dispatch(resetOracleOrderDetails())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchQuote);
