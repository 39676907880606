import React, { Component } from "react";
import PropTypes from "prop-types";
import { Table } from "semantic-ui-react";
import PriceItem from "./price-item";

class Price extends Component {
  getTotal(orders) {
    var total = 0;
    if (orders && orders.length > 0) {
      orders.map((order, idx) => {
        total += order.total;
      });
    }
    return `USD ${Number.format(total, 2)}`;
  }

  render() {
    const { orders } = this.props;

    return (
      <Table attached>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell textAlign="center">PRICE</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <p>
                This Quotation is provided by or on behalf of UL LLC. We are
                pleased to provide you with the following Quotation and we will
                make every effort to exceed your expectations as we fulfill this
                work.
              </p>
              <Table basic celled structured>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>#</Table.HeaderCell>
                    <Table.HeaderCell>Product Category</Table.HeaderCell>
                    <Table.HeaderCell>Equipment Type</Table.HeaderCell>
                    <Table.HeaderCell>Quantity</Table.HeaderCell>
                    <Table.HeaderCell>Price</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {orders && orders.length > 0
                    ? orders.map((order, idx) => {
                        return (
                          <React.Fragment key={idx}>
                            <PriceItem
                              key={idx}
                              item={order}
                              idx={idx}
                              isQuotation={true}
                            />
                            {Object.keys(order.others).map(o => {
                              return (
                                <Table.Row key={o}>
                                  <Table.Cell textAlign="right">
                                    Truck #
                                  </Table.Cell>
                                  <Table.Cell>{order.others[o]}</Table.Cell>
                                  <Table.Cell></Table.Cell>
                                  <Table.Cell></Table.Cell>
                                </Table.Row>
                              );
                            })}
                          </React.Fragment>
                        );
                      })
                    : null}
                </Table.Body>
                <Table.Footer>
                  <Table.Row>
                    <Table.HeaderCell></Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                    <Table.HeaderCell>
                      <b>Total</b>
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      <b>{this.getTotal(orders)}</b>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Footer>
              </Table>

              <p>
                The price is based on the information provided in your request.
                Unless otherwise noted in the assumptions, additional expenses
                such as travel costs, shipping, disposition of samples, local
                taxes, and others may be incurred. If these are required for
                your project they will be billed to you. Invoicing for this
                project will occur at the times stated in this Quotation or at
                completion of the project.
              </p>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  }
}

Price.propTypes = {
  orders: PropTypes.array.isRequired
};

export default Price;
