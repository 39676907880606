import { requests } from '../request-factory';
import { mapDataToQueryString } from '../request-helpers';

const fieldEvaluationService = {
  createFEOrder: data => requests.post('fieldevaluation/create', data),
  updateFEOrder: data => requests.post('fieldevaluation/update', data),
  getOracleOrderDetails: orderNumber =>
    requests.get(`fieldevaluation/order/oracle-details/${orderNumber}`),
  getOrderDetails: orderNumber =>
    requests.get(`fieldevaluation/order/details/${orderNumber}`),
  getArea: () => requests.get(`fieldevaluation/area`),
  getServiceArea: areaId =>
    requests.get(`fieldevaluation/service-area/${areaId}`),
  getServiceProducts: serviceType =>
    requests.get(
      `fieldevaluation/service-product?${mapDataToQueryString({
        serviceType,
      })}`
    ),
  getServiceSubProducts: serviceProductId =>
    requests.get(`fieldevaluation/service-sub-product/${serviceProductId}`),
  getServiceProductPrice: (
    serviceAreaId,
    serviceSubProductId,
    propertyType,
    quantity,
    scheduleDate,
    siteCharge
  ) =>
    requests.get(
      `fieldevaluation/service-product-price/${serviceAreaId}/${serviceSubProductId}/${propertyType}/${quantity}/${scheduleDate}/${siteCharge}`
    ),
  getOrders: (currentPage, pageSize) =>
    requests.get(
      `fieldevaluation?${mapDataToQueryString({ currentPage, pageSize })}`
    ),
  getAllOrders: (currentPage, pageSize, isMyWork) =>
    requests.get(
      `fieldevaluation/allorders?${mapDataToQueryString({
        currentPage,
        pageSize,
        isMyWork,
      })}`
    ),
  // getOrdersByFilter: (status, currentPage, pageSize) =>
  //   requests.get(
  //     `fieldevaluation/filter?${mapDataToQueryString({
  //       status,
  //       currentPage,
  //       pageSize
  //     })}`
  //   ),
  getOrdersByFilter: data => requests.post('fieldevaluation/filter', data),
  getOrdersByFilterByUser: filter =>
    requests.post('fieldevaluation/filterbyuser', filter),
  deleteOrder: requestNumber =>
    requests.post('fieldevaluation/delete', requestNumber),
  getRequestToCancelOrders: (currentPage, pageSize) => {
    return requests.get(
      `fieldevaluation/order/get-request-to-cancel?${mapDataToQueryString({
        currentPage,
        pageSize,
      })}`
    );
  },
  requestToCancel: data =>
    requests.post('fieldevaluation/order/request-to-cancel', data),
  cancel: data => requests.post('fieldevaluation/order/cancel', data),
  updateOrderType: data =>
    requests.post('fieldevaluation/order/type/update', data),
  createAccountSettings: data =>
    requests.post('fieldevaluation/account-settings', data),
  getAccountSettings: createdForUserId =>
    requests.get(
      `fieldevaluation/account-settings?${mapDataToQueryString({
        createdForUserId,
      })}`
    ),
  getCountries: isForLabel =>
    requests.get(`fieldevaluation/countries?isForLabel=${isForLabel}`),
  getStates: countryCode =>
    requests.get(`fieldevaluation/states?CountryCode=${countryCode}`),
  getCities: stateCode =>
    requests.get(`fieldevaluation/cities?StateCode=${stateCode}`),
  getNotifications: () => requests.get('fieldevaluation/notifications'),
  getAppointments: data => requests.post('click/appointments', data),
  uploadAttachment: formData =>
    requests.upload('fieldevaluation/upload-attachment', formData),
  uploadMultiSiteAttachment: data =>
    requests.post(`fieldevaluation/upload-attachment-multi-site`, data),
  getAssignedOrders: (currentPage, pageSize) =>
    requests.get(
      `fieldevaluation/orders/assigned?${mapDataToQueryString({
        currentPage,
        pageSize,
      })}`
    ),
  getProjectHandlers: () =>
    requests.get(`fieldevaluation/orders/projecthandlers`),
  assignProjectHandlers: data =>
    requests.post(`fieldevaluation/orders/projecthandlers/assign`, data),
  unAssignProjectHandlers: data =>
    requests.post(`fieldevaluation/orders/projecthandlers/unassign`, data),
  getAllFeOrders: (currentPage, pageSize, accountNumber, requestNumber) => {
    return requests.get(
      `fieldevaluation/orders/all?${mapDataToQueryString({
        currentPage,
        pageSize,
        accountNumber,
        requestNumber,
      })}`
    );
  },
  getAllFeOrdersAdminsSearch: (
    currentPage,
    pageSize,
    accountNumber,
    requestNumber,
    IsAdminSearch
  ) => {
    return requests.get(
      `fieldevaluation/orders/all?${mapDataToQueryString({
        currentPage,
        pageSize,
        accountNumber,
        requestNumber,
        IsAdminSearch,
      })}`
    );
  },
  getCancellationCode: () => requests.get(`fieldevaluation/cancellation-code`),
  setFilterOptions: data =>
    requests.post(`fieldevaluation/orders/setfilteroptions`, data),
  getFilterOptions: () =>
    requests.get(`fieldevaluation/orders/getfilteroptions`),
  getAccountSettingsByPsn: partySiteNumber =>
    requests.get(
      `fieldevaluation/account-settings/psn?${mapDataToQueryString({
        partySiteNumber,
      })}`
    ),
  getApplicants: () => requests.get(`fieldevaluation/orders/applicants`),
  getStatesFilter: () => requests.get(`fieldevaluation/orders/statesfilter`),
  getCitiesFilter: () => requests.get(`fieldevaluation/orders/citiesfilter`),
  getCustomerRelations: (
    accountNumber,
    isBillTo,
    itemNumber,
    applicantAccountNumber
  ) =>
    requests.get(
      `fieldevaluation/customerrelation?${mapDataToQueryString({
        accountNumber,
        isBillTo,
        itemNumber,
        applicantAccountNumber,
      })}`
    ),
  getCustomerRelationInformations: accountNumber =>
    requests.get(
      `fieldevaluation/customerrelationinformation?${mapDataToQueryString({
        accountNumber,
      })}`
    ),
  createFieldServiceOrder: data =>
    requests.post('fieldevaluation/multisites/create', data),
  updateFieldServiceOrder: data =>
    requests.post('fieldevaluation/multisites/update', data),
  getPpes: () => requests.get(`fieldevaluation/ppes`),
  getTrainings: () => requests.get(`fieldevaluation/trainings`),
  isAccountContactExist: (partySiteNumber, emailAdd) =>
    requests.get(
      `fieldevaluation/account-contact-exist?${mapDataToQueryString({
        partySiteNumber,
        emailAdd,
      })}`
    ),
  isEmailExist: emailAdd =>
    requests.get(
      `fieldevaluation/account-email-valid?${mapDataToQueryString({
        emailAdd,
      })}`
    ),
};

export default fieldEvaluationService;
