import * as types from "./product-details.type";
import api from "../../../../services/api";

export const productDetailsResult = (obj, type) => {
  return {
    data: obj,
    type: type
  };
};

export const getAreas = () => {
  return async (dispatch) => {
    await api.FieldEvaluation.getArea()
      .then((result) => {
        dispatch(productDetailsResult(result, types.AREA_REQUEST));
      })
      .catch((result) => {
        dispatch(productDetailsResult(result, types.AREA_FAILURE));
      });
  };
};

export const getServiceAreas = (areaId) => {
  return async (dispatch) => {
    await api.FieldEvaluation.getServiceArea(areaId)
      .then((result) => {
        dispatch(productDetailsResult(result, types.SERVICE_AREA_REQUEST));
      })
      .catch((result) => {
        dispatch(productDetailsResult(result, types.SERVICE_AREA_FAILURE));
      });
  };
};

export const clearServiceAreas = () => (dispatch) =>
  dispatch(productDetailsResult({}, types.SERVICE_AREA_CLEAR));

export const getServiceProducts = (serviceType) => {
  return async (dispatch) => {
    await api.FieldEvaluation.getServiceProducts(serviceType)
      .then((result) => {
        dispatch(productDetailsResult(result, types.SERVICE_PRODUCT_REQUEST));
      })
      .catch((result) => {
        dispatch(productDetailsResult(result, types.SERVICE_PRODUCT_FAILURE));
      });
  };
};

export const clearServiceProducts = () => (dispatch) =>
  dispatch(productDetailsResult({}, types.SERVICE_PRODUCT_CLEAR));

export const getServiceSubProducts = (serviceProductId) => {
  return async (dispatch) => {
    await api.FieldEvaluation.getServiceSubProducts(serviceProductId)
      .then((result) => {
        dispatch(
          productDetailsResult(result, types.SERVICE_SUB_PRODUCT_REQUEST)
        );
      })
      .catch((result) => {
        dispatch(
          productDetailsResult(result, types.SERVICE_SUB_PRODUCT_FAILURE)
        );
      });
  };
};

export const clearServiceSubProducts = () => (dispatch) =>
  dispatch(productDetailsResult({}, types.SERVICE_SUB_PRODUCT_CLEAR));

export const getServiceProductPrices = (serviceAreaId, serviceSubProductId) => {
  return async (dispatch) => {
    await api.FieldEvaluation.getServiceProductPrice(
      serviceAreaId,
      serviceSubProductId
    )
      .then((result) => {
        dispatch(
          productDetailsResult(result, types.SERVICE_PRODUCT_PRICE_REQUEST)
        );
      })
      .catch((result) => {
        dispatch(
          productDetailsResult(result, types.SERVICE_PRODUCT_PRICE_FAILURE)
        );
      });
  };
};

export const clearServiceProductPrices = () => (dispatch) =>
  dispatch(productDetailsResult({}, types.SERVICE_PRODUCT_PRICE_CLEAR));

export const updateForm = (result) => {
  return (dispatch) => dispatch(productDetailsResult(result, types.FORM));
};

export const addOrder = (result) => {
  return (dispatch) => dispatch(productDetailsResult(result, types.ADD_ORDER));
};

export const removeOrder = (idx) => {
  return (dispatch) => dispatch(productDetailsResult(idx, types.REMOVE_ORDER));
};

export const clearOrder = (idx) => {
  return (dispatch) => dispatch(productDetailsResult(idx, types.CLEAR_ORDER));
};

export const updateOrder = (idx) => {
  return (dispatch) => dispatch(productDetailsResult(idx, types.UPDATE_ORDER));
};

export const clearProductDetails = () => (dispatch) =>
  dispatch(productDetailsResult({}, types.CLEAR));
