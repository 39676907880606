import * as types from "./notifications.type";

const initialState = {
  isLoadingGetNotifications: false,
  isSuccessGetNotifications: false,
  data: []
};

const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FE_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        isLoadingGetNotifications: true,
        isSuccessGetNotifications: false
      };
    case types.FE_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        isLoadingGetNotifications: false,
        isSuccessGetNotifications: true,
        data: action.data
      };
    case types.FE_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        isLoadingGetNotifications: false,
        isSuccessGetNotifications: false
      };
    default:
      return state;
  }
};

export default notificationsReducer;
