import React, { Component } from "react";
import { Table } from "semantic-ui-react";
import PropTypes from "prop-types";

import ScopeValue from "./scope-value";

class Scope extends Component {
  render() {
    const { form } = this.props;
    return (
      <Table attached>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell textAlign="center">SCOPE</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell verticalAlign="top">
              <b>{ScopeValue(form)}</b>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  }
}

Scope.propTypes = {
  form: PropTypes.object.isRequired
};

export default Scope;
