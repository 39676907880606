import React, { Component, Fragment } from "react";
import {
  Table,
  Button,
  Segment,
  Pagination,
  Container,
  Menu,
  Divider,
  Popup,
  Label
} from "semantic-ui-react";

import { renderDisc, serviceTypeDisp } from "../../../helpers/component-helper";

import { CustomLabel } from "../../../components/labels/labels";
import { Link } from "react-router-dom";

import { GetStatusCode } from "../field-evaluation-helper";
import Role from "../../../enums/role";
import Status from "../../../enums/status";

class ViewAssignedItem extends Component {
  state = {
    openTac: false
  };

  // addExpirationDuration(date, days) {
  //   debugger;
  //   var result = new Date(date);
  //   result.setDate(result.getDate() + days);
  //   return result;
  // }

  render() {
    const { data, columnOptions } = this.props;
    var roleId = localStorage.getItem("roles");
    let editLink =
      data.siteCount == 1
        ? `/home/view-order-details/edit/${data && data.orderNumber}`
        : `/home/quote-details/edit/${data && data.orderNumber}`;
    var orderNo = null;

    if (roleId == Role.fieldEngineer) {
      orderNo = data.oracleNumber ? data.oracleNumber : "UL Order fail";
    } else if (roleId == Role.projectHandler) {
      orderNo = data.oracleNumber ? data.oracleNumber : data.oracleMessage;
    }
    return (
      <Fragment>
        <Table.Row textAlign="center">
          {columnOptions &&
            columnOptions.map((column, idx) => {
              // if (
              //   column &&
              //   column.key === "projectHandler" &&
              //   column.visible === 1
              // ) {
              //   return <Table.Cell key={idx}> {data.assignedToName}</Table.Cell>;
              //}
              if (
                column &&
                column.key === "statusName" &&
                column.visible === 1
              ) {
                return (
                  <Table.Cell key={idx}>
                    {renderDisc(data.status)}
                    {GetStatusCode(data.statusName)}
                  </Table.Cell>
                );
              } else if (
                column &&
                column.key === "quoteNumber" &&
                column.visible === 1
              ) {
                return (
                  <Table.Cell key={idx}>
                    {"UL FE-" + data.orderNumber}
                  </Table.Cell>
                );
              } else if (
                column &&
                column.key === "oracleNumber" &&
                column.visible === 1
              ) {
                return <Table.Cell key={idx}>{orderNo}</Table.Cell>;
              } else if (
                column &&
                column.key === "customerReferenceNumber" &&
                column.visible === 1
              ) {
                return (
                  <Table.Cell key={idx}>
                    {" "}
                    {data.clientReferenceNumber}
                  </Table.Cell>
                );
              } else if (
                column &&
                column.key === "customerPurchaseNumber" &&
                column.visible === 1
              ) {
                return (
                  <Table.Cell key={idx}>{data.purchaseOrderNumber}</Table.Cell>
                );
              } else if (
                column &&
                column.key === "inspectionSiteLocation" &&
                column.visible === 1
              ) {
                return (
                  <Table.Cell key={idx}>
                    {" "}
                    {data.addressLine1} {data.city} {data.state} {data.country}{" "}
                    {data.postalCode}
                  </Table.Cell>
                );
              } else if (
                column &&
                column.key === "applicantLegalName" &&
                column.visible === 1
              ) {
                return (
                  <Table.Cell key={idx}>{data.applicantLegalName}</Table.Cell>
                );
              } else if (
                column &&
                column.key === "installationSite" &&
                column.visible === 1
              ) {
                return (
                  <Table.Cell key={idx}>
                    {data.addressLine1} {data.city} {data.state} {data.country}{" "}
                    {data.postalCode}
                  </Table.Cell>
                );
              } else if (
                column &&
                column.key === "scope" &&
                column.visible === 1
              ) {
                return <Table.Cell key={idx}>{data.scope || ""}</Table.Cell>;
              } else if (
                column &&
                column.key === "serviceType" &&
                column.visible === 1
              ) {
                return (
                  <Table.Cell key={idx}>
                    {serviceTypeDisp(data.serviceType)}
                  </Table.Cell>
                );
              } else if (
                column &&
                column.key === "orderSubmittedDate" &&
                column.visible === 1
              ) {
                return (
                  <Table.Cell key={idx}> {data.orderSubmittedDate}</Table.Cell>
                );
              } else if (
                column &&
                column.key === "expirationDate" &&
                column.visible === 1
              ) {
                return (
                  <Table.Cell key={idx}>{data.orderExpiredDate}</Table.Cell>
                );
              } else if (
                column &&
                column.key === "quoteAcceptDate" &&
                column.visible === 1
              ) {
                return (
                  <Table.Cell key={idx}>{data.quoteAcceptDate}</Table.Cell>
                );
              } else if (
                column &&
                column.key === "orderLastUpdatedDate" &&
                column.visible === 1
              ) {
                return (
                  <Table.Cell key={idx}>
                    {data.lastOrderActivityDate}
                  </Table.Cell>
                );
              }
            })}

          <Table.Cell width={1}>
            {data.isExpandMultiSite === true ? (
              <Label
                color="blue"
                ribbon="right"
                style={{ marginBottom: "10px" }}
              >
                {`Site 1`}
              </Label>
            ) : (
              data &&
              data.siteCount > 1 && (
                <Label
                  color="blue"
                  ribbon="right"
                  style={{ marginBottom: "10px" }}
                >
                  {data.siteCount + " Sites"}
                </Label>
              )
            )}

            <Popup
              on="click"
              position="left center"
              trigger={
                <Button basic compact color="blue" icon="ellipsis vertical" />
              }
            >
              <Menu text vertical>
                {/* Generic in all status */}
                <Menu.Item
                  position="left"
                  name="View quote"
                  style={{ textAlign: "left" }}
                  as={Link}
                  to={`/home/preview-order-details/${data.orderNumber}`}
                />

                {data.siteCount > 1 &&
                  (data.isExpandMultiSite === true ? (
                    <Menu.Item
                      position="left"
                      name="Hide Multiple Sites"
                      style={{ textAlign: "left" }}
                      onClick={() => {
                        this.props.handleExpandItem(data.id);
                      }}
                    />
                  ) : (
                    <Menu.Item
                      position="left"
                      name="Show Multiple Sites"
                      style={{ textAlign: "left" }}
                      onClick={() => {
                        this.props.handleExpandItem(data.id);
                      }}
                    />
                  ))}

                {/* <Menu.Item
                name="Copy quote"
                style={{ textAlign: "left" }}
                as={Link}
                to={`/home/view-order-details/copy/${data.orderNumber}`}
              /> */}

                {/* Draft Status */}
                {(data.status == Status.draft ||
                  data.status == Status.pendingAssessment) && (
                  <span>
                    <Menu.Item
                      name="Edit quote"
                      style={{ textAlign: "left" }}
                      as={Link}
                      to={editLink}
                    />

                    <Menu.Item
                      name="Cancel quote"
                      style={{ textAlign: "left" }}
                      as={Button}
                      onClick={() => {
                        this.props.handleCancellationCodeModal(true, data.id);
                      }}
                    />
                  </span>
                )}
                {data.status == Status.offered && (
                  <span>
                    <Menu.Item
                      name="Edit quote"
                      style={{ textAlign: "left" }}
                      as={Link}
                      to={editLink}
                    />
                    <Menu.Item
                      name="Cancel quote"
                      style={{ textAlign: "left" }}
                      as={Button}
                      onClick={() => {
                        this.props.handleCancellationCodeModal(true, data.id);
                      }}
                    />
                    <Menu.Item
                      name="Accept quote"
                      style={{ textAlign: "left" }}
                      as={Button}
                      onClick={() => {
                        this.props.handleConfirmCompleteOrder(
                          true,
                          data.id,
                          data.orderNumber
                        );
                      }}
                    />
                  </span>
                )}

                {data.assignedToId && roleId == Role.projectHandler && (
                  <Menu.Item
                    name="Un-Assign Project Handler"
                    style={{ textAlign: "left" }}
                    as={Button}
                    onClick={() => {
                      this.props.handleUnAssignProjectHandler(
                        true,
                        data.id,
                        data.assignedToName
                      );
                    }}
                  />
                )}
              </Menu>
            </Popup>
          </Table.Cell>
        </Table.Row>
        {/* ------------------------ */}
        {data.isExpandMultiSite === true &&
          data.childItems &&
          data.childItems.map((item, idx) => {
            return (
              <Table.Row textAlign="center" warning>
                {columnOptions &&
                  columnOptions.map((column, idx) => {
                    if (
                      column &&
                      column.key === "projectHandler" &&
                      column.visible === 1
                    ) {
                      return <Table.Cell key={idx}></Table.Cell>;
                    } else if (
                      column &&
                      column.key === "statusName" &&
                      column.visible === 1
                    ) {
                      return <Table.Cell key={idx}></Table.Cell>;
                    } else if (
                      column &&
                      column.key === "quoteNumber" &&
                      column.visible === 1
                    ) {
                      return <Table.Cell key={idx}></Table.Cell>;
                    } else if (
                      column &&
                      column.key === "oracleNumber" &&
                      column.visible === 1
                    ) {
                      return <Table.Cell key={idx}></Table.Cell>;
                    } else if (
                      column &&
                      column.key === "customerReferenceNumber" &&
                      column.visible === 1
                    ) {
                      return (
                        <Table.Cell key={idx}>
                          {" "}
                          {item.clientReferenceNumber}
                        </Table.Cell>
                      );
                    } else if (
                      column &&
                      column.key === "customerPurchaseNumber" &&
                      column.visible === 1
                    ) {
                      return (
                        <Table.Cell key={idx}>
                          {" "}
                          {item.purchaseOrderNumber}
                        </Table.Cell>
                      );
                    } else if (
                      column &&
                      column.key === "inspectionSiteLocation" &&
                      column.visible === 1
                    ) {
                      return (
                        <Table.Cell key={idx}>
                          {" "}
                          {item.addressLine1} {item.city} {item.state}{" "}
                          {item.country} {item.postalCode}
                        </Table.Cell>
                      );
                    } else if (
                      column &&
                      column.key === "applicantLegalName" &&
                      column.visible === 1
                    ) {
                      return (
                        <Table.Cell key={idx}>
                          {item.applicantLegalName}
                        </Table.Cell>
                      );
                    } else if (
                      column &&
                      column.key === "installationSite" &&
                      column.visible === 1
                    ) {
                      return (
                        <Table.Cell key={idx}>
                          {item.addressLine1} {item.city} {item.state}{" "}
                          {item.country} {item.postalCode}
                        </Table.Cell>
                      );
                    } else if (
                      column &&
                      column.key === "scope" &&
                      column.visible === 1
                    ) {
                      return <Table.Cell key={idx}>{item.scope}</Table.Cell>;
                    } else if (
                      column &&
                      column.key === "serviceType" &&
                      column.visible === 1
                    ) {
                      return (
                        <Table.Cell key={idx}>
                          {serviceTypeDisp(item.serviceType)}
                        </Table.Cell>
                      );
                    } else if (
                      column &&
                      column.key === "orderSubmittedDate" &&
                      column.visible === 1
                    ) {
                      return (
                        <Table.Cell key={idx}>
                          {" "}
                          {item.orderSubmittedDate}
                        </Table.Cell>
                      );
                    } else if (
                      column &&
                      column.key === "expirationDate" &&
                      column.visible === 1
                    ) {
                      return (
                        <Table.Cell key={idx}>
                          {item.orderExpiredDate}
                        </Table.Cell>
                      );
                    } else if (
                      column &&
                      column.key === "quoteAcceptDate" &&
                      column.visible === 1
                    ) {
                      return (
                        <Table.Cell key={idx}>
                          {item.quoteAcceptDate}
                        </Table.Cell>
                      );
                    } else if (
                      column &&
                      column.key === "orderLastUpdatedDate" &&
                      column.visible === 1
                    ) {
                      return (
                        <Table.Cell key={idx}>
                          {item.lastOrderActivityDate}
                        </Table.Cell>
                      );
                    }
                  })}

                <Table.Cell width={1}>
                  <Label as="a" color="blue" ribbon="right">
                    {`Site ${idx + 2}`}
                  </Label>
                </Table.Cell>
              </Table.Row>
            );
          })}
      </Fragment>
    );
  }
}

export default ViewAssignedItem;
