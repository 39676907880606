import React, { Component } from "react";
import {
  Container,
  Button,
  Dropdown,
  Grid,
  Pagination,
  Table,
  Form,
  Checkbox,
  Divider,
  Segment,
  Header,
  Icon,
  Modal
} from "semantic-ui-react";
import FooterHomeBlock from "../../../components/footer/footer-home-block";
import {
  getActiveManufacturers,
  getFileNumbers,
  changePage,
  changeFileNumber,
  changeVolume,
  sort,
  selectFilterItem,
  selectAllFilters,
  selectFileVolume,
  selectAllFileVolumes,
  checkCustomerPilotIfExist,
  checkIfvsIfUp,
  checkIfServiceContractUp,
  cleanAllFilters,
  clearSelection
} from "./applicant-dashboard.action";

import "../../../assets/css/styles.css";
import { connect } from "react-redux";
import {
  CustomHeaderLabel,
  CustomTitle
} from "../../../components/labels/labels";
import { Link } from "react-router-dom";
import { toast } from "react-semantic-toasts";
import { getCartItems } from "../../../views/manufacturer/standard/label-cart/label-cart.action";
import toastHelper from "../../../helpers/toast.helper";
import { getLabels } from "../../../views/manufacturer/standard/labels/labels.action";
import { Common } from "../../../common/common";
import Role from "../../../enums/role";
class ApplicantDashboard extends Component {
  state = {
    fileNumbers: [],
    activeFilterIndex: -1,
    firstload: true,
    openPilotModal: false,
    zoomPercent: 100,
    checkBoxItems: []
  };

  labelOrderType = null;

  updateCheckboxState(activeIndex, isSelected, text) {
    let newCheckBoxItems = JSON.parse(JSON.stringify(this.state.checkBoxItems));
    let selectedCheckbox = newCheckBoxItems.find(
      (x) => x.activeIndex == activeIndex && x.text == text
    );

    if (selectedCheckbox) {
      selectedCheckbox.isSelected = !selectedCheckbox.isSelected;
    } else {
      newCheckBoxItems.push({ activeIndex, text, isSelected: true });
    }

    this.setState({ checkBoxItems: newCheckBoxItems });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.manufacturers !== this.props.manufacturers) {
      let indexes = [
        ...new Set(this.state.checkBoxItems.map((o) => o.activeIndex))
      ];
      let newCheckBoxItems = JSON.parse(
        JSON.stringify(this.state.checkBoxItems)
      );

      for (let index of indexes) {
        switch (index) {
          case 1:
            let fileNumberItems = newCheckBoxItems.filter(
              (x) => x.activeIndex == index
            );

            //clear all volume for this infdex
            newCheckBoxItems = newCheckBoxItems.filter(
              (x) => x.activeIndex != index
            );

            for (let fileNumberItem of fileNumberItems) {
              if (
                this.props.fileNumberFilter.filter(
                  (x) => x.text == fileNumberItem.text
                ).length >= 0
              ) {
                newCheckBoxItems.push(fileNumberItem);
              }
            }

            break;
          case 2:
            let volumeItems = newCheckBoxItems.filter(
              (x) => x.activeIndex == index
            );

            //clear all volume for this infdex
            newCheckBoxItems = newCheckBoxItems.filter(
              (x) => x.activeIndex != index
            );

            for (let volumeItem of volumeItems) {
              if (
                this.props.volumeFilter.filter((x) => x.text == volumeItem.text)
                  .length >= 0
              ) {
                newCheckBoxItems.push(volumeItem);
              }
            }

            break;
          case 3:
            let ccnItems = newCheckBoxItems.filter(
              (x) => x.activeIndex == index
            );

            newCheckBoxItems = newCheckBoxItems.filter(
              (x) => x.activeIndex != index
            );

            for (let ccnItem of ccnItems) {
              if (
                this.props.ccnFilter.filter((x) => x.text == ccnItem.text)
                  .length >= 0
              ) {
                newCheckBoxItems.push(ccnItem);
              }
            }

            break;
          case 4:
            let nameItems = newCheckBoxItems.filter(
              (x) => x.activeIndex == index
            );

            //clear all volume for this infdex
            newCheckBoxItems = newCheckBoxItems.filter(
              (x) => x.activeIndex != index
            );

            for (let nameItem of nameItems) {
              if (
                this.props.nameFilter.filter((x) => x.text == nameItem.text)
                  .length >= 0
              ) {
                newCheckBoxItems.push(nameItem);
              }
            }

            break;
          case 5:
            let addressItems = newCheckBoxItems.filter(
              (x) => x.activeIndex == index
            );

            //clear all volume for this infdex
            newCheckBoxItems = newCheckBoxItems.filter(
              (x) => x.activeIndex != index
            );

            for (let addressItem of addressItems) {
              if (
                this.props.addressFilter.filter(
                  (x) => x.text == addressItem.text
                ).length >= 0
              ) {
                newCheckBoxItems.push(addressItem);
              }
            }
            break;
          case 6:
            let accountItems = newCheckBoxItems.filter(
              (x) => x.activeIndex == index
            );

            //clear all volume for this infdex
            newCheckBoxItems = newCheckBoxItems.filter(
              (x) => x.activeIndex != index
            );

            for (let accountItem of accountItems) {
              if (
                this.props.accountFilter.filter(
                  (x) => x.text == accountItem.text
                ).length >= 0
              ) {
                newCheckBoxItems.push(accountItem);
              }
            }
            break;
          case 7:
            break;
        }
      }
      this.setState({ checkBoxItems: newCheckBoxItems });
    }
  }

  filterOptions = (data, activeIndex) => {
    var result = [];

    data.forEach((item, index) => {
      result.push({
        key: index,
        text: (
          <Checkbox
            label={item.text}
            checked={
              this.state.checkBoxItems.filter(
                (x) => x.text == item.text && x.activeIndex == activeIndex
              ).length > 0
                ? this.state.checkBoxItems.find(
                    (x) => x.text == item.text && x.activeIndex == activeIndex
                  ).isSelected
                : false
            }
            onClick={(e, self) => {
              this.props.selectFilterItem(item, self.checked, activeIndex);
              this.updateCheckboxState(activeIndex, self.checked, item.text);
              e.stopPropagation();
            }}
          />
        ),
        value: item.text
      });
    });

    // result.push({
    //   key: result.length,
    //   text: (
    //     <Button
    //       className="button-fluid"
    //       onClick={() => {
    //         this.handleSetFilter();
    //       }}
    //     >
    //       {translations["LexApply"]}
    //     </Button>
    //   )
    // });

    return result;
  };

  clearFilter = () => {
    const { filters, isFilterInitial } = this.props;

    this.props.getActiveManufacturers(
      null,
      2,
      1,
      5,
      filters,
      false,
      isFilterInitial,

      this.labelOrderType,
      false
    );
    this.setState({ checkBoxItems: [] });
    this.props.cleanAllFilters();
    this.setActiveFilterIndex(-1);
  };

  setActiveFilterIndex = (index) => {
    let { activeFilterIndex } = this.state;

    if (activeFilterIndex === index) activeFilterIndex = -1;
    else activeFilterIndex = index;

    this.setState({ activeFilterIndex });
  };
  handleZoomOut = () => {
    let { zoomPercent } = this.state;

    if (zoomPercent >= 50) {
      var currentZoom = zoomPercent - 10;
      //document.body.style.zoom = currentZoom + "%";
      this.setState({ zoomPercent: currentZoom });
    }
  };

  handleZoomIn = () => {
    let { zoomPercent } = this.state;

    if (zoomPercent <= 150) {
      var currentZoom = zoomPercent + 10;
      // document.body.style.zoom = currentZoom + "%";
      this.setState({ zoomPercent: currentZoom });
    }
  };

  handleSetFilter = () => {
    const { filters, isFilterInitial } = this.props;

    this.props.getActiveManufacturers(
      null,
      2,
      1,
      5,
      filters,
      true,
      isFilterInitial,

      this.labelOrderType,
      false
    );
    this.props.clearSelection();
    this.setActiveFilterIndex(-1);
  };

  componentDidMount() {
    this.labelOrderType = this.props.match.params.labelOrderType;

    this.props.cleanAllFilters();
    this.props.getFileNumbers(this.labelOrderType);
    this.props.getCartItems();
    this.showReminderInfo();
    this.props.checkIfvsIfUp(this.labelOrderType);
    this.props.checkIfServiceContractUp();
    // this.props
    //   .getActiveManufacturers(
    //     1,
    //     5,
    //     null,
    //     null,
    //     null,
    //     null,
    //     null,
    //     null,
    //     null,
    //     null
    //   )
    //   .then((result) => {
    //     this.handleSetFilter();
    //   });

    //  this.handleSetFilter();
    const { filters, isFilterInitial } = this.props;
    this.props.getActiveManufacturers(
      null,
      2,
      1,
      5,
      filters,
      false,
      isFilterInitial,

      this.labelOrderType,
      false
    );
  }

  showReminderInfo() {
    setTimeout(() => {
      toast({
        title: "",
        description: (
          <p>{Common.getTranslations()["LexSelectProperLabelAlert"]}</p>
        ),
        time: 10000,
        size: "small",
        icon: "warning",
        color: "yellow"
      });
    }, 100);
  }

  getFileNumberText(value) {
    const filtered = this.props.fileNumbers.filter(
      (item) => item.value === value
    );

    return filtered && filtered.length > 0 ? filtered[0].text : null;
  }

  getVolumeText(value) {
    const filtered = this.props.volumes.filter((item) => item.value === value);

    return filtered && filtered.length > 0 ? filtered[0].text : null;
  }

  getSortOrder(sortColumn, sortOrder) {
    if (this.props.sortColumn !== sortColumn) return "ascending";

    return sortOrder === "ascending" ? "descending" : "ascending";
  }

  handleClosePilotModal = () => {
    this.setState({ openPilotModal: false });
  };
  handleGetLabelsMultiple = (labelOrderType) => {
    const { selectedItems, currentFileNumbers, fileVolumeCcnDetails } =
      this.props;
    var isExist = false;
    var selectedName = null;
    var selectedAddress = null;

    var fileNumbers = selectedItems[0].map((item) => {
      return item.fileNumber;
    });

    var accountNumbers = selectedItems[0].map((item) => {
      return item.manufacturerAccount;
    });

    this.props
      .checkCustomerPilotIfExist(fileNumbers, accountNumbers)
      .then((result) => {
        if (result == false && this.labelOrderType == 2) {
          this.setState({ openPilotModal: true });
        } else {
          selectedItems[0].every((item) => {
            if (currentFileNumbers.length == 0) {
              if (selectedName == null) {
                selectedName = item.manufacturerName;
                selectedAddress = item.manufacturerAddress;

                isExist = true;
              } else {
                isExist =
                  item.manufacturerName == selectedName &&
                  item.manufacturerAddress == selectedAddress;
              }
            } else {
              isExist =
                item.manufacturerName ==
                  fileVolumeCcnDetails.manufacturerName &&
                item.manufacturerAddress ==
                  fileVolumeCcnDetails.manufacturerAddress;
            }

            if (!isExist) return false;

            return true;
          });

          if (!isExist) {
            setTimeout(() => {
              toastHelper.error({
                description: (
                  <p>{Common.getTranslations()["LexFilesInSameMfrCompany"]}</p>
                )
              });
            }, 100);
          } else {
            var ccnList = selectedItems[0].map((item) => {
              item.compCcns = item.compCcns.replaceAll("/", "-");
              return item.compCcns;
            });

            var fileNumberList = selectedItems[0].map((item) => {
              return item.fileNumber;
            });

            var volumeList = selectedItems[0].map((item) => {
              return item.volume;
            });

            var manufacturerAccount = selectedItems[0][0].manufacturerAccount;

            if (labelOrderType == 1) {
              return this.props.history.push(
                `/home/label-order/${fileNumberList || ""}/${
                  ccnList || ""
                }/${manufacturerAccount}/${volumeList}`
              );
            } else if (labelOrderType == 2) {
              return this.props.history.push(
                `/home/existing-combination-labels/${fileNumberList}/${
                  volumeList || ""
                }/${ccnList || ""}/${manufacturerAccount}`
              ); // to do multiple selections
            }
          }
        }
      });
  };

  handleGetLabels = (
    fileNumber,
    id,
    manufacturerId,
    volume,
    ccn,
    manufacturerName,
    manufacturerAddress,
    manufacturerAccount,
    cartLabelOrderType
  ) => {
    const { currentFileNumbers, fileVolumeCcnDetails } = this.props;

    this.props
      .checkCustomerPilotIfExist(fileNumber, manufacturerAccount)
      .then((result) => {
        if (result == false && this.labelOrderType == 2) {
          this.setState({ openPilotModal: true });
        } else {
          id = id ? id : 0;
          manufacturerId = manufacturerId ? manufacturerId : 0;
          var isExist =
            currentFileNumbers.length == 0
              ? true
              : manufacturerName == fileVolumeCcnDetails.manufacturerName &&
                manufacturerAddress == fileVolumeCcnDetails.manufacturerAddress;
          ccn = ccn.replaceAll("/", "-");
          if (!isExist) {
            setTimeout(() => {
              toastHelper.error({
                description: (
                  <p>{Common.getTranslations()["LexFilesInSameMfrCompany"]}</p>
                )
              });
            }, 100);
          } else {
            if (cartLabelOrderType == 1)
              return this.props.history.push(
                `/home/label-order/${fileNumber}/${id}/${manufacturerId}/${
                  volume || ""
                }/${ccn || ""}/${manufacturerAccount}`
              );
            else if (cartLabelOrderType == 2)
              return this.props.history.push(
                `/home/existing-combination-labels/${fileNumber}/${id}/${manufacturerId}/${
                  volume || ""
                }/${ccn || ""}/${manufacturerAccount}`
              );
          }
        }
      });
  };

  render() {
    const {
      manufacturers,
      fileNumbers,
      fileNumberFilter2,
      fileNumberValue,
      fileNumberText,
      volumeText,
      volumes,
      ccns,
      sortColumn,
      sortOrder,
      loading,
      isLoadingManufacturers,
      fileNumberFilter,
      volumeFilter,
      ccnFilter,
      accountFilter,
      nameFilter,
      addressFilter,
      filters,
      isFilterInitial,
      isSelectedAll,
      selectedItems,
      labelCart,
      hasFileVolumeData,
      firstload,
      isIfvsIsUp,
      isServiceContractUp
    } = this.props;

    const labelOrderType = this.props.match.params.labelOrderType;
    const translations = Common.getTranslations() || {};
    const languageCode = Common.getLanguageCode() || "";
    const { openPilotModal, zoomPercent } = this.state;

    let cartLabelOrderType = null;

    const cartData = labelCart
      ? labelCart.data
        ? labelCart.data[0]
        : null
      : null;
    if (cartData) cartLabelOrderType = cartData.labelOrderType;

    const { activeFilterIndex } = this.state;

    const hasData = manufacturers && this.props.totalPages > 0;
    var userRole = Common.getUserRole();
    var caption =
      userRole === Role.applicant
        ? translations["LexManufacturerName"]
        : translations["LexApplicantCompanyName"];
    var headerCaption =
      userRole === Role.applicant ? "Applicant" : "Manufacturer";

    if (isLoadingManufacturers) {
      return (
        <div className="div-container">
          <Form loading={isLoadingManufacturers}></Form>
        </div>
      );
    } else if (!hasFileVolumeData) {
      return (
        <div className="div-container">
          <Segment placeholder style={{ textAlign: "center" }}>
            <Header icon>
              <Icon name="exclamation triangle" />
            </Header>
            {translations["LexRefreshDataBanner"]}
          </Segment>
        </div>
      );
    } else if (!hasData) {
      return (
        <div className="div-container">
          <Segment placeholder style={{ textAlign: "center" }}>
            <Header icon>
              <Icon name="exclamation triangle" />
            </Header>
            {translations["LexRefreshDataBanner"]}
          </Segment>
        </div>
      );
    } else if (!isIfvsIsUp || !isServiceContractUp) {
      //} else if (false) {
      return (
        <div className="div-container">
          <Segment placeholder>
            <p
              hidden={
                languageCode == "ja" ||
                languageCode == "ko" ||
                languageCode == "fr-CA"
              }
            >
              {translations["LexSystemMaintenanceBanner"]
                .split("\n\n")
                .map((line, i) => (
                  <span key={i}>
                    {line}
                    {i == 0 ? (
                      <span>
                        <br />
                        <br />
                      </span>
                    ) : (
                      ""
                    )}
                  </span>
                ))}
              <a
                href={
                  "https://marks.ul.com/about/ul-listing-and-classification-marks/labels/label-centers/"
                }
                target="_blank"
              >
                {" "}
                {translations["LexLabelCenter"]}
              </a>
              .
            </p>
            <p hidden={languageCode != "ja"}>
              {translations["LexSystemMaintenanceBanner"]
                .split("\n\n")
                .map((line, i) => (
                  <span key={i}>
                    {i == 0 ? (
                      <span>
                        {line}
                        <br />
                        <br />
                      </span>
                    ) : i == 1 ? (
                      line.split("ラベルセンター").map((line2, x) => (
                        <span key={x}>
                          {line2}{" "}
                          {x == 0 ? (
                            <a
                              href={
                                "https://marks.ul.com/about/ul-listing-and-classification-marks/labels/label-centers/"
                              }
                              target="_blank"
                            >
                              {translations["LexLabelCenter"]}
                            </a>
                          ) : (
                            ""
                          )}
                        </span>
                      ))
                    ) : (
                      ""
                    )}
                  </span>
                ))}
            </p>
            <p hidden={languageCode != "ko"}>
              {translations["LexSystemMaintenanceBanner"]
                .split("\n\n")
                .map((line, i) => (
                  <span key={i}>
                    {i == 0 ? (
                      <span>
                        {line}
                        <br />
                        <br />
                      </span>
                    ) : i == 1 ? (
                      line.split("라벨 센터").map((line2, x) => (
                        <span key={x}>
                          {line2}{" "}
                          {x == 0 ? (
                            <a
                              href={
                                "https://marks.ul.com/about/ul-listing-and-classification-marks/labels/label-centers/"
                              }
                              target="_blank"
                            >
                              {translations["LexLabelCenter"]}
                            </a>
                          ) : (
                            ""
                          )}
                        </span>
                      ))
                    ) : (
                      ""
                    )}
                  </span>
                ))}
            </p>
            <p hidden={languageCode != "fr-CA"}>
              {translations["LexSystemMaintenanceBanner"]
                .split("\n\n")
                .map((line, i) => (
                  <span key={i}>
                    {i == 0 ? (
                      <span>
                        {line}
                        <br />
                        <br />
                      </span>
                    ) : i == 1 ? (
                      line.split("centre d’étiquetage").map((line2, x) => (
                        <span key={x}>
                          {line2}{" "}
                          {x == 0 ? (
                            <a
                              href={
                                "https://marks.ul.com/about/ul-listing-and-classification-marks/labels/label-centers/"
                              }
                              target="_blank"
                            >
                              {translations["LexLabelCenter"]}
                            </a>
                          ) : (
                            ""
                          )}
                        </span>
                      ))
                    ) : (
                      ""
                    )}
                  </span>
                ))}
            </p>
          </Segment>
        </div>
      );
    } else {
      return (
        <div className="div-container">
          <Modal
            size="tiny"
            open={openPilotModal}
            onClose={this.handleClosePilotModal}
          >
            <Modal.Content>
              <p>
                {translations["LexNALAComboLabelPrefix"]}{" "}
                <a
                  href="https://marks.ul.com/about/designing-and-ordering-ul-marks/combination-label-order-form/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {translations["LexNALAComboLabelHyperlink"]}
                </a>{" "}
                {translations["LexNALAComboLabelSuffix"]}
              </p>
            </Modal.Content>
            <Modal.Actions>
              <Button primary onClick={this.handleClosePilotModal}>
                Close
              </Button>
            </Modal.Actions>
          </Modal>
          <Container fluid>
            <br /> <br />
            <label>
              {translations["LexYouareloggesinwiththefollowingcredentials"]}
            </label>
            <br /> <br />
            <table>
              <tr>
                <td>
                  <label
                    style={{
                      fontWeight: "bold",
                      paddingRight: "5px",
                      fontSize: "16px"
                    }}
                  >
                    {`Party Site Number`}
                  </label>
                </td>
                <td
                  style={{
                    fontSize: "16px"
                  }}
                >
                  {Common.getPartySiteNumber()}
                </td>
              </tr>
              <tr>
                <td>
                  <label
                    style={{
                      fontWeight: "bold",
                      fontSize: "16px"
                    }}
                  >
                    {translations["LexRole"]}
                  </label>
                </td>
                <td
                  style={{
                    fontSize: "16px"
                  }}
                >
                  {Common.getUserRole() == 1
                    ? translations["LexApplicant"]
                    : translations["LexManufacturer"]}
                </td>
              </tr>
            </table>
            <Grid>
              <Grid.Row>
                <Grid.Column textAlign="center">
                  {/* <CustomTitle
                    message={`You are logged with Party Site No. ${Common.getPartySiteNumber()} with ${headerCaption} role`}
                  /> */}
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Divider fitted hidden />
            <div
              style={{
                width: zoomPercent + "%"
              }}
            >
              <Form loading={loading}>
                <Grid>
                  <Grid.Row style={{ padding: "20px" }}>
                    <Grid.Column textAlign="right">
                      <span hidden={this.labelOrderType == 2}>
                        <Button
                          primary
                          onClick={this.handleGetLabelsMultiple.bind(this, 1)}
                          disabled={
                            (selectedItems[0]
                              ? selectedItems[0] && selectedItems[0].length
                              : 0) === 0 || cartLabelOrderType == 2
                          }
                        >
                          {translations["LexOrderStandardLabels"]}
                        </Button>
                      </span>
                      <span hidden={this.labelOrderType == 1}>
                        <Button
                          primary
                          onClick={this.handleGetLabelsMultiple.bind(this, 2)}
                          disabled={
                            (selectedItems[0]
                              ? selectedItems[0] && selectedItems[0].length
                              : 0) === 0 || cartLabelOrderType == 1
                          }
                        >
                          {translations["LexOrderCombinationLabels"]}
                        </Button>
                      </span>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <div>
                  <div>
                    <Table
                    // style={{
                    //   padding: "0",
                    //   border: "1px solid",
                    //   borderColor: "gray"
                    // }}
                    >
                      <Table.Row>
                        <Table.Cell></Table.Cell>
                        <Table.Cell style={{ width: "60%" }}>
                          {translations["LexActiveFiles"]}
                        </Table.Cell>
                        {/* <Table.Cell
                          textAlign="right"
                          style={{
                            width: "30%",
                            borderRight: "1px solid",
                            borderRightColor: "gray"
                          }}
                        >
                          {selectedItems[0]
                            ? selectedItems[0] && selectedItems[0].length
                            : 0}{" "}
                          {translations["LexOptionselected"]}
                        </Table.Cell> */}
                        <Table.Cell
                          textAlign="right"
                          style={{
                            width: "30%",
                            borderRight: "1px solid",
                            borderRightColor: "gray"
                          }}
                        >
                          {selectedItems[0]
                            ? selectedItems[0] && selectedItems[0].length
                            : 0}{" "}
                          {translations["LexOptionselected"]}
                        </Table.Cell>
                        <Table.Cell
                          style={{
                            width: "10%",
                            borderRight: "1px solid",
                            borderRightColor: "gray"
                          }}
                        >
                          <label
                            onClick={this.clearFilter}
                            style={{ cursor: "pointer" }}
                          >
                            <i class="undo icon"></i>
                            {translations["LexClearFilter"]}
                          </label>
                        </Table.Cell>
                        {/* <Table.Cell
                          style={{
                            width: "5%",
                            borderRight: "2px solid",
                            borderRightColor: "gray",
                            cursor: "pointer"
                          }}
                        >
                          <i
                            class="zoom-out icon"
                            onClick={this.handleZoomOut}
                          ></i>
                        </Table.Cell>
                        <Table.Cell
                          style={{
                            width: "5%",
                            borderRight: "2px solid",
                            borderRightColor: "gray",
                            cursor: "pointer"
                          }}
                        >
                          <i
                            class="zoom-in icon"
                            onClick={this.handleZoomIn}
                          ></i>
                        </Table.Cell> */}
                      </Table.Row>
                    </Table>
                  </div>
                  <div
                    style={
                      activeFilterIndex == 0
                        ? { overflowX: "scroll" }
                        : { overflowX: "unset" }
                    }
                  >
                    <Table id="standard-order-table" celled striped>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>
                            <Dropdown
                              text={caption}
                              // options={this.filterOptions(nameFilter, 4)}
                              open={activeFilterIndex === 4}
                              onClick={() => {
                                this.setActiveFilterIndex(4);
                              }}
                            >
                              <Dropdown.Menu>
                                <Dropdown.Menu scrolling>
                                  {this.filterOptions(nameFilter, 4).map(
                                    (option) => (
                                      <Dropdown.Item
                                        key={option.key}
                                        {...option}
                                      />
                                    )
                                  )}
                                </Dropdown.Menu>
                                <Button
                                  className="button-fluid"
                                  onClick={() => {
                                    this.handleSetFilter();
                                  }}
                                >
                                  {translations["LexApply"]}
                                </Button>
                              </Dropdown.Menu>
                            </Dropdown>
                            {nameFilter.find((x) => x.isSelected) != null ? (
                              <i class="filter icon"></i>
                            ) : null}
                          </Table.HeaderCell>
                          <Table.HeaderCell>
                            <Dropdown
                              //scrolling
                              text={translations["LexAddress"]}
                              //options={this.filterOptions(addressFilter, 5)}
                              open={activeFilterIndex === 5}
                              onClick={() => {
                                this.setActiveFilterIndex(5);
                              }}
                            >
                              <Dropdown.Menu>
                                <Dropdown.Menu scrolling>
                                  {this.filterOptions(addressFilter, 5).map(
                                    (option) => (
                                      <Dropdown.Item
                                        key={option.key}
                                        {...option}
                                      />
                                    )
                                  )}
                                </Dropdown.Menu>
                                <Button
                                  className="button-fluid"
                                  onClick={() => {
                                    this.handleSetFilter();
                                  }}
                                >
                                  {translations["LexApply"]}
                                </Button>
                              </Dropdown.Menu>
                            </Dropdown>

                            {addressFilter.find((x) => x.isSelected) != null ? (
                              <i class="filter icon"></i>
                            ) : null}
                          </Table.HeaderCell>
                          <Table.HeaderCell>
                            <Dropdown
                              //scrolling
                              text={translations["LexAccountNumber"]}
                              //  options={this.filterOptions(accountFilter, 6)}
                              open={activeFilterIndex === 6}
                              onClick={() => {
                                this.setActiveFilterIndex(6);
                              }}
                            >
                              <Dropdown.Menu>
                                <Dropdown.Menu scrolling>
                                  {this.filterOptions(accountFilter, 6).map(
                                    (option) => (
                                      <Dropdown.Item
                                        key={option.key}
                                        {...option}
                                      />
                                    )
                                  )}
                                </Dropdown.Menu>
                                <Button
                                  className="button-fluid"
                                  onClick={() => {
                                    this.handleSetFilter();
                                  }}
                                >
                                  {translations["LexApply"]}
                                </Button>
                              </Dropdown.Menu>
                            </Dropdown>
                            {accountFilter.find((x) => x.isSelected) != null ? (
                              <i class="filter icon"></i>
                            ) : null}
                          </Table.HeaderCell>
                          <Table.HeaderCell>
                            <Dropdown
                              // scrolling
                              text={translations["LexFileNumber"]}
                              //options={this.filterOptions(fileNumberFilter, 1)}
                              open={activeFilterIndex === 1}
                              onClick={() => {
                                this.setActiveFilterIndex(1);
                              }}
                            >
                              <Dropdown.Menu>
                                <Dropdown.Menu scrolling>
                                  {this.filterOptions(fileNumberFilter, 1).map(
                                    (option) => (
                                      <Dropdown.Item
                                        key={option.key}
                                        {...option}
                                      />
                                    )
                                  )}
                                </Dropdown.Menu>
                                <Button
                                  className="button-fluid"
                                  onClick={() => {
                                    this.handleSetFilter();
                                  }}
                                >
                                  {translations["LexApply"]}
                                </Button>
                              </Dropdown.Menu>
                            </Dropdown>

                            {fileNumberFilter.find((x) => x.isSelected) !=
                            null ? (
                              <i class="filter icon"></i>
                            ) : null}
                          </Table.HeaderCell>
                          <Table.HeaderCell>
                            <Dropdown
                              //scrolling
                              text={translations["LexVolume"]}
                              //options={this.filterOptions(volumeFilter, 2)}
                              open={activeFilterIndex === 2}
                              onClick={() => {
                                this.setActiveFilterIndex(2);
                              }}
                            >
                              <Dropdown.Menu>
                                <Dropdown.Menu scrolling>
                                  {this.filterOptions(volumeFilter, 2).map(
                                    (option) => (
                                      <Dropdown.Item
                                        key={option.key}
                                        {...option}
                                      />
                                    )
                                  )}
                                </Dropdown.Menu>
                                <Button
                                  className="button-fluid"
                                  onClick={() => {
                                    this.handleSetFilter();
                                  }}
                                >
                                  {translations["LexApply"]}
                                </Button>
                              </Dropdown.Menu>
                            </Dropdown>

                            {volumeFilter.find((x) => x.isSelected) != null ? (
                              <i class="filter icon"></i>
                            ) : null}
                          </Table.HeaderCell>
                          <Table.HeaderCell>
                            {translations["LexProductCovered"]}
                          </Table.HeaderCell>
                          <Table.HeaderCell>
                            <Dropdown
                              //scrolling
                              text="+ CCN"
                              // options={this.filterOptions(ccnFilter, 3)}
                              open={activeFilterIndex === 3}
                              onClick={() => {
                                this.setActiveFilterIndex(3);
                              }}
                              style={{ marginRight: "20px" }}
                            >
                              <Dropdown.Menu>
                                <Dropdown.Menu scrolling>
                                  {this.filterOptions(ccnFilter, 3).map(
                                    (option) => (
                                      <Dropdown.Item
                                        key={option.key}
                                        {...option}
                                      />
                                    )
                                  )}
                                </Dropdown.Menu>
                                <Button
                                  className="button-fluid"
                                  onClick={() => {
                                    this.handleSetFilter();
                                  }}
                                >
                                  {translations["LexApply"]}
                                </Button>
                              </Dropdown.Menu>
                            </Dropdown>
                            {ccnFilter.find((x) => x.isSelected) != null ? (
                              <i class="filter icon"></i>
                            ) : null}
                          </Table.HeaderCell>
                          {/* <Table.HeaderCell /> */}
                          <Table.HeaderCell>
                            <Checkbox
                              style={{
                                border: "2px solid black",
                                padding: "0px",
                                height: "23px",
                                width: "23px"
                              }}
                              checked={isSelectedAll}
                              onChange={(e, { checked }) => {
                                this.props.selectAllFileVolumes(checked);
                              }}
                            />
                          </Table.HeaderCell>
                        </Table.Row>
                        {/* <Table.Row>
                      <Table.HeaderCell
                        width={2}
                        sorted={
                          sortColumn === "ManufacturerName" ? sortOrder : null
                        }
                        disabled={!hasData}
                        onClick={(e) => {
                          if (!hasData) return;
                          this.props.sort(
                            "ManufacturerName",
                            this.getSortOrder("ManufacturerName", sortOrder),
                            this.props.activePage,
                            this.props.pageSize,
                            fileNumberText,
                            volumeText,
                            this.labelOrderType
                          );
                        }}
                      >
                        {caption} Name
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        width={2}
                        sorted={
                          sortColumn === "ManufacturerAddress"
                            ? sortOrder
                            : null
                        }
                        disabled={!hasData}
                        onClick={(e) => {
                          if (!hasData) return;
                          this.props.sort(
                            "ManufacturerAddress",
                            this.getSortOrder("ManufacturerAddress", sortOrder),
                            this.props.activePage,
                            this.props.pageSize,
                            fileNumberText,
                            volumeText,
                            this.labelOrderType
                          );
                        }}
                      >
                        {caption} Address
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        width={3}
                        sorted={
                          sortColumn === "ManufacturerAccount"
                            ? sortOrder
                            : null
                        }
                        disabled={!hasData}
                        onClick={(e) => {
                          if (!hasData) return;
                          this.props.sort(
                            "ManufacturerAccount",
                            this.getSortOrder("ManufacturerAccount", sortOrder),
                            this.props.activePage,
                            this.props.pageSize,
                            fileNumberText,
                            volumeText,
                            this.labelOrderType
                          );
                        }}
                      >
                        {caption} Account Number
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        width={2}
                        sorted={sortColumn === "FileNumber" ? sortOrder : null}
                        disabled={!hasData}
                        onClick={(e) => {
                          if (!hasData) return;
                          this.props.sort(
                            "FileNumber",
                            this.getSortOrder("FileNumber", sortOrder),
                            this.props.activePage,
                            this.props.pageSize,
                            fileNumberText,
                            volumeText,
                            this.labelOrderType
                          );
                        }}
                      >
                        File #
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        width={2}
                        sorted={sortColumn === "Volume" ? sortOrder : null}
                        disabled={!hasData}
                        onClick={(e) => {
                          if (!hasData) return;
                          this.props.sort(
                            "Volume",
                            this.getSortOrder("Volume", sortOrder),
                            this.props.activePage,
                            this.props.pageSize,
                            fileNumberText,
                            volumeText,
                            this.labelOrderType
                          );
                        }}
                      >
                        Volume #
                      </Table.HeaderCell>

                      <Table.HeaderCell
                        width={2}
                        sorted={sortColumn === "ProductType" ? sortOrder : null}
                        disabled={!hasData}
                        onClick={(e) => {
                          if (!hasData) return;
                          this.props.sort(
                            "ProductType",
                            this.getSortOrder("ProductType", sortOrder),
                            this.props.activePage,
                            this.props.pageSize,
                            fileNumberText,
                            volumeText,
                            this.labelOrderType
                          );
                        }}
                      >
                        Product Covered
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        width={2}
                        sorted={sortColumn === "Ccn" ? sortOrder : null}
                        disabled={!hasData}
                        onClick={(e) => {
                          if (!hasData) return;
                          this.props.sort(
                            "Ccn",
                            this.getSortOrder("Ccn", sortOrder),
                            this.props.activePage,
                            this.props.pageSize,
                            fileNumberText,
                            volumeText,
                            this.labelOrderType
                          );
                        }}
                      >
                        CCN
                      </Table.HeaderCell>
                      <Table.HeaderCell width={7}>Actions</Table.HeaderCell>
                      <Table.HeaderCell width={7}>Select</Table.HeaderCell>
                    </Table.Row> */}
                      </Table.Header>
                      <Table.Body>
                        {manufacturers && manufacturers.length > 0 ? (
                          manufacturers.map((data, idx) => (
                            <Table.Row key={idx}>
                              <Table.Cell className="list-td max-width-20">
                                <div className="fixed-table">
                                  <div className="ellipsis-preview">
                                    {userRole === Role.applicant
                                      ? data.manufacturerName
                                      : data.applicantName}
                                  </div>
                                </div>
                              </Table.Cell>
                              <Table.Cell className="list-td max-width-30">
                                <div className="fixed-table">
                                  <div className="ellipsis-preview">
                                    {userRole === Role.applicant
                                      ? data.manufacturerAddress
                                      : data.applicantAddress}
                                  </div>
                                </div>
                              </Table.Cell>
                              <Table.Cell
                                className="list-td"
                                style={{ width: "7%" }}
                              >
                                <div className="fixed-table">
                                  <div className="ellipsis-preview">
                                    {userRole === Role.applicant
                                      ? data.manufacturerAccount
                                      : data.applicantAccount}
                                  </div>
                                </div>
                              </Table.Cell>
                              <Table.Cell
                                className="list-td"
                                style={{ width: "8%" }}
                              >
                                <div className="fixed-table">
                                  <div className="ellipsis-preview">
                                    {data.fileNumber}
                                  </div>
                                </div>
                              </Table.Cell>
                              <Table.Cell className="list-td">
                                <div className="fixed-table">
                                  <div className="ellipsis-preview">
                                    {data.volume}
                                  </div>
                                </div>
                              </Table.Cell>
                              <Table.Cell className="list-td max-width-20">
                                <div className="fixed-table">
                                  <div className="ellipsis-preview">
                                    {data.productType}
                                  </div>
                                </div>
                              </Table.Cell>
                              <Table.Cell
                                className="list-td"
                                style={{ width: "12%" }}
                              >
                                <div className="fixed-table">
                                  <div className="ellipsis-preview">
                                    {data.compCcns}
                                  </div>
                                </div>
                              </Table.Cell>
                              {/* <Table.Cell> */}
                              {/* <Button
                        color="blue"
                        compact
                        as={Link}
                        to={"/home/view-orders/"}
                      >
                        View History
                      </Button> */}
                              {/* {this.labelOrderType === "1" && (
                            <Button
                              basic
                              primary
                              onClick={this.handleGetLabels.bind(
                                this,
                                data.fileNumber,
                                data.id,
                                data.manufacturerId,
                                data.volume,
                                data.compCcns,
                                data.manufacturerName,
                                data.manufacturerAddress,
                                data.manufacturerAccount,
                                parseInt(this.labelOrderType)
                              )}
                              disabled={cartLabelOrderType == 2}
                            >
                              Order Standard Labels
                            </Button>
                          )}
                          {this.labelOrderType === "2" && (
                            <Button
                              basic
                              primary
                              onClick={this.handleGetLabels.bind(
                                this,
                                data.fileNumber,
                                data.id,
                                data.manufacturerId,
                                data.volume,
                                data.compCcns,
                                data.manufacturerName,
                                data.manufacturerAddress,
                                data.manufacturerAccount,
                                parseInt(this.labelOrderType)
                              )}
                              disabled={cartLabelOrderType == 1}
                            >
                              Order Combination Labels
                            </Button>
                          )} */}
                              {/* <Button
                        color="blue"
                        compact
                        as={Link}
                        to={"/home/label-order/"}
                      >
                        Repeat Previous Order
                      </Button> */}
                              {/* </Table.Cell> */}
                              <Table.Cell>
                                <Checkbox
                                  style={{
                                    border: "1px solid black",
                                    padding: "0px",
                                    height: "21px",
                                    width: "21px"
                                  }}
                                  checked={data.isSelected}
                                  onChange={(e, { checked }) => {
                                    this.props.selectFileVolume(data, checked);
                                  }}
                                />
                              </Table.Cell>
                            </Table.Row>
                          ))
                        ) : (
                          <Table.Row textAlign="center">
                            <Table.Cell colSpan={9}>
                              If the File Number is not displayed, please
                              contact the Label Center for assistance.
                            </Table.Cell>
                          </Table.Row>
                        )}
                      </Table.Body>
                      <Table.Footer class="full-width">
                        <Table.Row>
                          <Table.HeaderCell colSpan="8">
                            {hasData ? (
                              <div className={"footer-container-left"}>
                                <Pagination
                                  style={{ float: "right" }}
                                  activePage={this.props.activePage}
                                  totalPages={this.props.totalPages}
                                  onPageChange={(e, { activePage }) => {
                                    this.props.getActiveManufacturers(
                                      null,
                                      2,
                                      activePage,
                                      5,
                                      filters,
                                      true,
                                      isFilterInitial,
                                      labelOrderType,
                                      false
                                    );
                                  }}
                                />
                              </div>
                            ) : (
                              <CustomHeaderLabel Message="No record found." />
                            )}
                          </Table.HeaderCell>
                        </Table.Row>
                      </Table.Footer>
                    </Table>
                  </div>
                </div>
              </Form>
            </div>
            <FooterHomeBlock />
          </Container>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  data: state.labelCart.data,
  currentFileNumbers: state.labelCart.fileNumbers,
  fileVolumeCcnDetails: state.labelCart.fileVolumeCcnDetails,
  activePage: state.applicantDashboard.activePage,
  pageSize: state.applicantDashboard.pageSize,
  totalPages: state.applicantDashboard.totalPages,
  fileNumbers: state.applicantDashboard.fileNumbers,
  manufacturers: state.applicantDashboard.manufacturers,
  loading: state.applicantDashboard.loading,
  isLoadingManufacturers: state.applicantDashboard.isLoadingManufacturers,
  fileNumberText: state.applicantDashboard.fileNumberText,
  fileNumberValue: state.applicantDashboard.fileNumberValue,
  volumeText: state.applicantDashboard.volumeText,
  volumeValue: state.applicantDashboard.volumeValue,
  volumes: state.applicantDashboard.volumes,
  sortColumn: state.applicantDashboard.sortColumn,
  sortOrder: state.applicantDashboard.sortOrder,
  filters: state.applicantDashboard.filters,
  fileNumberFilter: state.applicantDashboard.fileNumberFilter,
  volumeFilter: state.applicantDashboard.volumeFilter,
  ccnFilter: state.applicantDashboard.ccnFilter,
  accountFilter: state.applicantDashboard.accountFilter,
  nameFilter: state.applicantDashboard.nameFilter,
  addressFilter: state.applicantDashboard.addressFilter,
  selectedItems: state.applicantDashboard.selectedItems,
  labelCart: state.labelCart,
  hasFileVolumeData: state.applicantDashboard.hasFileVolumeData,
  isIfvsIsUp: state.applicantDashboard.isIfvsIsUp,
  isServiceContractUp: state.applicantDashboard.isServiceContractUp,
  filters: state.applicantDashboard.filters,
  hasFilter: state.applicantDashboard.hasFilter,
  isFilterInitial: state.applicantDashboard.isFilterInitial
});

const mapDispatchToProps = (dispatch) => ({
  getCartItems: () => dispatch(getCartItems()),
  changePage: (
    sortColumn,
    sortOrder,
    currentPage,
    pageSize,
    filters,
    hasFilter,
    isFilterInitial,
    labelOrderType
  ) =>
    dispatch(
      changePage(
        sortColumn,
        sortOrder,
        currentPage,
        pageSize,
        filters,
        hasFilter,
        isFilterInitial,
        labelOrderType
      )
    ),
  changeFileNumber: (value, text, pageSize, labelOrderType) =>
    dispatch(changeFileNumber(value, text, pageSize, labelOrderType)),
  changeVolume: (value, text, fileNumber, pageSize) =>
    dispatch(changeVolume(value, text, fileNumber, pageSize)),
  getActiveManufacturers: (
    sortColumn,
    sortOrder,
    currentPage,
    pageSize,
    filters,
    hasFilter,
    isFilterInitial,
    labelOrderType,
    isFirstLoad
  ) =>
    dispatch(
      getActiveManufacturers(
        sortColumn,
        sortOrder,
        currentPage,
        pageSize,
        filters,
        hasFilter,
        isFilterInitial,
        labelOrderType,
        isFirstLoad
      )
    ),
  getFileNumbers: (labelOrderType) => dispatch(getFileNumbers(labelOrderType)),
  sort: (
    sortColumn,
    sortOrder,
    activePage,
    pageSize,
    fileNumber,
    volume,
    labelOrderType
  ) =>
    dispatch(
      sort(
        sortColumn,
        sortOrder,
        activePage,
        pageSize,
        fileNumber,
        volume,
        labelOrderType
      )
    ),
  selectFilterItem: (data, isSelected, activeFilterIndex) =>
    dispatch(selectFilterItem(data, isSelected, activeFilterIndex)),
  selectAllFilters: (isSelected, activeFilterIndex) =>
    dispatch(selectAllFilters(isSelected, activeFilterIndex)),
  selectFileVolume: (data, isSelected) =>
    dispatch(selectFileVolume(data, isSelected)),
  selectAllFileVolumes: (isSelectedAll) =>
    dispatch(selectAllFileVolumes(isSelectedAll)),
  checkCustomerPilotIfExist: (fileNumber, manufacturerAccountNumber) =>
    dispatch(checkCustomerPilotIfExist(fileNumber, manufacturerAccountNumber)),
  checkIfvsIfUp: (labelOrderType) => dispatch(checkIfvsIfUp(labelOrderType)),
  checkIfServiceContractUp: () => dispatch(checkIfServiceContractUp()),
  cleanAllFilters: () => dispatch(cleanAllFilters()),
  clearSelection: () => dispatch(clearSelection())
});

export default connect(mapStateToProps, mapDispatchToProps)(ApplicantDashboard);
