import React, { Component } from "react";
import {
  Form,
  Grid,
  Dropdown,
  Divider,
  Checkbox,
  Input,
  Label,
  Header
} from "semantic-ui-react";
import {
  CustomTitle,
  CustomHeader,
  CustomHeaderLabel,
  CustomLabel,
  CustomHeaderLabelRelaxed,
  CustomHeaderInlineBoldRelaxed
} from "../../components/labels/labels";

const cityOptions = [
  {
    key: "Huntsville",
    value: "Huntsville",
    text: "Huntsville"
  },
  { key: "Phoenix", value: "Phoenix", text: "Phoenix" },
  {
    key: "Tucson",
    value: "Tucson",
    text: "Tucson"
  }
];

const stateOptions = [
  {
    key: "California",
    value: "California",
    text: "California"
  },
  { key: "Arizona", value: "Arizona", text: "Arizona" },
  {
    key: "Florida",
    value: "Florida",
    text: "Florida"
  }
];

const countryOptions = [
  {
    key: "Australia",
    value: "Australia",
    text: "Australia"
  },
  { key: "United States", value: "United States", text: "United States" },
  {
    key: "Switzerland",
    value: "Switzerland",
    text: "Switzerland"
  }
];

const provinceOptions = [
  {
    key: "PH-CAV",
    value: "PH-CAV",
    text: "Cavite"
  },
  { key: "PH-BTN", value: "PH-BTN", text: "Batanes" },
  {
    key: "PH-BOH",
    value: "PH-BOH",
    text: "Bohol"
  }
];

const currencyOptions = [
  {
    key: "USD",
    value: "USD",
    text: "USD"
  },
  { key: "PHP", value: "PHP", text: "PHP" },
  {
    key: "THB",
    value: "THB",
    text: "THB"
  }
];

const rules = [];

class ApplicantDetails extends Component {
  state = {};

  render() {
    let { applicantDetails } = this.props.form;
    let preview = this.props.preview;
    let primaryDetails = null;
    let billToDetails = null;
    let quoteAcceptanceDetails = null;

    if (applicantDetails) {
      primaryDetails = applicantDetails.applicantDetails;

      billToDetails = applicantDetails.applicantBillToLocationDetails;
      quoteAcceptanceDetails = applicantDetails.quoteAcceptanceDetails;
    }

    return (
      <Form name="applicantDetails">
        <CustomLabel message="APPLICANT DETAILS" />
        <Divider fitted />
        <Divider hidden />
        {primaryDetails && (
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <span hidden={preview}>
                  <Form.Field
                    id="form-input-control-lcn"
                    control={Input}
                    label="* Legal Company Name"
                    placeholder="Enter legal company name"
                    name="legalCompanyName"
                    onChange={e => {
                      this.props.handleChange(e, "AD-AD");
                    }}
                    value={primaryDetails.legalCompanyName || ""}
                    error={this.props.getErrorFor("legalCompanyName", "AD-AD")}
                    autoComplete="off"
                  />
                </span>
                <span hidden={!preview}>
                  <label>
                    <b>* Legal Company Name</b>
                  </label>
                  <label className="custom-label">
                    {primaryDetails.legalCompanyName
                      ? primaryDetails.legalCompanyName
                      : ""}
                  </label>
                </span>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row columns={3}>
              <Grid.Column>
                <span hidden={preview}>
                  <Form.Field
                    id="form-input-control-lcn"
                    control={Input}
                    label="* Address Line 1"
                    placeholder="Enter address line 1"
                    name="addressLine1"
                    onChange={e => {
                      this.props.handleChange(e, "AD-AD");
                    }}
                    value={primaryDetails.addressLine1 || ""}
                    error={this.props.getErrorFor("addressLine1", "AD-AD")}
                    autoComplete="off"
                  />
                </span>
                <span hidden={!preview}>
                  <label>
                    <b>* Address Line 1</b>
                  </label>
                  <label className="custom-label">
                    {primaryDetails.addressLine1
                      ? primaryDetails.addressLine1
                      : ""}
                  </label>
                </span>
              </Grid.Column>
              <Grid.Column>
                <span hidden={preview}>
                  <Form.Field
                    id="form-input-control-lcn"
                    control={Input}
                    label="* Address Line 2"
                    placeholder="Enter address line 2"
                    name="addressLine2"
                    onChange={e => {
                      this.props.handleChange(e, "AD-AD");
                    }}
                    value={primaryDetails.addressLine2 || ""}
                    error={this.props.getErrorFor("addressLine2", "AD-AD")}
                    autoComplete="off"
                  />
                </span>
                <span hidden={!preview}>
                  <label>
                    <b>* Address Line 2</b>
                  </label>
                  <label className="custom-label">
                    {primaryDetails.addressLine2
                      ? primaryDetails.addressLine2
                      : ""}
                  </label>
                </span>
              </Grid.Column>
              <Grid.Column>
                <span hidden={preview}>
                  <Form.Field
                    id="form-input-control-lcn"
                    control={Input}
                    label="* Address Line 3"
                    placeholder="Enter address line 3"
                    name="addressLine3"
                    onChange={e => {
                      this.props.handleChange(e, "AD-AD");
                    }}
                    value={primaryDetails.addressLine3 || ""}
                    error={this.props.getErrorFor("addressLine3", "AD-AD")}
                    autoComplete="off"
                  />
                </span>
                <span hidden={!preview}>
                  <label>
                    <b>* Address Line 3</b>
                  </label>
                  <label className="custom-label">
                    {primaryDetails.addressLine3
                      ? primaryDetails.addressLine3
                      : ""}
                  </label>
                </span>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row columns={5}>
              <Grid.Column>
                <span hidden={preview}>
                  <Form.Field
                    control={Dropdown}
                    compact
                    selection
                    label="* City"
                    placeholder="Select City"
                    options={cityOptions}
                    onChange={(e, properties) => {
                      this.props.handleDropdownChange(
                        "city",
                        properties.value,
                        "AD-AD"
                      );
                    }}
                    value={primaryDetails.city || ""}
                    error={this.props.getErrorFor("city", "AD-AD")}
                  />
                </span>
                <span hidden={!preview}>
                  <label>
                    <b>* City</b>
                  </label>
                  <label className="custom-label">
                    {primaryDetails.city ? primaryDetails.city : ""}
                  </label>
                </span>
              </Grid.Column>
              <Grid.Column>
                <span hidden={preview}>
                  <Form.Field
                    control={Dropdown}
                    compact
                    selection
                    label="* State"
                    placeholder="Select State"
                    options={stateOptions}
                    onChange={(e, properties) => {
                      this.props.handleDropdownChange(
                        "state",
                        properties.value,
                        "AD-AD"
                      );
                    }}
                    value={primaryDetails.state || ""}
                    error={this.props.getErrorFor("state", "AD-AD")}
                  />
                </span>
                <span hidden={!preview}>
                  <label>
                    <b>* State</b>
                  </label>
                  <label className="custom-label">
                    {primaryDetails.state ? primaryDetails.state : ""}
                  </label>
                </span>
              </Grid.Column>
              <Grid.Column>
                <span hidden={preview}>
                  <Form.Field
                    control={Dropdown}
                    compact
                    selection
                    label="* Country"
                    placeholder="Select Country"
                    options={countryOptions}
                    onChange={(e, properties) => {
                      this.props.handleDropdownChange(
                        "country",
                        properties.value,
                        "AD-AD"
                      );
                    }}
                    value={primaryDetails.country || ""}
                    error={this.props.getErrorFor("country", "AD-AD")}
                  />
                </span>
                <span hidden={!preview}>
                  <label>
                    <b>* Country</b>
                  </label>
                  <label className="custom-label">
                    {primaryDetails.country ? primaryDetails.country : ""}
                  </label>
                </span>
              </Grid.Column>
              <Grid.Column>
                <span hidden={preview}>
                  <Form.Field
                    id="form-input-control-lcn"
                    control={Input}
                    label="* Province"
                    placeholder="Enter Province"
                    name="province"
                    onChange={e => {
                      this.props.handleChange(e, "AD-AD");
                    }}
                    value={primaryDetails.province || ""}
                    error={this.props.getErrorFor("province", "AD-AD")}
                    autoComplete="off"
                  />
                </span>
                <span hidden={!preview}>
                  <label>
                    <b>* Province</b>
                  </label>
                  <label className="custom-label">
                    {primaryDetails.province ? primaryDetails.province : ""}
                  </label>
                </span>
              </Grid.Column>
              <Grid.Column>
                <span hidden={preview}>
                  <Form.Field
                    id="form-input-control-lcn"
                    control={Input}
                    label="* Postal Code"
                    placeholder="Enter postal code"
                    name="postalCode"
                    onChange={e => {
                      this.props.handleChange(e, "AD-AD");
                    }}
                    value={primaryDetails.postalCode || ""}
                    error={this.props.getErrorFor("postalCode", "AD-AD")}
                    autoComplete="off"
                  />
                </span>
                <span hidden={!preview}>
                  <label>
                    <b>* Postal Code</b>
                  </label>
                  <label className="custom-label">
                    {primaryDetails.postalCode ? primaryDetails.postalCode : ""}
                  </label>
                </span>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={4}>
              <Grid.Column>
                <span hidden={preview}>
                  <Form.Field
                    id="form-input-control-lcn"
                    control={Input}
                    label="* Taxpayer ID # (TIN/VAT)"
                    placeholder="Enter taxpayer id # (tin/vat)"
                    name="taxPayerId"
                    onChange={e => {
                      this.props.handleChange(e, "AD-AD");
                    }}
                    value={primaryDetails.taxPayerId || ""}
                    error={this.props.getErrorFor("taxPayerId", "AD-AD")}
                    autoComplete="off"
                  />
                </span>
                <span hidden={!preview}>
                  <label>
                    <b>* Taxpayer ID # (TIN/VAT)</b>
                  </label>
                  <label className="custom-label">
                    {primaryDetails.taxPayerId ? primaryDetails.taxPayerId : ""}
                  </label>
                </span>
              </Grid.Column>
              <Grid.Column>
                <span hidden={preview}>
                  <Form.Field
                    id="form-input-control-lcn"
                    control={Input}
                    label="* Contact Name"
                    placeholder="Enter contact name"
                    name="contactName"
                    onChange={e => {
                      this.props.handleChange(e, "AD-AD");
                    }}
                    value={primaryDetails.contactName || ""}
                    error={this.props.getErrorFor("contactName", "AD-AD")}
                    autoComplete="off"
                  />
                </span>
                <span hidden={!preview}>
                  <label>
                    <b>* Contact Name</b>
                  </label>
                  <label className="custom-label">
                    {primaryDetails.contactName
                      ? primaryDetails.contactName
                      : ""}
                  </label>
                </span>
              </Grid.Column>
              <Grid.Column>
                <span hidden={preview}>
                  <Form.Field
                    id="form-input-control-lcn"
                    control={Input}
                    label="* Phone No"
                    placeholder="Enter phone no"
                    name="phoneNumber"
                    onChange={e => {
                      this.props.handleChange(e, "AD-AD");
                    }}
                    value={primaryDetails.phoneNumber || ""}
                    error={this.props.getErrorFor("phoneNumber", "AD-AD")}
                    autoComplete="off"
                  />
                </span>
                <span hidden={!preview}>
                  <label>
                    <b>* Phone No</b>
                  </label>
                  <label className="custom-label">
                    {primaryDetails.phoneNumber
                      ? primaryDetails.phoneNumber
                      : ""}
                  </label>
                </span>
              </Grid.Column>
              <Grid.Column>
                <span hidden={preview}>
                  <Form.Field
                    id="form-input-control-lcn"
                    control={Input}
                    label="* Cell No"
                    placeholder="Enter cell no"
                    name="mobileNumber"
                    onChange={e => {
                      this.props.handleChange(e, "AD-AD");
                    }}
                    value={primaryDetails.mobileNumber || ""}
                    error={this.props.getErrorFor("mobileNumber", "AD-AD")}
                    autoComplete="off"
                  />
                </span>
                <span hidden={!preview}>
                  <label>
                    <b>* Cell No</b>
                  </label>
                  <label className="custom-label">
                    {primaryDetails.mobileNumber
                      ? primaryDetails.mobileNumber
                      : ""}
                  </label>
                </span>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <span hidden={preview}>
                  <Form.Field
                    id="form-input-control-lcn"
                    control={Input}
                    label="* Email Address"
                    placeholder="Enter email address"
                    name="emailAddress"
                    onChange={e => {
                      this.props.handleChange(e, "AD-AD");
                    }}
                    value={primaryDetails.emailAddress || ""}
                    error={this.props.getErrorFor("emailAddress", "AD-AD")}
                    autoComplete="off"
                  />
                </span>
                <span hidden={!preview}>
                  <label>
                    <b>* Email Address</b>
                  </label>
                  <label className="custom-label">
                    {primaryDetails.emailAddress
                      ? primaryDetails.emailAddress
                      : ""}
                  </label>
                </span>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
        <Divider hidden />

        <Grid>
          <Grid.Row>
            <Grid.Column>
              <CustomLabel message="APPLICANT BILL TO LOCATION DETAILS" />
              <Divider fitted />
              <Divider fitted hidden />
              <Checkbox
                label="Same as above"
                checked={applicantDetails.isSameAsAbove}
                onChange={(e, { checked }) => {
                  this.props.handleCheckboxChange(
                    "isSameAsAbove",
                    checked,
                    "AD"
                  );
                }}
                disabled={preview}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <span hidden={preview}>
                <Form.Field
                  control={Input}
                  label="* Legal Company Name"
                  placeholder="Enter legal company name"
                  name="legalCompanyName"
                  onChange={e => {
                    this.props.handleChange(e, "AD-ABTLD");
                  }}
                  value={
                    (billToDetails && billToDetails.legalCompanyName) || ""
                  }
                  error={this.props.getErrorFor("legalCompanyName", "AD-ABTLD")}
                  disabled={applicantDetails.isSameAsAbove}
                  autoComplete="off"
                />
              </span>
              <span hidden={!preview}>
                <label>
                  <b>* Legal Company Name</b>
                </label>
                <label className="custom-label">
                  {billToDetails.legalCompanyName
                    ? billToDetails.legalCompanyName
                    : ""}
                </label>
              </span>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row columns={3}>
            <Grid.Column>
              <span hidden={preview}>
                <Form.Field
                  id="form-input-control-lcn"
                  control={Input}
                  label="* Address Line 1"
                  placeholder="Enter address line 1"
                  name="addressLine1"
                  onChange={e => {
                    this.props.handleChange(e, "AD-ABTLD");
                  }}
                  value={(billToDetails && billToDetails.addressLine1) || ""}
                  error={this.props.getErrorFor("addressLine1", "AD-ABTLD")}
                  disabled={applicantDetails.isSameAsAbove}
                  autoComplete="off"
                />
              </span>
              <span hidden={!preview}>
                <label>
                  <b>* Address Line 1</b>
                </label>
                <label className="custom-label">
                  {billToDetails.addressLine1 ? billToDetails.addressLine1 : ""}
                </label>
              </span>
            </Grid.Column>
            <Grid.Column>
              <span hidden={preview}>
                <Form.Field
                  id="form-input-control-lcn"
                  control={Input}
                  label="* Address Line 2"
                  placeholder="Enter address line 2"
                  name="addressLine2"
                  onChange={e => {
                    this.props.handleChange(e, "AD-ABTLD");
                  }}
                  value={(billToDetails && billToDetails.addressLine2) || ""}
                  error={this.props.getErrorFor("addressLine2", "AD-ABTLD")}
                  disabled={applicantDetails.isSameAsAbove}
                  autoComplete="off"
                />
              </span>
              <span hidden={!preview}>
                <label>
                  <b>* Address Line 2</b>
                </label>
                <label className="custom-label">
                  {billToDetails.addressLine2 ? billToDetails.addressLine2 : ""}
                </label>
              </span>
            </Grid.Column>
            <Grid.Column>
              <span hidden={preview}>
                <Form.Field
                  id="form-input-control-lcn"
                  control={Input}
                  label="* Address Line 3"
                  placeholder="Enter address line 3"
                  name="addressLine3"
                  onChange={e => {
                    this.props.handleChange(e, "AD-ABTLD");
                  }}
                  value={(billToDetails && billToDetails.addressLine3) || ""}
                  error={this.props.getErrorFor("addressLine3", "AD-ABTLD")}
                  disabled={applicantDetails.isSameAsAbove}
                  autoComplete="off"
                />
              </span>
              <span hidden={!preview}>
                <label>
                  <b>* Address Line 3</b>
                </label>
                <label className="custom-label">
                  {billToDetails.addressLine3 ? billToDetails.addressLine3 : ""}
                </label>
              </span>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={5}>
            <Grid.Column>
              <span hidden={preview}>
                <Form.Field
                  control={Dropdown}
                  compact
                  selection
                  label="* City"
                  placeholder="Select City"
                  options={cityOptions}
                  onChange={(e, properties) => {
                    this.props.handleDropdownChange(
                      "city",
                      properties.value,
                      "AD-ABTLD"
                    );
                  }}
                  value={(billToDetails && billToDetails.city) || ""}
                  error={this.props.getErrorFor("city", "AD-ABTLD")}
                  disabled={applicantDetails.isSameAsAbove}
                />
              </span>
              <span hidden={!preview}>
                <label>
                  <b>* City</b>
                </label>
                <label className="custom-label">
                  {billToDetails.city ? billToDetails.city : ""}
                </label>
              </span>
            </Grid.Column>
            <Grid.Column>
              <span hidden={preview}>
                <Form.Field
                  control={Dropdown}
                  compact
                  selection
                  label="* State"
                  placeholder="Select State"
                  options={stateOptions}
                  onChange={(e, properties) => {
                    this.props.handleDropdownChange(
                      "state",
                      properties.value,
                      "AD-ABTLD"
                    );
                  }}
                  value={(billToDetails && billToDetails.state) || ""}
                  error={this.props.getErrorFor("state", "AD-ABTLD")}
                  disabled={applicantDetails.isSameAsAbove}
                />
              </span>
              <span hidden={!preview}>
                <label>
                  <b>* State</b>
                </label>
                <label className="custom-label">
                  {billToDetails.state ? billToDetails.state : ""}
                </label>
              </span>
            </Grid.Column>
            <Grid.Column>
              <span hidden={preview}>
                <Form.Field
                  control={Dropdown}
                  compact
                  selection
                  label="* Country"
                  placeholder="Select Country"
                  options={countryOptions}
                  onChange={(e, properties) => {
                    this.props.handleDropdownChange(
                      "country",
                      properties.value,
                      "AD-ABTLD"
                    );
                  }}
                  value={(billToDetails && billToDetails.country) || ""}
                  error={this.props.getErrorFor("country", "AD-ABTLD")}
                  disabled={applicantDetails.isSameAsAbove}
                />
              </span>
              <span hidden={!preview}>
                <label>
                  <b>* Country</b>
                </label>
                <label className="custom-label">
                  {billToDetails.country ? billToDetails.country : ""}
                </label>
              </span>
            </Grid.Column>
            <Grid.Column>
              <span hidden={preview}>
                <Form.Field
                  disabled={applicantDetails.isSameAsAbove}
                  id="form-input-control-lcn"
                  control={Input}
                  label="* Province"
                  placeholder="Enter Province"
                  name="province"
                  onChange={e => {
                    this.props.handleChange(e, "AD-ABTLD");
                  }}
                  value={(billToDetails && billToDetails.province) || ""}
                  error={this.props.getErrorFor("province", "AD-ABTLD")}
                  autoComplete="off"
                />
              </span>
              <span hidden={!preview}>
                <label>
                  <b>* Province</b>
                </label>
                <label className="custom-label">
                  {billToDetails.province ? billToDetails.province : ""}
                </label>
              </span>
            </Grid.Column>
            <Grid.Column>
              <span hidden={preview}>
                <Form.Field
                  id="form-input-control-lcn"
                  control={Input}
                  label="* Postal Code"
                  placeholder="Enter postal code"
                  name="postalCode"
                  onChange={e => {
                    this.props.handleChange(e, "AD-ABTLD");
                  }}
                  value={(billToDetails && billToDetails.postalCode) || ""}
                  error={this.props.getErrorFor("postalCode", "AD-ABTLD")}
                  disabled={applicantDetails.isSameAsAbove}
                  autoComplete="off"
                />
              </span>
              <span hidden={!preview}>
                <label>
                  <b>* Postal Code</b>
                </label>
                <label className="custom-label">
                  {billToDetails.postalCode ? billToDetails.postalCode : ""}
                </label>
              </span>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={4}>
            <Grid.Column>
              <span hidden={preview}>
                <Form.Field
                  id="form-input-control-lcn"
                  control={Input}
                  label="* Taxpayer ID # (TIN/VAT)"
                  placeholder="Enter taxpayer id # (tin/vat)"
                  name="taxPayerId"
                  onChange={e => {
                    this.props.handleChange(e, "AD-ABTLD");
                  }}
                  value={(billToDetails && billToDetails.taxPayerId) || ""}
                  error={this.props.getErrorFor("taxPayerId", "AD-ABTLD")}
                  disabled={applicantDetails.isSameAsAbove}
                  autoComplete="off"
                />
              </span>
              <span hidden={!preview}>
                <label>
                  <b>* Taxpayer ID # (TIN/VAT)</b>
                </label>
                <label className="custom-label">
                  {billToDetails.taxPayerId ? billToDetails.taxPayerId : ""}
                </label>
              </span>
            </Grid.Column>
            <Grid.Column>
              <span hidden={preview}>
                <Form.Field
                  id="form-input-control-lcn"
                  control={Input}
                  label="* Contact Name"
                  placeholder="Enter contact name"
                  name="contactName"
                  onChange={e => {
                    this.props.handleChange(e, "AD-ABTLD");
                  }}
                  value={(billToDetails && billToDetails.contactName) || ""}
                  error={this.props.getErrorFor("contactName", "AD-ABTLD")}
                  disabled={applicantDetails.isSameAsAbove}
                  autoComplete="off"
                />
              </span>
              <span hidden={!preview}>
                <label>
                  <b>* Contact Name</b>
                </label>
                <label className="custom-label">
                  {billToDetails.contactName ? billToDetails.contactName : ""}
                </label>
              </span>
            </Grid.Column>
            <Grid.Column>
              <span hidden={preview}>
                <Form.Field
                  id="form-input-control-lcn"
                  control={Input}
                  label="* Phone No"
                  placeholder="Enter phone no"
                  name="phoneNumber"
                  onChange={e => {
                    this.props.handleChange(e, "AD-ABTLD");
                  }}
                  value={(billToDetails && billToDetails.phoneNumber) || ""}
                  error={this.props.getErrorFor("phoneNumber", "AD-ABTLD")}
                  disabled={applicantDetails.isSameAsAbove}
                  autoComplete="off"
                />
              </span>
              <span hidden={!preview}>
                <label>
                  <b>* Phone No</b>
                </label>
                <label className="custom-label">
                  {billToDetails.phoneNumber ? billToDetails.phoneNumber : ""}
                </label>
              </span>
            </Grid.Column>
            <Grid.Column>
              <span hidden={preview}>
                <Form.Field
                  id="form-input-control-lcn"
                  control={Input}
                  label="* Cell No"
                  placeholder="Enter cell no"
                  name="mobileNumber"
                  onChange={e => {
                    this.props.handleChange(e, "AD-ABTLD");
                  }}
                  value={(billToDetails && billToDetails.mobileNumber) || ""}
                  error={this.props.getErrorFor("mobileNumber", "AD-ABTLD")}
                  disabled={applicantDetails.isSameAsAbove}
                  autoComplete="off"
                />
              </span>
              <span hidden={!preview}>
                <label>
                  <b>* Cell No</b>
                </label>
                <label className="custom-label">
                  {billToDetails.mobileNumber ? billToDetails.mobileNumber : ""}
                </label>
              </span>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <span hidden={preview}>
                <Form.Field
                  id="form-input-control-lcn"
                  control={Input}
                  label="* Email Address"
                  placeholder="Enter email address"
                  name="emailAddress"
                  onChange={e => {
                    this.props.handleChange(e, "AD-ABTLD");
                  }}
                  value={(billToDetails && billToDetails.emailAddress) || ""}
                  error={this.props.getErrorFor("emailAddress", "AD-ABTLD")}
                  disabled={applicantDetails.isSameAsAbove}
                  autoComplete="off"
                />
              </span>
              <span hidden={!preview}>
                <label>
                  <b>* Email Address</b>
                </label>
                <label className="custom-label">
                  {billToDetails.emailAddress ? billToDetails.emailAddress : ""}
                </label>
              </span>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider hidden />
        <CustomLabel message="INVOICE DETAILS" />
        <Divider fitted />
        <Divider hidden />
        {quoteAcceptanceDetails && (
          <Grid>
            <Grid.Row columns={2}>
              <Grid.Column>
                <Form.Field>
                  <Checkbox
                    label="Does your company require Purchase Orders to make payments on vendor invoices?"
                    checked={quoteAcceptanceDetails.isPurchaseOrderRequired}
                    onChange={(e, { checked }) => {
                      this.props.handleCheckboxChange(
                        "isPurchaseOrderRequired",
                        checked,
                        "AD-QAD"
                      );
                    }}
                    disabled={preview}
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column>
                <span hidden={preview}>
                  <Form.Field
                    control={Dropdown}
                    compact
                    selection
                    label="* Billing Currency"
                    placeholder="Select billing currency"
                    options={currencyOptions}
                    onChange={(e, properties) => {
                      this.props.handleDropdownChange(
                        "currencyCode",
                        properties.value,
                        "AD-QAD"
                      );
                    }}
                    value={
                      (quoteAcceptanceDetails &&
                        quoteAcceptanceDetails.currencyCode) ||
                      ""
                    }
                    error={this.props.getErrorFor("currencyCode", "AD-QAD")}
                  />
                </span>
                <span hidden={!preview}>
                  <label>
                    <b>Billing Currency</b>
                  </label>
                  <label className="custom-label">
                    {quoteAcceptanceDetails.currencyCode
                      ? quoteAcceptanceDetails.currencyCode
                      : ""}
                  </label>
                </span>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <span hidden={preview}>
                  <Form.Field>
                    <Checkbox
                      label="Invoice Delivery"
                      checked={
                        quoteAcceptanceDetails.isInvoiceDeliveryRequested
                      }
                      onChange={(e, { checked }) => {
                        this.props.handleCheckboxChange(
                          "isInvoiceDeliveryRequested",
                          checked,
                          "AD-QAD"
                        );
                      }}
                    />
                  </Form.Field>
                </span>
                <span hidden={!preview}>
                  <label>
                    <b>Invoice Delivery</b>
                  </label>
                  <label className="custom-label">
                    {quoteAcceptanceDetails.isInvoiceDeliveryRequested
                      ? quoteAcceptanceDetails.isInvoiceDeliveryRequested
                      : ""}
                  </label>
                </span>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
              <Grid.Column>
                <span hidden={preview}>
                  <Form.Field
                    id="form-input-control-lcn"
                    control={Input}
                    label="* Billing Contact Name"
                    placeholder="Enter billing contact name"
                    name="billingContactName"
                    onChange={e => {
                      this.props.handleChange(e, "AD-QAD");
                    }}
                    value={quoteAcceptanceDetails.billingContactName || ""}
                    error={this.props.getErrorFor(
                      "billingContactName",
                      "AD-QAD"
                    )}
                    autoComplete="off"
                  />
                </span>
                <span hidden={!preview}>
                  <label>
                    <b>* Billing Contact Name</b>
                  </label>
                  <label className="custom-label">
                    {quoteAcceptanceDetails.billingContactName
                      ? quoteAcceptanceDetails.billingContactName
                      : ""}
                  </label>
                </span>
              </Grid.Column>
              <Grid.Column>
                <span hidden={preview}>
                  <Form.Field
                    id="form-input-control-lcn"
                    control={Input}
                    label="* Billing Contact Email"
                    placeholder="Enter billing contact email"
                    name="billingEmailAddress"
                    onChange={e => {
                      this.props.handleChange(e, "AD-QAD");
                    }}
                    value={quoteAcceptanceDetails.billingEmailAddress || ""}
                    error={this.props.getErrorFor(
                      "billingEmailAddress",
                      "AD-QAD"
                    )}
                    autoComplete="off"
                  />
                </span>
                <span hidden={!preview}>
                  <label>
                    <b>* Billing Contact Email</b>
                  </label>
                  <label className="custom-label">
                    {quoteAcceptanceDetails.billingEmailAddress
                      ? quoteAcceptanceDetails.billingEmailAddress
                      : ""}
                  </label>
                </span>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
      </Form>
    );
  }
}

export default ApplicantDetails;
