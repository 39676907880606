import * as types from "./admin-page.type";

let initialState = {
  data: [],
  currentPage: 1,
  pageSize: 10,
  totalPages: 1,
  loading: false,
  isSuccess: false,
  userPsns: [],
  userPsnsOption: [],
  manufacturers: [],
  manufacturersOption: [],
  cartOrders: [],
  cartOrdersOption: [],
  helpMeTotalRequest: 0,
  form: {
    showErrors: false,
    validationErrors: {}
  }
};

const adminPageReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
    case types.GET_USER_PSN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.GET_USER_PSN_SUCCESS:
    case types.GET_USER_PSN_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        userPsns: action.data.data || initialState.data,
        userPsnsOption:
          action.data &&
          action.data.data.map((i, index) => {
            return {
              key: index,
              value: i.partySiteNumber,
              text: `${i.partySiteNumber} - ${i.firstName} ${i.lastName}`
            };
          }),
        isSuccess: action.data.isSuccessful
      });
    case types.GET_MANUFACTURER_ACCOUNT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.GET_MANUFACTURER_ACCOUNT_SUCCESS:
    case types.GET_MANUFACTURER_ACCOUNT_FAILURE:
      var manufacturerResult = Object.assign({}, state, {
        loading: false,
        manufacturers: action.data.data || initialState.data,
        manufacturersOption:
          action.data &&
          action.data.data.map((i) => {
            return {
              key: i.manufacturerAccountNumber,
              value: i.manufacturerAccountNumber,
              text: `${
                i.manufacturerAccountNumber
              } - ${i.manufacturerName?.trim()}`
            };
          }),
        isSuccess: action.data.isSuccessful
      });

      var uniqueList = [];

      manufacturerResult.manufacturersOption.forEach((item) => {
        if (!uniqueList.find((l) => l.key === item.key)) {
          uniqueList.push(item);
        }
      });

      manufacturerResult.manufacturersOption = uniqueList;

      return manufacturerResult;

    case types.ADMIN_PAGE_FORM:
      return Object.assign({}, state, {
        form: action.data || initialState.form
      });
    case types.ADMIN_PAGE_CLEAR:
      return Object.assign({}, state, {
        userPsns: [],
        form: {
          isSuccess: false,
          loading: false,
          showErrors: false,
          validationErrors: {}
        }
      });

    case types.GET_HELPME_TOTAL_REQUEST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.GET_HELPME_TOTAL_REQUEST_SUCCESS:
    case types.GET_HELPME_TOTAL_REQUEST_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        helpMeTotalRequest: action.data
          ? action.data.data
          : initialState.helpMeTotalRequest,
        isSuccess: action.data ? action.data.isSuccessful : false
      });
  }
};

export default adminPageReducer;
