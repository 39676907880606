import React, { Component } from "react";
import {
  Table,
  Button,
  Segment,
  Pagination,
  Container,
  Menu,
  Modal,
  Popup
} from "semantic-ui-react";

import { renderDisc } from "../../../helpers/component-helper";

import { CustomLabel } from "../../../components/labels/labels";
import { Link } from "react-router-dom";

import { GetStatusCode } from "../field-evaluation-helper";

import { deleteDraftQuote } from "./view-draft-quote.action";

import { connect } from "react-redux";

class QuoteItem extends Component {
  state = {
    open: false
  };

  handleDelete = requestNumber => {
    const postData = {};
    postData.requestNumber = requestNumber;
    this.props.deleteDraftQuote(postData);
  };

  handleConfirm = () => {
    this.setState({ open: false });
    const postData = {};
    postData.requestNumber = this.state.requestNumber;
    this.props.deleteDraftQuote(postData);
  };

  handleOpenModal = requestNumber => {
    this.setState({ open: true, requestNumber: requestNumber });
  };

  handleCancel = () => {
    this.setState({ open: false });
  };

  render() {
    const { data, columnOptions } = this.props;
    const { open } = this.state;
    return (
      <Table.Row textAlign="center">
        <Modal size="mini" open={open} onClose={this.handleCancel}>
          <Modal.Header>Delete draft quote</Modal.Header>
          <Modal.Content>
            <p>Are you sure you want to delete this quote?</p>
          </Modal.Content>
          <Modal.Actions>
            <Button primary onClick={this.handleConfirm}>
              Delete
            </Button>
            <Button primary basic onClick={this.handleCancel}>
              Cancel
            </Button>
          </Modal.Actions>
        </Modal>

        {columnOptions &&
          columnOptions.map((column, idx) => {
            if (column && column.key === "status" && column.visible === 1) {
              return (
                <Table.Cell key={idx}>
                  {renderDisc(data.status)}
                  {data.statusName}
                </Table.Cell>
              );
            } else if (
              column &&
              column.key === "orderNumber" &&
              column.visible === 1
            ) {
              return <Table.Cell key={idx}> {data.orderNumber}</Table.Cell>;
            } else if (
              column &&
              column.key === "orderDate" &&
              column.visible === 1
            ) {
              return <Table.Cell key={idx}> {data.orderDate}</Table.Cell>;
            } else if (
              column &&
              column.key === "lastOrderActivityData" &&
              column.visible === 1
            ) {
              return (
                <Table.Cell key={idx}> {data.lastOrderActivityData}</Table.Cell>
              );
            } else if (
              column &&
              column.key === "inspectionType" &&
              column.visible === 1
            ) {
              return <Table.Cell key={idx}> Field Engineering</Table.Cell>;
            }
          })}

        <Table.Cell width={1}>
          <Popup
            on="click"
            position="left center"
            trigger={
              <Button basic compact color="blue" icon="ellipsis vertical" />
            }
          >
            <Menu text vertical>
              {/* <Menu.Item
                name="Copy quote"
                as={Link}
                to={`/home/view-order-details/${data.orderNumber}`}
              /> */}
              <Menu.Item
                name="View quote"
                style={{ textAlign: "left" }}
                as={Link}
                to={`/home/preview-quote-details/${data.orderNumber}`}
              />
              <Menu.Item
                name="Edit quote"
                style={{ textAlign: "left" }}
                as={Link}
                to={`/home/view-order-details/${data.orderNumber}`}
              />
              <Menu.Item
                name="Delete quote"
                style={{ textAlign: "left" }}
                as={Button}
                onClick={this.handleOpenModal.bind(this, data.orderNumber)}
              />
            </Menu>
          </Popup>
        </Table.Cell>
      </Table.Row>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  deleteDraftQuote: requestNumber => dispatch(deleteDraftQuote(requestNumber))
});

export default connect(null, mapDispatchToProps)(QuoteItem);
