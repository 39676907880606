import React, { Component, Fragment } from "react";
import {
  Accordion,
  Icon,
  Divider,
  Segment,
  Grid,
  Radio,
  Form,
  Button
} from "semantic-ui-react";
import {
  CustomLabel,
  CustomHeaderInlineBold,
  CustomHeaderBold,
  CustomHeaderLabel,
  RequiredFieldMessage
} from "../../components/labels/labels";

import LabelImageDetails from "../../components/label-image-details/label-image-details";
import { renderAsterisk } from "../../helpers/component-helper";

const labelOptions = [
  {
    key: "",
    value: "",
    text: "Choose from available options"
  },
  {
    key: "OWLC2",
    value: "OWLC2",
    text: "LIMITED-POWER WIRE AND CABLE USING INSULATED COPPER-CLAD"
  },
  { key: "GSYX", value: "GSYX", text: "SWINGING TYPE FIRE DOOR FIRE" },
  {
    key: "QPTZ",
    value: "QPTZ",
    text: "POWER-LIMITED CIRCUIT CABLE 100 FEET FORM"
  },
  { key: "MZQE", value: "MZQE", text: "FIRE HOSE UL CERTIFIED SAFETY US" },
  {
    key: "GSPR7",
    value: "GSPR7",
    text: "CHUTE FRAME AND FIRE DOOR ASSEMBLY FIRE "
  }
];

const denominationOptions = [
  {
    key: "",
    value: "",
    text: "Choose from available options"
  },
  {
    key: "ft",
    value: "ft",
    text: "FEET"
  },
  {
    key: "in",
    value: "in",
    text: "INCH"
  },
  {
    key: "cm",
    value: "cm",
    text: "CENTIMETER"
  }
];

class LabelDetails extends Component {
  state = {
    activeIndex: 0,
    value: "ul",
    selectedLabelImage: {},
    purchaseOrderNumber: "",
    partNumber: "",
    labelType: "",
    additionalDetails: "",
    denomination: ""
  };

  toggleHelp = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  selectMarking = ({ value }) => this.setState({ value });

  handleLabelType = (e, { name, value }) => {
    let { selectedLabelImage, labelType } = this.state;
    const image = labelOptions.filter(l => l.value === value)[0];

    selectedLabelImage = image;
    labelType = value;

    this.setState({ selectedLabelImage, labelType });

    this.props.onHandleChange(name, value);
  };

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
    this.props.onHandleChange(name, value);
  };

  render() {
    const {
      activeIndex,
      labelType,
      purchaseOrderNumber,
      partNumber,
      additionalDetails,
      denomination
    } = this.state;

    return (
      <Fragment>
        <Segment>
          <Accordion>
            <Accordion.Title
              active={activeIndex === 0}
              index={0}
              onClick={this.toggleHelp}
            >
              <Icon name="dropdown" />
              <CustomHeaderLabel message="Label details" />
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 0}>
              <Divider />
              <Grid>
                <Grid.Column width={4} style={{ textAlign: "justify" }}>
                  <i>
                    Please fill out the details of the label you're ordering and
                    feel free to contact us for assistance.
                  </i>
                </Grid.Column>
                <Grid.Column width={10}>
                  <Form.Field>
                    <CustomHeaderInlineBold
                      message={"Customer purchase order number (optional)"}
                    />
                    {renderAsterisk()}
                    <RequiredFieldMessage
                      isError={this.props.fieldErrors.purchaseOrderNumberError}
                    />
                    <Form.Input
                      placeholder="PO#"
                      error={this.props.fieldErrors.purchaseOrderNumberError}
                      name="purchaseOrderNumber"
                      value={purchaseOrderNumber}
                      onChange={this.handleChange}
                    />
                  </Form.Field>
                  <Form.Field>
                    <CustomHeaderInlineBold message="Customer part number (optional)" />
                    {renderAsterisk()}
                    <RequiredFieldMessage
                      isError={this.props.fieldErrors.partNumberError}
                    />
                    <Form.Input
                      placeholder="Part number"
                      error={this.props.fieldErrors.partNumberError}
                      name="partNumber"
                      value={partNumber}
                      onChange={this.handleChange}
                    />
                  </Form.Field>
                  <Form.Field>
                    <CustomLabel message="UL MARKING" />
                  </Form.Field>
                  <Form.Field>
                    <Radio
                      label="UL"
                      name="radioGroup"
                      value="ul"
                      checked={this.state.value === "ul"}
                      onChange={this.selectMarking}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Radio
                      label="cUL"
                      name="radioGroup"
                      value="cul"
                      checked={this.state.value === "cul"}
                      onChange={this.selectMarking}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Radio
                      label="Enhancement Mark-US"
                      name="radioGroup"
                      value="mark-us"
                      checked={this.state.value === "mark-us"}
                      onChange={this.selectMarking}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Radio
                      label="Enhancement Mark-CA"
                      name="radioGroup"
                      value="mark-ca"
                      checked={this.state.value === "mark-ca"}
                      onChange={this.selectMarking}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Radio
                      label="Enhancement Mark-US/CA"
                      name="radioGroup"
                      value="mark-us-ca"
                      checked={this.state.value === "mark-us-ca"}
                      onChange={this.selectMarking}
                    />
                  </Form.Field>
                  <Form.Field>
                    <CustomHeaderInlineBold message="Label type" />
                    {renderAsterisk()}
                    <RequiredFieldMessage
                      isError={this.props.fieldErrors.labelTypeError}
                    />
                    <Form.Select
                      placeholder="Choose from available options"
                      options={labelOptions}
                      onChange={this.handleLabelType}
                      error={this.props.fieldErrors.labelTypeError}
                      name="labelType"
                      value={labelType}
                    />
                  </Form.Field>
                  <Form.Field>
                    <LabelImageDetails
                      imageDetails={this.state.selectedLabelImage}
                    />
                  </Form.Field>
                  <Form.Field>
                    <CustomHeaderInlineBold message="Additional details to be printed" />
                    {renderAsterisk()}
                    <RequiredFieldMessage
                      isError={this.props.fieldErrors.additionalDetailsError}
                    />
                    <Form.Input
                      placeholder="Additional label details"
                      error={this.props.fieldErrors.additionalDetailsError}
                      name="additionalDetails"
                      value={additionalDetails}
                      onChange={this.handleChange}
                    />
                  </Form.Field>
                  <Form.Field>
                    <CustomHeaderInlineBold message="Denomination" />
                    {renderAsterisk()}
                    <RequiredFieldMessage
                      isError={this.props.fieldErrors.denominationError}
                    />
                    <Form.Select
                      placeholder="Choose a denomination"
                      options={denominationOptions}
                      onChange={this.handleChange}
                      error={this.props.fieldErrors.denominationError}
                      name="denomination"
                      value={denomination}
                    />
                  </Form.Field>
                  <Form.Field>
                    <CustomHeaderBold message="Upload vendor authorization" />
                    <Button primary basic>
                      Choose file
                    </Button>
                  </Form.Field>
                  <Form.Field>
                    <CustomHeaderBold message="Upload artwork" />
                    <Button primary basic>
                      Choose file
                    </Button>
                  </Form.Field>
                </Grid.Column>
              </Grid>
            </Accordion.Content>
          </Accordion>
        </Segment>
      </Fragment>
    );
  }
}

export default LabelDetails;
