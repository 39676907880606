import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

import {
  doResetPasswordValidate,
  clearResetPassword
} from "./reset-password.action";
import ResetPasswordMessage from "./reset-password-message";

class ResetPasswordValidate extends Component {
  componentDidMount() {
    // TODO: Fix this
    // let token = this.props.match.params.token;
    let token = this.props.history.location.search.replace("?t=", "");
    this.props.validateToken(escape(token));
  }

  componentWillUnmount() {
    this.props.clear();
  }

  render() {
    // TODO: Fix this bgcolor
    document.getElementById("root").style.backgroundColor = "#0b3c61";
    const { data } = this.props.resetPasswordValidate;
    return data.isSuccessful ? (
      <Redirect
        to={{ pathname: "/reset-password", state: { token: data.data } }}
      />
    ) : (
      <ResetPasswordMessage header="Error" message={data.message || ""} />
    );
  }
}

const mapStateToProps = state => {
  return {
    resetPasswordValidate: state.user.resetPassword
  };
};

const mapDispatchToProps = dispatch => ({
  validateToken: token => dispatch(doResetPasswordValidate(token)),
  clear: () => dispatch(clearResetPassword())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPasswordValidate);
