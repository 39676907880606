import { connect } from "react-redux";
import React, { Component, Fragment } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import HeaderBlock from "../../components/header/header-block";
import { Button, Header, Icon, Segment } from "semantic-ui-react";
import { doLogout, doExternalLogout } from "../../views/auth/auth.action";
import { Common } from "../../common/common";
import IdentityProvider from "../../enums/identity-provider";

const IDENTITY_PROVIDER = `${process.env.REACT_APP_IDENTITY_PROVIDER}`;
const API_BASE = `${process.env.REACT_APP_API_BASE}`;

class SuspendedPage extends Component {
  handleLogout = () => {
    if (IDENTITY_PROVIDER == IdentityProvider.Iam) {
      localStorage.clear();
      var externalLogoutURL = `${API_BASE}auth/logout`;
      window.location.replace(externalLogoutURL);
      this.props.logout();
    } else if (IDENTITY_PROVIDER == IdentityProvider.B2c) {
      var b2cToken = Common.getB2cToken();
      localStorage.clear();

      Common.toggleLogout(b2cToken);
    }
  };

  render() {
    return (
      <Fragment>
        <Router>
          <HeaderBlock />
          <Segment
            placeholder
            style={{ marginLeft: "35%", marginRight: "35%" }}
          >
            <Header icon>
              <Icon name="exclamation triangle" />
              <br />
              Account deactivated
            </Header>
            <p>
              <p>Dear {Common.getFullName()},</p>
              <p>
                Your account has been deactivated from UL's online ordering tool
                on MyUL Portal.
              </p>
              <p>
                If it has been disabled in error or if you want to reactivate
                your account, please contact:
              </p>
              <p>
                The <b>Label Center team</b> by clicking on the following link
                URL:&nbsp;
                <a href="https://marks.ul.com/about/ul-listing-and-classification-marks/labels/label-centers">
                  https://marks.ul.com/about/ul-listing-and-classification-marks/labels/label-centers
                </a>
                &nbsp;for<b> Labels Service</b>
              </p>
            </p>
            <p>
              {/* <p>
                The <b>Field Services team</b>- Telephone: 001-877-854-3577, Ext
                2, Email: <a href="mailto:field@ul.com">field@ul.com</a> for
                <b> Field Evaluation Service</b>
              </p> */}
              <p>Thank you.</p>
            </p>
            <hr />
            <Button primary onClick={this.handleLogout}>
              Back to Login
            </Button>
          </Segment>
        </Router>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLogoutSuccess: state.auth.isLogoutSuccess
  };
};

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(doLogout()),
  logoutExternal: () => dispatch(doExternalLogout())
});

export default connect(mapStateToProps, mapDispatchToProps)(SuspendedPage);
