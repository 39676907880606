import React, { Component } from "react";
import { Table } from "semantic-ui-react";
import { connect } from "react-redux";
import AssumptionValue from "./assumption-value";

class Assumption extends Component {
  createMarkup(string) {
    return { __html: string };
  }

  render() {
    const { productDetails } = this.props;
    let assumption = AssumptionValue(productDetails, null, true);
    return (
      <Table attached>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell textAlign="center">
              QUOTE ASSUMPTIONS
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell verticalAlign="top">
              {/* {productDetails.form.assumptions ? ( */}
              <span
                dangerouslySetInnerHTML={this.createMarkup(assumption)}
              ></span>
              {/* {AssumptionValue(productDetails)} */}
              {/* ) : (
                <span>
                  <p>
                    This quote is for the field evaluation of the equipment
                    specified. This field evaluation will cover the electrical
                    aspects of the equipment for electric shock, fire, and
                    casualty hazards only.
                  </p>
                  <p>
                    The evaluation will conclude in a comprehensive report. All
                    equipment found to be in compliance with the applicable
                    requirements will be eligible to be labeled with the UL
                    Field Evaluated Product marking.
                  </p>
                  <ul>
                    <li>
                      Included in the quoted price is a single site visit to the
                      location requested.
                    </li>
                    <li>
                      All travel costs are included in the quoted price, based
                      on use of our closest available qualified engineering
                      resources. When expedited service is requested, where the
                      closest qualified resource is not available, additional
                      costs may be incurred.
                    </li>
                    <li>
                      Progress invoicing may occur after the initial evaluation.
                    </li>
                    <li>
                      If an additional visit is necessary, additional costs will
                      be incurred and will be quoted at that time.
                    </li>
                  </ul>
                  <p>
                    Unless specifically detailed in the scope of work, this
                    quote excludes costs or fees associated with services of
                    outside contractors or test facilities and certain special
                    equipment charges.
                  </p>
                  <p>
                    Unless specifically stated in the scope above, the products
                    included in the evaluation are not covered under one of the
                    following:
                  </p>
                  <ul>
                    <li>
                      No legal action or other litigation pending regarding this
                      product.
                    </li>
                    <li>
                      The product is not associated with Homeland Security
                      equipment.
                    </li>
                    <li>
                      The product(s) to be evaluated is not intended for use in
                      a hazardous location, as defined by the NEC.
                    </li>
                    <li>
                      The product(s) to be evaluated does not operate at 600
                      volts or more.
                    </li>
                  </ul>
                </span>
              )} */}
              {/* TODO: Need to change based on GSQ */}
              {/* {productPrice.data && productPrice.data.isBusinessHours ? (
                <p>
                  All work will be completed during normal working hours (6:00
                  AM and 6:00 PM, Monday through Friday excluding holidays). If
                  your project requires work outside of normal working hours an
                  additional cost may be incurred.
                </p>
              ) : (
                <p>
                  This quote includes costs associated with work conducted
                  outside of normal working hours (Monday through Friday from
                  6:00 AM through 6:00 PM, excluding holidays).
                </p>
              )} */}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    productDetails: state.feProductDetails
  };
};

export default connect(mapStateToProps, null)(Assumption);
