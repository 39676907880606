export const MYFORM = "MYFORM";
export const CLEAR = "CLEAR";

export const AREA_REQUEST = "AREA_REQUEST";
export const AREA_SUCCESS = "AREA_SUCCESS";
export const AREA_FAILURE = "AREA_FAILURE";

export const SERVICE_AREA_REQUEST = "SERVICE_AREA_REQUEST";
export const SERVICE_AREA_SUCCESS = "SERVICE_AREA_SUCCESS";
export const SERVICE_AREA_FAILURE = "SERVICE_AREA_FAILURE";
export const SERVICE_AREA_CLEAR = "SERVICE_AREA_CLEAR";

export const SERVICE_PRODUCT_REQUEST = "SERVICE_PRODUCT_REQUEST";
export const SERVICE_PRODUCT_SUCCESS = "SERVICE_PRODUCT_SUCCESS";
export const SERVICE_PRODUCT_FAILURE = "SERVICE_PRODUCT_FAILURE";
export const SERVICE_PRODUCT_CLEAR = "SERVICE_PRODUCT_CLEAR";

export const SERVICE_SUB_PRODUCT_REQUEST = "SERVICE_SUB_PRODUCT_REQUEST";
export const SERVICE_SUB_PRODUCT_SUCCESS = "SERVICE_SUB_PRODUCT_SUCCESS";
export const SERVICE_SUB_PRODUCT_FAILURE = "SERVICE_SUB_PRODUCT_FAILURE";
export const SERVICE_SUB_PRODUCT_CLEAR = "SERVICE_SUB_PRODUCT_CLEAR";

export const PRODUCT_PRICE_REQUEST = "PRODUCT_PRICE_REQUEST";
export const PRODUCT_PRICE_SUCCESS = "PRODUCT_PRICE_SUCCESS";
export const PRODUCT_PRICE_FAILURE = "PRODUCT_PRICE_FAILURE";
export const PRODUCT_PRICE_CLEAR = "PRODUCT_PRICE_CLEAR";

export const GET_PRODUCT_DETAILS_REQUEST = "GET_PRODUCT_DETAILS_REQUEST";
export const GET_PRODUCT_DETAILS_SUCCESS = "GET_PRODUCT_DETAILS_SUCCESS";
