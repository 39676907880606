import "@babel/polyfill";
import "./polyfill";

import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./services/service-worker";
import Routes from "./services/routes";
import { Provider } from "react-redux";
import configureStore from "./store/configure-store";
import "../src/assets/css/styles.css";
import "semantic-ui-css/semantic.min.css";
import "semantic-ui-css/semantic.min.js";

const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <Routes />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
