import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import {
  Grid,
  Button,
  Form,
  Feed,
  Card,
  Message,
  Input,
  Radio,
  Divider
} from "semantic-ui-react";
import Role from "../../../enums/role";
import { Common } from "../../../common/common";
import ToastHelper from "../../../helpers/toast.helper";
import { CustomHeaderLabel2 } from "../../../components/labels/labels";
import { connect } from "react-redux";
import * as actions from "../help-me.action";
import { createValidation, validateField } from "../../../validator/validator";
import _ from "lodash";

const rules = [];

class HelpMeMessage extends Component {
  state = {
    activeIndex: 0,
    isLoading: "",
    recipientType: "1"
  };

  componentDidMount() {
    this.props.clearFormOnly();
  }

  toggle = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  handleChange = (e) => {
    let { form } = this.props.helpMe;

    const newState = validateField(rules, form, e.target.name, e.target.value);
    this.props.updateForm(newState);
  };

  handleSendMessage = () => {
    this.setState({ isLoading: "loading" });
    const { orderDetails } = this.props;
    const { form, data } = this.props.helpMe;
    const { recipientType } = this.state;
    const userId = Common.getUserId();
    let helpMeWithReferenceId = orderDetails.helpMes.find(
      (h) => h.referenceId != null
    );

    form.validationErrors = createValidation(form, rules);

    if (_.isEmpty(form.validationErrors)) {
      const param = {
        message: form.helpMeMessage,
        labelCenter: orderDetails.labelCenterName,
        userId:
          Common.getUserRole() == Role.labelCenter
            ? (orderDetails || {}).createdById
            : parseInt(userId),
        recipientType: orderDetails.labelOrderType == 1 ? "1" : recipientType
        // Customer: save userId, Admin: save userId of customer who created the order
      };

      if (form.cartOrderNo == "cart" || this.props.isCart) {
        param.cartId = orderDetails.id;
      } else {
        param.orderId = orderDetails.id;
      }

      this.props.createMessage(param).then((s) => {
        if (s && (s != null || s != "")) {
          if (s.isSuccessful) {
            // let addMe = {
            //   message: form.helpMeMessage,
            //   createdUnixTime: Date.now(),
            //   userCreated: {
            //     firstName: Common.getFirstName(),
            //     lastName: Common.getLastName()
            //   },
            //   referenceId: helpMeWithReferenceId
            //     ? helpMeWithReferenceId.referenceId
            //     : null
            // };

            // if (form.cartOrderNo == "cart" || this.props.isCart) {
            //   data.data.helpMes.unshift(addMe);
            // } else {
            //   this.props.data.unshift(addMe);
            // }

            // this.props.doAddMessage(
            //   form.cartOrderNo == "cart" ? data.data : data
            // );

            this.props.getLabelOrder();

            setTimeout(() => {
              ToastHelper.success({
                title: "Success",
                description: s.message
              });
            }, 100);

            this.setState({ isLoading: "" });
            this.props.clearFormOnly();
            return;
          }
        }

        setTimeout(() => {
          ToastHelper.error({
            title: "Error",
            // description: "Something went wrong"
            description: s.message
          });
        }, 100);
        this.setState({ isLoading: "" });
      });
    }
  };

  handleRadioChange = (e, { value }) => {
    this.setState({ recipientType: value });
  };

  render() {
    const { isLoading, recipientType } = this.state;
    const { data, orderDetails } = this.props;
    const { form } = this.props.helpMe;
    const translations = Common.getTranslations() || {};
    let printerUserId = 0;
    let customerUserId = 0;

    if (data.length > 0) {
      data.forEach((item) => {
        if (item.helpMe.length > 0) {
          let printerId = item.helpMe.find((h) => h.roleId == 12)?.userId;
          let customerId = item.helpMe.find(
            (h) => h.roleId == 1 || h.roleId == 2
          )?.userId;

          if (printerId) printerUserId = printerId;
          if (customerId) customerUserId = customerId;
        }
      });
    }
    return (
      <Fragment>
        <Grid style={{ padding: "0px 25px 25px 25px" }}>
          <Grid.Row>
            <Grid.Column>
              <CustomHeaderLabel2 message={translations["LexHelpMe"]} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Grid>
                {data.length > 0 ? (
                  <Grid.Row>
                    <Grid.Column>
                      <Card fluid>
                        <Card.Content>
                          <Feed>
                            {(Common.getUserRole() == Role.labelCenter ||
                              Common.getUserRole() == Role.labelManager) &&
                            orderDetails.labelOrderType == 2 ? (
                              <Fragment>
                                <fieldset
                                  style={{
                                    paddingLeft: "50px",
                                    paddingRight: "60px"
                                  }}
                                >
                                  <legend>
                                    <b>Customer</b>
                                  </legend>

                                  {data.map((hm, idx) => (
                                    <Fragment key={idx}>
                                      {hm &&
                                        hm.helpMe &&
                                        hm.helpMe.length > 0 && (
                                          <Grid>
                                            <Grid.Row>
                                              <Grid.Column width={3}>
                                                {hm.dateCreatedString}
                                              </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                              <Grid.Column>
                                                <table
                                                  className="details-table"
                                                  style={{ width: "50%" }}
                                                >
                                                  {hm &&
                                                    hm.helpMe
                                                      .filter(
                                                        (h) =>
                                                          (h.userId ==
                                                            customerUserId ||
                                                            h.createdById ==
                                                              customerUserId) &&
                                                          (h.roleId ==
                                                            Role.applicant ||
                                                            h.roleId ==
                                                              Role.manufacturer)
                                                      )
                                                      .map((msg) => (
                                                        <tr>
                                                          <td
                                                            style={{
                                                              width: "20%"
                                                            }}
                                                          >
                                                            <b>
                                                              {msg.displayName}
                                                            </b>
                                                          </td>
                                                          <td>{msg.message}</td>
                                                        </tr>
                                                      ))}
                                                </table>
                                              </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                              <Grid.Column>
                                                <div
                                                  style={{
                                                    border: "1px solid #DCDEE1"
                                                  }}
                                                ></div>
                                              </Grid.Column>
                                            </Grid.Row>
                                          </Grid>
                                        )}
                                    </Fragment>
                                  ))}
                                </fieldset>
                                <fieldset
                                  style={{
                                    paddingLeft: "50px",
                                    paddingRight: "60px"
                                  }}
                                >
                                  <legend>
                                    <b>Printer</b>
                                  </legend>

                                  {data.map((hm, idx) => (
                                    <Fragment key={idx}>
                                      {hm &&
                                        hm.helpMe &&
                                        hm.helpMe.length > 0 && (
                                          <Grid>
                                            <Grid.Row>
                                              <Grid.Column width={3}>
                                                {hm.dateCreatedString}
                                              </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                              <Grid.Column>
                                                <table
                                                  className="details-table"
                                                  style={{ width: "50%" }}
                                                >
                                                  {hm &&
                                                    hm.helpMe
                                                      .filter(
                                                        (h) =>
                                                          (h.userId ==
                                                            printerUserId ||
                                                            h.createdById ==
                                                              printerUserId) &&
                                                          h.roleId ==
                                                            Role.printer
                                                      )
                                                      .map((msg) => (
                                                        <tr>
                                                          <td
                                                            style={{
                                                              width: "20%"
                                                            }}
                                                          >
                                                            <b>
                                                              {msg.displayName}
                                                            </b>
                                                          </td>
                                                          <td>{msg.message}</td>
                                                        </tr>
                                                      ))}
                                                </table>
                                              </Grid.Column>
                                            </Grid.Row>
                                            <Grid.Row>
                                              <Grid.Column>
                                                <div
                                                  style={{
                                                    border: "1px solid #DCDEE1"
                                                  }}
                                                ></div>
                                              </Grid.Column>
                                            </Grid.Row>
                                          </Grid>
                                        )}
                                    </Fragment>
                                  ))}
                                </fieldset>
                              </Fragment>
                            ) : (
                              data.map((hm, idx) => (
                                <Fragment key={idx}>
                                  {hm && hm.helpMe && hm.helpMe.length > 0 && (
                                    <Grid>
                                      <Grid.Row>
                                        <Grid.Column width={3}>
                                          {hm.dateCreatedString}
                                        </Grid.Column>
                                      </Grid.Row>
                                      <Grid.Row>
                                        <Grid.Column>
                                          <table
                                            className="details-table"
                                            style={{ width: "50%" }}
                                          >
                                            {hm &&
                                              hm.helpMe.map((msg) => (
                                                <tr>
                                                  <td style={{ width: "20%" }}>
                                                    <b>{msg.displayName}</b>
                                                  </td>
                                                  <td>{msg.message}</td>
                                                </tr>
                                              ))}
                                          </table>
                                        </Grid.Column>
                                      </Grid.Row>
                                      <Grid.Row>
                                        <Grid.Column>
                                          <div
                                            style={{
                                              border: "1px solid #DCDEE1"
                                            }}
                                          ></div>
                                        </Grid.Column>
                                      </Grid.Row>
                                    </Grid>
                                  )}
                                </Fragment>
                              ))
                            )}

                            <Divider hidden />
                            <Grid>
                              <Grid.Row>
                                <Grid.Column
                                  width={
                                    (Common.getUserRole() == Role.labelCenter ||
                                      Common.getUserRole() ==
                                        Role.labelManager) &&
                                    orderDetails.labelOrderType == 2
                                      ? 4
                                      : 2
                                  }
                                >
                                  <Form>
                                    <Form.Group widths="equal">
                                      <Form.Field>
                                        <Button
                                          secondary
                                          basic
                                          size="small"
                                          floated="left"
                                          onClick={this.handleSendMessage}
                                          className={isLoading}
                                          disabled={
                                            form.helpMeMessage ? false : true
                                          }
                                        >
                                          {Common.getUserRole() !=
                                          Role.labelCenter
                                            ? translations["LexSendMessage"]
                                            : "Send Comment"}
                                        </Button>
                                      </Form.Field>
                                      {(Common.getUserRole() ==
                                        Role.labelCenter ||
                                        Common.getUserRole() ==
                                          Role.labelManager) &&
                                        orderDetails.labelOrderType == 2 && (
                                          <Form.Field>
                                            <Radio
                                              label="Send to Customer"
                                              name="radioGroup"
                                              value="1"
                                              checked={recipientType === "1"}
                                              onChange={this.handleRadioChange}
                                            />
                                            <Divider hidden fitted />
                                            <Form.Field>
                                              <Radio
                                                label="Send to Printer"
                                                name="radioGroup"
                                                value="2"
                                                checked={recipientType === "2"}
                                                onChange={
                                                  this.handleRadioChange
                                                }
                                              />
                                            </Form.Field>
                                          </Form.Field>
                                        )}
                                    </Form.Group>
                                  </Form>
                                </Grid.Column>
                                <Grid.Column width={11}>
                                  <Form.Field
                                    control={Input}
                                    name="helpMeMessage"
                                    onChange={this.handleChange.bind(this)}
                                    value={form && form.helpMeMessage}
                                    maxLength="500"
                                    autoFocus
                                    disabled={
                                      Common.getUserRole() ==
                                      Role.fieldEngineerRO
                                    }
                                  />
                                </Grid.Column>
                              </Grid.Row>
                            </Grid>
                          </Feed>
                        </Card.Content>
                      </Card>
                    </Grid.Column>
                  </Grid.Row>
                ) : (
                  <Grid.Row>
                    <Grid.Column>
                      <Fragment>
                        <Message
                          compact
                          info
                          icon="sticky note outline"
                          header={
                            form && form.cartOrderNo == "cart"
                              ? "Cart doesn't have comment"
                              : translations["LexOrderdoesnothavecomment"]
                          }
                        />
                        <Grid>
                          <Grid.Row>
                            <Grid.Column
                              width={
                                (Common.getUserRole() == Role.labelCenter ||
                                  Common.getUserRole() == Role.labelManager) &&
                                orderDetails.labelOrderType == 2
                                  ? 4
                                  : 2
                              }
                            >
                              <Form>
                                <Form.Group widths="equal">
                                  <Form.Field>
                                    <Button
                                      secondary
                                      basic
                                      size="small"
                                      floated="left"
                                      onClick={this.handleSendMessage}
                                      className={isLoading}
                                      disabled={
                                        form.helpMeMessage ? false : true
                                      }
                                    >
                                      {Common.getUserRole() != Role.labelCenter
                                        ? translations["LexSendMessage"]
                                        : "Send Comment"}
                                    </Button>
                                  </Form.Field>
                                  {(Common.getUserRole() == Role.labelCenter ||
                                    Common.getUserRole() ==
                                      Role.labelManager) &&
                                    orderDetails.labelOrderType == 2 && (
                                      <Form.Field>
                                        <Radio
                                          label="Send to Customer"
                                          name="radioGroup"
                                          value="1"
                                          checked={recipientType === "1"}
                                          onChange={this.handleRadioChange}
                                        />
                                        <Divider hidden fitted />
                                        <Form.Field>
                                          <Radio
                                            label="Send to Printer"
                                            name="radioGroup"
                                            value="2"
                                            checked={recipientType === "2"}
                                            onChange={this.handleRadioChange}
                                          />
                                        </Form.Field>
                                      </Form.Field>
                                    )}
                                </Form.Group>
                              </Form>
                            </Grid.Column>
                            <Grid.Column width={11}>
                              <Form.Field
                                control={Input}
                                name="helpMeMessage"
                                onChange={this.handleChange.bind(this)}
                                value={form && form.helpMeMessage}
                                maxLength="500"
                                autoFocus
                                disabled={
                                  Common.getUserRole() == Role.fieldEngineerRO
                                }
                              />
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </Fragment>
                    </Grid.Column>
                  </Grid.Row>
                )}
              </Grid>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Fragment>
    );
  }
}

HelpMeMessage.propTypes = {
  data: PropTypes.array.isRequired,
  orderDetails: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  return {
    loading: state.helpMe.loading,
    isSuccess: state.helpMe.isSuccess,
    helpMe: state.helpMe
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createMessage: (data) => dispatch(actions.createMessage(data)),
    updateForm: (result) => dispatch(actions.updateForm(result)),
    clearForm: (result) => dispatch(actions.clearForm(result)),
    doAddMessage: (params) => dispatch(actions.doAddMessage(params)),
    clearFormOnly: () => dispatch(actions.clearFormOnly())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(HelpMeMessage));
