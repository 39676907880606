export const GET_LABELS_REQUEST = 'GET_LABELS_REQUEST';
export const GET_LABELS_SUCCESS = 'GET_LABELS_SUCCESS';
export const GET_LABELS_FAILURE = 'GET_LABELS_FAILURE';

export const UPDATE_LABELS_REQUEST = 'UPDATE_LABELS_REQUEST';
export const UPDATE_LABELS_SUCCESS = 'UPDATE_LABELS_SUCCESS';
export const UPDATE_LABELS_FAILURE = 'UPDATE_LABELS_FAILURE';

export const UPDATE_LABELS_PRICE_REQUEST = 'UPDATE_LABELS_PRICE_REQUEST';
export const UPDATE_LABELS_PRICE_SUCCESS = 'UPDATE_LABELS_PRICE_SUCCESS';
export const UPDATE_LABELS_PRICE_FAILURE = 'UPDATE_LABELS_PRICE_FAILURE';

export const VIEW_ACTIVE_LABELS_REQUEST = 'VIEW_ACTIVE_LABELS_REQUEST';
export const VIEW_ACTIVE_LABELS = 'VIEW_ACTIVE_LABELS';
export const LABELS_CHANGE_PAGE = 'LABELS_CHANGE_PAGE';

export const APPLY_FILTER = 'APPLY_FILTER';
export const CHANGE_PAGE = 'CHANGE_PAGE';
export const PRICE_MNGMNT_FORM = 'PRICE_MNGMNT_FORM';
export const UPDATE_CHANGE_RELATIONSHIP = 'UPDATE_CHANGE_RELATIONSHIP';
export const PRICE_MNGMNT_CLEAR = 'PRICE_MNGMNT_CLEAR';
export const UPDATE_CHANGE_RELATIONSHIP_CLEAR =
  'UPDATE_CHANGE_RELATIONSHIP_CLEAR';

export const LABEL_MNGMNT_DATA = 'LABEL_MNGMNT_DATA';
export const PRICE_MNGMNT_DATA = 'PRICE_MNGMNT_DATA';
export const COMBINATION_LABEL_MNGMNT_DATA = 'COMBINATION_LABEL_MNGMNT_DATA';

export const GET_COMBINATION_LABELS_REQUEST = 'GET_COMBINATION_LABELS_REQUEST';
export const GET_COMBINATION_LABELS_SUCCESS = 'GET_COMBINATION_LABELS_SUCCESS';
export const GET_COMBINATION_LABELS_FAILURE = 'GET_COMBINATION_LABELS_FAILURE';

export const UPDATE_COMBINATIONS_LABELS_REQUEST =
  'UPDATE_COMBINATIONS_LABELS_REQUEST';
export const UPDATE_COMBINATIONS_LABELS_SUCCESS =
  'UPDATE_COMBINATIONS_LABELS_SUCCESS';
export const UPDATE_COMBINATIONS_LABELS_FAILURE =
  'UPDATE_COMBINATIONS_LABELS_FAILURE';

export const UPDATE_CUSTOMER_COMBINATIONS_LABEL_REQUEST =
  'UPDATE_CUSTOMER_COMBINATIONS_LABEL_REQUEST';
export const UPDATE_CUSTOMER_COMBINATIONS_LABEL_SUCCESS =
  'UPDATE_CUSTOMER_COMBINATIONS_LABEL_SUCCESS';
export const UPDATE_CUSTOMER_COMBINATIONS_LABEL_FAILURE =
  'UPDATE_CUSTOMER_COMBINATIONS_LABEL_FAILURE';

export const COURIER_MANAGEMENT_DATA = 'COURIER_MANAGEMENT_DATA';
export const COURIER_FOR_UPDATE_DATA = 'COURIER_FOR_UPDATE_DATA';
export const UPDATE_COURIER_REQUEST = 'UPDATE_COURIER_REQUEST';
export const UPDATE_COURIER_SUCCESS = 'UPDATE_COURIER_SUCCESS';
export const UPDATE_COURIER_FAILURE = 'UPDATE_COURIER_FAILURE';

export const GET_COURIER_REQUEST = 'GET_COURIER_REQUEST';
export const GET_COURIER_SUCCESS = 'GET_COURIER_SUCCESS';
export const GET_COURIER_FAILURE = 'GET_COURIER_FAILURE';

export const GET_COMBINATION_LABELS_FILTER = 'GET_COMBINATION_LABELS_FILTER';
export const CLEAR_COMBINATION_LABELS_FILTER =
  'CLEAR_COMBINATION_LABELS_FILTER';

export const NON_DENOMINATIONAL_LABELS_REQUEST =
  'NON_DENOMINATIONAL_LABELS_REQUEST';
export const NON_DENOMINATIONAL_LABELS_SUCCESS =
  'NON_DENOMINATIONAL_LABELS_SUCCESS';
export const NON_DENOMINATIONAL_LABELS_FAILURE =
  'NON_DENOMINATIONAL_LABELS_FAILURE';
export const LOAD_NON_DENOMINATIONAL_ACCOUNTS =
  'LOAD_NON_DENOMINATIONAL_ACCOUNTS';

export const CREATE_NON_DENOMINATIONAL_ACCOUNTS_REQUEST =
  'CREATE_NON_DENOMINATIONAL_ACCOUNTS_REQUEST';
export const CREATE_NON_DENOMINATIONAL_ACCOUNTS_SUCCESS =
  'CREATE_NON_DENOMINATIONAL_ACCOUNTS_SUCCESS';
export const CREATE_NON_DENOMINATIONAL_ACCOUNTS_FAILURE =
  'CREATE_NON_DENOMINATIONAL_ACCOUNTS_FAILURE';

export const UPDATE_SINGLE_RELATIONSHIP_CLEAR =
  'UPDATE_SINGLE_RELATIONSHIP_CLEAR';
export const UPDATE_SINGLE_RELATIONSHIP = 'UPDATE_SINGLE_RELATIONSHIP';

export const ADD_SINGLE_CUSTOMER_COMBINATIONS_LABEL_REQUEST =
  'ADD_SINGLE_CUSTOMER_COMBINATIONS_LABEL_REQUEST';
export const ADD_SINGLE_CUSTOMER_COMBINATIONS_LABEL_SUCCESS =
  'ADD_SINGLE_CUSTOMER_COMBINATIONS_LABEL_SUCCESS';
export const ADD_SINGLE_CUSTOMER_COMBINATIONS_LABEL_FAILURE =
  'ADD_SINGLE_CUSTOMER_COMBINATIONS_LABEL_FAILURE';

export const REFRESH_CSV_DATA = 'REFRESH_CSV_DATA';

export const LABEL_SUPPLIER_EMAIL_TEMPLATES_REQUEST =
  'LABEL_SUPPLIER_EMAIL_TEMPLATES_REQUEST';
export const LABEL_SUPPLIER_EMAIL_TEMPLATES_SUCCESS =
  'LABEL_SUPPLIER_EMAIL_TEMPLATES_SUCCESS';
export const LABEL_SUPPLIER_EMAIL_TEMPLATES_FAILED =
  'LABEL_SUPPLIER_EMAIL_TEMPLATES_FAILED';

export const LABEL_SUPPLIER_EMAIL_TEMPLATES_FILTER =
  'LABEL_SUPPLIER_EMAIL_TEMPLATES_FILTER';

export const CLEAR_LABEL_SUPPLIER_EMAIL_FILTER =
  'CLEAR_LABEL_SUPPLIER_EMAIL_FILTER';

export const LABEL_SUPPLIER_EMAIL_TEMPLATE_MNGMT_DATA =
  'LABEL_SUPPLIER_EMAIL_TEMPLATE_MNGMT_DATA';

export const LABEL_SUPPLIER_EMAIL_TEMPLATE_UPLOAD_REQUEST =
  'LABEL_SUPPLIER_EMAIL_TEMPLATE_UPLOAD_REQUEST';

export const LABEL_SUPPLIER_EMAIL_TEMPLATE_UPLOAD_SUCCESS =
  'LABEL_SUPPLIER_EMAIL_TEMPLATE_UPLOAD_SUCCESS';

export const LABEL_SUPPLIER_EMAIL_TEMPLATE_UPLOAD_FAILED =
  'LABEL_SUPPLIER_EMAIL_TEMPLATE_UPLOAD_FAILED';

export const COMBINATION_ALLOWED_CCN_TEMPLATES_REQUEST =
  'COMBINATION_ALLOWED_CCN_TEMPLATES_REQUEST';
export const COMBINATION_ALLOWED_CCN_TEMPLATES_SUCCESS =
  'COMBINATION_ALLOWED_CCN_TEMPLATES_SUCCESS';
export const COMBINATION_ALLOWED_CCN_TEMPLATES_FAILED =
  'COMBINATION_ALLOWED_CCN_TEMPLATES_FAILED';

export const COMBINATION_ALLOWED_CCN_TEMPLATES_FILTER =
  'COMBINATION_ALLOWED_CCN_TEMPLATES_FILTER';

export const CLEAR_COMBINATION_ALLOWED_CCN_FILTER =
  'CLEAR_COMBINATION_ALLOWED_CCN_FILTER';

export const COMBINATION_ALLOWED_CCN_TEMPLATE_MNGMT_DATA =
  'COMBINATION_ALLOWED_CCN_TEMPLATE_MNGMT_DATA';

export const COMBINATION_ALLOWED_CCN_TEMPLATE_UPLOAD_REQUEST =
  'COMBINATION_ALLOWED_CCN_TEMPLATE_UPLOAD_REQUEST';

export const COMBINATION_ALLOWED_CCN_TEMPLATE_UPLOAD_SUCCESS =
  'COMBINATION_ALLOWED_CCN_TEMPLATE_UPLOAD_SUCCESS';

export const COMBINATION_ALLOWED_CCN_TEMPLATE_UPLOAD_FAILED =
  'COMBINATION_ALLOWED_CCN_TEMPLATE_UPLOAD_FAILED';

export const SELECT_ITEM_NUMBER = 'SELECT_ITEM_NUMBER';
export const SELECT_ALL_ITEMS = 'SELECT_ALL_ITEMS';

export const REMOVE_COMBINATION_LABELS_REQUEST =
  'REMOVE_COMBINATION_LABELS_REQUEST';
export const REMOVE_COMBINATION_LABELS_SUCCESS =
  'REMOVE_COMBINATION_LABELS_SUCCESS';
export const REMOVE_COMBINATION_LABELS_FAILURE =
  'REMOVE_COMBINATION_LABELS_FAILURE';

export const REMOVE_LABELS_RESET = 'REMOVE_LABELS_RESET';

export const CREATE_CURRENCY_HANDLING_FEE_REQUEST =
  'CREATE_CURRENCY_HANDLING_FEE_REQUEST';
export const CREATE_CURRENCY_HANDLING_FEE_SUCCESS =
  'CREATE_CURRENCY_HANDLING_FEE_SUCCESS';
export const CREATE_CURRENCY_HANDLING_FEE_FAILURE =
  'CREATE_CURRENCY_HANDLING_FEE_FAILURE';

export const GET_CURRENCY_HANDLING_FEE_REQUEST =
  'GET_CURRENCY_HANDLING_FEE_REQUEST';
export const GET_CURRENCY_HANDLING_FEE_SUCCESS =
  'GET_CURRENCY_HANDLING_FEE_SUCCESS';
export const GET_CURRENCY_HANDLING_FEE_FAILURE =
  'GET_CURRENCY_HANDLING_FEE_FAILURE';

export const LOAD_CURRENCY_HANDLING_FEE = 'LOAD_CURRENCY_HANDLING_FEE';

export const GET_CONFIGURATION_SETTINGS_REQUEST =
  'GET_CONFIGURATION_SETTINGS_REQUEST';
export const GET_CONFIGURATION_SETTINGS_SUCCESS =
  'GET_CONFIGURATION_SETTINGS_SUCCESS';
export const GET_CONFIGURATION_SETTINGS_FAILURE =
  'GET_CONFIGURATION_SETTINGS_FAILURE';

export const UPDATE_CONFIGURATION_SETTINGS_REQUEST =
  'UPDATE_CONFIGURATION_SETTINGS_REQUEST';
export const UPDATE_CONFIGURATION_SETTINGS_SUCCESS =
  'UPDATE_CONFIGURATION_SETTINGS_SUCCESS';
export const UPDATE_CONFIGURATION_SETTINGS_FAILURE =
  'UPDATE_CONFIGURATION_SETTINGS_FAILURE';

export const CREATE_LABEL_SUPPLIER_EMAIL_REQUEST =
  'CREATE_LABEL_SUPPLIER_EMAIL_REQUEST';
export const CREATE_LABEL_SUPPLIER_EMAIL_SUCCESS =
  'CREATE_LABEL_SUPPLIER_EMAIL_SUCCESS';
export const CREATE_LABEL_SUPPLIER_EMAIL_FAILURE =
  'CREATE_LABEL_SUPPLIER_EMAIL_FAILURE';

export const CLEAR_LABEL_SUPPLIER_EMAIL_FORM =
  'CLEAR_LABEL_SUPPLIER_EMAIL_FORM';
export const UPDATE_LABEL_SUPPLIER_EMAIL_FORM =
  'UPDATE_LABEL_SUPPLIER_EMAIL_FORM';

export const UPDATE_LABEL_ITEM_REQUEST = 'UPDATE_LABEL_ITEM_REQUEST';
export const UPDATE_LABEL_ITEM_SUCCESS = 'UPDATE_LABEL_ITEM_SUCCESS';
export const UPDATE_LABEL_ITEM_FAILURE = 'UPDATE_LABEL_ITEM_FAILURE';

export const GET_LABEL_ITEMS_REQUEST = 'GET_LABEL_ITEMS_REQUEST';
export const GET_LABEL_ITEMS_SUCCESS = 'GET_LABEL_ITEMS_SUCCESS';
export const GET_LABEL_ITEMS_FAILURE = 'GET_LABEL_ITEMS_FAILURE';

export const STANDARD_LABEL_TEMPLATES_FILTER =
  'STANDARD_LABEL_TEMPLATES_FILTER';

export const CLEAR_STANDARD_LABEL_TEMPLATES_FILTER =
  'CLEAR_STANDARD_LABEL_TEMPLATES_FILTER';
