export const ORDER_HISTORY_REQUEST = "ORDER_HISTORY_REQUEST";
export const ORDER_HISTORY_FAILURE = "ORDER_HISTORY_FAILURE";
export const ORDER_HISTORY_SUCCESS = "ORDER_HISTORY_SUCCESS";
export const ORDER_SELECT_ALL = "ORDER_SELECT_ALL";
export const ORDER_SELECT = "ORDER_SELECT";
export const ORDER_CHANGE_PAGE = "ORDER_CHANGE_PAGE";
export const ORDER_DETAILS_REQUEST = "ORDER_DETAILS_REQUEST";
export const ORDER_DETAILS_FAILURE = "ORDER_DETAILS_FAILURE";
export const ORDER_DETAILS_SUCCESS = "ORDER_DETAILS_SUCCESS";
export const ORDER_LIST_EXPORT = "ORDER_LIST_EXPORT";
export const CLEAR_EXPORT_SELECTION = "CLEAR_EXPORT_SELECTION";

export const GET_ORDER_REQUEST = "GET_ORDER_REQUEST";
export const GET_ORDER_FAILURE = "GET_ORDER_FAILURE";
export const GET_ORDER_SUCCESS = "GET_ORDER_SUCCESS";

export const GET_ORDER_BY_USER_FILTER_REQUEST =
  "GET_ORDER_BY_USER_FILTER_REQUEST";
export const GET_ORDER_BY_USER_FILTER_FAILURE =
  "GET_ORDER_BY_USER_FILTER_FAILURE";
export const GET_ORDER_BY_USER_FILTER_SUCCESS =
  "GET_ORDER_BY_USER_FILTER_SUCCESS";

export const SET_USER_FILTER_REQUEST = "SET_USER_FILTER_REQUEST";
export const SET_USER_FILTER_FAILURE = "SET_USER_FILTER_FAILURE";
export const SET_USER_FILTER_SUCCESS = "SET_USER_FILTER_SUCCESS";

export const GET_USER_FILTER_REQUEST = "GET_USER_FILTER_REQUEST";
export const GET_USER_FILTER_FAILURE = "GET_USER_FILTER_FAILURE";
export const GET_USER_FILTER_SUCCESS = "GET_USER_FILTER_SUCCESS";

export const GET_FILE_NUMBERS_REQUEST = "GET_FILE_NUMBERS_REQUEST";
export const GET_FILE_NUMBERS_FAILURE = "GET_FILE_NUMBERS_FAILURE";
export const GET_FILE_NUMBERS_SUCCESS = "GET_FILE_NUMBERS_SUCCESS";

export const GET_APPLICANTS_REQUEST = "GET_APPLICANTS_REQUEST";
export const GET_APPLICANTS_FAILURE = "GET_APPLICANTS_FAILURE";
export const GET_APPLICANTS_SUCCESS = "GET_APPLICANTS_SUCCESS";

export const GET_MANUFACTURERS_REQUEST = "GET_MANUFACTURERS_REQUEST";
export const GET_MANUFACTURERS_FAILURE = "GET_MANUFACTURERS_FAILURE";
export const GET_MANUFACTURERS_SUCCESS = "GET_MANUFACTURERS_SUCCESS";

export const GET_ORACLE_NUMBERS_REQUEST = "GET_ORACLE_NUMBERS_REQUEST";
export const GET_ORACLE_NUMBERS_FAILURE = "GET_ORACLE_NUMBERS_FAILURE";
export const GET_ORACLE_NUMBERS_SUCCESS = "GET_ORACLE_NUMBERS_SUCCESS";

export const GET_REQUEST_NUMBERS_REQUEST = "GET_REQUEST_NUMBERS_REQUEST";
export const GET_REQUEST_NUMBERS_FAILURE = "GET_REQUEST_NUMBERS_FAILURE";
export const GET_REQUEST_NUMBERS_SUCCESS = "GET_REQUEST_NUMBERS_SUCCESS";

export const GET_LABEL_CENTERS_REQUEST = "GET_LABEL_CENTERS_REQUEST";
export const GET_LABEL_CENTERS_FAILURE = "GET_LABEL_CENTERS_FAILURE";
export const GET_LABEL_CENTERS_SUCCESS = "GET_LABEL_CENTERS_SUCCESS";

export const GET_LABEL_APPLICANT_ACCOUNTNO_REQUEST =
  "GET_LABEL_APPLICANT_ACCOUNTNO_REQUEST";
export const GET_LABEL_APPLICANT_ACCOUNTNO_FAILURE =
  "GET_LABEL_APPLICANT_ACCOUNTNO_FAILURE";
export const GET_LABEL_APPLICANT_ACCOUNTNO_SUCCESS =
  "GET_LABEL_APPLICANT_ACCOUNTNO_SUCCESS";

export const GET_LABEL_MANUFACTURER_ACCOUNTNO_REQUEST =
  "GET_LABEL_MANUFACTURER_ACCOUNTNO_REQUEST";
export const GET_LABEL_MANUFACTURER_ACCOUNTNO_FAILURE =
  "GET_LABEL_MANUFACTURER_ACCOUNTNO_FAILURE";
export const GET_LABEL_MANUFACTURER_ACCOUNTNO_SUCCESS =
  "GET_LABEL_MANUFACTURER_ACCOUNTNO_SUCCESS";

export const FILTEROPTION_RESET = "FILTEROPTION_RESET";

export const ORDER_HISTORY_UPDATE = "ORDER_HISTORY_UPDATE";

export const ORDER_HISTORY_FORM = "ORDER_HISTORY_FORM";
export const ORDER_HISTORY_CLEAR = "ORDER_HISTORY_CLEAR";

export const UPLOAD_LABEL_ORDER_ATTACHMENT_REQUEST =
  "UPLOAD_LABEL_ORDER_ATTACHMENT_REQUEST";
export const UPLOAD_LABEL_ORDER_ATTACHMENT_FAILURE =
  "UPLOAD_LABEL_ORDER_ATTACHMENT_FAILURE";
export const UPLOAD_LABEL_ORDER_ATTACHMENT_SUCCESS =
  "UPLOAD_LABEL_ORDER_ATTACHMENT_SUCCESS";

export const DELETE_LABEL_ORDER_ATTACHMENT_REQUEST =
  "DELETE_LABEL_ORDER_ATTACHMENT_REQUEST";
export const DELETE_LABEL_ORDER_ATTACHMENT_FAILURE =
  "DELETE_LABEL_ORDER_ATTACHMENT_FAILURE";
export const DELETE_LABEL_ORDER_ATTACHMENT_SUCCESS =
  "DELETE_LABEL_ORDER_ATTACHMENT_SUCCESS";

export const GET_LABEL_ORDER_ATTACHMENT_REQUEST =
  "GET_LABEL_ORDER_ATTACHMENT_REQUEST";
export const GET_LABEL_ORDER_ATTACHMENT_FAILURE =
  "GET_LABEL_ORDER_ATTACHMENT_FAILURE";
export const GET_LABEL_ORDER_ATTACHMENT_SUCCESS =
  "GET_LABEL_ORDER_ATTACHMENT_SUCCESS";

export const SEND_ATTACHMENT_MESSAGE_REQUEST =
  "SEND_ATTACHMENT_MESSAGE_REQUEST";
export const SEND_ATTACHMENT_MESSAGE_FAILURE =
  "SEND_ATTACHMENT_MESSAGE_FAILURE";
export const SEND_ATTACHMENT_MESSAGE_SUCCESS =
  "SEND_ATTACHMENT_MESSAGE_SUCCESS";

export const GET_CUSTOMER_PO_NUMBER_REQUEST = "GET_CUSTOMER_PO_NUMBER_REQUEST";
export const GET_CUSTOMER_PO_NUMBER_FAILURE = "GET_CUSTOMER_PO_NUMBER_FAILURE";
export const GET_CUSTOMER_PO_NUMBER_SUCCESS = "GET_CUSTOMER_PO_NUMBER_SUCCESS";

export const GET_CUSTOMER_CONTACT_NAME_REQUEST =
  "GET_CUSTOMER_CONTACT_NAME_REQUEST";
export const GET_CUSTOMER_CONTACT_NAME_FAILURE =
  "GET_CUSTOMER_CONTACT_NAME_FAILURE";
export const GET_CUSTOMER_CONTACT_NAME_SUCCESS =
  "GET_CUSTOMER_CONTACT_NAME_SUCCESS";

export const GET_APPLICANT_ADDRESS_REQUEST = "GET_APPLICANT_ADDRESS_REQUEST";
export const GET_APPLICANT_ADDRESS_FAILURE = "GET_APPLICANT_ADDRESS_FAILURE";
export const GET_APPLICANT_ADDRESS_SUCCESS = "GET_APPLICANT_ADDRESS_SUCCESS";

export const GET_MANUFACTURER_ADDRESS_REQUEST =
  "GET_MANUFACTURER_ADDRESS_REQUEST";
export const GET_MANUFACTURER_ADDRESS_FAILURE =
  "GET_MANUFACTURER_ADDRESS_FAILURE";
export const GET_MANUFACTURER_ADDRESS_SUCCESS =
  "GET_MANUFACTURER_ADDRESS_SUCCESS";

export const GET_MANUFACTURER_PSN_REQUEST = "GET_MANUFACTURER_PSN_REQUEST";
export const GET_MANUFACTURER_PSN_FAILURE = "GET_MANUFACTURER_PSN_FAILURE";
export const GET_MANUFACTURER_PSN_SUCCESS = "GET_MANUFACTURER_PSN_SUCCESS";

export const UPDATE_BUBBLE_STATUS_REQUEST = "UPDATE_BUBBLE_STATUS_REQUEST";
export const UPDATE_BUBBLE_STATUS_FAILURE = "UPDATE_BUBBLE_STATUS_FAILURE";
export const UPDATE_BUBBLE_STATUS_SUCCESS = "UPDATE_BUBBLE_STATUS_SUCCESS";

export const CREATE_REPORT_REQUEST = "CREATE_REPORT_REQUEST";
export const CREATE_REPORT_REQUEST_FAILURE = "CREATE_REPORT_REQUEST_FAILURE";
export const CREATE_REPORT_REQUEST_SUCCESS = "CREATE_REPORT_REQUEST_SUCCESS";
export const CLOSE_REPORT_REQUEST_DIALOG = "CLOSE_REPORT_REQUEST_DIALOG";
export const CLOSE_REPORT_LINK_DIALOG = "CLOSE_REPORT_LINK_DIALOG";

export const GET_REPORT_LINK_REQUEST = "GET_REPORT_LINK_REQUEST";
export const GET_REPORT_LINK_REQUEST_FAILURE =
  "GET_REPORT_LINK_REQUEST_FAILURE";
export const GET_REPORT_LINK_REQUEST_SUCCESS =
  "GET_REPORT_LINK_REQUEST_SUCCESS";

export const CLOSE_CANNOT_COPY_DALOG = "CLOSE_CANNOT_COPY_DALOG";
export const CLOSE_COPY_NOT_COMPLIED_DIALOG = "CLOSE_COPY_NOT_COMPLIED_DIALOG";
export const OPEN_CANNOT_COPY_DIALOG = "OPEN_CANNOT_COPY_DIALOG";
export const OPEN_COPY_NOT_COMPLIED_DIALOG = "OPEN_COPY_NOT_COMPLIED_DIALOG";
export const CLOSE_EXISTING_ITEM_IN_CART_DIALOG =
  "CLOSE_EXISTING_ITEM_IN_CART_DIALOG";
export const OPEN_EXISTING_ITEM_IN_CART_DIALOG =
  "OPEN_EXISTING_ITEM_IN_CART_DIALOG";
export const GET_CART_AND_ORDER_DETAILS = "GET_CART_AND_ORDER_DETAILS";
export const GET_CART_AND_ORDER_DETAILS_SUCCESS =
  "GET_CART_AND_ORDER_DETAILS_SUCCESS";
export const GET_CART_AND_ORDER_DETAILS_FAILURE =
  "GET_CART_AND_ORDER_DETAILS_FAILURE";

export const MANUAL_REPUSH = "MANUAL_REPUSH";
export const MANUAL_REPUSH_SUCCESS = "MANUAL_REPUSH_SUCCESS";
export const MANUAL_REPUSH_FAILURE = "MANUAL_REPUSH_FAILURE";

export const OPEN_AUTO_SWITCH_PSN_ROLE_DIALOG =
  "OPEN_AUTO_SWITCH_PSN_ROLE_DIALOG";
export const CLOSE_AUTO_SWITCH_PSN_ROLE_DIALOG =
  "CLOSE_AUTO_SWITCH_PSN_ROLE_DIALOG";

export const CLEAR_FILTER_TRIGGER = "CLEAR_FILTER_TRIGGER";
export const CLEAR_FILTER_TRIGGER_RESET = "CLEAR_FILTER_TRIGGER_RESET";

export const DISABLE_CLEAR_FILTER = "DISABLE_CLEAR_FILTER";
