import * as types from "./product-details.type";
import { CommentActions } from "semantic-ui-react";

let initialState = {
  data: [],
  form: {
    showErrors: false,
    validationErrors: {}
  },
  orders: [],
  area: {},
  serviceArea: {},
  serviceProduct: {},
  serviceSubProduct: {},
  serviceProductPrice: {}
};

const productDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
    case types.FORM:
      return Object.assign({}, state, {
        form: action.data || initialState.form
      });
    case types.ADD_ORDER:
      return Object.assign({}, state, {
        orders: state.orders.concat(action.data) || initialState.orders
      });
    case types.REMOVE_ORDER:
      return Object.assign({}, state, {
        orders: state.orders.filter((_, i) => i !== action.data)
      });
    case types.CLEAR_ORDER:
      return Object.assign({}, state, {
        orders: initialState.orders
      });
    case types.UPDATE_ORDER:
      return Object.assign({}, state, {
        orders: state.orders || initialState.orders
      });
    case types.AREA_REQUEST:
    case types.AREA_SUCCESS:
    case types.AREA_FAILURE:
      return Object.assign({}, state, {
        area: action.data || initialState.area
      });
    case types.SERVICE_AREA_REQUEST:
    case types.SERVICE_AREA_SUCCESS:
    case types.SERVICE_AREA_FAILURE:
      return Object.assign({}, state, {
        serviceArea: action.data || initialState.serviceArea
      });
    case types.SERVICE_AREA_CLEAR:
      return Object.assign({}, state, {
        serviceArea: initialState.serviceArea
      });
    case types.SERVICE_PRODUCT_REQUEST:
    case types.SERVICE_PRODUCT_SUCCESS:
    case types.SERVICE_PRODUCT_FAILURE:
      return Object.assign({}, state, {
        serviceProduct: action.data || initialState.serviceProduct
      });
    case types.SERVICE_PRODUCT_CLEAR:
      return Object.assign({}, state, {
        serviceProduct: initialState.serviceProduct
      });
    case types.SERVICE_SUB_PRODUCT_REQUEST:
    case types.SERVICE_SUB_PRODUCT_SUCCESS:
    case types.SERVICE_SUB_PRODUCT_FAILURE:
      return Object.assign({}, state, {
        serviceSubProduct: action.data || initialState.serviceSubProduct
      });
    case types.SERVICE_SUB_PRODUCT_CLEAR:
      return Object.assign({}, state, {
        serviceSubProduct: initialState.serviceSubProduct
      });
    case types.SERVICE_PRODUCT_PRICE_REQUEST:
    case types.SERVICE_PRODUCT_PRICE_SUCCESS:
    case types.SERVICE_PRODUCT_PRICE_FAILURE:
      return Object.assign({}, state, {
        serviceProductPrice: action.data || initialState.serviceProductPrice
      });
    case types.SERVICE_PRODUCT_PRICE_CLEAR:
      return Object.assign({}, state, {
        serviceProductPrice: initialState.serviceProductPrice
      });
    case types.CLEAR:
      return Object.assign({}, state, {
        data: initialState.data
      });
  }
};

export default productDetailsReducer;
