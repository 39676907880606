import * as types from "../../constants/labelOrderDetails";

const initialState = {
  loading: false,
  hasError: false,
  data: null,
  htmlData: null,
  checkoutSuccess: false,
  checkoutFailed: false,
  customerInfo: null,
  messageTimeout: 5000,
  loadingCustomerInfo: false,
  isLoadingEmailCopy: false,
  isEmailCopySuccess: false,
  order: {
    customerPoNumber: null,
    billToCustomerLocationId: null,
    billToValue: null,
    shipToCustomerLocationId: null,
    shipToValue: null,
    labelSupplierId: null,
    labelCenterId: null,
    instructions: null,
    comments: null
  },
  totalNetPrice: 0
};

const labelOrderDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LABEL_ORDER_DETAILS_GET_CUSTOMER_INFO_REQUEST:
      return {
        ...state,
        loadingCustomerInfo: true
      };
    case types.LABEL_ORDER_DETAILS_GET_CUSTOMER_INFO_SUCCESS:
      return {
        ...state,
        loadingCustomerInfo: false,
        customerInfo: action.payload
      };
    case types.LABEL_ORDER_DETAILS_GET_CUSTOMER_INFO_FAILURE:
      return {
        ...state,
        loadingCustomerInfo: false
      };
    case types.LABEL_ORDER_DETAILS_GET_LABEL_ORDER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.LABEL_ORDER_DETAILS_GET_LABEL_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        data: action.payload,
        order: {
          customerPoNumber: action.payload.customerPoNumber,
          billToCustomerLocationId: action.payload.billToCustomerLocationId,
          billToValue: action.payload.billToValue,
          shipToCustomerLocationId: action.payload.shipToCustomerLocationId,
          shipToValue: action.payload.shipToValue,
          labelSupplierId: action.payload.labelSupplierId,
          labelSupplierName: action.payload.labelSupplierName,
          labelCenterId: action.payload.labelCenterId,
          instructions: action.payload.instructions,
          comments: action.payload.comments,
          shipToContactName: action.payload.shipToContactName,
          billToContactName: action.payload.billToContactName,
          billToCountry: action.payload.billToCountry,
          manufacturerName: action.payload.manufacturerName,
          manufacturerAccountNumber: action.payload.manufacturerAccountNumber,
          manufacturerAddress: action.payload.manufacturerAddress,
          shipToCountry: action.payload.shipToCountry,
          brokerInformation: action.payload.brokerInformation,
          comments: action.payload.comments,
          labelCenterName: action.payload.labelCenterName,
          customerEmailAddress: action.payload.customerEmailAddress,
          customerPhoneNumber: action.payload.customerPhoneNumber,
          shippingOptionName: action.payload.shippingOptionName,
          shippingDetails: action.payload.shippingDetails,
          billToCompany: action.payload.billToCompany,
          billToAccountNo: action.payload.billToAccountNo,
          billToContactEmail: action.payload.billToContactEmail,
          applicantAccountNumber: action.payload.applicantAccountNumber,
          applicantCompany: action.payload.applicantCompany,
          applicantAddress: action.payload.applicantAddress
        }
      };
    case types.LABEL_ORDER_DETAILS_GET_LABEL_ORDER_FAILURE:
      return {
        ...state,
        hasError: true,
        loading: false
      };
    case types.LABEL_ORDER_DETAILS_INITIALIZE:
      return initialState;
    case types.EMAIL_COPY_REQUEST:
      return {
        ...state,
        isLoadingEmailCopy: true,
        isEmailCopySuccess: false
      };
    case types.EMAIL_COPY_SUCCESS:
      return {
        ...state,
        isLoadingEmailCopy: false,
        isEmailCopySuccess: action.payload
      };
    case types.EMAIL_COPY_FAILURE:
      return {
        ...state,
        isLoadingEmailCopy: false,
        isEmailCopySuccess: false
      };
    case types.RENDER_PDF_REQUEST:
      return {
        ...state,
        htmlData: null
      };
    case types.RENDER_PDF_SUCCESS:
      return {
        ...state,
        htmlData: action.payload
      };
    case types.RENDER_PDF_FAILURE:
      return {
        ...state,
        htmlData: null
      };
    case types.UPDATE_ORACLE_NUMBER_REQUEST:
      return {
        ...state,
        isLoadingUpdateOracleNumber: true,
        isSuccessUpdateOracleNumber: false
      };
    case types.UPDATE_ORACLE_NUMBER_SUCCESS:
      return {
        ...state,
        isLoadingUpdateOracleNumber: false,
        isSuccessUpdateOracleNumber: true
      };
    case types.UPDATE_ORACLE_NUMBER_FAILURE:
      return {
        ...state,
        isLoadingUpdateOracleNumber: false,
        isSuccessUpdateOracleNumber: false
      };
    case types.UPDATE_ORACLE_NUMBER_RESET:
      return {
        ...state,
        isLoadingUpdateOracleNumber: false,
        isSuccessUpdateOracleNumber: false
      };
    default:
      return state;
  }
};

export default labelOrderDetailsReducer;
