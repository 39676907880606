import React, { Component, Fragment } from "react";
import {
  Container,
  Button,
  Table,
  Pagination,
  Dropdown,
  Checkbox,
  Form,
  Grid,
  Divider,
  Segment,
  Header,
  Icon,
  Modal
} from "semantic-ui-react";
import FooterHomeBlock from "../../../../components/footer/footer-home-block";
import {
  getFileNumbers,
  changePage,
  selectFileNumber,
  selectAllFileNumbers,
  selectFileVolume,
  selectAllFileVolumes,
  cleanAllFilters
} from "../../../../views/manufacturer/standard/file-volumes/file-volumes.action";
import {
  checkCustomerPilotIfExist,
  checkIfServiceContractUp,
  clearSelection
} from "../../../../views/applicant/standard/applicant-dashboard.action";
import { getLabelOrderCart } from "../../../../views/label-order-cart/cart/label-order-cart.action";
import { getCartItems } from "../../../../views/manufacturer/standard/label-cart/label-cart.action";

import { connect } from "react-redux";
import {
  CustomTitle,
  CustomHeaderLabel
} from "../../../../components/labels/labels";
import { Link } from "react-router-dom";
import toastHelper from "../../../../helpers/toast.helper";
import Role from "../../../../enums/role";
import { Common } from "../../../../common";
import "../../../../assets/css/styles.css";
class FileVolumes extends Component {
  state = {
    activeFilterIndex: -1,
    openPilotModal: false,
    zoomPercent: 100,
    checkBoxItems: []
  };

  labelOrderType = null;
  customerId = null;
  filterOptions = (data, activeIndex) => {
    var result = [];

    data.forEach((item, index) => {
      result.push({
        key: index,
        text: (
          <Checkbox
            label={item.text}
            checked={
              this.state.checkBoxItems.filter(
                (x) => x.text == item.text && x.activeIndex == activeIndex
              ).length > 0
                ? this.state.checkBoxItems.find(
                    (x) => x.text == item.text && x.activeIndex == activeIndex
                  ).isSelected
                : false
            }
            onClick={(e, self) => {
              this.props.selectFileNumber(item, self.checked, activeIndex);
              this.updateCheckboxState(activeIndex, self.checked, item.text);
              e.stopPropagation();
            }}
          />
        ),
        value: item.text
      });
    });

    return result;
  };

  updateCheckboxState(activeIndex, isSelected, text) {
    let newCheckBoxItems = JSON.parse(JSON.stringify(this.state.checkBoxItems));
    let selectedCheckbox = newCheckBoxItems.find(
      (x) => x.activeIndex == activeIndex && x.text == text
    );

    if (selectedCheckbox) {
      selectedCheckbox.isSelected = !selectedCheckbox.isSelected;
    } else {
      newCheckBoxItems.push({ activeIndex, text, isSelected: true });
    }

    this.setState({ checkBoxItems: newCheckBoxItems });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.activeFiles !== this.props.activeFiles) {
      let indexes = [
        ...new Set(this.state.checkBoxItems.map((o) => o.activeIndex))
      ];
      let newCheckBoxItems = JSON.parse(
        JSON.stringify(this.state.checkBoxItems)
      );

      for (let index of indexes) {
        switch (index) {
          case 1:
            let fileNumberItems = newCheckBoxItems.filter(
              (x) => x.activeIndex == index
            );

            //clear all volume for this infdex
            newCheckBoxItems = newCheckBoxItems.filter(
              (x) => x.activeIndex != index
            );

            for (let fileNumberItem of fileNumberItems) {
              if (
                this.props.fileNumbers.filter(
                  (x) => x.text == fileNumberItem.text
                ).length >= 0
              ) {
                newCheckBoxItems.push(fileNumberItem);
              }
            }

            break;
          case 2:
            let volumeItems = newCheckBoxItems.filter(
              (x) => x.activeIndex == index
            );

            //clear all volume for this infdex
            newCheckBoxItems = newCheckBoxItems.filter(
              (x) => x.activeIndex != index
            );

            for (let volumeItem of volumeItems) {
              if (
                this.props.volumes.filter((x) => x.text == volumeItem.text)
                  .length >= 0
              ) {
                newCheckBoxItems.push(volumeItem);
              }
            }

            break;
          case 3:
            let ccnItems = newCheckBoxItems.filter(
              (x) => x.activeIndex == index
            );

            newCheckBoxItems = newCheckBoxItems.filter(
              (x) => x.activeIndex != index
            );

            for (let ccnItem of ccnItems) {
              if (
                this.props.ccns.filter((x) => x.text == ccnItem.text).length >=
                0
              ) {
                newCheckBoxItems.push(ccnItem);
              }
            }

            break;
          case 4:
            let nameItems = newCheckBoxItems.filter(
              (x) => x.activeIndex == index
            );

            //clear all volume for this infdex
            newCheckBoxItems = newCheckBoxItems.filter(
              (x) => x.activeIndex != index
            );

            for (let nameItem of nameItems) {
              if (
                this.props.applicantNames.filter((x) => x.text == nameItem.text)
                  .length >= 0
              ) {
                newCheckBoxItems.push(nameItem);
              }
            }

            break;
          case 5:
            let addressItems = newCheckBoxItems.filter(
              (x) => x.activeIndex == index
            );

            //clear all volume for this infdex
            newCheckBoxItems = newCheckBoxItems.filter(
              (x) => x.activeIndex != index
            );

            for (let addressItem of addressItems) {
              if (
                this.props.applicantAdds.filter(
                  (x) => x.text == addressItem.text
                ).length >= 0
              ) {
                newCheckBoxItems.push(addressItem);
              }
            }
            break;
          case 6:
            let accountItems = newCheckBoxItems.filter(
              (x) => x.activeIndex == index
            );

            //clear all volume for this infdex
            newCheckBoxItems = newCheckBoxItems.filter(
              (x) => x.activeIndex != index
            );

            for (let accountItem of accountItems) {
              if (
                this.props.applicantAccntNumbers.filter(
                  (x) => x.text == accountItem.text
                ).length >= 0
              ) {
                newCheckBoxItems.push(accountItem);
              }
            }
            break;
          case 7:
            break;
        }
      }
      this.setState({ checkBoxItems: newCheckBoxItems });
    }
  }

  handleSetFilter = () => {
    const { filters, isFilterInitial } = this.props;

    this.props.getFileNumbers(
      null,
      2,
      1,
      5,
      filters,
      true,
      isFilterInitial,

      this.labelOrderType,
      false
    );
    this.props.clearSelection();
    this.setActiveFilterIndex(-1);
  };

  clearFilter = () => {
    const { filters, isFilterInitial } = this.props;

    this.props.getFileNumbers(
      null,
      2,
      1,
      5,
      filters,
      false,
      isFilterInitial,

      this.labelOrderType,
      false
    );
    this.setState({ checkBoxItems: [] });
    this.props.cleanAllFilters();
    this.setActiveFilterIndex(-1);
  };

  handleClosePilotModal = () => {
    this.setState({ openPilotModal: false });
  };

  getSortOrder(sortColumn, sortOrder) {
    if (this.props.sortColumn !== sortColumn) return "ascending";

    return sortOrder === "ascending" ? "descending" : "ascending";
  }

  componentDidMount() {
    this.props.checkIfServiceContractUp();

    this.customerId = localStorage.getItem("customerId")
      ? localStorage.getItem("customerId")
      : "00000000-0000-0000-0000-000000000000";
    //this.customerId = customerId ? JSON.parse(customerId) : null;
    this.labelOrderType = this.props.match.params.labelOrderType;
    // this.props.getLabelOrderCart();
    // debugger;
    this.props.getFileNumbers(
      null,
      2,
      1,
      5,
      this.filters,
      false,
      this.isFilterInitial,
      this.labelOrderType,
      true
    );
    this.setActiveFilterIndex(-1);

    this.props.getCartItems();

    var userRole = Common.getUserRole();
  }

  setActiveFilterIndex = (index) => {
    let { activeFilterIndex } = this.state;

    if (activeFilterIndex === index) activeFilterIndex = -1;
    else activeFilterIndex = index;

    this.setState({ activeFilterIndex });
  };

  handleGetLabels = (
    fileNumber,
    id,
    manufacturerId,
    volume,
    ccn,
    applicantName,
    applicantaddress,
    applicantAccount,
    cartLabelOrderType
  ) => {
    const { currentFileNumbers, fileVolumeCcnDetails } = this.props;

    this.props
      .checkCustomerPilotIfExist(fileNumber, applicantAccount)
      .then((result) => {
        if (result == false && this.labelOrderType == 2) {
          this.setState({ openPilotModal: true });
        } else {
          var isExist =
            currentFileNumbers.length == 0
              ? true
              : applicantName == fileVolumeCcnDetails.applicantName &&
                applicantaddress == fileVolumeCcnDetails.applicantAddress;
          ccn = ccn.replaceAll("/", "-");
          if (!isExist) {
            setTimeout(() => {
              toastHelper.error({
                description: (
                  <p>
                    Only files with the same Applicant Company Name can be
                    ordered in a single order. <br /> Please create a new order
                    for this Applicant File Number.
                  </p>
                )
              });
            }, 100);
          } else {
            if (cartLabelOrderType == 1)
              return this.props.history.push(
                `/home/label-order/${fileNumber}/0/0/${volume}/${ccn}/${applicantAccount}`
              );
            else if (cartLabelOrderType == 2)
              return this.props.history.push(
                `/home/existing-combination-labels/${fileNumber}/0/0/${volume}/${ccn}/${applicantAccount}`
              );
          }
        }
      });
  };

  handleZoomOut = () => {
    let { zoomPercent } = this.state;

    if (zoomPercent >= 50) {
      var currentZoom = zoomPercent - 10;
      document.body.style.zoom = currentZoom + "%";
      this.setState({ zoomPercent: currentZoom });
    }
  };

  handleZoomIn = () => {
    let { zoomPercent } = this.state;

    if (zoomPercent <= 150) {
      var currentZoom = zoomPercent + 10;
      document.body.style.zoom = currentZoom + "%";
      this.setState({ zoomPercent: currentZoom });
    }
  };

  handleGetLabelsMultiple = (labelOrderType) => {
    const { selectedItems, currentFileNumbers, fileVolumeCcnDetails } =
      this.props;
    var isExist = false;
    var selectedName = null;
    var selectedAddress = null;

    var fileNumbers = selectedItems[0].map((item) => {
      return item.fileNumber;
    });

    var accountNumbers = selectedItems[0].map((item) => {
      return item.applicantAccount;
    });

    this.props
      .checkCustomerPilotIfExist(fileNumbers, accountNumbers)
      .then((result) => {
        if (result == false && this.labelOrderType == 2) {
          this.setState({ openPilotModal: true });
        } else {
          selectedItems[0].every((item) => {
            if (currentFileNumbers.length == 0) {
              if (selectedName == null) {
                selectedName = item.applicantName;
                selectedAddress = item.applicantAddress;

                isExist = true;
              } else {
                isExist =
                  item.applicantName == selectedName &&
                  item.applicantAddress == selectedAddress;
              }
            } else {
              isExist =
                item.applicantName == fileVolumeCcnDetails.applicantName &&
                item.applicantAddress == fileVolumeCcnDetails.applicantAddress;
            }

            if (!isExist) return false;

            return true;
          });

          if (!isExist) {
            setTimeout(() => {
              toastHelper.error({
                description: (
                  <p>
                    Only files with the same Applicant Company Name can be
                    ordered in a single order. <br /> Please create a new order
                    for this Applicant File Number.
                  </p>
                )
              });
            }, 100);
          } else {
            var ccnList = selectedItems[0].map((item) => {
              item.compCcns = item.compCcns.replaceAll("/", "-");
              return item.compCcns;
            });

            var fileNumberList = selectedItems[0].map((item) => {
              return item.fileNumber;
            });

            var volumeList = selectedItems[0].map((item) => {
              return item.volume;
            });

            var applicantAccount = selectedItems[0][0].applicantAccount;

            if (labelOrderType == 1) {
              return this.props.history.push(
                `/home/label-order/${fileNumberList || ""}/${
                  ccnList || ""
                }/${applicantAccount}/${volumeList}`
              );
            } else if (labelOrderType == 2) {
              return this.props.history.push(
                `/home/existing-combination-labels/${fileNumberList}/${
                  volumeList || ""
                }/${ccnList || ""}/${applicantAccount}`
              ); // to do multiple selections
            }
          }
        }
      });
  };

  render() {
    const {
      data,
      totalPages,
      sortColumn,
      sortOrder,
      currentPage,
      pageSize,
      applicantNames,
      applicantAdds,
      applicantAccntNumbers,
      fileNumbers,
      volumes,
      ccns,
      filters,
      hasFilter,
      isLoading,
      isSelectedAll,
      selectedItems,
      labelCart,
      hasFileVolumeData,
      isServiceContractUp,
      isFilterInitial
    } = this.props;

    const translations = Common.getTranslations() || {};
    const languageCode = Common.getLanguageCode() || "";
    const { activeFilterIndex, openPilotModal } = this.state;

    let activeFiles = null;

    let cartLabelOrderType = null;

    const cartData = labelCart
      ? labelCart.data
        ? labelCart.data[0]
        : null
      : null;

    if (cartData) cartLabelOrderType = cartData.labelOrderType;

    if (data) activeFiles = data;
    var userRole = Common.getUserRole();
    var caption =
      userRole === Role.applicant
        ? translations["LexManufacturerName"]
        : translations["LexApplicantCompanyName"];
    var headerCaption =
      userRole === Role.applicant ? "Applicant" : "Manufacturer";

    if (isLoading) {
      return (
        <div className="div-container">
          <Form loading={isLoading}></Form>
        </div>
      );
    } else if (!hasFileVolumeData) {
      return (
        <div className="div-container">
          <Segment placeholder style={{ textAlign: "center" }}>
            <Header icon>
              <Icon name="exclamation triangle" />
            </Header>
            {translations["LexRefreshDataBanner"]}
          </Segment>
        </div>
      );
    } else if (!data) {
      return (
        <div className="div-container">
          <Segment placeholder style={{ textAlign: "center" }}>
            <Header icon>
              <Icon name="exclamation triangle" />
            </Header>
            {translations["LexRefreshDataBanner"]}
          </Segment>
        </div>
      );
    } else if (!isServiceContractUp) {
      //} else if (false) {
      return (
        <div className="div-container">
          <Segment placeholder>
            <p
              hidden={
                languageCode == "ja" ||
                languageCode == "ko" ||
                languageCode == "fr-CA"
              }
            >
              {translations["LexSystemMaintenanceBanner"]
                .split("\n\n")
                .map((line, i) => (
                  <span key={i}>
                    {line}
                    {i == 0 ? (
                      <span>
                        <br />
                        <br />
                      </span>
                    ) : (
                      ""
                    )}
                  </span>
                ))}
              <a
                href={
                  "https://marks.ul.com/about/ul-listing-and-classification-marks/labels/label-centers/"
                }
                target="_blank"
              >
                {" "}
                {translations["LexLabelCenter"]}
              </a>
              .
            </p>
            <p hidden={languageCode != "ja"}>
              {translations["LexSystemMaintenanceBanner"]
                .split("\n\n")
                .map((line, i) => (
                  <span key={i}>
                    {i == 0 ? (
                      <span>
                        {line}
                        <br />
                        <br />
                      </span>
                    ) : i == 1 ? (
                      line.split("ラベルセンター").map((line2, x) => (
                        <span key={x}>
                          {line2}{" "}
                          {x == 0 ? (
                            <a
                              href={
                                "https://marks.ul.com/about/ul-listing-and-classification-marks/labels/label-centers/"
                              }
                              target="_blank"
                            >
                              {translations["LexLabelCenter"]}
                            </a>
                          ) : (
                            ""
                          )}
                        </span>
                      ))
                    ) : (
                      ""
                    )}
                  </span>
                ))}
            </p>
            <p hidden={languageCode != "ko"}>
              {translations["LexSystemMaintenanceBanner"]
                .split("\n\n")
                .map((line, i) => (
                  <span key={i}>
                    {i == 0 ? (
                      <span>
                        {line}
                        <br />
                        <br />
                      </span>
                    ) : i == 1 ? (
                      line.split("라벨 센터").map((line2, x) => (
                        <span key={x}>
                          {line2}{" "}
                          {x == 0 ? (
                            <a
                              href={
                                "https://marks.ul.com/about/ul-listing-and-classification-marks/labels/label-centers/"
                              }
                              target="_blank"
                            >
                              {translations["LexLabelCenter"]}
                            </a>
                          ) : (
                            ""
                          )}
                        </span>
                      ))
                    ) : (
                      ""
                    )}
                  </span>
                ))}
            </p>
            <p hidden={languageCode != "fr-CA"}>
              {translations["LexSystemMaintenanceBanner"]
                .split("\n\n")
                .map((line, i) => (
                  <span key={i}>
                    {i == 0 ? (
                      <span>
                        {line}
                        <br />
                        <br />
                      </span>
                    ) : i == 1 ? (
                      line.split("centre d’étiquetage").map((line2, x) => (
                        <span key={x}>
                          {line2}{" "}
                          {x == 0 ? (
                            <a
                              href={
                                "https://marks.ul.com/about/ul-listing-and-classification-marks/labels/label-centers/"
                              }
                              target="_blank"
                            >
                              {translations["LexLabelCenter"]}
                            </a>
                          ) : (
                            ""
                          )}
                        </span>
                      ))
                    ) : (
                      ""
                    )}
                  </span>
                ))}
            </p>
          </Segment>
        </div>
      );
    } else {
      return (
        <div className="div-container">
          <Modal
            size="tiny"
            open={openPilotModal}
            onClose={this.handleClosePilotModal}
          >
            <Modal.Content>
              <p>
                {translations["LexNALAComboLabelPrefix"]}{" "}
                <a
                  href="https://marks.ul.com/about/designing-and-ordering-ul-marks/combination-label-order-form/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {translations["LexNALAComboLabelHyperlink"]}
                </a>{" "}
                {translations["LexNALAComboLabelSuffix"]}
              </p>
            </Modal.Content>
            <Modal.Actions>
              <Button primary onClick={this.handleClosePilotModal}>
                Close
              </Button>
            </Modal.Actions>
          </Modal>
          <Container fluid>
            <br /> <br />
            <label>
              {translations["LexYouareloggesinwiththefollowingcredentials"]}
            </label>
            <br /> <br />
            <table>
              <tr>
                <td>
                  <label
                    style={{
                      fontWeight: "bold",
                      paddingRight: "5px",
                      fontSize: "16px"
                    }}
                  >
                    {`Party Site Number`}
                  </label>
                </td>
                <td
                  style={{
                    fontSize: "16px"
                  }}
                >
                  {Common.getPartySiteNumber()}
                </td>
              </tr>
              <tr>
                <td>
                  <label
                    style={{
                      fontWeight: "bold",
                      fontSize: "16px"
                    }}
                  >
                    {translations["LexRole"]}
                  </label>
                </td>
                <td
                  style={{
                    fontSize: "16px"
                  }}
                >
                  {Common.getUserRole() == 1
                    ? translations["LexApplicant"]
                    : translations["LexManufacturer"]}
                </td>
              </tr>
            </table>
            <Grid>
              <Grid.Row>
                <Grid.Column textAlign="center">
                  {/* <CustomTitle
                      message={`You are logged with Party Site No. ${Common.getPartySiteNumber()} with ${headerCaption} role`}
                    /> */}
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Divider fitted hidden />
            <div>
              <Form loading={isLoading}>
                <Grid>
                  {" "}
                  <Grid.Row style={{ padding: "20px" }}>
                    <Grid.Column textAlign="right">
                      <span hidden={this.labelOrderType == 2}>
                        <Button
                          primary
                          onClick={this.handleGetLabelsMultiple.bind(this, 1)}
                          disabled={
                            (selectedItems[0]
                              ? selectedItems[0] && selectedItems[0].length
                              : 0) === 0 || cartLabelOrderType == 2
                          }
                        >
                          {translations["LexOrderStandardLabels"]}
                        </Button>
                      </span>
                      <span hidden={this.labelOrderType == 1}>
                        <Button
                          primary
                          onClick={this.handleGetLabelsMultiple.bind(this, 2)}
                          disabled={
                            (selectedItems[0]
                              ? selectedItems[0] && selectedItems[0].length
                              : 0) === 0 || cartLabelOrderType == 1
                          }
                        >
                          {translations["LexOrderCombinationLabels"]}
                        </Button>
                      </span>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <div>
                  <div>
                    <Table>
                      <Table.Row>
                        <Table.Cell></Table.Cell>
                        <Table.Cell style={{ width: "60%" }}>
                          {translations["LexActiveFiles"]}
                        </Table.Cell>
                        {/* <Table.Cell
                          textAlign="right"
                          style={{
                            width: "30%",
                            borderRight: "1px solid",
                            borderRightColor: "gray"
                          }}
                        >
                          {selectedItems[0]
                            ? selectedItems[0] && selectedItems[0].length
                            : 0}{" "}
                          {translations["LexOptionselected"]}
                        </Table.Cell> */}
                        <Table.Cell
                          textAlign="right"
                          style={{
                            width: "30%",
                            borderRight: "1px solid",
                            borderRightColor: "gray"
                          }}
                        >
                          {selectedItems[0]
                            ? selectedItems[0] && selectedItems[0].length
                            : 0}{" "}
                          {translations["LexOptionselected"]}
                        </Table.Cell>
                        <Table.Cell
                          style={{
                            width: "10%",
                            borderRight: "1px solid",
                            borderRightColor: "gray"
                          }}
                        >
                          <label
                            onClick={this.clearFilter}
                            style={{ cursor: "pointer" }}
                          >
                            <i class="undo icon"></i>
                            {translations["LexClearFilter"]}
                          </label>
                        </Table.Cell>
                        {/* <Table.Cell
                          style={{
                            width: "5%",
                            borderRight: "2px solid",
                            borderRightColor: "gray",
                            cursor: "pointer"
                          }}
                        >
                          <i
                            class="zoom-out icon"
                            onClick={this.handleZoomOut}
                          ></i>
                        </Table.Cell>
                        <Table.Cell
                          style={{
                            width: "5%",
                            borderRight: "2px solid",
                            borderRightColor: "gray",
                            cursor: "pointer"
                          }}
                        >
                          <i
                            class="zoom-in icon"
                            onClick={this.handleZoomIn}
                          ></i>
                        </Table.Cell> */}
                      </Table.Row>
                    </Table>
                  </div>
                </div>
                <div
                  style={
                    activeFilterIndex == 0
                      ? { overflowX: "scroll" }
                      : { overflowX: "unset" }
                  }
                >
                  <Table id="standard-order-table" celled striped>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell
                          style={{
                            backgroundColor: "#D0D0D0",
                            borderRight: "1px solid",
                            borderRightColor: "gray"
                          }}
                        >
                          <Dropdown
                            //scrolling
                            text={caption}
                            key={4}
                            //options={this.filterOptions(applicantNames, 4)}
                            open={activeFilterIndex === 4}
                            onClick={() => {
                              this.setActiveFilterIndex(4);
                            }}
                          >
                            <Dropdown.Menu>
                              <Dropdown.Menu scrolling>
                                {this.filterOptions(applicantNames, 4).map(
                                  (option) => (
                                    <Dropdown.Item
                                      key={option.key}
                                      {...option}
                                    />
                                  )
                                )}
                              </Dropdown.Menu>
                              <Button
                                className="button-fluid"
                                onClick={() => {
                                  this.handleSetFilter();
                                }}
                              >
                                {translations["LexApply"]}
                              </Button>
                            </Dropdown.Menu>
                          </Dropdown>
                          {applicantNames.find((x) => x.isSelected) != null ? (
                            <i class="filter icon"></i>
                          ) : null}
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          style={{
                            backgroundColor: "#D0D0D0",
                            borderRight: "1px solid",
                            borderRightColor: "gray"
                          }}
                        >
                          <Dropdown
                            //scrolling
                            text={translations["LexAddress"]}
                            key={5}
                            // options={this.filterOptions(applicantAdds, 5)}
                            open={activeFilterIndex === 5}
                            onClick={() => {
                              this.setActiveFilterIndex(5);
                            }}
                          >
                            <Dropdown.Menu>
                              <Dropdown.Menu scrolling>
                                {this.filterOptions(applicantAdds, 5).map(
                                  (option) => (
                                    <Dropdown.Item
                                      key={option.key}
                                      {...option}
                                    />
                                  )
                                )}
                              </Dropdown.Menu>
                              <Button
                                className="button-fluid"
                                onClick={() => {
                                  this.handleSetFilter();
                                }}
                              >
                                {translations["LexApply"]}
                              </Button>
                            </Dropdown.Menu>
                          </Dropdown>
                          {applicantAdds.find((x) => x.isSelected) != null ? (
                            <i class="filter icon"></i>
                          ) : null}
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          style={{
                            backgroundColor: "#D0D0D0",
                            borderRight: "1px solid",
                            borderRightColor: "gray"
                          }}
                        >
                          <Dropdown
                            // scrolling
                            text={translations["LexAccountNumber"]}
                            key={6}
                            // options={this.filterOptions(
                            //   applicantAccntNumbers,
                            //   6
                            // )}
                            open={activeFilterIndex === 6}
                            onClick={() => {
                              this.setActiveFilterIndex(6);
                            }}
                          >
                            <Dropdown.Menu>
                              <Dropdown.Menu scrolling>
                                {this.filterOptions(
                                  applicantAccntNumbers,
                                  6
                                ).map((option) => (
                                  <Dropdown.Item key={option.key} {...option} />
                                ))}
                              </Dropdown.Menu>
                              <Button
                                className="button-fluid"
                                onClick={() => {
                                  this.handleSetFilter();
                                }}
                              >
                                {translations["LexApply"]}
                              </Button>
                            </Dropdown.Menu>
                          </Dropdown>

                          {applicantAccntNumbers.find((x) => x.isSelected) !=
                          null ? (
                            <i class="filter icon"></i>
                          ) : null}
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          style={{
                            backgroundColor: "#D0D0D0",
                            borderRight: "1px solid",
                            borderRightColor: "gray"
                          }}
                        >
                          <Dropdown
                            //scrolling
                            text={translations["LexFileNumber"]}
                            //options={this.filterOptions(fileNumbers, 1)}
                            open={activeFilterIndex === 1}
                            onClick={() => {
                              this.setActiveFilterIndex(1);
                            }}
                          >
                            <Dropdown.Menu>
                              <Dropdown.Menu scrolling>
                                {this.filterOptions(fileNumbers, 1).map(
                                  (option) => (
                                    <Dropdown.Item
                                      key={option.key}
                                      {...option}
                                    />
                                  )
                                )}
                              </Dropdown.Menu>
                              <Button
                                className="button-fluid"
                                onClick={() => {
                                  this.handleSetFilter();
                                }}
                              >
                                {translations["LexApply"]}
                              </Button>
                            </Dropdown.Menu>
                          </Dropdown>

                          {fileNumbers.find((x) => x.isSelected) != null ? (
                            <i class="filter icon"></i>
                          ) : null}
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          style={{
                            backgroundColor: "#D0D0D0",
                            borderRight: "1px solid",
                            borderRightColor: "gray"
                          }}
                        >
                          <Dropdown
                            //  scrolling
                            text={translations["LexVolume"]}
                            key={2}
                            //options={this.filterOptions(volumes, 2)}
                            open={activeFilterIndex === 2}
                            onClick={() => {
                              this.setActiveFilterIndex(2);
                            }}
                          >
                            <Dropdown.Menu>
                              <Dropdown.Menu scrolling>
                                {this.filterOptions(volumes, 2).map(
                                  (option) => (
                                    <Dropdown.Item
                                      key={option.key}
                                      {...option}
                                    />
                                  )
                                )}
                              </Dropdown.Menu>
                              <Button
                                className="button-fluid"
                                onClick={() => {
                                  this.handleSetFilter();
                                }}
                              >
                                {translations["LexApply"]}
                              </Button>
                            </Dropdown.Menu>
                          </Dropdown>

                          {volumes.find((x) => x.isSelected) != null ? (
                            <i class="filter icon"></i>
                          ) : null}
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          style={{
                            backgroundColor: "#D0D0D0",
                            borderRight: "1px solid",
                            borderRightColor: "gray"
                          }}
                        >
                          {translations["LexProductCovered"]}
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          style={{
                            backgroundColor: "#D0D0D0",
                            borderRight: "1px solid",
                            borderRightColor: "gray"
                          }}
                        >
                          <Dropdown
                            //scrolling
                            text="+ CCN"
                            key={3}
                            //  options={this.filterOptions(ccns, 3)}
                            open={activeFilterIndex === 3}
                            onClick={() => {
                              this.setActiveFilterIndex(3);
                            }}
                            style={{ marginRight: "20px" }}
                          >
                            <Dropdown.Menu>
                              <Dropdown.Menu scrolling>
                                {this.filterOptions(ccns, 3).map((option) => (
                                  <Dropdown.Item key={option.key} {...option} />
                                ))}
                              </Dropdown.Menu>
                              <Button
                                className="button-fluid"
                                onClick={() => {
                                  this.handleSetFilter();
                                }}
                              >
                                {translations["LexApply"]}
                              </Button>
                            </Dropdown.Menu>
                          </Dropdown>

                          {ccns.find((x) => x.isSelected) != null ? (
                            <i class="filter icon"></i>
                          ) : null}
                        </Table.HeaderCell>
                        {/* <Table.HeaderCell>
                          <Button primary onClick={this.handleSetFilter}>
                            Set filter
                          </Button>
                        </Table.HeaderCell> */}
                        <Table.HeaderCell>
                          <Checkbox
                            style={{
                              border: "1px solid black",
                              padding: "0px",
                              height: "23px",
                              width: "23px"
                            }}
                            checked={isSelectedAll}
                            onChange={(e, { checked }) => {
                              this.props.selectAllFileVolumes(checked);
                            }}
                          />
                        </Table.HeaderCell>
                      </Table.Row>
                      {/* <Table.Row>
                        <Table.HeaderCell
                          sorted={
                            sortColumn === "ApplicantName" ? sortOrder : null
                          }
                          onClick={() => {
                            this.props.getFileNumbers(
                              "ApplicantName",
                              this.getSortOrder("ApplicantName", sortOrder),
                              currentPage,
                              pageSize,
                              selectedFileNumbers,
                              selectedVolumes,
                              selectedCcns,
                              selectedApplicantNames,
                              selectedApplicantAddress,
                              selectedApplicantAccountNumbers,
                              this.labelOrderType
                            );
                          }}
                        >
                          {caption + " Name"}
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          sorted={
                            sortColumn === "ApplicantAddress" ? sortOrder : null
                          }
                          onClick={() => {
                            this.props.getFileNumbers(
                              "ApplicantAddress",
                              this.getSortOrder("ApplicantAddress", sortOrder),
                              currentPage,
                              pageSize,
                              selectedFileNumbers,
                              selectedVolumes,
                              selectedCcns,
                              selectedApplicantNames,
                              selectedApplicantAddress,
                              selectedApplicantAccountNumbers,
                              this.labelOrderType
                            );
                          }}
                        >
                          {caption + " Address"}
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          sorted={
                            sortColumn === "ApplicantAccountNumber"
                              ? sortOrder
                              : null
                          }
                          onClick={() => {
                            this.props.getFileNumbers(
                              "ApplicantAccountNumber",
                              this.getSortOrder(
                                "ApplicantAccountNumber",
                                sortOrder
                              ),
                              currentPage,
                              pageSize,
                              selectedFileNumbers,
                              selectedVolumes,
                              selectedCcns,
                              selectedApplicantNames,
                              selectedApplicantAddress,
                              selectedApplicantAccountNumbers,
                              this.labelOrderType
                            );
                          }}
                        >
                          {caption + " Account Number"}
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          sorted={
                            sortColumn === "FileNumber" ? sortOrder : null
                          }
                          onClick={() => {
                            this.props.getFileNumbers(
                              "FileNumber",
                              this.getSortOrder("FileNumber", sortOrder),
                              currentPage,
                              pageSize,
                              selectedFileNumbers,
                              selectedVolumes,
                              selectedCcns,
                              selectedApplicantNames,
                              selectedApplicantAddress,
                              selectedApplicantAccountNumbers,
                              this.labelOrderType
                            );
                          }}
                        >
                          File No.
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          sorted={sortColumn === "Volume" ? sortOrder : null}
                          onClick={() => {
                            this.props.getFileNumbers(
                              "Volume",
                              this.getSortOrder("Volume", sortOrder),
                              currentPage,
                              pageSize,
                              selectedFileNumbers,
                              selectedVolumes,
                              selectedCcns,
                              selectedApplicantNames,
                              selectedApplicantAddress,
                              selectedApplicantAccountNumbers,
                              this.labelOrderType
                            );
                          }}
                        >
                          Vol.
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          sorted={
                            sortColumn === "ProductType" ? sortOrder : null
                          }
                          onClick={() => {
                            this.props.getFileNumbers(
                              "ProductType",
                              this.getSortOrder("ProductType", sortOrder),
                              currentPage,
                              pageSize,
                              selectedFileNumbers,
                              selectedVolumes,
                              selectedCcns,
                              selectedApplicantNames,
                              selectedApplicantAddress,
                              selectedApplicantAccountNumbers,
                              this.labelOrderType
                            );
                          }}
                        >
                          Product Covered
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          sorted={sortColumn === "Ccn" ? sortOrder : null}
                          onClick={() => {
                            this.props.getFileNumbers(
                              "Ccn",
                              this.getSortOrder("Ccn", sortOrder),
                              currentPage,
                              pageSize,
                              selectedFileNumbers,
                              selectedVolumes,
                              selectedCcns,
                              selectedApplicantNames,
                              selectedApplicantAddress,
                              selectedApplicantAccountNumbers,
                              this.labelOrderType
                            );
                          }}
                        >
                          CCN
                        </Table.HeaderCell>
                        <Table.HeaderCell>Actions</Table.HeaderCell>
                        <Table.HeaderCell>Select</Table.HeaderCell>
                      </Table.Row> */}
                    </Table.Header>
                    <Table.Body>
                      {activeFiles && activeFiles.length > 0 ? (
                        activeFiles.map((data, idx) => {
                          return (
                            <Table.Row key={idx}>
                              <Table.Cell className="list-td">
                                <div className="fixed-table">
                                  <div className="ellipsis-preview">
                                    {userRole === Role.applicant
                                      ? data.manufacturerName
                                      : data.applicantName}
                                  </div>
                                </div>
                              </Table.Cell>
                              <Table.Cell className="list-td">
                                <div className="fixed-table">
                                  <div className="ellipsis-preview">
                                    {userRole === Role.applicant
                                      ? data.manufacturerAddress
                                      : data.applicantAddress}
                                  </div>
                                </div>
                              </Table.Cell>
                              <Table.Cell className="list-td">
                                <div className="fixed-table">
                                  <div className="ellipsis-preview">
                                    {userRole === Role.applicant
                                      ? data.manufacturerAccount
                                      : data.applicantAccount}
                                  </div>
                                </div>
                              </Table.Cell>
                              <Table.Cell className="list-td">
                                <div className="fixed-table">
                                  <div className="ellipsis-preview">
                                    {data.fileNumber}
                                  </div>
                                </div>
                              </Table.Cell>
                              <Table.Cell className="list-td">
                                <div className="fixed-table">
                                  <div className="ellipsis-preview">
                                    {data.volume}
                                  </div>
                                </div>
                                {/* {data.volume} */}
                              </Table.Cell>
                              <Table.Cell className="list-td">
                                <div className="fixed-table">
                                  <div className="ellipsis-preview">
                                    {data.productType}
                                  </div>
                                </div>
                              </Table.Cell>
                              <Table.Cell className="list-td">
                                <div className="fixed-table">
                                  <div className="ellipsis-preview">
                                    {data.compCcns}
                                  </div>
                                </div>
                              </Table.Cell>
                              {/* <Table.Cell> */}
                              {/* <Button as={Link} to="/home/label-order" primary>
                            History
                          </Button> */}
                              {/* {this.labelOrderType == 1 && (
                                  <Button
                                    basic
                                    primary
                                    onClick={this.handleGetLabels.bind(
                                      this,
                                      data.fileNumber,
                                      data.id,
                                      data.manufacturerId,
                                      data.volume,
                                      data.compCcns,
                                      data.applicantName,
                                      data.applicantAddress,
                                      data.applicantAccount,
                                      parseInt(this.labelOrderType)
                                    )}
                                    disabled={cartLabelOrderType == 2}
                                  >
                                    Order Standard Labels
                                  </Button>
                                )}

                                {this.labelOrderType == 2 && (
                                  <Button
                                    basic
                                    primary
                                    onClick={this.handleGetLabels.bind(
                                      this,
                                      data.fileNumber,
                                      data.id,
                                      data.manufacturerId,
                                      data.volume,
                                      data.compCcns,
                                      data.applicantName,
                                      data.applicantAddress,
                                      data.applicantAccount,
                                      parseInt(this.labelOrderType)
                                    )}
                                    disabled={cartLabelOrderType == 1}
                                  >
                                    Order Combination Labels
                                  </Button>
                                )} */}
                              {/* <Button
                            primary
                            as={Link}
                            to="/home/label-order"
                            disabled={true}
                          >
                            Repeat Previous Order
                          </Button> */}
                              {/* </Table.Cell> */}
                              <Table.Cell>
                                <Checkbox
                                  style={{
                                    border: "1px solid black",
                                    padding: "0px",
                                    height: "21px",
                                    width: "21px"
                                  }}
                                  checked={data.isSelected}
                                  onChange={(e, { checked }) => {
                                    this.props.selectFileVolume(data, checked);
                                  }}
                                />
                              </Table.Cell>
                            </Table.Row>
                          );
                        })
                      ) : (
                        <Table.Row textAlign="center">
                          <Table.Cell colSpan={9}>
                            If the File Number is not displayed, please contact
                            the Label Center for assistance.
                          </Table.Cell>
                        </Table.Row>
                      )}
                    </Table.Body>
                    <Table.Footer class="full-width">
                      <Table.Row>
                        <Table.HeaderCell
                          colSpan="8"
                          style={{
                            borderTop: "1px solid",
                            borderTopColor: "gray",
                            borderBottom: "1px solid",
                            borderBottomColor: "gray"
                          }}
                        >
                          {data && totalPages > 0 ? (
                            <div className={"footer-container-left"}>
                              <Pagination
                                style={{ float: "right" }}
                                activePage={currentPage}
                                totalPages={totalPages}
                                onPageChange={(e, { activePage }) => {
                                  this.props.changePage(
                                    sortColumn,
                                    sortOrder,
                                    activePage,
                                    pageSize,
                                    filters,
                                    hasFilter,
                                    isFilterInitial,
                                    this.labelOrderType
                                  );
                                }}
                              />
                            </div>
                          ) : (
                            <CustomHeaderLabel Message="No record found." />
                          )}
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Footer>
                  </Table>
                </div>
              </Form>
              {/* <Grid>
                  <Grid.Row columns="1">
                    <Grid.Column>
                      {data && totalPages > 0 ? (
                        <Pagination
                          activePage={currentPage}
                          totalPages={totalPages}
                          onPageChange={(e, { activePage }) => {
                            this.props.changePage(
                              sortColumn,
                              sortOrder,
                              activePage,
                              pageSize,
                              selectedFileNumbers,
                              selectedVolumes,
                              selectedCcns,
                              selectedApplicantNames,
                              selectedApplicantAddress,
                              selectedApplicantAccountNumbers,
                              this.labelOrderType
                            );
                          }}
                        />
                      ) : (
                        <CustomHeaderLabel
                          Message="If the File Number is not displayed, please contact the
                      Label Center for assistance."
                        />
                      )}
                    </Grid.Column>
                  </Grid.Row>
                </Grid> */}
            </div>
            <FooterHomeBlock />
          </Container>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  data: state.fileVolumes.data,
  currentFileNumbers: state.labelCart.fileNumbers,
  fileVolumeCcnDetails: state.labelCart.fileVolumeCcnDetails,
  totalPages: state.fileVolumes.totalPages,
  sortColumn: state.fileVolumes.sortColumn,
  sortOrder: state.fileVolumes.sortOrder,
  hasError: state.fileVolumes.hasError,
  currentPage: state.fileVolumes.currentPage,
  pageSize: state.fileVolumes.pageSize,
  fileNumbers: state.fileVolumes.fileNumbers,
  volumes: state.fileVolumes.volumes,
  ccns: state.fileVolumes.ccns,
  applicantNames: state.fileVolumes.applicantNames,
  applicantAdds: state.fileVolumes.applicantAdds,
  applicantAccntNumbers: state.fileVolumes.applicantAccntNumbers,
  // selectedFileNumbers: state.fileVolumes.selectedFileNumbers,
  // selectedVolumes: state.fileVolumes.selectedVolumes,
  // selectedCcns: state.fileVolumes.selectedCcns,
  // selectedApplicantNames: state.fileVolumes.selectedApplicantNames,
  // selectedApplicantAddress: state.fileVolumes.selectedApplicantAddress,
  // selectedApplicantAccountNumbers:
  //   state.fileVolumes.selectedApplicantAccountNumbers,
  filters: state.fileVolumes.filters,
  hasFilter: state.fileVolumes.hasFilter,
  isFilterInitial: state.fileVolumes.isFilterInitial,
  isLoading: state.fileVolumes.isLoading,
  isSelectedAll: state.fileVolumes.isSelectedAll,
  selectedItems: state.fileVolumes.selectedItems,
  labelCart: state.labelCart,
  hasFileVolumeData: state.fileVolumes.hasFileVolumeData,
  isServiceContractUp: state.applicantDashboard.isServiceContractUp
});

const mapDispatchToProps = (dispatch) => ({
  getFileNumbers: (
    sortColumn,
    sortOrder,
    currentPage,
    pageSize,
    filters,
    hasFilter,
    isFilterInitial,

    labelOrderType,
    isFirstLoad
  ) =>
    dispatch(
      getFileNumbers(
        sortColumn,
        sortOrder,
        currentPage,
        pageSize,
        filters,
        hasFilter,
        isFilterInitial,

        labelOrderType,
        isFirstLoad
      )
    ),
  changePage: (
    sortColumn,
    sortOrder,
    currentPage,
    pageSize,
    filters,
    hasFilter,
    isFilterInitial,

    labelOrderType
  ) =>
    dispatch(
      changePage(
        sortColumn,
        sortOrder,
        currentPage,
        pageSize,
        filters,
        hasFilter,
        isFilterInitial,

        labelOrderType
      )
    ),
  selectFileNumber: (data, isSelected, activeFilterIndex) =>
    dispatch(selectFileNumber(data, isSelected, activeFilterIndex)),
  selectAllFileNumbers: (isSelected, activeFilterIndex) =>
    dispatch(selectAllFileNumbers(isSelected, activeFilterIndex)),
  getLabelOrderCart: () => dispatch(getLabelOrderCart()),
  getCartItems: () => dispatch(getCartItems()),
  selectFileVolume: (data, isSelected) =>
    dispatch(selectFileVolume(data, isSelected)),
  selectAllFileVolumes: (isSelectedAll) =>
    dispatch(selectAllFileVolumes(isSelectedAll)),
  checkCustomerPilotIfExist: (fileNumber, manufacturerAccountNumber) =>
    dispatch(checkCustomerPilotIfExist(fileNumber, manufacturerAccountNumber)),
  checkIfServiceContractUp: () => dispatch(checkIfServiceContractUp()),
  cleanAllFilters: () => dispatch(cleanAllFilters()),
  clearSelection: () => dispatch(clearSelection())
});

export default connect(mapStateToProps, mapDispatchToProps)(FileVolumes);
