// Separated to re-use in summary, quotation scope and saving

const scopeValue = (form) => {
  return form.quantity
    ? `Field Evaluation of (${form.quantity}) ${
        form.isPanelboard
          ? form.serviceSubProductName
          : form.serviceSubProductName
          ? form.serviceSubProductName.substr(
              0,
              form.serviceSubProductName.lastIndexOf("/")
            )
          : ""
      },${form.isExistingUlEquipment ? " UL Listed" : ""} Modified for ${
        form.serviceProductName
      }, Single Site Visit`
    : null;
};

export default scopeValue;
