import React, { Component, Fragment } from "react";
import {
  Accordion,
  Grid,
  Icon,
  Divider,
  Segment,
  Form,
  Table
} from "semantic-ui-react";
import {
  CustomHeaderLabel,
  CustomLabel,
  CustomHeader,
  CustomHeaderBold,
  CustomTitle
} from "../../../components/labels/labels";
import PriceItem from "../product-details/fes/price-item";
import FesPrice from "../product-details/fes/price";
import AssumptionValue from "../product-details/fe/assumption-value";
import CancellationValue from "../product-details/fe/cancellation-value";

class QuoteAssumptions extends Component {
  state = {
    activeIndex: -1,
    isConfirming: false,
    toRemoveId: null
  };

  toggleDisplay = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  createMarkup(string) {
    return { __html: string };
  }

  render() {
    const { activeIndex } = this.state;
    const { data } = this.props;
    let assumption = AssumptionValue(null, data, true);
    let cancellation = CancellationValue(null, data);
    return (
      <Fragment>
        <Segment>
          <Accordion>
            <Accordion.Title
              active={activeIndex === 0}
              index={0}
              onClick={this.toggleDisplay}
            >
              <Grid columns={1}>
                <Grid.Column width={16}>
                  <Icon name="dropdown" />
                  <CustomHeaderLabel message="Quote Assumptions" />
                </Grid.Column>
              </Grid>
            </Accordion.Title>
            <Accordion.Content
              active={activeIndex === 0}
              className="print-expanded"
            >
              <Divider />
              <Form>
                <Grid>
                  <Grid.Column width={3}>
                    <p>
                      Please read this section in its entirety. If you have any
                      questions or concerns, please contact us at{" "}
                      <a href="tel:877.854.3577">1-877-854-3577</a>, prompt # 2
                      or <a href="mailto:field@ul.com">field@ul.com</a>
                    </p>
                  </Grid.Column>
                  <Grid.Column width={1}>
                    <Divider vertical />
                  </Grid.Column>
                  <Grid.Column width={12}>
                    <Table>
                      <Table.Body>
                        <Table.Row>
                          <Table.Cell>
                            <span
                              dangerouslySetInnerHTML={this.createMarkup(
                                assumption
                              )}
                            ></span>
                            <h4>Project Cancellation</h4>
                            <span
                              dangerouslySetInnerHTML={this.createMarkup(
                                cancellation
                              )}
                            ></span>
                          </Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </Grid.Column>
                </Grid>
              </Form>
            </Accordion.Content>
          </Accordion>
        </Segment>
      </Fragment>
    );
  }
}

export default QuoteAssumptions;
