import {
  FILE_NUMBERS_FAILURE,
  FILE_NUMBERS_REQUEST,
  FILE_NUMBERS_SUCCESS,
  FILE_NUMBERS_SORT,
  FILE_NUMBERS_PAGINATE,
  FILE_NUMBERS_SELECT,
  FILE_NUMBERS_SELECT_ALL,
  SELECT_FILE_VOLUME_MANU,
  SELECT_ALL_FILE_VOLUMES_MANU,
  CLEAN_ALL_FILTERS,
  CLEAR_SELECTION,
} from './file-volumes.type';

import orderTableFilter from '../../../../enums/order-table-filter';

const initialState = {
  hasError: false,
  message: '',
  isLoading: false,
  data: null,
  sortColumn: '',
  sortOrder: '',
  pageSize: 5,
  totalPages: 1,
  currentPage: 1,
  applicantNames: [],
  applicantAdds: [],
  applicantAccntNumbers: [],
  applicantPsns: [],
  applicantAccountNumbers: [],
  fileNumbers: [],
  volumes: [],
  ccns: [],
  selectedFileNumbers: [],
  selectedVolumes: [],
  selectedCcns: [],
  selectedApplicantNames: [],
  selectedApplicantAddress: [],
  selectedApplicantAccountNumbers: [],
  selectedItems: [],
  hasFilter: false,
  filters: [],
  isFilterInitial: true,
};

const getFilters = (data, filterIndex) => {
  var result = data.filters
    .find(x => x.filterType == filterIndex)
    .fileVolumeFilterObjectDetail.map(function (item) {
      return {
        text: item.text,
        isSelected: item.isSelected,
      };
    });

  return result;
};

const addFilterSelection = (data, payload) => {
  if (payload.isSelected) data.push(payload.data['text']);
  else data = data.filter(s => s !== payload.data['text']);

  return data;
};

const addAllFilterSelections = (data, currentFilters, payload) => {
  if (payload.isSelected) {
    data = [];
    currentFilters.forEach(filter => {
      data.push(filter.text);
    });
  } else data = [];

  return data;
};

const selectFileNumber = (data, payload) => {
  return data.map(item => {
    if (item.text !== payload.data['text']) return item;
    item.isSelected = payload.isSelected;
    return item;
  });
};

const selectAllFileNumbers = (data, payload) => {
  return data.map(item => {
    item.isSelected = payload.isSelected;
    return item;
  });
};

const getSelectedItems = selectedItems => {
  var result = [];

  // selectedItems
  //   .filter((d) => d.isSelected)
  //   .forEach((item) => {
  //     var row = result.filter(
  //       (f) =>
  //         f.fileNumber === item.fileNumber &&
  //         f.volume === item.volume &&
  //         f.ccn === item.ccn
  //     )[0];
  //     if (!row) {
  //       result.push({
  //         ccn: item.ccn,
  //         compCcns: item.compCcns,
  //         fileNumber: item.fileNumber,
  //         applicantName: item.applicantName,
  //         applicantAddress: item.applicantAddress,
  //         applicantAccount: item.applicantAccount,
  //         volume: item.volume
  //       });
  //     }
  //   });

  selectedItems
    .filter(d => d.isSelected)
    .forEach(item => {
      var row = result.filter(
        f =>
          f.fileNumber === item.fileNumber &&
          f.volume === item.volume &&
          f.ccn === item.ccn &&
          f.compCcns === item.compCcns &&
          f.productType === item.productType &&
          f.applicantAccount === item.applicantAccount &&
          f.applicantAddress === item.applicantAddress
      )[0];
      if (!row) {
        result.push({
          ccn: item.ccn,
          compCcns: item.compCcns,
          fileNumber: item.fileNumber,
          applicantName: item.applicantName,
          applicantAddress: item.applicantAddress,
          applicantAccount: item.applicantAccount,
          productType: item.productType,
          volume: item.volume,
        });
      }
    });

  return result;
};

const fileVolumesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FILE_NUMBERS_PAGINATE:
      return {
        ...state,
        currentPage: action.payload.activePage,
      };
    case FILE_NUMBERS_SORT:
      return {
        ...state,
        sortColumn: action.payload.sortColumn,
        sortOrder: action.payload.sortOrder,
      };
    case FILE_NUMBERS_REQUEST:
      return {
        ...state,
        hasError: false,
        isLoading: true,
      };
    case FILE_NUMBERS_SUCCESS:
      state.filters = action.payload.filters;

      if (state.hasFilter) {
        state.isFilterInitial = false;
      }

      if (action.payload.filters.length > 0) {
        state.fileNumbers = getFilters(action.payload, 1);

        state.volumes = getFilters(action.payload, 2);

        state.ccns = getFilters(action.payload, 3);

        state.applicantNames = getFilters(action.payload, 4);

        state.applicantAdds = getFilters(action.payload, 5);

        state.applicantAccntNumbers = getFilters(action.payload, 6);
      }

      if (action.firstload) {
        state.selectedItems = [];
      }

      state.data = action.payload.fileVolumeDetails
        ? action.payload.fileVolumeDetails.data
        : null;
      state.hasFileVolumeData = action.payload.hasFileVolumeData;

      // state.data.forEach((item, idx) => {
      //   item.id = idx;
      //   item.isSelected = false;
      // });

      if (state.data) {
        state.applicantPsns = [];
        state.applicantAccountNumbers = [];
        state.data.forEach((item, idx) => {
          item.id = idx;

          if (state.selectedItems && state.selectedItems.length > 0) {
            var selectedItem = state.selectedItems[0].filter(
              f =>
                f.fileNumber === item.fileNumber &&
                f.compCcns === item.compCcns &&
                f.ccn === item.ccn &&
                f.productType === item.productType &&
                f.applicantAccount === item.applicantAccount &&
                f.applicantAddress === item.applicantAddress &&
                f.volume === item.volume
            );

            item.isSelected =
              selectedItem && selectedItem.length > 0 ? true : false;
          } else {
            item.isSelected = false;
          }

          if (
            !state.applicantPsns.find(
              p => p.partySiteNumber === item.partySiteNumber
            )
          )
            state.applicantPsns.push({
              partySiteNumber: item.partySiteNumber,
              applicantName: item.applicantName,
            });

          if (
            !state.applicantAccountNumbers.find(
              p => p.applicantAccount === item.applicantAccount
            )
          )
            state.applicantAccountNumbers.push({
              applicantAccount: item.applicantAccount,
              applicantName: item.applicantName,
            });
        });
      }

      // if (state.selectedItems.length > 0) {
      //   state.selectedItems[0] = state.selectedItems[0].map((item) => {
      //     if (
      //       state.data.find(
      //         (x) =>
      //           x.fileNumber == item.fileNumber &&
      //           x.compCcns == item.compCcns &&
      //           x.ccn == item.ccn &&
      //           x.productType == item.productType &&
      //           x.applicantAccount == item.applicantAccount &&
      //           x.applicantAddress == item.applicantAddress &&
      //           x.volume == item.volume
      //       ) != null
      //     ) {
      //       return item;
      //     }
      //   });

      //   state.selectedItems[0] = state.selectedItems[0].filter((x) => x);
      // }

      return {
        ...state,
        hasError: false,
        totalPages: action.payload.fileVolumeDetails
          ? action.payload.fileVolumeDetails.totalPages
          : 0,
        isLoading: false,
      };
    case FILE_NUMBERS_FAILURE:
      return {
        ...state,
        message: action.payload,
        hasError: true,
        isLoading: false,
      };
    case FILE_NUMBERS_SELECT:
      const newState = { ...state };

      newState.hasFilter = true;

      let filterHeader = newState.filters.find(
        x => x.filterType == action.payload.activeFilterIndex
      );
      filterHeader.isSelectAll = false;
      filterHeader.isCurrentFilter = true;

      // filterHeader.fileVolumeFilterObjectDetail.forEach((item) => {
      //   item.isSelected = false;
      // });

      let currentItem = filterHeader.fileVolumeFilterObjectDetail.find(
        x => x.text == action.payload.data['text']
      );

      currentItem.isSelected = !currentItem.isSelected;

      switch (action.payload.activeFilterIndex) {
        case 1:
          newState.fileNumbers = filterHeader.fileVolumeFilterObjectDetail;
          break;
        case 2:
          newState.volumes = filterHeader.fileVolumeFilterObjectDetail;
          break;
        case 3:
          newState.ccns = filterHeader.fileVolumeFilterObjectDetail;
          break;
        case 4:
          newState.applicantNames = filterHeader.fileVolumeFilterObjectDetail;
          break;
        case 5:
          newState.applicantAdds = filterHeader.fileVolumeFilterObjectDetail;
          break;
        case 6:
          newState.applicantAccntNumbers =
            filterHeader.fileVolumeFilterObjectDetail;
          break;
        default:
          break;
      }

      return newState;
    case FILE_NUMBERS_SELECT_ALL:
      const newStateAll = { ...state };
      newStateAll.hasFilter = true;

      let filter = newStateAll.filters.find(
        x => x.filterType == action.payload.activeFilterIndex
      );

      filter.isSelectAll = true;

      return newStateAll;
    case SELECT_FILE_VOLUME_MANU:
      const newStateFileVols = { ...state };

      newStateFileVols.data = newStateFileVols.data.map(item => {
        if (item.id !== action.data.data.id) return item;
        item.isSelected = action.data.isSelected;
        return item;
      });

      // newStateFileVols.selectedItems = getSelectedItems(newStateFileVols.data);
      var selectedItems = getSelectedItems(newStateFileVols.data);

      if (selectedItems && selectedItems.length > 0) {
        if (
          newStateFileVols.selectedItems &&
          newStateFileVols.selectedItems.length == 0
        ) {
          newStateFileVols.selectedItems.push(selectedItems);
        } else {
          selectedItems.forEach(item => {
            var isExist = newStateFileVols.selectedItems[0].filter(
              f =>
                f.fileNumber === item.fileNumber &&
                f.compCcns === item.compCcns &&
                f.ccn === item.ccn &&
                f.productType === item.productType &&
                f.applicantAccount === item.applicantAccount &&
                f.applicantAddress === item.applicantAddress &&
                f.volume === item.volume
            )[0];
            if (!isExist) {
              newStateFileVols.selectedItems[0].push(item);
            } else {
              newStateFileVols.selectedItems[0].forEach(itm => {
                var data = newStateFileVols.data.filter(
                  f =>
                    f.fileNumber === itm.fileNumber &&
                    f.compCcns === itm.compCcns &&
                    f.ccn === itm.ccn &&
                    f.productType === itm.productType &&
                    f.applicantAccount === itm.applicantAccount &&
                    f.applicantAddress === itm.applicantAddress &&
                    f.volume === itm.volume
                )[0];
                if (!data.isSelected) {
                  newStateFileVols.selectedItems[0] =
                    newStateFileVols.selectedItems[0].filter(x => x != itm);
                }
              });
            }
          });
        }
      } else {
        newStateFileVols.data.forEach(item => {
          if (!item.isSelected)
            var data = newStateFileVols.selectedItems[0].filter(
              f =>
                f.fileNumber === item.fileNumber &&
                f.compCcns === item.compCcns &&
                f.ccn === item.ccn &&
                f.productType === item.productType &&
                f.applicantAccount === item.applicantAccount &&
                f.applicantAddress === item.applicantAddress &&
                f.volume === item.volume
            );
          newStateFileVols.selectedItems[0] =
            newStateFileVols.selectedItems[0].filter(x => x != data[0]);
        });
      }

      return newStateFileVols;
    case SELECT_ALL_FILE_VOLUMES_MANU:
      const newStateAllFileVols = { ...state };
      newStateAllFileVols.isSelectedAll = action.data.isSelectedAll;

      newStateAllFileVols.data = newStateAllFileVols.data.map(item => {
        item.isSelected = action.data.isSelectedAll;
        return item;
      });

      newStateAllFileVols.selectedItems[0] = getSelectedItems(
        newStateAllFileVols.data
      );

      return newStateAllFileVols;

    case CLEAN_ALL_FILTERS:
      const clearedFilterState = { ...state };
      clearedFilterState.isFilterInitial = true;
      clearedFilterState.hasFilter = false;

      return clearedFilterState;

    case CLEAR_SELECTION:
      const cleanSelectionState = { ...state };

      cleanSelectionState.selectedItems[0] = [];
      return cleanSelectionState;

    default:
      return state;
  }
};

export default fileVolumesReducer;
