export const CREATE_FIELD_SERVICE_REQUEST = "CREATE_FIELD_SERVICE_REQUEST";
export const CREATE_FIELD_SERVICE_SUCCESS = "CREATE_FIELD_SERVICE_SUCCESS";
export const CREATE_FIELD_SERVICE_FAILURE = "CREATE_FIELD_SERVICE_FAILURE";

export const UPDATE_FIELD_SERVICE_REQUEST = "UPDATE_FIELD_SERVICE_REQUEST";
export const UPDATE_FIELD_SERVICE_SUCCESS = "UPDATE_FIELD_SERVICE_SUCCESS";
export const UPDATE_FIELD_SERVICE_FAILURE = "UPDATE_FIELD_SERVICE_FAILURE";

export const RENDER_DYNAMIC_FORM = "RENDER_DYNAMIC_FORM";

export const COUNTRIES_PRELIM_PROJECT_REQUEST =
  "COUNTRIES_PRELIM_PROJECT_REQUEST";
export const COUNTRIES_PRELIM_PROJECT_FAILURE =
  "COUNTRIES_PRELIM_PROJECT_FAILURE";
export const COUNTRIES_PRELIM_PROJECT_SUCCESS =
  "COUNTRIES_PRELIM_PROJECT_SUCCESS";

export const CITIES_PRELIM_PROJECT_REQUEST = "CITIES_PRELIM_PROJECT_REQUEST";
export const CITIES_PRELIM_PROJECT_FAILURE = "CITIES_PRELIM_PROJECT_FAILURE";
export const CITIES_PRELIM_PROJECT_SUCCESS = "CITIES_PRELIM_PROJECT_SUCCESS";

export const STATES_PRELIM_PROJECT_REQUEST = "STATES_PRELIM_PROJECT_REQUEST";
export const STATES_PRELIM_PROJECT_FAILURE = "STATES_PRELIM_PROJECT_FAILURE";
export const STATES_PRELIM_PROJECT_SUCCESS = "STATES_PRELIM_PROJECT_SUCCESS";

export const COUNTRIES_FINAL_PROJECT_REQUEST =
  "COUNTRIES_FINAL_PROJECT_REQUEST";
export const COUNTRIES_FINAL_PROJECT_FAILURE =
  "COUNTRIES_FINAL_PROJECT_FAILURE";
export const COUNTRIES_FINAL_PROJECT_SUCCESS =
  "COUNTRIES_FINAL_PROJECT_SUCCESS";

export const CITIES_FINAL_PROJECT_REQUEST = "CITIES_FINAL_PROJECT_REQUEST";
export const CITIES_FINAL_PROJECT_FAILURE = "CITIES_FINAL_PROJECT_FAILURE";
export const CITIES_FINAL_PROJECT_SUCCESS = "CITIES_FINAL_PROJECT_SUCCESS";

export const STATES_FINAL_PROJECT_REQUEST = "STATES_FINAL_PROJECT_REQUEST";
export const STATES_FINAL_PROJECT_FAILURE = "STATES_FINAL_PROJECT_FAILURE";
export const STATES_FINAL_PROJECT_SUCCESS = "STATES_FINAL_PROJECT_SUCCESS";

export const FORM = "FORM";
export const CLEAR = "CLEAR";

export const QUOTE_DETAILS_REQUEST = "QUOTE_DETAILS_REQUEST";
export const QUOTE_DETAILS_SUCCESS = "QUOTE_DETAILS_SUCCESS";
export const QUOTE_DETAILS_FAILURE = "QUOTE_DETAILS_FAILURE";

export const FE_UPDATE_ORDER_TYPE_REQUEST = "FE_UPDATE_ORDER_TYPE_REQUEST";
export const FE_UPDATE_ORDER_TYPE_FAILURE = "FE_UPDATE_ORDER_TYPE_FAILURE";
export const FE_UPDATE_ORDER_TYPE_SUCCESS = "FE_UPDATE_ORDER_TYPE_SUCCESS";
export const FE_UPDATE_ORDER_TYPE_RESET = "FE_UPDATE_ORDER_TYPE_RESET";
