import XLSX from "xlsx";
/* generate an array of column objects */
export const make_cols = (refstr) => {
  let o = [],
    C = XLSX.utils.decode_range(refstr).e.c + 1;
  // for (var i = 0; i < C; ++i) o[i] = { name: XLSX.utils.encode_col(i), key: i };
  for (var i = 0; i < C; ++i) o[i] = XLSX.utils.encode_col(i) + i;
  return o;
};

export const get_header_row = (sheet) => {
  let headers = [];
  let range = XLSX.utils.decode_range(sheet["!ref"]);
  let C,
    R = range.s.r; /* start in the first row */
  /* walk every column in the range */
  for (C = range.s.c; C <= range.e.c; ++C) {
    let cell =
      sheet[
        XLSX.utils.encode_cell({ c: C, r: R })
      ]; /* find the cell in the first row */

    let hdr = "";
    if (cell && cell.t) hdr = XLSX.utils.format_cell(cell);

    headers.push(hdr);
  }
  return headers;
};
