import Role from '../../../enums/role';

export const controlKind = {
  dropdown: 'dropdown',
  textbox: 'textbox',
  radio: 'radio',
  checkbox: 'checkbox',
  date: 'date',
};

const statusOptions = [
  {
    key: 'Submitted',
    value: '19',
    text: 'Submitted',
    checked: false,
  },
  {
    key: 'InProgress',
    value: '2',
    text: 'In Progress',
    checked: false,
  },
  {
    key: 'Shipped',
    value: '21',
    text: 'On-hold',
    checked: false,
  },
  {
    key: 'Cancelled',
    value: '4',
    text: 'Cancelled',
    checked: false,
  },
  {
    key: 'Closed',
    value: '18',
    text: 'Closed',
    checked: false,
  },
];

const labelTypeOptions = [
  {
    key: '1',
    value: 1,
    text: 'Standard Label Order',
    checked: false,
  },
  {
    key: '2',
    value: 2,
    text: 'Combination Label Order',
    checked: false,
  },
];

const emailSentOptions = [
  {
    key: 'All',
    value: 'All',
    text: 'All',
    checked: false,
  },
  {
    key: 'No',
    value: 'No',
    text: 'No',
    checked: false,
  },
  {
    key: 'Yes',
    value: 'Yes',
    text: 'Yes',
    checked: false,
  },
];

const vendorLetterOptions = [
  {
    key: 'All',
    value: 'All',
    text: 'All',
    checked: false,
  },
  {
    key: 'No',
    value: 'No',
    text: 'No',
    checked: false,
  },
  {
    key: 'Yes',
    value: 'Yes',
    text: 'Yes',
    checked: false,
  },
];

const customStyles = {
  MainDivStyle: {
    padding: '20px',
  },
  FilterDisplayStyle: {
    marginRight: '10px',
  },
  DropdownInputStyle: {
    marginLeft: '10px',
  },
  TextboxInputStyle: {
    marginLeft: '10px',
  },
  FilterAddButtonStyle: {
    marginLeft: '20px',
  },
  DatePickerFormStyle: {
    marginLeft: '50px',
  },
  ToggleFieldStyle: {
    marginLeft: '40px',
  },
  RadioButtonFieldStyle: {
    marginLeft: '50px',
    marginTop: '10px',
  },
  SpanFieldStyle: {
    marginBottom: '5px',
  },
  ManufacturerTextboxStyle: {
    width: '250px',
  },
};

const isFieldHidden = (type, userRole) => {
  switch (type) {
    case 'isLabelCenterHidden':
      return userRole === Role.labelCenter ||
        userRole === Role.fieldEngineerRO ||
        userRole === Role.labelManager
        ? false
        : true;
    case 'isApplicantHidden':
      return userRole === (Role.manufacturer || Role.printer) ||
        userRole === Role.labelCenter ||
        userRole === Role.labelManager ||
        userRole === Role.fieldEngineerRO
        ? false
        : true;
    case 'isManufacturerHidden':
      return userRole === Role.applicant ||
        userRole === Role.labelCenter ||
        userRole === Role.labelManager ||
        userRole === Role.fieldEngineerRO
        ? false
        : true;
    case 'hasAdminRole':
      return userRole === Role.labelCenter ||
        userRole === Role.labelManager ||
        userRole === Role.fieldEngineerRO
        ? false
        : true;
    case 'isLabelSupplierFiltersHidden':
      return userRole === Role.labelCenter ||
        userRole === Role.fieldEngineerRO ||
        userRole === Role.labelManager
        ? false
        : true;
    case 'isVendorLetterSentHidden':
      return userRole === Role.labelCenter || userRole === Role.labelManager
        ? false
        : true;
    default:
      return false;
  }
};

export const filterOptionColumns = roleId => [
  {
    index: 0,
    title: 'Order Status',
    key: 'orderStatus',
    tokenIds: ['orderStatus'],
    hidden: false,
    type: controlKind.dropdown,
    source: statusOptions,
    customStyles: customStyles,
    hasMappingtoText: true,
    placeholder: [
      { tokenId: 'orderStatus', value: 'Select Order Status' },
      { tokenId: 'customerContactName', value: 'Input Customer Name' },
    ],
    isSingleSelection: false,
    isSourceFromProps: false,
  },
  {
    index: 1,
    title: 'Label Center',
    key: 'labelCenters',
    tokenIds: ['labelCenters'],
    hidden: isFieldHidden('isLabelCenterHidden', roleId),
    type: controlKind.dropdown,
    source: 'labelCentersOption',
    customStyles: customStyles,
    hasMappingtoText: false,
    placeholder: [{ tokenId: 'labelCenters', value: 'Select Label Center' }],
    isSingleSelection: false,
    isSourceFromProps: true,
  },
  {
    index: 2,
    title: 'Cart Order No',
    key: 'requestNo',
    tokenIds: ['requestNo'],
    hidden: false,
    type: controlKind.textbox,
    source: null,
    customStyles: customStyles,
    hasMappingtoText: false,
    placeholder: [{ tokenId: 'requestNo', value: 'Input Cart Number' }],
    isSingleSelection: false,
    isSourceFromProps: false,
  },
  {
    index: 3,
    title: 'Cart Order Date',
    key: 'cartOrderDate',
    tokenIds: ['startDate', 'endDate'],
    hidden: false,
    type: controlKind.date,
    source: null,
    customStyles: customStyles,
    hasMappingtoText: false,
    placeholder: [
      { tokenId: 'startDate', value: 'Start Date' },
      { tokenId: 'endDate', value: 'End Date' },
    ],
    isSingleSelection: false,
    isSourceFromProps: false,
  },
  {
    index: 4,
    title: 'Order No',
    key: 'oracleOrderNo',
    tokenIds: ['oracleOrderNo'],
    hidden: false,
    type: controlKind.textbox,
    source: null,
    customStyles: customStyles,
    hasMappingtoText: false,
    placeholder: [{ tokenId: 'oracleOrderNo', value: 'Input Order No.' }],
    isSingleSelection: false,
    isSourceFromProps: false,
  },
  {
    index: 5,
    title: 'Order Date',
    key: 'orderStartDate',
    tokenIds: ['orderStartDate', 'orderEndDate'],
    hidden: false,
    type: controlKind.date,
    source: null,
    customStyles: customStyles,
    hasMappingtoText: false,
    placeholder: [
      { tokenId: 'orderStartDate', value: 'Start Date' },
      { tokenId: 'orderEndDate', value: 'End Date' },
    ],
    isSingleSelection: false,
    isSourceFromProps: false,
  },
  {
    index: 6,
    title: 'Customer',
    key: 'customerPoNumber',
    tokenIds: ['customerPoNumber', 'customerContactName'],
    hidden: false,
    type: controlKind.textbox,
    source: null,
    customStyles: customStyles,
    hasMappingtoText: false,
    placeholder: [
      { tokenId: 'customerPoNumber', value: 'Input Customer PO No.' },
      { tokenId: 'customerContactName', value: 'Input Customer Name' },
    ],
    isSingleSelection: false,
    isSourceFromProps: false,
  },
  {
    index: 7,
    title: 'Applicant',
    key: 'companyAccountNos',
    tokenIds: ['companyAccountNos', 'companyName', 'applicantAddress'],
    hidden: isFieldHidden('isApplicantHidden', roleId),
    type: controlKind.textbox,
    source: null,
    customStyles: customStyles,
    hasMappingtoText: false,
    placeholder: [
      { tokenId: 'companyAccountNos', value: 'Input Applicant Account No.' },
      { tokenId: 'companyName', value: 'Input Applicant Name' },
      { tokenId: 'applicantAddress', value: 'Input Applicant Address' },
    ],
    isSingleSelection: false,
    isSourceFromProps: false,
  },
  {
    index: 8,
    title: 'Manufacturer',
    key: 'manufacturerAccountNos',
    tokenIds: [
      'manufacturerAccountNos',
      'manufacturer',
      'manufacturerAddress',
      'manufacturerPsn',
    ],
    hidden: isFieldHidden('isManufacturerHidden', roleId),
    type: controlKind.textbox,
    source: null,
    customStyles: customStyles,
    hasMappingtoText: false,
    placeholder: [
      {
        tokenId: 'manufacturerAccountNos',
        value: 'Input Manufacturer Account No.',
      },
      { tokenId: 'manufacturer', value: 'Input Manufacturer Name' },
      { tokenId: 'manufacturerAddress', value: 'Input Manufacturer Address' },
      { tokenId: 'manufacturerPsn', value: 'Input Manufacturer PSN' },
    ],
    isSingleSelection: false,
    isSourceFromProps: false,
  },
  {
    index: 9,
    title: 'File No',
    key: 'fileNo',
    tokenIds: ['fileNo'],
    hidden: false,
    type: controlKind.textbox,
    source: 'fileNumbersOption',
    customStyles: customStyles,
    hasMappingtoText: false,
    placeholder: [
      {
        tokenId: 'fileNo',
        value: 'Input File Number',
      },
    ],
    isSingleSelection: false,
    isSourceFromProps: true,
  },
  {
    index: 10,
    title: 'Expand Multiple Orders',
    key: 'isExpandMultipleOrders',
    tokenIds: ['isExpandMultipleOrders'],
    hidden: false,
    type: controlKind.checkbox,
    source: null,
    customStyles: customStyles,
    hasMappingtoText: false,
    placeholder: [
      {
        tokenId: 'isExpandMultipleOrders',
        value: 'Expand Multiple Orders',
      },
    ],
    isSingleSelection: false,
    isSourceFromProps: false,
  },
  {
    index: 11,
    title: 'Label Type',
    key: 'labelOrderType',
    tokenIds: ['labelOrderType'],
    hidden: roleId === Role.printer,
    type: controlKind.radio,
    source: labelTypeOptions,
    customStyles: customStyles,
    hasMappingtoText: false,
    placeholder: [
      {
        tokenId: 'labelOrderType',
        value: 'Label Type',
      },
    ],
    isSingleSelection: false,
    isSourceFromProps: false,
    radioButtonGroup: 'radioGroupLabelType',
  },
  {
    index: 12,
    title: 'Re-push Orders',
    key: 'isRepushOrders',
    tokenIds: ['isRepushOrders'],
    hidden: isFieldHidden('hasAdminRole', roleId),
    type: controlKind.checkbox,
    source: null,
    customStyles: customStyles,
    hasMappingtoText: false,
    placeholder: [
      {
        tokenId: 'isRepushOrders',
        value: 'Re-push Orders',
      },
    ],
    isSingleSelection: false,
    isSourceFromProps: false,
  },
  {
    index: 13,
    title: 'Email Sent',
    key: 'emailSent',
    tokenIds: ['emailSent'],
    hidden: isFieldHidden('hasAdminRole', roleId),
    type: controlKind.dropdown,
    source: emailSentOptions,
    customStyles: customStyles,
    hasMappingtoText: false,
    placeholder: [{ tokenId: 'emailSent', value: 'Email Sent' }],
    isSingleSelection: true,
    isSourceFromProps: false,
  },
  {
    index: 14,
    title: 'Re-order',
    key: 'isCopied',
    tokenIds: ['isCopied'],
    hidden: isFieldHidden('hasAdminRole', roleId),
    type: controlKind.checkbox,
    source: null,
    customStyles: customStyles,
    hasMappingtoText: false,
    placeholder: [
      {
        tokenId: 'isCopied',
        value: 'Re-order',
      },
    ],
    isSingleSelection: true,
    isSourceFromProps: false,
  },
  {
    index: 15,
    title: 'Label Supplier',
    key: 'labelSupplierCompanyName',
    tokenIds: [
      'labelSupplierCompanyName',
      'labelSupplierFileNumber',
      'labelSupplierPartySiteNumber',
    ],
    hidden: isFieldHidden('isLabelSupplierFiltersHidden', roleId),
    type: controlKind.textbox,
    source: null,
    customStyles: customStyles,
    hasMappingtoText: false,
    placeholder: [
      {
        tokenId: 'labelSupplierCompanyName',
        value: 'Label Supplier Company Name',
      },
      {
        tokenId: 'labelSupplierFileNumber',
        value: 'Label Supplier File Number',
      },
      {
        tokenId: 'labelSupplierPartySiteNumber',
        value: 'Label Supplier PSN',
      },
    ],
    isSingleSelection: false,
    isSourceFromProps: false,
  },
  {
    index: 16,
    title: 'Label Agent',
    key: 'labelAgents',
    tokenIds: ['labelAgents'],
    hidden: false,
    type: controlKind.textbox,
    source: null,
    customStyles: customStyles,
    hasMappingtoText: false,
    placeholder: [
      {
        tokenId: 'labelAgents',
        value: 'Label Agent',
      },
    ],
    isSingleSelection: false,
    isSourceFromProps: false,
  },
  {
    index: 17,
    title: 'Vendor letter sent to customer',
    key: 'vendorLetterSentToCustomer',
    tokenIds: ['vendorLetterSentToCustomer'],
    hidden: isFieldHidden('isVendorLetterSentHidden', roleId),
    type: controlKind.dropdown,
    source: vendorLetterOptions,
    customStyles: customStyles,
    hasMappingtoText: false,
    placeholder: [
      {
        tokenId: 'vendorLetterSentToCustomer',
        value: 'Select Filter',
      },
    ],
    isSingleSelection: true,
    isSourceFromProps: false,
  },
  {
    index: 18,
    title: 'Vendor letter sent to supplier',
    key: 'vendorLetterSentToSupplier',
    tokenIds: ['vendorLetterSentToSupplier'],
    hidden: isFieldHidden('isVendorLetterSentHidden', roleId),
    type: controlKind.dropdown,
    source: vendorLetterOptions,
    customStyles: customStyles,
    hasMappingtoText: false,
    placeholder: [
      {
        tokenId: 'vendorLetterSentToSupplier',
        value: 'Select Filter',
      },
    ],
    isSingleSelection: true,
    isSourceFromProps: false,
  },
];
