import * as types from './existing-combination-labels.type';
import api from '../../../services/api';
import merge from 'deepmerge';

const addExistingCombinationLabelsRequest = () => {
  return { type: types.COMBINATION_ADD_EXISTING_LABEL_ORDER_TO_CART_REQUEST };
};

const addExistingCombinationLabelsFailure = error => {
  return dispatch =>
    dispatch({
      type: types.COMBINATION_ADD_EXISTING_LABEL_ORDER_TO_CART_FAILURE,
      payload: error,
    });
};

const addExistingCombinationLabelsSuccess = response => {
  return dispatch =>
    dispatch({
      type: types.COMBINATION_ADD_EXISTING_LABEL_ORDER_TO_CART_SUCCESS,
      payload: response,
    });
};

export const addExistingCombinationLabelsToCart = data => {
  return async dispatch => {
    dispatch(addExistingCombinationLabelsRequest());
    const result = await api.LabelOrderCart.addExistingCombinationLabels(data);

    if (result) {
      dispatch(addExistingCombinationLabelsSuccess(result));
    } else {
      dispatch(addExistingCombinationLabelsFailure('Something went wrong.'));
    }
  };
};

const existingCombinationLabelsRequest = () => {
  return { type: types.COMBINATION_GET_EXISTING_LABEL_ORDER_REQUEST };
};

const existingCombinationLabelsFailure = error => {
  return dispatch =>
    dispatch({
      type: types.COMBINATION_GET_EXISTING_LABEL_ORDER_FAILURE,
      payload: error,
    });
};

const existingCombinationLabelsSuccess = (response, data) => {
  return dispatch =>
    dispatch({
      type: types.COMBINATION_GET_EXISTING_LABEL_ORDER_SUCCESS,
      payload: { response, data },
    });
};

const existingCombinationLabelsPaginate = (currentPage, pageSize) => {
  return {
    type: types.COMBINATION_GET_EXISTING_LABEL_ORDER_PAGINATE,
    payload: { currentPage, pageSize },
  };
};

const existingCombinationLabelsSort = (sortColumn, sortOrder) => {
  return {
    type: types.COMBINATION_GET_EXISTING_LABEL_ORDER_SORT,
    payload: { sortColumn, sortOrder },
  };
};

export const getExistingCombinationLabels = (
  fileNumber,
  itemNumber,
  applicantAccount,
  manufacturerAccount,
  currentPage,
  pageSize,
  id,
  isCopied
) => {
  return async dispatch => {
    dispatch(existingCombinationLabelsRequest());
    // dispatch(existingCombinationLabelsSort(sortColumn, sortOrder));
    // dispatch(existingCombinationLabelsPaginate(currentPage, pageSize));
    const result = await api.Order.getExistingLabelOrders(
      fileNumber,
      itemNumber,
      applicantAccount,
      manufacturerAccount,
      currentPage,
      pageSize
    );

    if (result) {
      dispatch(
        existingCombinationLabelsSuccess(result, { id, isCopied, fileNumber })
      );
      return result;
    } else {
      dispatch(existingCombinationLabelsFailure('Something went wrong.'));
    }
  };
};

export const initialize = () => {
  return { type: types.COMBINATION_EXISTING_LABEL_INITIALIZE };
};

export const updateExistingCombinationLabelFilters = (name, value) => {
  return {
    type: types.COMBINATION_UPDATE_FILTER,
    payload: { name, value },
  };
};

export const getCombinationLabelsByItemNumber = (
  itemNumber,
  id,
  fileNumber,
  ccn,
  labelAccount,
  volume,
  formIndex
) => {
  return dispatch =>
    dispatch({
      type: types.GET_COMBINATION_LABELS_BY_ITEM_NUMBER,
      payload: {
        itemNumber,
        id,
        fileNumber,
        ccn,
        labelAccount,
        volume,
        formIndex,
      },
    });
};

export const updateSelectedLabel = (idx, form, forms) => {
  const newForms = forms.slice(0);
  let newForm = forms[idx];

  if (!newForm) {
    newForms.push(form);
  } else {
    newForms[idx] = merge(newForm, form);
  }

  return dispatch =>
    dispatch({
      type: types.UPDATE_SELECTED_LABEL,
      payload: newForms,
    });
};

export const updateCombinationLabelsForm = (data, form) => {
  const newForm = merge(data, form);

  return dispatch =>
    dispatch({
      type: types.UPDATE_COMBINATION_LABELS_FORM,
      payload: newForm,
    });
};

export const pushSelectedLabel = formIndex => {
  return dispatch =>
    dispatch({
      type: types.PUSH_SELECTED_LABEL,
      payload: { formIndex },
    });
};

export const removeSelectedLabel = (formIndex, form, forms) => {
  const newForms = forms.slice(0);
  let newForm = forms[formIndex];

  if (!newForm) {
    newForms.push(form);
  } else {
    newForms[formIndex] = merge(form.itemList, newForm);
  }

  return dispatch =>
    dispatch({
      type: types.REMOVE_SELECTED_LABEL,
      payload: { newForms, formIndex },
    });
};

export const updateItemList = (id, name, value) => {
  return {
    type: types.UPDATE_ITEM_LIST,
    payload: { id, value, name },
  };
};

export const updateAttachment = (name, value, id, formIndex) => {
  return {
    type: types.UPDATE_ATTACHMENT,
    payload: { value, name, id, formIndex },
  };
};

export const uploadCombinationLabelAttachment = data => {
  return async dispatch => {
    await api.LabelOrderCartItem.uploadCombinationLabelAttachment(data);
  };
};

export const getLabelsByCcn = data => {
  return async dispatch => {
    dispatch({
      type: types.SET_PRODUCT_COVERED_REQUEST,
    });
    const result = await api.LabelOrder.getLabelsByCcn(data);

    if (result) {
      dispatch({
        type: types.SET_PRODUCT_COVERED_SUCCESS,
        payload: { result },
      });
    } else {
      dispatch({
        type: types.SET_PRODUCT_COVERED_FAILURE,
      });
    }
  };
};

export const resetItemNumberOptions = () => {
  return {
    type: types.ITEM_NUMBER_OPTIONS_RESET,
    payload: null,
  };
};

export const getListeeFileNumbers = (fileNumber, id, isCopied) => {
  return async dispatch => {
    dispatch({
      type: types.LISTEE_FILE_NUMBER_REQUEST,
    });
    const result = await api.Order.getListeeFileNumbers(fileNumber);

    if (result) {
      let data = { id, isCopied };
      dispatch({
        type: types.LISTEE_FILE_NUMBER_SUCCESS,
        payload: { result, data },
      });
    } else {
      dispatch({
        type: types.LISTEE_FILE_NUMBER_FAILURE,
      });
    }
  };
};

export const cancelNewLine = formIndex => {
  return {
    type: types.CANCEL_NEW_LINE,
    payload: { formIndex },
  };
};

export const getExistingLabelOrdersByCartId = labelOrderCartId => {
  return async dispatch => {
    dispatch({
      type: types.EXISTING_COMBINATION_BY_CART_ID_REQUEST,
    });
    const result = await api.Order.getExistingLabelOrdersByCartId(
      labelOrderCartId
    );

    if (result) {
      dispatch({
        type: types.EXISTING_COMBINATION_BY_CART_ID_SUCCESS,
        payload: { result },
      });
      return result;
    } else {
      dispatch({
        type: types.EXISTING_COMBINATION_BY_CART_ID_FAILURE,
      });
    }
  };
};

export const scanFile = data => {
  return async dispatch => {
    dispatch({
      type: types.SCAN_FILE_REQUEST,
    });
    const result = await api.Order.scanFile(data);

    if (result) {
      dispatch({
        type: types.SCAN_FILE_SUCCESS,
        payload: { result },
      });
      return result;
    } else {
      dispatch({
        type: types.SCAN_FILE_FAILURE,
      });
    }
  };
};

export const getHologramCcns = ccn => {
  return async dispatch => {
    dispatch({
      type: types.GET_HOLOGRAMCCNS_REQUEST,
    });
    const result = await api.LabelOrder.getHologramCcns(ccn);

    if (result) {
      dispatch({
        type: types.GET_HOLOGRAMCCNS_SUCCESS,
        payload: { result },
      });
      return result;
    } else {
      dispatch({
        type: types.GET_HOLOGRAMCCNS_FAILED,
      });
    }
  };
};

export const getLabelSupplierPrinterEmail = (printerFileNumber, printerPsn) => {
  return async dispatch => {
    dispatch({
      type: types.GET_LABEL_SUPPLIER_PRINTER_EMAIL_REQUEST,
    });
    const result = await api.LabelSupplier.getLabelSupplierPrinterEmail(
      printerFileNumber,
      printerPsn
    );

    if (result) {
      dispatch({
        type: types.GET_LABEL_SUPPLIER_PRINTER_EMAIL_SUCCESS,
        payload: { result },
      });
      return result;
    } else {
      dispatch({
        type: types.GET_LABEL_SUPPLIER_PRINTER_EMAIL_FAILED,
      });
    }
  };
};

export const getCombinationLabelsByPartNumber = (
  customerPartNumber,
  id,
  fileNumber,
  ccn,
  labelAccount,
  volume,
  formIndex
) => {
  return dispatch =>
    dispatch({
      type: types.GET_COMBINATION_LABELS_BY_PART_NUMBER,
      payload: {
        customerPartNumber,
        id,
        fileNumber,
        ccn,
        labelAccount,
        volume,
        formIndex,
      },
    });
};
