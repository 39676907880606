import * as types from "./quote-sites.type";

const quoteSitesResult = (obj, type) => {
  return {
    data: obj,
    type: type
  };
};
export const updateSitesCount = (count) => {
  return async (dispatch) => {
    dispatch(quoteSitesResult(count, types.UPDATE_SITES_COUNT));
  };
};
export const updateSameDayVisit = (toggle) => {
  return async (dispatch) => {
    dispatch(quoteSitesResult(toggle, types.UPDATE_SAMEDAY_VISIT));
  };
};

export const clearQuoteSites = () => (dispatch) =>
    dispatch(quoteSitesResult({}, types.CLEAR_QUOTE_SITES));
