export const LABEL_ORDER_CART_CHANGE_VALUE = "LABEL_ORDER_CART_CHANGE_VALUE";

export const LABEL_ORDER_CART_CONFIRM_SAVE_ORDER_REQUEST =
  "LABEL_ORDER_CART_CONFIRM_SAVE_ORDER_REQUEST";
export const LABEL_ORDER_CART_CONFIRM_SAVE_ORDER_ACCEPT =
  "LABEL_ORDER_CART_CONFIRM_SAVE_ORDER_ACCEPT";
export const LABEL_ORDER_CART_CONFIRM_SAVE_ORDER_REJECT =
  "LABEL_ORDER_CART_CONFIRM_SAVE_ORDER_REJECT";

export const LABEL_ORDER_CART_GET_CUSTOMER_BILL_TO_ADDRESSES_REQUEST =
  "LABEL_ORDER_CART_GET_CUSTOMER_BILL_TO_ADDRESSES_REQUEST";
export const LABEL_ORDER_CART_GET_CUSTOMER_BILL_TO_ADDRESSES_SUCCESS =
  "LABEL_ORDER_CART_GET_CUSTOMER_BILL_TO_ADDRESSES_SUCCESS";
export const LABEL_ORDER_CART_GET_CUSTOMER_BILL_TO_ADDRESSES_FAILURE =
  "LABEL_ORDER_CART_GET_CUSTOMER_BILL_TO_ADDRESSES_FAILURE";

export const LABEL_ORDER_CART_GET_CUSTOMER_INFO_REQUEST =
  "LABEL_ORDER_CART_GET_CUSTOMER_INFO_REQUEST";
export const LABEL_ORDER_CART_GET_CUSTOMER_INFO_SUCCESS =
  "LABEL_ORDER_CART_GET_CUSTOMER_INFO_SUCCESS";
export const LABEL_ORDER_CART_GET_CUSTOMER_INFO_FAILURE =
  "LABEL_ORDER_CART_GET_CUSTOMER_INFO_FAILURE";

export const LABEL_ORDER_CART_GET_CUSTOMER_SHIP_TO_ADDRESSES_REQUEST =
  "LABEL_ORDER_CART_GET_CUSTOMER_SHIP_TO_ADDRESSES_REQUEST";
export const LABEL_ORDER_CART_GET_CUSTOMER_SHIP_TO_ADDRESSES_SUCCESS =
  "LABEL_ORDER_CART_GET_CUSTOMER_SHIP_TO_ADDRESSES_SUCCESS";
export const LABEL_ORDER_CART_GET_CUSTOMER_SHIP_TO_ADDRESSES_FAILURE =
  "LABEL_ORDER_CART_GET_CUSTOMER_SHIP_TO_ADDRESSES_FAILURE";

export const LABEL_ORDER_CART_GET_LABEL_CENTERS_REQUEST =
  "LABEL_ORDER_CART_GET_LABEL_CENTERS_REQUEST";
export const LABEL_ORDER_CART_GET_LABEL_CENTERS_SUCCESS =
  "LABEL_ORDER_CART_GET_LABEL_CENTERS_SUCCESS";
export const LABEL_ORDER_CART_GET_LABEL_CENTERS_FAILURE =
  "LABEL_ORDER_CART_GET_LABEL_CENTERS_FAILURE";

export const LABEL_ORDER_CART_GET_LABEL_ORDER_CART_REQUEST =
  "LABEL_ORDER_CART_GET_LABEL_ORDER_CART_REQUEST";
export const LABEL_ORDER_CART_GET_LABEL_ORDER_CART_SUCCESS =
  "LABEL_ORDER_CART_GET_LABEL_ORDER_CART_SUCCESS";
export const LABEL_ORDER_CART_GET_LABEL_ORDER_CART_FAILURE =
  "LABEL_ORDER_CART_GET_LABEL_ORDER_CART_FAILURE";

export const LABEL_ORDER_CART_GET_LABEL_SUPPLIERS_REQUEST =
  "LABEL_ORDER_CART_GET_LABEL_SUPPLIERS_REQUEST";
export const LABEL_ORDER_CART_GET_LABEL_SUPPLIERS_SUCCESS =
  "LABEL_ORDER_CART_GET_LABEL_SUPPLIERS_SUCCESS";
export const LABEL_ORDER_CART_GET_LABEL_SUPPLIERS_FAILURE =
  "LABEL_ORDER_CART_GET_LABEL_SUPPLIERS_FAILURE";

export const LABEL_ORDER_CART_GET_UNITS_OF_MEASUREMENT_REQUEST =
  "LABEL_ORDER_CART_GET_UNITS_OF_MEASUREMENT_REQUEST";
export const LABEL_ORDER_CART_GET_UNITS_OF_MEASUREMENT_SUCCESS =
  "LABEL_ORDER_CART_GET_UNITS_OF_MEASUREMENT_SUCCESS";
export const LABEL_ORDER_CART_GET_UNITS_OF_MEASUREMENT_FAILURE =
  "LABEL_ORDER_CART_GET_UNITS_OF_MEASUREMENT_FAILURE";

export const LABEL_ORDER_CART_INITIALIZE = "LABEL_ORDER_CART_INITIALIZE";

export const LABEL_ORDER_CART_ITEM_CHANGE_VALUE =
  "LABEL_ORDER_CART_ITEM_CHANGE_VALUE";

export const LABEL_ORDER_CART_REMOVE_ITEM_REQUEST =
  "LABEL_ORDER_CART_REMOVE_ITEM_REQUEST";
export const LABEL_ORDER_CART_REMOVE_ITEM_SUCCESS =
  "LABEL_ORDER_CART_REMOVE_ITEM_SUCCESS";
export const LABEL_ORDER_CART_REMOVE_ITEM_FAILURE =
  "LABEL_ORDER_CART_REMOVE_ITEM_FAILURE";

export const LABEL_ORDER_CART_REMOVE_REMOVE_ITEM_MESSAGE =
  "LABEL_ORDER_CART_REMOVE_REMOVE_ITEM_MESSAGE";

export const LABEL_ORDER_CART_REMOVE_SAVE_ORDER_MESSAGE =
  "LABEL_ORDER_CART_REMOVE_SAVE_ORDER_MESSAGE";

export const LABEL_ORDER_CART_UPDATE_REQUEST =
  "LABEL_ORDER_CART_UPDATE_REQUEST";
export const LABEL_ORDER_CART_UPDATE_SUCCESS =
  "LABEL_ORDER_CART_UPDATE_SUCCESS";
export const LABEL_ORDER_CART_UPDATE_FAILURE =
  "LABEL_ORDER_CART_UPDATE_FAILURE";
export const LABEL_ORDER_CART_UPDATE_RESET = "LABEL_ORDER_CART_UPDATE_RESET";

export const LABEL_ORDER_CART_ITEM_UPLOAD_ATTACHMENT_REQUEST =
  "LABEL_ORDER_CART_ITEM_UPLOAD_ATTACHMENT_REQUEST";
export const LABEL_ORDER_CART_ITEM_UPLOAD_ATTACHMENT_SUCCESS =
  "LABEL_ORDER_CART_ITEM_UPLOAD_ATTACHMENT_SUCCESS";
export const LABEL_ORDER_CART_ITEM_UPLOAD_ATTACHMENT_FAILURE =
  "LABEL_ORDER_CART_ITEM_UPLOAD_ATTACHMENT_FAILURE";

export const FORM = "FORM";
export const CLEAR_FORM = "CLEAR_FORM";
