import { combineReducers } from 'redux';
import register from '../views/register/register.reducer';
import dashboard from '../views/dashboard/dashboard.reducer';
import fileVolumes from '../views/manufacturer/standard/file-volumes/file-volumes.reducer';
import labels from '../views/manufacturer/standard/labels/labels.reducer';
import labelCart from '../views/manufacturer/standard/label-cart/label-cart.reducer';
import viewOrders from '../views/view-orders/view-orders.reducer';
import applicantDashboard from '../views/applicant/standard/applicant-dashboard.reducer';
import language from './language-reducer';
import existingCombinationLabels from '../views/order/combination/existing-combination-labels.reducer';
import labelOrderCart from '../views/label-order-cart/cart/label-order-cart.reducer';
import labelOrderDetails from './labelOrder/labelOrderDetailsReducer';
import labelOrderCartReview from '../views/label-order-cart/cart-review/label-order-cart-review.reducer';
import login from '../views/login/login.reducer';
import user from '../views/user';
import cancelOrder from '../views/order/cancel-order/cancel-order.reducer';
import adminSearch from '../views/admin-search/admin-search.reducer';
import fieldEvaluation from '../views/field-evaluation/field-evaluation.reducer';
import viewHistory from '../views/field-evaluation/view-history/view-history.reducer';
//import viewHistory from "../views/field-evaluation/field-service/view-history/view-history.reducer";
import viewDraft from '../views/field-evaluation/view-draft/view-draft.reducer';
import viewDraftQuote from '../views/field-evaluation/view-draft-quote/view-draft-quote.reducer';
import viewOrderDetails from '../views/field-evaluation/view-order-details/view-order-details.reducer';
import viewQuoteDetails from '../views/field-evaluation/view-quote-details/view-quote-details.reducer';
import feCancelOrder from '../views/field-evaluation/cancel-order/cancel-order.reducer';
import fesProductDetails from '../views/field-evaluation/product-details/fes/product-details.reducer';
import feProductDetails from '../views/field-evaluation/product-details/fe/product-details.reducer';
import roleAssignment from '../views/role-assignment/role-assignment.reducer';
import auth from '../views/auth/auth.reducer';
import accountSettings from '../views/field-evaluation/account-settings/account-settings.reducer';
import notifications from '../views/field-evaluation/notifications/notifications.reducer';
import viewAssignedOrders from '../views/field-evaluation/view-assigned/view-assigned.reducer';
import feAdminSearch from '../views/field-evaluation/fe-admin-search/fe-admin-search.reducer';
import quoteSites from '../views/field-evaluation/field-service/quote-sites.reducer';
import fieldService from '../views/field-evaluation/field-service/new-quote-details/new-quote-details.reducer';
import feProjectDetails from '../views/field-evaluation/field-service/project-details/project-details.reducer';
import schedulingDetails from '../views/field-evaluation/field-service/scheduling-details/scheduling-details.reducer';
import adminPage from '../views/help-me/admin/admin-page.reducer';
import helpMe from '../views/help-me/help-me.reducer';
import healthDashBoard from '../views/health-dashboard/health-dashboard.reducer';
import priceManagement from '../views/price-management/price-management.reducer';
import callback from '../views/callback/callback.reducer';
import combinationLabelSearch from '../views/combination-label-search/combination-label-search.reducer';
import sidebar from './../components/sidebar/sidebar-menu.reducer';
const rootReducer = combineReducers({
  auth,
  login,
  register,
  language,
  dashboard,
  existingCombinationLabels,
  labelOrderCart,
  labelOrderDetails,
  labelOrderCartReview,
  fileVolumes,
  labels,
  labelCart,
  viewOrders,
  applicantDashboard,
  user,
  cancelOrder,
  adminSearch,
  fieldEvaluation,
  viewHistory,
  viewDraft,
  viewQuoteDetails,
  viewDraftQuote,
  viewOrderDetails,
  feCancelOrder,
  fesProductDetails,
  feProductDetails,
  roleAssignment,
  accountSettings,
  notifications,
  viewAssignedOrders,
  feAdminSearch,
  quoteSites,
  fieldService,
  feProjectDetails,
  schedulingDetails,
  adminPage,
  helpMe,
  healthDashBoard,
  priceManagement,
  callback,
  sidebar,
  combinationLabelSearch,
});

export default rootReducer;
