export const VIEW_HISTORY_FORM = "VIEW_HISTORY_FORM";
export const VIEW_HISTORY_CLEAR = "VIEW_HISTORY_CLEAR";
export const DATA = "DATA";

export const FE_ORDER_HISTORY_REQUEST = "FE_ORDER_HISTORY_REQUEST";
export const FE_ORDER_HISTORY_FAILURE = "FE_ORDER_HISTORY_FAILURE";
export const FE_ORDER_HISTORY_SUCCESS = "FE_ORDER_HISTORY_SUCCESS";

export const FE_ORDER_HISTORY_BY_FILTER_REQUEST =
  "FE_ORDER_HISTORY_BY_FILTER_REQUEST";
export const FE_ORDER_HISTORY_BY_FILTER_FAILURE =
  "FE_ORDER_HISTORY_BY_FILTER_FAILURE";
export const FE_ORDER_HISTORY_BY_FILTER_SUCCESS =
  "FE_ORDER_HISTORY_BY_FILTER_SUCCESS";

export const FE_ORDER_HISTORY_BY_FILTER_BY_USER_REQUEST =
  "FE_ORDER_HISTORY_BY_FILTER_BY_USER_REQUEST";
export const FE_ORDER_HISTORY_BY_FILTER_BY_USER_FAILURE =
  "FE_ORDER_HISTORY_BY_FILTER_BY_USER_FAILURE";
export const FE_ORDER_HISTORY_BY_FILTER_BY_USER_SUCCESS =
  "FE_ORDER_HISTORY_BY_FILTER_BY_USER_SUCCESS";

export const FE_ORDER_CHANGE_PAGE_REQUEST = "FE_ORDER_CHANGE_PAGE_REQUEST";
export const FE_ORDER_CHANGE_PAGE_SUCCESS = "FE_ORDER_CHANGE_PAGE_SUCCESS";
export const FE_ORDER_CHANGE_PAGE_FAILURE = "FE_ORDER_CHANGE_PAGE_FAILURE";

export const FE_UPDATE_ORDER_TYPE_REQUEST = "FE_UPDATE_ORDER_TYPE_REQUEST";
export const FE_UPDATE_ORDER_TYPE_FAILURE = "FE_UPDATE_ORDER_TYPE_FAILURE";
export const FE_UPDATE_ORDER_TYPE_SUCCESS = "FE_UPDATE_ORDER_TYPE_SUCCESS";
export const FE_UPDATE_ORDER_TYPE_RESET = "FE_UPDATE_ORDER_TYPE_RESET";

export const FE_ORDER_GET_PROJECTHANDLER_REQUEST =
  "FE_ORDER_GET_PROJECTHANDLER_REQUEST";
export const FE_ORDER_GET_PROJECTHANDLER_SUCCESS =
  "FE_ORDER_GET_PROJECTHANDLER_SUCCESS";
export const FE_ORDER_GET_PROJECTHANDLER_FAILURE =
  "FE_ORDER_GET_PROJECTHANDLER_FAILURE";

export const FE_ORDER_ASSIGN_PROJECTHANDLER_REQUEST =
  "FE_ORDER_ASSIGN_PROJECTHANDLER_REQUEST";
export const FE_ORDER_ASSIGN_PROJECTHANDLER_SUCCESS =
  "FE_ORDER_ASSIGN_PROJECTHANDLER_SUCCESS";
export const FE_ORDER_ASSIGN_PROJECTHANDLER_FAILURE =
  "FE_ORDER_ASSIGN_PROJECTHANDLER_FAILURE";
export const FE_ORDER_ASSIGN_PROJECTHANDLER_RESET =
  "FE_ORDER_ASSIGN_PROJECTHANDLER_RESET";

export const FE_ORDER_UNASSIGN_PROJECTHANDLER_REQUEST =
  "FE_ORDER_UNASSIGN_PROJECTHANDLER_REQUEST";
export const FE_ORDER_UNASSIGN_PROJECTHANDLER_SUCCESS =
  "FE_ORDER_UNASSIGN_PROJECTHANDLER_SUCCESS";
export const FE_ORDER_UNASSIGN_PROJECTHANDLER_FAILURE =
  "FE_ORDER_UNASSIGN_PROJECTHANDLER_FAILURE";

export const SET_FILTEROPTION_REQUEST = "SET_FILTEROPTION_REQUEST";
export const SET_FILTEROPTION_SUCCESS = "SET_FILTEROPTION_SUCCESS";
export const SET_FILTEROPTION_FAILURE = "SET_FILTEROPTION_FAILURE";

export const GET_FILTEROPTION_REQUEST = "GET_FILTEROPTION_REQUEST";
export const GET_FILTEROPTION_SUCCESS = "GET_FILTEROPTION_SUCCESS";
export const GET_FILTEROPTION_FAILURE = "GET_FILTEROPTION_FAILURE";

export const FILTEROPTION_RESET = "FILTEROPTION_RESET";

export const FE_ORDER_GET_APPLICANT_REQUEST = "FE_ORDER_GET_APPLICANT_REQUEST";
export const FE_ORDER_GET_APPLICANT_SUCCESS = "FE_ORDER_GET_APPLICANT_SUCCESS";
export const FE_ORDER_GET_APPLICANT_FAILURE = "FE_ORDER_GET_APPLICANT_FAILURE";

export const FE_ORDER_GET_CITIES_REQUEST = "FE_ORDER_GET_CITIES_REQUEST";
export const FE_ORDER_GET_CITIES_SUCCESS = "FE_ORDER_GET_CITIES_SUCCESS";
export const FE_ORDER_GET_CITIES_FAILURE = "FE_ORDER_GET_CITIES_FAILURE";

export const FE_ORDER_GET_STATES_REQUEST = "FE_ORDER_GET_STATES_REQUEST";
export const FE_ORDER_GET_STATES_SUCCESS = "FE_ORDER_GET_STATES_SUCCESS";
export const FE_ORDER_GET_STATES_FAILURE = "FE_ORDER_GET_STATES_FAILURE";
