import React, { Component, Fragment } from "react";
import {
  Accordion,
  Grid,
  Icon,
  Divider,
  Segment,
  Table,
} from "semantic-ui-react";
import { CustomHeaderLabel } from "../../../../components/labels/labels";

class GlobalServicesAgreement extends Component {
  state = {
    activeIndex: -1,
    isConfirming: false,
    toRemoveId: null,
  };

  toggleDisplay = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  render() {
    const { activeIndex } = this.state;
    const { data } = this.props;

    return (
      <Fragment>
        <Segment>
          <Accordion>
            <Accordion.Title
              active={activeIndex === 0}
              index={0}
              onClick={this.toggleDisplay}
            >
              <Grid columns={1}>
                <Grid.Column width={16}>
                  <Icon name="dropdown" />
                  <CustomHeaderLabel message="GSA - Field Evaluation Service Terms" />
                </Grid.Column>
              </Grid>
            </Accordion.Title>
            <Accordion.Content
              active={activeIndex === 0}
              className="print-expanded"
            >
              <Divider />
              <Table attached>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell textAlign="center">
                      GLOBAL SERVICES AGREEMENT
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell verticalAlign="top">
                      <p className="center">FIELD EVALUATIONS SERVICES TERMS</p>
                      <p>
                        These Service Terms shall govern Field Evaluation
                        Services performed by UL Contracting Party (as
                        identified in the Quotation or Project Confirmation) and
                        set out the responsibilities and obligations of the
                        Client. These Service Terms and the terms of the Global
                        Services Agreement (“GSA”) are incorporated by reference
                        into and are an integral part of each Service Agreement
                        entered into by the Parties for Field Evaluation
                        Services. The capitalized terms in these Service Terms
                        which are not defined herein shall have the same meaning
                        as in the GSA
                      </p>
                      <ol>
                        <li>
                          <b>Scope of Service</b>. UL Contracting Party will
                          conduct on-site evaluations of installed products or
                          products with a specified installation location that
                          have not been previously investigated by UL
                          Contracting Party, or a UL Contracting Party Listed
                          product that has been modified in the field. Field
                          Evaluation Services assess compliance with applicable
                          requirements and are limited to the features and
                          characteristics that can be evaluated at the installed
                          site without damage to the product. The services
                          requested by Client and to be provided by UL
                          Contracting Party for specific projects shall be set
                          out in individual Quotations or Project Confirmations.
                        </li>
                        <li>
                          <b>Access to Site</b>. Client shall provide
                          representatives of UL Contracting Party as well as any
                          third-party observers accompanying the
                          representatives, free, safe, and secure access to the
                          site to conduct its Field Evaluation Services. Client
                          shall ensure that Client and any third party will not
                          attempt to condition the right of UL Contracting
                          Party’s representatives to obtain free access to any
                          site upon the signing of any agreement, waiver or
                          release which in any way purports to affect the legal
                          rights or obligations of UL Contracting Party or its
                          representative. If any representative of UL
                          Contracting Party signs such an agreement, waiver, or
                          release, it shall be considered void and will be of no
                          force and effect. UL Contracting Party will, however,
                          direct its representatives to exercise reasonable care
                          to comply with any site safety regulations provided to
                          UL Contracting Party in advance that are generally
                          applicable to personnel at any such site. Client
                          agrees to provide UL Contracting Party’s
                          representatives and third-party observers with all
                          applicable safety, and other, protections required by
                          law for Client’s own employees, including, without
                          limitation, all U.S. Occupational Safety and Health
                          Administration (“OSHA”) rules and regulations or any
                          non-U.S. equivalents. If the Client or end user site
                          representatives require UL Contracting Party’s
                          representatives to participate in additional training
                          to permit site access, UL Contracting Party may quote
                          for additional fees
                        </li>
                        <li>
                          <b>Price</b>. The Quotation or Project Confirmation
                          will establish the price for UL Contracting Party’s
                          Services. The price will depend upon the type of
                          product and the test requirements. Any Quotation or
                          Project Confirmation is subject to change at UL
                          Contracting Party’s discretion, upon reasonable notice
                          to Client, depending upon the requirements of the
                          specific project
                        </li>
                        <li>
                          <b>Field Evaluation</b>. UL Contracting Party reserves
                          the right to terminate the field evaluation if, in UL
                          Contracting Party’s sole discretion, the equipment
                          features cannot be fully evaluated in the field, or
                          the evaluation presents a potential hazard to persons
                          or property
                        </li>
                        <li>
                          <b>Jurisdictional Authority Report</b>. UL Contracting
                          Party’s staff will address all questions or concerns
                          raised by the local Authority Having Jurisdiction
                          (“AHJ”). If UL Contracting Party cannot fully evaluate
                          the equipment, UL Contracting Party will provide a
                          report to the Client and the local AHJ indicating the
                          level of compliance that could be determined and
                          identifying the issues that UL Contracting Party was
                          unable to fully evaluate.
                        </li>
                        <li>
                          <b>Deliverables</b>.
                        </li>
                        <ol type="a">
                          <li>
                            If the equipment complies with the applicable
                            requirements, UL Contracting Party’s staff will
                            apply a serialized UL Evaluated product Mark to the
                            equipment. A final report will be issued to the
                            Client and AHJ.
                          </li>
                          <li>
                            If the equipment does not meet the requirements, a
                            report identifying the discrepancies will be issued
                            to the Client and AHJ.
                          </li>
                          <li>
                            If the equipment cannot be fully evaluated on site
                            or involves potential hazards that cannot be fully
                            assessed, UL will cease the evaluation and issue a
                            report that indicates the level of compliance that
                            could be determined and identifies the issues that
                            UL Contracting Party was not able to fully evaluate.
                          </li>
                        </ol>
                        <li>
                          <b>Use of Names and Marks</b>. Field Evaluation
                          Services shall not result in UL Contracting Party
                          issuing product safety certification or any
                          authorization to use the UL Marks. Except as otherwise
                          expressly authorized by UL Contracting Party, Client
                          shall not use UL Contracting Party's, or any other UL
                          Company’s, name, abbreviation, symbols, Marks or any
                          other form of reference which may be interpreted to
                          refer to the UL Contracting Party or any other UL
                          Company, on any goods or their containers or
                          packaging, or in connection with any oral or written
                          advertising, promotions, or otherwise.
                        </li>
                      </ol>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Accordion.Content>
          </Accordion>
        </Segment>
      </Fragment>
    );
  }
}

export default GlobalServicesAgreement;
