import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { resendVerificationEmailCard } from "../../../assets/js/common";
import { Container, Card, Button, Message } from "semantic-ui-react";
import HeaderBlock from "../../../components/header/header-block";
import FooterBlock from "../../../components/footer/footer-block";
import { CustomTitleLabel } from "../../../components/labels/labels";
import * as actions from "./verify-email.action";
import { Redirect } from "react-router-dom";
import _ from "lodash";
import { Common } from "../../../common";

class ResendEmailVerification extends Component {
  handleResendToken = () => {
    let userId = Common.getUserId();

    const { form } = this.props.verifyEmail;
    form.userId = userId;
    form.loading = true;
    return this.props.doResendEmailVerToken(_.omit(form));
  };

  handleLogout = () => {
    localStorage.removeItem("selection");
    this.props.doLogout();
  };

  componentWillUnmount() {
    this.props.clear();
  }

  render() {
    document.getElementById("root").style.backgroundColor = "#0b3c61";
    // debugger;
    const userId = Common.getUserId();

    if (!userId) {
      return <Redirect to="/" />;
    }

    const { form, resendTokenData } = this.props.verifyEmail;

    if (resendTokenData && resendTokenData.isSuccessful) {
      form.message =
        "We sent you an verification link. Check your email and click on the link to verify.";
    }

    const message =
      "We have sent a verification mail to applicant@example.com." +
      " Please activate your account with the link provided on the mail." +
      " If you cannot find the mail, please also check the Junk/Spam folder!";
    return (
      <Fragment>
        <HeaderBlock />
        <Container style={resendVerificationEmailCard}>
          <Card fluid>
            <Card.Content>
              <Card.Header>Email Verification</Card.Header>
              <br />
              <Message
                success
                hidden={resendTokenData && !resendTokenData.isSuccessful}
                header=""
                content={form.message}
              />
              {resendTokenData && !resendTokenData.isSuccessful ? (
                <CustomTitleLabel message={message} />
              ) : (
                ""
              )}
            </Card.Content>
            <Card.Content extra textAlign="right">
              {resendTokenData && !resendTokenData.isSuccessful ? (
                <Button
                  loading={form.loading}
                  onClick={this.handleResendToken.bind(this)}
                  color="blue"
                >
                  Resend Verify Email
                </Button>
              ) : (
                ""
              )}

              <Button onClick={this.handleLogout} color="blue">
                Log out
              </Button>
            </Card.Content>
          </Card>
        </Container>
        <FooterBlock />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    verifyEmail: state.user.verifyEmail
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doResendEmailVerToken: (param) =>
      dispatch(actions.doResendEmailVerToken(param)),
    form: (result) => dispatch(actions.updateForm(result)),
    doLogout: () => dispatch(actions.doLogout()),
    clear: () => dispatch(actions.clearUser())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResendEmailVerification);
