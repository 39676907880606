import React, { Component } from "react";
import { Table, Button, Icon } from "semantic-ui-react";
import PropTypes from "prop-types";

import * as actions from "./cancel-order.action";

class CancelOrderItem extends Component {
  onCancelOrder() {
    const { data } = this.props;
    this.props.onCancelling(data);
  }

  render() {
    const { data } = this.props;

    return (
      <Table.Row>
        <Table.Cell>{data.orderTypeName}</Table.Cell>
        <Table.Cell>
          <Icon color="red" name="attention" />
          {data.statusName}
        </Table.Cell>
        <Table.Cell>{data.orderNumber}</Table.Cell>
        <Table.Cell>{data.orderDate}</Table.Cell>
        <Table.Cell>
          <Button
            disabled={data.isDisable}
            size="mini"
            primary
            onClick={this.onCancelOrder.bind(this)}
          >
            Cancel
          </Button>
        </Table.Cell>
      </Table.Row>
    );
  }
}

CancelOrderItem.propTypes = {
  data: PropTypes.object.isRequired,
  cancelProcessResult: PropTypes.object.isRequired
};

export default CancelOrderItem;
