import React, { Component, Fragment } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Button,
  Container,
  Form,
  Grid,
  Icon,
  Input,
  Pagination,
  Message,
  Segment,
  Table,
  Tab,
  Divider,
  Dropdown,
  Popup,
  Label,
  Modal,
  Checkbox,
} from 'semantic-ui-react';
import FooterHomeBlock from '../../../components/footer/footer-home-block';
import {
  CustomHeaderInlineBold,
  CustomHeaderLabel,
  CustomLabel,
  CustomTitleLabel,
  SubLabel,
  SubLabelError,
} from '../../../components/labels/labels';
import {
  addToCart,
  resetAddToCart,
} from '../../../views/manufacturer/standard/labels/labels.action';

import {
  addExistingCombinationLabelsToCart,
  getExistingCombinationLabels,
  initialize,
  updateExistingCombinationLabelFilters,
  getCombinationLabelsByItemNumber,
  getCombinationLabelsByPartNumber,
  updateSelectedLabel,
  pushSelectedLabel,
  removeSelectedLabel,
  updateCombinationLabelsForm,
  updateItemList,
  updateAttachment,
  uploadCombinationLabelAttachment,
  getLabelsByCcn,
  resetItemNumberOptions,
  getListeeFileNumbers,
  cancelNewLine,
  getExistingLabelOrdersByCartId,
  scanFile,
  getHologramCcns,
  getLabelSupplierPrinterEmail,
} from './existing-combination-labels.action';
import { getLabelSuppliers } from '../../label-order-cart/cart/label-order-cart.action';
import {
  updateLabelOrderCartType,
  getCartItems,
  resetCartItemsForm,
} from '../../../views/manufacturer/standard/label-cart/label-cart.action';

import { getActiveManufacturers } from '../../../views/applicant/standard/applicant-dashboard.action';
import { getFileNumbers } from '../../../views/manufacturer/standard/file-volumes/file-volumes.action';

import { Common } from '../../../common';
import ReactAutocomplete from 'react-autocomplete';
import {
  validate,
  validateField,
  getError,
  createValidation,
} from '../../../validator/validator';
import { required, email } from '../../../validator/error-message';
import { toast } from 'react-semantic-toasts';
import Role from '../../../enums/role';
import _, { isLength } from 'lodash';
import { bool } from 'prop-types';

var rules = [];
var addItemRules = [];

const FILE_SIZE_LIMIT = 10000000;
const INVALID_FILE_SIZE_MESSAGE = 'Max. size for each file is 10MB.';
var INVALID_FILE_TYPE_MESSAGE = '';
const FILE_HAS_VIRUS_MESSAGE =
  'Virus or malware has been detected, this file cannot be uploaded.';

const allowedFileTypes = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'jpg', 'txt'];

class ExistingCombinationLabels extends Component {
  state = {
    cartStatus: -1,
    isLoadData: true,
    activeIndex: 0,
    value: '',
    hasSelectedItemNumber: false,
    displayError: false,
    showErrors: false,
    isUploadSuccessful: false,
    openRemoveModal: false,
    openModal: false,
    lengthExceededModal: false,
    sameSupplierModal: false,
    isLabelSupplierPreSelected: false,
    isAuthSupEmailAddressOpen: false,
  };

  fileNumber = null;
  fileNumberList = '';
  manufacturerId = null;
  roleId = Common.getUserRole();
  accountName = null;

  labelOrderCartId = null;
  ccn = null;
  ccnList = null;
  volume = null;
  volumeList = [];

  initializeValidations = () => {
    const translationsValidation = Common.getTranslations() || {};
    rules = [
      validate(
        'labelSupplierId',
        translationsValidation['LexSupplierFileNumberisRequired'],
        required
      ),
      validate(
        'labelSupplierEmailAddress',
        translationsValidation['LexSupplierEmailisRequired'],
        required,
        email
      ),
    ];
    addItemRules = [
      validate(
        'itemNumber',
        translationsValidation['LexULItemNumberisrequired'],
        required
      ),
      validate(
        'quantity',
        translationsValidation['LexQuantityisrequired'],
        required
      ),
    ];
    INVALID_FILE_TYPE_MESSAGE = translationsValidation['LexAcceptableDocType'];
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isHologramCcnFetched != this.props.isHologramCcnFetched) {
      if (this.props.hologramCcns.length > 0) {
        this.props.getLabelSuppliers('LP1064', 1, 10);
      }
    }

    if (
      prevProps.labelOrderCart.labelSupplierFileNumbers !=
        this.props.labelOrderCart.labelSupplierFileNumbers &&
      this.props.hologramCcns.length > 0
    ) {
      this.selectLabelSupplierHologramCcn();
    }

    if (
      prevProps.labelSupplierPrinterEmails !=
      this.props.labelSupplierPrinterEmails
    ) {
      // this.assignLabelPrinterEmail();
    }
  }
  componentDidMount() {
    this.props.initialize();
    this.getUrlParams();
    this.initializeValidations();

    this.props.updateLabelOrderCartType(2);
    const translations = Common.getTranslations() || {};
    var that = this;

    if (this.labelOrderCartId) {
      this.props
        .getExistingLabelOrdersByCartId(this.labelOrderCartId)
        .then(result => {
          this.fileNumberList = result.fileNumbers;
          this.ccnList = result.ccns;
          this.props.getLabelsByCcn(this.ccnList);
          this.props.getHologramCcns(this.ccnList);
          this.props.getListeeFileNumbers(
            this.fileNumberList,
            1,
            this.isCopied
          );
          this.props.getExistingCombinationLabels(
            this.fileNumberList,
            '',
            result.applicantAccountNumber,
            result.manufacturerAccountNumber,
            1,
            10000,
            1,
            this.isCopied
          );
          this.props.getLabelSuppliers(result.labelSupplierName, 1, 10);
          this.setState({
            hasSelectedItemNumber: true,
          });

          this.setAccountName(
            result.manufacturerAccountNumber,
            result.applicantAccountNumber
          );

          //if no file volume get from exsiting copy

          if (!this.volumeList) {
            const combinationLabelOrderDetails =
              result.combinationLabelOrderDetails;

            const distinctVolumes = [
              ...new Set(combinationLabelOrderDetails.map(obj => obj.volume)),
            ];

            this.volumeList = distinctVolumes.join(',');
          }

          this.props.getLabelSupplierPrinterEmail(
            result.labelSupplierFileNumber,
            result.labelSupplierPartySiteNumber
          );
        });
    } else {
      this.props.getLabelsByCcn(this.ccnList);
      this.props.getHologramCcns(this.ccnList);
      this.props.getListeeFileNumbers(this.fileNumberList, 1, false);
      this.props
        .getExistingCombinationLabels(
          this.fileNumberList,
          '',
          this.accountNumber,
          this.accountNumber,
          1,
          10000,
          1,
          false
        )
        .then(result => {
          if (result && result.data.length > 0) {
            this.showReminderInfo(translations['LexEnterCorrectComLabelAlert']);
          }
        });

      this.setAccountName(this.accountNumber, this.accountNumber);
    }

    this.props.resetCartItemsForm();
    this.props.getCartItems();
  }

  setAccountName = (manufacturerAccount, applicantAccount) => {
    if (this.roleId === 1) {
      this.props
        .getActiveManufacturers(
          1,
          100,
          null,
          null,
          [],
          [],
          [],
          [],
          [],
          [],
          2,
          true
        )
        .then(result => {
          this.accountName = result?.applicantFileVolumeDetails?.data?.filter(
            a => a.manufacturerAccount == manufacturerAccount
          )[0]?.manufacturerName;
        });
    } else {
      this.props
        .getApplicantFileNumbers(null, 2, 1, 5, [], [], [], [], [], [], 2, true)
        .then(result => {
          this.accountName = result?.fileVolumeDetails?.data?.filter(
            a => a.applicantAccount == applicantAccount
          )[0]?.applicantName;
        });
    }
  };

  showReminderInfo(msg) {
    setTimeout(() => {
      toast({
        title: '',
        description: msg,
        time: 10000,
        size: 'small',
        icon: 'warning',
        color: 'red',
      });
    }, 100);
  }

  addExistingLabelToCart = data => {
    this.props.addExistingCombinationLabelsToCart({
      fileNumber: data.fileNumber,
      labelItems: [
        {
          existingLabelOrderId: data.id,
          labelDescription: data.description,
          manufacturerId: this.manufacturerId,
          itemNumber: data.itemNumber,
          unitOfMeasurementName: 'EACH',
          quantity: data.quantity,
        },
      ],
    });
  };

  getUrlParams = () => {
    this.fileNumber = this.props.match.params.fileNumber;
    this.fileNumberList = this.props.match.params.fileNumberList;
    this.manufacturerId = this.props.match.params.manufacturerId;
    this.labelOrderCartId = this.props.match.params.labelOrderCartId;
    this.isCopied = this.props.match.params.isCopied;
    this.accountNumber = this.props.match.params.accountNumber;
    this.ccn = this.props.match.params.ccn;
    this.ccnList = this.props.match.params.ccnList;
    this.volume = this.props.match.params.volume;
    this.volumeList = this.props.match.params.volumeList;
  };

  getSortOrder(sortColumn, sortOrder) {
    if (this.props.sortColumn !== sortColumn) return 'ascending';

    return sortOrder === 'ascending' ? 'descending' : 'ascending';
  }

  search() {
    this.props.getExistingCombinationLabels(
      this.fileNumber,
      this.props.itemNumber,
      this.accountNumber,
      this.accountNumber
    );
  }

  handleChange = (e, id, formIndex) => {
    let { forms } = this.props;
    let form = forms && forms[formIndex];
    var addItemForm = form.itemList.filter(i => i.id == id)[0];

    const newState = validateField(
      rules,
      addItemForm,
      e.target.name,
      e.target.value
    );

    this.props.updateSelectedLabel(formIndex, newState, forms);
  };

  handleChangeQuantity = (e, id, formIndex) => {
    let { forms } = this.props;
    const re = /^[0-9\b]+$/;
    let form = forms && forms[formIndex];

    var addItemForm = form.itemList.filter(i => i.id == id)[0];

    var qty = e.target.value.replace(/,/g, '');

    if ((qty === '' || re.test(qty)) && qty <= 2147483647) {
      const newState = validateField(
        addItemRules,
        addItemForm,
        e.target.name,
        parseInt(qty)
      );

      this.props.updateSelectedLabel(formIndex, newState, forms);
    }
  };

  handleChangeAutoComplete = (event, properties, id, formIndex) => {
    let { selectedProductCovered, forms } = this.props;
    let form = forms[formIndex] || {};

    let fileNumber =
      this.fileNumberList.indexOf(',') !== -1
        ? this.fileNumberList.split(',')[formIndex]
        : this.fileNumberList;
    let ccn =
      this.ccnList && this.ccnList.indexOf(',') !== -1
        ? this.ccnList.split(',')[formIndex]
        : form.itemList[0].ccn;
    let volume =
      this.volumeList && this.volumeList.indexOf(',') !== -1
        ? this.volumeList.split(',')[formIndex]
        : this.volumeList;

    let labelAccount = selectedProductCovered || form.itemList[0].labelAccount;

    this.setState({
      hasSelectedItemNumber: true,
      displayError: false,
    });
    this.props.getCombinationLabelsByItemNumber(
      properties.value,
      id,
      fileNumber,
      ccn,
      labelAccount,
      volume,
      formIndex
    );
  };

  handleChangeAutoCompleteListeeFileNumber = (
    event,
    properties,
    id,
    formIndex
  ) => {
    let { forms } = this.props;
    let form = forms && forms[formIndex];

    var addItemForm = form.itemList.filter(i => i.id == id)[0];

    const newState = validateField(
      rules,
      addItemForm,
      properties.name,
      properties.value
    );

    this.props.updateSelectedLabel(formIndex, newState, forms);
  };

  handleItemOptionsReset = () => {
    this.props.resetItemNumberOptions();
  };

  handleAddItem = formIndex => {
    let { itemList, pushSelectedLabel, forms } = this.props;
    let form = forms[formIndex] || {};

    let hasErrors = false;
    form.itemList.forEach(item => {
      item.validationErrors = createValidation(item, addItemRules);
      if (!Object.IsEmpty(item.validationErrors)) {
        hasErrors = true;
      }
    });
    if (!hasErrors) {
      if (form.itemList.length < 50) {
        pushSelectedLabel(formIndex);
        this.setState({ hasSelectedItemNumber: false });
      }
    } else {
      this.setState({ showErrors: true });
    }
  };

  handleRemoveSelectedLabel = () => {
    this.setState({ openRemoveModal: true });
  };

  handleCancel = () => {
    this.setState({ openRemoveModal: false });
  };

  handleCloseModal = () => {
    this.setState({
      openModal: false,
      lengthExceededModal: false,
      sameSupplierModal: false,
    });
  };

  handleConfirmRemove = () => {
    let { removeSelectedLabel, itemList, forms } = this.props;
    let id = document.getElementById('itemIdToRemove').value;
    let formIndex = document.getElementById('formIndex').value;
    let form = forms[formIndex] || {};
    let newItemlist = form.itemList.filter(f => f.id != id);
    form.itemList = newItemlist;
    removeSelectedLabel(formIndex, form, forms);
    this.setState({ openRemoveModal: false });
  };

  handleChangeCombinationLabelsForm = (e, index) => {
    let { form } = this.props;
    let { activeIndex } = this.state;

    const newState = validateField(rules, form, e.target.name, e.target.value);
    this.props.updateCombinationLabelsForm(newState, form);
  };

  changeOrderCartDropdownValue = (e, properties) => {
    let { form, labelOrderCart } = this.props;
    let { labelSupplierData } = labelOrderCart;
    let newForm = form;
    let selectedSupplier = labelSupplierData.filter(
      l => l.id == properties.value
    )[0];

    if (selectedSupplier) {
      newForm.labelSupplierId = selectedSupplier.id;
      newForm.labelSupplierName = selectedSupplier.name;
      newForm.labelSupplierFileNumber = selectedSupplier.fileNumber;
      newForm.labelSupplierAddress = selectedSupplier.address;
      newForm.labelSupplierAccountNumber = selectedSupplier.accountNumber;
      newForm.labelSupplierPartySiteNumber = selectedSupplier.partySiteNumber;
      newForm.labelSupplierEmailAddress = null;
    }
    this.props.getLabelSupplierPrinterEmail(
      selectedSupplier.fileNumber,
      selectedSupplier.partySiteNumber
    );
    this.props.updateCombinationLabelsForm(newForm, form);
  };
  changeLabelPrinterEmailValue = (e, properties) => {
    let { form, labelSupplierPrinterEmails } = this.props;

    let newForm = form;
    let supplierEmails = properties.value.filter(l =>
      labelSupplierPrinterEmails
        .map(l => {
          return l.value;
        })
        .includes(l)
    );
    if (supplierEmails && supplierEmails.length <= 3) {
      newForm.labelSupplierEmailAddress = '';
      supplierEmails.forEach(s => {
        newForm.labelSupplierEmailAddress += s + ', ';
      });
    }
    newForm.labelSupplierEmailAddress =
      newForm.labelSupplierEmailAddress.replace(/,\s*$/, '');

    this.props.updateCombinationLabelsForm(newForm, form);
  };
  selectLabelSupplierHologramCcn() {
    let { form, labelOrderCart } = this.props;
    let { labelSupplierData } = labelOrderCart;
    let newForm = form;
    let selectedSupplier = labelSupplierData[0];

    if (selectedSupplier) {
      newForm.labelSupplierId = selectedSupplier.id;
      newForm.labelSupplierName = selectedSupplier.name;
      newForm.labelSupplierFileNumber = selectedSupplier.fileNumber;
      newForm.labelSupplierAddress = selectedSupplier.address;
      newForm.labelSupplierAccountNumber = selectedSupplier.accountNumber;
      newForm.labelSupplierPartySiteNumber = selectedSupplier.partySiteNumber;
      newForm.labelSupplierEmailAddress = null;
    }
    this.setState({ isLabelSupplierPreSelected: true });
    this.props.getLabelSupplierPrinterEmail(
      selectedSupplier.fileNumber,
      selectedSupplier.partySiteNumber
    );
    this.props.updateCombinationLabelsForm(newForm, form);
  }

  assignLabelPrinterEmail() {
    let { form, labelSupplierPrinterEmails } = this.props;

    let newForm = form;

    newForm.labelSupplierEmailAddress = '';
    if (labelSupplierPrinterEmails.length == 1) {
      newForm.labelSupplierEmailAddress = labelSupplierPrinterEmails[0].value;
    }

    this.props.updateCombinationLabelsForm(newForm, form);
  }

  addToCart = () => {
    const { form, forms, labelCart } = this.props;
    var { hasSelectedItemNumber, activeIndex } = this.state;
    form.validationErrors = createValidation(form, rules);
    let isLengthExceeded = false;
    var hasValidationErrors = false;
    let labelItems = [];

    let cartItemsLength = 0;

    if (labelCart && labelCart.data && labelCart.data.length > 0) {
      if (labelCart.data[0].carts.length > 0) {
        cartItemsLength = labelCart.data[0].carts[0].cartItems.length;
      }
    }

    forms.forEach(form => {
      if (
        form.itemList.length > 50 ||
        form.itemList.length + cartItemsLength > 50
      ) {
        isLengthExceeded = true;
      }
      form.itemList.forEach(item => {
        item.validationErrors = createValidation(item, addItemRules);
        if (!Object.IsEmpty(item.validationErrors)) {
          hasValidationErrors = true;
        }
      });
    });

    if (!hasSelectedItemNumber) {
      this.setState({ openModal: true });
    }

    if (isLengthExceeded) {
      this.setState({ lengthExceededModal: true });
      return;
    }

    if (!hasValidationErrors && Object.IsEmpty(form.validationErrors)) {
      var cnt = 0;
      forms.forEach(form => {
        form.itemList.forEach(item => {
          let ccn = this.ccnList.split(',')[cnt];

          item.ccn = ccn;
          labelItems.push(item);
          item.id = 0;
        });
        cnt = cnt + 1;
      });
      var model = {
        ...form,
        labelOrderCartItems: labelItems,
        fileNumber: this.fileNumber,
        cartStatus: 2,
        accountNumber: this.accountNumber,
        labelOrderType: 2,
        existingLabelOrderCartId: this.labelOrderCartId,
        volume: this.volume,
      };

      this.props.addToCart(model).then(result => {
        if (result.isSuccessful) {
          this.props.resetAddToCart();
          localStorage.setItem('cartLabelOrderType', 1);
          toast({
            title: '',
            description: (
              <p>{Common.getTranslations()['LexItemAddedtoCartAlert']}</p>
            ),
            time: 5000,
            size: 'tiny',
            icon: 'check',
            color: 'green',
          });
          setTimeout(function () {}, 2000);

          var listOfAttachments = [];
          if (this.isCopied) {
            labelItems
              .filter(item => item.fileName != null && item.fileName != '')
              .forEach(item => {
                let labelItem = result.data.labelOrderCartItems.filter(
                  l => l.itemNumber == item.itemNumber
                )[0];

                let formData = {
                  labelOrderCartItemId: labelItem.id || 0,
                  fileName: item.fileName,
                  artWorkLink: item.srcData,
                  existingLabelOrderCartItemId:
                    item.existingLabelOrderCartItemId,
                };
                listOfAttachments.push(formData);
              });
            this.props.uploadCombinationLabelAttachment(listOfAttachments);
          } else {
            labelItems
              .filter(item => item.file != null)
              .forEach(item => {
                let labelItem = result.data.labelOrderCartItems.filter(
                  l => l.itemNumber == item.itemNumber
                )[0];

                let formData = {
                  labelOrderCartItemId: labelItem.id || 0,
                  fileName: item.file.name || item.fileName,
                  artWorkLink: item.srcData,
                };
                listOfAttachments.push(formData);
              });
            this.props.uploadCombinationLabelAttachment(listOfAttachments);
          }
        } else {
          if (result.code == 500 && result.message == 'Duplicate Cart Error') {
            this.showReminderInfo(
              'You have an existing cart. Please checkout the cart first before placing new order.'
            );
          }
        }
      });
    } else {
      this.setState({ showErrors: true });
    }
  };

  resetFileUpload = id => {
    this.setState({ showErrors: true });

    this.props.updateItemList(id, 'srcData', null);
    this.props.updateItemList(id, 'file', null);
    this.props.updateItemList(id, 'fileName', null);
  };

  handleFileChange = event => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const id = document.getElementById('selectedItemId').value;
      let extension = file.name
        .substr(file.name.lastIndexOf('.') + 1)
        .toLowerCase();

      if (file.size > FILE_SIZE_LIMIT) {
        this.props.updateItemList(
          id,
          'errorMessage',
          INVALID_FILE_SIZE_MESSAGE
        );

        this.resetFileUpload(id);
      } else if (!allowedFileTypes.includes(extension)) {
        this.props.updateItemList(
          id,
          'errorMessage',
          INVALID_FILE_TYPE_MESSAGE
        );

        this.resetFileUpload(id);
      } else {
        this.props.updateItemList(id, 'errorMessage', null);
        this.setState({ showErrors: false });

        var fileReader = new FileReader();
        var that = this;
        fileReader.onload = function (fileLoadedEvent) {
          var srcData = fileLoadedEvent.target.result;
          that.handleUpdateItemArtwork(id, srcData, file);
        };

        fileReader.readAsDataURL(file);
      }
    }
  };

  handleUpdateItemArtwork = (id, srcData, file) => {
    this.props.updateItemList(id, 'srcData', srcData);
    this.props.updateItemList(id, 'file', file);
  };

  resetAddItemFileUpload = (id, formIndex) => {
    this.setState({ showErrors: true });

    this.props.updateAttachment('srcData', null, id, formIndex);
    this.props.updateAttachment('file', null, id, formIndex);
    this.props.updateAttachment('fileName', null, id, formIndex);
  };

  handleAddItemFileChange = event => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const id = document.getElementById('selectedItemId').value;
      const formIndex = document.getElementById('formIndex').value;

      let extension = file.name
        .substr(file.name.lastIndexOf('.') + 1)
        .toLowerCase();

      if (file.size > FILE_SIZE_LIMIT) {
        this.props.updateAttachment(
          'errorMessage',
          INVALID_FILE_SIZE_MESSAGE,
          id,
          formIndex
        );
        this.resetAddItemFileUpload(id, formIndex);
      } else if (!allowedFileTypes.includes(extension)) {
        this.props.updateAttachment(
          'errorMessage',
          INVALID_FILE_TYPE_MESSAGE,
          id,
          formIndex
        );
        this.resetAddItemFileUpload(id, formIndex);
      } else {
        this.props.updateAttachment('errorMessage', null, id, formIndex);
        this.setState({ showErrors: false });
        var fileReader = new FileReader();
        var that = this;
        fileReader.onload = function (fileLoadedEvent) {
          var srcData = fileLoadedEvent.target.result;

          that.props
            .scanFile({
              fileDetails: srcData,
            })
            .then(result => {
              if (result.isSuccessful) {
                that.handleAddItemArtwork(srcData, file, id, formIndex);
              } else {
                that.props.updateAttachment(
                  'errorMessage',
                  FILE_HAS_VIRUS_MESSAGE,
                  id,
                  formIndex
                );
                that.resetAddItemFileUpload(id, formIndex);
              }
            });
        };

        fileReader.readAsDataURL(file);
      }
    }
  };

  handleAddItemArtwork = (srcData, file, id, formIndex) => {
    this.props.updateAttachment('srcData', srcData, id, formIndex);
    this.props.updateAttachment('file', file, id, formIndex);
    this.props.updateAttachment(
      'fileName',
      file ? file.name : '',
      id,
      formIndex
    );
    this.setState({ isUploadSuccessful: file ? true : false });
  };

  setFileIcon = fileName => {
    let iconName = '';
    let extension = fileName.substr(fileName.lastIndexOf('.') + 1);

    if (extension == 'pdf') iconName = 'file pdf';
    else if (extension == 'doc' || extension == 'docx') iconName = 'file word';
    else if (extension == 'xls' || extension == 'xlsx') iconName = 'file excel';
    else if (extension == 'jpg' || extension == 'png') iconName = 'file image';
    else iconName = 'file';

    return iconName;
  };

  getErrorFor(field) {
    const { form } = this.props;
    let errors = getError(field, form, form);
    return errors;
  }

  getErrorForAddItem(field, id, formIndex) {
    const { itemList, forms } = this.props;
    let form = forms[formIndex] || {};
    var item = form.itemList.filter(i => i.id == id)[0];

    let errors = getError(field, item, item);
    return errors;
  }

  handleTabChange = (e, { activeIndex }) => {
    this.setState({ activeIndex });

    if (activeIndex != 0) {
      this.setState({ sameSupplierModal: true });
    }
  };

  handleCancelNewLine = formIndex => {
    this.setState({ hasSelectedItemNumber: true });
    this.props.cancelNewLine(formIndex);
  };

  handleChangeAutoCompletePartNumber = (event, properties, id, formIndex) => {
    let { selectedProductCovered, forms } = this.props;
    let form = forms[formIndex] || {};

    let fileNumber =
      this.fileNumberList.indexOf(',') !== -1
        ? this.fileNumberList.split(',')[formIndex]
        : this.fileNumberList;
    let ccn =
      this.ccnList && this.ccnList.indexOf(',') !== -1
        ? this.ccnList.split(',')[formIndex]
        : form.itemList[0].ccn;
    let volume =
      this.volumeList && this.volumeList.indexOf(',') !== -1
        ? this.volumeList.split(',')[formIndex]
        : this.volumeList;

    let labelAccount = selectedProductCovered || form.itemList[0].labelAccount;

    this.setState({
      hasSelectedItemNumber: true,
      displayError: false,
    });
    this.props.getCombinationLabelsByPartNumber(
      properties.value,
      id,
      fileNumber,
      ccn,
      labelAccount,
      volume,
      formIndex
    );
  };

  render() {
    const {
      data,
      listeeFileNumbers,
      totalPages,
      itemList,
      labelOrderCart,
      form,
      isSuccessAddToCart,
      loading,
      forms,
      labelSupplierPrinterEmails,
    } = this.props;
    const translations = Common.getTranslations() || {};
    const firstForm = (forms && forms[0]) || {};
    let hasLineItems = false;
    forms.forEach(form => {
      let hasValidRows = form.itemList.filter(item => item.itemNumber)[0];

      if (hasValidRows) {
        hasLineItems = true;
      }
    });
    const { labelSupplierFileNumbers } = labelOrderCart;

    let url = '';
    let label = '';

    const {
      activeIndex,
      hasSelectedItemNumber,
      displayError,
      openRemoveModal,
      openModal,
      lengthExceededModal,
      sameSupplierModal,
    } = this.state;

    let roleName = '';

    if (this.roleId == Role.applicant) {
      url = '/home/applicant-dashboard/2';
      label = 'MANUFACTURER';
      roleName = translations['LexAddLabelsForManufacturer'];
    } else if (this.roleId == Role.manufacturer) {
      url = '/home/order-standard-label/2';
      label = 'APPLICANT';
      roleName = translations['LexAddLabelsForApplicant'];
    }

    var fileNumbers =
      (this.fileNumberList && this.fileNumberList.split(',')) || [];

    for (let i = 0; i < fileNumbers.length; i++) {
      let itemsPerFileNumber = (data || []).find(
        d => d.fileNumber == fileNumbers[i]
      );
      if (!itemsPerFileNumber) {
        return (
          <div className="div-container">
            <Segment placeholder style={{ textAlign: 'center' }}>
              <p>
                You don't have proper existing combination label item. Please
                contact your label center for assistant.
              </p>
            </Segment>
          </div>
        );
      }
    }
    const panes = [];

    for (let index = 0; index < fileNumbers.length; index++) {
      const fileNumber = fileNumbers[index];
      let volumeList = this.volume ? [this.volume] : this.volumeList;
      const frm = forms && forms.length > 0 ? forms[index] || {} : {};

      if (frm && frm.itemList && frm.itemList.length > 0) {
        frm.itemList.forEach(result => {
          result.itemNumberOptions =
            data &&
            data
              .filter(d => d.fileNumber == fileNumber)
              .map(item => {
                return {
                  key: item.id,
                  text: `${item.itemNumber}`,
                  value: item.itemNumber,
                  content: `${item.itemNumber} - ${item.labelDescription}`,
                };
              });

          result.listeeFileNumbers =
            listeeFileNumbers &&
            listeeFileNumbers
              .filter(d => d.fileNumber == fileNumber)
              .map(item => {
                return {
                  key: item.id.toString(),
                  text: item.listeeFileNo,
                  value: item.listeeFileNo,
                };
              });

          result.customerPartNumbers =
            data &&
            data
              .filter(
                d =>
                  d.fileNumber === fileNumber && d.customerPartNumber !== 'NULL'
              )
              .map(item => {
                return {
                  key: item.id,
                  text: item.customerPartNumber,
                  value: item.customerPartNumber,
                };
              });
        });
      }

      const pane = {
        name: `${translations['LexFileNo.']} ${fileNumber}`,
        menuItem: `${translations['LexFileNo.']} ${fileNumber}`,
        render: () => (
          <Tab.Pane key={index}>
            <Form loading={false} style={{ fontSize: '15px' }}>
              <Table compact="very">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell />
                    <Table.HeaderCell
                      style={{
                        color: '#707070',
                      }}
                    >
                      <span
                        style={{
                          color: '#C02032',
                        }}
                      >
                        *
                      </span>
                      {translations['LexULItemNumber']}{' '}
                      <Popup
                        trigger={
                          <span
                            style={{
                              color: '#80B0D2',
                            }}
                          >
                            <Icon name="exclamation circle" />
                          </span>
                        }
                        content={translations['LexThelatest10itemnumbers']}
                        size="small"
                      />
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      style={{
                        color: '#707070',
                      }}
                    >
                      {translations['LexItemDescription']}
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      width="2"
                      style={{
                        color: '#707070',
                      }}
                    >
                      <span
                        style={{
                          color: '#C02032',
                        }}
                      >
                        *
                      </span>
                      {translations['LexQuantityofLabels']}
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      style={{
                        color: '#707070',
                      }}
                    >
                      {translations['LexCustomerPartNo']}{' '}
                      <Popup
                        trigger={
                          <span
                            style={{
                              color: '#80B0D2',
                            }}
                          >
                            <Icon name="exclamation circle" />
                          </span>
                        }
                        content={translations['LexRevisePartNumber']}
                        size="small"
                      />
                    </Table.HeaderCell>
                    <Table.HeaderCell />
                    <Table.HeaderCell />
                    <Table.HeaderCell width="3"></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {frm.itemList &&
                    frm.itemList.map((addItemForm, idx) => {
                      return (
                        <Table.Row key={idx}>
                          <Table.Cell>{addItemForm.id}.</Table.Cell>
                          <Table.Cell width="3" verticalAlign="middle">
                            <Divider hidden fitted />
                            <Divider hidden fitted />
                            <Form.Field
                              control={Dropdown}
                              compact
                              fluid
                              selection
                              search={true}
                              selectOnBlur={false}
                              placeholder={translations['LexULItemNumber']}
                              name="itemNumber"
                              options={addItemForm.itemNumberOptions}
                              onChange={(e, properties) => {
                                this.handleChangeAutoComplete(
                                  e,
                                  properties,
                                  addItemForm.id,
                                  index
                                );
                              }}
                              onBlur={() => {
                                this.handleItemOptionsReset();
                              }}
                              onKeyPress={event => {
                                const currentTextLength =
                                  event.target.value.length;
                                if (currentTextLength > 9) {
                                  event.preventDefault();
                                }
                              }}
                              value={addItemForm.itemNumber || ''}
                              autoComplete="off"
                              error={this.getErrorForAddItem(
                                'itemNumber',
                                addItemForm.id,
                                index
                              )}
                            />
                            <Divider hidden fitted vertical />
                            <Divider hidden fitted vertical />
                            {displayError && (
                              <Popup
                                trigger={
                                  <Icon name="exclamation circle" color="red" />
                                }
                                content="The item number is invalid. Please contact your label center."
                                size="small"
                              />
                            )}
                            <span></span>
                          </Table.Cell>
                          <Table.Cell width="7">
                            <CustomLabel
                              message={addItemForm.labelDescription}
                            />
                          </Table.Cell>
                          <Table.Cell width="2">
                            <Form.Field
                              id="form-input-control-quantity"
                              control={Input}
                              name="quantity"
                              onChange={e => {
                                this.handleChangeQuantity(
                                  e,
                                  addItemForm.id,
                                  index
                                );
                              }}
                              value={
                                addItemForm.quantity
                                  ? addItemForm.quantity.toLocaleString('en-US')
                                  : ''
                              }
                              error={this.getErrorForAddItem(
                                'quantity',
                                addItemForm.id,
                                index
                              )}
                            />
                          </Table.Cell>
                          <Table.Cell>
                            <Form.Field
                              control={Dropdown}
                              compact
                              fluid
                              selection
                              search={true}
                              selectOnBlur={false}
                              placeholder={translations['LexCustomerPartNo']}
                              name="customerPartNumber"
                              options={addItemForm.customerPartNumbers}
                              onChange={(e, properties) => {
                                this.handleChangeAutoCompletePartNumber(
                                  e,
                                  properties,
                                  addItemForm.id,
                                  index
                                );
                              }}
                              value={addItemForm.customerPartNumber || ''}
                              autoComplete="off"
                            />
                          </Table.Cell>
                          <Table.Cell
                            textAlign="center"
                            width={
                              addItemForm.file || addItemForm.errorMessage
                                ? '3'
                                : '1'
                            }
                          >
                            {addItemForm.fileName ? (
                              <Fragment>
                                <div style={{ position: 'relative' }}>
                                  <Form.Input iconPosition="right">
                                    <Icon
                                      name="x"
                                      link
                                      onClick={this.handleAddItemArtwork.bind(
                                        this,
                                        null,
                                        null,
                                        addItemForm.id,
                                        index
                                      )}
                                    />
                                  </Form.Input>
                                  <Icon
                                    name={this.setFileIcon(
                                      addItemForm.fileName
                                    )}
                                    size="big"
                                  />
                                  <Divider hidden fitted />
                                  <span style={{ overflowWrap: 'anywhere' }}>
                                    {addItemForm.fileName}
                                  </span>
                                </div>
                              </Fragment>
                            ) : addItemForm.errorMessage ? (
                              <span
                                style={{ color: 'red', fontWeight: 'bold' }}
                              >
                                {addItemForm.errorMessage}
                              </span>
                            ) : (
                              <span>
                                <Popup
                                  trigger={
                                    <span
                                      style={{
                                        color: '#80B0D2',
                                      }}
                                    >
                                      <Icon name="exclamation circle" />
                                    </span>
                                  }
                                  content={translations['LexNofileUploaded']}
                                  size="small"
                                />
                              </span>
                            )}
                          </Table.Cell>
                          <Table.Cell width="3" textAlign="center">
                            <input
                              type="file"
                              id="fileUploadAdd"
                              style={{ display: 'none' }}
                              onChange={this.handleAddItemFileChange}
                              ref={fileInputAdd =>
                                (this.fileInputAdd = fileInputAdd)
                              }
                              onClick={event => {
                                event.target.value = null;
                              }}
                            />
                            <input type="hidden" id="selectedItemId" />
                            <input type="hidden" id="formIndex" />
                            {!addItemForm.fileName && (
                              <Popup
                                trigger={
                                  <Button
                                    content={translations['LexUploadArtwork']}
                                    onClick={() => {
                                      document.getElementById(
                                        'selectedItemId'
                                      ).value = addItemForm.id;
                                      document.getElementById(
                                        'formIndex'
                                      ).value = index;
                                      this.fileInputAdd.click();
                                    }}
                                    compact
                                    primary
                                  />
                                }
                                content={
                                  translations['LexUploadartworkisoptional']
                                }
                                on="hover"
                                size="small"
                              />
                            )}
                          </Table.Cell>
                          <Table.Cell disabled={!addItemForm.itemNumber}>
                            <input type="hidden" id="itemIdToRemove" />
                            <Popup
                              trigger={
                                <i
                                  class="large trash alternate icon"
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    document.getElementById(
                                      'itemIdToRemove'
                                    ).value = addItemForm.id;
                                    document.getElementById('formIndex').value =
                                      index;
                                    this.handleRemoveSelectedLabel();
                                  }}
                                />
                              }
                              content={translations['LexDeleteRow']}
                              position="top left"
                            />
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                </Table.Body>
              </Table>
              <Grid>
                <Grid.Row>
                  <Grid.Column textAlign="right">
                    {((frm.itemList && frm.itemList.length == 1) ||
                      hasSelectedItemNumber) && (
                      <Button
                        size=""
                        compact
                        primary
                        basic
                        onClick={this.handleAddItem.bind(this, index)}
                        disabled={
                          frm.itemList &&
                          frm.itemList.length === 1 &&
                          !frm.itemList[0].itemNumber
                        }
                      >
                        <Icon name="add" />
                        {translations['LexAddNewLine']}
                      </Button>
                    )}

                    {frm.itemList &&
                      frm.itemList.length > 1 &&
                      !hasSelectedItemNumber && (
                        <Button
                          size=""
                          compact
                          primary
                          basic
                          onClick={this.handleCancelNewLine.bind(this, index)}
                        >
                          <Icon name="x" />
                          {translations['LexCancelNewLine']}
                        </Button>
                      )}
                  </Grid.Column>
                </Grid.Row>
              </Grid>

              <Divider hidden />
              <Divider hidden />
              <Grid style={{ overflow: 'auto' }}>
                <Grid.Row>
                  <Grid.Column>
                    <span style={{ fontWeight: 'bold', fontSize: '17px' }}>
                      {translations['LexAuthorizedLabelSupplier']}
                    </span>
                    :
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={5} verticalAlign="middle">
                    <b>
                      <span
                        style={{
                          color: '#C02032',
                        }}
                      >
                        *
                      </span>{' '}
                      {translations['LexAuthorizedLabelSupplierFileNumber']}
                    </b>
                    <Popup
                      trigger={
                        <span
                          style={{
                            color: '#80B0D2',
                          }}
                        >
                          <Icon name="exclamation circle" />
                        </span>
                      }
                      content={translations['LexEnterSupplierFileorName']}
                      size="small"
                    />
                    <Form.Field
                      control={Dropdown}
                      compact
                      fluid
                      selection
                      search={!this.state.isLabelSupplierPreSelected}
                      selectOnBlur={false}
                      label=""
                      placeholder={
                        translations['LexAuthorizedLabelSupplierFileNumber']
                      }
                      name="labelSupplierFileNumber"
                      options={labelSupplierFileNumbers}
                      onChange={(e, properties) => {
                        this.changeOrderCartDropdownValue(e, properties);
                      }}
                      onSearchChange={event => {
                        this.props.getLabelSuppliers(event.target.value, 1, 10);
                      }}
                      value={form.labelSupplierId || ''}
                      autoComplete="off"
                      error={this.getErrorFor('labelSupplierId')}
                      disabled={
                        index > 0 //&& this.state.isLabelSupplierPreSelected
                      }
                    />
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Grid.Column width={5} verticalAlign="middle">
                    <b>{translations['LexAuthorizedLabelSupplierAddress']}: </b>
                    <Form.Field
                      control={Input}
                      name="labelSupplierAddress"
                      onChange={this.handleChangeCombinationLabelsForm}
                      value={form.labelSupplierAddress || ''}
                      autoComplete="off"
                      readOnly
                      disabled={index > 0}
                    />
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row
                  style={
                    this.state.isAuthSupEmailAddressOpen
                      ? { marginBottom: '10em' }
                      : {}
                  }
                >
                  <Grid.Column width={5} verticalAlign="middle">
                    <b>
                      <span style={{ color: '#C02032' }}>*</span>{' '}
                      {translations['LexAuthorizedLabelSupplierEmailAddress']}:{' '}
                      <Popup
                        trigger={
                          <span
                            style={{
                              color: '#80B0D2',
                            }}
                          >
                            <Icon name="exclamation circle" />
                          </span>
                        }
                        content="You can select multiple emails from the dropdown"
                        size="small"
                      />
                    </b>

                    <Dropdown
                      placeholder={
                        translations['LexAuthorizedLabelSupplierEmailAddress']
                      }
                      fluid
                      multiple
                      selection
                      name="labelSupplierEmailAddress"
                      error={this.getErrorFor('labelSupplierEmailAddress')}
                      options={labelSupplierPrinterEmails}
                      onChange={(e, properties) => {
                        this.changeLabelPrinterEmailValue(e, properties);
                      }}
                      value={
                        form.labelSupplierEmailAddress
                          ? form.labelSupplierEmailAddress
                              .split(',')
                              .map(element => element.trim())
                          : []
                      }
                      disabled={index > 0}
                    />
                    <Form.Field
                      onClick={() => {
                        this.setState({ isAuthSupEmailAddressOpen: true });
                      }}
                      onClose={() => {
                        this.setState({ isAuthSupEmailAddressOpen: false });
                      }}
                      scrolling
                      control={Dropdown}
                      selection
                      selectOnBlur={false}
                      style={{ display: 'none' }}
                      label=""
                      placeholder={
                        translations['LexAuthorizedLabelSupplierEmailAddress']
                      }
                      onChange={(e, properties) => {
                        this.changeLabelPrinterEmailValue(e, properties);
                      }}
                      name="labelSupplierEmailAddress"
                      options={labelSupplierPrinterEmails}
                      value={form.labelSupplierEmailAddress || ''}
                      autoComplete="off"
                      error={this.getErrorFor('labelSupplierEmailAddress')}
                      disabled={labelSupplierPrinterEmails <= 1}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row></Grid.Row>
                <Grid.Row></Grid.Row>
              </Grid>
            </Form>
          </Tab.Pane>
        ),
      };

      panes.push(pane);
    }
    return isSuccessAddToCart ? (
      <Redirect to="/home/label-order-cart" />
    ) : (
      <div className="div-container">
        <Container fluid>
          <Grid>
            <Grid.Row>
              <Grid.Column width={8} verticalAlign="middle" textAlign="left">
                <CustomTitleLabel
                  message={`${roleName} ${
                    this.accountName || ''
                  } (PSN-${Common.getPartySiteNumber()})`}
                />
                <Divider hidden fitted />
                <Divider hidden fitted />
                <Divider hidden fitted />
                <Divider hidden fitted />
                <Button
                  style={{
                    backgroundColor: '#ffffff',
                    color: '#1b1c1d',
                    border: '1px solid #1b1c1d',
                  }}
                  compact
                  as={Link}
                  to={url}
                >
                  {translations['LexSwitchFile']}
                </Button>
              </Grid.Column>
              <Grid.Column width={8} verticalAlign="middle" textAlign="right">
                {/* <Button
                  color="blue"
                  primary
                  compact
                  as={Link}
                  to="/home/label-order-cart"
                >
                  View cart
                </Button> */}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <div style={{ border: '1px solid #DCDEE1' }}></div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Divider hidden />
          <div className="div-table">
            <Tab
              panes={panes}
              activeIndex={activeIndex}
              onTabChange={this.handleTabChange}
            ></Tab>
          </div>
          <Divider hidden />

          <Grid>
            <Grid.Row>
              <Grid.Column>
                <div style={{ border: '1px solid #DCDEE1' }}></div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column textAlign="right">
                <Button
                  style={{
                    backgroundColor: '#ffffff',
                    color: '#1b1c1d',
                    border: '1px solid #1b1c1d',
                  }}
                  as={Link}
                  to={url}
                >
                  {translations['LexBack']}
                </Button>
                <span style={{ marginRight: '10px' }}></span>
                <Button
                  primary
                  onClick={this.addToCart}
                  loading={loading}
                  disabled={!hasLineItems || loading}
                >
                  {translations['LexAddSelection']}
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Modal size="mini" open={openRemoveModal} onClose={this.handleCancel}>
            <Modal.Header>{translations['LexRemoveItem']}</Modal.Header>
            <Modal.Content>
              <p>{translations['LexComfimDelete']}</p>
            </Modal.Content>
            <Modal.Actions>
              <Button primary basic onClick={this.handleConfirmRemove}>
                {translations['LexYES']}
              </Button>
              <Button primary onClick={this.handleCancel}>
                {translations['LexNO']}
              </Button>
            </Modal.Actions>
          </Modal>
          <Modal size="mini" open={openModal} onClose={this.handleCloseModal}>
            <Modal.Header>{translations['LexValidation']}</Modal.Header>
            <Modal.Content>
              <p>{translations['Lexfillinnewlineorcancelthenewline']}</p>
            </Modal.Content>
            <Modal.Actions>
              <Button primary onClick={this.handleCloseModal}>
                {translations['LexClose']}
              </Button>
            </Modal.Actions>
          </Modal>
          <Modal
            size="mini"
            open={lengthExceededModal}
            onClose={this.handleCloseModal}
          >
            <Modal.Header>{translations['LexValidation']}</Modal.Header>
            <Modal.Content>
              <p>
                Max. allow 50 item lines. Please submit a new order for
                additional items. Thank you.
              </p>
            </Modal.Content>
            <Modal.Actions>
              <Button primary onClick={this.handleCloseModal}>
                Close
              </Button>
            </Modal.Actions>
          </Modal>
          <Modal
            size="mini"
            open={sameSupplierModal}
            onClose={this.handleCloseModal}
          >
            <Modal.Header>Information</Modal.Header>
            <Modal.Content>
              <p>{translations['LexSameLabelSupplierAlert']}</p>
            </Modal.Content>
            <Modal.Actions>
              <Button primary onClick={this.handleCloseModal}>
                Close
              </Button>
            </Modal.Actions>
          </Modal>
          <FooterHomeBlock />
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  hasError: state.existingCombinationLabels.hasError,
  hasMessage: state.existingCombinationLabels.hasMessage,
  message: state.existingCombinationLabels.message,
  loading: state.existingCombinationLabels.loading,
  data: state.existingCombinationLabels.data,
  itemNumber: state.existingCombinationLabels.itemNumber,
  description: state.existingCombinationLabels.description,
  articleNumber: state.existingCombinationLabels.articleNumber,
  totalPrice: state.existingCombinationLabels.totalPrice,
  selectedLabels: state.existingCombinationLabels.selectedLabels,
  sortOrder: state.existingCombinationLabels.sortOrder,
  sortColumn: state.existingCombinationLabels.sortColumn,
  currentPage: state.existingCombinationLabels.currentPage,
  pageSize: state.existingCombinationLabels.pageSize,
  totalPages: state.existingCombinationLabels.totalPages,
  addItemForm: state.existingCombinationLabels.addItemForm,
  itemList: state.existingCombinationLabels.itemList,
  form: state.existingCombinationLabels.form,
  forms: state.existingCombinationLabels.forms,
  labelOrderCart: state.labelOrderCart,
  isSuccessAddToCart: state.labels.isSuccessAddToCart,
  loading: state.labels.loading,
  selectedProductCovered:
    state.existingCombinationLabels.selectedProductCovered,
  labelCart: state.labelCart,
  listeeFileNumbers: state.existingCombinationLabels.listeeFileNumbers,
  hologramCcns: state.existingCombinationLabels.hologramCcns,
  isHologramCcnFetched: state.existingCombinationLabels.isHologramCcnFetched,
  labelSupplierPrinterEmails:
    state.existingCombinationLabels.labelSupplierPrinterEmails,
});

const mapDispatchToProps = dispatch => ({
  addExistingCombinationLabelsToCart: data =>
    dispatch(addExistingCombinationLabelsToCart(data)),
  getExistingCombinationLabels: (
    fileNumber,
    itemNumber,
    applicantAccount,
    manufacturerAccount,
    currentPage,
    pageSize,
    id,
    isCopied
  ) =>
    dispatch(
      getExistingCombinationLabels(
        fileNumber,
        itemNumber,
        applicantAccount,
        manufacturerAccount,
        currentPage,
        pageSize,
        id,
        isCopied
      )
    ),
  getExistingLabelOrdersByCartId: labelOrderCartId =>
    dispatch(getExistingLabelOrdersByCartId(labelOrderCartId)),
  initialize: () => dispatch(initialize()),
  updateExistingCombinationLabelFilters: (name, value) =>
    dispatch(updateExistingCombinationLabelFilters(name, value)),
  getCombinationLabelsByItemNumber: (
    itemNumber,
    id,
    fileNumber,
    ccn,
    labelAccount,
    volume,
    formIndex
  ) =>
    dispatch(
      getCombinationLabelsByItemNumber(
        itemNumber,
        id,
        fileNumber,
        ccn,
        labelAccount,
        volume,
        formIndex
      )
    ),
  updateSelectedLabel: (formIndex, form, forms) =>
    dispatch(updateSelectedLabel(formIndex, form, forms)),
  pushSelectedLabel: formIndex => dispatch(pushSelectedLabel(formIndex)),
  removeSelectedLabel: (formIndex, form, forms) =>
    dispatch(removeSelectedLabel(formIndex, form, forms)),
  getLabelSuppliers: (supplierDetails, currentPage, pageSize) =>
    dispatch(getLabelSuppliers(supplierDetails, currentPage, pageSize)),
  updateCombinationLabelsForm: (data, form) =>
    dispatch(updateCombinationLabelsForm(data, form)),
  addToCart: cartData => dispatch(addToCart(cartData)),
  resetAddToCart: () => dispatch(resetAddToCart()),
  updateItemList: (id, name, value) =>
    dispatch(updateItemList(id, name, value)),
  updateAttachment: (name, value, id, formIndex) =>
    dispatch(updateAttachment(name, value, id, formIndex)),
  uploadCombinationLabelAttachment: data =>
    dispatch(uploadCombinationLabelAttachment(data)),
  getLabelsByCcn: data => dispatch(getLabelsByCcn(data)),
  resetItemNumberOptions: () => dispatch(resetItemNumberOptions()),
  updateLabelOrderCartType: data => dispatch(updateLabelOrderCartType(data)),
  getListeeFileNumbers: (fileNumber, id, isCopied) =>
    dispatch(getListeeFileNumbers(fileNumber, id, isCopied)),
  cancelNewLine: formIndex => dispatch(cancelNewLine(formIndex)),
  getCartItems: labelUserId => dispatch(getCartItems(labelUserId)),
  resetCartItemsForm: () => dispatch(resetCartItemsForm()),
  getHologramCcns: ccnList => dispatch(getHologramCcns(ccnList)),
  getLabelSupplierPrinterEmail: (printerFileNumber, printerPsn) =>
    dispatch(getLabelSupplierPrinterEmail(printerFileNumber, printerPsn)),
  getApplicantFileNumbers: (
    sortColumn,
    sortOrder,
    currentPage,
    pageSize,
    selectedFileNumbers,
    selectedVolumes,
    selectedCcns,
    selectedApplicantNames,
    selectedApplicantAddress,
    selectedApplicantAccountNumbers,
    labelOrderType,
    isFirstLoad
  ) =>
    dispatch(
      getFileNumbers(
        sortColumn,
        sortOrder,
        currentPage,
        pageSize,
        selectedFileNumbers,
        selectedVolumes,
        selectedCcns,
        selectedApplicantNames,
        selectedApplicantAddress,
        selectedApplicantAccountNumbers,
        labelOrderType,
        isFirstLoad
      )
    ),
  getActiveManufacturers: (
    currentPage,
    pageSize,
    fileNumber,
    volume,
    selectedFileNumbers,
    selectedVolumes,
    selectedCcns,
    selectedAccounts,
    selectedNames,
    selectedAddress,
    labelOrderType,
    firstload
  ) =>
    dispatch(
      getActiveManufacturers(
        currentPage,
        pageSize,
        fileNumber,
        volume,
        selectedFileNumbers,
        selectedVolumes,
        selectedCcns,
        selectedAccounts,
        selectedNames,
        selectedAddress,
        labelOrderType,
        firstload
      )
    ),
  scanFile: data => dispatch(scanFile(data)),
  getCombinationLabelsByPartNumber: (
    customerPartNumber,
    id,
    fileNumber,
    ccn,
    labelAccount,
    volume,
    formIndex
  ) =>
    dispatch(
      getCombinationLabelsByPartNumber(
        customerPartNumber,
        id,
        fileNumber,
        ccn,
        labelAccount,
        volume,
        formIndex
      )
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExistingCombinationLabels);
