import React, { Component, Fragment } from "react";
import FinalSiteDetails from "../site-location/final-site-details";
import AuthorityJurisdictionDetails from "../authority-jurisdiction/authority-jurisdiction-details";
import {
  Grid,
  Form,
  Dropdown,
  Input,
  Checkbox,
  Icon,
  Popup,
  TextArea,
  Divider,
  Label,
  Card,
  Feed
} from "semantic-ui-react";
import {
  validateSubFormField,
  validateField,
  getError,
  createValidation
} from "../../../../../validator/validator";
import { CustomLabel } from "../../../../../components/labels/labels";
import { GetFormData } from "../../../field-evaluation-helper";
import { connect } from "react-redux";

import * as projActions from "../../project-details/project-details.action";
import * as accountActions from "../../../account-settings/account-settings.action";
import * as labelCartActions from "../../../../manufacturer/standard/label-cart/label-cart.action";
import * as schedulingActions from "../../scheduling-details/scheduling-details.action";
import { Common } from "../../../../../common";
import Role from "../../../../../enums/role";
import { GetFormDataRD } from "../../../field-evaluation-helper";

import {
  ADrules,
  ADADrules,
  ADABTLDrules,
  ADQADrules,
  SDRules,
  SDPSLSrules,
  SDFISSrules,
  SDAHJDrules,
  PDrules,
  RDPSLSrules,
  RDFSLSrules,
  RDAHJDrules,
  RDrules,
  ASrules,
  FErules,
  SDOtherDetailsRules,
  SDFSLSrules
} from "../../../validation-rules";
import ToastHelper from "../../../../../helpers/toast.helper";
import _ from "lodash";
import PrelimSiteDetails from "../site-location/prelim-site-details";

class SiteDetails extends Component {
  state = { hasRenderedSites: false, hasRenderedAhjs: false };

  componentDidMount() {
    var accountSettingsPsn = Common.getAccountSettingsPsn();
    var userRole = Common.getUserRole();
    let { orderNumber, createdForUserId } = this.props;

    if (userRole == Role.projectHandler && accountSettingsPsn) {
      this.props.getAccountSettingsByPsn(accountSettingsPsn);
      this.props.getCustomerDetails(accountSettingsPsn);
    } else {
      if (orderNumber) {
        this.props.getAccountSettings(createdForUserId);
      } else {
        this.props.getAccountSettings();
      }
    }

    // this.props.getSiteCountries(1);
    this.props.getSiteCountries(2);

    this.props.getAhjCountries();
  }

  getErrorForChkClass(field) {
    // const msg = this.getErrorFor(field);
    const msg = "";

    return msg && msg.length ? "error" : "";
  }

  getErrorForChk(field) {
    // const msg = this.getErrorFor(field);
    const msg = "";
    if (!msg || (msg && !msg.length)) return null;

    return (
      <React.Fragment>
        <br />
        <div className="ui pointing above prompt label chk-rad-error">
          {msg}
        </div>
      </React.Fragment>
    );
  }

  handleGetSiteStates = (countryCode, locationType) => {
    this.props.getSiteStates(countryCode, locationType);
  };

  handleGetSiteCities = (stateCode, locationType) => {
    this.props.getSiteCities(stateCode, locationType);
  };

  handleGetAhjStates = (countryCode) => {
    this.props.getAhjStates(countryCode);
  };

  handleGetAhjCities = (stateCode) => {
    this.props.getAhjCities(stateCode);
  };

  handleChange = (e, key) => {
    this.updateSiteDetailsForm(e.target.name, e.target.value, key);
  };

  handleDropdownChange = (dpName, dpValue, key) => {
    this.updateSiteDetailsForm(dpName, dpValue, key);
  };

  handleCheckboxChange = (cbName, cbValue, key) => {
    switch (cbName) {
      case "showPrelimLocation":
        // Set Default Value
        this.updateSiteDetailsForm("isProjectTab", true, "others");
        // this.updateSiteDetailsForm("isReadyToScheduleNow", false, "others");
        this.updateSiteDetailsForm(cbName, cbValue, key);
        break;
      case "prelimSameAsApplicant":
        this.updateSiteDetailsForm(cbName, cbValue, key);
        break;
      case "finalSameAsApplicant":
        this.updateSiteDetailsForm(cbName, cbValue, key);
        break;
      case "isReadyToScheduleNow":
        this.handleScheduleNow(cbName, cbValue, key);

        break;
      case "isFinalSameAsApplicant":
        this.handleFinalSameAsApplicant(cbName, cbValue, key);
        break;
    }
  };

  handleFinalSameAsApplicant = (name, value, key) => {
    const { siteDetailForm } = this.props;

    this.updateSiteDetailsForm(name, value, key);

    var prelim = siteDetailForm?.prelim;
    var final = siteDetailForm?.final;

    if (siteDetailForm.others.isFinalSameAsApplicant) {
      for (var propertyName in prelim) {
        this.updateSiteDetailsForm(propertyName, prelim[propertyName], "final");
      }
    } else {
      var final = _.omit(siteDetailForm?.final, [
        "showErrors",
        "validationErrors",
        "data"
      ]);

      for (var propertyName in final) {
        this.updateSiteDetailsForm(propertyName, "", "final");
      }
    }
  };

  handleScheduleNow = (cbName, cbValue, key) => {
    const { siteDetailForm } = this.props;
    if (siteDetailForm?.others?.showPrelimLocation) {
      siteDetailForm.final.validationErrors = createValidation(
        siteDetailForm.final,
        this.getFormRule("final")
      );

      if (siteDetailForm.others.showPrelimLocation === "false") {
        siteDetailForm.prelim.validationErrors = createValidation(
          siteDetailForm.prelim,
          this.getFormRule("prelim")
        );
      }

      if (
        Object.IsEmpty(siteDetailForm.final.validationErrors) ||
        Object.IsEmpty(siteDetailForm.prelim.validationErrors)
      ) {
        this.updateSiteDetailsForm(cbName, cbValue, key);

        // 1 - project details
        // 2 - engineer details
        var activeIndex = cbValue ? 1 : 0;
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        this.props.handleChangeTabIndex(activeIndex);
      } else {
        this.displayRequiredFieldsError();
      }
    }
  };

  handleFillFinalSiteDetails = (value, key) => {
    const { accountSettings, siteDetailForm } = this.props;

    var fieldValue =
      accountSettings.applicantDetails.applicantBillToLocationDetails &&
      accountSettings.applicantDetails.applicantBillToLocationDetails.isFromOracle.split(
        "|"
      );

    if (value == "true") {
      var finalDetails = {
        residenceName:
          accountSettings.applicantDetails.applicantBillToLocationDetails
            .legalCompanyName,
        residenceNameEnable: fieldValue && fieldValue[0],
        addressLine1:
          accountSettings.applicantDetails.applicantBillToLocationDetails
            .addressLine1,
        addressLine1Enable: fieldValue && fieldValue[1],
        addressLine2:
          accountSettings.applicantDetails.applicantBillToLocationDetails
            .addressLine2,
        addressLine2Enable: fieldValue && fieldValue[2],
        addressLine3:
          accountSettings.applicantDetails.applicantBillToLocationDetails
            .addressLine3,
        addressLine3Enable: fieldValue && fieldValue[3],
        countryCode:
          accountSettings.applicantDetails.applicantBillToLocationDetails
            .country,
        country:
          accountSettings.applicantDetails.applicantBillToLocationDetails
            .country,
        countryEnable: fieldValue && fieldValue[4],
        state:
          accountSettings.applicantDetails.applicantBillToLocationDetails.state,
        stateEnable: fieldValue && fieldValue[5],
        city:
          accountSettings.applicantDetails.applicantBillToLocationDetails.city,
        cityEnable: fieldValue && fieldValue[6],
        postalCode:
          accountSettings.applicantDetails.applicantBillToLocationDetails
            .postalCode,
        postalCodeEnable: fieldValue && fieldValue[7],
        siteContactName:
          accountSettings.applicantDetails.applicantBillToLocationDetails
            .contactName,
        siteContactNameEnable: fieldValue && fieldValue[8],
        siteContactOfficeNumber:
          accountSettings.applicantDetails.applicantBillToLocationDetails
            .phoneNumber,
        siteContactOfficeNumberEnable: fieldValue && fieldValue[9],
        siteContactMobileNumber:
          accountSettings.applicantDetails.applicantBillToLocationDetails
            .mobileNumber,
        siteContactMobileNumberEnable: fieldValue && fieldValue[10],
        siteContactEmailAddress:
          accountSettings.applicantDetails.applicantBillToLocationDetails
            .emailAddress,
        siteContactEmailAddressEnable: fieldValue && fieldValue[11]
      };

      for (var propertyName in finalDetails) {
        this.updateSiteDetailsForm(
          propertyName,
          finalDetails[propertyName],
          key
        );
      }
    } else {
      var final = _.omit(siteDetailForm?.final, [
        "showErrors",
        "validationErrors",
        "data"
      ]);

      for (var propertyName in final) {
        this.updateSiteDetailsForm(propertyName, "", key);
      }
    }

    if (value == "true") {
      this.handleGetSiteStates(finalDetails.countryCode, 2);
      this.handleGetSiteCities(finalDetails.state, 2);
    }
  };

  handleFillPrelimSiteDetails = (value, key) => {
    const { accountSettings, siteDetailForm } = this.props;

    var fieldValue =
      accountSettings.applicantDetails.applicantBillToLocationDetails &&
      accountSettings.applicantDetails.applicantBillToLocationDetails.isFromOracle.split(
        "|"
      );

    if (value == "true") {
      var prelimDetails = {
        residenceName:
          accountSettings.applicantDetails.applicantBillToLocationDetails
            .legalCompanyName,
        residenceNameEnable: fieldValue && fieldValue[0],
        addressLine1:
          accountSettings.applicantDetails.applicantBillToLocationDetails
            .addressLine1,
        addressLine1Enable: fieldValue && fieldValue[1],
        addressLine2:
          accountSettings.applicantDetails.applicantBillToLocationDetails
            .addressLine2,
        addressLine2Enable: fieldValue && fieldValue[2],
        addressLine3:
          accountSettings.applicantDetails.applicantBillToLocationDetails
            .addressLine3,
        addressLine3Enable: fieldValue && fieldValue[3],
        countryCode:
          accountSettings.applicantDetails.applicantBillToLocationDetails
            .country,
        country:
          accountSettings.applicantDetails.applicantBillToLocationDetails
            .country,
        countryEnable: fieldValue && fieldValue[4],
        state:
          accountSettings.applicantDetails.applicantBillToLocationDetails.state,
        stateEnable: fieldValue && fieldValue[5],
        city:
          accountSettings.applicantDetails.applicantBillToLocationDetails.city,
        cityEnable: fieldValue && fieldValue[6],
        postalCode:
          accountSettings.applicantDetails.applicantBillToLocationDetails
            .postalCode,
        postalCodeEnable: fieldValue && fieldValue[7],
        siteContactName:
          accountSettings.applicantDetails.applicantBillToLocationDetails
            .contactName,
        siteContactNameEnable: fieldValue && fieldValue[8],
        siteContactOfficeNumber:
          accountSettings.applicantDetails.applicantBillToLocationDetails
            .phoneNumber,
        siteContactOfficeNumberEnable: fieldValue && fieldValue[9],
        siteContactMobileNumber:
          accountSettings.applicantDetails.applicantBillToLocationDetails
            .mobileNumber,
        siteContactMobileNumberEnable: fieldValue && fieldValue[10],
        siteContactEmailAddress:
          accountSettings.applicantDetails.applicantBillToLocationDetails
            .emailAddress,
        siteContactEmailAddressEnable: fieldValue && fieldValue[11]
      };

      for (var propertyName in prelimDetails) {
        this.updateSiteDetailsForm(
          propertyName,
          prelimDetails[propertyName],
          key
        );
      }
    } else {
      var prelim = _.omit(siteDetailForm?.prelim, [
        "showErrors",
        "validationErrors",
        "data"
      ]);

      for (var propertyName in prelim) {
        this.updateSiteDetailsForm(propertyName, "", key);
      }
    }

    if (value == "true") {
      this.handleGetSiteStates(prelimDetails.countryCode, 2);
      this.handleGetSiteCities(prelimDetails.state, 2);
    }
  };

  handleFillSchedulingForm = () => {
    const { index } = this.props;
    const { siteDetails } = this.props.projectDetails;

    this.props.getEngineerStates(siteDetails[index]?.final.countryCode, 2);
    this.props.getEngineerCities(siteDetails[index]?.final.state, 2);

    this.props.getEngineerAhjStates(siteDetails[index]?.ahj.country, 2);
    this.props.getEngineerAhjCities(siteDetails[index]?.ahj.state, 2);

    this.props.populateSchedulingForm(index, siteDetails);
  };

  updateSiteDetailsForm = (name, value, key) => {
    const {
      index,
      updateSiteDetailsForm,
      projectDetails: { siteDetails }
    } = this.props;

    const form = siteDetails[index][key];

    var formRule = this.getFormRule(key);

    validateField(formRule, form, name, value);

    let newForm = Object.assign({}, form, {
      [name]: value
    });

    updateSiteDetailsForm(index, newForm, siteDetails, key);

    if (
      (name === "showPrelimLocation" && value === "false") ||
      name === "isReadyToScheduleNow" ||
      (key === "final" && name === "state") ||
      (key === "final" && name === "city")
    ) {
      setTimeout(() => {
        this.props.checkManualPricingTrigger(value, name, index);
      }, 100);
    }
  };

  updateFormObject = (obj, key) => {
    const {
      index,
      updateSiteDetailsForm,
      projectDetails: { siteDetails }
    } = this.props;

    const form = siteDetails[index][key];

    // let newForm = Object.assign({}, form, {
    //   [name]: value
    // });

    // updateSiteDetailsForm(index, newForm, siteDetails, key);
  };

  getErrorFor = (field, key) => {
    const {
      index,
      updateSiteDetailsForm,
      projectDetails: { siteDetails }
    } = this.props;

    const form = siteDetails[index][key];

    let errors = getError(field, form, form.data);
    return errors;
  };

  getFormRule = (key) => {
    let rule = null;
    switch (key) {
      case "prelim":
        rule = RDPSLSrules;
        break;
      case "final":
        rule = RDFSLSrules;
        break;
      case "ahj":
        rule = RDAHJDrules;
        break;
      case "others":
        rule = SDOtherDetailsRules;
    }
    return rule;
  };

  displayRequiredFieldsError() {
    setTimeout(() => {
      ToastHelper.error({
        title: "Error",
        description: "Please complete all the required fields"
      });
    }, 100);
  }

  renderExistingStatesCities = () => {
    const { siteDetailForm } = this.props;

    const { hasRenderedSites, hasRenderedAhjs } = this.state;

    if (siteDetailForm) {
      var final = siteDetailForm.final;
      var ahj = siteDetailForm.ahj;

      if (final && final.fieldEvaluationOrderId && !hasRenderedSites) {
        this.handleGetSiteStates(final.country, 2);
        this.handleGetSiteCities(final.state, 2);

        this.setState({ hasRenderedSites: true });
      }

      if (ahj && ahj.fieldEvaluationOrderId && !hasRenderedAhjs) {
        this.handleGetAhjStates(ahj.country);
        this.handleGetAhjCities(ahj.state);

        this.setState({ hasRenderedAhjs: true });
      }
    }
  };

  render() {
    const {
      index,
      form,
      siteDetailForm,
      otherDetails,
      accountSettings
    } = this.props;

    const {
      countryOptionsFinalSiteDetails,
      stateOptionsFinalSiteDetails,
      cityOptionsFinalSiteDetails,
      countryOptionsAhjSiteDetails,
      stateOptionsAhjSiteDetails,
      cityOptionsAhjSiteDetails
    } = this.props.projectDetails;

    let userRole = Common.getUserRole();

    this.renderExistingStatesCities();

    let specialNotes =
      ((siteDetailForm || {}).others || {}).specialNotesList || [];

    return (
      <Form>
        <Divider hidden />
        <CustomLabel message="SITE DETAILS" />
        <Divider fitted />
        <Divider hidden />

        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Form.Field
                className={`rad-chk-field ${this.getErrorForChkClass(
                  "showPrelimLocation"
                )}`}
              >
                {"* Is equipment already installed?"}
              </Form.Field>
              <Form.Field
                className={this.getErrorForChkClass("showPrelimLocation")}
              >
                <Checkbox
                  radio
                  label="Yes"
                  name="showPrelimLocation"
                  value="true"
                  checked={
                    siteDetailForm?.others?.showPrelimLocation === "true"
                  }
                  onChange={(e, { value }) => {
                    this.handleCheckboxChange(
                      "showPrelimLocation",
                      value,
                      "others"
                    );
                  }}
                />
                &nbsp;&nbsp;&nbsp;
                <Checkbox
                  radio
                  label="No"
                  name="showPrelimLocation"
                  value="false"
                  checked={
                    siteDetailForm?.others?.showPrelimLocation === "false"
                  }
                  onChange={(e, { value }) => {
                    this.handleCheckboxChange(
                      "showPrelimLocation",
                      value,
                      "others"
                    );
                  }}
                />
                {this.getErrorForChk("showPrelimLocation")}
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        {siteDetailForm?.others?.showPrelimLocation == "true" && (
          <span>
            <FinalSiteDetails
              siteDetails={siteDetailForm}
              finalDetails={siteDetailForm?.final}
              formType="final"
              handleCheckboxChange={this.handleCheckboxChange}
              handleChange={this.handleChange}
              handleDropdownChange={this.handleDropdownChange}
              getErrorFor={this.getErrorFor}
              countryOptions={countryOptionsFinalSiteDetails}
              stateOptions={stateOptionsFinalSiteDetails}
              cityOptions={cityOptionsFinalSiteDetails}
              handleGetProjectStates={this.handleGetSiteStates}
              handleGetProjectCities={this.handleGetSiteCities}
              handleFillFinalSiteDetails={this.handleFillFinalSiteDetails}
            />

            <AuthorityJurisdictionDetails
              siteDetails={siteDetailForm}
              ahjDetails={siteDetailForm?.ahj}
              countryOptions={countryOptionsAhjSiteDetails}
              stateOptions={stateOptionsAhjSiteDetails}
              cityOptions={cityOptionsAhjSiteDetails}
              getErrorFor={this.getErrorFor}
              formType="ahj"
              handleCheckboxChange={this.handleCheckboxChange}
              handleChange={this.handleChange}
              handleDropdownChange={this.handleDropdownChange}
              handleGetProjectStates={this.handleGetAhjStates}
              handleGetProjectCities={this.handleGetAhjCities}
            />
          </span>
        )}

        {siteDetailForm?.others?.showPrelimLocation == "false" && (
          <span>
            <PrelimSiteDetails
              siteDetails={siteDetailForm}
              prelimDetails={siteDetailForm?.prelim}
              formType="prelim"
              handleCheckboxChange={this.handleCheckboxChange}
              handleChange={this.handleChange}
              handleDropdownChange={this.handleDropdownChange}
              getErrorFor={this.getErrorFor}
              countryOptions={countryOptionsFinalSiteDetails}
              stateOptions={stateOptionsFinalSiteDetails}
              cityOptions={cityOptionsFinalSiteDetails}
              handleGetProjectStates={this.handleGetSiteStates}
              handleGetProjectCities={this.handleGetSiteCities}
              handleFillPrelimSiteDetails={this.handleFillPrelimSiteDetails}
            />

            <FinalSiteDetails
              siteDetails={siteDetailForm}
              finalDetails={siteDetailForm?.final}
              formType="final"
              handleCheckboxChange={this.handleCheckboxChange}
              handleChange={this.handleChange}
              handleDropdownChange={this.handleDropdownChange}
              getErrorFor={this.getErrorFor}
              countryOptions={countryOptionsFinalSiteDetails}
              stateOptions={stateOptionsFinalSiteDetails}
              cityOptions={cityOptionsFinalSiteDetails}
              handleGetProjectStates={this.handleGetSiteStates}
              handleGetProjectCities={this.handleGetSiteCities}
              handleFillFinalSiteDetails={this.handleFillFinalSiteDetails}
            />

            <AuthorityJurisdictionDetails
              siteDetails={siteDetailForm}
              ahjDetails={siteDetailForm?.ahj}
              countryOptions={countryOptionsAhjSiteDetails}
              stateOptions={stateOptionsAhjSiteDetails}
              cityOptions={cityOptionsAhjSiteDetails}
              getErrorFor={this.getErrorFor}
              formType="ahj"
              handleCheckboxChange={this.handleCheckboxChange}
              handleChange={this.handleChange}
              handleDropdownChange={this.handleDropdownChange}
              handleGetProjectStates={this.handleGetAhjStates}
              handleGetProjectCities={this.handleGetAhjCities}
            />
          </span>
        )}

        {siteDetailForm?.others?.showPrelimLocation && (
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Grid.Row>
                  <Grid.Column>
                    <Form.Field>Are you ready to schedule now?</Form.Field>
                    <Form.Field>
                      <Checkbox
                        radio
                        label="Yes"
                        name="isReadyToScheduleNow"
                        value={true}
                        checked={
                          siteDetailForm?.others?.isReadyToScheduleNow === true
                        }
                        onChange={(e, { value }) => {
                          this.handleCheckboxChange(
                            "isReadyToScheduleNow",
                            value,
                            "others"
                          );
                          this.handleFillSchedulingForm();
                        }}
                      />
                      &nbsp;&nbsp;&nbsp;
                      <Checkbox
                        radio
                        label="No"
                        name="isReadyToScheduleNow"
                        value={false}
                        checked={
                          siteDetailForm?.others?.isReadyToScheduleNow === false
                        }
                        onChange={(e, { value }) => {
                          this.handleCheckboxChange(
                            "isReadyToScheduleNow",
                            value,
                            "others"
                          );
                          // this.handleFillSchedulingDetails(value);
                        }}
                      />
                      {/* {this.getReadyToScheduleNowError() ? (
                        <React.Fragment>
                          <br />
                          <div className="ui pointing above prompt label ready-schedule">
                            {otherDetails.validationErrors.isReadyToScheduleNow}
                          </div>
                        </React.Fragment>
                      ) : null} */}
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
        <Divider hidden />
        <CustomLabel message="OTHER DETAILS" />
        <Divider fitted />
        <Divider hidden />
        <Grid>
          <Grid.Row>
            <Grid.Column width="4">
              <Form.Field
                id="form-input-control-lcn"
                control={Input}
                label="Customer Reference Number"
                placeholder="Enter Customer Reference Number"
                name="customerReferenceNumber"
                onChange={(e) => {
                  this.handleChange(e, "others");
                }}
                value={siteDetailForm?.others?.customerReferenceNumber || ""}
                //error={this.props.getErrorFor("residenceName", "SD-FISS")}
                autoComplete="off"
                disabled={
                  accountSettings.accountSettings &&
                  !accountSettings.accountSettings.isReferenceNoUsed
                }
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>

        {userRole === Role.projectHandler ? (
          <Fragment>
            <Divider hidden />
            <CustomLabel message="QUOTE SPECIAL NOTES" />
            <Divider fitted />
            <Divider hidden />

            <Grid>
              {specialNotes && specialNotes.length > 0 ? (
                <Grid.Row>
                  <Grid.Column>
                    <Card fluid>
                      <Card.Content>
                        <Feed>
                          {specialNotes.map((sn) => (
                            <Fragment>
                              <Feed.Event>
                                <div className="margin-right-5">
                                  <Icon
                                    className="initial"
                                    size="large"
                                    circular
                                    inverted
                                    color="blue"
                                  >
                                    {Common.getMyInitialName(
                                      sn.user.firstName,
                                      sn.user.lastName
                                    )}
                                  </Icon>
                                </div>
                                <Feed.Content>
                                  <Feed.Date
                                    content={`${sn.user.firstName} ${sn.user.lastName}`}
                                  />
                                  <span>{sn.note}</span>
                                </Feed.Content>
                              </Feed.Event>
                              <br />
                            </Fragment>
                          ))}
                        </Feed>
                      </Card.Content>
                    </Card>
                  </Grid.Column>
                </Grid.Row>
              ) : null}
              <Grid.Row>
                <Grid.Column>
                  <label>Special Notes</label>
                  <TextArea
                    name="specialNotes"
                    autoComplete="off"
                    className="margin-top-5"
                    onChange={(e) => {
                      this.handleChange(e, "others");
                    }}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Fragment>
        ) : null}
      </Form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    fieldService: state.fieldService,
    projectDetails: state.feProjectDetails,
    schedulingDetails: state.schedulingDetails,
    customerDetails: state.labelCart.customerDetails,
    accountSettings: state.accountSettings.form,
    accountDetails: state.accountSettings
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSiteCountries: (locationType) =>
      dispatch(projActions.getSiteCountries(locationType)),
    getSiteStates: (countryCode, locationType) =>
      dispatch(projActions.getSiteStates(countryCode, locationType)),
    getSiteCities: (stateCode, locationType) =>
      dispatch(projActions.getSiteCities(stateCode, locationType)),
    getAhjCountries: () => dispatch(projActions.getAhjCountries()),
    getAhjStates: (countryCode) =>
      dispatch(projActions.getAhjStates(countryCode)),
    getAhjCities: (stateCode) => dispatch(projActions.getAhjCities(stateCode)),
    updateSiteDetailsForm: (idx, form, forms, key) =>
      dispatch(projActions.updateSiteDetailsForm(idx, form, forms, key)),
    getAccountSettings: (userId) =>
      dispatch(accountActions.getAccountSettings(userId)),
    getAccountSettingsByPsn: (psn) =>
      dispatch(accountActions.getAccountSettingsByPsn(psn)),
    getCustomerDetails: (psn) =>
      dispatch(labelCartActions.getCustomerDetails(psn)),
    getEngineerStates: (countryCode, locationType) =>
      dispatch(schedulingActions.getEngineerStates(countryCode, locationType)),
    getEngineerCities: (stateCode, locationType) =>
      dispatch(schedulingActions.getEngineerCities(stateCode, locationType)),
    getEngineerAhjStates: (countryCode) =>
      dispatch(schedulingActions.getAhjStates(countryCode)),
    getEngineerAhjCities: (stateCode) =>
      dispatch(schedulingActions.getAhjCities(stateCode)),
    populateSchedulingForm: (index, data) =>
      dispatch(schedulingActions.populateSchedulingForm(index, data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SiteDetails);
