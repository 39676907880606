import * as types from "./admin-search.type";

let initialState = {
  data: {
    currentPage: 1,
    pageSize: 5,
    totalPages: 1,
    sortColumn: null,
    sortOrder: null
  },
  form: {},
  adminSearchResult: {}
};

const adminSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
    case types.ADMIN_SEARCH_REQUEST:
      return Object.assign({}, state, {
        data: action.data || initialState.data,
        loading: true
      });
    case types.ADMIN_SEARCH_SUCCESS:
    case types.ADMIN_SEARCH_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        currentPage: action.data.currentPage,
        pageSize: action.data.pageSize,
        totalPages: action.data.totalPages,
        data: action.data || initialState.data
      });
    case types.FORM:
      return Object.assign({}, state, {
        form: action.data || initialState.form
      });
    case types.CLEAR:
      return Object.assign({}, state, {
        data: initialState.data,
        form: {}
      });
  }
};

export default adminSearchReducer;
