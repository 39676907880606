import Dashboard from '../views/dashboard/dashboard';
import ViewOrders from '../views/view-orders/view-orders';
import FileVolumes from '../views/manufacturer/standard/file-volumes/file-volumes';
import Labels from '../views/manufacturer/standard/labels/labels';
import ApplicantDashboard from '../views/applicant/standard/applicant-dashboard';
import LabelCart from '../views/manufacturer/standard/label-cart/label-cart';
import ExistingCombinationLabels from '../views/order/combination/existing-combination-labels';
import GenericLabelOrderCart from '../views/label-order-cart/cart/label-order-cart';
import GenericLabelOrderCartReview from '../views/label-order-cart/cart-review/label-order-cart-review';
import LabelOrderConfirmation from '../views/applicant/standard/label-order-confirmation';
import UserProfile from '../views/user/profile/profile';
import OrderMain from '../views/order/order-main';
import NewQuote from '../views/field-evaluation/new-quote';
import AdminSearch from '../views/admin-search/admin-search';
//import AdminSearchResult from "../views/admin-search/admin-search-result";
import CancelOrder from '../views/order/cancel-order/cancel-order';
import Logout from '../components/logout/logout';
import ViewHistory from '../views/field-evaluation/view-history/view-history';
import ViewOrdersDetails from '../views/field-evaluation/view-order-details/view-order-details';
import OrderDetails from '../views/field-evaluation/order-details';
import SearchQuote from '../views/field-evaluation/search-quote';
import ViewDraft from '../views/field-evaluation/view-draft/view-draft';
import FeCancelOrder from '../views/field-evaluation/cancel-order/cancel-order';
import LabelOrderDetails from '../views/label-order/label-order-details';
import RoleAssignment from '../views/role-assignment/role-assignment';
// import Auth from "../views/auth/auth";
import viewDraftQuote from '../views/field-evaluation/view-draft-quote/view-draft-quote';
import viewQuoteDetails from '../views/field-evaluation/view-quote-details/view-quote-details';
// import ErrorPage from "../views/error-page/error-page";
// import CartMain from "../views/order/cart-main";
import AccountSettings from '../views/field-evaluation/account-settings/account-settings';
// import FeQuotation from "../views/field-evaluation/product-details/fe/quotation";
// import FesQuotation from "../views/field-evaluation/product-details/fes/quotation";
import Quotation from '../views/field-evaluation/product-details/quotation';
import Notifications from '../views/field-evaluation/notifications/notifications';
import ViewAssigned from '../views/field-evaluation/view-assigned/view-assigned';
import FeAdminSearch from '../views/field-evaluation/fe-admin-search/fe-admin-search';
import QuoteSites from '../views/field-evaluation/field-service/quote-sites';
import NewQuoteDetails from '../views/field-evaluation/field-service/new-quote-details/new-quote-details';
import FieldServiceQuotation from '../views/field-evaluation/field-service/common/quotation/quotation';
import HelpMe from '../views/help-me/customer/customer-page';
import AdminPage from '../views/help-me/admin/admin-page';
import HealthDashBoard from '../views/health-dashboard/health-dashboard';
import PriceManagement from '../views/price-management/price-management';
import CombinationLabelSearch from '../views/combination-label-search/combination-label-search';
// import ViewOrderHistoryMaintenance from "../views/error-page/view-order-history-maintenance-page";

const routes = [
  {
    name: 'view-orders',
    to: '/home/view-orders',
    value: 'View Order History',
    icon: 'bell',
    component: ViewOrders,
    //component: ViewOrderHistoryMaintenance,
    sidebar: true,
  },
  {
    name: 'combination-label-item-search',
    to: '/home/combination-label-item-search',
    value: 'Combination Label Search',
    icon: 'search',
    component: CombinationLabelSearch,
    sidebar: true,
  },
  {
    name: 'home',
    to: '/home/',
    value: 'Notifications',
    icon: 'bell outline',
    component: Dashboard,
    sidebar: true,
  },
  {
    name: 'applicant',
    to: '/home/applicant-dashboard/:labelOrderType',
    value: 'Applicant Dashboard',
    icon: 'linkify',
    component: ApplicantDashboard,
    sidebar: false,
  },
  // {
  //   name: "dashboard",
  //   to: "/home/dashboard",
  //   value: "Dashboard",
  //   icon: "linkify",
  //   component: Dashboard,
  //   sidebar: true
  // },
  {
    name: 'view-order-cart',
    to: '/home/label-order-cart/',
    value: 'View Order Cart',
    icon: 'cart',
    component: LabelCart,
    sidebar: true,
  },
  {
    name: 'dashboard',
    to: '/home',
    value: '',
    icon: '',
    component: Dashboard,
    sidebar: false,
  },
  {
    name: 'view-order-cart',
    to: '/home/view-order-cart/:id',
    value: '',
    icon: '',
    component: ViewOrdersDetails,
    sidebar: false,
  },
  {
    name: 'order-standard-label',
    to: '/home/order-standard-label/:labelOrderType',
    value: '',
    icon: '',
    component: FileVolumes,
    sidebar: false,
  },
  // {
  //   name: "label-order-manufacturer",
  //   to: "/home/label-order/:fileNumber/:volume/:ccn?",
  //   value: "",
  //   icon: "",
  //   component: LabelOrder,
  //   sidebar: false
  // },
  {
    name: 'order-main',
    to: '/home/order-main',
    value: '',
    icon: '',
    component: OrderMain,
    sidebar: false,
  },
  {
    name: 'label-order',
    to: '/home/label-order/:fileNumber/:customerId/:manufacturerId/:volume/:ccn/:accountNumber',
    value: '',
    icon: '',
    component: Labels,
    sidebar: false,
  },
  {
    name: 'label-order',
    to: '/home/label-order/:fileNumberList/:ccnList/:accountNumber/:volumeList',
    value: '',
    icon: '',
    component: Labels,
    sidebar: false,
  },
  {
    name: 'label-order-cart',
    to: '/home/label-order-cart',
    value: '',
    icon: '',
    component: LabelCart,
    sidebar: false,
  },
  {
    name: 'label-order-cart',
    to: '/home/label-order-cart/:userId/:psn',
    value: '',
    icon: '',
    component: LabelCart,
    sidebar: false,
  },
  {
    name: 'label-order-confirmation',
    to: '/home/label-order-confirmation',
    value: '',
    icon: '',
    component: LabelOrderConfirmation,
    sidebar: false,
  },
  {
    name: 'view-draft-cart-details',
    to: '/home/draft-cart-details/:labelOrderCartId',
    value: '',
    icon: '',
    component: Labels,
    sidebar: false,
  },
  {
    name: 'copy-order',
    to: '/home/copy-order/:labelOrderCartId/:isCopied/:labelOrderType/:fileNumber/:accountNumber',
    value: '',
    icon: '',
    component: Labels,
    sidebar: false,
  },
  {
    name: 'existing-combination-labels',
    to: '/home/existing-combination-labels/:fileNumber/:customerId/:manufacturerId/:volume/:ccn/:accountNumber',
    value: '',
    icon: '',
    component: ExistingCombinationLabels,
    sidebar: false,
  },
  {
    name: 'existing-combination-labels',
    to: '/home/existing-combination-labels/:fileNumberList/:volumeList/:ccnList/:accountNumber',
    value: '',
    icon: '',
    component: ExistingCombinationLabels,
    sidebar: false,
  },
  {
    name: 'copy-order',
    to: '/home/combination-copy-order/:labelOrderCartId/:isCopied/:labelOrderType/:fileNumber',
    value: '',
    icon: '',
    component: ExistingCombinationLabels,
    sidebar: false,
  },
  {
    name: 'user-profile',
    to: '/home/user-profile',
    value: '',
    icon: '',
    component: UserProfile,
    sidebar: false,
  },
  {
    name: 'generic-label-order-cart',
    to: '/home/generic-label-order-cart',
    value: '',
    icon: '',
    component: GenericLabelOrderCart,
    sidebar: false,
  },
  {
    name: 'generic-label-order-cart-review',
    to: '/home/generic-label-order-cart-review',
    value: '',
    icon: '',
    component: GenericLabelOrderCartReview,
    sidebar: false,
  },
  {
    name: 'admin-search',
    to: '/home/admin-search',
    value: 'Global admin search',
    icon: 'search',
    component: AdminSearch,
    sidebar: true,
  },
  {
    name: 'fe-admin-search',
    to: '/home/fe/admin-search',
    value: 'Global admin search',
    icon: 'search',
    component: FeAdminSearch,
    sidebar: true,
  },
  {
    name: 'new-quote-order',
    to: '/home/new-quote-order',
    value: 'New Quote-Order',
    icon: 'cart',
    component: NewQuote,
    sidebar: false,
  },
  {
    name: 'view-reports',
    to: '/home/view-reports',
    value: 'Reports',
    icon: 'chart line',
    component: LabelCart,
    sidebar: true,
  },
  {
    name: 'view-invoice',
    to: '/home/view-invoice',
    value: 'Invoice',
    icon: 'newspaper outline',
    component: LabelCart,
    sidebar: true,
  },
  {
    name: 'account-settings',
    to: '/home/account-settings',
    value: 'Account Settings',
    icon: 'address card outline',
    component: AccountSettings,
    sidebar: true,
  },
  {
    name: 'account-settings',
    to: '/home/account-settings/:psn/:redirect/:orderNumber?',
    value: 'Account Settings',
    icon: 'address card outline',
    component: AccountSettings,
    sidebar: false,
  },
  {
    name: 'inspection',
    to: '/home/inspection',
    value: 'Inspection',
    icon: 'eye',
    component: LabelCart,
    sidebar: true,
  },
  {
    name: 'label-order-details',
    to: '/home/label-order-details/:orderRequestNumber/:labelOrderType',
    value: '',
    icon: '',
    component: LabelOrderDetails,
    sidebar: false,
  },
  {
    name: 'cancel-order',
    to: '/home/cancel-order',
    value: 'Cancel Order',
    icon: 'cancel',
    component: CancelOrder,
    sidebar: true,
  },
  {
    name: 'logout',
    to: '/home/logout',
    value: '',
    icon: '',
    component: Logout,
    sidebar: false,
  },
  {
    name: 'view-history',
    to: '/home/view-history',
    value: 'View History',
    icon: 'newspaper outline',
    component: ViewHistory,
    sidebar: true,
  },
  {
    name: 'view-draft-order',
    to: '/home/view-draft',
    value: 'Draft',
    icon: 'edit outline',
    component: ViewDraft,
    sidebar: true,
  },
  {
    name: 'view-order-details',
    to: '/home/view-order-details/:action/:orderNumber',
    value: 'View Order Details',
    icon: 'newspaper outline',
    component: OrderDetails,
    sidebar: false,
  },
  {
    name: 'new-quote',
    to: '/home/new-quote',
    value: 'View Order Details',
    icon: 'newspaper outline',
    component: OrderDetails,
    sidebar: false,
  },
  {
    name: 'view-draft-quote',
    to: '/home/view-draft-quote',
    value: 'View Draft',
    icon: 'edit outline',
    component: viewDraftQuote,
    sidebar: true,
  },
  {
    name: 'preview-quote-details',
    to: '/home/preview-quote-details/:orderNumber',
    value: 'View Quote Details',
    icon: 'newspaper outline',
    component: viewQuoteDetails,
    sidebar: false,
  },
  {
    name: 'preview-order-details',
    to: '/home/preview-order-details/:orderNumber',
    value: 'View Order Details',
    icon: 'newspaper outline',
    component: ViewOrdersDetails,
    sidebar: false,
  },
  {
    name: 'search-quote',
    to: '/home/search-quote',
    value: 'Search Quote',
    icon: '',
    component: SearchQuote,
    sidebar: false,
  },
  {
    name: 'fe-cancel-order',
    to: '/home/fe/cancel-order',
    value: 'Cancel Order',
    icon: 'cancel',
    component: FeCancelOrder,
    sidebar: true,
  },
  {
    name: 'role-assignment',
    to: '/home/role-assignment',
    value: 'Role Assignment',
    icon: 'users',
    component: RoleAssignment,
    sidebar: true,
  },
  {
    name: 'quotation',
    to: '/home/quotation',
    value: '',
    icon: '',
    component: Quotation,
    sidebar: false,
  },
  // {
  //   name: "fe-quotation",
  //   to: "/home/fe/quotation",
  //   value: "",
  //   icon: "",
  //   component: FeQuotation,
  //   sidebar: false
  // },
  // {
  //   name: "fes-quotation",
  //   to: "/home/fes/quotation",
  //   value: "",
  //   icon: "",
  //   component: FesQuotation,
  //   sidebar: false
  // },
  {
    name: 'view-all-orders',
    to: '/home/view-all-orders',
    value: 'View Quotes/Orders',
    icon: 'newspaper outline',
    component: ViewHistory,
    sidebar: true,
  },
  {
    name: 'notifications',
    to: '/home/notifications',
    value: 'Notifications',
    icon: 'bell outline',
    component: Notifications,
    sidebar: true,
  },
  {
    name: 'view-assigned',
    to: '/home/view-assigned',
    value: 'My Work',
    icon: 'file alternate outline',
    component: ViewAssigned,
    sidebar: true,
  },
  {
    name: 'quote-sites',
    to: '/home/quote-sites',
    value: '',
    icon: '',
    component: QuoteSites,
    sidebar: false,
  },
  {
    name: 'new-quote-details',
    to: '/home/new-quote-details',
    value: '',
    icon: '',
    component: NewQuoteDetails,
    sidebar: false,
  },
  {
    name: 'field-service-quotation',
    to: '/home/field-service/quotation',
    value: '',
    icon: '',
    component: FieldServiceQuotation,
    sidebar: false,
  },
  {
    name: 'quote-details',
    to: '/home/quote-details/edit/:orderNumber',
    value: 'View Quote Details',
    icon: 'newspaper outline',
    component: NewQuoteDetails,
    sidebar: false,
  },
  {
    name: 'reply-to-help',
    to: '/home/reply-to-help',
    value: 'Reply to Help',
    icon: 'wechat',
    component: AdminPage,
    sidebar: true,
  },
  {
    name: 'help-me',
    to: '/home/help-me',
    value: 'Help Me',
    icon: 'wechat',
    component: HelpMe,
    sidebar: true,
  },
  {
    name: 'help-me',
    to: '/home/help-me/:cart',
    value: 'Help Me',
    icon: 'wechat',
    component: HelpMe,
    sidebar: false,
  },
  {
    name: 'reply-to-help',
    to: '/home/reply-to-help/:cart/:uid',
    value: 'Reply to Help',
    icon: 'wechat',
    component: AdminPage,
    sidebar: false,
  },
  {
    name: 'price-management',
    to: '/home/price-management',
    value: 'Price/Label Management',
    icon: 'dollar sign',
    component: PriceManagement,
    sidebar: true,
  },
  {
    name: 'health-dashboard',
    to: '/home/health-dashboard',
    value: 'Health Dashboard',
    icon: 'heartbeat',
    component: HealthDashBoard,
    sidebar: true,
  },
];

export default routes;
