import { connect } from "react-redux";
import React, { Component } from "react";
import { Table } from "semantic-ui-react";
import RequirementsValue from "./requirement-value";

class Requirements extends Component {
  createMarkup(string) {
    return { __html: string };
  }

  render() {
    const { projectDetails } = this.props;
    let requirements = RequirementsValue(projectDetails);
    return (
      <Table attached>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell textAlign="center">
              IMPORTANT REQUIREMENTS FOR YOUR INSPECTION
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              {
                <span
                  dangerouslySetInnerHTML={this.createMarkup(requirements)}
                ></span>
              }
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    projectDetails: state.feProjectDetails
  };
};

export default connect(mapStateToProps, null)(Requirements);
