import { requests } from '../request-factory';
import { mapDataToQueryString } from '../request-helpers';

const helpMeService = {
  getCartMessages: userId =>
    requests.get(
      `helpme/list/cart?${mapDataToQueryString({
        userId,
      })}`
    ),
  getUserPsns: (isGetUnread, labelCenter) =>
    requests.get(
      `helpme/psns?${mapDataToQueryString({
        isGetUnread,
        labelCenter,
      })}`
    ),
  getManufacturers: (isGetUnread, labelCenter) =>
    requests.get(
      `helpme/manufacturers?${mapDataToQueryString({
        isGetUnread,
        labelCenter,
      })}`
    ),
  getCartOrders: (userId, manufacturerAccountNo, isGetUnread, labelCenter) =>
    requests.get(
      `helpme/cartorders?${mapDataToQueryString({
        userId,
        manufacturerAccountNo,
        isGetUnread,
        labelCenter,
      })}`
    ),
  getUnreadMessage: userId =>
    requests.get(
      `helpme/unread-message?${mapDataToQueryString({
        userId,
      })}`
    ),
  createMessage: data => requests.post('helpme/create', data),
  updateIsViewedMessage: data => requests.post('helpme/update-isviewed', data),
  getLabelCenter: userId =>
    requests.get(
      `helpme/label-centers?${mapDataToQueryString({
        userId,
      })}`
    ),
  getTotalRequests: (userId, labelCenter, isOutstandingRequest) =>
    requests.get(
      `helpme/total-requests?${mapDataToQueryString({
        userId,
        labelCenter,
        isOutstandingRequest,
      })}`
    ),
};

export default helpMeService;
