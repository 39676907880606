import React, { Component, Fragment } from "react";
import {
  Container,
  Button,
  Grid,
  Divider,
  Pagination,
  Message,
  Input,
  Dropdown,
  Dimmer,
  Loader,
  Form,
  Table,
  Icon,
  Popup,
  Menu,
  Sidebar,
  Accordion,
  List,
  Modal,
  Transition,
  Radio
} from "semantic-ui-react";
import {
  CustomTitle,
  CustomHeader,
  CustomHeaderInlineBold,
  CustomHeaderLabel,
  CustomLabel
} from "../../../components/labels/labels";
import FooterHomeBlock from "../../../components/footer/footer-home-block";
import QuoteItem from "./quote-item";
import * as actions from "./view-draft-quote.action";
import {
  doCancelOrder,
  clearCancelOrder,
  resetCancelOrder
} from "../cancel-order/cancel-order.action";
import { connect } from "react-redux";

import { validateField } from "../../../validator/validator";
import { renderDisc } from "../../../helpers/component-helper";
import { GetStatusCode } from "../field-evaluation-helper";
import { Link } from "react-router-dom";

import _ from "lodash";
import SideBarFilterOptions from "../../../components/sidebar-filter-options/sidebar-filter-options";
import TermsAndConditions from "../../manufacturer/standard/label-cart/terms-and-conditions";

const rules = [];

const labelOptions = [
  {
    key: "OrderNumber",
    value: "OrderNumber",
    text: "Order #"
  },
  { key: "ApplicantName", value: "ApplicantName", text: "Applicant Name" },
  {
    key: "AccountNumber",
    value: "AccountNumber",
    text: "Account #"
  },
  { key: "ContactName", value: "ContactName", text: "Contact Name" }
];

const statusOptions = [
  {
    key: "Draft",
    value: "1",
    text: "Draft",
    checked: false
  },
  {
    key: "Pending UL Assessment",
    value: "2",
    text: "Pending UL Assessment",
    checked: false
  },
  {
    key: "Offered",
    value: "1",
    text: "Offered",
    checked: false
  },
  {
    key: "Accepted",
    value: "1",
    text: "Accepted",
    checked: false
  },
  {
    key: "Cancel",
    value: "1",
    text: "Cancel",
    checked: false
  }
];

const columnOptions = [
  {
    key: "status",
    value: "status",
    text: "Status",
    visible: 1,
    sort: 0,
    checked: false
  },
  {
    key: "orderNumber",
    value: "orderNumber",
    text: "Reference #",
    visible: 1,
    sort: 0,
    checked: false
  },
  {
    key: "orderDate",
    value: "orderDate",
    text: "Order date",
    visible: 1,
    sort: 0,
    checked: false
  },
  {
    key: "lastOrderActivityData",
    value: "lastOrderActivityData",
    text: "Last activity",
    visible: 1,
    sort: 0,
    checked: false
  },
  {
    key: "inspectionType",
    value: "ispectionType",
    text: "Inspection Type",
    visible: 1,
    sort: 0,
    checked: false
  }
];

class ViewDraftQuote extends Component {
  state = {
    column: null,
    direction: null,
    isFilterVisible: false,
    isColumnOptVisible: false,
    activeIndex: 0,
    isOpenTac: false,
    isOpenCancelModal: false,
    isCheckAgree: false,
    feOrderId: 0,
    columnOptions: columnOptions,
    columnSortable: false
  };

  componentDidMount() {
    var { currentPage, pageSize } = this.props;
    var status = [1]; //Draft Status
    var filter = { status, currentPage, pageSize };
    this.props.getFEDraftQuotesByFilterByUser(filter);
  }

  handleChange = (dpName, dpValue) => {
    let { form } = this.props.viewHistory;
    validateField(rules, form, dpName, dpValue);
    const newState = { ...this.props.viewHistory.form };
    this.props.form(newState);
  };

  handleSearch = () => {
    let { form, currentPage, pageSize } = this.props.viewHistory;
    let status = form.status;
    // if (status)
    //this.props.getFEDraftQoutesByFilter(status, currentPage, pageSize);
  };

  handleSort = clickedColumn => () => {
    const { column, data, direction } = this.state;

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        direction: "ascending"
      });

      return;
    }

    this.setState({
      direction: direction === "ascending" ? "descending" : "ascending"
    });
  };

  handleToggleFilter = () => {
    const { isFilterVisible } = this.state;

    var _isFilterVisible = !isFilterVisible;
    this.setState({
      isFilterVisible: _isFilterVisible,
      isColumnOptVisible: false
    });
  };

  handleToggleColumn = () => {
    const { isColumnOptVisible } = this.state;

    var _isColumnOptVisible = !isColumnOptVisible;

    this.setState({
      isColumnOptVisible: _isColumnOptVisible,
      isFilterVisible: false
    });
  };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  handleConfirmCompleteOrder = (isOpenTac, feOrderId) => {
    this.setState({ isOpenTac: isOpenTac, feOrderId: feOrderId });
  };

  handleConfirmCancelOrder = (isOpenCancelModal, feOrderId) => {
    this.setState({
      isOpenCancelModal: isOpenCancelModal,
      feOrderId: feOrderId
    });
  };

  onClickAgreeChecbox = () =>
    this.setState(prevState => ({ isCheckAgree: !prevState.isCheckAgree }));

  handleCompleteOrder = () => {
    var { feOrderId } = this.state;

    var data = {
      fieldEvaluationOrderId: feOrderId,
      orderType: 2
    };

    this.props.updateOrderType(data);
    this.setState({ isOpenTac: false });
  };

  handleCancelOrder = () => {
    var { feOrderId } = this.state;

    var data = {
      orderId: feOrderId
    };

    this.props.doCancelOrder(data);
    this.setState({ isOpenCancelModal: false });
  };

  handleRemoveColumn = columnKey => {
    var { columnOptions } = this.state;
    var selectedColumn = columnOptions.filter(i => i.key == columnKey);
    selectedColumn[0].visible = 0;

    const newState = { ...this.selectedColumn };
    this.setState(newState);
    console.log(this.state);
  };

  handleAddColumn = () => {
    var { columnOptions } = this.state;
    var selectedColumn = columnOptions.filter(i => i.selected == true);
    if (selectedColumn.length > 0) selectedColumn[0].visible = 1;

    const newState = { ...this.selectedColumn };

    this.setState(newState);
  };

  handleDdColumnChange = (event, { value }) => {
    var { columnOptions } = this.state;

    // clear all selected column
    var resetColumn = columnOptions.map((column, idx) => {
      column.selected = false;
    });
    const newResetState = { ...this.resetColumn };
    this.setState(newResetState);

    // add selected column
    var selectedColumn = columnOptions.filter(i => i.value == value);
    selectedColumn[0].selected = true;
    const newAddedState = { ...this.selectedColumn };
    this.setState(newAddedState);

    console.log(this.state);
  };

  handleColumnSortChange = e => {
    var { columnSortable } = this.state;

    columnSortable = !columnSortable;

    this.setState({ columnSortable: columnSortable });
  };

  render() {
    const { data, form } = this.props.viewDraftQuote;
    const {
      column,
      direction,
      isFilterVisible,
      isColumnOptVisible,
      activeIndex,
      isOpenTac,
      isOpenCancelModal,
      isCheckAgree,
      columnSortable,
      columnOptions
    } = this.state;

    const { isSuccessUpdateOrderType, cancelOrder } = this.props;
    const isSuccessCancelOrder = cancelOrder
      ? cancelOrder.cancelProcessResult.isSuccessful
      : false;

    if (isSuccessUpdateOrderType)
      setTimeout(() => {
        this.props.getFEOrders(this.props.currentPage, this.props.pageSize);
        this.props.updateOrderTypeReset();
      }, 3000);

    if (isSuccessCancelOrder)
      setTimeout(() => {
        this.props.getFEOrders(this.props.currentPage, this.props.pageSize);
        this.props.resetCancelOrder();
      }, 3000);

    return (
      <div>
        <Modal open={isOpenTac}>
          <Modal.Header className="ui center aligned">
            Terms and Conditions
          </Modal.Header>
          <Modal.Content scrolling>
            <TermsAndConditions
              onClickAgreeChecbox={this.onClickAgreeChecbox}
              isCheckAgree={isCheckAgree}
            />
          </Modal.Content>
          <Modal.Actions>
            {isCheckAgree ? (
              <Button primary onClick={this.handleCompleteOrder.bind(this)}>
                Submit
              </Button>
            ) : null}

            <Button
              primary
              basic
              onClick={() =>
                this.setState({ isOpenTac: false, isCheckAgree: false })
              }
            >
              Cancel
            </Button>
          </Modal.Actions>
        </Modal>
        <Modal open={isOpenCancelModal} size="mini">
          <Modal.Header className="ui center aligned">
            Confirm Cancellation
          </Modal.Header>
          <Modal.Content scrolling>
            Are you sure you want to cancel? This cannot be undone.
          </Modal.Content>
          <Modal.Actions>
            <Button
              primary
              onClick={() => this.setState({ isOpenCancelModal: false })}
            >
              No
            </Button>
            <Button secondary basic onClick={this.handleCancelOrder.bind(this)}>
              Yes
            </Button>
          </Modal.Actions>
        </Modal>
        <Sidebar.Pushable>
          <Sidebar
            as={Menu}
            animation="overlay"
            vertical
            direction="left"
            visible={isFilterVisible}
            width="wide"
          >
            <div style={{ padding: "20px" }}>
              <Grid columns={2}>
                <Grid.Column verticalAlign="middle">
                  <CustomHeaderInlineBold message="Filters" />
                </Grid.Column>
                <Grid.Column textAlign="right">
                  <Button basic color="blue" content="Clear"></Button>
                </Grid.Column>
              </Grid>

              <Divider />
              <Accordion fluid>
                <Accordion.Title
                  active={activeIndex === 0}
                  index={0}
                  onClick={this.handleClick}
                >
                  <Icon name="dropdown" />
                  Order Status
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 0}>
                  <p>
                    A dog is a type of domesticated animal. Known for its
                    loyalty and faithfulness, it can be found as a welcome guest
                    in many households across the world.
                  </p>
                </Accordion.Content>

                <Accordion.Title
                  active={activeIndex === 1}
                  index={1}
                  onClick={this.handleClick}
                >
                  <Icon name="dropdown" />
                  Order #
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 1}>
                  <p>
                    There are many breeds of dogs. Each breed varies in size and
                    temperament. Owners often select a breed of dog that they
                    find to be compatible with their own lifestyle and desires
                    from a companion.
                  </p>
                </Accordion.Content>

                <Accordion.Title
                  active={activeIndex === 2}
                  index={2}
                  onClick={this.handleClick}
                >
                  <Icon name="dropdown" />
                  Order Date
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 2}>
                  <p>
                    Three common ways for a prospective owner to acquire a dog
                    is from pet shops, private owners, or shelters.
                  </p>
                  <p>
                    A pet shop may be the most convenient way to buy a dog.
                    Buying a dog from a private owner allows you to assess the
                    pedigree and upbringing of your dog before choosing to take
                    it home. Lastly, finding your dog from a shelter, helps give
                    a good home to a dog who may not find one so readily.
                  </p>
                </Accordion.Content>
              </Accordion>
            </div>
          </Sidebar>
          <Sidebar
            as={Menu}
            animation="overlay"
            vertical
            direction="left"
            visible={isColumnOptVisible}
            width="wide"
          >
            <div style={{ padding: "20px" }}>
              <Grid columns={2}>
                <Grid.Column verticalAlign="middle">
                  <CustomHeaderInlineBold message="Column Options" />
                </Grid.Column>
                <Grid.Column textAlign="right">
                  <Button
                    basic
                    icon="delete"
                    onClick={this.handleToggleColumn}
                  ></Button>
                </Grid.Column>
              </Grid>
              <Divider />
              <Accordion fluid>
                <Accordion.Title
                  active={activeIndex === 0}
                  index={0}
                  onClick={this.handleClick}
                >
                  <Icon name="dropdown" />
                  Columns
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 0}>
                  <List>
                    {columnOptions &&
                      columnOptions.map((column, idx) => {
                        return (
                          column &&
                          column.visible === 1 && (
                            <List.Item key={idx}>
                              <Grid>
                                <Grid.Row centered>
                                  <Input
                                    icon="dropdown"
                                    iconPosition="left"
                                    value={column.text}
                                    disabled
                                    style={{ marginRight: "10px" }}
                                  />

                                  <Button
                                    color="red"
                                    basic
                                    icon="delete"
                                    disabled={
                                      columnOptions.filter(i => i.visible === 1)
                                        .length > 1
                                        ? false
                                        : true
                                    }
                                    onClick={() => {
                                      this.handleRemoveColumn(column.key);
                                    }}
                                  />
                                </Grid.Row>
                              </Grid>
                            </List.Item>
                          )
                        );
                      })}
                    {columnOptions.filter(i => i.visible === 0).length > 0 && (
                      <List.Item>
                        <Grid>
                          <Grid.Row centered>
                            <Dropdown
                              placeholder="Select column"
                              selection
                              onChange={this.handleDdColumnChange.bind(this)}
                              options={columnOptions.filter(
                                i => i.visible == 0
                              )}
                            />

                            <Button
                              color="green"
                              basic
                              icon="add"
                              style={{ marginLeft: "20px" }}
                              onClick={() => {
                                this.handleAddColumn();
                              }}
                            />
                          </Grid.Row>
                        </Grid>
                      </List.Item>
                    )}
                  </List>
                </Accordion.Content>

                <Accordion.Title
                  active={activeIndex === 1}
                  index={1}
                  onClick={this.handleClick}
                >
                  <Icon name="dropdown" />
                  Sorting
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 1}>
                  <Form.Field
                    control={Radio}
                    toggle
                    label="Enable table sorting."
                    name="sort"
                    onChange={e => {
                      this.handleColumnSortChange(e);
                    }}
                    value={columnSortable}
                  />
                </Accordion.Content>
              </Accordion>
            </div>
          </Sidebar>

          <Sidebar.Pusher>
            <div style={{ width: "80%", padding: "20px" }}>
              <Container fluid>
                <Dimmer active={this.props.loading} inverted>
                  <Loader inverted content="Loading" />
                </Dimmer>
                <CustomHeaderLabel message="FIELD INSPECTION DRAFT QUOTE" />
                <Divider hidden />
                <Transition.Group animation="fade down" duration={500}>
                  {isSuccessUpdateOrderType && (
                    <Message
                      success
                      content="Quote successfully placed as order."
                    />
                  )}
                </Transition.Group>
                <Transition.Group animation="fade down" duration={500}>
                  {isSuccessCancelOrder && (
                    <Message success content="Quote successfully cancelled." />
                  )}
                </Transition.Group>
                <Grid>
                  <Grid.Row>
                    <Grid.Column textAlign="right">
                      {/* <Button
                        color="blue"
                        icon="filter"
                        onClick={this.handleToggleFilter}
                      ></Button> */}
                      {/* <Button
                        color="blue"
                        content="Column Options"
                        basic
                        onClick={this.handleToggleColumn}
                      ></Button> */}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <Divider />
                <Table striped celled sortable={columnSortable}>
                  <Table.Header>
                    <Table.Row textAlign="center">
                      {columnOptions &&
                        columnOptions.map(
                          (_column, idx) =>
                            _column.visible === 1 && (
                              <Table.HeaderCell
                                key={idx}
                                sorted={
                                  column === _column.key ? direction : null
                                }
                                onClick={this.handleSort(_column.key)}
                              >
                                {_column.text}
                              </Table.HeaderCell>
                            )
                        )}

                      <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {data &&
                      data.map((data, idx) => (
                        <QuoteItem
                          key={idx}
                          data={data}
                          columnOptions={columnOptions}
                          handleConfirmCompleteOrder={
                            this.handleConfirmCompleteOrder
                          }
                          handleConfirmCancelOrder={
                            this.handleConfirmCancelOrder
                          }
                        />
                      ))}
                  </Table.Body>
                  <Table.Footer>
                    <Table.Row>
                      <Table.HeaderCell colSpan="6">
                        {data && this.props.totalPages > 0 ? (
                          <Pagination
                            floated="right"
                            activePage={this.props.currentPage}
                            totalPages={this.props.totalPages}
                            onPageChange={(e, { activePage }) => {
                              this.props.changePage(
                                activePage,
                                this.props.pageSize
                              );
                            }}
                          />
                        ) : (
                          <Message>No Order Found</Message>
                        )}
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Footer>
                </Table>
                {/* {data && data.map((data, idx) => <OrderItem key={idx} data={data} />)} */}

                {/* {data && this.props.totalPages > 0 ? (
            <Pagination
              activePage={this.props.currentPage}
              totalPages={this.props.totalPages}
              onPageChange={(e, { activePage }) => {
                this.props.changePage(activePage, this.props.pageSize);
              }}
            />
          ) : (
            <Message>No Order Found</Message>
          )} */}
                <FooterHomeBlock />
              </Container>
            </div>
          </Sidebar.Pusher>
        </Sidebar.Pushable>
      </div>
    );
  }
}

const mapStateToProps = state => {
  //console.log(state);
  return {
    currentPage: state.currentPage,
    pageSize: state.viewDraftQuote.pageSize,
    totalPages: state.viewDraftQuote.totalPages,
    loading: state.viewDraftQuote.loading,
    viewDraftQuote: state.viewDraftQuote,
    cancelOrder: state.feCancelOrder
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changePage: (activePage, pageSize) =>
      dispatch(actions.changePage(activePage, pageSize)),
    getFEDraftQoutes: (currentPage, pageSize) =>
      dispatch(actions.getFEDraftQoutes(currentPage, pageSize)),
    getFEDraftQuotesByFilterByUser: (status, currentPage, pageSize) =>
      dispatch(
        actions.getFEDraftQuotesByFilterByUser(status, currentPage, pageSize)
      ),
    form: result => dispatch(actions.updateForm(result)),
    doCancelOrder: data => dispatch(doCancelOrder(data)),
    resetCancelOrder: () => dispatch(resetCancelOrder())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewDraftQuote);
