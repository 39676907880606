import React, { Component, Fragment } from "react";
import { Segment, Grid, Button, Divider, Table } from "semantic-ui-react";
import { renderDisc } from "../../../helpers/component-helper";
import {
  CustomLabel,
  SubText,
  CustomHeaderBold
} from "../../../components/labels/labels";

class LabelOrderWorkflow extends Component {
  render() {
    return (
      <Fragment>
        <Segment>
          <Grid>
            <Grid.Column width={8}>
              {renderDisc("")}
              <CustomLabel message="PENDING ACTION" />
              <SubText message="As of 11:10 on May 2, 2019" />
              <Divider hidden />
              <Divider hidden />
              <CustomHeaderBold message="Current status: order placed" />

              <div className="connected-steps">
                <div className="step-active" />
                <div className="connector" />
                <div className="step" />
                <div className="connector" />
                <div className="step" />
                <div className="connector" />
                <div className="step" />
                <div className="connector" />
                <div className="step" />
              </div>

              <Divider hidden />
              <CustomHeaderBold message="System comments on status" />
              <label>
                Order already checked. Please review the application again for
                comments.
              </label>
              <Divider hidden />
            </Grid.Column>
            <Grid.Column width={6}>
              <Table basic="very" fixed compact="very">
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>
                      <CustomLabel message="ORDER DATE" />
                    </Table.Cell>
                    <Table.Cell>July 30, 2019</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>
                      <CustomLabel message="QUANTITY" />
                    </Table.Cell>
                    <Table.Cell>25</Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>

              <Divider hidden />
              <CustomHeaderBold message="Tracking number" />
              <label>9921491772</label>
              <Divider hidden />

              <Button basic color="blue">
                View packing slip
              </Button>
            </Grid.Column>
            <Grid.Column width={2}>
              <Button basic color="blue">
                Copy
              </Button>
            </Grid.Column>
          </Grid>
        </Segment>
      </Fragment>
    );
  }
}

export default LabelOrderWorkflow;
