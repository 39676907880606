export const LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "USER_LOGOUT_FAILURE";

export const EXTERNAL_LOGOUT_REQUEST = "EXTERNAL_LOGOUT_REQUEST";
export const EXTERNAL_LOGOUT_FAILURE = "EXTERNAL_LOGOUT_FAILURE";
export const EXTERNAL_LOGOUT_SUCCESS = "EXTERNAL_LOGOUT_SUCCESS";

export const ROLEPAGES_REQUEST = "ROLEPAGES_REQUEST";
export const ROLEPAGES_FAILURE = "ROLEPAGES_FAILURE";
export const ROLEPAGES_SUCCESS = "ROLEPAGES_SUCCESS";

export const VALIDATE_LOGIN_REQUEST = "VALIDATE_LOGIN_REQUEST";
export const VALIDATE_LOGIN_FAILURE = "VALIDATE_LOGIN_FAILURE";
export const VALIDATE_LOGIN_SUCCESS = "VALIDATE_LOGIN_SUCCESS";
