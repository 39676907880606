import * as types from "./cancel-order.type";

let initialState = {
  data: {
    currentPage: 1,
    pageSize: 5,
    totalPages: 1
  },
  notificationCount: 0,
  cancelProcessResult: {},
  cancellationCode: {},
  selectedCancellationCode: {}
};

const cancelOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
    case types.LIST_CANCEL_ORDER_REQUEST:
    case types.LIST_CANCEL_ORDER_SUCCESS:
    case types.LIST_CANCEL_ORDER_FAILURE:
    case types.LIST_CANCEL_ORDER_CHANGE_PAGE:
    case types.REQUEST_TO_CANCEL_REQUEST:
    case types.REQUEST_TO_CANCEL_SUCCESS:
    case types.REQUEST_TO_CANCEL_FAILURE:
      return Object.assign({}, state, {
        data: action.data || initialState.data,
        notificationCount: action.data.total
      });
    case types.CANCEL_ORDER_REQUEST:
      return Object.assign({}, state, {
        data: Object.assign({}, state.data, {
          data: action.data || initialState.data
        })
      });
    case types.CANCEL_ORDER_SUCCESS:
    case types.CANCEL_ORDER_FAILURE:
      return Object.assign({}, state, {
        cancelProcessResult: action.data || initialState.cancelProcessResult
      });
    case types.NOTIFICATION_COUNT:
      return Object.assign({}, state, {
        notificationCount: state.notificationCount - 1
      });
    case types.CLEAR:
      return Object.assign({}, state, {
        data: initialState.data
      });
    case types.RESET:
      var result = Object.assign({}, state, {
        data: initialState.data
      });

      result.cancelProcessResult = {};
      result.selectedCancellationCode = {};

      return result;
    case types.CANCELLATION_CODE_SUCCESS:
      return Object.assign({}, state, {
        cancellationCode: action.data || initialState.cancellationCode
      });
    case types.CANCELLATION_CODE_UPDATE:
      return Object.assign({}, state, {
        selectedCancellationCode:
          action.data || initialState.selectedCancellationCode
      });
  }
};

export default cancelOrderReducer;
