import { requests } from '../request-factory';
import { mapDataToQueryString } from '../request-helpers';

const labelOrderCartService = {
  addExistingCombinationLabels: data =>
    requests.post('label-order-cart/add-existing-combination-labels', data),
  checkOutLabelOrderCart: () => requests.post('label-order-cart/check-out'),
  getLabelOrderCart: () => requests.get('label-order-cart'),
  removeLabelOrderCartItem: id =>
    requests.delete(`label-order-cart/remove-label-order-cart-item?id=${id}`),
  updateLabelOrderCart: data => requests.put('label-order-cart', data),
  uploadLabelOrderTempAttachment: data =>
    requests.post('label-order-cart/upload-attachment-temp', data),
  getLabelOrderTempAttachment: (partySiteNumber, labelOrderType) =>
    requests.get(
      `label-order-cart/get-attachments-temp?${mapDataToQueryString({
        partySiteNumber,
        labelOrderType,
      })}`
    ),

  deleteLabelOrderTempAttachment: orderAttachmentId =>
    requests.post('label-order-cart/delete-attachment-temp', {
      orderAttachmentId: orderAttachmentId,
    }),
};

export default labelOrderCartService;
