import React, { Component, Fragment } from "react";
import { Accordion, Grid, Icon, Divider, Segment } from "semantic-ui-react";
import { CustomHeaderLabel } from "../../../components/labels/labels";

class CombinationLabelOrderItemReview extends Component {
  state = {
    activeIndex: -1,
    isConfirming: false,
    toRemoveId: null
  };

  toggleDisplay = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  render() {
    const { activeIndex } = this.state;
    const { data, unitsOfMeasurement, changeOrderCartItemValue } = this.props;

    return (
      <Fragment>
        <Segment>
          <Accordion>
            <Accordion.Title
              active={activeIndex === 0}
              index={0}
              onClick={this.toggleDisplay}
            >
              <Grid columns={1}>
                <Grid.Column width={16}>
                  <Icon name="dropdown" />
                  <CustomHeaderLabel message={data.fileNumber} />
                </Grid.Column>
              </Grid>
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 0}>
              <Divider />
              <Grid columns={2}>
                <Grid.Row>
                  <Grid.Column width={8} textAlign="left">
                    <div>Item Number: {data.itemNumber}</div>
                    <div>Label Description: {data.labelDescription}</div>
                  </Grid.Column>

                  <Grid.Column width={8} textAlign="right">
                    <div>Quantity: {data.quantity}</div>
                    <div>Unit of Measurement: {"Each"}</div>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={16} textAlign="left">
                    <div>
                      Label Image:
                      <Divider hidden fitted />
                      <img
                        src={data.artworkLink || ""}
                        style={{
                          width: "180px"
                        }}
                      />
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Accordion.Content>
          </Accordion>
        </Segment>
      </Fragment>
    );
  }
}

export default CombinationLabelOrderItemReview;
