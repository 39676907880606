import {
  LABEL_ORDER_CART_REVIEW_CHECK_OUT_REQUEST,
  LABEL_ORDER_CART_REVIEW_CHECK_OUT_SUCCESS,
  LABEL_ORDER_CART_REVIEW_CHECK_OUT_FAILURE,
  LABEL_ORDER_CART_REVIEW_CONFIRM_CHECK_OUT_REQUEST,
  LABEL_ORDER_CART_REVIEW_CONFIRM_CHECK_OUT_ACCEPT,
  LABEL_ORDER_CART_REVIEW_CONFIRM_CHECK_OUT_REJECT,
  LABEL_ORDER_CART_REVIEW_GET_CUSTOMER_INFO_REQUEST,
  LABEL_ORDER_CART_REVIEW_GET_CUSTOMER_INFO_SUCCESS,
  LABEL_ORDER_CART_REVIEW_GET_CUSTOMER_INFO_FAILURE,
  LABEL_ORDER_CART_REVIEW_GET_LABEL_ORDER_CART_REQUEST,
  LABEL_ORDER_CART_REVIEW_GET_LABEL_ORDER_CART_SUCCESS,
  LABEL_ORDER_CART_REVIEW_GET_LABEL_ORDER_CART_FAILURE,
  LABEL_ORDER_CART_REVIEW_INITIALIZE,
  LABEL_ORDER_CART_REVIEW_REMOVE_CHECK_OUT_MESSAGE
} from "./label-order-cart-review.type";
import api from "../../../services/api";

const checkOutLabelOrderCartRequest = () => {
  return { type: LABEL_ORDER_CART_REVIEW_CHECK_OUT_REQUEST };
};

const checkOutLabelOrderCartFailure = error => {
  return dispatch =>
    dispatch({
      type: LABEL_ORDER_CART_REVIEW_CHECK_OUT_FAILURE,
      payload: error
    });
};

const checkOutLabelOrderCartSuccess = response => {
  return dispatch =>
    dispatch({
      type: LABEL_ORDER_CART_REVIEW_CHECK_OUT_SUCCESS,
      payload: response
    });
};

export const checkOutLabelOrderCart = () => {
  return async dispatch => {
    dispatch(checkOutLabelOrderCartRequest());
    const result = await api.LabelOrderCart.checkOutLabelOrderCart();

    if (result) {
      dispatch(checkOutLabelOrderCartSuccess(result));
      return result;
    } else {
      dispatch(checkOutLabelOrderCartFailure("Something went wrong."));
    }
  };
};

export const confirmCheckoutRequest = () => {
  return { type: LABEL_ORDER_CART_REVIEW_CONFIRM_CHECK_OUT_REQUEST };
};

export const confirmCheckoutAccept = () => {
  return { type: LABEL_ORDER_CART_REVIEW_CONFIRM_CHECK_OUT_ACCEPT };
};

export const confirmCheckoutReject = () => {
  return { type: LABEL_ORDER_CART_REVIEW_CONFIRM_CHECK_OUT_REJECT };
};

const getCustomerInfoRequest = () => {
  return { type: LABEL_ORDER_CART_REVIEW_GET_CUSTOMER_INFO_REQUEST };
};

const getCustomerInfoFailure = error => {
  return dispatch =>
    dispatch({
      type: LABEL_ORDER_CART_REVIEW_GET_CUSTOMER_INFO_FAILURE,
      payload: error
    });
};

const getCustomerInfoSuccess = response => {
  return dispatch =>
    dispatch({
      type: LABEL_ORDER_CART_REVIEW_GET_CUSTOMER_INFO_SUCCESS,
      payload: response
    });
};

export const getCustomerInfo = customerId => {
  return async dispatch => {
    dispatch(getCustomerInfoRequest());
    const result = await api.Customer.getInfo(customerId);
    if (result) {
      dispatch(getCustomerInfoSuccess(result));
    } else {
      dispatch(getCustomerInfoFailure("Something went wrong."));
    }
  };
};

const getLabelOrderCartRequest = () => {
  return { type: LABEL_ORDER_CART_REVIEW_GET_LABEL_ORDER_CART_REQUEST };
};

const getLabelOrderCartFailure = error => {
  return dispatch =>
    dispatch({
      type: LABEL_ORDER_CART_REVIEW_GET_LABEL_ORDER_CART_FAILURE,
      payload: error
    });
};

const getLabelOrderCartSuccess = response => {
  return dispatch =>
    dispatch({
      type: LABEL_ORDER_CART_REVIEW_GET_LABEL_ORDER_CART_SUCCESS,
      payload: response
    });
};

export const getLabelOrderCart = () => {
  return async dispatch => {
    dispatch(getLabelOrderCartRequest());
    const result = await api.LabelOrderCart.getLabelOrderCart();

    if (result) {
      dispatch(getLabelOrderCartSuccess(result));
    } else {
      dispatch(getLabelOrderCartFailure("Something went wrong."));
    }
  };
};

export const initialize = () => {
  return { type: LABEL_ORDER_CART_REVIEW_INITIALIZE };
};

export const removeCheckOutMessage = () => {
  return { type: LABEL_ORDER_CART_REVIEW_REMOVE_CHECK_OUT_MESSAGE };
};
