import React, { Component } from "react";
import { Table } from "semantic-ui-react";

class ServiceTerms extends Component {
  render() {
    return (
      <Table attached>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell textAlign="center">
              FIRE EQUIPMENT SERVICES TERMS FOR INSPECTION OF IN-SERVICE
              AUTOMOTIVE FIRE APPARATUS AND EQUIPMENT SERVICE TERMS
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell verticalAlign="top">
              <p>
                <span>GSA – Fire In-Service</span>
                <br />
                <span>01/01/2012</span>
              </p>
              <p>
                These Service Terms shall govern all Fire Equipment Services
                (“FES”) for Inspection of In-Service Automotive Fire Apparatus
                and Equipment performed by UL Contracting Party (as identified
                in the Quotation or Project Confirmation), and set out the
                responsibilities and obligations of the client (“Client”). These
                Terms and the terms of the Global Services Agreement (“GSA”) are
                incorporated by reference into and are an integral part of each
                Service Agreement entered into by the Parties for Fire Equipment
                Services for Inspection of In-Service Automotive Fire Apparatus
                and Equipment. The capitalized terms in these Service Terms
                which are not defined herein shall have the same meaning as in
                the GSA.
              </p>

              <ol>
                <li>
                  <b>Scope of Service</b>. Upon Client’s request, UL Contracting
                  Party will perform inspection of in service automotive fire
                  apparatus and equipment (“FES Services”), according to the
                  appropriate provisions of:
                  <ol type="i">
                    <li>Standard 1901 for Automotive Fire Apparatus;</li>
                    <li>
                      Standard 1911 for Inspection, Maintenance, Testing, and
                      Retirement of In Service Automotive Fire Apparatus; and
                    </li>
                    <li>
                      Standard 1932 for Use, Maintenance, and Service Testing of
                      In-Service Fire Department Ground Ladders or as
                      promulgated by the National Fire Protection Association
                      (NFPA).
                    </li>
                  </ol>
                  <br />
                  <ol className="custom-ol">
                    <li>
                      These additional Terms cover FES Services for automotive
                      fire apparatus already in service. The FES Services
                      requested by Client and to be provided by UL Contracting
                      Party shall be set out in the Quotation or Project
                      Confirmation. The Client agrees to cooperate with UL
                      Contracting Party to facilitate the agreed upon inspection
                      of the designated equipment. Upon written request, each of
                      the parties hereto shall execute and deliver, or cause to
                      be executed and delivered, such additional instruments and
                      documents as may be necessary to carry out the terms of
                      the Service Agreement.
                    </li>
                    <br />
                    <li>
                      The Quotation or Project Confirmation shall be
                      incorporated into and made a part of the Service Agreement
                      and shall establish the sole purpose, scope, and nature of
                      work to be provided by UL Contracting Party. UL
                      Contracting Party agrees to inspect in service automotive
                      fire apparatus and equipment described in the Quotation or
                      Project Confirmation, and to report the results of such
                      inspection. Except as recorded in the report, UL
                      Contracting Party makes no statement, express or implied,
                      as to the construction, materials, or design of such in
                      service automotive fire apparatus and equipment.
                    </li>
                    <br />
                    <li>
                      Each Quotation or Project Confirmation issued under the
                      Service Agreement covers inspections determined to be
                      appropriate for the in service automotive fire apparatus
                      and equipment. Upon conclusion of the inspection, UL
                      Contracting Party will issue an inspection report and, if
                      appropriate, issue a Certificate of Inspection. In the
                      event additional services are requested by Client or
                      required by UL Contracting Party, a new Quotation with a
                      new cost limit may be required. Charges are due and
                      payable, without discount, upon completion of the work,
                      and upon presentation of invoices. It is understood that
                      Client’s obligation for all charges accruing under the
                      Service Agreement continues in full force and effect
                      irrespective of whether the inspection does or does not
                      result in a Certificate of Inspection. It is further
                      understood that Client is responsible for payment of all
                      bank fees incurred with wire transfers.
                    </li>
                  </ol>
                </li>
                <br />
                <li>
                  <p>
                    <b>Price</b>. The Quotation or Project Confirmation will
                    establish the price for the FES Services. The price will
                    depend upon the type of product and the inspection
                    requirements. All Quotations and Project Confirmations are
                    subject to change at UL Contracting Party’s discretion, upon
                    reasonable notice to Client, and depending upon the
                    requirements of the specific project. A new Quotation will
                    be issued if UL Contracting Party changes its pricing or
                    services.
                  </p>
                </li>
                <br />
                <li>
                  <p>
                    <b>Requirements, Specifications, and Protocols</b>. Client
                    is solely responsible for establishing or selecting all
                    requirements, specifications, and protocols that UL
                    Contracting Party may use in performing FES Services,
                    regardless of the source of information used to develop the
                    requirements and specifications. For purposes of FES
                    Services, Client acknowledges that it is responsible for
                    selecting the NFPA Standards. UL Contracting Party may be
                    able to provide Client with assistance in developing
                    protocols that meet Client’s needs. In all cases, however,
                    Client must review and approve the final requirements and
                    protocols.
                  </p>
                  <p>
                    The Client recognizes that many procedures required by UL
                    Contracting Party’s FES Services under the Service Agreement
                    are inherently hazardous and require the loading of the in
                    service automotive fire apparatus and equipment under the
                    conditions stated in the NFPA Standards. The Client agrees
                    that UL Contracting Party neither assumes nor accepts any
                    responsibility for any injury or damage to property or
                    personnel that may occur during or as a result of such FES
                    Services.
                    <b>
                      &nbsp;
                      <u>
                        CLIENT IS SOLELY RESPONSIBLE FOR PROVIDING A SAFE AND
                        SUITABLE INSPECTION SITE ALONG WITH A QUALIFIED OPERATOR
                        OF THE APPARATUS AS DESCRIBED IN THE QUOTATION LETTER.
                      </u>
                    </b>
                  </p>

                  <p>
                    The Client agrees that UL Contracting Party, in performing
                    its functions in accordance with its objects and purposes,
                    does not assume or undertake to discharge any responsibility
                    of the Client to any other party or parties. The Client
                    recognizes that UL Contracting Party’s opinions and findings
                    represent its judgment given with due consideration to the
                    necessary limitations of practical operation and in
                    accordance with its objects and purposes, and agrees that UL
                    Contracting Party does not warrant or guarantee its opinions
                    or that its findings will be recognized or accepted.
                  </p>
                </li>
                <br />
                <li>
                  <p>
                    <b>Inspection Site</b>. UL Contracting Party may perform FES
                    Services on site at Client’s facilities or, upon Client’s
                    request, at facilities of other parties. Client agrees that
                    UL Contracting Party’s representatives shall have safe,
                    secure, and free access to the facilities where the
                    requested services will be performed. UL Contracting Party
                    shall direct its representatives to exercise due care in
                    complying with any safety regulations which may be generally
                    applicable to the facility’s personnel. Access to the
                    facilities by UL Contracting Party’s representatives shall
                    not be conditioned upon the execution of any agreement,
                    waiver, or release. If UL Contracting Party’s
                    representatives are prevented from performing or completing
                    any on site services for any reason beyond UL Contracting
                    Party’s reasonable control, UL Contracting Party shall not
                    be responsible for the non performance, and Client may be
                    charged for any actual expenses UL Contracting Party incurs,
                    including part of the price equal to the cost of services
                    already performed.
                  </p>
                </li>
                <br />
                <li>
                  <p>
                    <b>Deliverables</b>. When UL Contracting Party completes the
                    FES Services, UL Contracting Party will provide Client with
                    a report and/or Certificate of Inspection outlining the
                    method and results of the FES Services. If UL Contracting
                    Party requires the Client to resolve any items that do not
                    comply with NFPA Standards and/or the FES Services, Client
                    will correct these items prior to UL Contracting Party
                    issuing a report and/or Certificate of Inspection. Except as
                    recorded in the report and/or Certificate of Inspection, UL
                    Contracting Party makes no statement, express or implied, as
                    to the construction, materials or design of such in service
                    automotive fire apparatus and equipment.
                  </p>
                  <p>
                    The Client agrees that UL Contracting Party, in performing
                    its functions in accordance with its objects and purposes,
                    does not assume or undertake to discharge any responsibility
                    of the Client to any other party or parties. The Client
                    recognizes that UL Contracting Party’s opinions and findings
                    represent its judgment given with due consideration to the
                    necessary limitations of practical operation and in
                    accordance with its objects and purposes, and agrees that UL
                    Contracting Party does not warrant or guarantee its opinions
                    or that its findings will be recognized or accepted. The FES
                    Services, report/or Certificate of Inspection provided under
                    the Service Agreement are provided solely for the benefit
                    and exclusive use of the Client.
                  </p>
                </li>
                <br />
                <li>
                  <p>
                    <b>Use of Name and Marks</b>. FES Services shall not result
                    in UL Contracting Party issuing product safety certification
                    or any authorization to use the Marks. Except as otherwise
                    expressly authorized by UL Contracting Party, Client shall
                    not use UL Contracting Party's, or any other UL Company’s,
                    name, abbreviation, or symbols, or any other form of
                    reference which may be interpreted to refer to UL
                    Contracting Party or any other UL Company, on or in
                    connection with any oral or written advertising, promotions,
                    or otherwise.
                  </p>
                </li>
              </ol>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  }
}

export default ServiceTerms;
