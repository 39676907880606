import React, { Component, Fragment } from 'react';
import {
  Grid,
  Button,
  Divider,
  Table,
  Pagination,
  Message,
  Modal,
  Icon,
  Popup,
  Card,
  Image,
  List,
  Input,
  Accordion,
  Checkbox,
} from 'semantic-ui-react';
import ViewLabelsItem from './view-labels-item';
import CsvDownload from 'react-json-to-csv';
import { uploadTemplate } from '../../../enums/upload-type';

class ViewLabelsDialog extends Component {
  state = {
    isFilterVisible: false,
    selectedItemNos: [],
    selectedApplicantAccntNos: [],
    selectedManufacturerAccntNos: [],
    selectedFileNos: [],
    selectedCustomerPartNos: [],
    selectedItem: '',
    selectedApplicantNo: '',
    selectedManufactureNo: '',
    selectefFileNo: '',
    selectedCustomerPartNo: '',
    activeIndex: 0,
    selectedPrinterFileNumbers: [],
    selectedPrinterPSNs: [],
    selectedEmailAddresses: [],
    selectedPrinterFileNumber: '',
    selectedPrinterPSN: '',
    selectedEmailAddress: '',
    isDeleteModalOpen: false,
    selectedStandardItemNumbers: [],
    selectedStandardCcns: [],
    selectedStandardItemNumber: '',
    selectedStandardCcn: '',
  };

  handleToggleFilter = isOpen => {
    this.setState({ isFilterVisible: isOpen });
  };

  handleTextChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleAddItemNumber = itemNumber => {
    const { selectedItemNos } = this.state;

    const newSelectedItemNo = selectedItemNos;
    if (itemNumber) {
      if (!newSelectedItemNo.includes(itemNumber))
        newSelectedItemNo.push(itemNumber);
    }

    this.setState({ selectedItemNos: newSelectedItemNo });
    this.setState({ selectedItem: '' });
  };

  handleAddPrinterFileNumber = printerFileNumber => {
    const { selectedPrinterFileNumbers } = this.state;

    const newSelectedPrinterFileNumbers = selectedPrinterFileNumbers;
    if (printerFileNumber) {
      if (!newSelectedPrinterFileNumbers.includes(printerFileNumber))
        newSelectedPrinterFileNumbers.push(printerFileNumber);
    }

    this.setState({
      selectedPrinterFileNumbers: newSelectedPrinterFileNumbers,
    });
    this.setState({ selectedPrinterFileNumber: '' });
  };

  handleAddPrinterPSN = item => {
    const { selectedPrinterPSNs } = this.state;

    const newSelectedPrinterPSNs = selectedPrinterPSNs;
    if (item) {
      if (!newSelectedPrinterPSNs.includes(item))
        newSelectedPrinterPSNs.push(item);
    }

    this.setState({
      selectedPrinterPSNs: newSelectedPrinterPSNs,
    });
    this.setState({ selectedPrinterPSN: '' });
  };

  handleAddPrinterEmail = item => {
    const { selectedEmailAddresses } = this.state;

    const newSelectedEmailAddresses = selectedEmailAddresses;
    if (item) {
      if (!newSelectedEmailAddresses.includes(item))
        newSelectedEmailAddresses.push(item);
    }

    this.setState({
      selectedEmailAddresses: newSelectedEmailAddresses,
    });
    this.setState({ selectedEmailAddress: '' });
  };
  handleAddApplicantNumber = applicantNmber => {
    const { selectedApplicantAccntNos } = this.state;

    const newSelectedApplicantAccntNos = selectedApplicantAccntNos;
    if (applicantNmber) {
      if (!newSelectedApplicantAccntNos.includes(applicantNmber))
        newSelectedApplicantAccntNos.push(applicantNmber);
    }

    this.setState({ selectedApplicantAccntNos: newSelectedApplicantAccntNos });
    this.setState({ selectedApplicantNo: '' });
  };

  handleAddManufacterNumber = manufacturerNmber => {
    const { selectedManufacturerAccntNos } = this.state;

    const newSelecteManufacturerAccntNos = selectedManufacturerAccntNos;
    if (manufacturerNmber) {
      if (!newSelecteManufacturerAccntNos.includes(manufacturerNmber))
        newSelecteManufacturerAccntNos.push(manufacturerNmber);
    }

    this.setState({
      selectedManufacturerAccntNos: newSelecteManufacturerAccntNos,
    });
    this.setState({ selectedManufactureNo: '' });
  };

  handleAddFileNumber = fileNumber => {
    const { selectedFileNos } = this.state;

    const newSelectedFileNos = selectedFileNos;
    if (fileNumber) {
      if (!newSelectedFileNos.includes(fileNumber))
        newSelectedFileNos.push(fileNumber);
    }

    this.setState({ selectedFileNos: newSelectedFileNos });
    this.setState({ selectedFileNo: '' });
  };

  handleAddCustomerPartNumber = customerPartNumber => {
    const { selectedCustomerPartNos } = this.state;

    const newSelectedCustomerPartNos = selectedCustomerPartNos;
    if (customerPartNumber) {
      if (!newSelectedCustomerPartNos.includes(customerPartNumber))
        newSelectedCustomerPartNos.push(customerPartNumber);
    }

    this.setState({ selectedCustomerPartNos: newSelectedCustomerPartNos });
    this.setState({ selectedCustomerPartNo: '' });
  };

  handleAddStandardItemNumber = itemNumber => {
    const { selectedStandardItemNumbers } = this.state;

    const newSelectedStandardItemNumbers = selectedStandardItemNumbers;
    if (itemNumber) {
      if (!newSelectedStandardItemNumbers.includes(itemNumber))
        newSelectedStandardItemNumbers.push(itemNumber);
    }

    this.setState({
      selectedStandardItemNumbers: newSelectedStandardItemNumbers,
    });
    this.setState({ selectedStandardItemNumber: '' });
  };

  handleAddStandardCcn = ccn => {
    const { selectedStandardCcns } = this.state;

    const newSelectedStandardCcns = selectedStandardCcns;
    if (ccn) {
      if (!newSelectedStandardCcns.includes(ccn))
        newSelectedStandardCcns.push(ccn);
    }

    this.setState({
      selectedStandardCcns: newSelectedStandardCcns,
    });
    this.setState({ selectedStandardCcn: '' });
  };

  handleRemoveItemNumber = itemNumber => {
    const { selectedItemNos } = this.state;
    const newSelectedItemNo = selectedItemNos;
    if (itemNumber) {
      const index = newSelectedItemNo.indexOf(itemNumber);
      if (index > -1) {
        newSelectedItemNo.splice(index, 1);
      }
    }
    this.setState({ selectedItemNos: newSelectedItemNo });
  };

  handleRemovePrinterFileNumber = item => {
    const { selectedPrinterFileNumbers } = this.state;
    const newSelectedPrinterFileNumbers = selectedPrinterFileNumbers;
    if (item) {
      const index = newSelectedPrinterFileNumbers.indexOf(item);
      if (index > -1) {
        newSelectedPrinterFileNumbers.splice(index, 1);
      }
    }
    this.setState({
      selectedPrinterFileNumbers: newSelectedPrinterFileNumbers,
    });
  };

  handleRemovePrinterPSN = item => {
    const { selectedPrinterPSNs } = this.state;
    const newSelectedPrinterPSNs = selectedPrinterPSNs;
    if (item) {
      const index = newSelectedPrinterPSNs.indexOf(item);
      if (index > -1) {
        newSelectedPrinterPSNs.splice(index, 1);
      }
    }
    this.setState({
      selectedPrinterPSNs: newSelectedPrinterPSNs,
    });
  };

  handleRemovePrinterEmail = item => {
    const { selectedEmailAddresses } = this.state;
    const newSelectedEmailAddresses = selectedEmailAddresses;
    if (item) {
      const index = newSelectedEmailAddresses.indexOf(item);
      if (index > -1) {
        newSelectedEmailAddresses.splice(index, 1);
      }
    }
    this.setState({
      selectedEmailAddresses: newSelectedEmailAddresses,
    });
  };

  handleRemoveApplicantNumber = applicantNmber => {
    const { selectedApplicantAccntNos } = this.state;
    const newSelectedApplicantAccntNos = selectedApplicantAccntNos;
    if (applicantNmber) {
      const index = newSelectedApplicantAccntNos.indexOf(applicantNmber);
      if (index > -1) {
        newSelectedApplicantAccntNos.splice(index, 1);
      }
    }
    this.setState({ selectedApplicantAccntNos: newSelectedApplicantAccntNos });
  };

  handleRemoveManufacturerNumber = manufacturerNmber => {
    const { selectedManufacturerAccntNos } = this.state;
    const newselectedManufactureNos = selectedManufacturerAccntNos;
    if (manufacturerNmber) {
      const index = newselectedManufactureNos.indexOf(manufacturerNmber);
      if (index > -1) {
        newselectedManufactureNos.splice(index, 1);
      }
    }
    this.setState({ selectedManufacturerAccntNos: newselectedManufactureNos });
  };

  handleRemoveFileNumber = fileNumber => {
    const { selectedFileNos } = this.state;
    const newSelecteFileNos = selectedFileNos;
    if (fileNumber) {
      const index = newSelecteFileNos.indexOf(fileNumber);
      if (index > -1) {
        newSelecteFileNos.splice(index, 1);
      }
    }
    this.setState({ selectedFileNos: newSelecteFileNos });
  };

  handleRemoveCustomerPartNumber = customerPartNumber => {
    const { selectedCustomerPartNos } = this.state;
    const newSelectedCustomerPartNos = selectedCustomerPartNos;
    if (customerPartNumber) {
      const index = newSelectedCustomerPartNos.indexOf(customerPartNumber);
      if (index > -1) {
        newSelectedCustomerPartNos.splice(index, 1);
      }
    }
    this.setState({ selectedCustomerPartNos: newSelectedCustomerPartNos });
  };

  handleRemoveStandardItemNumber = item => {
    const { selectedStandardItemNumbers } = this.state;
    const newSelectedStandardItemNumbers = selectedStandardItemNumbers;
    if (item) {
      const index = newSelectedStandardItemNumbers.indexOf(item);
      if (index > -1) {
        newSelectedStandardItemNumbers.splice(index, 1);
      }
    }
    this.setState({
      selectedStandardItemNumbers: newSelectedStandardItemNumbers,
    });
  };

  handleRemoveStandardCcn = item => {
    const { selectedStandardCcns } = this.state;
    const newSelectedStandardCcns = selectedStandardCcns;
    if (item) {
      const index = newSelectedStandardCcns.indexOf(item);
      if (index > -1) {
        newSelectedStandardCcns.splice(index, 1);
      }
    }
    this.setState({
      selectedStandardCcns: newSelectedStandardCcns,
    });
  };

  handleApplyFilter = () => {
    if (
      this.props.uploadType ===
      uploadTemplate.combinationLabelItemNumbersTemplate
    ) {
      const {
        selectedItemNos,
        selectedApplicantAccntNos,
        selectedManufacturerAccntNos,
        selectedFileNos,
        selectedCustomerPartNos,
      } = this.state;
      this.props.handleApplyCombinationLabelFilter({
        selectedItemNos,
        selectedApplicantAccntNos,
        selectedManufacturerAccntNos,
        selectedFileNos,
        selectedCustomerPartNos,
      });
    }

    if (
      this.props.uploadType ===
      uploadTemplate.authorizedLabelSupplierEmailTemplate
    ) {
      const {
        selectedPrinterFileNumbers,
        selectedPrinterPSNs,
        selectedEmailAddresses,
      } = this.state;

      this.props.handleApplyLabelSupplierPrinterEmailFilter({
        selectedPrinterFileNumbers,
        selectedPrinterPSNs,
        selectedEmailAddresses,
      });
    }

    if (
      this.props.uploadType === uploadTemplate.standardLabelManagementTemplate
    ) {
      const { selectedStandardItemNumbers, selectedStandardCcns } = this.state;

      this.props.handleApplyStandardLabelsFilter({
        selectedStandardItemNumbers,
        selectedStandardCcns,
      });
    }
  };

  handleDateFormat = dateUpdate => {
    let retDate = '';
    if (dateUpdate) {
      retDate = new Date(dateUpdate).toLocaleDateString();
    }
    return retDate.toString();
  };

  handleClearFilter = () => {
    this.setState({
      selectedItemNos: [],
      selectedApplicantAccntNos: [],
      selectedManufacturerAccntNos: [],
      selectedFileNos: [],
      selectedCustomerPartNos: [],
      selectedPrinterFileNumbers: [],
      selectedPrinterPSNs: [],
      selectedEmailAddresses: [],
      selectedStandardItemNumbers: [],
      selectedStandardCcns: [],
    });

    if (
      this.props.uploadType ===
      uploadTemplate.combinationLabelItemNumbersTemplate
    ) {
      this.props.handleClearCombinationLabelFilter();
    }
    if (
      this.props.uploadType ===
      uploadTemplate.authorizedLabelSupplierEmailTemplate
    ) {
      this.props.handleClearLabelSupplierEmailFilter();
    }
    if (
      this.props.uploadType === uploadTemplate.standardLabelManagementTemplate
    ) {
      this.props.handleClearStandardLabelsFilter();
    }
  };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  toggleDeleteModal = toggle => {
    this.setState({ isDeleteModalOpen: toggle });
  };

  handleConfirmRemoveItems = toggle => {
    const { removeCombinationLabels } = this.props;
    this.setState({ isDeleteModalOpen: toggle });

    removeCombinationLabels();
  };

  render() {
    const {
      isOpenViewLabelsDialog,
      labels,
      userRole,
      uploadType,
      nonDenominationalAccounts,
      labelSupplierEmailsFiltered,
      combinationAllowedCcns,
      selectItemNumber,
      selectAllItems,
      isSelectedAll,
      isRemoveSuccess,
      selectedItems,
      resetRemoveLabels,
      selectedItemNumbers,
      currencyHandlingFees,
    } = this.props;
    let { mapLabels } = this.props;
    const {
      selectedItemNos,
      selectedApplicantAccntNos,
      selectedManufacturerAccntNos,
      selectedCustomerPartNos,
      selectedFileNos,
      selectedItem,
      selectedApplicantNo,
      activeIndex,
      selectedManufactureNo,
      selectefFileNo,
      selectedCustomerPartNo,
      selectedPrinterFileNumbers,
      selectedPrinterPSNs,
      selectedEmailAddresses,
      selectedPrinterFileNumber,
      selectedPrinterPSN,
      selectedEmailAddress,
      isDeleteModalOpen,
      selectedStandardItemNumbers,
      selectedStandardCcns,
      selectedStandardItemNumber,
      selectedStandardCcn,
    } = this.state;

    let newLabels = [];
    if (uploadType === uploadTemplate.combinationLabelEligibleCcnsTemplate) {
      newLabels = combinationAllowedCcns.map(item => {
        return {
          CCN: item.ccn,
          'Service Type': item.surveyType,
          Status: item.status,
        };
      });
    } else if (
      uploadType === uploadTemplate.authorizedLabelSupplierEmailTemplate
    ) {
      newLabels = labelSupplierEmailsFiltered.map(item => {
        return {
          'Printer File Number': item.printerFileNumber,
          'Printer PSN': item.printerPSN,
          'Email Address': item.emailAddress,
          'Email Status': item.emailStatus,
          'Updated By': item.updatedByDisplay,
        };
      });
    } else if (uploadType === uploadTemplate.standardLabelManagementTemplate) {
      newLabels = labels.map(item => {
        return {
          'Label Acct': item.labelAccount,
          CCN: item.ccn,
          'Label Description': item.description,
          'Item Number': item.itemNumber,
          'Label Order Package': item.labelOrderPackage,
          'Min. Order Qty': item.minOrderQuantity,
          'ORACLE UOM': item.oracleUnitOfMeasurementName,
        };
      });
    } else if (uploadType === uploadTemplate.priceListManagementTemplate) {
      newLabels = labels.map(item => {
        return {
          'Product Value': item.itemNumber,
          'Product Description': item.description,
          UOM: item.unitOfMeasurementName,
          Value: item.price,
        };
      });
    } else if (
      uploadType === uploadTemplate.combinationLabelItemNumbersTemplate
    ) {
      newLabels = labels.map(item => {
        return {
          'Appl Account#': item.applicantAccountNumber,
          'Mfr Account#': item.manufacturerAccountNumber,
          'Basic File#': item.fileNumber,
          'Item Number': item.itemNumber,
          'Item Description': item.itemDescription,
          'Customer Part No.': item.customerPartNo,
          'Updated By': item.updatedByDisplay,
          'Updated Date': this.handleDateFormat(item.updatedDate),
        };
      });
    } else if (uploadType === uploadTemplate.authorizedNdLabelTemplate) {
      newLabels = nonDenominationalAccounts.map(item => {
        return {
          'Manufacturer Account#': item.manufacturerAccount,
          'Manufacturer Customer Name': item.manufacturerName,
          Status: item.status,
        };
      });
    } else if (uploadType === uploadTemplate.standardLabelHandlingFeeTemplate) {
      newLabels = currencyHandlingFees.map(item => {
        return {
          Currency: item.currency,
          'Handling Fee': item.handlingFee,
          'Updated By': item.updatedBy,
          'Updated Date': item.updatedDate,
        };
      });
    }

    if (isRemoveSuccess) {
      mapLabels = mapLabels.filter(m => selectedItems.indexOf(m.Id) === -1);
      resetRemoveLabels();
      this.handleClearFilter();
    }

    return (
      <Fragment>
        <Modal
          size="large"
          open={isOpenViewLabelsDialog}
          onClose={this.props.handleCloseViewLabelsDialog}
          closeOnEscape={false}
          closeOnDimmerClick={false}
        >
          <Modal.Header>
            <Table>
              <Table.Row>
                <Table.Cell verticalAlign="middle">
                  <Icon name="file" /> View Records
                </Table.Cell>
                <Table.Cell width={10} verticalAlign="middle" textAlign="right">
                  {uploadType ===
                  uploadTemplate.combinationLabelItemNumbersTemplate ? (
                    <Fragment>
                      <Button
                        basic
                        color="red"
                        content="Clear"
                        floated="right"
                        onClick={this.toggleDeleteModal.bind(this, true)}
                        disabled={
                          selectedItemNumbers &&
                          selectedItemNumbers.length === 0
                        }
                      >
                        Delete
                      </Button>
                      <Popup
                        trigger={
                          <Button
                            icon="filter"
                            floated="right"
                            basic
                            color="blue"
                          >
                            <Icon name="filter"></Icon>
                            Filter
                          </Button>
                        }
                        on="click"
                        pinned
                        position="bottom right"
                      >
                        <Card>
                          <Card.Content>
                            <Image floated="right" size="mini" />
                            <Card.Header>Set Filter Option</Card.Header>
                            {/* <Card.Meta>Friends of Elliot</Card.Meta> */}
                            <Card.Description>
                              <Divider hidden />
                              <Accordion fluid>
                                <Accordion.Title
                                  active={activeIndex === 0}
                                  index={0}
                                  onClick={this.handleClick}
                                >
                                  <Icon name="dropdown" />
                                  Item Number
                                </Accordion.Title>
                                <Accordion.Content active={activeIndex === 0}>
                                  <List>
                                    {selectedItemNos &&
                                      selectedItemNos.map((item, idx) => {
                                        return (
                                          item &&
                                          selectedItemNos.includes(item) && (
                                            <List.Item key={idx}>
                                              <Grid>
                                                <Grid.Row centered>
                                                  <Input
                                                    value={item}
                                                    disabled
                                                    style={{
                                                      marginRight: '10px',
                                                    }}
                                                  />
                                                  <Button
                                                    color="red"
                                                    basic
                                                    icon="delete"
                                                    onClick={() => {
                                                      this.handleRemoveItemNumber(
                                                        item
                                                      );
                                                    }}
                                                  />
                                                </Grid.Row>
                                              </Grid>
                                            </List.Item>
                                          )
                                        );
                                      })}
                                    {selectedItemNos && (
                                      <List.Item>
                                        <Grid>
                                          <Grid.Row centered>
                                            <Input
                                              name="selectedItem"
                                              value={selectedItem}
                                              onChange={e => {
                                                this.handleTextChange(e);
                                              }}
                                              style={{ marginRight: '10px' }}
                                            />

                                            <Button
                                              color="green"
                                              basic
                                              icon="add"
                                              style={{ marginLeft: '20px' }}
                                              onClick={() => {
                                                this.handleAddItemNumber(
                                                  selectedItem
                                                );
                                              }}
                                            />
                                          </Grid.Row>
                                        </Grid>
                                      </List.Item>
                                    )}
                                  </List>
                                </Accordion.Content>
                                <Accordion.Title
                                  active={activeIndex === 1}
                                  index={1}
                                  onClick={this.handleClick}
                                >
                                  <Icon name="dropdown" />
                                  Applicant Account No.
                                </Accordion.Title>
                                <Accordion.Content active={activeIndex === 1}>
                                  <List>
                                    {selectedApplicantAccntNos &&
                                      selectedApplicantAccntNos.map(
                                        (item, idx) => {
                                          return (
                                            item &&
                                            selectedApplicantAccntNos.includes(
                                              item
                                            ) && (
                                              <List.Item key={idx}>
                                                <Grid>
                                                  <Grid.Row centered>
                                                    <Input
                                                      value={item}
                                                      disabled
                                                      style={{
                                                        marginRight: '10px',
                                                      }}
                                                    />
                                                    <Button
                                                      color="red"
                                                      basic
                                                      icon="delete"
                                                      onClick={() => {
                                                        this.handleRemoveApplicantNumber(
                                                          item
                                                        );
                                                      }}
                                                    />
                                                  </Grid.Row>
                                                </Grid>
                                              </List.Item>
                                            )
                                          );
                                        }
                                      )}
                                    {selectedApplicantAccntNos && (
                                      <List.Item>
                                        <Grid>
                                          <Grid.Row centered>
                                            <Input
                                              name="selectedApplicantNo"
                                              value={selectedApplicantNo}
                                              onChange={e => {
                                                this.handleTextChange(e);
                                              }}
                                              style={{ marginRight: '10px' }}
                                            />

                                            <Button
                                              color="green"
                                              basic
                                              icon="add"
                                              style={{ marginLeft: '20px' }}
                                              onClick={() => {
                                                this.handleAddApplicantNumber(
                                                  selectedApplicantNo
                                                );
                                              }}
                                            />
                                          </Grid.Row>
                                        </Grid>
                                      </List.Item>
                                    )}
                                  </List>
                                </Accordion.Content>
                                <Accordion.Title
                                  active={activeIndex === 2}
                                  index={2}
                                  onClick={this.handleClick}
                                >
                                  <Icon name="dropdown" />
                                  Manufacturer Account No.
                                </Accordion.Title>
                                <Accordion.Content active={activeIndex === 2}>
                                  <List>
                                    {selectedManufacturerAccntNos &&
                                      selectedManufacturerAccntNos.map(
                                        (item, idx) => {
                                          return (
                                            item &&
                                            selectedManufacturerAccntNos.includes(
                                              item
                                            ) && (
                                              <List.Item key={idx}>
                                                <Grid>
                                                  <Grid.Row centered>
                                                    <Input
                                                      value={item}
                                                      disabled
                                                      style={{
                                                        marginRight: '10px',
                                                      }}
                                                    />
                                                    <Button
                                                      color="red"
                                                      basic
                                                      icon="delete"
                                                      onClick={() => {
                                                        this.handleRemoveManufacturerNumber(
                                                          item
                                                        );
                                                      }}
                                                    />
                                                  </Grid.Row>
                                                </Grid>
                                              </List.Item>
                                            )
                                          );
                                        }
                                      )}
                                    {selectedManufacturerAccntNos && (
                                      <List.Item>
                                        <Grid>
                                          <Grid.Row centered>
                                            <Input
                                              name="selectedManufactureNo"
                                              value={selectedManufactureNo}
                                              onChange={e => {
                                                this.handleTextChange(e);
                                              }}
                                              style={{ marginRight: '10px' }}
                                            />

                                            <Button
                                              color="green"
                                              basic
                                              icon="add"
                                              style={{ marginLeft: '20px' }}
                                              onClick={() => {
                                                this.handleAddManufacterNumber(
                                                  selectedManufactureNo
                                                );
                                              }}
                                            />
                                          </Grid.Row>
                                        </Grid>
                                      </List.Item>
                                    )}
                                  </List>
                                </Accordion.Content>
                                <Accordion.Title
                                  active={activeIndex === 3}
                                  index={3}
                                  onClick={this.handleClick}
                                >
                                  <Icon name="dropdown" />
                                  File No.
                                </Accordion.Title>
                                <Accordion.Content active={activeIndex === 3}>
                                  <List>
                                    {selectedFileNos &&
                                      selectedFileNos.map((item, idx) => {
                                        return (
                                          item &&
                                          selectedFileNos.includes(item) && (
                                            <List.Item key={idx}>
                                              <Grid>
                                                <Grid.Row centered>
                                                  <Input
                                                    value={item}
                                                    disabled
                                                    style={{
                                                      marginRight: '10px',
                                                    }}
                                                  />
                                                  <Button
                                                    color="red"
                                                    basic
                                                    icon="delete"
                                                    onClick={() => {
                                                      this.handleRemoveFileNumber(
                                                        item
                                                      );
                                                    }}
                                                  />
                                                </Grid.Row>
                                              </Grid>
                                            </List.Item>
                                          )
                                        );
                                      })}
                                    {selectedFileNos && (
                                      <List.Item>
                                        <Grid>
                                          <Grid.Row centered>
                                            <Input
                                              name="selectefFileNo"
                                              value={selectefFileNo}
                                              onChange={e => {
                                                this.handleTextChange(e);
                                              }}
                                              style={{ marginRight: '10px' }}
                                            />

                                            <Button
                                              color="green"
                                              basic
                                              icon="add"
                                              style={{ marginLeft: '20px' }}
                                              onClick={() => {
                                                this.handleAddFileNumber(
                                                  selectefFileNo
                                                );
                                              }}
                                            />
                                          </Grid.Row>
                                        </Grid>
                                      </List.Item>
                                    )}
                                  </List>
                                </Accordion.Content>
                                <Accordion.Title
                                  active={activeIndex === 4}
                                  index={4}
                                  onClick={this.handleClick}
                                >
                                  <Icon name="dropdown" />
                                  Customer Part No.
                                </Accordion.Title>
                                <Accordion.Content active={activeIndex === 4}>
                                  <List>
                                    {selectedCustomerPartNos &&
                                      selectedCustomerPartNos.map(
                                        (item, idx) => {
                                          return (
                                            item &&
                                            selectedCustomerPartNos.includes(
                                              item
                                            ) && (
                                              <List.Item key={idx}>
                                                <Grid>
                                                  <Grid.Row centered>
                                                    <Input
                                                      value={item}
                                                      disabled
                                                      style={{
                                                        marginRight: '10px',
                                                      }}
                                                    />
                                                    <Button
                                                      color="red"
                                                      basic
                                                      icon="delete"
                                                      onClick={() => {
                                                        this.handleRemoveCustomerPartNumber(
                                                          item
                                                        );
                                                      }}
                                                    />
                                                  </Grid.Row>
                                                </Grid>
                                              </List.Item>
                                            )
                                          );
                                        }
                                      )}
                                    {selectedCustomerPartNos && (
                                      <List.Item>
                                        <Grid>
                                          <Grid.Row centered>
                                            <Input
                                              name="selectedCustomerPartNo"
                                              value={selectedCustomerPartNo}
                                              onChange={e => {
                                                this.handleTextChange(e);
                                              }}
                                              style={{ marginRight: '10px' }}
                                            />

                                            <Button
                                              color="green"
                                              basic
                                              icon="add"
                                              style={{ marginLeft: '20px' }}
                                              onClick={() => {
                                                this.handleAddCustomerPartNumber(
                                                  selectedCustomerPartNo
                                                );
                                              }}
                                            />
                                          </Grid.Row>
                                        </Grid>
                                      </List.Item>
                                    )}
                                  </List>
                                </Accordion.Content>
                              </Accordion>
                            </Card.Description>
                          </Card.Content>
                          <Card.Content extra>
                            <div className="ui two buttons">
                              <Button
                                basic
                                primary
                                onClick={this.handleApplyFilter}
                              >
                                Apply
                              </Button>
                              <Button
                                basic
                                secondary
                                onClick={this.handleClearFilter}
                              >
                                Clear
                              </Button>
                            </div>
                          </Card.Content>
                        </Card>
                      </Popup>
                    </Fragment>
                  ) : (
                    <></>
                  )}
                  {uploadType ===
                  uploadTemplate.authorizedLabelSupplierEmailTemplate ? (
                    <Popup
                      trigger={
                        <Button
                          icon="filter"
                          floated="right"
                          basic
                          color="blue"
                        >
                          <Icon name="filter"></Icon>
                          Filter
                        </Button>
                      }
                      on="click"
                      pinned
                      position="bottom right"
                    >
                      <Card>
                        <Card.Content>
                          <Image floated="right" size="mini" />
                          <Card.Header>Set Filter Option</Card.Header>
                          {/* <Card.Meta>Friends of Elliot</Card.Meta> */}
                          <Card.Description>
                            <Divider hidden />
                            <Accordion fluid>
                              <Accordion.Title
                                active={activeIndex === 0}
                                index={0}
                                onClick={this.handleClick}
                              >
                                <Icon name="dropdown" />
                                Printer File Number
                              </Accordion.Title>
                              <Accordion.Content active={activeIndex === 0}>
                                <List>
                                  {selectedPrinterFileNumbers &&
                                    selectedPrinterFileNumbers.map(
                                      (item, idx) => {
                                        return (
                                          item &&
                                          selectedPrinterFileNumbers.includes(
                                            item
                                          ) && (
                                            <List.Item key={idx}>
                                              <Grid>
                                                <Grid.Row centered>
                                                  <Input
                                                    value={item}
                                                    disabled
                                                    style={{
                                                      marginRight: '10px',
                                                    }}
                                                  />
                                                  <Button
                                                    color="red"
                                                    basic
                                                    icon="delete"
                                                    onClick={() => {
                                                      this.handleRemovePrinterFileNumber(
                                                        item
                                                      );
                                                    }}
                                                  />
                                                </Grid.Row>
                                              </Grid>
                                            </List.Item>
                                          )
                                        );
                                      }
                                    )}
                                  {selectedPrinterFileNumbers && (
                                    <List.Item>
                                      <Grid>
                                        <Grid.Row centered>
                                          <Input
                                            name="selectedPrinterFileNumber"
                                            value={selectedPrinterFileNumber}
                                            onChange={e => {
                                              this.handleTextChange(e);
                                            }}
                                            style={{ marginRight: '10px' }}
                                          />

                                          <Button
                                            color="green"
                                            basic
                                            icon="add"
                                            style={{ marginLeft: '20px' }}
                                            onClick={() => {
                                              this.handleAddPrinterFileNumber(
                                                selectedPrinterFileNumber
                                              );
                                            }}
                                          />
                                        </Grid.Row>
                                      </Grid>
                                    </List.Item>
                                  )}
                                </List>
                              </Accordion.Content>
                              <Accordion.Title
                                active={activeIndex === 1}
                                index={1}
                                onClick={this.handleClick}
                              >
                                <Icon name="dropdown" />
                                Printer PSN
                              </Accordion.Title>
                              <Accordion.Content active={activeIndex === 1}>
                                <List>
                                  {selectedPrinterPSNs &&
                                    selectedPrinterPSNs.map((item, idx) => {
                                      return (
                                        item &&
                                        selectedPrinterPSNs.includes(item) && (
                                          <List.Item key={idx}>
                                            <Grid>
                                              <Grid.Row centered>
                                                <Input
                                                  value={item}
                                                  disabled
                                                  style={{
                                                    marginRight: '10px',
                                                  }}
                                                />
                                                <Button
                                                  color="red"
                                                  basic
                                                  icon="delete"
                                                  onClick={() => {
                                                    this.handleRemovePrinterPSN(
                                                      item
                                                    );
                                                  }}
                                                />
                                              </Grid.Row>
                                            </Grid>
                                          </List.Item>
                                        )
                                      );
                                    })}
                                  {selectedPrinterPSNs && (
                                    <List.Item>
                                      <Grid>
                                        <Grid.Row centered>
                                          <Input
                                            name="selectedPrinterPSN"
                                            value={selectedPrinterPSN}
                                            onChange={e => {
                                              this.handleTextChange(e);
                                            }}
                                            style={{ marginRight: '10px' }}
                                          />

                                          <Button
                                            color="green"
                                            basic
                                            icon="add"
                                            style={{ marginLeft: '20px' }}
                                            onClick={() => {
                                              this.handleAddPrinterPSN(
                                                selectedPrinterPSN
                                              );
                                            }}
                                          />
                                        </Grid.Row>
                                      </Grid>
                                    </List.Item>
                                  )}
                                </List>
                              </Accordion.Content>
                              <Accordion.Title
                                active={activeIndex === 2}
                                index={2}
                                onClick={this.handleClick}
                              >
                                <Icon name="dropdown" />
                                Email Address
                              </Accordion.Title>
                              <Accordion.Content active={activeIndex === 2}>
                                <List>
                                  {selectedEmailAddresses &&
                                    selectedEmailAddresses.map((item, idx) => {
                                      return (
                                        item &&
                                        selectedEmailAddresses.includes(
                                          item
                                        ) && (
                                          <List.Item key={idx}>
                                            <Grid>
                                              <Grid.Row centered>
                                                <Input
                                                  value={item}
                                                  disabled
                                                  style={{
                                                    marginRight: '10px',
                                                  }}
                                                />
                                                <Button
                                                  color="red"
                                                  basic
                                                  icon="delete"
                                                  onClick={() => {
                                                    this.handleRemovePrinterEmail(
                                                      item
                                                    );
                                                  }}
                                                />
                                              </Grid.Row>
                                            </Grid>
                                          </List.Item>
                                        )
                                      );
                                    })}
                                  {selectedEmailAddresses && (
                                    <List.Item>
                                      <Grid>
                                        <Grid.Row centered>
                                          <Input
                                            name="selectedEmailAddress"
                                            value={selectedEmailAddress}
                                            onChange={e => {
                                              this.handleTextChange(e);
                                            }}
                                            style={{ marginRight: '10px' }}
                                          />

                                          <Button
                                            color="green"
                                            basic
                                            icon="add"
                                            style={{ marginLeft: '20px' }}
                                            onClick={() => {
                                              this.handleAddPrinterEmail(
                                                selectedEmailAddress
                                              );
                                            }}
                                          />
                                        </Grid.Row>
                                      </Grid>
                                    </List.Item>
                                  )}
                                </List>
                              </Accordion.Content>
                            </Accordion>
                          </Card.Description>
                        </Card.Content>
                        <Card.Content extra>
                          <div className="ui two buttons">
                            <Button
                              basic
                              primary
                              onClick={this.handleApplyFilter}
                            >
                              Apply
                            </Button>
                            <Button
                              basic
                              secondary
                              onClick={this.handleClearFilter}
                            >
                              Clear
                            </Button>
                          </div>
                        </Card.Content>
                      </Card>
                    </Popup>
                  ) : (
                    <></>
                  )}
                  {uploadType ===
                  uploadTemplate.standardLabelManagementTemplate ? (
                    <Popup
                      trigger={
                        <Button
                          icon="filter"
                          floated="right"
                          basic
                          color="blue"
                        >
                          <Icon name="filter"></Icon>
                          Filter
                        </Button>
                      }
                      on="click"
                      pinned
                      position="bottom right"
                    >
                      <Card>
                        <Card.Content>
                          <Image floated="right" size="mini" />
                          <Card.Header>Set Filter Option</Card.Header>
                          {/* <Card.Meta>Friends of Elliot</Card.Meta> */}
                          <Card.Description>
                            <Divider hidden />
                            <Accordion fluid>
                              <Accordion.Title
                                active={activeIndex === 0}
                                index={0}
                                onClick={this.handleClick}
                              >
                                <Icon name="dropdown" />
                                Item Number
                              </Accordion.Title>
                              <Accordion.Content active={activeIndex === 0}>
                                <List>
                                  {selectedStandardItemNumbers &&
                                    selectedStandardItemNumbers.map(
                                      (item, idx) => {
                                        return (
                                          item &&
                                          selectedStandardItemNumbers.includes(
                                            item
                                          ) && (
                                            <List.Item key={idx}>
                                              <Grid>
                                                <Grid.Row centered>
                                                  <Input
                                                    value={item}
                                                    disabled
                                                    style={{
                                                      marginRight: '10px',
                                                    }}
                                                  />
                                                  <Button
                                                    color="red"
                                                    basic
                                                    icon="delete"
                                                    onClick={() => {
                                                      this.handleRemoveStandardItemNumber(
                                                        item
                                                      );
                                                    }}
                                                  />
                                                </Grid.Row>
                                              </Grid>
                                            </List.Item>
                                          )
                                        );
                                      }
                                    )}
                                  {selectedStandardItemNumbers && (
                                    <List.Item>
                                      <Grid>
                                        <Grid.Row centered>
                                          <Input
                                            name="selectedStandardItemNumber"
                                            value={selectedStandardItemNumber}
                                            onChange={e => {
                                              this.handleTextChange(e);
                                            }}
                                            style={{ marginRight: '10px' }}
                                          />

                                          <Button
                                            color="green"
                                            basic
                                            icon="add"
                                            style={{ marginLeft: '20px' }}
                                            onClick={() => {
                                              this.handleAddStandardItemNumber(
                                                selectedStandardItemNumber
                                              );
                                            }}
                                          />
                                        </Grid.Row>
                                      </Grid>
                                    </List.Item>
                                  )}
                                </List>
                              </Accordion.Content>
                              <Accordion.Title
                                active={activeIndex === 1}
                                index={1}
                                onClick={this.handleClick}
                              >
                                <Icon name="dropdown" />
                                CCN
                              </Accordion.Title>
                              <Accordion.Content active={activeIndex === 1}>
                                <List>
                                  {selectedStandardCcns &&
                                    selectedStandardCcns.map((item, idx) => {
                                      return (
                                        item &&
                                        selectedStandardCcns.includes(item) && (
                                          <List.Item key={idx}>
                                            <Grid>
                                              <Grid.Row centered>
                                                <Input
                                                  value={item}
                                                  disabled
                                                  style={{
                                                    marginRight: '10px',
                                                  }}
                                                />
                                                <Button
                                                  color="red"
                                                  basic
                                                  icon="delete"
                                                  onClick={() => {
                                                    this.handleRemoveStandardCcn(
                                                      item
                                                    );
                                                  }}
                                                />
                                              </Grid.Row>
                                            </Grid>
                                          </List.Item>
                                        )
                                      );
                                    })}
                                  {selectedStandardCcns && (
                                    <List.Item>
                                      <Grid>
                                        <Grid.Row centered>
                                          <Input
                                            name="selectedStandardCcn"
                                            value={selectedStandardCcn}
                                            onChange={e => {
                                              this.handleTextChange(e);
                                            }}
                                            style={{ marginRight: '10px' }}
                                          />

                                          <Button
                                            color="green"
                                            basic
                                            icon="add"
                                            style={{ marginLeft: '20px' }}
                                            onClick={() => {
                                              this.handleAddStandardCcn(
                                                selectedStandardCcn
                                              );
                                            }}
                                          />
                                        </Grid.Row>
                                      </Grid>
                                    </List.Item>
                                  )}
                                </List>
                              </Accordion.Content>
                            </Accordion>
                          </Card.Description>
                        </Card.Content>
                        <Card.Content extra>
                          <div className="ui two buttons">
                            <Button
                              basic
                              primary
                              onClick={this.handleApplyFilter}
                            >
                              Apply
                            </Button>
                            <Button
                              basic
                              secondary
                              onClick={this.handleClearFilter}
                            >
                              Clear
                            </Button>
                          </div>
                        </Card.Content>
                      </Card>
                    </Popup>
                  ) : (
                    <></>
                  )}
                </Table.Cell>
              </Table.Row>
            </Table>
          </Modal.Header>
          <Modal.Content>
            <Table celled>
              <Table.Header>
                <Table.Row>
                  {uploadType ===
                    uploadTemplate.combinationLabelEligibleCcnsTemplate && (
                    <Fragment>
                      <Table.HeaderCell style={{ width: '10%' }}>
                        CCN
                      </Table.HeaderCell>
                      <Table.HeaderCell style={{ width: '10%' }}>
                        Service Type
                      </Table.HeaderCell>
                      <Table.HeaderCell style={{ width: '10%' }}>
                        Status
                      </Table.HeaderCell>
                    </Fragment>
                  )}
                  {uploadType ===
                    uploadTemplate.authorizedLabelSupplierEmailTemplate && (
                    <Fragment>
                      <Table.HeaderCell style={{ width: '10%' }}>
                        Printer File Number
                      </Table.HeaderCell>
                      <Table.HeaderCell style={{ width: '10%' }}>
                        Printer PSN
                      </Table.HeaderCell>
                      <Table.HeaderCell style={{ width: '10%' }}>
                        Email Address
                      </Table.HeaderCell>
                      <Table.HeaderCell style={{ width: '8%' }}>
                        Email Status
                      </Table.HeaderCell>
                      <Table.HeaderCell style={{ width: '8%' }}>
                        Updated By
                      </Table.HeaderCell>
                    </Fragment>
                  )}
                  {uploadType ===
                    uploadTemplate.standardLabelManagementTemplate && (
                    <Fragment>
                      <Table.HeaderCell style={{ width: '6%' }}>
                        Label Acct
                      </Table.HeaderCell>
                      <Table.HeaderCell style={{ width: '5%' }}>
                        CCN
                      </Table.HeaderCell>
                      <Table.HeaderCell style={{ width: '12.5%' }}>
                        Label Description
                      </Table.HeaderCell>
                      <Table.HeaderCell style={{ width: '5%' }}>
                        Item Number
                      </Table.HeaderCell>
                      <Table.HeaderCell style={{ width: '12%' }}>
                        Image
                      </Table.HeaderCell>
                      <Table.HeaderCell style={{ width: '2%' }}>
                        Label Order Package
                      </Table.HeaderCell>
                      <Table.HeaderCell style={{ width: '2%' }}>
                        Min. Order Qty
                      </Table.HeaderCell>
                      <Table.HeaderCell style={{ width: '0%' }}>
                        ORACLE UOM
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        style={{ width: '4.6%' }}
                      ></Table.HeaderCell>
                    </Fragment>
                  )}
                  {uploadType ===
                    uploadTemplate.combinationLabelItemNumbersTemplate && (
                    <Fragment>
                      <Table.HeaderCell style={{ width: '15%' }}>
                        Appl Account#
                      </Table.HeaderCell>
                      <Table.HeaderCell style={{ width: '15%' }}>
                        Mfr Account#
                      </Table.HeaderCell>
                      <Table.HeaderCell style={{ width: '10%' }}>
                        Basic File#
                      </Table.HeaderCell>
                      <Table.HeaderCell style={{ width: '7.2%' }}>
                        Item Number
                      </Table.HeaderCell>
                      <Table.HeaderCell style={{ width: '7.2%' }}>
                        Item Description
                      </Table.HeaderCell>
                      <Table.HeaderCell style={{ width: '7.2%' }}>
                        Customer Part No.
                      </Table.HeaderCell>
                      <Table.HeaderCell style={{ width: '5%' }}>
                        Oracle Status
                      </Table.HeaderCell>
                      <Table.HeaderCell style={{ width: '5%' }}>
                        Updated By
                      </Table.HeaderCell>
                      <Table.HeaderCell style={{ width: '5%' }}>
                        Updated Date
                      </Table.HeaderCell>
                      <Table.HeaderCell>
                        <Checkbox
                          style={{
                            border: '2px solid black',
                            padding: '0px',
                            height: '23px',
                            width: '23px',
                          }}
                          checked={isSelectedAll}
                          onChange={(e, { checked }) => {
                            selectAllItems(checked);
                          }}
                        />
                      </Table.HeaderCell>
                    </Fragment>
                  )}

                  {uploadType ===
                    uploadTemplate.priceListManagementTemplate && (
                    <Fragment>
                      <Table.HeaderCell style={{ width: '10%' }}>
                        Product Value
                      </Table.HeaderCell>
                      <Table.HeaderCell style={{ width: '39%' }}>
                        Product Description
                      </Table.HeaderCell>
                      <Table.HeaderCell style={{ width: '10%' }}>
                        UOM
                      </Table.HeaderCell>
                      <Table.HeaderCell style={{ width: '5%' }}>
                        Value
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        style={{ width: '6.4%' }}
                      ></Table.HeaderCell>
                    </Fragment>
                  )}
                  {uploadType === uploadTemplate.authorizedNdLabelTemplate && (
                    <Fragment>
                      <Table.HeaderCell>Manufacturer Account#</Table.HeaderCell>
                      <Table.HeaderCell>
                        Manufacturer Customer Name
                      </Table.HeaderCell>
                      <Table.HeaderCell>Status</Table.HeaderCell>
                    </Fragment>
                  )}
                  {uploadType ===
                    uploadTemplate.standardLabelHandlingFeeTemplate && (
                    <Fragment>
                      <Table.HeaderCell>Currency</Table.HeaderCell>
                      <Table.HeaderCell>Handling Fee</Table.HeaderCell>
                      <Table.HeaderCell>Updated By</Table.HeaderCell>
                      <Table.HeaderCell>Updated Date</Table.HeaderCell>
                    </Fragment>
                  )}
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {mapLabels && mapLabels.length > 0 ? (
                  mapLabels.map((item, idx) => {
                    return (
                      <ViewLabelsItem
                        key={idx}
                        item={item}
                        userRole={userRole}
                        uploadType={uploadType}
                        selectItemNumber={selectItemNumber}
                        selectedItems={selectedItems}
                      />
                    );
                  })
                ) : (
                  <Message>No Order Found</Message>
                )}
              </Table.Body>

              <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell colSpan="15">
                    {newLabels && this.props.totalPages > 0 && (
                      <Pagination
                        floated="right"
                        boundaryRange={2}
                        basic
                        activePage={this.props.currentPage}
                        totalPages={this.props.totalPages}
                        onPageChange={(e, { activePage }) => {
                          this.props.handlePageChangeLabels({
                            currentPage: activePage,
                            pageSize: this.props.pageSize,
                          });
                        }}
                      />
                    )}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          </Modal.Content>
          <Modal.Actions>
            <CsvDownload
              filename={new Date().toISOString() + '.csv'}
              data={this.props.csvData}
              style={{
                //pass other props, like styles
                background: '#21ba45',
                backgroundColor: '#21ba45',
                borderRadius: '.28571429rem',
                border: 0,
                display: 'inline-block',
                cursor: 'pointer',
                color: '#ffffff',
                fontStyle: 'normal',
                fontWeight: 'bold',
                textDecoration: 'none',
                lineHeight: '1em',
                minHeight: '1em',
                outline: 0,
                verticalAlign: 'center',
                fontFamily: "Lato,'Helvetica Neue',Arial,Helvetica,sans-serif",
                margin: '0 .25em 0 0',
                padding: '.78571429em 1.5em .78571429em',
                textAlign: 'center',
              }}
              delimiter=","
            >
              <Icon name="file excel" /> Export
            </CsvDownload>

            <Button
              primary
              basic
              onClick={this.props.handleCloseViewLabelsDialog}
            >
              Close
            </Button>
          </Modal.Actions>
        </Modal>
        <Modal
          size="small"
          open={isDeleteModalOpen}
          onClose={this.toggleDeleteModal.bind(this, false)}
        >
          <Modal.Header>Confirmation</Modal.Header>
          <Modal.Content>
            <Message
              negative
              icon="exclamation"
              header="Are you sure to delete the selected relationship(s)?"
            />
          </Modal.Content>
          <Modal.Actions>
            <Button primary onClick={this.toggleDeleteModal.bind(this, false)}>
              No
            </Button>
            <Button
              primary
              basic
              onClick={this.handleConfirmRemoveItems.bind(this, false)}
            >
              Yes
            </Button>
          </Modal.Actions>
        </Modal>
      </Fragment>
    );
  }
}

export default ViewLabelsDialog;
