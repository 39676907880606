import * as types from "./login.type";
import api from "../../services/api";
import jwtDecode from "jwt-decode";
import axios from "axios";

const dispatchResult = (type, payload) => {
  return { type: type, payload: payload };
};

export const doExternalLogin = () => {
  return async (dispatch) => {
    dispatch(dispatchResult(types.LOGIN_REQUEST));
    await api.Auth.loginExternal()
      .then((result) => {
        //console.log(result);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.status === 400) {
            dispatch(
              dispatchResult(
                types.LOGIN_FAILURE,
                error.response.data.errors.Email[0]
              )
            );
          }
        } else
          dispatch(
            dispatchResult(
              types.LOGIN_FAILURE,
              "System error. You may contact technical resource team for assistance: +1.847.272.8800"
            )
          );
      });
  };
};

export const doLogin = (username, password) => {
  return async (dispatch) => {
    dispatch(dispatchResult(types.LOGIN_REQUEST));
    await api.Auth.login(username, password)
      .then((result) => {
        if (result.code === 401 || result.code === 500) {
          dispatch(dispatchResult(types.LOGIN_FAILURE, result.message));
        } else {
          const {
            id,
            token,
            firstName,
            lastName,
            isVerified,
            partySiteNumber
          } = result.data;
          if (token && token !== null) {
            const value = jwtDecode(token);
            localStorage.setItem("roles", JSON.stringify(value.role));
            localStorage.setItem("customerId", JSON.stringify(value.nameid));
          }

          if (id && token) {
            localStorage.setItem("userId", JSON.stringify(id));
            localStorage.setItem("token", JSON.stringify(token));
            localStorage.setItem("firstName", JSON.stringify(firstName));
            localStorage.setItem("lastName", JSON.stringify(lastName));
            localStorage.setItem("isVerified", JSON.stringify(isVerified));
            localStorage.setItem("partySiteNumber", partySiteNumber);
            dispatch(dispatchResult(types.LOGIN_SUCCESS, result.data));
          } else {
            dispatch(
              dispatchResult(types.LOGIN_FAILURE, "Something went wrong.")
            );
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.status === 400) {
            dispatch(
              dispatchResult(
                types.LOGIN_FAILURE,
                error.response.data.errors.Email[0]
              )
            );
          }
        } else
          dispatch(
            dispatchResult(
              types.LOGIN_FAILURE,
              "System error. You may contact technical resource team for assistance: +1.847.272.8800"
            )
          );
      });
  };
};

export const doLogout = () => {
  return async (dispatch) => {
    //invokeSingleSignOut();
    dispatch(dispatchResult(types.LOGOUT_SUCCESS));
    localStorage.removeItem("userId");
    localStorage.removeItem("roles");
    localStorage.removeItem("user");
    localStorage.removeItem("userToken");
    localStorage.removeItem("isFirstTimeLogin");
    localStorage.removeItem("token");
    localStorage.removeItem("firstName");
    localStorage.removeItem("lastName");
    localStorage.removeItem("isVerified");
    localStorage.removeItem("isSuspended");
  };
};

export const invokeSingleSignOut = () => {
  return async () => {
    const response = await axios.get(
      "https://devsignin.ul.com/oam/server/logout"
    );
    //console.log(response.data);
  };
};

export const getRolePages = () => {
  return async (dispatch) => {
    dispatch(dispatchResult(types.ROLEPAGES_REQUEST));

    await api.User.getRolePages()
      .then((result) => {
        if (result) dispatch(dispatchResult(types.ROLEPAGES_SUCCESS, result));
      })
      .catch((error) => {
        dispatch(dispatchResult(types.ROLEPAGES_FAILURE, error));
      });
  };
};

export const clearLogin = () => (dispatch) => {
  dispatch(dispatchResult(types.CLEAR));
};

export const updateOrderPage = (roleId) => {
  return async (dispatch) => {
    dispatch(
      dispatchResult(types.UPDATE_ORDERPAGE_SUCCESS, { roleId: roleId })
    );
  };
};
