export const CREATE_ACCOUNT_SETTINGS_REQUEST =
  "CREATE_ACCOUNT_SETTINGS_REQUEST";
export const CREATE_ACCOUNT_SETTINGS_SUCCESS =
  "CREATE_ACCOUNT_SETTINGS_SUCCESS";
export const CREATE_ACCOUNT_SETTINGS_FAILURE =
  "CREATE_ACCOUNT_SETTINGS_FAILURE";
export const CREATE_ACCOUNT_SETTINGS_INITIALIZE =
  "CREATE_ACCOUNT_SETTINGS_INITIALIZE";
export const UPDATE_ACCOUNT_SETTINGS_FORM = "UPDATE_ACCOUNT_SETTINGS_FORM";

export const GET_ACCOUNT_SETTINGS_REQUEST = "GET_ACCOUNT_SETTINGS_REQUEST";
export const GET_ACCOUNT_SETTINGS_SUCCESS = "GET_ACCOUNT_SETTINGS_SUCCESS";
export const GET_ACCOUNT_SETTINGS_FAILURE = "GET_ACCOUNT_SETTINGS_FAILURE";

export const GET_COUNTRIES_REQUEST = "GET_COUNTRIES_REQUEST";
export const GET_COUNTRIES_SUCCESS = "GET_COUNTRIES_SUCCESS";
export const GET_COUNTRIES_FAILURE = "GET_COUNTRIES_FAILURE";

export const GET_BILLTO_COUNTRIES_REQUEST = "GET_BILLTO_COUNTRIES_REQUEST";
export const GET_BILLTO_COUNTRIES_SUCCESS = "GET_BILLTO_COUNTRIES_SUCCESS";
export const GET_BILLTO_COUNTRIES_FAILURE = "GET_BILLTO_COUNTRIES_FAILURE";

export const GET_STATES_REQUEST = "GET_STATES_REQUEST";
export const GET_STATES_SUCCESS = "GET_STATES_SUCCESS";
export const GET_STATES_FAILURE = "GET_STATES_FAILURE";

export const GET_BILLTO_STATES_REQUEST = "GET_BILLTO_STATES_REQUEST";
export const GET_BILLTO_STATES_SUCCESS = "GET_BILLTO_STATES_SUCCESS";
export const GET_BILLTO_STATES_FAILURE = "GET_BILLTO_STATES_FAILURE";

export const GET_CITIES_REQUEST = "GET_CITIES_REQUEST";
export const GET_CITIES_SUCCESS = "GET_CITIES_SUCCESS";
export const GET_CITIES_FAILURE = "GET_CITIES_FAILURE";

export const GET_BILLTO_CITIES_REQUEST = "GET_BILLTO_CITIES_REQUEST";
export const GET_BILLTO_CITIES_SUCCESS = "GET_BILLTO_CITIES_SUCCESS";
export const GET_BILLTO_CITIES_FAILURE = "GET_BILLTO_CITIES_FAILURE";

export const GET_CUSTOMER_RELATIONS_REQUEST = "GET_CUSTOMER_RELATIONS_REQUEST";
export const GET_CUSTOMER_RELATIONS_SUCCESS = "GET_CUSTOMER_RELATIONS_SUCCESS";
export const GET_CUSTOMER_RELATIONS_FAILURE = "GET_CUSTOMER_RELATIONS_FAILURE";

export const GET_CUSTOMER_RELATIONS_SHIPTO_REQUEST =
  "GET_CUSTOMER_RELATIONS_SHIPTO_REQUEST";
export const GET_CUSTOMER_RELATIONS_SHIPTO_SUCCESS =
  "GET_CUSTOMER_RELATIONS_SHIPTO_SUCCESS";
export const GET_CUSTOMER_RELATIONS_SHIPTO_FAILURE =
  "GET_CUSTOMER_RELATIONS_SHIPTO_FAILURE";

export const GET_CUSTOMER_RELATION_INFORMATIONS_REQUEST =
  "GET_CUSTOMER_RELATION_INFORMATIONS_REQUEST";
export const GET_CUSTOMER_RELATION_INFORMATIONS_SUCCESS =
  "GET_CUSTOMER_RELATION_INFORMATIONS_SUCCESS";
export const GET_CUSTOMER_RELATION_INFORMATIONS_FAILURE =
  "GET_CUSTOMER_RELATION_INFORMATIONS_FAILURE";

export const RESET_CUSTOMER_RELATION_INFORMATIONS =
  "RESET_CUSTOMER_RELATION_INFORMATIONS";

export const GET_CUSTOMER_RELATION_INFORMATIONS_REQUEST_SHIP_TO =
  "GET_CUSTOMER_RELATION_INFORMATIONS_REQUEST_SHIP_TO";
export const GET_CUSTOMER_RELATION_INFORMATIONS_SUCCESS_SHIP_TO =
  "GET_CUSTOMER_RELATION_INFORMATIONS_SUCCESS_SHIP_TO";
export const GET_CUSTOMER_RELATION_INFORMATIONS_FAILURE_SHIP_TO =
  "GET_CUSTOMER_RELATION_INFORMATIONS_FAILURE_SHIP_TO";

export const GET_APPLICANT_CONTACT_INFORMATIONS_REQUEST =
  "GET_APPLICANT_CONTACT_INFORMATIONS_REQUEST";
export const GET_APPLICANT_CONTACT_INFORMATIONS_SUCCESS =
  "GET_APPLICANT_CONTACT_INFORMATIONS_SUCCESS";
export const GET_APPLICANT_CONTACT_INFORMATIONS_FAILURE =
  "GET_APPLICANT_CONTACT_INFORMATIONS_FAILURE";

export const APPLICANT_CONTACT_REQUEST = "APPLICANT_CONTACT_REQUEST";
export const APPLICANT_CONTACT_SUCCESS = "APPLICANT_CONTACT_SUCCESS";
export const APPLICANT_CONTACT_FAILURE = "APPLICANT_CONTACT_FAILURE";

export const EMAIL_CONTACT_EXIST_REQUEST = "EMAIL_CONTACT_EXIST_REQUEST";
export const EMAIL_CONTACT_EXIST_SUCCESS = "EMAIL_CONTACT_EXIST_SUCCESS";
export const EMAIL_CONTACT_EXIST_FAILURE = "EMAIL_CONTACT_EXIST_FAILURE";

export const UPDATE_BILL_TO_ACCCOUNT_NUMBERS = "UPDATE_BILL_TO_ACCCOUNT_NUMBERS";


