export const GET_USER_PSN_REQUEST = "GET_USER_PSN_REQUEST";
export const GET_USER_PSN_SUCCESS = "GET_USER_PSN_SUCCESS";
export const GET_USER_PSN_FAILURE = "GET_USER_PSN_FAILURE";

export const GET_MANUFACTURER_ACCOUNT_REQUEST =
  "GET_MANUFACTURER_ACCOUNT_REQUEST";
export const GET_MANUFACTURER_ACCOUNT_SUCCESS =
  "GET_MANUFACTURER_ACCOUNT_SUCCESS";
export const GET_MANUFACTURER_ACCOUNT_FAILURE =
  "GET_MANUFACTURER_ACCOUNT_FAILURE";

export const ADMIN_PAGE_FORM = "ADMIN_PAGE_FORM";
export const ADMIN_PAGE_CLEAR = "ADMIN_PAGE_CLEAR";

export const GET_HELPME_TOTAL_REQUEST_REQUEST =
  "GET_HELPME_TOTAL_REQUEST_REQUEST";
export const GET_HELPME_TOTAL_REQUEST_SUCCESS =
  "GET_HELPME_TOTAL_REQUEST_SUCCESS";
export const GET_HELPME_TOTAL_REQUEST_FAILURE =
  "GET_HELPME_TOTAL_REQUEST_FAILURE";
