import {
  LABEL_ORDER_CART_CHANGE_VALUE,
  LABEL_ORDER_CART_CONFIRM_SAVE_ORDER_REQUEST,
  LABEL_ORDER_CART_CONFIRM_SAVE_ORDER_ACCEPT,
  LABEL_ORDER_CART_CONFIRM_SAVE_ORDER_REJECT,
  LABEL_ORDER_CART_GET_CUSTOMER_BILL_TO_ADDRESSES_REQUEST,
  LABEL_ORDER_CART_GET_CUSTOMER_BILL_TO_ADDRESSES_SUCCESS,
  LABEL_ORDER_CART_GET_CUSTOMER_BILL_TO_ADDRESSES_FAILURE,
  LABEL_ORDER_CART_GET_CUSTOMER_INFO_REQUEST,
  LABEL_ORDER_CART_GET_CUSTOMER_INFO_SUCCESS,
  LABEL_ORDER_CART_GET_CUSTOMER_INFO_FAILURE,
  LABEL_ORDER_CART_GET_CUSTOMER_SHIP_TO_ADDRESSES_REQUEST,
  LABEL_ORDER_CART_GET_CUSTOMER_SHIP_TO_ADDRESSES_SUCCESS,
  LABEL_ORDER_CART_GET_CUSTOMER_SHIP_TO_ADDRESSES_FAILURE,
  LABEL_ORDER_CART_GET_LABEL_CENTERS_REQUEST,
  LABEL_ORDER_CART_GET_LABEL_CENTERS_SUCCESS,
  LABEL_ORDER_CART_GET_LABEL_CENTERS_FAILURE,
  LABEL_ORDER_CART_GET_LABEL_ORDER_CART_REQUEST,
  LABEL_ORDER_CART_GET_LABEL_ORDER_CART_SUCCESS,
  LABEL_ORDER_CART_GET_LABEL_ORDER_CART_FAILURE,
  LABEL_ORDER_CART_GET_LABEL_SUPPLIERS_REQUEST,
  LABEL_ORDER_CART_GET_LABEL_SUPPLIERS_SUCCESS,
  LABEL_ORDER_CART_GET_LABEL_SUPPLIERS_FAILURE,
  LABEL_ORDER_CART_GET_UNITS_OF_MEASUREMENT_REQUEST,
  LABEL_ORDER_CART_GET_UNITS_OF_MEASUREMENT_SUCCESS,
  LABEL_ORDER_CART_GET_UNITS_OF_MEASUREMENT_FAILURE,
  LABEL_ORDER_CART_INITIALIZE,
  LABEL_ORDER_CART_ITEM_CHANGE_VALUE,
  LABEL_ORDER_CART_REMOVE_ITEM_REQUEST,
  LABEL_ORDER_CART_REMOVE_ITEM_SUCCESS,
  LABEL_ORDER_CART_REMOVE_ITEM_FAILURE,
  LABEL_ORDER_CART_REMOVE_REMOVE_ITEM_MESSAGE,
  LABEL_ORDER_CART_REMOVE_SAVE_ORDER_MESSAGE,
  LABEL_ORDER_CART_UPDATE_REQUEST,
  LABEL_ORDER_CART_UPDATE_SUCCESS,
  LABEL_ORDER_CART_UPDATE_FAILURE,
  LABEL_ORDER_CART_ITEM_UPLOAD_ATTACHMENT_REQUEST,
  LABEL_ORDER_CART_ITEM_UPLOAD_ATTACHMENT_FAILURE,
  LABEL_ORDER_CART_ITEM_UPLOAD_ATTACHMENT_SUCCESS,
  FORM,
  LABEL_ORDER_CART_UPDATE_RESET
} from "./label-order-cart.type";

const originalAddresses = {
  billTo: null,
  shipTo: null
};

const initialState = {
  hasError: false,
  message: "",
  loading: false,
  data: null,
  unitsOfMeasurement: null,
  labelCenters: null,
  loadingLabelCenters: false,
  labelSuppliers: null,
  labelSupplierData: null,
  loadingSuppliers: false,
  messageTimeout: 5000,
  isUploading: false,
  isConfirmingSave: false,
  saveOrderSuccess: false,
  saveOrderFailed: false,
  removeItemFailed: false,
  removeItemSuccess: false,
  isRemovingItem: false,
  customerInfo: null,
  order: {
    customerPoNumber: null,
    billToCustomerLocationId: null,
    billToLineOne: null,
    billToLineTwo: null,
    billToLineThree: null,
    billToLineFour: null,
    billToLineFive: null,
    billToLineSix: null,
    billToCountry: null,
    billToPostalCode: null,
    shipToCustomerLocationId: null,
    shipToLineOne: null,
    shipToLineTwo: null,
    shipToLineThree: null,
    shipToLineFour: null,
    shipToLineFive: null,
    shipToLineSix: null,
    shipToCountry: null,
    shipToPostalCode: null,
    labelSupplierId: null,
    labelSupplierName: null,
    labelCenterId: null,
    labelCenterName: null,
    instructions: null,
    comments: null,
    brokerInformation: null
  },
  totalNetPrice: 0,
  isSuccessGetCartItems: false,
  form: {
    showErrors: false,
    validationErrors: {}
  }
};

const labelOrderCartReducer = (state = initialState, action) => {
  switch (action.type) {
    case LABEL_ORDER_CART_CHANGE_VALUE:
      const order = { ...state.order };
      order[action.payload.name] = action.payload.value;
      if (changeOrderCartHandlers.hasOwnProperty(action.payload.name))
        changeOrderCartHandlers[action.payload.name](
          state,
          order,
          action.payload.value
        );

      return {
        ...state,
        order
      };
    case LABEL_ORDER_CART_CONFIRM_SAVE_ORDER_REQUEST:
      return {
        ...state,
        isConfirmingSave: true
      };
    case LABEL_ORDER_CART_CONFIRM_SAVE_ORDER_ACCEPT:
    case LABEL_ORDER_CART_CONFIRM_SAVE_ORDER_REJECT:
      return {
        ...state,
        isConfirmingSave: false
      };
    case LABEL_ORDER_CART_GET_CUSTOMER_BILL_TO_ADDRESSES_REQUEST:
      return {
        ...state,
        loadingCustomerBillToAddresses: true
      };
    case LABEL_ORDER_CART_GET_CUSTOMER_BILL_TO_ADDRESSES_SUCCESS:
      originalAddresses.billTo = action.payload.data;
      return {
        ...state,
        loadingCustomerBillToAddresses: false,
        customerBillToAddresses: action.payload
          ? action.payload.data.map((item) => {
              return {
                key: item.id,
                text: item.value,
                value: item.id
              };
            })
          : null
      };
    case LABEL_ORDER_CART_GET_CUSTOMER_BILL_TO_ADDRESSES_FAILURE:
      return {
        ...state,
        loadingCustomerBillToAddresses: false
      };
    case LABEL_ORDER_CART_GET_CUSTOMER_INFO_REQUEST:
      return {
        ...state,
        loadingCustomerInfo: true
      };
    case LABEL_ORDER_CART_GET_CUSTOMER_INFO_SUCCESS:
      return {
        ...state,
        loadingCustomerInfo: false,
        customerInfo: action.payload
      };
    case LABEL_ORDER_CART_GET_CUSTOMER_INFO_FAILURE:
      return {
        ...state,
        loadingCustomerInfo: false
      };
    case LABEL_ORDER_CART_GET_CUSTOMER_SHIP_TO_ADDRESSES_REQUEST:
      return {
        ...state,
        loadingCustomerShipToAddresses: true
      };
    case LABEL_ORDER_CART_GET_CUSTOMER_SHIP_TO_ADDRESSES_SUCCESS:
      originalAddresses.shipTo = action.payload.data;
      return {
        ...state,
        loadingCustomerShipToAddresses: false,
        customerShipToAddresses: action.payload
          ? action.payload.data.map((item) => {
              return {
                key: item.id,
                text: item.value,
                value: item.id
              };
            })
          : null
      };
    case LABEL_ORDER_CART_GET_CUSTOMER_SHIP_TO_ADDRESSES_FAILURE:
      return {
        ...state,
        loadingCustomerShipToAddresses: false
      };
    case LABEL_ORDER_CART_GET_LABEL_CENTERS_REQUEST:
      return {
        ...state,
        loadingLabelCenters: true
      };
    case LABEL_ORDER_CART_GET_LABEL_CENTERS_SUCCESS:
      return {
        ...state,
        loadingLabelCenters: false,
        labelCenters: action.payload.data.map((item) => {
          return {
            key: item.id,
            text: item.name,
            value: item.id
          };
        })
      };
    case LABEL_ORDER_CART_GET_LABEL_CENTERS_FAILURE:
      return {
        ...state,
        loadingLabelCenters: false
      };
    case LABEL_ORDER_CART_GET_LABEL_ORDER_CART_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
        isSuccessGetCartItems: false
      };
    case LABEL_ORDER_CART_GET_LABEL_ORDER_CART_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        data: action.payload,
        form: {
          showErrors: false,
          validationErrors: {},
          customerPoNumber: action.payload.customerPoNumber,
          labelCenterName: action.payload.labelCenterName,
          labelCenterId: action.payload.labelCenterId,
          instructions: action.payload.instructions,
          labelSupplierName: action.payload.labelSupplierName,
          labelSupplierId: action.payload.labelSupplierId,
          billToContactName: action.payload.billToContactName,
          billToAddress: action.payload.billToLineOne,
          shipToContactName: action.payload.shipToContactName,
          shipToAddress: action.payload.shipToLineOne,
          manufacturerName: action.payload.manufacturerName,
          manufacturerAddress: action.payload.manufacturerAddress,
          manufacturerAccountNumber: action.payload.manufacturerAccountNumber,
          brokerInformation: action.payload.brokerInformation,
          comments: action.payload.comments,
          customerEmailAddress: action.payload.customerEmailAddress,
          customerPhoneNumber: action.payload.customerPhoneNumber,
          billToCompany: action.payload.billToCompany,
          billToAccountNo: action.payload.billToAccountNo,
          billToCountry: action.payload.billToCountry,
          shipToCountry: action.payload.shipToCountry
        },
        order: {
          customerPoNumber: action.payload.customerPoNumber,
          billToCustomerLocationId: action.payload.billToCustomerLocationId,
          billToLineOne: action.payload.billToLineOne,
          billToLineTwo: action.payload.billToLineTwo,
          billToLineThree: action.payload.billToLineThree,
          billToLineFour: action.payload.billToLineFour,
          billToLineFive: action.payload.billToLineFive,
          billToLineSix: action.payload.billToLineSix,
          billToCountry: action.payload.billToCountry,
          billToPostalCode: action.payload.billToPostalCode,
          shipToCustomerLocationId: action.payload.shipToCustomerLocationId,
          shipToLineOne: action.payload.shipToLineOne,
          shipToLineTwo: action.payload.shipToLineTwo,
          shipToLineThree: action.payload.shipToLineThree,
          shipToLineFour: action.payload.shipToLineFour,
          shipToLineFive: action.payload.shipToLineFive,
          shipToLineSix: action.payload.shipToLineSix,
          shipToCountry: action.payload.shipToCountry,
          shipToPostalCode: action.payload.shipToPostalCode,
          labelSupplierId: action.payload.labelSupplierId,
          labelSupplierName: action.payload.labelSupplierName,
          labelCenterId: action.payload.labelCenterId,
          labelCenterName: action.payload.labelCenterName,
          instructions: action.payload.instructions,
          comments: action.payload.comments
        },
        isSuccessGetCartItems: true
      };
    case LABEL_ORDER_CART_GET_LABEL_ORDER_CART_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        isSuccessGetCartItems: false
      };
    case LABEL_ORDER_CART_GET_LABEL_SUPPLIERS_REQUEST:
      return {
        ...state,
        loadingLabelSuppliers: true
      };
    case LABEL_ORDER_CART_GET_LABEL_SUPPLIERS_SUCCESS:
      return {
        ...state,
        loadingLabelSuppliers: false,
        labelSupplierFileNumbers: action.payload.data
          ? action.payload.data.map((item) => {
              return {
                key: item.id,
                text: item.fileNumber + "-" + item.name,
                value: item.id
              };
            })
          : null,
        labelSupplierData: action.payload.data
      };
    case LABEL_ORDER_CART_GET_LABEL_SUPPLIERS_FAILURE:
      return {
        ...state,
        loadingLabelSuppliers: false
      };
    case LABEL_ORDER_CART_GET_UNITS_OF_MEASUREMENT_REQUEST:
      return {
        ...state,
        loadingUnitsOfMeasurement: true
      };
    case LABEL_ORDER_CART_GET_UNITS_OF_MEASUREMENT_SUCCESS:
      return {
        ...state,
        loadingUnitsOfMeasurement: false,
        unitsOfMeasurement: action.payload.data.map((item) => {
          return {
            key: item.id,
            text: item.value,
            value: item.id
          };
        })
      };
    case LABEL_ORDER_CART_GET_UNITS_OF_MEASUREMENT_FAILURE:
      return {
        ...state,
        loadingUnitsOfMeasurement: false
      };
    case LABEL_ORDER_CART_INITIALIZE: {
      var origState = Object.assign({}, state, {
        form: {
          showErrors: false,
          validationErrors: {}
        }
      });

      return origState;
    }
    case LABEL_ORDER_CART_ITEM_CHANGE_VALUE:
      const { id, value, name } = action.payload;
      const newCartItems = state.data.cartItems.map((cartItem) => {
        if (cartItem.id !== id) return cartItem;

        const updatedState = { ...cartItem };
        updatedState[name] = value;
        if (changeOrderCartItemHandlers.hasOwnProperty(name))
          changeOrderCartItemHandlers[name](state, updatedState, value);

        return updatedState;
      });

      return {
        ...state,
        data: {
          ...state.data,
          cartItems: newCartItems
        }
      };
    case LABEL_ORDER_CART_REMOVE_ITEM_REQUEST:
      return {
        ...state,
        isRemovingItem: true
      };
    case LABEL_ORDER_CART_REMOVE_ITEM_SUCCESS:
      const updatedCartItems = state.data.cartItems
        .filter((cartItem) => {
          return cartItem.id !== action.payload.id;
        })
        .map((cartItem) => {
          return cartItem;
        });

      return {
        ...state,
        data: {
          ...state.data,
          cartItems: updatedCartItems
        },
        removeItemFailed: false,
        removeItemSuccess: true,
        isRemovingItem: false
      };
    case LABEL_ORDER_CART_REMOVE_ITEM_FAILURE:
      return {
        ...state,
        removeItemFailed: true,
        removeItemSuccess: false,
        isRemovingItem: false
      };
    case LABEL_ORDER_CART_REMOVE_REMOVE_ITEM_MESSAGE:
      return {
        ...state,
        removeItemSuccess: false,
        removeItemFailed: false
      };
    case LABEL_ORDER_CART_REMOVE_SAVE_ORDER_MESSAGE:
      return {
        ...state,
        saveOrderSuccess: false,
        saveOrderFailed: false
      };
    case LABEL_ORDER_CART_UPDATE_REQUEST:
      return {
        ...state,
        loadingUpdateCart: true,
        saveOrderSuccess: false,
        saveOrderFailed: false
      };
    case LABEL_ORDER_CART_UPDATE_SUCCESS:
      return {
        ...state,
        loadingUpdateCart: false,
        saveOrderSuccess: true,
        saveOrderFailed: false
      };
    case LABEL_ORDER_CART_UPDATE_FAILURE:
      return {
        ...state,
        loadingUpdateCart: false,
        saveOrderSuccess: false,
        saveOrderFailed: true
      };
    case LABEL_ORDER_CART_UPDATE_RESET:
      return {
        ...state,
        loadingUpdateCart: false,
        saveOrderSuccess: false,
        saveOrderFailed: false
      };
    case LABEL_ORDER_CART_ITEM_UPLOAD_ATTACHMENT_REQUEST:
      return {
        ...state,
        isUploading: true
      };
    case LABEL_ORDER_CART_ITEM_UPLOAD_ATTACHMENT_SUCCESS:
      return {
        ...state,
        isUploading: false
      };
    case LABEL_ORDER_CART_ITEM_UPLOAD_ATTACHMENT_FAILURE:
      return {
        ...state,
        isUploading: false
      };
    case FORM:
      // return {
      //   ...state,
      //   isSuccessful: false,
      //   hasError: false,
      //   isLoading: false,
      //   form: action.payload || initialState.form
      // };
      return Object.assign({}, state, {
        form: action.payload || initialState.form
      });
    default:
      return state;
  }
};

const changeOrderCartHandlers = {
  billToCustomerLocationId: (state, order, value) => {
    const filtered = originalAddresses.billTo.filter(
      (item) => item.id === value
    );

    if (!filtered || filtered.length === 0) return;
    const billToAddress = filtered[0];
    order.billToLineOne = billToAddress.lineOne;
    order.billToLineTwo = billToAddress.lineTwo;
    order.billToLineThree = billToAddress.lineThree;
    order.billToLineFour = billToAddress.lineFour;
    order.billToLineFive = billToAddress.lineFive;
    order.billToLineSix = billToAddress.lineSix;
    order.billToCountry = billToAddress.country;
    order.billToPostalCode = billToAddress.postalCode;
  },
  shipToCustomerLocationId: (state, order, value) => {
    const filtered = originalAddresses.shipTo.filter(
      (item) => item.id === value
    );

    if (!filtered || filtered.length === 0) return;
    const shipToAddress = filtered[0];
    order.shipToLineOne = shipToAddress.lineOne;
    order.shipToLineTwo = shipToAddress.lineTwo;
    order.shipToLineThree = shipToAddress.lineThree;
    order.shipToLineFour = shipToAddress.lineFour;
    order.shipToLineFive = shipToAddress.lineFive;
    order.shipToLineSix = shipToAddress.lineSix;
    order.shipToCountry = shipToAddress.country;
    order.shipToPostalCode = shipToAddress.postalCode;
  },
  labelSupplierId: (state, orderCart, value) => {
    const filtered = state.labelSuppliers.filter(
      (item) => item.value === value
    );
    orderCart.labelSupplierName =
      filtered && filtered.length > 0 ? filtered[0].text : null;
  },
  labelCenterId: (state, orderCart, value) => {
    const filtered = state.labelCenters.filter((item) => item.value === value);
    orderCart.labelCenterName =
      filtered && filtered.length > 0 ? filtered[0].text : null;
  }
};

const changeOrderCartItemHandlers = {
  unitOfMeasurementId: (state, orderCartItem, value) => {
    const filtered = state.unitsOfMeasurement.filter(
      (item) => item.value === value
    );
    orderCartItem.unitOfMeasurementName =
      filtered && filtered.length > 0 ? filtered[0].text : null;
  }
};

export default labelOrderCartReducer;
