import * as types from "../../constants/labelOrderDetails";
import api from "../../services/api";

const labelOrderResult = (obj, type) => {
  return {
    payload: obj,
    type: type
  };
};

export const getLabelOrder = (orderRequestNumber) => {
  return async (dispatch) => {
    dispatch(
      labelOrderResult(null, types.LABEL_ORDER_DETAILS_GET_LABEL_ORDER_REQUEST)
    );
    const result = await api.LabelOrder.getLabelOrder(orderRequestNumber);
    if (result) {
      dispatch(
        labelOrderResult(
          result,
          types.LABEL_ORDER_DETAILS_GET_LABEL_ORDER_SUCCESS
        )
      );
    } else {
      dispatch(
        labelOrderResult(
          null,
          types.LABEL_ORDER_DETAILS_GET_LABEL_ORDER_FAILURE
        )
      );
    }
  };
};

export const renderPdf = (data) => {
  return async (dispatch) => {
    dispatch(labelOrderResult(null, types.RENDER_PDF_REQUEST));
    const result = await api.LabelOrder.renderPdf(data);
    if (result) {
      dispatch(labelOrderResult(result, types.RENDER_PDF_SUCCESS));
    } else {
      dispatch(labelOrderResult(null, types.RENDER_PDF_FAILURE));
    }
  };
};

export const updateOracleNumber = (data) => {
  return async (dispatch) => {
    dispatch(labelOrderResult(null, types.UPDATE_ORACLE_NUMBER_REQUEST));
    const result = await api.Order.updateOracleNumber(data);
    if (result) {
      dispatch(labelOrderResult(result, types.UPDATE_ORACLE_NUMBER_SUCCESS));
    } else {
      dispatch(labelOrderResult(null, types.UPDATE_ORACLE_NUMBER_FAILURE));
    }
  };
};

export const updateOracleNumberReset = () => {
  return async (dispatch) => {
    dispatch(labelOrderResult(null, types.UPDATE_ORACLE_NUMBER_RESET));
  };
};
