import React, { Component, Fragment } from 'react';
import {
  getCartItems,
  selectLabel,
  setQuantity,
  removeCartItem,
  updateModel,
  updateCart,
  resetUpdateCart,
  deleteCart,
  resetDeleteCart,
  createOrder,
  resetCartItemsForm,
  cancelOrder,
  resetCancelOrder,
  updateForm,
  clearForm,
  getShippingOptions,
  setShippingOption,
  getCustomerDetails,
  updateShippingOptions,
  getLabelCenterCountryDetails,
  updateTabForm,
  getFormByTabIndex,
  updateLabelOrderCartType,
  getExchangeRate,
  updateReviewCart,
  getCustomerEcmApiStatus,
  uploadLabelOrderTempAttachment,
  getLabelOrderTempAttachment,
  deleteLabelOrderTempAttachment,
  getExistingPoNumber,
  deleteTab,
  updateBillToData,
  getCreditProfile,
  checkIfServiceContractUp,
  checkFileVolCcn,
} from '../../../../views/manufacturer/standard/label-cart/label-cart.action';
import { resetAddToCart } from '../../../../views/manufacturer/standard/labels/labels.action';
import {
  getCustomerInfo,
  getLabelCenters,
} from '../../../label-order-cart/cart/label-order-cart.action';
import { getUnitsOfMeasurement } from '../../../../views/label-order-cart/cart/label-order-cart.action';
import * as actions from '../../../../views/field-evaluation/account-settings/account-settings.action';

import * as commonActions from '../../../../views/user/common/common.action';

import { connect } from 'react-redux';
import {
  Container,
  Button,
  Table,
  Divider,
  Image,
  Form,
  Checkbox,
  Grid,
  Label,
  Message,
  Segment,
  Transition,
  Modal,
  Dropdown,
  Input,
  TextArea,
  Tab,
  Loader,
  Icon,
  Dimmer,
  Popup,
  Menu,
  Accordion,
} from 'semantic-ui-react';
import FooterHomeBlock from '../../../../components/footer/footer-home-block';
import {
  SubLabel,
  CustomHeaderLabel,
  CustomHeaderLabel2,
  CustomLabel2,
  CustomHeaderLabelRelaxed,
} from '../../../../components/labels/labels';
import {
  validate,
  getError,
  validateField,
  createValidation,
} from '../../../../validator/validator';
import { required, email } from '../../../../validator/error-message';
import TermsAndConditions from './terms-and-conditions';
import _ from 'lodash';

import { toast } from 'react-semantic-toasts';

import { Link } from 'react-router-dom';
import { Common } from '../../../../common';
import Role from '../../../../enums/role';

import { scanFile } from '../../../order/combination/existing-combination-labels.action';

var rules = [];

class LabelCart extends Component {
  state = {
    forReview: false,
    open: false,
    openSameFileNumbers: false,
    isCheckAgree: false,
    shippingOptionName: '',
    labelCenterName: '',
    showErrors: false,
    isLoading: '',
    // isSuccessCancelCart: false
    isSucessCustomer: false,
    isCustomerRelationEmpty: true,
    isCustomerRelationShipToEmpty: true,
    isCompanyApplicantEnable: true,
    isCompanyApplicantAddEnable: true,
    isCompanyEnable: true,
    isAddressEnable: true,
    isCompanyShipToEnable: true,
    isAddressShipToEnable: true,
    isLoadApplicantContactInfo: false,
    isLoadCustomerRelation: false,
    isLoadCustomerRelationShipTo: false,
    billToCountryCode: null,
    shipToCountryCode: null,
    applicantCountryCode: null,
    isSuccessUpdateShippingOption: false,
    countryName: null,
    isContryNameSuccess: false,
    activeIndex: 0,
    selectedIndex: 0,
    isLoadBillTo: false,
    isLoadShipTo: false,
    isLoadCountryOptions: false,
    isDifferentBillTo: false,
    openCustomerEcmStatusModal: false,
    OldAppAccntNo: '',
    psnCountry: '',
    showManageDocs: false,
    isReadOnlyBillToEmail: false,
    isReadOnlyShipToEmail: false,
    isLoadBillToAccntEmpty: false,
    openBillToModal: false,
    accordIndexes: [],
    hasSameBillToResponse: false,
  };

  customerId = localStorage.getItem('customerId');

  handleChangeShippingOption = properties => {
    var shippingOptionName = properties.options.filter(
      o => o.value === properties.value
    )[0].text;

    const { forms } = this.props;
    const { activeIndex } = this.state;

    let { data } = this.props.labelCart;

    const form = forms[activeIndex];

    this.setState({ shippingOptionName: shippingOptionName });
    let newStateShipDetails = {};
    let newStateOtherShipDetails = {};

    let newState = validateField(
      rules,
      form,
      properties.name,
      properties.value
    );

    //this.props.updateTabForm(newState);
    this.props.updateTabForm(activeIndex, newState, forms);

    newState = validateField(
      rules,
      form,
      'shippingOptionName',
      shippingOptionName
    );

    //this.props.updateTabForm(newState);
    this.props.updateTabForm(activeIndex, newState, forms);

    if (properties.value === 6) {
      newState = validateField(
        rules,
        form,
        'shippingDetails',
        data.shippingDetails && data.shippingDetails !== 'N/A'
          ? data.shippingDetails
          : form.shippingDetails === 'N/A'
          ? null
          : form.shippingDetails
      );
      newStateShipDetails = validateField(
        rules,
        form,
        'hideShippingDetails',
        false
      );
      if (
        this.state.countryName === 'Taiwan' ||
        this.state.countryName === 'China'
      ) {
        newStateOtherShipDetails = validateField(
          rules,
          form,
          'hidecourierAccountNumber',
          false
        );
      } else {
        newStateOtherShipDetails = validateField(
          rules,
          form,
          'hidecourierAccountNumber',
          true
        );
      }
    } else {
      if (
        !(
          form.labelCenterName?.trim().toLowerCase() === 'north america' ||
          form.labelCenterName?.trim().toLowerCase() === 'latin america'
        )
      ) {
        newState = validateField(rules, form, 'shippingDetails', 'N/A');
        newStateShipDetails = validateField(
          rules,
          form,
          'hideShippingDetails',
          true
        );
      }

      newStateOtherShipDetails = validateField(
        rules,
        form,
        'hidecourierAccountNumber',
        false
      );
    }

    this.props.updateTabForm(activeIndex, newStateShipDetails, forms);
    this.props.updateTabForm(activeIndex, newStateOtherShipDetails, forms);
    //this.props.updateTabForm(newStateShipDetails);
    //this.props.updateTabForm(newStateOtherShipDetails);
  };

  handleHeaderLabel = () => {
    const { labelCart } = this.props;
    if (labelCart.data && labelCart.data.length > 0) {
      if (labelCart.data[0].labelOrderType === 1) {
        this.props.getActivePage('Standard Label Order');
      } else {
        this.props.getActivePage('Combination Label Order');
      }
    } else if (labelCart) {
      if (labelCart.labelOrderType === 1) {
        this.props.getActivePage('Standard Label Order');
      } else {
        this.props.getActivePage('Combination Label Order');
      }
    } else {
      this.props.getActivePage('Order Cart');
    }
  };
  handleChangeLabelCenter = properties => {
    const { activeIndex } = this.state;
    const { forms } = this.props;

    var labelCenterName = forms[activeIndex].labelCenterName;

    if (properties.name === 'labelCenter') {
      this.props.updateShippingOptions(labelCenterName);
      this.setState({ labelCenterName: labelCenterName });
    }

    var country = this.props.labelCenters.filter(
      x => x.value === properties.value
    );

    if (country && country.length > 0) {
      this.setState({ countryName: labelCenterName });
    }

    if (properties.name === 'billToCountry') {
      this.setState({ billToCountryCode: properties.value });
      forms[activeIndex].billToCountryCode = properties.value;
    }

    if (properties.name === 'shipToCountry') {
      this.setState({ shipToCountryCode: properties.value });
      forms[activeIndex].shipToCountryCode = properties.value;
      forms[activeIndex].shipToCountry = properties.value;

      this.props.getLabelCenterCountryDetails(properties.value).then(() => {
        if (this.props.labelCenterCountryDetails) {
          var labelCenter = this.props.labelCenterCountryDetails.labelCenter;
          if (labelCenter === 'EUROPE') {
            labelCenter = 'Europe';
          }

          this.setState({ countryName: labelCenter });
          var result = this.props.labelCenters.find(function (item) {
            return item.text === labelCenter;
          });

          if (result) {
            forms[activeIndex].labelCenter = result.value;
            this.props.updateShippingOptions(result.text);
            this.setState({ labelCenterName: result.text });
            forms[activeIndex].labelCenterName = result.text;

            let newStateShipDetails = {};
            if (
              result.text === 'Japan' ||
              result.text === 'South Korea' ||
              result.text === 'Europe'
            ) {
              newStateShipDetails = validateField(
                rules,
                form,
                'hideShippingDetails',
                true
              );
            } else {
              newStateShipDetails = validateField(
                rules,
                form,
                'hideShippingDetails',
                false
              );
            }
            this.props.updateForm(newStateShipDetails);
          } else {
            forms[activeIndex].labelCenter = null;
            this.props.updateShippingOptions('');
          }
        }
      });
    }

    //let { form } = this.props.labelCart.forms[activeIndex];
    let form = getFormByTabIndex(activeIndex, forms);

    if (properties.name === 'applicantCountry') {
      this.setState({ applicantCountryCode: properties.value });
    }

    if (properties.name === 'billToContactName') {
      const billToDetails = this.props.customerRelationInformationsData.find(
        s => s.contactName === properties.value
      );
      if (billToDetails.emailAdd) {
        this.setState({ isReadOnlyBillToEmail: true });
        form.isBillToEmailDisable = true;
      } else {
        this.setState({ isReadOnlyBillToEmail: false });
        form.isBillToEmailDisable = false;
      }
      form.billToContactEmail = billToDetails.emailAdd;
    }

    if (properties.name === 'shipToContactName') {
      const shipToDetails =
        this.props.customerRelationInformationsShiptToData.find(
          s => s.contactName === properties.value
        );

      if (shipToDetails.emailAdd) {
        this.setState({ isReadOnlyShipToEmail: true });
      } else {
        this.setState({ isReadOnlyShipToEmail: false });
      }
      form.customerEmailAddress = shipToDetails.emailAdd;
      form.customerPhoneNumber = shipToDetails.phoneNumber;
    }

    validateField(rules, form, properties.name, properties.value);
    let newState = validateField(
      rules,
      form,
      properties.name,
      properties.value
    );
    this.props.updateTabForm(activeIndex, newState, forms);

    newState = validateField(rules, form, 'labelCenterName', labelCenterName);

    this.props.updateTabForm(activeIndex, newState, forms);

    if (form.shippingOption) {
      let newStateOtherShipDetails = {};
      newStateOtherShipDetails = validateField(
        rules,
        form,
        'hidecourierAccountNumber',
        false
      );
      this.props.updateTabForm(activeIndex, newStateOtherShipDetails, forms);

      let newStateShipDetails = {};
      if (
        country.length > 0 &&
        (country[0].text === 'Japan' ||
          country[0].text === 'South Korea' ||
          country[0].text === 'Europe' ||
          country[0].text === 'China' ||
          country[0].text === 'Taiwan')
      ) {
        newStateShipDetails = validateField(
          rules,
          form,
          'hideShippingDetails',
          true
        );
      } else {
        newStateShipDetails = validateField(
          rules,
          form,
          'hideShippingDetails',
          false
        );
      }
      this.props.updateTabForm(activeIndex, newStateShipDetails, forms);
    }
  };

  handleChange = e => {
    let { forms } = this.props;
    let { activeIndex } = this.state;

    const form = getFormByTabIndex(activeIndex, forms);

    const newState = validateField(rules, form, e.target.name, e.target.value);
    this.props.updateTabForm(activeIndex, newState, forms);
  };

  getErrorFor(field) {
    const { data } = this.props;
    let errors = getError(
      field,
      data ? data[this.state.activeIndex] : '',
      data ? data[this.state.activeIndex] : ''
    );
    return errors;
  }

  handleEditCart = () => {
    this.setState({ forReview: false });
    this.componentDidMount();
  };

  scrollStep() {
    if (window.pageYOffset === 0) {
      clearInterval(this.state.intervalId);
    }
    window.scroll(0, window.pageYOffset - this.props.scrollStepInPx);
  }

  scrollToTop() {
    let intervalId = setInterval(this.scrollStep.bind(this), 1000);
    this.setState({ intervalId: intervalId });
  }
  handleDuplicatePoBillTo = () => {
    const { forms } = this.props;
    var returnVal = false;
    var newArray = [];
    forms.forEach(item => {
      newArray.push([item.customerPoNumber, item.billToAccountNo]);
    });

    newArray.forEach(item => {
      var dupItems = newArray.filter(
        obj => obj[0] === item[0] && obj[1] !== item[1]
      );
      if (dupItems.length > 0) {
        returnVal = true;
      }
    });
    return returnVal;
  };

  handleReviewCart = () => {
    const { forms } = this.props;
    const { activeIndex } = this.state;
    // var cartsData = labelCart.data;

    var form = forms[activeIndex];
    var result = this.props.labelCenters.find(function (item) {
      return item.value === form.labelCenter;
    });
    var poNumbers = '';
    if (forms.length > 1) {
      var multiPo = '';
      forms.forEach(form => {
        multiPo += form.customerPoNumber + '|';
      });
      poNumbers = multiPo.slice(0, -1);

      if (this.handleDuplicatePoBillTo()) {
        setTimeout(() => {
          toast({
            title: '',
            description: (
              <p>{Common.getTranslations()['LexPurchaseOrderNumberAlert']}</p>
            ),
            time: 10000,
            size: 'small',
            icon: '',
            color: 'red',
          });
        }, 100);
        return;
      }
    } else {
      poNumbers = form.customerPoNumber;
    }

    form.validationErrors = createValidation(form, rules);
    if (
      (result && result.text === 'Japan') ||
      (result && result.text === 'South Korea') ||
      (result && result.text === 'Europe') ||
      (result && result.text === 'China') ||
      (result && result.text === 'Taiwan') ||
      form.labelOrderType === 2
    ) {
      form.validationErrors = _.omit(form.validationErrors, ['shippingOption']);
    }
    //console.log(form.labelCenterName);
    this.props.getExistingPoNumber(poNumbers).then(() => {
      if (!this.props.labelCart.newPoNumber) {
        setTimeout(() => {
          toast({
            title: '',
            description: (
              <p>{Common.getTranslations()['LexPurchaseOrderNumberAlert']}</p>
            ),
            time: 10000,
            size: 'small',
            icon: '',
            color: 'red',
          });
        }, 100);
        return;
      } else {
        //check labels if exist on reference tables

        let cartItems = [];

        for (var cart of this.props.data[activeIndex].carts) {
          for (var cartItem of cart.cartItems) {
            cartItems.push({
              ccn: cartItem.ccn,
              fileNumber: cart.fileNumber,
              volume: cart.volume,
            });
          }
        }

        this.props.checkFileVolCcn({ list: cartItems }).then(result => {
          if (!result.isSuccessful) {
            setTimeout(() => {
              toast({
                title: '',
                description: (
                  // <p>{Common.getTranslations()["LexPurchaseOrderNumberAlert"]}</p>
                  <p>{Common.getTranslations()['LexFileVolCCNInactive']}</p>
                ),
                time: 10000,
                size: 'small',
                icon: '',
                color: 'red',
              });
            }, 100);
            return;
          } else {
            this.scrollToTop();
            this.handleSaveChanges();
          }
        });
      }
    });
  };

  //
  // handleCheckout = () => {
  //   this.props.createOrder({
  //     status: 2,
  //     isCheckedOut: true,
  //     customerPoNumber: this.props.customerPoNumber,
  //     comments: this.props.comments
  //   });
  // };;

  handleSaveChanges = () => {
    const { forms, hasServiceContractData, hasSameBillTo } = this.props;
    const { activeIndex } = this.state;

    var form = forms[activeIndex];
    if (Object.IsEmpty(form.validationErrors)) {
      if (activeIndex === 0) {
        if (forms.length === 1) this.handleOk();
        else {
          if (!this.state.forReview) {
            if (!hasServiceContractData) {
              this.setState({ openSameFileNumbers: true });
            } else {
              this.handleCopyFormDetails(
                forms[activeIndex + 1],
                forms,
                hasSameBillTo,
                hasServiceContractData,
                false
              );

              if (!forms[activeIndex + 1].hasCompleteServiceContractData) {
                this.props.getCustomerRelationInformations(
                  forms[activeIndex + 1].billToAccountNo
                );
              }
              this.setState({ activeIndex: activeIndex + 1 });
            }
          } else {
            this.setState({ activeIndex: activeIndex + 1 });
          }
        }
      } else {
        if (activeIndex === forms.length - 1) {
          this.handleOk();
          this.setState({ activeIndex: 0 });
        } else {
          this.handleCopyDetails();
          this.setState({ activeIndex: activeIndex + 1 });
        }
      }
    } else {
      this.setState({ showErrors: true });
    }
  };

  handleCopyDetails = () => {
    const { forms } = this.props;
    const { activeIndex } = this.state;
    var form = forms[activeIndex + 1];

    form.labelCenterName = forms[0].labelCenterName;
    form.labelCenterId = forms[0].labelCenterId;
    form.instructions = forms[0].instructions;
    form.labelSupplierName = forms[0].labelSupplierName;
    form.labelSupplierId = forms[0].labelSupplierId;
    if (forms[0].billToAccountNo === forms[activeIndex + 1].billToAccountNo) {
      form.billToAccountNo = forms[0].billToAccountNo;
      form.billToCompany = forms[0].billToCompany;
      form.billToContactName = forms[0].billToContactName;
      form.billToAddress = forms[0].billToAddress;
      form.billToCountry = forms[0].billToCountry;
      form.billToCountryCode = forms[0].billToCountryCode;
      form.billToContactEmail = forms[0].billToContactEmail;
      form.customerPoNumber = forms[0].customerPoNumber;
    }
    form.shipToContactName = forms[0].shipToContactName;
    form.shipToAddress = forms[0].shipToAddress;
    form.shipToCountry = forms[0].shipToCountry;
    form.shipToCountryCode = forms[0].shipToCountryCode;
    form.manufacturerName = forms[0].manufacturerName;
    form.manufacturerAddress = forms[0].manufacturerAddress;
    form.manufacturerPartySiteNumber = forms[0].manufacturerPartySiteNumber;
    form.manufacturerAccountNumber = forms[0].manufacturerAccountNumber;

    //form.brokerInformation = forms[0].brokerInformation;
    form.shippingOption = forms[0].shippingOption;
    form.shippingOptionName = forms[0].shippingOptionName;
    form.shippingDetails = forms[0].shippingDetails;
    form.courierAccountNumber = forms[0].courierAccountNumber;
    form.hideShippingDetails = forms[0].hideShippingDetails;
    form.hidecourierAccountNumber = forms[0].hidecourierAccountNumber;
    form.customerEmailAddress = forms[0].customerEmailAddress;
    form.customerPhoneNumber = forms[0].customerPhoneNumber;
    //form.comments = forms[0].comments;
    form.labelCenter = forms[0].labelCenter;
    form.applicantAccountNo = forms[0].applicantAccountNo;
    form.applicantAccountNoSelection = forms[0].applicantAccountNo;
    form.applicantAddress = forms[0].applicantAddress;
    form.applicantCompany = forms[0].applicantCompany;
    form.applicantCountry = forms[0].applicantCountry;
    form.applicantCountryCode = forms[0].applicantCountryCode;
    form.applicantContactName = forms[0].applicantContactName;
  };

  handleRemoveSelections = () => {
    const { activeIndex } = this.state;
    var cartData = {
      cartItemIds: this.props.selectedLabels.map(s => {
        return s.id;
      }),
    };

    if (cartData.cartItemIds.length > 0) {
      this.props.deleteCart(cartData);
      this.props.removeCartItem(cartData.cartItemIds, activeIndex);
    }
  };

  handleRemoveLabel = labelId => {
    const { activeIndex } = this.state;
    var labelIds = [];
    labelIds.push(labelId);

    var cartData = {
      cartItemIds: labelIds,
    };

    if (cartData.cartItemIds.length > 0) {
      this.props.deleteCart(cartData);
      this.props.removeCartItem(cartData.cartItemIds, activeIndex);
    }
  };

  handleConfirm = () => {
    const { attachments } = this.props.labelCart;
    this.setState({ open: false });
    this.setState({ forReview: false });
    var orderCardId = this.props.data[0].labelOrderCartId;
    var data = { labelOrderCartId: orderCardId };

    this.props.cancelOrder(data);

    if (attachments && attachments.length > 0) {
      attachments.forEach(column => {
        this.handleDeleteTempAttachment(column.id);
      });
    }
  };

  handleCopyFormDetails = (
    form,
    forms,
    hasSameBillTo,
    hasServiceContractData,
    sameFileNumberResponse
  ) => {
    form.customerPoNumber = hasServiceContractData
      ? hasSameBillTo
        ? forms[0].customerPoNumber
        : form.customerPoNumber
      : sameFileNumberResponse
      ? forms[0].customerPoNumber
      : form.customerPoNumber;
    form.labelCenterName = forms[0].labelCenterName;
    form.labelCenterId = forms[0].labelCenterId;
    form.instructions = forms[0].instructions;
    form.labelSupplierName = forms[0].labelSupplierName;
    form.labelSupplierId = forms[0].labelSupplierId;
    form.billToAccountNo = hasServiceContractData
      ? hasSameBillTo
        ? forms[0].billToAccountNo
        : form.billToAccountNo
      : sameFileNumberResponse
      ? forms[0].billToAccountNo
      : form.billToAccountNo;
    form.billToCompany = hasServiceContractData
      ? hasSameBillTo
        ? forms[0].billToCompany
        : form.billToCompany
      : sameFileNumberResponse
      ? forms[0].billToCompany
      : form.billToCompany;
    form.billToContactName = hasServiceContractData
      ? hasSameBillTo
        ? forms[0].billToContactName
        : form.billToContactName
      : sameFileNumberResponse
      ? forms[0].billToContactName
      : form.billToContactName;
    form.billToAddress = hasServiceContractData
      ? hasSameBillTo
        ? forms[0].billToAddress
        : form.billToAddress
      : sameFileNumberResponse
      ? forms[0].billToAddress
      : form.billToAddress;
    form.billToCountry = hasServiceContractData
      ? hasSameBillTo
        ? forms[0].billToCountry
        : form.billToCountry
      : sameFileNumberResponse
      ? forms[0].billToCountry
      : form.billToCountry;
    form.billToCountryCode = hasServiceContractData
      ? hasSameBillTo
        ? forms[0].billToCountryCode
        : form.billToCountryCode
      : sameFileNumberResponse
      ? forms[0].billToCountryCode
      : form.billToCountryCode;
    form.billToContactEmail = hasServiceContractData
      ? hasSameBillTo
        ? forms[0].billToContactEmail
        : form.billToContactEmail
      : sameFileNumberResponse
      ? forms[0].billToContactEmail
      : form.billToContactEmail;
    form.isBillToDisable = true;
    form.isBillToDetailsDisable = hasServiceContractData
      ? hasServiceContractData
      : sameFileNumberResponse;
    form.isCommentsDisable = false;
    form.isBillToEmailDisable = true;
    form.isOtherDetailsDisable = hasServiceContractData
      ? hasSameBillTo
      : sameFileNumberResponse;
    form.shipToContactName = forms[0].shipToContactName;
    form.shipToAddress = forms[0].shipToAddress;
    form.shipToCountry = forms[0].shipToCountry;
    form.shipToCountryCode = forms[0].shipToCountryCode;
    form.manufacturerName = forms[0].manufacturerName;
    form.manufacturerAddress = forms[0].manufacturerAddress;
    form.manufacturerPartySiteNumber = forms[0].manufacturerPartySiteNumber;
    form.manufacturerAccountNumber = forms[0].manufacturerAccountNumber;
    form.brokerInformation = hasServiceContractData
      ? hasSameBillTo
        ? forms[0].brokerInformation
        : form.brokerInformation
      : sameFileNumberResponse
      ? forms[0].brokerInformation
      : form.brokerInformation;
    form.shippingOption = forms[0].shippingOption;
    form.shippingOptionName = forms[0].shippingOptionName;
    form.shippingDetails = forms[0].shippingDetails;
    form.courierAccountNumber = forms[0].courierAccountNumber;
    form.hideShippingDetails = forms[0].hideShippingDetails;
    form.hidecourierAccountNumber = forms[0].hidecourierAccountNumber;
    form.customerEmailAddress = forms[0].customerEmailAddress;
    form.customerPhoneNumber = forms[0].customerPhoneNumber;
    form.comments = hasServiceContractData
      ? hasSameBillTo
        ? forms[0].comments
        : form.comments
      : sameFileNumberResponse
      ? forms[0].comments
      : form.comments;
    form.labelCenter = forms[0].labelCenter;
    form.applicantAccountNo = forms[0].applicantAccountNo;
    form.applicantAccountNoSelection = forms[0].applicantAccountNo;
    form.applicantAddress = forms[0].applicantAddress;
    form.applicantCompany = forms[0].applicantCompany;
    form.applicantCountry = forms[0].applicantCountry;
    form.applicantCountryCode = forms[0].applicantCountryCode;
    form.applicantContactName = forms[0].applicantContactName;
    form.conversionRate = forms[0].conversionRate;
    form.currency = forms[0].currency;
    form.handlingFee = forms[0].handlingFee;

    return form;
  };

  handleOk = async () => {
    const { forms, data, hasSameBillTo, hasServiceContractData } = this.props;
    const { activeIndex } = this.state;

    if (activeIndex === 0) {
      const cartList = [];
      let form = {};
      for (var cnt = 0; cnt < forms.length; cnt++) {
        form = forms[cnt];
        if (cnt > 0) {
          this.handleCopyFormDetails(
            form,
            forms,
            hasSameBillTo,
            hasServiceContractData,
            true
          );
          this.setState({ hasSameBillToResponse: true });
          this.props.getExchangeRate(forms[0].billToCountryCode, cnt);
        }
      }
      if (forms.length > 1) {
        this.setState({ activeIndex: this.state.activeIndex + 1 });
        this.scrollToTop();
      } else {
        const exchangeRateResult = await this.props.getExchangeRate(
          data[0].billToCountryCode,
          0
        );

        if (exchangeRateResult) {
          cartList.push({
            id: data[0].labelOrderCartId,
            ...form,
            shippingDetails: form.shippingDetails,
            courierAccountNumber: form.courierAccountNumber,
            shippingOptionId: form.shippingOption,
            shippingOptionName: this.state.shippingOptionName,
            labelCenterId: form.labelCenter,
            labelCenterName: this.state.labelCenterName,
            conversionRate: exchangeRateResult.conversionRate,
            cartItems: form.carts[0].cartItems,
            grandTotal: forms[0].grantTotal,
            totalPrintingFee: forms[0].totalPrintingFee,
            totalHandlingFee: forms[0].totalHandlingFee,
          });

          const cartsModel = { orderCarts: cartList };

          const result = await this.props.updateCart(cartsModel);

          if (result) {
            this.setState({ forReview: true, showErrors: false });
            this.props.getCartItems();
          } else {
            this.setState({ forReview: false, showErrors: true });
            setTimeout(() => {
              toast({
                title: '',
                description: <p>{'Something went wrong in review cart api'}</p>,
                time: 10000,
                size: 'small',
                icon: '',
                color: 'red',
              });
            }, 100);
          }
        }
      }
    } else {
      var withoutDuplicates = [...new Set(forms)];
      const cartList = [];
      for (var index = 0; index < withoutDuplicates.length; index++) {
        let form = forms[index] || {};

        const exchangeRateResult = await this.props.getExchangeRate(
          form.billToCountryCode,
          index
        );

        if (exchangeRateResult) {
          form = forms[exchangeRateResult.index] || {};
          form.isBillToDisable = true;
          if (form.carts[0]?.cartItems) {
            cartList.push({
              id: data[0].labelOrderCartId,
              ...form,
              shippingDetails: form.shippingDetails,
              courierAccountNumber: form.courierAccountNumber,
              shippingOptionId: form.shippingOption,
              shippingOptionName: this.state.shippingOptionName,
              labelCenterId: form.labelCenter,
              labelCenterName: this.state.labelCenterName,
              conversionRate: exchangeRateResult.conversionRate,
              cartItems: form.carts[0]?.cartItems,
              fileNumber: forms[exchangeRateResult.index].fileNumber,
              grandTotal: forms[exchangeRateResult.index].grantTotal,
              totalPrintingFee:
                forms[exchangeRateResult.index].totalPrintingFee,
              totalHandlingFee:
                forms[exchangeRateResult.index].totalHandlingFee,
            });
          }
        }
      }

      const cartsModel = { orderCarts: cartList };

      if (cartsModel) {
        const result = await this.props.updateCart(cartsModel);

        if (result) {
          this.setState({ forReview: true, showErrors: false });
          this.props.getCartItems();
        } else {
          this.setState({ forReview: false, showErrors: true });
          setTimeout(() => {
            toast({
              title: '',
              description: <p>{'Something went wrong in review cart api'}</p>,
              time: 10000,
              size: 'small',
              icon: '',
              color: 'red',
            });
          }, 100);
        }
      }
    }
    this.setState({ openSameFileNumbers: false });
  };

  handleCancelSameFileNumbers = () => {
    const { forms } = this.props;
    this.setState({ openSameFileNumbers: false });
    this.setState({ activeIndex: this.state.activeIndex + 1 });
    this.setState({ hasSameBillToResponse: false });

    for (var cnt = 0; cnt < forms.length; cnt++) {
      var form = forms[cnt];
      if (cnt > 0) {
        form.labelCenterName = forms[0].labelCenterName;
        form.labelCenterId = forms[0].labelCenterId;
        form.instructions = forms[0].instructions;
        form.labelSupplierName = forms[0].labelSupplierName;
        form.labelSupplierId = forms[0].labelSupplierId;
        form.billToAccountNo = '';
        form.billToCompany = '';
        form.billToContactName = '';
        form.billToAddress = '';
        form.billToCountry = '';
        form.billToCountryCode = '';
        form.billToContactEmail = '';
        form.isBillToDisable = false;
        form.isBillToDetailsDisable = false;
        form.isOtherDetailsDisable = false;
        form.shipToContactName = '';
        form.shipToContactName = forms[0].shipToContactName;
        form.shipToAddress = forms[0].shipToAddress;
        form.shipToCountry = forms[0].shipToCountry;
        form.shipToCountryCode = forms[0].shipToCountryCode;
        form.manufacturerName = forms[0].manufacturerName;
        form.manufacturerAddress = forms[0].manufacturerAddress;
        form.manufacturerPartySiteNumber = forms[0].manufacturerPartySiteNumber;
        form.manufacturerAccountNumber = forms[0].manufacturerAccountNumber;
        form.brokerInformation = '';
        form.shippingOption = forms[0].shippingOption;
        form.shippingOptionName = forms[0].shippingOptionName;
        form.shippingDetails = forms[0].shippingDetails;
        form.courierAccountNumber = forms[0].courierAccountNumber;
        form.hideShippingDetails = forms[0].hideShippingDetails;
        form.hidecourierAccountNumber = forms[0].hidecourierAccountNumber;
        form.customerEmailAddress = forms[0].customerEmailAddress;
        form.customerPhoneNumber = forms[0].customerPhoneNumber;
        form.comments = '';
        form.labelCenter = forms[0].labelCenter;
        form.applicantAccountNo = forms[0].applicantAccountNo;
        form.applicantAccountNoSelection = forms[0].applicantAccountNo;
        form.applicantAddress = forms[0].applicantAddress;
        form.applicantCompany = forms[0].applicantCompany;
        form.applicantCountry = forms[0].applicantCountry;
        form.applicantCountryCode = forms[0].applicantCountryCode;
        form.applicantContactName = forms[0].applicantContactName;
        form.customerPoNumber = '';
      }
    }

    this.scrollToTop();
  };

  handleCancel = () => {
    this.setState({ open: false });
  };

  handleCustomerEcmStatusApiModal = () => {
    this.setState({ openCustomerEcmStatusModal: false });
  };

  handleCheckBillTo = () => {
    const { forms } = this.props;
    var billToAccountNo = forms[0].billToAccountNo;
    this.setState({ isDifferentBillTo: false });

    forms.forEach(item => {
      if (billToAccountNo !== item.billToAccountNo)
        this.setState({ isDifferentBillTo: true });
    });
  };

  handleOpenCheckoutModal = () => {
    this.props.getCustomerEcmApiStatus().then(s => {
      if (s === false) {
        this.setState({ openCustomerEcmStatusModal: true });
      } else {
        this.handleCheckBillTo();
        this.setState({ openCheckOut: true });
      }
    });
  };

  handleConfirmCheckOut = () => {
    this.setState({ openCheckOut: false });
    this.setState({ openTac: true });
  };

  handleCancelCheckOut = () => {
    this.setState({ openCheckOut: false });
  };

  handleDeleteCart = () => {
    this.setState({ open: true });
  };

  handleManageDocs = value => {
    var userPsn = this.props.match.params.psn
      ? this.props.match.params.psn
      : Common.getPartySiteNumber();

    this.setState({ showManageDocs: value });

    this.props.getLabelOrderTempAttachment(userPsn, 1);
  };

  handleDeleteTempAttachment = attachmentId => {
    const { deleteLabelOrderTempAttachment, getLabelOrderTempAttachment } =
      this.props;
    var userPsn = this.props.match.params.psn
      ? this.props.match.params.psn
      : Common.getPartySiteNumber();

    if (attachmentId) {
      deleteLabelOrderTempAttachment(attachmentId).then(s => {
        if (s.isSuccessful) {
          getLabelOrderTempAttachment(userPsn, 1);
        }
      });
    }
  };

  handleUploadFile = event => {
    const { uploadLabelOrderTempAttachment, getLabelOrderTempAttachment } =
      this.props;
    var userPsn = this.props.match.params.psn
      ? this.props.match.params.psn
      : Common.getPartySiteNumber();
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const fileSize = file.size / 1024 / 1024;

      if (fileSize < 2) {
        var extention = file.name.split('.').pop();

        if (
          extention === 'docx' ||
          extention === 'doc' ||
          extention === 'pdf' ||
          extention === 'jpg' ||
          extention === 'txt'
        ) {
          var fileReader = new FileReader();
          var that = this;

          //this.updateForm("fileName", file.name);

          fileReader.onload = function (fileLoadedEvent) {
            var srcData = fileLoadedEvent.target.result;

            that.props
              .scanFile({
                fileDetails: srcData,
              })
              .then(result => {
                if (result.isSuccessful) {
                  var data = {
                    Psn: userPsn,
                    ArtWorkLink: srcData,
                    FileName: file.name,
                  };
                  uploadLabelOrderTempAttachment(data).then(s => {
                    if (s.isSuccessful) {
                      getLabelOrderTempAttachment(userPsn, 1);
                    }
                  });
                } else {
                  setTimeout(() => {
                    toast({
                      title: '',
                      description: (
                        <p>
                          {
                            'Virus or malware has been detected, this file cannot be uploaded.'
                          }
                        </p>
                      ),
                      time: 10000,
                      size: 'small',
                      icon: 'check',
                      color: 'red',
                    });
                  }, 100);
                }
              });
          };
          fileReader.readAsDataURL(file);
        } else {
          setTimeout(() => {
            toast({
              title: '',
              description: (
                <p>{Common.getTranslations()['LexAcceptableDocTypeV2']}</p>
              ),
              time: 10000,
              size: 'small',
              icon: 'check',
              color: 'red',
            });
          }, 100);
        }
      } else {
        setTimeout(() => {
          toast({
            title: '',
            description: <p>{'Max. size for each document is 2MB'}</p>,
            time: 10000,
            size: 'small',
            icon: 'check',
            color: 'red',
          });
        }, 100);
      }
    }
  };

  handleRedirectToHelpMe = () => {
    this.props.history.push(`/home/reply-to-help`);
  };

  handleReturnToShopping = () => {
    this.handleOk();
  };

  handleChangeQuantity = (e, cartItem) => {
    const { activeIndex } = this.state;
    const re = /^[0-9\b]+$/;

    if (
      e.target.value % cartItem.minOrderQuantity !== 0 ||
      e.target.value === 0
    ) {
      e.target.value = cartItem.quantity;
      setTimeout(() => {
        toast({
          title: '',
          description: (
            <p>
              {
                'Invalid quantity. Please refer to the label package description for min. order quantity.'
              }
            </p>
          ),
          time: 10000,
          size: 'small',
          icon: '',
          color: 'red',
        });
      }, 100);
    }

    if (e.target.value !== '' || re.test(e.target.value)) {
      this.props.setQuantity(activeIndex, cartItem, e.target.value);
    }
  };

  onClickAgreeChecbox = () =>
    this.setState(prevState => ({ isCheckAgree: !prevState.isCheckAgree }));

  handleSubmit = () => {
    const { billToCountryCode, shipToCountryCode, applicantCountryCode } =
      this.state;
    const { forms, labelCart } = this.props;
    let applicantAccountNumbers = [],
      manufacturerAccountNumbers = [],
      billToAccountNumbers = [],
      orderAmounts = [];

    for (var cnt = 0; cnt < forms.length; cnt++) {
      var form = forms[cnt];

      applicantAccountNumbers.push(form.applicantAccountNoSelection);
      manufacturerAccountNumbers.push(form.manufacturerAccountNumber);
      billToAccountNumbers.push(form.billToAccountNo);
      orderAmounts.push(
        labelCart.data[0].labelOrderType === 1 ? form.grantTotal : '0'
      );
    }

    this.props
      .getCreditProfile(
        applicantAccountNumbers,
        manufacturerAccountNumbers,
        billToAccountNumbers,
        orderAmounts
      )
      .then(response => {
        if (response) {
          setTimeout(() => {
            toast({
              title: '',
              description: (
                <p>{Common.getTranslations()['LexCreditCheckAlert']}</p>
              ),
              time: 10000,
              size: 'small',
              icon: '',
              color: 'yellow',
            });
          }, 100);
          return;
        }
      });

    this.setState({ isLoading: 'loading' });
    this.props
      .createOrder({
        status: 2,
        isCheckedOut: true,
        customerPoNumber: this.props.form.customerPoNumber,
        brokerInformation: this.props.form.brokerInformation,
        shippingOptionId:
          this.props.form.shippingOption == null
            ? 0
            : this.props.form.shippingOption,
        billToContactName: this.props.form.billToContactName,
        billToAddress: this.props.form.billToAddress,
        shipToContactName: this.props.form.shipToContactName,
        shipToAddress: this.props.form.shipToAddress,
        billToCountry: this.props.countryOptions
          ? this.props.form.billToCountry
          : this.state.billToCountryCode,
        manufacturerName: this.props.form.manufacturerName,
        manufacturerAccountNumber: this.props.form.manufacturerAccountNumber,
        manufacturerAddress: this.props.form.manufacturerAddress,
        manufacturerPartySiteNumber:
          this.props.form.manufacturerPartySiteNumber,
        shipToCountry: this.props.countryOptions
          ? this.props.form.shipToCountry
          : this.state.shipToCountryCode,
        comments: this.props.form.comments,
        customerEmailAddress: this.props.form.customerEmailAddress,
        customerPhoneNumber: this.props.form.customerPhoneNumber,
        billToCompany: this.props.form.billToCompany,
        billToAccountNo: this.props.form.billToAccountNo,
        totalPrintingFee: this.props.total,
        totalHandlingFee: this.props.totalHandlingFee,
        grandTotal: this.props.grantTotal,
        billToCountryCode: billToCountryCode
          ? billToCountryCode
          : this.props.form.billToCountryCode,
        shipToCountryCode: shipToCountryCode
          ? shipToCountryCode
          : this.props.form.shipToCountryCode,
        applicantAccountNo: this.props.form.applicantAccountNo,
        applicantCompany: this.props.form.applicantCompany,
        applicantAddress: this.props.form.applicantAddress,
        applicantCountry: this.props.form.applicantCountry,
        applicantCountryCode: applicantCountryCode
          ? applicantCountryCode
          : this.props.form.applicantCountryCode,
        billToContactEmail: this.props.form.billToContactEmail,
      })
      .then(s => {
        if (s != null) this.setState({ openTac: false, isLoading: '' });

        if (s) {
          setTimeout(() => {
            toast({
              title: '',
              description: (
                <p>
                  {Common.getTranslations()[
                    'LexPlaceOrderSuccessAlert'
                  ].replace('{0}', s.number)}
                </p>
              ),
              time: 10000,
              size: 'small',
              icon: 'check',
              color: 'green',
            });
          }, 100);

          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });

          this.props.getCartItems();
        } else {
          this.setState({ openTac: false, isLoading: '' });
          setTimeout(() => {
            toast({
              title: '',
              description: <p>{'Something went wrong, push order failed'}</p>,
              time: 10000,
              size: 'small',
              icon: 'check',
              color: 'red',
            });
          }, 100);

          window.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
        }
      });
  };

  initializeValidations = () => {
    rules = [
      validate(
        'customerPoNumber',

        Common.getTranslations()['LexPPNoisrequired'],
        required
      ),
      validate(
        'labelCenter',

        Common.getTranslations()['LexLabelCenterisrequired'],
        required
      ),
      validate(
        'shippingOption',

        Common.getTranslations()['LexShippingOptionsisRequired'],
        required
      ),
      //validate("shippingDetails", "Shipping Details", required),
      validate(
        'billToContactName',

        Common.getTranslations()['LexBilltoContactNameisrequired'],
        required
      ),
      validate(
        'billToAddress',

        Common.getTranslations()['LexBilltoAddressisrequired'],
        required
      ),
      validate(
        'shipToContactName',

        Common.getTranslations()['LexManufacturerContactNameisRequired'],
        required
      ),
      // validate("shipToAddress", "Ship To Address", required),
      validate(
        'manufacturerName',

        Common.getTranslations()['LexManufacturerNameisRequired'],
        required
      ),
      validate(
        'manufacturerAccountNumber',

        Common.getTranslations()['LexManufacturerAccountNumberisRequired'],
        required
      ),
      validate(
        'shipToCountry',

        Common.getTranslations()['LexManufacturerCountryisRequired'],
        required
      ),
      validate(
        'manufacturerAddress',
        Common.getTranslations()['LexManufacturerAddressisRequired'],
        required
      ),
      validate(
        'customerEmailAddress',
        Common.getTranslations()['LexManufacturerEmailAddressisRequired'],
        required,
        email
      ),
      validate(
        'customerPhoneNumber',
        Common.getTranslations()['LexManufacturerPhoneNumberisRequired'],
        required
      ),
      validate(
        'billToCompany',
        Common.getTranslations()['LexBilltoCompanyisrequired'],
        required
      ),
      validate(
        'billToAccountNo',
        Common.getTranslations()['LexBilltoAccountNumberisrequired'],
        required
      ),
      validate(
        'billToContactEmail',
        Common.getTranslations()['LexBilltoContactEmailisrequired'],
        required,
        email
      ),
      validate(
        'billToCountry',
        Common.getTranslations()['LexBilltoCountryisrequired'],
        required
      ),
      validate(
        'applicantAccountNoSelection',
        Common.getTranslations()['LexApplicantAccountNumberisRequired'],
        required
      ),
      validate(
        'applicantCompany',
        Common.getTranslations()['LexApplicantCompanyisRequired'],
        required
      ),
      validate(
        'applicantAddress',
        Common.getTranslations()['LexApplicantAddressisRequired'],
        required
      ),
      validate(
        'applicantContactName',
        Common.getTranslations()['LexApplicantContactNameisRequired'],
        required
      ),
      validate(
        'applicantCountry',
        Common.getTranslations()['LexApplicantCountryisRequired'],
        required
      ),
    ];
  };
  componentDidMount() {
    var labelUserId = this.props.match.params.userId;
    this.initializeValidations();
    this.props.resetCartItemsForm();
    this.setState({ isLoadCountryOptions: false });
    if (labelUserId) {
      this.props.getCartItems(labelUserId).then(results => {
        if (results.length > 1 && results[0].isItemUpdated) {
          this.showReminderInfo();
        }
      });
      this.setState({ forReview: true });
    } else {
      this.props.getCartItems().then(results => {
        if (results && results.length > 1 && results[0].isItemUpdated) {
          this.showReminderInfo();
        }
      });
    }

    this.props.getShippingOptions('US');

    this.setState({ isSucessCustomer: false });
    this.props.getLabelCenters();
    this.props.getCountries(1);
    this.handleHeaderLabel();
    this.props.checkIfServiceContractUp();
  }

  componentWillUnmount() {
    //this.props.clearForm();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.data !== this.props.data) {
      this.openAccordionByDefault();
    }

    if (prevState.activeIndex !== this.state.activeIndex) {
      this.openAccordionByDefault();
    }
  }
  openAccordionByDefault = () => {
    const { data } = this.props.labelCart;
    const { activeIndex } = this.state;
    if (data) {
      if (data[activeIndex]) {
        let newAccIndex = [];

        for (let idx in data[activeIndex].carts) {
          newAccIndex.push(parseInt(idx));
        }

        this.setState(() => ({
          accordIndexes: newAccIndex,
        }));

        // this.setState({ accordIndexes: newAccIndex });
      }
    }
  };

  handleLoadAccountDetails = accountNumber => {
    this.props.getCustomerRelations(accountNumber, 'true');
  };

  handleLoadAccountDetailsShipTo = accountNumber => {
    this.props.getCustomerRelationsShipTo(accountNumber, 'false');
  };

  handleAccntNumDorpDownChange = properties => {
    const { forms } = this.props;

    var currentIndex = this.state.activeIndex;

    var form = forms[currentIndex];

    if (properties.name === 'applicantAccountNoSelection') {
      var fileVolumeDetails = form.applicantFileVolumeDetails.filter(
        f => f.accountNumber === properties.value
      )[0];

      if (fileVolumeDetails) {
        // Applicant details

        form.applicantCompany = fileVolumeDetails.partyName;
        form.applicantAccountNoSelection = fileVolumeDetails.accountNumber;
        form.applicantAccountNo = fileVolumeDetails.accountNumber;
        form.applicantAddress = fileVolumeDetails.addressLine1;
        form.applicantCountry = fileVolumeDetails.countryName;
        form.applicantCountryCode = fileVolumeDetails.countryCode;
        this.setState({ applicantCountryCode: fileVolumeDetails.countryCode });
        // this.props.getCustomerRelationInformations(
        //   fileVolumeDetails.accountNumber
        // );

        if (form.applicantCompany) {
          this.setState({ isCompanyApplicantEnable: false });
        }

        if (form.applicantAddress) {
          this.setState({ isCompanyApplicantAddEnable: false });
        }

        this.props.getApplicantContactInformations(
          fileVolumeDetails.accountNumber
        );
      }
    } else if (properties.name === 'accntNumber') {
      var add1;
      var add2;
      var add3;
      var add4;
      var city;
      var province;
      var postalCode;
      var accountRecord = this.props.customerRelationsData.find(function (
        item
      ) {
        return item.accountNumber === properties.value;
      });
      if (accountRecord) {
        add1 = accountRecord.address1;
        add2 = accountRecord.address2 ? ', ' + accountRecord.address2 : '';
        add3 = accountRecord.address3 ? ', ' + accountRecord.address3 : '';
        add4 = accountRecord.address4 ? ', ' + accountRecord.address4 : '';
        city = accountRecord.city ? ', ' + accountRecord.city : '';
        province = accountRecord.province ? ', ' + accountRecord.province : '';
        postalCode = accountRecord.postalCode
          ? ', ' + accountRecord.postalCode
          : '';

        // Bill to
        form.billToCompany = accountRecord.accountName;
        form.billToAccountNo = accountRecord.accountNumber;
        form.billToAddress =
          add1 + add2 + add3 + add4 + city + province + postalCode;
        form.billToAddress = form.billToAddress.replace(/(^,)|(,$)/g, '');
        form.billToContactName = null;

        var country = [];

        if (accountRecord.country === 'United States') {
          accountRecord.country = 'United States of America';
        }

        if (accountRecord.country.length > 2) {
          country = this.props.countryOptions.find(function (item) {
            return (
              item.text.toUpperCase() === accountRecord.country.toUpperCase()
            );
          });
        } else {
          country = this.props.countryOptions.find(function (item) {
            return item.key === accountRecord.country;
          });
        }

        if (country) {
          this.setState({ billToCountryCode: country.value });

          form.billToCountry = country.text;
          form.billToCountryCode = country.value;

          if (country.value === 'TW' || country.value === 'KR') {
            this.setState({ billToCountryCode: country.value });
          }

          this.props.getExchangeRate(country.value, currentIndex);
        }

        if (form.billToCompany) {
          this.setState({ isCompanyEnable: false });
        }
        if (form.billToAddress) {
          this.setState({ isAddressEnable: false });
        }
        // this.props.form.billToContactName = "N/A";
        // this.props.form.customerPhoneNumber = "N/A";
        // this.props.form.customerEmailAddress = "N/A";
        this.props.getCustomerRelationInformations(accountRecord.accountNumber);
      } else {
        form.billToAccountNo = properties.value;
        form.billToContactName = null;
        form.billToContactEmail = null;
        let fileVolumeDetails = form.applicantFileVolumeDetails.filter(
          f => f.accountNumber === properties.value
        )[0];

        if (!fileVolumeDetails) {
          fileVolumeDetails = form.manufacturerFileVolumeDetails.filter(
            f => f.accountNumber === properties.value
          )[0];
        }

        if (fileVolumeDetails) {
          form.manufacturerName = fileVolumeDetails.partyName;
          form.manufacturerAccountNumber = fileVolumeDetails.accountNumber;
          form.manufacturerAddress = fileVolumeDetails.addressLine1;
          form.manufacturerPartySiteNumber = fileVolumeDetails.partySiteNumber;
          form.shipToCountry = fileVolumeDetails.countryName;
          form.shipToCountryCode = fileVolumeDetails.countryCode;
        }
        if (fileVolumeDetails) {
          // Applicant details
          form.billToCompany = fileVolumeDetails.partyName;
          form.billToAddress = fileVolumeDetails.addressLine1;

          const country = this.props.countryOptions.find(function (item) {
            return item.key === fileVolumeDetails.countryCode;
          });

          if (country) {
            this.setState({ billToCountryCode: country.value });

            form.billToCountry = country.text;
            form.billToCountryCode = country.value;

            if (country.value === 'TW' || country.value === 'KR') {
              this.setState({ billToCountryCode: country.value });
            }

            this.props.getExchangeRate(country.value, currentIndex);
          }

          if (form.billToCompany) {
            this.setState({ isCompanyEnable: false });
          }
          if (form.billToAddress) {
            this.setState({ isAddressEnable: false });
          }
        }
        this.props.getCustomerRelationInformations(properties.value);
      }
    }
  };

  handleAccntNumShipToDropDownChange = accntNumber => {
    const { forms } = this.props;
    var currentIndex = this.state.activeIndex;

    var form = forms[currentIndex];

    var fileVolumeDetails = form.manufacturerFileVolumeDetails.filter(
      f => f.accountNumber === accntNumber
    )[0];

    if (fileVolumeDetails) {
      form.manufacturerName = fileVolumeDetails.partyName;
      form.manufacturerAccountNumber = fileVolumeDetails.accountNumber;
      form.manufacturerAddress = fileVolumeDetails.addressLine1;
      form.manufacturerPartySiteNumber = fileVolumeDetails.partySiteNumber;
      form.shipToCountry = fileVolumeDetails.countryName;
      form.shipToCountryCode = fileVolumeDetails.countryCode;
    }

    this.props
      .getLabelCenterCountryDetails(fileVolumeDetails.countryCode)
      .then(() => {
        if (this.props.labelCenterCountryDetails) {
          var labelCenter = this.props.labelCenterCountryDetails.labelCenter;

          if (labelCenter === 'EUROPE') {
            labelCenter = 'Europe';
          }
          var result = this.props.labelCenters.find(function (item) {
            return item.text === labelCenter;
          });

          if (result) {
            forms[this.state.activeIndex].labelCenter = result.value;
            //to remove
            forms[this.state.activeIndex].labelCenterName = result.text;
            this.props.updateShippingOptions(result.text);
            this.setState({ labelCenterName: result.text });
          } else {
            forms[this.state.activeIndex].labelCenter = null;
            this.props.updateShippingOptions('');
          }
        }
      });

    if (form.manufacturerName) {
      this.setState({ isCompanyShipToEnable: false });
    }
    if (form.manufacturerAddress) {
      this.setState({ isAddressShipToEnable: false });
    }
  };

  deleteTabConfirmationOpen = () => {
    this.setState({ openBillToModal: true });
  };

  deleteTabConfirmationCancel = () => {
    this.setState({ openBillToModal: false });
  };

  handleDeleteTab = () => {
    const { activeIndex } = this.state;
    const { data } = this.props;
    var cartIds = [];

    data[activeIndex].carts.map(x => {
      return x.cartItems.map(s => {
        cartIds.push(s.id);
        return s.id;
      });
    });

    var cartData = {
      cartItemIds: cartIds,
      isDeleteTab: true,
    };

    if (cartData.cartItemIds.length > 0) {
      this.props.deleteCart(cartData);
      this.props.deleteTab(activeIndex);
    }

    if (activeIndex > 0) {
      var newActiveIndex = activeIndex - 1;
      this.setState({ activeIndex: newActiveIndex });
    }

    setTimeout(() => {
      toast({
        title: '',
        description: 'File successfully deleted',
        time: 10000,
        size: 'small',
        icon: 'success',
        color: 'green',
      });
    }, 100);

    this.setState({ openBillToModal: false });
  };

  handleTabChange = (e, { activeIndex }) => {
    const { forms } = this.props;
    var currentIndex = this.state.activeIndex;

    if (activeIndex !== currentIndex) {
      var form = forms[currentIndex];
      var result = this.props.labelCenters.find(function (item) {
        return item.value === form.labelCenter;
      });

      //console.log(form.labelCenterName);

      form.validationErrors = createValidation(form, rules);
      if (
        (result && result.text === 'Japan') ||
        (result && result.text === 'South Korea') ||
        (result && result.text === 'Europe') ||
        (result && result.text === 'China') ||
        (result && result.text === 'Taiwan') ||
        form.labelOrderType === 2
      ) {
        form.validationErrors = _.omit(form.validationErrors, [
          'shippingOption',
        ]);
      }

      if (Object.IsEmpty(form.validationErrors)) {
        // this.setState({ activeIndex });
        if (currentIndex === 0) {
          this.handleSaveChanges();
        } else {
          this.setState({ activeIndex });
        }
      } else {
        this.setState({ showErrors: true });
      }
      this.setState({ isLoadCustomerRelation: false });
      this.setState({ isLoadCustomerRelationShipTo: false });
      this.setState({ isLoadCountryOptions: false });
    }
  };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { accordIndexes } = this.state;
    const newIndex = accordIndexes;

    const currentIndexPosition = accordIndexes.indexOf(index);
    if (currentIndexPosition > -1) {
      newIndex.splice(currentIndexPosition, 1);
    } else {
      newIndex.push(index);
    }

    this.setState({ accordIndexes: newIndex });
  };

  showReminderInfo() {
    setTimeout(() => {
      toast({
        title: '',
        description: (
          <p>
            {` System change has effected you cart order please delete your cart and start your Label ordering process again.`}
          </p>
        ),
        time: 10000,
        size: 'small',
        icon: 'warning',
        color: 'yellow',
      });
    }, 100);
  }

  renderLabelOrderPackage = labelOrderPackage => {
    const translations = Common.getTranslations() || {};

    if (labelOrderPackage && labelOrderPackage !== 'EACH') {
      labelOrderPackage = labelOrderPackage
        .toUpperCase()
        .replace('RL', translations['LexRL'])
        .replace('LABELS', translations['LexLabels'].toUpperCase());
    }
    return labelOrderPackage;
  };

  render() {
    const {
      data,
      createOrderResult,
      isSuccessUpdateCart,
      isSuccessDeleteCart,
      isSuccessCancelOrder,
      isSuccessCancelCart,
      forms,
      shippingOptions,
      customerDetails,
      isSuccessCreateOrder,
      attachments,
      hasSameBillTo,
    } = this.props.labelCart;

    const {
      accountNumbersOptions,
      accountNumbersShipToOptions,
      customerRelationInformationsOptions,
      customerRelationInformationsShiptToOptions,
      customerRelationInformationsApplicantOptions,
      countryOptions,
      applicantAccountNumbers,
      isLoadingRelation,
      isLoadingRelationInformation,
      orderPage,
      isLoadingCartItems,
    } = this.props;

    const { labelCenters } = this.props;

    const {
      forReview,
      openSameFileNumbers,
      open,
      openCheckOut,
      openTac,
      isCheckAgree,
      isLoading,
      isSucessCustomer,
      isCustomerRelationEmpty,
      isCustomerRelationShipToEmpty,
      isCompanyApplicantEnable,
      isCompanyApplicantAddEnable,
      isCompanyEnable,
      isAddressEnable,
      isCompanyShipToEnable,
      isAddressShipToEnable,
      isLoadApplicantContactInfo,
      isLoadCustomerRelation,
      isLoadCustomerRelationShipTo,
      isSuccessUpdateShippingOption,
      activeIndex,
      isLoadCountryOptions,
      isDifferentBillTo,
      openCustomerEcmStatusModal,
      OldAppAccntNo,
      isReadOnlyBillToEmail,
      isReadOnlyShipToEmail,
      isLoadBillToAccntEmpty,
      openBillToModal,
      accordIndexes,
      hasSameBillToResponse,
    } = this.state;

    const translations = Common.getTranslations() || {};
    const hasCartItems = data && data.length > 0;
    const custDetails = customerDetails.data;

    var totalLabels = 0;
    var concatenatedComments = '';
    var hasSameBillToAccountNumber = false;
    var userPsn = this.props.match.params.psn
      ? this.props.match.params.psn
      : Common.getPartySiteNumber();

    if (data) {
      data.forEach(item => {
        item.carts.forEach(cart => {
          cart.cartItems.forEach(() => {
            totalLabels += 1;
          });
        });
      });
    }

    const roleId = Common.getUserRole();
    let nf = new Intl.NumberFormat('en-US');

    const panes = [];

    var labelOrderType = null;

    if (data && data.length > 0) {
      var fileNumberList = data;
      let duplicates = [];
      let fileNumDups = [];
      var commentsList = forms.map(item => item.comments);
      var billToAccountNumbers = forms.map(item => item.billToAccountNo);
      var uniqueComments = [...new Set(commentsList)];
      hasSameBillToAccountNumber =
        [...new Set(billToAccountNumbers)].length === 1;
      concatenatedComments = uniqueComments.map(item => item || '').join(' / ');
      labelOrderType = data[activeIndex].labelOrderType;

      if (forReview) {
        const tempArray = [...fileNumberList];

        for (let i = 0; i < tempArray.length; i++) {
          if (i !== tempArray.length - 1) {
            if (
              tempArray[i + 1].billToAccountNo === tempArray[i].billToAccountNo
            ) {
              duplicates.push(tempArray[i].billToAccountNo);
              fileNumDups.push(tempArray[i].fileNumber);
            }
          }
        }
      }
      for (let index = 0; index < data.length; index++) {
        const element = data[index];

        const frm = forms && forms.length > 0 ? forms[activeIndex] || {} : {};

        var isDuplicate = duplicates.find(function (item) {
          return item === element.billToAccountNo;
        });

        if (forReview && isDuplicate) {
          //var n = element.fileNumber.includes("-");
          const fileNo = fileNumDups[0];

          //if (!n) {
          // element.fileNumber =
          //   element.fileNumber + "-" + element.billToAccountNo;
          if (fileNo !== element.fileNumber) {
            var isSameBillTo = data.filter(x => x.fileNumber === fileNo);
            for (let index = 0; index < element.carts.length; index++) {
              const cart = element.carts[index];
              var isExist = isSameBillTo[0].carts.find(function (item) {
                return item === cart;
              });
              if (!isExist) {
                isSameBillTo[0].carts.push(cart);
              }
            }
            this.props.updateReviewCart(element);
          }
          //}
        }

        let isBillToContactDropdownHidden =
          (customerRelationInformationsOptions &&
            customerRelationInformationsOptions.length === 0) ||
          ((hasSameBillTo || frm.hasCompleteServiceContractData) &&
            activeIndex > 0) ||
          (hasSameBillTo &&
            frm.hasCompleteServiceContractData &&
            activeIndex === 0) ||
          (!hasSameBillTo &&
            frm.hasCompleteServiceContractData &&
            activeIndex === 0) ||
          hasSameBillToResponse;

        const pane = {
          name: forReview ? element.billToAccountNo : element.fileNumber,
          menuItem: forReview ? (
            <Menu.Item>
              {translations['LexAccountNumber']} {element.billToAccountNo}
            </Menu.Item>
          ) : (
            <Menu.Item>
              <table>
                <tr>
                  <td>
                    {translations['LexFileNumber']} {element.fileNumber}{' '}
                  </td>
                  <td hidden={index !== activeIndex}>
                    <Label onClick={this.deleteTabConfirmationOpen}>X</Label>
                  </td>
                </tr>
              </table>
            </Menu.Item>
          ),
          render: () => (
            <Tab.Pane key={index} style={{ padding: '20px' }}>
              {' '}
              <Form loading={isLoadingCartItems}>
                {/* {forReview && (
                  <CustomerInformation
                    model={custDetails}
                    loadingCustomerInfo={isLoadingCustomerDetails}
                    data={data && data[0]}
                  />
                )} */}
                {data && data.length > 0 && data[0].carts.length > 0 && (
                  <Segment>
                    <Grid style={{ padding: '20px' }}>
                      <span hidden={!forReview}>
                        <Grid.Row>
                          <table>
                            <tr>
                              <td style={{ verticalAlign: 'top' }}>
                                <i
                                  class="exclamation circle icon"
                                  style={{
                                    backgroundColor: 'white',
                                    color: 'orange',
                                  }}
                                ></i>
                              </td>
                              <td>
                                {translations['LexPleasereviewtheinformation']}
                                <a
                                  href="https://marks.ul.com/about/ul-listing-and-classification-marks/labels/label-centers/"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {' '}
                                  {translations['LexContactLabelCenter']}.
                                </a>
                              </td>
                            </tr>
                          </table>
                        </Grid.Row>
                      </span>
                      <Grid.Row>
                        <Grid.Column width={8}>
                          <span hidden={!forReview}>
                            <CustomHeaderLabel2
                              message={translations['LexYourInformation']}
                            />
                            <br />
                            <hr
                              style={{
                                width: '70%',
                                color: 'gray',
                                textAlign: 'left',
                                margin: '0px',
                              }}
                            />
                            <table>
                              <tr>
                                <td>
                                  <CustomLabel2
                                    message={translations['LexName']}
                                  />
                                </td>
                                <td style={{ paddingLeft: '50px' }}>
                                  {custDetails
                                    ? custDetails.combintationCustomerName
                                    : '--'}
                                </td>
                              </tr>
                              <tr></tr>
                              <tr>
                                <td>
                                  <CustomLabel2
                                    message={translations['LexAccountNumber']}
                                  />
                                </td>
                                <td style={{ paddingLeft: '50px' }}>
                                  {custDetails
                                    ? custDetails.accountNumber
                                    : '--'}
                                </td>
                              </tr>
                              <tr></tr>
                              <tr>
                                <td>
                                  <CustomLabel2 message="Party Site Number" />
                                </td>
                                <td style={{ paddingLeft: '50px' }}>
                                  {custDetails ? custDetails.psn : '--'}
                                </td>
                              </tr>
                              <tr></tr>
                              <tr>
                                <td>
                                  <CustomLabel2
                                    message={translations['LexEmail']}
                                  />
                                </td>
                                <td style={{ paddingLeft: '50px' }}>
                                  {custDetails
                                    ? custDetails.combintationCustomerEmail
                                    : '--'}
                                </td>
                              </tr>
                              <tr></tr>
                              <tr>
                                <td>
                                  <CustomLabel2
                                    message={translations['LexPhoneNumber']}
                                  />
                                </td>
                                <td style={{ paddingLeft: '50px' }}>
                                  {custDetails
                                    ? custDetails.combintationCustomerPhone
                                      ? custDetails.combintationCustomerPhone
                                      : '--'
                                    : '--'}
                                </td>
                              </tr>
                            </table>
                            <br />
                          </span>
                          <CustomHeaderLabel2
                            message={translations['LexApplicantDetails']}
                          />
                          <span hidden={forReview}>
                            <br />
                            <br />
                            <i>{translations['LexBillToReminder']}</i>
                            <br />
                            <br />
                          </span>
                          <span hidden={forReview || applicantAccountNumbers}>
                            <Dimmer
                              active={
                                isLoadingRelation ||
                                isLoadingRelationInformation
                              }
                              inverted
                            >
                              <Loader />
                            </Dimmer>
                            <Form.Field>
                              <Form.Field
                                control={Dropdown}
                                compact
                                selection
                                selectOnBlur={false}
                                label={`* ${translations['LexAccountNumber']}`}
                                placeholder={`${translations['LexSelect']}  ${translations['LexAccountNumber']}`}
                                name="applicantAccountNoSelection"
                                options={frm.applicantAccountNumbers}
                                disabled={isLoading || frm.isBillToDisable}
                                onChange={(e, properties) => {
                                  //this.handleChangeLabelCenter(properties);
                                  this.handleAccntNumDorpDownChange(properties);
                                }}
                                value={frm.applicantAccountNoSelection || ''}
                                error={this.getErrorFor(
                                  'applicantAccountNoSelection'
                                )}
                                autoComplete="off"
                              />
                            </Form.Field>
                          </span>
                          <span hidden={!forReview}>
                            <hr
                              style={{
                                width: '70%',
                                color: 'gray',
                                textAlign: 'left',
                                margin: '0px',
                              }}
                            />
                            <table>
                              <tr>
                                <td>
                                  <CustomLabel2
                                    message={translations['LexAccountNumber']}
                                  />
                                </td>
                                <td style={{ paddingLeft: '50px' }}>
                                  {frm.applicantAccountNo
                                    ? frm.applicantAccountNo
                                    : '--'}
                                </td>
                              </tr>
                              <tr></tr>
                              <tr>
                                <td>
                                  <CustomLabel2
                                    message={translations['LexCompany']}
                                  />
                                </td>
                                <td style={{ paddingLeft: '50px' }}>
                                  {frm.applicantCompany
                                    ? frm.applicantCompany
                                    : '--'}
                                </td>
                              </tr>
                              <tr></tr>
                              <tr>
                                <td>
                                  <CustomLabel2
                                    message={translations['LexAddress']}
                                  />
                                </td>
                                <td style={{ paddingLeft: '50px' }}>
                                  {frm.applicantAddress
                                    ? frm.applicantAddress
                                    : '--'}
                                </td>
                              </tr>
                              <tr></tr>
                              <tr>
                                <td>
                                  <CustomLabel2
                                    message={translations['LexCountry']}
                                  />
                                </td>
                                <td style={{ paddingLeft: '50px' }}>
                                  {frm.applicantCountry
                                    ? frm.applicantCountry
                                    : '--'}
                                </td>
                              </tr>
                              <tr></tr>
                              <tr>
                                <td>
                                  <CustomLabel2
                                    message={translations['LexContactName']}
                                  />
                                </td>
                                <td style={{ paddingLeft: '50px' }}>
                                  {frm.applicantContactName
                                    ? frm.applicantContactName
                                    : '--'}
                                </td>
                              </tr>
                            </table>
                          </span>
                          <Divider fitted hidden />
                          <Form.Field>
                            <span hidden={forReview}>
                              <Form.Field
                                control={Input}
                                label={`* ${translations['LexCompany']}`}
                                placeholder={translations['LexCompany']}
                                name="applicantCompany"
                                onChange={this.handleChange}
                                value={frm.applicantCompany || ''}
                                error={this.getErrorFor('applicantCompany')}
                                autoComplete="off"
                                disabled={isLoading || frm.isBillToDisable}
                                readOnly={
                                  !isCompanyApplicantEnable ||
                                  frm.isAppCompanyReadOnly ||
                                  frm.applicantAccountNumbers
                                }
                              />
                            </span>
                          </Form.Field>
                          <Form.Field>
                            <span hidden={forReview}>
                              <Form.Field
                                control={Input}
                                label={`* ${translations['LexAddress']}`}
                                placeholder={translations['LexAddress']}
                                name="applicantAddress"
                                onChange={this.handleChange}
                                value={frm.applicantAddress || ''}
                                error={this.getErrorFor('applicantAddress')}
                                autoComplete="off"
                                disabled={isLoading || frm.isBillToDisable}
                                readOnly={
                                  !isCompanyApplicantAddEnable ||
                                  frm.isAppAddressReadOnly ||
                                  frm.applicantAccountNumbers
                                }
                              />
                            </span>
                          </Form.Field>
                          <Form.Field>
                            <span hidden={forReview || applicantAccountNumbers}>
                              <Form.Field
                                control={Input}
                                label={`* ${translations['LexCountry']}`}
                                placeholder={translations['LexCountry']}
                                name="applicantCountry"
                                value={frm.applicantCountry || ''}
                                error={this.getErrorFor('applicantCountry')}
                                autoComplete="off"
                                disabled={isLoading || frm.isBillToDisable}
                                readOnly={
                                  !isCompanyApplicantAddEnable ||
                                  frm.isAppAddressReadOnly ||
                                  frm.applicantAccountNumbers
                                }
                              />
                            </span>
                          </Form.Field>
                          <Form.Field>
                            <span
                              hidden={
                                forReview ||
                                (customerRelationInformationsApplicantOptions &&
                                  customerRelationInformationsApplicantOptions.length !==
                                    0)
                              }
                            >
                              <Form.Field
                                control={Input}
                                label={`* ${translations['LexContactName']}`}
                                placeholder={translations['LexContactName']}
                                name="applicantContactName"
                                onChange={this.handleChange}
                                value={frm.applicantContactName || ''}
                                error={this.getErrorFor('applicantContactName')}
                                autoComplete="off"
                                disabled={isLoading || frm.isBillToDisable}
                                readOnly={
                                  !isCompanyApplicantAddEnable ||
                                  frm.isAppAddressReadOnly ||
                                  frm.applicantAccountNumbers
                                }
                              />
                            </span>
                            <span hidden={!forReview}>
                              <CustomHeaderLabel2
                                message={translations['LexManufacturerDetails']}
                              />
                              <hr
                                style={{
                                  width: '70%',
                                  color: 'gray',
                                  textAlign: 'left',
                                  margin: '0px',
                                }}
                              />
                              <table>
                                <tr>
                                  <td>
                                    <CustomLabel2
                                      message={translations['LexAccountNumber']}
                                    />
                                  </td>
                                  <td style={{ paddingLeft: '50px' }}>
                                    {frm.manufacturerAccountNumber
                                      ? frm.manufacturerAccountNumber
                                      : '--'}
                                  </td>
                                </tr>
                                <tr></tr>
                                <tr>
                                  <td>
                                    <CustomLabel2
                                      message={`Party Site Number`}
                                    />
                                  </td>
                                  <td style={{ paddingLeft: '50px' }}>
                                    {frm.manufacturerPartySiteNumber
                                      ? frm.manufacturerPartySiteNumber
                                      : '--'}
                                  </td>
                                </tr>
                                <tr></tr>
                                <tr>
                                  <td>
                                    <CustomLabel2
                                      message={translations['LexCompany']}
                                    />
                                  </td>
                                  <td style={{ paddingLeft: '50px' }}>
                                    {frm.manufacturerName
                                      ? frm.manufacturerName
                                      : '--'}
                                  </td>
                                </tr>
                                <tr></tr>
                                <tr>
                                  <td>
                                    <CustomLabel2
                                      message={translations['LexAddress']}
                                    />
                                  </td>
                                  <td style={{ paddingLeft: '50px' }}>
                                    {frm.manufacturerAddress
                                      ? frm.manufacturerAddress
                                      : '--'}
                                  </td>
                                </tr>
                                <tr></tr>
                                <tr>
                                  <td>
                                    <CustomLabel2
                                      message={translations['LexCountry']}
                                    />
                                  </td>
                                  <td style={{ paddingLeft: '50px' }}>
                                    {frm.shipToCountry
                                      ? frm.shipToCountry
                                      : '--'}
                                  </td>
                                </tr>
                                <tr></tr>
                                <tr>
                                  <td>
                                    <CustomLabel2
                                      message={translations['LexContactName']}
                                    />
                                  </td>
                                  <td style={{ paddingLeft: '50px' }}>
                                    {frm.shipToContactName
                                      ? frm.shipToContactName
                                      : '--'}
                                  </td>
                                </tr>
                                <tr></tr>
                                <tr>
                                  <td>
                                    <CustomLabel2
                                      message={translations['LexContactEmail']}
                                    />
                                  </td>
                                  <td style={{ paddingLeft: '50px' }}>
                                    {frm.customerEmailAddress
                                      ? frm.customerEmailAddress
                                      : '--'}
                                  </td>
                                </tr>
                                <tr></tr>
                                <tr>
                                  <td>
                                    <CustomLabel2
                                      message={translations['LexPhoneNumber']}
                                    />
                                  </td>
                                  <td style={{ paddingLeft: '50px' }}>
                                    {frm.customerPhoneNumber
                                      ? frm.customerPhoneNumber
                                      : '--'}
                                  </td>
                                </tr>
                              </table>
                            </span>
                          </Form.Field>
                          <Form.Field>
                            <span
                              hidden={
                                forReview ||
                                customerRelationInformationsApplicantOptions ==
                                  null ||
                                (customerRelationInformationsApplicantOptions &&
                                  customerRelationInformationsApplicantOptions.length ===
                                    0)
                              }
                            >
                              <Form.Field
                                control={Dropdown}
                                compact
                                selection
                                label={`* ${translations['LexContactName']}`}
                                placeholder={`${translations['LexSelect']}  ${translations['LexContactName']}`}
                                name="applicantContactName"
                                disabled={
                                  !frm.applicantAccountNumbers ||
                                  frm.isBillToDisable
                                }
                                options={
                                  customerRelationInformationsApplicantOptions
                                }
                                onChange={(e, properties) => {
                                  this.handleChangeLabelCenter(properties);
                                }}
                                value={frm.applicantContactName || ''}
                                error={this.getErrorFor('applicantContactName')}
                                selectOnBlur={false}
                              />
                            </span>
                          </Form.Field>
                        </Grid.Column>
                        <Grid.Column width={8}>
                          <Grid.Row>
                            <Grid.Column width={10}>
                              <CustomHeaderLabel2
                                message={translations['LexBilltoDetails']}
                              />
                              <span hidden={forReview}>
                                <br />
                                <br />
                                <i>{translations['LexPleaseReviewBillTo']}</i>
                              </span>
                              <span hidden={!forReview}>
                                <hr
                                  style={{
                                    width: '70%',
                                    color: 'gray',
                                    textAlign: 'left',
                                    margin: '0px',
                                  }}
                                />
                                <table>
                                  <tr>
                                    <td>
                                      <CustomLabel2
                                        message={
                                          translations['LexAccountNumber']
                                        }
                                      />
                                    </td>
                                    <td style={{ paddingLeft: '50px' }}>
                                      {frm.billToAccountNo
                                        ? frm.billToAccountNo
                                        : '--'}
                                    </td>
                                  </tr>
                                  <tr></tr>
                                  <tr>
                                    <td>
                                      <CustomLabel2
                                        message={translations['LexCompany']}
                                      />
                                    </td>
                                    <td style={{ paddingLeft: '50px' }}>
                                      {frm.billToCompany
                                        ? frm.billToCompany
                                        : '--'}
                                    </td>
                                  </tr>
                                  <tr></tr>
                                  <tr>
                                    <td>
                                      <CustomLabel2
                                        message={translations['LexAddress']}
                                      />
                                    </td>
                                    <td style={{ paddingLeft: '50px' }}>
                                      {frm.billToAddress
                                        ? frm.billToAddress
                                        : '--'}
                                    </td>
                                  </tr>
                                  <tr></tr>
                                  <tr>
                                    <td>
                                      <CustomLabel2
                                        message={translations['LexCountry']}
                                      />
                                    </td>
                                    <td style={{ paddingLeft: '50px' }}>
                                      {frm.billToCountry
                                        ? frm.billToCountry
                                        : '--'}
                                    </td>
                                  </tr>
                                  <tr></tr>
                                  <tr>
                                    <td>
                                      <CustomLabel2
                                        message={translations['LexContactName']}
                                      />
                                    </td>
                                    <td style={{ paddingLeft: '50px' }}>
                                      {frm.billToContactName
                                        ? frm.billToContactName
                                        : '--'}
                                    </td>
                                  </tr>
                                  <tr></tr>
                                  <tr>
                                    <td>
                                      <CustomLabel2
                                        message={
                                          translations['LexContactEmail']
                                        }
                                      />
                                    </td>
                                    <td style={{ paddingLeft: '50px' }}>
                                      {frm.billToContactEmail
                                        ? frm.billToContactEmail
                                        : '--'}
                                    </td>
                                  </tr>
                                </table>
                              </span>
                              <span
                                hidden={
                                  (!forReview &&
                                    data[activeIndex].labelOrderType === 2) ||
                                  data[activeIndex].labelOrderType !== 2
                                }
                              >
                                <br />
                                <br />
                                <Grid.Row>
                                  <Grid.Column
                                    width={3}
                                    style={{ marginTop: '10px' }}
                                  >
                                    <CustomHeaderLabel2
                                      message={
                                        translations[
                                          'LexAuthorizedLabelSupplierDetails'
                                        ]
                                      }
                                    />
                                    <hr
                                      style={{
                                        width: '70%',
                                        color: 'gray',
                                        textAlign: 'left',
                                        margin: '0px',
                                      }}
                                    />
                                  </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                  <table>
                                    <tr>
                                      <td>
                                        <CustomLabel2
                                          message={
                                            translations['LexFileNumber']
                                          }
                                        />
                                      </td>
                                      <td style={{ paddingLeft: '77px' }}>
                                        {frm.labelSupplierFileNumber
                                          ? frm.labelSupplierFileNumber
                                          : '--'}
                                      </td>
                                    </tr>
                                    <tr></tr>
                                    <tr>
                                      <td>
                                        <CustomLabel2
                                          message={translations['LexCompany']}
                                        />
                                      </td>
                                      <td style={{ paddingLeft: '77px' }}>
                                        {frm.labelSupplierName
                                          ? frm.labelSupplierName
                                          : '--'}
                                      </td>
                                    </tr>
                                    <tr></tr>
                                    <tr>
                                      <td>
                                        <CustomLabel2
                                          message={translations['LexAddress']}
                                        />
                                      </td>
                                      <td style={{ paddingLeft: '77px' }}>
                                        {frm.labelSupplierAddress
                                          ? frm.labelSupplierAddress
                                          : '--'}
                                      </td>
                                    </tr>
                                    <tr></tr>
                                    <tr>
                                      <td>
                                        <CustomLabel2
                                          message={translations['LexEmail']}
                                        />
                                      </td>
                                      <td style={{ paddingLeft: '77px' }}>
                                        {frm.labelSupplierEmailAddress
                                          ? frm.labelSupplierEmailAddress
                                          : '--'}
                                      </td>
                                    </tr>
                                  </table>
                                </Grid.Row>
                              </span>
                              <br />
                              <br />
                              <span hidden={!forReview}>
                                <CustomHeaderLabel2
                                  message={translations['LexOtherDetails']}
                                />
                                <hr
                                  style={{
                                    width: '70%',
                                    color: 'gray',
                                    textAlign: 'left',
                                    margin: '0px',
                                  }}
                                />
                                <table>
                                  <tr>
                                    <td>
                                      <CustomLabel2
                                        message={translations['LexLabelCenter']}
                                      />
                                    </td>
                                    <td style={{ paddingLeft: '50px' }}>
                                      {frm.labelCenterName
                                        ? frm.labelCenterName
                                        : '--'}
                                    </td>
                                  </tr>
                                  <tr></tr>
                                  <tr>
                                    <td style={{ width: '120px' }}>
                                      <CustomLabel2
                                        message={
                                          translations['LexPurchaseOrderNumber']
                                        }
                                      />
                                    </td>
                                    <td style={{ paddingLeft: '50px' }}>
                                      {frm.customerPoNumber
                                        ? frm.customerPoNumber
                                        : '--'}
                                    </td>
                                  </tr>
                                  <tr></tr>
                                  <tr
                                    hidden={
                                      data[activeIndex].labelOrderType === 2
                                    }
                                  >
                                    <td>
                                      <CustomLabel2
                                        message={
                                          translations['LexShippingOptions']
                                        }
                                      />
                                    </td>
                                    <td style={{ paddingLeft: '50px' }}>
                                      {frm.shippingOptionName
                                        ? frm.shippingOptionName
                                        : '--'}
                                    </td>
                                  </tr>
                                  <tr></tr>
                                  <tr
                                    hidden={
                                      frm.hideShippingDetails ||
                                      data[activeIndex].labelOrderType === 2
                                    }
                                  >
                                    <td>
                                      <CustomLabel2
                                        message={
                                          translations['LexShippingDetails']
                                        }
                                      />
                                    </td>
                                    <td style={{ paddingLeft: '50px' }}>
                                      {frm.shippingDetails
                                        ? frm.shippingDetails
                                        : '--'}
                                    </td>
                                  </tr>
                                  <tr></tr>
                                  <tr
                                    hidden={
                                      frm.hidecourierAccountNumber ||
                                      data[activeIndex].labelOrderType === 2
                                    }
                                  >
                                    <td style={{ width: '120px' }}>
                                      <CustomLabel2
                                        message={
                                          translations[
                                            'LexCourierAccountNumber'
                                          ]
                                        }
                                      />
                                    </td>
                                    <td style={{ paddingLeft: '50px' }}>
                                      {frm.courierAccountNumber
                                        ? frm.courierAccountNumber
                                        : '--'}
                                    </td>
                                  </tr>
                                  <tr></tr>
                                  <tr>
                                    <td>
                                      <CustomLabel2
                                        message={
                                          translations[
                                            'LexBrokerInformationwoOptinal'
                                          ]
                                        }
                                      />
                                    </td>
                                    <td style={{ paddingLeft: '50px' }}>
                                      {frm.brokerInformation
                                        ? frm.brokerInformation
                                        : '--'}
                                    </td>
                                  </tr>
                                </table>
                              </span>
                              <Dimmer
                                active={
                                  isLoadingRelation ||
                                  isLoadingRelationInformation
                                }
                                inverted
                              >
                                <Loader />
                              </Dimmer>
                              <Form.Field>
                                <span hidden={forReview}>
                                  <label style={{ fontWeight: 'bold' }}>
                                    {`* ${translations['LexAccountNumber']}`}{' '}
                                  </label>
                                  <Popup
                                    trigger={
                                      <Icon name="question circle outline" />
                                    }
                                    content={
                                      translations[
                                        'LexBilltoaccountisnotproperlydisplayed'
                                      ]
                                    }
                                    size="small"
                                  />
                                  <span hidden={frm.hasServiceContractData}>
                                    <Form.Field
                                      control={Dropdown}
                                      compact
                                      selection
                                      label=""
                                      placeholder={`${translations['LexSelect']}  ${translations['LexAccountNumber']}`}
                                      name="accntNumber"
                                      disabled={
                                        isLoading || frm.isBillToDetailsDisable
                                      }
                                      options={accountNumbersOptions}
                                      onChange={(e, properties) => {
                                        this.handleChangeLabelCenter(
                                          properties
                                        );
                                        this.handleAccntNumDorpDownChange(
                                          properties
                                        );
                                      }}
                                      value={frm.billToAccountNo || ''}
                                      error={this.getErrorFor(
                                        'billToAccountNo'
                                      )}
                                      selectOnBlur={false}
                                    />
                                  </span>
                                  <span hidden={!frm.hasServiceContractData}>
                                    <Form.Field
                                      control={Input}
                                      readOnly={
                                        !isCompanyEnable ||
                                        accountNumbersOptions
                                      }
                                      placeholder={`${translations['LexSelect']}  ${translations['LexAccountNumber']}`}
                                      name="accntNumber"
                                      onChange={this.handleChange}
                                      value={frm.billToAccountNo || ''}
                                      error={this.getErrorFor(
                                        'billToAccountNo'
                                      )}
                                      disabled={
                                        isLoading || frm.isBillToDetailsDisable
                                      }
                                    />
                                  </span>
                                </span>
                              </Form.Field>
                              <Form.Field></Form.Field>
                              <Form.Field>
                                <Divider fitted hidden />
                                <span hidden={forReview}>
                                  <Form.Field
                                    control={Input}
                                    label={`* ${translations['LexCompany']}`}
                                    readOnly={
                                      !isCompanyEnable || accountNumbersOptions
                                    }
                                    placeholder={translations['LexCompany']}
                                    name="billToCompany"
                                    onChange={this.handleChange}
                                    value={frm.billToCompany || ''}
                                    error={this.getErrorFor('billToCompany')}
                                    autoComplete="off"
                                    disabled={frm.isBillToDetailsDisable}
                                  />
                                </span>
                              </Form.Field>

                              <Form.Field>
                                <Divider fitted hidden />
                                <span hidden={forReview}>
                                  <Form.Field
                                    control={Input}
                                    label={`* ${translations['LexAddress']}`}
                                    readOnly={
                                      !isAddressEnable || accountNumbersOptions
                                    }
                                    placeholder={translations['LexAddress']}
                                    name="billToAddress"
                                    onChange={this.handleChange}
                                    value={frm.billToAddress || ''}
                                    error={this.getErrorFor('billToAddress')}
                                    autoComplete="off"
                                    disabled={frm.isBillToDetailsDisable}
                                  />
                                </span>
                              </Form.Field>
                              <Form.Field>
                                <Divider fitted hidden />
                                <span
                                  hidden={forReview || !isCustomerRelationEmpty}
                                >
                                  <Form.Field
                                    control={Dropdown}
                                    compact
                                    selectOnBlur={false}
                                    selection
                                    label="* Country"
                                    name="billToCountry"
                                    placeholder="Select Country"
                                    options={countryOptions}
                                    onChange={(e, properties) => {
                                      this.handleChangeLabelCenter(properties);
                                    }}
                                    value={frm.billToCountry || ''}
                                    error={this.getErrorFor(
                                      'billToCountry',
                                      'AD-ABTLD'
                                    )}
                                    disabled={frm.isBillToDetailsDisable}
                                    readOnly={
                                      !isAddressEnable || accountNumbersOptions
                                    }
                                  />
                                </span>
                                <span
                                  hidden={forReview || isCustomerRelationEmpty}
                                >
                                  <Form.Field
                                    control={Input}
                                    label={`* ${translations['LexCountry']}`}
                                    readOnly={
                                      !isAddressEnable || accountNumbersOptions
                                    }
                                    placeholder={translations['LexCountry']}
                                    name="billToCountry"
                                    value={frm.billToCountry || ''}
                                    error={this.getErrorFor('billToCountry')}
                                    autoComplete="off"
                                    disabled={frm.isBillToDetailsDisable}
                                  />
                                </span>
                              </Form.Field>
                              <Form.Field>
                                <span
                                  hidden={
                                    forReview || isBillToContactDropdownHidden
                                  }
                                >
                                  <Form.Field
                                    control={Dropdown}
                                    compact
                                    selection
                                    label={`* ${translations['LexContactName']}`}
                                    disabled={
                                      !frm.billToAccountNo ||
                                      frm.hasCompleteServiceContractData
                                    }
                                    name="billToContactName"
                                    placeholder={`${translations['LexSelect']}  ${translations['LexContactName']}`}
                                    options={
                                      customerRelationInformationsOptions
                                    }
                                    onChange={(e, properties) => {
                                      this.handleChangeLabelCenter(properties);
                                      // this.handleCustomerInfoDorpDownChange(
                                      //   properties.value
                                      // );
                                    }}
                                    value={frm.billToContactName || ''}
                                    error={this.getErrorFor(
                                      'billToContactName',
                                      'AD-ABTLD'
                                    )}
                                    selectOnBlur={false}
                                  />
                                </span>
                                <span
                                  hidden={
                                    forReview || !isBillToContactDropdownHidden
                                  }
                                >
                                  <Form.Field
                                    control={Input}
                                    label={`* ${translations['LexContactName']}`}
                                    placeholder={translations['LexContactName']}
                                    name="billToContactName"
                                    onChange={this.handleChange}
                                    value={frm.billToContactName || ''}
                                    error={this.getErrorFor(
                                      'billToContactName'
                                    )}
                                    autoComplete="off"
                                    disabled={frm.isBillToDetailsDisable}
                                    readOnly={
                                      !isAddressEnable || accountNumbersOptions
                                    }
                                  />
                                </span>
                              </Form.Field>

                              <Form.Field>
                                <Divider fitted hidden />
                                <span hidden={forReview}>
                                  <Form.Field
                                    control={Input}
                                    label={`* ${translations['LexContactEmail']} `}
                                    disabled={
                                      !frm.billToAccountNo ||
                                      frm.isBillToEmailDisable
                                    }
                                    readOnly={isReadOnlyBillToEmail}
                                    placeholder={
                                      translations['LexContactEmail']
                                    }
                                    name="billToContactEmail"
                                    onChange={this.handleChange}
                                    value={frm.billToContactEmail || ''}
                                    error={this.getErrorFor(
                                      'billToContactEmail'
                                    )}
                                    autoComplete="off"
                                  />
                                </span>
                              </Form.Field>
                            </Grid.Column>
                          </Grid.Row>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <table
                          hidden={!forReview}
                          width={'100%'}
                          style={{ borderSpacing: '0px' }}
                        >
                          <tr
                            style={{
                              height: '10px',
                              backgroundColor: '#488EC1',
                            }}
                          >
                            <td>
                              <label style={{ color: '#488EC1' }}>.</label>
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                backgroundColor: '#F5F5F5',
                                padding: '15px',
                                color: 'blue',
                                fontWeight: 'bold',
                              }}
                            >
                              {
                                translations[
                                  'LexCommentsInstructionswoOptional'
                                ]
                              }
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                height: '100px',
                                backgroundColor: '#F5F5F5',
                                padding: '15px',
                                overflowWrap: 'break-word',
                                whiteSpace: 'pre-wrap',
                                verticalAlign: 'top',
                                overflowX: 'auto',
                              }}
                            >
                              <CustomHeaderLabelRelaxed
                                message={
                                  hasSameBillToAccountNumber
                                    ? concatenatedComments
                                      ? concatenatedComments
                                      : '--'
                                    : frm.comments
                                    ? frm.comments
                                    : '--'
                                }
                              />
                            </td>
                          </tr>
                        </table>
                      </Grid.Row>
                      <table hidden={forReview} width="100%">
                        <tr>
                          <td
                            width="50%"
                            style={{
                              verticalAlign: 'top',
                            }}
                          >
                            <span hidden={forReview}>
                              <CustomHeaderLabel2
                                message={translations['LexManufacturerDetails']}
                              />
                              <br />
                              <br />
                              <i>{translations['LexPleaseReviewShipTo']}</i>
                              <br />
                              <br />
                            </span>
                            <Dimmer
                              active={
                                isLoadingRelation ||
                                isLoadingRelationInformation
                              }
                              inverted
                            >
                              <Loader />
                            </Dimmer>
                            <Form.Field>
                              <span hidden={forReview}>
                                <span
                                  hidden={
                                    frm.manufacturerAccountNumbers.length === 0
                                  }
                                >
                                  <Form.Field
                                    control={Dropdown}
                                    compact
                                    selection
                                    selectOnBlur={false}
                                    label={`* ${translations['LexAccountNumber']}`}
                                    placeholder={`${
                                      translations['LexSelect']
                                    } ${' '} ${
                                      translations['LexAccountNumber']
                                    } `}
                                    name="manufacturerAccountNumber"
                                    disabled={isLoading || frm.isBillToDisable}
                                    options={frm.manufacturerAccountNumbers}
                                    onChange={(e, properties) => {
                                      this.handleChangeLabelCenter(properties);
                                      this.handleAccntNumShipToDropDownChange(
                                        properties.value
                                      );
                                    }}
                                    value={frm.manufacturerAccountNumber || ''}
                                    error={this.getErrorFor(
                                      'manufacturerAccountNumber'
                                    )}
                                  />
                                </span>
                                <span
                                  hidden={
                                    frm.manufacturerAccountNumbers.length > 0
                                  }
                                >
                                  <Form.Field
                                    control={Input}
                                    label={`* ${translations['LexAccountNumber']}`}
                                    placeholder={`${translations['LexSelect']}  ${translations['LexAccountNumber']}`}
                                    name="manufacturerAccountNumber"
                                    onChange={this.handleChange}
                                    disabled={isLoading || frm.isBillToDisable}
                                    value={frm.manufacturerAccountNumber || ''}
                                    error={this.getErrorFor(
                                      'manufacturerAccountNumber'
                                    )}
                                    autoComplete="off"
                                  />
                                </span>
                              </span>
                            </Form.Field>
                            <Form.Field>
                              <span hidden={forReview}>
                                <Form.Field
                                  control={Input}
                                  label={`* Party Site Number`}
                                  disabled={isLoading || frm.isBillToDisable}
                                  name="manufacturerPartySiteNumber"
                                  value={frm.manufacturerPartySiteNumber || ''}
                                  autoComplete="off"
                                />
                              </span>
                            </Form.Field>
                            <Form.Field>
                              <span hidden={forReview}>
                                <Form.Field
                                  control={Input}
                                  label={`* ${translations['LexCompany']}`}
                                  readOnly={
                                    !isCompanyShipToEnable ||
                                    frm.isManuNameReadOnly ||
                                    frm.manufacturerAccountNumbers
                                  }
                                  placeholder={translations['LexCompany']}
                                  name="manufacturerName"
                                  onChange={this.handleChange}
                                  value={frm.manufacturerName || ''}
                                  error={this.getErrorFor('manufacturerName')}
                                  autoComplete="off"
                                  disabled={isLoading || frm.isBillToDisable}
                                />
                              </span>
                            </Form.Field>
                            <Form.Field>
                              <Divider fitted hidden />
                              <span hidden={forReview}>
                                <Form.Field
                                  control={Input}
                                  label={`* ${translations['LexAddress']}`}
                                  readOnly={
                                    !isAddressShipToEnable ||
                                    frm.isManuAddReadOnly ||
                                    frm.manufacturerAccountNumbers
                                  }
                                  placeholder={translations['LexAddress']}
                                  name="manufacturerAddress"
                                  onChange={this.handleChange}
                                  value={frm.manufacturerAddress || ''}
                                  error={this.getErrorFor(
                                    'manufacturerAddress'
                                  )}
                                  autoComplete="off"
                                  disabled={isLoading || frm.isBillToDisable}
                                />
                              </span>
                            </Form.Field>
                            <Form.Field>
                              <Divider fitted hidden />
                              <span
                                hidden={
                                  forReview ||
                                  (frm.manufacturerAccountNumbers &&
                                    frm.manufacturerAccountNumbers.length > 0)
                                }
                              >
                                <Form.Field
                                  control={Dropdown}
                                  compact
                                  selectOnBlur={false}
                                  selection
                                  label="* Country"
                                  placeholder="Select Country"
                                  name="shipToCountry"
                                  options={countryOptions}
                                  onChange={(e, properties) => {
                                    this.handleChangeLabelCenter(properties);
                                  }}
                                  value={frm.shipToCountryCode}
                                  error={this.getErrorFor('shipToCountry')}
                                  disabled={isLoading || frm.isBillToDisable}
                                  readOnly={
                                    !isAddressShipToEnable ||
                                    frm.isManuAddReadOnly ||
                                    frm.manufacturerAccountNumbers
                                  }
                                />
                              </span>
                              <span
                                hidden={
                                  forReview ||
                                  !frm.manufacturerAccountNumbers ||
                                  frm.manufacturerAccountNumbers.length === 0
                                }
                              >
                                <Form.Field
                                  control={Input}
                                  label={`* ${translations['LexCountry']}`}
                                  readOnly={
                                    !isAddressShipToEnable ||
                                    frm.isManuAddReadOnly ||
                                    frm.manufacturerAccountNumbers
                                  }
                                  placeholder={translations['LexCountry']}
                                  name="shipToCountry"
                                  value={frm.shipToCountry || ''}
                                  error={this.getErrorFor('shipToCountry')}
                                  autoComplete="off"
                                  disabled={isLoading || frm.isBillToDisable}
                                />
                              </span>
                            </Form.Field>
                            <Form.Field>
                              <Divider fitted hidden />
                              <span
                                hidden={
                                  forReview ||
                                  (customerRelationInformationsShiptToOptions &&
                                    customerRelationInformationsShiptToOptions.length !==
                                      0)
                                }
                              >
                                <Form.Field
                                  control={Input}
                                  label={`* ${translations['LexContactName']}`}
                                  placeholder={translations['LexContactName']}
                                  name="shipToContactName"
                                  onChange={this.handleChange}
                                  value={frm.shipToContactName || ''}
                                  error={this.getErrorFor('shipToContactName')}
                                  autoComplete="off"
                                  disabled={isLoading || frm.isBillToDisable}
                                  readOnly={
                                    !isAddressShipToEnable ||
                                    frm.isManuAddReadOnly ||
                                    frm.manufacturerAccountNumbers
                                  }
                                />
                              </span>
                            </Form.Field>
                            <Form.Field>
                              <span
                                hidden={
                                  forReview ||
                                  customerRelationInformationsShiptToOptions ==
                                    null ||
                                  (customerRelationInformationsShiptToOptions &&
                                    customerRelationInformationsShiptToOptions.length ===
                                      0)
                                }
                              >
                                <Form.Field
                                  control={Dropdown}
                                  compact
                                  selection
                                  label={`* ${translations['LexContactName']}`}
                                  placeholder={`${translations['LexSelect']}  ${translations['LexContactName']}`}
                                  name="shipToContactName"
                                  disabled={
                                    !frm.manufacturerAccountNumber ||
                                    frm.isBillToDisable
                                  }
                                  options={
                                    customerRelationInformationsShiptToOptions
                                  }
                                  onChange={(e, properties) => {
                                    this.handleChangeLabelCenter(properties);
                                  }}
                                  value={frm.shipToContactName || ''}
                                  error={this.getErrorFor('shipToContactName')}
                                  selectOnBlur={false}
                                />
                              </span>
                            </Form.Field>
                            <Form.Field>
                              <span hidden={forReview}>
                                <Form.Field
                                  control={Input}
                                  label={`* ${translations['LexContactEmail']} `}
                                  placeholder={translations['LexContactEmail']}
                                  name="customerEmailAddress"
                                  onChange={this.handleChange}
                                  value={frm.customerEmailAddress || ''}
                                  error={this.getErrorFor(
                                    'customerEmailAddress'
                                  )}
                                  autoComplete="off"
                                  disabled={isLoading || frm.isBillToDisable}
                                  readOnly={isReadOnlyShipToEmail}
                                />
                              </span>
                            </Form.Field>
                            <Divider fitted hidden />
                            <Form.Field>
                              <Divider fitted hidden />
                              <span hidden={forReview}>
                                <Form.Field
                                  control={Input}
                                  label={`* ${translations['LexPhoneNumber']}`}
                                  placeholder={translations['LexPhoneNumber']}
                                  name="customerPhoneNumber"
                                  onChange={this.handleChange}
                                  value={frm.customerPhoneNumber || ''}
                                  error={this.getErrorFor(
                                    'customerPhoneNumber'
                                  )}
                                  autoComplete="off"
                                  disabled={isLoading || frm.isBillToDisable}
                                />
                              </span>
                            </Form.Field>
                          </td>
                          <td
                            style={{
                              paddingLeft: '20px',
                              paddingTop: '0px',
                              verticalAlign: 'top',
                            }}
                          >
                            <span hidden={forReview}>
                              <Grid.Row style={{ paddingTop: '0px' }}>
                                <Grid.Column width={8}>
                                  <CustomHeaderLabel2
                                    message={translations['LexOtherDetails']}
                                  />
                                  <br />
                                  <br />
                                  <Form.Field>
                                    <span hidden={forReview}>
                                      <Divider fitted hidden />
                                      <Form.Field
                                        control={Dropdown}
                                        label={`* ${translations['LexLabelCenter']}`}
                                        placeholder={
                                          translations['LexLabelCenter']
                                        }
                                        name="labelCenter"
                                        fluid
                                        selection
                                        options={labelCenters || []}
                                        disabled={
                                          !labelCenters ||
                                          labelCenters.length === 0 ||
                                          frm.shipToCountry
                                        }
                                        onChange={(e, properties) => {
                                          this.handleChangeLabelCenter(
                                            properties
                                          );
                                        }}
                                        value={frm.labelCenter}
                                        error={this.getErrorFor('labelCenter')}
                                      />
                                    </span>
                                  </Form.Field>
                                  <Form.Field>
                                    <span hidden={forReview}>
                                      <Form.Field
                                        control={Input}
                                        label={`* ${translations['LexPurchaseOrderNumber']}`}
                                        placeholder={
                                          translations[
                                            'LexPleasedonotuseduplicatedPONo'
                                          ]
                                        }
                                        index={index}
                                        name="customerPoNumber"
                                        onChange={this.handleChange}
                                        value={frm.customerPoNumber || ''}
                                        error={this.getErrorFor(
                                          'customerPoNumber'
                                        )}
                                        autoComplete="off"
                                        disabled={
                                          isLoading || frm.isOtherDetailsDisable
                                        }
                                        maxLength={29}
                                      />
                                    </span>
                                  </Form.Field>

                                  <Form.Field>
                                    <span hidden={forReview}>
                                      <Divider fitted hidden />
                                      <Form.Field
                                        control={Dropdown}
                                        label={`* ${translations['LexShippingOptions']}`}
                                        placeholder={
                                          translations['LexShippingOptions']
                                        }
                                        name="shippingOption"
                                        fluid
                                        selection
                                        options={shippingOptions || []}
                                        disabled={
                                          frm.labelCenterName === 'Japan' ||
                                          frm.labelCenterName ===
                                            'South Korea' ||
                                          frm.labelCenterName === 'Europe' ||
                                          frm.labelCenterName === 'China' ||
                                          frm.labelCenterName === 'Taiwan' ||
                                          frm.shipToCountry === 'CHINA' ||
                                          (shippingOptions &&
                                            shippingOptions.length === 0) ||
                                          frm.isOtherDetailsDisable ||
                                          (frm.labelCenterName
                                            .trim()
                                            .toLowerCase() !==
                                            'north america' &&
                                            frm.labelCenterName
                                              .trim()
                                              .toLowerCase() !==
                                              'latin america') ||
                                          data[activeIndex].labelOrderType === 2
                                        }
                                        onChange={(e, properties) => {
                                          this.handleChangeShippingOption(
                                            properties
                                          );
                                        }}
                                        value={frm.shippingOption}
                                        error={this.getErrorFor(
                                          'shippingOption'
                                        )}
                                        selectOnBlur={false}
                                      />
                                    </span>
                                  </Form.Field>
                                  <Form.Field>
                                    <Divider fitted hidden />
                                    <span hidden={forReview}>
                                      <span hidden={frm.hideShippingDetails}>
                                        <Form.Field
                                          control={Input}
                                          label={
                                            translations['LexShippingDetails']
                                          }
                                          placeholder={
                                            translations['LexShippingDetails']
                                          }
                                          name="shippingDetails"
                                          onChange={this.handleChange}
                                          value={frm.shippingDetails || ''}
                                          error={this.getErrorFor(
                                            'shippingDetails'
                                          )}
                                          autoComplete="off"
                                          disabled={
                                            isLoading ||
                                            frm.isOtherDetailsDisable ||
                                            (frm.labelCenterName
                                              .trim()
                                              .toLowerCase() !==
                                              'north america' &&
                                              frm.labelCenterName
                                                .trim()
                                                .toLowerCase() !==
                                                'latin america') ||
                                            frm.labelCenterName === 'Japan' ||
                                            frm.labelCenterName ===
                                              'South Korea' ||
                                            frm.labelCenterName === 'Europe' ||
                                            frm.labelCenterName === 'China' ||
                                            frm.shipToCountry === 'CHINA' ||
                                            data[activeIndex].labelOrderType ===
                                              2
                                          }
                                        />
                                      </span>
                                      <Divider fitted hidden />
                                      <span
                                        hidden={frm.hidecourierAccountNumber}
                                      >
                                        <Form.Field
                                          control={Input}
                                          label={
                                            translations[
                                              'LexCourierAccountNumber'
                                            ]
                                          }
                                          placeholder={
                                            translations[
                                              'LexCourierAccountNumber'
                                            ]
                                          }
                                          name="courierAccountNumber"
                                          disabled={
                                            frm.labelCenterName === 'Japan' ||
                                            frm.labelCenterName ===
                                              'South Korea' ||
                                            frm.labelCenterName === 'Europe' ||
                                            frm.labelCenterName === 'China' ||
                                            frm.shipToCountry === 'CHINA' ||
                                            (shippingOptions &&
                                              shippingOptions.length === 0) ||
                                            frm.isOtherDetailsDisable ||
                                            (frm.labelCenterName
                                              .trim()
                                              .toLowerCase() !==
                                              'north america' &&
                                              frm.labelCenterName
                                                .trim()
                                                .toLowerCase() !==
                                                'latin america') ||
                                            data[activeIndex].labelOrderType ===
                                              2
                                          }
                                          onChange={this.handleChange}
                                          value={frm.courierAccountNumber || ''}
                                          error={this.getErrorFor(
                                            'courierAccountNumber'
                                          )}
                                          autoComplete="off"
                                        />
                                      </span>
                                    </span>
                                  </Form.Field>
                                  <Form.Field>
                                    <Divider fitted hidden />
                                    <span hidden={forReview}>
                                      <Form.Input
                                        label={
                                          translations['LexBrokerInformation']
                                        }
                                        placeholder={
                                          translations['LexBrokerInformation']
                                        }
                                        name="brokerInformation"
                                        onChange={this.handleChange}
                                        value={frm.brokerInformation || ''}
                                        error={this.getErrorFor(
                                          'brokerInformation'
                                        )}
                                        autoComplete="off"
                                        disabled={
                                          isLoading || frm.isOtherDetailsDisable
                                        }
                                      />
                                    </span>
                                  </Form.Field>
                                  <Form.Field>
                                    <Divider fitted hidden />
                                    <span hidden={forReview}>
                                      <Form.Field
                                        control={TextArea}
                                        label={
                                          translations[
                                            'LexCommentsInstructions'
                                          ]
                                        }
                                        placeholder={
                                          translations[
                                            'LexCommentsInstructions'
                                          ]
                                        }
                                        name="comments"
                                        onChange={this.handleChange}
                                        value={frm.comments || ''}
                                        error={this.getErrorFor('comments')}
                                        autoComplete="off"
                                        length="256"
                                        disabled={
                                          isLoading || frm.isCommentsDisable
                                        }
                                      />
                                    </span>
                                  </Form.Field>
                                </Grid.Column>
                              </Grid.Row>
                            </span>
                          </td>
                        </tr>
                      </table>
                      {/* <Grid.Row>
                        <Grid.Column width={12}></Grid.Column>
                      </Grid.Row> */}
                    </Grid>
                  </Segment>
                )}
                {data &&
                  data.length > 0 &&
                  data[activeIndex].carts.map((item, idx) => {
                    return (
                      <div style={{ width: '100%' }}>
                        <table
                          style={{
                            padding: '20px',
                            border: '0px',
                            margin: '0px',
                            width: '100%',
                          }}
                        >
                          <Accordion>
                            <Accordion.Title
                              active={accordIndexes.includes(idx)}
                              index={idx}
                              onClick={this.handleClick}
                            >
                              <label
                                style={{
                                  color: 'gray',
                                  fontSize: '17px',
                                  fontWeight: 'normal',
                                }}
                              >
                                {translations['LexFileNumber']}
                                {': '}
                              </label>
                              <label
                                style={{
                                  color: 'gray',
                                  fontSize: '17px',
                                  fontWeight: 'bold',
                                }}
                              >
                                {item.fileNumber}
                                {'(Vol. '}
                                {item.volume}
                                {')'}
                              </label>
                              <Icon name="dropdown" />
                            </Accordion.Title>
                            <Accordion.Content
                              active={accordIndexes.includes(idx)}
                            >
                              <table style={{ width: '100%' }}>
                                {item.cartItems &&
                                  item.cartItems.map((cartItem, index) => {
                                    return !forReview ? (
                                      <Table.Row
                                        key={index}
                                        style={{
                                          backgroundColor: '#FAFAFA',
                                          padding: '20px',
                                          boxShadow: 'inset 0 0 0 10px white',
                                        }}
                                      >
                                        <Table.Cell
                                          style={{
                                            textAlign: 'center',
                                            border: '0 px',
                                            padding: '10px',
                                          }}
                                        >
                                          <span hidden={forReview}>
                                            <Checkbox
                                              style={{
                                                border: '3px solid black',
                                                padding: '0px',
                                                height: '23px',
                                                width: '23px',
                                              }}
                                              checked={cartItem.isSelected}
                                              onChange={(e, { checked }) => {
                                                this.props.selectLabel(
                                                  cartItem,
                                                  checked,
                                                  activeIndex
                                                );
                                              }}
                                            />
                                          </span>
                                        </Table.Cell>
                                        <span
                                          hidden={
                                            data[activeIndex].labelOrderType ===
                                            2
                                          }
                                        >
                                          <Table.Cell
                                            style={{
                                              textAlign: 'center',
                                              padding: '20px',
                                            }}
                                          >
                                            <Image
                                              size="medium"
                                              src={cartItem.imageUrl}
                                            />
                                          </Table.Cell>
                                        </span>
                                        <Table.Cell
                                          style={{
                                            color: 'gray',
                                            padding: '20px',
                                          }}
                                        >
                                          <table>
                                            <tr>
                                              <td
                                                style={{
                                                  paddingRight: '20px',
                                                  width:
                                                    data[activeIndex]
                                                      .labelOrderType === 1
                                                      ? '550px'
                                                      : '750px',
                                                  borderRight: 'solid 1px gray',
                                                }}
                                              >
                                                <table
                                                  style={{
                                                    paddingLeft: '20px',
                                                    paddingRight: '20px',
                                                  }}
                                                >
                                                  <tr>
                                                    <td
                                                      style={{
                                                        fontSize: '14px',
                                                        fontWeight: 'bold',
                                                        textAlign: 'top',
                                                      }}
                                                    >
                                                      {cartItem.ccn}
                                                    </td>
                                                    <td
                                                      style={{
                                                        textAlign: 'left',
                                                      }}
                                                    >
                                                      {cartItem.labelAccount}
                                                    </td>
                                                  </tr>
                                                  <br />
                                                  <tr>
                                                    <td
                                                      style={{
                                                        fontSize: '14px',
                                                        paddingRight: '20px',
                                                        fontWeight: 'bold',
                                                      }}
                                                    >
                                                      {
                                                        translations[
                                                          'LexULItemNumber'
                                                        ]
                                                      }
                                                    </td>
                                                    <td>
                                                      {cartItem.itemNumber}
                                                    </td>
                                                  </tr>
                                                  <br />
                                                  <tr
                                                    hidden={
                                                      data[activeIndex]
                                                        .labelOrderType === 1
                                                    }
                                                  >
                                                    <td
                                                      style={{
                                                        fontSize: '14px',
                                                        fontWeight: 'bold',
                                                      }}
                                                    >
                                                      {
                                                        translations[
                                                          'LexMLALListeeFileNo'
                                                        ]
                                                      }
                                                      :{' '}
                                                      <Popup
                                                        trigger={
                                                          <span
                                                            style={{
                                                              color: '#80B0D2',
                                                            }}
                                                          >
                                                            <Icon name="exclamation circle" />
                                                          </span>
                                                        }
                                                        content={
                                                          translations[
                                                            'lexMultipleListeeAlternateListee'
                                                          ]
                                                        }
                                                        size="small"
                                                      />
                                                    </td>
                                                    <td>
                                                      {cartItem.listeeFileNumber
                                                        ? cartItem.listeeFileNumber
                                                        : '--'}
                                                    </td>
                                                  </tr>
                                                  <span
                                                    hidden={
                                                      data[activeIndex]
                                                        .labelOrderType === 1
                                                    }
                                                  >
                                                    {' '}
                                                    <br />
                                                  </span>
                                                  <tr
                                                    hidden={
                                                      data[activeIndex]
                                                        .labelOrderType === 1
                                                    }
                                                  >
                                                    <td
                                                      style={{
                                                        fontSize: '14px',
                                                        paddingRight: '20px',
                                                        fontWeight: 'bold',
                                                      }}
                                                    >
                                                      <label>
                                                        {
                                                          translations[
                                                            'LexCustomerPartNo'
                                                          ]
                                                        }
                                                        .{' '}
                                                      </label>
                                                    </td>
                                                    <td>
                                                      {cartItem.customerPartNo}
                                                    </td>
                                                  </tr>
                                                </table>
                                              </td>
                                              <td
                                                style={{
                                                  paddingLeft: '20px',
                                                }}
                                              >
                                                <table>
                                                  <tr>
                                                    <table>
                                                      <tr
                                                        style={{
                                                          paddingTop: '10px',
                                                        }}
                                                      >
                                                        <td
                                                          style={{
                                                            paddingRight:
                                                              '10px',
                                                            fontSize: '14px',
                                                            fontWeight: 'bold',
                                                            color: 'gray',
                                                          }}
                                                        >
                                                          {
                                                            translations[
                                                              'LexQuantity'
                                                            ]
                                                          }
                                                        </td>
                                                        <td>
                                                          <span
                                                            hidden={forReview}
                                                          >
                                                            <input
                                                              placeholder={
                                                                translations[
                                                                  'LexQuantity'
                                                                ]
                                                              }
                                                              onKeyDown={e => {
                                                                if (
                                                                  e.keyCode ===
                                                                    8 ||
                                                                  e.keyCode ===
                                                                    46
                                                                ) {
                                                                  return false;
                                                                }
                                                              }}
                                                              onChange={e => {
                                                                this.handleChangeQuantity(
                                                                  e,
                                                                  cartItem
                                                                );
                                                              }}
                                                              maxLength="5"
                                                              step={
                                                                cartItem.minOrderQuantity
                                                              }
                                                              min={
                                                                cartItem.minOrderQuantity
                                                              }
                                                              type="number"
                                                              value={
                                                                cartItem.quantity
                                                              }
                                                              style={{
                                                                fontSize:
                                                                  '0.8em',
                                                                width: '130px',
                                                              }}
                                                            />
                                                          </span>
                                                          <span
                                                            hidden={!forReview}
                                                          >
                                                            <SubLabel
                                                              message={nf.format(
                                                                cartItem.quantity
                                                              )}
                                                            />
                                                          </span>
                                                        </td>
                                                        <span
                                                          hidden={
                                                            data[activeIndex]
                                                              .labelOrderType ===
                                                            1
                                                          }
                                                        >
                                                          <td
                                                            style={{
                                                              textAlign:
                                                                'center',
                                                              paddingTop: '5px',
                                                            }}
                                                          >
                                                            {
                                                              translations[
                                                                'LexLabels'
                                                              ]
                                                            }
                                                          </td>
                                                        </span>
                                                        <td>
                                                          <span
                                                            hidden={
                                                              data[activeIndex]
                                                                .labelOrderType ===
                                                              2
                                                            }
                                                          >
                                                            <label
                                                              style={{
                                                                padding: '20px',
                                                                fontSize:
                                                                  '12px',
                                                                fontWeight:
                                                                  'bold',
                                                                color: 'gray',
                                                              }}
                                                            >
                                                              {cartItem.oracleUnitOfMeasurementName ===
                                                              'RL'
                                                                ? translations[
                                                                    'LexRL'
                                                                  ]
                                                                : translations[
                                                                    'LexEa'
                                                                  ]}
                                                            </label>
                                                          </span>
                                                        </td>
                                                      </tr>
                                                    </table>
                                                  </tr>
                                                  <tr>
                                                    <table>
                                                      <span
                                                        hidden={
                                                          data[activeIndex]
                                                            .labelOrderType ===
                                                          2
                                                        }
                                                      >
                                                        <tr>
                                                          <i
                                                            class="info circle icon"
                                                            style={{
                                                              color: 'blue',
                                                            }}
                                                          ></i>
                                                          <SubLabel
                                                            message={
                                                              translations[
                                                                'LexLabelPackageDesc'
                                                              ]
                                                            }
                                                          />
                                                          <br />
                                                        </tr>
                                                      </span>
                                                      <tr>
                                                        <td
                                                          style={{
                                                            paddingLeft: '20px',
                                                          }}
                                                        >
                                                          <SubLabel
                                                            message={this.renderLabelOrderPackage(
                                                              cartItem.labelOrderPackage
                                                            )}
                                                          />
                                                        </td>
                                                      </tr>
                                                    </table>
                                                  </tr>
                                                  <br />
                                                  <span>
                                                    <Button
                                                      disabled={
                                                        !cartItem.isSelected
                                                      }
                                                      style={{
                                                        backgroundColor:
                                                          'white',
                                                        color: 'red',
                                                        border: '1px solid',
                                                      }}
                                                      class="large trash alternate icon"
                                                      onClick={() =>
                                                        this.handleRemoveLabel(
                                                          cartItem.id
                                                        )
                                                      }
                                                    >
                                                      <Icon name="trash"></Icon>
                                                      {
                                                        translations[
                                                          'LexDeleteLabel'
                                                        ]
                                                      }
                                                    </Button>
                                                  </span>
                                                </table>
                                              </td>
                                            </tr>
                                            <span
                                              hidden={
                                                data[activeIndex]
                                                  .labelOrderType === 1
                                              }
                                            >
                                              {' '}
                                              <br />
                                            </span>
                                            <tr>
                                              <td
                                                style={{ paddingLeft: '20px' }}
                                                colSpan={5}
                                              >
                                                <label
                                                  style={{
                                                    border: '0px',
                                                    fontSize: '14px',
                                                    fontWeight: 'bold',
                                                  }}
                                                >
                                                  {
                                                    translations[
                                                      'LexItemDescription'
                                                    ]
                                                  }
                                                  :
                                                </label>
                                                <div
                                                  style={{
                                                    overflowWrap: 'break-word',
                                                    whiteSpace: 'pre-wrap',
                                                    fontSize: '12px',
                                                    paddingBottom: '20px',
                                                  }}
                                                >
                                                  {cartItem.description.replace(
                                                    /\s\s+/g,
                                                    ' '
                                                  )}
                                                </div>
                                              </td>
                                            </tr>
                                          </table>
                                        </Table.Cell>
                                      </Table.Row>
                                    ) : (
                                      cartItem.isSelected === true && (
                                        <Table.Row
                                          key={index}
                                          style={{
                                            backgroundColor: '#FAFAFA',
                                            padding: '20px',
                                            boxShadow: 'inset 0 0 0 10px white',
                                          }}
                                        >
                                          <table
                                            width="100%"
                                            hidden={
                                              data[activeIndex]
                                                .labelOrderType === 1
                                            }
                                          >
                                            <tr>
                                              <td width="100%">
                                                <table
                                                  style={{
                                                    padding: '20px',
                                                    margin: '0px',
                                                  }}
                                                >
                                                  <tr
                                                    style={{
                                                      border: '0px',
                                                      fontSize: '14px',
                                                      fontWeight: 'bold',
                                                    }}
                                                  >
                                                    <td
                                                      style={{
                                                        paddingLeft: '80px',
                                                        borderRight:
                                                          '1px solid gray',
                                                        width: '600px',
                                                      }}
                                                    >
                                                      <a
                                                        style={{
                                                          border: '0px',
                                                          fontSize: '14px',
                                                          fontWeight: 'bold',
                                                          color: '#63A8DB',
                                                          cursor:
                                                            cartItem.imageUrl
                                                              ? 'pointer'
                                                              : 'default',
                                                        }}
                                                        href={cartItem.imageUrl}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                      >
                                                        {' '}
                                                        {
                                                          translations[
                                                            'LexArtworkLink'
                                                          ]
                                                        }
                                                      </a>
                                                      <br />
                                                      <br />
                                                      <label
                                                        style={{
                                                          border: '0px',
                                                          fontSize: '14px',
                                                          fontWeight: 'bold',
                                                          color: 'gray',
                                                        }}
                                                      >
                                                        {
                                                          translations[
                                                            'LexItemDescription'
                                                          ]
                                                        }
                                                        :
                                                      </label>
                                                      <br />
                                                      <div
                                                        style={{
                                                          overflowWrap:
                                                            'break-word',
                                                          whiteSpace:
                                                            'pre-wrap',
                                                          fontSize: '12px',
                                                          color: 'gray',
                                                          paddingRight: '30px',
                                                        }}
                                                      >
                                                        {cartItem.description.replace(
                                                          /\s\s+/g,
                                                          ' '
                                                        )}
                                                      </div>
                                                    </td>
                                                    <td>
                                                      <table>
                                                        <tr
                                                          style={{
                                                            padding: '20px',
                                                          }}
                                                        >
                                                          <td
                                                            style={{
                                                              fontSize: '14px',
                                                              fontWeight:
                                                                'bold',
                                                              color: 'gray',
                                                              paddingLeft:
                                                                '20px',
                                                            }}
                                                          >
                                                            {
                                                              translations[
                                                                'LexULItemNumber'
                                                              ]
                                                            }
                                                          </td>
                                                          <td
                                                            style={{
                                                              fontSize: '12px',
                                                              color: 'gray',
                                                            }}
                                                          >
                                                            {
                                                              cartItem.itemNumber
                                                            }
                                                          </td>
                                                        </tr>
                                                        <br />
                                                        <tr>
                                                          <td
                                                            style={{
                                                              fontSize: '14px',
                                                              fontWeight:
                                                                'bold',
                                                              color: 'gray',
                                                              paddingLeft:
                                                                '20px',
                                                            }}
                                                          >
                                                            {
                                                              translations[
                                                                'LexQuantity'
                                                              ]
                                                            }
                                                          </td>
                                                          <td
                                                            style={{
                                                              fontSize: '12px',
                                                              color: 'gray',
                                                            }}
                                                          >
                                                            {nf.format(
                                                              cartItem.quantity
                                                            )}{' '}
                                                            {
                                                              translations[
                                                                'LexLabels'
                                                              ]
                                                            }
                                                          </td>
                                                        </tr>
                                                        <br />
                                                        <tr>
                                                          <td
                                                            style={{
                                                              fontSize: '14px',
                                                              fontWeight:
                                                                'bold',
                                                              color: 'gray',
                                                              paddingLeft:
                                                                '20px',
                                                            }}
                                                          >
                                                            {
                                                              translations[
                                                                'LexMLALListeeFileNo'
                                                              ]
                                                            }
                                                          </td>
                                                          <td
                                                            style={{
                                                              fontSize: '12px',
                                                              color: 'gray',
                                                            }}
                                                          >
                                                            {cartItem.listeeFileNumber
                                                              ? cartItem.listeeFileNumber
                                                              : '--'}
                                                          </td>
                                                        </tr>
                                                        <br />
                                                        <tr>
                                                          <td
                                                            style={{
                                                              fontSize: '14px',
                                                              fontWeight:
                                                                'bold',
                                                              color: 'gray',
                                                              paddingRight:
                                                                '20px',
                                                              paddingLeft:
                                                                '20px',
                                                            }}
                                                          >
                                                            {
                                                              translations[
                                                                'LexCustomerPartNo'
                                                              ]
                                                            }
                                                            :
                                                          </td>
                                                          <td
                                                            style={{
                                                              fontSize: '12px',
                                                              color: 'gray',
                                                            }}
                                                          >
                                                            {
                                                              cartItem.customerPartNo
                                                            }
                                                          </td>
                                                        </tr>
                                                      </table>
                                                    </td>
                                                  </tr>
                                                </table>
                                              </td>
                                            </tr>
                                          </table>

                                          <Table.Cell
                                            style={{
                                              padding: '20px',
                                              width: '25%',
                                            }}
                                          >
                                            <span
                                              hidden={
                                                data[activeIndex]
                                                  .labelOrderType === 2
                                              }
                                            >
                                              <Image
                                                size="medium"
                                                src={cartItem.imageUrl}
                                              />
                                            </span>
                                          </Table.Cell>

                                          <Table.Cell
                                            width={9}
                                            style={{ color: 'gray' }}
                                          >
                                            <table
                                              hidden={
                                                data[activeIndex]
                                                  .labelOrderType === 2
                                              }
                                            >
                                              <tr>
                                                <td
                                                  style={{
                                                    paddingRight: '40px',
                                                    borderRight:
                                                      'solid 1px gray',
                                                  }}
                                                >
                                                  <table>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          fontSize: '14px',
                                                          fontWeight: 'bold',
                                                          paddingRight: '20px',
                                                        }}
                                                      >
                                                        {
                                                          translations[
                                                            'LexULItemNumber'
                                                          ]
                                                        }
                                                      </td>
                                                      <td>
                                                        {cartItem.itemNumber}
                                                      </td>
                                                    </tr>

                                                    <tr>
                                                      <td
                                                        style={{
                                                          fontSize: '14px',
                                                          fontWeight: 'bold',
                                                        }}
                                                      >
                                                        {
                                                          translations[
                                                            'LexQuantity'
                                                          ]
                                                        }
                                                      </td>
                                                      <td>
                                                        {nf.format(
                                                          cartItem.quantity
                                                        )}
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          fontSize: '14px',
                                                          fontWeight: 'bold',
                                                        }}
                                                      >
                                                        {
                                                          translations[
                                                            'LexUnitofMeasure'
                                                          ]
                                                        }
                                                      </td>
                                                      <td>
                                                        {cartItem.oracleUnitOfMeasurementName ===
                                                        'RL'
                                                          ? translations[
                                                              'LexRL'
                                                            ]
                                                          : translations[
                                                              'LexEa'
                                                            ]}
                                                        <Popup
                                                          trigger={
                                                            <span
                                                              style={{
                                                                color:
                                                                  '#80B0D2',
                                                              }}
                                                            >
                                                              <Icon name="exclamation circle" />
                                                            </span>
                                                          }
                                                          content={this.renderLabelOrderPackage(
                                                            cartItem.labelOrderPackage
                                                          )}
                                                          size="small"
                                                        />
                                                      </td>
                                                    </tr>
                                                  </table>
                                                </td>
                                                <td
                                                  style={{
                                                    width: '50%',
                                                    paddingLeft: '50px',
                                                    verticalAlign: 'top',
                                                  }}
                                                >
                                                  <table>
                                                    <tr>
                                                      <td
                                                        style={{
                                                          fontSize: '14px',
                                                          fontWeight: 'bold',
                                                          color: 'gray',
                                                          paddingRight: '20px',
                                                        }}
                                                      >
                                                        {
                                                          translations[
                                                            'LexLabelPrintingFee'
                                                          ]
                                                        }
                                                        {' ('}
                                                        {
                                                          data[activeIndex]
                                                            .currency
                                                        }
                                                        {')'}
                                                      </td>
                                                      <td
                                                        style={{
                                                          textAlign: 'right',
                                                        }}
                                                      >
                                                        {(
                                                          cartItem.newPrice *
                                                          forms[activeIndex]
                                                            .conversionRate
                                                        )?.toFixed(2)}
                                                      </td>
                                                    </tr>

                                                    <tr>
                                                      <td
                                                        style={{
                                                          fontSize: '14px',
                                                          fontWeight: 'bold',
                                                          color: 'gray',
                                                        }}
                                                      >
                                                        {
                                                          translations[
                                                            'LexLabelHandlingFee'
                                                          ]
                                                        }
                                                        {' ('}
                                                        {
                                                          data[activeIndex]
                                                            .currency
                                                        }
                                                        {')'}
                                                      </td>
                                                      <td
                                                        style={{
                                                          textAlign: 'right',
                                                        }}
                                                      >
                                                        {forms[
                                                          activeIndex
                                                        ].handlingFee?.toFixed(
                                                          2
                                                        )}
                                                      </td>
                                                    </tr>
                                                  </table>
                                                </td>
                                              </tr>
                                              <br />
                                              <tr
                                                style={{
                                                  border: '0px',
                                                }}
                                              >
                                                <td colSpan={6}>
                                                  <label
                                                    style={{
                                                      border: '0px',
                                                      fontSize: '14px',
                                                      fontWeight: 'bold',
                                                    }}
                                                  >
                                                    {
                                                      translations[
                                                        'LexItemDescription'
                                                      ]
                                                    }
                                                    :
                                                  </label>
                                                  <div
                                                    style={{
                                                      overflowWrap:
                                                        'break-word',
                                                      whiteSpace: 'pre-wrap',
                                                      fontSize: '12px',
                                                      width: '100%',
                                                    }}
                                                  >
                                                    {cartItem.description.replace(
                                                      /\s\s+/g,
                                                      ' '
                                                    )}
                                                  </div>
                                                </td>
                                              </tr>
                                            </table>
                                          </Table.Cell>
                                          <span hidden={forReview}>
                                            <Table.HeaderCell
                                              style={{ textAlign: 'center' }}
                                              width={1}
                                            >
                                              <Checkbox
                                                style={{
                                                  border: '3px solid black',
                                                  padding: '0px',
                                                  height: '23px',
                                                  width: '23px',
                                                }}
                                                checked={cartItem.isSelected}
                                                onChange={(e, { checked }) => {
                                                  this.props.selectLabel(
                                                    cartItem,
                                                    checked
                                                  );
                                                }}
                                              />
                                            </Table.HeaderCell>
                                          </span>
                                          <span hidden={forReview}>
                                            <Table.Cell width={3}>
                                              <input
                                                placeholder={
                                                  translations['LexQuantity']
                                                }
                                                onKeyDown={e => {
                                                  if (
                                                    e.keyCode === 8 ||
                                                    e.keyCode === 46
                                                  ) {
                                                    return false;
                                                  }
                                                }}
                                                onChange={e => {
                                                  this.handleChangeQuantity(
                                                    e,
                                                    cartItem
                                                  );
                                                }}
                                                maxLength="5"
                                                min="1"
                                                type="number"
                                                value={cartItem.quantity}
                                                style={{
                                                  fontSize: '0.8em',
                                                  width: '130px',
                                                }}
                                              />
                                              <SubLabel
                                                message={
                                                  translations['LexLabels']
                                                }
                                              />
                                            </Table.Cell>
                                          </span>
                                        </Table.Row>
                                      )
                                    );
                                  })}
                              </table>
                            </Accordion.Content>
                          </Accordion>
                        </table>
                      </div>
                    );
                  })}
                <Table
                  hidden={!forReview}
                  style={{
                    padding: '20px',
                    border: '0px',
                    margin: '0px',
                  }}
                >
                  <Table.Body>
                    <Grid padded>
                      <Grid.Row
                        style={{
                          borderTop: '1px solid gray',
                          borderBottom: '1px solid gray',
                        }}
                      >
                        <Grid.Column width={17} style={{ textAlign: 'right' }}>
                          <span hidden={data[activeIndex].labelOrderType === 2}>
                            <span hidden={!forReview}>
                              <table width="100%">
                                <tr style={{ color: 'gray' }}>
                                  <td style={{ border: '0px' }}></td>
                                  <td width="15%" style={{ textAlign: 'left' }}>
                                    {translations['LexTotalPrintingFee']}:{' ('}{' '}
                                    {forms[activeIndex].currency} {') '}{' '}
                                  </td>
                                  <td width="8%">
                                    {forms[activeIndex].billToCountryCode ===
                                      'JP' ||
                                    forms[activeIndex].billToCountryCode ===
                                      'KR'
                                      ? forms[
                                          activeIndex
                                        ].totalPrintingFeeConverted?.toFixed()
                                      : forms[
                                          activeIndex
                                        ].totalPrintingFeeConverted?.toFixed(
                                          2
                                        )}{' '}
                                  </td>
                                </tr>
                                <tr style={{ color: 'gray' }}>
                                  <td style={{ border: '0px' }}></td>
                                  <td
                                    width="15%"
                                    style={{ border: '0px', textAlign: 'left' }}
                                  >
                                    {translations['LexTotalHandlingFee']}:{' '}
                                    {' ('} {forms[activeIndex].currency} {') '}{' '}
                                  </td>
                                  <td style={{ border: '0px' }}>
                                    {forms[activeIndex].billToCountryCode ===
                                      'JP' ||
                                    forms[activeIndex].billToCountryCode ===
                                      'KR'
                                      ? forms[
                                          activeIndex
                                        ].totalHandlingFeeConverted?.toFixed()
                                      : forms[
                                          activeIndex
                                        ].totalHandlingFeeConverted?.toFixed(
                                          2
                                        )}{' '}
                                  </td>
                                </tr>
                                <tr>
                                  <td></td>
                                  <td colSpan={2}>
                                    <hr />
                                  </td>
                                </tr>
                                <tr>
                                  <td style={{ border: '0px' }}></td>
                                  <td
                                    width="15%"
                                    style={{
                                      border: '0px',
                                      borderTop: '2px',
                                      textAlign: 'left',
                                    }}
                                  >
                                    <label
                                      style={{
                                        fontWeight: 'bold',
                                        color: 'gray',
                                      }}
                                    >
                                      {translations['LexOrderTotal']}
                                      {' ('} {forms[activeIndex].currency}{' '}
                                      {') '}
                                    </label>{' '}
                                    <br />
                                    {translations['Lexbeforeshipping']}
                                  </td>
                                  <td
                                    style={{
                                      border: '0px',
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    {forms[activeIndex].billToCountryCode ===
                                      'JP' ||
                                    forms[activeIndex].billToCountryCode ===
                                      'KR'
                                      ? forms[
                                          activeIndex
                                        ].grantTotalConverted?.toFixed()
                                      : forms[
                                          activeIndex
                                        ].grantTotalConverted?.toFixed(2)}{' '}
                                  </td>
                                </tr>
                              </table>
                            </span>
                          </span>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row
                        style={{
                          padding: '20px',
                        }}
                      >
                        {
                          <Fragment>
                            <span
                              hidden={data[activeIndex].labelOrderType === 2}
                            >
                              <label
                                style={{
                                  fontSize: '20px',
                                  fontWeight: 'bold',
                                  color: 'gray',
                                }}
                              >
                                {translations['LexNotes']}
                              </label>
                              <br />
                              <br />
                              <label style={{ color: 'gray' }}>
                                {translations['LexLabelFeeNotesDesc']
                                  .split('\n\n')
                                  .map((line, i) => (
                                    <span key={i}>
                                      {line}
                                      <br />
                                      <br />
                                    </span>
                                  ))}
                              </label>
                            </span>
                            <span
                              hidden={data[activeIndex].labelOrderType === 1}
                            >
                              <label
                                style={{
                                  fontSize: '17px',
                                  fontWeight: 'bold',
                                  color: 'gray',
                                }}
                              >
                                {translations['LexNotes']}
                              </label>
                              <br />
                              <br />
                              <label style={{ color: 'gray' }}>
                                {translations['LexComLabelNotes']
                                  .split('\n\n')
                                  .map((line, i) => (
                                    <span key={i}>
                                      {line}
                                      <br />
                                      <br />
                                    </span>
                                  ))}
                              </label>
                            </span>
                          </Fragment>
                        }
                      </Grid.Row>
                    </Grid>
                  </Table.Body>
                </Table>
              </Form>
            </Tab.Pane>
          ),
        };
        panes.push(pane);
      }
    }
    if (
      forms &&
      forms.length > 0 &&
      (forms[activeIndex].labelCenterName === 'Japan' ||
        forms[activeIndex].labelCenterName === 'South Korea' ||
        forms[activeIndex].labelCenterName === 'Europe' ||
        forms[activeIndex].labelCenterName === 'China' ||
        forms[activeIndex].labelCenterName === 'Taiwan') &&
      shippingOptions.length === 6 &&
      isSuccessUpdateShippingOption
    ) {
      this.setState({ isSuccessUpdateShippingOption: false });
    }

    if (
      forms &&
      forms.length > 0 &&
      forms[activeIndex].labelCenterName &&
      !isSuccessUpdateShippingOption
    ) {
      this.props.updateShippingOptions(forms[activeIndex].labelCenterName);
      this.setState({ isSuccessUpdateShippingOption: true });
    }

    if (createOrderResult) {
      this.props.resetAddToCart();
      localStorage.setItem('cartLabelOrderType', null);
    }

    if (isSuccessUpdateCart) {
      setTimeout(() => {
        toast({
          title: '',
          description: (
            <p>{Common.getTranslations()['LexCartItemUpdateSucessAlert']}</p>
          ),
          time: 10000,
          size: 'small',
          icon: 'check',
          color: 'green',
        });
      }, 100);

      this.props.getActivePage('Review Cart Form');
      this.props.resetUpdateCart();
    }

    if (isSuccessDeleteCart) {
      setTimeout(() => {
        this.props.resetDeleteCart();
      }, 3000);
    }

    if (isSuccessCancelOrder) {
      this.props.getCartItems();

      setTimeout(() => {
        this.props.resetCancelOrder();
      }, 3000);
    }

    if (isSuccessCancelCart) {
      localStorage.setItem('cartLabelOrderType', null);

      setTimeout(() => {
        this.props.resetCancelOrder();
        this.props.getCartItems();
      }, 2000);
    }

    if (!isSucessCustomer && forms && forms.length > 0) {
      this.setState({ isSucessCustomer: true });

      this.props.getCustomerDetails(userPsn).then(result => {
        if (result && result.isSuccessful) {
          let custAccountNumber = result.data.accountNumber;

          if (custAccountNumber && custAccountNumber !== OldAppAccntNo) {
            this.setState({ OldAppAccntNo: custAccountNumber });

            this.props
              .getCustomerRelations(
                roleId === 1
                  ? forms[activeIndex].manufacturerAccountNumber
                  : custAccountNumber,
                true,
                forms[0].carts[0].cartItems[0].itemNumber,
                roleId === 1
                  ? custAccountNumber
                  : forms[activeIndex].applicantAccountNoSelection
              )
              .then(s => {
                if (s == null) {
                  this.setState({ isLoadBillToAccntEmpty: false });
                }
              });
            this.handleLoadAccountDetailsShipTo(custAccountNumber);
          }
        }
      });
    }

    if (
      forms &&
      forms.length > 0 &&
      ((forms[activeIndex].applicantAccountNumbers &&
        forms[activeIndex].applicantAccountNumbers.length > 0) ||
        (forms[activeIndex].manufacturerAccountNumbers &&
          forms[activeIndex].manufacturerAccountNumbers.length > 0)) &&
      !isLoadBillToAccntEmpty &&
      (accountNumbersOptions == null ||
        (accountNumbersOptions && accountNumbersOptions.length === 0))
    ) {
      this.setState({ isLoadBillToAccntEmpty: true });
      var accntNumbers = [];
      if (forms[activeIndex].applicantAccountNumbers.length > 0) {
        forms[activeIndex].applicantAccountNumbers.forEach(item => {
          accntNumbers.push(item);
        });
      }
      if (forms[activeIndex].manufacturerAccountNumbers.length > 0) {
        forms[activeIndex].manufacturerAccountNumbers.forEach(item => {
          accntNumbers.push(item);
        });
      }

      const uniqAccnts = [
        ...new Map(
          accntNumbers.map(item => [JSON.stringify(item), item])
        ).values(),
      ];

      this.props.updateBillTOAccountNumbers(uniqAccnts);
    }

    //applicant contact
    if (
      forms &&
      forms.length > 0 &&
      forms[activeIndex].applicantAccountNoSelection &&
      !isLoadApplicantContactInfo
    ) {
      this.setState({ isLoadApplicantContactInfo: true });
      this.props.getApplicantContactInformations(
        forms[activeIndex].applicantAccountNoSelection
      );
    }

    if (
      forms &&
      forms.length > 0 &&
      forms[activeIndex].billToAccountNo &&
      // !forms[0].billToContactName &&
      !isLoadCustomerRelation
    ) {
      this.setState({ isLoadCustomerRelation: true });
      this.props.getCustomerRelationInformations(
        forms[activeIndex].billToAccountNo
      );

      if (
        forms[activeIndex].billToContactName &&
        this.props.customerRelationInformationsData
      ) {
        const billToDetails = this.props.customerRelationInformationsData.find(
          s => s.contactName === forms[activeIndex].billToContactName
        );
        if (billToDetails && billToDetails.emailAdd) {
          this.setState({ isReadOnlyBillToEmail: true });
        } else {
          this.setState({ isReadOnlyBillToEmail: false });
        }
      }
    }

    if (
      forms &&
      forms.length > 0 &&
      forms[activeIndex].manufacturerAccountNumber &&
      !isLoadCustomerRelationShipTo
    ) {
      this.setState({ isLoadCustomerRelationShipTo: true });
      this.props.getCustomerRelationInformationsShipTo(
        forms[activeIndex].manufacturerAccountNumber
      );

      if (
        forms[activeIndex].shipToContactName &&
        this.props.customerRelationInformationsShiptToData
      ) {
        const shipToDetails =
          this.props.customerRelationInformationsShiptToData.find(
            s => s.contactName === forms[activeIndex].shipToContactName
          );

        if (shipToDetails && shipToDetails.emailAdd) {
          this.setState({ isReadOnlyShipToEmail: true });
        } else {
          this.setState({ isReadOnlyShipToEmail: false });
        }
      }
    }

    if (
      accountNumbersOptions &&
      accountNumbersOptions.length > 0 &&
      isCustomerRelationEmpty
    ) {
      this.setState({ isCustomerRelationEmpty: false });
      this.setState({ isLoadCountryOptions: false });
    }
    if (
      accountNumbersShipToOptions &&
      accountNumbersShipToOptions.length > 0 &&
      isCustomerRelationShipToEmpty
    ) {
      this.setState({ isCustomerRelationShipToEmpty: false });
    }

    if (countryOptions == null) {
      this.props.getCountries(1);
    }

    if (
      forms &&
      forms.length > 0 &&
      forms[activeIndex].billToCountry.length > 3 &&
      isLoadCountryOptions &&
      accountNumbersOptions == null
    ) {
      this.setState({ isLoadCountryOptions: false });
    }

    if (
      forms &&
      forms.length > 0 &&
      countryOptions &&
      countryOptions.length > 0 &&
      !isLoadCountryOptions &&
      (accountNumbersOptions == null ||
        (accountNumbersOptions && accountNumbersOptions.length === 0))
    ) {
      this.setState({ isLoadCountryOptions: true });
      //applicantCountry
      if (forms[activeIndex].applicantCountry) {
      }
      //billToCountry
      if (forms[activeIndex].billToCountry) {
        const country = this.props.countryOptions.find(function (item) {
          return item.text === forms[activeIndex].billToCountry;
        });

        forms[activeIndex].billToCountry = country
          ? country.value
          : forms[activeIndex].billToCountry;
      }
    }

    if (
      forms &&
      forms.length > 0 &&
      forms[activeIndex].billToCountry.length === 2 &&
      isLoadCountryOptions &&
      accountNumbersOptions &&
      accountNumbersOptions.length > 0
    ) {
      this.setState({ isLoadCountryOptions: false });
    }

    if (
      forms &&
      forms.length > 0 &&
      countryOptions &&
      countryOptions.length > 0 &&
      !isLoadCountryOptions &&
      accountNumbersOptions &&
      accountNumbersOptions.length > 0
    ) {
      this.setState({ isLoadCountryOptions: true });
      //applicantCountry
      if (forms[activeIndex].applicantCountry) {
        if (forms[activeIndex].applicantCountry.length === 2) {
          const country = this.props.countryOptions.find(function (item) {
            return item.value === forms[activeIndex].applicantCountry;
          });
          forms[activeIndex].applicantCountry = country
            ? country.text
            : forms[activeIndex].applicantCountry;
        } else {
          const country = this.props.countryOptions.find(function (item) {
            return item.value === forms[activeIndex].applicantCountry;
          });
          forms[activeIndex].applicantCountry = country
            ? country.text
            : forms[activeIndex].applicantCountry;
        }
      }
      //billToCountry
      if (forms[activeIndex].billToCountry) {
        const country = this.props.countryOptions.find(function (item) {
          return item.value === forms[activeIndex].billToCountry;
        });
        forms[activeIndex].billToCountry = country
          ? country.text
          : forms[activeIndex].billToCountry;
      }
    }

    return (
      <Fragment>
        <div style={{ padding: '20px' }}>
          <Modal
            size="tiny"
            open={openCustomerEcmStatusModal}
            onClose={this.handleCustomerEcmStatusApiModal}
          >
            <Modal.Header>Customer Ecm Api</Modal.Header>
            <Modal.Content>
              <p>
                The UL Label ordering application is currently down for
                maintenance. <br />
                For any question or assistance please contact your local UL
                Label Center, your order will be saved in cart.{' '}
              </p>
            </Modal.Content>
            <Modal.Actions>
              <Button primary onClick={this.handleCustomerEcmStatusApiModal}>
                Close
              </Button>
            </Modal.Actions>
          </Modal>
          <Modal
            closeOnEscape={false}
            closeOnDimmerClick={false}
            size="mini"
            open={openSameFileNumbers}
            onClose={this.handleCancelSameFileNumbers}
          >
            <Modal.Header></Modal.Header>
            <Modal.Content>
              <p>{translations['LexIsSameBillTo']}</p>
            </Modal.Content>
            <Modal.Actions>
              <Button primary basic onClick={this.handleOk}>
                Yes
              </Button>
              <Button primary onClick={this.handleCancelSameFileNumbers}>
                No
              </Button>
            </Modal.Actions>
          </Modal>

          <Modal size="mini" open={open} onClose={this.handleCancel}>
            <Modal.Header>{translations['LexDeleteCart']}</Modal.Header>
            <Modal.Content>
              <p>{translations['LexConfirmDeleteCart']}</p>
            </Modal.Content>
            <Modal.Actions>
              <Button
                style={{ backgroundColor: 'lightgray', color: 'black' }}
                onClick={this.handleCancel}
              >
                {translations['LexNO']}
              </Button>
              <Button primary onClick={this.handleConfirm}>
                {translations['LexYES']}
              </Button>
            </Modal.Actions>
          </Modal>
          <Modal
            size="mini"
            open={openBillToModal}
            onClose={this.deleteTabConfirmationCancel}
          >
            <Modal.Header>{translations['LexDeleteFile']}</Modal.Header>
            <Modal.Content>
              <p>{translations['LexConfirmDeleteFile']}</p>
            </Modal.Content>
            <Modal.Actions>
              <Button
                style={{ backgroundColor: 'lightgray', color: 'black' }}
                onClick={this.deleteTabConfirmationCancel}
              >
                {translations['LexNO']}
              </Button>
              <Button primary onClick={this.handleDeleteTab}>
                {translations['LexYES']}
              </Button>
            </Modal.Actions>
          </Modal>
          <Modal
            size="tiny"
            open={openCheckOut}
            onClose={this.handleCancelCheckOut}
          >
            <Modal.Header>{translations['LexCheckout']}</Modal.Header>
            <Modal.Content>
              <p style={{ fontSize: '18px' }}>
                {translations['LexConfirmInfoaccuratebeforeCheckOut']} <br />{' '}
                <br /> <br />
                {isDifferentBillTo && (
                  <span>
                    <b>
                      Only files with the same Bill To account can be ordered in
                      a single order. System will split this order.
                    </b>
                    <br /> <br /> <br />
                  </span>
                )}
                {translations['LexConfirmCheckOut']}
              </p>
            </Modal.Content>
            <Modal.Actions>
              <Button primary onClick={this.handleConfirmCheckOut}>
                {translations['LexYES']}
              </Button>
              <Button primary basic onClick={this.handleCancelCheckOut}>
                {translations['LexCancel']}
              </Button>
            </Modal.Actions>
          </Modal>
          <Modal open={openTac}>
            <Modal.Header className="ui center aligned">
              Terms and Conditions
            </Modal.Header>
            <Modal.Content scrolling>
              <TermsAndConditions
                onClickAgreeChecbox={this.onClickAgreeChecbox}
                isCheckAgree={isCheckAgree}
              />
            </Modal.Content>
            <Modal.Actions>
              <table>
                <tr>
                  <td style={{ textAlign: 'right', width: '20%' }}>
                    <Checkbox
                      style={{
                        border: '3px solid black',
                        padding: '0px',
                        height: '23px',
                        width: '23px',
                      }}
                      checked={isCheckAgree}
                      onChange={this.onClickAgreeChecbox}
                    />
                  </td>{' '}
                  *
                  <td style={{ textAlign: 'left' }}>
                    <label>
                      <b>{translations['LexConfirmacknowledge']}</b>
                    </label>
                  </td>
                  <td style={{ verticalAlign: 'right', width: '27%' }}>
                    {isCheckAgree ? (
                      <Button
                        primary
                        className={isLoading}
                        onClick={this.handleSubmit.bind(this)}
                        disabled={isLoading}
                      >
                        {translations['LexSubmit']}
                      </Button>
                    ) : null}

                    <Button
                      primary
                      basic
                      onClick={() =>
                        this.setState({ openTac: false, isCheckAgree: false })
                      }
                    >
                      {translations['LexCancel']}
                    </Button>
                  </td>
                </tr>
              </table>
            </Modal.Actions>
          </Modal>
          <Modal size="small" open={this.state.showManageDocs}>
            <Dimmer active={this.props.uploading}>
              <Loader>Loading</Loader>
            </Dimmer>
            <Modal.Header>
              <table>
                <tr>
                  <th width="850">{translations['LexFileUpload']}</th>
                  <th>
                    <Button
                      primary
                      onClick={() => this.handleManageDocs(false)}
                    >
                      X
                    </Button>
                  </th>
                </tr>
              </table>
            </Modal.Header>
            <Modal.Content>
              <Table>
                <Table.Header>
                  <Table.Row textAlign="center">
                    <Table.HeaderCell>
                      {translations['LexFileName']}
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      {translations['LexFileSize']}
                    </Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {attachments &&
                    attachments.map(column => {
                      return (
                        <Table.Row>
                          <Table.Cell>
                            <a
                              href={column.filePath}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {column.originalFileName}
                            </a>
                          </Table.Cell>
                          <Table.Cell textAlign="center">
                            {column.fileSize}
                          </Table.Cell>
                          <Table.Cell textAlign="right">
                            <Button
                              onClick={() =>
                                this.handleDeleteTempAttachment(column.id)
                              }
                              disabled={
                                // roleId != Role.labelCenter ||
                                this.props.labelOrderType === 2
                              }
                            >
                              {translations['LexDelete']}
                            </Button>
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                </Table.Body>
              </Table>
            </Modal.Content>
            <Modal.Actions>
              <div style={{ float: 'left', marginRight: '15px' }}>
                <input
                  type="file"
                  id="file"
                  accept=".pdf,.doc,.docx,.jpg"
                  style={{ display: 'none' }}
                  onChange={this.handleUploadFile}
                  ref={fileInput => (this.fileInput = fileInput)}
                />
                <button
                  disabled={this.props.attachments.length > 4}
                  onClick={() => this.fileInput.click()}
                  className="ui primary button"
                >
                  Choose File
                </button>
              </div>
              {/* <Button primary basic onClick={this.handleOk}>
                Upload
              </Button> */}
              <br />
              <br />
            </Modal.Actions>
          </Modal>
          <Container fluid>
            <Grid verticalAlign="middle">
              <Grid.Column width={7}>
                <table>
                  <tr>
                    <td>
                      <span hidden={forReview}>
                        <CustomHeaderLabel
                          message={` ${
                            roleId
                              ? roleId === 1
                                ? translations['LexCartInfoMfr']
                                : translations['LexCartInfoApplicant']
                              : ''
                          } ${
                            roleId
                              ? roleId === 1
                                ? data && data.length > 0
                                  ? data[0].manufacturerName
                                  : ''
                                : data && data.length > 0
                                ? data[0].applicantCompany
                                : ''
                              : ''
                          } ${`(PSN-`}${userPsn}${`)`}     `}
                        />
                      </span>
                      <span hidden={!forReview}>
                        <CustomHeaderLabel
                          message={` ${
                            roleId
                              ? roleId === 1
                                ? translations['LexReviewcartforManufacturer']
                                : translations['LexReviewcartforApplicant']
                              : ''
                          } ${
                            roleId
                              ? roleId === 1
                                ? data && data.length > 0
                                  ? data[0].manufacturerName
                                  : ''
                                : data && data.length > 0
                                ? data[0].applicantCompany
                                : ''
                              : ''
                          } ${`(PSN-`}${userPsn}${`)`}    `}
                        />
                      </span>
                    </td>
                    <td style={{ paddingLeft: '20px' }}>
                      <span hidden={isSuccessCreateOrder || !hasCartItems}>
                        <Button
                          style={{
                            backgroundColor: 'white',
                            color: 'red',
                            border: '1px solid',
                            width: '130px',
                          }}
                          compact
                          onClick={this.handleDeleteCart}
                        >
                          {translations['LexDeleteCart']}
                        </Button>
                      </span>
                    </td>
                  </tr>
                </table>
              </Grid.Column>
              <Grid.Column width={4}>
                {/* <span hidden={!isSuccess}>
                  <NotificationSuccess
                    message={
                      "Thank you, your order has been placed successfully. Order #: UL Label-" +
                      orderNumber
                    }
                  />
                </span> */}
              </Grid.Column>
              <Grid.Column
                width={5}
                style={{ textAlign: 'right' }}
                verticalAlign="middle"
              >
                {/* <span hidden={forReview}>
                  <Button
                    basic
                    primary
                    compact
                    onClick={this.handleRemoveSelections}
                    disabled={selectedLabels.length === 0}
                  >
                    Remove selections
                  </Button>
                </span> */}
                <span hidden={roleId !== Role.labelCenter}>
                  <Button primary compact onClick={this.handleRedirectToHelpMe}>
                    Back to Help Me
                  </Button>
                </span>
                {/* <span hidden={roleId == Role.labelCenter}>
                  <span hidden={!forReview || total === 0}>
                    <Button
                      basic
                      primary
                      onClick={this.handleOpenCheckoutModal}
                      disabled={isSuccessCreateOrder}
                    >
                      Check out
                    </Button>
                  </span>
                </span> */}
                <span
                  hidden={
                    isSuccessCreateOrder ||
                    !hasCartItems ||
                    data[activeIndex].labelOrderType === 2
                  }
                >
                  <span
                  // hidden={
                  //   !(
                  //     this.props.psnCountry == "CN" ||
                  //     this.props.psnCountry == "TW"
                  //   )
                  // }
                  >
                    <Button
                      style={{
                        backgroundColor: 'white',
                        color: 'gray',
                        border: 'solid 1px black',
                      }}
                      compact
                      onClick={this.handleManageDocs.bind(this, true)}
                    >
                      {translations['LexManageDocuments']}
                    </Button>
                  </span>
                </span>
              </Grid.Column>
            </Grid>
            <Divider hidden />
            <Transition.Group animation="fade down" duration={500}>
              {isSuccessDeleteCart && (
                <Message
                  negative
                  header="Cart item/s successfully deleted."
                  content="Check other cart items that you wish to delete."
                />
              )}
            </Transition.Group>
            <Transition.Group animation="fade down" duration={500}>
              {isSuccessCancelOrder && (
                <Message
                  negative
                  header="Your order has been cancelled."
                  content="You may go back to the file/volumes page and order again."
                />
              )}
            </Transition.Group>
            <Transition.Group animation="fade down" duration={500}>
              {isSuccessCancelCart && (
                <Message
                  negative
                  header={translations['LexYourcarthasbeencancelled']}
                  content={translations['LexBacktoOrder']}
                />
              )}
            </Transition.Group>
            <div>
              <Form loading={isLoadingCartItems}>
                <Tab
                  panes={panes}
                  activeIndex={activeIndex}
                  onTabChange={this.handleTabChange}
                ></Tab>
              </Form>

              {data && data.length === 0 && !isLoadingCartItems && (
                <Grid>
                  <Grid.Row>
                    <Grid.Column>
                      <table style={{ margin: '100px auto auto auto' }}>
                        <tr style={{ textAlign: 'center' }}>
                          <td style={{ color: '#7F7F7F' }}>
                            <Icon name="huge cart arrow down" />
                          </td>
                        </tr>
                        <tr style={{ textAlign: 'center' }}>
                          <td style={{ padding: '30px' }}>
                            <CustomHeaderLabel2
                              message={translations['LexEmptyCartBanner']
                                .split('.')
                                .map((line, i) => (
                                  <span key={i}>
                                    {line}
                                    <br />
                                  </span>
                                ))}
                            />
                            {/* <Divider hidden fitted />
                            <CustomHeaderLabel2 message="Add items to the cart from the order page." /> */}
                          </td>
                        </tr>
                        <tr style={{ textAlign: 'center' }}>
                          <td>
                            <Button
                              onClick={() => {
                                this.props.getActivePage(
                                  'Standard Label Order'
                                );
                              }}
                              style={{
                                backgroundColor: '#ffffff',
                                color: '#1b1c1d',
                                border: '1px solid #1b1c1d',
                              }}
                              as={Link}
                              to={orderPage + '/1'}
                            >
                              {translations['LexOrderStandardLabels']}
                            </Button>
                            <span style={{ marginRight: '10px' }}></span>
                            <Button
                              onClick={() => {
                                this.props.getActivePage(
                                  'Combination Label Order'
                                );
                              }}
                              style={{
                                backgroundColor: '#ffffff',
                                color: '#1b1c1d',
                                border: '1px solid #1b1c1d',
                              }}
                              as={Link}
                              to={orderPage + '/2'}
                            >
                              {translations['LexOrderCombinationLabels']}
                            </Button>
                          </td>
                        </tr>
                      </table>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              )}
            </div>

            <Divider hidden />
            <Grid>
              <Grid.Column width={11}>
                {
                  <span hidden={!forReview}>
                    {/* <span style={{ fontSize: "17px", fontWeight: "bold" }}>
                      Please note that costs may vary as a weekly fix rate is
                      issued and that UL reserves the right to increase prices
                      in the cost of our standard labels at any time.
                    </span>
                    <br />
                    <br />
                    <span style={{ fontSize: "17px", fontWeight: "bold" }}>
                      The invoice will be issued in local currency based on the
                      “Bill To” account in some countries.
                    </span>
                    <br />
                    <br />
                    <span style={{ fontSize: "17px", fontWeight: "bold" }}>
                      The fee amount shown on the label order form is the net
                      label printing fee & handling fee, not including shipping
                      fee. The amount is just for reference only, please refer
                      to the final amount on the invoice.
                    </span>
                    <br />
                    <br />
                    <span style={{ fontSize: "17px", fontWeight: "bold" }}>
                      Each label item is charged $35 for handling fee
                    </span>
                    <br />
                    <br />
                    <span style={{ fontSize: "17px", fontWeight: "bold" }}>
                      If applicable, the Production Volume Fee ( also known as
                      Service Charge) will be invoiced at month end
                    </span> */}
                    {/* <br /> <br /> */}
                    {/* <span hidden={roleId == Role.labelCenter}>
                      <span hidden={!forReview || !hasCartItems}>
                        <Button
                          primary
                          basic
                          onClick={this.handleEditCart}
                          disabled={isSuccessCreateOrder}
                        >
                          Edit cart
                        </Button>
                      </span>
                    </span> */}
                  </span>
                }
              </Grid.Column>
              <Grid.Column width={5} style={{ textAlign: 'right' }}>
                <span hidden={roleId === Role.labelCenter}>
                  <span hidden={!forReview || !hasCartItems}>
                    <Button
                      style={{
                        backgroundColor: 'white',
                        color: 'gray',
                        paddingRight: '20px',
                        border: 'solid black 1px',
                      }}
                      onClick={this.handleEditCart}
                      disabled={isSuccessCreateOrder}
                    >
                      {translations['LexEditCart']}
                    </Button>
                  </span>
                </span>
                <span hidden={forReview || !labelOrderType}>
                  <Button
                    style={{
                      backgroundColor: 'white',
                      color: 'gray',
                      paddingRight: '20px',
                      border: 'solid 1px',
                    }}
                    as={Link}
                    to={orderPage + '/' + labelOrderType}
                    onClick={this.handleReturnToShopping}
                  >
                    {translations['LexReturntoshopping']}
                  </Button>
                </span>
                <span hidden={forReview || !hasCartItems}>
                  <Button primary onClick={this.handleReviewCart}>
                    {translations['LexReviewCart']} ({totalLabels}{' '}
                    {totalLabels === 1 ? 'Item' : 'Items'})
                  </Button>
                </span>
                <span hidden={roleId === Role.labelCenter}>
                  <span hidden={!forReview || !hasCartItems}>
                    <Button
                      primary
                      onClick={this.handleOpenCheckoutModal}
                      disabled={isSuccessCreateOrder}
                    >
                      {translations['LexProceedToCheckout']}
                    </Button>
                  </span>
                </span>
              </Grid.Column>
            </Grid>
            <FooterHomeBlock />
          </Container>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  data: state.labelCart.data,
  isSuccessUpdateCart: state.labelCart.isSuccessUpdateCart,
  isSuccessDeleteCart: state.labelCart.isSuccessDeleteCart,
  total: state.labelCart.total,
  totalHandlingFee: state.labelCart.totalHandlingFee,
  grantTotal: state.labelCart.grantTotal,
  selectedLabels: state.labelCart.selectedLabels,
  allLabels: state.labelCart.allLabels,
  isLoading: state.labelCart.isLoading,
  isLoadingCartItems: state.labelCart.isLoadingCartItems,
  isLoadingCreateOrder: state.labelCart.isLoadingCreateOrder,
  createOrderResult: state.labelCart.createOrderResult,
  customerPoNumber: state.labelCart.customerPoNumber,
  comments: state.labelCart.comments,
  isSuccessCancelCart: state.labelCart.isSuccessCancelCart,
  customerInfo: state.labelOrderCart.customerInfo,
  loadingCustomerInfo: state.labelOrderCart.loadingCustomerInfo,
  form: state.labelCart.form,
  forms: state.labelCart.forms,
  unitsOfMeasurement: state.labelOrderCart.unitsOfMeasurement,
  shippingOptions: state.labelCart.shippingOptions,
  shippingOptionId: state.labelCart.shippingOptionId,
  customerDetails: state.labelCart.customerDetails,
  isLoadingCustomerDetails: state.labelCart.isLoadingCustomerDetails,
  isSuccessGetCartItems: state.labelCart.isSuccessGetCartItems,
  labelOrderType: state.labelCart.labelOrderType,
  labelCart: state.labelCart,
  labelCenters: state.labelOrderCart.labelCenters,
  customerRelationsData: state.accountSettings.customerRelationsData,
  accountNumbersOptions: state.accountSettings.accountNumbersOptions,
  accountNumbersShipToOptions:
    state.accountSettings.accountNumbersShipToOptions,
  customerRelationsShipToData:
    state.accountSettings.customerRelationsShipToData,
  customerRelationInformationsOptions:
    state.accountSettings.customerRelationInformationsOptions,
  customerRelationInformationsData:
    state.accountSettings.customerRelationInformationsData,
  customerRelationInformationsShiptToOptions:
    state.accountSettings.customerRelationInformationsShiptToOptions,
  customerRelationInformationsShiptToData:
    state.accountSettings.customerRelationInformationsShiptToData,
  customerRelationInformationsApplicantOptions:
    state.accountSettings.applicantContactInformationsOptions,
  countryOptions: state.accountSettings.countryOptions,
  labelCenterCountryDetails: state.labelCart.labelCenterCountryDetails,
  isLoadingRelation: state.accountSettings.isLoadingRelation,
  isLoadingRelationInformation:
    state.accountSettings.isLoadingRelationInformation,
  exchangeRate: state.labelCart.exchangeRate,
  orderPage: state.login.orderPage,
  psnCountry: state.user.common.psnCountry,
  attachments: state.labelCart.attachments,
  newPoNumber: state.labelCart.newPoNumber,
  activePage: state.user.common.activePage,
  isServiceContractUp: state.labelCart.isServiceContractUp,
  hasServiceContractData: state.labelCart.hasServiceContractData,
  hasSameBillTo: state.labelCart.hasSameBillTo,
});

const mapDispatchToProps = dispatch => ({
  getCartItems: labelUserId => dispatch(getCartItems(labelUserId)),
  createOrder: data => dispatch(createOrder(data)),
  resetCartItemsForm: () => dispatch(resetCartItemsForm()),
  selectLabel: (item, isSelected, activeIndex) =>
    dispatch(selectLabel(item, isSelected, activeIndex)),
  setQuantity: (index, item, quantity) =>
    dispatch(setQuantity(index, item, quantity)),
  updateCart: data => dispatch(updateCart(data)),
  deleteCart: data => dispatch(deleteCart(data)),
  removeCartItem: (cartItemIds, activeIndex) =>
    dispatch(removeCartItem(cartItemIds, activeIndex)),
  resetUpdateCart: () => dispatch(resetUpdateCart()),
  resetDeleteCart: () => dispatch(resetDeleteCart()),
  updateModel: (name, value) => dispatch(updateModel(name, value)),
  cancelOrder: data => dispatch(cancelOrder(data)),
  resetCancelOrder: () => dispatch(resetCancelOrder()),
  getCustomerInfo: customerId => dispatch(getCustomerInfo(customerId)),
  updateForm: result => dispatch(updateForm(result)),
  clearForm: () => dispatch(clearForm()),
  resetAddToCart: () => dispatch(resetAddToCart()),
  getUnitsOfMeasurement: () => dispatch(getUnitsOfMeasurement()),
  getShippingOptions: countryCode => dispatch(getShippingOptions(countryCode)),
  setShippingOption: optionId => dispatch(setShippingOption(optionId)),
  getCustomerDetails: partySiteNumber =>
    dispatch(getCustomerDetails(partySiteNumber)),
  getLabelCenters: () => dispatch(getLabelCenters()),
  getCustomerRelations: (
    manufacturerAccountNumber,
    isBillTo,
    itemNumber,
    applicantAccountNumber,
    labelOrderInfoId
  ) =>
    dispatch(
      actions.getCustomerRelations(
        manufacturerAccountNumber,
        isBillTo,
        itemNumber,
        applicantAccountNumber,
        labelOrderInfoId
      )
    ),
  getCustomerRelationsShipTo: (accountNumber, isBillTo) =>
    dispatch(actions.getCustomerRelationsShipTo(accountNumber, isBillTo)),
  getCustomerRelationInformations: accountNumber =>
    dispatch(actions.getCustomerRelationInformations(accountNumber)),
  resetContactInformations: () => dispatch(actions.resetContactInformations()),
  getCustomerRelationInformationsShipTo: accountNumber =>
    dispatch(actions.getCustomerRelationInformationsShipTo(accountNumber)),
  getApplicantContactInformations: accountNumber =>
    dispatch(actions.getApplicantContactInformations(accountNumber)),
  getCountries: locationType =>
    dispatch(actions.getCountries(locationType, 'true')),
  updateShippingOptions: labelCenter =>
    dispatch(updateShippingOptions(labelCenter)),
  getLabelCenterCountryDetails: shipToCountry =>
    dispatch(getLabelCenterCountryDetails(shipToCountry)),
  updateTabForm: (idx, form, forms) =>
    dispatch(updateTabForm(idx, form, forms)),
  getFormByTabIndex: (idx, forms) => dispatch(updateTabForm(idx, forms)),
  updateLabelOrderCartType: data => dispatch(updateLabelOrderCartType(data)),
  getExchangeRate: (countryCode, index) =>
    dispatch(getExchangeRate(countryCode, index)),
  updateReviewCart: data => dispatch(updateReviewCart(data)),
  getCustomerEcmApiStatus: () => dispatch(getCustomerEcmApiStatus()),
  uploadLabelOrderTempAttachment: data =>
    dispatch(uploadLabelOrderTempAttachment(data)),
  getLabelOrderTempAttachment: (partySiteNumber, labelOrderType) =>
    dispatch(getLabelOrderTempAttachment(partySiteNumber, labelOrderType)),
  deleteLabelOrderTempAttachment: attachmentId =>
    dispatch(deleteLabelOrderTempAttachment(attachmentId)),
  getExistingPoNumber: poNumber => dispatch(getExistingPoNumber(poNumber)),
  updateBillTOAccountNumbers: billtoAccnts =>
    dispatch(actions.updateBillTOAccountNumbers(billtoAccnts)),
  getActivePage: page => dispatch(commonActions.getActivePage(page)),
  deleteTab: activeIndex => dispatch(deleteTab(activeIndex)),
  updateBillToData: data => dispatch(updateBillToData(data)),
  getCreditProfile: (
    applicantAccountNumbers,
    manufacturerAccountNumbers,
    billToAccountNumbers,
    orderAmounts
  ) =>
    dispatch(
      getCreditProfile(
        applicantAccountNumbers,
        manufacturerAccountNumbers,
        billToAccountNumbers,
        orderAmounts
      )
    ),
  scanFile: data => dispatch(scanFile(data)),
  checkIfServiceContractUp: () => dispatch(checkIfServiceContractUp()),
  checkFileVolCcn: data => dispatch(checkFileVolCcn(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LabelCart);
