import PropTypes from "prop-types";
import { connect } from "react-redux";
import React, { Component, Fragment } from "react";
import {
  Modal,
  Button,
  Header,
  Grid,
  Card,
  Feed,
  Icon,
  Message,
  TextArea,
  Form
} from "semantic-ui-react";

import { Common } from "../../common/common";
import ToastHelper from "../../helpers/toast.helper";

import * as actions from "./role-assignment.action";

class NoteModal extends Component {
  state = { isShowAddNote: false, note: "", loading: "" };

  componentDidMount() {
    const { data } = this.props;
    this.props.doGetNotes(data.id);
  }

  onCLickAddNote() {
    this.setState({ isShowAddNote: true });
  }

  onclickSaveNote() {
    const { data } = this.props;
    const params = {
      userId: data.id,
      note: this.state.note
    };

    if (this.state.note.trim() === "") {
      setTimeout(() => {
        ToastHelper.error({
          title: "Error",
          description: "Remarks is required"
        });
      }, 100);
      return false;
    }

    this.setState({ loading: "loading" });
    this.props.doAddNote(params).then((s) => {
      if (s.isSuccessful) {
        setTimeout(() => {
          ToastHelper.success({
            title: "Successfully Added",
            description: s.message
          });
        }, 100);
      } else {
        setTimeout(() => {
          ToastHelper.error({
            title: "Error",
            description: s.message
          });
        }, 100);
      }
      this.setState({ loading: "" });
      this.props.handleNoteModal(false);
    });
  }

  onChange(e) {
    this.setState({ note: e.target.value });
  }

  render() {
    const {
      isSaveNote,
      isShowNoteModal,
      handleNoteModal,
      roleAssignment: { notes }
    } = this.props;
    const { isShowAddNote, loading } = this.state;

    return (
      <Modal open={isShowNoteModal}>
        <Modal.Header>
          <Header textAlign="center">Remarks</Header>
        </Modal.Header>
        <Modal.Content scrolling>
          <Modal.Description>
            <Grid>
              {notes && ((notes || {}).accountNotes || []).length > 0 ? (
                <Grid.Row>
                  <Grid.Column>
                    <Card fluid>
                      <Card.Content>
                        <Feed>
                          {notes.accountNotes.map((ac) => (
                            <Fragment>
                              <Feed.Event>
                                <div className="margin-right-5">
                                  <Icon
                                    className="initial"
                                    size="large"
                                    circular
                                    inverted
                                    color="blue"
                                  >
                                    {Common.getMyInitialName(
                                      ac.userCreated.firstName,
                                      ac.userCreated.lastName
                                    )}
                                  </Icon>
                                </div>
                                <Feed.Content>
                                  <Feed.Date
                                    content={`${ac.userCreated.firstName} ${ac.userCreated.lastName}`}
                                  />
                                  <span>{ac.note}</span>
                                </Feed.Content>
                              </Feed.Event>
                              <br />
                            </Fragment>
                          ))}
                        </Feed>
                      </Card.Content>
                    </Card>
                  </Grid.Column>
                </Grid.Row>
              ) : (
                <Message
                  compact
                  info
                  icon="sticky note outline"
                  header="User doesn't have remarks yet"
                />
              )}
            </Grid>

            {isShowAddNote ? (
              <Grid>
                <Grid.Row>
                  <Grid.Column>
                    <Form>
                      <label>Account Remarks</label>
                      <TextArea
                        name="accountNote"
                        autoComplete="off"
                        onChange={this.onChange.bind(this)}
                      />
                    </Form>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            ) : null}
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          {!isShowAddNote ? (
            <Button primary onClick={this.onCLickAddNote.bind(this)}>
              Add Remarks
            </Button>
          ) : (
            <Button
              primary
              onClick={this.onclickSaveNote.bind(this)}
              className={loading}
            >
              Save
            </Button>
          )}
          <Button
            onClick={handleNoteModal.bind(this, false)}
            className={loading}
          >
            Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

NoteModal.propTypes = {
  isShowNoteModal: PropTypes.bool.isRequired,
  handleNoteModal: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  return {
    roleAssignment: state.roleAssignment
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doGetNotes: (userId) => dispatch(actions.doGetNotes(userId)),
    doAddNote: (params) => dispatch(actions.doAddNote(params))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NoteModal);
