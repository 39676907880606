import React, { Component, Fragment } from "react";
import {
  Accordion,
  Grid,
  Icon,
  Divider,
  Segment,
  Form,
  Button
} from "semantic-ui-react";
import {
  CustomHeaderLabel,
  CustomLabel
} from "../../../components/labels/labels";

import FesAssumption from "../product-details/fes/assumption";
import FeAssumption from "../product-details/fe/assumption";

import RequirementsValue from "../product-details/fe/requirements-value";

class InspectionDetails extends Component {
  state = {
    activeIndex: -1,
    isConfirming: false,
    toRemoveId: null
  };

  toggleDisplay = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  createMarkup(string) {
    return { __html: string };
  }

  render() {
    const { activeIndex } = this.state;
    const { data } = this.props;

    let requirements = RequirementsValue(null, data);
    return (
      <Fragment>
        <Segment>
          <Accordion>
            <Accordion.Title
              active={activeIndex === 0}
              index={0}
              onClick={this.toggleDisplay}
            >
              <Grid columns={1}>
                <Grid.Column width={16}>
                  <Icon name="dropdown" />
                  <CustomHeaderLabel message="Important requirements for your inspection" />
                </Grid.Column>
              </Grid>
            </Accordion.Title>
            <Accordion.Content
              active={activeIndex === 0}
              className="print-expanded"
            >
              <Divider />
              <Form>
                <Grid>
                  <Grid.Column width={4}>
                    <CustomLabel message="Please read and follow all requirements to make sure the inspection can be completed efficiently." />

                    <Divider hidden />
                  </Grid.Column>
                  <Grid.Column width={1}>
                    <Divider vertical />
                  </Grid.Column>
                  <Grid.Column width={10}>
                    <span
                      dangerouslySetInnerHTML={this.createMarkup(requirements)}
                    ></span>
                    {/* <FesAssumption orders={data} /> */}
                    {/* <FeAssumption orders={data} /> */}
                    {/* <Grid>
                      <Grid.Column width={16}>
                        <span>
                          <label>
                            <b>Electrical requirements</b>
                          </label>
                          <br />
                          <br />
                          <label>
                            Please note the equipment must be de-energized for
                            the evaluation. For Service Equipment, if the
                            modification includes the main disconnect or any
                            equipment located on the line side of the main
                            disconnect, a utility shutdown must be arranged for
                            the evaluation.
                          </label>
                        </span>
                      </Grid.Column>
                      <Grid.Column width={16}>
                        <span>
                          <label>
                            <b>Critical Components</b>
                          </label>
                          <br />
                          <br />
                          <label>
                            All critical components (such as insulation piercing
                            connectors, lugs, crimp connectors, terminal blocks,
                            polaris connectors, or breaker taps) must be
                            certified and installed in accordance with the
                            installation instruction and, if UL Recognized, meet
                            any conditions of use.
                          </label>
                        </span>
                      </Grid.Column>
                      <Grid.Column width={16}>
                        <span>
                          <label>
                            <b>Equipment Requirements</b>
                          </label>
                          <br />
                          <br />
                          <label>
                            A calibrated torque wrench with a current calibrated
                            certificate with manufacturer name and serial number
                            must be available on site during the day of
                            evaluation.
                          </label>
                        </span>
                      </Grid.Column>
                    </Grid> */}
                  </Grid.Column>
                </Grid>
              </Form>
            </Accordion.Content>
          </Accordion>
        </Segment>
      </Fragment>
    );
  }
}

export default InspectionDetails;
