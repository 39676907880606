import React, { Component, Fragment } from "react";
import HeaderBlock from "../../components/header/header-block";
import { Redirect, BrowserRouter as Router } from "react-router-dom";
import { Button, Header, Icon, Segment, Divider } from "semantic-ui-react";

import { connect } from "react-redux";

import { doLogout, doExternalLogout } from "../../views/auth/auth.action";
import { Common } from "../../common";
import Role from "../../enums/role";
import IdentityProvider from "../../enums/identity-provider";

const IDENTITY_PROVIDER = `${process.env.REACT_APP_IDENTITY_PROVIDER}`;
const API_BASE = `${process.env.REACT_APP_API_BASE}`;

class ErrorPage extends Component {
  state = {};

  handleLogout = () => {
    if (IDENTITY_PROVIDER == IdentityProvider.Iam) {
      localStorage.clear();
      var externalLogoutURL = `${API_BASE}auth/logout`;
      window.location.replace(externalLogoutURL);
      this.props.logout();
    } else if (IDENTITY_PROVIDER == IdentityProvider.B2c) {
      var b2cToken = Common.getB2cToken();
      localStorage.clear();

      Common.toggleLogout(b2cToken);
    }
  };

  render() {
    const translations = Common.getTranslations() || {};
    return (
      <Fragment>
        <Router>
          <HeaderBlock />
          <Segment
            placeholder
            style={{ marginLeft: "35%", marginRight: "35%" }}
          >
            <Header icon>
              <Icon name="exclamation triangle" />
            </Header>
            {/* <Segment
              placeholder
              style={{ marginLeft: "200px", marginRight: "200px" }}
            > */}

            <p className="center">
              <b>FOR LABEL SERVICE</b>
            </p>
            <p>
              {/* The Label order application, at this moment, is only available for
              North America, Latin America, Europe-Africa and Middle East
              regions.
              <br />
              <br /> */}
              {/* Your account setting has issue. Please contact your local label
              center for assistance. */}
              {translations["LexAccountSettingBanner"]}
              {/* If you are in the supported regions then
              your registration has been successfully submitted and if you do
              not receive a confirmation email please contact your local Label
              Center. For other regions please follow your normal Label ordering
              process or please contact your Local Label Center at the link here{" "}
              <span>
                <a
                  href="https://marks.ul.com/about/ul-listing-and-classification-marks/labels/label-centers/"
                  target="_blank"
                >
                  CONTACT UL LABEL CENTERS
                </a>
              </span> */}
            </p>
            <Divider hidden />
            <Divider />
            <Divider hidden />
            {/* <p className="center">
              <b>FOR SPECIALTY FIELD ENGINEERING TEAM</b>
            </p>
            <p>Your access request is pending.</p>
            <p>We will contact you within 48 hours to confirm.</p>
            <p>
              This application is currently available for North America
              customers only. For further support, please contact us at
              1-877-854-3577 Option 2.
            </p> */}
            <Divider hidden />
            <Button primary onClick={this.handleLogout}>
              Back to Login
            </Button>
          </Segment>
        </Router>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLogoutSuccess: state.auth.isLogoutSuccess
  };
};

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(doLogout()),
  logoutExternal: () => dispatch(doExternalLogout())
});

export default connect(mapStateToProps, mapDispatchToProps)(ErrorPage);
