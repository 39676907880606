import React, { Component, Fragment } from "react";
import {
  Accordion,
  Button,
  Confirm,
  Grid,
  Icon,
  Divider,
  Input,
  Segment,
  Dropdown,
  Form
} from "semantic-ui-react";
import {
  CustomHeaderLabel,
  CustomHeaderBold,
  CustomLabel
} from "../../../components/labels/labels";

import "../../../assets/css/label-order-cart.css";

class CombinationLabelOrderItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeIndex: 0,
      isConfirming: false,
      toRemoveId: null,
      file: null
    };

    this.handleFileChange.bind(this);
  }

  confirm = () => {
    this.setState({ isConfirming: true });
  };

  handleCancel = () => {
    this.setState({ isConfirming: false });
  };

  handleConfirm = () => {
    this.setState({ isConfirming: false });
    this.props.removeLabelOrderCartItem(this.state.toRemoveId);
  };

  handleFileChange = (event) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      var fileReader = new FileReader();
      const changeOrderCartItemValue = this.props.changeOrderCartItemValue;
      const uploadCartItemAttachment = this.props.uploadCartItemAttachment;
      const orderCartItemId = this.props.data.id;

      changeOrderCartItemValue(orderCartItemId, "artworkFileName", file.name);

      this.setState({ file: file });
      fileReader.onload = function (fileLoadedEvent) {
        var srcData = fileLoadedEvent.target.result;
        changeOrderCartItemValue(orderCartItemId, "artworkLink", srcData);

        changeOrderCartItemValue(orderCartItemId, "file", file);
      };

      fileReader.readAsDataURL(file);
    } else this.setState({ file: null });
  };

  removeLabelOrderCartItem = (id) => {
    this.confirm();
    this.setState({ toRemoveId: id });
  };

  toggleDisplay = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  render() {
    const { activeIndex } = this.state;
    const { data, unitsOfMeasurement, changeOrderCartItemValue } = this.props;

    return (
      <Fragment>
        <Segment>
          <Accordion>
            <Accordion.Title
              active={activeIndex === 0}
              index={0}
              onClick={this.toggleDisplay}
            >
              <Grid columns={3} verticalAlign="middle">
                <Grid.Column width={3}>
                  <Icon name="dropdown" />
                  <CustomHeaderLabel message={data.fileNumber} />
                </Grid.Column>
                <Grid.Column width={9}>
                  <CustomHeaderLabel message={data.labelDescription} />
                </Grid.Column>
                <Grid.Column width={3} textAlign="right">
                  <Button
                    negative
                    onClick={(e) => this.removeLabelOrderCartItem(data.id)}
                  >
                    Remove
                  </Button>
                </Grid.Column>
              </Grid>
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 0}>
              <Divider />

              <Grid columns={2} stackable>
                <Grid.Row stretched>
                  <Grid.Column width={5} style={{ textAlign: "justify" }}>
                    <Grid columns={2}>
                      <Grid.Column width={16}>
                        <i>
                          Please fill out the details of the label you're
                          ordering and feel free to contact us for assistance
                        </i>
                      </Grid.Column>
                      <Grid.Column
                        width={16}
                        verticalAlign="bottom"
                      ></Grid.Column>
                    </Grid>
                  </Grid.Column>

                  <Grid.Column width={11}>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column width={6}>
                          <CustomHeaderBold message="Quantity" />
                          <input
                            type="number"
                            min="1"
                            placeholder="Quantity"
                            onKeyDown={(e) => {
                              if (e.keyCode === 8 || e.keyCode === 46) {
                                return false;
                              }
                            }}
                            onChange={(e, properties) => {
                              changeOrderCartItemValue(
                                data.id,
                                "quantity",
                                e.target.value
                              );
                            }}
                            className="combination-label-quantity-input"
                            maxLength="5"
                            value={data.quantity}
                          />
                        </Grid.Column>
                      </Grid.Row>

                      {/* <Grid.Row>
                        <Grid.Column width={8}>
                          <CustomHeaderBold message="Unit of measurement" />

                          <Dropdown
                            fluid
                            selection
                            options={unitsOfMeasurement || []}
                            disabled={
                              !unitsOfMeasurement ||
                              unitsOfMeasurement.length === 0
                            }
                            placeholder="Choose from the options"
                            value={data.unitOfMeasurementId}
                            onChange={(e, properties) => {
                              changeOrderCartItemValue(
                                data.id,
                                "unitOfMeasurementId",
                                properties.value
                              );
                            }}
                          />
                        </Grid.Column>
                      </Grid.Row> */}

                      <Grid.Row>
                        <Grid.Column width={6}>
                          <CustomHeaderBold message="Item number (optional)" />
                          <CustomLabel message={data.itemNumber} />
                          {/* <Input
                            placeholder="Item number"
                            value={data.itemNumber || ""}
                            onChange={(e, properties) => {
                              changeOrderCartItemValue(
                                data.id,
                                "itemNumber",
                                properties.value
                              );
                            }}
                            maxLength="20"
                          /> */}
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Column width={10}>
                          <CustomHeaderBold message="Artwork" />

                          <div style={{ float: "left", marginRight: "15px" }}>
                            <Button
                              primary
                              as="label"
                              htmlFor="file"
                              type="button"
                              style={{ border: "none" }}
                            >
                              Choose File
                            </Button>
                            <input
                              type="file"
                              id="file"
                              name="file"
                              style={{ display: "none" }}
                              onChange={this.handleFileChange}
                            />
                          </div>

                          <div
                            style={{ float: "left" }}
                            hidden={
                              this.state.file == null &&
                              !this.props.data.artworkLink
                            }
                          >
                            <div style={{ position: "relative" }}>
                              <Icon
                                name="close"
                                className="remove-image"
                              ></Icon>
                              <img
                                src={this.props.data.artworkLink || ""}
                                style={{ width: "180px" }}
                              />
                            </div>

                            {this.props.data.artworkFileName || ""}
                          </div>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Accordion.Content>
          </Accordion>
        </Segment>
        <Confirm
          size="mini"
          open={this.state.isConfirming}
          header="Remove order cart item"
          content="Are you sure you want to continue?"
          onCancel={this.handleCancel}
          onConfirm={this.handleConfirm}
        />
      </Fragment>
    );
  }
}

export default CombinationLabelOrderItem;
