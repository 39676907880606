import React, { Component, Fragment } from "react";
import { Table, Button, Menu, Popup, Icon } from "semantic-ui-react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as actions from "./cancel-order.action";
import _ from "lodash";

class CancelOrderItem extends Component {
  getStatus() {
    const { data } = this.props;
    return data.statusName;
  }

  render() {
    const { data } = this.props;
    let orderCount = data.orderCount;
    let quantity = data.totalQuantity;
    let price = data.totalPrice;

    if (data.childItems.length) {
      orderCount = data.childItems.reduce((c, n) => c + n.orderCount || 0, 0);
      quantity = data.childItems.reduce((c, n) => c + n.totalQuantity || 0, 0);
      price = data.childItems.reduce((c, n) => c + n.totalPrice || 0, 0);
    }

    return (
      <Fragment>
        <Table.Row>
          <Table.Cell>{this.getStatus()}</Table.Cell>
          <Table.Cell>{data.requestNumber}</Table.Cell>
          <Table.Cell>{data.oracleNumber}</Table.Cell>
          <Table.Cell>{data.orderDate}</Table.Cell>
          <Table.Cell>{orderCount}</Table.Cell>
          <Table.Cell>{Number.format(quantity, 0)}</Table.Cell>
          <Table.Cell>{Number.format(price, 2)}</Table.Cell>
          <Table.Cell>
            <Button
              className={data.isLoading}
              disabled={data.isDisable}
              size="mini"
              primary
              onClick={this.props.onCancelling.bind(this, data, true)}
            >
              Cancel
            </Button>
          </Table.Cell>
          {/* <Table.Cell>{data.comments}</Table.Cell> */}
          <Table.Cell textAlign="center">
            {data.childItems.length ? (
              <Popup
                content={
                  data.isExpandMultiple
                    ? "Hide Multiple Orders"
                    : "Show Multiple Orders"
                }
                position="top center"
                flowing
                trigger={
                  <Button
                    basic
                    compact
                    color="blue"
                    icon
                    onClick={this.props.handleExpandMultipleOrders.bind(
                      this,
                      data.id
                    )}
                  >
                    <Icon name="copy outline" />
                  </Button>
                }
              ></Popup>
            ) : (
              <Popup
                content="Show Item"
                position="top center"
                flowing
                trigger={
                  <Button
                    basic
                    compact
                    color="blue"
                    icon
                    onClick={this.props.onViewItem.bind(this, data)}
                  >
                    <Icon name="file outline" />
                  </Button>
                }
              ></Popup>
            )}
          </Table.Cell>
        </Table.Row>
        {data.isExpandMultiple && data.childItems
          ? data.childItems.map((d, i) => {
              return (
                <Table.Row active key={i}>
                  <Table.Cell>{d.statusName}</Table.Cell>
                  <Table.Cell>{d.requestNumber}</Table.Cell>
                  <Table.Cell>{d.oracleNumber}</Table.Cell>
                  <Table.Cell>{d.orderDate}</Table.Cell>
                  <Table.Cell>{d.orderCount}</Table.Cell>
                  <Table.Cell>{d.quantityFormatted}</Table.Cell>
                  <Table.Cell>
                    {Number.format(parseFloat(d.priceFormatted), 2)}
                  </Table.Cell>
                  <Table.Cell>
                    <Button
                      className={d.isLoading}
                      disabled={d.isDisable}
                      size="mini"
                      primary
                      onClick={this.props.onCancelling.bind(this, d, false)}
                    >
                      Cancel
                    </Button>
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    <Popup
                      content="Show Item"
                      position="top center"
                      flowing
                      trigger={
                        <Button
                          basic
                          compact
                          color="blue"
                          icon
                          onClick={this.props.onViewItem.bind(this, d)}
                        >
                          <Icon name="copy outline" />
                        </Button>
                      }
                    ></Popup>
                  </Table.Cell>
                </Table.Row>
              );
            })
          : null}
      </Fragment>
    );
  }
}

CancelOrderItem.propTypes = {
  data: PropTypes.object.isRequired
};

const mapDispatchToProps = (dispatch) => {
  return {
    doCancelOrder: (param) => dispatch(actions.doCancelOrder(param))
  };
};

export default connect(null, mapDispatchToProps)(CancelOrderItem);
