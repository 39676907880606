import * as types from './price-management.type';
import api from '../../services/api';

export const priceManagementResult = (obj, type) => {
  return {
    type: type,
    data: obj,
  };
};

export const getLabels = () => {
  return async dispatch => {
    dispatch(priceManagementResult(null, types.GET_LABELS_REQUEST));
    await api.LabelOrder.getLabels()
      .then(result => {
        dispatch(priceManagementResult(result, types.GET_LABELS_SUCCESS));
      })
      .catch(result => {
        dispatch(
          priceManagementResult(
            result.response && result.response.data,
            types.GET_LABELS_FAILURE
          )
        );
      });
  };
};

export const getCombinationLabels = () => {
  return async dispatch => {
    dispatch(priceManagementResult(null, types.GET_COMBINATION_LABELS_REQUEST));
    const result = await api.LabelOrder.getCombinationLabels();
    if (result) {
      dispatch(
        priceManagementResult(result, types.GET_COMBINATION_LABELS_SUCCESS)
      );
      return result;
    } else {
      dispatch(
        priceManagementResult(
          result.response && result.response.data,
          types.GET_COMBINATION_LABELS_FAILURE
        )
      );
    }
  };
};

export const updateLabels = data => {
  return async dispatch => {
    dispatch(priceManagementResult(null, types.UPDATE_LABELS_REQUEST));
    return await api.LabelOrder.updateLabels(data)
      .then(result => {
        dispatch(priceManagementResult(result, types.UPDATE_LABELS_SUCCESS));
        return result;
      })
      .catch(result => {
        dispatch(
          priceManagementResult(
            result.response && result.response.data,
            types.UPDATE_LABELS_FAILURE
          )
        );
      });
  };
};

export const updateCombinationLabels = data => {
  return async dispatch => {
    dispatch(
      priceManagementResult(null, types.UPDATE_COMBINATIONS_LABELS_REQUEST)
    );
    return await api.LabelOrder.updateCombinationLabels(data)
      .then(result => {
        dispatch(
          priceManagementResult(
            result,
            types.UPDATE_COMBINATIONS_LABELS_SUCCESS
          )
        );
        return result;
      })
      .catch(result => {
        dispatch(
          priceManagementResult(
            result.response && result.response.data,
            types.UPDATE_COMBINATIONS_LABELS_FAILURE
          )
        );
      });
  };
};

export const updateCustomerCombinationLabel = data => {
  return async dispatch => {
    dispatch(
      priceManagementResult(
        null,
        types.UPDATE_CUSTOMER_COMBINATIONS_LABEL_REQUEST
      )
    );
    return await api.LabelOrder.updateCustomerCombinationLabel(data)
      .then(result => {
        dispatch(
          priceManagementResult(
            result,
            types.UPDATE_CUSTOMER_COMBINATIONS_LABEL_SUCCESS
          )
        );
        return result;
      })
      .catch(result => {
        dispatch(
          priceManagementResult(
            result.response && result.response.data,
            types.UPDATE_CUSTOMER_COMBINATIONS_LABEL_FAILURE
          )
        );
      });
  };
};

export const addSingleCustomerCombinationLabel = data => {
  return async dispatch => {
    dispatch(
      priceManagementResult(
        null,
        types.ADD_SINGLE_CUSTOMER_COMBINATIONS_LABEL_REQUEST
      )
    );
    return await api.LabelOrder.addSingleCustomerCombinationLabel(data)
      .then(result => {
        dispatch(
          priceManagementResult(
            result,
            types.ADD_SINGLE_CUSTOMER_COMBINATIONS_LABEL_SUCCESS
          )
        );
        return result;
      })
      .catch(result => {
        dispatch(
          priceManagementResult(
            result.response && result.response.data,
            types.ADD_SINGLE_CUSTOMER_COMBINATIONS_LABEL_FAILURE
          )
        );
      });
  };
};

export const updateLabelsPrice = data => {
  return async dispatch => {
    dispatch(priceManagementResult(null, types.UPDATE_LABELS_PRICE_REQUEST));
    return await api.LabelOrder.updateLabelsPrice(data)
      .then(result => {
        dispatch(
          priceManagementResult(result, types.UPDATE_LABELS_PRICE_SUCCESS)
        );
        return result;
      })
      .catch(result => {
        dispatch(
          priceManagementResult(
            result.response && result.response.data,
            types.UPDATE_LABELS_PRICE_FAILURE
          )
        );
      });
  };
};

export const viewLabels = (
  labels,
  nonDenominationalAccounts,
  uploadType,
  labelSupplierEmailsFiltered,
  combinationAllowedCcns,
  currencyHandlingFees,
  standardLabelsFiltered
) => {
  return async dispatch => {
    dispatch(priceManagementResult(null, types.VIEW_ACTIVE_LABELS_REQUEST));

    dispatch(
      priceManagementResult(
        {
          labels,
          nonDenominationalAccounts,
          uploadType,
          labelSupplierEmailsFiltered,
          combinationAllowedCcns,
          currencyHandlingFees,
          standardLabelsFiltered,
        },
        types.VIEW_ACTIVE_LABELS
      )
    );
  };
};

export const changePageLabels = result => {
  return dispatch =>
    dispatch(priceManagementResult(result, types.LABELS_CHANGE_PAGE));
};

export const applyFilter = result => {
  return dispatch =>
    dispatch(priceManagementResult(result, types.APPLY_FILTER));
};

export const changePage = result => {
  return dispatch => dispatch(priceManagementResult(result, types.CHANGE_PAGE));
};

export const updateData = result => {
  return dispatch =>
    dispatch(priceManagementResult(result, types.LABEL_MNGMNT_DATA));
};

export const updateCombinationData = result => {
  return dispatch =>
    dispatch(
      priceManagementResult(result, types.COMBINATION_LABEL_MNGMNT_DATA)
    );
};

export const updateLabelSupplierEmailTemplateData = result => {
  return dispatch =>
    dispatch(
      priceManagementResult(
        result,
        types.LABEL_SUPPLIER_EMAIL_TEMPLATE_MNGMT_DATA
      )
    );
};

export const updateCombinationAllowedCcnTemplateData = result => {
  return dispatch =>
    dispatch(
      priceManagementResult(
        result,
        types.COMBINATION_ALLOWED_CCN_TEMPLATE_MNGMT_DATA
      )
    );
};

export const labelSupplierEmailUpload = data => {
  return async dispatch => {
    dispatch(
      priceManagementResult(
        data,
        types.LABEL_SUPPLIER_EMAIL_TEMPLATE_UPLOAD_REQUEST
      )
    );
    return await api.LabelSupplier.upsertLabelSupplierEmail(data)
      .then(result => {
        dispatch(
          priceManagementResult(
            result,
            types.LABEL_SUPPLIER_EMAIL_TEMPLATE_UPLOAD_SUCCESS
          )
        );
        return result;
      })
      .catch(result => {
        dispatch(
          priceManagementResult(
            null,
            types.LABEL_SUPPLIER_EMAIL_TEMPLATE_UPLOAD_FAILED
          )
        );
      });
  };
};

export const combinationAllowedCcnUpload = data => {
  return async dispatch => {
    dispatch(
      priceManagementResult(
        data,
        types.COMBINATION_ALLOWED_CCN_TEMPLATE_UPLOAD_REQUEST
      )
    );
    return await api.LabelSupplier.upsertCombinationAllowedCcn(data)
      .then(result => {
        dispatch(
          priceManagementResult(
            result,
            types.COMBINATION_ALLOWED_CCN_TEMPLATE_UPLOAD_SUCCESS
          )
        );
        return result;
      })
      .catch(result => {
        dispatch(
          priceManagementResult(
            null,
            types.COMBINATION_ALLOWED_CCN_TEMPLATE_UPLOAD_FAILED
          )
        );
      });
  };
};

export const forUpdateCourierData = result => {
  return dispatch =>
    dispatch(priceManagementResult(result, types.COURIER_FOR_UPDATE_DATA));
};

export const updateDataPrice = result => {
  return dispatch =>
    dispatch(priceManagementResult(result, types.PRICE_MNGMNT_DATA));
};

export const updateForm = result => {
  return dispatch =>
    dispatch(priceManagementResult(result, types.PRICE_MNGMNT_FORM));
};

export const updateChangeRelationshipForm = result => {
  return dispatch =>
    dispatch(priceManagementResult(result, types.UPDATE_CHANGE_RELATIONSHIP));
};

export const updateSingleRelationshipForm = result => {
  return dispatch =>
    dispatch(priceManagementResult(result, types.UPDATE_SINGLE_RELATIONSHIP));
};
export const clearForm = () => {
  return dispatch =>
    dispatch(priceManagementResult({}, types.PRICE_MNGMNT_CLEAR));
};

export const clearChangeRelationshipForm = () => {
  return dispatch =>
    dispatch(priceManagementResult({}, types.UPDATE_CHANGE_RELATIONSHIP_CLEAR));
};

export const clearSingleRelationshipForm = () => {
  return dispatch =>
    dispatch(priceManagementResult({}, types.UPDATE_SINGLE_RELATIONSHIP_CLEAR));
};

export const getCourierTracking = () => {
  return async dispatch => {
    dispatch(priceManagementResult(null, types.GET_COURIER_REQUEST));
    await api.LabelOrder.getCourierTracking()
      .then(result => {
        dispatch(priceManagementResult(result, types.GET_COURIER_SUCCESS));
      })
      .catch(result => {
        dispatch(
          priceManagementResult(result.response, types.GET_COURIER_FAILURE)
        );
      });
  };
};

export const updateCourierTracking = data => {
  return async dispatch => {
    dispatch(priceManagementResult(null, types.UPDATE_COURIER_REQUEST));
    return await api.LabelOrder.updateCourierTracking(data)
      .then(result => {
        dispatch(priceManagementResult(result, types.UPDATE_COURIER_SUCCESS));
        return result;
      })
      .catch(result => {
        dispatch(
          priceManagementResult(
            result.response && result.response.data,
            types.UPDATE_COURIER_FAILURE
          )
        );
      });
  };
};

export const refreshCsvData = data => {
  return dispatch =>
    dispatch(priceManagementResult(data, types.REFRESH_CSV_DATA));
};

export const getCombinationLabelFilter = data => {
  return dispatch =>
    dispatch(priceManagementResult(data, types.GET_COMBINATION_LABELS_FILTER));
};

export const getLabelSupplierPrinterEmailFilter = data => {
  return dispatch =>
    dispatch(
      priceManagementResult(data, types.LABEL_SUPPLIER_EMAIL_TEMPLATES_FILTER)
    );
};

export const clearCombinationLabelFilter = () => {
  return dispatch =>
    dispatch(priceManagementResult({}, types.CLEAR_COMBINATION_LABELS_FILTER));
};

export const clearLabelSupplierEmailFilter = () => {
  return dispatch =>
    dispatch(
      priceManagementResult({}, types.CLEAR_LABEL_SUPPLIER_EMAIL_FILTER)
    );
};
export const getNonDenominationalAccounts = () => {
  return async dispatch => {
    dispatch(
      priceManagementResult(null, types.NON_DENOMINATIONAL_LABELS_REQUEST)
    );
    await api.Manufacturer.getNonDenominationalAccounts()
      .then(result => {
        dispatch(
          priceManagementResult(result, types.NON_DENOMINATIONAL_LABELS_SUCCESS)
        );
      })
      .catch(result => {
        dispatch(
          priceManagementResult(
            result.response && result.response.data,
            types.NON_DENOMINATIONAL_LABELS_FAILURE
          )
        );
      });
  };
};

export const getLabelSupplierEmail = () => {
  return async dispatch => {
    dispatch(
      priceManagementResult(null, types.LABEL_SUPPLIER_EMAIL_TEMPLATES_REQUEST)
    );
    await api.LabelSupplier.getLabelSupplierPrinterEmail('', '')
      .then(result => {
        dispatch(
          priceManagementResult(
            result,
            types.LABEL_SUPPLIER_EMAIL_TEMPLATES_SUCCESS
          )
        );
      })
      .catch(result => {
        dispatch(
          priceManagementResult({}, types.LABEL_SUPPLIER_EMAIL_TEMPLATES_FAILED)
        );
      });
  };
};

export const getCombinationAllowedCcn = () => {
  return async dispatch => {
    dispatch(
      priceManagementResult(
        null,
        types.COMBINATION_ALLOWED_CCN_TEMPLATES_REQUEST
      )
    );
    await api.LabelSupplier.getCombinationAllowedCcn()
      .then(result => {
        dispatch(
          priceManagementResult(
            result,
            types.COMBINATION_ALLOWED_CCN_TEMPLATES_SUCCESS
          )
        );
      })
      .catch(result => {
        dispatch(
          priceManagementResult(
            {},
            types.COMBINATION_ALLOWED_CCN_TEMPLATES_FAILED
          )
        );
      });
  };
};

export const loadNonDenominationalAccounts = result => {
  return dispatch =>
    dispatch(
      priceManagementResult(result, types.LOAD_NON_DENOMINATIONAL_ACCOUNTS)
    );
};

export const createNonDenominationalAccounts = data => {
  return async dispatch => {
    dispatch(
      priceManagementResult(
        null,
        types.CREATE_NON_DENOMINATIONAL_ACCOUNTS_REQUEST
      )
    );
    return await api.Manufacturer.createNonDenominationalAccounts(data)
      .then(result => {
        dispatch(
          priceManagementResult(
            result,
            types.CREATE_NON_DENOMINATIONAL_ACCOUNTS_SUCCESS
          )
        );
        return result;
      })
      .catch(result => {
        dispatch(
          priceManagementResult(
            result.response && result.response.data,
            types.CREATE_NON_DENOMINATIONAL_ACCOUNTS_FAILURE
          )
        );
      });
  };
};

export const selectItemNumber = (id, checked) => {
  return dispatch =>
    dispatch(priceManagementResult({ id, checked }, types.SELECT_ITEM_NUMBER));
};

export const selectAllItems = checked => {
  return dispatch =>
    dispatch(priceManagementResult({ checked }, types.SELECT_ALL_ITEMS));
};

export const removeCombinationLabels = data => {
  return async dispatch => {
    dispatch(
      priceManagementResult(null, types.REMOVE_COMBINATION_LABELS_REQUEST)
    );
    const result = await api.LabelOrder.removeCombinationLabels(data);

    if (result) {
      dispatch(
        priceManagementResult(result, types.REMOVE_COMBINATION_LABELS_SUCCESS)
      );
      return result;
    } else {
      dispatch(
        priceManagementResult(
          result.response && result.response.data,
          types.REMOVE_COMBINATION_LABELS_FAILURE
        )
      );
    }
  };
};

export const resetRemoveLabels = () => {
  return dispatch =>
    dispatch(priceManagementResult(null, types.REMOVE_LABELS_RESET));
};

export const createCurrencyHandlingFees = data => {
  return async dispatch => {
    dispatch(
      priceManagementResult(null, types.CREATE_CURRENCY_HANDLING_FEE_REQUEST)
    );
    return await api.Order.createCurrencyHandlingFees(data)
      .then(result => {
        dispatch(
          priceManagementResult(
            result,
            types.CREATE_CURRENCY_HANDLING_FEE_SUCCESS
          )
        );
        return result;
      })
      .catch(result => {
        dispatch(
          priceManagementResult(
            result.response && result.response.data,
            types.CREATE_CURRENCY_HANDLING_FEE_FAILURE
          )
        );
      });
  };
};

export const getCurrencyHandlingFees = () => {
  return async dispatch => {
    dispatch(
      priceManagementResult(null, types.GET_CURRENCY_HANDLING_FEE_REQUEST)
    );
    await api.Order.getCurrencyHandlingFees()
      .then(result => {
        dispatch(
          priceManagementResult(result, types.GET_CURRENCY_HANDLING_FEE_SUCCESS)
        );
      })
      .catch(result => {
        dispatch(
          priceManagementResult(
            result.response && result.response.data,
            types.GET_CURRENCY_HANDLING_FEE_FAILURE
          )
        );
      });
  };
};

export const loadCurrencyHandlingFee = result => {
  return dispatch =>
    dispatch(priceManagementResult(result, types.LOAD_CURRENCY_HANDLING_FEE));
};

export const getConfigurationSettings = () => {
  return async dispatch => {
    dispatch(
      priceManagementResult(null, types.GET_CONFIGURATION_SETTINGS_REQUEST)
    );
    await api.LabelOrder.getConfigurationSettings()
      .then(result => {
        dispatch(
          priceManagementResult(
            result,
            types.GET_CONFIGURATION_SETTINGS_SUCCESS
          )
        );
      })
      .catch(result => {
        dispatch(
          priceManagementResult(
            result.response && result.response.data,
            types.GET_CONFIGURATION_SETTINGS_FAILURE
          )
        );
      });
  };
};

export const updateConfigurationSettings = data => {
  return async dispatch => {
    dispatch(
      priceManagementResult(null, types.UPDATE_CONFIGURATION_SETTINGS_REQUEST)
    );
    const result = await api.LabelOrder.updateConfigurationSettings(data);

    if (result) {
      dispatch(
        priceManagementResult(
          result,
          types.UPDATE_CONFIGURATION_SETTINGS_SUCCESS
        )
      );
      return result;
    } else {
      dispatch(
        priceManagementResult(
          result.response && result.response.data,
          types.UPDATE_CONFIGURATION_SETTINGS_FAILURE
        )
      );
    }
  };
};

export const createLabelSupplierEmail = data => {
  return async dispatch => {
    dispatch(
      priceManagementResult(data, types.CREATE_LABEL_SUPPLIER_EMAIL_REQUEST)
    );
    return await api.LabelSupplier.createLabelSupplierEmail(data)
      .then(result => {
        dispatch(
          priceManagementResult(
            result,
            types.CREATE_LABEL_SUPPLIER_EMAIL_SUCCESS
          )
        );
        return result;
      })
      .catch(result => {
        dispatch(
          priceManagementResult(null, types.CREATE_LABEL_SUPPLIER_EMAIL_FAILURE)
        );
      });
  };
};

export const updateLabelSupplierEmailForm = result => {
  return dispatch =>
    dispatch(
      priceManagementResult(result, types.UPDATE_LABEL_SUPPLIER_EMAIL_FORM)
    );
};

export const clearLabelSupplierEmailForm = () => {
  return dispatch =>
    dispatch(priceManagementResult({}, types.CLEAR_LABEL_SUPPLIER_EMAIL_FORM));
};

export const updateLabelItem = data => {
  return async dispatch => {
    dispatch(priceManagementResult(null, types.UPDATE_LABEL_ITEM_REQUEST));
    return await api.LabelOrder.updateLabelItem(data)
      .then(result => {
        dispatch(
          priceManagementResult(result, types.UPDATE_LABEL_ITEM_SUCCESS)
        );
        return result;
      })
      .catch(result => {
        dispatch(
          priceManagementResult(
            result.response && result.response.data,
            types.UPDATE_LABEL_ITEM_FAILURE
          )
        );
      });
  };
};

export const getLabelItems = (
  currentPage,
  pageSize,
  viewLtlItems,
  itemNumberSearch
) => {
  return async dispatch => {
    dispatch(priceManagementResult(null, types.GET_LABEL_ITEMS_REQUEST));
    await api.LabelOrder.getLabelItems(
      currentPage,
      pageSize,
      viewLtlItems,
      itemNumberSearch
    )
      .then(result => {
        dispatch(priceManagementResult(result, types.GET_LABEL_ITEMS_SUCCESS));
      })
      .catch(result => {
        dispatch(
          priceManagementResult(
            result.response && result.response.data,
            types.GET_LABEL_ITEMS_FAILURE
          )
        );
      });
  };
};

export const getStandardLabelTemplatesFilter = data => {
  return dispatch =>
    dispatch(
      priceManagementResult(data, types.STANDARD_LABEL_TEMPLATES_FILTER)
    );
};

export const clearStandardLabelTemplatesFilter = () => {
  return dispatch =>
    dispatch(
      priceManagementResult({}, types.CLEAR_STANDARD_LABEL_TEMPLATES_FILTER)
    );
};
