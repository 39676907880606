import * as types from "./register.type";
import api from "../../services/api";

export const registerResult = (obj, type) => {
  return {
    type: type,
    data: obj
  };
};

export const doRegister = model => {
  return async dispatch => {
    dispatch(registerResult(null, types.REGISTER_REQUEST));
    await api.User.register(model)
      .then(result => {
        dispatch(registerResult(result, types.REGISTER_SUCCESS));
      })
      .catch(result => {
        //console.log(result);
        dispatch(registerResult(result.response.data, types.REGISTER_FAILURE));
      });
  };
};

export const getPartySiteNumberDetails = partySiteNumber => {
  return async dispatch => {
    dispatch(registerResult(null, types.PARTYSITENUMBER_DETAILS_REQUEST));
    await api.PartySiteNumber.getPartySiteNumberDetails(partySiteNumber)
      .then(result => {
        dispatch(registerResult(result, types.PARTYSITENUMBER_DETAILS_SUCCESS));
      })
      .catch(result => {
        dispatch(
          registerResult(
            result.response.data,
            types.PARTYSITENUMBER_DETAILS_FAILURE
          )
        );
      });
  };
};

export const updateForm = result => {
  return dispatch => dispatch(registerResult(result, types.FORM));
};

export const clearRegister = () => dispatch =>
  dispatch(registerResult({}, types.CLEAR));
