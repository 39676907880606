import * as types from "./view-history.types";

import { renderDisc } from "../../../helpers/component-helper";
import _ from "lodash";

let initialState = {
  data: [],
  currentPage: 1,
  pageSize: 10,
  totalPages: 1,
  loading: false,
  isSuccessAssignProjectHandler: false,
  isSuccessUpdateOrderType: false,
  isSuccessSetFilterOption: false,
  isSuccess: false,
  projectHandlers: [],
  applicants: [],
  cities: [],
  states: [],
  filterOptions: {},
  form: {
    showErrors: false,
    validationErrors: {}
  }
};

const viewHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
    case types.FE_ORDER_HISTORY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.FE_ORDER_HISTORY_SUCCESS:
    case types.FE_ORDER_HISTORY_FAILURE:
      var result = Object.assign({}, state, {
        loading: false,
        currentPage: action.data.currentPage,
        pageSize: action.data.pageSize,
        totalPages: action.data.totalPages,
        isSuccess: true,
        data: action.data.data || initialState.data
      });

      result &&
        result.data.map((data) => {
          return { statusName: renderDisc(data.status) };
        });

      // var groupData = _.chain(result.data)
      //   .groupBy("orderNumber")
      //   .map((value, key) => {
      //     var result = {
      //       parent: value[0]
      //     };

      //     result.parent.childItems = value.slice(1);
      //     result.parent.isExpandable = result.parent.isExpandMultiSite;

      //     return result.parent;
      //   })
      //   .value();

      // result.data = groupData;

      return result;
    case types.FE_ORDER_HISTORY_BY_FILTER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.FE_ORDER_HISTORY_BY_FILTER_SUCCESS:
    case types.FE_ORDER_HISTORY_BY_FILTER_FAILURE:
      var result = Object.assign({}, state, {
        loading: false,
        currentPage: action.data.currentPage,
        pageSize: action.data.pageSize,
        totalPages: action.data.totalPages,
        data: action.data.data || initialState.data,
        isSuccess: true
      });

      return result;
    case types.FE_ORDER_HISTORY_BY_FILTER_BY_USER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.FE_ORDER_HISTORY_BY_FILTER_BY_USER_SUCCESS:
    case types.FE_ORDER_HISTORY_BY_FILTER_BY_USER_FAILURE:
      var result = Object.assign({}, state, {
        loading: false,
        currentPage: action.data.currentPage,
        pageSize: action.data.pageSize,
        totalPages: action.data.totalPages,
        data: action.data.data || initialState.data,
        isSuccess: true
      });

      return result;
    case types.FE_UPDATE_ORDER_TYPE_REQUEST:
      return {
        ...state,
        loading: true,
        isSuccessUpdateOrderType: false
      };
    case types.FE_UPDATE_ORDER_TYPE_SUCCESS:
    case types.FE_UPDATE_ORDER_TYPE_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        isSuccessUpdateOrderType: action.data
      });
    case types.FE_UPDATE_ORDER_TYPE_RESET:
      return {
        ...state,
        loading: false,
        isSuccessUpdateOrderType: false
      };
    case types.FE_ORDER_CHANGE_PAGE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.FE_ORDER_CHANGE_PAGE_SUCCESS:
    case types.FE_ORDER_CHANGE_PAGE_FAILURE:
      var result = Object.assign({}, state, {
        loading: false,
        currentPage: action.data.currentPage,
        pageSize: action.data.pageSize,
        totalPages: action.data.totalPages,
        data: action.data.data || initialState.data
      });

      return result;
    case types.FE_ORDER_GET_PROJECTHANDLER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.FE_ORDER_GET_PROJECTHANDLER_SUCCESS:
    case types.FE_ORDER_GET_PROJECTHANDLER_FAILURE:
      var result = Object.assign({}, state, {
        loading: false,
        projectHandlers:
          action.data.data.map((ph) => {
            return {
              key: ph.userId,
              value: ph.userId,
              text: ph.fullName
            };
          }) || initialState.projectHandlers
      });
      return result;
    case types.FE_ORDER_ASSIGN_PROJECTHANDLER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.FE_ORDER_ASSIGN_PROJECTHANDLER_SUCCESS:
    case types.FE_ORDER_ASSIGN_PROJECTHANDLER_FAILURE:
      var result = Object.assign({}, state, {
        loading: false,
        isSuccessAssignProjectHandler: action.data.isSuccessful
      });
      return result;
    case types.FE_ORDER_UNASSIGN_PROJECTHANDLER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.FE_ORDER_UNASSIGN_PROJECTHANDLER_SUCCESS:
    case types.FE_ORDER_UNASSIGN_PROJECTHANDLER_FAILURE:
      var result = Object.assign({}, state, {
        loading: false,
        isSuccessAssignProjectHandler: action.data.isSuccessful
      });
      return result;
    case types.FE_ORDER_ASSIGN_PROJECTHANDLER_RESET:
      return {
        ...state,
        loading: false,
        isSuccessAssignProjectHandler: false
      };
    case types.SET_FILTEROPTION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.SET_FILTEROPTION_SUCCESS:
    case types.SET_FILTEROPTION_FAILURE:
      var result = Object.assign({}, state, {
        loading: false,
        currentPage: 1,
        filterOptions: action.data.data || initialState.filterOptions,
        isSuccessSetFilterOption: action.data.isSuccessful
      });
      return result;
    case types.GET_FILTEROPTION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.GET_FILTEROPTION_SUCCESS:
    case types.GET_FILTEROPTION_FAILURE:
      var result = Object.assign({}, state, {
        loading: false,
        filterOptions: action.data.data || initialState.filterOptions,
        isSuccessSetFilterOption: action.data.isSuccessful
      });
      return result;
    case types.FE_ORDER_GET_APPLICANT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.FE_ORDER_GET_APPLICANT_SUCCESS:
    case types.FE_ORDER_GET_APPLICANT_FAILURE:
      var result = Object.assign({}, state, {
        loading: false,
        applicants:
          action.data.data.map((a) => {
            return {
              key: a.applicantId,
              value: a.applicantId,
              text: a.fullName
            };
          }) || initialState.applicants
      });
      return result;
    case types.FE_ORDER_GET_CITIES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.FE_ORDER_GET_CITIES_SUCCESS:
    case types.FE_ORDER_GET_CITIES_FAILURE:
      var result = Object.assign({}, state, {
        loading: false,
        cities:
          action.data.map((a) => {
            return {
              key: a.cityName,
              value: a.cityName,
              text: a.cityName
            };
          }) || initialState.cities
      });
      return result;
    case types.FE_ORDER_GET_STATES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.FE_ORDER_GET_STATES_SUCCESS:
    case types.FE_ORDER_GET_STATES_FAILURE:
      var result = Object.assign({}, state, {
        loading: false,
        states:
          action.data.map((a) => {
            return {
              key: a.stateCode,
              value: a.stateCode,
              text: a.stateName
            };
          }) || initialState.states
      });
      return result;
    case types.FILTEROPTION_RESET:
      return {
        ...state,
        loading: false,
        isSuccessSetFilterOption: false
      };
    case types.VIEW_HISTORY_FORM:
      var result = Object.assign({}, state, {
        form: action.data || initialState.form
      });
      return result;
    case types.DATA:
      return Object.assign({}, state, {
        isSuccess: false,
        data: action.data || initialState.form
      });
    case types.VIEW_HISTORY_CLEAR:
      var result = Object.assign({}, state, {
        form: {
          showErrors: false,
          validationErrors: {}
        },
        isSuccessSetFilterOption: false,
        isSuccess: false
      });

      return result;
  }
};

export default viewHistoryReducer;
