import React, { Component, createRef } from "react";
import CookieConsent from "react-cookie-consent";

class Cookies extends Component {
  contextRef = createRef();
  render() {
    return (
      <CookieConsent
        //debug={true}
        buttonText="Accept"
        style={{
          background: "rgba(33, 133, 208, 0.85)",
          fontSize: "14px"
        }}
        buttonStyle={{ marginRight: "5em" }}
        buttonClasses="ui inverted button"
        disableButtonStyles={true}
      >
        <span>
          <b>Cookies on UL</b>
          <br />
          We use cookies to personalize content and ads, to provide social media
          features and to analyze our traffic. We also share information about
          your use of our site with our social media, advertising and analytics
          partners.&nbsp;
          <a
            className="cookie-about"
            href="https://www.ul.com/customer-resources/online-policies/about-cookies"
            target="_blank"
          >
            About our cookies
          </a>
        </span>
      </CookieConsent>
    );
  }
}

export default Cookies;
