import * as types from "./change-password.type";
import api from "../../../services/api";

export const changePasswordResult = (obj, type) => {
  return {
    type: type,
    data: obj
  };
};

export const doChangePassword = (model, callback) => {
  return async dispatch => {
    await api.User.changePassword(model)
      .then(result => {
        // TODO: if success call callback
        // callback.call(this, result);
        dispatch(changePasswordResult(result, types.CHANGE_PASSWORD_SUCCESS));
      })
      .catch(result => {
        dispatch(
          changePasswordResult(
            result.response.data,
            types.CHANGE_PASSWORD_FAILURE
          )
        );
      });
  };
};

export const updateForm = result => {
  return dispatch => dispatch(changePasswordResult(result, types.FORM));
};

export const clearChangePassword = () => dispatch =>
  dispatch(changePasswordResult({}, types.CLEAR));
