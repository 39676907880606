export const uploadTemplate = {
  standardLabelManagementTemplate: 1,
  priceListManagementTemplate: 2,
  combinationLabelItemNumbersTemplate: 3,
  courierTrackingNumbersTemplate: 4,
  authorizedNdLabelTemplate: 5,
  authorizedLabelSupplierEmailTemplate: 6,
  combinationLabelEligibleCcnsTemplate: 7,
  standardLabelHandlingFeeTemplate: 8,
};
