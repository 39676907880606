export const UPDATE_SCHEDULING_FORM = "UPDATE_SCHEDULING_FORM";
export const RENDER_SCHEDULING_FORM = "RENDER_SCHEDULING_FORM";

export const COUNTRIES_PRELIM_ENGR_REQUEST = "COUNTRIES_PRELIM_ENGR_REQUEST";
export const COUNTRIES_PRELIM_ENGR_FAILURE = "COUNTRIES_PRELIM_ENGR_FAILURE";
export const COUNTRIES_PRELIM_ENGR_SUCCESS = "COUNTRIES_PRELIM_ENGR_SUCCESS";

export const CITIES_PRELIM_ENGR_REQUEST = "CITIES_PRELIM_ENGR_REQUEST";
export const CITIES_PRELIM_ENGR_FAILURE = "CITIES_PRELIM_ENGR_FAILURE";
export const CITIES_PRELIM_ENGR_SUCCESS = "CITIES_PRELIM_ENGR_SUCCESS";

export const STATES_PRELIM_ENGR_REQUEST = "STATES_PRELIM_ENGR_REQUEST";
export const STATES_PRELIM_ENGR_FAILURE = "STATES_PRELIM_ENGR_FAILURE";
export const STATES_PRELIM_ENGR_SUCCESS = "STATES_PRELIM_ENGR_SUCCESS";

export const COUNTRIES_FINAL_ENGR_REQUEST = "COUNTRIES_FINAL_ENGR_REQUEST";
export const COUNTRIES_FINAL_ENGR_FAILURE = "COUNTRIES_FINAL_ENGR_FAILURE";
export const COUNTRIES_FINAL_ENGR_SUCCESS = "COUNTRIES_FINAL_ENGR_SUCCESS";

export const CITIES_FINAL_ENGR_REQUEST = "CITIES_FINAL_ENGR_REQUEST";
export const CITIES_FINAL_ENGR_FAILURE = "CITIES_FINAL_ENGR_FAILURE";
export const CITIES_FINAL_ENGR_SUCCESS = "CITIES_FINAL_ENGR_SUCCESS";

export const STATES_FINAL_ENGR_REQUEST = "STATES_FINAL_ENGR_REQUEST";
export const STATES_FINAL_ENGR_FAILURE = "STATES_FINAL_ENGR_FAILURE";
export const STATES_FINAL_ENGR_SUCCESS = "STATES_FINAL_ENGR_SUCCESS";

export const COUNTRIES_AHJ_REQUEST = "COUNTRIES_AHJ_REQUEST";
export const COUNTRIES_AHJ_FAILURE = "COUNTRIES_AHJ_FAILURE";
export const COUNTRIES_AHJ_SUCCESS = "COUNTRIES_AHJ_SUCCESS";

export const CITIES_AHJ_REQUEST = "CITIES_AHJ_REQUEST";
export const CITIES_AHJ_FAILURE = "CITIES_AHJ_FAILURE";
export const CITIES_AHJ_SUCCESS = "CITIES_AHJ_SUCCESS";

export const STATES_AHJ_REQUEST = "STATES_AHJ_REQUEST";
export const STATES_AHJ_FAILURE = "STATES_AHJ_FAILURE";
export const STATES_AHJ_SUCCESS = "STATES_AHJ_SUCCESS";

export const GET_PPE_REQUEST = "GET_PPE_REQUEST";
export const GET_PPE_FAILURE = "GET_PPE_FAILURE";
export const GET_PPE_SUCCESS = "GET_PPE_SUCCESS";

export const GET_TRAININGS_REQUEST = "GET_TRAININGS_REQUEST";
export const GET_TRAININGS_FAILURE = "GET_TRAININGS_FAILURE";
export const GET_TRAININGS_SUCCESS = "GET_TRAININGS_SUCCESS";

export const UPDATE_SELECTED_PPES = "UPDATE_SELECTED_PPES";
export const UPDATE_SELECTED_TRAININGS = "UPDATE_SELECTED_TRAININGS";

export const POPULATE_SCHEDULING_FORM = "POPULATE_SCHEDULING_FORM";

export const RENDER_SCHEDULING_DETAILS = "RENDER_SCHEDULING_DETAILS";
