export const UPDATE_USER_PSN_REQUEST = 'UPDATE_USER_PSN_REQUEST';
export const UPDATE_USER_PSN_FAILURE = 'UPDATE_USER_PSN_FAILURE';
export const UPDATE_USER_PSN_SUCCESS = 'UPDATE_USER_PSN_SUCCESS';

export const UPDATE_USER_PSN_RESET = 'UPDATE_USER_PSN_RESET';

export const UPDATE_USER_ROLE_REQUEST = 'UPDATE_USER_ROLE_REQUEST';
export const UPDATE_USER_ROLE_FAILURE = 'UPDATE_USER_ROLE_FAILURE';
export const UPDATE_USER_ROLE_SUCCESS = 'UPDATE_USER_ROLE_SUCCESS';

export const UPDATE_USER_ROLE_RESET = 'UPDATE_USER_ROLE_RESET';

export const PSN_ASSIGNMENTS_REQUEST = 'PSN_ASSIGNMENTS_REQUEST';
export const PSN_ASSIGNMENTS_FAILURE = 'PSN_ASSIGNMENTS_FAILURE';
export const PSN_ASSIGNMENTS_SUCCESS = 'PSN_ASSIGNMENTS_SUCCESS';

export const GET_ACTIVE_PAGE = 'GET_ACTIVE_PAGE';

export const SET_FILTER_OPTION_COLUMNS = 'SET_FILTER_OPTION_COLUMNS';
