import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import jwtDecode from "jwt-decode";
import * as actions from "../auth/auth.action";
import { connect } from "react-redux";
import jwtEncode from "jwt-encode";

const SECURITY_KEY = `${process.env.REACT_APP_SECURITY_KEY}`;

var userId = null;

class Auth extends Component {
  state = {};

  componentDidMount() {
    var token = this.props.match.params.token;
    if (token && token !== null) {
      const value = jwtDecode(token);
      localStorage.setItem("token", JSON.stringify(token));
      userId = JSON.stringify(value.unique_name);
      localStorage.setItem("cartLabelOrderType", null);
      localStorage.setItem(
        "appToken",
        JSON.stringify(jwtEncode(value, SECURITY_KEY))
      );
    }
  }

  render() {
    if (userId != "") {
      console.log("auth.jsx: " + userId);
      return <Redirect to="/home" />;
    }

    return "Received Token: ";
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
