import {
 API_STATUS_REQUEST,
 API_STATUS_FAILURE,
 API_STATUS_SUCCESS
} from "./health-dashboard.type";
import api from "../../services/api";

const getApiStatusRequest = () => {
  return { type: API_STATUS_REQUEST };
};

const getApiStatusFailure = error => {
  return dispatch => dispatch({ type: API_STATUS_FAILURE, payload: error });
};

const getApiStatusSuccess = response => {
  return dispatch =>
    dispatch({ type: API_STATUS_SUCCESS, payload: response });
};

export const getApiStatus = (day, week, orderStatusDay, authWeek) => {
  return async dispatch => {
    dispatch(getApiStatusRequest());
    const result = await api.HealthDashboard.getApiStatus(day, week, orderStatusDay, authWeek);

    if (result) {
      dispatch(getApiStatusSuccess(result));
    } else {
      dispatch(getApiStatusFailure("Something went wrong."));
    }
  };
};