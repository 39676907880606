import React, { Component, Fragment } from "react";
import {
  Accordion,
  Grid,
  Icon,
  Divider,
  Segment,
  Form,
  Checkbox,
  GridColumn,
  Input
} from "semantic-ui-react";
import {
  CustomHeaderLabel,
  CustomLabel,
  LabelBold
} from "../../../components/labels/labels";

import { unixToLocaleDate } from "../field-evaluation-helper";

class SchedulingDetails extends Component {
  state = {
    activeIndex: -1,
    isConfirming: false,
    toRemoveId: null
  };

  toggleDisplay = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  render() {
    const { activeIndex } = this.state;
    const {
      reservationDetails,
      trainingSelections,
      ppeSelections
    } = this.props;
    console.log(reservationDetails);

    var _trainingSelections = null;
    var _ppeSelections = null;
    if (
      reservationDetails.preliminarySiteLocationSection &&
      reservationDetails.preliminarySiteLocationSection.trainingSelections
    ) {
      _trainingSelections = reservationDetails.preliminarySiteLocationSection.trainingSelections.split(
        "|"
      );
    }

    if (
      reservationDetails.preliminarySiteLocationSection &&
      reservationDetails.preliminarySiteLocationSection.ppeSelections
    ) {
      _ppeSelections = reservationDetails.preliminarySiteLocationSection.ppeSelections.split(
        "|"
      );
    }
    return (
      <Fragment>
        <Segment>
          <Accordion>
            <Accordion.Title
              active={activeIndex === 0}
              index={0}
              onClick={this.toggleDisplay}
            >
              <Grid columns={1}>
                <Grid.Column width={16}>
                  <Icon name="dropdown" />
                  <CustomHeaderLabel message="Scheduling details" />
                </Grid.Column>
              </Grid>
            </Accordion.Title>
            <Accordion.Content
              active={activeIndex === 0}
              className="print-expanded"
            >
              <Divider />
              <Form>
                <Grid>
                  <Grid.Column width={4}>
                    <CustomLabel message="INSPECTION SITE LOCATION" />
                  </Grid.Column>
                  <Grid.Column width={1}>
                    <Divider vertical />
                  </Grid.Column>
                  <Grid.Column width={10}>
                    <Grid>
                      <Grid.Column width={16}>
                        <span>
                          <label>
                            <b>Business/Residence Name</b>
                          </label>
                          <label className="custom-label">
                            {(reservationDetails.preliminarySiteLocationSection &&
                              reservationDetails.preliminarySiteLocationSection
                                .residenceName) ||
                              "-NOTHING PROVIDED-"}
                          </label>
                        </span>
                      </Grid.Column>

                      <Grid.Column width={16}>
                        <label>
                          <b>Address Line 1</b>
                        </label>
                        <label className="custom-label">
                          {(reservationDetails.preliminarySiteLocationSection &&
                            reservationDetails.preliminarySiteLocationSection
                              .addressLine1) ||
                            "-NOTHING PROVIDED-"}
                        </label>
                      </Grid.Column>

                      <Grid.Column width={16}>
                        <label>
                          <b>Address Line 2</b>
                        </label>
                        <label className="custom-label">
                          {(reservationDetails.preliminarySiteLocationSection &&
                            reservationDetails.preliminarySiteLocationSection
                              .addressLine2) ||
                            "-NOTHING PROVIDED-"}
                        </label>
                      </Grid.Column>

                      <Grid.Column width={16}>
                        <label>
                          <b>Address Line 3</b>
                        </label>
                        <label className="custom-label">
                          {(reservationDetails.preliminarySiteLocationSection &&
                            reservationDetails.preliminarySiteLocationSection
                              .addressLine3) ||
                            "-NOTHING PROVIDED-"}
                        </label>
                      </Grid.Column>

                      <Grid.Column width={16}>
                        <label>
                          <b>Country</b>
                        </label>
                        <label className="custom-label">
                          {(reservationDetails.preliminarySiteLocationSection &&
                            reservationDetails.preliminarySiteLocationSection
                              .country) ||
                            "-NOTHING PROVIDED-"}
                        </label>
                      </Grid.Column>
                      <Grid.Column width={16}>
                        <label>
                          <b>State</b>
                        </label>
                        <label className="custom-label">
                          {(reservationDetails.preliminarySiteLocationSection &&
                            reservationDetails.preliminarySiteLocationSection
                              .state) ||
                            "-NOTHING PROVIDED-"}
                        </label>
                      </Grid.Column>
                      <Grid.Column width={16}>
                        <label>
                          <b>City</b>
                        </label>
                        <label className="custom-label">
                          {(reservationDetails.preliminarySiteLocationSection &&
                            reservationDetails.preliminarySiteLocationSection
                              .city) ||
                            "-NOTHING PROVIDED-"}
                        </label>
                      </Grid.Column>

                      <Grid.Column width={16}>
                        <label>
                          <b>Postal Code</b>
                        </label>
                        <label className="custom-label">
                          {(reservationDetails.preliminarySiteLocationSection &&
                            reservationDetails.preliminarySiteLocationSection
                              .postalCode) ||
                            "-NOTHING PROVIDED-"}
                        </label>
                      </Grid.Column>

                      <Grid.Column width={16}>
                        <label>
                          <b>Site Contact Name</b>
                        </label>
                        <label className="custom-label">
                          {(reservationDetails.preliminarySiteLocationSection &&
                            reservationDetails.preliminarySiteLocationSection
                              .siteContactName) ||
                            "-NOTHING PROVIDED-"}
                        </label>
                      </Grid.Column>
                      <Grid.Column width={16}>
                        <label>
                          <b>Site Contact Office Tel</b>
                        </label>
                        <label className="custom-label">
                          {(reservationDetails.preliminarySiteLocationSection &&
                            reservationDetails.preliminarySiteLocationSection
                              .siteContactOfficeNumber) ||
                            "-NOTHING PROVIDED-"}
                        </label>
                      </Grid.Column>
                      <Grid.Column width={16}>
                        <label>
                          <b>Site Contact Cell</b>
                        </label>
                        <label className="custom-label">
                          {(reservationDetails.preliminarySiteLocationSection &&
                            reservationDetails.preliminarySiteLocationSection
                              .siteContactMobileNumber) ||
                            "-NOTHING PROVIDED-"}
                        </label>
                      </Grid.Column>

                      <Grid.Column width={16}>
                        <label>
                          <b>Site Contact Email</b>
                        </label>
                        <label className="custom-label">
                          {(reservationDetails.preliminarySiteLocationSection &&
                            reservationDetails.preliminarySiteLocationSection
                              .siteContactEmailAddress) ||
                            "-NOTHING PROVIDED-"}
                        </label>
                      </Grid.Column>
                      <Grid.Column width={16}>
                        <label>
                          <b>Schedule Date & Time of Inspection</b>
                        </label>
                        <label className="custom-label">
                          {(reservationDetails.preliminarySiteLocationSection &&
                            unixToLocaleDate(
                              reservationDetails.preliminarySiteLocationSection
                                .scheduleUnixTime
                            )) ||
                            "-NOTHING PROVIDED-"}
                        </label>
                      </Grid.Column>
                    </Grid>
                  </Grid.Column>
                </Grid>

                <Divider />

                <Grid>
                  <Grid.Column width={4}>
                    <CustomLabel message="SITE SPECIFIC REQUIREMENTS" />
                  </Grid.Column>
                  <Grid.Column width={1}>
                    <Divider vertical />
                  </Grid.Column>
                  <Grid.Column width={10}>
                    <Grid.Row columns={2}>
                      <Grid.Column>
                        {trainingSelections &&
                          trainingSelections.map(training => {
                            return (
                              <span>
                                <Divider fitted hidden />
                                <Grid>
                                  <Grid.Column width={6}>
                                    <label>
                                      <b>{training.text}</b>
                                    </label>
                                  </Grid.Column>
                                  <Grid.Column width={6}>
                                    <label>
                                      {_trainingSelections &&
                                      _trainingSelections.includes(
                                        training.value.toString()
                                      )
                                        ? "Yes"
                                        : "No"}
                                    </label>
                                  </Grid.Column>
                                </Grid>
                              </span>
                            );
                          })}

                        <Divider fitted hidden />
                        <label>
                          <b>Describe other requirements</b>
                        </label>
                        <label className="custom-label">
                          {(reservationDetails.preliminarySiteLocationSection &&
                            reservationDetails.preliminarySiteLocationSection
                              .otherTrainingDetails) ||
                            "-NOTHING PROVIDED-"}
                        </label>
                      </Grid.Column>
                      <Divider hidden />
                      <Grid.Column>
                        <label>
                          <b>
                            Provide any security steps necessary for the
                            engineer to access the site
                          </b>
                        </label>
                        <label className="custom-label">
                          {(reservationDetails.preliminarySiteLocationSection &&
                            reservationDetails.preliminarySiteLocationSection
                              .securityStepDetail) ||
                            "-NOTHING PROVIDED-"}
                        </label>
                      </Grid.Column>
                    </Grid.Row>
                    <Divider hidden />
                    <Grid.Row>
                      <Grid.Column>
                        <LabelBold
                          message={
                            "Select all required PPE from the list below"
                          }
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Divider hidden />
                    <Grid.Row columns="2">
                      <GridColumn>
                        {ppeSelections &&
                          ppeSelections
                            .filter(p => p.value <= 5)
                            .map(ppe => {
                              return (
                                <span>
                                  <Divider fitted hidden />
                                  <Grid>
                                    <Grid.Column width={6}>
                                      <label>
                                        <b>{ppe.text}</b>
                                      </label>
                                    </Grid.Column>
                                    <Grid.Column width={6}>
                                      <label>
                                        {_ppeSelections &&
                                        _ppeSelections.includes(
                                          ppe.value.toString()
                                        )
                                          ? "Yes"
                                          : "No"}
                                      </label>
                                    </Grid.Column>
                                  </Grid>
                                </span>
                              );
                            })}
                      </GridColumn>
                      <GridColumn>
                        {ppeSelections &&
                          ppeSelections
                            .filter(p => p.value >= 6)
                            .map(ppe => {
                              return (
                                <span>
                                  <Divider fitted hidden />
                                  <Grid>
                                    <Grid.Column width={6}>
                                      <label>
                                        <b>{ppe.text}</b>
                                      </label>
                                    </Grid.Column>
                                    <Grid.Column width={6}>
                                      <label>
                                        {_ppeSelections &&
                                        _ppeSelections.includes(
                                          ppe.value.toString()
                                        )
                                          ? "Yes"
                                          : "No"}
                                      </label>
                                    </Grid.Column>
                                  </Grid>
                                </span>
                              );
                            })}
                      </GridColumn>
                    </Grid.Row>
                  </Grid.Column>
                </Grid>
              </Form>
            </Accordion.Content>
          </Accordion>
        </Segment>
      </Fragment>
    );
  }
}

export default SchedulingDetails;
