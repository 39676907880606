import {
  LABEL_ORDER_CART_REVIEW_CHECK_OUT_REQUEST,
  LABEL_ORDER_CART_REVIEW_CHECK_OUT_SUCCESS,
  LABEL_ORDER_CART_REVIEW_CHECK_OUT_FAILURE,
  LABEL_ORDER_CART_REVIEW_CONFIRM_CHECK_OUT_REQUEST,
  LABEL_ORDER_CART_REVIEW_CONFIRM_CHECK_OUT_ACCEPT,
  LABEL_ORDER_CART_REVIEW_CONFIRM_CHECK_OUT_REJECT,
  LABEL_ORDER_CART_REVIEW_GET_CUSTOMER_INFO_REQUEST,
  LABEL_ORDER_CART_REVIEW_GET_CUSTOMER_INFO_SUCCESS,
  LABEL_ORDER_CART_REVIEW_GET_CUSTOMER_INFO_FAILURE,
  LABEL_ORDER_CART_REVIEW_GET_LABEL_ORDER_CART_REQUEST,
  LABEL_ORDER_CART_REVIEW_GET_LABEL_ORDER_CART_SUCCESS,
  LABEL_ORDER_CART_REVIEW_GET_LABEL_ORDER_CART_FAILURE,
  LABEL_ORDER_CART_REVIEW_INITIALIZE,
  LABEL_ORDER_CART_REVIEW_REMOVE_CHECK_OUT_MESSAGE,
} from "./label-order-cart-review.type";

const initialState = {
  hasError: false,
  message: "",
  loading: false,
  data: null,
  orderConfirmationNumber: null,
  checkoutSuccess: false,
  checkoutFailed: false,
  customerInfo: null,
  isConfirmingCheckout: false,
  messageTimeout: 5000,
  order: {
    customerPoNumber: null,
    billToCustomerLocationId: null,
    billToValue: null,
    shipToCustomerLocationId: null,
    shipToValue: null,
    labelSupplierId: null,
    labelSupplierName: null,
    labelCenterId: null,
    instructions: null,
    comments: null,
    brokerInformation: null,
  },
  redirectToConfirmationPage: false,
  totalNetPrice: 0,
};

const labelOrderCartReviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case LABEL_ORDER_CART_REVIEW_CHECK_OUT_REQUEST:
      return {
        ...state,
        loadingCheckout: true,
        checkoutSuccess: false,
        checkoutFailed: false,
      };
    case LABEL_ORDER_CART_REVIEW_CHECK_OUT_SUCCESS:
      return {
        ...state,
        orderConfirmationNumber: action.payload,
        loadingCheckout: false,
        checkoutSuccess: true,
        checkoutFailed: false,
      };
    case LABEL_ORDER_CART_REVIEW_CHECK_OUT_FAILURE:
      return {
        ...state,
        loadingCheckout: false,
        checkoutSuccess: false,
        checkoutFailed: true,
      };
    case LABEL_ORDER_CART_REVIEW_CONFIRM_CHECK_OUT_REQUEST:
      return {
        ...state,
        isConfirmingCheckout: true,
      };
    case LABEL_ORDER_CART_REVIEW_CONFIRM_CHECK_OUT_ACCEPT:
    case LABEL_ORDER_CART_REVIEW_CONFIRM_CHECK_OUT_REJECT:
      return {
        ...state,
        isConfirmingCheckout: false,
      };
    case LABEL_ORDER_CART_REVIEW_GET_CUSTOMER_INFO_REQUEST:
      return {
        ...state,
        loadingCustomerInfo: true,
      };
    case LABEL_ORDER_CART_REVIEW_GET_CUSTOMER_INFO_SUCCESS:
      return {
        ...state,
        loadingCustomerInfo: false,
        customerInfo: action.payload,
      };
    case LABEL_ORDER_CART_REVIEW_GET_CUSTOMER_INFO_FAILURE:
      return {
        ...state,
        loadingCustomerInfo: false,
      };
    case LABEL_ORDER_CART_REVIEW_GET_LABEL_ORDER_CART_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case LABEL_ORDER_CART_REVIEW_GET_LABEL_ORDER_CART_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        data: action.payload,
        order: {
          customerPoNumber: action.payload.customerPoNumber,
          billToCustomerLocationId: action.payload.billToCustomerLocationId,
          billToValue: action.payload.billToValue,
          shipToCustomerLocationId: action.payload.shipToCustomerLocationId,
          shipToValue: action.payload.shipToValue,
          labelSupplierId: action.payload.labelSupplierId,
          labelSupplierName: action.payload.labelSupplierName,
          labelCenterId: action.payload.labelCenterId,
          instructions: action.payload.instructions,
          comments: action.payload.comments,
          shipToContactName: action.payload.shipToContactName,
          billToContactName: action.payload.billToContactName,
          manufacturerName: action.payload.manufacturerName,
          manufacturerAccountNumber: action.payload.manufacturerAccountNumber,
          manufacturerAddress: action.payload.manufacturerAddress,
          brokerInformation: action.payload.brokerInformation,
          customerEmailAddress: action.payload.customerEmailAddress,
          customerPhoneNumber: action.payload.customerPhoneNumber,
          billToCompany: action.payload.billToCompany,
          billToAccountNo: action.payload.billToAccountNo,
        },
      };
    case LABEL_ORDER_CART_REVIEW_GET_LABEL_ORDER_CART_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
      };
    case LABEL_ORDER_CART_REVIEW_INITIALIZE:
      return initialState;
    case LABEL_ORDER_CART_REVIEW_REMOVE_CHECK_OUT_MESSAGE:
      return {
        ...state,
        checkoutFailed: false,
        checkoutSuccess: false,
      };
    default:
      return state;
  }
};

export default labelOrderCartReviewReducer;
