import React, { Component, Fragment } from 'react';
import CcnStatus from '../../enums/ccn-status';
import {
  Container,
  Grid,
  Segment,
  Form,
  Button,
  Dropdown,
  Divider,
  Table,
  Label,
  Pagination,
  Message,
  Modal,
  Icon,
  Popup,
  Radio,
  Card,
  Image,
  List,
  Input,
  Checkbox,
  TabPane,
  Tab,
} from 'semantic-ui-react';
import {
  CustomHeader,
  CustomTitle,
  CustomLabel,
} from '../../components/labels/labels';
import XLSX from 'xlsx';
import { get_header_row } from './common/make-columns';
import {
  validate,
  validateField,
  getError,
  createValidation,
} from '../../validator/validator';
import * as actions from './price-management.action';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import PriceManagementItem from './price-management-item';
import ToastHelper from '../../helpers/toast.helper';
import ViewLabelsDialog from './dialogs/view-labels-dialog';
import { Common } from '../../common';
import Role from '../../enums/role';
import { email, required } from '../../validator/error-message';
import { toast } from 'react-semantic-toasts';
import * as commonActions from '../../views/user/common/common.action';
import { uploadTemplate } from '../../enums/upload-type';

const uploadType = [
  {
    key: '1',
    text: 'STD Label Management Template',
    value: uploadTemplate.standardLabelManagementTemplate,
  },
  {
    key: '2',
    text: 'Pricelist Management Template',
    value: uploadTemplate.priceListManagementTemplate,
  },
  {
    key: '3',
    text: 'Combo Label Item numbers Template',
    value: uploadTemplate.combinationLabelItemNumbersTemplate,
  },
  {
    key: '4',
    text: 'Courier and Tracking numbers Template',
    value: uploadTemplate.courierTrackingNumbersTemplate,
  },
  {
    key: '5',
    text: 'Authorized ND Label Template',
    value: uploadTemplate.authorizedNdLabelTemplate,
  },
  {
    key: '6',
    text: 'Authorized Label Supplier Email Template',
    value: uploadTemplate.authorizedLabelSupplierEmailTemplate,
  },
  {
    key: '7',
    text: 'Combo Label Eligible CCNs Template',
    value: uploadTemplate.combinationLabelEligibleCcnsTemplate,
  },
  {
    key: '8',
    text: 'STD Handling Fee Template',
    value: uploadTemplate.standardLabelHandlingFeeTemplate,
  },
];

const rules = [validate('effectiveDate', 'Effective Date', required)];
const changeRelationshipRules = [
  validate('wrSoldToAccntNo', 'Sold To Account No.', required),
  validate('wrShipToAccntNo', 'Ship To Account No.', required),
  validate('wrBasicFileNo', 'Basic File No', required),
  validate('wrItemNo', 'Item Number', required),
  validate('crSoldToAccntNo', 'Sold To Account No.', required),
  validate('crShipToAccntNo', 'Ship To Account No.', required),
  validate('crBasicFileNo', 'Basic File No', required),
  validate('crItemNo', 'Item Number', required),
];

const singleRelationshipRules = [
  validate('soldToAccntNo', 'Sold To Account No.', required),
  validate('shipToAccntNo', 'Ship To Account No.', required),
  validate('basicFileNo', 'Basic File No', required),
  validate('itemNo', 'Item Number', required),
];
const labelSupplierEmailFormRules = [
  validate('printerFileNumber', 'Printer File Number', required),
  validate('printerPsn', 'Printer PSN', required),
  validate('emailAddress', 'Email Address', required, email),
];

const allowedFileTypes = ['xlsx', 'xls', 'csv', 'xlsm'];
const customerCombinationFields = [
  'Appl Account#',
  'Mfr Account#',
  'Basic File#',
  'Item Number',
];

const labelSupplierPrinterEmailFields = [
  'Printer File Number',
  'Printer PSN',
  'Email Address',
  'Email Status',
];

const comboAllowedCcnFields = ['CCN', 'Service Type', 'Status'];

const nonDenominationalFields = [
  'Manufacturer Account#',
  'Manufacturer Customer Name',
  'Status',
];
const standardLabelFields = [
  'Label Acct',
  'CCN',
  'Label Description',
  'Item Number',
  'Label Order Package',
  'Min. Order Qty',
  'ORACLE UOM',
];
const pricelistFields = [
  'Product Value',
  'Product Description',
  'UOM',
  'Value',
];
const courierFields = [
  'Oracle Order Number',
  'Tracking number',
  'Courier Name',
];

const handlingFeeAllowedFields = ['Currency', 'Handling Fee'];

class PriceManagement extends Component {
  state = {
    file: {},
    data: [],
    cols: [],
    effectiveDate: new Date(),
    isOpen: false,
    isOpenErrorDialog: false,
    isOpenViewLabelsDialog: false,
    filter: null,
    filterStatus: 0,
    isChangeRelModalOpen: false,
    isSingleRelModalOpen: false,
    isCreateLabelSupplierEmailModalOpen: false,
    courierTrackingError: '',
    showErrors: false,
    showModalLtlWarehouse: false,
    selectedLabelItem: null,
    viewLtlItems: false,
    itemNumberSearch: '',
  };

  componentDidMount() {
    this.props.getLabels();
    this.props.getLabelItems(1, 10, false, null);
    this.props.updateData({ data: [], labels: [] });
    this.props.clearForm();
    this.props.getActivePage('Price/Label Management');
    this.props.getConfigurationSettings();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.priceManagement.mapLabels !==
      this.props.priceManagement.mapLabels
    ) {
      this.refreshCsvData();
    }
  }

  handleChange = e => {
    const files = e.target.files;
    if (files && files[0]) {
      this.setState({ file: files[0] });
      this.readFile(files[0]);
      e.target.value = null;
    }
  };

  hasDuplicateValue = (array, keyName) => {
    return new Set(array.map(item => item[keyName])).size !== array.length;
  };

  handleTextChange = e => {
    const { changeRelationshipForm } = this.props.priceManagement;

    const newState = validateField(
      changeRelationshipRules,
      changeRelationshipForm,
      e.target.name,
      e.target.value
    );
    this.props.updateChangeRelationshipForm(newState);
  };

  handleTextChangeSingleRel = e => {
    const { singleRelationShipForm } = this.props.priceManagement;

    const newState = validateField(
      singleRelationshipRules,
      singleRelationShipForm,
      e.target.name,
      e.target.value
    );
    this.props.updateSingleRelationshipForm(newState);
  };

  handleChangeLabelSupplierEmailForm = e => {
    const { createLabelSupplierEmailForm } = this.props.priceManagement;

    const newState = validateField(
      labelSupplierEmailFormRules,
      createLabelSupplierEmailForm,
      e.target.name,
      e.target.value
    );
    this.props.updateLabelSupplierEmailForm(newState);
  };

  handleUpdateRelationship = () => {
    const { changeRelationshipForm } = this.props.priceManagement;

    changeRelationshipForm.validationErrors = createValidation(
      changeRelationshipForm,
      changeRelationshipRules
    );

    if (Object.IsEmpty(changeRelationshipForm.validationErrors)) {
      const data = {
        wrApplicantAccountNumber: changeRelationshipForm.wrSoldToAccntNo,
        wrManufacturerAccountNumber: changeRelationshipForm.wrShipToAccntNo,
        wrFileNumber: changeRelationshipForm.wrBasicFileNo,
        wrItemNumber: changeRelationshipForm.wrItemNo,
        crApplicantAccountNumber: changeRelationshipForm.crSoldToAccntNo,
        crManufacturerAccountNumber: changeRelationshipForm.crShipToAccntNo,
        crFileNumber: changeRelationshipForm.crBasicFileNo,
        crItemNumber: changeRelationshipForm.crItemNo,
      };
      this.props.updateCustomerCombinationLabel(data).then(result => {
        if (result.isSuccessful) {
          this.handleChangeRelationModal(false);
          this.props.getCombinationLabels();
          setTimeout(() => {
            toast({
              title: '',
              description: <p>Relationship successfully updated.</p>,
              time: 10000,
              size: 'small',
              icon: 'check',
              color: 'green',
            });
          }, 100);
        } else {
          setTimeout(() => {
            toast({
              title: '',
              description: <p>Wrong Relationship doesn't exist</p>,
              time: 10000,
              size: 'small',
              icon: 'check',
              color: 'red',
            });
          }, 100);
        }
      });
    }

    this.props.updateChangeRelationshipForm(changeRelationshipForm);
  };

  handleAddSingleRelationship = () => {
    const { singleRelationShipForm } = this.props.priceManagement;

    singleRelationShipForm.validationErrors = createValidation(
      singleRelationShipForm,
      singleRelationshipRules
    );

    if (Object.IsEmpty(singleRelationShipForm.validationErrors)) {
      const data = {
        applicantAccountNumber: singleRelationShipForm.soldToAccntNo.trim(),
        manufacturerAccountNumber: singleRelationShipForm.shipToAccntNo.trim(),
        fileNumber: singleRelationShipForm.basicFileNo.trim(),
        itemNumber: singleRelationShipForm.itemNo.trim(),
      };
      this.props.addSingleCustomerCombinationLabel(data).then(result => {
        if (result.isSuccessful) {
          this.handleAddSingleRelationModal(false);
          this.props.getCombinationLabels();
          setTimeout(() => {
            toast({
              title: '',
              description: <p>Relationship successfully added.</p>,
              time: 10000,
              size: 'small',
              icon: 'check',
              color: 'green',
            });
          }, 100);
        } else {
          setTimeout(() => {
            toast({
              title: '',
              description: <p>The relationship already existed.</p>,
              time: 10000,
              size: 'small',
              icon: 'check',
              color: 'red',
            });
          }, 100);
        }
      });
    }

    this.props.updateSingleRelationshipForm(singleRelationShipForm);
  };

  handleCreateLabelSupplierEmail = async () => {
    const { createLabelSupplierEmailForm } = this.props.priceManagement;

    createLabelSupplierEmailForm.validationErrors = createValidation(
      createLabelSupplierEmailForm,
      labelSupplierEmailFormRules
    );

    if (Object.IsEmpty(createLabelSupplierEmailForm.validationErrors)) {
      const data = {
        printerFileNumber:
          createLabelSupplierEmailForm.printerFileNumber.trim(),
        printerPsn: createLabelSupplierEmailForm.printerPsn.trim(),
        emailAddress: createLabelSupplierEmailForm.emailAddress.trim(),
      };

      const result = await this.props.createLabelSupplierEmail({
        labelPrinterEmailModel: data,
      });

      if (result.isSuccessful) {
        this.handleAddLabelSupplierEmailModal(false);
        this.props.getCombinationLabels();
        setTimeout(() => {
          toast({
            title: '',
            description: <p>{result.message}</p>,
            time: 10000,
            size: 'small',
            icon: 'check',
            color: 'green',
          });
        }, 100);
      } else {
        setTimeout(() => {
          toast({
            title: '',
            description: <p>{result.message}</p>,
            time: 10000,
            size: 'small',
            icon: 'check',
            color: 'red',
          });
        }, 100);
      }
    } else {
      this.setState({ showErrors: true });
    }
  };
  handleChangeRelationModal = isPopUp => {
    this.props.clearChangeRelationshipForm();
    this.setState({ isChangeRelModalOpen: isPopUp });
  };

  handleAddSingleRelationModal = isPopUp => {
    this.props.clearSingleRelationshipForm();
    this.setState({ isSingleRelModalOpen: isPopUp });
  };

  handleAddLabelSupplierEmailModal = isPopUp => {
    this.props.clearLabelSupplierEmailForm();
    this.setState({ isCreateLabelSupplierEmailModalOpen: isPopUp });
  };

  handleRadioChange = (e, { value }) => {
    this.setState({ filter: value });
  };

  readFile = file => {
    const { form, labels, orderCourier } = this.props.priceManagement;

    const extension = file.name
      .substr(file.name.lastIndexOf('.') + 1)
      .toLowerCase();

    if (!allowedFileTypes.includes(extension)) {
      this.setState({ file: {}, cols: [] });
      this.props.updateDataPrice({ data: [], labels: [] });
      this.props.clearForm();

      setTimeout(() => {
        ToastHelper.error({
          title: 'Error',
          description:
            'The selected file is invalid. Please select another file',
        });
      }, 100);
    } else {
      /* Boilerplate to set up FileReader */
      const reader = new FileReader();
      const rABS = !!reader.readAsBinaryString;

      reader.onload = e => {
        /* Parse data */
        const bstr = e.target.result;
        const wb = XLSX.read(bstr, {
          type: rABS ? 'binary' : 'array',
          bookVBA: true,
          cellText: false,
          cellDates: true,
        });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        let data = XLSX.utils.sheet_to_json(ws, {
          header: 0,
          defval: '',
          raw: false,
          dateNF: 'mm-dd-yyyy',
        });

        //clean white spaces
        data = JSON.parse(JSON.stringify(data), function (key, value) {
          if (typeof value === 'string') {
            return value.trim();
          }
          return value;
        });

        const colCount = get_header_row(ws).length;
        const columns = get_header_row(ws);
        const userRole = Common.getUserRole();

        if (this.hasValidFields(columns, form.uploadType)) {
          if (
            form.uploadType ===
            uploadTemplate.combinationLabelEligibleCcnsTemplate
          ) {
            this.setState({ data: data, cols: get_header_row(ws) });

            this.props.updateCombinationAllowedCcnTemplateData({ data });
          } else if (
            form.uploadType ===
            uploadTemplate.authorizedLabelSupplierEmailTemplate
          ) {
            this.setState({ data: data, cols: get_header_row(ws) });

            this.props.updateLabelSupplierEmailTemplateData({ data });
          } else if (
            userRole === Role.labelManager &&
            form.uploadType ===
              uploadTemplate.standardLabelManagementTemplate &&
            colCount === 7
          ) {
            this.setState({ data: data, cols: get_header_row(ws) });

            this.props.updateData({ data, labels });
          } else if (
            userRole === Role.pricingManager &&
            form.uploadType === uploadTemplate.priceListManagementTemplate
          ) {
            this.setState({ data: data, cols: get_header_row(ws) });

            this.props.updateDataPrice({ data, labels });
          } else if (
            (userRole === Role.labelManager || userRole === Role.labelCenter) &&
            form.uploadType ===
              uploadTemplate.combinationLabelItemNumbersTemplate &&
            colCount === 4
          ) {
            this.setState({ data: data, cols: get_header_row(ws) });

            this.props.updateCombinationData({ data, labels });
          } else if (
            (userRole === Role.labelManager || userRole === Role.labelCenter) &&
            form.uploadType === uploadTemplate.courierTrackingNumbersTemplate
          ) {
            data = XLSX.utils.sheet_to_json(ws, {
              header: 0,
              defval: '',
              raw: true,
              dateNF: 'mm-dd-yyyy',
            });

            this.setState({ data: data, cols: get_header_row(ws) });

            this.props.forUpdateCourierData({ data, labels, orderCourier });
          } else if (
            (userRole === Role.labelManager || userRole === Role.labelCenter) &&
            colCount === 3 &&
            form.uploadType === uploadTemplate.authorizedNdLabelTemplate
          ) {
            data = XLSX.utils.sheet_to_json(ws, {
              header: 0,
              defval: '',
              raw: true,
              dateNF: 'mm-dd-yyyy',
            });

            this.setState({ data: data, cols: get_header_row(ws) });

            this.props.loadNonDenominationalAccounts({ data });
          } else if (
            userRole === Role.labelManager &&
            colCount === 2 &&
            form.uploadType === uploadTemplate.standardLabelHandlingFeeTemplate
          ) {
            data = XLSX.utils.sheet_to_json(ws, {
              header: 0,
              defval: '',
              raw: true,
              dateNF: 'mm-dd-yyyy',
            });

            this.setState({ data: data, cols: get_header_row(ws) });

            this.props.loadCurrencyHandlingFee({ data });
          } else {
            this.setState({ file: {}, cols: [] });
            this.props.updateDataPrice({ data: [], labels: [] });
            this.props.clearForm();

            setTimeout(() => {
              ToastHelper.error({
                title: 'Error',
                description:
                  'The selected file is invalid. Please select another file',
              });
            }, 100);
          }
        } else {
          this.setState({ file: {}, cols: [] });
          this.props.updateDataPrice({ data: [], labels: [] });
          this.props.clearForm();

          setTimeout(() => {
            ToastHelper.error({
              title: 'Error',
              description:
                'The selected file is invalid. Please select another file',
            });
          }, 100);
        }
      };

      if (rABS) {
        reader.readAsBinaryString(file);
      } else {
        reader.readAsArrayBuffer(file);
      }
    }
  };

  handleDropdownChange = (dpName, dpValue) => {
    let { form } = this.props.priceManagement;

    if (dpValue === uploadTemplate.combinationLabelItemNumbersTemplate) {
      this.props.getCombinationLabels();
    } else if (dpValue === uploadTemplate.courierTrackingNumbersTemplate) {
      this.props.getCourierTracking();
    } else if (dpValue === uploadTemplate.authorizedNdLabelTemplate) {
      this.props.getNonDenominationalAccounts();
    } else if (
      dpValue === uploadTemplate.authorizedLabelSupplierEmailTemplate
    ) {
      this.props.getLabelSupplierEmail();
    } else if (
      dpValue === uploadTemplate.combinationLabelEligibleCcnsTemplate
    ) {
      this.props.getCombinationAllowedCcn();
    } else if (dpValue === uploadTemplate.standardLabelHandlingFeeTemplate) {
      this.props.getCurrencyHandlingFees();
    } else {
      this.props.getLabels();
    }

    const newState = validateField(rules, form, dpName, dpValue);
    this.props.updateForm(newState);
  };

  handleDateChange = (date, name) => {
    let { form, data } = this.props.priceManagement;

    if (data && data.length > 0) {
      data.forEach(item => {
        if (date != null) {
          item.backupStartDate = item['Start Date'];
          item['Start Date'] = date.toLocaleDateString();
        } else {
          item['Start Date'] = item.backupStartDate;
          item.backupStartDate = '';
        }
      });
    }

    const newState = validateField(rules, form, name, date);
    this.props.updateForm(newState);
  };

  handlePageChange = pageData => {
    const { origData } = this.props.priceManagement;
    const newData = {
      data: origData,
      currentPage: pageData.currentPage,
    };
    this.props.changePage(newData);
  };

  handleDiscard = () => {
    this.setState({ file: {}, cols: [] });
    this.props.updateData({ data: [], labels: [] });
    this.props.clearForm();
  };

  handleOpenConfirmDialog = () => {
    const { form } = this.props.priceManagement;

    form.validationErrors = createValidation(form, rules);

    const userRole = Common.getUserRole();

    if (userRole === Role.pricingManager) {
      if (Object.IsEmpty(form.validationErrors)) {
        this.setState({ isOpen: true });
      }
    } else {
      this.setState({ isOpen: true });
    }

    this.props.updateForm(form);
  };

  handleCloseConfirmDialog = () => {
    this.setState({ isOpen: false });
  };

  handleOpenErrorDialog = () => {
    this.setState({ isOpenErrorDialog: true, isOpen: false });
  };

  handleCloseErrorDialog = () => {
    this.setState({ isOpenErrorDialog: false });
    const { form, uploadData } = this.props.priceManagement;

    const newUploadData = uploadData.map(item => {
      return {
        description: item['Product Description'],
        itemNumber: item['Product Value'].toString(),
        UnitOfMeasurementName: item['UOM'],
        price: item['Value'] ? item['Value'] : 0,
        startDate: form.effectiveDate
          ? new Date(form.effectiveDate).toLocaleDateString()
          : item['Start Date'],
      };
    });

    const data = {
      labels: newUploadData,
    };

    this.props.updateLabelsPrice(data).then(result => {
      if (result.isSuccessful) {
        this.handleCloseConfirmDialog();
        this.handleDiscard();
        this.props.getLabels();
        setTimeout(() => {
          ToastHelper.success({
            title: '',
            description: 'File successfully imported.',
          });
        }, 100);
      }
    });
  };

  handleOpenViewLabelsDialog = () => {
    const {
      labels,
      nonDenominationalAccounts,
      form,
      labelSupplierEmailsFiltered,
      standardLabelsFiltered,
      combinationAllowedCcns,
      currencyHandlingFees,
    } = this.props.priceManagement;

    this.props.viewLabels(
      labels,
      nonDenominationalAccounts,
      form.uploadType,
      labelSupplierEmailsFiltered,
      combinationAllowedCcns,
      currencyHandlingFees,
      standardLabelsFiltered
    );

    this.setState({ isOpenViewLabelsDialog: true });
  };

  handleCloseViewLabelsDialog = () => {
    this.setState({ isOpenViewLabelsDialog: false });
  };

  handlePageChangeLabels = pageData => {
    const { origLabels, filteredLabels, noFilter } = this.props.priceManagement;
    const newData = {
      data: noFilter ? origLabels : filteredLabels,
      currentPage: pageData.currentPage,
    };
    this.props.changePageLabels(newData);
  };

  handlePageChangeLabelItems = (currentPage, pageSize) => {
    const { viewLtlItems, itemNumberSearch } = this.state;

    this.props.getLabelItems(
      currentPage,
      pageSize,
      viewLtlItems,
      itemNumberSearch
    );
  };

  handleDuplicateCourierUpload = result => {
    let dupOrders = '';
    result.forEach(o => {
      dupOrders = dupOrders + ',' + o.toString();
    });
    dupOrders = dupOrders.substring(1);
    return dupOrders;
  };

  handleSubmit = () => {
    const { form, uploadData } = this.props.priceManagement;
    const userRole = Common.getUserRole();
    if (
      form.uploadType === uploadTemplate.combinationLabelEligibleCcnsTemplate
    ) {
      const list = uploadData.map(item => {
        return {
          ccn: item['CCN'],
          serviceType: item['Service Type'],
          status:
            item['Status'].trim().toLowerCase() === 'active'
              ? CcnStatus.active
              : CcnStatus.inActive,
        };
      });

      if (this.hasDuplicateValue(list, 'ccn')) {
        setTimeout(() => {
          ToastHelper.error({
            title: '',
            description: 'Duplicate CCN found.',
          });
          this.handleCloseConfirmDialog();
        }, 100);
        return;
      }

      this.props.combinationAllowedCcnUpload({ list }).then(result => {
        if (result.isSuccessful) {
          this.handleCloseConfirmDialog();
          this.handleDiscard();
          this.props.getLabels();
          setTimeout(() => {
            ToastHelper.success({
              title: '',
              description: 'File successfully imported.',
            });
          }, 100);
        }
      });
    } else if (
      form.uploadType === uploadTemplate.authorizedLabelSupplierEmailTemplate
    ) {
      const list = uploadData.map(item => {
        return {
          printerFileNumber: item['Printer File Number'],
          printerPSN: item['Printer PSN'],
          emailAddress: item['Email Address'],
          emailStatus: item['Email Status'],
          updatedBy: item['Updated By'],
        };
      });

      this.props.labelSupplierEmailUpload({ list }).then(result => {
        if (result.isSuccessful) {
          this.handleCloseConfirmDialog();
          this.handleDiscard();
          this.props.getLabels();
          setTimeout(() => {
            ToastHelper.success({
              title: '',
              description: 'File successfully imported.',
            });
          }, 100);
        }
      });
    } else if (
      form.uploadType === uploadTemplate.standardLabelManagementTemplate
    ) {
      const labels = uploadData.map(item => {
        return {
          labelAccount: item['Label Acct'],
          ccn: item['CCN'],
          description:
            userRole === Role.labelManager
              ? item['Label Description']
              : item['Product Description'],
          itemNumber:
            userRole === Role.labelManager
              ? item['Item Number'].toString()
              : item['Product Value'],
          labelOrderPackage: item['Label Order Package'],
          minOrderQuantity: Number.parseInt(item['Min. Order Qty']),
          oracleUnitOfMeasurementName: item['ORACLE UOM'],
          price: item['Unit Price'],
          unitOfMeasurementName: item['UOM'],
        };
      });

      this.props.updateLabels({ labels }).then(result => {
        if (result.isSuccessful) {
          this.handleCloseConfirmDialog();
          this.handleDiscard();
          this.props.getLabels();
          setTimeout(() => {
            ToastHelper.success({
              title: '',
              description: 'File successfully imported.',
            });
          }, 100);
        }
      });
    } else if (
      form.uploadType === uploadTemplate.combinationLabelItemNumbersTemplate
    ) {
      const combinationLabels = uploadData.map(item => {
        return {
          applicantAccountNumber: item['Appl Account#'],
          manufacturerAccountNumber: item['Mfr Account#'],
          fileNumber: item['Basic File#'],
          itemNumber: Number.parseInt(item['Item Number']),
          status: item['Status'],
        };
      });

      this.props.updateCombinationLabels({ combinationLabels }).then(result => {
        if (result.isSuccessful) {
          this.handleCloseConfirmDialog();
          this.handleDiscard();
          this.props.getCombinationLabels();
          setTimeout(() => {
            ToastHelper.success({
              title: '',
              description: 'File successfully imported.',
            });
          }, 100);
        }
      });
    } else if (
      form.uploadType === uploadTemplate.courierTrackingNumbersTemplate
    ) {
      const { orderCourier, uploadData } = this.props.priceManagement;
      let orderWithError = '';
      let orderEmpty = '';
      let duplicateUpload = '';
      let orderDuplicate = '';

      uploadData.forEach(item => {
        const orderExist = orderCourier.filter(
          i => i.orderNumber === item['Oracle Order Number'].toString()
        );

        if (orderExist.length <= 0) {
          orderWithError =
            orderWithError + ',' + item['Oracle Order Number'].toString();
        }

        if (
          item['Tracking number'].toString() === '' 
        ) {
          orderEmpty =
            orderEmpty + ',' + item['Oracle Order Number'].toString();
        }
      });

      const newUploadData = [];
      const copyUploadData = uploadData;
      let allowedRec = true;

      uploadData.forEach(item => {
        allowedRec = true;
        const uploadDataExists = copyUploadData.filter(
          i =>
            i['Oracle Order Number'] === item['Oracle Order Number'] &&
            i['Tracking number'] === item['Tracking number'] &&
            i['Courier Name'] === item['Courier Name']
        );
        if (
          item['Tracking number'].toString() !== '' 
        ) {
          if (uploadDataExists.length > 1) {
            if (duplicateUpload.indexOf(item['Oracle Order Number']) < 0) {
              duplicateUpload =
                duplicateUpload + ',' + item['Oracle Order Number'].toString();
            } else {
              allowedRec = false;
            }
          }
          if (allowedRec) {
            const uploadFilter = newUploadData.filter(
              o => o.orderNumber === item['Oracle Order Number'].toString()
            );
            if (uploadFilter.length <= 0) {
              const tempUpload = uploadData.filter(
                i =>
                  i['Oracle Order Number'].toString() ===
                  item['Oracle Order Number'].toString()
              );

              let multiTrack = '';
              let multiCourier = '';

              tempUpload.forEach(o => {
                if (multiTrack.indexOf(o['Tracking number']) < 0) {
                  multiTrack =
                    multiTrack + ',' + o['Tracking number'].toString();
                }
                if (multiCourier.indexOf(o['Courier Name']) < 0) {
                  multiCourier =
                    multiCourier + ',' + o['Courier Name'].toString();
                }
              });
              if (multiTrack.charAt(0) === ',') {
                multiTrack = multiTrack.substring(1);
              }
              if (multiCourier.charAt(0) === ',') {
                multiCourier = multiCourier.substring(1);
              }

              newUploadData.push({
                orderNumber: item['Oracle Order Number'].toString(),
                trackingNumber: multiTrack,
                courier: multiCourier,
              });
            }
          }
        }
      });

      if (orderWithError !== '') {
        orderWithError = orderWithError.substring(1);
      }
      if (orderEmpty !== '') {
        orderEmpty = orderEmpty.substring(1);
      }
      if (duplicateUpload !== '') {
        duplicateUpload = duplicateUpload.substring(1);
      }

      const data = {
        courierTracking: newUploadData,
      };

      if (orderWithError !== '') {
        const errorPop = 'Oracle Order ' + orderWithError + ' not exists.';
        setTimeout(() => {
          toast({
            type: 'error',
            title: 'Error!',
            description: errorPop,
            time: 5000,
            size: 'large',
            icon: 'delete',
          });
        }, 100);
      }

      if (orderEmpty !== '') {
        const emptyPop =
          'Oracle Order ' + orderEmpty + ' contains empty information.';
        setTimeout(() => {
          toast({
            type: 'error',
            title: 'Error!',
            description: emptyPop,
            time: 5000,
            size: 'large',
            icon: 'delete',
          });
        }, 100);
      }

      if (duplicateUpload !== '') {
        const dupliPop =
          'Oracle Order ' +
          duplicateUpload +
          ' got duplicated tracking numbers and couriers in upload file.';
        setTimeout(() => {
          toast({
            type: 'error',
            title: 'Error!',
            description: dupliPop,
            time: 5000,
            size: 'large',
            icon: 'delete',
          });
        }, 100);
      }
      if (data.courierTracking.length > 0) {
        this.props.updateCourierTracking(data).then(result => {
          if (result.isSuccessful) {
            this.handleCloseConfirmDialog();
            this.handleDiscard();
            orderDuplicate = this.handleDuplicateCourierUpload(
              result.data,
              duplicateUpload
            );

            if (orderDuplicate !== '') {
              const orderDuplicateMsg =
                'Order order ' +
                orderDuplicate +
                ' got duplicated tracking numbers and couriers in the database.';

              if (orderDuplicate !== '') {
                setTimeout(() => {
                  toast({
                    type: 'error',
                    title: 'Error!',
                    description: orderDuplicateMsg,
                    time: 5000,
                    size: 'large',
                    icon: 'delete',
                  });
                }, 100);
              }
            } else {
              if (orderWithError === '') {
                setTimeout(() => {
                  ToastHelper.success({
                    title: '',
                    description: 'File successfully imported.',
                  });
                }, 100);
              }
            }
          }
        });
      }
    } else if (form.uploadType === uploadTemplate.authorizedNdLabelTemplate) {
      const nonDenominationalAccounts = uploadData.map(item => {
        return {
          manufacturerAccount: item['Manufacturer Account#'],
          manufacturerName: item['Manufacturer Customer Name'],
          status: item['Status'],
        };
      });

      this.props
        .createNonDenominationalAccounts({ nonDenominationalAccounts })
        .then(result => {
          if (result.isSuccessful) {
            this.handleCloseConfirmDialog();
            this.handleDiscard();
            setTimeout(() => {
              ToastHelper.success({
                title: '',
                description: 'File successfully imported.',
              });
            }, 100);
          }
        });
    } else if (
      form.uploadType === uploadTemplate.standardLabelHandlingFeeTemplate
    ) {
      const handlingFees = uploadData.map(item => {
        return {
          currency: item['Currency'],
          handlingFee: item['Handling Fee'],
        };
      });

      this.props.createCurrencyHandlingFees({ handlingFees }).then(result => {
        if (result.isSuccessful) {
          this.handleCloseConfirmDialog();
          this.handleDiscard();
          setTimeout(() => {
            ToastHelper.success({
              title: '',
              description: 'File successfully imported.',
            });
          }, 100);
        }
      });
    } else {
      const newData = uploadData.filter(i => i.childItem == null);

      if (newData.length > 0) {
        this.handleOpenErrorDialog();
      } else {
        const labels = uploadData.map(item => {
          return {
            description: item['Product Description'],
            itemNumber: item['Product Value'].toString(),
            UnitOfMeasurementName: item['UOM'],
            price: item['Value'] ? item['Value'] : 0,
            startDate: form.effectiveDate
              ? new Date(form.effectiveDate).toLocaleDateString()
              : item['Start Date'],
          };
        });

        this.props.updateLabelsPrice({ labels }).then(result => {
          if (result.isSuccessful) {
            this.handleCloseConfirmDialog();
            this.handleDiscard();
            this.props.getLabels();
            setTimeout(() => {
              ToastHelper.success({
                title: '',
                description: 'File successfully imported.',
              });
            }, 100);
          }
        });
      }
    }
  };

  handleApplyFilter = () => {
    const { filter } = this.state;
    const { form, backupData } = this.props.priceManagement;

    this.setState({ filterStatus: filter });

    if (
      form.uploadType === uploadTemplate.combinationLabelItemNumbersTemplate
    ) {
      this.props.applyFilter({
        data: backupData,
        filter: filter,
        uploadType: uploadTemplate.combinationLabelItemNumbersTemplate,
      });
    } else {
      this.props.applyFilter({
        data: backupData,
        filter: filter,
        uploadType: uploadTemplate.standardLabelManagementTemplate,
      });
    }
  };

  handleClearFilter = () => {
    const { backupData } = this.props.priceManagement;

    this.setState({ filter: null });
    this.setState({ filterStatus: null });
    const newData = {
      data: backupData,
      filter: null,
    };
    this.props.applyFilter(newData);
  };

  fileInputRef = React.createRef();

  getErrorFor(field) {
    const { form, data } = this.props.priceManagement;
    let errors = getError(field, form, data);
    return errors;
  }

  handleCourierTrackingCheck = () => {
    const { orderCourier, uploadData } = this.props.priceManagement;
    let orderWithError = '';
    uploadData.forEach(item => {
      const orderExist = orderCourier.filter(
        i => i.orderNumber === item['Oracle Order Number'].toString()
      );
      if (orderExist.length <= 0) {
        orderWithError = item['Oracle Order Number'].toString() + '|';
      }
    });
    if (orderWithError !== '') {
      this.setState({ courierTrackingError: orderWithError.slice(0, -1) });
    }
  };

  getErrorForChangeRelationship(field) {
    const { changeRelationshipForm, data } = this.props.priceManagement;
    let errors = getError(field, changeRelationshipForm, data);
    return errors;
  }

  getErrorForSingleChangeRelationship(field) {
    const { singleRelationShipForm, data } = this.props.priceManagement;
    let errors = getError(field, singleRelationShipForm, data);
    return errors;
  }

  getErrorForLabelSupplierEmailForm(field) {
    const { createLabelSupplierEmailForm, data } = this.props.priceManagement;
    let errors = getError(field, createLabelSupplierEmailForm, data);
    return errors;
  }

  handleApplyCombinationLabelFilter = data => {
    this.props.getCombinationLabelFilter(data);
    //this.refreshCsvData();
  };

  handleApplyLabelSupplierPrinterEmailFilter = data => {
    this.props.getLabelSupplierPrinterEmailFilter(data);
    //this.refreshCsvData();
  };

  handleClearLabelSupplierEmailFilter = () => {
    this.props.clearLabelSupplierEmailFilter();
  };

  handleClearCombinationLabelFilter = () => {
    this.props.clearCombinationLabelFilter();
  };

  handleApplyStandardLabelsFilter = data => {
    this.props.getStandardLabelTemplatesFilter(data);
  };

  handleClearStandardLabelsFilter = () => {
    this.props.clearStandardLabelTemplatesFilter();
  };

  hasValidFields(columns, uploadType) {
    let isExist = true;
    let validFields = [];
    if (uploadType === uploadTemplate.authorizedLabelSupplierEmailTemplate) {
      validFields = labelSupplierPrinterEmailFields;
    } else if (uploadType === uploadTemplate.standardLabelManagementTemplate) {
      validFields = standardLabelFields;
    } else if (uploadType === uploadTemplate.priceListManagementTemplate) {
      validFields = pricelistFields;
    } else if (
      uploadType === uploadTemplate.combinationLabelItemNumbersTemplate
    ) {
      validFields = customerCombinationFields;
    } else if (uploadType === uploadTemplate.courierTrackingNumbersTemplate) {
      validFields = courierFields;
    } else if (uploadType === uploadTemplate.authorizedNdLabelTemplate) {
      validFields = nonDenominationalFields;
    } else if (
      uploadType === uploadTemplate.combinationLabelEligibleCcnsTemplate
    ) {
      validFields = comboAllowedCcnFields;
    } else if (uploadType === uploadTemplate.standardLabelHandlingFeeTemplate) {
      validFields = handlingFeeAllowedFields;
    }

    columns.forEach(col => {
      let field = validFields.find(f => f === col);
      if (!field) isExist = false;
    });

    return isExist;
  }

  handleDateFormat = dateUpdate => {
    let retDate = '';
    if (dateUpdate) {
      retDate = new Date(dateUpdate).toLocaleDateString();
    }
    return retDate.toString();
  };

  refreshCsvData = () => {
    const {
      form,
      filteredLabels,
      labels,
      nonDenominationalAccounts,
      labelSupplierEmailsFiltered,
      standardLabelsFiltered,
      combinationAllowedCcns,
      currencyHandlingFees,
    } = this.props.priceManagement;

    let newLabels = [];

    if (
      form.uploadType === uploadTemplate.combinationLabelEligibleCcnsTemplate
    ) {
      newLabels = combinationAllowedCcns.map(item => {
        return {
          CCN: item.ccn,
          'Service Type': item.serviceType,
          Status: item.statusDisplay,
        };
      });
    } else if (
      form.uploadType === uploadTemplate.authorizedLabelSupplierEmailTemplate
    ) {
      newLabels = labelSupplierEmailsFiltered.map(item => {
        return {
          'Printer File Number': item.printerFileNumber,
          'Printer PSN': item.printerPSN,
          'Email Address': item.emailAddress,
          'Email Status': item.emailStatus,
          'Updated By': item.updatedByDisplay,
        };
      });
    } else if (
      form.uploadType === uploadTemplate.standardLabelManagementTemplate
    ) {
      newLabels = standardLabelsFiltered.map(item => {
        return {
          'Label Acct': item.labelAccount,
          CCN: item.ccn,
          'Label Description': item.description,
          'Item Number': item.itemNumber,
          'Label Order Package': item.labelOrderPackage,
          'Min. Order Qty': item.minOrderQuantity,
          'ORACLE UOM': item.oracleUnitOfMeasurementName,
        };
      });
    } else if (form.uploadType === uploadTemplate.priceListManagementTemplate) {
      newLabels = labels.map(item => {
        return {
          'Product Value': item.itemNumber,
          'Product Description': item.description,
          UOM: item.unitOfMeasurementName,
          Value: item.price,
        };
      });
    } else if (
      form.uploadType === uploadTemplate.combinationLabelItemNumbersTemplate
    ) {
      newLabels = filteredLabels.map(item => {
        return {
          'Appl Account#': item.applicantAccountNumber
            ? item.applicantAccountNumber
            : item['Appl Account#'],
          'Mfr Account#': item.manufacturerAccountNumber
            ? item.manufacturerAccountNumber
            : item['Mfr Account#'],
          'Basic File#': item.fileNumber
            ? item.fileNumber
            : item['Basic File#'],
          'Item Number': item.itemNumber
            ? item.itemNumber
            : item['Item Number'],
          'Item Description': item.itemDescription
            ? item.itemDescription
            : item['Item Description'],
          'Customer Part No.': item.customerPartNo
            ? item.customerPartNo
            : item['Customer Part No.'],
          'Updated By': item.updatedByDisplay
            ? item.updatedByDisplay
            : item['Updated By'],
          'Updated Date': item.updatedDate
            ? this.handleDateFormat(item.updatedDate)
            : this.handleDateFormat(item['Updated Date']),
        };
      });
    } else if (form.uploadType === uploadTemplate.authorizedNdLabelTemplate) {
      newLabels = nonDenominationalAccounts.map(item => {
        return {
          'Manufacturer Account#': item.manufacturerAccount,
          'Manufacturer Customer Name': item.manufacturerName,
          Status: item.status,
        };
      });
    } else if (
      form.uploadType === uploadTemplate.standardLabelHandlingFeeTemplate
    ) {
      newLabels = currencyHandlingFees.map(item => {
        return {
          Currency: item.currency,
          'Handling Fee': item.handlingFee,
          'Updated By': item.updatedBy,
          'Updated Date': item.updatedDate,
        };
      });
    }

    this.props.refreshCsvData(newLabels);
  };

  handleSelectItemNumber = (id, checked) => {
    this.props.selectItemNumber(id, checked);
  };
  handleSelectAllItems = checked => {
    this.props.selectAllItems(checked);
  };

  handleRemoveCombinationLabels = () => {
    const {
      labels,
      nonDenominationalAccounts,
      form,
      labelSupplierEmailsFiltered,
      combinationAllowedCcns,
      selectedItems,
    } = this.props.priceManagement;

    const data = {
      customerCombinationLabelIds: selectedItems,
    };

    this.props.removeCombinationLabels(data).then(result => {
      if (result.isSuccessful) {
        this.props.getCombinationLabels().then(() => {
          this.props.viewLabels(
            labels,
            nonDenominationalAccounts,
            form.uploadType,
            labelSupplierEmailsFiltered,
            combinationAllowedCcns
          );
        });
      }
    });
  };

  handleResetRemoveLabels = () => {
    const { resetRemoveLabels } = this.props;
    resetRemoveLabels();
  };

  handleChangeSetting = async isChecked => {
    const { configurationSettings } = this.props.priceManagement;

    const configurationSetting = configurationSettings.find(
      c => c.settingName === 'Enable-LTL-Warehouse'
    );

    const isLtlSettingNewValue = isChecked ? '1' : '0';

    await this.props.updateConfigurationSettings({
      id: configurationSetting.id,
      settingName: configurationSetting.settingName,
      settingValue: isLtlSettingNewValue,
    });

    this.props.getConfigurationSettings();
  };

  handleLtlWarehouseModal = (showModalLtlWarehouse, selectedLabelItem) => {
    this.setState({ showModalLtlWarehouse, selectedLabelItem });
  };

  onConfirmToggleLtlWarehouse = async () => {
    const { selectedLabelItem, viewLtlItems, itemNumberSearch } = this.state;
    const { labelItemsCurrentPage } = this.props.priceManagement;

    const result = await this.props.updateLabelItem({
      labelId: selectedLabelItem.labelId,
      isUsedForLTLWarehouse: !selectedLabelItem.isUsedForLTLWarehouse,
    });

    if (result.isSuccessful) {
      setTimeout(() => {
        toast({
          title: '',
          description: <p>LTL Warehouse setting successfully updated.</p>,
          time: 5000,
          size: 'small',
          icon: 'check',
          color: 'green',
        });
      }, 100);
      this.props.getLabelItems(
        labelItemsCurrentPage,
        10,
        viewLtlItems,
        itemNumberSearch
      );

      this.handleLtlWarehouseModal(false, null);
    } else {
      setTimeout(() => {
        toast({
          type: 'error',
          title: 'Error',
          description: <p>An error occured. Please try again.</p>,
          time: 5000,
          size: 'small',
          icon: 'delete',
        });
      }, 100);
    }
  };

  handleViewLtlItems = properties => {
    const { itemNumberSearch } = this.state;
    this.props.getLabelItems(1, 10, properties.checked, itemNumberSearch);
    this.setState({ viewLtlItems: properties.checked });
  };

  handleChangeItemNumberSearch = properties => {
    this.setState({ itemNumberSearch: properties.value });
  };

  handleClickSearchItemNumbers = () => {
    const { itemNumberSearch, viewLtlItems } = this.state;
    this.props.getLabelItems(1, 10, viewLtlItems, itemNumberSearch);
  };

  render() {
    const {
      form,
      data,
      mapLabels,
      labels,
      loading,
      isLoadingViewActiveLabels,
      changeRelationshipForm,
      singleRelationShipForm,
      noFilter,
      nonDenominationalAccounts,
      labelSupplierEmailsFiltered,
      combinationAllowedCcns,
      isRemoveSuccess,
      selectedItems,
      selectedItemNumbers,
      currencyHandlingFees,
      createLabelSupplierEmailForm,
      labelItems,
      labelItemsTotalPages,
      labelItemsCurrentPage,
      labelItemsPageSize,
    } = this.props.priceManagement;

    const {
      file,
      cols,
      isOpen,
      isOpenErrorDialog,
      filter,
      isOpenViewLabelsDialog,
      filterStatus,
      isChangeRelModalOpen,
      isSingleRelModalOpen,
      isCreateLabelSupplierEmailModalOpen,
      showModalLtlWarehouse,
    } = this.state;

    const panes = [];

    const userRole = Common.getUserRole();
    let uploadTypeOption = [];
    if (userRole === Role.labelManager)
      uploadTypeOption = uploadType.filter(
        i =>
          i.value === uploadTemplate.standardLabelManagementTemplate ||
          i.value === uploadTemplate.combinationLabelItemNumbersTemplate ||
          i.value === uploadTemplate.courierTrackingNumbersTemplate ||
          i.value === uploadTemplate.authorizedNdLabelTemplate ||
          i.value === uploadTemplate.authorizedLabelSupplierEmailTemplate ||
          i.value === uploadTemplate.combinationLabelEligibleCcnsTemplate ||
          i.value === uploadTemplate.standardLabelHandlingFeeTemplate
      );
    else if (userRole === Role.labelCenter)
      uploadTypeOption = uploadType.filter(
        i =>
          i.value === uploadTemplate.combinationLabelItemNumbersTemplate ||
          i.value === uploadTemplate.courierTrackingNumbersTemplate ||
          i.value === uploadTemplate.authorizedNdLabelTemplate ||
          i.value === uploadTemplate.authorizedLabelSupplierEmailTemplate ||
          i.value === uploadTemplate.combinationLabelEligibleCcnsTemplate
      );
    else
      uploadTypeOption = uploadType.filter(
        i => i.value === uploadTemplate.priceListManagementTemplate
      );

    panes.push({
      menuItem: 'Price/Label Management',
      render: () => (
        <TabPane>
          <Container fluid>
            <Grid>
              <Grid.Row>
                <Grid.Column width={7}></Grid.Column>
              </Grid.Row>
            </Grid>
            <Segment>
              <Grid divided="vertically">
                <Grid.Row columns={2}>
                  <Grid.Column>
                    <CustomTitle message="Upload a spreedsheet of templated data to update either pricing or label information." />
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row columns={2}>
                  <Grid.Column>
                    <Form>
                      <Form.Field
                        control={Dropdown}
                        label="Upload Type"
                        required
                        selection
                        search
                        fluid
                        selectOnBlur={false}
                        placeholder="Select upload type"
                        options={uploadTypeOption}
                        onChange={(e, properties) => {
                          this.handleDropdownChange(
                            'uploadType',
                            properties.value
                          );
                        }}
                        value={(form && form.uploadType) || ''}
                      />
                    </Form>
                  </Grid.Column>
                  <Grid.Column verticalAlign="bottom">
                    <Button
                      content="Choose File"
                      labelPosition="left"
                      icon="file"
                      basic
                      color="blue"
                      disabled={!form.uploadType}
                      onClick={() => this.fileInputRef.current.click()}
                    />
                    <input
                      type="file"
                      className="form-control"
                      id="file"
                      ref={this.fileInputRef}
                      hidden
                      // accept={SheetJSFT}
                      accept=".xlsx, .xls, .csv, .xlsm"
                      value={form && form.file}
                      onChange={this.handleChange}
                    />
                    {file && <CustomLabel message={file.name} />}

                    <span
                      hidden={
                        form.uploadType !==
                        uploadTemplate.authorizedLabelSupplierEmailTemplate
                      }
                    >
                      <Button
                        content="Add Single Email"
                        labelPosition="left"
                        icon="add"
                        basic
                        color="blue"
                        onClick={() =>
                          this.handleAddLabelSupplierEmailModal(true)
                        }
                      />
                    </span>

                    <span
                      hidden={
                        form.uploadType !==
                        uploadTemplate.combinationLabelItemNumbersTemplate
                      }
                    >
                      <Button
                        content="Add Single item No. Relationship"
                        labelPosition="left"
                        icon="add"
                        basic
                        color="blue"
                        onClick={() => this.handleAddSingleRelationModal(true)}
                      />
                    </span>

                    <span
                      hidden={
                        form.uploadType !==
                        uploadTemplate.combinationLabelItemNumbersTemplate
                      }
                    >
                      <Button
                        content="Modify Item No. Relationship"
                        labelPosition="left"
                        icon="edit"
                        basic
                        color="blue"
                        onClick={() => this.handleChangeRelationModal(true)}
                      />
                    </span>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <Form>
                      <Form.Field
                        required
                        disabled={userRole === Role.labelManager}
                        error={this.getErrorFor('effectiveDate')}
                      >
                        <label>Effective Date</label>
                        <DatePicker
                          selected={form.effectiveDate}
                          onChange={date =>
                            this.handleDateChange(date, 'effectiveDate')
                          }
                          isClearable
                          minDate={new Date()}
                          dateFormat="MM/dd/yyyy"
                          placeholderText="MM/DD/YYYY"
                          value={form.effectiveDate}
                        />
                      </Form.Field>
                    </Form>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <List>
                      <List.Item>
                        <Label color="orange" horizontal>
                          Orange
                        </Label>
                        New label item does not existing in database
                      </List.Item>
                      <List.Item>
                        <Label color="green" horizontal>
                          Green
                        </Label>
                        New label item data to update
                      </List.Item>
                      <List.Item>
                        <Label color="red" horizontal>
                          Red
                        </Label>
                        Active label item data to remove
                      </List.Item>
                    </List>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2} style={{ margin: 0, padding: 0 }}>
                  <Grid.Column>
                    <CustomHeader message="REVIEW UPLOADED LABEL DATA" />
                  </Grid.Column>
                  <Grid.Column>
                    <Popup
                      trigger={
                        <Button
                          icon="filter"
                          floated="right"
                          basic
                          color="blue"
                          disabled={
                            form.uploadType ===
                              uploadTemplate.courierTrackingNumbersTemplate ||
                            form.uploadType ===
                              uploadTemplate.authorizedNdLabelTemplate ||
                            form.uploadType ===
                              uploadTemplate.authorizedLabelSupplierEmailTemplate
                          }
                        >
                          <Icon name="filter"></Icon>
                          Filter
                        </Button>
                      }
                      on="click"
                      pinned
                      position="bottom right"
                    >
                      <Card>
                        <Card.Content>
                          <Image floated="right" size="mini" />
                          <Card.Header>Set Filter Option</Card.Header>
                          {/* <Card.Meta>Friends of Elliot</Card.Meta> */}
                          <Card.Description>
                            <Divider hidden />
                            <Form>
                              <Form.Field>
                                <Radio
                                  label="New"
                                  name="radioGroup"
                                  value={1}
                                  toggle
                                  checked={filter === 1}
                                  onChange={this.handleRadioChange}
                                />
                              </Form.Field>
                              <Form.Field>
                                <Radio
                                  label="Active"
                                  name="radioGroup"
                                  value={2}
                                  toggle
                                  checked={filter === 2}
                                  onChange={this.handleRadioChange}
                                />
                              </Form.Field>
                              <Form.Field>
                                <Radio
                                  label="To Be Removed"
                                  name="radioGroup"
                                  value={3}
                                  toggle
                                  checked={filter === 3}
                                  onChange={this.handleRadioChange}
                                />
                              </Form.Field>
                            </Form>
                          </Card.Description>
                        </Card.Content>
                        <Card.Content extra>
                          <div className="ui two buttons">
                            <Button
                              basic
                              primary
                              onClick={this.handleApplyFilter}
                            >
                              Apply
                            </Button>
                            <Button
                              basic
                              secondary
                              onClick={this.handleClearFilter}
                            >
                              Clear
                            </Button>
                          </div>
                        </Card.Content>
                      </Card>
                    </Popup>

                    <Button
                      floated="right"
                      basic
                      color="blue"
                      onClick={this.handleOpenViewLabelsDialog}
                      loading={isLoadingViewActiveLabels}
                      disabled={
                        !form.uploadType ||
                        form.uploadType ===
                          uploadTemplate.courierTrackingNumbersTemplate ||
                        isLoadingViewActiveLabels
                      }
                    >
                      <Icon name="file"></Icon>
                      View Records
                    </Button>
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row>
                  <Table celled>
                    <Table.Header>
                      {cols && cols.length > 0 && (
                        <Table.Row textAlign="center">
                          {cols &&
                            cols.map((_column, idx) => (
                              <Table.HeaderCell key={idx}>
                                {_column}
                              </Table.HeaderCell>
                            ))}

                          {form.uploadType !==
                            uploadTemplate.authorizedLabelSupplierEmailTemplate ?? (
                            <Table.HeaderCell></Table.HeaderCell>
                          )}
                        </Table.Row>
                      )}
                    </Table.Header>

                    <Table.Body>
                      {data && data.length > 0 ? (
                        data.map((item, idx) => {
                          return (
                            <PriceManagementItem
                              key={idx}
                              item={item}
                              cols={cols}
                              filter={filterStatus}
                              uploadType={form.uploadType}
                            />
                          );
                        })
                      ) : (
                        <Table.Cell colSpan={10}>
                          <Message>No Order Found</Message>
                        </Table.Cell>
                      )}
                    </Table.Body>
                    <Table.Footer>
                      <Table.Row>
                        <Table.HeaderCell colSpan="15">
                          {data && this.props.totalPages > 0 && (
                            <Pagination
                              floated="right"
                              boundaryRange={2}
                              basic
                              activePage={this.props.currentPage}
                              totalPages={this.props.totalPages}
                              onPageChange={(e, { activePage }) => {
                                this.handlePageChange({
                                  currentPage: activePage,
                                  pageSize: this.props.pageSize,
                                });
                              }}
                            />
                          )}
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Footer>
                  </Table>
                </Grid.Row>
                <Grid.Row columns={2}>
                  <Grid.Column>
                    <Button
                      basic
                      color="blue"
                      disabled={!(data && data.length > 0)}
                      onClick={this.handleDiscard}
                    >
                      Discard
                    </Button>
                  </Grid.Column>
                  <Grid.Column>
                    <Button
                      basic
                      floated="right"
                      color="blue"
                      disabled={!(data && data.length > 0)}
                      onClick={this.handleOpenConfirmDialog}
                    >
                      Apply
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          </Container>
        </TabPane>
      ),
    });

    panes.push({
      menuItem: 'Standard Labels',
      render: () => (
        <TabPane>
          <Container fluid>
            <Segment>
              {userRole === Role.labelManager && (
                <>
                  <Grid>
                    <Grid.Row columns={8} verticalAlign="middle">
                      <Grid.Column width={3}>
                        <Form>
                          <Form.Field
                            control={Checkbox}
                            label={'View LTL Item Numbers'}
                            onChange={(e, properties) => {
                              this.handleViewLtlItems(properties);
                            }}
                          ></Form.Field>
                        </Form>
                      </Grid.Column>
                      <Grid.Column width={3}>
                        <Form>
                          <Form.Field
                            control={Input}
                            placeholder={'Search Item Numbers'}
                            onChange={(e, properties) => {
                              this.handleChangeItemNumberSearch(properties);
                            }}
                          ></Form.Field>
                        </Form>
                      </Grid.Column>
                      <Grid.Column width={2}>
                        <Button
                          primary
                          onClick={this.handleClickSearchItemNumbers}
                          loading={loading}
                          disabled={loading}
                        >
                          Search
                        </Button>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>

                  <Table>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell style={{ width: '6%' }}>
                          Label Acct
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ width: '5%' }}>
                          CCN
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ width: '12.5%' }}>
                          Label Description
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ width: '5%' }}>
                          Item Number
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ width: '12%' }}>
                          Image
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ width: '2%' }}>
                          Label Order Package
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ width: '2%' }}>
                          Min. Order Qty
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ width: '0%' }}>
                          ORACLE UOM
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ width: '4.6%' }}>
                          Go for LTL Warehouse
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {labelItems && labelItems.length > 0 ? (
                        labelItems.map(item => {
                          return (
                            <Table.Row>
                              <Table.Cell style={{ width: '5%' }}>
                                {item.labelAccount}
                              </Table.Cell>
                              <Table.Cell style={{ width: '5%' }}>
                                {item.ccn}
                              </Table.Cell>
                              <Table.Cell style={{ width: '15%' }}>
                                {item.description}
                              </Table.Cell>
                              <Table.Cell style={{ width: '5%' }}>
                                {item.itemNumber}
                              </Table.Cell>
                              <Table.Cell style={{ width: '10%' }}>
                                <Image src={item.imageUrl} size="small" />
                              </Table.Cell>
                              <Table.Cell style={{ width: '5%' }}>
                                {item.labelOrderPackage}
                              </Table.Cell>
                              <Table.Cell style={{ width: '5%' }}>
                                {item.minOrderQuantity}
                              </Table.Cell>
                              <Table.Cell style={{ width: '5%' }}>
                                {item.oracleUnitOfMeasurementName}
                              </Table.Cell>
                              <Table.Cell>
                                <div className="fixed-table">
                                  <div className="ellipsis-preview">
                                    <Checkbox
                                      toggle
                                      onChange={this.handleLtlWarehouseModal.bind(
                                        this,
                                        true,
                                        item
                                      )}
                                      checked={item.isUsedForLTLWarehouse}
                                    />
                                  </div>
                                </div>
                              </Table.Cell>
                            </Table.Row>
                          );
                        })
                      ) : (
                        <Table.Row textAlign="center">
                          <Table.Cell colSpan="9">
                            <Message>No Records Found</Message>
                          </Table.Cell>
                        </Table.Row>
                      )}
                    </Table.Body>
                    <Table.Footer>
                      <Table.Row>
                        <Table.HeaderCell colSpan="15">
                          {labelItems && labelItemsTotalPages > 0 && (
                            <Pagination
                              floated="right"
                              boundaryRange={2}
                              basic
                              activePage={labelItemsCurrentPage}
                              totalPages={labelItemsTotalPages}
                              onPageChange={(e, { activePage }) => {
                                this.handlePageChangeLabelItems(
                                  activePage,
                                  labelItemsPageSize
                                );
                              }}
                            />
                          )}
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Footer>
                  </Table>
                </>
              )}
              {userRole !== Role.labelManager && (
                <Table size="small">
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>No settings available</Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              )}
            </Segment>
          </Container>
        </TabPane>
      ),
    });

    return (
      <Fragment>
        <Modal
          size="tiny"
          open={isOpen}
          onClose={this.handleCloseConfirmDialog}
        >
          <Modal.Header>Confirm</Modal.Header>
          <Modal.Content>
            <h3> Are you sure you want to upload this file?</h3>

            <Message
              negative
              icon="exclamation"
              header=" Note: All existing label items will be deleted and replace."
            />
          </Modal.Content>
          <Modal.Actions>
            <Button
              primary
              disabled={loading}
              loading={loading}
              onClick={this.handleSubmit}
            >
              Yes
            </Button>
            <Button
              primary
              disabled={loading}
              basic
              onClick={this.handleCloseConfirmDialog}
            >
              No
            </Button>
          </Modal.Actions>
        </Modal>

        <Modal
          size="tiny"
          open={isOpenErrorDialog}
          onClose={this.handleCloseErrorDialog}
        >
          <Modal.Header>Error</Modal.Header>
          <Modal.Content>
            <Message
              negative
              icon="exclamation"
              header=" Please contact Label Center for confirmation for those un-matched data. System will only update the prices for matched label items."
            />
          </Modal.Content>
          <Modal.Actions>
            <Button primary onClick={this.handleCloseErrorDialog}>
              Yes
            </Button>
          </Modal.Actions>
        </Modal>

        <Modal
          size="mini"
          open={isChangeRelModalOpen}
          onClose={this.handleCloseConfirmDialog}
        >
          <Modal.Header style={{ textAlign: 'center' }}>
            Change Relationship
          </Modal.Header>
          <Modal.Content>
            <fieldset
              style={{
                width: '230px',
                paddingLeft: '50px',
                paddingRight: '60px',
              }}
            >
              <legend>Wrong Relationship</legend>
              <Form>
                <Form.Field
                  size="large"
                  control={Input}
                  placeholder="Sold To Account No."
                  onChange={this.handleTextChange}
                  name="wrSoldToAccntNo"
                  value={changeRelationshipForm.wrSoldToAccntNo || ''}
                  error={this.getErrorForChangeRelationship('wrSoldToAccntNo')}
                  autoComplete="off"
                />
                <Divider hidden fitted />
                <Divider hidden fitted />
                <Form.Field
                  size="large"
                  control={Input}
                  placeholder="Ship To Account No."
                  onChange={this.handleTextChange}
                  name="wrShipToAccntNo"
                  value={changeRelationshipForm.wrShipToAccntNo || ''}
                  error={this.getErrorForChangeRelationship('wrShipToAccntNo')}
                  autoComplete="off"
                />
                <Divider hidden fitted />
                <Divider hidden fitted />
                <Form.Field
                  size="large"
                  control={Input}
                  placeholder="Basic File No."
                  onChange={this.handleTextChange}
                  name="wrBasicFileNo"
                  value={changeRelationshipForm.wrBasicFileNo || ''}
                  error={this.getErrorForChangeRelationship('wrBasicFileNo')}
                  autoComplete="off"
                />
                <Divider hidden fitted />
                <Divider hidden fitted />
                <Form.Field
                  size="large"
                  control={Input}
                  placeholder="Item Number"
                  onChange={this.handleTextChange}
                  name="wrItemNo"
                  value={changeRelationshipForm.wrItemNo || ''}
                  error={this.getErrorForChangeRelationship('wrItemNo')}
                  autoComplete="off"
                />
              </Form>
            </fieldset>
            <Divider hidden fitted />
            <Divider hidden fitted />
            <fieldset
              style={{
                width: '230px',
                paddingLeft: '50px',
                paddingRight: '60px',
              }}
            >
              <legend>Correct Relationship</legend>
              <Form>
                <Form.Field
                  size="large"
                  control={Input}
                  placeholder="Sold To Account No."
                  onChange={this.handleTextChange}
                  name="crSoldToAccntNo"
                  value={changeRelationshipForm.crSoldToAccntNo || ''}
                  error={this.getErrorForChangeRelationship('crSoldToAccntNo')}
                  autoComplete="off"
                />
                <Divider hidden fitted />
                <Divider hidden fitted />
                <Form.Field
                  size="large"
                  control={Input}
                  placeholder="Ship To Account No."
                  onChange={this.handleTextChange}
                  name="crShipToAccntNo"
                  value={changeRelationshipForm.crShipToAccntNo || ''}
                  error={this.getErrorForChangeRelationship('crShipToAccntNo')}
                  autoComplete="off"
                />
                <Divider hidden fitted />
                <Divider hidden fitted />
                <Form.Field
                  size="large"
                  control={Input}
                  placeholder="Basic File No."
                  onChange={this.handleTextChange}
                  name="crBasicFileNo"
                  value={changeRelationshipForm.crBasicFileNo || ''}
                  error={this.getErrorForChangeRelationship('crBasicFileNo')}
                  autoComplete="off"
                />
                <Divider hidden fitted />
                <Divider hidden fitted />
                <Form.Field
                  size="large"
                  control={Input}
                  placeholder="Item Number"
                  onChange={this.handleTextChange}
                  name="crItemNo"
                  value={changeRelationshipForm.crItemNo || ''}
                  error={this.getErrorForChangeRelationship('crItemNo')}
                  autoComplete="off"
                />
              </Form>
            </fieldset>
          </Modal.Content>
          <Modal.Actions>
            <Button primary onClick={() => this.handleUpdateRelationship()}>
              Ok
            </Button>
            <Button
              primary
              disabled={loading}
              basic
              onClick={() => this.handleChangeRelationModal(false)}
            >
              Cancel
            </Button>
          </Modal.Actions>
        </Modal>

        <Modal
          size="mini"
          open={isSingleRelModalOpen}
          onClose={this.handleCloseConfirmDialog}
        >
          <Modal.Header style={{ textAlign: 'center' }}>
            Add New Relationship
          </Modal.Header>
          <Modal.Content>
            <fieldset
              style={{
                width: '230px',
                paddingLeft: '50px',
                paddingRight: '60px',
              }}
            >
              <legend>New Relationship</legend>
              <Form>
                <Form.Field
                  size="large"
                  control={Input}
                  placeholder="Sold To Account No."
                  onChange={this.handleTextChangeSingleRel}
                  name="soldToAccntNo"
                  value={singleRelationShipForm.soldToAccntNo || ''}
                  error={this.getErrorForSingleChangeRelationship(
                    'soldToAccntNo'
                  )}
                  autoComplete="off"
                />
                <Divider hidden fitted />
                <Divider hidden fitted />
                <Form.Field
                  size="large"
                  control={Input}
                  placeholder="Ship To Account No."
                  onChange={this.handleTextChangeSingleRel}
                  name="shipToAccntNo"
                  value={singleRelationShipForm.shipToAccntNo || ''}
                  error={this.getErrorForSingleChangeRelationship(
                    'shipToAccntNo'
                  )}
                  autoComplete="off"
                />
                <Divider hidden fitted />
                <Divider hidden fitted />
                <Form.Field
                  size="large"
                  control={Input}
                  placeholder="Basic File No."
                  onChange={this.handleTextChangeSingleRel}
                  name="basicFileNo"
                  value={singleRelationShipForm.basicFileNo || ''}
                  error={this.getErrorForSingleChangeRelationship(
                    'basicFileNo'
                  )}
                  autoComplete="off"
                />
                <Divider hidden fitted />
                <Divider hidden fitted />
                <Form.Field
                  size="large"
                  control={Input}
                  placeholder="Item Number"
                  onChange={this.handleTextChangeSingleRel}
                  name="itemNo"
                  value={singleRelationShipForm.itemNo || ''}
                  error={this.getErrorForSingleChangeRelationship('itemNo')}
                  autoComplete="off"
                />
              </Form>
            </fieldset>
          </Modal.Content>
          <Modal.Actions>
            <Button primary onClick={() => this.handleAddSingleRelationship()}>
              Ok
            </Button>
            <Button
              primary
              disabled={loading}
              basic
              onClick={() => this.handleAddSingleRelationModal(false)}
            >
              Cancel
            </Button>
          </Modal.Actions>
        </Modal>

        <Modal
          size="mini"
          open={isCreateLabelSupplierEmailModalOpen}
          onClose={this.handleCloseConfirmDialog}
        >
          <Modal.Header style={{ textAlign: 'center' }}>
            New Label Supplier Email
          </Modal.Header>
          <Modal.Content>
            <fieldset
              style={{
                width: '230px',
                paddingLeft: '50px',
                paddingRight: '60px',
              }}
            >
              <legend>Label Supplier Email Details</legend>
              <Form>
                <Form.Field
                  size="large"
                  control={Input}
                  placeholder="Printer File Number"
                  onChange={this.handleChangeLabelSupplierEmailForm}
                  name="printerFileNumber"
                  value={createLabelSupplierEmailForm.printerFileNumber || ''}
                  error={this.getErrorForLabelSupplierEmailForm(
                    'printerFileNumber'
                  )}
                  autoComplete="off"
                />
                <Divider hidden fitted />
                <Divider hidden fitted />
                <Form.Field
                  size="large"
                  control={Input}
                  placeholder="Printer PSN"
                  onChange={this.handleChangeLabelSupplierEmailForm}
                  name="printerPsn"
                  value={createLabelSupplierEmailForm.printerPsn || ''}
                  error={this.getErrorForLabelSupplierEmailForm('printerPsn')}
                  autoComplete="off"
                />
                <Divider hidden fitted />
                <Divider hidden fitted />
                <Form.Field
                  size="large"
                  control={Input}
                  placeholder="Email Address"
                  onChange={this.handleChangeLabelSupplierEmailForm}
                  name="emailAddress"
                  value={createLabelSupplierEmailForm.emailAddress || ''}
                  error={this.getErrorForLabelSupplierEmailForm('emailAddress')}
                  autoComplete="off"
                />
              </Form>
            </fieldset>
          </Modal.Content>
          <Modal.Actions>
            <Button
              primary
              onClick={() => this.handleCreateLabelSupplierEmail()}
              loading={loading}
              disabled={loading}
            >
              Ok
            </Button>
            <Button
              primary
              disabled={loading}
              basic
              onClick={() => this.handleAddLabelSupplierEmailModal(false)}
            >
              Cancel
            </Button>
          </Modal.Actions>
        </Modal>

        <Modal open={showModalLtlWarehouse} size="mini">
          <Modal.Header>Confirmation</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <p>Set LTL Warehouse for this Item Number?</p>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.handleLtlWarehouseModal.bind(this, false)}>
              Cancel
            </Button>
            <Button
              primary
              onClick={this.onConfirmToggleLtlWarehouse.bind(this)}
            >
              Yes
            </Button>
          </Modal.Actions>
        </Modal>

        <ViewLabelsDialog
          isOpenViewLabelsDialog={isOpenViewLabelsDialog}
          handleCloseViewLabelsDialog={this.handleCloseViewLabelsDialog}
          handlePageChangeLabels={this.handlePageChangeLabels}
          currentPage={this.props.currentPage}
          totalPages={this.props.totalPages}
          pageSize={this.props.pageSize}
          mapLabels={mapLabels}
          noFilter={noFilter}
          labels={labels}
          userRole={uploadTypeOption}
          uploadType={form.uploadType}
          labelSupplierEmailsFiltered={labelSupplierEmailsFiltered}
          combinationAllowedCcns={combinationAllowedCcns}
          combinationItemNumbers={
            this.props.priceManagement.combinationItemNumbers
          }
          handleApplyCombinationLabelFilter={
            this.handleApplyCombinationLabelFilter
          }
          handleClearCombinationLabelFilter={
            this.handleClearCombinationLabelFilter
          }
          handleApplyLabelSupplierPrinterEmailFilter={
            this.handleApplyLabelSupplierPrinterEmailFilter
          }
          handleClearLabelSupplierEmailFilter={
            this.handleClearLabelSupplierEmailFilter
          }
          nonDenominationalAccounts={nonDenominationalAccounts}
          csvData={this.props.priceManagement.csvData}
          selectItemNumber={this.handleSelectItemNumber}
          selectAllItems={this.handleSelectAllItems}
          removeCombinationLabels={this.handleRemoveCombinationLabels}
          isRemoveSuccess={isRemoveSuccess}
          selectedItems={selectedItems}
          selectedItemNumbers={selectedItemNumbers}
          resetRemoveLabels={this.handleResetRemoveLabels}
          currencyHandlingFees={currencyHandlingFees}
          handleApplyStandardLabelsFilter={this.handleApplyStandardLabelsFilter}
          handleClearStandardLabelsFilter={this.handleClearStandardLabelsFilter}
        />

        <div className="container-wrapper">
          <Tab panes={panes} />
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentPage: state.priceManagement.currentPage,
    pageSize: state.priceManagement.pageSize,
    totalPages: state.priceManagement.totalPages,
    loading: state.priceManagement.loading,
    isSuccess: state.priceManagement.isSuccess,
    isRemoveSuccess: state.priceManagement.isRemoveSuccess,
    priceManagement: state.priceManagement,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getLabels: () => dispatch(actions.getLabels()),
    viewLabels: (
      labels,
      nonDenominationalAccounts,
      uploadType,
      labelSupplierEmailsFiltered,
      combinationAllowedCcns,
      currencyHandlingFees,
      standardLabelsFiltered
    ) =>
      dispatch(
        actions.viewLabels(
          labels,
          nonDenominationalAccounts,
          uploadType,
          labelSupplierEmailsFiltered,
          combinationAllowedCcns,
          currencyHandlingFees,
          standardLabelsFiltered
        )
      ),
    changePageLabels: data => dispatch(actions.changePageLabels(data)),
    applyFilter: data => dispatch(actions.applyFilter(data)),
    updateLabels: data => dispatch(actions.updateLabels(data)),
    updateLabelsPrice: data => dispatch(actions.updateLabelsPrice(data)),
    changePage: data => dispatch(actions.changePage(data)),
    updateData: data => dispatch(actions.updateData(data)),
    updateDataPrice: data => dispatch(actions.updateDataPrice(data)),
    updateCombinationData: data =>
      dispatch(actions.updateCombinationData(data)),
    forUpdateCourierData: data => dispatch(actions.forUpdateCourierData(data)),
    updateForm: result => dispatch(actions.updateForm(result)),
    clearForm: () => dispatch(actions.clearForm()),
    getCombinationLabels: () => dispatch(actions.getCombinationLabels()),
    updateCombinationLabels: data =>
      dispatch(actions.updateCombinationLabels(data)),
    updateChangeRelationshipForm: result =>
      dispatch(actions.updateChangeRelationshipForm(result)),
    updateSingleRelationshipForm: result =>
      dispatch(actions.updateSingleRelationshipForm(result)),
    clearChangeRelationshipForm: () =>
      dispatch(actions.clearChangeRelationshipForm()),
    updateCustomerCombinationLabel: data =>
      dispatch(actions.updateCustomerCombinationLabel(data)),
    addSingleCustomerCombinationLabel: data =>
      dispatch(actions.addSingleCustomerCombinationLabel(data)),
    getCourierTracking: () => dispatch(actions.getCourierTracking()),
    updateCourierTracking: data =>
      dispatch(actions.updateCourierTracking(data)),
    getCombinationLabelFilter: data =>
      dispatch(actions.getCombinationLabelFilter(data)),
    clearCombinationLabelFilter: () =>
      dispatch(actions.clearCombinationLabelFilter()),
    getActivePage: page => dispatch(commonActions.getActivePage(page)),
    getNonDenominationalAccounts: () =>
      dispatch(actions.getNonDenominationalAccounts()),
    loadNonDenominationalAccounts: data =>
      dispatch(actions.loadNonDenominationalAccounts(data)),
    createNonDenominationalAccounts: data =>
      dispatch(actions.createNonDenominationalAccounts(data)),
    clearSingleRelationshipForm: () =>
      dispatch(actions.clearSingleRelationshipForm()),
    refreshCsvData: data => dispatch(actions.refreshCsvData(data)),
    getLabelSupplierEmail: () => dispatch(actions.getLabelSupplierEmail()),
    getLabelSupplierPrinterEmailFilter: data =>
      dispatch(actions.getLabelSupplierPrinterEmailFilter(data)),
    updateLabelSupplierEmailTemplateData: data =>
      dispatch(actions.updateLabelSupplierEmailTemplateData(data)),
    labelSupplierEmailUpload: data =>
      dispatch(actions.labelSupplierEmailUpload(data)),
    clearLabelSupplierEmailFilter: () =>
      dispatch(actions.clearLabelSupplierEmailFilter()),
    getCombinationAllowedCcn: () =>
      dispatch(actions.getCombinationAllowedCcn()),

    updateCombinationAllowedCcnTemplateData: data =>
      dispatch(actions.updateCombinationAllowedCcnTemplateData(data)),

    combinationAllowedCcnUpload: data =>
      dispatch(actions.combinationAllowedCcnUpload(data)),
    selectItemNumber: (id, checked) =>
      dispatch(actions.selectItemNumber(id, checked)),
    selectAllItems: checked => dispatch(actions.selectAllItems(checked)),
    removeCombinationLabels: data =>
      dispatch(actions.removeCombinationLabels(data)),
    resetRemoveLabels: () => dispatch(actions.resetRemoveLabels()),
    createCurrencyHandlingFees: data =>
      dispatch(actions.createCurrencyHandlingFees(data)),
    getCurrencyHandlingFees: () => dispatch(actions.getCurrencyHandlingFees()),
    loadCurrencyHandlingFee: data =>
      dispatch(actions.loadCurrencyHandlingFee(data)),
    getConfigurationSettings: () =>
      dispatch(actions.getConfigurationSettings()),
    updateConfigurationSettings: data =>
      dispatch(actions.updateConfigurationSettings(data)),
    updateLabelSupplierEmailForm: result =>
      dispatch(actions.updateLabelSupplierEmailForm(result)),
    createLabelSupplierEmail: data =>
      dispatch(actions.createLabelSupplierEmail(data)),
    clearLabelSupplierEmailForm: () =>
      dispatch(actions.clearLabelSupplierEmailForm()),
    updateLabelItem: data => dispatch(actions.updateLabelItem(data)),
    getLabelItems: (currentPage, pageSize, viewLtlItems, itemNumberSearch) =>
      dispatch(
        actions.getLabelItems(
          currentPage,
          pageSize,
          viewLtlItems,
          itemNumberSearch
        )
      ),
    getStandardLabelTemplatesFilter: data =>
      dispatch(actions.getStandardLabelTemplatesFilter(data)),
    clearStandardLabelTemplatesFilter: () =>
      dispatch(actions.clearStandardLabelTemplatesFilter()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PriceManagement);
