import { requests } from '../request-factory';
import { mapDataToQueryString } from '../request-helpers';

const manufacturerService = {
  getFileNumbers: data => requests.post(`manufacturer/filenumbers`, data),
  getLabels: (
    labelOrderCartId,
    ccn,
    manufacturerId,
    fileNumber,
    ccnList,
    fileNumberList,
    volume,
    volumeList,
    accountNumber
  ) =>
    requests.get(
      `manufacturer/labels?${mapDataToQueryString({
        labelOrderCartId,
        ccn,
        manufacturerId,
        fileNumber,
        ccnList,
        fileNumberList,
        volume,
        volumeList,
        accountNumber,
      })}`
    ),
  addToCart: cartData => requests.post('manufacturer/cart', cartData),
  updateCart: cartData => requests.post('manufacturer/cart/update', cartData),
  deleteCart: cartData => requests.post('manufacturer/cart/delete', cartData),
  getCartItems: labelUserId =>
    requests.get(`manufacturer/cart?${mapDataToQueryString({ labelUserId })}`),
  getDraftCarts: () => requests.get('manufacturer/cart/draft'),
  deleteDraftCart: labelOrderId =>
    requests.post(`manufacturer/cart/draft/delete/${labelOrderId}`),
  getExistingPoNumber: customerPoNumber =>
    requests.post('manufacturer/cart/validate-po', { customerPoNumber }),
  checkFileVolCcn: cartData =>
    requests.post('manufacturer/cart/validate-filevolccn', cartData),
  getNonDenominationalAccounts: () =>
    requests.get('manufacturer/non-denominational-accounts'),
  createNonDenominationalAccounts: data =>
    requests.post('manufacturer/non-denominational-accounts', data),
};

export default manufacturerService;
