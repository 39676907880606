import { requests } from '../request-factory';

const labelOrderCartItemService = {
  uploadAttachment: formData =>
    requests.upload('label-order-cart-item/upload-attachment', formData),
  uploadCombinationLabelAttachment: data =>
    requests.post('label-order-cart-item/upload-combination-label-attachment', {
      orderAttachmentData: JSON.stringify(data),
    }),
};

export default labelOrderCartItemService;
