import React, { Component } from "react";
// import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { doLogout, doExternalLogout } from "../../views/auth/auth.action";
import { Icon, Menu } from "semantic-ui-react";
import { Common } from "../../common";
import Role from "../../enums/role";
import IdentityProvider from "../../enums/identity-provider";

const IDENTITY_PROVIDER = `${process.env.REACT_APP_IDENTITY_PROVIDER}`;
const API_BASE = `${process.env.REACT_APP_API_BASE}`;

class Logout extends Component {
  state = {
    navigate: false
  };

  handleLogout = () => {
    if (IDENTITY_PROVIDER == IdentityProvider.Iam) {
      localStorage.clear();
      var externalLogoutURL = `${API_BASE}auth/logout`;
      window.location.replace(externalLogoutURL);
      this.props.logout();
    } else if (IDENTITY_PROVIDER == IdentityProvider.B2c) {
      var b2cToken = Common.getB2cToken();
      localStorage.clear();

      Common.toggleLogout(b2cToken);
    }

    this.setState({ navigate: true });
  };

  render() {
    const { navigate } = this.state;
    const { isLogoutSuccess, data } = this.props;
    const framesLength = parseInt(localStorage.getItem("framesLength"));
    const translations = Common.getTranslations() || {};
    var roleId = Common.getUserRole();
    var isAdmin = roleId === Role.labelCenter || roleId === Role.labelManager;

    if (framesLength == 0) {
      return (
        <Menu.Item
          name="logout"
          className="inactive-item"
          onClick={this.handleLogout}
        >
          <Icon name="log out" className="item-icon" />
          {isAdmin ? "Log out" : translations["LexLogOut"]}
        </Menu.Item>
        // <h5>Logout</h5>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    isLogoutSuccess: state.auth.isLogoutSuccess,
    data: state.auth.data
  };
};

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(doLogout()),
  logoutExternal: () => dispatch(doExternalLogout())
});

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
