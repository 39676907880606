import React, { Component, Fragment } from "react";

import { Redirect, BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";
import { Common } from "../../common/common";

import HeaderBlock from "../../components/header/header-block";

import "../../assets/css/welcome.css";

import SidebarMenu from "../../components/sidebar/sidebar-menu";
import Cookies from "../../components/cookies/cookies";
import "react-datepicker/dist/react-datepicker.css";
import { SemanticToastContainer } from "react-semantic-toasts";
const CHECK_API_SWITCH = `${process.env.REACT_APP_CHECK_API_SWITCH}`;

class Welcome extends Component {
  state = {
    isLoggedOut: false,
    activeItem: "bio"
  };

  handleItemClick = (e, { name }) => this.setState({ activeItem: name });

  render() {
    document.getElementById("root").style.backgroundColor = "#EDF1F6";

    const userId = Common.getUserId();
    const userRole = Common.getUserRole();
    const isSuspended = Common.getIsSuspended();
    const isThereApiDown = Common.getIsThereApiDown();
    const isSuccessSetRole = Common.getIsSuccessSetRole();

    if (userId) {
      if (isSuspended === "True") return <Redirect to="/suspended-page" />;
      if (isThereApiDown === "True" && CHECK_API_SWITCH === "1")
        return <Redirect to="/api-down-page" />;
      if (isSuccessSetRole !== "True")
        return <Redirect to="/role-not-found-page" />;
      if (userRole == 7) {
        return <Redirect to="/error-page" />;
      }
    } else {
      Common.toggleLogin();
    }

    return (
      <Fragment>
        <Router>
          <HeaderBlock />
          <SemanticToastContainer position="top-right" />
          <SidebarMenu />
          <Cookies />
        </Router>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  hasError: state.auth.hasError,
  message: state.auth.message,
  loading: state.auth.loading,
  userId: state.auth.userId
});

export default connect(mapStateToProps)(Welcome);
