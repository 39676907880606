import * as types from "./admin-search.type";
import api from "../../services/api";

export const adminSearchResult = (obj, type) => {
  return {
    type: type,
    data: obj
  };
};

export const doSearch = (
  psn,
  accountNumber,
  orderConfirmationNumber,
  oracleOrderNumber,
  sortColumn,
  sortOrder,
  currentPage,
  pageSize,
  searchTerm,
  searchType
) => {
  return async (dispatch) => {
    dispatch({ type: types.ADMIN_SEARCH_REQUEST });
    const result = await api.Order.searchOrder(
      psn,
      accountNumber,
      orderConfirmationNumber,
      oracleOrderNumber,
      sortColumn,
      sortOrder,
      currentPage,
      pageSize,
      searchTerm,
      searchType
    );
    if (result) {
      dispatch(adminSearchResult(result, types.ADMIN_SEARCH_SUCCESS));
      return result;
    } else {
      dispatch(adminSearchResult(result, types.ADMIN_SEARCH_FAILURE));
    }
  };
};

export const updateForm = (result) => {
  return (dispatch) => dispatch(adminSearchResult(result, types.FORM));
};

export const clearAdminSearch = () => (dispatch) =>
  dispatch(adminSearchResult({}, types.CLEAR));
