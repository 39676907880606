import React, { Component } from "react";
import {
  Form,
  Grid,
  Dropdown,
  Divider,
  Input,
  Checkbox,
  Segment,
  Popup
} from "semantic-ui-react";
import {
  CustomTitle,
  CustomHeader,
  CustomHeaderLabel,
  CustomLabel,
  CustomSubLabel,
  CustomTitleLabel
} from "../../components/labels/labels";
import { connect } from "react-redux";
import FesProductDetails from "./product-details/fes/product-details";
import FeProductDetails from "./product-details/fe/product-details";
import Role from "../../enums/role";
import { Common } from "../../common";

import * as actions from "./field-evaluation.action";
import * as fesAction from "./product-details/fes/product-details.action";
import * as feActions from "./product-details/fe/product-details.action";
import PrelimSiteLocation from "./components/fe/prelim-site-location";
import FinalSiteLocation from "./components/fe/final-site-location";
import AHJDetails from "./components/fe/ahj-details";
import { getError } from "../../validator/validator";

class SiteDetails extends Component {
  state = {
    showPrelimLocation: "true",
    isSuccessGetSiteDetails: false,
    isSuccessGetSchedulingDetails: false
  };

  onChangeInstallatioRadioGroup = (e, { value }) => {
    let { siteDetails, reservationDetails } = this.props.form;
    siteDetails.showPrelimLocation = value;
    reservationDetails.sameAsApplicant = value;
    const newState = { ...this.props.form };
    this.props.form(newState);
  };

  onChangeScheduleNowRadioGroup(e, { value }) {
    this.setState({ scheduleNow: value });
  }

  handleGetProjectStates = (countryCode, locationType) => {
    this.props.getProjectStates(countryCode, locationType);
  };

  handleGetEngineerStates = (countryCode, locationType) => {
    this.props.getEngineerStates(countryCode, locationType);
  };

  handleGetProjectCities = (stateCode, locationType) => {
    this.props.getProjectCities(stateCode, locationType);
  };

  handleGetEngineerCities = (stateCode, locationType) => {
    this.props.getEngineerCities(stateCode, locationType);
  };

  handleFillPrelimSiteDetails = (checked) => {
    const { accountSettings } = this.props;

    let accountDetails = {
      residenceName:
        accountSettings.applicantDetails.applicantDetails.legalCompanyName,
      addressLine1:
        accountSettings.applicantDetails.applicantDetails.addressLine1,
      addressLine2:
        accountSettings.applicantDetails.applicantDetails.addressLine2,
      addressLine3:
        accountSettings.applicantDetails.applicantDetails.addressLine3,
      country: accountSettings.applicantDetails.applicantDetails.country,
      state: accountSettings.applicantDetails.applicantDetails.state,
      city: accountSettings.applicantDetails.applicantDetails.city,
      postalCode: accountSettings.applicantDetails.applicantDetails.postalCode,
      contactName:
        accountSettings.applicantDetails.applicantDetails.contactName,
      phoneNumber:
        accountSettings.applicantDetails.applicantDetails.phoneNumber,
      mobileNumber:
        accountSettings.applicantDetails.applicantDetails.mobileNumber,
      emailAddress:
        accountSettings.applicantDetails.applicantDetails.emailAddress
    };

    this.props.fillPrelimSiteDetails(checked, accountDetails);

    if (checked == "true") {
      this.handleGetProjectStates(accountDetails.country, 1);
      this.handleGetProjectCities(accountDetails.state, 1);
    }
  };

  handleFillFinalSiteDetails = (checked) => {
    const { accountSettings } = this.props;

    let accountDetails = {
      residenceName:
        accountSettings.applicantDetails.applicantDetails.legalCompanyName,
      addressLine1:
        accountSettings.applicantDetails.applicantDetails.addressLine1,
      addressLine2:
        accountSettings.applicantDetails.applicantDetails.addressLine2,
      addressLine3:
        accountSettings.applicantDetails.applicantDetails.addressLine3,
      countryCode: accountSettings.applicantDetails.applicantDetails.country,
      state: accountSettings.applicantDetails.applicantDetails.state,
      city: accountSettings.applicantDetails.applicantDetails.city,
      postalCode: accountSettings.applicantDetails.applicantDetails.postalCode,
      contactName:
        accountSettings.applicantDetails.applicantDetails.contactName,
      phoneNumber:
        accountSettings.applicantDetails.applicantDetails.phoneNumber,
      mobileNumber:
        accountSettings.applicantDetails.applicantDetails.mobileNumber,
      emailAddress:
        accountSettings.applicantDetails.applicantDetails.emailAddress
    };

    this.props.fillFinalSiteDetails(checked, accountDetails);

    if (checked == "true") {
      this.handleGetProjectStates(accountDetails.countryCode, 2);
      this.handleGetProjectCities(accountDetails.state, 2);
    }
  };

  handleFillSchedulingDetails = (checked) => {
    const { customerDetails } = this.props;
    const { siteDetails } = this.props.form;
    // let accountDetails = customerDetails.data;

    var roleId = Common.getUserRole();
    let accountDetails = null;
    if (roleId == Role.fireEquipment) {
      accountDetails = {
        prelim: siteDetails.preliminarySiteLocationSection
      };
    } else {
      if (siteDetails.showPrelimLocation == "false") {
        accountDetails = {
          prelim: siteDetails.preliminarySiteLocationSection,
          final: siteDetails.finalInstallationSiteSection,
          ahj: siteDetails.authorityHavingJurisdictionDetails
        };
      } else {
        accountDetails = {
          prelim: null,
          final: siteDetails.finalInstallationSiteSection,
          ahj: siteDetails.authorityHavingJurisdictionDetails
        };
      }
    }

    this.props.fillSchedulingDetails(checked, accountDetails);
    if (checked && accountDetails) {
      if (accountDetails.prelim) {
        this.handleGetEngineerStates(accountDetails.prelim.country, 1);
        this.handleGetEngineerCities(accountDetails.prelim.state, 1);
      }
      if (accountDetails.final) {
        this.handleGetEngineerStates(accountDetails.final.country, 2);
        this.handleGetEngineerCities(accountDetails.final.state, 2);
      }
    }
  };

  handleFillExistingSchedulingDetails = (checked, custDetails) => {
    this.props.fillSchedulingDetails(checked, custDetails);
    if (checked) {
      this.handleGetEngineerStates(custDetails.countryCode, 1);
      this.handleGetEngineerCities(custDetails.state, 1);
    }
  };

  getReadyToScheduleNowError() {
    const { otherDetails } = this.props.form;

    return (
      otherDetails &&
      otherDetails.validationErrors &&
      otherDetails.validationErrors.isReadyToScheduleNow
    );
  }

  getErrorFor(field) {
    const { siteDetails } = this.props.form;

    return getError(field, siteDetails, siteDetails);
  }

  getErrorForChk(field) {
    const msg = this.getErrorFor(field);

    if (!msg || (msg && !msg.length)) return null;

    return (
      <React.Fragment>
        <br />
        <div className="ui pointing above prompt label chk-rad-error">
          {msg}
        </div>
      </React.Fragment>
    );
  }

  getErrorForChkClass(field) {
    const msg = this.getErrorFor(field);

    return msg && msg.length ? "error" : "";
  }

  render() {
    let {
      siteDetails,
      otherDetails,
      productDetails,
      orderDetails,
      reservationDetails
    } = this.props.form;
    const { customerDetails, accountSettings, custDetails } = this.props;

    let accountDetails = customerDetails.data;

    let {
      countryOptionsPrelimProjectDetails,
      countryOptionsFinalProjectDetails,
      stateOptionsPrelimProjectDetails,
      stateOptionsFinalProjectDetails,
      cityOptionsPrelimProjectDetails,
      cityOptionsFinalProjectDetails,
      countryOptionsAhjDetails,
      stateOptionsAhjDetails,
      cityOptionsAhjDetails,
      isSuccessGetOrderDetails
    } = this.props.fieldEvaluation;

    let preview = this.props.preview;
    let preliminarySiteDetails = null;
    let finalInstallationSiteDetails = null;
    let ahjDetails = null;
    let productOrders = null;
    const {
      showPrelimLocation,
      scheduleNow,
      isSuccessGetSiteDetails,
      isSuccessGetSchedulingDetails
    } = this.state;

    // if (siteDetails) {
    //   preliminarySiteDetails = siteDetails.preliminarySiteLocationSection;
    //   finalInstallationSiteDetails = siteDetails.finalInstallationSiteSection;
    //   ahjDetails = siteDetails.authorityHavingJurisdictionDetails;
    // }

    if (siteDetails) {
      preliminarySiteDetails = siteDetails.preliminarySiteLocationSection;
      finalInstallationSiteDetails = siteDetails.finalInstallationSiteSection;
      ahjDetails = siteDetails.authorityHavingJurisdictionDetails;

      if (
        finalInstallationSiteDetails.fieldEvaluationOrderId &&
        !isSuccessGetSiteDetails
      ) {
        this.handleGetProjectStates(finalInstallationSiteDetails.country, 2);
        this.handleGetProjectCities(finalInstallationSiteDetails.state, 2);
        // this.handleGetProjectStates(preliminarySiteDetails.country, 2);
        // this.handleGetProjectCities(preliminarySiteDetails.state, 2);

        // this.handleFillSchedulingDetails(otherDetails.isReadyToScheduleNow);
        this.setState({ isSuccessGetSiteDetails: true });
      }

      if (
        preliminarySiteDetails.fieldEvaluationOrderId &&
        !isSuccessGetSiteDetails
      ) {
        this.handleGetProjectStates(preliminarySiteDetails.country, 1);
        this.handleGetProjectCities(preliminarySiteDetails.state, 1);

        this.setState({ isSuccessGetSiteDetails: true });
      }

      if (ahjDetails.fieldEvaluationOrderId && !isSuccessGetSiteDetails) {
        this.handleGetProjectStates(ahjDetails.country, 3);
        this.handleGetProjectCities(ahjDetails.state, 3);
      }

      if (
        productDetails.initialState.orders.length > 0 &&
        isSuccessGetOrderDetails
      ) {
        productOrders = productDetails.initialState.orders;

        this.props.getProductDetails(productOrders[0]);

        this.props.updateIsSuccess();
      }
    }

    // if (custDetails && custDetails.data && !isSuccessGetSchedulingDetails) {
    //   this.handleFillExistingSchedulingDetails(
    //     otherDetails.isReadyToScheduleNow,
    //     custDetails.data
    //   );
    //   this.setState({ isSuccessGetSchedulingDetails: true });
    // }

    let userRole = Common.getUserRole();

    if (userRole == Role.fireEquipment) {
      if (productOrders && productOrders.length > 0) {
        productOrders.forEach((me) => {
          this.props.addFESOrder(me);
          const index = productOrders.indexOf(me);
          if (index > -1) {
            productOrders.splice(index, 1);
          }
        });
      }
    }

    return (
      <Form>
        {userRole != Role.projectHandler ? (
          <span>
            {userRole == Role.fieldEngineer ? (
              <FeProductDetails
                checkManualPricingTrigger={this.props.checkManualPricingTrigger}
              />
            ) : null}
            {userRole == Role.fireEquipment ? <FesProductDetails /> : null}
          </span>
        ) : (
          <span>
            {/* {orderDetails && orderDetails.serviceType == 1 ? (
              <FeProductDetails
                checkManualPricingTrigger={this.props.checkManualPricingTrigger}
              />
            ) : null}
            {orderDetails && orderDetails.serviceType == 2 ? (
              <FesProductDetails />
            ) : null} */}
            <FeProductDetails
              checkManualPricingTrigger={this.props.checkManualPricingTrigger}
            />
          </span>
        )}

        <Divider hidden />
        <CustomLabel message="SITE DETAILS" />
        <Divider fitted />
        <Divider hidden />
        <Grid>
          {userRole == Role.fieldEngineer || userRole == Role.projectHandler ? (
            <Grid.Row>
              <Grid.Column>
                <Form.Field
                  className={`rad-chk-field ${this.getErrorForChkClass(
                    "showPrelimLocation"
                  )}`}
                >
                  {"* Is equipment already installed?"}
                </Form.Field>
                <Form.Field
                  className={this.getErrorForChkClass("showPrelimLocation")}
                >
                  <Checkbox
                    radio
                    label="Yes"
                    name="showPrelimLocation"
                    value="true"
                    checked={siteDetails.showPrelimLocation === "true"}
                    onChange={(e, { value }) => {
                      this.props.handleCheckboxChange(
                        "showPrelimLocation",
                        value,
                        "SD"
                      );
                    }}
                  />
                  &nbsp;&nbsp;&nbsp;
                  <Checkbox
                    radio
                    label="No"
                    name="showPrelimLocation"
                    value="false"
                    checked={siteDetails.showPrelimLocation === "false"}
                    onChange={(e, { value }) => {
                      this.props.handleCheckboxChange(
                        "showPrelimLocation",
                        value,
                        "SD"
                      );
                    }}
                  />
                  {this.getErrorForChk("showPrelimLocation")}
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
          ) : (
            <Grid.Row>
              <Grid.Column>
                <Form.Field>
                  {"Is place of inspection same as the Applicant?"}
                </Form.Field>
                <Form.Field>
                  <Checkbox
                    radio
                    label="Yes"
                    name="installationRadioGroup"
                    value="true"
                    checked={siteDetails.sameAsApplicant === "true"}
                    onChange={(e, { value }) => {
                      this.props.handleCheckboxChange(
                        "sameAsApplicant",
                        value,
                        "SD"
                      );
                      this.handleFillSiteDetails(value);
                    }}
                    // onChange={this.onChangeInstallatioRadioGroup.bind(this)}
                  />
                  &nbsp;&nbsp;&nbsp;
                  <Checkbox
                    radio
                    label="No"
                    name="installationRadioGroup"
                    value="false"
                    checked={siteDetails.sameAsApplicant === "false"}
                    onChange={(e, { value }) => {
                      this.props.handleCheckboxChange(
                        "sameAsApplicant",
                        value,
                        "SD"
                      );
                      this.handleFillSiteDetails(value);
                    }}
                    // onChange={this.onChangeInstallatioRadioGroup.bind(this)}
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
        {userRole == Role.fireEquipment ? (
          <span>
            <Divider hidden />
            <CustomLabel message="INSPECTION SITE LOCATION" />
            <Divider fitted />
            <Divider hidden />
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <span hidden={preview}>
                    <Form.Field
                      id="form-input-control-lcn"
                      control={Input}
                      label="* Business/Residence Name"
                      placeholder="Enter business/residence Name"
                      name="residenceName"
                      onChange={(e) => {
                        this.props.handleChange(e, "SD-FISS");
                      }}
                      value={finalInstallationSiteDetails.residenceName || ""}
                      error={this.props.getErrorFor("residenceName", "SD-FISS")}
                      autoComplete="off"
                    />
                  </span>
                  <span hidden={!preview}>
                    <label>
                      <b>* Business/Residence Name</b>
                    </label>
                    <label className="custom-label">
                      {finalInstallationSiteDetails.residenceName
                        ? finalInstallationSiteDetails.residenceName
                        : ""}
                    </label>
                  </span>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row columns={3}>
                <Grid.Column>
                  <span hidden={preview}>
                    <Form.Field
                      id="form-input-control-lcn"
                      control={Input}
                      label="* Address Line 1"
                      placeholder="Enter address line 1"
                      name="addressLine1"
                      onChange={(e) => {
                        this.props.handleChange(e, "SD-FISS");
                      }}
                      value={finalInstallationSiteDetails.addressLine1 || ""}
                      error={this.props.getErrorFor("addressLine1", "SD-FISS")}
                      autoComplete="off"
                    />
                  </span>
                  <span hidden={!preview}>
                    <label>
                      <b>* Address Line 1</b>
                    </label>
                    <label className="custom-label">
                      {finalInstallationSiteDetails.addressLine1
                        ? finalInstallationSiteDetails.addressLine1
                        : ""}
                    </label>
                  </span>
                </Grid.Column>
                <Grid.Column>
                  <span hidden={preview}>
                    <Form.Field
                      id="form-input-control-lcn"
                      control={Input}
                      label="Address Line 2"
                      placeholder="Enter address line 2"
                      name="addressLine2"
                      onChange={(e) => {
                        this.props.handleChange(e, "SD-FISS");
                      }}
                      value={finalInstallationSiteDetails.addressLine2 || ""}
                      error={this.props.getErrorFor("addressLine2", "SD-FISS")}
                      autoComplete="off"
                    />
                  </span>
                  <span hidden={!preview}>
                    <label>
                      <b>Address Line 2</b>
                    </label>
                    <label className="custom-label">
                      {finalInstallationSiteDetails.addressLine2
                        ? finalInstallationSiteDetails.addressLine2
                        : ""}
                    </label>
                  </span>
                </Grid.Column>
                <Grid.Column>
                  <span hidden={preview}>
                    <Form.Field
                      id="form-input-control-lcn"
                      control={Input}
                      label="Address Line 3"
                      placeholder="Enter address line 3"
                      name="addressLine3"
                      onChange={(e) => {
                        this.props.handleChange(e, "SD-FISS");
                      }}
                      value={finalInstallationSiteDetails.addressLine3 || ""}
                      error={this.props.getErrorFor("addressLine3", "SD-FISS")}
                      autoComplete="off"
                    />
                  </span>
                  <span hidden={!preview}>
                    <label>
                      <b>Address Line 3</b>
                    </label>
                    <label className="custom-label">
                      {finalInstallationSiteDetails.addressLine3
                        ? finalInstallationSiteDetails.addressLine3
                        : ""}
                    </label>
                  </span>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row columns={4}>
                <Grid.Column>
                  <span hidden={preview}>
                    <Form.Field
                      control={Dropdown}
                      compact
                      selection
                      label="* Country"
                      placeholder="Select Country"
                      options={countryOptionsFinalProjectDetails}
                      onChange={(e, properties) => {
                        this.props.handleDropdownChange(
                          "country",
                          properties.value,
                          "SD-FISS"
                        );
                        this.handleGetProjectStates(properties.value, 2);
                      }}
                      value={finalInstallationSiteDetails.country || ""}
                      error={this.props.getErrorFor("country", "SD-FISS")}
                    />
                  </span>
                  <span hidden={!preview}>
                    <label>
                      <b>* Country</b>
                    </label>
                    <label className="custom-label">
                      {finalInstallationSiteDetails.country
                        ? finalInstallationSiteDetails.country
                        : ""}
                    </label>
                  </span>
                </Grid.Column>
                <Grid.Column>
                  <span hidden={preview}>
                    <Form.Field
                      control={Dropdown}
                      compact
                      selection
                      label="* State"
                      placeholder="Select State"
                      options={stateOptionsFinalProjectDetails}
                      onChange={(e, properties) => {
                        this.props.handleDropdownChange(
                          "state",
                          properties.value,
                          "SD-FISS"
                        );
                        this.handleGetProjectCities(properties.value, 2);
                      }}
                      value={finalInstallationSiteDetails.state || ""}
                      error={this.props.getErrorFor("state", "SD-FISS")}
                    />
                  </span>
                  <span hidden={!preview}>
                    <label>
                      <b>* State</b>
                    </label>
                    <label className="custom-label">
                      {finalInstallationSiteDetails.state
                        ? finalInstallationSiteDetails.state
                        : ""}
                    </label>
                  </span>
                </Grid.Column>
                <Grid.Column>
                  <span hidden={preview}>
                    <Form.Field
                      control={Dropdown}
                      compact
                      selection
                      label="* City"
                      placeholder="Select City"
                      options={cityOptionsFinalProjectDetails}
                      onChange={(e, properties) => {
                        this.props.handleDropdownChange(
                          "city",
                          properties.value,
                          "SD-FISS"
                        );
                      }}
                      value={finalInstallationSiteDetails.city || ""}
                      error={this.props.getErrorFor("city", "SD-FISS")}
                    />
                  </span>
                  <span hidden={!preview}>
                    <label>
                      <b>* City</b>
                    </label>
                    <label className="custom-label">
                      {finalInstallationSiteDetails.city
                        ? finalInstallationSiteDetails.city
                        : ""}
                    </label>
                  </span>
                </Grid.Column>
                <Grid.Column>
                  <span hidden={preview}>
                    <Form.Field
                      id="form-input-control-lcn"
                      control={Input}
                      label="* Postal Code"
                      placeholder="Enter postal code"
                      name="postalCode"
                      onChange={(e) => {
                        this.props.handleChange(e, "SD-FISS");
                      }}
                      value={finalInstallationSiteDetails.postalCode || ""}
                      error={this.props.getErrorFor("postalCode", "SD-FISS")}
                      autoComplete="off"
                    />
                  </span>
                  <span hidden={!preview}>
                    <label>
                      <b>* Postal Code</b>
                    </label>
                    <label className="custom-label">
                      {finalInstallationSiteDetails.postalCode
                        ? finalInstallationSiteDetails.postalCode
                        : ""}
                    </label>
                  </span>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column>
                  <Grid.Row>
                    <Grid.Column>
                      <Form.Field
                        className={`rad-chk-field ${this.getErrorForChkClass(
                          "isReadyToScheduleNow"
                        )}`}
                      >
                        Are you ready to schedule now?
                      </Form.Field>
                      <Form.Field
                        className={this.getErrorForChkClass(
                          "isReadyToScheduleNow"
                        )}
                      >
                        <Checkbox
                          radio
                          label="Yes"
                          name="isReadyToScheduleNow"
                          value={true}
                          checked={otherDetails.isReadyToScheduleNow === true}
                          onChange={(e, { value }) => {
                            this.props.handleCheckboxChange(
                              "isReadyToScheduleNow",
                              value,
                              "SD-OTHERS"
                            );
                            this.handleFillSchedulingDetails(value);
                          }}
                        />
                        &nbsp;&nbsp;&nbsp;
                        <Checkbox
                          radio
                          label="No"
                          name="isReadyToScheduleNow"
                          value={false}
                          checked={otherDetails.isReadyToScheduleNow === false}
                          onChange={(e, { value }) => {
                            this.props.handleCheckboxChange(
                              "isReadyToScheduleNow",
                              value,
                              "SD-OTHERS"
                            );
                            this.handleFillSchedulingDetails(value);
                          }}
                        />
                        {this.getReadyToScheduleNowError() ? (
                          <React.Fragment>
                            <br />
                            <div className="ui pointing above prompt label ready-schedule">
                              {
                                otherDetails.validationErrors
                                  .isReadyToScheduleNow
                              }
                            </div>
                          </React.Fragment>
                        ) : null}
                      </Form.Field>
                    </Grid.Column>
                  </Grid.Row>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </span>
        ) : (
          ""
        )}

        {siteDetails.showPrelimLocation == "false" && (
          <span>
            <PrelimSiteLocation
              siteDetails={siteDetails}
              prelimDetails={siteDetails.preliminarySiteLocationSection}
              countryOptions={countryOptionsPrelimProjectDetails}
              stateOptions={stateOptionsPrelimProjectDetails}
              cityOptions={cityOptionsPrelimProjectDetails}
              getErrorFor={this.props.getErrorFor}
              handleCheckboxChange={this.props.handleCheckboxChange}
              handleChange={this.props.handleChange}
              handleDropdownChange={this.props.handleDropdownChange}
              handleFillSiteDetails={this.handleFillPrelimSiteDetails}
              handleGetProjectStates={this.handleGetProjectStates}
              handleGetProjectCities={this.handleGetProjectCities}
              formType="SD-PSLS"
            />

            <FinalSiteLocation
              siteDetails={siteDetails}
              finalDetails={siteDetails.finalInstallationSiteSection}
              countryOptions={countryOptionsFinalProjectDetails}
              stateOptions={stateOptionsFinalProjectDetails}
              cityOptions={cityOptionsFinalProjectDetails}
              getErrorFor={this.props.getErrorFor}
              handleCheckboxChange={this.props.handleCheckboxChange}
              handleChange={this.props.handleChange}
              handleDropdownChange={this.props.handleDropdownChange}
              handleFillSiteDetails={this.handleFillFinalSiteDetails}
              handleGetProjectStates={this.handleGetProjectStates}
              handleGetProjectCities={this.handleGetProjectCities}
              formType="SD-FISS"
            />

            <AHJDetails
              siteDetails={siteDetails}
              ahjDetails={siteDetails.authorityHavingJurisdictionDetails}
              countryOptions={countryOptionsAhjDetails}
              stateOptions={stateOptionsAhjDetails}
              cityOptions={cityOptionsAhjDetails}
              getErrorFor={this.props.getErrorFor}
              handleCheckboxChange={this.props.handleCheckboxChange}
              handleChange={this.props.handleChange}
              handleDropdownChange={this.props.handleDropdownChange}
              handleGetProjectStates={this.handleGetProjectStates}
              handleGetProjectCities={this.handleGetProjectCities}
              formType="SD-AHJD"
            />
          </span>
        )}

        {siteDetails.showPrelimLocation == "true" && (
          <span>
            <FinalSiteLocation
              siteDetails={siteDetails}
              finalDetails={siteDetails.finalInstallationSiteSection}
              countryOptions={countryOptionsFinalProjectDetails}
              stateOptions={stateOptionsFinalProjectDetails}
              cityOptions={cityOptionsFinalProjectDetails}
              getErrorFor={this.props.getErrorFor}
              handleCheckboxChange={this.props.handleCheckboxChange}
              handleChange={this.props.handleChange}
              handleDropdownChange={this.props.handleDropdownChange}
              handleFillSiteDetails={this.handleFillFinalSiteDetails}
              handleGetProjectStates={this.handleGetProjectStates}
              handleGetProjectCities={this.handleGetProjectCities}
              formType="SD-FISS"
            />

            <AHJDetails
              siteDetails={siteDetails}
              ahjDetails={siteDetails.authorityHavingJurisdictionDetails}
              countryOptions={countryOptionsAhjDetails}
              stateOptions={stateOptionsAhjDetails}
              cityOptions={cityOptionsAhjDetails}
              getErrorFor={this.props.getErrorFor}
              handleCheckboxChange={this.props.handleCheckboxChange}
              handleChange={this.props.handleChange}
              handleDropdownChange={this.props.handleDropdownChange}
              handleGetProjectStates={this.handleGetProjectStates}
              handleGetProjectCities={this.handleGetProjectCities}
              formType="SD-AHJD"
            />
          </span>
        )}
        {siteDetails.showPrelimLocation && (
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Grid.Row>
                  <Grid.Column>
                    <Form.Field>Are you ready to schedule now?</Form.Field>
                    <Form.Field>
                      <Checkbox
                        radio
                        label="Yes"
                        name="isReadyToScheduleNow"
                        value={true}
                        checked={otherDetails.isReadyToScheduleNow === true}
                        onChange={(e, { value }) => {
                          this.props.handleCheckboxChange(
                            "isReadyToScheduleNow",
                            value,
                            "SD-OTHERS"
                          );
                          this.handleFillSchedulingDetails(value);
                        }}
                      />
                      &nbsp;&nbsp;&nbsp;
                      <Checkbox
                        radio
                        label="No"
                        name="isReadyToScheduleNow"
                        value={false}
                        checked={otherDetails.isReadyToScheduleNow === false}
                        onChange={(e, { value }) => {
                          this.props.handleCheckboxChange(
                            "isReadyToScheduleNow",
                            value,
                            "SD-OTHERS"
                          );
                          this.handleFillSchedulingDetails(value);
                        }}
                      />
                      {this.getReadyToScheduleNowError() ? (
                        <React.Fragment>
                          <br />
                          <div className="ui pointing above prompt label ready-schedule">
                            {otherDetails.validationErrors.isReadyToScheduleNow}
                          </div>
                        </React.Fragment>
                      ) : null}
                    </Form.Field>
                  </Grid.Column>
                </Grid.Row>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        )}
        <Divider hidden />
        <CustomLabel message="OTHER DETAILS" />
        <Divider fitted />
        <Divider hidden />
        <Grid>
          <Grid.Row>
            <Grid.Column width="4">
              <Form.Field
                id="form-input-control-lcn"
                control={Input}
                label="Customer Reference Number"
                placeholder="Enter Customer Reference Number"
                name="customerReferenceNumber"
                onChange={(e) => {
                  this.props.handleChange(e, "FE");
                }}
                value={otherDetails.customerReferenceNumber || ""}
                // error={this.props.getErrorFor("residenceName", "SD-FISS")}
                autoComplete="off"
                disabled={
                  accountSettings.accountSettings &&
                  !accountSettings.accountSettings.isReferenceNoUsed
                }
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    siteDetails: state.fieldEvaluation.siteDetails,
    fieldEvaluation: state.fieldEvaluation,
    customerDetails: state.labelCart.customerDetails,
    accountSettings: state.accountSettings.form,
    fesProductDetails: state.fesProductDetails
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProjectStates: (countryCode, locationType) =>
      dispatch(actions.getProjectStates(countryCode, locationType)),
    getEngineerStates: (countryCode, locationType) =>
      dispatch(actions.getEngineerStates(countryCode, locationType)),
    getProjectCities: (stateCode, locationType) =>
      dispatch(actions.getProjectCities(stateCode, locationType)),
    getEngineerCities: (stateCode, locationType) =>
      dispatch(actions.getEngineerCities(stateCode, locationType)),
    fillPrelimSiteDetails: (checked, accountDetails) =>
      dispatch(actions.fillPrelimSiteDetails(checked, accountDetails)),
    fillFinalSiteDetails: (checked, accountDetails) =>
      dispatch(actions.fillFinalSiteDetails(checked, accountDetails)),
    fillSchedulingDetails: (checked, accountDetails) =>
      dispatch(actions.fillSchedulingDetails(checked, accountDetails)),
    addFESOrder: (param) => dispatch(fesAction.addOrder(param)),
    getProductDetails: (orderDetails) =>
      dispatch(feActions.getProductDetails(orderDetails)),
    updateIsSuccess: () => dispatch(actions.updateIsSuccess())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SiteDetails);
