import * as types from "./verify-email.type";
import api from "../../../services/api";

export const userResult = (obj, type) => {
    return {
      type: type,
      data: obj
    };
  };

export const verifyUser = model => {
    return async dispatch => {
      
        await api.User.verifyUser(model)
        .then(result => {
            dispatch(userResult(result, types.USER_SUCCESS));
        })
        .catch(result => {
            dispatch(
                userResult(
                result.response.data,
                types.USER_FAILURE
            )
            );
        });
    };
};

export const doValidateToken = model => {
    return async dispatch => {
        dispatch(userResult(null, types.USER_VALIDATE_TOKEN_REQUEST));
        await api.User.validateToken(model)
        .then(result => {
            dispatch(userResult(result, types.USER_VALIDATE_TOKEN_SUCCESS));
        })
        .catch(result => {
            dispatch(
                userResult(
                result.response.data,
                types.USER_VALIDATE_TOKEN_FAILURE
            )
            );
        });
    };
};

export const doResendEmailVerToken = model => {
    return async dispatch => {
        dispatch(userResult(null, types.USER_RESEND_TOKEN_REQUEST));
        await api.User.resendEmailVerificationToken(model)
        .then(result => {
            dispatch(userResult(result, types.USER_RESEND_TOKEN_SUCCESS));
        })
        .catch(result => {
            dispatch(
                userResult(
                result.response.data,
                types.USER_RESEND_TOKEN_FAILURE
            )
            );
        });
    };
};

export const getTokenDetails = model => {
    return async dispatch => {
        dispatch(userResult(null, types.USER_TOKEN_DETAILS_REQUEST));
        await api.User.getTokenDetails(model)
        .then(result => {
            dispatch(userResult(result, types.USER_TOKEN_DETAILS_SUCCESS));
        })
        .catch(result => {
            dispatch(
                userResult(
                result.response.data,
                types.USER_TOKEN_DETAILS_FAILURE
            )
            );
        });
    };
};

export const doLogout = () => {
    return async dispatch => {
      dispatch(userResult(null, types.LOGOUT_SUCCESS));
  
      localStorage.removeItem("userId");
      localStorage.removeItem("roles");
      localStorage.removeItem("user");
      localStorage.removeItem("userToken");
      localStorage.removeItem("isFirstTimeLogin");
      localStorage.removeItem("token");
      localStorage.removeItem("firstName");
      localStorage.removeItem("lastName");
      localStorage.removeItem("isVerified");
    };
  };

export const updateForm = result => {
    return dispatch => dispatch(userResult(result, types.FORM));
};

export const clearUser = () =>
  dispatch => dispatch(userResult({}, types.CLEAR))
