import {
  NOTIFICATIONS_FAILURE,
  NOTIFICATIONS_REQUEST,
  NOTIFICATIONS_SUCCESS,
  UPDATE_NOTIFICATIONS_FAILURE,
  UPDATE_NOTIFICATIONS_REQUEST,
  UPDATE_NOTIFICATIONS_SUCCESS,
  CART_ITEMS_REQUEST,
  CART_ITEMS_SUCCESS,
  CART_ITEMS_FAILURE,
  ORDER_CHANGE_PAGE,
  UPDATE_NOTIF_DATA
} from "./dashboard.type";
import api from "../../services/api";

const notificationsRequest = () => {
  return { type: NOTIFICATIONS_REQUEST };
};

const notificationsFailure = error => {
  return dispatch => dispatch({ type: NOTIFICATIONS_FAILURE, payload: error });
};

const notificationsSuccess = response => {
  return dispatch =>
    dispatch({ type: NOTIFICATIONS_SUCCESS, payload: response });
};

const cartItemsRequest = () => {
  return { type: CART_ITEMS_REQUEST };
};

const cartItemsFailure = error => {
  return dispatch => dispatch({ type: CART_ITEMS_FAILURE, payload: error });
};

const cartItemsSuccess = response => {
  return dispatch => dispatch({ type: CART_ITEMS_SUCCESS, payload: response });
};

export const getNotifications = (currentPage, pageSize) => {
  return async dispatch => {
    dispatch(notificationsRequest());
    dispatch({ type: ORDER_CHANGE_PAGE, payload: { currentPage } });
    const result = await api.Dashboard.getNotifications(currentPage, pageSize);

    if (result) {
      dispatch(notificationsSuccess(result));
    } else {
      dispatch(notificationsFailure("Something went wrong."));
    }
  };
};

const updateNotificationViewRequest = () => {
  return { type: UPDATE_NOTIFICATIONS_REQUEST };
};

const updateNotificationViewFailure = error => {
  return dispatch =>
    dispatch({ type: UPDATE_NOTIFICATIONS_FAILURE, payload: error });
};

const updateNotificationViewSuccess = response => {
  return dispatch =>
    dispatch({ type: UPDATE_NOTIFICATIONS_SUCCESS, payload: response });
};

export const updateNotificationView = userId => {
  return async dispatch => {
    dispatch(updateNotificationViewRequest());
    const result = await api.Dashboard.updateNotificationView(userId);

    if (result) {
      dispatch(updateNotificationViewSuccess(result));
    } else {
      dispatch(updateNotificationViewFailure("Something went wrong."));
    }
  };
};

export const getCartItems = () => {
  return async dispatch => {
    dispatch(cartItemsRequest());
    const result = await api.Manufacturer.getCartItems();

    if (result) {
      dispatch(cartItemsSuccess(result));
    } else {
      dispatch(cartItemsFailure("Something went wrong."));
    }
  };
};

export const updateData = (result) => {
  return (dispatch) =>
    dispatch({ type: UPDATE_NOTIF_DATA, payload: result });
};
