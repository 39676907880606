import PropTypes from "prop-types";
import { Table } from "semantic-ui-react";
import React, { Component, Fragment } from "react";

import PriceItem from "./price-item";

class Price extends Component {
  render() {
    const { forms } = this.props;
    return (
      <Table attached>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell textAlign="center">PRICE</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <Table basic celled structured>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Visit</Table.HeaderCell>
                    <Table.HeaderCell>Site Summary Details</Table.HeaderCell>
                    <Table.HeaderCell>Product Category</Table.HeaderCell>
                    <Table.HeaderCell>Equipment Type</Table.HeaderCell>
                    <Table.HeaderCell>No. of Units</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {forms && forms.length > 0
                    ? forms.map((form, idx) => {
                        return (
                          <Fragment key={idx}>
                            <PriceItem form={form} index={idx} />
                          </Fragment>
                        );
                      })
                    : null}
                </Table.Body>
                <Table.Footer>
                  <Table.Row>
                    <Table.HeaderCell></Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                    <Table.HeaderCell>
                      <b>Net Total Price</b>
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      <b>{this.props.netTotal}</b>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Footer>
              </Table>

              <p>
                The price is based on the information provided in your request.
                Please note that additional expenses such as travel costs,
                shipping, disposition of samples, local taxes, and others may be
                incurred. If these are required for your project, they will be
                billed to you. Invoicing for this project will occur at the
                times stated in this Quotation or at completion of the project.
              </p>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  }
}

Price.propTypes = {
  forms: PropTypes.array.isRequired
};

export default Price;
