import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Modal, Form, Icon } from "semantic-ui-react";
import _ from "lodash";
import * as actions from "./change-password.action";
import {
  validate,
  getError,
  validateField,
  createValidation
} from "../../../validator/validator";
import {
  required,
  minLength,
  mustMatch,
  maxLength,
  password
} from "../../../validator/error-message";

const rules = [
  validate(
    "currentPassword",
    "Current Password",
    required,
    minLength(8),
    maxLength(30),
    password
  ),
  validate(
    "password",
    "New Password",
    required,
    minLength(8),
    maxLength(30),
    password
  ),
  validate(
    "confirmPassword",
    "Confirm Password",
    required,
    minLength(8),
    maxLength(30),
    mustMatch("password", "New Password"),
    password
  )
];

class ChangePasswordModal extends Component {
  state = { currentPassword: true, password: true, confirmPassword: true };

  componentWillUnmount() {
    this.props.clear();
  }

  handleChangePassword = () => {
    const { form } = this.props.changePassword;
    form.validationErrors = createValidation(form, rules);

    if (Object.IsEmpty(form.validationErrors)) {
      const model = {
        currentPassword: form.currentPassword,
        newPassword: form.password,
        confirmPassword: form.confirmPassword
      };
      return this.props.doChangePassword(
        _.omit(model, ["showErrors", "validationErrors"])
      );
      // , this.handleSucess
      // TODO: at api success
      // if (typeof this.props.onSubmit === "function")
      //   this.props.onSubmit.call(this, model);
    }
    // TODO: clear API error and display only on the field
    form.showErrors = true;
    this.props.form(form);
    return false;
  };

  handleChange = e => {
    let { form } = this.props.changePassword;
    const newState = validateField(rules, form, e.target.name, e.target.value);
    this.props.form(newState);
  };

  getErrorFor(field) {
    const { form, data } = this.props.changePassword;
    let errors = getError(field, form, data);
    return errors;
  }

  handleClose() {
    this.props.clear();
    this.props.onClose();
  }

  // TODO
  // handleSucess = result => {
  //   if (typeof this.props.onSubmit === "function" && result === "success")
  //     this.props.onSubmit.call(this, result);
  // };
  onToggleShowPassword = e => {
    let name = e.currentTarget.parentElement.firstElementChild.name;
    let icon = `${name}Icon`;

    if (this.state[name] === false) {
      this.setState({ [name]: true, [icon]: "eye slash" });
    } else {
      this.setState({ [name]: false, [icon]: "eye" });
    }
  };

  render() {
    const { form } = this.props.changePassword;
    const { open } = this.props;
    const { currentPassword, password, confirmPassword } = this.state;

    return (
      <Modal open={open} onClose={this.handleClose.bind(this)} size={"tiny"}>
        <Modal.Header>Change Password</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <Form.Input
                icon={
                  <Icon
                    name={
                      this.state.currentPasswordIcon
                        ? this.state.currentPasswordIcon
                        : "eye slash"
                    }
                    link
                    onClick={this.onToggleShowPassword.bind(this)}
                  />
                }
                value={form.currentPassword || ""}
                label="Current Password"
                placeholder="Current Password"
                name="currentPassword"
                autoComplete="off"
                onChange={this.handleChange}
                type={currentPassword ? "password" : "text"}
                error={this.getErrorFor("currentPassword")}
              />
            </Form.Field>
            <Form.Field>
              <Form.Input
                icon={
                  <Icon
                    name={
                      this.state.passwordIcon
                        ? this.state.passwordIcon
                        : "eye slash"
                    }
                    link
                    onClick={this.onToggleShowPassword.bind(this)}
                  />
                }
                value={form.password || ""}
                label="New Password"
                placeholder="New Password"
                name="password"
                autoComplete="off"
                onChange={this.handleChange}
                type={password ? "password" : "text"}
                error={this.getErrorFor("password")}
              />
            </Form.Field>
            <Form.Field>
              <Form.Input
                icon={
                  <Icon
                    name={
                      this.state.confirmPasswordIcon
                        ? this.state.confirmPasswordIcon
                        : "eye slash"
                    }
                    link
                    onClick={this.onToggleShowPassword.bind(this)}
                  />
                }
                value={form.confirmPassword || ""}
                label="Confirm Password"
                placeholder="Confirm Password"
                name="confirmPassword"
                autoComplete="off"
                onChange={this.handleChange}
                type={confirmPassword ? "password" : "text"}
                error={this.getErrorFor("confirmPassword")}
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button type="button" onClick={this.handleClose.bind(this)}>
            Cancel
          </Button>
          <Button
            type="button"
            className="primary"
            onClick={this.handleChangePassword.bind(this)}
          >
            Confirm
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  return {
    changePassword: state.user.changePassword
  };
};

const mapDispatchToProps = dispatch => {
  return {
    doChangePassword: params => dispatch(actions.doChangePassword(params)),
    form: result => dispatch(actions.updateForm(result)),
    clear: () => dispatch(actions.clearChangePassword())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePasswordModal);
