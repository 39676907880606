import { requests } from '../request-factory';
import { mapDataToQueryString } from '../request-helpers';

const labelCenterService = {
  getLabelCenters: () => requests.get('label-center'),
  getLabelCenterCountryDetails: shipToCountry =>
    requests.get(
      `label-center/label-center-country?${mapDataToQueryString({
        shipToCountry,
      })}`
    ),
};

export default labelCenterService;
