import React, { Component } from "react";

import {
  Button,
  Grid,
  Divider,
  Icon,
  Menu,
  Sidebar,
  Accordion,
  List,
  Checkbox,
  Form,
  Radio,
  Dropdown,
  Input
} from "semantic-ui-react";

import { CustomHeaderInlineBold } from "../../../components/labels/labels";
import DatePicker from "react-datepicker";
import { Common } from "../../../common";
import Role from "../../../enums/role";

class FilterSidebar extends Component {
  state = { startDate: new Date(), endDate: new Date() };

  handleDateChange = (date, name) => {
    const { startDate, endDate } = this.state;

    switch (name) {
      case "startDate":
        this.setState({ startDate: date });
        break;
      case "endDate":
        this.setState({ endDate: date });
        break;
    }
  };

  render() {
    const {
      isFilterVisible,
      activeIndex,
      statusOptions,
      dateRangeOptions,
      filterDate,
      filterDateType,
      projectHandlers,
      applicants,
      states,
      cities,
      countryOptions,
      stateOptions,
      cityOptions,
      form,
      selectedProjectHandlers,
      selectedApplicants,
      selectedStates,
      selectedCities,
      isExpandMultiSite,
      isUnassignQuoteOnly
    } = this.props;

    var roleId = Common.getUserRole();

    const { startDate, endDate } = this.state;

    // const [startDate, setStartDate] = useState(new Date());
    // const [endDate, setEndDate] = useState(new Date());

    return (
      <Sidebar
        as={Menu}
        animation="overlay"
        vertical
        direction="left"
        visible={isFilterVisible}
        width="wide"
      >
        <div style={{ padding: "20px" }}>
          <Grid columns={2}>
            <Grid.Column verticalAlign="middle">
              <CustomHeaderInlineBold message="Filters" />
            </Grid.Column>
            <Grid.Column textAlign="right">
              <Button
                basic
                icon="delete"
                onClick={this.props.handleToggleFilter}
              ></Button>
            </Grid.Column>
          </Grid>
          <Divider />
          <Accordion fluid>
            <Accordion.Title
              active={activeIndex === 0}
              index={0}
              onClick={this.props.handleClick}
            >
              <Icon name="dropdown" />
              Quote Status
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 0}>
              <List>
                {statusOptions &&
                  statusOptions.map((status, idx) => {
                    return (
                      <List.Item key={idx}>
                        <Grid>
                          <Grid.Row>
                            <Checkbox
                              label={status.text}
                              style={{ marginLeft: "50px" }}
                              checked={status.checked}
                              onClick={(e, properties) => {
                                this.props.handleStatusFilterChange(properties);
                              }}
                            />
                          </Grid.Row>
                        </Grid>
                      </List.Item>
                    );
                  })}
              </List>
            </Accordion.Content>

            <Accordion.Title
              active={activeIndex === 1}
              index={1}
              onClick={this.props.handleClick}
            >
              <Icon name="dropdown" />
              Creation/Expiration Date
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 1}>
              <p>
                <List>
                  {dateRangeOptions &&
                    dateRangeOptions.map((date, idx) => {
                      return (
                        <List.Item key={idx}>
                          <Grid>
                            <Grid.Row>
                              <Radio
                                style={{
                                  marginLeft: "50px",
                                  marginTop: "10px"
                                }}
                                label={date.text}
                                name="radioGroupDate"
                                value={date.value}
                                checked={date.checked}
                                onClick={(e, properties) => {
                                  this.props.handleDateFilterChange(properties);
                                }}
                              />
                            </Grid.Row>
                          </Grid>
                        </List.Item>
                      );
                    })}
                </List>
                {/* <Form>
                  {dateRangeOptions.filter((i) => i.value === "0")[0].checked ==
                    true && (
                    <Form style={{ marginLeft: "50px" }}>
                      <Form.Field>
                        <DatePicker
                          selected={startDate}
                          onChange={(date) =>
                            this.handleDateChange(date, "startDate")
                          }
                          selectsStart
                          startDate={startDate}
                          endDate={endDate}
                          placeholderText="Start Date"
                        />
                      </Form.Field>
                      <Form.Field>
                        <DatePicker
                          selected={endDate}
                          onChange={(date) =>
                            this.handleDateChange(date, "endDate")
                          }
                          selectsEnd
                          startDate={startDate}
                          endDate={endDate}
                          placeholderText="End Date"
                        />
                      </Form.Field>
                    </Form>
                  )}
                  <Divider />
                  <Form.Field>
                    <Radio
                      style={{ marginLeft: "40px" }}
                      label="Creation Date"
                      name="radioGroupDateFilterType"
                      value="1"
                      checked={filterDateType === "1"}
                      onClick={(e, properties) => {
                        this.props.handleDateFilterChange(properties);
                      }}
                    />
                  </Form.Field>
                  <Form.Field>
                    <Radio
                      style={{ marginLeft: "40px" }}
                      label="Expiration Date"
                      name="radioGroupDateFilterType"
                      value="2"
                      checked={filterDateType === "2"}
                      onClick={(e, properties) => {
                        this.props.handleDateFilterChange(properties);
                      }}
                    />
                  </Form.Field>
                </Form> */}
              </p>
            </Accordion.Content>

            {roleId && roleId == Role.projectHandler && (
              <div>
                <Accordion.Title
                  active={activeIndex === 2}
                  index={2}
                  onClick={this.props.handleClick}
                >
                  <Icon name="dropdown" />
                  Program Type
                </Accordion.Title>
                <Accordion.Content
                  active={activeIndex === 2}
                ></Accordion.Content>

                <Accordion.Title
                  active={activeIndex === 3}
                  index={3}
                  onClick={this.props.handleClick}
                >
                  <Icon name="dropdown" />
                  Project Handler
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 3}>
                  <List>
                    {selectedProjectHandlers &&
                      projectHandlers.map((column, idx) => {
                        return (
                          column &&
                          selectedProjectHandlers.includes(column.value) && (
                            <List.Item key={idx}>
                              <Grid>
                                <Grid.Row centered>
                                  <Input
                                    value={column.text}
                                    disabled
                                    style={{ marginRight: "10px" }}
                                  />

                                  <Button
                                    color="red"
                                    basic
                                    icon="delete"
                                    // disabled={
                                    //   selectedProjectHandlers.filter(
                                    //     (i) => i.visible === 1
                                    //   ).length > 1
                                    //     ? false
                                    //     : true
                                    // }
                                    onClick={() => {
                                      this.props.handleRemovePHFilter(
                                        column.key
                                      );
                                    }}
                                  />
                                </Grid.Row>
                              </Grid>
                            </List.Item>
                          )
                        );
                      })}
                    {selectedProjectHandlers && (
                      <List.Item>
                        <Grid>
                          <Grid.Row centered>
                            <Form.Field
                              control={Dropdown}
                              selection
                              placeholder="Select project handler"
                              style={{
                                marginLeft: "10px"
                              }}
                              options={projectHandlers}
                              onChange={(e, properties) => {
                                this.props.handleDropdownChange(
                                  "projectHandler",
                                  properties.value
                                );
                              }}
                              value={form.projectHandler || ""}
                            />

                            <Button
                              color="green"
                              basic
                              icon="add"
                              style={{ marginLeft: "20px" }}
                              onClick={() => {
                                this.props.handleAddPHFilter(
                                  form.projectHandler
                                );
                              }}
                            />
                          </Grid.Row>
                        </Grid>
                      </List.Item>
                    )}
                  </List>
                </Accordion.Content>

                <Accordion.Title
                  active={activeIndex === 4}
                  index={4}
                  onClick={this.props.handleClick}
                >
                  <Icon name="dropdown" />
                  Applicant
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 4}>
                  <List>
                    {selectedApplicants &&
                      applicants.map((column, idx) => {
                        return (
                          column &&
                          selectedApplicants.includes(column.value) && (
                            <List.Item key={idx}>
                              <Grid>
                                <Grid.Row centered>
                                  <Input
                                    value={column.text}
                                    disabled
                                    style={{ marginRight: "10px" }}
                                  />

                                  <Button
                                    color="red"
                                    basic
                                    icon="delete"
                                    // disabled={
                                    //   selectedProjectHandlers.filter(
                                    //     (i) => i.visible === 1
                                    //   ).length > 1
                                    //     ? false
                                    //     : true
                                    // }
                                    onClick={() => {
                                      this.props.handleRemoveApplicantFilter(
                                        column.key
                                      );
                                    }}
                                  />
                                </Grid.Row>
                              </Grid>
                            </List.Item>
                          )
                        );
                      })}
                    {selectedApplicants && (
                      <List.Item>
                        <Grid>
                          <Grid.Row centered>
                            <Form.Field
                              control={Dropdown}
                              selection
                              placeholder="Select applicants"
                              style={{
                                marginLeft: "10px"
                              }}
                              search
                              options={applicants}
                              onChange={(e, properties) => {
                                this.props.handleDropdownChange(
                                  "applicant",
                                  properties.value
                                );
                              }}
                              value={form.applicant || ""}
                            />

                            <Button
                              color="green"
                              basic
                              icon="add"
                              style={{ marginLeft: "20px" }}
                              onClick={() => {
                                this.props.handleAddApplicantFilter(
                                  form.applicant
                                );
                              }}
                            />
                          </Grid.Row>
                        </Grid>
                      </List.Item>
                    )}
                  </List>
                </Accordion.Content>

                <Accordion.Title
                  active={activeIndex === 5}
                  index={5}
                  onClick={this.props.handleClick}
                >
                  <Icon name="dropdown" />
                  Final Site
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 5}>
                  <List>
                    {selectedStates &&
                      states.map((column, idx) => {
                        return (
                          column &&
                          selectedStates.includes(column.value) && (
                            <List.Item key={idx}>
                              <Grid>
                                <Grid.Row centered>
                                  <Input
                                    value={column.text}
                                    disabled
                                    style={{ marginRight: "10px" }}
                                  />

                                  <Button
                                    color="red"
                                    basic
                                    icon="delete"
                                    onClick={() => {
                                      this.props.handleRemoveStatesFilter(
                                        column.key
                                      );
                                    }}
                                  />
                                </Grid.Row>
                              </Grid>
                            </List.Item>
                          )
                        );
                      })}
                    {selectedStates && (
                      <List.Item>
                        <Grid>
                          <Grid.Row>
                            <Form.Field
                              control={Dropdown}
                              selection
                              placeholder="Select country"
                              search
                              style={{
                                marginLeft: "40px"
                              }}
                              options={countryOptions}
                              onChange={(e, properties) => {
                                this.props.handleDropdownChange(
                                  "country",
                                  properties.value
                                );
                                this.props.handleGetProjectStates(
                                  properties.value,
                                  1
                                );
                              }}
                              value={form.country || ""}
                            />

                            <Form.Field
                              control={Dropdown}
                              selection
                              placeholder="Select state"
                              search
                              style={{
                                marginLeft: "40px",
                                marginTop: "10px"
                              }}
                              options={stateOptions}
                              onChange={(e, properties) => {
                                this.props.handleDropdownChange(
                                  "state",
                                  properties.value
                                );
                                this.props.handleGetProjectCities(
                                  properties.value,
                                  1
                                );
                              }}
                              value={form.state || ""}
                            />

                            <Form.Field
                              control={Dropdown}
                              selection
                              placeholder="Select city"
                              search
                              style={{
                                marginLeft: "40px",
                                marginTop: "10px"
                              }}
                              options={cityOptions}
                              onChange={(e, properties) => {
                                this.props.handleDropdownChange(
                                  "city",
                                  properties.value
                                );
                              }}
                              value={form.city || ""}
                            />

                            {/* <Button
                              color="green"
                              basic
                              icon="add"
                              style={{ marginLeft: "20px" }}
                              onClick={() => {
                                this.props.handleAddStateFilter(form.state);
                              }}
                            /> */}
                          </Grid.Row>
                        </Grid>
                      </List.Item>
                    )}
                  </List>
                </Accordion.Content>

                <Accordion.Title
                  active={activeIndex === 7}
                  index={7}
                  onClick={this.props.handleClick}
                >
                  <Icon name="dropdown" />
                  Show Unassign Quote Only
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 7}>
                  <Form.Field
                    control={Radio}
                    toggle
                    name="sort"
                    style={{
                      marginLeft: "40px"
                    }}
                    onChange={(e, properties) => {
                      this.props.handleShowUnassignQuoteOnly(properties);
                    }}
                    checked={isUnassignQuoteOnly}
                  />
                </Accordion.Content>

                {/* <Accordion.Title
                  active={activeIndex === 6}
                  index={6}
                  onClick={this.props.handleClick}
                >
                  <Icon name="dropdown" />
                  Cities
                </Accordion.Title>
                <Accordion.Content active={activeIndex === 6}>
                  <List>
                    {selectedCities &&
                      cities.map((column, idx) => {
                        return (
                          column &&
                          selectedCities.includes(column.value) && (
                            <List.Item key={idx}>
                              <Grid>
                                <Grid.Row centered>
                                  <Input
                                    value={column.text}
                                    disabled
                                    style={{ marginRight: "10px" }}
                                  />

                                  <Button
                                    color="red"
                                    basic
                                    icon="delete"
                                    onClick={() => {
                                      this.props.handleRemoveCitiesFilter(
                                        column.key
                                      );
                                    }}
                                  />
                                </Grid.Row>
                              </Grid>
                            </List.Item>
                          )
                        );
                      })}
                    {selectedCities && (
                      <List.Item>
                        <Grid>
                          <Grid.Row centered>
                            <Form.Field
                              control={Dropdown}
                              selection
                              placeholder="Select cities"
                              search
                              style={{
                                marginLeft: "10px",
                              }}
                              options={cities}
                              onChange={(e, properties) => {
                                this.props.handleDropdownChange(
                                  "city",
                                  properties.value
                                );
                              }}
                              value={form.city || ""}
                            />

                            <Button
                              color="green"
                              basic
                              icon="add"
                              style={{ marginLeft: "20px" }}
                              onClick={() => {
                                this.props.handleAddCitiesFilter(form.city);
                              }}
                            />
                          </Grid.Row>
                        </Grid>
                      </List.Item>
                    )}
                  </List>
                </Accordion.Content> */}
              </div>
            )}

            <Accordion.Title
              active={activeIndex === 6}
              index={6}
              onClick={this.props.handleClick}
            >
              <Icon name="dropdown" />
              Expand Mutiple Sites Quote
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 6}>
              <Form.Field
                control={Radio}
                toggle
                name="sort"
                style={{
                  marginLeft: "40px"
                }}
                onChange={(e, properties) => {
                  this.props.handleExpandMultiSite(properties);
                }}
                checked={isExpandMultiSite}
              />
            </Accordion.Content>
          </Accordion>
          <Divider />
          <Grid>
            <Grid.Column textAlign="right">
              <Button
                basic
                color="blue"
                content="Clear"
                onClick={this.props.handleClearFilter}
              ></Button>
              <Button
                basic
                color="green"
                content="Apply"
                onClick={this.props.handleApplyFilter}
              ></Button>
            </Grid.Column>
          </Grid>
        </div>
      </Sidebar>
    );
  }
}

export default FilterSidebar;
