import { requests } from '../request-factory';
import { mapDataToQueryString } from '../request-helpers';

const orderService = {
  getOrdersByUser: (currentPage, pageSize, startDate, endDate) => {
    return requests.get(
      `order?${mapDataToQueryString({
        currentPage,
        pageSize,
        startDate,
        endDate,
      })}`
    );
  },
  exportOrderListUser: data => requests.post('order/export-orders-user', data),
  exportOrderListCenter: data =>
    requests.post('order/export-orders-center', data),
  getOrders: (currentPage, pageSize) => {
    return requests.get(
      `order/all?${mapDataToQueryString({
        currentPage,
        pageSize,
      })}`
    );
  },
  getOrdersByUserFilter: data =>
    requests.post('order/byuserfilter', { filters: JSON.stringify(data) }),

  createOrder: data => requests.post('order/create', data),
  getActiveFiles: userId => requests.get(`order/files/${userId}`),
  getExistingLabelOrders: (
    fileNumber,
    itemNumber,
    applicantAccount,
    manufacturerAccount,
    currentPage,
    pageSize
  ) =>
    requests.get(
      `order/existing-combination-labels?${mapDataToQueryString({
        fileNumber,
        itemNumber,
        applicantAccount,
        manufacturerAccount,
        currentPage,
        pageSize,
      })}`
    ),
  getExistingLabelOrdersByCartId: labelOrderCartId =>
    requests.get(
      `order/existing-combination-labels/by-cart-id?${mapDataToQueryString({
        labelOrderCartId,
      })}`
    ),
  cancelOrder: cartData => requests.post('order/cancel', cartData),
  getInProgressOrders: (currentPage, pageSize, requestNumber) => {
    return requests.get(
      `order/getinprogress?${mapDataToQueryString({
        currentPage,
        pageSize,
        requestNumber,
      })}`
    );
  },
  cancelInProgressOrder: data =>
    requests.post('order/cancel-inprogress-order', data),
  searchOrder: (
    pSN,
    accountNumber,
    orderConfirmationNumber,
    oracleOrderNumber,
    sortColumn,
    sortOrder,
    currentPage,
    pageSize,
    searchTerm,
    searchType
  ) =>
    requests.get(
      `order/search?${mapDataToQueryString({
        pSN,
        accountNumber,
        orderConfirmationNumber,
        oracleOrderNumber,
        sortColumn,
        sortOrder,
        currentPage,
        pageSize,
        searchTerm,
        searchType,
      })}`
    ),
  getOrderByRequestNumber: requestNumber =>
    requests.get(
      `order/request-number?${mapDataToQueryString({ requestNumber })}`
    ),
  getShippingOptions: countryCode =>
    requests.get(`order/shipping-options?CountryCode=${countryCode}`),
  emailCopy: data => requests.post('order/email-copy', data),
  getListFileNumbers: () => requests.get(`order/list/filenumbers`),
  getListApplicants: userId => requests.get(`order/list/applicants`),
  getListManufacturers: userId => requests.get(`order/list/manufacturers`),
  getListOracleNumbers: userId => requests.get(`order/list/oraclenumbers`),
  getListRequestNumbers: userId => requests.get(`order/list/requestnumbers`),
  getListLabelCenters: () => requests.get(`order/list/labelcenters`),
  getListApplicantAccountNos: userId =>
    requests.get(`order/list/companyaccountnumbers`),
  getListManufacturerAccountNos: userId =>
    requests.get(`order/list/manufactureraccountnumbers`),
  setUserFilter: data => requests.post('order/setfilteroption', data),
  getUserFilter: () => requests.get(`order/getfilteroption`),
  deleteLabelOrderAttachment: (
    orderAttachmentId,
    labelOrderType,
    isCombinationArtwork
  ) =>
    requests.post('order/delete-attachment', {
      orderAttachmentId: orderAttachmentId,
      labelOrderType: labelOrderType,
      isCombinationArtwork: isCombinationArtwork,
    }),
  getLabelOrderAttachment: (labelOrderId, labelOrderType) =>
    requests.get(
      `order/get-attachments?${mapDataToQueryString({
        labelOrderId,
        labelOrderType,
      })}`
    ),
  sendAttachmentMessage: data =>
    requests.post('order/send-attachment-message', data),
  getOrderItem: (orderId, fileNumber) =>
    requests.get(
      `order/get-order-item?${mapDataToQueryString({ orderId, fileNumber })}`
    ),
  cancelOrderItem: data => requests.post('order/cancel-order-item', data),
  getExchangeRate: countryCode =>
    requests.get(
      `order/exchange-rate?${mapDataToQueryString({ countryCode })}`
    ),
  getListCustomerPoNumber: userId =>
    requests.get(`order/list/customerponumbers`),
  getListCustomerContactName: userId =>
    requests.get(`order/list/customercontactnames`),
  getListApplicantAddress: () => requests.get(`order/list/applicantaddress`),
  getListManufacturerAddress: () =>
    requests.get(`order/list/manufactureraddress`),
  getListManufacturerPsn: () => requests.get(`order/list/manufacturerpsn`),
  getCustomerEcmApiStatus: () => requests.get(`order/customer-ecm-status`),
  updateOracleNumber: data => requests.post('order/update-oracle-number', data),
  getListeeFileNumbers: fileNumber =>
    requests.get(
      `order/listee-file-number?${mapDataToQueryString({
        fileNumber,
      })}`
    ),
  uploadLabelOrderAttachment: data =>
    requests.post('order/upload-attachment', data),
  uploadLabelOrderTempAttachment: data =>
    requests.post('label-order-cart/upload-attachment-temp', data),
  updateBubbleStatus: data => requests.post('order/update-bubble-status', data),
  createReportRequest: data =>
    requests.post('order/create-report-request', {
      columnPreferences: data.columnPreferences,
      filterValues: JSON.stringify(data.filterValues),
    }),
  getExportLink: () => requests.get(`order/list/getreportlink`),
  getCreditProfile: (
    applicantAccountNumbers,
    manufacturerAccountNumbers,
    billToAccountNumbers,
    orderAmounts
  ) =>
    requests.get(
      `order/credit-profile/details?${mapDataToQueryString({
        applicantAccountNumbers,
        manufacturerAccountNumbers,
        billToAccountNumbers,
        orderAmounts,
      })}`
    ),
  checkCartAndOrderDetails: (userRole, labelOrderId) =>
    requests.get(
      `order/check-status-copy?${mapDataToQueryString({
        userRole,
        labelOrderId,
      })}`
    ),
  manualRepushOrder: data =>
    requests.get(`order/repush-order?labelOrderId=${data}`),
  scanFile: data => requests.post('order/file/scan', data),
  createCurrencyHandlingFees: data =>
    requests.post('legal-entity/currency-handling-fee', data),
  getCurrencyHandlingFees: () => {
    return requests.get('legal-entity/currency-handling-fee');
  },
  getCombinationLabelsByFilter: (
    applicantAccountNumber,
    manufacturerAccountNumber,
    fileNumber,
    itemNumber,
    customerPartNo,
    currentPage,
    pageSize
  ) =>
    requests.get(
      `order/combination-label-item-search?${mapDataToQueryString({
        applicantAccountNumber,
        manufacturerAccountNumber,
        fileNumber,
        itemNumber,
        customerPartNo,
        currentPage,
        pageSize,
      })}`
    ),
};

export default orderService;
