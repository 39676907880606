import * as types from "./labels.type";

const initialState = {
  hasError: false,
  message: "",
  loading: false,
  data: null,
  selectedLabels: [],
  isSelectedAll: false,
  total: 0,
  totalHandlingFee: 0,
  grantTotal: 0,
  isSuccessAddToCart: false
};

const getSelectedLabels = (selectedLabels) => {
  var result = [];

  selectedLabels.forEach((s) => {
    s.labelDetails
      .filter((d) => d.isSelected)
      .forEach((item) => {
        result.push({
          id: item.labelOrderCartItemId,
          labelAccount: item.labelAccount,
          ccn: item.ccn,
          labelDescription: item.description,
          itemNumber: item.itemNumber,
          labelImageUrl: item.imageUrl,
          quantity: item.quantity,
          netPrice: item.price,
          fileNumber: item.fileNumber,
          unitOfMeasurementName: item.unitOfMeasurementName,
          newPrice: item.newPrice,
          oracleUnitOfMeasurement: item.oracleUnitOfMeasurementName,
          labelOrderPackage: item.labelOrderPackage,
          minOrderQuantity: item.minOrderQuantity,
          volume: item.volume
        });
      });
  });

  return result;
};

const computeTotal = (selectedLabels) => {
  var total = 0;

  selectedLabels.forEach((labelItem) => {
    total +=
      labelItem.newPrice *
      (isNaN(parseInt(labelItem.quantity)) ? 0 : labelItem.quantity);
  });

  return total;
};

const computeTotalHandlingFee = (selectedLabels) => {
  var total = 0;

  selectedLabels.forEach((labelItem) => {
    total += 35 * 1;
  });

  return total;
};

const labelsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESET_LABEL_ORDER_FORM:
      let origState = initialState;

      return {
        ...origState,
        hasError: false,
        loading: false
      };
    case types.LABELS_REQUEST:
      return {
        ...state,
        hasError: false,
        loading: true
      };
    case types.LABELS_SUCCESS:
      state.selectedLabels = getSelectedLabels(
        action.payload["fileNumberLabels"]
      );
      state.total = 0;

      state.total = computeTotal(state.selectedLabels);

      return {
        ...state,
        hasError: false,
        data: action.payload["fileNumberLabels"],
        loading: false
      };
    case types.LABELS_FAILURE:
      return {
        ...state,
        message: action.payload,
        hasError: true,
        loading: false
      };
    case types.SELECT_LABEL:
      const newState = { ...state };
      newState.total = 0;
      newState.totalHandlingFee = 0;
      var result = [];

      newState.data.forEach((item) => {
        var labels = [];
        item.labelDetails.forEach((labelItem) => {
          if (
            labelItem.labelOrderCartItemId ===
            action.payload.data["labelOrderCartItemId"]
          )
            labelItem.isSelected = action.payload.isSelected;
          labels.push(labelItem);
        });

        result.push({ fileNumber: item.fileNumber, labelDetails: labels });
      });

      newState.data = result;
      newState.selectedLabels = getSelectedLabels(newState.data);
      newState.total = computeTotal(newState.selectedLabels);
      newState.totalHandlingFee = computeTotalHandlingFee(
        newState.selectedLabels
      );

      newState.grantTotal = newState.total + newState.totalHandlingFee;

      return newState;
    case types.TOGGLE_ALL_LABELS:
      const newStateAllLabels = { ...state };
      newStateAllLabels.total = 0;
      newStateAllLabels.isSelectedAll = action.payload.isSelectedAll;
      var result = [];

      newStateAllLabels.data.forEach((item) => {
        var labels = [];
        item.labelDetails.forEach((labelItem) => {
          labelItem.isSelected = action.payload.isSelectedAll;
          labels.push(labelItem);
        });

        result.push({ fileNumber: item.fileNumber, labelDetails: labels });
      });

      newStateAllLabels.data = result;
      newStateAllLabels.selectedLabels = getSelectedLabels(
        newStateAllLabels.data
      );
      newStateAllLabels.total = computeTotal(newStateAllLabels.selectedLabels);
      newStateAllLabels.totalHandlingFee = computeTotalHandlingFee(
        newStateAllLabels.selectedLabels
      );

      newStateAllLabels.grantTotal =
        newStateAllLabels.totalHandlingFee + newStateAllLabels.total;

      return newStateAllLabels;
    case types.SET_QUANTITY:
      const newStateQty = { ...state };
      newStateQty.total = 0;
      var result = [];

      newStateQty.data.forEach((item) => {
        var labels = [];
        item.labelDetails.forEach((labelItem) => {
          if (
            labelItem.labelOrderCartItemId ===
            action.payload.data["labelOrderCartItemId"]
          )
            labelItem.quantity = action.payload.quantity;
          labels.push(labelItem);
        });

        result.push({ fileNumber: item.fileNumber, labelDetails: labels });
      });

      newStateQty.data = result;
      newStateQty.selectedLabels = getSelectedLabels(newStateQty.data);
      newStateQty.total = computeTotal(newStateQty.selectedLabels);
      newStateQty.totalHandlingFee = newStateQty.totalHandlingFee;
      newStateQty.grantTotal = newStateQty.totalHandlingFee + newStateQty.total;

      return newStateQty;
    case types.SET_UNIT_OF_MEASUREMENT:
      const newStateUom = { ...state };
      newStateUom.total = 0;
      var result = [];

      newStateUom.data.forEach((item) => {
        var labels = [];
        item.labelDetails.forEach((labelItem) => {
          if (
            labelItem.labelOrderCartItemId ===
            action.payload.data["labelOrderCartItemId"]
          ) {
            labelItem.unitOfMeasurementId = action.payload.uom;
          }

          labels.push(labelItem);
        });

        result.push({ fileNumber: item.fileNumber, labelDetails: labels });
      });

      newStateUom.data = result;
      newStateUom.selectedLabels = getSelectedLabels(newStateUom.data);
      newStateUom.total = computeTotal(newStateUom.selectedLabels);
      newStateUom.totalHandlingFee = computeTotal(newStateUom.selectedLabels);

      return newStateUom;
    case types.MANUFACTURER_STANDARD_LABEL_ITEMS_REMOVE:
      let stateCartItems = { ...state };
      var result = [];

      stateCartItems.data.forEach((item) => {
        var index = action.payload.cartItemIds.indexOf(
          item.labelOrderCartItemId
        );
        if (index === -1) result.push(item);
      });

      stateCartItems.data = result;

      return stateCartItems;
    case types.ADD_TO_CART_RESET:
      return {
        ...state,
        hasError: false,
        loading: false,
        isSuccessAddToCart: false
      };
    case types.ADD_TO_CART_REQUEST:
      return {
        ...state,
        isSuccessAddToCart: false,
        loading: true
      };
    case types.ADD_TO_CART_SUCCESS:
      return {
        ...state,
        hasError: false,
        isSuccessAddToCart: action.payload.isSuccessful,
        loading: false
      };
    case types.ADD_TO_CART_FAILURE:
      return {
        ...state,
        message: action.payload,
        isSuccessAddToCart: false,
        loading: false
      };
    default:
      return state;
  }
};

export default labelsReducer;
