import React, { Component } from "react";
import {
  Form,
  Grid,
  Divider,
  Popup,
  Icon,
  Button,
  Table
} from "semantic-ui-react";
import Role from "../../../../enums/role";
import DatePicker from "react-datepicker";
import { setHours, setMinutes, getDay } from "date-fns";
import { Common } from "../../../../common";
import PrelimSiteDetails from "../common/site-location/prelim-site-details";
import FinalSiteDetails from "../common/site-location/final-site-details";
import AuthorityJurisdictionDetails from "../common/authority-jurisdiction/authority-jurisdiction-details";
import SiteSpecificRequirements from "../common/site-specific-requirements/site-specific-requirements";
import { connect } from "react-redux";
import * as actions from "./scheduling-details.action";
import { GetFormDataRD } from "../../field-evaluation-helper";
import { getError, validateField } from "../../../../validator/validator";

class SchedulingDetails extends Component {
  state = { hasRenderedSites: false, hasRenderedAhjs: false };

  handleGetEngineerStates = (countryCode, locationType) => {
    this.props.getEngineerStates(countryCode, locationType);
  };

  handleGetEngineerCities = (stateCode, locationType) => {
    this.props.getEngineerCities(stateCode, locationType);
  };

  handleGetAhjStates = (countryCode) => {
    this.props.getAhjStates(countryCode);
  };

  handleGetAhjCities = (stateCode) => {
    this.props.getAhjCities(stateCode);
  };

  updateFormObject(obj) {
    const {
      tabIndex,
      updateForm,
      schedulingDetails: { schedulingForms }
    } = this.props;
    const form = schedulingForms[tabIndex];

    const newForm = Object.assign({}, form, obj);

    updateForm(tabIndex, newForm, schedulingForms);
  }

  updateForm(name, value, formType) {
    const {
      tabIndex,
      updateForm,
      schedulingDetails: { schedulingForms }
    } = this.props;
    const form = schedulingForms[tabIndex];

    var formSection = GetFormDataRD(form, formType);

    validateField(formSection.rules, formSection.formData, name, value);

    let newForm = Object.assign({}, formSection.formData, {
      [name]: value
    });

    updateForm(tabIndex, newForm, schedulingForms, formType);
  }

  handleChange = (e, formType) => {
    this.updateForm(e.target.name, e.target.value, formType);
  };

  handleDropdownChange = (dpName, dpValue, formType) => {
    this.updateForm(dpName, dpValue, formType);
  };

  handleCheckboxChange = (cbName, cbValue, formType) => {
    this.updateForm(cbName, cbValue, formType);
  };

  getErrorFor = (field, formType) => {
    const {
      tabIndex,
      schedulingDetails: { schedulingForms }
    } = this.props;

    const form = schedulingForms[tabIndex];

    let results = GetFormDataRD(form, formType);

    let errors = getError(field, results.formData, form.data);
    return errors;
  };

  renderExistingStatesCities = () => {
    const {
      tabIndex,
      schedulingDetails: { schedulingForms }
    } = this.props;

    var scheduling = schedulingForms[tabIndex];

    const { hasRenderedSites, hasRenderedAhjs } = this.state;

    if (scheduling) {
      var final = scheduling.finalSiteLocationSection;
      var ahj = scheduling.authorityHavingJurisdictionDetails;

      if (final.fieldEvaluationOrderId && !hasRenderedSites) {
        this.handleGetEngineerStates(final.country, 2);
        this.handleGetEngineerCities(final.state, 2);

        this.setState({ hasRenderedSites: true });
      }

      if (ahj.fieldEvaluationOrderId && !hasRenderedAhjs) {
        this.handleGetAhjStates(ahj.country);
        this.handleGetAhjCities(ahj.state);

        this.setState({ hasRenderedAhjs: true });
      }
    }
  };

  componentDidMount() {
    let { reservationDetails, siteDetails } = this.props.form;

    //this.updateFormObject(reservationDetails);
  }

  render() {
    const {
      tabIndex,
      updateForm,
      schedulingDetails: {
        schedulingForms,
        countryOptionsPrelimEngineerDetails,
        countryOptionsFinalEngineerDetails,
        stateOptionsPrelimEngineerDetails,
        stateOptionsFinalEngineerDetails,
        cityOptionsPrelimEngineerDetails,
        cityOptionsFinalEngineerDetails,
        countryOptionsAhjDetails,
        stateOptionsAhjDetails,
        cityOptionsAhjDetails
      },
      fieldService: { arrForm }
    } = this.props;

    var reservationDetails = schedulingForms[tabIndex];
    var mainForm = arrForm[tabIndex];

    this.renderExistingStatesCities();

    const handleColor = (time) => {
      let roleId = Common.getUserRole();
      let newTime = time;
      //TODO
      //   if (preliminarySiteDetails.scheduleDate)
      //     newTime = preliminarySiteDetails.scheduleDate;

      // Business hours: 6:00 AM - 5:30 PM
      const res = roleId == Role.fieldEngineer || roleId == Role.projectHandler;
      const isPrevTime = res
        ? time.getHours() < 6 || time.getHours() > 17
          ? "custom-previous-time"
          : ""
        : "";

      return isPrevTime;
    };

    let roleId = Common.getUserRole();

    return (
      <Form>
        <Grid>
          <Grid.Row columns={3}>
            <Grid.Column>
              <Form.Field>Preferred Start Date & Time of Inspection</Form.Field>
              <DatePicker
                onChange={(date) => {
                  this.handleDropdownChange("scheduleDate", date, "RD-PSLS");
                }}
                selected={
                  reservationDetails.preliminarySiteLocationSection
                    .scheduleDate || ""
                }
                error={this.props.getErrorFor(
                  "scheduleUnixTime",
                  "RD-PSLS",
                  tabIndex
                )}
                name="scheduleDate"
                minDate={new Date()}
                showDisabledMonthNavigation
                showTimeSelect
                timeFormat="hh:mm aa"
                timeIntervals={30}
                timeCaption="Time"
                dateFormat="MM/dd/yyyy h:mm aa"
                minTime={
                  roleId == Role.fieldEngineer || roleId == Role.projectHandler
                    ? setHours(setMinutes(new Date(), 0), 0)
                    : setHours(setMinutes(new Date(), 0), 6)
                }
                maxTime={setHours(setMinutes(new Date(), 30), 23)}
                timeClassName={handleColor.bind(this)}
                autoComplete="off"
                disabled={mainForm.isSchedulingDisabled}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider hidden />
        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column width="5">
              <Form.Field>
                Reserve An Engineer{" "}
                <Popup
                  trigger={<Icon name="info circle" />}
                  content="This function will be available soon."
                  size="mini"
                />
              </Form.Field>
              <Button
                primary
                disabled
                //TODO
                // onClick={this.handleOpenAppointments.bind(this, true)}
              >
                Select An Engineer
              </Button>
            </Grid.Column>
            <Grid.Column verticalAlign="bottom" textAlign="left" width="7">
              {/* TODO */}
              {/* {selectedEngineer && (
                <Table basic="very">
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>{selectedEngineer.engineerName}</Table.Cell>
                      <Table.Cell>
                        {selectedEngineer.appointmentStart}
                      </Table.Cell>
                      <Table.Cell>{selectedEngineer.location}</Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              )} */}
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {reservationDetails?.others?.showPrelimLocation == "false" && (
          <PrelimSiteDetails
            // siteDetails={siteDetailForm}
            prelimDetails={reservationDetails.preliminarySiteLocationSection}
            formType="RD-PSLS"
            handleCheckboxChange={this.handleCheckboxChange}
            handleChange={this.handleChange}
            handleDropdownChange={this.handleDropdownChange}
            getErrorFor={this.getErrorFor}
            countryOptions={countryOptionsFinalEngineerDetails}
            stateOptions={stateOptionsFinalEngineerDetails}
            cityOptions={cityOptionsFinalEngineerDetails}
            handleGetProjectStates={this.handleGetEngineerStates}
            handleGetProjectCities={this.handleGetEngineerCities}
          />
        )}

        <FinalSiteDetails
          finalDetails={reservationDetails.finalSiteLocationSection}
          countryOptions={countryOptionsFinalEngineerDetails}
          stateOptions={stateOptionsFinalEngineerDetails}
          cityOptions={cityOptionsFinalEngineerDetails}
          getErrorFor={this.getErrorFor}
          tabIndex={this.props.tabIndex}
          formType="RD-FSLS"
          handleGetProjectStates={this.handleGetEngineerStates}
          handleGetProjectCities={this.handleGetEngineerCities}
          handleChange={this.handleChange}
          handleDropdownChange={this.handleDropdownChange}
          handleCheckboxChange={this.handleCheckboxChange}
        />
        <AuthorityJurisdictionDetails
          ahjDetails={reservationDetails.authorityHavingJurisdictionDetails}
          countryOptions={countryOptionsAhjDetails}
          stateOptions={stateOptionsAhjDetails}
          cityOptions={cityOptionsAhjDetails}
          getErrorFor={this.getErrorFor}
          handleCheckboxChange={this.props.handleCheckboxChange}
          handleChange={this.handleChange}
          handleDropdownChange={this.handleDropdownChange}
          handleGetProjectStates={this.handleGetEngineerStates}
          handleGetProjectCities={this.handleGetEngineerCities}
          formType="RD-AHJD"
          tabIndex={this.props.tabIndex}
        />
        <SiteSpecificRequirements
          reservationDetails={reservationDetails}
          handleChange={this.handleChange}
          tabIndex={this.props.tabIndex}
          getErrorFor={this.getErrorFor}
          updatePpeSelections={this.props.updatePpeSelections}
          updateTrainingSelections={this.props.updateTrainingSelections}
          formType="RD-PSLS"
          checkManualPricingTrigger={this.props.checkManualPricingTrigger}
        />
      </Form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    fieldService: state.fieldService,
    schedulingDetails: state.schedulingDetails
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getEngineerStates: (countryCode, locationType) =>
      dispatch(actions.getEngineerStates(countryCode, locationType)),
    getEngineerCities: (stateCode, locationType) =>
      dispatch(actions.getEngineerCities(stateCode, locationType)),
    getAhjStates: (countryCode) => dispatch(actions.getAhjStates(countryCode)),
    getAhjCities: (stateCode) => dispatch(actions.getAhjCities(stateCode)),
    updatePpeSelections: (selectedPpe, isChecked, tabIndex) =>
      dispatch(actions.updatePpeSelections(selectedPpe, isChecked, tabIndex)),
    updateTrainingSelections: (
      selectedTraining,
      isChecked,
      isTrainingTwoHours,
      tabIndex
    ) =>
      dispatch(
        actions.updateTrainingSelections(
          selectedTraining,
          isChecked,
          isTrainingTwoHours,
          tabIndex
        )
      ),
    updateForm: (idx, form, forms, formType) =>
      dispatch(actions.updateForm(idx, form, forms, formType))
    // getAppointments: (data) => dispatch(actions.getAppointments(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SchedulingDetails);
