import React, { Component } from "react";
import {
  Container,
  Divider,
  Tab,
  Grid,
  Button,
  Modal,
  Header,
  Form,
  Table,
  Menu,
  Label,
  Popup,
  Icon
} from "semantic-ui-react";
import {
  CustomTitle,
  CustomHeader,
  CustomHeaderLabel,
  CustomLabel,
  CustomTitleLabel
} from "../../components/labels/labels";
import FooterHomeBlock from "../../components/footer/footer-home-block";
import ViewHistory from "./view-history/view-history";
import { Link } from "react-router-dom";
import ApplicantDetails from "./applicant-details";
import SiteDetails from "./site-details";
import ReservationDetails from "./reservation-details";
import { NotificationSuccess } from "../../components/notification/notifications";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import * as accountActions from "../field-evaluation/account-settings/account-settings.action";
import FesSummary from "../field-evaluation/product-details/fes/summary";
import FeSummary from "../field-evaluation/product-details/fe/summary";
import Role from "../../enums/role";
import { Common } from "../../common";
import ServiceType from "../../enums/service-type";
import Status from "../../enums/status";
import * as feProductDetailsAction from "./product-details/fe/product-details.action";

import {
  validate,
  getError,
  validateField,
  validateSubFormField,
  createValidation
} from "../../validator/validator";

import * as actions from "./field-evaluation.action";
import { GetFormData } from "./field-evaluation-helper";

import ToastHelper from "../../helpers/toast.helper";

import _ from "lodash";
import update from "immutability-helper";

import * as fesActions from "./product-details/fes/product-details.action";
import * as feActions from "./product-details/fe/product-details.action";
import AssumptionModal from "./product-details/fe/assumption-modal";

import {
  ADADrules,
  ADABTLDrules,
  ADQADrules,
  SDPSLSrules,
  SDFISSrules,
  PDrules,
  RDPSLSrules,
  SDOtherDetailsRules,
  FEGSQRules,
  IsEquipmentInstalledRules
  // RDFSLSrules,
  // RDAHJDrules
} from "./validation-rules";
import { getCustomerDetails } from "../manufacturer/standard/label-cart/label-cart.action";

import ScopeValue from "./product-details/fe/scope-value";
import AssumptionValue from "./product-details/fe/assumption-value";
import CancellationValue from "./product-details/fe/cancellation-value";
import RequirementsValue from "./product-details/fe/requirements-value";

class OrderDetails extends Component {
  state = {
    openCheckOut: false,
    openSaveAsDraft: false,
    openSaveChanges: false,
    preview: false,
    orderNumber: null,
    openSummaryModal: false,
    showErrors: false,
    price: "",
    tabActiveIndex: 0,
    isCopyOrder: false,
    isLoading: "",
    isSuccess: false,
    isManualPricingModal: false,
    isShowAssumptionModal: false
  };

  getErrorFor = (field, formType) => {
    const { form, data } = this.props.fieldEvaluation;

    let results = GetFormData(form, formType);

    let errors = getError(field, results.formData, data);
    return errors;
  };

  handleRenderScheduleDate = (date) => {
    if (date) {
      var date = new Date(date);
      var day = date.getDate();
      var month = date.getMonth() + 1;
      var year = date.getFullYear();
      var hour = date.getHours();
      var minute = date.getMinutes();
      var second = date.getSeconds();

      var time =
        month +
        "/" +
        day +
        "/" +
        year +
        " " +
        hour +
        ":" +
        minute +
        ":" +
        second;

      return time;
    }

    return null;
  };

  handleOpenSummaryModal = () => {
    let {
      applicantDetails,
      siteDetails,
      productDetails,
      reservationDetails,
      showErrors,
      otherDetails
    } = this.props.fieldEvaluation.form;

    let { orders } = this.props.fesProductItem;

    // handle offered to accepted quote
    otherDetails.isFromOffered = false;

    var _action = this.props.match.params.action;
    otherDetails.isCopyOrder = _action == "copy" ? true : false;

    // FE GSQ Validation
    const feProductDetailsForm = this.props.feProductDetails.form;
    this.feGsqValidation();

    // TODO: Change the condition when PH creation of quote/order has been implemented
    // No distiction yet if the qupte/order is FE or FES, will base on role for the meantime
    const userRole = Common.getUserRole();

    if (userRole == Role.fireEquipment) {
      if (orders.length == 0) {
        setTimeout(() => {
          ToastHelper.error({
            title: "Error",
            description: "No service product added"
          });
        }, 100);
        return false;
      }
    }

    // For single GL order only without changing the quantity, min fee will apply
    if (orders.length === 1) {
      if (orders[0].serviceProductCode == "GL" && orders[0].quantity === 1) {
        orders[0].total = orders[0].minimumFee;

        this.props.updateOrder(0);
      }
    }

    // if (siteDetails.showPrelimLocation === "false") {
    //   siteDetails.preliminarySiteLocationSection.validationErrors = createValidation(
    //     siteDetails.preliminarySiteLocationSection,
    //     SDPSLSrules
    //   );
    // }
    if (
      typeof siteDetails.showPrelimLocation === "undefined" ||
      siteDetails.showPrelimLocation === null
    )
      siteDetails.validationErrors = createValidation(
        siteDetails,
        IsEquipmentInstalledRules
      );

    otherDetails.validationErrors = createValidation(
      otherDetails,
      SDOtherDetailsRules
    );

    siteDetails.finalInstallationSiteSection.validationErrors =
      createValidation(siteDetails.finalInstallationSiteSection, SDFISSrules);

    if (otherDetails.showPrelimLocation === false) {
      reservationDetails.preliminarySiteLocationSection.validationErrors =
        createValidation(
          reservationDetails.preliminarySiteLocationSection,
          RDPSLSrules
        );

      // reservationDetails.finalSiteLocationSection.validationErrors = createValidation(
      //   reservationDetails.finalSiteLocationSection,
      //   RDFSLSrules
      // );

      // reservationDetails.authorityHavingJurisdictionDetails.validationErrors = createValidation(
      //   reservationDetails.authorityHavingJurisdictionDetails,
      //   RDAHJDrules
      // );
    }

    if (
      Object.IsEmpty(
        siteDetails.finalInstallationSiteSection.validationErrors
      ) &&
      // Object.IsEmpty(
      //   reservationDetails.preliminarySiteLocationSection.validationErrors
      // ) &&
      // Object.IsEmpty(
      //   reservationDetails.finalSiteLocationSection.validationErrors
      // ) &&
      // Object.IsEmpty(
      //   reservationDetails.authorityHavingJurisdictionDetails.validationErrors
      // )
      Object.IsEmpty(otherDetails.validationErrors) &&
      Object.IsEmpty(feProductDetailsForm.validationErrors)
    ) {
      // --- If PrelimLocation Validation Enable ---
      if (otherDetails.isReadyToScheduleNow === true) {
        if (
          Object.IsEmpty(
            reservationDetails.preliminarySiteLocationSection.validationErrors
          )
        ) {
          this.setState({ openSummaryModal: true });
          showErrors = false;
        } else {
          showErrors = true;
        }
      } else {
        this.setState({ openSummaryModal: true });
        showErrors = false;
      }
    } else {
      showErrors = true;
      this.displayRequiredFieldsError();
    }

    const newState = { ...this.props.fieldEvaluation.form };
    this.props.form(newState);
  };

  handleOpenCheckoutModal = () => {
    this.setState({ openCheckOut: true });
  };

  handleConfirmCheckOut = () => {
    this.setState({ openCheckOut: false, openSummaryModal: false });

    this.handleSubmit();
  };

  handleCancelCheckOut = () => {
    this.setState({ openCheckOut: false });
  };

  handleOpenSaveAsDraftModal = () => {
    this.setState({ openSaveAsDraft: true });
  };

  handleConfirmSaveAsDraft = () => {
    let { siteDetails, showErrors } = this.props.fieldEvaluation.form;

    siteDetails.finalInstallationSiteSection.validationErrors =
      createValidation(siteDetails.finalInstallationSiteSection, SDFISSrules);

    // FE GSQ Validation
    const feProductDetailsForm = this.props.feProductDetails.form;
    this.feGsqValidation();

    // Is equipment already installed validation
    if (
      typeof siteDetails.showPrelimLocation === "undefined" ||
      siteDetails.showPrelimLocation === null
    )
      siteDetails.validationErrors = createValidation(
        siteDetails,
        IsEquipmentInstalledRules
      );

    if (
      Object.IsEmpty(
        siteDetails.finalInstallationSiteSection.validationErrors
      ) &&
      Object.IsEmpty(feProductDetailsForm.validationErrors)
    ) {
      let userRole = Common.getUserRole();

      let myStatus =
        userRole == Role.projectHandler
          ? Status.draft
          : feProductDetailsForm && feProductDetailsForm.isManualPricing
          ? Status.pendingAssessment
          : Status.draft;

      // Save as Draft
      this.handleSubmit(
        myStatus,
        userRole == Role.fieldEngineer || Role.projectHandler
          ? ServiceType.fe
          : ServiceType.fes
      );

      showErrors = false;
    } else {
      showErrors = true;
      this.displayRequiredFieldsError();
    }

    this.setState({ openSaveAsDraft: false });

    const newState = { ...this.props.fieldEvaluation.form };
    this.props.form(newState);
  };

  handleCancelSaveAsDraft = () => {
    this.setState({ openSaveAsDraft: false });
  };

  handleOpenSaveChangesModal = () => {
    this.setState({ openSaveChanges: true });
  };

  handleConfirmSaveChanges = () => {
    this.setState({ openSaveChanges: false });

    let userRole = Common.getUserRole();
    // Save changes
    if (userRole == Role.fieldEngineer || userRole == Role.projectHandler)
      this.handleSaveChanges(ServiceType.fe);
    else this.handleSaveChanges(ServiceType.fes); //FES
  };

  handleCancelSaveChanges = () => {
    this.setState({ openSaveChanges: false });
  };

  handleSaveChanges = (serviceType) => {
    // FE GSQ Validation
    const feProductDetailsForm = this.props.feProductDetails.form;
    this.feGsqValidation();

    if (!Object.IsEmpty(feProductDetailsForm.validationErrors))
      return this.displayRequiredFieldsError();

    this.setState({
      isLoading: "loading"
    });
    let {
      applicantDetails,
      siteDetails,
      productDetails,
      reservationDetails,
      otherDetails,
      orderDetails
    } = this.props.fieldEvaluation.form;

    let { orders } = this.props.fesProductItem;

    const { form } = this.props.feProductItem;

    // Repopulate post data
    let applicantCollection = [];
    let siteCollection = [];
    let reservationCollection = [];
    let fesProductItems = [];
    let feProductItems = [];
    let ahjCollection = [];

    const _applicantDetails = applicantDetails.applicantDetails;
    const _applicantBillToLocationDetails =
      applicantDetails.applicantBillToLocationDetails;
    siteDetails.preliminarySiteLocationSection.siteType = 1;
    siteDetails.finalInstallationSiteSection.siteType = 2;

    reservationDetails.preliminarySiteLocationSection.siteType = 1;
    reservationDetails.preliminarySiteLocationSection.scheduleUnixTime =
      reservationDetails.preliminarySiteLocationSection.scheduleDate
        ? reservationDetails.preliminarySiteLocationSection.scheduleDate.getTime() /
          1000
        : null;

    reservationDetails.finalSiteLocationSection.siteType = 2;

    siteDetails.authorityHavingJurisdictionDetails.baseType = 1;
    reservationDetails.authorityHavingJurisdictionDetails.baseType = 2;

    // Primary
    // _applicantDetails.locationType = 1;
    applicantCollection.push(_applicantDetails);
    // Bill to
    // _applicantBillToLocationDetails.locationType = 2;
    applicantCollection.push(_applicantBillToLocationDetails);

    // Preliminary
    siteCollection.push(siteDetails.preliminarySiteLocationSection);
    // Final
    siteCollection.push(siteDetails.finalInstallationSiteSection);

    // Preliminary
    reservationCollection.push(
      reservationDetails.preliminarySiteLocationSection
    );
    // Final
    reservationCollection.push(reservationDetails.finalSiteLocationSection);

    // AHJ
    ahjCollection.push(siteDetails.authorityHavingJurisdictionDetails);
    ahjCollection.push(reservationDetails.authorityHavingJurisdictionDetails);

    // FES
    orders.map((o, idx) => {
      let me = {
        id: o.id,
        serviceProductPriceId: o.priceId, // FES
        quantity: parseInt(o.quantity),
        price: o.price,
        total: o.total,
        isMinimumFee: o.isMinimumFee,
        areAerialsBrontoSchwingMetz: o.areAerialsBrontoSchwingMetz,
        hasExistingULLabel: o.hasExistingULLabel,
        requestedScheduleUnixTime: o.requestedScheduleUnixTime,
        isProductInvolvedInLitigation: o.isProductInvolvedInLitigation,
        productItemOtherInfos: o.others
          ? Object.keys(o.others).map((me) => ({
              code: me,
              name: "Truck #", // For Aerial only, TODO: improve this
              info: o.others[me]
            }))
          : null
      };
      fesProductItems.push(me);
    });

    let isPHEdit = Common.isPhEditing(form.isManualPricing);
    // FE-DRAFT note: no price yet since price computation is in quotation page
    // TODO: multiple
    let feOrder = {
      id: form.id,
      quantity: parseInt(form.quantity),
      isMinimumFee: false,
      areAerialsBrontoSchwingMetz: false,
      hasExistingULLabel: form.isExistingUlEquipment,
      isProductInvolvedInLitigation: form.isInvolvedLitigation,
      isMinimumFee: false,
      isManualPricing: form.isManualPricing,
      litigationDescription: form.isInvolvedLitigation
        ? form.litigationDescription
        : null,
      isInstalledSameLocation: form.isInstalledSameLocation,
      isEvaluatedSameVisit: form.isEvaluatedSameVisit,
      isPanelboardOver200: form.isPanelboard ? form.panelboardOver200 : null,
      isSwitchboardOver600: form.isSwitchboard ? form.switchboardOver600 : null,
      propertyType: form.propertyType,
      serviceSubProductId: form.serviceSubProductId,
      additionalInformation: form.additionalInfo,
      assumptions: AssumptionValue(this.props.feProductItem, null, false),
      scope: ScopeValue(form),
      cancellation: CancellationValue(this.props.feProductItem),
      requirementsInspection: RequirementsValue(this.props.feProductItem)
    };

    if (isPHEdit) {
      Object.assign(feOrder, {
        total: parseFloat(form.totalPriceByPh)
      });
    }
    feProductItems.push(feOrder);

    let copyOrderPostData = {};

    copyOrderPostData.applicants = applicantCollection;
    copyOrderPostData.quoteAcceptance = applicantDetails.quoteAcceptanceDetails;
    copyOrderPostData.sites = siteCollection;
    copyOrderPostData.products = productDetails;
    copyOrderPostData.productItems =
      serviceType == ServiceType.fe ? feProductItems : fesProductItems;
    copyOrderPostData.engineers = reservationCollection;
    copyOrderPostData.authorityJurisdictions = ahjCollection;
    // for customer only, changing of draft to PUA and offered to PUA if manual pricing triggers
    copyOrderPostData.status =
      orderDetails.status == Status.draft ||
      orderDetails.status == Status.offered
        ? form.isManualPricing && Common.getUserRole() != Role.projectHandler
          ? Status.pendingAssessment
          : orderDetails.status
        : orderDetails.status;
    copyOrderPostData.customerReferenceNumber =
      otherDetails.customerReferenceNumber;
    copyOrderPostData.purchaseOrderNumber = this.state.purchaseOrderNumber;
    copyOrderPostData.requestNumber = orderDetails.orderNumber;
    copyOrderPostData.isReadyToScheduleNow = otherDetails.isReadyToScheduleNow;
    copyOrderPostData.preferredScheduleDate = reservationDetails
      .preliminarySiteLocationSection.scheduleDate
      ? reservationDetails.preliminarySiteLocationSection.scheduleDate.toISOString()
      : new Date().toISOString();

    if (isPHEdit) {
      copyOrderPostData.isEditedByPh = true;
    }
    // copyOrderPostData.createdForUserId =
    //   accountDetails.form.applicantDetails.applicantDetails.userId ||
    //   orderDetails.createdForUserId;
    // if (Object.IsEmpty(applicantDetails.applicantDetails.validationErrors)) {
    return this.props
      .doUpdateFEOrder(
        _.omit(copyOrderPostData, ["showErrors", "validationErrors"])
      )
      .then((s) => {
        if (s) {
          if (s.number != "") {
            this.setState({
              // isSuccess: true,
              refereceNmber: s.number,
              isShowButton: false,
              isLoading: "",
              isSuccess: true
            });

            setTimeout(() => {
              ToastHelper.success({
                description: (
                  <p>
                    {`Save Changes Successfully. Your quote number is ${s.number}.`}
                  </p>
                )
              });
            }, 100);
          } else {
            setTimeout(() => {
              ToastHelper.error({
                title: "Something went wrong",
                description: s.message
              });
            }, 100);
          }
        } else {
          this.setState({
            isLoading: "",
            isSuccess: false
          });
          setTimeout(() => {
            ToastHelper.error({
              title: "Error",
              description: "Something went wrong"
            });
          }, 100);
        }
        this.handleUploadAttachment(form.file, s);
      });
    // }
  };

  handleSubmit = (statusType, serviceType) => {
    this.setState({ isLoading: "loading" });
    let {
      applicantDetails,
      siteDetails,
      productDetails,
      reservationDetails,
      otherDetails
    } = this.props.fieldEvaluation.form;

    let { accountDetails } = this.props;

    let { orders } = this.props.fesProductItem;
    const { form } = this.props.feProductItem;

    // Repopulate post data
    let applicantCollection = [];
    let siteCollection = [];
    let reservationCollection = [];
    let fesProductItems = [];
    let feProductItems = [];
    let ahjCollection = [];

    const _applicantDetails = applicantDetails.applicantDetails;
    const _applicantBillToLocationDetails =
      applicantDetails.applicantBillToLocationDetails;
    siteDetails.preliminarySiteLocationSection.siteType = 1;
    siteDetails.finalInstallationSiteSection.siteType = 2;

    reservationDetails.preliminarySiteLocationSection.siteType = 1;
    reservationDetails.preliminarySiteLocationSection.scheduleUnixTime =
      reservationDetails.preliminarySiteLocationSection.scheduleDate
        ? reservationDetails.preliminarySiteLocationSection.scheduleDate.getTime() /
          1000
        : null;
    reservationDetails.finalSiteLocationSection.siteType = 2;

    siteDetails.authorityHavingJurisdictionDetails.baseType = 1;
    reservationDetails.authorityHavingJurisdictionDetails.baseType = 2;

    // Primary
    // _applicantDetails.locationType = 1;
    applicantCollection.push(_applicantDetails);
    // Bill to
    // _applicantBillToLocationDetails.locationType = 2;
    applicantCollection.push(_applicantBillToLocationDetails);

    // Preliminary
    siteCollection.push(siteDetails.preliminarySiteLocationSection);
    // Final
    siteCollection.push(siteDetails.finalInstallationSiteSection);

    // Preliminary
    reservationCollection.push(
      reservationDetails.preliminarySiteLocationSection
    );
    // Final
    reservationCollection.push(reservationDetails.finalSiteLocationSection);

    // AHJ
    ahjCollection.push(siteDetails.authorityHavingJurisdictionDetails);
    ahjCollection.push(reservationDetails.authorityHavingJurisdictionDetails);

    // FES
    orders.map((o, idx) => {
      let me = {
        serviceProductPriceId: o.priceId,
        quantity: parseInt(o.quantity),
        price: o.price,
        total: o.total,
        isMinimumFee: o.isMinimumFee,
        areAerialsBrontoSchwingMetz: o.areAerialsBrontoSchwingMetz,
        hasExistingULLabel: o.hasExistingULLabel,
        requestedScheduleUnixTime: o.requestedScheduleUnixTime,
        isProductInvolvedInLitigation: o.isProductInvolvedInLitigation,
        productItemOtherInfos: o.others
          ? Object.keys(o.others).map((me) => ({
              code: me,
              name: "Truck #", // For Aerial only, TODO: improve this
              info: o.others[me]
            }))
          : null
      };
      fesProductItems.push(me);
    });

    let isPHEdit = Common.isPhEditing(form.isManualPricing);
    // FE-DRAFT note: no price yet since price computation is in quotation page
    // TODO: multiple
    let feOrder = {
      quantity: parseInt(form.quantity),
      isMinimumFee: false,
      areAerialsBrontoSchwingMetz: false,
      hasExistingULLabel: form.isExistingUlEquipment,
      isProductInvolvedInLitigation: form.isInvolvedLitigation,
      isMinimumFee: false,
      isManualPricing: form.isManualPricing,
      litigationDescription: form.isInvolvedLitigation
        ? form.litigationDescription
        : null,
      isInstalledSameLocation: form.isInstalledSameLocation,
      isEvaluatedSameVisit: form.isEvaluatedSameVisit,
      isPanelboardOver200: form.isPanelboard ? form.panelboardOver200 : null,
      isSwitchboardOver600: form.isSwitchboard ? form.switchboardOver600 : null,
      propertyType: form.propertyType,
      serviceSubProductId:
        statusType == Status.draft || Status.pendingAssessment
          ? form.serviceSubProductId
          : null,
      additionalInformation: form.additionalInfo,
      assumptions: AssumptionValue(this.props.feProductItem, null, false),
      scope: ScopeValue(form),
      cancellation: CancellationValue(this.props.feProductItem),
      requirementsInspection: RequirementsValue(this.props.feProductItem)
    };
    if (isPHEdit) {
      Object.assign(feOrder, {
        total: parseFloat(form.totalPriceByPh)
      });
    }
    feProductItems.push(feOrder);

    let copyOrderPostData = {};

    copyOrderPostData.applicants = applicantCollection;
    copyOrderPostData.quoteAcceptance = applicantDetails.quoteAcceptanceDetails;
    copyOrderPostData.sites = siteCollection;
    copyOrderPostData.products = productDetails;
    copyOrderPostData.productItems =
      serviceType == ServiceType.fe ? feProductItems : fesProductItems;
    copyOrderPostData.engineers = reservationCollection;
    copyOrderPostData.authorityJurisdictions = ahjCollection;
    copyOrderPostData.status = statusType;
    copyOrderPostData.orderType = 1;
    copyOrderPostData.serviceType = serviceType;
    copyOrderPostData.customerReferenceNumber =
      otherDetails.customerReferenceNumber;
    copyOrderPostData.purchaseOrderNumber = this.state.purchaseOrderNumber;
    copyOrderPostData.isReadyToScheduleNow = otherDetails.isReadyToScheduleNow;
    copyOrderPostData.preferredScheduleDate = this.handleRenderScheduleDate(
      reservationDetails.preliminarySiteLocationSection.scheduleDate
    );
    copyOrderPostData.createdForUserId =
      accountDetails.form.applicantDetails.applicantDetails.userId;

    if (isPHEdit) {
      copyOrderPostData.isEditedByPh = true;
    }
    // if (Object.IsEmpty(applicantDetails.applicantDetails.validationErrors)) {
    return this.props
      .doCreateFEOrder(
        _.omit(copyOrderPostData, ["showErrors", "validationErrors"])
      )
      .then((s) => {
        this.handleUploadAttachment(form.file, s);
        this.handleToastMessage(s);
      });
  };

  handleToastMessage(result) {
    debugger;
    if (result) {
      if (result.number != "") {
        this.setState({
          // isSuccess: true,
          refereceNmber: result.number,
          isShowButton: false,
          isLoading: "",
          isSuccess: true
        });

        setTimeout(() => {
          ToastHelper.success({
            description: (
              <p>
                {`Thank you for your business. Your quote reference number is ${result.number}.
                  A Project Handler will be in touch with you as soon as
                  possible.`}
              </p>
            )
          });
        }, 100);
      } else {
        setTimeout(() => {
          ToastHelper.error({
            title: "Something went wrong",
            description: result.message
          });
        }, 100);
      }
    } else {
      this.setState({
        isLoading: "",
        isSuccess: false
      });
      setTimeout(() => {
        ToastHelper.error({
          title: "Error",
          description: "Something went wrong"
        });
      }, 100);
    }
  }

  handleChange = (e, formType) => {
    const { form } = this.props.fieldEvaluation;
    let result = GetFormData(form, formType);
    validateSubFormField(
      result.rules,
      result.formData,
      e.target.name,
      e.target.value
    );
    const newState = { ...this.props.fieldEvaluation.form };
    this.props.form(newState);
  };

  handleDropdownChange = (dpName, dpValue, formType) => {
    const { form } = this.props.fieldEvaluation;
    let result = GetFormData(form, formType);
    validateField(result.rules, result.formData, dpName, dpValue);
    const newState = { ...this.props.fieldEvaluation.form };
    this.props.form(newState);
  };

  handleCheckboxChange = (cbName, cbValue, formType) => {
    const { form } = this.props.fieldEvaluation;
    let result = GetFormData(form, formType);
    validateField(result.rules, result.formData, cbName, cbValue);
    this.handleIsSameAboveCheckChange(result.formData);

    if (cbName === "showPrelimLocation") this.handleShowPrelimLocation(cbValue);

    if (cbName === "prelimSameAsApplicant" || cbName === "finalSameAsApplicant")
      this.handleSameAsApplicant(cbValue, cbName);

    if (cbName === "isFinalSameAsApplicant")
      this.handleFinalSameAsApplicant(cbValue);

    if (cbName === "isReadyToScheduleNow") this.handleScheduleNow(cbValue);

    const newState = { ...this.props.fieldEvaluation.form };
    this.props.form(newState);
  };

  handleShowPrelimLocation = (value) => {
    let { siteDetails } = this.props.fieldEvaluation.form;

    this.handleClearSiteDetails();

    siteDetails.showPrelimLocation = value;

    // Is equipment already installed? No, will trigger manual pricing
    this.checkManualPricingTrigger(value, "showPrelimLocation");
  };

  handleClearSiteDetails = () => {
    let { siteDetails, otherDetails } = this.props.fieldEvaluation.form;

    siteDetails.preliminarySiteLocationSection = {
      data: [],
      showErrors: false,
      validationErrors: {}
    };

    siteDetails.finalInstallationSiteSection = {
      data: [],
      showErrors: false,
      validationErrors: {}
    };

    siteDetails.authorityHavingJurisdictionDetails = {
      data: [],
      showErrors: false,
      validationErrors: {},
      validationErrors: {}
    };

    siteDetails.prelimSameAsApplicant = null;
    siteDetails.finalSameAsApplicant = null;
    otherDetails.isReadyToScheduleNow = false;
    siteDetails.isFinalSameAsApplicant = false;
  };

  handleSameAsApplicant = (value, name) => {
    let { reservationDetails, siteDetails } = this.props.fieldEvaluation.form;

    reservationDetails.sameAsApplicant = value;

    switch (name) {
      case "prelimSameAsApplicant":
        siteDetails.prelimSameAsApplicant = value;
        break;
      case "finalSameAsApplicant":
        siteDetails.finalSameAsApplicant = value;
    }
  };

  handleScheduleNow = (value) => {
    let { otherDetails, siteDetails } = this.props.fieldEvaluation.form;

    siteDetails.preliminarySiteLocationSection.validationErrors =
      createValidation(siteDetails.preliminarySiteLocationSection, SDPSLSrules);

    siteDetails.finalInstallationSiteSection.validationErrors =
      createValidation(siteDetails.finalInstallationSiteSection, SDFISSrules);

    if (siteDetails.showPrelimLocation === false) {
      if (
        Object.IsEmpty(
          siteDetails.preliminarySiteLocationSection.validationErrors
        ) &&
        Object.IsEmpty(
          siteDetails.finalInstallationSiteSection.validationErrors
        )
      ) {
        otherDetails.isReadyToScheduleNow = value;

        // 1 - project details
        // 2 - engineer details
        var activeIndex = value ? 1 : 0;

        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;

        this.setState({ tabActiveIndex: activeIndex });
      } else {
        otherDetails.isReadyToScheduleNow = false;
        this.displayRequiredFieldsError();
      }
    } else {
      if (
        Object.IsEmpty(
          siteDetails.finalInstallationSiteSection.validationErrors
        )
      ) {
        otherDetails.isReadyToScheduleNow = value;

        // 1 - project details
        // 2 - engineer details
        var activeIndex = value ? 1 : 0;

        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;

        this.setState({ tabActiveIndex: activeIndex });
      } else {
        otherDetails.isReadyToScheduleNow = false;
        this.displayRequiredFieldsError();
      }
    }
  };

  handleFinalSameAsApplicant = (value) => {
    let { siteDetails } = this.props.fieldEvaluation.form;

    siteDetails.isFinalSameAsApplicant = value;

    if (siteDetails.isFinalSameAsApplicant) {
      siteDetails.finalInstallationSiteSection = Object.assign(
        {},
        siteDetails.preliminarySiteLocationSection
      );

      this.props.getProjectStates(
        siteDetails.finalInstallationSiteSection.country,
        2
      );
      this.props.getProjectCities(
        siteDetails.finalInstallationSiteSection.state,
        2
      );
    } else {
      siteDetails.finalInstallationSiteSection = {
        data: [],
        showErrors: false,
        validationErrors: {}
      };
    }
  };

  handleTabChange = (e, { activeIndex }) =>
    this.setState({ tabActiveIndex: activeIndex });

  handleIsSameAboveCheckChange = (formData) => {
    let { applicantDetails, reservationDetails } =
      this.props.fieldEvaluation.form;
    // applicantDetails && applicantDetails.isSameAsAbove
    //   ? (applicantDetails.applicantBillToLocationDetails =
    //       applicantDetails.applicantDetails)
    //   : (applicantDetails.applicantBillToLocationDetails = {
    //       locationType: 2,
    //       showErrors: false,
    //       validationErrors: {}
    //     });

    if (applicantDetails && applicantDetails.isSameAsAbove) {
      var _appDetails = _.omit(applicantDetails.applicantDetails, [
        "locationType"
      ]);
      applicantDetails.applicantBillToLocationDetails = _appDetails;
      applicantDetails.applicantBillToLocationDetails.locationType = 2;
    }

    if (reservationDetails && reservationDetails.isSameAsAbove) {
      var _reservationDetails = _.omit(
        reservationDetails.preliminarySiteLocationSection,
        ["siteType"]
      );
      reservationDetails.finalSiteLocationSection = _reservationDetails;
      reservationDetails.finalSiteLocationSection.siteType = 2;
    }

    // reservationDetails && reservationDetails.isSameAsAbove
    //   ? (reservationDetails.finalSiteLocationSection =
    //       reservationDetails.preliminarySiteLocationSection)
    //   : (reservationDetails.finalSiteLocationSection = {
    //       siteType: 2,
    //       showErrors: false,
    //       validationErrors: {}
    //     });
  };

  onCloseSummaryModal = () => {
    this.setState({ openSummaryModal: false });
  };

  handleCopyOrder = (value) => {
    let { otherDetails } = this.props.fieldEvaluation.form;

    otherDetails.isCopyOrder = value;

    const newState = { ...this.props.fieldEvaluation.form };
    this.props.form(newState);
  };

  // FE manual pricing triggers
  updateFeForm(name, value) {
    let { form } = this.props.feProductItem;
    let newState = update(form, {
      $merge: _.set(form, name, value)
    });
    this.props.updateFeForm(newState);
  }

  checkManualPricingTrigger(value, target) {
    const { form } = this.props.feProductItem;
    const { siteDetails, reservationDetails } = this.props.fieldEvaluation.form;

    // More than 4 quantity for switchboard
    let isQtyGreaterFour =
      parseInt(target === "quantity" ? value : form.quantity) > 4 &&
      form.isSwitchboard;

    // Is equipment already installed? No, will trigger manual pricing
    let isEquipInstalled =
      siteDetails.showPrelimLocation == "false" ? true : false;

    // Will the training take more than 2 hours? Yes, will trigger manual pricing
    // Index 0 is the on-site training
    let isTrainingTwoHours =
      reservationDetails.trainingSelections[0].trainingTwoHours;

    // More than 1 quantity for panelboard
    let isQtyGreaterOne =
      parseInt(target === "quantity" ? value : form.quantity) > 1 &&
      form.isPanelboard;

    let isManualModal = false;
    switch (target) {
      case "quantity":
      case "equipmentType":
        if (form.isSwitchboard) {
          if (isQtyGreaterFour) isManualModal = true;
          if (parseInt(value || form.quantity) === 1)
            form.isEvaluatedSameVisit = null;
        }
        if (form.isPanelboard) {
          isQtyGreaterOne
            ? (isManualModal = true)
            : (form.isEvaluatedSameVisit = null);
        }
        break;
      case "showPrelimLocation":
        if (isEquipInstalled) isManualModal = true;
        break;
      case "trainingTwoHours":
        if (isTrainingTwoHours) isManualModal = true;
        break;
      case "isEvaluatedSameVisit":
        if (
          !form.isEvaluatedSameVisit &&
          typeof form.isEvaluatedSameVisit !== "undefined" &&
          form.isEvaluatedSameVisit !== null
        )
          isManualModal = true;
        break;
      case "isInstalledSameLocation":
        if (
          !form.isInstalledSameLocation &&
          typeof form.isInstalledSameLocation !== "undefined"
        )
          isManualModal = true;
        break;
      case "isInvolvedLitigation":
        if (
          form.isInvolvedLitigation &&
          typeof form.isInvolvedLitigation !== "undefined"
        )
          isManualModal = true;
      default:
        break;
    }

    let isManual = false;

    if (
      (!form.isInstalledSameLocation &&
        typeof form.isInstalledSameLocation !== "undefined") ||
      (!form.isEvaluatedSameVisit &&
        typeof form.isEvaluatedSameVisit !== "undefined" &&
        form.isEvaluatedSameVisit !== null) ||
      (form.isInvolvedLitigation &&
        typeof form.isInvolvedLitigation !== "undefined") ||
      isQtyGreaterFour ||
      isEquipInstalled ||
      isTrainingTwoHours ||
      isQtyGreaterOne
    )
      isManual = true;

    if (
      form.switchboardOver600 &&
      typeof form.switchboardOver600 !== "undefined"
    )
      isManual = true;

    this.setState({ isManualPricingModal: isManualModal });
    this.updateFeForm("isManualPricing", isManual);
  }

  handleIsManualPricingModalClose = () => {
    this.setState({ isManualPricingModal: false });
  };

  handleUploadAttachment(file, result) {
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("requestNumber", result.number);
      this.props.uploadAttachment(formData);
    }
  }

  handleAssumptionModal(isShow) {
    this.setState({ isShowAssumptionModal: isShow });
  }

  componentDidMount() {
    // CLEAR FES GSQ
    this.props.clearFesServiceProducts();
    this.props.clearFesServiceSubProducts();
    this.props.clearFesServiceProductPrices();
    this.props.clearFesOrder();

    // FORM
    this.props.clear();

    // CLEAR FE GSQ
    this.props.clearFeGsq();
    this.props.clearServiceAreas();
    this.props.clearServiceProducts();
    this.props.clearServiceSubProducts();

    const { accountDetails, accountSettings } = this.props;

    var _orderNumber = this.props.match.params.orderNumber;
    var _action = this.props.match.params.action;
    var preview = this.props.match.params.preview;

    var _orderNo = _orderNumber ? _orderNumber : null;
    this.setState({ orderNumber: _orderNo });

    var isPreview = preview ? true : false;
    this.setState({ preview: isPreview });

    var _isCopyOrder = _action == "copy" ? true : false;
    this.handleCopyOrder(_isCopyOrder);
    var userRole = Common.getUserRole();
    var accountSettingsPsn = localStorage.getItem("accountSettingsPsn");

    if (_orderNumber) {
      this.props.getOrderDetails(_orderNumber).then((s) => {
        const userId = s.data.orderDetails.createdForUserId;

        this.props.getAccountSettings(userId);
        this.props.getCustomerDetails(s.data.orderDetails.partySiteNumber);
      });
    } else {
      if (userRole == Role.projectHandler && accountSettingsPsn) {
        //this.props.getAccountSettingsByPsn(accountSettingsPsn);

        this.props.getAccountSettings(
          accountDetails.user && accountDetails.user.id
        );
        this.props.getCustomerDetails(accountSettingsPsn);
      } else {
        this.props.getAccountSettings();
      }
    }

    this.props.getProjectCountries(1);
    this.props.getProjectCountries(2);

    this.props.getEngineerCountries(1);
    this.props.getEngineerCountries(2);

    this.props.getAhjCountries();

    let psn = Common.getPartySiteNumber();
    this.props.getCustomerDetails(psn);
  }

  handleCheckOffered = () => {
    // handle offered to accepted quote
    let { otherDetails, orderDetails } = this.props.fieldEvaluation.form;

    if (orderDetails.status == 9) {
      otherDetails.isFromOffered = true;
      const newState = { ...this.props.fieldEvaluation.form };
      this.props.form(newState);
    }
  };

  displayRequiredFieldsError() {
    setTimeout(() => {
      ToastHelper.error({
        title: "Error",
        description: "Please complete all the required fields"
      });
    }, 100);
  }

  feGsqValidation() {
    const feProductDetailsForm = this.props.feProductDetails.form;
    const feProductDetailsRules = feProductDetailsAction.getValidation(
      feProductDetailsForm,
      FEGSQRules
    );

    feProductDetailsForm.validationErrors = createValidation(
      feProductDetailsForm,
      feProductDetailsRules
    );

    feProductDetailsAction.updateForm(feProductDetailsForm);
  }

  render() {
    const {
      form,
      isCheckoutSuccess,
      requestNumber,
      appointments,
      isSuccessGetOrderDetails
    } = this.props.fieldEvaluation;

    if (isSuccessGetOrderDetails) {
      this.handleCheckOffered();
      // this.props.updateIsSuccess();
    }

    const { customerDetails, isSuccessGetSettings } = this.props;

    let accountDetails = customerDetails.data;
    let accountSettings = this.props.accountSettings;
    let primaryDetails = accountSettings
      ? accountSettings.applicantDetails.applicantDetails
      : {};
    let billToDetails = accountSettings
      ? accountSettings.applicantDetails.applicantBillToLocationDetails
      : {};

    const fe = this.props.feProductItem.form;
    const { orders } = this.props.fesProductItem;

    const {
      openCheckOut,
      preview,
      openSummaryModal,
      price,
      orderNumber,
      tabActiveIndex,
      openSaveAsDraft,
      openSaveChanges,
      isCopyOrder,
      isLoading,
      isSuccess,
      isManualPricingModal,
      isShowAssumptionModal
    } = this.state;

    const userRole = Common.getUserRole();

    var editUrl =
      accountDetails && accountDetails.psn
        ? this.props.match.params.orderNumber
          ? `/home/account-settings/${accountDetails.psn}/true/${this.props.match.params.orderNumber}`
          : `/home/account-settings/${accountDetails.psn}/true/`
        : "/home/account-settings/0/true";

    if (isSuccess == true) {
      return userRole == Role.projectHandler ? (
        <Redirect to="/home/view-all-orders" />
      ) : (
        <Redirect to="/home/view-history" />
      );
    }

    let isPHEdit = Common.isPhEditing(fe.isManualPricing);

    return (
      <div className="container-wrapper">
        <Modal
          size="mini"
          open={openCheckOut}
          onClose={this.handleCancelCheckOut}
        >
          <Modal.Header>Check out</Modal.Header>
          <Modal.Content>
            <p>Are you sure you want to check out this order?</p>
          </Modal.Content>
          <Modal.Actions>
            <Button primary onClick={this.handleConfirmCheckOut}>
              Yes
            </Button>
            <Button primary basic onClick={this.handleCancelCheckOut}>
              Cancel
            </Button>
          </Modal.Actions>
        </Modal>
        <Modal
          size="mini"
          open={openSaveAsDraft}
          onClose={this.handleCancelSaveAsDraft}
        >
          <Modal.Header>Save</Modal.Header>
          <Modal.Content>
            <p>Are you sure you want to save?</p>
          </Modal.Content>
          <Modal.Actions>
            <Button primary onClick={this.handleConfirmSaveAsDraft}>
              Yes
            </Button>
            <Button primary basic onClick={this.handleCancelSaveAsDraft}>
              Cancel
            </Button>
          </Modal.Actions>
        </Modal>
        <Modal
          size="mini"
          open={openSaveChanges}
          onClose={this.handleCancelSaveChanges}
        >
          <Modal.Header>Save Changes</Modal.Header>
          <Modal.Content>
            <p>Are you sure you want to save changes?</p>
          </Modal.Content>
          <Modal.Actions>
            <Button primary onClick={this.handleConfirmSaveChanges}>
              Yes
            </Button>
            <Button primary basic onClick={this.handleCancelSaveChanges}>
              Cancel
            </Button>
          </Modal.Actions>
        </Modal>
        <Modal
          open={isManualPricingModal}
          size="mini"
          onClose={this.handleIsManualPricingModalClose}
        >
          <Modal.Header>
            <Header icon="info circle" textAlign="center" />
          </Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <p>
                Please contact us at{" "}
                <a href="tel:1-877-854-3577">1-877-854-3577</a> ext 2 or{" "}
                <a href="mailto:field@ul.com">field@ul.com</a> and a Project
                Handler will assist with your request
              </p>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button
              primary
              basic
              onClick={this.handleIsManualPricingModalClose}
            >
              OK
            </Button>
          </Modal.Actions>
        </Modal>
        <Container fluid>
          {userRole == Role.projectHandler && (
            <Grid>
              <Grid.Row columns={3}>
                <Grid.Column>
                  <CustomHeaderLabel message="Company Applicant Details" />{" "}
                </Grid.Column>
                <Grid.Column>
                  <Button compact primary as={Link} to={editUrl}>
                    Search/Edit
                  </Button>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={3}>
                <Grid.Column>
                  <CustomTitleLabel message={"Account Number:"} />
                  <br />
                  <CustomLabel
                    message={accountDetails && accountDetails.accountNumber}
                  />
                </Grid.Column>
                <Grid.Column>
                  <CustomTitleLabel message={"Party Site #:"} />
                  <br />
                  <CustomLabel message={accountDetails && accountDetails.psn} />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={3}>
                <Grid.Column>
                  <CustomTitleLabel message={"Legal Company Name:"} />
                  <br />
                  <CustomLabel
                    message={accountDetails && accountDetails.partyName}
                  />
                </Grid.Column>

                <Grid.Column>
                  <CustomTitleLabel message={"Address:"} />
                  <br />
                  <CustomLabel
                    message={
                      accountDetails &&
                      `${accountDetails.addressLine1} ${accountDetails.city} ${accountDetails.state} ${accountDetails.countryCode} ${accountDetails.postalCode}`
                    }
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          )}
          <Grid verticalAlign="middle">
            <Grid.Column>
              <CustomHeaderLabel message="Quote Details" />
            </Grid.Column>
            {/* <Grid.Column width={12}>
              <span hidden={!isCheckoutSuccess}>
                <NotificationSuccess
                  message={`Thank You for your business. You request number is ${requestNumber}. A Project Handler will be in touch with you as soon as possible`}
                />
              </span>
            </Grid.Column> */}
          </Grid>

          <Divider hidden />
          <Tab
            panes={[
              {
                menuItem: "Project Details",
                render: () => (
                  <Tab.Pane>
                    <SiteDetails
                      form={form}
                      handleChange={this.handleChange}
                      handleDropdownChange={this.handleDropdownChange}
                      handleCheckboxChange={this.handleCheckboxChange}
                      getErrorFor={this.getErrorFor}
                      preview={preview}
                      custDetails={customerDetails}
                      checkManualPricingTrigger={this.checkManualPricingTrigger.bind(
                        this
                      )}
                    />
                  </Tab.Pane>
                )
              },
              {
                menuItem: form.otherDetails.isReadyToScheduleNow === true && (
                  <Menu.Item>Scheduling Details</Menu.Item>
                ),
                render: () =>
                  form.otherDetails.isReadyToScheduleNow === true && (
                    <Tab.Pane>
                      <ReservationDetails
                        form={form}
                        handleChange={this.handleChange}
                        handleDropdownChange={this.handleDropdownChange}
                        handleCheckboxChange={this.handleCheckboxChange}
                        getErrorFor={this.getErrorFor}
                        preview={preview}
                        checkManualPricingTrigger={this.checkManualPricingTrigger.bind(
                          this
                        )}
                      />
                    </Tab.Pane>
                  )
              }
            ]}
            activeIndex={tabActiveIndex}
            onTabChange={this.handleTabChange}
          />
          <Divider hidden />
          <Grid centered columns={2}>
            <Grid.Column>
              {!orderNumber ? (
                <Button
                  className={isLoading}
                  color="green"
                  onClick={this.handleOpenSaveAsDraftModal}
                >
                  Save
                </Button>
              ) : !form.otherDetails.isCopyOrder ? (
                <Button
                  className={isLoading}
                  color="green"
                  onClick={this.handleOpenSaveChangesModal}
                >
                  Save Changes
                </Button>
              ) : (
                <Button
                  className={isLoading}
                  color="green"
                  onClick={this.handleOpenSaveAsDraftModal}
                >
                  Save
                </Button>
              )}
              {fe.isManualPricing && userRole != Role.projectHandler ? (
                <Popup
                  position="top center"
                  hoverable
                  trigger={
                    <span>
                      <Button disabled color="blue">
                        Review Project Details
                      </Button>
                    </span>
                  }
                >
                  <Grid centered>
                    <Grid.Column textAlign="center">
                      <p>
                        Please contact us at{" "}
                        <a href="tel:1-877-854-3577">1-877-854-3577</a> ext 2 or{" "}
                        <a href="mailto:field@ul.com">field@ul.com</a> and a
                        Project Handler will assist with your request
                      </p>
                    </Grid.Column>
                  </Grid>
                </Popup>
              ) : isPHEdit ? (
                <span>
                  <Button
                    color="blue"
                    onClick={this.handleAssumptionModal.bind(this, true)}
                  >
                    Edit Assumption
                  </Button>
                  <Button
                    color="blue"
                    onClick={this.handleOpenSummaryModal.bind(this)}
                  >
                    Review Project Details
                  </Button>
                </span>
              ) : (
                <Button
                  color="blue"
                  onClick={this.handleOpenSummaryModal.bind(this)}
                >
                  Review Project Details
                </Button>
              )}

              {/* <Button
                disabled={isManualPricing}
                color="blue"
                onClick={this.handleOpenSummaryModal}
              >
                Review Project Details
              </Button> */}

              {userRole == Role.projectHandler ? (
                <Button negative as={Link} to={"/home/view-all-orders/"}>
                  Cancel
                </Button>
              ) : (
                <Button negative as={Link} to={"/home/view-history/"}>
                  Cancel
                </Button>
              )}
            </Grid.Column>
          </Grid>
          <FooterHomeBlock />
        </Container>

        {/* SUMMARY */}
        {userRole == Role.fireEquipment || userRole == Role.projectHandler ? (
          <FesSummary
            openSummaryModal={openSummaryModal}
            onCloseSummaryModal={this.onCloseSummaryModal}
            primaryDetails={primaryDetails}
            billToDetails={billToDetails}
            orders={orders}
          />
        ) : null}

        {userRole == Role.fieldEngineer || userRole == Role.projectHandler ? (
          <FeSummary
            openSummaryModal={openSummaryModal}
            onCloseSummaryModal={this.onCloseSummaryModal}
            primaryDetails={primaryDetails}
            billToDetails={billToDetails}
            reservationDetails={form.reservationDetails}
            siteDetails={form.siteDetails}
            otherDetails={form.otherDetails}
            accountSettings={accountSettings}
            fe={fe}
            isFromOffered={false}
          />
        ) : null}

        {/* PH EDIT ASSUMPTION */}
        {isShowAssumptionModal ? (
          <AssumptionModal
            isShowAssumptionModal={isShowAssumptionModal}
            handleAssumptionModal={this.handleAssumptionModal.bind(this)}
            data={this.props.feProductItem}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    fieldEvaluation: state.fieldEvaluation,
    appointments: state.fieldEvaluation.appointments,
    fesProductItem: state.fesProductDetails,
    feProductItem: state.feProductDetails,
    accountSettings: state.accountSettings.form,
    accountDetails: state.accountSettings,
    feProductDetails: state.feProductDetails,
    customerDetails: state.labelCart.customerDetails
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doCreateFEOrder: (param) => dispatch(actions.doCreateFEOrder(param)),
    doUpdateFEOrder: (param) => dispatch(actions.doUpdateFEOrder(param)),
    form: (result) => dispatch(actions.updateForm(result)),
    clear: () => dispatch(actions.clearFEOrder()),
    getOrderDetails: (orderNumber) =>
      dispatch(actions.getOrderDetails(orderNumber)),
    getAccountSettings: (userId) =>
      dispatch(accountActions.getAccountSettings(userId)),
    getAccountSettingsByPsn: (psn) =>
      dispatch(accountActions.getAccountSettingsByPsn(psn)),
    getProjectCountries: (locationType) =>
      dispatch(actions.getProjectCountries(locationType)),
    getEngineerCountries: (locationType) =>
      dispatch(actions.getEngineerCountries(locationType)),
    getAhjCountries: () => dispatch(actions.getAhjCountries()),
    getCustomerDetails: (psn) => dispatch(getCustomerDetails(psn)),

    // FES GSQ
    clearFesServiceProducts: () => dispatch(fesActions.clearServiceProducts()),
    clearFesServiceSubProducts: () =>
      dispatch(fesActions.clearServiceSubProducts()),
    clearFesServiceProductPrices: () =>
      dispatch(fesActions.clearServiceProductPrices()),
    clearFesOrder: () => dispatch(fesActions.clearOrder()),
    updateOrder: (idx) => dispatch(fesActions.updateOrder(idx)),

    getProjectStates: (countryCode, locationType) =>
      dispatch(actions.getProjectStates(countryCode, locationType)),
    getProjectCities: (stateCode, locationType) =>
      dispatch(actions.getProjectCities(stateCode, locationType)),

    // FE Manual Pricing trigger form updating
    updateFeForm: (result) => dispatch(feActions.updateForm(result)),
    clearFeGsq: () => dispatch(feActions.clearFeGsq()),
    clearServiceAreas: () => dispatch(feActions.clearServiceAreas()),
    clearServiceProducts: () => dispatch(feActions.clearServiceProducts()),
    clearServiceSubProducts: () =>
      dispatch(feActions.clearServiceSubProducts()),
    uploadAttachment: (data) => dispatch(actions.uploadAttachment(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetails);
