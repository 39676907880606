import Role from '../enums/role';
import jwtDecode from 'jwt-decode';
import { randomBytes, createHash } from 'crypto-browserify';
import files from './../translations/RF_en-US.json';

const REDIRECT_URL = `${process.env.REACT_APP_REDIRECT_URL}`;
const AUTH_URL = `${process.env.REACT_APP_AUTH_URL}`;
const POLICY_NAME = `${process.env.REACT_APP_B2C_POLICY_NAME}`;
const CLIENT_ID = `${process.env.REACT_APP_B2C_CLIENT_ID}`;
const LOGOUT_URL = `${process.env.REACT_APP_LOGOUT_URL}`;
const WEB_URL = `${process.env.REACT_APP_WEB_URL}`;

const getLocalStorage = data => {
  const raw = localStorage.getItem(data);
  return raw ? JSON.parse(raw) : {};
};

const getLocalPage = data => {
  const raw = localStorage.getItem(data);
  return raw;
};
function base64URLEncode(str) {
  return str
    .toString('base64')
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '');
}

function sha256(buffer) {
  return createHash('sha256').update(buffer).digest();
}
export const Common = {
  getFullName: () => {
    return `${Common.getFirstName()} ${Common.getLastName()}`;
  },

  getFirstName: () => {
    let token = getLocalStorage('token');
    let firstName = null;
    if (!Object.IsEmpty(token)) {
      let value = jwtDecode(token);
      firstName = value.given_name;
    }

    return firstName;
  },

  getLastName: () => {
    let token = getLocalStorage('token');
    let lastName = null;
    if (!Object.IsEmpty(token)) {
      let value = jwtDecode(token);
      lastName = value.family_name;
    }

    return lastName;
  },

  getInitialName: () => {
    let fName = Common.getFirstName();
    let lName = Common.getLastName();

    fName = fName && fName.length > 0 ? fName.charAt(0).toUpperCase() : '';
    lName = lName && lName.length > 0 ? lName.charAt(0).toUpperCase() : '';
    return `${fName}${lName}`;
  },

  getActivePage: () => {
    let activePage = getLocalPage('activePage');

    return activePage;
  },

  getUserId: () => {
    let token = getLocalStorage('token');
    let userId = null;
    if (!Object.IsEmpty(token)) {
      let value = jwtDecode(token);
      userId = JSON.parse(value.unique_name);
    }

    return userId;
  },

  getUserRole: () => {
    let appToken = getLocalStorage('appToken');
    let roleId = null;
    if (!Object.IsEmpty(appToken)) {
      let value = jwtDecode(appToken);
      roleId = JSON.parse(value.role);
    }

    return roleId;
  },

  getPartySiteNumber: () => {
    let appToken = getLocalStorage('appToken');
    let psn = null;
    if (!Object.IsEmpty(appToken)) {
      let value = jwtDecode(appToken);
      psn = JSON.parse(value.certserialnumber);
    }

    return psn;
  },

  getEmail: () => {
    let appToken = getLocalStorage('appToken');
    let email = null;
    if (!Object.IsEmpty(appToken)) {
      let value = jwtDecode(appToken);
      email = value.email;
    }

    return email;
  },

  getPhoneNumber: () => {
    let appToken = getLocalStorage('appToken');
    let phone = null;
    if (!Object.IsEmpty(appToken)) {
      let value = jwtDecode(appToken);
      phone = value.phone_number;
    }
    return phone;
  },

  getUserRoleFromToken: () => {
    let token = getLocalStorage('token');
    let role = null;
    if (!Object.IsEmpty(token)) {
      let value = jwtDecode(token);
      role = JSON.parse(value.role);
    }

    return role;
  },

  getIsDualRole: () => {
    let token = getLocalStorage('appToken');
    let isDualrole = null;
    if (!Object.IsEmpty(token)) {
      let value = jwtDecode(token);
      isDualrole = value.is_dual_role;
    }

    return isDualrole;
  },

  getToken: () => {
    return getLocalStorage('token');
  },

  getB2cToken: () => {
    return getLocalStorage('b2cToken');
  },

  isPhEditing: isManualPricing => {
    return isManualPricing && Common.getUserRole() === Role.projectHandler;
  },

  getSiteCount: () => getLocalStorage('sitesCount'),

  getAccountSettingsPsn: () => getLocalStorage('accountSettingsPsn'),

  getSameDayVisitFlag: () => getLocalStorage('isSameDayVisit'),

  getMyInitialName: (fName, lName) => {
    fName = fName && fName.length > 0 ? fName.charAt(0).toUpperCase() : '';
    lName = lName && lName.length > 0 ? lName.charAt(0).toUpperCase() : '';
    return `${fName}${lName}`;
  },

  getIsSuspended: () => {
    let token = getLocalStorage('token');
    let isSuspended = null;
    if (!Object.IsEmpty(token)) {
      let value = jwtDecode(token);
      isSuspended = value.is_suspended;
    }

    return isSuspended;
  },

  getIsThereApiDown: () => {
    let token = getLocalStorage('token');
    let isThereApiDown = null;
    if (!Object.IsEmpty(token)) {
      let value = jwtDecode(token);
      isThereApiDown = value.is_there_api_down;
    }
    return isThereApiDown;
  },

  getIsSuccessSetRole: () => {
    let token = getLocalStorage('token');
    let isSuccessSetRole = null;
    if (!Object.IsEmpty(token)) {
      let value = jwtDecode(token);
      isSuccessSetRole = value.is_success_set_role;
    }
    return isSuccessSetRole;
  },

  getPsnList: () => {
    let token = getLocalStorage('token');
    let psnList = null;
    if (!Object.IsEmpty(token)) {
      let value = jwtDecode(token);
      psnList = JSON.parse(value.psn_assignments);
    }

    return psnList;
  },

  getTokenExpiration: () => {
    let token = getLocalStorage('b2cToken');
    let expiration = null;
    if (!Object.IsEmpty(token)) {
      let value = jwtDecode(token);
      expiration = JSON.parse(value.exp);
    }

    return expiration;
  },

  getTokenExpirationIam: () => {
    let token = getLocalStorage('token');
    let expiration = null;
    if (!Object.IsEmpty(token)) {
      let value = jwtDecode(token);
      expiration = JSON.parse(value.exp);
    }

    return expiration;
  },

  getRefreshToken: () => {
    let refreshToken = getLocalStorage('refreshToken');
    return refreshToken;
  },

  toggleLogin: () => {
    var codeVerifier = base64URLEncode(randomBytes(32));
    var challenge = base64URLEncode(sha256(codeVerifier));
    localStorage.setItem('codeVerifier', codeVerifier);
    var redirectUri = encodeURIComponent(REDIRECT_URL);
    var externalAuthURL = AUTH_URL.replace('--CODE_CHALLENGE--', challenge)
      .replace('--REDIRECT_URI--', redirectUri)
      .replace('--POLICY_NAME--', POLICY_NAME)
      .replace('--CLIENT_ID--', CLIENT_ID);

    var a = document.createElement('a');
    a.style = 'display: none';
    a.href = externalAuthURL;
    a.target = '_self';
    a.click();
  },

  toggleSilentLogin: () => {
    var codeVerifier = base64URLEncode(randomBytes(32));
    var challenge = base64URLEncode(sha256(codeVerifier));
    localStorage.setItem('codeVerifier', codeVerifier);
    var redirectUri = encodeURIComponent(REDIRECT_URL);
    var externalAuthURL = AUTH_URL.replace('--CODE_CHALLENGE--', challenge)
      .replace('--REDIRECT_URI--', redirectUri)
      .replace('--POLICY_NAME--', POLICY_NAME)
      .replace('--CLIENT_ID--', CLIENT_ID)
      .replace('prompt=login', 'prompt=none');

    var a = document.createElement('a');
    a.style = 'display: none';
    a.href = externalAuthURL;
    a.target = '_self';
    a.click();
  },

  toggleLogout: b2cToken => {
    var logoutUrl = LOGOUT_URL.replace('--POLICY_NAME--', POLICY_NAME)
      .replace('--TOKEN_HINT--', b2cToken)
      .replace('--REDIRECT_URI--', WEB_URL);

    var a = document.createElement('a');
    a.style = 'display: none';
    a.href = logoutUrl;
    a.target = '_self';
    a.click();
  },

  getNumberWithCommas: number => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  },
  getTranslations: () => {
    let appToken = getLocalStorage('appToken');
    let translations = null;
    let terms = JSON.stringify(files);

    if (!Object.IsEmpty(appToken)) {
      let value = jwtDecode(appToken);
      translations = JSON.parse(value.translations || terms);
    } else translations = JSON.parse(terms);

    return translations;
  },
  getLanguageCode: () => {
    let appToken = getLocalStorage('appToken');
    let languageCode = null;
    if (!Object.IsEmpty(appToken)) {
      let value = jwtDecode(appToken);
      //let terms = JSON.stringify(files);
      languageCode = value.languageCode;
    }

    return languageCode;
  },

  translateStatus: (status, translations) => {
    switch (status.toLowerCase()) {
      case 'cancelled':
        return translations['LexCancelled'];
      case 'submitted':
        return translations['LexSubmitted'];
      case 'in progress':
        return translations['LexInProgress'];
      case 'inprogress':
        return translations['LexInProgress'];
      case 'on hold':
        return translations['LexOnHold'];
      case 'onhold':
        return translations['LexOnHold'];
      case 'closed':
        return translations['LexClosed'];

      default:
        return status;
    }
  },

  translateUnitOfMeasure: (measurement, translations) => {
    switch (measurement.toLowerCase()) {
      case 'ea':
        return translations['LexEa'];
      case 'rl':
        return translations['LexRL'];
      default:
        return measurement;
    }
  },
};
