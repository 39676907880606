import * as types from "./profile.type";
import api from "../../../services/api";

export const profileResult = (obj, type) => {
  return {
    type: type,
    data: obj
  };
};

export const getProfile = () => {
  return async dispatch => {
    await api.User.getProfile()
      .then(result => {
        dispatch(profileResult(result, types.PROFILE_SUCCESS));
      })
      .catch(result => {
        dispatch(profileResult(result, types.PROFILE_FAILURE));
      });
  };
};

export const clearProfile = () => dispatch =>
  dispatch(profileResult({}, types.CLEAR));
