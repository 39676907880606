import React, { Component, Fragment } from "react";
import {
  Accordion,
  Grid,
  Icon,
  Divider,
  Segment,
  Form,
  Table,
  Tab
} from "semantic-ui-react";
import {
  CustomHeaderLabel,
  CustomLabel,
  CustomHeader,
  CustomHeaderBold,
  CustomTitle
} from "../../../components/labels/labels";
import PriceItem from "../product-details/fes/price-item";
import FesPrice from "../product-details/fes/price";
import { connect } from "react-redux";
import Status from "../../../enums/status";
import Role from "../../../enums/role";
import { Common } from "../../../common";

class ProjectDetails extends Component {
  state = {
    activeIndex: -1,
    isConfirming: false,
    toRemoveId: null
  };

  toggleDisplay = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  render() {
    const { activeIndex } = this.state;
    const {
      productItems,
      reservationDetails,
      fieldEvaluation,
      siteDetails,
      forms,
      ppeSelections,
      trainingSelections,
      siteCount
    } = this.props;

    var panes = [];

    var scope = (forms.length > 0 && forms[0].productItems[0].scope) || "";

    for (let i = 0; i < siteCount; i++) {
      var orderDetails = (forms.length > 0 && forms[i].orderDetails) || null;
      var otherDetails = (forms.length > 0 && forms[i].otherDetails) || null;
      var productDetails =
        (forms.length > 0 && forms[i].productItems[0]) || null;
      var finalSiteDetails =
        forms.length > 0
          ? orderDetails.isReadyToScheduleNow
            ? forms[i].reservationDetails.finalSiteLocationSection || null
            : forms[i].siteDetails.finalInstallationSiteSection || null
          : null;
      var prelimEngineerDetails =
        (forms.length > 0 &&
          forms[i].reservationDetails.preliminarySiteLocationSection) ||
        null;
      var attachment = (forms.length > 0 && forms[i].attachment) || null;
      var ahj =
        (forms.length > 0 &&
          (forms[i].authorityHavingJurisdictionDetails || []).find(
            (s) => s.baseType == (orderDetails.isReadyToScheduleNow ? 2 : 1)
          )) ||
        null; // BaseType: 1-Project, 2-Schedulling
      var custRef =
        siteCount > 1
          ? otherDetails.customerReferenceNumber
          : orderDetails.clientReferenceNumber;
      var poNumber =
        siteCount > 1
          ? otherDetails.purchaseOrderNumber
          : orderDetails.purchaseOrderNumber;
      var scheduleDate =
        siteCount > 1
          ? otherDetails.scheduleDate
          : prelimEngineerDetails.preferredScheduleDate == null
          ? ""
          : new Date(
              prelimEngineerDetails.preferredScheduleDate
            ).toLocaleDateString();
      var scheduleTime =
        siteCount > 1
          ? otherDetails.scheduleTime
          : prelimEngineerDetails.preferredScheduleDate == null
          ? ""
          : new Date(
              prelimEngineerDetails.preferredScheduleDate
            ).toLocaleTimeString();

      panes.push({
        menuItem: `Site ${i + 1}`,
        pane: (
          <Tab.Pane key={i}>
            <table className="table-bordered" cellSpacing="0">
              <tr>
                <td colSpan="4">
                  <b>{`SITE ${i + 1}`}: Project & Scheduling Details</b>
                  <br />
                  <i>
                    the below summarizes the scope of your project and
                    scheduling details from your application:
                  </i>
                </td>
              </tr>
              <tr>
                <td>
                  <b>Customer Reference No.</b>
                </td>
                <td>{custRef}</td>
                <td>
                  <b>Customer Purchase Order No.</b>
                </td>
                <td>{poNumber}</td>
              </tr>
              <tr>
                <td style={{ width: "35%" }}>
                  <b>Requested Scheduled Date & Time:</b>
                </td>
                <td colSpan="4">
                  <table
                    className="table-child"
                    cellSpacing="0"
                    style={{ width: "50%" }}
                  >
                    <tr>
                      <th>Date:</th>
                      <th>Time:</th>
                    </tr>
                    <tr>
                      <td>{scheduleDate}</td>
                      <td>{scheduleTime}</td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <b>Equipment to be evaluated:</b>
                </td>
                <td colSpan="3">
                  <table className="table-child" cellSpacing="0">
                    <tr>
                      <th>Equipment Type</th>
                      <th># of units (Ea)</th>
                      <th>Property Type</th>
                    </tr>
                    <tr>
                      <td>
                        {productDetails && productDetails.serviceSubProductName}
                      </td>
                      <td>{productDetails && productDetails.quantity}</td>
                      <td>
                        {productDetails && productDetails.propertyDescription}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <b>Final Installation Site:</b>
                </td>
                <td colSpan="3">
                  {finalSiteDetails && finalSiteDetails.residenceName}
                  <br />
                  {finalSiteDetails && finalSiteDetails.addressLine1}
                  <br />
                  {finalSiteDetails && finalSiteDetails.city}{" "}
                  {finalSiteDetails && finalSiteDetails.state}
                  <br />
                  {finalSiteDetails && finalSiteDetails.country},{" "}
                  {finalSiteDetails && finalSiteDetails.postalCode}
                </td>
              </tr>
              <tr>
                <td>
                  <b>Site Contact Details:</b>
                </td>
                <td colSpan="3">
                  <table className="table-child" cellSpacing="0">
                    <tr>
                      <th>Contact Name:</th>
                      <td>
                        {finalSiteDetails && finalSiteDetails.siteContactName}
                      </td>
                    </tr>
                    <tr>
                      <th>Office:</th>
                      <td>
                        {finalSiteDetails &&
                          finalSiteDetails.siteContactOfficeNumber}
                      </td>
                    </tr>
                    <tr>
                      <th>Mobile:</th>
                      <td>
                        {finalSiteDetails &&
                          finalSiteDetails.siteContactMobileNumber}
                      </td>
                    </tr>
                    <tr>
                      <th>Email:</th>
                      <td>
                        {finalSiteDetails &&
                          finalSiteDetails.siteContactEmailAddress}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <b>Site Specific Requirements:</b>
                </td>
                <td colSpan="3">
                  <table className="table-child" cellSpacing="0">
                    <tr>
                      <th>Site Training:</th>
                      <td>
                        <ul>
                          {trainingSelections &&
                            trainingSelections.map((item, idx) => {
                              if (
                                prelimEngineerDetails &&
                                prelimEngineerDetails.trainingSelections &&
                                prelimEngineerDetails.trainingSelections.indexOf(
                                  item.value
                                ) !== -1
                              )
                                if (item.value === 3) {
                                  return (
                                    <li key={idx}>
                                      {item.text}:{" "}
                                      {
                                        prelimEngineerDetails.otherTrainingDetails
                                      }
                                    </li>
                                  );
                                } else {
                                  return <li key={idx}>{item.text}</li>;
                                }
                            })}
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <th>Security Steps:</th>
                      <td>
                        {prelimEngineerDetails &&
                          prelimEngineerDetails.securityStepDetail}
                      </td>
                    </tr>
                    <tr>
                      <th>Other Site Requirements:</th>
                      <td>
                        {prelimEngineerDetails &&
                          prelimEngineerDetails.otherSiteRequirements}
                      </td>
                    </tr>
                    <tr>
                      <th>Required PPE:</th>
                      <td>
                        <ul>
                          {ppeSelections &&
                            ppeSelections.map((item, idx) => {
                              if (
                                prelimEngineerDetails &&
                                prelimEngineerDetails.ppeSelections &&
                                prelimEngineerDetails.ppeSelections.indexOf(
                                  item.value
                                ) !== -1
                              )
                                return <li key={idx}>{item.text}</li>;
                            })}
                        </ul>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td>
                  <b>Authority Having Jurisdiction</b>
                </td>
                <td colSpan="3">
                  {ahj && ahj.jurisdictionName}
                  <br />
                  {ahj && ahj.addressLine1} {ahj && ahj.city} {ahj && ahj.state}{" "}
                  {ahj && ahj.country}, {ahj && ahj.postalCode}
                  <br />
                  {ahj && ahj.addressLine2}
                  <br />
                  {ahj && ahj.addressLine3}
                  <br />
                  Permit # {ahj && ahj.permitNo}
                </td>
              </tr>
              <tr>
                <td>
                  <b>Inspection Contact Details</b>
                </td>
                <td colSpan="3">
                  {ahj && ahj.inspectorName}
                  <br />
                  {ahj && ahj.inspectorOfficeNumber}
                  <br />
                  {ahj && ahj.inspectorMobileNumber}
                  <br />
                  {ahj && ahj.inspectorEmailAddress}
                </td>
              </tr>
              <tr>
                <td>
                  <b>Attachment</b>
                </td>
                <td colSpan="3">
                  <a href={attachment && attachment.filePath} target="_blank">
                    {attachment && attachment.originalFileName}
                  </a>
                </td>
              </tr>
            </table>
          </Tab.Pane>
        )
      });
    }
    return (
      <Fragment>
        <Segment>
          <Accordion>
            <Accordion.Title
              active={activeIndex === 0}
              index={0}
              onClick={this.toggleDisplay}
            >
              <Grid columns={1}>
                <Grid.Column width={16}>
                  <Icon name="dropdown" />
                  <CustomHeaderLabel message="Project Summary Details" />
                </Grid.Column>
              </Grid>
            </Accordion.Title>
            <Accordion.Content
              active={activeIndex === 0}
              className="print-expanded"
            >
              <Divider />
              <Form>
                <Grid>
                  <Grid.Column width={3}>
                    <p>
                      Please review your Project & Scheduling Details Summary
                      for accuracy. Should you need to make changes, you can do
                      so by selecting “edit” on the quote actions button found
                      in the upper left hand corner. If you require assistance,
                      please contact us at{" "}
                      <a href="tel:877.854.3577">1-877-854-3577</a>, prompt # 2
                      or <a href="mailto:field@ul.com">field@ul.com</a> and
                      reference your Quote (enter tool quote# here). Thank you!
                    </p>
                  </Grid.Column>
                  <Grid.Column width={1}>
                    <Divider vertical />
                  </Grid.Column>
                  <Grid.Column width={12}>
                    <p>
                      <b>Project Scope:</b> {scope}
                    </p>
                    <Tab
                      renderActiveOnly={false}
                      panes={panes}
                      //onTabChange={this.handleSiteTabChange}
                    />
                  </Grid.Column>
                </Grid>
              </Form>
            </Accordion.Content>
          </Accordion>
        </Segment>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    fieldEvaluation: state.fieldEvaluation
  };
};

export default connect(mapStateToProps, null)(ProjectDetails);
