import * as types from "./product-details.types";
import api from "../../../../services/api";
import { Common } from "../../../../common";
import Role from "../../../../enums/role";

export const productDetailsResult = (obj, type) => {
  return {
    data: obj,
    type: type
  };
};

export const updateForm = (result) => {
  return (dispatch) => dispatch(productDetailsResult(result, types.MYFORM));
};

export const getAreas = () => {
  return async (dispatch) => {
    await api.FieldEvaluation.getArea()
      .then((result) => {
        dispatch(productDetailsResult(result, types.AREA_REQUEST));
      })
      .catch((result) => {
        dispatch(productDetailsResult(result, types.AREA_FAILURE));
      });
  };
};

export const getServiceAreas = (areaId) => {
  return async (dispatch) => {
    await api.FieldEvaluation.getServiceArea(areaId)
      .then((result) => {
        dispatch(productDetailsResult(result, types.SERVICE_AREA_REQUEST));
      })
      .catch((result) => {
        dispatch(productDetailsResult(result, types.SERVICE_AREA_FAILURE));
      });
  };
};

export const clearServiceAreas = () => (dispatch) =>
  dispatch(productDetailsResult({}, types.SERVICE_AREA_CLEAR));

export const getServiceProducts = (serviceType) => {
  return async (dispatch) => {
    return await api.FieldEvaluation.getServiceProducts(serviceType)
      .then((result) => {
        dispatch(productDetailsResult(result, types.SERVICE_PRODUCT_REQUEST));
        return result;
      })
      .catch((result) => {
        dispatch(productDetailsResult(result, types.SERVICE_PRODUCT_FAILURE));
      });
  };
};

export const clearServiceProducts = () => (dispatch) =>
  dispatch(productDetailsResult({}, types.SERVICE_PRODUCT_CLEAR));

export const getServiceSubProducts = (serviceProductId) => {
  return async (dispatch) => {
    return await api.FieldEvaluation.getServiceSubProducts(serviceProductId)
      .then((result) => {
        dispatch(
          productDetailsResult(result, types.SERVICE_SUB_PRODUCT_REQUEST)
        );
        return result;
      })
      .catch((result) => {
        dispatch(
          productDetailsResult(result, types.SERVICE_SUB_PRODUCT_FAILURE)
        );
      });
  };
};

export const clearServiceSubProducts = () => (dispatch) =>
  dispatch(productDetailsResult({}, types.SERVICE_SUB_PRODUCT_CLEAR));

// FE PRICE
export const getServiceProductPrice = (
  serviceAreaId,
  serviceSubProductId,
  propertyType,
  quantity,
  scheduleDate,
  siteCharge
) => {
  return async (dispatch) => {
    await api.FieldEvaluation.getServiceProductPrice(
      serviceAreaId,
      serviceSubProductId,
      propertyType,
      quantity,
      scheduleDate,
      siteCharge
    )
      .then((result) => {
        dispatch(productDetailsResult(result, types.PRODUCT_PRICE_REQUEST));
      })
      .catch((result) => {
        dispatch(productDetailsResult(result, types.PRODUCT_PRICE_FAILURE));
      });
  };
};

export const clearProductPrices = () => (dispatch) =>
  dispatch(productDetailsResult({}, types.PRODUCT_PRICE_CLEAR));

export const clearFeGsq = () => (dispatch) =>
  dispatch(productDetailsResult({}, types.CLEAR));

const filterValidation = (rules, name) => {
  return rules.filter(([r, n]) => {
    return n !== name;
  });
};

export const getValidation = (state, rules) => {
  let copyRules = rules.slice(0);
  const {
    quantity,
    isPanelboard,
    isSwitchboard,
    isInvolvedLitigation,
    isManualPricing
  } = state;

  // removed scope validation, for multi only
  copyRules = filterValidation(copyRules, "scope");

  // qty < 2, remove isInstalledSameLocation & isEvaluatedSameVisit validations
  if (parseInt(quantity) < 2) {
    copyRules = filterValidation(copyRules, "isInstalledSameLocation");
    copyRules = filterValidation(copyRules, "isEvaluatedSameVisit");
  }

  // pnl, remove rated over 600 VAC validation
  if (isPanelboard)
    copyRules = filterValidation(copyRules, "switchboardOver600");

  // swb, remove rated over 200A validation
  if (isSwitchboard)
    copyRules = filterValidation(copyRules, "panelboardOver200");

  // if litigation no, remove litigation description validation
  //if (!isInvolvedLitigation)
  //  copyRules = filterValidation(copyRules, "litigationDescription");

  // PH edit total price
  const userRole = Common.getUserRole();
  if (
    userRole != Role.projectHandler ||
    (userRole == Role.projectHandler && !isManualPricing)
  )
    copyRules = filterValidation(copyRules, "totalPriceByPh");

  return copyRules.map((s) => s[0]);
};

export const getProductDetails = (orderDetails) => {
  return async (dispatch) => {
    dispatch(productDetailsResult({}, types.GET_PRODUCT_DETAILS_REQUEST));
    await dispatch(
      productDetailsResult(orderDetails, types.GET_PRODUCT_DETAILS_SUCCESS)
    );
  };
};
