import React from 'react';
import { Route, BrowserRouter, Switch } from 'react-router-dom';
import Welcome from '../views/welcome/welcome';
import DashboardSelection from '../views/dashboard/dashboard-selection';
import Register from '../views/register/register';
import RegisterSuccess from '../views/register/register-success';
import ResetPassword from '../views/reset-password/reset-password';
import ResetPasswordRequest from '../views/reset-password/reset-password-request';
import ResetPasswordValidate from '../views/reset-password/reset-password-validate';
import ResendEmailVerification from '../views/user/verify-email/resend-email-verification';
import VerifyEmail from '../views/user/verify-email/verify-email';
import Auth from '../views/auth/auth';
import Main from '../views/main/main';
import ErrorPage from '../views/error-page/error-page';
import SuspendedPage from '../views/error-page/suspended-page';
import UnavailablePage from '../views/error-page/unavailable-page';
import CallBack from '../views/callback/callback';
import ApiDownPage from '../views/error-page/api-down-page';
import RoleNotFoundPage from '../views/error-page/role-not-found-page';

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Main} />
        {/* <Route exact path="/" component={Login} /> */}
        <Route path="/dashboard-selection" component={DashboardSelection} />
        <Route path="/home" component={Welcome} />
        <Route exact path="/register" component={Register} />
        <Route path="/register/success" component={RegisterSuccess} />
        <Route
          path="/reset-password/request"
          component={ResetPasswordRequest}
        />
        <Route
          path="/reset-password/validate/:token*"
          component={ResetPasswordValidate}
        />
        <Route path="/reset-password" component={ResetPassword} />
        <Route
          path="/resend-email-verification"
          component={ResendEmailVerification}
        />
        <Route path="/verify-email/token/:token*" component={VerifyEmail} />
        <Route path="/token/:token*" component={Auth} />
        <Route path="/error-page" component={ErrorPage} />
        <Route path="/suspended-page" component={SuspendedPage} />
        <Route path="/unavailable-page" component={UnavailablePage} />
        <Route path="/callback" component={CallBack} />
        <Route path="/api-down-page" component={ApiDownPage} />
        <Route path="/role-not-found-page" component={RoleNotFoundPage} />
        <Route path="*">Not Found</Route>
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
