import * as types from "./reset-password.type";

let initialState = {
  data: [],
  form: {
    showErrors: false,
    validationErrors: {}
  }
};

const resetPasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
    case types.RESET_PASSWORD_REQUEST_REQUEST:
    case types.RESET_PASSWORD_REQUEST_SUCCESS:
    case types.RESET_PASSWORD_REQUEST_FAILURE:
    case types.RESET_PASSWORD_VALIDATE_REQUEST:
    case types.RESET_PASSWORD_VALIDATE_SUCCESS:
    case types.RESET_PASSWORD_VALIDATE_FAILURE:
    case types.RESET_PASSWORD_REQUEST:
    case types.RESET_PASSWORD_SUCCESS:
    case types.RESET_PASSWORD_FAILURE:
      return Object.assign({}, state, {
        data: action.data || initialState.data
      });
    case types.FORM:
      return Object.assign({}, state, {
        form: action.data || initialState.form
      });
    case types.CLEAR:
      return Object.assign({}, state, {
        data: initialState.data
      });
  }
};

export default resetPasswordReducer;
