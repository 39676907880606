import React, { Component, Fragment } from 'react';
import { Label, Table, Image, Checkbox } from 'semantic-ui-react';
import { uploadTemplate } from '../../../enums/upload-type';

class ViewLabelsItem extends Component {
  state = {};

  handleDateFormat = dateUpdate => {
    var retDate = '';
    if (dateUpdate) {
      retDate = new Date(dateUpdate).toLocaleDateString();
    }
    return retDate.toString();
  };
  render() {
    const { item, uploadType, selectItemNumber } = this.props;

    return (
      <Fragment>
        <Table.Row active>
          {uploadType ===
            uploadTemplate.combinationLabelEligibleCcnsTemplate && (
            <Fragment>
              <Table.Cell style={{ width: '10%' }}>{item['CCN']}</Table.Cell>

              <Table.Cell style={{ width: '10%' }}>
                {item['Service Type']}
              </Table.Cell>
              <Table.Cell style={{ width: '10%' }}>{item['Status']}</Table.Cell>
            </Fragment>
          )}
          {uploadType ===
            uploadTemplate.authorizedLabelSupplierEmailTemplate && (
            <Fragment>
              <Table.Cell style={{ width: '10%' }}>
                {item['Printer File Number']}
              </Table.Cell>

              <Table.Cell style={{ width: '10%' }}>
                {item['Printer PSN']}
              </Table.Cell>
              <Table.Cell style={{ width: '10%' }}>
                {item['Email Address']}
              </Table.Cell>
              <Table.Cell style={{ width: '5%' }}>
                {item['Email Status']}
              </Table.Cell>
              <Table.Cell style={{ width: '5%' }}>
                {item['Updated By']}
              </Table.Cell>
            </Fragment>
          )}
          {uploadType === uploadTemplate.standardLabelManagementTemplate && (
            <Fragment>
              <Table.Cell style={{ width: '5%' }}>
                {item['Label Acct']}
              </Table.Cell>
              <Table.Cell style={{ width: '5%' }}>{item['CCN']}</Table.Cell>
              <Table.Cell style={{ width: '15%' }}>
                {item['Label Description']}
              </Table.Cell>
              <Table.Cell style={{ width: '5%' }}>
                {item['Item Number']}
              </Table.Cell>
              <Table.Cell style={{ width: '10%' }}>
                <Image src={item['Image URL']} size="small" />
              </Table.Cell>
              <Table.Cell style={{ width: '5%' }}>
                {item['Label Order Package']}
              </Table.Cell>
              <Table.Cell style={{ width: '5%' }}>
                {item['Min. Order Qty']}
              </Table.Cell>
              <Table.Cell style={{ width: '5%' }}>
                {item['ORACLE UOM']}
              </Table.Cell>
              <Table.Cell style={{ color: 'gray', width: '2%' }}>
                <Label style={{ color: 'gray' }}>Active</Label>
              </Table.Cell>
            </Fragment>
          )}
          {uploadType ===
            uploadTemplate.combinationLabelItemNumbersTemplate && (
            <Fragment>
              <Table.Cell style={{ width: '15%' }}>
                {item['Appl Account#']}
              </Table.Cell>
              <Table.Cell style={{ width: '15%' }}>
                {item['Mfr Account#']}
              </Table.Cell>
              <Table.Cell style={{ width: '10%' }}>
                {item['Basic File#']}
              </Table.Cell>
              <Table.Cell style={{ width: '6.1%' }}>
                {item['Item Number']}
              </Table.Cell>
              <Table.Cell style={{ width: '6.1%' }}>
                {item['Item Description']}
              </Table.Cell>
              <Table.Cell style={{ width: '6.1%' }}>
                {item['Customer Part No.']}
              </Table.Cell>
              <Table.Cell style={{ color: 'gray', width: '3%' }}>
                <Label style={{ color: 'gray' }}>{item['Status']}</Label>
              </Table.Cell>
              <Table.Cell style={{ width: '6.1%' }}>
                {item['Updated By']}
              </Table.Cell>
              <Table.Cell style={{ width: '6.1%' }}>
                {this.handleDateFormat(item['Updated Date'])}
              </Table.Cell>
              <Table.Cell>
                <Checkbox
                  style={{
                    border: '1px solid black',
                    padding: '0px',
                    height: '21px',
                    width: '21px',
                  }}
                  checked={item.checked}
                  onChange={(e, { checked }) => {
                    selectItemNumber(item['Id'], checked);
                  }}
                />
              </Table.Cell>
            </Fragment>
          )}
          {uploadType === uploadTemplate.priceListManagementTemplate && (
            <Fragment>
              <Table.Cell style={{ width: '10%' }}>
                {item['Item Number']}
              </Table.Cell>
              <Table.Cell style={{ width: '40%' }}>
                {item['Label Description']}
              </Table.Cell>
              <Table.Cell style={{ width: '10%' }}>{item['UOM']}</Table.Cell>
              <Table.Cell style={{ width: '5%' }}>
                {item['Unit Price']}
              </Table.Cell>
              <Table.Cell style={{ color: 'gray', width: '5%' }}>
                <Label style={{ color: 'gray' }}>Active</Label>
              </Table.Cell>
            </Fragment>
          )}
          {uploadType === uploadTemplate.courierTrackingNumbersTemplate && (
            <Fragment>
              <Table.Cell style={{ width: '40%' }}>
                {item['Oracle Order Number']}
              </Table.Cell>
              <Table.Cell style={{ width: '40%' }}>
                {item['Tracking Number']}
              </Table.Cell>
              <Table.Cell style={{ width: '20%' }}>
                {item['Courier']}
              </Table.Cell>
            </Fragment>
          )}
          {uploadType === uploadTemplate.authorizedNdLabelTemplate && (
            <Fragment>
              <Table.Cell>{item['Manufacturer Account#']}</Table.Cell>
              <Table.Cell>{item['Manufacturer Customer Name']}</Table.Cell>
              <Table.Cell>{item['Status']}</Table.Cell>
            </Fragment>
          )}
          {uploadType === uploadTemplate.standardLabelHandlingFeeTemplate && (
            <Fragment>
              <Table.Cell>{item['Currency']}</Table.Cell>
              <Table.Cell>{item['Handling Fee']}</Table.Cell>
              <Table.Cell>{item['Updated By']}</Table.Cell>
              <Table.Cell>{item['Updated Date']}</Table.Cell>
            </Fragment>
          )}
        </Table.Row>
      </Fragment>
    );
  }
}

export default ViewLabelsItem;
