import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Container, Card, Button, Form } from "semantic-ui-react";
import _ from "lodash";

import HeaderBlock from "../../components/header/header-block";
import FooterBlock from "../../components/footer/footer-block";
import { CustomTitleLabel } from "../../components/labels/labels";
import { resetPasswordCard } from "../../assets/js/common";
import {
  validate,
  getError,
  validateField,
  createValidation
} from "../../validator/validator";
import { required, email } from "../../validator/error-message";
import * as actions from "./reset-password.action";
import ResetPasswordMessage from "./reset-password-message";

const rules = [validate("email", "Email", required, email)];

class ResetPasswordRequest extends Component {
  componentWillUnmount() {
    this.props.clear();
  }

  handleResetPassword = () => {
    const { form } = this.props.resetPassword;

    form.validationErrors = createValidation(form, rules);

    if (Object.IsEmpty(form.validationErrors))
      return this.props.doResetPasswordRequest(
        _.omit(form, ["showErrors", "validationErrors"])
      );

    form.showErrors = true;
    this.props.form(form);

    return false;
  };

  handleChange = e => {
    let { form } = this.props.resetPassword;
    const newState = validateField(rules, form, e.target.name, e.target.value);
    this.props.form(newState);
  };

  getErrorFor(field) {
    const { form, data } = this.props.resetPassword;
    let errors = getError(field, form, data);
    return errors;
  }

  render() {
    // TODO: Fix this bgcolor
    document.getElementById("root").style.backgroundColor = "#0b3c61";
    const { form, data } = this.props.resetPassword;

    return data.isSuccessful ? (
      <ResetPasswordMessage
        header={data.data.header}
        message={data.data.message}
      />
    ) : (
      <Fragment>
        <HeaderBlock />
        <Container style={resetPasswordCard}>
          <Card fluid>
            <Card.Content>
              <Card.Header>Reset your password</Card.Header>
              <br />
              <CustomTitleLabel
                message="Need to reset your password? We will send you a temporary link
                to set a new one."
              />
              <br />
              <br />
              <a href="/">Never mind, take me back to the login.</a>
              <br />
              <br />
              <Form>
                <Form.Field>
                  <Form.Input
                    label="Email address"
                    name="email"
                    autoComplete="off"
                    placeholder="Email address"
                    onChange={this.handleChange.bind(this)}
                    value={form.email || ""}
                    error={this.getErrorFor("email")}
                  />
                </Form.Field>
              </Form>
            </Card.Content>
            <Card.Content extra textAlign="right">
              <Button
                onClick={this.handleResetPassword.bind(this)}
                color="blue"
              >
                Send reset link
              </Button>
            </Card.Content>
          </Card>
        </Container>
        <FooterBlock />
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    resetPassword: state.user.resetPassword
  };
};

const mapDispatchToProps = dispatch => {
  return {
    doResetPasswordRequest: param =>
      dispatch(actions.doResetPasswordRequest(param)),
    form: result => dispatch(actions.updateForm(result)),
    clear: () => dispatch(actions.clearResetPassword())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPasswordRequest);
