import PropTypes from "prop-types";
import React, { Component } from "react";
import { Table } from "semantic-ui-react";

import ScopeValue from "./scope-value";

class Scope extends Component {
  render() {
    const { forms } = this.props;
    const scopeManual = forms.find((form) => form.tabIndex === 0);

    return (
      <Table attached>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell textAlign="center">SCOPE</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell verticalAlign="top">
              {(scopeManual || {}).scope || (
                <b>{ScopeValue.scopeValueMulti(forms)}</b>
              )}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  }
}

Scope.propTypes = {
  forms: PropTypes.array.isRequired
};

export default Scope;
