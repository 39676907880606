import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import {
  Button,
  Confirm,
  Container,
  Dimmer,
  Grid,
  Header,
  Loader,
  Message,
  Modal,
  Segment
} from "semantic-ui-react";
import CustomerInformation from "./customer-information";
import AdditionalComments from "./additional-comments";
import LabelOrderProduction from "./label-order-production";
import FooterHomeBlock from "../../../components/footer/footer-home-block";
import CombinationLabelOrderItem from "./combination-label-order-item";
import {
  changeOrderCartItemValue,
  changeOrderCartValue,
  confirmSaveOrderRequest,
  confirmSaveOrderAccept,
  confirmSaveOrderReject,
  getLabelOrderCart,
  getUnitsOfMeasurement,
  getLabelSuppliers,
  getLabelCenters,
  getCustomerInfo,
  getCustomerBillToAddresses,
  getCustomerShipToAddresses,
  initialize,
  removeLabelOrderCartItem,
  removeRemoveItemMessage,
  removeSaveOrderMessage,
  updateLabelOrderCart,
  resetLabelOrderCart,
  uploadCartItemAttachment,
  updateForm
} from "./label-order-cart.action";
import {
  getCustomerDetails,
  cancelOrder
} from "../../../views/manufacturer/standard/label-cart/label-cart.action";

import {
  validate,
  getError,
  validateField,
  createValidation
} from "../../../validator/validator";
import { required, email, numberOnly } from "../../../validator/error-message";
import * as actions from "../../../views/field-evaluation/account-settings/account-settings.action";
import { Common } from "../../../common";
import { toast } from "react-semantic-toasts";
import * as commonActions from "../../../views/user/common/common.action";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2
});

const rules = [
  validate("customerPoNumber", "Purchase order number", required),
  validate("labelCenterId", "UL Solutions center", required),
  validate("labelSupplierId", "Authorized label supplier", required),
  validate("billToContactName", "Bill To Contact Name", required),
  validate("billToAddress", "Bill To Address", required),
  validate("shipToContactName", "Manufacturer Contact Name", required),
  // validate("shipToAddress", "Ship To Address", required),
  validate("manufacturerName", "Manufacturer Name", required),
  validate(
    "manufacturerAccountNumber",
    "Manufacturer Account Number",
    required
  ),
  validate("manufacturerAddress", "Manufacturer Address", required),
  validate("customerEmailAddress", "Customer Email Address", required, email),
  validate(
    "customerPhoneNumber",
    "Customer Phone Address",
    required,
    numberOnly
  )
];

class LabelOrderCart extends Component {
  state = {
    showErrors: false,
    isSucessCustomer: false,
    isCustomerRelationEmpty: true,
    isCustomerRelationShipToEmpty: true,
    isCompanyEnable: true,
    isAddressEnable: true,
    isCompanyShipToEnable: true,
    isAddressShipToEnable: true,
    isLoadCustomerRelation: false,
    isLoadCustomerRelationShipTo: false,
    open: false
  };

  customerId = localStorage.getItem("customerId");

  componentDidMount() {
    this.props.initialize();
    this.props.getCustomerInfo(this.customerId);
    this.props.getLabelOrderCart();
    this.props.getUnitsOfMeasurement();
    this.props.getLabelSuppliers();
    this.props.getLabelCenters();
    this.props.getCustomerBillToAddresses(this.customerId);
    this.props.getCustomerShipToAddresses(this.customerId);
    this.props.getCustomerDetails(Common.getPartySiteNumber());
    this.props.handleHeaderLabel();
  }

  handleConfirm = () => {
    this.props.confirmSaveOrderAccept();
    const model = {
      orderCart: {
        id: this.props.data.id,
        ...this.props.labelOrderCart.form,
        cartItems: this.props.data.cartItems
      }
    };
    // upload attachments
    this.props.data.cartItems
      .filter((item) => item.file != null)
      .forEach((item) => {
        const formData = new FormData();
        formData.append("file", item.file);
        formData.append("labelOrderCartItemId", item.id);
        this.props.uploadCartItemAttachment(formData);
      });

    // update label order cart
    this.props.updateLabelOrderCart(model);
    setTimeout(
      () => this.props.removeSaveOrderMessage(),
      this.props.messageTimeout
    );
  };

  handleCancel = () => {
    this.props.confirmSaveOrderReject();
  };

  handleHeaderLabel = () => {
    const { labelCart } = this.props;
    if (labelCart.data && labelCart.data.length > 0) {
      if (labelCart.data[0].labelOrderType == 1) {
        this.props.getActivePage("Standard Label Order");
      } else {
        this.props.getActivePage("Combination Label Order");
      }
    } else {
      this.props.getActivePage("Order Cart");
    }
  };
  removeLabelOrderCartItem = (id) => {
    this.props.removeLabelOrderCartItem(id);
    setTimeout(
      () => this.props.removeRemoveItemMessage(),
      this.props.messageTimeout
    );
  };

  handleReviewCart = () => {
    const { form } = this.props.labelOrderCart;

    form.validationErrors = createValidation(form, rules);

    return this.saveOrder();
  };

  saveOrder = () => {
    const { form } = this.props.labelOrderCart;

    if (Object.IsEmpty(form.validationErrors)) {
      this.setState({ showErrors: false });
      this.props.confirmSaveOrderRequest();
    } else {
      this.setState({ showErrors: true });
    }
  };

  getErrorFor = (field) => {
    const { form, data } = this.props.labelOrderCart;
    let errors = getError(field, form, data);
    return errors;
  };

  changeOrderCartValue = (e) => {
    let { form } = this.props.labelOrderCart;
    const newState = validateField(rules, form, e.target.name, e.target.value);
    this.props.updateForm(newState);
  };

  changeOrderCartDropdownValue = (e, properties) => {
    let { form } = this.props.labelOrderCart;

    if (properties.name === "labelSupplierId")
      form.labelSupplierName = e.target.innerText;

    if (properties.name == "labelCenterId")
      form.labelCenterName = e.target.innerText;

    const newState = validateField(
      rules,
      form,
      properties.name,
      properties.value
    );

    this.props.updateForm(newState);
  };

  handleLoadAccountDetails = (accountNumber) => {
    this.props.getCustomerRelations(accountNumber, "true");
  };
  handleLoadAccountDetailsShipTo = (accountNumber) => {
    this.props.getCustomerRelationsShipTo(accountNumber, "false");
  };

  handleAccntNumDorpDownChange = (properties) => {
    let { form } = this.props.labelOrderCart;
    var add1;
    var add2;
    var add3;
    var add4;
    var accountRecord = this.props.customerRelationsData.find(function (item) {
      return item.accountNumber == properties.value;
    });

    form.billToCompany = accountRecord.accountName;
    form.billToAccountNo = accountRecord.accountNumber;
    add1 = accountRecord.address1;
    add2 = accountRecord.address2 ? ", " + accountRecord.address2 : "";
    add3 = accountRecord.address3 ? ", " + accountRecord.address3 : "";
    add4 = accountRecord.address4 ? ", " + accountRecord.address4 : "";
    form.billToAddress = add1 + add2 + add3 + add4;
    form.billToAddress = form.billToAddress.replace(/(^,)|(,$)/g, "");
    form.billToCountry = accountRecord.country;

    if (form.billToCompany) {
      this.setState({ isCompanyEnable: false });
    }
    if (form.billToAddress) {
      this.setState({ isAddressEnable: false });
    }

    this.props.getCustomerRelationInformations(accountRecord.accountNumber);

    const newState = validateField(
      rules,
      form,
      properties.name,
      properties.value
    );

    this.props.updateForm(newState);
  };

  handleAccntNumShipToDropDownChange = (properties) => {
    let { form } = this.props.labelOrderCart;
    var add1;
    var add2;
    var add3;
    var add4;
    var accountRecord = this.props.customerRelationsShipToData.find(function (
      item
    ) {
      return item.accountNumber == properties.value;
    });

    form.manufacturerName = accountRecord.accountName;
    form.manufacturerAccountNumber = accountRecord.accountNumber;
    form.manufacturerPartySiteNumber = accountRecord.partySiteNumber;
    add1 = accountRecord.address1;
    add2 = accountRecord.address2 ? ", " + accountRecord.address2 : "";
    add3 = accountRecord.address3 ? ", " + accountRecord.address3 : "";
    add4 = accountRecord.address4 ? ", " + accountRecord.address4 : "";
    form.manufacturerAddress = add1 + add2 + add3 + add4;
    form.manufacturerAddress = form.manufacturerAddress.replace(
      /(^,)|(,$)/g,
      ""
    );
    form.shipToCountry = accountRecord.country;

    if (form.manufacturerName) {
      this.setState({ isCompanyShipToEnable: false });
    }
    if (form.manufacturerAddress) {
      this.setState({ isAddressShipToEnable: false });
    }

    this.props.getCustomerRelationInformationsShipTo(
      accountRecord.accountNumber
    );

    const newState = validateField(
      rules,
      form,
      properties.name,
      properties.value
    );

    this.props.updateForm(newState);
  };

  handleDeleteCart = () => {
    this.setState({ open: true });
  };

  handleCancelDelete = () => {
    this.setState({ open: false });
  };

  handleConfirmDelete = () => {
    this.setState({ open: false });
    const { labelOrderCart } = this.props;
    const { data } = labelOrderCart;
    var model = { labelOrderCartId: data.id };

    this.props.cancelOrder(model).then((result) => {
      if (result) {
        setTimeout(() => {
          toast({
            title: "",
            description: (
              <p>
                Your order has been cancelled. You may go back to the
                file/volumes page and order again.
              </p>
            ),
            time: 10000,
            size: "small",
            icon: "check",
            color: "green"
          });
        }, 100);

        this.props.getLabelOrderCart();
      }
    });
  };

  render() {
    const {
      data,
      customerInfo,
      loadingUpdateCart,
      saveOrderSuccess,
      saveOrderFailed,
      removeItemSuccess,
      removeItemFailed,
      loadingCustomerInfo,
      order,
      labelCenters,
      loadingLabelCenters,
      labelSuppliers,
      loadingLabelSuppliers,
      loadingCustomerBillToAddresses,
      loadingCustomerShipToAddresses,
      unitsOfMeasurement,
      changeOrderCartItemValue,
      customerDetails,
      isSuccessGetCartItems,
      accountNumbersOptions,
      customerRelationsData,
      accountNumbersShipToOptions,
      customerRelationsShipToData,
      customerRelationInformationsOptions,
      customerRelationInformationsData,
      customerRelationInformationsShiptToOptions,
      customerRelationInformationsShiptToData,
      isLoading,
      labelCartData
    } = this.props;
    const { form } = this.props.labelOrderCart;
    const hasCartItems = data && data.cartItems && data.cartItems.length > 0;
    const custDetails = customerDetails.data;
    const shipToDetails = customerDetails.data
      ? customerDetails.data.shipToAddress
      : null;
    const {
      isSucessCustomer,
      isCustomerRelationEmpty,
      isCustomerRelationShipToEmpty,
      accntNumber,
      accntNumberShipTo,
      isCompanyEnable,
      isAddressEnable,
      isCompanyShipToEnable,
      isAddressShipToEnable,
      isLoadCustomerRelation,
      isLoadCustomerRelationShipTo,
      open
    } = this.state;

    if (form && form.billToAccountNo && !isLoadCustomerRelation) {
      this.props.getCustomerRelationInformations(form.billToAccountNo);
      this.setState({ isLoadCustomerRelation: true });
    }
    if (
      form &&
      form.manufacturerAccountNumber &&
      !isLoadCustomerRelationShipTo
    ) {
      this.props.getCustomerRelationInformationsShipTo(
        form.manufacturerAccountNumber
      );
      this.setState({ isLoadCustomerRelationShipTo: true });
    }

    if (isSuccessGetCartItems && !hasCartItems)
      localStorage.setItem("cartLabelOrderType", null);

    if (saveOrderSuccess) {
      this.props.resetLabelOrderCart();
      return <Redirect to="/home/generic-label-order-cart-review" />;
    }
    if (custDetails) {
      if (custDetails.accountNumber && !isSucessCustomer) {
        this.setState({ isSucessCustomer: true });
        this.handleLoadAccountDetails(custDetails.accountNumber);
        this.handleLoadAccountDetailsShipTo(custDetails.accountNumber);
      }
    }
    if (
      accountNumbersOptions &&
      accountNumbersOptions.length > 0 &&
      isCustomerRelationEmpty
    ) {
      this.setState({ isCustomerRelationEmpty: false });
    }
    if (
      accountNumbersShipToOptions &&
      accountNumbersShipToOptions.length > 0 &&
      isCustomerRelationShipToEmpty
    ) {
      this.setState({ isCustomerRelationShipToEmpty: false });
    }
    return (
      <Fragment>
        <div style={{ width: "60%", padding: "20px" }}>
          <Dimmer.Dimmable as={Container} dimmed={loadingUpdateCart} fluid>
            <Message
              hidden={!saveOrderSuccess}
              success
              header="Success"
              content="Changes to the order have been saved."
            />

            <Message
              hidden={!saveOrderFailed}
              error
              header="Failed"
              content="Changes to the order are not yet saved. Please try again."
            />

            <Message
              hidden={!removeItemSuccess}
              success
              header="Success"
              content="Order item has been removed."
            />

            <Message
              hidden={!removeItemFailed}
              error
              header="Failed"
              content="Order item was not removed. Please try again."
            />

            <Dimmer active={loadingUpdateCart} inverted>
              <Loader>Saving order</Loader>
            </Dimmer>

            <CustomerInformation
              model={custDetails}
              loadingCustomerInfo={loadingCustomerInfo}
            />

            {hasCartItems && (
              <Fragment>
                <LabelOrderProduction
                  getErrorFor={this.getErrorFor}
                  data={order}
                  labelCenters={labelCenters}
                  loadingLabelCenters={loadingLabelCenters}
                  labelSuppliers={labelSuppliers}
                  loadingLabelSuppliers={loadingLabelSuppliers}
                  loadingCustomerBillToAddresses={
                    loadingCustomerBillToAddresses
                  }
                  loadingCustomerShipToAddresses={
                    loadingCustomerShipToAddresses
                  }
                  customerBillToAddresses={custDetails}
                  customerShipToAddresses={shipToDetails}
                  loadingAccountNumbersOptions={accountNumbersOptions}
                  loadingAccountNumbersShipToOptions={
                    accountNumbersShipToOptions
                  }
                  changeOrderCartValue={this.changeOrderCartValue}
                  changeOrderCartDropdownValue={
                    this.changeOrderCartDropdownValue
                  }
                  changeAccountNumberValue={this.handleAccntNumDorpDownChange}
                  changeAccountNumShipTOValue={
                    this.handleAccntNumShipToDropDownChange
                  }
                  IsAccntEmpty={isCustomerRelationEmpty}
                  isAccntShiptToEmpty={isCustomerRelationShipToEmpty}
                  isCompanyEnable={isCompanyEnable}
                  isCompanyShipToEnable={isCompanyShipToEnable}
                  isAddressEnable={isAddressEnable}
                  isAddressShipToEnable={isAddressShipToEnable}
                  form={form}
                  accountNumber={accntNumber}
                  accountNumberShipTo={accntNumberShipTo}
                  isLoadingAccountNUmbers={isLoading}
                  customerInformationsOptions={
                    customerRelationInformationsOptions
                  }
                  // customerInformationsData={
                  //   customerRelationInformationsShiptToData
                  // }
                  customerInformationsShipToOptions={
                    customerRelationInformationsShiptToOptions
                  }
                  // customerInformationsShipToData={
                  //   customerRelationInformationsShiptToData
                  // }
                />
                {data.cartItems.map((item, idx) => {
                  return (
                    <CombinationLabelOrderItem
                      key={idx}
                      data={item}
                      unitsOfMeasurement={unitsOfMeasurement}
                      changeOrderCartItemValue={changeOrderCartItemValue}
                      removeLabelOrderCartItem={this.removeLabelOrderCartItem}
                    />
                  );
                })}
                <AdditionalComments
                  changeOrderCartValue={this.changeOrderCartValue}
                  form={form}
                  getErrorFor={this.getErrorFor}
                />
                <Grid>
                  <Grid.Column width={8}></Grid.Column>
                  <Grid.Column style={{ textAlign: "right" }} width={8}>
                    <span>
                      <Button basic secondary onClick={this.handleDeleteCart}>
                        Delete cart
                      </Button>
                    </span>
                    <span>
                      <Button
                        color="blue"
                        primary
                        onClick={(e) => this.handleReviewCart()}
                        disabled={loadingUpdateCart}
                      >
                        Review Cart
                      </Button>
                    </span>
                  </Grid.Column>
                </Grid>
              </Fragment>
            )}

            {!hasCartItems && (
              <Segment textAlign="center">
                <Header>No items added on cart</Header>
              </Segment>
            )}

            <FooterHomeBlock />

            <Confirm
              size="mini"
              open={this.props.isConfirmingSave}
              header="Save order"
              content="Are you sure you want to continue?"
              onCancel={this.handleCancel}
              onConfirm={this.handleConfirm}
            />
          </Dimmer.Dimmable>
          <Modal size="mini" open={open} onClose={this.handleCancelDelete}>
            <Modal.Header>Delete cart</Modal.Header>
            <Modal.Content>
              <p>Are you sure you want to delete your cart?</p>
            </Modal.Content>
            <Modal.Actions>
              <Button primary basic onClick={this.handleConfirmDelete}>
                Delete
              </Button>
              <Button primary onClick={this.handleCancelDelete}>
                Cancel
              </Button>
            </Modal.Actions>
          </Modal>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  hasError: state.labelOrderCart.hasError,
  message: state.labelOrderCart.message,
  loading: state.labelOrderCart.loading,
  data: state.labelOrderCart.data,
  unitsOfMeasurement: state.labelOrderCart.unitsOfMeasurement,
  labelCenters: state.labelOrderCart.labelCenters,
  loadingLabelCenters: state.labelOrderCart.loadingLabelCenters,
  labelSuppliers: state.labelOrderCart.labelSuppliers,
  loadingLabelSuppliers: state.labelOrderCart.loadingLabelSuppliers,
  loadingCustomerInfo: state.labelOrderCart.loadingCustomerInfo,
  loadingCustomerBillToAddresses:
    state.labelOrderCart.loadingCustomerBillToAddresses,
  loadingCustomerShipToAddresses:
    state.labelOrderCart.loadingCustomerShipToAddresses,
  loadingUnitsOfMeasurement: state.labelOrderCart.loadingUnitsOfMeasurement,
  loadingUpdateCart: state.labelOrderCart.loadingUpdateCart,
  messageTimeout: state.labelOrderCart.messageTimeout,
  isConfirmingSave: state.labelOrderCart.isConfirmingSave,
  saveOrderSuccess: state.labelOrderCart.saveOrderSuccess,
  saveOrderFailed: state.labelOrderCart.saveOrderFailed,
  removeItemSuccess: state.labelOrderCart.removeItemSuccess,
  removeItemFailed: state.labelOrderCart.removeItemFailed,
  customerBillToAddresses: state.labelOrderCart.customerBillToAddresses,
  customerShipToAddresses: state.labelOrderCart.customerShipToAddresses,
  order: state.labelOrderCart.order,
  customerInfo: state.labelOrderCart.customerInfo,
  totalNetPrice: state.labelOrderCart.totalNetPrice,
  customerDetails: state.labelCart.customerDetails,
  labelCartData: state.labelCart.data,
  isSuccessGetCartItems: state.labelOrderCart.isSuccessGetCartItems,
  labelOrderCart: state.labelOrderCart,
  customerRelationsData: state.accountSettings.customerRelationsData,
  accountNumbersOptions: state.accountSettings.accountNumbersOptions,
  accountNumbersShipToOptions:
    state.accountSettings.accountNumbersShipToOptions,
  customerRelationsShipToData:
    state.accountSettings.customerRelationsShipToData,
  isLoading: state.accountSettings.isLoading,
  customerRelationInformationsOptions:
    state.accountSettings.customerRelationInformationsOptions,
  customerRelationInformationsData:
    state.accountSettings.customerRelationInformationsData,
  customerRelationInformationsShiptToOptions:
    state.accountSettings.customerRelationInformationsShiptToOptions,
  customerRelationInformationsShiptToData:
    state.accountSettings.customerRelationInformationsShiptToData,
  labelCart: state.labelCart.data,
  isServiceContractUp: state.labelOrderCart.isServiceContractUp
});

const mapDispatchToProps = (dispatch) => ({
  changeOrderCartItemValue: (id, name, value) =>
    dispatch(changeOrderCartItemValue(id, name, value)),
  changeOrderCartValue: (name, value) =>
    dispatch(changeOrderCartValue(name, value)),
  confirmSaveOrderRequest: () => dispatch(confirmSaveOrderRequest()),
  confirmSaveOrderAccept: () => dispatch(confirmSaveOrderAccept()),
  confirmSaveOrderReject: () => dispatch(confirmSaveOrderReject()),
  getCustomerInfo: (customerId) => dispatch(getCustomerInfo(customerId)),
  getCustomerBillToAddresses: (customerId) =>
    dispatch(getCustomerBillToAddresses(customerId)),
  getCustomerShipToAddresses: (customerId) =>
    dispatch(getCustomerShipToAddresses(customerId)),
  getLabelCenters: () => dispatch(getLabelCenters()),
  getLabelOrderCart: () => dispatch(getLabelOrderCart()),
  getLabelSuppliers: () => dispatch(getLabelSuppliers()),
  getUnitsOfMeasurement: () => dispatch(getUnitsOfMeasurement()),
  initialize: () => dispatch(initialize()),
  removeLabelOrderCartItem: (id) => dispatch(removeLabelOrderCartItem(id)),
  removeRemoveItemMessage: () => dispatch(removeRemoveItemMessage()),
  removeSaveOrderMessage: () => dispatch(removeSaveOrderMessage()),
  updateLabelOrderCart: (data) => dispatch(updateLabelOrderCart(data)),
  resetLabelOrderCart: () => dispatch(resetLabelOrderCart()),
  getCustomerDetails: (partySiteNumber) =>
    dispatch(getCustomerDetails(partySiteNumber)),
  uploadCartItemAttachment: (formData) =>
    dispatch(uploadCartItemAttachment(formData)),
  updateForm: (result) => dispatch(updateForm(result)),
  getCustomerRelations: (accountNumber, isBillTo) =>
    dispatch(actions.getCustomerRelations(accountNumber, isBillTo)),
  getCustomerRelationsShipTo: (accountNumber, isBillTo) =>
    dispatch(actions.getCustomerRelationsShipTo(accountNumber, isBillTo)),
  getCustomerRelationInformations: (accountNumber) =>
    dispatch(actions.getCustomerRelationInformations(accountNumber)),
  getCustomerRelationInformationsShipTo: (accountNumber) =>
    dispatch(actions.getCustomerRelationInformationsShipTo(accountNumber)),
  cancelOrder: (data) => dispatch(cancelOrder(data)),
  getActivePage: (page) => dispatch(commonActions.getActivePage(page))
});

export default connect(mapStateToProps, mapDispatchToProps)(LabelOrderCart);
