import * as types from "./reset-password.type";
import api from "../../services/api";

export const resetPasswordResult = (obj, type) => {
  return {
    type: type,
    data: obj
  };
};

export const doResetPasswordRequest = email => {
  return async dispatch => {
    await api.User.resetPasswordRequest(email)
      .then(result => {
        dispatch(
          resetPasswordResult(result, types.RESET_PASSWORD_REQUEST_SUCCESS)
        );
      })
      .catch(result => {
        dispatch(
          resetPasswordResult(
            result.response.data,
            types.RESET_PASSWORD_REQUEST_FAILURE
          )
        );
      });
  };
};

export const doResetPasswordValidate = token => {
  return async dispatch => {
    await api.User.resetPasswordValidate(token)
      .then(result => {
        dispatch(
          resetPasswordResult(result, types.RESET_PASSWORD_VALIDATE_SUCCESS)
        );
      })
      .catch(result => {
        dispatch(
          resetPasswordResult(
            result.response.data,
            types.RESET_PASSWORD_VALIDATE_FAILURE
          )
        );
      });
  };
};

export const doResetPassword = model => {
  return async dispatch => {
    await api.User.resetPassword(model)
      .then(result => {
        dispatch(resetPasswordResult(result, types.RESET_PASSWORD_SUCCESS));
      })
      .catch(result => {
        dispatch(
          resetPasswordResult(
            result.response.data,
            types.RESET_PASSWORD_FAILURE
          )
        );
      });
  };
};

export const updateForm = result => {
  return dispatch => dispatch(resetPasswordResult(result, types.FORM));
};

export const clearResetPassword = () =>
  dispatch => dispatch(resetPasswordResult({}, types.CLEAR))
