import {
 API_STATUS_REQUEST,
 API_STATUS_FAILURE,
 API_STATUS_SUCCESS
} from "./health-dashboard.type";

const initialState = {
  hasError: false,
  message: "",
  loading: false,
  data: null,
  filterOptions: [],
  filterByWeek: [],
  filterByOrderStatus: [],
};

const healthDashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case API_STATUS_REQUEST:
      return {
        ...state,
        hasError: false,
        loading: true
      };
    case API_STATUS_SUCCESS:
      return {
        ...state,
        hasError: false,
        data: action.payload,
        filterOptions: action.payload.data[0].filterDay.map((cr, idx) => {
            return {
                key:idx,
                value:cr.day,
                text: cr.inHrs
            }
          }),
        filterByWeek:action.payload.data[4].filterWeek.map((cr, idx) => {
            return {
                key:idx,
                value:cr.id,
                text: cr.week
            }
          }),
        filterByOrderStatus:action.payload.data[7].filterDay.map((cr, idx) => {
            return {
                key:idx,
                value:cr.day,
                text: cr.inHrs
            }
          }),
        loading: false
      };
    case API_STATUS_FAILURE:
      return {
        ...state,
        message: action.payload,
        hasError: true,
        loading: false
      };
    default:
      return state;
  }
};

export default healthDashboardReducer;