import {
  LABEL_ORDER_DETAILS_GET_CUSTOMER_INFO_REQUEST,
  LABEL_ORDER_DETAILS_GET_CUSTOMER_INFO_SUCCESS,
  LABEL_ORDER_DETAILS_GET_CUSTOMER_INFO_FAILURE
} from "../../constants/labelOrderDetails";
import api from "../../services/api";

const getCustomerInfoRequest = () => {
  return { type: LABEL_ORDER_DETAILS_GET_CUSTOMER_INFO_REQUEST };
};

const getCustomerInfoFailure = error => {
  return dispatch =>
    dispatch({
      type: LABEL_ORDER_DETAILS_GET_CUSTOMER_INFO_FAILURE,
      payload: error
    });
};

const getCustomerInfoSuccess = response => {
  return dispatch =>
    dispatch({
      type: LABEL_ORDER_DETAILS_GET_CUSTOMER_INFO_SUCCESS,
      payload: response
    });
};

export const getCustomerInfo = customerId => {
  return async dispatch => {
    dispatch(getCustomerInfoRequest());
    const result = await api.Customer.getInfo(customerId);
    if (result) {
      dispatch(getCustomerInfoSuccess(result));
      return result;
    } else {
      dispatch(getCustomerInfoFailure("Something went wrong."));
    }
  };
};
