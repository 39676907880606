import { requests } from '../request-factory';
import { mapDataToQueryString } from '../request-helpers';

const applicantService = {
  getFileNumbers: labelOrderType =>
    requests.get(
      `applicant/filenumbers?${mapDataToQueryString({
        labelOrderType,
      })}`
    ),
  getManufacturers: data => requests.post(`applicant/manufacturers`, data),
  getLabels: (fileNumber, volume, manufacturerId, currentPage, pageSize) =>
    requests.get(
      `applicant/labels?${mapDataToQueryString({
        fileNumber,
        volume,
        manufacturerId,
        currentPage,
        pageSize,
      })}`
    ),
  getVolumes: (fileNumber, labelOrderType) =>
    requests.get(
      `applicant/volumes?${mapDataToQueryString({
        fileNumber,
        labelOrderType,
      })}`
    ),
  addLabelToCart: cartData => requests.post('applicant/label-cart', cartData),
  checkCustomerPilotIfExist: (fileNumber, manufacturerAccountNumber) =>
    requests.get(
      `applicant/customer-pilot-exist?${mapDataToQueryString({
        fileNumber,
        manufacturerAccountNumber,
      })}`
    ),
  checkIfvsIfUp: labelOrderType =>
    requests.get(
      `applicant/ifvs-is-up?${mapDataToQueryString({
        labelOrderType,
      })}`
    ),
  checkIfServiceContractUp: () =>
    requests.get(
      `applicant/service-contract-is-up?${mapDataToQueryString({})}`
    ),
};

export default applicantService;
