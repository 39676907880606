const Rules = {
  emailRule: () => {
    return /^\s|^$|^NULL|(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    //return /^((([a-z]|\d|[!#$%&'*+\-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-||_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+([a-z]+|\d|-|\.{0,1}|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])?([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/;
  },
  numberRule: () => {
    return /^-?\d+\.?\d*$/;
  },
  passwordRule: () => {
    // at least three of the following categories:
    // uppercase letters
    // lowercase letters
    // numbers
    // special characters
    // allowed special characters: @%+\/'!#$^?:,(){}[]~`-_
    return /^((?=.*[A-Z])(?=.*[a-z])(?=.*\d)|(?=.*[a-z])(?=.*\d)(?=.*[\@\%\+\\\/\'\!\#\$\^\?\:\,\(\)\{\}\[\]\~\`\-\_])|(?=.*[A-Z])(?=.*\d)(?=.*[\@\%\+\\\/\'\!\#\$\^\?\:\,\(\)\{\}\[\]\~\`\-\_])|(?=.*[A-Z])(?=.*[a-z])(?=.*[\@\%\+\\\/\'\!\#\$\^\?\:\,\(\)\{\}\[\]\~\`\-\_])).{8,30}$/;
  },
  passwordRuleDoubleChars: () => {
    return /(.)\1/;
  },
  lettersRule: () => {
    return /^[A-Za-z]+$/;
  },
  phoneNumber: () => {
    return /^\s|^$|^NULL|[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
  },
};

export default Rules;
