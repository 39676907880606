import React, { Fragment } from "react";
import styled from "styled-components";

const MessageSuccessStyle = styled.div`
  background-color: rgba(76, 158, 69);
  color: #ffffff;
`;

const MessageWarningStyle = styled.div`
  background-color: rgba(207, 95, 40);
  color: #ffffff;
`;

const MessageErrorStyle = styled.div`
  background-color: #801818;
  color: #ffffff;
`;

const MessageBodyStyle = styled.div`
  display: table-cell;
  vertical-align: middle;
  padding: 15px 10px 15px 20px;
`;

const MessageSuccessIconStyle = styled.div`
  display: table-cell;
  vertical-align: middle;
  width: 50px;
  text-align: center;
  background-color: rgba(146, 200, 62);
`;

const MessageWarningIconStyle = styled.div`
  display: table-cell;
  vertical-align: middle;
  width: 50px;
  text-align: center;
  background-color: rgba(241, 138, 33);
`;

const MessageErrorIconStyle = styled.div`
  display: table-cell;
  vertical-align: middle;
  width: 50px;
  text-align: center;
  background-color: #c32929;
`;

const MessageIconSpanStyle = styled.span`
  border: 2px solid #ffffff;
  border-radius: 12px;
  padding: 0px 6px;
`;

export const NotificationSuccess = ({ message }) => {
  return (
    <Fragment>
      <MessageSuccessStyle>
        <MessageSuccessIconStyle>
          <MessageIconSpanStyle>i</MessageIconSpanStyle>
        </MessageSuccessIconStyle>
        <MessageBodyStyle>{message}</MessageBodyStyle>
      </MessageSuccessStyle>
    </Fragment>
  );
};

export const NotificationWarning = ({ message }) => {
  return (
    <Fragment>
      <MessageWarningStyle>
        <MessageWarningIconStyle>
          <MessageIconSpanStyle>i</MessageIconSpanStyle>
        </MessageWarningIconStyle>
        <MessageBodyStyle>{message}</MessageBodyStyle>
      </MessageWarningStyle>
    </Fragment>
  );
};

export const NotificationError = ({ message }) => {
  return (
    <Fragment>
      <MessageErrorStyle>
        <MessageErrorIconStyle>
          <MessageIconSpanStyle>i</MessageIconSpanStyle>
        </MessageErrorIconStyle>
        <MessageBodyStyle>{message}</MessageBodyStyle>
      </MessageErrorStyle>
    </Fragment>
  );
};
