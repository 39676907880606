import {
  FILE_NUMBER_REQUEST,
  FILE_NUMBER_FAILURE,
  FILE_NUMBER_SUCCESS,
  VOLUME_REQUEST,
  VOLUME_FAILURE,
  VOLUME_SUCCESS,
  ACTIVE_MANUFACTURER_REQUEST,
  ACTIVE_MANUFACTURER_FAILURE,
  ACTIVE_MANUFACTURER_SUCCESS,
  ACTIVE_MANUFACTURER_CHANGE_PAGE,
  ACTIVE_MANUFACTURER_SORT,
  CHANGE_FILE_NUMBER,
  CHANGE_VOLUME,
  FILTER_ITEM_SELECT,
  FILTER_ITEM_SELECT_ALL,
  SELECT_FILE_VOLUME,
  SELECT_ALL_FILE_VOLUMES,
  CHECK_CUSTOMER_LABEL_REQUEST,
  CHECK_CUSTOMER_LABEL_FAILURE,
  CHECK_CUSTOMER_LABEL_SUCCESS,
  CHECK_IFVS_IF_UP_REQUEST,
  CHECK_IFVS_IF_UP_FAILURE,
  CHECK_IFVS_IF_UP_SUCCESS,
  CHECK_SERVICE_CONTRACT_REQUEST,
  CHECK_SERVICE_CONTRACT_SUCCESS,
  CHECK_SERVICE_CONTRACT_FAILURE,
  CLEAN_ALL_FILTERS,
  CLEAR_SELECTION,
} from './applicant-dashboard.type';

const initialState = {
  hasError: false,
  message: '',
  loading: false,
  isLoadingManufacturers: false,
  activePage: 1,
  pageSize: 5,
  totalPages: 1,
  fileNumbers: null,
  volumes: null,
  manufacturers: [],
  fileNumberText: '',
  fileNumberValue: '',
  volumeText: '',
  volumeValue: '',
  sortColumn: '',
  sortOrder: '',
  fileNumberFilter: [],
  volumeFilter: [],
  ccnFilter: [],
  accountFilter: [],
  nameFilter: [],
  addressFilter: [],
  selectedFileNumbers: [],
  selectedVolumes: [],
  selectedCcns: [],
  selectedAccounts: [],
  selectedNames: [],
  selectedAddress: [],
  selectedItems: [],
  customerPilotExist: false,
  isIfvsIsUp: false,
  isServiceContractUp: false,
  hasFilter: false,
  filters: [],
  isFilterInitial: true,
  manufacturerPsns: [],
  manufacturerAccountNumbers: [],
};

const getFilters = (data, filterIndex) => {
  var result = data.filters
    .find(x => x.filterType == filterIndex)
    .fileVolumeFilterObjectDetail.map(function (item) {
      return {
        text: item.text,
        isSelected: item.isSelected,
      };
    });

  return result;
};

const addFilterSelection = (data, payload) => {
  if (payload.isSelected) data.push(payload.data['text']);
  else data = data.filter(s => s !== payload.data['text']);

  return data;
};

const addAllFilterSelections = (data, currentFilters, payload) => {
  if (payload.isSelected) {
    data = [];
    currentFilters.forEach(filter => {
      data.push(filter.text);
    });
  } else data = [];

  return data;
};

const toggleFilterItem = (data, payload) => {
  return data.map(item => {
    if (item.text !== payload.data['text']) return item;
    item.isSelected = payload.isSelected;
    return item;
  });
};

const toggleAllFilterItems = (data, payload) => {
  return data.map(item => {
    item.isSelected = payload.isSelected;
    return item;
  });
};

const getSelectedItems = selectedItems => {
  var result = [];

  selectedItems
    .filter(d => d.isSelected)
    .forEach(item => {
      var row = result.filter(
        f =>
          f.fileNumber === item.fileNumber &&
          f.volume === item.volume &&
          f.ccn === item.ccn &&
          f.compCcns === item.compCcns &&
          f.productType === item.productType &&
          f.manufacturerAccount === item.manufacturerAccount &&
          f.manufacturerAddress === item.manufacturerAddress
      )[0];
      if (!row) {
        result.push({
          ccn: item.ccn,
          compCcns: item.compCcns,
          fileNumber: item.fileNumber,
          manufacturerAccount: item.manufacturerAccount,
          manufacturerAddress: item.manufacturerAddress,
          manufacturerName: item.manufacturerName,
          productType: item.productType,
          volume: item.volume,
        });
      }
    });

  return result;
};

const applicantDashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case FILE_NUMBER_REQUEST:
      return {
        ...state,
        hasError: false,
        loading: true,
      };
    case FILE_NUMBER_SUCCESS:
      return {
        ...state,
        hasError: false,
        fileNumbers: action.payload.data,
        loading: false,
      };
    case FILE_NUMBER_FAILURE:
      return {
        ...state,
        message: action.payload,
        hasError: true,
        loading: false,
      };
    case VOLUME_REQUEST:
      return {
        ...state,
        hasError: false,
        loading: true,
      };
    case VOLUME_SUCCESS:
      return {
        ...state,
        hasError: false,
        volumes: action.payload.data,
        loading: false,
      };
    case CHECK_IFVS_IF_UP_REQUEST:
      return {
        ...state,
        message: action.payload,
        hasError: true,
        loading: true,
      };
    case CHECK_IFVS_IF_UP_SUCCESS:
      return {
        ...state,
        hasError: false,
        isIfvsIsUp: action.payload,
        loading: false,
      };
    case CHECK_IFVS_IF_UP_FAILURE:
      return {
        ...state,
        isIfvsIsUp: action.payload,
        loading: false,
      };
    case CHECK_SERVICE_CONTRACT_REQUEST:
      return {
        ...state,
        message: action.payload,
        hasError: true,
        loading: true,
      };
    case CHECK_SERVICE_CONTRACT_SUCCESS:
      return {
        ...state,
        hasError: false,
        isServiceContractUp: action.payload,
        loading: false,
      };
    case CHECK_SERVICE_CONTRACT_FAILURE:
      return {
        ...state,
        isServiceContractUp: action.payload,
        loading: false,
      };
    case ACTIVE_MANUFACTURER_CHANGE_PAGE:
      return {
        ...state,
        activePage: action.payload.activePage,
      };
    case ACTIVE_MANUFACTURER_SORT:
      return {
        ...state,
        sortColumn: action.payload.sortColumn,
        sortOrder: action.payload.sortOrder,
      };
    case ACTIVE_MANUFACTURER_REQUEST:
      return {
        ...state,
        isLoadingManufacturers: true,
        hasFileVolumeData: false,
      };
    case ACTIVE_MANUFACTURER_SUCCESS:
      state.filters = action.payload.filters;

      if (state.hasFilter) {
        state.isFilterInitial = false;
      }

      if (action.payload.filters.length > 0) {
        state.fileNumberFilter = getFilters(action.payload, 1);

        state.volumeFilter = getFilters(action.payload, 2);

        state.ccnFilter = getFilters(action.payload, 3);

        state.nameFilter = getFilters(action.payload, 4);

        state.addressFilter = getFilters(action.payload, 5);

        state.accountFilter = getFilters(action.payload, 6);
      }

      state.manufacturers = action.payload.applicantFileVolumeDetails
        ? action.payload.applicantFileVolumeDetails.data
        : null;
      state.hasFileVolumeData = action.payload.hasFileVolumeData;

      // state.data.forEach((item, idx) => {
      //   item.id = idx;
      //   item.isSelected = false;
      // });
      //
      if (state.manufacturers) {
        state.manufacturerPsns = [];
        state.manufacturerAccountNumbers = [];

        state.manufacturers.forEach((item, idx) => {
          item.id = idx;
          if (state.selectedItems && state.selectedItems.length > 0) {
            var selectedItem = state.selectedItems[0].filter(
              f =>
                f.fileNumber === item.fileNumber &&
                f.compCcns === item.compCcns &&
                f.ccn === item.ccn &&
                f.productType === item.productType &&
                f.manufacturerAccount === item.manufacturerAccount &&
                f.manufacturerAddress === item.manufacturerAddress &&
                f.manufacturerName === item.manufacturerName &&
                f.volume === item.volume
            );

            item.isSelected =
              selectedItem && selectedItem.length > 0 ? true : false;
          } else {
            item.isSelected = false;
          }

          if (
            !state.manufacturerPsns.find(
              p => p.partySiteNumber === item.partySiteNumber
            )
          )
            state.manufacturerPsns.push({
              partySiteNumber: item.partySiteNumber,
              manufacturerName: item.manufacturerName,
            });

          if (
            !state.manufacturerAccountNumbers.find(
              p => p.manufacturerAccount === item.manufacturerAccount
            )
          )
            state.manufacturerAccountNumbers.push({
              manufacturerAccount: item.manufacturerAccount,
              manufacturerName: item.manufacturerName,
            });
        });
      }

      return {
        ...state,
        hasError: false,
        totalPages: action.payload.applicantFileVolumeDetails
          ? action.payload.applicantFileVolumeDetails.totalPages
          : 0,
        activePage: action.payload.applicantFileVolumeDetails?.currentPage,
        isLoadingManufacturers: false,
      };
    case ACTIVE_MANUFACTURER_FAILURE:
      return {
        ...state,
        isLoadingManufacturers: false,
        totalPages: action.payload.totalPages,
        hasFileVolumeData: false,
        manufacturers: null,
      };
    case CHANGE_FILE_NUMBER:
      const filtered = state.fileNumbers.filter(
        item => item.value === action.payload
      );

      let fileNumberText = null;
      if (filtered && filtered.length > 0) fileNumberText = filtered[0].text;

      return {
        ...state,
        fileNumberText: fileNumberText,
        fileNumberValue: action.payload.value,
        volumeText: null,
        volumeValue: null,
      };
    case CHANGE_VOLUME:
      const filteredVolumes = state.volumes.filter(
        item => item.value === action.payload
      );

      let volumeText = null;
      if (filteredVolumes && filteredVolumes.length > 0)
        volumeText = filteredVolumes[0].text;

      return {
        ...state,
        volumeText: volumeText,
        volumeValue: action.payload.value,
      };
    case FILTER_ITEM_SELECT:
      const newState = { ...state };

      newState.hasFilter = true;

      let filterHeader = newState.filters.find(
        x => x.filterType == action.data.activeFilterIndex
      );
      filterHeader.isSelectAll = false;
      filterHeader.isCurrentFilter = true;

      // filterHeader.fileVolumeFilterObjectDetail.forEach((item) => {
      //   item.isSelected = false;
      // });

      let currentItem = filterHeader.fileVolumeFilterObjectDetail.find(
        x => x.text == action.data.data['text']
      );

      currentItem.isSelected = !currentItem.isSelected;

      switch (action.data.activeFilterIndex) {
        case 1:
          newState.fileNumberFilter = filterHeader.fileVolumeFilterObjectDetail;
          break;
        case 2:
          newState.volumeFilter = filterHeader.fileVolumeFilterObjectDetail;
          break;
        case 3:
          newState.ccnFilter = filterHeader.fileVolumeFilterObjectDetail;
          break;
        case 4:
          newState.nameFilter = filterHeader.fileVolumeFilterObjectDetail;
          break;
        case 5:
          newState.addressFilter = filterHeader.fileVolumeFilterObjectDetail;
          break;
        case 6:
          newState.accountFilter = filterHeader.fileVolumeFilterObjectDetail;
          break;
        default:
          break;
      }

      return newState;

    case FILTER_ITEM_SELECT_ALL:
      const newStateAll = { ...state };

      switch (action.data.activeFilterIndex) {
        case 1:
          newStateAll.selectedFileNumbers = addAllFilterSelections(
            newStateAll.selectedFileNumbers,
            newStateAll.fileNumberFilter,
            action.data
          );
          newStateAll.fileNumberFilter = toggleAllFilterItems(
            newStateAll.fileNumberFilter,
            action.data
          );
          break;
        case 2:
          newStateAll.selectedVolumes = addAllFilterSelections(
            newStateAll.selectedVolumes,
            newStateAll.volumeFilter,
            action.data
          );
          newStateAll.volumeFilter = toggleAllFilterItems(
            newStateAll.volumeFilter,
            action.data
          );
          break;
        case 3:
          newStateAll.selectedNames = addAllFilterSelections(
            newStateAll.selectedNames,
            newStateAll.nameFilter,
            action.data
          );
          newStateAll.nameFilter = toggleAllFilterItems(
            newStateAll.nameFilter,
            action.data
          );
          break;
        case 4:
          newStateAll.selectedAddress = addAllFilterSelections(
            newStateAll.selectedAddress,
            newStateAll.addressFilter,
            action.data
          );
          newStateAll.addressFilter = toggleAllFilterItems(
            newStateAll.addressFilter,
            action.data
          );
          break;
        case 5:
          newStateAll.selectedAccounts = addAllFilterSelections(
            newStateAll.selectedAccounts,
            newStateAll.accountFilter,
            action.data
          );
          newStateAll.accountFilter = toggleAllFilterItems(
            newStateAll.accountFilter,
            action.data
          );
          break;
        case 6:
          newStateAll.selectedCcns = addAllFilterSelections(
            newStateAll.selectedCcns,
            newStateAll.ccnFilter,
            action.data
          );
          newStateAll.ccnFilter = toggleAllFilterItems(
            newStateAll.ccnFilter,
            action.data
          );
          break;

        default:
          break;
      }

      return newStateAll;
    case SELECT_FILE_VOLUME:
      const newStateFileVols = { ...state };

      newStateFileVols.manufacturers = newStateFileVols.manufacturers.map(
        item => {
          if (item.id !== action.data.data.id) return item;
          item.isSelected = action.data.isSelected;
          return item;
        }
      );

      var selectedItems = getSelectedItems(newStateFileVols.manufacturers);

      if (selectedItems && selectedItems.length > 0) {
        if (
          newStateFileVols.selectedItems &&
          newStateFileVols.selectedItems.length == 0
        ) {
          newStateFileVols.selectedItems.push(selectedItems);
        } else {
          selectedItems.forEach(item => {
            var isExist = newStateFileVols.selectedItems[0].filter(
              f =>
                f.fileNumber === item.fileNumber &&
                f.compCcns === item.compCcns &&
                f.ccn === item.ccn &&
                f.productType === item.productType &&
                f.manufacturerAccount === item.manufacturerAccount &&
                f.manufacturerAddress === item.manufacturerAddress &&
                f.volume === item.volume
            )[0];
            if (!isExist) {
              newStateFileVols.selectedItems[0].push(item);
            } else {
              newStateFileVols.selectedItems[0].forEach(itm => {
                var data = newStateFileVols.manufacturers.filter(
                  f =>
                    f.fileNumber === itm.fileNumber &&
                    f.compCcns === itm.compCcns &&
                    f.ccn === itm.ccn &&
                    f.productType === itm.productType &&
                    f.manufacturerAccount === itm.manufacturerAccount &&
                    f.manufacturerAddress === itm.manufacturerAddress &&
                    f.volume === itm.volume
                )[0];
                if (!data.isSelected) {
                  newStateFileVols.selectedItems[0] =
                    newStateFileVols.selectedItems[0].filter(x => x != itm);
                }
              });
            }
          });
        }
      } else {
        newStateFileVols.manufacturers.forEach(item => {
          if (!item.isSelected)
            var data = newStateFileVols.selectedItems[0].filter(
              f =>
                f.fileNumber === item.fileNumber &&
                f.compCcns === item.compCcns &&
                f.ccn === item.ccn &&
                f.productType === item.productType &&
                f.manufacturerAccount === item.manufacturerAccount &&
                f.manufacturerAddress === item.manufacturerAddress &&
                f.volume === item.volume
            );
          newStateFileVols.selectedItems[0] =
            newStateFileVols.selectedItems[0].filter(x => x != data[0]);
        });
      }

      return newStateFileVols;
    case SELECT_ALL_FILE_VOLUMES:
      const newStateAllFileVols = { ...state };
      newStateAllFileVols.isSelectedAll = action.data.isSelectedAll;

      newStateAllFileVols.manufacturers = newStateAllFileVols.manufacturers.map(
        item => {
          item.isSelected = action.data.isSelectedAll;
          return item;
        }
      );

      newStateAllFileVols.selectedItems[0] = getSelectedItems(
        newStateAllFileVols.manufacturers
      );

      return newStateAllFileVols;

    case CLEAN_ALL_FILTERS:
      const clearedFilterState = { ...state };
      clearedFilterState.isFilterInitial = true;
      clearedFilterState.hasFilter = false;

      clearedFilterState.selectedItems[0] = [];
      return clearedFilterState;

    case CLEAR_SELECTION:
      const cleanSelectionState = { ...state };

      cleanSelectionState.selectedItems[0] = [];
      return cleanSelectionState;

    default:
      return state;
  }
};

export default applicantDashboardReducer;
