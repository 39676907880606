import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Table } from "semantic-ui-react";

import * as actions from "./product-details.action";
import Property from "../../../../enums/property";

class Price extends Component {
  componentDidMount() {
    const { form } = this.props;
    const { preliminarySiteLocationSection } = this.props.reservationDetails;

    let scheduleDate = preliminarySiteLocationSection.scheduleDate
      ? new Date(
          preliminarySiteLocationSection.scheduleDate
        ).toDateStringFormat()
      : "0"; // not ready to schedule

    this.props.getServiceProductPrice(
      0, // TODO: implement on different zoning
      form.serviceSubProductId,
      form.propertyType,
      parseInt(form.quantity),
      scheduleDate,
      0 // single site no site charge
    );
  }

  render() {
    const { form } = this.props;
    const { data } = this.props.productDetails.productPrice;

    return (
      <Table attached>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell textAlign="center">PRICE</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              {form && form.isManualPricing == "true" ? (
                <p className="center">
                  <h2>
                    <u>For admin review</u>
                  </h2>
                </p>
              ) : (
                <Table basic celled structured>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Product Category</Table.HeaderCell>
                      <Table.HeaderCell>Equipment Type</Table.HeaderCell>
                      <Table.HeaderCell>Property Type</Table.HeaderCell>
                      <Table.HeaderCell>Quantity</Table.HeaderCell>
                      <Table.HeaderCell>Price (USD)</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>{form.serviceProductName}</Table.Cell>
                      <Table.Cell>{form.serviceSubProductName}</Table.Cell>
                      <Table.Cell>
                        {(
                          Object.keys(Property).find(
                            (k) => Property[k] === form.propertyType
                          ) || ""
                        ).capitalize()}
                      </Table.Cell>
                      <Table.Cell>{form.quantity}</Table.Cell>
                      <Table.Cell>
                        ${" "}
                        {(data || (form && form.totalPriceByPh)) &&
                          Number.format(
                            form && form.totalPriceByPh
                              ? parseInt(form.totalPriceByPh)
                              : data.totalPrice,
                            2
                          )}
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              )}
              {form && !form.isManualPricing ? (
                <p>
                  The price is based on the information provided in your
                  request. Please note that additional expenses such as travel
                  costs, shipping, disposition of samples, local taxes, and
                  others may be incurred. If these are required for your
                  project, they will be billed to you. Invoicing for this
                  project will occur at the times stated in this Quotation or at
                  completion of the project.
                </p>
              ) : null}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  }
}

Price.propTypes = {
  form: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  return {
    productDetails: state.feProductDetails,
    reservationDetails: state.fieldEvaluation.form.reservationDetails
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getServiceProductPrice: (
      serviceAreaId,
      serviceSubProductId,
      propertyType,
      quantity,
      scheduleDate,
      siteCharge
    ) =>
      dispatch(
        actions.getServiceProductPrice(
          serviceAreaId,
          serviceSubProductId,
          propertyType,
          quantity,
          scheduleDate,
          siteCharge
        )
      )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Price);
