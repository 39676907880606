import React, { Component } from "react";
import PropTypes from "prop-types";
import { Checkbox } from "semantic-ui-react";

class TermsAndConditions extends Component {
  render() {
    const { isCheckAgree, onClickAgreeChecbox, isHidden } = this.props;
    return (
      <div style={{ lineHeight: "1.5" }}>
        <p style={{ textAlign: "center" }}>
          <strong>UL Mark Label Program Service Terms</strong>
        </p>
        <p>
          These Service Terms will govern UL Mark Label Service (“Services”)
          performed by the UL Contracting Party (“we”, “our”, or “us” as the
          context requires and as identified in the Authorization (“Quotation”)
          or Confirmation) and set out the responsibilities and obligations of
          the Client (“you” or “your” as the context requires). These Service
          Terms and the terms of the Global Services Agreement (“GSA”) are
          incorporated by reference into and are an integral part of each
          Service Agreement entered into by the Parties for Services. The
          capitalized terms in these Service Terms which are not defined herein
          will have the same meaning as in the GSA.
        </p>
        <ol>
          <li>
            UL CERTIFICATION MARKS - Labels bearing the Registered trademark(s)
            of UL LLC and must not be released or delivered to any person or
            point other than as specified on the online UL Standard Label Order
            form or the UL Order Authorization (“Quotation”).
          </li>
          <li>
            NUMBERING - No UL Certification Marks bearing any serial or issue
            number other than as authorized on this Quotation are to be
            manufactured.
          </li>
          <li>
            Client agrees not to order more than a three-month supply of UL
            Certification Marks unless authorized by UL Contracting Party.
          </li>
          <li>
            QUANTITY - Only the exact quantity of UL Certification Marks covered
            by this Quotation shall be manufactured. Trade practices to the
            country notwithstanding, overruns, if any, shall be destroyed
            immediately with immediate written notice to UL Contracting Party.
          </li>
          <li>
            We have only reviewed the elements of the label related to UL Mark.
            Other markings that may appear on this same label must comply with
            the UL Procedure description.
          </li>
          <li>
            The Registered trademark of UL as authorized for printing and
            described in this Quotation must be the current and appropriate
            trademark, in the proper dimensions, as it appears on
            http://www.ul.com/marks.
          </li>
          <li>
            PACKING - All UL Certification Marks must be securely wrapped in
            sealed packages in the quantities indicated. Each package must be
            identified with the type of UL Certification Mark, part number, name
            of the Client (if indicated), and issue or consecutive numbering
            contained therein. All metal UL Certification Marks must be banded
            together before packing.
          </li>
          <li>
            SHIPPING - All shipments must be F.O.B. DESTINATION unless otherwise
            specified.
          </li>
          <li>
            TRACKING - Issue or consecutive numbering identified on this
            Quotation must be shown on all invoices and shipping notices.
          </li>
          <li>
            DELIVERY – We reserve the right to cancel any Quotation if UL
            Certification Marks are not finished and ready for release within
            120 days.
          </li>
          <li>
            DESIGNATED MANUFACTURER - The Registered trademark(s) of UL LLC
            shall be reproduced on or applied to labels, nameplates, or other
            material covered by this Quotation only by the company at the
            address designated as the Manufacturer on this Quotation. UL
            Certification Marks may not be applied to production at a newly
            authorized manufacturing facility or on production for a new product
            category at an established authorized manufacturing facility without
            first receiving authorization from the UL Field Representative. The
            Client requesting this Quotation, agrees that Client and its
            designated Manufacturer will not at any time manufacture, release or
            deliver to any person any labels, including samples, bearing the
            Registered trademark(s) of UL LLC, or wording such as, "Underwriters
            Laboratories Inc. Listed", "Underwriters Laboratories Inc.
            Classified", "Classified by Underwriters Laboratories Inc.", UL LLC
            or an abbreviation thereof, without express written approval from
            UL. Client will be responsible to us for any breach of these UL
            Label Program Service Terms by the Manufacturer.
          </li>
          <li>
            LABEL DESTRUCTION - Unused pressure sensitive UL Certification Marks
            must be destroyed after 3 years from the date of this Quotation. If
            the related service with the UL Contracting Party or any UL Company
            is discontinued, unused UL Certification Marks in the possession of
            the Manufacturer must be surrendered to the UL Field Representative
            or destroyed in their presence.
          </li>
          <li>
            UL Certification Marks of any type must be destroyed if the related
            service with UL Contracting Party or any UL Company is discontinued.
            There will be no reimbursement or replacement for UL Certification
            Marks destroyed.
          </li>
          <li>
            REFUNDS - There will be no refunds for UL Certification Marks after
            60 days from the date of this order.
          </li>
        </ol>
        {/* <div style={{ textAlign: "center" }}>
          <span hidden={isHidden == "true" ? "1" : ""}>
              <tr>   
                <td>       
            <Checkbox
              style={{border: "3px solid black", padding: "0px", height:"23px", width:"23px"}}
              checked={isCheckAgree}
              onChange={onClickAgreeChecbox}
            />
                </td>  
                <td style={{ align: "center", verticalAlign: "top" }}>
                  <label>
                   <b>
                    I acknowledge that I have read and agree to the above Terms
                    and Conditions
                  </b>
                  </label>
                </td>
              </tr>
          </span>
        </div> */}
      </div>
    );
  }
}

TermsAndConditions.propTypes = {
  isCheckAgree: PropTypes.bool.isRequired,
  onClickAgreeChecbox: PropTypes.func.isRequired
};

export default TermsAndConditions;
