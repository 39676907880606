import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, Grid, Table } from "semantic-ui-react";
import PropTypes from "prop-types";
import { Form, Radio, Button } from "semantic-ui-react";
import ToastHelper from "../../../helpers/toast.helper";

import * as actions from "./cancel-order.action";

class CancellationCode extends Component {
  state = {};

  componentDidMount() {
    this.props.getCancellationCode();
  }

  handleChange(id, e, { value }) {
    this.updateCancellationCode("id", id);
    this.setState({ value: value });
  }

  onChange(e) {
    this.updateCancellationCode(e.target.name, e.target.value);
  }

  updateCancellationCode(name, value) {
    // TODO: implement validation
    let { selectedCancellationCode } = this.props.cancellationCode;
    let newForm = Object.assign({}, selectedCancellationCode, {
      [name]: value
    });
    this.props.updateCancellationCode(newForm);

    // on update modal remain open
    this.props.handleCancellationCodeModal.bind(this, true);
  }

  handleConfirm() {
    const { handleConfirmCancelOrder, orderId } = this.props;
    let { selectedCancellationCode } = this.props.cancellationCode;

    // No selected cancellation reason code
    if (typeof this.state.value === "undefined") {
      return setTimeout(() => {
        ToastHelper.error({
          title: "Error",
          description: "Please select cancellation reason",
          time: 2000
        });
      }, 100);
    }

    // `Other` selected w/o description
    if (
      this.state.value === "OTHER" &&
      (typeof selectedCancellationCode.other === "undefined" ||
        Object.IsEmpty(selectedCancellationCode.other))
    )
      return setTimeout(() => {
        ToastHelper.error({
          title: "Error",
          description: "Please provide description",
          time: 2000
        });
      }, 100);

    handleConfirmCancelOrder.call(this, true, orderId);
  }

  render() {
    const {
      isShowCancellationCodeModal,
      handleCancellationCodeModal
    } = this.props;
    const {
      cancellationCode,
      selectedCancellationCode
    } = this.props.cancellationCode;

    return (
      <Modal
        open={isShowCancellationCodeModal}
        onClose={handleCancellationCodeModal.bind(this, false)}
      >
        <Modal.Header className="center">Cancellation</Modal.Header>
        <Modal.Content scrolling>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Form>
                  <Table basic="very" celled>
                    <Table.Header>
                      <Table.Row className="center">
                        <Table.HeaderCell>Reason</Table.HeaderCell>
                        <Table.HeaderCell>Description</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {cancellationCode.data
                        ? cancellationCode.data.map((c, idx) => {
                            return (
                              <Table.Row key={idx}>
                                <Table.Cell collapsing>
                                  <Form.Field>
                                    <Radio
                                      label={c.name}
                                      name="cancellationCode"
                                      value={c.code}
                                      checked={this.state.value === c.code}
                                      onChange={this.handleChange.bind(
                                        this,
                                        c.id
                                      )}
                                    />
                                  </Form.Field>
                                </Table.Cell>
                                <Table.Cell>
                                  {c.code === "OTHER" &&
                                  this.state.value === c.code ? (
                                    <Form.Field>
                                      <Form.Input
                                        value={
                                          selectedCancellationCode.other || ""
                                        }
                                        placeholder="Describe"
                                        name="other"
                                        autoComplete="off"
                                        onChange={this.onChange.bind(this)}
                                      />
                                    </Form.Field>
                                  ) : (
                                    <Form.Field>{c.description}</Form.Field>
                                  )}
                                </Table.Cell>
                              </Table.Row>
                            );
                          })
                        : null}
                    </Table.Body>
                  </Table>
                </Form>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button primary onClick={this.handleConfirm.bind(this)}>
            Continue
          </Button>
          <Button onClick={handleCancellationCodeModal.bind(this, false)}>
            Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

CancellationCode.propTypes = {
  orderId: PropTypes.number.isRequired,
  isShowCancellationCodeModal: PropTypes.bool.isRequired,
  handleCancellationCodeModal: PropTypes.func.isRequired,
  handleConfirmCancelOrder: PropTypes.func.isRequired
};

const mapStateToProps = (state) => {
  return {
    cancellationCode: state.feCancelOrder
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCancellationCode: () => dispatch(actions.getCancellationCode()),
    updateCancellationCode: (data) =>
      dispatch(actions.updateCancellationCode(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CancellationCode);
