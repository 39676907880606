import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Table,
  Popup,
  Label,
  Icon,
  Menu,
  Modal,
  Dimmer,
  Loader,
  Form,
  Radio,
} from 'semantic-ui-react';
import { Common } from '../../common/common';
import Role from '../../enums/role';

// const formatter = new Intl.NumberFormat("en-US", {
//   style: "currency",
//   currency: "USD",
//   minimumFractionDigits: 2,
// });

const customStyles = {
  FileUploadInputStyle: {
    float: 'left',
    marginRight: '15px',
  },
  CheckboxStyle: {
    border: '3px solid black',
    padding: '0px',
    height: '23px',
    width: '23px',
  },
  OracleNumberColStyle: {
    border: 0,
  },
  StatusLabelStyle: {
    color: '#1e70bf',
  },
};

class OrderItem extends Component {
  renderTableData(data) {
    return data.map((data, index) => {
      return (
        <tr border="0" key={index}>
          {data}
        </tr>
      );
    });
  }

  handleDateFormat = dateUpdate => {
    var retDate = '';
    if (dateUpdate) {
      retDate = new Date(dateUpdate).toLocaleDateString();
    }
    return retDate.toString();
  };
  adjustStatus = (data, translations) => {
    var returnData = data.statusName;

    if (data.labelOrderStatus === 4) {
      returnData = 'Cancelled';
    } else {
      if (data.statusName === 'SHIPPED') {
        returnData = 'On-Hold';
      } else if (data.statusName === 'INPROGRESS') {
        returnData = 'In Progress';
      } else if (data.statusName === 'INPROCRESS') {
        returnData = 'In Process';
      } else {
        returnData = data.statusName.replace(/\w+/g, function (w) {
          return w[0].toUpperCase() + w.slice(1).toLowerCase();
        });
      }
    }
    return Common.translateStatus(returnData, translations);
  };

  render() {
    const {
      data,
      columnOptions,
      openFileUpload,
      showStatusModel,
      bubblStatusValue,
    } = this.props;

    const roleId = Common.getUserRole();
    const isAdmin = roleId === Role.labelCenter || roleId === Role.labelManager;
    const fileType =
      this.props.labelOrderType === 1
        ? '.pdf,.doc,.docx,.jpg'
        : '.pdf,.jpg,.jpeg,.doc,.docx,.xls,.xlsx';
    const isAttachmentOnLimit =
      this.props.labelOrderType === 1
        ? this.props.attachments?.length > 4
        : this.props.attachments?.length >= 50;
    const disableUpload = !isAdmin || isAttachmentOnLimit;
    const translations = Common.getTranslations() || {};
    return (
      <Fragment>
        <Modal size="small" open={openFileUpload}>
          <Dimmer active={this.props.uploading}>
            <Loader>Loading</Loader>
          </Dimmer>
          <Modal.Header>
            <table>
              <tr>
                <th width="850">{translations['LexFileUpload']}</th>
                <th>
                  <Button
                    primary
                    onClick={() =>
                      this.props.handleCloseMangeDocument(
                        this.props.requestNo,
                        this.props.createdById,
                        isAdmin
                      )
                    }
                  >
                    X
                  </Button>
                </th>
              </tr>
            </table>
          </Modal.Header>
          <Modal.Content>
            <Table>
              <Table.Header>
                <Table.Row textAlign="center">
                  <Table.HeaderCell>
                    {translations['LexFileName']}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {translations['LexFileSize']}
                  </Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.props.attachments &&
                  this.props.attachments.map((column, idx) => {
                    return (
                      <Table.Row>
                        <Table.Cell>
                          <a
                            href={column.filePath}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {column.originalFileName}
                          </a>
                        </Table.Cell>
                        <Table.Cell textAlign="center">
                          {column.fileSize}
                        </Table.Cell>
                        {roleId !== Role.printer ? (
                          <Table.Cell textAlign="right">
                            <Button
                              disabled={!isAdmin}
                              onClick={() =>
                                this.props.handleDeleteAttachment(
                                  column.id,
                                  this.props.labelOrderId,
                                  this.props.labelOrderType,
                                  column.isCombinationArtwork
                                )
                              }
                            >
                              {translations['LexDelete']}
                            </Button>
                          </Table.Cell>
                        ) : (
                          <></>
                        )}
                      </Table.Row>
                    );
                  })}
              </Table.Body>
            </Table>
          </Modal.Content>
          <Modal.Actions>
            <div style={customStyles.FileUploadInputStyle}>
              <input
                type="file"
                id="file"
                accept={fileType}
                name={this.props.labelOrderId}
                style={{ display: 'none' }}
                onChange={this.props.handleUploadFile}
                ref={fileInput => (this.fileInput = fileInput)}
                onClick={event => {
                  event.target.value = null;
                }}
              />
              <button
                disabled={disableUpload}
                onClick={() => this.fileInput.click()}
                className="ui primary button"
              >
                Choose File
              </button>
            </div>
            <br />
            <br />
          </Modal.Actions>
        </Modal>
        <Modal open={showStatusModel} size="small">
          <Modal.Header className="ui center aligned"></Modal.Header>
          <Modal.Content scrolling>
            <Table striped celled selectable sortable>
              <Table.Header>
                Please select a bubble status to update:
              </Table.Header>
              <Table.Body>
                <br />
                <Form.Field>
                  <Radio
                    label="In Progress"
                    name="radioGroup"
                    value="inProgress"
                    checked={bubblStatusValue === 'inProgress'}
                    onChange={this.props.handleSelectedStatus}
                  />
                </Form.Field>
                <br />
                <Form.Field>
                  <Radio
                    label="On-Hold"
                    name="radioGroup"
                    value="shipped"
                    checked={bubblStatusValue === 'shipped'}
                    onChange={this.props.handleSelectedStatus}
                  />
                </Form.Field>
                <br />
                <Form.Field>
                  <Radio
                    label="Closed"
                    name="radioGroup"
                    value="closed"
                    checked={bubblStatusValue === 'closed'}
                    onChange={this.props.handleSelectedStatus}
                  />
                </Form.Field>
                <br />
              </Table.Body>
            </Table>
          </Modal.Content>
          <Modal.Actions>
            <Button
              secondary
              basic
              onClick={() => this.props.handleUpdateStatus()}
            >
              Ok
            </Button>
            <Button
              secondary
              basic
              onClick={this.props.handleBubbleModal.bind(this, false)}
            >
              Cancel
            </Button>
          </Modal.Actions>
        </Modal>
        <Table.Row>
          <Table.Cell>
            <Popup
              className="ellipsis-order"
              on="click"
              position="left center"
              flowing
              hoverable
              trigger={<Icon name="ellipsis vertical" />}
            >
              <Menu text vertical>
                {/* Generic in all status */}
                {/* {(roleId === Role.manufacturer ||
                  roleId === Role.applicant) && (
                  <Menu.Item
                    position="left"
                    name={translations["LexCopy"]}
                    style={{ textAlign: "left" }}
                    onClick={() => this.props.handleCopyOrder(data)}
                  />
                )} */}
                <Menu.Item
                  position="left"
                  name={translations['LexView']}
                  style={{ textAlign: 'left' }}
                  as={Link}
                  to={`/home/label-order-details/${data.requestNumber}/${data.labelOrderType}`}
                />
                {data &&
                  data.childItems.length > 0 &&
                  (data.isExpandMultiple === true ? (
                    <Menu.Item
                      position="left"
                      name={translations['LexHideMultipleOrders']}
                      style={{ textAlign: 'left' }}
                      onClick={() =>
                        this.props.handleExpandMultipleOrders(data.id)
                      }
                    />
                  ) : (
                    <Menu.Item
                      position="left"
                      name={translations['LexShowMultipleOrders']}
                      style={{ textAlign: 'left' }}
                      onClick={() =>
                        this.props.handleExpandMultipleOrders(data.id)
                      }
                    />
                  ))}
                <Menu.Item
                  position="left"
                  name={translations['LexManageDocuments']}
                  style={{ textAlign: 'left' }}
                  onClick={() =>
                    this.props.handleMangeDocument(
                      data.id,
                      data.createdById,
                      data.requestNumber,
                      data.labelOrderType
                    )
                  }
                />

                {data &&
                  data.oracleNumber &&
                  data.oracleMessageUnfiltered &&
                  (roleId === Role.labelCenter ||
                    roleId === Role.labelManager) && (
                    <Menu.Item
                      position="left"
                      name="Update Bubble Status"
                      style={{ textAlign: 'left' }}
                      onClick={() =>
                        this.props.handleBubbleModal(
                          true,
                          data.labelOrderInfoId
                        )
                      }
                    />
                  )}

                {data &&
                  !data.oracleNumber &&
                  (roleId === Role.labelCenter ||
                    roleId === Role.labelManager) &&
                  data.labelOrderStatus === 2 && (
                    <Menu.Item
                      position="left"
                      name="Re-push Oracle Order"
                      style={{ textAlign: 'left' }}
                      onClick={() =>
                        this.props.handleManualRepushOrder(
                          data.labelOrderInfoId
                        )
                      }
                    />
                  )}
              </Menu>
            </Popup>
          </Table.Cell>
          {columnOptions &&
            columnOptions.map((column, idx) => {
              if (
                column &&
                column.key === 'orderStatus' &&
                column.visible === 1 &&
                column.disabled === 0
              ) {
                return (
                  <Table.Cell key={idx} style={customStyles.StatusLabelStyle}>
                    {this.adjustStatus(data, translations)}
                  </Table.Cell>
                );
              } else if (
                column &&
                column.key === 'reOrder' &&
                column.visible === 1 &&
                column.disabled === 0
              ) {
                return (
                  <Table.Cell
                    key={idx}
                    style={{ textAlign: 'center', cursor: 'pointer' }}
                  >
                    <Icon
                      className="cart arrow down"
                      size="big"
                      onClick={() => this.props.handleCopyOrder(data)}
                    />
                  </Table.Cell>
                );
              } else if (
                column &&
                column.key === 'labelCenter' &&
                column.visible === 1 &&
                column.disabled === 0
              ) {
                return (
                  <Table.Cell key={idx}>{data.labelCenterName}</Table.Cell>
                );
              } else if (
                column &&
                column.key === 'cartOrderNo' &&
                column.visible === 1 &&
                column.disabled === 0
              ) {
                return <Table.Cell key={idx}>{data.requestNumber}</Table.Cell>;
              } else if (
                column &&
                column.key === 'statusUpdateDateFormatted' &&
                column.visible === 1 &&
                column.disabled === 0
              ) {
                return (
                  <Table.Cell key={idx}>
                    {data.statusUpdateDateFormatted}
                  </Table.Cell>
                );
              } else if (
                column &&
                column.key === 'orderNo' &&
                column.visible === 1 &&
                column.disabled === 0
              ) {
                return (
                  <Table.Cell key={idx}>
                    {data.childItems.length > 0 ? (
                      data.isExpandMultiple === true ? (
                        data.oracleNumber ? (
                          data.oracleNumber
                        ) : roleId === Role.applicant ||
                          roleId === Role.manufacturer ||
                          roleId === Role.printer ? (
                          data.labelOrderType === 1 ? (
                            ''
                          ) : (
                            ''
                          )
                        ) : (
                          data.oracleMessageUnfiltered
                        )
                      ) : (
                        <Label
                          style={customStyles.OracleNumberColStyle}
                          basic
                          circular
                          color="blue"
                        >
                          MULTIPLE
                        </Label>
                      )
                    ) : data.oracleNumber ? (
                      data.oracleNumber
                    ) : roleId === Role.applicant ||
                      roleId === Role.manufacturer ||
                      roleId === Role.printer ? (
                      data.labelOrderType === 1 ? (
                        ''
                      ) : (
                        ''
                      )
                    ) : (
                      data.oracleMessageUnfiltered
                    )}
                  </Table.Cell>
                );
              } else if (
                column &&
                column.key === 'oracleOrderDate' &&
                column.visible === 1 &&
                column.disabled === 0
              ) {
                return (
                  <Table.Cell key={idx}>
                    {this.handleDateFormat(data.oracleOrderCreationDate)}
                  </Table.Cell>
                );
              } else if (
                column &&
                column.key === 'customerPONo' &&
                column.visible === 1 &&
                column.disabled === 0
              ) {
                return (
                  <Table.Cell key={idx}>{data.customerPoNumber}</Table.Cell>
                );
              } else if (
                column &&
                column.key === 'customerContactName' &&
                column.visible === 1 &&
                column.disabled === 0
              ) {
                return (
                  <Table.Cell key={idx}>{data.customerContactName}</Table.Cell>
                );
              } else if (
                column &&
                column.key === 'applicantAccountNo' &&
                column.visible === 1 &&
                column.disabled === 0
              ) {
                return (
                  <Table.Cell key={idx}>
                    {data.applicantAccountNumber}
                  </Table.Cell>
                );
              } else if (
                column &&
                column.key === 'applicantCompanyName' &&
                column.visible === 1 &&
                column.disabled === 0
              ) {
                return (
                  <Table.Cell key={idx}>{data.applicantCompanyName}</Table.Cell>
                );
              } else if (
                column &&
                column.key === 'applicantAddress' &&
                column.visible === 1 &&
                column.disabled === 0
              ) {
                return (
                  <Table.Cell key={idx}>{data.applicantAddress}</Table.Cell>
                );
              } else if (
                column &&
                column.key === 'manufacturerAccountNumber' &&
                column.visible === 1 &&
                column.disabled === 0
              ) {
                return (
                  <Table.Cell key={idx}>
                    {data.manufacturerAccountNumber}
                  </Table.Cell>
                );
              } else if (
                column &&
                column.key === 'manufacturerCompanyName' &&
                column.visible === 1 &&
                column.disabled === 0
              ) {
                return (
                  <Table.Cell key={idx}>
                    {data.manufacturerCompanyName}
                  </Table.Cell>
                );
              } else if (
                column &&
                column.key === 'manufacturerAddress' &&
                column.visible === 1 &&
                column.disabled === 0
              ) {
                return (
                  <Table.Cell key={idx}>{data.manufacturerAddress}</Table.Cell>
                );
              } else if (
                column &&
                column.key === 'shipToPartySiteNo' &&
                column.visible === 1 &&
                column.disabled === 0
              ) {
                return <Table.Cell key={idx}>{data.psn}</Table.Cell>;
              } else if (
                column &&
                column.key === 'fileNo' &&
                column.visible === 1 &&
                column.disabled === 0
              ) {
                return <Table.Cell key={idx}>{data.fileNumber}</Table.Cell>;
              } else if (
                column &&
                column.key === 'labelType' &&
                column.visible === 1 &&
                column.disabled === 0
              ) {
                return (
                  <Table.Cell key={idx}>
                    {data.labelOrderType === 1
                      ? translations['LexSTD']
                      : translations['LexCMB']}
                  </Table.Cell>
                );
              } else if (
                column &&
                column.key === 'repushOrder' &&
                column.visible === 1 &&
                column.disabled === 0
              ) {
                return (
                  <Table.Cell key={idx}>
                    {data.repushOrder === 'YES' ? translations['LexYES'] : ''}
                  </Table.Cell>
                );
              } else if (
                column &&
                column.key === 'emailSent' &&
                column.visible === 1 &&
                column.disabled === 0
              ) {
                return (
                  <Table.Cell key={idx}>
                    {data.emailSent === 'YES'
                      ? translations['LexYES']
                      : translations['LexNO']}
                  </Table.Cell>
                );
              } else if (
                column &&
                column.key === 'vendorLetter' &&
                column.visible === 1 &&
                column.disabled === 0
              ) {
                return (
                  <Table.Cell key={idx}>
                    {data.labelOrderType === 1
                      ? ''
                      : data.attachmentDetails?.labelOrderInfoId
                      ? translations['LexYES']
                      : translations['LexNO']}
                  </Table.Cell>
                );
              } else if (
                column &&
                column.key === 'vendorLetterSentToCustomer' &&
                column.visible === 1 &&
                column.disabled === 0
              ) {
                return (
                  <Table.Cell key={idx}>
                    {data.labelOrderType === 1
                      ? translations['LexNO']
                      : data.vendorLetterSentToCustomer === 'YES'
                      ? translations['LexYES']
                      : translations['LexNO']}
                  </Table.Cell>
                );
              } else if (
                column &&
                column.key === 'vendorLetterSentToSupplier' &&
                column.visible === 1 &&
                column.disabled === 0
              ) {
                return (
                  <Table.Cell key={idx}>
                    {data.labelOrderType === 1
                      ? translations['LexNO']
                      : data.vendorLetterSentToSupplier === 'YES'
                      ? translations['LexYES']
                      : translations['LexNO']}
                  </Table.Cell>
                );
              } else if (
                column &&
                column.key === 'letterDate' &&
                column.visible === 1 &&
                column.disabled === 0
              ) {
                return (
                  <Table.Cell key={idx}>
                    {data.labelOrderType === 1
                      ? ''
                      : data.attachmentDetails
                      ? data.attachmentDetails.lastModifiedDate
                      : ''}
                  </Table.Cell>
                );
              } else if (
                column &&
                column.key === 'copyDetail' &&
                column.visible === 1 &&
                column.disabled === 0
              ) {
                return <Table.Cell key={idx}>{data.copyDetail}</Table.Cell>;
              } else if (
                column &&
                column.key === 'icon' &&
                column.visible === 1 &&
                column.disabled === 0
              ) {
                return (
                  <Table.Cell key={idx}>
                    <span hidden={data.attachmentLength === 0}>
                      <Icon size="big" name="file outline" />
                    </span>
                  </Table.Cell>
                );
              } else if (
                column &&
                column.key === 'labelAgent' &&
                column.visible === 1 &&
                column.disabled === 0
              ) {
                return <Table.Cell key={idx}>{data.labelAgent}</Table.Cell>;
              }
              // else if (
              //   column &&
              //   column.key === "selectOrder" &&
              //   column.visible === 1 &&
              //   column.disabled === 0
              // ) {
              //   return (
              //     <Table.Cell key={idx}>
              //       <Checkbox
              //         style={customStyles.CheckboxStyle}
              //         checked={data.isSelected}
              //         onChange={(e, { checked }) => {
              //           this.props.handleChangeSelection(data, checked);
              //         }}
              //       />
              //     </Table.Cell>
              //   );
              // }
              //else if (
              //   column &&
              //   column.key === "shipToAddress" &&
              //   column.visible === 1 &&
              //   column.disabled === 0
              // ) {
              //   return <Table.Cell key={idx}>{data.shipToAddress}</Table.Cell>;
              // }
              // else if (
              //   column &&
              //   column.key === "creationDate" &&
              //   column.visible === 1 &&
              //   column.disabled === 0
              // ) {
              //   return <Table.Cell key={idx}>{data.creationDate}</Table.Cell>;
              // }
            })}
        </Table.Row>
        {data.isExpandMultiple === true &&
          data.childItems &&
          data.childItems.map((item, idx) => {
            return (
              <Table.Row active>
                <Table.Cell></Table.Cell>
                {columnOptions &&
                  columnOptions.map((column, idx) => {
                    if (
                      column &&
                      column.key === 'orderStatus' &&
                      column.visible === 1 &&
                      column.disabled === 0
                    ) {
                      return (
                        <Table.Cell
                          key={idx}
                          style={customStyles.StatusLabelStyle}
                        >
                          {this.adjustStatus(data, translations)}
                        </Table.Cell>
                      );
                    } else if (
                      column &&
                      column.key === 'reOrder' &&
                      column.visible === 1 &&
                      column.disabled === 0
                    ) {
                      return (
                        <Table.Cell
                          key={idx}
                          style={{ textAlign: 'center', cursor: 'pointer' }}
                        >
                          <Icon
                            className="cart arrow down"
                            size="big"
                            onClick={() => this.props.handleCopyOrder(data)}
                          />
                        </Table.Cell>
                      );
                    } else if (
                      column &&
                      column.key === 'labelCenter' &&
                      column.visible === 1 &&
                      column.disabled === 0
                    ) {
                      return (
                        <Table.Cell key={idx}>
                          {item.labelCenterName}
                        </Table.Cell>
                      );
                    } else if (
                      column &&
                      column.key === 'cartOrderNo' &&
                      column.visible === 1 &&
                      column.disabled === 0
                    ) {
                      return (
                        <Table.Cell key={idx}>{item.requestNumber}</Table.Cell>
                      );
                    } else if (
                      column &&
                      column.key === 'statusUpdateDateFormatted' &&
                      column.visible === 1 &&
                      column.disabled === 0
                    ) {
                      return (
                        <Table.Cell key={idx}>
                          {item.statusUpdateDateFormatted}
                        </Table.Cell>
                      );
                    } else if (
                      column &&
                      column.key === 'orderNo' &&
                      column.visible === 1 &&
                      column.disabled === 0
                    ) {
                      return (
                        <Table.Cell key={idx}>
                          {item.oracleNumber
                            ? item.oracleNumber
                            : roleId === Role.applicant ||
                              roleId === Role.manufacturer ||
                              roleId === Role.printer
                            ? ''
                            : item.oracleMessageUnfiltered}
                        </Table.Cell>
                      );
                    } else if (
                      column &&
                      column.key === 'oracleOrderDate' &&
                      column.visible === 1 &&
                      column.disabled === 0
                    ) {
                      return (
                        <Table.Cell key={idx}>
                          {this.handleDateFormat(data.oracleOrderCreationDate)}
                        </Table.Cell>
                      );
                    } else if (
                      column &&
                      column.key === 'customerPONo' &&
                      column.visible === 1 &&
                      column.disabled === 0
                    ) {
                      return (
                        <Table.Cell key={idx}>
                          {item.customerPoNumber}
                        </Table.Cell>
                      );
                    } else if (
                      column &&
                      column.key === 'customerContactName' &&
                      column.visible === 1 &&
                      column.disabled === 0
                    ) {
                      return (
                        <Table.Cell key={idx}>
                          {item.customerContactName}
                        </Table.Cell>
                      );
                    } else if (
                      column &&
                      column.key === 'applicantAccountNo' &&
                      column.visible === 1 &&
                      column.disabled === 0
                    ) {
                      return (
                        <Table.Cell key={idx}>
                          {item.applicantAccountNumber}
                        </Table.Cell>
                      );
                    } else if (
                      column &&
                      column.key === 'applicantCompanyName' &&
                      column.visible === 1 &&
                      column.disabled === 0
                    ) {
                      return (
                        <Table.Cell key={idx}>
                          {item.applicantCompanyName}
                        </Table.Cell>
                      );
                    } else if (
                      column &&
                      column.key === 'applicantAddress' &&
                      column.visible === 1 &&
                      column.disabled === 0
                    ) {
                      return (
                        <Table.Cell key={idx}>
                          {item.applicantAddress}
                        </Table.Cell>
                      );
                    } else if (
                      column &&
                      column.key === 'manufacturerAccountNumber' &&
                      column.visible === 1 &&
                      column.disabled === 0
                    ) {
                      return (
                        <Table.Cell key={idx}>
                          {item.manufacturerAccountNumber}
                        </Table.Cell>
                      );
                    } else if (
                      column &&
                      column.key === 'manufacturerCompanyName' &&
                      column.visible === 1 &&
                      column.disabled === 0
                    ) {
                      return (
                        <Table.Cell key={idx}>
                          {item.manufacturerCompanyName}
                        </Table.Cell>
                      );
                    } else if (
                      column &&
                      column.key === 'manufacturerAddress' &&
                      column.visible === 1 &&
                      column.disabled === 0
                    ) {
                      return (
                        <Table.Cell key={idx}>
                          {item.manufacturerAddress}
                        </Table.Cell>
                      );
                    } else if (
                      column &&
                      column.key === 'shipToPartySiteNo' &&
                      column.visible === 1 &&
                      column.disabled === 0
                    ) {
                      return <Table.Cell key={idx}>{item.psn}</Table.Cell>;
                    } else if (
                      column &&
                      column.key === 'fileNo' &&
                      column.visible === 1 &&
                      column.disabled === 0
                    ) {
                      return (
                        <Table.Cell key={idx}>{item.fileNumber}</Table.Cell>
                      );
                    } else if (
                      column &&
                      column.key === 'labelType' &&
                      column.visible === 1 &&
                      column.disabled === 0
                    ) {
                      return (
                        <Table.Cell key={idx}>
                          {data.labelOrderType === 1
                            ? translations['LexSTD']
                            : translations['LexCMB']}
                        </Table.Cell>
                      );
                    } else if (
                      column &&
                      column.key === 'repushOrder' &&
                      column.visible === 1 &&
                      column.disabled === 0
                    ) {
                      return (
                        <Table.Cell key={idx}>
                          {data.repushOrder === 'YES'
                            ? translations['LexYES']
                            : ''}
                        </Table.Cell>
                      );
                    } else if (
                      column &&
                      column.key === 'emailSent' &&
                      column.visible === 1 &&
                      column.disabled === 0
                    ) {
                      return (
                        <Table.Cell key={idx}>
                          {data.emailSent === 'YES'
                            ? translations['LexYES']
                            : ''}
                        </Table.Cell>
                      );
                    } else if (
                      column &&
                      column.key === 'vendorLetter' &&
                      column.visible === 1 &&
                      column.disabled === 0
                    ) {
                      return (
                        <Table.Cell key={idx}>
                          {data.labelOrderType === 1
                            ? ''
                            : data.attachmentDetails?.labelOrderInfoId
                            ? translations['LexYES']
                            : translations['LexNO']}
                        </Table.Cell>
                      );
                    } else if (
                      column &&
                      column.key === 'vendorLetterSentToCustomer' &&
                      column.visible === 1 &&
                      column.disabled === 0
                    ) {
                      return (
                        <Table.Cell key={idx}>
                          {data.labelOrderType === 1
                            ? translations['LexNO']
                            : data.vendorLetterSentToCustomer === 'YES'
                            ? translations['LexYES']
                            : translations['LexNO']}
                        </Table.Cell>
                      );
                    } else if (
                      column &&
                      column.key === 'vendorLetterSentToSupplier' &&
                      column.visible === 1 &&
                      column.disabled === 0
                    ) {
                      return (
                        <Table.Cell key={idx}>
                          {data.labelOrderType === 1
                            ? translations['LexNO']
                            : data.vendorLetterSentToSupplier === 'YES'
                            ? translations['LexYES']
                            : translations['LexNO']}
                        </Table.Cell>
                      );
                    } else if (
                      column &&
                      column.key === 'letterDate' &&
                      column.visible === 1 &&
                      column.disabled === 0
                    ) {
                      return (
                        <Table.Cell key={idx}>
                          {data.labelOrderType === 1
                            ? ''
                            : data.attachmentDetails
                            ? data.attachmentDetails.lastModifiedDate
                            : ''}
                        </Table.Cell>
                      );
                    } else if (
                      column &&
                      column.key === 'copyDetail' &&
                      column.visible === 1 &&
                      column.disabled === 0
                    ) {
                      return (
                        <Table.Cell key={idx}>{item.copyDetail}</Table.Cell>
                      );
                    } else if (
                      column &&
                      column.key === 'icon' &&
                      column.visible === 1 &&
                      column.disabled === 0
                    ) {
                      return (
                        <Table.Cell key={idx}>
                          <span hidden={data.attachmentLength === 0}>
                            <Icon size="big" name="file outline" />
                          </span>
                        </Table.Cell>
                      );
                    } else if (
                      column &&
                      column.key === 'labelAgent' &&
                      column.visible === 1 &&
                      column.disabled === 0
                    ) {
                      return (
                        <Table.Cell key={idx}>{data.labelAgent}</Table.Cell>
                      );
                    }
                  })}
              </Table.Row>
            );
          })}
      </Fragment>
    );
  }
}

export default OrderItem;
