import React, { Component } from "react";
import { Form, Divider } from "semantic-ui-react";
import { Common } from "../../common";
import Role from "../../enums/role";

class ContactForm extends Component {
  render() {
    var userRole = localStorage.getItem("roles");
    const translations = Common.getTranslations() || {};
    var roleId = Common.getUserRole();
    var isAdmin = roleId === Role.labelCenter || roleId === Role.labelManager;

    return (
      //   <Form>
      //     <Form.Input label="Subject" placeholder="Enter subject" />
      //     <Form.TextArea label="Comments" placeholder="Comments" />
      //     <Button>Submit</Button>
      //   </Form>
      <Form className="com-contact-container">
        <p>
          {isAdmin
            ? "If you're having an issue that could use the attention of a real person, please call us or send an email."
            : translations["LexHavingIssueCallUs"]}
        </p>
        <Divider hidden />

        {/* <p className="contactus">CONTACT US</p> */}
        <p className="info">
          {(userRole == 1 || userRole == 2 || userRole == 5) && (
            <a
              href="https://marks.ul.com/about/ul-listing-and-classification-marks/labels/label-centers/"
              target="_blank"
            >
              CONTACT UL Solutions LABEL CENTERS
            </a>
          )}
          {(userRole == 3 || userRole == 4 || userRole == 6) && (
            <span>
              Please contact us at 1-877-854-3577, Ext. 2 or field@ul.com
            </span>
          )}
        </p>
      </Form>
    );
  }
}

export default React.memo(ContactForm);
