import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import HeaderBlock from "../../components/header/header-block";
import FooterBlock from "../../components/footer/footer-block";
import { Container, Card, Button } from "semantic-ui-react";
import { CustomTitleLabel } from "../../components/labels/labels";
import { resetPasswordCard } from "../../assets/js/common";

class ResetPasswordMessage extends Component {
  static defaultProps = {
    buttonText: "Return to login page",
    link: "/"
  };
  render() {
    // TODO: Fix this bgcolor
    document.getElementById("root").style.backgroundColor = "#0b3c61";
    const { header, message, link, buttonText } = this.props;
    return (
      <Fragment>
        <HeaderBlock />
        <Container style={resetPasswordCard}>
          <Card fluid>
            <Card.Content>
              <Card.Header>{header}</Card.Header>
              <br />
              <CustomTitleLabel message={message} />
            </Card.Content>
            <Card.Content extra textAlign="right">
              <Button as={Link} to={link} basic color="blue">
                {buttonText}
              </Button>
            </Card.Content>
          </Card>
        </Container>
        <FooterBlock />
      </Fragment>
    );
  }
}

ResetPasswordMessage.propTypes = {
  header: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  link: PropTypes.string
};

export default ResetPasswordMessage;
