import {
  FILE_NUMBERS_FAILURE,
  FILE_NUMBERS_REQUEST,
  FILE_NUMBERS_SUCCESS,
  FILE_NUMBERS_SORT,
  FILE_NUMBERS_PAGINATE,
  FILE_NUMBERS_SELECT,
  FILE_NUMBERS_SELECT_ALL,
  SELECT_FILE_VOLUME_MANU,
  SELECT_ALL_FILE_VOLUMES_MANU,
  CLEAN_ALL_FILTERS,
  CLEAR_SELECTION
} from "./file-volumes.type";
import api from "../../../../services/api";

const dispatchRequest = (obj, type) => {
  return {
    data: obj,
    type: type
  };
};

const fileNumbersRequest = () => {
  return { type: FILE_NUMBERS_REQUEST };
};

const fileNumbersSort = (sortColumn, sortOrder) => {
  return { type: FILE_NUMBERS_SORT, payload: { sortColumn, sortOrder } };
};

const fileNumbersPaginate = (activePage) => {
  return { type: FILE_NUMBERS_PAGINATE, payload: { activePage } };
};

const fileNumbersFailure = (error) => {
  return (dispatch) => dispatch({ type: FILE_NUMBERS_FAILURE, payload: error });
};

const fileNumbersSuccess = (response, firstload) => {
  return (dispatch) =>
    dispatch({
      type: FILE_NUMBERS_SUCCESS,
      payload: response,
      firstload: firstload
    });
};

export const selectFileNumber = (data, isSelected, activeFilterIndex) => {
  return (dispatch) =>
    dispatch({
      type: FILE_NUMBERS_SELECT,
      payload: { data, isSelected, activeFilterIndex }
    });
};

export const selectAllFileNumbers = (isSelected, activeFilterIndex) => {
  return (dispatch) =>
    dispatch({
      type: FILE_NUMBERS_SELECT_ALL,
      payload: { isSelected, activeFilterIndex }
    });
};

export const getFileNumbers = (
  sortColumn,
  sortOrder,
  currentPage,
  pageSize,
  filters,
  hasFilter,
  isFilterInitial,
  labelOrderType,
  isFirstLoad
) => {
  return async (dispatch) => {
    dispatch(fileNumbersRequest());
    dispatch(fileNumbersSort(sortColumn, sortOrder));

    const result = await api.Manufacturer.getFileNumbers({
      sortColumn,
      sortOrder,
      currentPage,
      pageSize,
      filters,
      hasFilter,
      isFilterInitial,
      labelOrderType
    });

    if (result) {
      dispatch(fileNumbersSuccess(result, isFirstLoad));
      return result;
    } else {
      dispatch(fileNumbersFailure("Something went wrong."));
    }
  };
};

export const changePage = (
  sortColumn,
  sortOrder,
  currentPage,
  pageSize,
  filters,
  hasFilter,
  isFilterInitial,
  labelOrderType
) => {
  return async (dispatch) => {
    dispatch(fileNumbersRequest());
    dispatch(fileNumbersPaginate(currentPage));
    const result = await api.Manufacturer.getFileNumbers({
      sortColumn,
      sortOrder,
      currentPage,
      pageSize,
      filters,
      hasFilter,
      isFilterInitial,
      labelOrderType
    });

    if (result) {
      dispatch(fileNumbersSuccess(result));
    } else {
      dispatch(fileNumbersFailure("Something went wrong."));
    }
  };
};

export const selectFileVolume = (data, isSelected) => {
  return async (dispatch) => {
    dispatch(dispatchRequest({ data, isSelected }, SELECT_FILE_VOLUME_MANU));
  };
};

export const selectAllFileVolumes = (isSelectedAll) => {
  return async (dispatch) => {
    dispatch(dispatchRequest({ isSelectedAll }, SELECT_ALL_FILE_VOLUMES_MANU));
  };
};

export const cleanAllFilters = () => {
  return async (dispatch) => {
    dispatch(dispatchRequest({}, CLEAN_ALL_FILTERS));
  };
};

export const clearSelection = () => {
  return (dispatch) => dispatch({ type: CLEAR_SELECTION });
};
