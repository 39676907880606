export const FORM = "FORM";
export const CLEAR = "CLEAR";

export const RESET_PASSWORD_REQUEST_REQUEST = "RESET_PASSWORD_REQUEST_REQUEST";
export const RESET_PASSWORD_REQUEST_SUCCESS = "RESET_PASSWORD_REQUEST_SUCCESS";
export const RESET_PASSWORD_REQUEST_FAILURE = "RESET_PASSWORD_REQUEST_FAILURE";

export const RESET_PASSWORD_VALIDATE_REQUEST =
  "RESET_PASSWORD_VALIDATE_REQUEST";
export const RESET_PASSWORD_VALIDATE_SUCCESS =
  "RESET_PASSWORD_VALIDATE_SUCCESS";
export const RESET_PASSWORD_VALIDATE_FAILURE =
  "RESET_PASSWORD_VALIDATE_FAILURE";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";
