import * as types from "./account-settings.type";
import api from "../../../services/api";

export const accountSettingsResult = (obj, type) => {
  return {
    data: obj,
    type: type
  };
};

export const createAccountSettings = (data) => {
  return async (dispatch) => {
    dispatch(
      accountSettingsResult(null, types.CREATE_ACCOUNT_SETTINGS_REQUEST)
    );

    await api.FieldEvaluation.createAccountSettings(data)
      .then((result) => {
        dispatch(
          accountSettingsResult(result, types.CREATE_ACCOUNT_SETTINGS_SUCCESS)
        );
      })
      .catch((result) => {
        dispatch(
          accountSettingsResult(result, types.CREATE_ACCOUNT_SETTINGS_FAILURE)
        );
      });
  };
};

export const resetAccountSettingsForm = () => {
  return async (dispatch) => {
    dispatch(
      accountSettingsResult(null, types.CREATE_ACCOUNT_SETTINGS_INITIALIZE)
    );
  };
};

export const updateForm = (result) => {
  return async (dispatch) => {
    dispatch(accountSettingsResult(result, types.UPDATE_ACCOUNT_SETTINGS_FORM));
  };
};

export const getAccountSettings = (userId) => {
  return async (dispatch) => {
    dispatch(accountSettingsResult(null, types.GET_ACCOUNT_SETTINGS_REQUEST));

    await api.FieldEvaluation.getAccountSettings(userId)
      .then((result) => {
        dispatch(
          accountSettingsResult(result, types.GET_ACCOUNT_SETTINGS_SUCCESS)
        );
      })
      .catch((result) => {
        dispatch(
          accountSettingsResult(result, types.GET_ACCOUNT_SETTINGS_FAILURE)
        );
      });
  };
};

export const getAccountSettingsByPsn = (partySiteNumber) => {
  return async (dispatch) => {
    dispatch(accountSettingsResult(null, types.GET_ACCOUNT_SETTINGS_REQUEST));

    await api.FieldEvaluation.getAccountSettingsByPsn(partySiteNumber)
      .then((result) => {
        dispatch(
          accountSettingsResult(result, types.GET_ACCOUNT_SETTINGS_SUCCESS)
        );
      })
      .catch((result) => {
        dispatch(
          accountSettingsResult(result, types.GET_ACCOUNT_SETTINGS_FAILURE)
        );
      });
  };
};

export const getCountries = (locationType, isForLabel) => {
  return async (dispatch) => {
    dispatch(
      accountSettingsResult(
        null,
        locationType === 1
          ? types.GET_COUNTRIES_REQUEST
          : types.GET_BILLTO_COUNTRIES_REQUEST
      )
    );

    await api.FieldEvaluation.getCountries(isForLabel)
      .then((result) => {
        dispatch(
          accountSettingsResult(
            result,
            locationType === 1
              ? types.GET_COUNTRIES_SUCCESS
              : types.GET_BILLTO_COUNTRIES_SUCCESS
          )
        );
      })
      .catch((result) => {
        dispatch(
          accountSettingsResult(
            result,
            locationType === 1
              ? types.GET_COUNTRIES_FAILURE
              : types.GET_BILLTO_COUNTRIES_FAILURE
          )
        );
      });
  };
};

export const getStates = (countryCode, locationType) => {
  return async (dispatch) => {
    dispatch(
      accountSettingsResult(
        null,
        locationType === 1
          ? types.GET_STATES_REQUEST
          : types.GET_BILLTO_STATES_REQUEST
      )
    );

    await api.FieldEvaluation.getStates(countryCode)
      .then((result) => {
        dispatch(
          accountSettingsResult(
            result,
            locationType === 1
              ? types.GET_STATES_SUCCESS
              : types.GET_BILLTO_STATES_SUCCESS
          )
        );
      })
      .catch((result) => {
        dispatch(
          accountSettingsResult(
            result,
            locationType === 1
              ? types.GET_STATES_FAILURE
              : types.GET_BILLTO_STATES_FAILURE
          )
        );
      });
  };
};

export const getCities = (stateCode, locationType) => {
  return async (dispatch) => {
    dispatch(
      accountSettingsResult(
        null,
        locationType === 1
          ? types.GET_CITIES_REQUEST
          : types.GET_BILLTO_CITIES_REQUEST
      )
    );

    await api.FieldEvaluation.getCities(stateCode)
      .then((result) => {
        dispatch(
          accountSettingsResult(
            result,
            locationType === 1
              ? types.GET_CITIES_SUCCESS
              : types.GET_BILLTO_CITIES_SUCCESS
          )
        );
      })
      .catch((result) => {
        dispatch(
          accountSettingsResult(
            result,
            locationType === 1
              ? types.GET_CITIES_FAILURE
              : types.GET_BILLTO_CITIES_FAILURE
          )
        );
      });
  };
};

export const getCustomerRelations = (
  manufacturerAccountNumber,
  isBillTo,
  itemNumber,
  applicantAccountNumber
) => {
  return async (dispatch) => {
    dispatch(accountSettingsResult(null, types.GET_CUSTOMER_RELATIONS_REQUEST));

    return await api.FieldEvaluation.getCustomerRelations(
      manufacturerAccountNumber,
      isBillTo,
      itemNumber,
      applicantAccountNumber
    )
      .then((result) => {
        dispatch(
          accountSettingsResult(result, types.GET_CUSTOMER_RELATIONS_SUCCESS)
        );
        return result;
      })
      .catch((result) => {
        dispatch(
          accountSettingsResult(result, types.GET_CUSTOMER_RELATIONS_FAILURE)
        );
      });
  };
};

export const getCustomerRelationsShipTo = (accountNumber, isBillTo) => {
  return async (dispatch) => {
    dispatch(
      accountSettingsResult(null, types.GET_CUSTOMER_RELATIONS_SHIPTO_REQUEST)
    );

    await api.FieldEvaluation.getCustomerRelations(accountNumber, isBillTo)
      .then((result) => {
        dispatch(
          accountSettingsResult(
            result,
            types.GET_CUSTOMER_RELATIONS_SHIPTO_SUCCESS
          )
        );
      })
      .catch((result) => {
        dispatch(
          accountSettingsResult(
            result,
            types.GET_CUSTOMER_RELATIONS_SHIPTO_FAILURE
          )
        );
      });
  };
};

export const getCustomerRelationInformations = (accountNumber) => {
  return async (dispatch) => {
    dispatch(
      accountSettingsResult(
        null,
        types.GET_CUSTOMER_RELATION_INFORMATIONS_REQUEST
      )
    );

    await api.FieldEvaluation.getCustomerRelationInformations(accountNumber)
      .then((result) => {
        dispatch(
          accountSettingsResult(
            result,
            types.GET_CUSTOMER_RELATION_INFORMATIONS_SUCCESS
          )
        );
      })
      .catch((result) => {
        dispatch(
          accountSettingsResult(
            result,
            types.GET_CUSTOMER_RELATION_INFORMATIONS_FAILURE
          )
        );
      });
  };
};

export const getCustomerRelationInformationsShipTo = (accountNumber) => {
  return async (dispatch) => {
    dispatch(
      accountSettingsResult(
        null,
        types.GET_CUSTOMER_RELATION_INFORMATIONS_REQUEST_SHIP_TO
      )
    );

    await api.FieldEvaluation.getCustomerRelationInformations(accountNumber)
      .then((result) => {
        dispatch(
          accountSettingsResult(
            result,
            types.GET_CUSTOMER_RELATION_INFORMATIONS_SUCCESS_SHIP_TO
          )
        );
      })
      .catch((result) => {
        dispatch(
          accountSettingsResult(
            result,
            types.GET_CUSTOMER_RELATION_INFORMATIONS_FAILURE_SHIP_TO
          )
        );
      });
  };
};

export const resetContactInformations = () => {
  return async (dispatch) => {
    dispatch(
      accountSettingsResult(null, types.RESET_CUSTOMER_RELATION_INFORMATIONS)
    );
  };
};

export const getApplicantContactInformations = (accountNumber) => {
  return async (dispatch) => {
    dispatch(
      accountSettingsResult(
        null,
        types.GET_APPLICANT_CONTACT_INFORMATIONS_REQUEST
      )
    );

    await api.FieldEvaluation.getCustomerRelationInformations(accountNumber)
      .then((result) => {
        dispatch(
          accountSettingsResult(
            result,
            types.GET_APPLICANT_CONTACT_INFORMATIONS_SUCCESS
          )
        );
      })
      .catch((result) => {
        dispatch(
          accountSettingsResult(
            result,
            types.GET_APPLICANT_CONTACT_INFORMATIONS_FAILURE
          )
        );
      });
  };
};

export const isAccountContactExist = (partySiteNumber, emailAdd) => {
  return async (dispatch) => {
    dispatch(accountSettingsResult(null, types.APPLICANT_CONTACT_REQUEST));

    return await api.FieldEvaluation.isAccountContactExist(
      partySiteNumber,
      emailAdd
    )
      .then((result) => {
        dispatch(
          accountSettingsResult(result, types.APPLICANT_CONTACT_SUCCESS)
        );
        return result;
      })
      .catch((result) => {
        dispatch(
          accountSettingsResult(
            result,
            types.GET_APPLICANT_CONTACT_INFORMATIONS_FAILURE
          )
        );
      });
  };
};

export const isEmailExist = (emailAdd) => {
  return async (dispatch) => {
    dispatch(accountSettingsResult(null, types.EMAIL_CONTACT_EXIST_REQUEST));

    return await api.FieldEvaluation.isEmailExist(emailAdd)
      .then((result) => {
        dispatch(
          accountSettingsResult(result, types.EMAIL_CONTACT_EXIST_SUCCESS)
        );
        return result;
      })
      .catch((result) => {
        dispatch(
          accountSettingsResult(result, types.EMAIL_CONTACT_EXIST_FAILURE)
        );
      });
  };
};

export const updateBillTOAccountNumbers = (billToAccnts) => {
  return async (dispatch) => {
    dispatch(
      accountSettingsResult(billToAccnts, types.UPDATE_BILL_TO_ACCCOUNT_NUMBERS)
    );
  };
};
