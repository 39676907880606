import * as types from "./view-quote-details.type";
import api from "../../../services/api";

export const viewQuoteDetailsResult = (obj, type) => {
  return {
    type: type,
    data: obj
  };
};

export const getQuoteDetails = orderNumber => {
  return async dispatch => {
    dispatch(viewQuoteDetailsResult(null, types.DRAFT_QUOTE_DETAILS_REQUEST));
    await api.FieldEvaluation.getOrderDetails(orderNumber)
      .then(result => {
        dispatch(
          viewQuoteDetailsResult(result, types.DRAFT_QUOTE_DETAILS_SUCCESS)
        );
      })
      .catch(result => {
        console.log(result);
        dispatch(
          viewQuoteDetailsResult(
            result.response && result.response.data,
            types.DRAFT_QUOTE_DETAILS_FAILURE
          )
        );
      });
  };
};
