import React, { Component, Fragment } from "react";
import {
  Accordion,
  Grid,
  Icon,
  Divider,
  Segment,
  Form,
  Table
} from "semantic-ui-react";
import {
  CustomHeaderLabel,
  CustomLabel,
  CustomHeader,
  CustomHeaderBold,
  CustomTitle
} from "../../../components/labels/labels";
import PriceItem from "../product-details/fes/price-item";
import FesPrice from "../product-details/fes/price";

class OrderItemInfo extends Component {
  state = {
    activeIndex: -1,
    isConfirming: false,
    toRemoveId: null
  };

  toggleDisplay = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  render() {
    const { activeIndex } = this.state;
    const { productItems, reservationDetails, orderDetails } = this.props;

    return (
      <Fragment>
        <Segment>
          <Accordion>
            <Accordion.Title
              active={activeIndex === 0}
              index={0}
              onClick={this.toggleDisplay}
            >
              <Grid columns={1}>
                <Grid.Column width={16}>
                  <Icon name="dropdown" />
                  <CustomHeaderLabel message="Equipment Details" />
                </Grid.Column>
              </Grid>
            </Accordion.Title>
            <Accordion.Content
              active={activeIndex === 0}
              className="print-expanded"
            >
              <Divider />
              <Form>
                <Grid>
                  <Grid.Column width={4}>
                    <CustomLabel message="Please review all the site details to confirm they are correct." />

                    <Divider hidden />
                  </Grid.Column>
                  <Grid.Column width={1}>
                    <Divider vertical />
                  </Grid.Column>
                  <Grid.Column width={10}>
                    <Grid>
                      <Grid.Column width={16}>
                        <span>
                          <CustomHeader message="Inspection Location" />
                          <label>
                            <b>
                              {(reservationDetails.preliminarySiteLocationSection &&
                                reservationDetails
                                  .preliminarySiteLocationSection
                                  .residenceName) ||
                                "-NOTHING PROVIDED-"}
                            </b>
                          </label>
                          <label className="custom-label">
                            {(reservationDetails.preliminarySiteLocationSection &&
                              reservationDetails.preliminarySiteLocationSection
                                .inspectionLocation) ||
                              "-NOTHING PROVIDED-"}
                          </label>
                        </span>
                      </Grid.Column>
                      <Grid.Column width={16}>
                        <span>
                          <CustomHeader message="Site Contact" />
                          <label>
                            <b>
                              {(reservationDetails.preliminarySiteLocationSection &&
                                reservationDetails
                                  .preliminarySiteLocationSection
                                  .siteContactName) ||
                                "-NOTHING PROVIDED-"}
                            </b>
                          </label>
                          <label className="custom-label">
                            {`Office #: ${(reservationDetails.preliminarySiteLocationSection &&
                              reservationDetails.preliminarySiteLocationSection
                                .siteContactOfficeNumber) ||
                              "-NOTHING PROVIDED-"} `}
                            {}
                          </label>
                          <label className="custom-label">
                            {`Mobile #: ${(reservationDetails.preliminarySiteLocationSection &&
                              reservationDetails.preliminarySiteLocationSection
                                .siteContactMobileNumber) ||
                              "-NOTHING PROVIDED-"} `}
                            {}
                          </label>
                          <label className="custom-label">
                            {`Email Address: ${(reservationDetails.preliminarySiteLocationSection &&
                              reservationDetails.preliminarySiteLocationSection
                                .siteContactEmailAddress) ||
                              "-NOTHING PROVIDED-"} `}
                            {}
                          </label>
                        </span>
                      </Grid.Column>
                      <Grid.Column width={16}>
                        <span>
                          <CustomHeader message="Equipment Details" />
                        </span>

                        <Table celled structured>
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell>#</Table.HeaderCell>
                              <Table.HeaderCell>
                                Product Category
                              </Table.HeaderCell>
                              <Table.HeaderCell>
                                Equipment Type
                              </Table.HeaderCell>
                              <Table.HeaderCell>Quantity</Table.HeaderCell>
                              {orderDetails && orderDetails.status != 1 && (
                                <Table.HeaderCell>Price</Table.HeaderCell>
                              )}
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            {productItems && productItems.length > 0
                              ? productItems.map((item, idx) =>
                                  orderDetails && orderDetails.status != 1 ? (
                                    <React.Fragment key={idx}>
                                      <PriceItem
                                        key={idx}
                                        item={item}
                                        idx={idx}
                                        isQuotation={true}
                                      />
                                      {Object.keys(item.others).map(o => {
                                        return (
                                          <Table.Row key={o}>
                                            <Table.Cell textAlign="right">
                                              Truck #
                                            </Table.Cell>
                                            <Table.Cell>
                                              {item.others[o]}
                                            </Table.Cell>
                                            <Table.Cell></Table.Cell>
                                            <Table.Cell></Table.Cell>
                                          </Table.Row>
                                        );
                                      })}
                                    </React.Fragment>
                                  ) : (
                                    <React.Fragment key={idx}>
                                      <PriceItem
                                        key={idx}
                                        item={item}
                                        idx={idx}
                                        isQuotation={false}
                                      />
                                      {Object.keys(item.others).map(o => {
                                        return (
                                          <Table.Row key={o}>
                                            <Table.Cell textAlign="right">
                                              Truck #
                                            </Table.Cell>
                                            <Table.Cell>
                                              {item.others[o]}
                                            </Table.Cell>
                                            <Table.Cell></Table.Cell>
                                          </Table.Row>
                                        );
                                      })}
                                    </React.Fragment>
                                  )
                                )
                              : null}
                            {orderDetails && orderDetails.status != 1 && (
                              <Table.Row>
                                <Table.Cell></Table.Cell>
                                <Table.Cell></Table.Cell>
                                <Table.Cell></Table.Cell>
                                <Table.Cell textAlign="right">
                                  <b>Net Total</b>
                                </Table.Cell>
                                <Table.Cell>
                                  {orderDetails.netTotal
                                    ? `USD ${orderDetails.netTotal}`
                                    : `0.00`}
                                </Table.Cell>
                              </Table.Row>
                            )}
                          </Table.Body>
                        </Table>
                      </Grid.Column>
                      {/* <Grid.Column width={16}>
                        <span>
                          <CustomHeader message="Schedulling details" />
                          <label>
                            <b>Ready to schedule?</b>
                          </label>{" "}
                          &nbsp;
                          <label>{"-NOTHING PROVIDED-"}</label>
                          <br />
                          <label>
                            <b>Preffered start time</b>
                          </label>{" "}
                          &nbsp;
                          <label>{"-NOTHING PROVIDED-"}</label>
                          <br />
                          <label>
                            <b>On-site security steps?</b>
                          </label>{" "}
                          &nbsp;
                          <label>{"-NOTHING PROVIDED-"}</label>
                          <br />
                          <label>
                            <b>On-site safety training?</b>
                          </label>{" "}
                          &nbsp;
                          <label>{"-NOTHING PROVIDED-"}</label>
                          <br />
                          <label>
                            <b>Training more that two hours?</b>
                          </label>{" "}
                          &nbsp;
                          <label>{"-NOTHING PROVIDED-"}</label>
                          <br />
                          <label>
                            <b>Fall protection harness needed?</b>
                          </label>{" "}
                          &nbsp;
                          <label>{"-NOTHING PROVIDED-"}</label>
                        </span>
                      </Grid.Column> */}
                      {/* <Grid.Column width={16}>
                        <span>
                          <CustomHeader message="AHJ" />
                          <label>
                            <b>Adirondack Jurisdiction</b>
                          </label>
                          <label className="custom-label">
                            {"-NOTHING PROVIDED-"}
                          </label>
                          <br />
                          <label>
                            <b>Max Mustermann</b>
                          </label>
                          <label className="custom-label">
                            {"-NOTHING PROVIDED-"}
                          </label>
                        </span>
                      </Grid.Column> */}
                    </Grid>
                  </Grid.Column>
                </Grid>
              </Form>
            </Accordion.Content>
          </Accordion>
        </Segment>
      </Fragment>
    );
  }
}

export default OrderItemInfo;
