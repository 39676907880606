import { Common } from "../common";
import IdentityProvider from "../enums/identity-provider";
import { doLogout, doExternalLogout } from "../views/auth/auth.action";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";

const API_BASE = `${process.env.REACT_APP_API_BASE}`;
const MYUL_URL = `${process.env.REACT_APP_MYUL_URL}`;
const IDENTITY_PROVIDER = `${process.env.REACT_APP_IDENTITY_PROVIDER}`;

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_INSIGHTS_INSTRUMENTATION_KEY
  }
});
appInsights.loadAppInsights();

const checkTokenExpirationMiddleware = (store) => (next) => (action) => {
  const expiration = Common.getTokenExpiration();
  const expirationIam = Common.getTokenExpirationIam();

  appInsights.trackPageView({ name: `Page Accessed: ${window.location.href}` });
  appInsights.trackPageViewPerformance({
    name: action.type,
    url: window.location.href
  });

  if (IDENTITY_PROVIDER == IdentityProvider.B2c) {
    if (!expiration) {
      if (window.location.href.indexOf("callback?code") == -1) {
        localStorage.setItem("framesLength", window.parent.frames.length);

        Common.toggleLogin();
      }
    }
  } else if (IDENTITY_PROVIDER == IdentityProvider.Iam) {
    if (!expirationIam) {
      localStorage.setItem("framesLength", window.parent.frames.length);
      var externalLogoutURL = `${API_BASE}auth/logout`;
      window.top.location.href = externalLogoutURL;
    }
  } else {
    if (!expiration) {
      if (window.location.href.indexOf("callback?code") == -1) {
        localStorage.setItem("framesLength", window.parent.frames.length);

        Common.toggleLogin();
      }
    }
  }

  next(action);
};
export default checkTokenExpirationMiddleware;
