export const CLEAR = "CLEAR";
export const RESET = "RESET";

export const LIST_CANCEL_ORDER_REQUEST = "LIST_CANCEL_ORDER_REQUEST";
export const LIST_CANCEL_ORDER_SUCCESS = "LIST_CANCEL_ORDER_SUCCESS";
export const LIST_CANCEL_ORDER_FAILURE = "LIST_CANCEL_ORDER_FAILURE";
export const LIST_CANCEL_ORDER_CHANGE_PAGE = "LIST_CANCEL_ORDER_CHANGE_PAGE";

export const CANCEL_ORDER_REQUEST = "CANCEL_ORDER_REQUEST";
export const CANCEL_ORDER_SUCCESS = "CANCEL_ORDER_SUCCESS";
export const CANCEL_ORDER_FAILURE = "CANCEL_ORDER_FAILURE";

export const REQUEST_TO_CANCEL_REQUEST = "REQUEST_TO_CANCEL_REQUEST";
export const REQUEST_TO_CANCEL_SUCCESS = "REQUEST_TO_CANCEL_SUCCESS";
export const REQUEST_TO_CANCEL_FAILURE = "REQUEST_TO_CANCEL_FAILURE";

export const NOTIFICATION_COUNT = "NOTIFICATION_COUNT";

export const CANCELLATION_CODE_REQUEST = "CANCELLATION_CODE_REQUEST";
export const CANCELLATION_CODE_SUCCESS = "CANCELLATION_CODE_SUCCESS";
export const CANCELLATION_CODE_FAILURE = "CANCELLATION_CODE_FAILURE";
export const CANCELLATION_CODE_UPDATE = "CANCELLATION_CODE_UPDATE";
