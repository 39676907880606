import * as types from "./scheduling-details.type";

let initialState = {
  schedulingForms: []
};

const schedulingDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
    case types.UPDATE_SCHEDULING_FORM:
      return Object.assign({}, state, {
        schedulingForms: action.data
      });
    case types.RENDER_SCHEDULING_FORM:
      var result = Object.assign({}, state, {
        data: action.data || initialState.data
      });

      var siteCount = action.data;

      result.schedulingForms = [];

      for (let i = 0; i < siteCount; i++) {
        var newForm = new Object(null);
        newForm = {
          data: [],
          preliminarySiteLocationSection: {
            data: null,
            siteType: 1,
            showErrors: false,
            validationErrors: {},
            ppeSelections: "",
            trainingSelections: ""
          },
          finalSiteLocationSection: {
            data: null,
            siteType: 2,
            showErrors: false,
            validationErrors: {}
          },
          authorityHavingJurisdictionDetails: {
            data: null,
            showErrors: false,
            validationErrors: {}
          },
          others: {},
          ppeSelections: [],
          trainingSelections: [],
          isSiteDetails: false,
          showErrors: false,
          validationErrors: {}
        };
        result.schedulingForms.push(newForm);
      }

      return result;
    case types.COUNTRIES_PRELIM_ENGR_REQUEST:
      return {
        ...state,
        isSuccessGetCountries: false,
        countryOptionsPrelimEngineerDetails: []
      };
    case types.COUNTRIES_PRELIM_ENGR_SUCCESS:
      return {
        ...state,
        isSuccessGetCountries: true,
        countryOptionsPrelimEngineerDetails: action.data.map((c) => {
          return {
            key: c.countryCode,
            value: c.countryCode,
            text: c.countryName
          };
        })
      };
    case types.COUNTRIES_PRELIM_ENGR_FAILURE:
      return {
        ...state,
        isSuccessGetCountries: false,
        countryOptionsPrelimEngineerDetails: []
      };
    case types.CITIES_PRELIM_ENGR_REQUEST:
      return {
        ...state,
        isSuccessGetCities: false,
        cityOptionsPrelimEngineerDetails: []
      };
    case types.CITIES_PRELIM_ENGR_SUCCESS:
      return {
        ...state,
        isSuccessGetCities: true,
        cityOptionsPrelimEngineerDetails: action.data.map((c) => {
          return {
            key: c.cityName,
            value: c.cityName,
            text: c.cityName
          };
        })
      };
    case types.CITIES_PRELIM_ENGR_FAILURE:
      return {
        ...state,
        isSuccessGetCities: false,
        cityOptionsPrelimEngineerDetails: []
      };
    case types.STATES_PRELIM_ENGR_REQUEST:
      return {
        ...state,
        isSuccessGetStates: false,
        stateOptionsPrelimEngineerDetails: []
      };
    case types.STATES_PRELIM_ENGR_SUCCESS:
      return {
        ...state,
        isSuccessGetStates: true,
        stateOptionsPrelimEngineerDetails: action.data.map((c) => {
          return {
            key: c.stateCode,
            value: c.stateCode,
            text: c.stateName
          };
        })
      };
    case types.STATES_PRELIM_ENGR_FAILURE:
      return {
        ...state,
        isSuccessGetStates: false,
        stateOptionsPrelimEngineerDetails: []
      };
    case types.COUNTRIES_FINAL_ENGR_REQUEST:
      return {
        ...state,
        isSuccessGetCountries: false,
        countryOptionsFinalEngineerDetails: []
      };
    case types.COUNTRIES_FINAL_ENGR_SUCCESS:
      return {
        ...state,
        isSuccessGetCountries: true,
        countryOptionsFinalEngineerDetails: action.data.map((c) => {
          return {
            key: c.countryCode,
            value: c.countryCode,
            text: c.countryName
          };
        })
      };
    case types.COUNTRIES_FINAL_ENGR_FAILURE:
      return {
        ...state,
        isSuccessGetCountries: false,
        countryOptionsFinalEngineerDetails: []
      };
    case types.CITIES_FINAL_ENGR_REQUEST:
      return {
        ...state,
        isSuccessGetCities: false,
        cityOptionsFinalEngineerDetails: []
      };
    case types.CITIES_FINAL_ENGR_SUCCESS:
      return {
        ...state,
        isSuccessGetCities: true,
        cityOptionsFinalEngineerDetails: action.data.map((c) => {
          return {
            key: c.cityName,
            value: c.cityName,
            text: c.cityName
          };
        })
      };
    case types.CITIES_FINAL_ENGR_FAILURE:
      return {
        ...state,
        isSuccessGetCities: false,
        cityOptionsFinalEngineerDetails: []
      };
    case types.STATES_FINAL_ENGR_REQUEST:
      return {
        ...state,
        isSuccessGetStates: false,
        stateOptionsFinalEngineerDetails: []
      };
    case types.STATES_FINAL_ENGR_SUCCESS:
      return {
        ...state,
        isSuccessGetStates: true,
        stateOptionsFinalEngineerDetails: action.data.map((c) => {
          return {
            key: c.stateCode,
            value: c.stateCode,
            text: c.stateName
          };
        })
      };
    case types.STATES_FINAL_ENGR_FAILURE:
      return {
        ...state,
        isSuccessGetStates: false,
        stateOptionsFinalEngineerDetails: []
      };
    case types.COUNTRIES_AHJ_REQUEST:
      return {
        ...state,
        isSuccessGetCountries: false,
        countryOptionsAhjDetails: []
      };
    case types.COUNTRIES_AHJ_SUCCESS:
      return {
        ...state,
        isSuccessGetCountries: true,
        countryOptionsAhjDetails: action.data.map((c) => {
          return {
            key: c.countryCode,
            value: c.countryCode,
            text: c.countryName
          };
        })
      };
    case types.COUNTRIES_AHJ_FAILURE:
      return {
        ...state,
        isSuccessGetCountries: false,
        countryOptionsAhjDetails: []
      };
    case types.CITIES_AHJ_REQUEST:
      return {
        ...state,
        isSuccessGetCities: false,
        cityOptionsAhjDetails: []
      };
    case types.CITIES_AHJ_SUCCESS:
      return {
        ...state,
        isSuccessGetCities: true,
        cityOptionsAhjDetails: action.data.map((c) => {
          return {
            key: c.cityName,
            value: c.cityName,
            text: c.cityName
          };
        })
      };
    case types.CITIES_AHJ_FAILURE:
      return {
        ...state,
        isSuccessGetCities: false,
        cityOptionsAhjDetails: []
      };
    case types.STATES_AHJ_REQUEST:
      return {
        ...state,
        isSuccessGetStates: false,
        stateOptionsAhjDetails: []
      };
    case types.STATES_AHJ_SUCCESS:
      return {
        ...state,
        isSuccessGetStates: true,
        stateOptionsAhjDetails: action.data.map((c) => {
          return {
            key: c.stateCode,
            value: c.stateCode,
            text: c.stateName
          };
        })
      };
    case types.STATES_AHJ_FAILURE:
      return {
        ...state,
        isSuccessGetStates: false,
        stateOptionsAhjDetails: []
      };
    case types.GET_PPE_REQUEST:
      var result = Object.assign({}, state, {
        data: initialState.data,
        form: initialState.form
      });

      result.schedulingForms.forEach((formItem) => {
        formItem.ppeSelections = [];
      });

      return result;
    case types.GET_PPE_SUCCESS:
      var result = Object.assign({}, state, {
        data: initialState.data,
        form: initialState.form
      });

      result.schedulingForms.forEach((formItem) => {
        formItem.ppeSelections = action.data.map((c) => {
          return {
            value: c.id,
            text: c.description,
            checked: false
          };
        });
      });

      return result;
    case types.GET_PPE_FAILURE:
      var result = Object.assign({}, state, {
        data: initialState.data,
        form: initialState.form
      });

      result.schedulingForms.forEach((formItem) => {
        formItem.ppeSelections = [];
      });

      return result;
    case types.GET_TRAININGS_REQUEST:
      var result = Object.assign({}, state, {
        data: initialState.data,
        form: initialState.form
      });

      result.schedulingForms.forEach((formItem) => {
        formItem.trainingSelections = [];
      });

      return result;
    case types.GET_TRAININGS_SUCCESS:
      var result = Object.assign({}, state, {
        data: initialState.data,
        form: initialState.form
      });

      result.schedulingForms.forEach((formItem) => {
        formItem.trainingSelections = action.data.map((c) => {
          return {
            value: c.id,
            text: c.description,
            checked: false
          };
        });
      });

      return result;
    case types.GET_TRAININGS_FAILURE:
      var result = Object.assign({}, state, {
        data: initialState.data,
        form: initialState.form
      });

      result.schedulingForms.forEach((formItem) => {
        formItem.trainingSelections = [];
      });

      return result;
    case types.UPDATE_SELECTED_PPES:
      var result = Object.assign({}, state, {
        data: initialState.data,
        form: initialState.form
      });

      var ppeSelections =
        result.schedulingForms[action.data.tabIndex].ppeSelections;

      ppeSelections.forEach((ppe) => {
        if (ppe.value === action.data.ppe) {
          ppe.checked = action.data.checked;
        }
      });

      result.schedulingForms[
        action.data.tabIndex
      ].ppeSelections = ppeSelections;

      var checkedPpeIds = ppeSelections
        .filter((p) => p.checked)
        .map((ppe) => {
          return ppe.value;
        });

      result.schedulingForms[
        action.data.tabIndex
      ].preliminarySiteLocationSection.ppeSelections = checkedPpeIds.join("|");

      return result;
    case types.UPDATE_SELECTED_TRAININGS:
      var result = Object.assign({}, state, {
        data: initialState.data,
        form: initialState.form
      });

      var trainingSelections =
        result.schedulingForms[action.data.tabIndex].trainingSelections;

      trainingSelections.forEach((item) => {
        if (item.value === action.data.training) {
          item.checked = action.data.checked;
          item.trainingTwoHours = action.data.trainingTwoHours;
        }
      });

      result.schedulingForms[
        action.data.tabIndex
      ].trainingSelections = trainingSelections;

      var checkedTrainingIds = trainingSelections
        .filter((p) => p.checked)
        .map((item) => {
          return item.value;
        });
      result.schedulingForms[
        action.data.tabIndex
      ].preliminarySiteLocationSection.trainingSelections = checkedTrainingIds.join(
        "|"
      );
      // index 0 for onsite training is 2 hrs
      result.schedulingForms[
        action.data.tabIndex
      ].preliminarySiteLocationSection.isTrainingTwoHours = trainingSelections[0]
        .checked
        ? trainingSelections[0].trainingTwoHours
        : null;

      return result;
    case types.POPULATE_SCHEDULING_FORM:
      var result = Object.assign({}, state, {
        data: action.data || initialState.data
      });

      var index = action.data.index;
      var siteDetailForm = action.data.data[index];
      var schedulingForm = result.schedulingForms[index];
      // result.forms[index].preliminarySiteLocationSection =
      //   siteDetailForm.prelim;
      // result.forms[index].finalSiteLocationSection = siteDetailForm.final;
      // result.forms[index].authorityHavingJurisdictionDetails =
      //   siteDetailForm.ahj;

      schedulingForm.preliminarySiteLocationSection = siteDetailForm.prelim;
      schedulingForm.finalSiteLocationSection = siteDetailForm.final;
      schedulingForm.authorityHavingJurisdictionDetails = siteDetailForm.ahj;
      schedulingForm.others = siteDetailForm.others;
      return result;
    case types.RENDER_SCHEDULING_DETAILS:
      var result = Object.assign({}, state, {});
      var siteCount = action.data.count;
      var quote = action.data.data;

      result.schedulingForms = [];
      for (let i = 0; i < siteCount; i++) {
        var acceptance = quote.quoteAcceptances.filter(
          (f) => f.tabIndex == i
        )[0];
        var engineers = quote.engineers.filter((f) => f.tabIndex == i);
        var ppes = quote.orderDetails.ppes;
        var trainings = quote.orderDetails.trainings;
        var selectedPpes = engineers.filter((f) => f.siteType === 1)[0]
          .ppeSelections;
        var selectedTrainings = engineers.filter((f) => f.siteType === 1)[0]
          .trainingSelections;
        var isTrainingTwoHours = engineers.filter((f) => f.siteType === 1)[0]
          .isTrainingTwoHours;

        var newForm = {
          data: [],
          preliminarySiteLocationSection: engineers.filter(
            (f) => f.siteType === 1
          )[0],
          finalSiteLocationSection: engineers.filter(
            (f) => f.siteType === 2
          )[0],
          authorityHavingJurisdictionDetails: quote.authorityJurisdictions.filter(
            (f) => f.baseType === 2 && f.tabIndex == i
          )[0],
          ppeSelections: ppes.map((c) => {
            return {
              value: c.id,
              text: c.description,
              checked: (selectedPpes || []).indexOf(c.id) !== -1 ? true : false
            };
          }),
          trainingSelections: trainings.map((c) => {
            return {
              value: c.id,
              text: c.description,
              checked:
                (selectedTrainings || []).indexOf(c.id) !== -1 ? true : false,
              trainingTwoHours: isTrainingTwoHours
            };
          }),
          others: {
            isReadyToScheduleNow: acceptance.isReadyToScheduleNow,
            customerReferenceNumber: acceptance.customerReferenceNumber,
            data: {},
            showErrors: false,
            validationErrors: {},
            specialNotesList:
              (
                (quote.products || []).find((s) => (s || {}).tabIndex === i) ||
                {}
              ).specialNotes || []
            // (quote.products.find((s) => s.tabIndex == i) || {})
            //   .specialNotes || []
          },
          isSiteDetails: false,
          showErrors: false,
          validationErrors: {}
        };

        var scheduleUnixTime =
          newForm.preliminarySiteLocationSection.scheduleUnixTime;
        newForm.preliminarySiteLocationSection.scheduleDate = scheduleUnixTime
          ? new Date(scheduleUnixTime * 1000)
          : null;

        if (newForm.preliminarySiteLocationSection) {
          newForm.preliminarySiteLocationSection.data = {};
          newForm.preliminarySiteLocationSection.showErrors = false;
          newForm.preliminarySiteLocationSection.validationErrors = {};
        }

        if (newForm.finalSiteLocationSection) {
          newForm.finalSiteLocationSection.data = {};
          newForm.finalSiteLocationSection.showErrors = false;
          newForm.finalSiteLocationSection.validationErrors = {};
        }

        if (newForm.authorityHavingJurisdictionDetails) {
          newForm.authorityHavingJurisdictionDetails.data = {};
          newForm.authorityHavingJurisdictionDetails.showErrors = false;
          newForm.authorityHavingJurisdictionDetails.validationErrors = {};
        }

        if (newForm.others) {
          newForm.others.showPrelimLocation =
            newForm?.preliminarySiteLocationSection?.residenceName == null
              ? "true"
              : "false";
        }

        result.schedulingForms.push(newForm);
      }

      return result;
  }
};

export default schedulingDetailsReducer;
