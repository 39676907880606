import React, { Component, Fragment } from "react";
import {
  Accordion,
  Grid,
  Icon,
  Segment,
  Divider,
  Form,
  Table
} from "semantic-ui-react";
import { Common } from "../../common";
import {
  CustomHeaderLabel,
  CustomHeaderBold,
  SubText,
  CustomHeaderLabel2
} from "../../components/labels/labels";

class LabelOrderInfo extends Component {
  state = {
    activeIndex: 0
  };

  toggleDisplay = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  render() {
    const { activeIndex } = this.state;
    const { data, labelOrderData } = this.props;
    const { customerDetails } = data;
    const translations = Common.getTranslations() || {};

    let email = customerDetails && customerDetails.combintationCustomerEmail;
    let phone = customerDetails && customerDetails.combintationCustomerPhone;

    return (
      <Fragment>
        <Grid style={{ padding: "0px 25px 25px 25px" }}>
          <Grid.Row stretched>
            <Grid.Column width="7">
              <table>
                <tr>
                  <td
                    className="label-cell"
                    style={{
                      color: "#F6CD91"
                    }}
                  >
                    <Icon name="small exclamation circle" />
                  </td>
                  <td>
                    <span style={{ fontSize: "10px" }}>
                      {
                        translations[
                          "LexPleasereviewtheinformationOrderHistory"
                        ]
                      }{" "}
                      &nbsp;
                      <a
                        href="https://marks.ul.com/about/ul-listing-and-classification-marks/labels/label-centers"
                        target="_blank"
                      >
                        {translations["LexContactLabelCenter"]}
                      </a>
                    </span>
                  </td>
                </tr>
              </table>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width="6" style={{ borderRight: "2px solid #EBEBEB" }}>
              <table style={{ width: "100%" }} className="details-table">
                <tr>
                  <td className="section-header" colSpan={2}>
                    <CustomHeaderLabel2
                      message={translations["LexYourInformation"]}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>{translations["LexName"]}</b>
                  </td>
                  <td>
                    {customerDetails.combintationCustomerName}
                    {/* {`${Common.getLastName()}, ${Common.getFirstName()}`} */}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>{translations["LexAccountNumber"]}</b>
                  </td>
                  <td>{customerDetails.accountNumber}</td>
                </tr>
                <tr>
                  <td style={{ width: "30%" }}>
                    <b>Party Site Number</b>
                  </td>
                  <td>{customerDetails.psn}</td>
                </tr>
                <tr>
                  <td>
                    <b>{translations["LexEmail"]}</b>
                  </td>
                  <td>{email}</td>
                </tr>
                <tr>
                  <td>
                    <b>{translations["LexPhoneNumber"]}</b>
                  </td>
                  <td>{phone}</td>
                </tr>
              </table>
              <Divider hidden />
              <table style={{ width: "100%" }} className="details-table">
                <tr>
                  <td className="section-header" colSpan={2}>
                    <CustomHeaderLabel2
                      message={translations["LexApplicantsDetails"]}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>{translations["LexAccountNumber"]}</b>
                  </td>
                  <td>{(data && data.applicantAccountNumber) || "--"}</td>
                </tr>
                <tr>
                  <td>
                    <b>{translations["LexCompany"]}</b>
                  </td>
                  <td>{(data && data.applicantCompany) || "--"}</td>
                </tr>
                <tr>
                  <td style={{ width: "30%" }}>
                    <b>{translations["LexAddress"]}</b>
                  </td>
                  <td>{(data && data.applicantAddress) || "--"}</td>
                </tr>
                <tr>
                  <td style={{ width: "30%" }}>
                    <b>{translations["LexCountry"]}</b>
                  </td>
                  <td>{(data && data.applicantCountry) || "--"}</td>
                </tr>
                <tr>
                  <td>
                    <b>{translations["LexContactName"]}</b>
                  </td>
                  <td>{(data && data.applicantContactName) || "--"}</td>
                </tr>
              </table>
              <Divider hidden />
              <table style={{ width: "100%" }} className="details-table">
                <tr>
                  <td className="section-header" colSpan={2}>
                    <CustomHeaderLabel2
                      message={translations["LexManufacturesDetails"]}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>{translations["LexAccountNumber"]}</b>
                  </td>
                  <td>{(data && data.manufacturerAccountNumber) || "--"}</td>
                </tr>
                <tr>
                  <td>
                    <b>Party Site Number</b>
                  </td>
                  <td>{(data && data.manufacturerPartySiteNumber) || "--"}</td>
                </tr>
                <tr>
                  <td>
                    <b>{translations["LexCompany"]}</b>
                  </td>
                  <td>{(data && data.manufacturerName) || "--"}</td>
                </tr>
                <tr>
                  <td style={{ width: "30%" }}>
                    <b>{translations["LexAddress"]}</b>
                  </td>
                  <td>{(data && data.shipToValue) || "--"}</td>
                </tr>
                <tr>
                  <td>
                    <b>{translations["LexCountry"]}</b>
                  </td>
                  <td>{(data && data.shipToCountry) || "--"}</td>
                </tr>
                <tr>
                  <td>
                    <b>{translations["LexContactName"]}</b>
                  </td>
                  <td>{(data && data.shipToContactName) || "--"}</td>
                </tr>
                <tr>
                  <td>
                    <b>{translations["LexContactEmail"]}</b>
                  </td>
                  <td>{data && data.customerEmailAddress}</td>
                </tr>
                <tr>
                  <td>
                    <b>{translations["LexPhoneNumber"]}</b>
                  </td>
                  <td>{data && data.customerPhoneNumber}</td>
                </tr>
              </table>
            </Grid.Column>
            <Grid.Column width="6">
              <table style={{ width: "100%" }} className="details-table">
                <tr>
                  <td className="section-header" colSpan={2}>
                    <CustomHeaderLabel2
                      message={translations["LexBilltoDetails"]}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>{translations["LexAccountNumber"]}</b>
                  </td>
                  <td>{(data && data.billToAccountNo) || "--"}</td>
                </tr>
                <tr>
                  <td>
                    <b>{translations["LexCompany"]}</b>
                  </td>
                  <td>{(data && data.billToCompany) || "--"}</td>
                </tr>
                <tr>
                  <td style={{ width: "30%" }}>
                    <b>{translations["LexAddress"]}</b>
                  </td>
                  <td>{(data && data.billToValue) || "--"}</td>
                </tr>
                <tr>
                  <td>
                    <b>{translations["LexCountry"]}</b>
                  </td>
                  <td>{(data && data.billToCountry) || "--"}</td>
                </tr>
                <tr>
                  <td>
                    <b>{translations["LexContactName"]}</b>
                  </td>
                  <td>{(data && data.billToContactName) || "--"}</td>
                </tr>
                <tr>
                  <td>
                    <b>{translations["LexContactEmail"]}</b>
                  </td>
                  <td>{data && data.billToContactEmail}</td>
                </tr>
              </table>
              <span
                hidden={labelOrderData && labelOrderData.labelOrderType == 1}
              >
                <Divider hidden />
                <table style={{ width: "100%" }} className="details-table">
                  <tr>
                    <td className="section-header" colSpan={2}>
                      <CustomHeaderLabel2
                        message={
                          translations["LexAuthorizedLabelSupplierDetails"]
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>{translations["LexFileNo."]}</b>
                    </td>
                    <td>{(data && data.labelSupplierFileNumber) || "--"}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>{translations["LexCompany"]}</b>
                    </td>
                    <td>{(data && data.labelSupplierName) || "--"}</td>
                  </tr>
                  <tr>
                    <td style={{ width: "30%" }}>
                      <b>{translations["LexAddress"]}</b>
                    </td>
                    <td>{(data && data.labelSupplierAddress) || "--"}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>{translations["LexEmail"]}</b>
                    </td>
                    <td>{data && data.labelSupplierEmailAddress}</td>
                  </tr>
                </table>
              </span>
              <Divider hidden />
              <table style={{ width: "100%" }} className="details-table">
                <tr>
                  <td className="section-header" colSpan={2}>
                    <CustomHeaderLabel2
                      message={translations["LexOtherDetails"]}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>{translations["LexLabelCenter"]}</b>
                  </td>
                  <td>{(data && data.labelCenterName) || "--"}</td>
                </tr>
                <tr>
                  <td>
                    <b>{translations["LexPurchaseOrderNumber"]}</b>
                  </td>
                  <td>{(data && data.customerPoNumber) || "--"}</td>
                </tr>
                <tr>
                  <td style={{ width: "30%" }}>
                    <b>{translations["LexCartNumber"]}</b>
                  </td>
                  <td>{(data && data.requestNumber) || "--"}</td>
                </tr>
                <tr>
                  <td>
                    <b>{translations["LexSalesOrderNumber"]}</b>
                  </td>
                  <td>{(data && data.oracleNumber) || "--"}</td>
                </tr>
                <tr
                  hidden={labelOrderData && labelOrderData.labelOrderType == 2}
                >
                  <td>
                    <b>{translations["LexShippingOptions"]}</b>
                  </td>
                  <td>{(data && data.shippingOptionName) || "--"}</td>
                </tr>
                <tr
                  hidden={labelOrderData && labelOrderData.labelOrderType == 2}
                >
                  <td>
                    <b>{translations["LexShippingDetails"]}</b>
                  </td>
                  <td>{(data && data.shippingDetails) || "--"}</td>
                </tr>
                <tr
                  hidden={labelOrderData && labelOrderData.labelOrderType == 2}
                >
                  <td>
                    <b>{translations["LexCourierAccountNumber"]}</b>
                  </td>
                  <td>{(data && data.courierAccountNumber) || "--"}</td>
                </tr>
                <tr>
                  <td>
                    <b>{translations["LexBrokerInformationwoOptinal"]}</b>
                  </td>
                  <td>{(data && data.brokerInformation) || "--"}</td>
                </tr>
              </table>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Fragment>
    );
  }
}

export default React.memo(LabelOrderInfo);
