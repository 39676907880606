import React, { Component } from "react";
import PropTypes from "prop-types";
import { Modal, Header, Button } from "semantic-ui-react";

class AuthorizeNDLabelModal extends Component {
  render() {
    const {
      isShowAuthorizeNDLabelModal,
      handleAuthorizeNDLabelModal,
      onConfirmAuthorizeNDLabel,
      authorizeNDLabelLoading,
      data
    } = this.props;

    return (
      <Modal open={isShowAuthorizeNDLabelModal} size="mini">
        <Modal.Header>
          <Header icon="info circle" textAlign="center" />
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <p>
              {data.isAuthorizedNDLabel ? "Unauthorizing" : "Authorizing"}
              <b> {data.email}</b>. Are you sure?
            </p>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            className={authorizeNDLabelLoading}
            onClick={onConfirmAuthorizeNDLabel.bind(this, data)}
          >
            Yes
          </Button>
          <Button
            onClick={handleAuthorizeNDLabelModal.bind(this, false)}
            className={authorizeNDLabelLoading}
          >
            Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

AuthorizeNDLabelModal.propTypes = {
  isShowAuthorizeNDLabelModal: PropTypes.bool.isRequired,
  handleAuthorizeNDLabelModal: PropTypes.func.isRequired,
  onConfirmAuthorizeNDLabel: PropTypes.func.isRequired,
  authorizeNDLabelLoading: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired
};

export default AuthorizeNDLabelModal;
