import * as types from "./product-details.types";

let initialState = {
  data: [],
  form: {
    isInstalledSameLocation: true,
    showErrors: false,
    validationErrors: {}
  },
  area: {},
  serviceArea: {},
  serviceProduct: {},
  serviceSubProduct: {},
  productPrice: {}
};

const productDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
    case types.MYFORM:
      var result = Object.assign({}, state, {
        form: action.data || initialState.form
      });
      return result;
    case types.AREA_REQUEST:
    case types.AREA_SUCCESS:
    case types.AREA_FAILURE:
      return Object.assign({}, state, {
        area: action.data || initialState.area
      });
    case types.SERVICE_AREA_REQUEST:
    case types.SERVICE_AREA_SUCCESS:
    case types.SERVICE_AREA_FAILURE:
      return Object.assign({}, state, {
        serviceArea: action.data || initialState.serviceArea
      });
    case types.SERVICE_AREA_CLEAR:
      return Object.assign({}, state, {
        serviceArea: initialState.serviceArea
      });
    case types.SERVICE_PRODUCT_REQUEST:
    case types.SERVICE_PRODUCT_SUCCESS:
    case types.SERVICE_PRODUCT_FAILURE:
      return Object.assign({}, state, {
        serviceProduct: action.data || initialState.serviceProduct
      });
    case types.SERVICE_PRODUCT_CLEAR:
      return Object.assign({}, state, {
        serviceProduct: initialState.serviceProduct
      });
    case types.SERVICE_SUB_PRODUCT_REQUEST:
    case types.SERVICE_SUB_PRODUCT_SUCCESS:
    case types.SERVICE_SUB_PRODUCT_FAILURE:
      return Object.assign({}, state, {
        serviceSubProduct: action.data || initialState.serviceSubProduct
      });
    case types.SERVICE_SUB_PRODUCT_CLEAR:
      return Object.assign({}, state, {
        serviceSubProduct: initialState.serviceSubProduct
      });
    case types.PRODUCT_PRICE_REQUEST:
    case types.PRODUCT_PRICE_SUCCESS:
    case types.PRODUCT_PRICE_FAILURE:
      return Object.assign({}, state, {
        productPrice: action.data || initialState.productPrice
      });
    case types.PRODUCT_PRICE_CLEAR:
      return Object.assign({}, state, {
        productPrice: initialState.productPrice
      });
    case types.CLEAR:
      var result = Object.assign({}, state, {
        form: initialState.form
      });
      let initialKeys = [
        "isInstalledSameLocation",
        "showErrors",
        "validationErrors"
      ];
      Object.keys(result.form).map((key) => {
        if (!initialKeys.includes(key)) delete result.form[key];

        result.form.validationErrors = {};
      });
      return result;
    case types.GET_PRODUCT_DETAILS_REQUEST:
      var result = Object.assign({}, state, {
        data: initialState.data,
        form: initialState.form
      });
      result.form = {
        isInstalledSameLocation: true,
        showErrors: false,
        validationErrors: {}
      };
      return result;
    case types.GET_PRODUCT_DETAILS_SUCCESS:
      var result = Object.assign({}, state, {
        data: initialState.data,
        form: initialState.form
      });

      result.form.id = action.data.id;
      result.form.serviceProductName = action.data.productCategory;
      result.form.serviceProductCode = action.data.productCategoryCode;
      result.form.serviceSubProductId = action.data.serviceSubProductId;
      result.form.serviceSubProductCode = action.data.serviceSubProductCode;
      result.form.serviceSubProductName = action.data.serviceSubProductName;
      result.form.isExistingUlEquipment = action.data.isExistingUlEquipment;
      result.form.isInvolvedLitigation = action.data.isInvolvedLitigation;
      result.form.additionalInfo = action.data.additionalInfo;
      result.form.litigationDescription = action.data.litigationDescription;
      result.form.quantity = action.data.quantity;
      // for validation purposes, don't include object if no value to trigger the creation of validation
      if (action.data.isEvaluatedSameVisit != null)
        result.form.isEvaluatedSameVisit = action.data.isEvaluatedSameVisit;
      result.form.isManualPricing = action.data.isManualPricing;
      result.form.assumptions = action.data.assumptions;
      result.form.cancellation = action.data.cancellation;
      result.form.totalPriceByPh = action.data.totalPriceByPh || null;
      result.form.requirementsInspection = action.data.requirementsInspection;

      if (result.form.serviceSubProductCode === "PNL") {
        result.form.isPanelboard = true;
        result.form.propertyType = 1; //residential
        result.form.panelboardOver200 = action.data.isPanelboardOver200;
      } else {
        result.form.isSwitchboard = true;
        result.form.propertyType = 2; //commercial
        result.form.switchboardOver600 = action.data.isSwitchboardOver600;
      }

      return result;
  }
};

export default productDetailsReducer;
