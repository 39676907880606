import { requests } from '../request-factory';

const customerService = {
  getInfo: customerId => requests.get(`customer/${customerId}`),
  getCustomerDetails: partySiteNumber =>
    requests.get(`customer/details/${partySiteNumber}`),
  getCustomerDetailsByAccountNumber: accountNumber =>
    requests.get(`customer/details-by-account-number/${accountNumber}`),
  getBillToAddresses: customerId =>
    requests.get(`customer/bill-to-addresses/${customerId}`),
  getShipToAddresses: customerId =>
    requests.get(`customer/ship-to-addresses/${customerId}`),
};

export default customerService;
