import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, Grid, Form, Button } from "semantic-ui-react";
import PropTypes from "prop-types";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import * as actions from "./product-details.action";
import AssumptionValue from "./assumption-value";
import CancellationValue from "./cancellation-value";

class AssumptionModal extends Component {
  state = {};
  handleChange = (name, event, editor) => {
    let { form } = this.props.data;
    let value = editor.getData();

    let newForm = Object.assign({}, form, {
      [name]: value
    });

    this.props.updateForm(newForm);
  };

  render() {
    const { isShowAssumptionModal, handleAssumptionModal, data } = this.props;

    // will used on creation, display the global assumption per sub product
    let assumption = AssumptionValue(data, null, false);
    let cancellation = CancellationValue(data);

    return (
      <Modal
        open={isShowAssumptionModal}
        onClose={handleAssumptionModal.bind(this, false)}
      >
        <Modal.Header className="center">Edit Assumption</Modal.Header>
        <Modal.Content scrolling>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Form>
                  <h4 className="center">Assumption</h4>
                  <CKEditor
                    name="assumptions"
                    editor={ClassicEditor}
                    data={data.form.assumptions || assumption || ""}
                    config={{
                      toolbar: [
                        "heading",
                        "|",
                        "bold",
                        "italic",
                        "bulletedList",
                        "numberedList",
                        "|",
                        "undo",
                        "redo"
                      ]
                    }}
                    onChange={this.handleChange.bind(this, "assumptions")}
                  />
                  <h4 className="center">Cancellation</h4>
                  <CKEditor
                    name="cancellation"
                    editor={ClassicEditor}
                    data={data.form.cancellation || cancellation || ""}
                    config={{
                      toolbar: [
                        "heading",
                        "|",
                        "bold",
                        "italic",
                        "bulletedList",
                        "numberedList",
                        "|",
                        "undo",
                        "redo"
                      ]
                    }}
                    onChange={this.handleChange.bind(this, "cancellation")}
                  />
                </Form>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          {/* TODO: On cancel diregard changes */}
          <Button primary onClick={handleAssumptionModal.bind(this, false)}>
            Save
          </Button>
          <Button onClick={handleAssumptionModal.bind(this, false)}>
            Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

AssumptionModal.propTypes = {
  isShowAssumptionModal: PropTypes.bool.isRequired,
  handleAssumptionModal: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateForm: (form) => dispatch(actions.updateForm(form))
  };
};

export default connect(null, mapDispatchToProps)(AssumptionModal);
