import { connect } from "react-redux";
import React, { Component, Fragment } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import HeaderBlock from "../../components/header/header-block";
import { Button, Header, Icon, Segment } from "semantic-ui-react";
import { doLogout, doExternalLogout } from "../../views/auth/auth.action";
import { Common } from "../../common/common";

const API_BASE = `${process.env.REACT_APP_API_BASE}`;

class UnavailablePage extends Component {
  handleLogout = () => {
    localStorage.removeItem("selection");
    var externalLogoutURL = `${API_BASE}auth/logout`;
    window.location.replace(externalLogoutURL);
    this.props.logout();
  };

  render() {
    return (
      <Fragment>
        <Router>
          <HeaderBlock />
          <Segment
            placeholder
            style={{ marginLeft: "35%", marginRight: "35%" }}
          >
            <Header icon color="red">
              <Icon name="exclamation triangle" color="red" />
              <br />
              Due to a system outage, Field Services application is unavailable.
            </Header>
          </Segment>
        </Router>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isLogoutSuccess: state.auth.isLogoutSuccess
  };
};

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(doLogout()),
  logoutExternal: () => dispatch(doExternalLogout())
});

export default connect(mapStateToProps, mapDispatchToProps)(UnavailablePage);
