import * as types from "./view-order-details.type";
import api from "../../../services/api";

export const viewOrderDetailsResult = (obj, type) => {
  return {
    type: type,
    data: obj
  };
};

export const getOrderDetails = (orderNumber) => {
  return async (dispatch) => {
    dispatch(viewOrderDetailsResult(null, types.ORDER_DETAILS_REQUEST));
    return await api.FieldEvaluation.getOrderDetails(orderNumber)
      .then((result) => {
        dispatch(viewOrderDetailsResult(result, types.ORDER_DETAILS_SUCCESS));
        return result;
      })
      .catch((result) => {
        dispatch(
          viewOrderDetailsResult(
            result.response && result.response.data,
            types.ORDER_DETAILS_FAILURE
          )
        );
      });
  };
};

export const getPpes = () => {
  return async (dispatch) => {
    dispatch(viewOrderDetailsResult(null, types.PPE_REQUEST));

    await api.FieldEvaluation.getPpes()
      .then((result) => {
        dispatch(viewOrderDetailsResult(result, types.PPE_SUCCESS));
      })
      .catch((result) => {
        dispatch(viewOrderDetailsResult(result, types.PPE_FAILURE));
      });
  };
};

export const getTrainings = () => {
  return async (dispatch) => {
    dispatch(viewOrderDetailsResult(null, types.TRAINING_REQUEST));

    await api.FieldEvaluation.getTrainings()
      .then((result) => {
        dispatch(viewOrderDetailsResult(result, types.TRAINING_SUCCESS));
      })
      .catch((result) => {
        dispatch(viewOrderDetailsResult(result, types.TRAINING_FAILURE));
      });
  };
};

export const updateIsSuccess = () => {
  return async (dispatch) => {
    dispatch(viewOrderDetailsResult(null, types.UPDATE_ISSUCCESS));
  };
};
