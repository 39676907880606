import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import {
  Container,
  Grid,
  Segment,
  Form,
  Button,
  Dropdown,
  Checkbox,
  Label
} from "semantic-ui-react";
import { Common } from "../../../common/common";
import { CustomHeader } from "../../../components/labels/labels";
import ToastHelper from "../../../helpers/toast.helper";
import CartOrderDropdown from "../common/cart-order-dropdown";
import * as actions from "./admin-page.action";
import * as helpMeActions from "../help-me.action";
import HelpMessage from "../common/help-message";
import _ from "lodash";
import Role from "../../../enums/role";
import { Redirect } from "react-router-dom";
import * as commonActions from "../../../views/user/common/common.action";

import {
  validate,
  createValidation,
  validateField
} from "../../../validator/validator";

import { required } from "../../../validator/error-message";
import { objectOf } from "prop-types";
const rules = [validate("labelCenter", "Label Center", required)];

class AdminPage extends Component {
  state = {
    isGetCartOrders: false,
    isCart: false,
    isOutstandingChecked: false,
    labelCenter: "",
    isManuReset: false,
    isPsnReset: false
  };

  onClickSearchOrder = () => {};

  componentDidMount() {
    // this.props.clearForm();
    // this.props.clearHelpMeForm();
    this.props.getUserPsns();
    this.props.getManufacturers();

    let showCart = this.props.match.params.cart;
    let userId = this.props.match.params.uid;

    this.props.getLabelCenter(userId);

    if (showCart != undefined) {
      const { isCart } = this.state;

      let { form } = this.props.helpMe;
      form.cartOrderNo = "cart";
      this.props.updateForm(form);

      this.props.getCartMessages(userId).then((s) => {
        if (s == null || s == "" || s.data == null)
          setTimeout(() => {
            ToastHelper.error({
              title: "Error",
              description: "Customer cart is empty."
            });
          }, 100);

        if ((s || {}).isSuccessful) {
          if (!isCart) this.setState({ isCart: !isCart });

          if (
            s.data.helpMes.filter(
              (a) => a.isViewed != true && a.createdById != Common.getUserId()
            ).length
          ) {
            this.props.updateIsViewedMessage({
              cartId: s.data.id
            });
          }
        }
      });
      return false;
    }
    this.props.getActivePage("Reply to Help");
  }

  handleDropdownChange = (dpName, dpValue) => {
    let { form, userPsns, manufacturers } = this.props.adminPage;
    const { isOutstandingChecked } = this.state;

    var userId = Common.getUserId();
    var roleId = Common.getUserRole();

    if (dpName === "psn" && dpValue) {
      const selectedUser =
        userPsns && userPsns.find((s) => s.partySiteNumber == dpValue);

      if (selectedUser) {
        form.customerName =
          selectedUser.firstName + " " + selectedUser.lastName;

        const newState = validateField(rules, form, "userId", selectedUser.id);
        this.props.updateForm(newState);
      }
    } else {
      form.customerName = null;
    }

    if (dpName === "manufacturerAccount" && dpValue) {
      const selectedManufacturer =
        manufacturers &&
        manufacturers.filter((i) => i.manufacturerAccountNumber === dpValue)[0];
      form.manufacturerName = selectedManufacturer.manufacturerName;
      form.psn = selectedManufacturer.createdById;
    } else {
      form.manufacturerName = null;
    }

    if (dpName == "labelCenter") {
      this.setState({ labelCenter: dpValue });
      this.props.getUserPsns(isOutstandingChecked, dpValue);
      this.props.getManufacturers(isOutstandingChecked, dpValue);
      this.handleTotalRequest(isOutstandingChecked, dpValue);
    }

    const newState = validateField(rules, form, dpName, dpValue);
    this.props.updateForm(newState);

    this.setState({ isGetCartOrders: false });
  };

  handleTotalRequest = (isOutstandingCheck, labelCenterVal) => {
    var userId = Common.getUserId();
    var roleId = Common.getUserRole();
    const { labelCenter } = this.state;

    this.props.getTotalRequests(
      roleId == Role.labelCenter || roleId == Role.labelManager ? null : userId,
      labelCenterVal,
      isOutstandingCheck
    );
  };

  handleSetIsGetCartOrders = () => {
    this.setState({ isGetCartOrders: true });
  };

  handleViewCartDetails = () => {
    const { userId, labelOrderType, psn } = this.props.helpMe;

    if (labelOrderType == 1)
      this.props.history.push(`/home/label-order-cart/${userId}/${psn}`);
    else if (labelOrderType == 2)
      this.props.history.push(`home/generic-label-order-cart/${userId}`);
  };
  handleSearch = () => {
    const { form, userId, labelOrderType } = this.props.helpMe;
    const { isCart, labelCenter } = this.state;
    const { psn } = this.props.adminPage.form;
    form.validationErrors = "";

    if (!Object.IsEmpty(labelCenter)) {
      if (form.cartOrderNo == "cart") {
        this.props.getCartMessages(psn).then((s) => {
          if (s == null || s == "" || s.data == null)
            setTimeout(() => {
              ToastHelper.error({
                title: "Error",
                description: "Customer cart is empty."
              });
            }, 100);

          if (s.isSuccessful) {
            if (!isCart) this.setState({ isCart: !isCart });

            if (
              s.data.helpMes.filter(
                (a) => a.isViewed != true && a.createdById != Common.getUserId()
              ).length
            ) {
              this.props.updateIsViewedMessage({
                cartId: s.data.id
              });
            }
          }
        });
        return false;
      }
      this.props.history.push(
        `/home/label-order-details/${form.cartOrderNo}/1`
      );
    } else {
      setTimeout(() => {
        ToastHelper.error({
          title: "Error",
          description: "Label Center is required."
        });
      }, 100);
    }
  };

  toggleOutstanding = () => {
    this.setState((prevState) => ({
      isOutstandingChecked: !prevState.isOutstandingChecked
    }));

    const { isOutstandingChecked, labelCenter } = this.state;

    this.props.clearForm();
    this.props.getUserPsns(!isOutstandingChecked, labelCenter);
    this.props.getManufacturers(!isOutstandingChecked, labelCenter);
    this.handleTotalRequest(!isOutstandingChecked, labelCenter);

    this.setState({ isGetCartOrders: false });
  };

  render() {
    const {
      form,
      userPsnsOption,
      manufacturersOption,
      loading,
      helpme,
      helpMeTotalRequest
    } = this.props.adminPage;
    const {
      isGetCartOrders,
      isCart,
      isOutstandingChecked,
      labelCenter,
      isManuReset,
      isPsnReset
    } = this.state;
    const { data, labelOrderType, labelCenterOption } = this.props.helpMe;

    if (
      Common.getUserRole() != Role.labelCenter &&
      Common.getUserRole() != Role.labelManager
    ) {
      return <Redirect to="/home/help-me" />;
    }

    if (!isManuReset && form) {
      form.manufacturerAccount = null;
      form.manufacturerName = null;
      this.setState({ isManuReset: true });
    }

    if (!isPsnReset && form) {
      form.psn = null;
      form.customerName = null;
      this.setState({ isPsnReset: true });
    }

    return (
      <Fragment>
        <div className="container-wrapper-mod">
          <Container fluid>
            <Grid>
              <Grid.Row>
                <Grid.Column width={7}></Grid.Column>
              </Grid.Row>
            </Grid>
            <Segment>
              <Grid divided="vertically">
                <Grid.Row columns={2}>
                  <Grid.Column>
                    <CustomHeader message="Search all orders" />
                    <Form>
                      <Form.Group widths="equal">
                        <Form.Field
                          control={Dropdown}
                          compact
                          selection
                          search
                          clearable
                          label="Label Center"
                          selectOnBlur={false}
                          placeholder="Select Label Center"
                          options={labelCenterOption}
                          onChange={(e, properties) => {
                            this.handleDropdownChange(
                              "labelCenter",
                              properties.value
                            );
                          }}
                          value={labelCenter || ""}
                          loading={loading}
                          required
                          //   error={this.getErrorFor("contactName", "AD-AD")}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Checkbox
                          toggle
                          label="Outstanding Requests"
                          style={{ marginLeft: "auto", marginRight: "auto" }}
                          checked={isOutstandingChecked}
                          onChange={this.toggleOutstanding.bind(this)}
                        />
                        <Form.Group>
                          <Label size="large">
                            Number of Requests: {helpMeTotalRequest}
                          </Label>
                        </Form.Group>
                      </Form.Group>
                      <Form.Group widths="equal">
                        <Form.Field
                          control={Dropdown}
                          compact
                          selection
                          search
                          clearable
                          disabled={form && form.manufacturerAccount}
                          label="Party Site Number"
                          selectOnBlur={false}
                          placeholder="Select Party Site Number"
                          options={userPsnsOption}
                          onChange={(e, properties) => {
                            this.handleDropdownChange("psn", properties.value);
                          }}
                          value={(form && form.psn) || ""}
                          loading={loading}
                          //   error={this.getErrorFor("contactName", "AD-AD")}
                        />
                      </Form.Group>
                      {form && form.customerName && (
                        <Form.Group widths="equal">
                          <Form.Input
                            fluid
                            autoComplete="off"
                            readOnly
                            label="Customer Name"
                            name="customerName"
                            value={(form && form.customerName) || ""}
                          />
                        </Form.Group>
                      )}
                      <Form.Group widths="equal">
                        <Form.Field
                          control={Dropdown}
                          compact
                          selection
                          search
                          clearable
                          disabled={form && form.customerName}
                          label="Manufacturer Account Number"
                          selectOnBlur={false}
                          placeholder="Select Manufacturer Account Number"
                          options={manufacturersOption}
                          onChange={(e, properties) => {
                            this.handleDropdownChange(
                              "manufacturerAccount",
                              properties.value
                            );
                          }}
                          value={(form && form.manufacturerAccount) || ""}
                          loading={loading}
                          //   error={this.getErrorFor("contactName", "AD-AD")}
                        />
                      </Form.Group>
                      {form && form.manufacturerName && (
                        <Form.Group widths="equal">
                          <Form.Input
                            fluid
                            autoComplete="off"
                            readOnly
                            label="Manufacturer Name"
                            name="manufacturerName"
                            value={(form && form.manufacturerName) || ""}
                          />
                        </Form.Group>
                      )}
                      <CartOrderDropdown
                        isGetCartOrders={isGetCartOrders}
                        userId={form && form.userId}
                        manufacturerAccountNo={form && form.manufacturerAccount}
                        handleSetIsGetCartOrders={this.handleSetIsGetCartOrders}
                        isGetUnread={isOutstandingChecked}
                        labelCenter={labelCenter}
                      />
                      {labelOrderType != 0 && isCart ? (
                        <Button
                          primary
                          floated="right"
                          onClick={this.handleViewCartDetails.bind(this)}
                        >
                          View Cart Details
                        </Button>
                      ) : null}
                      <Button
                        primary
                        floated="right"
                        disabled={!this.props.helpMe.form.cartOrderNo}
                        onClick={this.handleSearch.bind(this)}
                      >
                        Search orders
                      </Button>
                    </Form>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              {isCart ? (
                <Grid>
                  <Grid.Row columns={2}>
                    <Grid.Column>
                      <Form>
                        {data.data && (
                          <HelpMessage
                            data={data.data.helpMes || data.data.data.helpMes}
                            orderDetails={data.data}
                            isCart={isCart}
                          />
                        )}
                      </Form>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              ) : null}
            </Segment>
          </Container>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.adminPage.loading,
    isSuccess: state.adminPage.isSuccess,
    adminPage: state.adminPage,
    helpMe: state.helpMe
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserPsns: (isGetUnread, labelCenter) =>
      dispatch(actions.getUserPsns(isGetUnread, labelCenter)),
    getManufacturers: (isGetUnread, labelCenter) =>
      dispatch(actions.getManufacturers(isGetUnread, labelCenter)),
    updateForm: (result) => dispatch(actions.updateForm(result)),
    clearForm: () => dispatch(actions.clearForm()),
    clearHelpMeForm: () => dispatch(helpMeActions.clearForm()),
    getCartMessages: (userId) =>
      dispatch(helpMeActions.getCartMessages(userId)),
    updateIsViewedMessage: (data) =>
      dispatch(helpMeActions.updateIsViewedMessage(data)),
    getLabelCenter: (userId) => dispatch(helpMeActions.getLabelCenter(userId)),
    getTotalRequests: (userId, labelCenter, isOutstanding) =>
      dispatch(actions.getTotalRequests(userId, labelCenter, isOutstanding)),
    getActivePage: (page) => dispatch(commonActions.getActivePage(page))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminPage);
