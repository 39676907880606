//import SpecificAssumption from "./specific-assumption";

const businessHrs = `<p>All work will be completed during normal working hours (6:00 AM and
            6:00 PM, Monday through Friday excluding holidays). If your project
            requires work outside of normal working hours an additional cost may
            be incurred.
            </p>`;

const outsideBusinenessHrs = `<p>This quote includes costs associated with work conducted outside of
            normal working hours (Monday through Friday from 6:00 AM through
            6:00 PM, excluding holidays).
            </p>`;

const evaluatedSameDayText = `<p>This quote price includes all sites included in this quote and assumes we can complete the inspection in one single visit which will be displayed in all quote forms.</p>`;

const assumptionValue = (
  data,
  fromViewOrderData,
  isIncludedBusinessHrs,
  isIncludedSameDay,
  evaluatedSameDay
) => {
  let value = "";
  // refer to index 0 only
  const idx = data.forms.findIndex((d) => d.tabIndex === 0);
  if (idx != -1) {
    if (data.forms && data.forms[idx].assumptions) {
      value = data.forms[idx].assumptions;
    }
    // Global/Fixed assumption per sub product
    else {
      let assumption = data.serviceSubProduct.data;
      value = assumption
        ? assumption.length
          ? assumption[0].assumption.details
          : ""
        : "";
    }
  }

  let isSitesHaveOutsideBizHrs = (data.forms || []).some(
    (form) => ((form || {}).productPrice || {}).isBusinessHours === false
  );

  return `${value} ${
    isIncludedSameDay ? (evaluatedSameDay ? evaluatedSameDayText : "") : ""
  } ${
    isIncludedBusinessHrs
      ? isSitesHaveOutsideBizHrs
        ? outsideBusinenessHrs
        : businessHrs
      : ""
  }`;
};

export default assumptionValue;
