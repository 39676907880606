import React from "react";

import styled from "styled-components";

const DiscPrimary = styled.span`
  height: 9px;
  width: 8px;
  background-color: #67b1e2;
  border-radius: 50%;
  display: inline-block;
  margin-right: 15px;
`;

const DiscSuccess = styled.span`
  height: 9px;
  width: 8px;
  background-color: #4c9e45;
  border-radius: 50%;
  display: inline-block;
  margin-right: 15px;
`;

const DiscDanger = styled.span`
  height: 9px;
  width: 8px;
  background-color: #c02032;
  border-radius: 50%;
  display: inline-block;
  margin-right: 15px;
`;

const DiscWarning = styled.span`
  height: 9px;
  width: 8px;
  background-color: #f18a21;
  border-radius: 50%;
  display: inline-block;
  margin-right: 15px;
`;

export const renderAsterisk = () => {
  return <span style={{ color: "#ff695e" }}> *</span>;
};

export const renderDisc = (status) => {
  switch (status) {
    case 4:
      return <DiscDanger />;
    case 2:
      return <DiscSuccess />;
    case 3:
      return <DiscPrimary />;
    case 1:
      return <DiscWarning />;
    default:
      return <DiscSuccess />;
  }
};

export const GetStatusCode = (status) => {
  let statusCode;

  switch (status) {
    case 1:
    case "DRAFT":
      statusCode = "DRAFT";
      break;
    case 2:
    case "INPROGRESS":
      statusCode = "IN PROGRESS";
      break;
    case 3:
    case "PROCESSED":
      statusCode = "PROCESSED";
      break;
    case 4:
    case "CANCELLED":
      statusCode = "CANCELLED";
      break;
    case 6:
    case "ACCEPTED":
      statusCode = "ACCEPTED";
      break;
    case 9:
    case "OFFERED":
      statusCode = "OFFERED";
      break;
    case 7:
    case "PENDINGASSESSMENT":
      statusCode = "PENDING UL ASSESSMENT";
      break;
    case 10:
    case "INPROCESS":
      statusCode = "IN PROCESS";
      break;
    case 11:
    case "ONHOLDACTIONREQUIRED":
      statusCode = "ON HOLD ACTION REQUIRED";
      break;
    case 12:
    case "PENDINGCORRECTIVEACTION":
      statusCode = "PENDING CORRECTIVE ACTION";
      break;
    case 13:
    case "ERROR":
      statusCode = "ERROR";
      break;
    case 14:
    case "ENTERED":
      statusCode = "ENTERED";
      break;
    case 15:
    case "BOOKED":
      statusCode = "BOOKED";
      break;
    case 16:
    case "AWAITINGSHIPPING":
      statusCode = "AWAITING FOR SHIPPING";
      break;
    case 17:
    case "AWAITINGFULFILLMENT":
      statusCode = "AWAITING FULFILLMENT";
      break;
    case 18:
    case "CLOSED":
      statusCode = "CLOSED";
      break;
    case 19:
    case "SUBMITTED":
      statusCode = "SUBMITTED";
      break;
    case 20:
    case "PREPARINGFORSHIPPING":
      statusCode = "PREPARING FOR SHIPPING";
      break;
    case 21:
    case "SHIPPED":
      statusCode = "ON-HOLD";
      break;
    case 22:
    case "PICKED":
      statusCode = "PICKED";
      break;
  }

  return statusCode;
};

export const ChangeOrderLineStatus = (status) => {
  let statusCode;

  switch (status) {
    case 1:
    case "DRAFT":
      statusCode = "DRAFT";
      break;
    case 2:
    case "INPROGRESS":
      statusCode = "IN PROGRESS";
      break;
    case 3:
    case "PROCESSED":
      statusCode = "PROCESSED";
      break;
    case 4:
    case "CANCELLED":
      statusCode = "CANCELLED";
      break;
    case 6:
    case "ACCEPTED":
      statusCode = "ACCEPTED";
      break;
    case 9:
    case "OFFERED":
      statusCode = "OFFERED";
      break;
    case 7:
    case "PENDINGASSESSMENT":
      statusCode = "PENDING UL ASSESSMENT";
      break;
    case 10:
    case "INPROCESS":
      statusCode = "IN PROCESS";
      break;
    case 11:
    case "ONHOLDACTIONREQUIRED":
      statusCode = "ON HOLD ACTION REQUIRED";
      break;
    case 12:
    case "PENDINGCORRECTIVEACTION":
      statusCode = "PENDING CORRECTIVE ACTION";
      break;
    case 13:
    case "ERROR":
      statusCode = "ERROR";
      break;
    case 14:
    case "ENTERED":
      statusCode = "SUBMITTED";
      break;
    case 15:
    case "BOOKED":
      statusCode = "BOOKED";
      break;
    case 16:
    case "AWAITINGSHIPPING":
      statusCode = "IN PROGRESS";
      break;
    case 17:
    case "AWAITINGFULFILLMENT":
      statusCode = "AWAITING FULFILLMENT";
      break;
    case 18:
    case "CLOSED":
      statusCode = "CLOSED";
      break;
    case 19:
    case "SUBMITTED":
      statusCode = "SUBMITTED";
      break;
    case 20:
    case "PREPARINGFORSHIPPING":
      statusCode = "PREPARING FOR SHIPPING";
      break;
    case 21:
    case "SHIPPED":
      statusCode = "SHIPPED";
      break;
    case 22:
    case "PICKED":
      statusCode = "PREPARING FOR SHIPPING";
      break;
  }

  return statusCode;
};

export const GetBUType = (buType) => {
  let buTypeName;

  switch (buType) {
    case 0:
      buTypeName = "";
      break;
    case 1:
      buTypeName = "LABEL";
      break;
    case 2:
      buTypeName = "FE";
      break;
  }

  return buTypeName;
};

export const serviceTypeDisp = (serviceType) => {
  switch (serviceType) {
    case 1:
      return "Field Engineer";
    case 2:
      return "Field Equipment Service";
  }
};

export const renderQuotationBody = () => {
  return `<html>
  <head>
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.2.9/semantic.min.css"
    />
  </head>
  <body>
      <div class="container-wrapper" id="quotation"></div>
  </body>
</html>
`;
};

export const toPascalCase = (value) => {
  return value.replace(/\w+/g, function (w) {
    return w[0].toUpperCase() + w.slice(1).toLowerCase();
  });
};

export const covertUnixTimeToDate = (value) => {
  var date = new Date(value * 1000);
  return date.toLocaleString();
};
