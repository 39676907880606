import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactJson from 'react-json-view';
import React, { Component } from 'react';
import {
  Header,
  Modal,
  Button,
  Grid,
  Dimmer,
  Loader,
  Segment,
} from 'semantic-ui-react';

import ToastHelper from '../../helpers/toast.helper';

import * as actions from './role-assignment.action';

class ViewClaimsModal extends Component {
  state = { isLoading: true };

  componentDidMount() {
    this.props.doClearClaimsDetails();
    const { claimsType, data, handleViewClaimsModal } = this.props;
    // 1 - PSN
    // 2 - Email
    if (claimsType === 1) {
      this.props.doGetUserClaimsByPsn(data.partySiteNumber).then(res => {
        if (res && (res != null || res != '')) {
          if (res.isSuccessful) {
            this.setState({ isLoading: false });
            return;
          }
        }

        setTimeout(() => {
          ToastHelper.error({
            title: 'Error',
            description: 'Something went wrong',
          });
        }, 100);
        this.setState({ isLoading: false });
        handleViewClaimsModal(false);
      });
    } else {
      this.props.doGetUserClaimsByEmail(data.b2cId).then(res => {
        if (res && (res != null || res != '')) {
          if (res.isSuccessful) {
            this.setState({ isLoading: false });
            return;
          }
        }

        setTimeout(() => {
          ToastHelper.error({
            title: 'Error',
            description: 'Something went wrong',
          });
        }, 100);
        this.setState({ isLoading: false });
        handleViewClaimsModal(false);
      });
    }
  }
  render() {
    const {
      isShowViewClaimsModal,
      handleViewClaimsModal,
      claimsType,
      data,
      roleAssignment: { claimsDetails },
    } = this.props;

    return (
      <Modal open={isShowViewClaimsModal}>
        <Dimmer active={this.state.isLoading} inverted>
          <Loader />
        </Dimmer>
        <Modal.Header>
          <Header textAlign="center">
            Details:{' '}
            {claimsType === 1
              ? (data || {}).partySiteNumber
              : (data || {}).email}
          </Header>
        </Modal.Header>
        <Modal.Content scrolling>
          <Modal.Description>
            <Grid>
              {claimsDetails ? (
                <Grid.Row>
                  <Grid.Column>
                    <ReactJson
                      src={(claimsDetails || {}).data}
                      displayDataTypes={false}
                      name="data"
                    />
                  </Grid.Column>
                </Grid.Row>
              ) : null}
            </Grid>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={handleViewClaimsModal.bind(this, false)}>
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

ViewClaimsModal.propTypes = {
  isShowNoteModal: PropTypes.bool.isRequired,
  handleNoteModal: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  claimsType: PropTypes.number.isRequired,
};

const mapStateToProps = state => {
  return {
    roleAssignment: state.roleAssignment,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    doGetUserClaimsByEmail: email =>
      dispatch(actions.doGetUserClaimsByEmail(email)),
    doGetUserClaimsByPsn: psn => dispatch(actions.doGetUserClaimsByPsn(psn)),
    doClearClaimsDetails: () => dispatch(actions.doClearClaimsDetails()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewClaimsModal);
