import React, { Component, Fragment } from "react";

import { connect } from "react-redux";

import {
  Divider,
  Table,
  Grid,
  Button,
  Container,
  Card
} from "semantic-ui-react";

import {
  CustomHeaderBold,
  CustomSubLabel,
  CustomLabel
} from "../../../components/labels/labels";
import { Link } from "react-router-dom";

import { renderDisc } from "../../../helpers/component-helper";
import { GetStatusCode } from "../field-evaluation-helper";

import * as actions from "./view-quote-details.action";

class ViewQuoteDetails extends Component {
  state = {};

  componentDidMount() {
    var orderNumber = this.props.match.params.orderNumber;

    if (orderNumber) this.props.getQuoteDetails(orderNumber);
  }

  render() {
    const { quoteDetails } = this.props.viewQuoteDetails.form;

    return !quoteDetails ? (
      <div style={{ width: "80%", padding: "20px" }}>
        <Container fluid>
          <Card fluid>
            <Card.Content>
              <CustomLabel message="NO DATA FOUND." />
            </Card.Content>
          </Card>
          <Button basic primary compact as={Link} to={`/home/view-history`}>
            Back
          </Button>
        </Container>
      </div>
    ) : (
      <Fragment>
        <div style={{ width: "80%", padding: "20px" }}>
          <Container fluid>
            <Grid verticalAlign="middle">
              <Grid.Column width={3}>
                <CustomHeaderBold
                  message={"ORDER #" + quoteDetails.orderNumber}
                />
              </Grid.Column>
              <Grid.Column
                width={3}
                style={{ textAlign: "right" }}
                verticalAlign="middle"
              ></Grid.Column>
              <Grid.Column width={6}>
                {/* <span hidden={!isSuccess}>
                <NotificationSuccess
                  message={
                    "Thank you, your order has been placed successfully. Order #: " +
                    orderNumber
                  }
                />
              </span> */}
              </Grid.Column>
            </Grid>
            <Divider hidden />
            <Card fluid>
              <Card.Content>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={4}>
                      {renderDisc("")}
                      {GetStatusCode(quoteDetails.status)}
                      <p
                        style={{
                          fontSize: "12px",
                          fontWeight: "700",
                          marginLeft: "20px"
                        }}
                      >
                        As of 00:00 on Month, DD, YYYY
                        {/* {orderDetails.lastOrderActivityData} */}
                      </p>
                    </Grid.Column>
                    <Grid.Column width={4}>
                      <CustomSubLabel message="CLIENT REFERENCE # " />
                      <span
                        style={{
                          fontSize: "14px",
                          fontWeight: "700",
                          marginLeft: "14px"
                        }}
                      >
                        {quoteDetails.oracleNumber}
                      </span>
                      <br />
                      <CustomSubLabel message="SITE LOCATION " />
                      <span
                        style={{
                          fontSize: "14px",
                          fontWeight: "700",
                          marginLeft: "14px"
                        }}
                      >
                        {quoteDetails.siteLocation}
                      </span>
                    </Grid.Column>
                    <Grid.Column width={4}>
                      <CustomSubLabel message="ORDER DATE " />
                      <span
                        style={{
                          fontSize: "14px",
                          fontWeight: "700",
                          marginLeft: "14px"
                        }}
                      >
                        {quoteDetails.orderDate}
                      </span>
                      <br />
                      <CustomSubLabel message="PRODUCT TYPE " />
                      <span
                        style={{
                          fontSize: "14px",
                          fontWeight: "700",
                          marginLeft: "14px"
                        }}
                      >
                        {/* {orderDetails.productType} */}
                        Line Site Taps
                      </span>
                      <br />
                      <CustomSubLabel message="INSPECTION TYPE " />
                      <span
                        style={{
                          fontSize: "14px",
                          fontWeight: "700",
                          marginLeft: "14px"
                        }}
                      >
                        {/* {orderDetails.inspectionType} */}
                        Field Engineering
                      </span>
                    </Grid.Column>
                    <Grid.Column width={2} floated="right">
                      <Button
                        primary
                        fluid
                        compact
                        as={Link}
                        to={`/home/view-order-details/${quoteDetails.orderNumber}`}
                      >
                        Copy quote
                      </Button>
                      <br></br>
                      <Button
                        primary
                        fluid
                        compact
                        as={Link}
                        to={`/home/view-order-details/${quoteDetails.orderNumber}`}
                      >
                        Edit Order
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row></Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={4}></Grid.Column>
                    <Grid.Column width={4}></Grid.Column>
                    <Grid.Column width={4}></Grid.Column>
                    <Grid.Column width={2} floated="right">
                      {/* <Button basic fluid compact color="red">
                        Delete Order
                      </Button> */}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Card.Content>
            </Card>
            <Button
              basic
              primary
              compact
              as={Link}
              to={`/home/view-draft-quote`}
            >
              Back
            </Button>
          </Container>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    viewQuoteDetails: state.viewQuoteDetails
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getQuoteDetails: orderNumber =>
      dispatch(actions.getQuoteDetails(orderNumber))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewQuoteDetails);
