import React, { Component } from "react";
import {
  Table,
  Button,
  Segment,
  Pagination,
  Container,
  Modal
} from "semantic-ui-react";

import { renderDisc } from "../../../helpers/component-helper";

import { CustomLabel } from "../../../components/labels/labels";
import { Link } from "react-router-dom";

import { GetStatusCode } from "../field-evaluation-helper";

import { deleteOrder } from "./view-draft.action";
import { connect } from "react-redux";

class OrderItem extends Component {
  state = {
    open: false,
    requestNumber: ""
  };

  handleDelete = (requestNumber) => {
    const postData = {};
    postData.requestNumber = requestNumber;
    this.props.deleteOrder(postData);
  };

  handleConfirm = () => {
    this.setState({ open: false });
    const postData = {};
    postData.requestNumber = this.state.requestNumber;
    this.props.deleteOrder(postData);
  };

  handleOpenModal = (requestNumber) => {
    this.setState({ open: true, requestNumber: requestNumber });
  };

  handleCancel = () => {
    this.setState({ open: false });
  };

  render() {
    const { data } = this.props;
    const { open } = this.state;
    return (
      <Segment>
        <Modal size="mini" open={open} onClose={this.handleCancel}>
          <Modal.Header>Delete draft order</Modal.Header>
          <Modal.Content>
            <p>Are you sure you want to delete this order?</p>
          </Modal.Content>
          <Modal.Actions>
            <Button primary onClick={this.handleConfirm}>
              Delete
            </Button>
            <Button primary basic onClick={this.handleCancel}>
              Cancel
            </Button>
          </Modal.Actions>
        </Modal>
        <Table basic="very" fixed compact="very">
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                {renderDisc("")}
                <CustomLabel message={"Status: "} />
                {GetStatusCode(data.status)}
              </Table.Cell>
              <Table.Cell>
                <CustomLabel message="Client Ref #: " />
                {data.clientReferenceNumber}
              </Table.Cell>
              <Table.Cell>
                <CustomLabel message="Order #: " />
                {data.orderNumber}
              </Table.Cell>
              <Table.Cell>
                <CustomLabel message="FE Type: " />
                {data.feType}
              </Table.Cell>
              <Table.Cell>
                <CustomLabel message="Product Type: " />
                {data.productType}
              </Table.Cell>
              <Table.Cell>
                <CustomLabel message="Last Order Activity: " />
                {data.lastOrderActivityData}
              </Table.Cell>
              <Table.Cell>
                <CustomLabel message="Site Location: " />
                {data.siteLocation}
              </Table.Cell>
              <Table.Cell width={4}>
                <Button
                  compact
                  color="blue"
                  as={Link}
                  to={`/home/view-order-details/${data.oracleNumber}`}
                >
                  Open
                </Button>
                <Button
                  compact
                  content="Delete"
                  color="blue"
                  onClick={this.handleOpenModal.bind(this, data.orderNumber)}
                />
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </Segment>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  deleteOrder: (requestNumber) => dispatch(deleteOrder(requestNumber))
});

export default connect(null, mapDispatchToProps)(OrderItem);
