import merge from "deepmerge";
import Role from "../../../../enums/role";
import api from "../../../../services/api";
import { Common } from "../../../../common";
import * as types from "./project-details.types";

export const projectDetailsResult = (obj, type) => {
  return {
    data: obj,
    type: type
  };
};

export const updateForm = (idx, form, forms) => {
  const newForms = forms.slice(0);
  const index = getFormIndexByTabIndex(idx, forms);
  let newForm = getFormByTabIndex(idx, forms);

  if (!newForm) {
    newForms.push(form);
  } else {
    newForms[index] = merge(newForm, form);
  }

  return (dispatch) =>
    dispatch(projectDetailsResult(newForms, types.UPDATE_FORMS));
};

export const updateSiteDetailsForm = (idx, form, forms, key) => {
  const newForms = forms.slice(0);
  let newForm = forms[idx][key];

  if (!newForm) {
    newForms.push(form);
  } else {
    newForm.specialNotesList = []; // to prevent concating the nested array specialNotesList
    newForms[idx][key] = merge(newForm, form);
  }

  return (dispatch) =>
    dispatch(projectDetailsResult(newForms, types.UPDATE_SITE_FORMS));
};

export const updatePriceForm = (idx, form) => {
  return (dispatch) =>
    dispatch(
      projectDetailsResult(
        {
          index: idx,
          result: form
        },
        types.FE_PRODUCT_PRICE_REQUEST
      )
    );
};

/**
 * Return's object by tab index
 * @param {number} idx
 * @param {array<object>} forms
 */
export const getFormByTabIndex = (idx, forms) => {
  return (forms || []).find((s) => (s || {}).tabIndex === idx);
};

/**
 * Return's form index by tab index
 * @param {number} idx
 * @param {array<object>} forms
 */
export const getFormIndexByTabIndex = (idx, forms) => {
  return (forms || []).findIndex((s) => (s || {}).tabIndex === idx);
};

export const getServiceProducts = (serviceType) => {
  return async (dispatch) => {
    return await api.FieldEvaluation.getServiceProducts(serviceType)
      .then((result) => {
        dispatch(
          projectDetailsResult(result, types.FE_SERVICE_PRODUCT_REQUEST)
        );
        return result;
      })
      .catch((result) => {
        dispatch(
          projectDetailsResult(result, types.FE_SERVICE_PRODUCT_FAILURE)
        );
      });
  };
};

export const getServiceSubProducts = (serviceProductId) => {
  return async (dispatch) => {
    return await api.FieldEvaluation.getServiceSubProducts(serviceProductId)
      .then((result) => {
        dispatch(
          projectDetailsResult(result, types.FE_SERVICE_SUB_PRODUCT_REQUEST)
        );
        return result;
      })
      .catch((result) => {
        dispatch(
          projectDetailsResult(result, types.FE_SERVICE_SUB_PRODUCT_FAILURE)
        );
      });
  };
};

export const getServiceProductPrice = (
  serviceAreaId,
  serviceSubProductId,
  propertyType,
  quantity,
  scheduleDate,
  siteCharge
) => {
  return async (dispatch) => {
    return await api.FieldEvaluation.getServiceProductPrice(
      serviceAreaId,
      serviceSubProductId,
      propertyType,
      quantity,
      scheduleDate,
      siteCharge
    )
      .then((result) => {
        return result;
      })
      .catch((result) => {
        return {};
      });
  };
};

export const clearGsq = () => (dispatch) =>
  dispatch(projectDetailsResult({}, types.CLEAR_GSQ));

const filterValidation = (rules, name) => {
  return rules.filter(([r, n]) => {
    return n !== name;
  });
};

export const getValidation = (state, rules) => {
  let copyRules = rules.slice(0);
  const {
    quantity,
    isPanelboard,
    isSwitchboard,
    isInvolvedLitigation,
    isManualPricing
  } = state;

  // remove in multi sites, gsq not included. cannot remove in validation rules: used by single site
  copyRules = filterValidation(copyRules, "isInstalledSameLocation");

  // qty < 2, remove isInstalledSameLocation & isEvaluatedSameVisit validations
  if (parseInt(quantity) < 2) {
    copyRules = filterValidation(copyRules, "isEvaluatedSameVisit");
  }

  // pnl, remove rated over 600 VAC validation for swb
  if (isPanelboard)
    copyRules = filterValidation(copyRules, "switchboardOver600");

  // swb, remove rated over 200A validation for pnl
  if (isSwitchboard)
    copyRules = filterValidation(copyRules, "panelboardOver200");

  // if litigation no, remove litigation description validation
  //if (!isInvolvedLitigation)
  //  copyRules = filterValidation(copyRules, "litigationDescription");

  // PH edit total price
  const userRole = Common.getUserRole();
  if (
    userRole != Role.projectHandler ||
    (userRole == Role.projectHandler && !isManualPricing)
  )
    copyRules = filterValidation(copyRules, "totalPriceByPh");

  //PH edit scope
  if (userRole != Role.projectHandler)
    copyRules = filterValidation(copyRules, "scope");

  return copyRules.map((s) => s[0]);
};

// ** SITE DETAILS

export const renderDynamicSiteForm = (count) => {
  return async (dispatch) => {
    dispatch(projectDetailsResult(count, types.RENDER_DYNAMIC_SITE_FORM));
  };
};

export const getSiteCountries = (locationType) => {
  return async (dispatch) => {
    let type = null;
    switch (locationType) {
      case 1:
        type = types.COUNTRIES_PRELIM_SITE_REQUEST;
        break;
      case 2:
        type = types.COUNTRIES_FINAL_SITE_REQUEST;
        break;
      case 3:
        type = types.COUNTRIES_AHJ_SITE_REQUEST;
        break;
    }
    dispatch(projectDetailsResult(null, type));

    await api.FieldEvaluation.getCountries()
      .then((result) => {
        let type = null;
        switch (locationType) {
          case 1:
            type = types.COUNTRIES_PRELIM_SITE_REQUEST;
            break;
          case 2:
            type = types.COUNTRIES_FINAL_SITE_REQUEST;
            break;
          case 3:
            type = types.COUNTRIES_AHJ_SITE_REQUEST;
            break;
        }
        dispatch(projectDetailsResult(result, type));
      })
      .catch((result) => {
        let type = null;
        switch (locationType) {
          case 1:
            type = types.COUNTRIES_PRELIM_SITE_FAILURE;
            break;
          case 2:
            type = types.COUNTRIES_FINAL_SITE_FAILURE;
            break;
          case 3:
            type = types.COUNTRIES_AHJ_SITE_FAILURE;
            break;
        }
        dispatch(projectDetailsResult(result, type));
      });
  };
};

export const getSiteStates = (countryCode, locationType) => {
  return async (dispatch) => {
    let type = null;
    switch (locationType) {
      case 1:
        type = types.STATES_PRELIM_SITE_REQUEST;
        break;
      case 2:
        type = types.STATES_FINAL_SITE_REQUEST;
        break;
      case 3:
        type = types.STATES_AHJ_SITE_REQUEST;
        break;
    }
    dispatch(projectDetailsResult(null, type));

    await api.FieldEvaluation.getStates(countryCode)
      .then((result) => {
        let type = null;
        switch (locationType) {
          case 1:
            type = types.STATES_PRELIM_SITE_SUCCESS;
            break;
          case 2:
            type = types.STATES_FINAL_SITE_SUCCESS;
            break;
          case 3:
            type = types.STATES_AHJ_SITE_SUCCESS;
            break;
        }
        dispatch(projectDetailsResult(result, type));
      })
      .catch((result) => {
        let type = null;
        switch (locationType) {
          case 1:
            type = types.STATES_PRELIM_SITE_FAILURE;
            break;
          case 2:
            type = types.STATES_FINAL_SITE_FAILURE;
            break;
          case 3:
            type = types.STATES_AHJ_SITE_FAILURE;
            break;
        }
        dispatch(projectDetailsResult(result, type));
      });
  };
};

export const getSiteCities = (stateCode, locationType) => {
  return async (dispatch) => {
    let type = null;
    switch (locationType) {
      case 1:
        type = types.CITIES_PRELIM_SITE_REQUEST;
        break;
      case 2:
        type = types.CITIES_FINAL_SITE_REQUEST;
        break;
      case 3:
        type = types.CITIES_AHJ_SITE_REQUEST;
        break;
    }
    dispatch(projectDetailsResult(null, type));

    await api.FieldEvaluation.getCities(stateCode)
      .then((result) => {
        let type = null;
        switch (locationType) {
          case 1:
            type = types.CITIES_PRELIM_SITE_SUCCESS;
            break;
          case 2:
            type = types.CITIES_FINAL_SITE_SUCCESS;
            break;
          case 3:
            type = types.CITIES_AHJ_SITE_SUCCESS;
            break;
        }
        dispatch(projectDetailsResult(result, type));
      })
      .catch((result) => {
        let type = null;
        switch (locationType) {
          case 1:
            type = types.CITIES_PRELIM_SITE_FAILURE;
            break;
          case 2:
            type = types.CITIES_FINAL_SITE_FAILURE;
            break;
          case 3:
            type = types.CITIES_AHJ_SITE_FAILURE;
            break;
        }
        dispatch(projectDetailsResult(result, type));
      });
  };
};

export const getAhjCountries = () => {
  return async (dispatch) => {
    dispatch(projectDetailsResult(null, types.COUNTRIES_AHJ_SITE_REQUEST));

    await api.FieldEvaluation.getCountries()
      .then((result) => {
        dispatch(
          projectDetailsResult(result, types.COUNTRIES_AHJ_SITE_SUCCESS)
        );
      })
      .catch((result) => {
        dispatch(
          projectDetailsResult(result, types.COUNTRIES_AHJ_SITE_FAILURE)
        );
      });
  };
};

export const getAhjStates = (countryCode) => {
  return async (dispatch) => {
    dispatch(projectDetailsResult(null, types.STATES_AHJ_SITE_REQUEST));

    await api.FieldEvaluation.getStates(countryCode)
      .then((result) => {
        dispatch(projectDetailsResult(result, types.STATES_AHJ_SITE_SUCCESS));
      })
      .catch((result) => {
        dispatch(projectDetailsResult(result, types.STATES_AHJ_SITE_FAILURE));
      });
  };
};

export const getAhjCities = (stateCode) => {
  return async (dispatch) => {
    dispatch(projectDetailsResult(null, types.CITIES_AHJ_SITE_REQUEST));

    await api.FieldEvaluation.getCities(stateCode)
      .then((result) => {
        dispatch(projectDetailsResult(result, types.CITIES_AHJ_SITE_SUCCESS));
      })
      .catch((result) => {
        dispatch(projectDetailsResult(result, types.CITIES_AHJ_SITE_FAILURE));
      });
  };
};

export const renderProductDetails = (count, data) => {
  return async (dispatch) => {
    dispatch(
      projectDetailsResult(
        { count: count, data: data },
        types.RENDER_PRODUCT_DETAILS
      )
    );
  };
};

export const renderSiteDetails = (count, data) => {
  return async (dispatch) => {
    dispatch(
      projectDetailsResult(
        { count: count, data: data },
        types.RENDER_SITE_DETAILS
      )
    );
  };
};
