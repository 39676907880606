import * as types from "./help-me.type";
import api from "../../services/api";

export const helpMeResult = (obj, type) => {
  return {
    type: type,
    data: obj,
  };
};

export const getCartOrders = (
  userId,
  manufacturerAccountNo,
  isGetUnread,
  labelCenter
) => {
  return async (dispatch) => {
    dispatch(helpMeResult(null, types.GET_CART_ORDERS_REQUEST));
    await api.HelpMe.getCartOrders(
      userId,
      manufacturerAccountNo,
      isGetUnread,
      labelCenter
    )
      .then((result) => {
        dispatch(helpMeResult(result, types.GET_CART_ORDERS_SUCCESS));
      })
      .catch((result) => {
        dispatch(
          helpMeResult(
            result.response && result.response.data,
            types.GET_CART_ORDERS_FAILURE
          )
        );
      });
  };
};

export const createMessage = (data) => {
  return async (dispatch) => {
    dispatch(helpMeResult(null, types.CREATE_MESSAGE_REQUEST));
    return await api.HelpMe.createMessage(data)
      .then((result) => {
        dispatch(helpMeResult(result, types.CREATE_MESSAGE_SUCCESS));
        return result;
      })
      .catch((result) => {
        dispatch(
          helpMeResult(
            result.response && result.response.data,
            types.CREATE_MESSAGE_FAILURE
          )
        );
      });
  };
};

export const getCartMessages = (userId) => {
  return async (dispatch) => {
    return await api.HelpMe.getCartMessages(userId)
      .then((result) => {
        dispatch(helpMeResult(result, types.CART_MESSAGE_SUCCESS));
        return result;
      })
      .catch((result) => {
        dispatch(
          helpMeResult(result.response.data, types.CART_MESSAGE_FAILURE)
        );
      });
  };
};

export const getUnreadMessage = (userId) => {
  return async (dispatch) => {
    dispatch(helpMeResult(null, types.GET_UNREAD_MESSAGE_REQUEST));
    await api.HelpMe.getUnreadMessage(userId)
      .then((result) => {
        dispatch(helpMeResult(result, types.GET_UNREAD_MESSAGE_SUCCESS));
      })
      .catch((result) => {
        dispatch(
          helpMeResult(
            result.response && result.response.data,
            types.GET_UNREAD_MESSAGE_FAILURE
          )
        );
      });
  };
};

export const updateIsViewedMessage = (data) => {
  return async (dispatch) => {
    dispatch(helpMeResult(null, types.UPDATE_IS_VIEWED_REQUEST));
    return await api.HelpMe.updateIsViewedMessage(data)
      .then((result) => {
        dispatch(helpMeResult(result, types.UPDATE_IS_VIEWED_SUCCESS));
        return result;
      })
      .catch((result) => {
        dispatch(
          helpMeResult(
            result.response && result.response.data,
            types.UPDATE_IS_VIEWED_FAILURE
          )
        );
      });
  };
};

export const getLabelCenter = (userId) => {
  return async (dispatch) => {
    return await api.HelpMe.getLabelCenter(userId)
      .then((result) => {
        dispatch(helpMeResult(result, types.GET_LABAEL_CENTER_SUCCESS));
        return result;
      })
      .catch((result) => {
        dispatch(
          helpMeResult(result.response.data, types.GET_LABAEL_CENTER_FAILURE)
        );
      });
  };
};

export const doAddMessage = (params) => {
  return (dispatch) => dispatch(helpMeResult(params, types.ADD_MESSAGE));
};

export const updateForm = (result) => {
  return (dispatch) => dispatch(helpMeResult(result, types.HELP_ME_FORM));
};

export const clearForm = () => {
  return (dispatch) => dispatch(helpMeResult({}, types.HELP_ME_CLEAR));
};

export const clearFormOnly = () => {
  return (dispatch) => dispatch(helpMeResult({}, types.CLEAR_FORM_ONLY));
};
