import React, { Component } from "react";
import PropTypes from "prop-types";
import { Modal, Header, Button } from "semantic-ui-react";

class SuspendedModal extends Component {
  render() {
    const {
      isShowSuspendedModal,
      handleSuspendedModal,
      onConfirmSuspend,
      suspendLoading,
      data
    } = this.props;

    return (
      <Modal open={isShowSuspendedModal} size="mini">
        <Modal.Header>
          <Header icon="info circle" textAlign="center" />
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <p>
              {data.isSuspended ? "Reactivating" : "Deactivating"}
              <b> {data.email}</b>. Are you sure?
            </p>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            className={suspendLoading}
            onClick={onConfirmSuspend.bind(this, data)}
          >
            Yes
          </Button>
          <Button
            onClick={handleSuspendedModal.bind(this, false)}
            className={suspendLoading}
          >
            Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

SuspendedModal.propTypes = {
  isShowSuspendedModal: PropTypes.bool.isRequired,
  handleSuspendedModal: PropTypes.func.isRequired,
  onConfirmSuspend: PropTypes.func.isRequired,
  suspendLoading: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired
};

export default SuspendedModal;
