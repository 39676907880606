import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import * as actions from "./notifications.action";
import { Container, Segment, Table, Button } from "semantic-ui-react";
import {
  CustomTitle,
  CustomHeaderLabel,
  CustomLabel
} from "../../../components/labels/labels";
import { renderDisc, GetStatusCode } from "../../../helpers/component-helper";
import { Link } from "react-router-dom";

import { Common } from "../../../common/common";
import Role from "../../../enums/role";

class Notifications extends Component {
  state = {};

  componentDidMount() {
    this.props.getNotifications();
  }
  render() {
    const { notifications } = this.props;

    let fullName = Common.getFullName();
    let roleId = Common.getUserRole();

    return (
      <Fragment>
        <div style={{ width: "70%", padding: "20px" }}>
          <Container fluid>
            {roleId == Role.fireEquipment && (
              <CustomTitle
                message={"WELCOME TO UL FIRE EQUIPMENT SERVICES, " + fullName}
              />
            )}
            {roleId == Role.fieldEngineer && (
              <CustomTitle
                message={"WELCOME TO UL FIELD EVALUATION SERVICES, " + fullName}
              />
            )}

            <CustomHeaderLabel message="Notifications since last login" />

            {notifications &&
              notifications.map((data, idx) => {
                return (
                  <Segment key={idx}>
                    <Table basic="very" fixed compact="very">
                      <Table.Body>
                        <Table.Row>
                          <Table.Cell>
                            {renderDisc(data.statusCode)}
                            {/* <CustomLabel message={data.statusName} /> */}
                            <Link
                              to={`/home/preview-order-details/${data.requestNumber}`}
                            >
                              {GetStatusCode(data.statusName)}
                            </Link>
                          </Table.Cell>
                          <Table.Cell>
                            <CustomLabel message="Quote #" />
                            {data.requestNumber}
                            <br />
                            {data.message}
                          </Table.Cell>
                          <Table.Cell>
                            <CustomLabel message="Status updated as of: " />
                            {data.updatedUnixTime} on {data.updatedUnixDate}
                          </Table.Cell>
                          <Table.Cell width={2}>
                            <Button
                              basic
                              compact
                              color="blue"
                              as={Link}
                              to={`/home/preview-order-details/${data.requestNumber}`}
                            >
                              View
                            </Button>
                          </Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </Segment>
                );
              })}

            {notifications && notifications.length === 0 && (
              <Table>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>
                      <CustomLabel message="No New Notifications" />
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            )}
          </Container>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  notifications: state.notifications.data
});

const mapDispatchToProps = (dispatch) => ({
  getNotifications: () => dispatch(actions.getNotifications())
});

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
