import * as types from "./cancel-order.type";

let initialState = {
  data: {
    currentPage: 1,
    pageSize: 10,
    totalPages: 1
  },
  notificationCount: 0,
  cancelProcessResult: {},
  itemDetails: [],
  cancelItemResult: {}
};

const cancelOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
    case types.CANCEL_ORDER_REQUEST:
    case types.CANCEL_ORDER_SUCCESS:
    case types.CANCEL_ORDER_FAILURE:
    case types.CANCEL_ORDER_CHANGE_PAGE:
      return Object.assign({}, state, {
        data: action.data || initialState.data,
        notificationCount: action.data ? action.data.total : 0
      });
    case types.CANCEL_ORDER_PROCESS_REQUEST:
      return Object.assign({}, state, {
        data: Object.assign({}, state.data, {
          data: action.data || initialState.data
        })
      });
    case types.CANCEL_ORDER_PROCESS:
      return Object.assign({}, state, {
        cancelProcessResult: action.data || initialState.cancelProcessResult
      });
    case types.NOTIFICATION_COUNT:
      return Object.assign({}, state, {
        notificationCount: state.notificationCount - 1
      });
    case types.CLEAR:
      return Object.assign({}, state, {
        data: initialState.data
      });
    case types.CANCEL_ORDER_UPDATE:
      return Object.assign({}, state, {
        data : Object.assign({}, state.data, {
          data: action.payload || initialState.data
        })
      })
    case types.GET_ITEM_SUCCESS:
    case types.GET_ITEM_FAILURE:
      return Object.assign({}, state, {
        itemDetails: action.data || initialState.result
      });
    case types.CLEAR_ITEM_DETAILS:
       return Object.assign({}, state, {
         itemDetails: {}
    });
    case types.CANCEL_ORDER_ITEM_SUCCESS:
      return Object.assign({}, state, {
        cancelItemResult: action.data || initialState.cancelItemResult
    });
  }
};

export default cancelOrderReducer;
