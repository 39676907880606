import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Container,
  Grid,
  Segment,
  Form,
  Button,
  Divider,
  Radio,
} from 'semantic-ui-react';
import { CustomHeader } from '../../components/labels/labels';
import update from 'immutability-helper';
import _ from 'lodash';
import * as actions from './admin-search.action';
import AdminSearchResult from './admin-search-result';
import * as commonActions from '../../views/user/common/common.action';
import { toast } from 'react-semantic-toasts';

class AdminSearch extends Component {
  state = { searchType: '1' };
  // componentWillUnmount() {
  //   this.props.clear();
  // }
  componentDidMount() {
    this.props.getActivePage('Global Admin Search');
  }

  onClickSearchOrder(searchType) {
    const { data, form } = this.props.adminSearch;
    this.props
      .doSearch(
        form.psn,
        form.accountNumber,
        form.orderConfirmationNumber,
        form.oracleOrderNumber,
        data.sortColumn,
        data.sortOrder,
        data.currentPage,
        data.pageSize,
        form.searchTerm,
        searchType
      )
      .then(result => {
        if (result && result.data.length === 0) {
          setTimeout(() => {
            toast({
              title: '',
              description: (
                <p>
                  The number is not found in the Label Service application.
                  Please contact your local Label Center for help.
                </p>
              ),
              time: 10000,
              size: 'small',
              icon: 'warning',
              color: 'yellow',
            });
          }, 100);
        }
      });

    this.setState({ searchType: searchType });
  }

  handleChange = e => {
    let { form } = this.props.adminSearch;
    let newState = update(form, {
      $merge: _.set(form, e.target.name, e.target.value),
    });
    this.props.form(newState);
  };
  handleRadioChange = (e, { value }) => {
    this.setState({ searchType: value });
  };
  render() {
    const { form, data, loading } = this.props.adminSearch;
    const { searchType } = this.state;
    return data && data.data && data.data.length > 0 ? (
      <AdminSearchResult data={data} form={form} searchType={searchType} />
    ) : (
      <div className="container-wrapper-mod">
        <Container fluid>
          <Segment>
            <Grid columns={2} relaxed="very">
              <Grid.Column>
                <CustomHeader message="Search all orders" />
                <Form>
                  <Form.Group widths="equal">
                    <Form.Input
                      fluid
                      autoComplete="off"
                      label="Party Site Number"
                      placeholder="Party Site Number"
                      name="psn"
                      value={form.psn || ''}
                      onChange={this.handleChange}
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Input
                      fluid
                      autoComplete="off"
                      label="Manufacturer Account Number"
                      placeholder="Manufacturer Account Number"
                      name="accountNumber"
                      value={form.accountNumber || ''}
                      onChange={this.handleChange}
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Input
                      fluid
                      autoComplete="off"
                      label="Order Confirmation Number"
                      placeholder="Order Confirmation Number"
                      name="orderConfirmationNumber"
                      value={form.orderConfirmationNumber || ''}
                      onChange={this.handleChange}
                    />
                  </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Input
                      fluid
                      autoComplete="off"
                      label="Oracle Order Number"
                      placeholder="Oracle Order Number"
                      name="oracleOrderNumber"
                      value={form.oracleOrderNumber || ''}
                      onChange={this.handleChange}
                    />
                  </Form.Group>
                  <Button
                    primary
                    floated="right"
                    onClick={this.onClickSearchOrder.bind(this, 0)}
                    loading={loading}
                    disabled={loading}
                  >
                    Search orders
                  </Button>
                </Form>
              </Grid.Column>
              <Grid.Column>
                <CustomHeader message="Search orders by Lot/Serial Number" />
                <Form>
                  <Form.Group widths="equal">
                    <Form.Field>
                      <Radio
                        label="By Lot Number"
                        name="radioGroup"
                        value="1"
                        checked={searchType === '1'}
                        onChange={this.handleRadioChange}
                      />
                      <Divider hidden fitted />
                      <Form.Field>
                        <Radio
                          label="By Serial Number"
                          name="radioGroup"
                          value="2"
                          checked={searchType === '2'}
                          onChange={this.handleRadioChange}
                        />
                      </Form.Field>
                      <Divider hidden fitted />
                      <Form.Input
                        fluid
                        autoComplete="off"
                        placeholder={
                          searchType === '1'
                            ? 'Search by Lot Number'
                            : 'Search by Serial Number'
                        }
                        name="searchTerm"
                        value={form.searchTerm || ''}
                        onChange={this.handleChange}
                      />
                    </Form.Field>
                  </Form.Group>
                  <Button
                    primary
                    floated="right"
                    onClick={this.onClickSearchOrder.bind(this, searchType)}
                    loading={loading}
                    disabled={loading}
                  >
                    Search orders
                  </Button>
                </Form>
              </Grid.Column>
            </Grid>
            <Divider vertical>OR</Divider>
          </Segment>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  adminSearch: state.adminSearch,
});

const mapDispatchToProps = dispatch => {
  return {
    doSearch: (
      pSN,
      accountNumber,
      orderConfirmationNumber,
      oracleOrderNumber,
      sortColumn,
      sortOrder,
      currentPage,
      pageSize,
      searchTerm,
      searchType
    ) =>
      dispatch(
        actions.doSearch(
          pSN,
          accountNumber,
          orderConfirmationNumber,
          oracleOrderNumber,
          sortColumn,
          sortOrder,
          currentPage,
          pageSize,
          searchTerm,
          searchType
        )
      ),
    form: result => dispatch(actions.updateForm(result)),
    clear: () => dispatch(actions.clearAdminSearch()),
    getActivePage: page => dispatch(commonActions.getActivePage(page)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminSearch);
