import * as types from "./view-draft.types";
import api from "../../../services/api";

export const viewDraftResult = (obj, type) => {
  return {
    type: type,
    data: obj
  };
};

export const getFEOrdersByFilter = (status, currentPage, pageSize) => {
  return async dispatch => {
    dispatch(viewDraftResult(null, types.FE_ORDER_DRAFT_BY_FILTER_REQUEST));
    await api.FieldEvaluation.getOrdersByFilter(status, currentPage, pageSize)
      .then(result => {
        dispatch(
          viewDraftResult(result, types.FE_ORDER_DRAFT_BY_FILTER_SUCCESS)
        );
      })
      .catch(result => {
        dispatch(
          viewDraftResult(
            result.response && result.response.data,
            types.FE_ORDER_DRAFT_BY_FILTER_FAILURE
          )
        );
      });
  };
};

export const getFEOrdersByFilterByUser = (status, currentPage, pageSize) => {
  return async dispatch => {
    dispatch(
      viewDraftResult(null, types.FE_ORDER_HISTORY_BY_FILTER_BY_USER_REQUEST)
    );
    await api.FieldEvaluation.getOrdersByFilterByUser(
      status,
      currentPage,
      pageSize
    )
      .then(result => {
        dispatch(
          viewDraftResult(
            result,
            types.FE_ORDER_HISTORY_BY_FILTER_BY_USER_SUCCESS
          )
        );
      })
      .catch(result => {
        dispatch(
          viewDraftResult(
            result.response && result.response.data,
            types.FE_ORDER_HISTORY_BY_FILTER_BY_USER_FAILURE
          )
        );
      });
  };
};

export const deleteOrder = requestNumber => {
  return async dispatch => {
    dispatch(viewDraftResult(null, types.FE_ORDER_DELETE_REQUEST));
    await api.FieldEvaluation.deleteOrder(requestNumber)
      .then(result => {
        dispatch(viewDraftResult(result, types.FE_ORDER_DELETE_SUCCESS));
      })
      .catch(result => {
        dispatch(
          viewDraftResult(
            result.response && result.response.data,
            types.FE_ORDER_DELETE_FAILURE
          )
        );
      });
  };
};

export const changePage = (activePage, pageSize) => {
  return async dispatch => {
    dispatch(viewDraftResult({ activePage }, types.FE_ORDER_DRAFT_REQUEST));
    await api.Order.getOrders(activePage, pageSize)
      .then(result => {
        dispatch(viewDraftResult(result, types.FE_ORDER_DRAFT_SUCCESS));
      })
      .catch(result => {
        dispatch(
          viewDraftResult(
            result.response && result.response.data,
            types.FE_ORDER_DRAFT_FAILURE
          )
        );
      });
  };
};

export const updateForm = result => {
  return dispatch => dispatch(viewDraftResult(result, types.FORM));
};
