import * as types from "./view-draft-quote.type";

let initialState = {
  data: [],
  currentPage: 1,
  pageSize: 10,
  totalPages: 1,
  loading: false,
  form: {
    showErrors: false,
    validationErrors: {}
  }
};

const viewDraftQuoteReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
    case types.FE_QUOTE_DRAFT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.FE_QUOTE_DRAFT_SUCCESS:
    case types.FE_QUOTE_DRAFT_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        currentPage: action.data.currentPage,
        pageSize: action.data.pageSize,
        totalPages: action.data.totalPages,
        data: action.data.data || initialState.data
      });
    case types.FE_QUOTE_DRAFT_BY_FILTER_FAILURE:
      return {
        ...state,
        loading: true
      };
    case types.FE_QUOTE_DRAFT_BY_FILTER_SUCCESS:
    case types.FE_QUOTE_DRAFT_BY_FILTER_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        currentPage: action.data.currentPage,
        pageSize: action.data.pageSize,
        totalPages: action.data.totalPages,
        data: action.data.data || initialState.data
      });
    case types.FE_ORDER_HISTORY_BY_FILTER_BY_USER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.FE_ORDER_HISTORY_BY_FILTER_BY_USER_SUCCESS:
    case types.FE_ORDER_HISTORY_BY_FILTER_BY_USER_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        currentPage: action.data.currentPage,
        pageSize: action.data.pageSize,
        totalPages: action.data.totalPages,
        data: action.data.data || initialState.data
      });
    case types.FE_QUOTE_DRAFT_DELETE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.FE_QUOTE_DRAFT_DELETE_SUCCESS:
      debugger;
      const updatedDraftItems = state.data
        .filter(draftItem => {
          return draftItem.id !== action.data.data.id;
        })
        .map(draftItem => {
          return draftItem;
        });
      return Object.assign({}, state, {
        loading: false,
        data: updatedDraftItems || initialState.data
      });
    case types.FE_QUOTE_DRAFT_DELETE_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        data: action.data || initialState.data
      });
    case types.FORM:
      return Object.assign({}, state, {
        form: action.data || initialState.form
      });
  }
};

export default viewDraftQuoteReducer;
