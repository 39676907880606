import * as types from "./new-quote-details.type";

const initialState = {
  isSuccess: false,
  data: null,
  orderDetails: null,
  arrForm: [],
  isSuccessRenderForm: false,
  isSuccessGetCountries: false,
  isSuccessGetStates: false,
  isSuccessGetCities: false,
  countryOptionsPrelimProjectDetails: [],
  countryOptionsFinalProjectDetails: [],
  stateOptionsPrelimProjectDetails: [],
  stateOptionsFinalProjectDetails: [],
  cityOptionsPrelimProjectDetails: [],
  cityOptionsFinalProjectDetails: [],
  countryOptionsPrelimEngineerDetails: [],
  countryOptionsFinalEngineerDetails: [],
  stateOptionsPrelimEngineerDetails: [],
  stateOptionsFinalEngineerDetails: [],
  cityOptionsPrelimEngineerDetails: [],
  cityOptionsFinalEngineerDetails: [],
  countryOptionsAhjDetails: [],
  stateOptionsAhjDetails: [],
  cityOptionsAhjDetails: [],
  form: {
    otherDetails: {
      isReadyToScheduleNow: false,
      isCopyOrder: false,
      isFromOffered: false,
      showErrors: false,
      validationErrors: {}
    },
    accountSettings: {
      data: {},
      showErrors: false,
      validationErrors: {}
    },
    orderDetails: {
      data: {},
      showErrors: false,
      validationErrors: {}
    },
    applicantDetails: {
      applicantDetails: {
        data: null,
        locationType: 1,
        showErrors: false,
        validationErrors: {}
      },
      applicantBillToLocationDetails: {
        data: {},
        locationType: 2,
        showErrors: false,
        validationErrors: {}
      },
      quoteAcceptanceDetails: {
        data: {},
        showErrors: false,
        validationErrors: {}
      },
      isSameAsAbove: false,
      showErrors: false,
      validationErrors: {}
    },
    siteDetails: {
      data: {},
      preliminarySiteLocationSection: {
        data: [],
        siteType: 1,
        showErrors: false,
        validationErrors: {}
      },
      finalInstallationSiteSection: {
        data: [],
        siteType: 2,
        showErrors: false,
        validationErrors: {}
      },
      authorityHavingJurisdictionDetails: {
        data: null,
        showErrors: false,
        validationErrors: {}
      },
      scheduleNow: "false",
      showPrelimLocation: null,
      prelimSameAsApplicant: false,
      finalSameAsApplicant: false,
      isFinalSameAsApplicant: false,
      isSiteDetails: true,
      showErrors: false,
      validationErrors: {}
    },
    productDetails: {
      data: [],
      specialNotes: [],
      initialState: {
        orders: [],
        form: {
          showErrors: false,
          validationErrors: {}
        }
      },
      showErrors: false,
      validationErrors: {}
    },
    reservationDetails: {
      preliminarySiteLocationSection: {
        data: null,
        siteType: 1,
        showErrors: false,
        validationErrors: {},
        ppeSelections: "",
        trainingSelections: ""
      },
      finalSiteLocationSection: {
        data: null,
        siteType: 2,
        showErrors: false,
        validationErrors: {}
      },
      authorityHavingJurisdictionDetails: {
        data: null,
        showErrors: false,
        validationErrors: {}
      },
      ppeSelections: [],
      trainingSelections: [],
      isSiteDetails: false,
      showErrors: false,
      validationErrors: {}
    },
    attachment: null
  }
};
const newQuoteDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CREATE_FIELD_SERVICE_REQUEST:
      return {
        ...state,
        isSuccess: false
      };
    case types.CREATE_FIELD_SERVICE_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        data: action.data
      };
    case types.CREATE_FIELD_SERVICE_FAILURE:
      return {
        ...state,
        isSuccess: false,
        data: null
      };
    case types.UPDATE_FIELD_SERVICE_REQUEST:
      return {
        ...state,
        isSuccess: false
      };
    case types.UPDATE_FIELD_SERVICE_SUCCESS:
      return {
        ...state,
        isSuccess: true,
        data: action.data
      };
    case types.UPDATE_FIELD_SERVICE_FAILURE:
      return {
        ...state,
        isSuccess: false,
        data: null
      };
    case types.RENDER_DYNAMIC_FORM:
      var result = Object.assign({}, state, {
        data: action.data || initialState.data
      });

      var siteCount = action.data.count;
      var isSameDayVisit = action.data.isSameDayVisit;

      result.arrForm = [];
      for (let i = 0; i < siteCount; i++) {
        var newForm = new Object(null);
        newForm = {
          otherDetails: {
            isReadyToScheduleNow: false,
            isCopyOrder: false,
            isFromOffered: false,
            showErrors: false,
            validationErrors: {}
          },
          accountSettings: {
            data: {},
            showErrors: false,
            validationErrors: {}
          },
          orderDetails: {
            data: {},
            showErrors: false,
            validationErrors: {}
          },
          applicantDetails: {
            applicantDetails: {
              data: null,
              locationType: 1,
              showErrors: false,
              validationErrors: {}
            },
            applicantBillToLocationDetails: {
              data: {},
              locationType: 2,
              showErrors: false,
              validationErrors: {}
            },
            quoteAcceptanceDetails: {
              data: {},
              showErrors: false,
              validationErrors: {}
            },
            isSameAsAbove: false,
            showErrors: false,
            validationErrors: {}
          },
          siteDetails: {
            data: {},
            preliminarySiteLocationSection: {
              data: [],
              siteType: 1,
              showErrors: false,
              validationErrors: {}
            },
            finalInstallationSiteSection: {
              data: [],
              siteType: 2,
              showErrors: false,
              validationErrors: {}
            },
            authorityHavingJurisdictionDetails: {
              data: null,
              showErrors: false,
              validationErrors: {}
            },
            scheduleNow: "false",
            showPrelimLocation: null,
            prelimSameAsApplicant: false,
            finalSameAsApplicant: false,
            isFinalSameAsApplicant: false,
            isSiteDetails: true,
            showErrors: false,
            validationErrors: {}
          },
          productDetails: {
            data: [],
            initialState: {
              orders: [],
              form: {
                showErrors: false,
                validationErrors: {}
              }
            },
            showErrors: false,
            validationErrors: {}
          },
          reservationDetails: {
            preliminarySiteLocationSection: {
              data: null,
              siteType: 1,
              showErrors: false,
              validationErrors: {},
              ppeSelections: "",
              trainingSelections: ""
            },
            finalSiteLocationSection: {
              data: null,
              siteType: 2,
              showErrors: false,
              validationErrors: {}
            },
            authorityHavingJurisdictionDetails: {
              data: null,
              showErrors: false,
              validationErrors: {}
            },
            ppeSelections: [],
            trainingSelections: [],
            isSiteDetails: false,
            showErrors: false,
            validationErrors: {}
          },
          attachment: null,
          tabIndex: i,
          isSchedulingDisabled: isSameDayVisit ? (i == 0 ? false : true) : false
        };
        result.arrForm.push(newForm);
      }
      result.isSuccessRenderForm = true;

      return result;

    case types.FORM:
      var result = Object.assign({}, state, {
        arrForm: action.data || initialState.form
      });
      return result;
    case types.CLEAR:
      var result = Object.assign({}, state, {
        data: initialState.data,
        form: initialState.form
      });

      result.orderDetails = null;

      result.form.orderDetails = {
        data: {},
        showErrors: false,
        validationErrors: {}
      };

      result.form.otherDetails.isReadyToScheduleNow = null;
      result.form.otherDetails.customerReferenceNumber = null;
      result.form.otherDetails.isCopyOrder = false;
      result.form.otherDetails = {
        isReadyToScheduleNow: false,
        showErrors: false,
        validationErrors: {}
      };
      result.form.siteDetails.showPrelimLocation = null;

      result.form.siteDetails.finalInstallationSiteSection = {
        data: [],
        showErrors: false,
        validationErrors: {}
      };

      result.form.reservationDetails.preliminarySiteLocationSection = {
        data: [],
        showErrors: false,
        validationErrors: {}
      };

      result.form.reservationDetails.ppeSelections.forEach((item) => {
        item.checked = false;
      });

      result.form.reservationDetails.trainingSelections.forEach((item) => {
        item.checked = false;
        item.trainingTwoHours = null;
      });

      result.form.productDetails = {
        data: [],
        initialState: {
          orders: [],
          form: {
            showErrors: false,
            validationErrors: {}
          }
        },
        showErrors: false,
        validationErrors: {}
      };
      result.isSuccess = false;
      return result;
    case types.QUOTE_DETAILS_SUCCESS:
      var result = Object.assign({}, state, {});

      result.orderDetails = action.data.data.orderDetails;

      return result;
    case types.FE_UPDATE_ORDER_TYPE_SUCCESS:
      return Object.assign({}, state, {
        data: action.data || initialState.data
      });
    default:
      return state;
  }
};

export default newQuoteDetailsReducer;
