import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Container, Card, Button, Form } from "semantic-ui-react";
import _ from "lodash";

import HeaderBlock from "../../components/header/header-block";
import FooterBlock from "../../components/footer/footer-block";
import { CustomTitleLabel } from "../../components/labels/labels";
import { resetPasswordCard } from "../../assets/js/common";
import {
  validate,
  getError,
  validateField,
  createValidation
} from "../../validator/validator";
import {
  required,
  minLength,
  mustMatch,
  maxLength
} from "../../validator/error-message";
import * as actions from "./reset-password.action";
import ResetPasswordMessage from "./reset-password-message";

const rules = [
  validate("password", "New Password", required, minLength(8), maxLength(30)),
  validate(
    "password2",
    "Confirm Paswword",
    required,
    minLength(8),
    maxLength(30),
    mustMatch("password", "New Password")
  )
];

class ResetPassword extends Component {
  componentWillUnmount() {
    this.props.clear();
  }

  handleResetPassword() {
    const { form } = this.props.resetPassword;
    const { token } = this.props.location.state;
    form.validationErrors = createValidation(form, rules);

    if (Object.IsEmpty(form.validationErrors)) {
      const model = {
        token: token,
        password: form.password
      };
      return this.props.doResetPassword(
        _.omit(model, ["showErrors", "validationErrors"])
      );
    }

    form.showErrors = true;
    this.props.form(form);

    return false;
  }

  handleChange = e => {
    let { form } = this.props.resetPassword;
    const newState = validateField(rules, form, e.target.name, e.target.value);
    this.props.form(newState);
  };

  getErrorFor(field) {
    const { form, data } = this.props.resetPassword;
    let errors = getError(field, form, data);
    return errors;
  }

  render() {
    const { data } = this.props.resetPassword;
    const header = data.isSuccessful ? "Success" : "Error";
    const { form } = this.props.resetPassword;

    return data.message ? (
      <ResetPasswordMessage header={header} message={data.message} />
    ) : (
      <Fragment>
        <HeaderBlock />
        <Container style={resetPasswordCard}>
          <Card fluid>
            <Card.Content>
              <Card.Header>Set a new password</Card.Header>
              <br />
              <CustomTitleLabel message="Order product labels or request an inspection visit from a UL field engineer using our system." />
              <br />
              <br />
              <Form>
                <Form.Field>
                  <Form.Input
                    value={form.password || ""}
                    label="New Password"
                    placeholder="New Password"
                    name="password"
                    autoComplete="off"
                    onChange={this.handleChange}
                    type="password"
                    error={this.getErrorFor("password")}
                  />
                </Form.Field>
                <Form.Field>
                  <Form.Input
                    value={form.password2 || ""}
                    label="Confirm Password"
                    placeholder="Confirm Password"
                    name="password2"
                    autoComplete="off"
                    onChange={this.handleChange}
                    type="password"
                    error={this.getErrorFor("password2")}
                  />
                </Form.Field>
              </Form>
            </Card.Content>
            <Card.Content extra textAlign="right">
              <Button
                onClick={this.handleResetPassword.bind(this)}
                color="blue"
              >
                Reset Password
              </Button>
            </Card.Content>
          </Card>
        </Container>
        <FooterBlock />
      </Fragment>
    );
  }
}
const mapStateToProps = state => {
  return {
    resetPassword: state.user.resetPassword
  };
};

const mapDispatchToProps = dispatch => {
  return {
    doResetPassword: param => dispatch(actions.doResetPassword(param)),
    form: result => dispatch(actions.updateForm(result)),
    clear: () => dispatch(actions.clearResetPassword())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPassword);
