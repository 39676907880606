import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import jwtEncode from 'jwt-encode';
import * as actions from './callback.action';
import { connect } from 'react-redux';
import { Form } from 'semantic-ui-react';
import * as commonActions from '../user/common/common.action';

const SECURITY_KEY = `${process.env.REACT_APP_SECURITY_KEY}`;
class Callback extends Component {
  state = {};

  componentDidMount() {
    var query = new URLSearchParams(this.props.location.search);
    var code = query.get('code');
    var codeVerifier = localStorage.getItem('codeVerifier');
    if (code && code !== null) {
      this.props.validateAuthenticationCode(code, codeVerifier);
    }
  }

  render() {
    const { callback, setFilterOptionColumns } = this.props;
    const { data } = callback;
    var token = null;
    var b2cToken = null;
    var refreshToken = null;
    var userId = null;

    if (data && data.authenticatedUserToken) {
      console.log('selected language from myul', data.languageCode);

      token = data.authenticatedUserToken;
      b2cToken = data.idToken;
      refreshToken = data.refreshToken;

      const value = jwtDecode(token);
      value.translations = data.translations;
      value.languageCode = data.languageCode;
      localStorage.setItem('token', JSON.stringify(token));
      localStorage.setItem('b2cToken', JSON.stringify(b2cToken));
      localStorage.setItem('cartLabelOrderType', null);
      localStorage.setItem('refreshToken', JSON.stringify(refreshToken));
      localStorage.setItem(
        'appToken',
        JSON.stringify(jwtEncode(value, SECURITY_KEY))
      );
      userId = JSON.stringify(value.unique_name);
      var expiration = JSON.stringify(value.exp);
      var isExpired = Date.now() >= expiration * 1000;

      setFilterOptionColumns(parseInt(value?.role));
    }

    if (userId && !isExpired) {
      return <Redirect to="/home/view-orders" />;
    } else {
      return (
        <div style={{ marginTop: '20%' }}>
          <Form loading={true}></Form>
        </div>
      );
    }
  }
}

const mapStateToProps = state => ({
  callback: state.callback,
});

const mapDispatchToProps = dispatch => ({
  validateAuthenticationCode: (authenticationCode, verifier) =>
    dispatch(actions.validateAuthenticationCode(authenticationCode, verifier)),
  setFilterOptionColumns: roleId =>
    dispatch(commonActions.setFilterOptionColumns(roleId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Callback);
