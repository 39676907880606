import React, { Component, Fragment } from "react";
import {
  Accordion,
  Icon,
  Divider,
  Segment,
  Grid,
  Form,
  TextArea
} from "semantic-ui-react";
import { CustomHeaderLabel } from "../../components/labels/labels";

class AdditionalComments extends Component {
  state = {
    activeIndex: 0,
    value: "ul"
  };

  toggleHelp = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  selectMarking = (e, { value }) => this.setState({ value });

  render() {
    const { activeIndex } = this.state;

    return (
      <Fragment>
        <Segment>
          <Accordion>
            <Accordion.Title
              active={activeIndex === 0}
              index={0}
              onClick={this.toggleHelp}
            >
              <Icon name="dropdown" />
              <CustomHeaderLabel message="Additional comments" />
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 0}>
              <Divider />
              <Grid>
                <Grid.Column width={4} style={{ textAlign: "justify" }}>
                  <i>Provide additional information if necessary.</i>
                </Grid.Column>
                <Grid.Column width={10}>
                  <Form.Field>
                    <TextArea placeholder="Additional comments" />
                  </Form.Field>
                </Grid.Column>
              </Grid>
            </Accordion.Content>
          </Accordion>
        </Segment>
      </Fragment>
    );
  }
}

export default AdditionalComments;
