import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../health-dashboard/health-dashboard.action";
import {
  Container,
  Button,
  Icon,
  Grid,
  Dimmer,
  Loader,
  Popup,
  Form,
  Table,
  Sidebar,
  Dropdown,
  Modal,
  Label,
  Segment
} from "semantic-ui-react";
import { CustomHeader, CustomHeaderBold } from "../../components/labels/labels";
import * as commonActions from "../../views/user/common/common.action";

class HealthDashBoard extends Component {
  state = {
    isLoading: "",
    OverAllStat: "",
    isLoad: false,
    day: 0,
    week: 0,
    authWeek: 0,
    orderStatusDay: 0,
    openErrorMessage: false,
    errorMessage: "",
    errorMessageList: []
  };

  componentDidMount() {
    this.props.getApiStatus(1, 1, 1, 1);
    this.setState({ day: 1 });
    this.setState({ week: 1 });
    this.setState({ authWeek: 1 });
    this.setState({ orderStatusDay: 1 });
    this.props.getActivePage("Health Dashboard");
  }

  handleFilter = (property) => {
    const { week, orderStatusDay, authWeek } = this.state;

    this.props.getApiStatus(property.value, week, orderStatusDay, authWeek);
    this.setState({ day: property.value });
  };

  handleFilterByWeek = (property) => {
    const { day, orderStatusDay, authWeek } = this.state;
    this.props.getApiStatus(day, property.value, orderStatusDay, authWeek);
    this.setState({ week: property.value });
  };

  handleAuthLetterFilterByWeek = (property) => {
    const { day, orderStatusDay, week } = this.state;
    this.props.getApiStatus(day, week, orderStatusDay, property.value);
    this.setState({ authWeek: property.value });
  };

  handleFilterOrderStatus = (property) => {
    const { week, day, authWeek } = this.state;

    this.props.getApiStatus(day, week, property.value, authWeek);
    this.setState({ orderStatusDay: property.value });
  };

  handleErrorMessage = (column) => {
    if (column.errorMessage != "Passed") {
      this.setState({ errorMessage: column.errorMessage });
      this.setState({ openErrorMessage: true });
      this.setState({ errorMessageList: column.errorMessageList });
    }
  };

  handleCloseErrorBox = () => {
    this.setState({ openErrorMessage: false });
  };
  render() {
    const { data, filterOptions, filterByWeek, filterByOrderStatus } =
      this.props;
    const {
      OverAllStat,
      isLoad,
      day,
      week,
      authWeek,
      orderStatusDay,
      openErrorMessage,
      errorMessage,
      errorMessageList
    } = this.state;

    if (data && data.data && !isLoad) {
      var redStat = data.data.filter((i) => i.statusResult == 2);
      var amber = data.data.filter((i) => i.statusResult == 1);

      if (amber.length == 1) {
        this.setState({
          OverAllStat: "ui yellow circular label, ui massive label "
        });
      } else if (amber.length > 1) {
        this.setState({
          OverAllStat: "ui red circular label, ui massive label"
        });
      } else if (redStat.length > 0) {
        this.setState({
          OverAllStat: "ui red circular label, ui massive label"
        });
      } else {
        this.setState({
          OverAllStat: "ui green circular label, ui massive label"
        });
      }
      this.setState({ isLoad: true });
    }
    return (
      <div className="container-wrapper">
        <Modal size="small" open={openErrorMessage}>
          <Modal.Header>
            <table>
              <tr>
                <th width="700">Error Message</th>
                <th textAlign="right">
                  <Button primary onClick={() => this.handleCloseErrorBox()}>
                    X
                  </Button>
                </th>
              </tr>
            </table>
          </Modal.Header>
          <Modal.Content>
            <Table basic="very" hidden={errorMessageList == null}>
              <Table.Body>
                {errorMessageList &&
                  errorMessageList.map((column, idx) => {
                    return (
                      <Table.Row>
                        <Table.Cell>{column}</Table.Cell>
                      </Table.Row>
                    );
                  })}
              </Table.Body>
            </Table>
            <span hidden={errorMessageList}>{errorMessage}</span>
          </Modal.Content>
        </Modal>
        <Popup
          trigger={<Icon exclamation name="error" />}
          hoverable
          content="View Error"
          size="mini"
        />
        <Segment fluid>
          <Sidebar.Pushable>
            <Container fluid style={{ padding: "30px" }}>
              <Dimmer active={this.props.loading}>
                <Loader>Loading</Loader>
              </Dimmer>
              <Grid>
                <Grid.Row columns={16}>
                  <Grid.Column width={11}></Grid.Column>
                  <Grid.Column width={5} class="right floated column">
                    <Grid>
                      <Grid.Column
                        width={13}
                        textAlign="right"
                        verticalAlign="middle"
                      >
                        <CustomHeader message="System Status" />
                      </Grid.Column>
                      <Grid.Column>
                        <a class={OverAllStat}></a>
                      </Grid.Column>
                    </Grid>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <Table style={{ border: "1px medium" }}>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Integrations</Table.HeaderCell>
                    <Table.HeaderCell>Details</Table.HeaderCell>
                    <Table.HeaderCell>Error Message</Table.HeaderCell>
                    <Table.HeaderCell>Filter</Table.HeaderCell>
                    <Table.HeaderCell textAlign="right">
                      Status
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {data &&
                    data.data &&
                    data.data.map((column, idx) => {
                      return (
                        <Table.Row>
                          {/* <Table.Cell>Integration  {column.id}
                  </Table.Cell> */}
                          <Table.Cell>{column.name}</Table.Cell>

                          {/* <label style={{ color: column.fontColor }}  hidden={column.errorMessage == "" ? 1 : 0}>
                     {column.errorMessage}
                    </label>  */}
                          {/* <span hidden={column.errorMessage ? 0 : 1}>       
                             
                        <Popup
                           trigger={<i class={column.fontColor} onClick={() => this.handleErrorMessage(column.errorMessage)} 
                           style={{cursor: 'pointer'}} />}
                           content='Please Open a Service Desk ticket for this issue'
                           size='mini'                          
                        />
                    </span> */}

                          <Table.Cell>{column.totalFailed}</Table.Cell>
                          <Table.Cell>
                            <Table
                              basic="very"
                              hidden={data.data[idx].errorMessageList == null}
                            >
                              <Table.Body>
                                {data &&
                                  data.data &&
                                  data.data[0].errorMessageList &&
                                  data.data[0].errorMessageList &&
                                  data.data[0].errorMessageList.map(
                                    (column, idx) => {
                                      return (
                                        <Table.Row>
                                          <Table.Cell>{column}</Table.Cell>
                                        </Table.Row>
                                      );
                                    }
                                  )}
                              </Table.Body>
                            </Table>
                            <span hidden={data.data[idx].errorMessageList}>
                              {column.errorMessage}
                            </span>
                          </Table.Cell>

                          <Table.Cell>
                            <span hidden={column.id != 1}>
                              <Form.Field
                                control={Dropdown}
                                compact
                                selection
                                label="Filter by "
                                selectOnBlur={false}
                                placeholder="Filter by"
                                name="filterBy"
                                options={filterOptions}
                                onChange={(e, properties) => {
                                  this.handleFilter(properties);
                                }}
                                value={day || ""}
                                autoComplete="off"
                              />
                            </span>

                            <span hidden={column.id != 5}>
                              <Form.Field
                                control={Dropdown}
                                compact
                                selection
                                label="Filter by "
                                selectOnBlur={false}
                                placeholder="Filter by"
                                name="filterBy"
                                options={filterByWeek}
                                onChange={(e, properties) => {
                                  this.handleFilterByWeek(properties);
                                }}
                                value={week || ""}
                                autoComplete="off"
                              />
                            </span>

                            <span hidden={column.id != 8}>
                              <Form.Field
                                control={Dropdown}
                                compact
                                selection
                                label="Filter by "
                                selectOnBlur={false}
                                placeholder="Filter by"
                                name="filterBy"
                                options={filterByOrderStatus}
                                onChange={(e, properties) => {
                                  this.handleFilterOrderStatus(properties);
                                }}
                                value={orderStatusDay || ""}
                                autoComplete="off"
                              />
                            </span>

                            <span hidden={column.id != 10}>
                              <Form.Field
                                control={Dropdown}
                                compact
                                selection
                                label="Filter by "
                                selectOnBlur={false}
                                placeholder="Filter by"
                                name="filterBy"
                                options={filterByWeek}
                                onChange={(e, properties) => {
                                  this.handleAuthLetterFilterByWeek(properties);
                                }}
                                value={authWeek || ""}
                                autoComplete="off"
                              />
                            </span>
                          </Table.Cell>

                          <Table.Cell textAlign="right">
                            <span hidden={column.errorMessage == "Passed"}>
                              <Popup
                                trigger={
                                  <i
                                    class={column.statusColor}
                                    onClick={() =>
                                      this.handleErrorMessage(column)
                                    }
                                    style={{ cursor: "pointer" }}
                                  />
                                }
                                content="Please Open a Service Desk ticket for this issue"
                                size="mini"
                              />
                            </span>
                            <span hidden={column.errorMessage != "Passed"}>
                              <a class={column.statusColor}></a>
                            </span>
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                </Table.Body>
              </Table>
              <Grid class="ui hidden divider">
                <Grid.Row></Grid.Row>
                <Grid.Row columns={2}>
                  <Grid.Column width={5}>
                    <CustomHeaderBold message="Integrations Health Indicators" />
                  </Grid.Column>
                  <Grid.Column columns={5}>
                    <CustomHeaderBold message="System/ Overall Health Indicators" />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                  <Grid.Column width={5}>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column>
                          <a class="ui green circular label"></a>
                        </Grid.Column>
                        <Grid.Column width={10} verticalAlign="middle">
                          Normal System Function
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>
                  <Grid.Column width={5}>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column>
                          <a class="ui green circular label"></a>
                        </Grid.Column>
                        <Grid.Column width={10} verticalAlign="middle">
                          All integrations are functioning normally
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                  <Grid.Column width={5}>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column>
                          <a class="ui yellow circular label"></a>
                        </Grid.Column>
                        <Grid.Column width={10} verticalAlign="middle">
                          Partial system function/ performance issues
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>
                  <Grid.Column width={5}>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column>
                          <a class="ui yellow circular label"></a>
                        </Grid.Column>
                        <Grid.Column width={15} verticalAlign="middle">
                          1 Integration undergoing partial system function/
                          performance issues
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2}>
                  <Grid.Column width={5}>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column>
                          <a class="ui red circular label"></a>
                        </Grid.Column>
                        <Grid.Column width={10} verticalAlign="middle">
                          Integration failure
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>
                  <Grid.Column width={5}>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column>
                          <a class="ui red circular label"></a>
                        </Grid.Column>
                        <Grid.Column width={15} verticalAlign="middle">
                          1+ integrations undergoing failure or 2+ integration
                          partial system function / performance issue or failure
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Container>
          </Sidebar.Pushable>
        </Segment>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.healthDashBoard.loading,
    data: state.healthDashBoard.data,
    filterOptions: state.healthDashBoard.filterOptions,
    filterByWeek: state.healthDashBoard.filterByWeek,
    filterByOrderStatus: state.healthDashBoard.filterByOrderStatus
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getApiStatus: (day, week, orderStatusDay, authWeek) =>
      dispatch(actions.getApiStatus(day, week, orderStatusDay, authWeek)),
    getActivePage: (page) => dispatch(commonActions.getActivePage(page))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HealthDashBoard);
