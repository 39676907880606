import * as types from "./view-quote-details.type";

let initialState = {
  data: [],
  isCheckoutSuccess: false,
  form: {
    quoteDetails: {
      data: {},
      showErrors: false,
      validationErrors: {}
    },
    applicantDetails: {
      applicantDetails: {
        data: null,
        locationType: 1,
        showErrors: false,
        validationErrors: {}
      },
      applicantBillToLocationDetails: {
        data: {},
        locationType: 2,
        showErrors: false,
        validationErrors: {}
      },
      quoteAcceptanceDetails: {
        data: {},
        showErrors: false,
        validationErrors: {}
      },
      showErrors: false,
      validationErrors: {}
    },
    siteDetails: {
      data: {},
      preliminarySiteLocationSection: {
        data: [],
        showErrors: false,
        validationErrors: {}
      },
      finalInstallationSiteSection: {
        data: [],
        showErrors: false,
        validationErrors: {}
      },
      showErrors: false,
      validationErrors: {}
    },
    productDetails: {
      data: [],
      showErrors: false,
      validationErrors: {}
    },
    reservationDetails: {
      preliminarySiteLocationSection: {
        data: null,
        showErrors: false,
        validationErrors: {}
      },
      finalSiteLocationSection: {
        data: null,
        showErrors: false,
        validationErrors: {}
      },
      authorityHavingJurisdictionDetails: {
        data: null,
        showErrors: false,
        validationErrors: {}
      },
      showErrors: false,
      validationErrors: {}
    }
  }
};

const viewQuoteDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
    case types.DRAFT_QUOTE_DETAILS_REQUEST:
      var result = Object.assign({}, state, {
        data: action.data || initialState.data
      });

      result.data.isLoading = true;

      return result;
    case types.DRAFT_QUOTE_DETAILS_FAILURE:
      var result = Object.assign({}, state, {
        data: action.data || initialState.data
      });
      return result;
    case types.DRAFT_QUOTE_DETAILS_SUCCESS:
      var result = Object.assign({}, state, {
        data: initialState.data,
        form: initialState.form
      });
      result.form.quoteDetails = action.data.data.orderDetails;
      result.form.quoteDetails.showErrors = false;
      result.form.quoteDetails.validationErrors = {};

      result.form.applicantDetails.applicantDetails = action.data.data.applicants.filter(
        f => f.locationType === 1
      )[0];
      result.form.applicantDetails.applicantDetails.showErrors = false;
      result.form.applicantDetails.applicantDetails.validationErrors = {};

      result.form.applicantDetails.applicantBillToLocationDetails = action.data.data.applicants.filter(
        f => f.locationType === 2
      )[0];
      result.form.applicantDetails.applicantBillToLocationDetails.showErrors = false;
      result.form.applicantDetails.applicantBillToLocationDetails.validationErrors = {};

      result.form.applicantDetails.quoteAcceptanceDetails =
        action.data.data.quoteAcceptances;
      result.form.applicantDetails.quoteAcceptanceDetails.showErrors = false;
      result.form.applicantDetails.quoteAcceptanceDetails.validationErrors = {};

      result.form.reservationDetails.preliminarySiteLocationSection = action.data.data.engineers.filter(
        f => f.siteType === 1
      )[0];
      result.form.reservationDetails.preliminarySiteLocationSection.showErrors = false;
      result.form.reservationDetails.preliminarySiteLocationSection.validationErrors = {};

      result.form.reservationDetails.finalSiteLocationSection = action.data.data.engineers.filter(
        f => f.siteType === 2
      )[0];
      result.form.reservationDetails.finalSiteLocationSection.showErrors = false;
      result.form.reservationDetails.finalSiteLocationSection.validationErrors = {};

      result.form.siteDetails.preliminarySiteLocationSection = action.data.data.sites.filter(
        f => f.siteType === 1
      )[0];
      result.form.siteDetails.preliminarySiteLocationSection.showErrors = false;
      result.form.siteDetails.preliminarySiteLocationSection.validationErrors = {};

      result.form.siteDetails.finalInstallationSiteSection = action.data.data.sites.filter(
        f => f.siteType === 2
      )[0];
      result.form.siteDetails.finalInstallationSiteSection.showErrors = false;
      result.form.siteDetails.finalInstallationSiteSection.validationErrors = {};

      result.form.reservationDetails.authorityHavingJurisdictionDetails =
        action.data.data.authorityJurisdictions;
      result.form.reservationDetails.authorityHavingJurisdictionDetails.showErrors = false;
      result.form.reservationDetails.authorityHavingJurisdictionDetails.validationErrors = {};

      return result;
    case types.DRAFT_QUOTE_DETAILS_RESET:
      var result = Object.assign({}, state, {
        data: initialState.data
      });

      result.data.isLoading = false;

      return result;
    case types.FORM:
      var result = Object.assign({}, state, {
        form: action.data || initialState.form
      });
      return result;
    case types.CLEAR:
      return Object.assign({}, state, {
        data: initialState.data,
        form: {
          showErrors: false,
          validationErrors: {}
        }
      });
  }
};

export default viewQuoteDetailsReducer;
