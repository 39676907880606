import React, { Component, Fragment } from "react";
import {
  Button,
  Table,
  Popup,
  Icon,
  Menu,
  Tab,
  Label
} from "semantic-ui-react";
import { Link, NavLink } from "react-router-dom";
import Role from "../../enums/role";
import { Common } from "../../common/common";

class DashBoardItem extends Component {
  state = {};

  adjustStatus = (data) => {
    var returnData = data.toUpperCase();
    if (data === "INPROGRESS") {
      returnData = "In Progress";
    } else if (data === "INPROCRESS") {
      returnData = "In Process";
    } else {
      returnData = data.replace(/\w+/g, function (w) {
        return w[0].toUpperCase() + w.slice(1).toLowerCase();
      });
    }
    return returnData;
  };

  render() {
    const { data, columnOptions } = this.props;
    const roleId = Common.getUserRole();
    const translations = Common.getTranslations() || {};
    return (
      <Fragment>
        <Table.Row className="table-border-layout">
          {columnOptions &&
            columnOptions.map((column, idx) => {
              if (
                column &&
                column.key === "status" &&
                column.visible === 1 &&
                column.disabled === 0
              ) {
                return (
                  <Table.Cell key={idx} textAlign="left" className="list-td">
                    <div className="fixed-table">
                      {" "}
                      <Link
                        className="ellipsis-preview"
                        to={`/home/label-order-details/${data.requestNumber}/${data.labelOrderType}`}
                      >
                        {Common.translateStatus(
                          this.adjustStatus(data.statusName),
                          translations
                        )}
                      </Link>
                    </div>
                  </Table.Cell>
                );
              } else if (
                column &&
                column.key === "cartOrderNo" &&
                column.visible === 1 &&
                column.disabled === 0
              ) {
                return (
                  <Table.Cell key={idx} textAlign="left" className="list-td">
                    <div className="fixed-table">
                      <div className="ellipsis-preview">
                        {data.requestNumber}
                      </div>
                    </div>
                  </Table.Cell>
                );
              } else if (
                column &&
                column.key === "orderNo" &&
                column.visible === 1 &&
                column.disabled === 0
              ) {
                return (
                  <Table.Cell key={idx} textAlign="left" className="list-td">
                    <div className="fixed-table">
                      <div className="ellipsis-preview">
                        {data.isMultiple
                          ? data.oracleNumber
                          : // data.isExpandMultiple === true ? (
                          //   data.oracleNumber
                          // ) : (
                          //   <Label
                          //     style={{ border: 0 }}
                          //     basic
                          //     circular
                          //     color="blue"
                          //     className="ellipsis-preview"
                          //   >
                          //     MULTIPLE
                          //   </Label>
                          // )
                          data.oracleNumber
                          ? data.oracleNumber
                          : roleId == Role.applicant ||
                            roleId == Role.manufacturer
                          ? ""
                          : data.oracleMessage}
                      </div>
                    </div>
                  </Table.Cell>
                );
              } else if (
                column &&
                column.key === "date" &&
                column.visible === 1 &&
                column.disabled === 0
              ) {
                return (
                  <Table.Cell key={idx} textAlign="left" className="list-td">
                    <div className="fixed-table">
                      <div className="ellipsis-preview">
                        {data.updatedUnixDate}
                      </div>
                    </div>
                  </Table.Cell>
                );
              } else if (
                column &&
                column.key === "fileNumbers" &&
                column.visible === 1 &&
                column.disabled === 0
              ) {
                return (
                  <Table.Cell key={idx} textAlign="left" className="list-td">
                    <div className="fixed-table">
                      <div className="ellipsis-preview">{data.fileNumbers}</div>
                    </div>
                  </Table.Cell>
                );
              } else if (
                column &&
                column.key === "labelType" &&
                column.visible === 1 &&
                column.disabled === 0
              ) {
                return (
                  <Table.Cell key={idx} textAlign="left" className="list-td">
                    <div className="fixed-table">
                      <div className="ellipsis-preview">
                        {data.labelOrderType == 1
                          ? "Standard Labels"
                          : "Combination Labels"}
                      </div>
                    </div>
                  </Table.Cell>
                );
              } else if (
                column &&
                column.key === "view" &&
                column.visible === 1 &&
                column.disabled === 0
              ) {
                return (
                  <Table.Cell key={idx} textAlign="left" className="list-td">
                    <div className="fixed-table">
                      {data.statusCode != 1 && (
                        <div className="ellipsis-preview">
                          <NavLink
                            to={`/home/label-order-details/${data.requestNumber}/${data.labelOrderType}`}
                          >
                            {translations["LexViewDetails"]}
                          </NavLink>
                        </div>
                        // <Button
                        //   basic
                        //   compact
                        //   color="blue"
                        //   as={Link}
                        //   to={`/home/label-order-details/${data.requestNumber}/${data.labelOrderType}`}
                        // >
                        //   View
                        // </Button>
                      )}
                      {data.statusCode === 1 && (
                        <NavLink
                          to={"/home/draft-cart-details/" + data.labelOrderId}
                        >
                          <div className="ellipsis-preview">View Details</div>
                        </NavLink>
                        // <Button
                        //   basic
                        //   compact
                        //   color="blue"
                        //   as={Link}
                        //   to={"/home/draft-cart-details/" + data.labelOrderId}
                        // >
                        //   View
                        // </Button>
                      )}
                    </div>
                  </Table.Cell>
                );
              }
            })}
        </Table.Row>
        {data &&
          data.isExpandMultiple === true &&
          data.childItems &&
          data.childItems.map((item, idx) => {
            return (
              <Table.Row active>
                {columnOptions &&
                  columnOptions.map((column, idx) => {
                    if (
                      column &&
                      column.key === "multiple" &&
                      column.visible === 1 &&
                      column.disabled === 0
                    ) {
                      return <Table.Cell></Table.Cell>;
                    } else if (
                      column &&
                      column.key === "status" &&
                      column.visible === 1 &&
                      column.disabled === 0
                    ) {
                      return (
                        <Table.Cell key={idx} textAlign="left">
                          <div className="fixed-table">
                            {" "}
                            <Link
                              to={`/home/label-order-details/${item.requestNumber}/${item.labelOrderType}`}
                            >
                              <div className="ellipsis-preview">
                                {item.statusName}
                              </div>
                            </Link>
                          </div>
                        </Table.Cell>
                      );
                    } else if (
                      column &&
                      column.key === "cartOrderNo" &&
                      column.visible === 1 &&
                      column.disabled === 0
                    ) {
                      return (
                        <Table.Cell key={idx} textAlign="left">
                          <div className="fixed-table">
                            <div className="ellipsis-preview">
                              {item.requestNumber}
                            </div>
                          </div>
                        </Table.Cell>
                      );
                    } else if (
                      column &&
                      column.key === "orderNo" &&
                      column.visible === 1 &&
                      column.disabled === 0
                    ) {
                      return (
                        <Table.Cell key={idx} textAlign="left">
                          <div className="fixed-table">
                            <div className="ellipsis-preview">
                              {" "}
                              {item.oracleNumber ? item.oracleNumber : ""}
                            </div>
                          </div>
                        </Table.Cell>
                      );
                    } else if (
                      column &&
                      column.key === "date" &&
                      column.visible === 1 &&
                      column.disabled === 0
                    ) {
                      return (
                        <Table.Cell key={idx} textAlign="left">
                          <div className="fixed-table">
                            <div className="ellipsis-preview">
                              {item.updatedUnixDate}
                            </div>
                          </div>
                        </Table.Cell>
                      );
                    } else if (
                      column &&
                      column.key === "fileNumbers" &&
                      column.visible === 1 &&
                      column.disabled === 0
                    ) {
                      return (
                        <Table.Cell key={idx} textAlign="left">
                          <div className="fixed-table">
                            <div className="ellipsis-preview">
                              {data.fileNumbers}
                            </div>
                          </div>
                        </Table.Cell>
                      );
                    } else if (
                      column &&
                      column.key === "labelType" &&
                      column.visible === 1 &&
                      column.disabled === 0
                    ) {
                      return (
                        <Table.Cell key={idx} textAlign="left">
                          <div className="fixed-table">
                            <div className="ellipsis-preview">
                              {data.labelOrderType == 1
                                ? "Standard Labels"
                                : "Combination Labels"}
                            </div>
                          </div>
                        </Table.Cell>
                      );
                    } else if (
                      column &&
                      column.key === "view" &&
                      column.visible === 1 &&
                      column.disabled === 0
                    ) {
                      return (
                        <Table.Cell key={idx} textAlign="left"></Table.Cell>
                      );
                    }
                  })}
              </Table.Row>
            );
          })}
      </Fragment>
    );
  }
}

export default DashBoardItem;
