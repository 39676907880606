import React, { Component, Fragment } from "react";
import {
  Accordion,
  Dimmer,
  Divider,
  Icon,
  Loader,
  Segment,
  Grid
} from "semantic-ui-react";
import { Common } from "../../../common";
import {
  CustomHeaderLabel,
  CustomHeader,
  CustomHeaderLabelRelaxed,
  CustomHeaderInlineBoldRelaxed
} from "../../../components/labels/labels";
import { CustomHeaderBold } from "../../../components/labels/labels";

class CustomerInformation extends Component {
  state = {
    activeIndex: 0
  };

  toggleHelp = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  render() {
    const { activeIndex } = this.state;
    const { model, loadingCustomerInfo, data } = this.props;

    return (
      <Dimmer.Dimmable as={Segment} dimmed={loadingCustomerInfo}>
        <Dimmer active={loadingCustomerInfo} inverted>
          <Loader />
        </Dimmer>

        <Accordion>
          <Accordion.Title
            active={activeIndex === 0}
            index={0}
            onClick={this.toggleHelp}
          >
            <Icon name="dropdown" />
            <CustomHeaderLabel message="Customer Information" />
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 0}>
            <Divider />

            <Grid columns={3}>
              <Grid.Column width={5} style={{ textAlign: "justify" }}>
                <i>
                  Please review the information we have on record for your
                  account, to make sure our service contract information is up
                  to date.
                </i>
                <Divider hidden />
                <i>
                  If you see any issues, please contact us to update our
                  records:
                </i>
                <Divider hidden />
                <p style={{ fontSize: "10px", fontWeight: "700" }}>
                  CONTACT US
                </p>
                <p className="info">
                  <a
                    href="https://marks.ul.com/about/ul-listing-and-classification-marks/labels/label-centers/"
                    target="_blank"
                  >
                    CONTACT UL Solutions LABEL CENTERS
                  </a>
                </p>
              </Grid.Column>
              <Grid.Column width={5}>
                <CustomHeaderBold message="Contact Information" />
                {data && data.labelOrderType == 1 && (
                  <Fragment>
                    <label>{(model && model.partyName) || " "}</label>
                    <Divider hidden fitted />
                    <label>{(data && data.customerEmailAddress) || " "}</label>

                    <Divider hidden fitted />
                    <label>{(data && data.customerPhoneNumber) || " "}</label>
                    <Divider hidden />
                  </Fragment>
                )}
                {data && data.labelOrderType == 2 && (
                  <Fragment>
                    {/* <label>{`${Common.getFirstName()} ${Common.getLastName()}`}</label>
                    <Divider hidden fitted />
                    <label>{Common.getEmail()}</label>

                    <Divider hidden fitted />
                    <label>{Common.getPhoneNumber()}</label>
                    <Divider hidden /> */}
                    <label>
                      {(model && model.combintationCustomerName) || " "}
                    </label>
                    <Divider hidden fitted />
                    <label>
                      {(model && model.combintationCustomerEmail) || " "}
                    </label>

                    <Divider hidden fitted />
                    <label>
                      {(model && model.combintationCustomerPhone) || " "}
                    </label>
                    <Divider hidden />
                  </Fragment>
                )}

                <label>{(model && model.addressLine1) || " "}</label>
                <Divider hidden fitted />
                <label>
                  {`${(model && model.city) || ""}  ${
                    (model && model.state) || ""
                  }`}
                </label>
                <Divider hidden fitted />
                <label>
                  {`${(model && model.postalCode) || ""} ${
                    (model && model.countryCode) || ""
                  }`}
                </label>
              </Grid.Column>
              <Grid.Column width={6}>
                <CustomHeaderBold message="User Name" />
                <label>{(model && model.partySiteName) || " "}</label>
                <Divider hidden />

                <CustomHeaderBold message="Account Number" />
                <label>{(model && model.accountNumber) || " "}</label>

                <Divider hidden />
                <CustomHeaderBold message="Party Site Number" />
                <label>{(model && model.psn) || " "}</label>
              </Grid.Column>
            </Grid>
          </Accordion.Content>
        </Accordion>
      </Dimmer.Dimmable>
    );
  }
}

export default CustomerInformation;
