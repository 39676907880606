import * as types from "./profile.type";

let initialState = {
  data: []
};

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
    case types.PROFILE_REQUEST:
    case types.PROFILE_SUCCESS:
    case types.PROFILE_FAILURE:
      return Object.assign({}, state, {
        data: action.data || initialState.data
      });
    case types.CLEAR:
      return Object.assign({}, state, {
        data: initialState.data
      });
  }
};

export default profileReducer;
