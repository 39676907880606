import React, { Component, Fragment } from "react";
import {
  Accordion,
  Icon,
  Divider,
  Segment,
  Grid,
  Input,
  Form,
  Select,
  Button
} from "semantic-ui-react";
import {
  CustomHeaderInlineBold,
  CustomHeaderBold,
  CustomHeaderLabel,
  RequiredFieldMessage
} from "../../components/labels/labels";
import { renderAsterisk } from "../../helpers/component-helper";

const labelCenterOptions = [
  {
    key: "",
    value: "",
    text: "Choose from available options"
  },
  {
    key: "china",
    value: "china",
    text: "UL Solutions Label Center Guangzhou"
  },
  {
    key: "japan",
    value: "japan",
    text: "UL Solutions Label Center Japan"
  },
  {
    key: "america",
    value: "america",
    text: "UL Solutions Label Center Latin America"
  }
];

const shippingOptions = [
  {
    key: "",
    value: "",
    text: "Choose from available options"
  },
  {
    key: "fedex",
    value: "fedex",
    text: "FedEx"
  },
  {
    key: "ups",
    value: "ups",
    text: "UPS"
  }
];

class LabelProduction extends Component {
  state = {
    activeIndex: 0,
    value: "ul",
    labelCenter: "",
    instructions: "",
    labelSupplier: ""
  };

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
    this.props.onHandleChange(name, value);
  };

  toggleHelp = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  render() {
    const { activeIndex, labelCenter, instructions, labelSupplier } =
      this.state;

    return (
      <Fragment>
        <Segment>
          <Accordion>
            <Accordion.Title
              active={activeIndex === 0}
              index={0}
              onClick={this.toggleHelp}
            >
              <Icon name="dropdown" />
              <CustomHeaderLabel message="Label production" />
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 0}>
              <Divider />
              <Grid>
                <Grid.Column width={4} style={{ textAlign: "justify" }}>
                  <i>
                    Adjust your preferences for how the labels are produced. If
                    you have questions, please contact us.
                  </i>
                </Grid.Column>
                <Grid.Column width={10}>
                  <Form.Field>
                    <CustomHeaderInlineBold message="UL Solutions label center" />
                    {renderAsterisk()}
                    <RequiredFieldMessage
                      isError={this.props.fieldErrors.labelCenterError}
                    />
                    <Form.Select
                      placeholder="Choose from available options"
                      options={labelCenterOptions}
                      onChange={this.handleChange}
                      error={this.props.fieldErrors.labelCenterError}
                      name="labelCenter"
                      value={labelCenter}
                    />
                  </Form.Field>
                  <Form.Field>
                    <CustomHeaderInlineBold message="Instructions for the label center (optional)" />
                    {renderAsterisk()}
                    <RequiredFieldMessage
                      isError={this.props.fieldErrors.instructionsError}
                    />
                    <Form.TextArea
                      placeholder="Instructions"
                      error={this.props.fieldErrors.instructionsError}
                      name="instructions"
                      value={instructions}
                      onChange={this.handleChange}
                    />
                  </Form.Field>
                  <Form.Field>
                    <CustomHeaderInlineBold message="Authorized label supplier" />
                    {renderAsterisk()}
                    <RequiredFieldMessage
                      isError={this.props.fieldErrors.labelSupplierError}
                    />
                    <Form.Select
                      placeholder="Choose from available options"
                      options={labelCenterOptions}
                      onChange={this.handleChange}
                      error={this.props.fieldErrors.labelSupplierError}
                      name="labelSupplier"
                      value={labelSupplier}
                    />
                  </Form.Field>
                  <Form.Field>
                    <CustomHeaderBold message="Shipping" />
                    <Grid>
                      <Grid.Row columns={3}>
                        <Grid.Column width={6}>
                          <Select
                            placeholder="Shipping address"
                            options={labelCenterOptions}
                          />
                        </Grid.Column>
                        <Grid.Column width={4}>
                          <Input fluid name="quantity" placeholder="Quantity" />
                        </Grid.Column>
                        <Grid.Column width={3}>
                          <Select
                            placeholder="Shipping method"
                            options={shippingOptions}
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Form.Field>
                  <Form.Field>
                    <Button primary basic>
                      Ship to an additional address
                    </Button>
                  </Form.Field>
                </Grid.Column>
              </Grid>
            </Accordion.Content>
          </Accordion>
        </Segment>
      </Fragment>
    );
  }
}

export default LabelProduction;
