import {
  ADrules,
  ADADrules,
  ADABTLDrules,
  ADQADrules,
  SDRules,
  SDPSLSrules,
  SDFISSrules,
  SDAHJDrules,
  PDrules,
  RDPSLSrules,
  RDFSLSrules,
  RDAHJDrules,
  RDrules,
  ASrules,
  FErules,
  SDOtherDetailsRules,
  SDFSLSrules,
} from "./validation-rules";

export const GetFormData = (form, formType) => {
  let {
    applicantDetails,
    siteDetails,
    productDetails,
    reservationDetails,
    accountSettings,
    otherDetails,
  } = form;

  let formData;
  let rules;
  switch (formType) {
    case "AD":
      formData = applicantDetails;
      rules = ADrules;
      break;
    case "AD-AD":
      formData = applicantDetails.applicantDetails;
      rules = ADADrules;
      break;
    case "AD-ABTLD":
      formData = applicantDetails.applicantBillToLocationDetails;
      rules = ADABTLDrules;
      break;
    case "AD-QAD":
      formData = applicantDetails.quoteAcceptanceDetails;
      rules = ADQADrules;
      break;
    case "SD":
      formData = siteDetails;
      rules = SDPSLSrules;
      break;
    case "SD-PSLS":
      formData = siteDetails.preliminarySiteLocationSection;
      rules = SDPSLSrules;
      break;
    case "SD-FISS":
      formData = siteDetails.finalInstallationSiteSection;
      rules = SDFISSrules;
      break;
    case "SD-AHJD":
      formData = siteDetails.authorityHavingJurisdictionDetails;
      rules = SDAHJDrules;
      break;
    case "SD-OTHERS":
      formData = otherDetails;
      rules = SDOtherDetailsRules;
      break;
    case "PD":
      formData = productDetails;
      rules = PDrules;
      break;
    case "RD":
      formData = reservationDetails;
      rules = RDrules;
      break;
    case "RD-PSLS":
      formData = reservationDetails.preliminarySiteLocationSection;
      rules = RDPSLSrules;
      break;
    case "RD-FSLS":
      formData = reservationDetails.finalSiteLocationSection;
      rules = RDFSLSrules;
      break;
    case "RD-AHJD":
      formData = reservationDetails.authorityHavingJurisdictionDetails;
      rules = RDAHJDrules;
      break;
    case "AS":
      formData = accountSettings;
      rules = ASrules;
      break;
    case "FE":
      formData = otherDetails;
      rules = FErules;
      break;
  }

  const result = {
    formData,
    rules,
  };

  return result;
};

export const GetFormDataRD = (form, formType) => {
  let {
    preliminarySiteLocationSection,
    finalSiteLocationSection,
    authorityHavingJurisdictionDetails,
    siteSpecificRequirements
  } = form;

  let formData;
  let rules;

  switch (formType) {
    case "RD-PSLS":
      formData = preliminarySiteLocationSection;
      rules = RDPSLSrules;
      break;
    case "RD-FSLS":
      formData = finalSiteLocationSection;
      rules = RDFSLSrules;
      break;
    case "RD-AHJD":
      formData = authorityHavingJurisdictionDetails;
      rules = RDAHJDrules;
      break;
  }

  const result = {
    formData,
    rules
  };

  return result;
};

export const GetStatusCode = (status) => {
  let statusCode;

  switch (status) {
    case 1:
    case "DRAFT":
      statusCode = "DRAFT";
      break;
    case 2:
    case "INPROGRESS":
      statusCode = "IN PROGRESS";
      break;
    case 3:
    case "PROCESSED":
      statusCode = "PROCESSED";
      break;
    case 4:
    case "CANCELLED":
      statusCode = "CANCELLED";
      break;
    case 6:
    case "ACCEPTED":
      statusCode = "ACCEPTED";
      break;
    case 9:
    case "OFFERED":
      statusCode = "OFFERED";
      break;
    case 7:
    case "PENDINGASSESSMENT":
      statusCode = "PENDING UL ASSESSMENT";
      break;
  }

  return statusCode;
};

export const unixToLocaleDate = (unixDateTime) => {
  var scheduleDateTime = new Date(0);
  scheduleDateTime.setSeconds(unixDateTime);
  return scheduleDateTime.toLocaleString();
};
