import React, { Component } from "react";
import { Modal, Grid, Table, Header, Button } from "semantic-ui-react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Property from "../../../../enums/property";
import ScopeValue from "./scope-value";

class FeSummary extends Component {
  render() {
    const {
      openSummaryModal,
      onCloseSummaryModal,
      primaryDetails,
      billToDetails,
      reservationDetails,
      siteDetails,
      otherDetails,
      accountSettings,
      fe,
      isFromOffered
    } = this.props;

    let final = otherDetails.isReadyToScheduleNow
      ? reservationDetails.finalSiteLocationSection
      : siteDetails.finalInstallationSiteSection;
    let ahj = otherDetails.isReadyToScheduleNow
      ? reservationDetails.authorityHavingJurisdictionDetails
      : siteDetails.authorityHavingJurisdictionDetails;
    let prelim = reservationDetails.preliminarySiteLocationSection;
    let otherTraining = prelim.otherTrainingDetails;
    let scheduleDate = prelim.scheduleDate
      ? new Date(prelim.scheduleDate).toDateOnlyStringFormat()
      : "";
    let scheduleTime = prelim.scheduleDate
      ? new Date(prelim.scheduleDate).toTimeOnlyStringFormat()
      : "";

    return (
      <Modal size="large" open={openSummaryModal} onClose={onCloseSummaryModal}>
        <Modal.Header className="center">Project Review Summary</Modal.Header>
        <Modal.Content scrolling>
          <Grid>
            <Grid.Row>
              <Grid.Column width={8}>
                <Table celled striped compact>
                  <Table.Header>
                    <Table.Row className="center">
                      <Table.HeaderCell colSpan="2">
                        Applicant Details
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell collapsing>UL Account #</Table.Cell>
                      <Table.Cell>
                        {
                          accountSettings.applicantDetails.applicantDetails
                            .accountNumber
                        }
                      </Table.Cell>
                    </Table.Row>

                    <Table.Row>
                      <Table.Cell collapsing>Company Name</Table.Cell>
                      <Table.Cell>
                        {primaryDetails && primaryDetails.legalCompanyName}
                      </Table.Cell>
                    </Table.Row>

                    <Table.Row>
                      <Table.Cell collapsing>Address</Table.Cell>
                      <Table.Cell>
                        {primaryDetails && primaryDetails.addressLine1}
                        <br />
                        {primaryDetails && primaryDetails.city}{" "}
                        {primaryDetails && primaryDetails.state}
                        <br />
                        {primaryDetails && primaryDetails.country},{" "}
                        {primaryDetails && primaryDetails.postalCode}
                        {primaryDetails && primaryDetails.addressLine2}
                        {primaryDetails && primaryDetails.addressLine3}
                      </Table.Cell>
                    </Table.Row>

                    <Table.Row>
                      <Table.Cell collapsing>Contact Name</Table.Cell>
                      <Table.Cell>
                        {primaryDetails && primaryDetails.contactName}
                      </Table.Cell>
                    </Table.Row>

                    <Table.Row>
                      <Table.Cell collapsing>Contact Details</Table.Cell>
                      <Table.Cell>
                        <table className="no-border">
                          <tbody>
                            <tr>
                              <td>
                                <b>T:</b>
                              </td>
                              <td>
                                {primaryDetails && primaryDetails.phoneNumber}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b>M:</b>
                              </td>
                              <td>
                                {primaryDetails && primaryDetails.mobileNumber}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b>E:</b>
                              </td>
                              <td>
                                {primaryDetails && primaryDetails.emailAddress}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Grid.Column>

              <Grid.Column width={8}>
                <Table celled striped compact>
                  <Table.Header>
                    <Table.Row className="center">
                      <Table.HeaderCell colSpan="2">
                        Bill To Details
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell collapsing>UL Account #</Table.Cell>
                      <Table.Cell>
                        {
                          accountSettings.applicantDetails
                            .applicantBillToLocationDetails.accountNumber
                        }
                      </Table.Cell>
                    </Table.Row>

                    <Table.Row>
                      <Table.Cell collapsing>Company Name</Table.Cell>
                      <Table.Cell>
                        {billToDetails && billToDetails.legalCompanyName}
                      </Table.Cell>
                    </Table.Row>

                    <Table.Row>
                      <Table.Cell collapsing>Address</Table.Cell>
                      <Table.Cell>
                        {billToDetails && billToDetails.addressLine1}
                        <br />
                        {billToDetails && billToDetails.city}{" "}
                        {billToDetails && billToDetails.state}
                        <br />
                        {billToDetails && billToDetails.country},{" "}
                        {billToDetails && billToDetails.postalCode}
                        {billToDetails && billToDetails.addressLine2}
                        {billToDetails && billToDetails.addressLine3}
                      </Table.Cell>
                    </Table.Row>

                    <Table.Row>
                      <Table.Cell collapsing>Contact Name</Table.Cell>
                      <Table.Cell>
                        {billToDetails && billToDetails.contactName}
                      </Table.Cell>
                    </Table.Row>

                    <Table.Row>
                      <Table.Cell collapsing>Contact Details</Table.Cell>
                      <Table.Cell>
                        <table className="no-border">
                          <tbody>
                            <tr>
                              <td>
                                <b>T:</b>
                              </td>
                              <td>
                                {billToDetails && billToDetails.phoneNumber}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b>M:</b>
                              </td>
                              <td>
                                {billToDetails && billToDetails.mobileNumber}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b>E:</b>
                              </td>
                              <td>
                                {billToDetails && billToDetails.emailAddress}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column width={3}>Client Reference #:</Grid.Column>
              <Grid.Column width={4}>
                {otherDetails.customerReferenceNumber
                  ? otherDetails.customerReferenceNumber
                  : "-NOTHING PROVIDED-"}
              </Grid.Column>
            </Grid.Row>

            <Grid.Row>
              <Grid.Column>
                <Table celled striped compact structured>
                  <Table.Header>
                    <Table.Row className="center">
                      <Table.HeaderCell colSpan="2">
                        Project Summary Details
                      </Table.HeaderCell>
                    </Table.Row>

                    <Table.Row className="center">
                      <Table.HeaderCell colSpan="2">
                        SITE 1: Project & Scheduling Details –{" "}
                        <i>
                          The below summarizes the scope of your project and
                          scheduling details from your application:
                        </i>
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {otherDetails.isReadyToScheduleNow ? (
                      <Table.Row>
                        <Table.Cell collapsing>
                          Requested Scheduled Date & Time:
                        </Table.Cell>
                        <Table.Cell>
                          <Table>
                            <Table.Header>
                              <Table.Row className="center">
                                <Table.HeaderCell collapsing>
                                  Date:
                                </Table.HeaderCell>
                                <Table.HeaderCell collapsing>
                                  Time:
                                </Table.HeaderCell>
                              </Table.Row>
                            </Table.Header>
                            <Table.Body>
                              <Table.Row>
                                <Table.Cell textAlign="center">
                                  {scheduleDate}
                                </Table.Cell>
                                <Table.Cell textAlign="center">
                                  {scheduleTime}
                                </Table.Cell>
                              </Table.Row>
                            </Table.Body>
                          </Table>
                        </Table.Cell>
                      </Table.Row>
                    ) : null}

                    <Table.Row>
                      <Table.Cell collapsing>Project Scope:</Table.Cell>
                      <Table.Cell>
                        <span>{ScopeValue(fe)}</span>
                      </Table.Cell>
                    </Table.Row>

                    <Table.Row>
                      <Table.Cell>Project Details:</Table.Cell>
                      <Table.Cell>
                        <Table>
                          <Table.Body>
                            <Table.Row>
                              <Table.Cell collapsing>
                                Project Category:
                              </Table.Cell>
                              <Table.Cell>
                                {fe.serviceProductName || ""}
                              </Table.Cell>
                            </Table.Row>

                            <Table.Row>
                              <Table.Cell collapsing>
                                Equipment Type:
                              </Table.Cell>
                              <Table.Cell>
                                {fe.serviceSubProductName || ""}
                              </Table.Cell>
                            </Table.Row>

                            <Table.Row>
                              <Table.Cell collapsing>
                                # of Units (EA):
                              </Table.Cell>
                              <Table.Cell>{fe.quantity || ""}</Table.Cell>
                            </Table.Row>

                            <Table.Row>
                              <Table.Cell collapsing>Property Type:</Table.Cell>
                              <Table.Cell>
                                {(
                                  Object.keys(Property).find(
                                    (k) => Property[k] === fe.propertyType
                                  ) || ""
                                ).capitalize()}
                              </Table.Cell>
                            </Table.Row>

                            {fe.isPanelboard ? (
                              <Table.Row>
                                <Table.Cell collapsing>
                                  Is the Panelboard Main breaker rated over
                                  200A?:
                                </Table.Cell>
                                <Table.Cell>
                                  {fe.panelboardOver200 || "" ? "Yes" : "No"}
                                </Table.Cell>
                              </Table.Row>
                            ) : null}

                            {fe.isSwitchboard ? (
                              <Table.Row>
                                <Table.Cell collapsing>
                                  Is the Switchboard/Switchgear rated over 600
                                  VAC?:
                                </Table.Cell>
                                <Table.Cell>
                                  {fe.switchboardOver600 || "" ? "Yes" : "No"}
                                </Table.Cell>
                              </Table.Row>
                            ) : null}

                            {fe.quantity ? (
                              parseInt(fe.quantity) > 1 ? (
                                <Table.Row>
                                  <Table.Cell>
                                    Will all units be installed at the same
                                    location?:
                                  </Table.Cell>
                                  <Table.Cell>
                                    {fe.isInstalledSameLocation || ""
                                      ? "Yes"
                                      : "No"}
                                  </Table.Cell>
                                </Table.Row>
                              ) : null
                            ) : null}

                            {fe.isInstalledSameLocation &&
                            parseInt(fe.quantity) > 1 ? (
                              parseInt(fe.quantity) > 1 ? (
                                <Table.Row>
                                  <Table.Cell>
                                    Can all of the units be evaluated during the
                                    same day/visit?:
                                  </Table.Cell>
                                  <Table.Cell>
                                    {fe.isEvaluatedSameVisit || ""
                                      ? "Yes"
                                      : "No"}
                                  </Table.Cell>
                                </Table.Row>
                              ) : null
                            ) : null}

                            <Table.Row>
                              <Table.Cell>
                                Is there an existing UL label(s) on the
                                equipment?:
                              </Table.Cell>
                              <Table.Cell>
                                {fe.isExistingUlEquipment || "" ? "Yes" : "No"}
                              </Table.Cell>
                            </Table.Row>

                            <Table.Row>
                              <Table.Cell collapsing>
                                Is product involved in current or past
                                litigation?:
                              </Table.Cell>
                              <Table.Cell>
                                {fe.isInvolvedLitigation || "" ? "Yes" : "No"}
                              </Table.Cell>
                            </Table.Row>

                            {fe.isInvolvedLitigation || "" ? (
                              <Table.Row>
                                <Table.Cell>Litigation Description:</Table.Cell>
                                <Table.Cell>
                                  {fe.litigationDescription}
                                </Table.Cell>
                              </Table.Row>
                            ) : null}

                            <Table.Row>
                              <Table.Cell collapsing>
                                Additional Information:
                              </Table.Cell>
                              <Table.Cell>{fe.additionalInfo || ""}</Table.Cell>
                            </Table.Row>
                          </Table.Body>
                        </Table>
                      </Table.Cell>
                    </Table.Row>

                    <Table.Row>
                      <Table.Cell collapsing>
                        Final Installation Site:
                      </Table.Cell>
                      <Table.Cell>
                        {final && final.residenceName}
                        <br />
                        {final && final.addressLine1}
                        <br />
                        {final && final.city} {final && final.state}
                        <br />
                        {final && final.country}, {final && final.postalCode}
                        <br />
                        {final && final.addressLine2}
                        <br />
                        {final && final.addressLine3}
                      </Table.Cell>
                    </Table.Row>

                    <Table.Row>
                      <Table.Cell>Final Site Contact Details:</Table.Cell>
                      <Table.Cell>
                        <Table>
                          <Table.Body>
                            <Table.Row>
                              <Table.Cell collapsing>Contact Name:</Table.Cell>
                              <Table.Cell>
                                {final.siteContactName || ""}
                              </Table.Cell>
                            </Table.Row>

                            <Table.Row>
                              <Table.Cell collapsing>Office:</Table.Cell>
                              <Table.Cell>
                                {final.siteContactOfficeNumber || ""}
                              </Table.Cell>
                            </Table.Row>

                            <Table.Row>
                              <Table.Cell collapsing>Mobile:</Table.Cell>
                              <Table.Cell>
                                {final.siteContactMobileNumber || ""}
                              </Table.Cell>
                            </Table.Row>

                            <Table.Row>
                              <Table.Cell collapsing>Email:</Table.Cell>
                              <Table.Cell>
                                {final.siteContactEmailAddress || ""}
                              </Table.Cell>
                            </Table.Row>
                          </Table.Body>
                        </Table>
                      </Table.Cell>
                    </Table.Row>

                    <Table.Row>
                      <Table.Cell>Final Site Specific Requirements:</Table.Cell>
                      <Table.Cell>
                        <Table>
                          <Table.Body>
                            <Table.Row>
                              <Table.Cell collapsing>Site Training:</Table.Cell>
                              <Table.Cell>
                                {reservationDetails.trainingSelections
                                  .filter((tr) => tr.checked)
                                  .map((t, idx) => {
                                    return (
                                      <div key={idx}>
                                        <span>
                                          {t.text}
                                          {/* 1: On-site Training, display 2 hours training */}
                                          {t.value == 1 && t.checked
                                            ? `: Will the training take more than 2 hours? : ${
                                                t.trainingTwoHours
                                                  ? "Yes"
                                                  : "No"
                                              }`
                                            : null}
                                          {/* 3: Others, display others inputted */}
                                          {t.value == 3 && t.checked
                                            ? `: ${otherTraining}`
                                            : null}
                                        </span>
                                        <br />
                                      </div>
                                    );
                                  })}
                              </Table.Cell>
                            </Table.Row>

                            <Table.Row>
                              <Table.Cell collapsing>
                                Security Steps:
                              </Table.Cell>
                              <Table.Cell>
                                {prelim.securityStepDetail || ""}
                              </Table.Cell>
                            </Table.Row>

                            <Table.Row>
                              <Table.Cell collapsing>
                                Other Site Requirements:
                              </Table.Cell>
                              <Table.Cell>
                                {prelim.otherSiteRequirements || ""}
                              </Table.Cell>
                            </Table.Row>

                            <Table.Row>
                              <Table.Cell collapsing>Required PPE:</Table.Cell>
                              <Table.Cell>
                                {reservationDetails.ppeSelections
                                  .filter((p) => p.checked)
                                  .map((pr, idx) => {
                                    return (
                                      <div key={idx}>
                                        <span>{pr.text}</span>
                                        <br />
                                      </div>
                                    );
                                  })}
                              </Table.Cell>
                            </Table.Row>
                          </Table.Body>
                        </Table>
                      </Table.Cell>
                    </Table.Row>

                    <Table.Row>
                      <Table.Cell>Authority Having Jurisdiction:</Table.Cell>
                      <Table.Cell>
                        {`${ahj.jurisdictionName || ""}`}
                        <br />
                        {`${ahj.addressLine1 || ""} ${ahj.addressLine2 || ""} ${
                          ahj.addressLine3 || ""
                        } ${ahj.city || ""}`}
                        <br />
                        {`${ahj.country || ""}, ${ahj.state || ""} ${
                          ahj.postalCode || ""
                        }`}
                      </Table.Cell>
                    </Table.Row>

                    <Table.Row>
                      <Table.Cell>Inspector Contact Details:</Table.Cell>
                      <Table.Cell>
                        <Table>
                          <Table.Body>
                            <Table.Row>
                              <Table.Cell collapsing>Contact Name:</Table.Cell>
                              <Table.Cell>{ahj.inspectorName || ""}</Table.Cell>
                            </Table.Row>

                            <Table.Row>
                              <Table.Cell collapsing>Office:</Table.Cell>
                              <Table.Cell>
                                {ahj.inspectorOfficeNumber || ""}
                              </Table.Cell>
                            </Table.Row>

                            <Table.Row>
                              <Table.Cell collapsing>Mobile:</Table.Cell>
                              <Table.Cell>
                                {ahj.inspectorMobileNumber || ""}
                              </Table.Cell>
                            </Table.Row>

                            <Table.Row>
                              <Table.Cell collapsing>Email:</Table.Cell>
                              <Table.Cell>
                                {ahj.inspectorEmailAddress || ""}
                              </Table.Cell>
                            </Table.Row>
                          </Table.Body>
                        </Table>
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button primary as={Link} to="/home/quotation">
            Continue
          </Button>
          <Button primary basic onClick={onCloseSummaryModal}>
            {!isFromOffered ? "Edit" : "Close"}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

FeSummary.propTypes = {
  openSummaryModal: PropTypes.bool.isRequired,
  onCloseSummaryModal: PropTypes.func.isRequired,
  primaryDetails: PropTypes.object.isRequired,
  billToDetails: PropTypes.object.isRequired,
  reservationDetails: PropTypes.object.isRequired,
  siteDetails: PropTypes.object.isRequired,
  otherDetails: PropTypes.object.isRequired,
  accountSettings: PropTypes.object.isRequired,
  fe: PropTypes.object.isRequired,
  isFromOffered: PropTypes.bool.isRequired
};

export default FeSummary;
