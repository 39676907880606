import { UPDATE_MENU } from "./sidebar-menu.type";

const dispatchRequest = (obj, type) => {
  return {
    data: obj,
    type: type
  };
};

export const updateMenu = (data) => {
  return (dispatch) =>
    dispatch({
      type: UPDATE_MENU,
      payload: { data }
    });
};
