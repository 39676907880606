import * as types from "./register.type";

let initialState = {
  data: [],
  form: {
    isExternal: false,
    showErrors: false,
    validationErrors: {}
  }
};

const registerReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
    case types.REGISTER_REQUEST:
    case types.REGISTER_SUCCESS:
    case types.REGISTER_FAILURE:
      return Object.assign({}, state, {
        data: action.data || initialState.data
      });
    case types.FORM:
      return Object.assign({}, state, {
        form: action.data || initialState.form
      });
    case types.CLEAR:
      return Object.assign({}, state, {
        data: initialState.data,
        form: {
          showErrors: false,
          validationErrors: {}
        }
      });
    case types.PARTYSITENUMBER_DETAILS_REQUEST:
      return Object.assign({}, state, {});
    case types.PARTYSITENUMBER_DETAILS_FAILURE:
    case types.PARTYSITENUMBER_DETAILS_SUCCESS:
      // return Object.assign({}, state, {
      //   form: action.data
      // });
      var result = Object.assign({}, state);

      if (action.data) {
        if (action.data.data) {
          var detail = action.data.data;

          result.form.firstName = detail.firstName;
          result.form.lastName = detail.lastName;
          result.form.email = detail.emailAddress;
          result.form.roleId = detail.roleId;
        } else {
          result.form.firstName = null;
          result.form.lastName = null;
          result.form.email = null;
          result.form.roleId = null;
        }
      } else {
        result.form.firstName = null;
        result.form.lastName = null;
        result.form.email = null;
        result.form.roleId = null;
      }

      result.form.psnLoading = false;
      return result;
  }
};

export default registerReducer;
