import React, { Component, Fragment } from "react";
import {
  Accordion,
  Grid,
  Icon,
  Divider,
  Segment,
  Table,
} from "semantic-ui-react";
import { CustomHeaderLabel } from "../../../../components/labels/labels";

class TermsAndConditions extends Component {
  state = {
    activeIndex: -1,
    isConfirming: false,
    toRemoveId: null,
  };

  toggleDisplay = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  render() {
    const { activeIndex } = this.state;
    const { data } = this.props;

    return (
      <Fragment>
        <Segment>
          <Accordion>
            <Accordion.Title
              active={activeIndex === 0}
              index={0}
              onClick={this.toggleDisplay}
            >
              <Grid columns={1}>
                <Grid.Column width={16}>
                  <Icon name="dropdown" />
                  <CustomHeaderLabel message="Terms and Conditions" />
                </Grid.Column>
              </Grid>
            </Accordion.Title>
            <Accordion.Content
              active={activeIndex === 0}
              className="print-expanded"
            >
              <Divider />
              <Table attached>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell textAlign="center">
                      TERMS & CONDITIONS
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell verticalAlign="top">
                      <p>
                        <b>Terms and Conditions</b>
                      </p>
                      <p>
                        All product/services provided under this Quotation are
                        governed by and incorporate by reference the following
                        terms and conditions which are either provided with this
                        Quotation or which you have previously accepted: Global
                        Services Agreement (GSA). For a copy of your terms and
                        conditions on file, please contact the sender and
                        reference the above Quotation number.
                      </p>
                      <p>
                        In addition to the Global Services Agreement, your terms
                        and conditions also include the Service Terms identified
                        below.
                      </p>
                      <p>
                        The terms and conditions will take precedence over any
                        preprinted, additional, or different terms and
                        conditions on your requests for quotation, POs,
                        invoices, sales or marketing materials, or other
                        business documents.
                      </p>
                      <p>
                        <b>Payment</b>
                      </p>
                      <p>
                        Unless credit has been established, a 100% deposit of
                        quoted fees will be required prior to your project start
                        date.
                      </p>
                      <p>
                        <b>Expedited Services</b>
                      </p>
                      <p>
                        To expedite your project, please provide any relevant
                        requested additional information upon acceptance of the
                        Quotation. Soon after receipt of both your Quotation
                        acceptance and relevant requested information, a member
                        of our UL staff may contact you to confirm the required
                        items and finalize the scope of work. Any necessary
                        information should be provided within a time frame
                        mutually agreed by both parties at the start of the
                        project.
                      </p>
                      <p>
                        <b>Purchase Orders</b>
                      </p>
                      <p>
                        If your Accounts Payable process requires a Purchase
                        Order (PO) to be referenced on UL's invoice to
                        facilitate payment, please provide a copy of your PO
                        document when this quotation is accepted.
                      </p>
                      <p>
                        Your issuance of a PO to UL is for accounting purposes
                        only and does not constitute your counter offer. Any
                        additional or conflicting terms and conditions on your
                        PO do not apply to any Services or bind us.
                      </p>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Accordion.Content>
          </Accordion>
        </Segment>
      </Fragment>
    );
  }
}

export default TermsAndConditions;
