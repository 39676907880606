import React, { Component, Fragment } from "react";
import {
  Accordion,
  Divider,
  Form,
  Grid,
  Icon,
  Segment
} from "semantic-ui-react";
import {
  CustomHeaderLabel,
  CustomHeaderBold
} from "../../../components/labels/labels";

class AdditionalCommentsReview extends Component {
  state = {
    activeIndex: 0
  };

  toggleDisplay = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  render() {
    const { activeIndex } = this.state;
    const { data } = this.props;
    return (
      <Fragment>
        <Segment>
          <Accordion>
            <Accordion.Title
              active={activeIndex === 0}
              index={0}
              onClick={this.toggleDisplay}
            >
              <Grid columns={2}>
                <Grid.Column width={5}>
                  <Icon name="dropdown" />
                  <CustomHeaderLabel message="Review your additional details" />
                </Grid.Column>
                <Grid.Column width={5}></Grid.Column>
              </Grid>
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 0}>
              <Divider />

              <Grid columns={2}>
                <Grid.Row>
                  <Grid.Column width={11}>
                    <Form>
                      <Grid>
                        <Grid.Row>
                          <Grid.Column width={14}>
                            <CustomHeaderBold message="Additional comments" />
                            {data && data.comments}
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Form>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Accordion.Content>
          </Accordion>
        </Segment>
      </Fragment>
    );
  }
}

export default AdditionalCommentsReview;
