import { requests } from '../request-factory';
import { mapDataToQueryString } from '../request-helpers';

const userService = {
  register: registerUser => requests.post('user', registerUser),
  changePassword: changePassword =>
    requests.post('user/change-password', changePassword),
  getProfile: () => requests.get('user/profile'),
  resetPasswordRequest: email =>
    requests.post('user/reset-password-request', email),
  resetPasswordValidate: token =>
    requests.get(`user/reset-password-validate?token=${token}`),
  resetPassword: model => requests.post('user/reset-password-confirm', model),
  verifyUser: userId => requests.get(`user/verify-user?UserId=${userId}`),
  validateToken: token => requests.get(`user/verify-email?Token=${token}`),
  getTokenDetails: token => requests.get(`user/token-details?Token=${token}`),
  resendEmailVerificationToken: userId =>
    requests.post('user/resend-email-verification-token', userId),
  getRolePages: () => requests.get('user/role-pages'),
  getAllUsers: (currentPage, pageSize) =>
    requests.get(
      `user/get-all-users?${mapDataToQueryString({ currentPage, pageSize })}`
    ),
  suspendUser: params => requests.post('user/suspend-user', params),
  getAccountNotes: userId =>
    requests.get(`user/account-note/list?userId=${userId}`),
  addNote: params => requests.post('user/account-note/create', params),
  getUserClaimsByEmail: email =>
    requests.get(`user/email-claims?b2cId=${email}`),
  getUserClaimsByPsn: psn => requests.get(`user/psn-claims/${psn}`),
  updateUserPsn: model => requests.post('user/psn/update', model),
  updateUserRole: userRoleModel =>
    requests.post('user/role/update', userRoleModel),
  authorizeNDLabel: params =>
    requests.post('user/non-denominational/authorize', params),
};

export default userService;
