import React, { Component } from "react";
import {
  Form,
  Grid,
  Dropdown,
  Divider,
  Input,
  Checkbox,
} from "semantic-ui-react";

import { CustomLabel } from "../../../../components/labels/labels";

class PrelimSiteLocation extends Component {
  state = {};
  render() {
    var {
      siteDetails,
      prelimDetails,
      countryOptions,
      stateOptions,
      cityOptions,
      formType,
    } = this.props;

    return (
      <Form>
        <Divider hidden />
        <label style={{ marginLeft: "0px", fontWeight: "bold" }}>
          PRELIMINARY SITE LOCATION SECTION
        </label>
        {/* <CustomLabel message="PRELIMINARY SITE LOCATION SECTION" /> */}
        <Divider fitted />
        <br />
        <CustomLabel
          message={`NFPA 790, Clause 10.2.2 states “A preliminary evaluation for the field evaluation, if conducted, 
                shall be permitted to be conducted at the point of manufacturing, at interim points of distribution, 
                in the evaluating company’s facilities, or at the final installation site.”   
                A trip to the final installation site will be required to complete the process and apply the UL label`}
        />
        <Divider hidden />
        <Grid>
          {siteDetails && siteDetails.isSiteDetails && (
            <Grid.Row>
              <Grid.Column>
                <Form.Field>
                  {
                    "Is the Preliminary Evaluation location the same as the Applicant details found in your account settings tab?"
                  }
                </Form.Field>
                <Form.Field>
                  <Checkbox
                    radio
                    label="Yes"
                    name="prelimSameAsApplicantRadioGroup"
                    value="true"
                    checked={siteDetails.prelimSameAsApplicant === "true"}
                    onChange={(e, { value }) => {
                      this.props.handleCheckboxChange(
                        "prelimSameAsApplicant",
                        value,
                        "SD"
                      );
                      this.props.handleFillSiteDetails(value);
                    }}
                  />
                  &nbsp;&nbsp;&nbsp;
                  <Checkbox
                    radio
                    label="No"
                    name="prelimSameAsApplicantRadioGroup"
                    value="false"
                    checked={siteDetails.prelimSameAsApplicant === "false"}
                    onChange={(e, { value }) => {
                      this.props.handleCheckboxChange(
                        "prelimSameAsApplicant",
                        value,
                        "SD"
                      );
                      this.props.handleFillSiteDetails(value);
                    }}
                  />
                </Form.Field>
              </Grid.Column>
            </Grid.Row>
          )}

          <Grid.Row>
            <Grid.Column>
              <Form.Field
                id="form-input-control-lcn"
                control={Input}
                label="* Business/Residence Name"
                placeholder="Enter business/residence name"
                name="residenceName"
                onChange={(e) => {
                  this.props.handleChange(e, formType);
                }}
                value={prelimDetails.residenceName || ""}
                error={this.props.getErrorFor("residenceName", formType)}
                autoComplete="off"
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row columns={3}>
            <Grid.Column>
              <Form.Field
                id="form-input-control-lcn"
                control={Input}
                label="* Street Address Line 1"
                placeholder="Enter address line 1"
                name="addressLine1"
                onChange={(e) => {
                  this.props.handleChange(e, formType);
                }}
                value={prelimDetails.addressLine1 || ""}
                error={this.props.getErrorFor("addressLine1", formType)}
                autoComplete="off"
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Field
                id="form-input-control-lcn"
                control={Input}
                label="Street Address Line 2"
                placeholder="Enter address line 2"
                name="addressLine2"
                onChange={(e) => {
                  this.props.handleChange(e, formType);
                }}
                value={prelimDetails.addressLine2 || ""}
                error={this.props.getErrorFor("addressLine2", formType)}
                autoComplete="off"
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Field
                id="form-input-control-lcn"
                control={Input}
                label="Street Address Line 3"
                placeholder="Enter address line 3"
                name="addressLine3"
                onChange={(e) => {
                  this.props.handleChange(e, formType);
                }}
                value={prelimDetails.addressLine3 || ""}
                error={this.props.getErrorFor("addressLine3", formType)}
                autoComplete="off"
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row columns={4}>
            <Grid.Column>
              <Form.Field>
                <label>* Country</label>
                <Dropdown
                  compact
                  selection
                  placeholder="Select Country"
                  options={countryOptions}
                  onChange={(e, properties) => {
                    this.props.handleDropdownChange(
                      "country",
                      properties.value,
                      formType
                    );
                    this.props.handleGetProjectStates(properties.value, 1);
                  }}
                  value={prelimDetails.country || ""}
                  error={this.props.getErrorFor("country", formType)}
                />
              </Form.Field>
            </Grid.Column>
            <Grid.Column>
              <Form.Field>
                <label>* State</label>
                <Dropdown
                  compact
                  selection
                  placeholder="Select State"
                  options={stateOptions}
                  onChange={(e, properties) => {
                    this.props.handleDropdownChange(
                      "state",
                      properties.value,
                      formType
                    );
                    this.props.handleGetProjectCities(properties.value, 1);
                  }}
                  value={prelimDetails.state || ""}
                  error={this.props.getErrorFor("state", formType)}
                />
              </Form.Field>
            </Grid.Column>
            <Grid.Column>
              <Form.Field>
                <label>* City</label>
                <Dropdown
                  compact
                  selection
                  placeholder="Select City"
                  options={cityOptions}
                  onChange={(e, properties) => {
                    this.props.handleDropdownChange(
                      "city",
                      properties.value,
                      formType
                    );
                  }}
                  value={prelimDetails.city || ""}
                  error={this.props.getErrorFor("city", formType)}
                />
              </Form.Field>
            </Grid.Column>

            <Grid.Column>
              <Form.Field
                id="form-input-control-lcn"
                control={Input}
                label="* Postal Code"
                placeholder="Enter postal code"
                name="postalCode"
                onChange={(e) => {
                  this.props.handleChange(e, formType);
                }}
                value={prelimDetails.postalCode || ""}
                error={this.props.getErrorFor("postalCode", formType)}
                autoComplete="off"
              />
            </Grid.Column>
          </Grid.Row>

          <CustomLabel message="SITE CONTACT DETAILS" />

          <Grid.Row columns={3}>
            <Grid.Column>
              <Form.Field
                id="form-input-control-lcn"
                control={Input}
                label="* Contact Name"
                placeholder="Enter contact name"
                name="siteContactName"
                onChange={(e) => {
                  this.props.handleChange(e, formType);
                }}
                value={prelimDetails.siteContactName || ""}
                error={this.props.getErrorFor("siteContactName", formType)}
                autoComplete="off"
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Field
                id="form-input-control-lcn"
                control={Input}
                label="* Phone No."
                placeholder="Enter phone no"
                name="siteContactOfficeNumber"
                onChange={(e) => {
                  this.props.handleChange(e, formType);
                }}
                value={prelimDetails.siteContactOfficeNumber || ""}
                error={this.props.getErrorFor(
                  "siteContactOfficeNumber",
                  formType
                )}
                autoComplete="off"
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Field
                id="form-input-control-lcn"
                control={Input}
                label="Mobile No."
                placeholder="Enter mobile no"
                name="siteContactMobileNumber"
                onChange={(e) => {
                  this.props.handleChange(e, formType);
                }}
                value={prelimDetails.siteContactMobileNumber || ""}
                error={this.props.getErrorFor(
                  "siteContactMobileNumber",
                  formType
                )}
                autoComplete="off"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Form.Field
                id="form-input-control-lcn"
                control={Input}
                label="Email"
                placeholder="Enter email"
                name="siteContactEmailAddress"
                onChange={(e) => {
                  this.props.handleChange(e, formType);
                }}
                value={prelimDetails.siteContactEmailAddress || ""}
                error={this.props.getErrorFor(
                  "siteContactEmailAddress",
                  formType
                )}
                autoComplete="off"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    );
  }
}

export default PrelimSiteLocation;
