export const FILE_NUMBERS_SORT = "FILE_NUMBERS_SORT";
export const FILE_NUMBERS_PAGINATE = "FILE_NUMBERS_PAGINATE";
export const FILE_NUMBERS_REQUEST = "FILE_NUMBERS_REQUEST";
export const FILE_NUMBERS_FAILURE = "FILE_NUMBERS_FAILURE";
export const FILE_NUMBERS_SUCCESS = "FILE_NUMBERS_SUCCESS";
export const FILE_NUMBERS_SELECT = "FILE_NUMBERS_SELECT";
export const FILE_NUMBERS_SELECT_ALL = "FILE_NUMBERS_SELECT_ALL";

export const SELECT_FILE_VOLUME_MANU = "SELECT_FILE_VOLUME_MANU";
export const SELECT_ALL_FILE_VOLUMES_MANU = "SELECT_ALL_FILE_VOLUMES_MANU";
export const CLEAN_ALL_FILTERS = "CLEAN_ALL_FILTERS";
export const CLEAR_SELECTION = "CLEAR_SELECTION";
