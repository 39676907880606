import * as types from "./fe-admin-search.type";

let initialState = {
  data: [],
  currentPage: 1,
  pageSize: 10,
  totalPages: 1,
  isLoading: false,
  form: {
    showErrors: false,
    validationErrors: {},
  },
};

const adminSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
    case types.FE_ADMIN_SEARCH_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.FE_ADMIN_SEARCH_SUCCESS:
    case types.FE_ADMIN_SEARCH_FAILURE:
      var result = Object.assign({}, state, {
        isLoading: false,
        currentPage: action.data.currentPage,
        pageSize: action.data.pageSize,
        totalPages: action.data.totalPages,
        data: action.data.data || initialState.data,
      });

      return result;
  }
};

export default adminSearchReducer;
