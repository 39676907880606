export const FORM = "FORM";

export const ORDER_DETAILS_REQUEST = "ORDER_DETAILS_REQUEST";
export const ORDER_DETAILS_FAILURE = "ORDER_DETAILS_FAILURE";
export const ORDER_DETAILS_SUCCESS = "ORDER_DETAILS_SUCCESS";
export const ORDER_DETAILS_RESET = "ORDER_DETAILS_RESET";

export const CLEAR = "CLEAR";

export const PPE_REQUEST = "PPE_REQUEST";
export const PPE_FAILURE = "PPE_FAILURE";
export const PPE_SUCCESS = "PPE_SUCCESS";

export const TRAINING_REQUEST = "TRAINING_REQUEST";
export const TRAINING_FAILURE = "TRAINING_FAILURE";
export const TRAINING_SUCCESS = "TRAINING_SUCCESS";

export const UPDATE_ISSUCCESS = "UPDATE_ISSUCCESS";
