import React, { Component, Fragment } from "react";
import { Grid, Button } from "semantic-ui-react";
import { CustomHeaderLabel } from "../../components/labels/labels";

class OrderPricing extends Component {
  state = {};
  render() {
    return (
      <Fragment>
        <Grid>
          <Grid.Column width={8}>
            <Button basic primary>
              Save
            </Button>
            <Button basic primary>
              Delete label
            </Button>
          </Grid.Column>
          <Grid.Column width={8} style={{ textAlign: "right" }}>
            <CustomHeaderLabel message="Order net total: USD 109, 000.00 " />
            <Button
              primary
              disabled={!this.props.isValid}
              onClick={this.props.onAddToCart}
            >
              Add to cart
            </Button>
          </Grid.Column>
        </Grid>
      </Fragment>
    );
  }
}

export default OrderPricing;
