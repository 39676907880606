import React, { Component, Fragment } from "react";
import {
  Form,
  Divider,
  Grid,
  Checkbox,
  Input,
  Dropdown
} from "semantic-ui-react";
import { CustomLabel } from "../../../../../components/labels/labels";

class FinalSiteDetails extends Component {
  state = {};
  render() {
    var {
      countryOptions,
      stateOptions,
      cityOptions,
      formType,
      siteDetails,
      finalDetails
    } = this.props;

    return (
      <Fragment>
        <Divider hidden />
        <label style={{ marginLeft: "0px", fontWeight: "bold" }}>
          FINAL INSTALLATION SITE LOCATION
        </label>
        <Divider fitted />
        <br />
        <CustomLabel
          message={`NFPA 790, Clause 10.2.1  states “The field evaluation process shall be completed 
          at the final installation site" This is where the product is permanently installed and the UL label is  applied`}
        />
        {/* <CustomLabel message="FINAL SITE LOCATION SECTION" />  */}

        <Divider hidden />
        <Grid>
          {siteDetails?.others?.isProjectTab &&
            (siteDetails?.others?.showPrelimLocation === "true" ? (
              <Grid.Row>
                <Grid.Column>
                  <Form.Field>
                    {`Is the final installation site location the same as the 
                  Applicant details found in your account settings tab?`}
                  </Form.Field>
                  <Form.Field>
                    <Checkbox
                      radio
                      label="Yes"
                      name="finalSameAsApplicantRadioGroup"
                      value="true"
                      checked={
                        siteDetails?.others?.finalSameAsApplicant === "true"
                      }
                      onChange={(e, { value }) => {
                        this.props.handleCheckboxChange(
                          "finalSameAsApplicant",
                          value,
                          "others"
                        );
                        this.props.handleFillFinalSiteDetails(value, "final");
                      }}
                    />
                    &nbsp;&nbsp;&nbsp;
                    <Checkbox
                      radio
                      label="No"
                      name="finalSameAsApplicantRadioGroup"
                      value="false"
                      checked={
                        siteDetails?.others?.finalSameAsApplicant === "false"
                      }
                      onChange={(e, { value }) => {
                        this.props.handleCheckboxChange(
                          "finalSameAsApplicant",
                          value,
                          "others"
                        );
                        this.props.handleFillFinalSiteDetails(value, "final");
                      }}
                    />
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
            ) : (
              <Grid.Row>
                <Grid.Column>
                  <Checkbox
                    label="Is the Final Installation site details the same as the Preliminary Site details above"
                    checked={siteDetails?.others?.isFinalSameAsApplicant}
                    onChange={(e, { checked }) => {
                      this.props.handleCheckboxChange(
                        "isFinalSameAsApplicant",
                        checked,
                        "others"
                      );
                    }}
                  />
                </Grid.Column>
              </Grid.Row>
            ))}

          <Grid.Row>
            <Grid.Column>
              <Form.Field
                id="form-input-control-lcn"
                control={Input}
                label="* Business/Residence Name"
                placeholder="Enter business/residence name"
                name={"residenceName"}
                onChange={(e) => {
                  this.props.handleChange(e, formType);
                }}
                value={finalDetails?.residenceName || ""}
                error={this.props.getErrorFor("residenceName", formType)}
                autoComplete="new-password"
                readOnly={
                  finalDetails?.residenceNameEnable == "true" ? true : false
                }
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={3}>
            <Grid.Column>
              <Form.Field
                id="form-input-control-lcn"
                control={Input}
                label="* Street Address Line 1"
                placeholder="Enter address line 1"
                name="addressLine1"
                onChange={(e) => {
                  this.props.handleChange(e, formType);
                }}
                value={finalDetails?.addressLine1 || ""}
                error={this.props.getErrorFor("addressLine1", formType)}
                autoComplete="new-password"
                readOnly={
                  finalDetails?.addressLine1Enable == "true" ? true : false
                }
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Field
                id="form-input-control-lcn"
                control={Input}
                label="Street Address Line 2"
                placeholder="Enter address line 2"
                name="addressLine2"
                onChange={(e) => {
                  this.props.handleChange(e, formType);
                }}
                value={finalDetails?.addressLine2 || ""}
                error={this.props.getErrorFor("addressLine2", formType)}
                autoComplete="new-password"
                readOnly={
                  finalDetails?.addressLine2Enable == "true" ? true : false
                }
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Field
                id="form-input-control-lcn"
                control={Input}
                label="Street Address Line 3"
                placeholder="Enter address line 3"
                name="addressLine3"
                onChange={(e) => {
                  this.props.handleChange(e, formType);
                }}
                value={finalDetails?.addressLine3 || ""}
                error={this.props.getErrorFor("addressLine3", formType)}
                autoComplete="new-password"
                readOnly={
                  finalDetails?.addressLine3Enable == "true" ? true : false
                }
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={4}>
            <Grid.Column>
              <Form.Field
                control={Dropdown}
                compact
                selection
                label="* Country"
                placeholder="Select Country"
                options={countryOptions || []}
                onChange={(e, properties) => {
                  this.props.handleDropdownChange(
                    "country",
                    properties.value,
                    formType
                  );
                  this.props.handleGetProjectStates(properties.value, 2);
                }}
                value={finalDetails?.country || ""}
                error={this.props.getErrorFor("country", formType)}
                disabled={finalDetails?.countryEnable == "true" ? true : false}
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Field
                control={Dropdown}
                compact
                selection
                label="* State"
                placeholder="Select State"
                options={stateOptions || []}
                onChange={(e, properties) => {
                  this.props.handleDropdownChange(
                    "state",
                    properties.value,
                    formType
                  );
                  this.props.handleGetProjectCities(properties.value, 2);
                }}
                value={finalDetails?.state || ""}
                error={this.props.getErrorFor("state", formType)}
                disabled={finalDetails?.stateEnable == "true" ? true : false}
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Field
                control={Dropdown}
                compact
                selection
                label="* City"
                placeholder="Select City"
                options={cityOptions || []}
                onChange={(e, properties) => {
                  this.props.handleDropdownChange(
                    "city",
                    properties.value,
                    formType
                  );
                }}
                value={finalDetails?.city || ""}
                error={this.props.getErrorFor("city", formType)}
                disabled={finalDetails?.cityEnable == "true" ? true : false}
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Field
                id="form-input-control-lcn"
                control={Input}
                label="* Postal Code"
                placeholder="Enter postal code"
                name="postalCode"
                onChange={(e) => {
                  this.props.handleChange(e, formType);
                }}
                value={finalDetails?.postalCode || ""}
                error={this.props.getErrorFor("postalCode", formType)}
                autoComplete="new-password"
                readOnly={
                  finalDetails?.postalCodeEnable == "true" ? true : false
                }
              />
            </Grid.Column>
          </Grid.Row>
          <CustomLabel message="SITE CONTACT DETAILS" />

          <Grid.Row columns={3}>
            <Grid.Column>
              <Form.Field
                id="form-input-control-lcn"
                control={Input}
                label="* Contact Name"
                placeholder="Enter contact name"
                name="siteContactName"
                onChange={(e) => {
                  this.props.handleChange(e, formType);
                }}
                value={finalDetails?.siteContactName || ""}
                error={this.props.getErrorFor("siteContactName", formType)}
                autoComplete="new-password"
                readOnly={
                  finalDetails?.siteContactNameEnable == "true" ? true : false
                }
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Field
                id="form-input-control-lcn"
                control={Input}
                label="* Phone No."
                placeholder="Enter phone no"
                name="siteContactOfficeNumber"
                onChange={(e) => {
                  this.props.handleChange(e, formType);
                }}
                value={finalDetails?.siteContactOfficeNumber || ""}
                error={this.props.getErrorFor(
                  "siteContactOfficeNumber",
                  formType
                )}
                autoComplete="new-password"
                readOnly={
                  finalDetails?.siteContactOfficeNumberEnable == "true"
                    ? true
                    : false
                }
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Field
                id="form-input-control-lcn"
                control={Input}
                label="Mobile No."
                placeholder="Enter mobile no"
                name="siteContactMobileNumber"
                onChange={(e) => {
                  this.props.handleChange(e, formType);
                }}
                value={finalDetails?.siteContactMobileNumber || ""}
                error={this.props.getErrorFor(
                  "siteContactMobileNumber",
                  formType
                )}
                autoComplete="new-password"
                readOnly={
                  finalDetails?.siteContactMobileNumberEnable == "true"
                    ? true
                    : false
                }
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Form.Field
                id="form-input-control-lcn"
                control={Input}
                label="Email"
                placeholder="Enter email"
                name={"siteContactEmailAddress"}
                onChange={(e) => {
                  this.props.handleChange(e, formType);
                }}
                value={finalDetails?.siteContactEmailAddress || ""}
                error={this.props.getErrorFor(
                  "siteContactEmailAddress",
                  formType
                )}
                autoComplete="new-password"
                readOnly={
                  finalDetails?.siteContactEmailAddressEnable == "true"
                    ? true
                    : false
                }
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Fragment>
    );
  }
}

export default FinalSiteDetails;
