import * as types from "../../constants/labelOrderDetails";
import api from "../../services/api";

const emailCopyRequest = () => {
  return { type: types.EMAIL_COPY_REQUEST };
};

const emailCopyFailure = error => {
  return dispatch =>
    dispatch({
      type: types.EMAIL_COPY_FAILURE,
      payload: error
    });
};

const emailCopySuccess = response => {
  return dispatch =>
    dispatch({
      type: types.EMAIL_COPY_SUCCESS,
      payload: response
    });
};

export const emailCopy = data => {
  return async dispatch => {
    dispatch(emailCopyRequest());
    const result = await api.Order.emailCopy(data);
    if (result) {
      dispatch(emailCopySuccess(result));
    } else {
      dispatch(emailCopyFailure("Something went wrong."));
    }
  };
};
