export const CLEAR = "CLEAR";
export const FORM = "FORM";

export const ADMIN_SEARCH_REQUEST = "ADMIN_SEARCH_REQUEST";
export const ADMIN_SEARCH_SUCCESS = "ADMIN_SEARCH_SUCCESS";
export const ADMIN_SEARCH_FAILURE = "ADMIN_SEARCH_FAILURE";

export const ADMIN_SEARCH_CHANGE_PAGE_REQUEST =
  "ADMIN_SEARCH_CHANGE_PAGE_REQUEST";
export const ADMIN_SEARCH_CHANGE_PAGE_SUCCESS =
  "ADMIN_SEARCH_CHANGE_PAGE_SUCCESS";
export const ADMIN_SEARCH_CHANGE_PAGE_FAILURE =
  "ADMIN_SEARCH_CHANGE_PAGE_FAILURE";
