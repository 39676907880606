import React, { Component, Fragment } from "react";
import { Accordion, Grid, Icon, Divider, Segment } from "semantic-ui-react";
import { CustomHeaderLabel } from "../../components/labels/labels";

class LabelOrderComments extends Component {
  state = {
    activeIndex: -1,
    isConfirming: false,
    toRemoveId: null
  };

  toggleDisplay = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  render() {
    const { activeIndex } = this.state;
    const { data } = this.props;

    return (
      <Fragment>
        <Segment>
          <Accordion>
            <Accordion.Title
              active={activeIndex === 0}
              index={0}
              onClick={this.toggleDisplay}
            >
              <Grid columns={1}>
                <Grid.Column width={16}>
                  <Icon name="dropdown" />
                  <CustomHeaderLabel message="Comment/ Instruction" />
                </Grid.Column>
              </Grid>
            </Accordion.Title>
            <Accordion.Content active={true} className="print-expanded">
              <Divider />
              <Grid>
                <Grid.Row>
                  <Grid.Column width="15">
                    <table style={{ width: "90%" }}>
                      <tbody>
                        <tr>                        
                          <td>{data.comments}</td>                        
                        </tr>
                      </tbody>
                    </table>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Accordion.Content>
          </Accordion>
        </Segment>
      </Fragment>
    );
  }
}

export default LabelOrderComments;
