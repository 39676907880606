import React, { Component, Fragment } from "react";
import {
  Accordion,
  Grid,
  Icon,
  Divider,
  Segment,
  Image
} from "semantic-ui-react";
import { Common } from "../../common";
import { CustomHeaderLabel } from "../../components/labels/labels";
import { isEqual } from "lodash";

import {
  ChangeOrderLineStatus,
  GetStatusCode
} from "../../helpers/component-helper";

function compareProps(prevProps, nextProps) {
  return isEqual(prevProps, nextProps);
}

class CombinationLabelOrderItem extends Component {
  state = {
    activeIndex: 0,
    isConfirming: false,
    toRemoveId: null
  };

  toggleDisplay = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  render() {
    const { activeIndex } = this.state;
    const { data, fileNumber, orderDetails } = this.props;
    const uniqueVolume = [
      ...new Set(
        data.map((i) => {
          return i.volume;
        })
      )
    ].join("/");
    const translations = Common.getTranslations() || {};

    return (
      <Fragment>
        <Grid style={{ padding: "0px 25px 0px 25px" }}>
          <Grid.Row style={{ paddingBottom: "0px" }}>
            <Grid.Column>
              <Segment>
                <Accordion>
                  <Accordion.Title
                    active={activeIndex === 0}
                    index={0}
                    onClick={this.toggleDisplay}
                  >
                    <Grid columns={1}>
                      <Grid.Column width={16}>
                        <Icon name="dropdown" />
                        <b>{fileNumber}</b>
                        {` (Vol. ${uniqueVolume})`}
                      </Grid.Column>
                    </Grid>
                  </Accordion.Title>
                  <Accordion.Content
                    active={activeIndex === 0}
                    className="print-expanded"
                  >
                    <Divider />
                    <Grid>
                      <Grid.Row>
                        <Grid.Column width="15">
                          <span hidden={orderDetails.labelOrderType == 2}>
                            {data.map((item, idx) => {
                              return (
                                <Segment
                                  style={{
                                    backgroundColor: "#FAFAFA",
                                    border: "none",
                                    boxShadow: "none",
                                    paddingBottom: "20px"
                                  }}
                                >
                                  <table style={{ width: "100%" }}>
                                    <tr>
                                      <td style={{ width: "20%" }}>
                                        <Image
                                          size="medium"
                                          src={item.imageUrl}
                                        />
                                      </td>
                                      <td
                                        style={{
                                          paddingLeft: "10px",
                                          verticalAlign: "top"
                                        }}
                                      >
                                        <table style={{ width: "80%" }}>
                                          <tr>
                                            <td
                                              style={{
                                                borderRight: "2px solid #ebebeb"
                                              }}
                                            >
                                              <table style={{ width: "100%" }}>
                                                <tr>
                                                  <td style={{ width: "35%" }}>
                                                    <b>
                                                      {
                                                        translations[
                                                          "LexULItemNumber"
                                                        ]
                                                      }
                                                    </b>
                                                  </td>
                                                  <td>{item.itemNumber}</td>
                                                </tr>
                                                <tr>
                                                  <td>
                                                    <b>
                                                      {
                                                        translations[
                                                          "LexQuantity"
                                                        ]
                                                      }
                                                    </b>
                                                  </td>
                                                  <td>
                                                    {item.quantity.toLocaleString()}
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>
                                                    <b>
                                                      {
                                                        translations[
                                                          "LexUnitofMeasure"
                                                        ]
                                                      }
                                                    </b>
                                                  </td>
                                                  <td>
                                                    {Common.translateUnitOfMeasure(
                                                      item.oracleUnitOfMeasurementName,
                                                      translations
                                                    )}
                                                  </td>
                                                </tr>
                                              </table>
                                            </td>
                                            <td
                                              style={{
                                                paddingLeft: "40px",
                                                verticalAlign: "top"
                                              }}
                                            >
                                              <table style={{ width: "100%" }}>
                                                <tr>
                                                  <td
                                                    style={{
                                                      width: "50%"
                                                    }}
                                                  >
                                                    <b>
                                                      {
                                                        translations[
                                                          "LexLabelPrintingFee"
                                                        ]
                                                      }{" "}
                                                      ({item.currency})
                                                    </b>
                                                  </td>
                                                  <td>{item.newPrice}</td>
                                                </tr>
                                                <tr>
                                                  <td>
                                                    <b>
                                                      {
                                                        translations[
                                                          "LexLabelHandlingFee"
                                                        ]
                                                      }{" "}
                                                      ({item.currency})
                                                    </b>
                                                  </td>
                                                  <td>
                                                    {Common.getNumberWithCommas(
                                                      item.billToCountryName ==
                                                        "KOREA" ||
                                                        item.billToCountryName ==
                                                          "JAPAN"
                                                        ? item.handlingFee.toFixed(
                                                            0
                                                          )
                                                        : item.handlingFee.toFixed(
                                                            2
                                                          )
                                                    )}
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>
                                                    <b>
                                                      {
                                                        translations[
                                                          "LexItemStatus"
                                                        ]
                                                      }
                                                    </b>
                                                  </td>
                                                  <td>
                                                    {Common.translateStatus(
                                                      ChangeOrderLineStatus(
                                                        item.orderStatus
                                                      ),
                                                      translations
                                                    )}
                                                  </td>
                                                </tr>
                                              </table>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              style={{ paddingBottom: "20px" }}
                                            ></td>
                                          </tr>
                                          <tr>
                                            <td colSpan={2}>
                                              <b>
                                                {
                                                  translations[
                                                    "LexItemDescription"
                                                  ]
                                                }
                                                :
                                              </b>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              colSpan={2}
                                              style={{
                                                wordWrap: "break-word",
                                                width: "580px"
                                              }}
                                            >
                                              {item.labelDescription}
                                            </td>
                                          </tr>
                                        </table>
                                      </td>
                                    </tr>
                                  </table>
                                </Segment>
                              );
                            })}
                          </span>
                          <span hidden={orderDetails.labelOrderType == 1}>
                            {data.map((item, idx) => {
                              return (
                                <Segment
                                  style={{
                                    backgroundColor: "#FAFAFA",
                                    border: "none",
                                    boxShadow: "none",
                                    paddingBottom: "20px"
                                  }}
                                >
                                  <table style={{ width: "100%" }}>
                                    <tr>
                                      <td
                                        style={{
                                          paddingLeft: "10px",
                                          verticalAlign: "top"
                                        }}
                                      >
                                        <table style={{ width: "100%" }}>
                                          <tr>
                                            <td
                                              style={{
                                                borderRight:
                                                  "2px solid #ebebeb",
                                                width: "40%"
                                              }}
                                            >
                                              <table style={{ width: "100%" }}>
                                                <tr>
                                                  <td style={{ width: "35%" }}>
                                                    {orderDetails.artworkLinks &&
                                                    orderDetails.artworkLinks.find(
                                                      (o) =>
                                                        o.itemNumber ==
                                                        item.itemNumber
                                                    )?.filePath ? (
                                                      <a
                                                        href={
                                                          orderDetails.artworkLinks.find(
                                                            (o) =>
                                                              o.itemNumber ==
                                                              item.itemNumber
                                                          )?.filePath
                                                        }
                                                        target="_blank"
                                                      >
                                                        {
                                                          translations[
                                                            "LexArtworkLink"
                                                          ]
                                                        }
                                                      </a>
                                                    ) : null}
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td
                                                    style={{
                                                      paddingBottom: "20px"
                                                    }}
                                                  ></td>
                                                </tr>
                                                <tr>
                                                  <td colSpan={2}>
                                                    <b>
                                                      {
                                                        translations[
                                                          "LexItemDescription"
                                                        ]
                                                      }
                                                      :
                                                    </b>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td
                                                    colSpan={2}
                                                    style={{
                                                      wordWrap: "break-word",
                                                      width: "580px"
                                                    }}
                                                  >
                                                    {item.labelDescription}
                                                  </td>
                                                </tr>
                                              </table>
                                            </td>
                                            <td
                                              style={{
                                                paddingLeft: "40px",
                                                verticalAlign: "top"
                                              }}
                                            >
                                              <table style={{ width: "100%" }}>
                                                <tr>
                                                  <td style={{ width: "30%" }}>
                                                    <b>
                                                      {
                                                        translations[
                                                          "LexULItemNumber"
                                                        ]
                                                      }
                                                    </b>
                                                  </td>
                                                  <td>{item.itemNumber}</td>
                                                </tr>
                                                <tr>
                                                  <td>
                                                    <b>
                                                      {
                                                        translations[
                                                          "LexQuantity"
                                                        ]
                                                      }
                                                    </b>
                                                  </td>
                                                  <td>
                                                    {item.quantity.toLocaleString()}{" "}
                                                    {translations["LexLabels"]}
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>
                                                    <b>
                                                      {
                                                        translations[
                                                          "LexMLALListeeFileNumber"
                                                        ]
                                                      }
                                                    </b>
                                                  </td>
                                                  <td>
                                                    {item.listeeFileNumber}
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>
                                                    <b>
                                                      {
                                                        translations[
                                                          "LexCustPartNo"
                                                        ]
                                                      }
                                                    </b>
                                                  </td>
                                                  <td>
                                                    {item.customerPartNumber}
                                                  </td>
                                                </tr>
                                                {ChangeOrderLineStatus(
                                                  item.orderStatus
                                                ).toLocaleLowerCase() ==
                                                "cancelled" ? (
                                                  <tr>
                                                    <td>
                                                      <b>
                                                        {
                                                          translations[
                                                            "LexItemStatus"
                                                          ]
                                                        }
                                                      </b>
                                                    </td>
                                                    <td>
                                                      <td>
                                                        {Common.translateStatus(
                                                          ChangeOrderLineStatus(
                                                            item.orderStatus
                                                          ),
                                                          translations
                                                        )}
                                                      </td>
                                                    </td>
                                                  </tr>
                                                ) : null}
                                              </table>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              style={{ paddingBottom: "20px" }}
                                            ></td>
                                          </tr>
                                        </table>
                                      </td>
                                    </tr>
                                  </table>
                                </Segment>
                              );
                            })}
                          </span>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Accordion.Content>
                </Accordion>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Fragment>
    );
  }
}

export default React.memo(CombinationLabelOrderItem, compareProps);
