const Role = {
  applicant: 1,
  manufacturer: 2,
  projectHandler: 3,
  fieldEngineer: 4,
  labelCenter: 5,
  fireEquipment: 6,
  noRole: 7,
  iT: 8,
  pricingManager: 9,
  fieldEngineerRO: 10,
  labelManager: 11,
  printer: 12
};

export default Role;
