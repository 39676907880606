import React, { Component, Fragment } from "react";
import {
  Container,
  Segment,
  Button,
  Table,
  Divider,
  Label,
  Pagination,
  Message,
  Grid,
  Popup,
  Menu,
  Icon,
  Form
} from "semantic-ui-react";
import FooterHomeBlock from "../../components/footer/footer-home-block";
import { Link, Redirect } from "react-router-dom";
import {
  getNotifications,
  updateData
} from "../../views/dashboard/dashboard.action";
import { updateNotificationView } from "../../views/dashboard/dashboard.action";
import { connect } from "react-redux";
import { getCartItems } from "../../views/dashboard/dashboard.action";
import { getLabelOrderCart } from "../../views/label-order-cart/cart/label-order-cart.action";
import DashBoardItem from "./dashboard-item";
import * as userActions from "../../views/user/common/common.action";
import "../../assets/css/lexington-revamp.css";
import * as commonActions from "../../views/user/common/common.action";

import {
  CustomTitle,
  CustomHeader,
  CustomHeaderLabel,
  CustomLabel,
  CustomHeaderBold,
  CustomTitleLabel,
  SubLabel,
  CustomHeaderLabelRelaxed,
  CustomContainerHeader
} from "../../components/labels/labels";

import { renderDisc } from "../../helpers/component-helper";

import { Common } from "../../common/common";
import Role from "../../enums/role";

const CustomButtonStyle = {
  border: "1px solid #2185d0",
  color: "#2185d0",
  background: "white"
};

class Dashboard extends Component {
  state = {
    filterData: null
  };

  componentDidMount() {
    this.props.getNotifications(this.props.currentPage, this.props.pageSize);
    this.props.getCartItems();
    this.props.getLabelOrderCart();
    this.props.getActivePage("Label Services");
  }

  componentWillUnmount() {
    const userId = JSON.parse(Common.getUserId());
    this.props.updateNotificationView(userId);
  }

  handlePageChange = (filterData) => {
    this.props.getNotifications(filterData.currentPage, filterData.pageSize);
  };

  handleExpandMultipleOrders = (orderId) => {
    const { data } = this.props;

    var newData =
      data &&
      data.data &&
      data.data.map((item) => {
        if (item.labelOrderId == orderId)
          item.isExpandMultiple = !item.isExpandMultiple;

        return item;
      });

    this.props.updateData(newData);
  };

  render() {
    const {
      data,
      standardLabelsCart,
      isSuccessUpdateNotification,
      combinationLabelsCart,
      dashboard
    } = this.props;
    const translations = Common.getTranslations() || {};

    const columnOptions = [
      // {
      //   key: "multiple",
      //   value: 0,
      //   text: "",
      //   visible: 1,
      //   sort: 0,
      //   checked: 1,
      //   disabled: 0
      // },
      {
        key: "status",
        value: 1,
        text: translations["LexOrderStatus"],
        visible: 1,
        sort: 0,
        checked: 1,
        disabled: 0
      },
      {
        key: "cartOrderNo",
        value: 2,
        text: translations["LexCartOrderNo."],
        visible: 1,
        sort: 0,
        checked: 1,
        disabled: 0
      },
      {
        key: "orderNo",
        value: 3,
        text: translations["LexULOrderNumber"],
        visible: 1,
        sort: 0,
        checked: 1,
        disabled: 0
      },
      {
        key: "date",
        value: 4,
        text: translations["LexOrderDate"],
        visible: 1,
        sort: 0,
        checked: 1,
        disabled: 0
      },

      {
        key: "fileNumbers",
        value: 5,
        text: translations["LexFileNo."],
        visible: 1,
        sort: 0,
        checked: 1,
        disabled: 0
      },

      {
        key: "labelType",
        value: 6,
        text: translations["LexLabelType"],
        visible: 1,
        sort: 0,
        checked: 1,
        disabled: 0
      },
      {
        key: "view",
        value: 7,
        text: translations["LexView"],
        visible: 1,
        sort: 0,
        checked: 1,
        disabled: 0
      }
    ];
    const { filterData } = this.state;

    let newNotificationData = null;
    let oldNotificationData = null;
    let standardCartItems = [];
    let combinationCartItems = [];

    if (data) {
      newNotificationData = data.newNotifications;
      oldNotificationData = data.oldNotifications;
    }
    if (
      standardLabelsCart &&
      standardLabelsCart.data &&
      standardLabelsCart.data.length > 0
    ) {
      standardCartItems =
        standardLabelsCart.data[0].carts.length > 0
          ? standardLabelsCart.data[0].carts[0].cartItems
          : [];
    }
    if (combinationLabelsCart && combinationLabelsCart.data) {
      combinationCartItems = combinationLabelsCart.data.cartItems;
    }
    if (standardCartItems.length > 0)
      localStorage.setItem("cartLabelOrderType", 1);
    else if (combinationCartItems.length > 0)
      localStorage.setItem("cartLabelOrderType", 2);

    let userRole = Common.getUserRole();

    // if (userRole == 4) return <Redirect to="/home/account-settings" />;
    if (
      userRole == Role.projectHandler ||
      userRole == Role.fireEquipment ||
      userRole == Role.fieldEngineer
    )
      return <Redirect to="/home/notifications" />;
    if (userRole == Role.labelCenter)
      return <Redirect to="/home/role-assignment" />;
    if (
      userRole == Role.fieldEngineerRO ||
      userRole == Role.applicant ||
      userRole == Role.manufacturer
    )
      return <Redirect to="/home/view-orders" />;

    let fullName = Common.getFullName();

    return (
      <Fragment>
        <div className="div-container">
          <Container fluid>
            <Grid>
              <Grid.Row className="label-header">
                <Grid.Column>
                  <CustomContainerHeader message={"Hello " + fullName + "!"} />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row className="label-desc">
                <Grid.Column>
                  <CustomHeaderLabelRelaxed
                    message={translations["LexWelcome"]}
                  />
                </Grid.Column>
                {/* <Grid.Column width={10} textAlign="right">
                    <Button
                      style={CustomButtonStyle}
                      icon="filter"
                      compact
                      onClick={this.handleToggleFilter}
                    ></Button>
                    <Button
                      style={CustomButtonStyle}
                      content="Column Options"
                      compact
                      onClick={this.handleToggleColumn}
                    ></Button>                  
                  </Grid.Column>                   */}
              </Grid.Row>
            </Grid>
            <br />
            <div className="div-table list-table">
              <Segment fluid>
                <Form loading={dashboard.loading}>
                  <Table
                    striped
                    structured
                    celled
                    sortable
                    className="table-revamp"
                  >
                    <Table.Header>
                      <Table.Row textAlign="left">
                        {columnOptions &&
                          columnOptions.map((_column, idx) => (
                            <Table.HeaderCell key={idx}>
                              <div className="fixed-table">
                                <div className="ellipsis-preview">
                                  {_column.text}
                                </div>
                              </div>
                            </Table.HeaderCell>
                          ))}
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {data &&
                        data.data.map((datarow, idx) => (
                          <DashBoardItem
                            key={idx}
                            data={datarow}
                            columnOptions={columnOptions}
                            handleExpandMultipleOrders={
                              this.handleExpandMultipleOrders
                            }
                            roleId={userRole}
                          ></DashBoardItem>
                        ))}
                    </Table.Body>
                    <Table.Footer>
                      <Table.Row>
                        <Table.HeaderCell colSpan="8">
                          {data && this.props.totalPages > 0 ? (
                            <div className={"footer-container-left"}>
                              <Pagination
                                id="footer-pagination"
                                activePage={this.props.currentPage}
                                totalPages={this.props.totalPages}
                                boundaryRange={2}
                                onPageChange={(e, { activePage }) => {
                                  var newfilterData = {
                                    ...filterData,
                                    currentPage: activePage,
                                    pageSize: this.props.pageSize
                                  };

                                  this.handlePageChange(newfilterData);
                                }}
                              />
                            </div>
                          ) : (
                            <Message>No Order Found</Message>
                          )}
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Footer>
                  </Table>
                </Form>
              </Segment>
            </div>
          </Container>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  data: state.dashboard.data,
  dashboard: state.dashboard,
  standardLabelsCart: state.labelCart,
  isSuccessUpdateNotification: state.dashboard.isSuccessUpdateNotification,
  combinationLabelsCart: state.labelOrderCart,
  currentPage: state.dashboard.currentPage,
  pageSize: state.dashboard.pageSize,
  totalPages: state.dashboard.totalPages
});

const mapDispatchToProps = (dispatch) => ({
  getNotifications: (currentPage, pageSize) =>
    dispatch(getNotifications(currentPage, pageSize)),
  updateNotificationView: (userId) => dispatch(updateNotificationView(userId)),
  getCartItems: () => dispatch(getCartItems()),
  getLabelOrderCart: () => dispatch(getLabelOrderCart()),
  updateData: (result) => dispatch(updateData(result)),
  getActivePage: (page) => dispatch(commonActions.getActivePage(page))
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
