import * as types from "./auth.type";
import api from "../../services/api";
import jwtDecode from "jwt-decode";
import axios from "axios";

const dispatchResult = (type, payload) => {
  return { type: type, payload: payload };
};

export const getRolePages = () => {
  return async (dispatch) => {
    dispatch(dispatchResult(types.ROLEPAGES_REQUEST));

    await api.User.getRolePages()
      .then((result) => {
        if (result) dispatch(dispatchResult(types.ROLEPAGES_SUCCESS, result));
      })
      .catch((error) => {
        dispatch(dispatchResult(types.ROLEPAGES_FAILURE, error));
      });
  };
};

export const doExternalLogout = () => {
  return async (dispatch) => {
    dispatch(dispatchResult(types.EXTERNAL_LOGOUT_REQUEST));

    await api.Auth.logoutExternal()
      .then((result) => {
        if (result)
          //console.log("actionResult:" + result);
          dispatch(dispatchResult(types.EXTERNAL_LOGOUT_SUCCESS, result));
      })
      .catch((error) => {
        dispatch(dispatchResult(types.EXTERNAL_LOGOUT_FAILURE, error));
      });
  };
};

export const validateLogin = () => {
  return async (dispatch) => {
    dispatch(dispatchResult(types.VALIDATE_LOGIN_REQUEST));

    await api.Auth.validateLogin()
      .then((result) => {
        if (result)
          dispatch(dispatchResult(types.VALIDATE_LOGIN_SUCCESS, result));
      })
      .catch((error) => {
        dispatch(dispatchResult(types.VALIDATE_LOGIN_FAILURE, error));
      });
  };
};

export const doLogout = () => {
  return async (dispatch) => {
    await api.Auth.logoutExternal()
      .then((result) => {
        if (result) dispatch(dispatchResult(types.LOGOUT_SUCCESS, result));
      })
      .catch((error) => {
        dispatch(dispatchResult(types.LOGOUT_FAILURE, error));
      });
  };
};
