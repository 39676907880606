import React, { Component, Fragment } from "react";
import {
  Tab,
  Grid,
  Button,
  Divider,
  Modal,
  Header,
  Menu
} from "semantic-ui-react";
import { connect } from "react-redux";
import { Link, Redirect, withRouter } from "react-router-dom";

import { Common } from "../../../../common";
import ToastHelper from "../../../../helpers/toast.helper";
import { GetFormData } from "../../field-evaluation-helper";
import { CustomHeaderBold } from "../../../../components/labels/labels";
import {
  validateSubFormField,
  validateField,
  getError
} from "../../../../validator/validator";

// Component
import Scope from "../common/scope/scope-value";
import FeSummary from "../common/review-summary/fe-summary";
import ProjectDetails from "../project-details/project-details";
import AssumptionModal from "../common/assumption/assumption-modal";
import AssumptionValue from "../common/assumption/assumption-value";
import ManualPricingModal from "../common/manual-pricing/modal-message";
import RequirementsValue from "../common/requirement/requirement-value";
import SchedulingDetails from "../scheduling-details/scheduling-details";
import CancellationValue from "../common/cancellation/cancellation-value";

// Enums
import Role from "../../../../enums/role";
import Status from "../../../../enums/status";
import OrderType from "../../../../enums/order-type";
import ServiceType from "../../../../enums/service-type";

import { createValidation } from "../../../../validator/validator";
import { FEGSQRules } from "../../../field-evaluation/validation-rules";

// Action
import * as actions from "./new-quote-details.action";
import * as schedActions from "../scheduling-details/scheduling-details.action";
import * as projectActions from "../project-details/project-details.action";
import * as accountActions from "../../../field-evaluation/account-settings/account-settings.action";
import * as fieldEvaluationActions from "../../field-evaluation.action";
import { getCustomerDetails } from "../../../manufacturer/standard/label-cart/label-cart.action";

class NewQuoteDetails extends Component {
  state = {
    activeIndex: 0,
    siteActiveIndex: 0,
    openSummaryModal: false,
    openSaveAsDraft: false,
    openSaveChanges: false,
    isManualPricingModal: false,
    loading: true,
    orderNumber: null,
    isLoading: "",
    orderSiteCount: null,
    isShowAssumptionModal: false,
    createdForUserId: 0,
    evaluatedSameDay: false,
    currentStatus: null
  };

  feGsqValidation() {
    const { forms } = this.props.projectDetails;
    forms.map((form, idx) => {
      const rules = projectActions.getValidation(form, FEGSQRules);
      form.validationErrors = createValidation(form, rules);

      projectActions.updateForm(idx, form, forms);
    });
  }

  handleOpenSummaryModal() {
    this.feGsqValidation();

    const { forms } = this.props.projectDetails;
    let hasGsqError = forms
      .map((form) => {
        return !Object.IsEmpty(form.validationErrors);
      })
      .some((s) => s);

    if (hasGsqError) {
      setTimeout(() => {
        ToastHelper.error({
          title: "Error",
          description: "Please complete all the required fields"
        });
      }, 100);
    }
    this.setState({ openSummaryModal: !hasGsqError });
  }

  handleCloseSummaryModal = () => {
    this.setState({ openSummaryModal: false });
  };

  checkManualPricingTrigger(value, target, index) {
    const { forms, siteDetails } = this.props.projectDetails;
    const { schedulingForms } = this.props.schedulingDetails;
    const { isSameDayVisit } = this.props;

    const form = forms.find((form) => form.tabIndex == index);

    const group = (arr) => {
      return arr.reduce((c, b) => {
        (c[b] = c[b] || []).push(b);

        return c;
      }, {});
    };

    // More than 4 quantity for switchboard
    let isQtyGreaterFour =
      parseInt(target && target === "quantity" ? value : form.quantity) > 4 &&
      form.isSwitchboard;

    // Is equipment already installed? No, will trigger manual pricing
    let isEquipInstalled =
      siteDetails[index].others.showPrelimLocation == "false" ? true : false;

    let isReadyToScheduleNow = siteDetails[index].others.isReadyToScheduleNow;

    // Will the training take more than 2 hours? Yes, will trigger manual pricing
    // Index 0 is the on-site training
    let isTrainingTwoHours =
      schedulingForms[index].trainingSelections[0].trainingTwoHours;

    // More than 1 quantity for panelboard
    let isQtyGreaterOne =
      parseInt(target && target === "quantity" ? value : form.quantity) > 1 &&
      form.isPanelboard;

    // final installation, diff state
    let statesArr = group(
      siteDetails.map((s) => s.final.state).filter((c) => !!c)
    );
    let states = Object.keys(statesArr).length > 1;

    // final installation, same state and diff cities
    let citiesArr = group(
      siteDetails.map((s) => s.final.city).filter((c) => !!c)
    );
    let cities = !states && Object.keys(citiesArr).length > 1;

    let isManualModal = false;
    let scopeIdx = forms.findIndex((form) => form.tabIndex === 0); // update index 0 only
    switch (target) {
      case "quantity":
      case "equipmentType":
        if (form.isSwitchboard) {
          if (isQtyGreaterFour) isManualModal = true;
          if (parseInt(value || form.quantity) === 1)
            form.isEvaluatedSameVisit = null;
        }
        if (form.isPanelboard) {
          isQtyGreaterOne
            ? (isManualModal = true)
            : (form.isEvaluatedSameVisit = null);
        }

        this.updateGsqForm(
          "scope",
          Scope.scopeValueMulti(forms, null, false),
          scopeIdx
        );
        break;
      case "showPrelimLocation":
        if (isEquipInstalled) {
          isManualModal = true;
        } else {
          // Is equipment already installed? Yes, will show ready to shced form
          // onload check ready to sched default value - "No"
          if (!isReadyToScheduleNow) isManualModal = true;
        }
        break;
      case "isReadyToScheduleNow":
        if (!isReadyToScheduleNow) isManualModal = true;
        break;
      case "trainingTwoHours":
        if (isTrainingTwoHours) isManualModal = true;
        break;
      case "state":
        if (states) isManualModal = true;
        break;
      case "city":
        if (cities) isManualModal = true;
        break;
      case "isInvolvedLitigation":
        if (
          form.isInvolvedLitigation &&
          typeof form.isInvolvedLitigation !== "undefined"
        )
          isManualModal = true;
        break;
      case "switchboardOver600":
        if (
          form.switchboardOver600 &&
          typeof form.switchboardOver600 !== "undefined"
        )
          isManualModal = true;
        break;
      case "isEvaluatedSameVisit":
        // Is Evaluated Same Visit per form
        if (
          !form.isEvaluatedSameVisit &&
          typeof form.isEvaluatedSameVisit !== "undefined" &&
          form.isEvaluatedSameVisit !== null
        )
          isManualModal = true;
        break;
      case "isExistingUlEquipment":
        // No manual pricing, update scope only
        // update index 0 only
        this.updateGsqForm(
          "scope",
          Scope.scopeValueMulti(forms, null, false),
          scopeIdx
        );
        break;
      default:
        // Is Evaluated Same Visit overall
        if (!isSameDayVisit) isManualModal = true;
        break;
    }

    let isManual = false;

    if (
      (!form.isEvaluatedSameVisit &&
        typeof form.isEvaluatedSameVisit !== "undefined" &&
        form.isEvaluatedSameVisit !== null) ||
      (form.isInvolvedLitigation &&
        typeof form.isInvolvedLitigation !== "undefined") ||
      isQtyGreaterFour ||
      isEquipInstalled ||
      isTrainingTwoHours ||
      isQtyGreaterOne ||
      states ||
      cities ||
      (!isSameDayVisit && isSameDayVisit !== null) ||
      (!isReadyToScheduleNow && typeof isReadyToScheduleNow !== "undefined")
    )
      isManual = true;

    if (
      form.switchboardOver600 &&
      typeof form.switchboardOver600 !== "undefined"
    )
      isManual = true;

    this.setState({
      isManualPricingModal: isManualModal
    });
    this.updateGsqForm("isManualPricing", isManual, index);
  }

  handleIsManualPricingModalClose = () => {
    this.setState({ isManualPricingModal: false });
  };

  updateGsqForm(name, value, index) {
    const {
      updateGsqForm,
      projectDetails: { forms }
    } = this.props;
    const form = this.props.getFormByTabIndex(index, forms);

    let newForm = Object.assign({}, form, {
      [name]: value
    });

    updateGsqForm(index, newForm, forms);
  }

  handleRenderScheduleDate = (date) => {
    if (date) {
      var date = new Date(date);
      var day = date.getDate();
      var month = date.getMonth() + 1;
      var year = date.getFullYear();
      var hour = date.getHours();
      var minute = date.getMinutes();
      var second = date.getSeconds();

      var time =
        month +
        "/" +
        day +
        "/" +
        year +
        " " +
        hour +
        ":" +
        minute +
        ":" +
        second;

      return time;
    }

    return null;
  };

  renderFeItem(item, idx, isIncludedSameDay, isEvaluatedSameDay) {
    const { projectDetails } = this.props;
    let isPHEdit = Common.isPhEditing(item.isManualPricing);

    let data = {
      quantity: parseInt(item.quantity),
      isMinimumFee: false, // FES only
      areAerialsBrontoSchwingMetz: false, // FES only
      hasExistingULLabel: item.isExistingUlEquipment,
      isProductInvolvedInLitigation: item.isInvolvedLitigation,
      isManualPricing: item.isManualPricing,
      litigationDescription: item.isInvolvedLitigation
        ? item.litigationDescription
        : null,
      isInstalledSameLocation: item.isInstalledSameLocation,
      isEvaluatedSameVisit: item.isEvaluatedSameVisit,
      isPanelboardOver200: item.isPanelboard ? item.panelboardOver200 : null,
      isSwitchboardOver600: item.isSwitchboard ? item.switchboardOver600 : null,
      propertyType: item.propertyType,
      additionalInformation: item.additionalInfo,
      assumptions: AssumptionValue(
        projectDetails,
        null,
        false,
        isIncludedSameDay,
        isEvaluatedSameDay
      ),
      scope: projectDetails.forms.find((f) => f.tabIndex === 0).scope,
      cancellation: CancellationValue(projectDetails),
      requirementsInspection: RequirementsValue(projectDetails),
      serviceSubProductId: item.serviceSubProductId, // for draft & pua only
      tabIndex: idx
    };
    if (isPHEdit) data.total = parseInt(item.totalPriceByPh);

    return data;
  }

  renderQuoteDetails = (
    projectDetails,
    arrForm,
    schedulingDetails,
    sitesCount,
    isIncludedSameDay,
    isEvaluatedSameDay
  ) => {
    var quoteDetails = [];

    for (let i = 0; i < sitesCount; i++) {
      var items =
        projectDetails.forms.length > 0
          ? projectDetails.forms.filter((p) => p.tabIndex == i)[0]
          : {};
      var reservationDetails = schedulingDetails.schedulingForms[i]
        ? schedulingDetails.schedulingForms[i]
        : {};

      var siteDetails = projectDetails.siteDetails[i]
        ? projectDetails.siteDetails[i]
        : {};

      var { otherDetails, productDetails } = arrForm[i];

      let notes = {
        note: (siteDetails.others || {}).specialNotes
      };
      productDetails.specialNotes = notes.note ? [notes] : null;

      var sites = [],
        engineers = [],
        ahjs = [],
        productItems = [],
        quoteAcceptance = null;

      quoteAcceptance = {
        customerReferenceNumber: siteDetails.others.customerReferenceNumber,
        isReadyToScheduleNow: siteDetails.others.isReadyToScheduleNow,
        preferredScheduleDate: this.handleRenderScheduleDate(
          reservationDetails.preliminarySiteLocationSection.scheduleDate
        )
      };

      siteDetails.prelim.siteType = 1;
      siteDetails.final.siteType = 2;

      sites.push(siteDetails.prelim);
      sites.push(siteDetails.final);

      reservationDetails.preliminarySiteLocationSection.siteType = 1;
      reservationDetails.preliminarySiteLocationSection.scheduleUnixTime = reservationDetails
        .preliminarySiteLocationSection.scheduleDate
        ? reservationDetails.preliminarySiteLocationSection.scheduleDate.getTime() /
          1000
        : null;
      reservationDetails.finalSiteLocationSection.siteType = 2;

      engineers.push(reservationDetails.preliminarySiteLocationSection);
      engineers.push(reservationDetails.finalSiteLocationSection);

      siteDetails.ahj.baseType = 1;
      reservationDetails.authorityHavingJurisdictionDetails.baseType = 2;

      ahjs.push(siteDetails.ahj);
      ahjs.push(reservationDetails.authorityHavingJurisdictionDetails);

      productItems.push(
        this.renderFeItem(items, i, isIncludedSameDay, isEvaluatedSameDay)
      );

      quoteDetails.push({
        quoteAcceptance: quoteAcceptance,
        productItems: productItems,
        sites: sites,
        products: productDetails,
        engineers: engineers,
        authorityJurisdictions: ahjs,
        tabIndex: i
      });
    }

    return quoteDetails;
  };

  handleConfirmSaveAsDraft = (status) => {
    // saving draft or PUA
    this.setState({
      isLoading: "loading"
    });
    const sitesCount = localStorage.getItem("sitesCount");

    const {
      projectDetails,
      arrForm,
      schedulingDetails,
      accountDetails,
      isSameDayVisit
    } = this.props;

    const isEvaluatedSameDay =
      isSameDayVisit === null ? this.state.evaluatedSameDay : isSameDayVisit;

    var model = {
      siteCount: sitesCount,
      status: status,
      orderType: OrderType.Quote,
      serviceType: ServiceType.fe,
      quoteDetails: this.renderQuoteDetails(
        projectDetails,
        arrForm,
        schedulingDetails,
        sitesCount,
        isSameDayVisit !== null, // if true, from new quote always display added assumption
        isEvaluatedSameDay
      ),
      createdForUserId:
        accountDetails.form.applicantDetails.applicantDetails.userId,
      evaluatedSameDay: isEvaluatedSameDay
    };

    this.props.createFieldServiceOrder(model).then((s) => {
      var message =
        status === Status.pendingAssessment
          ? `Thank you for you application. We will be in contact with you shortly.`
          : `Thank you for your business. Your quote reference number is ${
              s ? s.number : ""
            }.
                  A Project Handler will be in touch with you as soon as
                  possible.`;
      this.handleToastMessage(s, message);
      this.handleUploadAttachments(projectDetails.forms, s);
    });

    if (status === Status.draft) {
      this.toggleSaveAsDraftModal(false);
    } else {
      this.handleCloseSummaryModal();
    }
  };

  handleConfirmSaveChanges = () => {
    this.setState({
      isLoading: "loading"
    });
    const { orderNumber, orderSiteCount, currentStatus } = this.state;

    const localCountSite = Common.getSiteCount();
    const sitesCount = orderNumber
      ? orderSiteCount
      : localCountSite > 0
      ? localCountSite
      : 0;

    const {
      projectDetails,
      arrForm,
      schedulingDetails,
      updateFieldServiceOrder
    } = this.props;
    var _orderNumber = this.props.match.params.orderNumber;

    var model = {
      requestNumber: _orderNumber,
      siteCount: sitesCount,
      status: currentStatus,
      orderType: OrderType.Quote,
      serviceType: ServiceType.fe,
      quoteDetails: this.renderQuoteDetails(
        projectDetails,
        arrForm,
        schedulingDetails,
        sitesCount
      )
    };

    updateFieldServiceOrder(model).then((result) => {
      var message = `Save Changes Successfully. Your quote number is ${
        result ? result.number : ""
      }.`;
      this.handleToastMessage(result, message);
      this.handleUploadAttachments(projectDetails.forms, result);
    });

    this.toggleSaveChangesModal(false);
  };

  handleUploadAttachments(attachments, result) {
    for (var i = 0; i < attachments.length; i++) {
      var data = {
        fileName: attachments[i]["fileName"],
        artworkLink: attachments[i]["artworkLink"],
        tabIndex: attachments[i]["tabIndex"],
        requestNumber: result.number
      };
      if (data.fileName) this.props.uploadMultiSiteAttachment(data);
    }
  }

  handleToastMessage(result, message) {
    if (result) {
      if (result.number != "") {
        this.setState({
          // isSuccess: true,
          refereceNmber: result.number,
          isShowButton: false,
          isLoading: "",
          isSuccess: true
        });

        setTimeout(() => {
          ToastHelper.success({
            description: <p>{message}</p>
          });
        }, 100);
      } else {
        setTimeout(() => {
          ToastHelper.error({
            title: "Something went wrong",
            description: result.message
          });
        }, 100);
      }
    } else {
      this.setState({
        isLoading: "",
        isSuccess: false
      });
      setTimeout(() => {
        ToastHelper.error({
          title: "Error",
          description: "Something went wrong"
        });
      }, 100);
    }
  }

  toggleSaveAsDraftModal = (toggle) => {
    this.setState({ openSaveAsDraft: toggle });
  };
  toggleSaveChangesModal = (toggle) => {
    this.setState({ openSaveChanges: toggle });
  };

  getErrorFor = (field, formType) => {
    // const { form, data } = this.props.fieldEvaluation;
    // let results = GetFormData(form, formType);
    // let errors = getError(field, results.formData, data);
    // return errors;
  };

  componentDidMount() {
    const {
      clearFEOrder,
      clearGsq,
      renderDynamicForm,
      renderDynamicSiteForm,
      renderSchedulingForm,
      getEngineerCountries,
      getAhjCountries,
      getPpes,
      getTrainings,
      getOrderDetails,
      renderProductDetails,
      renderSiteDetails,
      renderSchedulingDetails,
      getAccountSettings,
      getAccountSettingsByPsn,
      getCustomerDetails
    } = this.props;

    clearFEOrder();
    clearGsq();

    const sitesCount = localStorage.getItem("sitesCount");
    const isSameDayVisit = Common.getSameDayVisitFlag();

    getEngineerCountries(1);
    getEngineerCountries(2);

    getAhjCountries();

    //Fallback: Close loading at certain timeout
    setTimeout(() => this.setState({ loading: false }), 20000);

    var _orderNumber = this.props.match.params.orderNumber;
    var _orderNo = _orderNumber ? _orderNumber : null;
    this.setState({ orderNumber: _orderNo });

    var userRole = Common.getUserRole();
    var accountSettingsPsn = localStorage.getItem("accountSettingsPsn");

    if (_orderNumber) {
      getOrderDetails(_orderNumber).then((result) => {
        const userId = result.data.orderDetails.createdForUserId;
        const isEvaluatedSameDay = result.data.orderDetails.evaluatedSameDay;
        this.setState({
          createdForUserId: userId,
          evaluatedSameDay: isEvaluatedSameDay,
          currentStatus: result.data.orderDetails.status
        });
        let count = result.data.orderDetails.siteCount;

        renderDynamicForm(count, isSameDayVisit);
        renderProductDetails(count, result.data);
        renderSiteDetails(count, result.data);
        renderSchedulingDetails(count, result.data);

        getAccountSettings(userId);

        this.setState({ orderSiteCount: count });
        //getCustomerDetails(s.data.orderDetails.partySiteNumber);
      });
    } else {
      let sameDay = this.props.isSameDayVisit;
      if (sameDay === undefined || sameDay === null)
        return this.props.history.push("/home/quote-sites");

      if (userRole == Role.projectHandler && accountSettingsPsn) {
        getAccountSettingsByPsn(accountSettingsPsn);
        getCustomerDetails(accountSettingsPsn);
      } else {
        getAccountSettings();
      }

      renderDynamicForm(sitesCount, isSameDayVisit);
      renderDynamicSiteForm(sitesCount);
      renderSchedulingForm(sitesCount);

      getPpes();
      getTrainings();
    }
  }

  handleTabChange = (e, { activeIndex }) => {
    this.setState({ activeIndex });
  };

  handleLoading() {
    // checking if forms are complete based on # of sites and each has tabIndex
    const sitesCount = Common.getSiteCount();
    const me = this;
    const {
      projectDetails: { forms }
    } = me.props;

    setTimeout(() => {
      if (!me.state.loading) return;

      me.setState({
        loading: !(
          (forms || []).length === sitesCount &&
          forms.every((s) => (s || {}).tabIndex > -1)
        )
      });
    }, 700);
  }

  handleSiteTabChange = (e, { activeIndex }) => {
    this.setState({ siteActiveIndex: activeIndex, activeIndex: 0 });
  };

  handleChangeTabIndex = (activeIndex) => {
    this.setState({ activeIndex });
    this.handleTabChange({}, { activeIndex });
  };

  handleAssumptionModal(isShow) {
    this.setState({ isShowAssumptionModal: isShow });
  }

  render() {
    const {
      activeIndex,
      siteActiveIndex,
      openSummaryModal,
      openSaveAsDraft,
      openSaveChanges,
      isManualPricingModal,
      orderNumber,
      isLoading,
      orderSiteCount,
      isShowAssumptionModal,
      createdForUserId
    } = this.state;

    const localCountSite = Common.getSiteCount();
    const sitesCount = orderNumber
      ? orderSiteCount
      : localCountSite > 0
      ? localCountSite
      : 0;

    const {
      isSuccessRenderForm,
      arrForm,
      isSuccess,
      accountSettings
    } = this.props;

    const { forms, siteDetails } = this.props.projectDetails;
    const { schedulingForms } = this.props.schedulingDetails;
    const primaryDetails = accountSettings
      ? accountSettings.applicantDetails.applicantDetails
      : {};
    const billToDetails = accountSettings
      ? accountSettings.applicantDetails.applicantBillToLocationDetails
      : {};
    var panes = [];

    var userRole = Common.getUserRole();

    if (isSuccess) {
      this.props.clearFEOrder();
      if (userRole == Role.projectHandler)
        return <Redirect to="/home/view-assigned" />;
      return <Redirect to="/home/view-history" />;
    }

    if (isSuccessRenderForm) {
      for (let i = 0; i < sitesCount; i++) {
        const frm =
          forms && forms.length
            ? forms.find((s) => (s || {}).tabIndex === i) || {}
            : {};

        panes.push({
          menuItem: (
            <Menu.Item
              key={`site-${i + 1}`}
              className={frm.isManualPricing ? "manual-pricing" : ""}
            >
              {`Site ${i + 1}`}
            </Menu.Item>
          ),
          pane: (
            <Tab.Pane key={i} loading={this.state.loading}>
              <Tab
                panes={[
                  {
                    menuItem: "Project Details",
                    render: () => (
                      <Tab.Pane>
                        <ProjectDetails
                          tabIndex={i}
                          activeIndex={activeIndex}
                          //siteDetails={arrForm[i].siteDetails}
                          //otherDetails={arrForm[i].otherDetails}
                          handleChangeTabIndex={this.handleChangeTabIndex}
                          form={arrForm[i]}
                          index={i}
                          checkManualPricingTrigger={this.checkManualPricingTrigger.bind(
                            this
                          )}
                          isLoading={this.handleLoading.bind(this)}
                          orderNumber={orderNumber}
                          createdForUserId={createdForUserId}
                        />
                      </Tab.Pane>
                    )
                  },
                  {
                    menuItem: siteDetails[i]?.others?.isReadyToScheduleNow ===
                      true && <Menu.Item>Scheduling Details</Menu.Item>,
                    render: () => (
                      <Tab.Pane>
                        <SchedulingDetails
                          tabIndex={i}
                          getErrorFor={this.getErrorFor}
                          form={arrForm[i]}
                          checkManualPricingTrigger={this.checkManualPricingTrigger.bind(
                            this
                          )}
                        />
                      </Tab.Pane>
                    )
                  }
                ]}
                activeIndex={activeIndex}
                onTabChange={this.handleTabChange}
              />
            </Tab.Pane>
          )
        });
      }
    }

    let siteHasManualPricing = forms.some(
      (form) => form["isManualPricing"] === true
    );
    let isPHEdit = Common.isPhEditing(siteHasManualPricing);

    return (
      <Fragment>
        <div
          style={{
            width: "80%",
            padding: "20px"
          }}
        >
          <CustomHeaderBold message="Quote Details" />
          <div>
            Editing site {siteActiveIndex + 1} of {sitesCount}
          </div>

          <Tab
            renderActiveOnly={false}
            panes={panes}
            activeIndex={siteActiveIndex}
            onTabChange={this.handleSiteTabChange}
          />
          <Divider hidden />
          <Grid centered columns={2}>
            {!orderNumber ? (
              <Button
                color="green"
                className={isLoading}
                onClick={this.toggleSaveAsDraftModal.bind(this, true)}
              >
                Save
              </Button>
            ) : (
              <Button
                className={isLoading}
                color="green"
                onClick={this.toggleSaveChangesModal.bind(this, true)}
              >
                Save Changes
              </Button>
            )}

            <Button
              color="blue"
              onClick={this.handleOpenSummaryModal.bind(this)}
            >
              Review Project Details
            </Button>
            {isPHEdit ? (
              <Button
                color="blue"
                onClick={this.handleAssumptionModal.bind(this, true)}
              >
                Edit Assumption/Scope
              </Button>
            ) : null}
            <Button negative as={Link} to={"/home/quote-sites"}>
              Cancel
            </Button>
          </Grid>

          {/* REVIEW SUMMARY */}
          <FeSummary
            openSummaryModal={openSummaryModal}
            handleCloseSummaryModal={this.handleCloseSummaryModal}
            forms={forms}
            schedulingForms={schedulingForms}
            siteDetails={siteDetails}
            primaryDetails={primaryDetails}
            billToDetails={billToDetails}
            handleSavePUA={this.handleConfirmSaveAsDraft}
            isFromOffered={false} // always false, newly create
          />

          <Modal
            size="mini"
            open={openSaveAsDraft}
            onClose={this.toggleSaveAsDraftModal.bind(this, false)}
          >
            <Modal.Header>Save</Modal.Header>
            <Modal.Content>
              <p>Are you sure you want to save?</p>
            </Modal.Content>
            <Modal.Actions>
              <Button
                primary
                onClick={this.handleConfirmSaveAsDraft.bind(this, Status.draft)}
              >
                Yes
              </Button>
              <Button
                primary
                basic
                onClick={this.toggleSaveAsDraftModal.bind(this, false)}
              >
                Cancel
              </Button>
            </Modal.Actions>
          </Modal>

          <Modal
            size="mini"
            open={openSaveChanges}
            onClose={this.toggleSaveChangesModal.bind(this, false)}
          >
            <Modal.Header>Save Changes</Modal.Header>
            <Modal.Content>
              <p>Are you sure you want to save changes?</p>
            </Modal.Content>
            <Modal.Actions>
              <Button primary onClick={this.handleConfirmSaveChanges}>
                Yes
              </Button>
              <Button
                primary
                basic
                onClick={this.toggleSaveChangesModal.bind(this, false)}
              >
                Cancel
              </Button>
            </Modal.Actions>
          </Modal>

          {/* MANUAL PRICING MESSAGE */}
          <ManualPricingModal
            isManualPricingModal={isManualPricingModal}
            handleIsManualPricingModalClose={
              this.handleIsManualPricingModalClose
            }
          />

          {/* PH EDIT ASSUMPTION */}
          {isShowAssumptionModal ? (
            <AssumptionModal
              isShowAssumptionModal={isShowAssumptionModal}
              handleAssumptionModal={this.handleAssumptionModal.bind(this)}
              data={this.props.projectDetails}
            />
          ) : null}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  sitesCount: state.quoteSites.sitesCount,
  arrForm: state.fieldService.arrForm,
  form: state.fieldService.form,
  isSuccess: state.fieldService.isSuccess,
  isSuccessRenderForm: state.fieldService.isSuccessRenderForm,
  projectDetails: state.feProjectDetails,
  schedulingDetails: state.schedulingDetails,
  accountSettings: state.accountSettings.form,
  isSameDayVisit: state.quoteSites.isSameDayVisit,
  accountDetails: state.accountSettings
});

const mapDispatchToProps = (dispatch) => ({
  createFieldServiceOrder: (data) =>
    dispatch(actions.createFieldServiceOrder(data)),
  updateFieldServiceOrder: (data) =>
    dispatch(actions.updateFieldServiceOrder(data)),
  renderDynamicForm: (count, isSameDayVisit) =>
    dispatch(actions.renderDynamicForm(count, isSameDayVisit)),
  getEngineerCountries: (locationType) =>
    dispatch(schedActions.getEngineerCountries(locationType)),
  getAhjCountries: () => dispatch(schedActions.getAhjCountries()),
  getPpes: () => dispatch(schedActions.getPpes()),
  getTrainings: () => dispatch(schedActions.getTrainings()),
  updateForm: (data) => dispatch(schedActions.updateForm(data)),
  renderSchedulingForm: (count) =>
    dispatch(schedActions.renderSchedulingForm(count)),
  clearFEOrder: () => dispatch(actions.clearFEOrder()),
  renderDynamicSiteForm: (count) =>
    dispatch(projectActions.renderDynamicSiteForm(count)),
  updateGsqForm: (idx, form, forms) =>
    dispatch(projectActions.updateForm(idx, form, forms)),
  getFormByTabIndex: (idx, forms) =>
    projectActions.getFormByTabIndex(idx, forms),
  clearGsq: () => dispatch(projectActions.clearGsq()),
  getAccountSettings: (userId) =>
    dispatch(accountActions.getAccountSettings(userId)),
  uploadMultiSiteAttachment: (attachments) =>
    dispatch(fieldEvaluationActions.uploadMultiSiteAttachment(attachments)),
  getOrderDetails: (orderNumber) =>
    dispatch(actions.getOrderDetails(orderNumber)),
  renderProductDetails: (siteCount, data) =>
    dispatch(projectActions.renderProductDetails(siteCount, data)),
  renderSiteDetails: (siteCount, data) =>
    dispatch(projectActions.renderSiteDetails(siteCount, data)),
  renderSchedulingDetails: (siteCount, data) =>
    dispatch(schedActions.renderSchedulingDetails(siteCount, data)),
  getAccountSettingsByPsn: (psn) =>
    dispatch(accountActions.getAccountSettingsByPsn(psn)),
  getCustomerDetails: (psn) => dispatch(getCustomerDetails(psn))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NewQuoteDetails));
