import * as types from "./scheduling-details.type";
import api from "../../../../services/api";
import merge from "deepmerge";
import { GetFormDataRD } from "../../field-evaluation-helper";

const dispatchRequest = (obj, type) => {
  return {
    data: obj,
    type: type
  };
};

export const updateForm = (idx, form, forms, formType) => {
  const newForms = forms.slice(0);
  let newForm = forms[idx];

  var formSection = GetFormDataRD(newForm, formType);

  switch (formType) {
    case "RD-PSLS":
      newForms[idx].preliminarySiteLocationSection = merge(
        formSection.formData,
        form
      );
      break;
    case "RD-FSLS":
      newForms[idx].finalSiteLocationSection = merge(
        formSection.formData,
        form
      );
      break;
    case "RD-AHJD":
      newForms[idx].authorityHavingJurisdictionDetails = merge(
        formSection.formData,
        form
      );
      break;
  }

  return (dispatch) =>
    dispatch(dispatchRequest(newForms, types.UPDATE_SCHEDULING_FORM));
};

export const getEngineerStates = (countryCode, locationType) => {
  return async (dispatch) => {
    let type = null;
    switch (locationType) {
      case 1:
        type = types.STATES_PRELIM_ENGR_REQUEST;
        break;
      case 2:
        type = types.STATES_FINAL_ENGR_REQUEST;
        break;
      case 3:
        type = types.STATES_AHJ_REQUEST;
        break;
    }
    dispatch(dispatchRequest(null, type));

    await api.FieldEvaluation.getStates(countryCode)
      .then((result) => {
        let type = null;
        switch (locationType) {
          case 1:
            type = types.STATES_PRELIM_ENGR_SUCCESS;
            break;
          case 2:
            type = types.STATES_FINAL_ENGR_SUCCESS;
            break;
          case 3:
            type = types.STATES_AHJ_SUCCESS;
            break;
        }
        dispatch(dispatchRequest(result, type));
      })
      .catch((result) => {
        let type = null;
        switch (locationType) {
          case 1:
            type = types.STATES_PRELIM_ENGR_FAILURE;
            break;
          case 2:
            type = types.STATES_FINAL_ENGR_FAILURE;
            break;
          case 3:
            type = types.STATES_AHJ_FAILURE;
            break;
        }
        dispatch(dispatchRequest(result, type));
      });
  };
};

export const getEngineerCities = (stateCode, locationType) => {
  return async (dispatch) => {
    let type = null;
    switch (locationType) {
      case 1:
        type = types.CITIES_PRELIM_ENGR_REQUEST;
        break;
      case 2:
        type = types.CITIES_FINAL_ENGR_REQUEST;
        break;
      case 3:
        type = types.CITIES_AHJ_REQUEST;
        break;
    }
    dispatch(dispatchRequest(null, type));

    await api.FieldEvaluation.getCities(stateCode)
      .then((result) => {
        let type = null;
        switch (locationType) {
          case 1:
            type = types.CITIES_PRELIM_ENGR_SUCCESS;
            break;
          case 2:
            type = types.CITIES_FINAL_ENGR_SUCCESS;
            break;
          case 3:
            type = types.CITIES_AHJ_SUCCESS;
            break;
        }
        dispatch(dispatchRequest(result, type));
      })
      .catch((result) => {
        let type = null;
        switch (locationType) {
          case 1:
            type = types.CITIES_PRELIM_ENGR_FAILURE;
            break;
          case 2:
            type = types.CITIES_FINAL_ENGR_FAILURE;
            break;
          case 3:
            type = types.CITIES_AHJ_FAILURE;
            break;
        }
        dispatch(dispatchRequest(result, type));
      });
  };
};

export const getAhjCountries = () => {
  return async (dispatch) => {
    dispatch(dispatchRequest(null, types.COUNTRIES_AHJ_REQUEST));

    await api.FieldEvaluation.getCountries()
      .then((result) => {
        dispatch(dispatchRequest(result, types.COUNTRIES_AHJ_SUCCESS));
      })
      .catch((result) => {
        dispatch(dispatchRequest(result, types.COUNTRIES_AHJ_FAILURE));
      });
  };
};

export const getAhjStates = (countryCode) => {
  return async (dispatch) => {
    dispatch(dispatchRequest(null, types.STATES_AHJ_REQUEST));

    await api.FieldEvaluation.getStates(countryCode)
      .then((result) => {
        dispatch(dispatchRequest(result, types.STATES_AHJ_SUCCESS));
      })
      .catch((result) => {
        dispatch(dispatchRequest(result, types.STATES_AHJ_FAILURE));
      });
  };
};

export const getAhjCities = (stateCode) => {
  return async (dispatch) => {
    dispatch(dispatchRequest(null, types.CITIES_AHJ_REQUEST));

    await api.FieldEvaluation.getCities(stateCode)
      .then((result) => {
        dispatch(dispatchRequest(result, types.CITIES_AHJ_SUCCESS));
      })
      .catch((result) => {
        dispatch(dispatchRequest(result, types.CITIES_AHJ_FAILURE));
      });
  };
};

export const getEngineerCountries = (locationType) => {
  return async (dispatch) => {
    let type = null;
    switch (locationType) {
      case 1:
        type = types.COUNTRIES_PRELIM_ENGR_REQUEST;
        break;
      case 2:
        type = types.COUNTRIES_FINAL_ENGR_REQUEST;
        break;
      case 3:
        type = types.COUNTRIES_AHJ_REQUEST;
        break;
    }
    dispatch(dispatchRequest(null, type));

    await api.FieldEvaluation.getCountries()
      .then((result) => {
        let type = null;
        switch (locationType) {
          case 1:
            type = types.COUNTRIES_PRELIM_ENGR_SUCCESS;
            break;
          case 2:
            type = types.COUNTRIES_FINAL_ENGR_SUCCESS;
            break;
          case 3:
            type = types.COUNTRIES_AHJ_SUCCESS;
            break;
        }
        dispatch(dispatchRequest(result, type));
      })
      .catch((result) => {
        let type = null;
        switch (locationType) {
          case 1:
            type = types.COUNTRIES_PRELIM_ENGR_FAILURE;
            break;
          case 2:
            type = types.COUNTRIES_FINAL_ENGR_FAILURE;
            break;
          case 3:
            type = types.COUNTRIES_AHJ_FAILURE;
            break;
        }
        dispatch(dispatchRequest(result, type));
      });
  };
};

export const getPpes = () => {
  return async (dispatch) => {
    dispatch(dispatchRequest(null, types.GET_PPE_REQUEST));

    await api.FieldEvaluation.getPpes()
      .then((result) => {
        dispatch(dispatchRequest(result, types.GET_PPE_SUCCESS));
      })
      .catch((result) => {
        dispatch(dispatchRequest(result, types.GET_PPE_FAILURE));
      });
  };
};

export const getTrainings = () => {
  return async (dispatch) => {
    dispatch(dispatchRequest(null, types.GET_TRAININGS_REQUEST));

    await api.FieldEvaluation.getTrainings()
      .then((result) => {
        dispatch(dispatchRequest(result, types.GET_TRAININGS_SUCCESS));
      })
      .catch((result) => {
        dispatch(dispatchRequest(result, types.GET_TRAININGS_FAILURE));
      });
  };
};

export const updatePpeSelections = (selectedPpe, isChecked, tabIndex) => {
  return (dispatch) =>
    dispatch(
      dispatchRequest(
        { ppe: selectedPpe, checked: isChecked, tabIndex: tabIndex },
        types.UPDATE_SELECTED_PPES
      )
    );
};

export const updateTrainingSelections = (
  selectedTraining,
  isChecked,
  isTrainingTwoHours,
  tabIndex
) => {
  return (dispatch) =>
    dispatch(
      dispatchRequest(
        {
          training: selectedTraining,
          checked: isChecked,
          trainingTwoHours: isTrainingTwoHours,
          tabIndex: tabIndex
        },
        types.UPDATE_SELECTED_TRAININGS
      )
    );
};

export const renderSchedulingForm = (count) => {
  return async (dispatch) => {
    dispatch(dispatchRequest(count, types.RENDER_SCHEDULING_FORM));
  };
};

export const populateSchedulingForm = (index, data) => {
  return async (dispatch) => {
    dispatch(dispatchRequest({ index, data }, types.POPULATE_SCHEDULING_FORM));
  };
};

export const renderSchedulingDetails = (count, data) => {
  return async (dispatch) => {
    dispatch(
      dispatchRequest(
        { count: count, data: data },
        types.RENDER_SCHEDULING_DETAILS
      )
    );
  };
};
