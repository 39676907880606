import _ from "lodash";
import PropTypes from "prop-types";
import Scope from "../scope/scope-value";
import { withRouter } from "react-router-dom";
import Status from "../../../../../enums/status";
import React, { Component, Fragment } from "react";
import Property from "../../../../../enums/property";
import {
  Modal,
  Grid,
  Table,
  Button,
  Accordion,
  Label
} from "semantic-ui-react";

import { Common } from "../../../../../common/common";
import ManualPricingModal from "../../common/manual-pricing/modal-message";

const panels = (forms, schedulingForms, siteDetails) =>
  _.times(forms.length, (i) => ({
    key: `panel-${i}`,
    title: {
      content: (
        <Label
          size="large"
          color="blue"
          content={`Site ${i + 1} - ${
            (forms.find((f) => f.tabIndex === i) || {}).serviceSubProductName
          }`}
        />
      )
    },
    content: {
      content: (
        <Content
          item={forms.find((f) => f.tabIndex === i)}
          schedulingItem={schedulingForms[i]}
          siteDetail={siteDetails[i]}
        />
      )
    }
  }));

class FeSummary extends Component {
  state = {
    isManualPricingModal: false
  };

  handleIsManualPricingModalClose = () => {
    this.setState({ isManualPricingModal: false });
  };

  handleContinue() {
    const { forms, isFromOffered } = this.props;
    let siteHasManualPricing = forms.some(
      (form) => form["isManualPricing"] === true
    );

    let isPhEdit = Common.isPhEditing(siteHasManualPricing);

    if (siteHasManualPricing && !isPhEdit && !isFromOffered) {
      this.setState({ isManualPricingModal: true });
      setTimeout(() => {
        this.props.handleSavePUA(Status.pendingAssessment);
      }, 1000);
    } else {
      return this.props.history.push("/home/field-service/quotation");
    }
  }

  render() {
    const {
      openSummaryModal,
      handleCloseSummaryModal,
      forms,
      schedulingForms,
      primaryDetails,
      billToDetails,
      isFromOffered,
      siteDetails
    } = this.props;

    const { isManualPricingModal } = this.state;
    const form = forms.find((form) => form.tabIndex === 0);

    return (
      <Fragment>
        <Modal
          size="large"
          open={openSummaryModal}
          onClose={handleCloseSummaryModal}
        >
          <Modal.Header className="center">Project Review Summary</Modal.Header>
          <Modal.Content scrolling>
            <Grid>
              <Grid.Row>
                <Grid.Column width={8}>
                  <Table celled striped compact>
                    <Table.Header>
                      <Table.Row className="center">
                        <Table.HeaderCell colSpan="2">
                          Applicant Details
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell collapsing>UL Account #</Table.Cell>
                        <Table.Cell>{primaryDetails.accountNumber}</Table.Cell>
                      </Table.Row>

                      <Table.Row>
                        <Table.Cell collapsing>Company Name</Table.Cell>
                        <Table.Cell>
                          {primaryDetails && primaryDetails.legalCompanyName}
                        </Table.Cell>
                      </Table.Row>

                      <Table.Row>
                        <Table.Cell collapsing>Address</Table.Cell>
                        <Table.Cell>
                          {primaryDetails &&
                            `${primaryDetails.addressLine1} ${
                              primaryDetails.addressLine2 || ""
                            } ${primaryDetails.addressLine3 || ""}`}
                        </Table.Cell>
                      </Table.Row>

                      <Table.Row>
                        <Table.Cell collapsing>Contact Name</Table.Cell>
                        <Table.Cell>
                          {primaryDetails && primaryDetails.contactName}
                        </Table.Cell>
                      </Table.Row>

                      <Table.Row>
                        <Table.Cell collapsing>Contact Details</Table.Cell>
                        <Table.Cell>
                          {primaryDetails && primaryDetails.phoneNumber}
                        </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </Grid.Column>

                <Grid.Column width={8}>
                  <Table celled striped compact>
                    <Table.Header>
                      <Table.Row className="center">
                        <Table.HeaderCell colSpan="2">
                          Bill To Details
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell collapsing>UL Account #</Table.Cell>
                        <Table.Cell>{billToDetails.accountNumber}</Table.Cell>
                      </Table.Row>

                      <Table.Row>
                        <Table.Cell collapsing>Company Name</Table.Cell>
                        <Table.Cell>
                          {billToDetails && billToDetails.legalCompanyName}
                        </Table.Cell>
                      </Table.Row>

                      <Table.Row>
                        <Table.Cell collapsing>Address</Table.Cell>
                        <Table.Cell>
                          {billToDetails &&
                            `${billToDetails.addressLine1} ${
                              billToDetails.addressLine2 || ""
                            } ${billToDetails.addressLine3 || ""}`}
                        </Table.Cell>
                      </Table.Row>

                      <Table.Row>
                        <Table.Cell collapsing>Contact Name</Table.Cell>
                        <Table.Cell>
                          {billToDetails && billToDetails.contactName}
                        </Table.Cell>
                      </Table.Row>

                      <Table.Row>
                        <Table.Cell collapsing>Contact Details</Table.Cell>
                        <Table.Cell>
                          {billToDetails && billToDetails.phoneNumber}
                        </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column>
                  <Table striped compact structured>
                    <Table.Header>
                      <Table.Row className="center">
                        <Table.HeaderCell colSpan="2">
                          Project Summary Details
                        </Table.HeaderCell>
                      </Table.Row>

                      <Table.Row className="center">
                        <Table.HeaderCell colSpan="2">
                          Project & Scheduling Details –{" "}
                          <i>
                            The below summarizes the scope of your project and
                            scheduling details from your application:
                          </i>
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>

                    <Table.Body>
                      <Table.Row>
                        <Table.Cell>
                          <span>Project Scope: &nbsp;</span>
                          {(form || {}).scope || Scope.scopeValueMulti(forms)}
                        </Table.Cell>
                      </Table.Row>

                      <Table.Row>
                        <Table.Cell>
                          <Accordion
                            defaultActiveIndex={0}
                            panels={panels(forms, schedulingForms, siteDetails)}
                          />
                        </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Modal.Content>
          <Modal.Actions>
            <Button primary onClick={this.handleContinue.bind(this)}>
              Continue
            </Button>
            <Button primary basic onClick={handleCloseSummaryModal}>
              {!isFromOffered ? "Edit" : "Close"}
            </Button>
          </Modal.Actions>
        </Modal>

        {/* MANUAL PRICING MESSAGE */}
        <ManualPricingModal
          isManualPricingModal={isManualPricingModal}
          handleIsManualPricingModalClose={this.handleIsManualPricingModalClose}
        />
      </Fragment>
    );
  }
}

class Content extends Component {
  render() {
    const { item, schedulingItem, siteDetail } = this.props;

    let final = siteDetail.final;
    let ahj = siteDetail.ahj;
    let prelim = schedulingItem.preliminarySiteLocationSection;
    let otherTraining = prelim.otherTrainingDetails;
    let scheduleDate = prelim.scheduleDate
      ? new Date(prelim.scheduleDate).toDateOnlyStringFormat()
      : "";
    let scheduleTime = prelim.scheduleDate
      ? new Date(prelim.scheduleDate).toTimeOnlyStringFormat()
      : "";

    return (
      <Table>
        <Table.Body>
          <Table.Row>
            <Table.Cell collapsing>Requested Scheduled Date & Time:</Table.Cell>
            <Table.Cell>
              <Table>
                <Table.Header>
                  <Table.Row className="center">
                    <Table.HeaderCell collapsing>Date:</Table.HeaderCell>
                    <Table.HeaderCell collapsing>Time:</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell textAlign="center">{scheduleDate}</Table.Cell>
                    <Table.Cell textAlign="center">{scheduleTime}</Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Project Details:</Table.Cell>
            <Table.Cell>
              <Table>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell collapsing>Project Category:</Table.Cell>
                    <Table.Cell>{item.serviceProductName || ""}</Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell collapsing>Equipment Type:</Table.Cell>
                    <Table.Cell>{item.serviceSubProductName || ""}</Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell collapsing># of Units (EA):</Table.Cell>
                    <Table.Cell>{item.quantity || ""}</Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell collapsing>Property Type:</Table.Cell>
                    <Table.Cell>
                      {(
                        Object.keys(Property).find(
                          (k) => Property[k] === item.propertyType
                        ) || ""
                      ).capitalize()}
                    </Table.Cell>
                  </Table.Row>

                  {item.isPanelboard ? (
                    <Table.Row>
                      <Table.Cell collapsing>
                        Is the Panelboard Main breaker rated over 200A?:
                      </Table.Cell>
                      <Table.Cell>
                        {item.panelboardOver200 || "" ? "Yes" : "No"}
                      </Table.Cell>
                    </Table.Row>
                  ) : null}

                  {item.isSwitchboard ? (
                    <Table.Row>
                      <Table.Cell collapsing>
                        Is the Switchboard/Switchgear rated over 600 VAC?:
                      </Table.Cell>
                      <Table.Cell>
                        {item.switchboardOver600 || "" ? "Yes" : "No"}
                      </Table.Cell>
                    </Table.Row>
                  ) : null}

                  {parseInt(item.quantity) > 1 ? (
                    <Table.Row>
                      <Table.Cell>
                        Can all of the units be evaluated during the same
                        day/visit?:
                      </Table.Cell>
                      <Table.Cell>
                        {item.isEvaluatedSameVisit || "" ? "Yes" : "No"}
                      </Table.Cell>
                    </Table.Row>
                  ) : null}

                  <Table.Row>
                    <Table.Cell>
                      Is there an existing UL label(s) on the equipment?:
                    </Table.Cell>
                    <Table.Cell>
                      {item.isExistingUlEquipment || "" ? "Yes" : "No"}
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell collapsing>
                      Is product involved in current or past litigation?:
                    </Table.Cell>
                    <Table.Cell>
                      {item.isInvolvedLitigation || "" ? "Yes" : "No"}
                    </Table.Cell>
                  </Table.Row>

                  {item.isInvolvedLitigation || "" ? (
                    <Table.Row>
                      <Table.Cell>Litigation Description:</Table.Cell>
                      <Table.Cell>{item.litigationDescription}</Table.Cell>
                    </Table.Row>
                  ) : null}

                  <Table.Row>
                    <Table.Cell collapsing>Additional Information:</Table.Cell>
                    <Table.Cell>{item.additionalInfo || ""}</Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell collapsing>Final Installation Site:</Table.Cell>
            <Table.Cell>
              {`${final.addressLine1 || ""} ${final.addressLine2 || ""} ${
                final.addressLine3 || ""
              } ${final.city || ""}`}
              <br />
              {`${final.country || ""}, ${final.state || ""} ${
                final.postalCode || ""
              }`}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Final Site Contact Details:</Table.Cell>
            <Table.Cell>
              <Table>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell collapsing>Contact Name:</Table.Cell>
                    <Table.Cell>{final.siteContactName || ""}</Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell collapsing>Office:</Table.Cell>
                    <Table.Cell>
                      {final.siteContactOfficeNumber || ""}
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell collapsing>Mobile:</Table.Cell>
                    <Table.Cell>
                      {final.siteContactMobileNumber || ""}
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell collapsing>Email:</Table.Cell>
                    <Table.Cell>
                      {final.siteContactEmailAddress || ""}
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Final Site Specific Requirements:</Table.Cell>
            <Table.Cell>
              <Table>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell collapsing>Site Training:</Table.Cell>
                    <Table.Cell>
                      {schedulingItem.trainingSelections
                        .filter((tr) => tr.checked)
                        .map((t, idx) => {
                          return (
                            <div key={idx}>
                              <span>
                                {t.text}
                                {/* 1: On-site Training, display 2 hours training */}
                                {t.value == 1 && t.checked
                                  ? `: Will the training take more than 2 hours? : ${
                                      t.trainingTwoHours ? "Yes" : "No"
                                    }`
                                  : null}
                                {/* 3: Others, display others inputted */}
                                {t.value == 3 && t.checked
                                  ? `: ${otherTraining}`
                                  : null}
                              </span>
                              <br />
                            </div>
                          );
                        })}
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell collapsing>Security Steps:</Table.Cell>
                    <Table.Cell>{prelim.securityStepDetail || ""}</Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell collapsing>Other Site Requirements:</Table.Cell>
                    <Table.Cell>
                      {prelim.otherSiteRequirements || ""}
                    </Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell collapsing>Required PPE:</Table.Cell>
                    <Table.Cell>
                      {schedulingItem.ppeSelections
                        .filter((p) => p.checked)
                        .map((pr, idx) => {
                          return (
                            <div key={idx}>
                              <span>{pr.text}</span>
                              <br />
                            </div>
                          );
                        })}
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Authority Having Jurisdiction:</Table.Cell>
            <Table.Cell>
              {`${ahj.jurisdictionName || ""}`}
              <br />
              {`${ahj.addressLine1 || ""} ${ahj.addressLine2 || ""} ${
                ahj.addressLine3 || ""
              } ${ahj.city || ""}`}
              <br />
              {`${ahj.country || ""}, ${ahj.state || ""} ${
                ahj.postalCode || ""
              }`}
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>Inspector Contact Details:</Table.Cell>
            <Table.Cell>
              <Table>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell collapsing>Contact Name:</Table.Cell>
                    <Table.Cell>{ahj.inspectorName || ""}</Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell collapsing>Office:</Table.Cell>
                    <Table.Cell>{ahj.inspectorOfficeNumber || ""}</Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell collapsing>Mobile:</Table.Cell>
                    <Table.Cell>{ahj.inspectorMobileNumber || ""}</Table.Cell>
                  </Table.Row>

                  <Table.Row>
                    <Table.Cell collapsing>Email:</Table.Cell>
                    <Table.Cell>{ahj.inspectorEmailAddress || ""}</Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  }
}

FeSummary.propTypes = {
  openSummaryModal: PropTypes.bool.isRequired,
  handleCloseSummaryModal: PropTypes.func.isRequired,
  forms: PropTypes.array.isRequired
};

FeSummary.contextType = {
  history: PropTypes.object
};

export default withRouter(FeSummary);
