import * as types from "./login.type";
import Role from "../../enums/role";
import { Common } from "../../common";

const loggedInUserId = Common.getUserId();
const localUserId = +loggedInUserId ? JSON.parse(Common.getUserId()) : null;

const initialState = {
  hasError: false,
  message: null,
  authenticatedOnLogin: localUserId ? true : false,
  userId: localUserId ? localUserId : "",
  loading: false,
  rolePages: [],
  isSuccessGetRolePage: false
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOGIN_REQUEST:
      return {
        ...state,
        hasError: false,
        loading: true
      };
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        authenticatedOnLogin: true,
        hasError: false,
        userId: action.payload.id,
        loading: false,
        message: null
      };
    case types.LOGIN_FAILURE:
      return {
        ...state,
        message: action.payload,
        hasError: true,
        loading: false
      };
    case types.LOGOUT_SUCCESS:
      return {
        ...state,
        userId: ""
      };
    case types.LOGIN_UPDATE_MODEL:
      let newState = {
        ...state
      };

      newState[action.payload.name] = action.payload.value;
      return newState;
    case types.ROLEPAGES_REQUEST:
      return Object.assign({}, state, {
        rolePages: [],
        isSuccessGetRolePage: false
      });
    case types.ROLEPAGES_SUCCESS:
      var newStateRolePage = {
        ...state,
        rolePages: action.payload,
        isSuccessGetRolePage: true
      };

      let roleId = newStateRolePage.rolePages[0].roleId;

      if (roleId == Role.applicant) {
        newStateRolePage.orderPage = "/home/applicant-dashboard";
      } else if (roleId == Role.manufacturer) {
        newStateRolePage.orderPage = "/home/order-standard-label";
      }

      return newStateRolePage;
    case types.ROLEPAGES_FAILURE:
      return Object.assign({}, state, {
        rolePages: [],
        isSuccessGetRolePage: false
      });
    case types.CLEAR:
      return {
        ...state,
        userId: ""
      };
    case types.UPDATE_ORDERPAGE_SUCCESS:
      var newStatePage = {
        ...state
      };

      if (action.payload.roleId == Role.applicant) {
        newStatePage.orderPage = "/home/applicant-dashboard";
      } else if (action.payload.roleId == Role.manufacturer) {
        newStatePage.orderPage = "/home/order-standard-label";
      }

      return newStatePage;
    default:
      return state;
  }
};

export default loginReducer;
