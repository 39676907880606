import PropTypes from "prop-types";
import { connect } from "react-redux";
import React, { Component } from "react";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ToastHelper from "../../../../../helpers/toast.helper";
import { Modal, Grid, Form, Button, Input } from "semantic-ui-react";

import AssumptionValue from "./assumption-value";
import { FEGSQRules } from "../../../validation-rules";
import CancellationValue from "../cancellation/cancellation-value";
import { getError, validateField } from "../../../../../validator/validator";

import * as actions from "../../project-details/project-details.action";

class AssumptionModal extends Component {
  state = {};

  componentDidMount() {
    const {
      data: { forms },
      getFormByTabIndex
    } = this.props;
    const index = 0;
    const form = getFormByTabIndex(index, forms);

    // get the original text before editing
    this.setState({
      assumptions: form.assumptions,
      cancellation: form.cancellation,
      scope: form.scope
    });
  }

  handleChange(name, event, editor, isCancel) {
    const {
      updateForm,
      data: { forms },
      getFormByTabIndex
    } = this.props;

    // always get index 0 - it'll be same to all site
    const index = 0;
    const form = getFormByTabIndex(index, forms);

    let newForm = {};

    if (isCancel) {
      const { assumptions, cancellation, scope } = this.state;
      newForm = Object.assign({}, form, {
        assumptions: assumptions,
        cancellation: cancellation,
        scope: scope
      });

      const newRules = actions.getValidation(newForm, FEGSQRules);
      const newState = validateField(newRules, newForm, name, this.state.scope);

      updateForm(index, newState, forms);
      return;
    }
    let value = name === "scope" ? editor.value : editor.getData();

    newForm = Object.assign({}, form, {
      [name]: value
    });

    const newRules = actions.getValidation(newForm, FEGSQRules);
    const newState = validateField(newRules, form, name, value);

    updateForm(index, newState, forms);
  }

  getErrorFor(field) {
    const { getFormByTabIndex, data } = this.props;

    const { forms } = data || {};
    const form = getFormByTabIndex(0, forms); // validate index 0 only

    return form ? getError(field, form, form) : "";
  }

  handleSave(data) {
    if ((data.validationErrors || {}).scope) {
      setTimeout(() => {
        ToastHelper.error({
          title: "Error",
          description: data.validationErrors.scope
        });
      }, 100);
      return false;
    }
    this.props.handleAssumptionModal(false);
  }

  handleCancel() {
    // validate only scope field
    this.handleChange("scope", null, null, true);
    this.props.handleAssumptionModal(false);
  }

  render() {
    const { isShowAssumptionModal, data } = this.props;

    // will used on creation, display the global assumption per sub product
    let assumption = AssumptionValue(data, null, false, false);
    let cancellation = CancellationValue(data);
    let index = data.forms.findIndex((form) => form.tabIndex === 0); // update form index 0 only since assumption/scope will be the same to all sites

    return (
      <Modal open={isShowAssumptionModal}>
        <Modal.Header className="center">Edit Assumption</Modal.Header>
        <Modal.Content scrolling>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Form>
                  <h4 className="center">Assumption</h4>
                  <CKEditor
                    name="assumptions"
                    editor={ClassicEditor}
                    data={data.forms[index].assumptions || assumption || ""}
                    config={{
                      toolbar: [
                        "heading",
                        "|",
                        "bold",
                        "italic",
                        "bulletedList",
                        "numberedList",
                        "|",
                        "undo",
                        "redo"
                      ]
                    }}
                    onChange={this.handleChange.bind(this, "assumptions")}
                  />
                  <h4 className="center">Cancellation</h4>
                  <CKEditor
                    name="cancellation"
                    editor={ClassicEditor}
                    data={data.forms[index].cancellation || cancellation || ""}
                    config={{
                      toolbar: [
                        "heading",
                        "|",
                        "bold",
                        "italic",
                        "bulletedList",
                        "numberedList",
                        "|",
                        "undo",
                        "redo"
                      ]
                    }}
                    onChange={this.handleChange.bind(this, "cancellation")}
                  />
                  <h4 className="center">Project Scope</h4>
                  <Form.Field
                    control={Input}
                    name="scope"
                    onChange={this.handleChange.bind(this, "scope")}
                    value={data.forms[index].scope || ""}
                    autoComplete="off"
                    error={this.getErrorFor("scope")}
                  ></Form.Field>
                  <span className="eg-note">
                    e.g. Field Evaluation of (1) Panelboard & (2) Switchboard,
                    UL Listed / Modified for Alternate Energy Source
                    Interconnection (2 sites in a Single Visit)
                  </span>
                </Form>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          {/* TODO: On cancel diregard changes */}
          <Button
            primary
            onClick={this.handleSave.bind(this, data.forms[index])}
          >
            Save
          </Button>
          <Button onClick={this.handleCancel.bind(this)}>Cancel</Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

AssumptionModal.propTypes = {
  isShowAssumptionModal: PropTypes.bool.isRequired,
  handleAssumptionModal: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateForm: (idx, form, forms) =>
      dispatch(actions.updateForm(idx, form, forms)),
    getFormByTabIndex: (idx, forms) => actions.getFormByTabIndex(idx, forms)
  };
};

export default connect(null, mapDispatchToProps)(AssumptionModal);
