import { connect } from "react-redux";
import React, { Component, Fragment } from "react";
import {
  Container,
  Segment,
  Table,
  Label,
  Checkbox,
  Grid,
  Button,
  Form,
  Modal,
  Input
} from "semantic-ui-react";

import Header from "./header";
import Acceptance from "./acceptance";
import Role from "../../../../../enums/role";
import { Common } from "../../../../../common";
import Status from "../../../../../enums/status";
import OrderType from "../../../../../enums/order-type";
import ServiceType from "../../../../../enums/service-type";
import ToastHelper from "../../../../../helpers/toast.helper";

// FE
import FePrice from "./price";
import FeScope from "../scope/scope";
import FeAssumption from "../assumption/assumption";
import FeTermsAndConditions from "../t-and-c/t-and-c";
import FeRequirement from "../requirement/requirement";
import FeCancellation from "../cancellation/cancellation";
import AssumptionValue from "../assumption/assumption-value";
import RequirementsValue from "../requirement/requirement-value";
import CancellationValue from "../cancellation/cancellation-value";
import FeServiceAgreement from "../service-agreement/service-agreement";

import * as actions from "../../new-quote-details/new-quote-details.action";
import * as projActions from "../../project-details/project-details.action";
import * as fieldEvaluationActions from "../../../field-evaluation.action";
import { validateField } from "../../../../../validator/validator";
import {
  RDPSLSrules,
  RDFSLSrules,
  RDAHJDrules,
  SDOtherDetailsRules
} from "../../../validation-rules";
import { Redirect, withRouter } from "react-router-dom";

class Quotation extends Component {
  state = {
    isCheckAgree: false,
    isLoading: "",
    openPoNumber: false,
    isShowButton: true
  };

  getFormRule = (key) => {
    let rule = null;
    switch (key) {
      case "prelim":
        rule = RDPSLSrules;
        break;
      case "final":
        rule = RDFSLSrules;
        break;
      case "ahj":
        rule = RDAHJDrules;
        break;
      case "others":
        rule = SDOtherDetailsRules;
    }
    return rule;
  };

  updateSiteDetailsForm = (name, value, key, index) => {
    const {
      updateSiteDetailsForm,
      projectDetails: { siteDetails }
    } = this.props;

    const form = siteDetails[index][key];

    var formRule = this.getFormRule(key);

    validateField(formRule, form, name, value);

    let newForm = Object.assign({}, form, {
      [name]: value
    });

    updateSiteDetailsForm(index, newForm, siteDetails, key);
  };

  handleChange = (e, key, index) => {
    this.updateSiteDetailsForm(e.target.name, e.target.value, key, index);
  };

  handleClickAgreeChecbox = () =>
    this.setState((prevState) => ({ isCheckAgree: !prevState.isCheckAgree }));

  handleTogglePoNumberModal = (isOpen) => {
    this.setState({ openPoNumber: isOpen });
  };

  handleAcceptOrderFromOffered = () => {
    this.setState({ isLoading: "loading" });

    const {
      projectDetails,
      schedulingDetails,
      fieldService: { arrForm, orderDetails }
    } = this.props;

    const sitesCount =
      orderDetails && orderDetails.orderNumber
        ? orderDetails.siteCount
        : Common.getSiteCount();

    const evaluatedSameDay =
      orderDetails && orderDetails.orderNumber
        ? orderDetails.evaluatedSameDay
        : Common.getSameDayVisitFlag();

    var data = {
      fieldEvaluationOrderId: orderDetails.id,
      orderType: OrderType.Order,
      quoteDetails: this.renderPoDetails(projectDetails, sitesCount)
    };

    return this.props.updateOrderType(data).then((s) => {
      var message = `Thank you for your business. Your quote reference number is ${
        s ? s.number : ""
      }.
                  A Project Handler will be in touch with you as soon as
                  possible.`;

      this.handleToastMessage(s, message);
    });
  };

  handleToastMessage(result, message) {
    if (result) {
      if (result.number != "") {
        this.setState({
          refereceNmber: result.number,
          isShowButton: false,
          isLoading: "",
          isSuccess: true
        });

        setTimeout(() => {
          ToastHelper.success({
            description: <p>{message}</p>
          });
        }, 100);
      } else {
        setTimeout(() => {
          ToastHelper.error({
            title: "Something went wrong",
            description: result.message
          });
        }, 100);
      }
    } else {
      this.setState({
        isLoading: "",
        isSuccess: false
      });
      setTimeout(() => {
        ToastHelper.error({
          title: "Something went wrong",
          description: result.message
        });
      }, 100);
    }
  }

  renderFeItem(item, idx, isIncludedSameDay, evaluatedSameDay) {
    const { projectDetails } = this.props;
    let isPHEdit = Common.isPhEditing(item.isManualPricing);

    let data = {
      serviceProductPriceId: item.productPrice.id,
      quantity: parseInt(item.quantity),
      price: item.productPrice.price,
      total: isPHEdit
        ? parseInt(item.totalPriceByPh)
        : item.productPrice.totalPrice + item.productPrice.siteCharge,
      isMinimumFee: false, // FES only
      areAerialsBrontoSchwingMetz: false, // FES only
      hasExistingULLabel: item.isExistingUlEquipment,
      isProductInvolvedInLitigation: item.isInvolvedLitigation,
      isManualPricing: item.isManualPricing,
      litigationDescription: item.isInvolvedLitigation
        ? item.litigationDescription
        : null,
      isInstalledSameLocation: item.isInstalledSameLocation,
      isEvaluatedSameVisit: item.isEvaluatedSameVisit,
      isPanelboardOver200: item.isPanelboard ? item.panelboardOver200 : null,
      isSwitchboardOver600: item.isSwitchboard ? item.switchboardOver600 : null,
      propertyType: item.propertyType,
      additionalInformation: item.additionalInfo,
      isBusinessHours: item.productPrice.isBusinessHours,
      assumptions: AssumptionValue(
        projectDetails,
        null,
        false,
        isIncludedSameDay,
        evaluatedSameDay
      ),
      scope: projectDetails.forms.find((f) => f.tabIndex === 0).scope,
      cancellation: CancellationValue(projectDetails),
      requirementsInspection: RequirementsValue(projectDetails),
      tabIndex: idx
    };
    return data;
  }

  handleRenderScheduleDate = (date) => {
    if (date) {
      var date = new Date(date);
      var day = date.getDate();
      var month = date.getMonth() + 1;
      var year = date.getFullYear();
      var hour = date.getHours();
      var minute = date.getMinutes();
      var second = date.getSeconds();

      var time =
        month +
        "/" +
        day +
        "/" +
        year +
        " " +
        hour +
        ":" +
        minute +
        ":" +
        second;

      return time;
    }

    return null;
  };

  renderPoDetails = (projectDetails, sitesCount) => {
    var quoteDetails = [];
    for (let i = 0; i < sitesCount; i++) {
      var siteDetails = projectDetails.siteDetails[i]
        ? projectDetails.siteDetails[i]
        : {};

      var quoteAcceptance = null;

      quoteAcceptance = {
        purchaseOrderNumber: siteDetails.others.purchaseOrderNumber
      };

      quoteDetails.push({
        quoteAcceptance: quoteAcceptance,
        tabIndex: i
      });
    }

    return quoteDetails;
  };

  renderQuoteDetails = (
    projectDetails,
    arrForm,
    schedulingDetails,
    sitesCount,
    isIncludedSameDay,
    evaluatedSameDay
  ) => {
    var quoteDetails = [];

    for (let i = 0; i < sitesCount; i++) {
      var items =
        projectDetails.forms.length > 0
          ? projectDetails.forms.filter((p) => p.tabIndex == i)[0]
          : {};
      var reservationDetails = schedulingDetails.schedulingForms[i]
        ? schedulingDetails.schedulingForms[i]
        : {};

      var siteDetails = projectDetails.siteDetails[i]
        ? projectDetails.siteDetails[i]
        : {};

      var { otherDetails, productDetails } = arrForm[i];

      let notes = {
        note: (siteDetails.others || {}).specialNotes
      };
      productDetails.specialNotes = notes.note ? [notes] : null;

      var sites = [],
        engineers = [],
        ahjs = [],
        productItems = [],
        quoteAcceptance = null;

      quoteAcceptance = {
        purchaseOrderNumber: siteDetails.others.purchaseOrderNumber,
        customerReferenceNumber: siteDetails.others.customerReferenceNumber,
        isReadyToScheduleNow: siteDetails.others.isReadyToScheduleNow,
        preferredScheduleDate: this.handleRenderScheduleDate(
          reservationDetails.preliminarySiteLocationSection.scheduleDate
        )
      };

      siteDetails.prelim.siteType = 1;
      siteDetails.final.siteType = 2;

      sites.push(siteDetails.prelim);
      sites.push(siteDetails.final);

      reservationDetails.preliminarySiteLocationSection.siteType = 1;
      reservationDetails.preliminarySiteLocationSection.scheduleUnixTime = reservationDetails
        .preliminarySiteLocationSection.scheduleDate
        ? reservationDetails.preliminarySiteLocationSection.scheduleDate.getTime() /
          1000
        : null;
      reservationDetails.finalSiteLocationSection.siteType = 2;

      engineers.push(reservationDetails.preliminarySiteLocationSection);
      engineers.push(reservationDetails.finalSiteLocationSection);

      siteDetails.ahj.baseType = 1;
      reservationDetails.authorityHavingJurisdictionDetails.baseType = 2;

      ahjs.push(siteDetails.ahj);
      ahjs.push(reservationDetails.authorityHavingJurisdictionDetails);

      productItems.push(
        this.renderFeItem(items, i, isIncludedSameDay, evaluatedSameDay)
      );

      quoteDetails.push({
        quoteAcceptance: quoteAcceptance,
        productItems: productItems,
        sites: sites,
        products: productDetails,
        engineers: engineers,
        authorityJurisdictions: ahjs,
        tabIndex: i
      });
    }

    return quoteDetails;
  };

  handleSubmit(status) {
    this.setState({ isLoading: "loading" });
    const {
      projectDetails,
      schedulingDetails,
      fieldService: { arrForm, orderDetails },
      createFieldServiceOrder,
      updateFieldServiceOrder,
      accountDetails
    } = this.props;

    const sitesCount =
      orderDetails && orderDetails.orderNumber
        ? orderDetails.siteCount
        : Common.getSiteCount();

    const evaluatedSameDay =
      orderDetails && orderDetails.orderNumber
        ? orderDetails.evaluatedSameDay
        : Common.getSameDayVisitFlag();

    let userRole = Common.getUserRole();
    let redirectTo =
      userRole === Role.projectHandler
        ? "/home/view-assigned"
        : "/home/view-history";

    var model = {
      requestNumber: (orderDetails && orderDetails.orderNumber) || null,
      siteCount: sitesCount,
      status: status,
      orderType: OrderType.Quote,
      serviceType: ServiceType.fe,
      quoteDetails: this.renderQuoteDetails(
        projectDetails,
        arrForm,
        schedulingDetails,
        sitesCount,
        (orderDetails || {}).evaluatedSameDay === undefined,
        evaluatedSameDay
      ),
      createdForUserId:
        accountDetails.form.applicantDetails.applicantDetails.userId ||
        orderDetails.createdForUserId,
      evaluatedSameDay: evaluatedSameDay
    };

    let isPHEdit = Common.isPhEditing(
      projectDetails.forms.some((form) => form["isManualPricing"] === true)
    );
    if (isPHEdit) {
      model.isEditedByPh = true;
    }

    if (orderDetails && orderDetails.orderNumber) {
      updateFieldServiceOrder(model).then((result) => {
        var message = `Save Changes Successfully. Your quote number is ${
          result ? result.number : ""
        }.`;

        this.handleUploadAttachments(projectDetails.forms, result);
        this.handleToastMessage(result, message);
        return this.props.history.push(redirectTo);
      });
    } else {
      createFieldServiceOrder(model).then((s) => {
        var message = `Thank you for your business. Your quote reference number is ${
          s ? s.number : ""
        }.
                  A Project Handler will be in touch with you as soon as
                  possible.`;

        this.handleUploadAttachments(projectDetails.forms, s);
        this.handleToastMessage(s, message);
        return this.props.history.push(redirectTo);
      });
    }
  }

  handleUploadAttachments(attachments, result) {
    for (var i = 0; i < attachments.length; i++) {
      var data = {
        fileName: attachments[i]["fileName"],
        artworkLink: attachments[i]["artworkLink"],
        tabIndex: attachments[i]["tabIndex"],
        requestNumber: result ? result.number : ""
      };
      this.props.uploadMultiSiteAttachment(data);
    }
  }

  render() {
    const { forms, siteDetails } = this.props.projectDetails;

    const { isCheckAgree, isLoading, openPoNumber, isShowButton } = this.state;
    const { isSuccess } = this.props;
    const { otherDetails } = this.props.fieldService.form;

    let userRole = Common.getUserRole();
    let siteHasManualPricing = forms.some(
      (form) => form["isManualPricing"] === true
    );
    let isPHEdit = Common.isPhEditing(siteHasManualPricing);

    const netTotal = `$
                    ${Number.format(
                      forms.reduce(
                        (c, n) =>
                          c +
                          (n.totalPriceByPh
                            ? parseInt(n.totalPriceByPh)
                            : (n.productPrice || {}).totalPrice) +
                          (isPHEdit
                            ? 0
                            : (n.productPrice || {}).siteCharge || 0),
                        0
                      ),
                      2
                    )} USD`;

    return (
      <div className="container-wrapper">
        <Container fluid>
          <Segment>
            <Header />
            <Grid>
              <Grid.Row>
                <Grid.Column width={4}>
                  <Segment>
                    <Label color="blue" ribbon>
                      NET TOTAL
                    </Label>
                    <Label basic color="blue" size="large">
                      {netTotal}
                    </Label>
                  </Segment>
                </Grid.Column>
              </Grid.Row>
            </Grid>

            <Table attached="top" basic="very">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell textAlign="center" colSpan="1">
                    <h2>QUOTATION</h2>
                    <p>
                      This Quotation is provided by or on behalf of UL LLC. We
                      are pleased to provide you with the following Quotation
                      and we will make every effort to exceed your expectations
                      as we fulfill this work.
                    </p>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
            </Table>

            <FeScope forms={forms} />
            <FePrice forms={forms} netTotal={netTotal} />
            <FeAssumption />
            <FeCancellation />
            <FeRequirement />
            <FeTermsAndConditions />
            <FeServiceAgreement />

            {userRole != Role.projectHandler ? (
              <div>
                <div id="checkbox-section">
                  <Table attached>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell textAlign="center">
                          <Checkbox
                            checked={isCheckAgree}
                            onChange={this.handleClickAgreeChecbox.bind()}
                            label={
                              <label>
                                <b>
                                  I have read and accept the “Terms and
                                  Conditions” to
                                  {/* TODO: Change condition per service type not by role*/}
                                  {userRole == Role.fireEquipment
                                    ? " “Fire Equipment Services Terms For Inspection Of In-Service Automotive Fire Apparatus And Equipment Service Terms”"
                                    : " “Field Evaluations Services”"}
                                </b>
                              </label>
                            }
                          />
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                  </Table>
                </div>

                <div id="acceptance-section">
                  <Acceptance />
                </div>
              </div>
            ) : null}
            <br />
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <Form>
                    <Form.Field>
                      {isCheckAgree ? (
                        isShowButton ? (
                          <Button
                            className={isLoading}
                            primary
                            onClick={this.handleTogglePoNumberModal.bind(
                              this,
                              true
                            )}
                          >
                            Accept
                          </Button>
                        ) : null
                      ) : null}
                      {!otherDetails.isFromOffered ? (
                        <Button
                          className={isLoading}
                          primary
                          onClick={this.handleSubmit.bind(this, Status.offered)}
                        >
                          {userRole != Role.projectHandler
                            ? "Save"
                            : "Offer Quote to Customer"}
                        </Button>
                      ) : null}
                    </Form.Field>
                  </Form>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Container>
        <Modal open={openPoNumber} size="mini">
          <Modal.Header className="ui center aligned">
            Enter Purchase Order Number
          </Modal.Header>
          <Modal.Content scrolling>
            <table>
              <tbody>
                {siteDetails.map((item, idx) => {
                  return (
                    <tr key={idx}>
                      <td>{`Site ${idx + 1}`} PO Number</td>
                      <td>
                        <Form.Field
                          control={Input}
                          placeholder="Enter PO Number"
                          name="purchaseOrderNumber"
                          onChange={(e) => {
                            this.handleChange(e, "others", idx);
                          }}
                          value={item.others.purchaseOrderNumber || ""}
                          autoComplete="off"
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Modal.Content>
          <Modal.Actions>
            <Button
              primary
              onClick={() => {
                otherDetails.isFromOffered
                  ? this.handleAcceptOrderFromOffered()
                  : this.handleSubmit(Status.accepted);
                this.handleTogglePoNumberModal(false);
              }}
            >
              Submit
            </Button>

            <Button
              primary
              basic
              onClick={() => {
                this.handleTogglePoNumberModal(false);
              }}
            >
              Cancel
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    projectDetails: state.feProjectDetails,
    fieldService: state.fieldService,
    schedulingDetails: state.schedulingDetails,
    isSuccess: state.fieldService.isSuccess,
    accountDetails: state.accountSettings
  };
};

const mapDispatchToProps = (dispatch) => ({
  createFieldServiceOrder: (data) =>
    dispatch(actions.createFieldServiceOrder(data)),
  updateFieldServiceOrder: (data) =>
    dispatch(actions.updateFieldServiceOrder(data)),
  updateSiteDetailsForm: (idx, form, forms, key) =>
    dispatch(projActions.updateSiteDetailsForm(idx, form, forms, key)),
  uploadMultiSiteAttachment: (attachments) =>
    dispatch(fieldEvaluationActions.uploadMultiSiteAttachment(attachments)),
  clearFEOrder: () => dispatch(actions.clearFEOrder()),
  updateOrderType: (param) => dispatch(actions.updateOrderType(param))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Quotation));
