import React, { Fragment, Component } from "react";
import { Header, Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Segment } from "semantic-ui-react";
import { changeLanguage } from "../../actions/language/language";
import { Common } from "../../common";
import * as commonActions from "../../views/user/common/common.action";

class HeaderBlock extends Component {
  state = { initialName: "", showIcon: false, isLoad: false };

  componentDidMount() {
    let name = Common.getInitialName();

    this.setState({
      initialName: Common.getInitialName(),
      showIcon: name == "" ? false : true
    });
  }

  handleChange(value) {
    this.props.dispatch(changeLanguage(value));
  }

  onClickUser() {
    this.props.history.push("/home/user-profile");
  }

  translatePageName = (activePage) => {
    const translations = Common.getTranslations() || {};
    let translatedName = "";
    switch (activePage) {
      case "Help Me":
        translatedName = translations["LexHelpMe"];
        break;
      default:
        translatedName = activePage;
        break;
    }
    return translatedName;
  };

  render() {
    const { initialName } = this.state;
    const { standardLabelsCart } = this.props;

    let cartLabelOrderType = localStorage.getItem("cartLabelOrderType");
    let activePage = Common.getActivePage();
    const translatedPageName = this.translatePageName(this.props.activePage);

    return (
      <Fragment>
        <Segment clearing className="com-header-container print-hidden" basic>
          <Header as="h3" floated="left" className="header">
            <table style={{ color: "gray" }}>
              <tr style={{ fontSize: "16px" }}>
                {/* {" "}
                <Image
                  className="image"
                  src={imageUrl}
                  size="small"
                  as={Link}
                  to="/home"
                /> */}
                Field Services
              </tr>
              <tr>
                <label style={{ fontSize: "30px", fontWeight: "bold" }}>
                  {translatedPageName}
                </label>
              </tr>
            </table>
          </Header>
          {false ? (
            <Icon
              className="initial"
              size="large"
              circular
              inverted
              color="red"
              link
              onClick={this.onClickUser.bind(this)}
            >
              {initialName}
            </Icon>
          ) : null}
          {/* <Dropdown
            button
            className="icon language"
            labeled
            icon="world"
            options={LANGUAGE_OPTIONS}
            onChange={(e, { value }) => {
              this.handleChange(value);
            }}
            text={this.props.text}
            value={this.props.value}
          /> */}
        </Segment>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  hasError: state.language.hasError,
  message: state.language.message,
  loading: state.language.loading,
  text: state.language.text,
  value: state.language.value,
  activePage: state.user.common.activePage
});

const mapDispatchToProps = (dispatch) => ({
  getActivePage: (page) => dispatch(commonActions.getActivePage(page))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(HeaderBlock));
