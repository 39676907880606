export const GET_CART_ORDERS_REQUEST = "GET_CART_ORDERS_REQUEST";
export const GET_CART_ORDERS_SUCCESS = "GET_CART_ORDERS_SUCCESS";
export const GET_CART_ORDERS_FAILURE = "GET_CART_ORDERS_FAILURE";

export const CREATE_MESSAGE_REQUEST = "CREATE_MESSAGE_REQUEST";
export const CREATE_MESSAGE_SUCCESS = "CREATE_MESSAGE_SUCCESS";
export const CREATE_MESSAGE_FAILURE = "CREATE_MESSAGE_FAILURE";

export const CART_MESSAGE_REQUEST = "CART_MESSAGE_REQUEST";
export const CART_MESSAGE_SUCCESS = "CART_MESSAGE_SUCCESS";
export const CART_MESSAGE_FAILURE = "CART_MESSAGE_FAILURE";

export const ADD_MESSAGE = "ADD_MESSAGE";

export const GET_UNREAD_MESSAGE_REQUEST = "GET_UNREAD_MESSAGE_REQUEST";
export const GET_UNREAD_MESSAGE_SUCCESS = "GET_UNREAD_MESSAGE_SUCCESS";
export const GET_UNREAD_MESSAGE_FAILURE = "GET_UNREAD_MESSAGE_FAILURE";

export const UPDATE_IS_VIEWED_REQUEST = "UPDATE_IS_VIEWED_REQUEST";
export const UPDATE_IS_VIEWED_SUCCESS = "UPDATE_IS_VIEWED_SUCCESS";
export const UPDATE_IS_VIEWED_FAILURE = "UPDATE_IS_VIEWED_FAILURE";

export const HELP_ME_FORM = "HELP_ME_FORM";
export const HELP_ME_CLEAR = "HELP_ME_CLEAR";
export const CLEAR_FORM_ONLY = "CLEAR_FORM_ONLY";

export const GET_LABAEL_CENTER_REQUEST = "GET_LABAEL_CENTER_REQUEST";
export const GET_LABAEL_CENTER_SUCCESS = "GET_LABAEL_CENTER_SUCCESS";
export const GET_LABAEL_CENTER_FAILURE = "GET_LABAEL_CENTER_FAILURE";

export const GET_HELPME_TOTAL_REQUEST_REQUEST =
  "GET_HELPME_TOTAL_REQUEST_REQUEST";
export const GET_HELPME_TOTAL_REQUEST_SUCCESS =
  "GET_HELPME_TOTAL_REQUEST_SUCCESS";
export const GET_HELPME_TOTAL_REQUEST_FAILURE =
  "GET_HELPME_TOTAL_REQUEST_FAILURE";
