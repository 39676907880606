import { requests } from '../request-factory';
import { mapDataToQueryString } from '../request-helpers';

const partySiteNumberService = {
  getPartySiteNumberDetails: partySiteNumber =>
    requests.get(`party-site-number/details/${partySiteNumber}`),
  getPsnAssignments: partySiteNumbers =>
    requests.get(
      `party-site-number/assignments?${mapDataToQueryString({
        partySiteNumbers,
      })}`
    ),
};

export default partySiteNumberService;
