import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "./fe-admin-search.action";
import {
  Container,
  Divider,
  Grid,
  Form,
  Input,
  Button,
  Table,
  Pagination,
  Message
} from "semantic-ui-react";
import { CustomHeaderLabel } from "../../../components/labels/labels";
import FeAdminSearchItem from "./fe-admin-search-item";

class FeAdminSearch extends Component {
  state = {
    quoteNumber: null,
    accountNumber: null
  };

  componentDidMount() {
    const { currentPage, pageSize } = this.props;

    this.props.getAllFeOrders(currentPage, pageSize, null, null);
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSearchOrders = () => {
    const { quoteNumber, accountNumber } = this.state;
    const { currentPage, pageSize } = this.props;

    this.props.getAllFeOrders(
      currentPage,
      pageSize,
      accountNumber,
      quoteNumber
    );
  };
  render() {
    const { orders, totalPages, currentPage, pageSize, isLoading } = this.props;
    const { quoteNumber, accountNumber } = this.state;
    return (
      <div style={{ width: "80%", padding: "20px" }}>
        <Container>
          <CustomHeaderLabel message="ADMIN SEARCH" />
          <Divider />
          <Grid>
            <Grid.Row columns="3">
              <Grid.Column>
                <Form>
                  <Form.Field
                    control={Input}
                    label="Quote Number"
                    placeholder="Enter Quote Number"
                    name="quoteNumber"
                    onChange={(e) => {
                      this.handleChange(e);
                    }}
                    value={quoteNumber}
                    autoComplete="off"
                  />
                </Form>
              </Grid.Column>
              <Grid.Column>
                <Form>
                  <Form.Field
                    control={Input}
                    label="Account Number"
                    placeholder="Enter Account Number"
                    name="accountNumber"
                    onChange={(e) => {
                      this.handleChange(e);
                    }}
                    value={accountNumber}
                    autoComplete="off"
                  />
                </Form>
              </Grid.Column>
              <Grid.Column verticalAlign="bottom">
                <Button primary onClick={this.handleSearchOrders}>
                  Search
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Divider hidden />
          <Form loading={isLoading}>
            <Table striped structured celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell textAlign="center">
                    Project Handler
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="center">Status</Table.HeaderCell>
                  <Table.HeaderCell textAlign="center">
                    Quote #{" "}
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="center">
                    Account #{" "}
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="center">
                    Creation date
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="center">
                    Customer Reference #
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="center">
                    Installation Site
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="center">Scope</Table.HeaderCell>
                  <Table.HeaderCell textAlign="center">
                    Expiration Date
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="center">
                    Last Activity Date
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {orders &&
                  orders.map((data, idx) => <FeAdminSearchItem data={data} />)}
              </Table.Body>
              <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell colSpan="10">
                    {orders && totalPages > 0 ? (
                      <Pagination
                        floated="right"
                        activePage={currentPage}
                        totalPages={totalPages}
                        onPageChange={(e, { activePage }) => {
                          this.props.getAllFeOrders(
                            activePage,
                            pageSize,
                            accountNumber,
                            quoteNumber
                          );
                        }}
                      />
                    ) : (
                      <Message>No Order Found</Message>
                    )}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          </Form>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentPage: state.feAdminSearch.currentPage,
    pageSize: state.feAdminSearch.pageSize,
    totalPages: state.feAdminSearch.totalPages,
    isLoading: state.feAdminSearch.isLoading,
    orders: state.feAdminSearch.data
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // getAllFeOrders: (currentPage, pageSize, accountNumber, requestNumber) =>
    //   dispatch(
    //     actions.getAllFeOrders(
    //       currentPage,
    //       pageSize,
    //       accountNumber,
    //       requestNumber
    //     )
    //   ),
    getAllFeOrders: (currentPage, pageSize, accountNumber, requestNumber) =>
      dispatch(
        actions.getAllFeOrdersAdminSearch(
          currentPage,
          pageSize,
          accountNumber,
          requestNumber,
          "true"
        )
      )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FeAdminSearch);
