import { requests } from '../request-factory';

const authService = {
  login: (email, password) => requests.post('auth', { email, password }),
  validateAuthenticationCode: (authenticationCode, codeVerifier) =>
    requests.post('auth/validate-code', { authenticationCode, codeVerifier }),
  loginExternal: () => requests.get('auth', {}),
  logoutExternal: () => requests.get('auth/logout', {}),
  validateLogin: () => requests.get('user/isloggedin'),
};

export default authService;
