import PropTypes from "prop-types";
import { connect } from "react-redux";
import React, { Component } from "react";
import { Table } from "semantic-ui-react";

import * as actions from "../../project-details/project-details.action";

class PriceItem extends Component {
  componentDidMount() {
    const {
      form,
      index,
      updatePriceForm,
      projectDetails: { forms },
      schedulingDetails: { schedulingForms }
    } = this.props;

    const { preliminarySiteLocationSection } = schedulingForms[index];

    let scheduleDate = preliminarySiteLocationSection.scheduleDate
      ? new Date(
          preliminarySiteLocationSection.scheduleDate
        ).toDateStringFormat()
      : "0"; // not ready to schedule

    this.props
      .getServiceProductPrice(
        0, // TODO: implement on different zoning
        form.serviceSubProductId,
        form.propertyType,
        parseInt(form.quantity),
        scheduleDate,
        index // siteCharge: if > 0 add charge, index 0 has no charge
      )
      .then((s) => {
        const item = forms[index];
        const newForm = Object.assign({}, item, {
          productPrice: (s || {}).data
        });

        setTimeout(() => updatePriceForm(index, newForm), 200);
      });
  }
  render() {
    const {
      index,
      form,
      projectDetails: { siteDetails }
    } = this.props;

    let details = siteDetails.length ? siteDetails[index].final : {};

    return (
      <Table.Row>
        <Table.Cell>1</Table.Cell>
        <Table.Cell>{`${(details || {}).residenceName} - ${
          (details || {}).city
        }, ${(details || {}).state}`}</Table.Cell>
        <Table.Cell>{form.serviceProductName}</Table.Cell>
        <Table.Cell>{form.serviceSubProductName}</Table.Cell>
        <Table.Cell>{form.quantity}</Table.Cell>
      </Table.Row>
    );
  }
}

PriceItem.propTypes = {
  form: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  return {
    projectDetails: state.feProjectDetails,
    schedulingDetails: state.schedulingDetails
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getServiceProductPrice: (
      serviceAreaId,
      serviceSubProductId,
      propertyType,
      quantity,
      scheduleDate,
      siteCharge
    ) =>
      dispatch(
        actions.getServiceProductPrice(
          serviceAreaId,
          serviceSubProductId,
          propertyType,
          quantity,
          scheduleDate,
          siteCharge
        )
      ),
    updatePriceForm: (idx, form, forms) =>
      dispatch(actions.updatePriceForm(idx, form, forms))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PriceItem);
