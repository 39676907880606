import React, { Component } from "react";
import { Table } from "semantic-ui-react";
import { connect } from "react-redux";
import RequirementsValue from "./requirements-value";

class Requirements extends Component {
  createMarkup(string) {
    return { __html: string };
  }

  render() {
    const { productDetails } = this.props;
    let requirements = RequirementsValue(productDetails);
    return (
      <Table attached>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell textAlign="center">
              IMPORTANT REQUIREMENTS FOR YOUR INSPECTION
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              {
                <span
                  dangerouslySetInnerHTML={this.createMarkup(requirements)}
                ></span>
              }
              {/*               
              <ul>
                <li>
                  <b>Electrical Requirements:</b> Please note the equipment must
                  be de-energized to conduct the field evaluation. Further, if
                  the evaluation includes the main (service) disconnect or any
                  equipment located on the line side of the main disconnect on
                  service equipment, a utility shutdown of the equipment must be
                  arranged to complete the evaluation.
                </li>
                <Divider hidden />
                <li>
                  <b>Critical Component Requirements:</b> All critical
                  components (such as Insulation Piercing Connectors, Lugs,
                  Crimp Connectors, Terminal Blocks, Polaris Connectors, or
                  Breaker Taps) must be UL Certified and installed in accordance
                  with the installation instructions and if Recognized meet any
                  Conditions of Acceptability.
                </li>
                <Divider hidden />
                <li>
                  <b>Equipment Requirements:</b> A calibrated torque wrench that
                  bears a valid certification or sticker (within the last twelve
                  months) with manufacture name and serial number must be
                  available on site during the day of evaluation.
                </li>
                <Divider hidden />
                <li>
                  <b>Site Access Requirements:</b> Refer to the “Field
                  Evaluation Service Terms” Section for further details.
                </li>
                <Divider hidden />
                <li>
                  <b>Other:</b> Unless specifically identified in the scope of
                  work, the UL inspection will not include any (AE) panel(s) or
                  other (AE) source, DC conductor, DC disconnect, inverter,
                  raceways (AC or DC), AC disconnect, overcurrent protection of
                  (AE) system, grounding or bonding of (AE) components, (AE)
                  system, or raceways. These items are typically the
                  responsibility of the local inspection authority (AHJ) to
                  review.
                </li>
              </ul>
             */}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    productDetails: state.feProductDetails
  };
};

export default connect(mapStateToProps, null)(Requirements);
