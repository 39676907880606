const businessHrs = `<p>All work will be completed during normal working hours (6:00 AM and
            6:00 PM, Monday through Friday excluding holidays). If your project
            requires work outside of normal working hours an additional cost may
            be incurred.
            </p>`;

const outsideBusinenessHrs = `<p>This quote includes costs associated with work conducted outside of
            normal working hours (Monday through Friday from 6:00 AM through
            6:00 PM, excluding holidays).
            </p>`;

const assumptionValue = (data, fromViewOrderData, isIncludedBusinessHrs) => {
  // data: from new & edit
  // fromViewOrderData: from vire order
  // isIncludedBusinessHrs: for viewing, include working hrs wording, on saving/updating: don't include to avoid multiple entry

  let assumptionValue = "";
  if (data && data.form) {
    // saved data from product item or from form changes
    if (data.form.assumptions) {
      assumptionValue = data.form.assumptions;
    } else {
      // Global/Fixed assumption per sub product
      let assumption = data.serviceSubProduct.data
        ? data.serviceSubProduct.data.filter(
            (s) => s.id == data.form.serviceSubProductId
          )
        : "";
      assumptionValue = assumption.length
        ? assumption[0].assumption.details
        : "";
    }
  }

  let fromViewBusinessHrs;
  if (fromViewOrderData) {
    if (fromViewOrderData.length > 0) {
      assumptionValue = fromViewOrderData[0].assumptions;
      fromViewBusinessHrs = fromViewOrderData[0].isBusinessHours;
    }
  }

  return `${assumptionValue} ${
    isIncludedBusinessHrs
      ? !(
          (data &&
            data.productPrice &&
            ((data.productPrice || {}).data || {}).isBusinessHours) ||
          fromViewBusinessHrs
        )
        ? outsideBusinenessHrs
        : businessHrs
      : ""
  }`;
};

export default assumptionValue;
