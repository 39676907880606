import React, { Component, Fragment, Link } from "react";
import {
  Segment,
  Grid,
  Button,
  Divider,
  Table,
  Popup,
  Icon,
  Modal,
  Form,
  Header,
  Message
} from "semantic-ui-react";
import { renderDisc } from "../../helpers/component-helper";
import {
  CustomLabel,
  SubText,
  CustomHeaderBold
} from "../../components/labels/labels";
import Role from "../../enums/role";
import { Common } from "../../common";

import { covertUnixTimeToDate } from "../../helpers/component-helper";

class LabelOrderWorkflow extends Component {
  state = {
    openUpdateOracleNo: false,
    openOracleWarning: false
  };

  handleViewPackingSlip = () => {
    var { data } = this.props;

    data.packingSlips.map((item) => {
      var a = document.createElement("a");
      a.style = "display: none";
      a.href = item;
      a.target = "_blank";
      a.click();
      window.URL.revokeObjectURL(item);
    });
  };

  handleViewAuthorizationLetter = () => {
    var { data } = this.props;

    data.packingSlips.map((item) => {
      var a = document.createElement("a");
      a.style = "display: none";
      a.href = item;
      a.target = "_blank";
      a.click();
      window.URL.revokeObjectURL(item);
    });
  };

  handleYes = () => {
    this.setState({ openOracleWarning: false });
    this.setState({ openUpdateOracleNo: true });
  };

  handleNo = () => {
    this.setState({ openUpdateOracleNo: false });
  };

  handleWarningYes = () => {
    this.setState({ openOracleWarning: true });
  };

  handleWarningNo = () => {
    this.setState({ openOracleWarning: false });
  };

  render() {
    var {
      data,
      isEmailCopySuccess,
      emailAddress,
      isLoadingEmailCopy,
      isSuccessUpdateOracleNumber,
      isLoadingUpdateOracleNumber,
      oracleNumber
    } = this.props;

    const translations = Common.getTranslations() || {};

    var { openUpdateOracleNo, openOracleWarning } = this.state;

    const roleId = Common.getUserRole();
    var orderNo = null;
    var oracleMessage = null;

    if (roleId == Role.applicant || roleId == Role.manufacturer) {
      orderNo = data.oracleNumber
        ? data.oracleNumber
        : data.labelOrderType == 1
        ? ""
        : "";
    } else if (roleId == Role.labelCenter || roleId == Role.labelManager) {
      orderNo = data.oracleNumber ? data.oracleNumber : data.oracleMessage;
      oracleMessage = data.oracleMessage;
    }

    var activeStatus =
      data.labelOrderInfoHistories &&
      data.labelOrderInfoHistories.length > 0 &&
      data.labelOrderInfoHistories.filter((i) => i.isActive === true)[0];

    var bubbleStatus = data.bubbleStatus;

    var courierTrackingNumberList = data.items
      .filter((x) => x.fileNumber == data.fileNumber)
      .map((item) => {
        return item.courierTrackingNumberList;
      });

    courierTrackingNumberList = courierTrackingNumberList.filter(
      (c) => c.length > 0
    );

    var couriers = [];
    courierTrackingNumberList &&
      courierTrackingNumberList.forEach((c) => {
        {
          c.forEach((item) => {
            couriers.push(item.courier);
          });
        }
      });

    couriers = [...new Set(couriers)].join(",");

    var courierList = `${
      data && data.courier ? data.courier + ", " : ""
    } ${couriers}`.replace(/,\s*$/, "");

    var trackingNumbers = [];
    courierTrackingNumberList &&
      courierTrackingNumberList.forEach((c) => {
        {
          c.forEach((item) => {
            trackingNumbers.push(item.trackingNumber);
          });
        }
      });

    trackingNumbers = [...new Set(trackingNumbers)].join(",");

    var trackingNumberList = `${
      data && data.trackingNumber ? data.trackingNumber + ", " : ""
    } ${trackingNumbers}`.replace(/,\s*$/, "");

    return (
      <Fragment>
        <Grid style={{ padding: "25px" }}>
          <Grid.Row>
            <Grid.Column>
              {translations["LexOrderUpdatedDate"].replace(
                "{0}",
                activeStatus.formattedUpdatedDate || ""
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column style={{ borderRight: "2px solid #EBEBEB" }} width="3">
              <Divider hidden />
              {data.labelOrderStatus === 4 ? (
                <div class="progress">
                  <div
                    class={
                      data.labelOrderStatus === 19
                        ? "circle active"
                        : "circle done"
                    }
                  >
                    {" "}
                    <span class="label">
                      {data.labelOrderStatus === 19 ? (
                        <Icon
                          name="small check"
                          style={{ paddingLeft: "3px" }}
                        />
                      ) : (
                        ""
                      )}
                    </span>
                    <span class="title">
                      {" "}
                      {Common.translateStatus("Submitted", translations)}
                    </span>
                  </div>{" "}
                  <span class="bar active"></span>
                  <div
                    class={
                      data.labelOrderStatus === 4
                        ? "circle active"
                        : "circle done"
                    }
                  >
                    {" "}
                    <span class="label">
                      {data.labelOrderStatus === 4 ? (
                        <Icon
                          name="small check"
                          style={{ paddingLeft: "3px" }}
                        />
                      ) : (
                        ""
                      )}
                    </span>
                    <span class="title">
                      {" "}
                      {Common.translateStatus("Cancelled", translations)}
                    </span>
                  </div>
                </div>
              ) : bubbleStatus === 4 ? (
                <div class="progress">
                  <div
                    class={
                      bubbleStatus === 19 ? "circle active" : "circle done"
                    }
                  >
                    {" "}
                    <span class="label">
                      {bubbleStatus === 19 ? (
                        <Icon
                          name="small check"
                          style={{ paddingLeft: "3px" }}
                        />
                      ) : (
                        ""
                      )}
                    </span>
                    <span class="title">
                      {" "}
                      {Common.translateStatus("Submitted", translations)}
                    </span>
                  </div>{" "}
                  <span class="bar active"></span>
                  <div
                    class={bubbleStatus === 4 ? "circle active" : "circle done"}
                  >
                    {" "}
                    <span class="label">
                      {bubbleStatus === 4 ? (
                        <Icon
                          name="small check"
                          style={{ paddingLeft: "3px" }}
                        />
                      ) : (
                        ""
                      )}
                    </span>
                    <span class="title">
                      {" "}
                      {Common.translateStatus("Cancelled", translations)}
                    </span>
                  </div>
                </div>
              ) : (
                <div class="progress">
                  <div
                    class={
                      bubbleStatus === 19 ? "circle active" : "circle done"
                    }
                  >
                    {" "}
                    <span class="label">
                      {bubbleStatus === 19 ? (
                        <Icon
                          name="small check"
                          style={{ paddingLeft: "3px" }}
                        />
                      ) : (
                        ""
                      )}
                    </span>
                    <span class="title">
                      {" "}
                      {Common.translateStatus("Submitted", translations)}
                    </span>
                  </div>{" "}
                  <span class="bar active"></span>
                  <div
                    class={bubbleStatus === 2 ? "circle active" : "circle done"}
                  >
                    {" "}
                    <span class="label">
                      {bubbleStatus === 2 ? (
                        <Icon
                          name="small check"
                          style={{ paddingLeft: "3px" }}
                        />
                      ) : (
                        ""
                      )}
                    </span>
                    <span class="title">
                      {Common.translateStatus("In Progress", translations)}
                      {bubbleStatus === 2 &&
                      data.packingSlips.length > 0 &&
                      data.labelOrderType == 2 ? (
                        <label>
                          {translations["LexVendorletterCompleted"]}
                        </label>
                      ) : null}
                    </span>
                  </div>{" "}
                  <span class="bar active"></span>
                  <div
                    class={
                      bubbleStatus === 21 ? "circle active" : "circle done"
                    }
                  >
                    {" "}
                    <span class="label">
                      {bubbleStatus === 21 ? (
                        <Icon
                          name="small check"
                          style={{ paddingLeft: "3px" }}
                        />
                      ) : (
                        ""
                      )}
                    </span>
                    <span class="title">
                      {" "}
                      {Common.translateStatus("On Hold", translations)}
                    </span>
                  </div>{" "}
                  <span class="bar active"></span>
                  <div
                    class={
                      bubbleStatus === 18 ? "circle active" : "circle done"
                    }
                  >
                    {" "}
                    <span class="label">
                      {bubbleStatus === 18 ? (
                        <Icon
                          name="small check"
                          style={{ paddingLeft: "3px" }}
                        />
                      ) : (
                        ""
                      )}
                    </span>
                    <span class="title">
                      {" "}
                      {Common.translateStatus("Closed", translations)}
                    </span>
                  </div>
                </div>
              )}

              <Divider hidden />
            </Grid.Column>
            <Grid.Column width="5">
              <table className="details-table">
                <tr>
                  <td>
                    <b>{translations["LexOrderDate"]}</b>
                  </td>
                  <td>{data.orderSubmittedDate}</td>
                </tr>
                <tr>
                  <td style={{ verticalAlign: "top" }}>
                    <b>{translations["LexExpirationDate"]}</b>
                  </td>
                  <td>
                    {data.expirationDate}
                    <br /> {`(${translations["LexApplicablenonmetallabels"]})`}
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>{translations["LexOrderNumber"]}</b>
                  </td>
                  <td>
                    <label>
                      {data && data.oracleNumber}
                      <br />
                      {oracleMessage}
                    </label>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b>{translations["LexLabelAgent"]}</b>
                  </td>
                  <td>
                    <label>{data && data.labelAgent}</label>
                  </td>
                </tr>
                <tr hidden={data.labelOrderType == 2}>
                  <td>
                    <b>{translations["LexCourier"]}</b>
                  </td>
                  <td>{courierList}</td>
                </tr>
                <tr hidden={data.labelOrderType == 2}>
                  <td>
                    <b>{translations["LexTrackingNumber"]}</b>
                  </td>
                  <td>{trackingNumberList}</td>
                </tr>
                <tr>
                  <td>
                    <b>{translations["LexCancellationDate"]}</b>
                  </td>
                  <td>
                    {data.orderStatus === "Cancelled" ||
                    data.labelOrderStatusName === "Cancelled"
                      ? data.orderCancelledDate
                      : "--"}
                  </td>
                </tr>
                <tr>
                  <td style={{ width: "65%" }}>
                    <b>{translations["LexCancellationReason"]}</b>
                  </td>
                  <td>{data.cancelReason}</td>
                </tr>
              </table>
            </Grid.Column>
            <Grid.Column width="5">
              <table style={{ width: "90%" }}>
                <tr hidden={data.labelOrderType == 2}>
                  <td>
                    {translations["LexTotalPrintingFee"]} ({data.currency})
                  </td>
                  <td>
                    {Common.getNumberWithCommas(
                      data.billToCountry == "KOREA" ||
                        data.billToCountry == "JAPAN"
                        ? data.totalLabelPrintingFee.toFixed(0)
                        : data.totalLabelPrintingFee.toFixed(2)
                    )}
                  </td>
                </tr>
                <tr hidden={data.labelOrderType == 2}>
                  <td>
                    {translations["LexTotalHandlingFee"]} ({data.currency})
                  </td>
                  <td>
                    {Common.getNumberWithCommas(
                      data.billToCountry == "KOREA" ||
                        data.billToCountry == "JAPAN"
                        ? data.totalHandlingFee.toFixed(0)
                        : data.totalHandlingFee.toFixed(2)
                    )}
                  </td>
                </tr>
                <tr hidden={data.labelOrderType == 2}>
                  <td colSpan={2}>
                    <div style={{ border: "1px solid #DCDEE1" }}></div>
                  </td>
                </tr>
                <tr hidden={data.labelOrderType == 2}>
                  <td>
                    <b>
                      {translations["LexOrderTotal"]} ({data.currency})
                    </b>
                    <br />
                    <SubText message={translations["Lexbeforeshipping"]} />
                  </td>
                  <td>
                    <b>
                      {Common.getNumberWithCommas(
                        data.billToCountry == "KOREA" ||
                          data.billToCountry == "JAPAN"
                          ? data.orderTotal.toFixed(0)
                          : data.orderTotal.toFixed(2)
                      )}
                    </b>
                  </td>
                </tr>
                <tr hidden={data.labelOrderType == 2}>
                  <td>
                    <Divider hidden />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2} style={{ textAlign: "justify" }}>
                    {translations["LexCommentsInstructionswoOptional"]}:
                    <br />
                    <b>{data.comments}</b>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Divider hidden />
                  </td>
                </tr>
                <tr>
                  <td>
                    {data.labelOrderType == 1 && (
                      <Button
                        basic
                        compact
                        color="blue"
                        className="print-hidden"
                        disabled={data.packingSlips.length > 0 ? false : true}
                        onClick={this.handleViewPackingSlip}
                      >
                        {translations["LexViewPackingSlip"]}
                      </Button>
                    )}

                    {data.labelOrderType == 2 && (
                      <Button
                        basic
                        compact
                        color="blue"
                        className="print-hidden"
                        disabled={data.packingSlips.length > 0 ? false : true}
                        onClick={this.handleViewAuthorizationLetter}
                      >
                        {translations["LexViewVendorAuthorizationLetter"]}
                      </Button>
                    )}
                  </td>
                  <td>
                    <Modal
                      size="mini"
                      trigger={
                        <Button
                          color="blue"
                          compact
                          disabled={roleId === Role.fieldEngineerRO}
                        >
                          {translations["LexEmailthisOrder"]}
                        </Button>
                      }
                      closeIcon
                    >
                      <Header content="Email a copy" />
                      <Modal.Content>
                        {isEmailCopySuccess && (
                          <Message success content="Email Copy Successful." />
                        )}
                        <p>
                          Send a record of your cart to yourself or another
                          email address.
                        </p>
                        <Form>
                          <Form.Field>
                            <label>Email Address</label>
                            <input
                              id="emailAddress"
                              placeholder="Email Address"
                              name="emailAddress"
                              onChange={this.props.handleChange}
                              value={emailAddress}
                              autoComplete="off"
                            />
                          </Form.Field>
                        </Form>
                      </Modal.Content>
                      <Modal.Actions>
                        <Button
                          primary
                          onClick={this.props.handleEmailCopy.bind(
                            this,
                            data.fileNumber
                          )}
                          disabled={isLoadingEmailCopy}
                        >
                          Send
                        </Button>
                      </Modal.Actions>
                    </Modal>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    {(roleId == Role.labelCenter ||
                      roleId == Role.labelManager) &&
                      bubbleStatus !== 18 && (
                        <Button onClick={this.handleWarningYes} compact>
                          Update Oracle Order Number
                        </Button>
                      )}
                    <Modal
                      size="mini"
                      open={openOracleWarning}
                      onClose={this.handleWarningNo}
                    >
                      <Modal.Header>Warning</Modal.Header>
                      <Modal.Content>
                        <p>
                          Please ensure the replacement Oracle Order has the
                          same number of order lines, Items types, sequence and
                          quantities as per the Customers Order in the Field
                          Ordering application or the Oracle Order status will
                          not function correctly. Do you really want to proceed?{" "}
                        </p>
                      </Modal.Content>
                      <Modal.Actions>
                        <Button
                          closeIcon
                          primary
                          basic
                          onClick={this.handleYes}
                        >
                          Yes
                        </Button>
                        <Button onClick={this.handleWarningNo}>No</Button>
                      </Modal.Actions>
                    </Modal>
                    <Modal
                      size="mini"
                      open={openUpdateOracleNo}
                      onClose={this.handleNo}
                    >
                      <Header content="Update Oracle Order Number" />
                      <Modal.Content>
                        {isSuccessUpdateOracleNumber && (
                          <Message
                            success
                            content="Update Oracle Order Number Successful."
                          />
                        )}
                        <p>Please enter Oracle Order Number:</p>
                        <Form>
                          <Form.Field>
                            <label>Oracle Number</label>
                            <input
                              id="oracleNumber"
                              placeholder="Oracle Number"
                              name="oracleNumber"
                              onChange={this.props.handleChange}
                              value={oracleNumber}
                              autoComplete="off"
                            />
                          </Form.Field>
                        </Form>
                      </Modal.Content>
                      <Modal.Actions>
                        <Button
                          primary
                          onClick={this.props.handleUpdateOracleNumber.bind(
                            this,
                            oracleNumber
                          )}
                          disabled={isLoadingUpdateOracleNumber}
                        >
                          Update
                        </Button>
                        <Button primary onClick={this.handleNo}>
                          Close
                        </Button>
                      </Modal.Actions>
                    </Modal>
                  </td>
                </tr>
              </table>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={13}>
              <div style={{ border: "1px solid #DCDEE1" }}></div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Fragment>
    );
  }
}

export default React.memo(LabelOrderWorkflow);
