import React from "react";
import { Segment, Grid, Image, Divider } from "semantic-ui-react";
import { LabelBold } from "../labels/labels";

const LabelImageDetails = ({ imageDetails }) => {
  return (
    <Segment secondary>
      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column width={6}>
            <Image
              src="https://react.semantic-ui.com/images/wireframe/image.png"
              size="small"
            />
          </Grid.Column>
          <Grid.Column width={10}>
            <LabelBold message={imageDetails.text} />
            <Divider hidden fitted />

            <LabelBold message="CCN: " />
            <label>{imageDetails.value}</label>
            <Divider hidden fitted />
            <LabelBold message="Item #: " />
            <label>50027245</label>
            <Divider hidden fitted />
            <LabelBold message="Product type: " />
            <label>Wire and Cable</label>
            <Divider hidden fitted />
            <LabelBold message="Unit price: " />
            <label>USD 0.10</label>
            <Divider hidden fitted />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <LabelBold message="Label Description" />
            <Divider hidden fitted />
            <label>{imageDetails.text}</label>
            <Divider hidden fitted />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

export default LabelImageDetails;
