import React, { Component, Fragment } from "react";
import {
  Accordion,
  Divider,
  Dropdown,
  Form,
  Grid,
  Icon,
  Input,
  Segment,
} from "semantic-ui-react";
import {
  CustomHeaderLabel,
  CustomHeaderBold,
  CustomHeaderLabelRelaxed,
} from "../../../components/labels/labels";

class LabelOrderCartInfoReview extends Component {
  state = {
    activeIndex: 0,
  };

  toggleDisplay = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  render() {
    const { activeIndex } = this.state;
    const {
      data,
      customerBillToAddresses,
      customerShipToAddresses,
    } = this.props;

    return (
      <Fragment>
        <Segment>
          <Accordion>
            <Accordion.Title
              active={activeIndex === 0}
              index={0}
              onClick={this.toggleDisplay}
            >
              <Grid columns={2}>
                <Grid.Column width={5}>
                  <Icon name="dropdown" />
                  <CustomHeaderLabel message="Review your cart information" />
                </Grid.Column>
                <Grid.Column width={5}></Grid.Column>
              </Grid>
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 0}>
              <Divider />

              <Grid columns={2} stackable>
                <Grid.Row stretched>
                  <Grid.Column width={5} style={{ textAlign: "justify" }}>
                    <Grid>
                      <Grid.Column width={16}>
                        <i>
                          Adjust your preferences for how the labels are
                          produced. If you have questions, please contact us.
                        </i>
                      </Grid.Column>
                    </Grid>
                  </Grid.Column>

                  <Grid.Column width={11}>
                    <Form>
                      <Grid>
                        <Grid.Row>
                          <Grid.Column width={14}>
                            <CustomHeaderBold message="Customer Purchase Order Number" />
                            <Divider fitted hidden />
                            {(data && data.customerPoNumber) ||
                              "-NOTHING PROVIDED-"}
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <Grid.Column width={14}>
                            <CustomHeaderBold message="Instructions for the label center" />
                            <Divider fitted hidden />
                            {(data && data.instructions) ||
                              "-NOTHING PROVIDED-"}
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <Grid.Column width={14}>
                            <CustomHeaderBold message="Authorized Label Supplier" />
                            <Divider fitted hidden />
                            {(data && data.labelSupplierName) ||
                              "-NOTHING PROVIDED-"}
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <Grid.Column width={14}>
                            <CustomHeaderBold message="Bill To Company" />
                            <Divider fitted hidden />
                            {(data && data.billToCompany) ||
                              "-NOTHING PROVIDED-"}
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <Grid.Column width={14}>
                            <CustomHeaderBold message="Bill To Account No" />
                            <Divider fitted hidden />
                            {(data && data.billToAccountNo) ||
                              "-NOTHING PROVIDED-"}
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <Grid.Column width={14}>
                            <CustomHeaderBold message="Bill To Contact Name" />
                            <Divider fitted hidden />
                            {(data && data.billToContactName) ||
                              "-NOTHING PROVIDED-"}
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <Grid.Column width={14}>
                            <CustomHeaderBold message="Bill To Address" />
                            <Divider fitted hidden />
                            {(data && data.billToValue) || "-NOTHING PROVIDED-"}
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <Grid.Column width={14}>
                            <CustomHeaderBold message="Manufacturer Contact Name" />
                            <Divider fitted hidden />
                            {(data && data.shipToContactName) ||
                              "-NOTHING PROVIDED-"}
                          </Grid.Column>
                        </Grid.Row>

                        {/* <Grid.Row>
                          <Grid.Column width={14}>
                            <CustomHeaderBold message="Ship To Address" />
                            <Divider fitted hidden />
                            {(data && data.shipToValue) || "-NOTHING PROVIDED-"}
                          </Grid.Column>
                        </Grid.Row> */}

                        <Grid.Row>
                          <Grid.Column width={14}>
                            <CustomHeaderBold message="Manufacturer Name" />
                            <Divider fitted hidden />
                            {(data && data.manufacturerName) ||
                              "-NOTHING PROVIDED-"}
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <Grid.Column width={14}>
                            <CustomHeaderBold message="Manufacturer Address" />
                            <Divider fitted hidden />
                            {(data && data.manufacturerAddress) ||
                              "-NOTHING PROVIDED-"}
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <Grid.Column width={14}>
                            <CustomHeaderBold message="Manufacturer Account Number" />
                            <Divider fitted hidden />
                            {(data && data.manufacturerAccountNumber) ||
                              "-NOTHING PROVIDED-"}
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <Grid.Column width={14}>
                            <CustomHeaderBold message="Customer Email Address" />
                            <Divider fitted hidden />
                            {(data && data.customerEmailAddress) ||
                              "-NOTHING PROVIDED-"}
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <Grid.Column width={14}>
                            <CustomHeaderBold message="Customer Phone Number" />
                            <Divider fitted hidden />
                            {(data && data.customerPhoneNumber) ||
                              "-NOTHING PROVIDED-"}
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <Grid.Column width={14}>
                            <CustomHeaderBold message="Broker Information" />
                            <Divider fitted hidden />
                            {(data && data.brokerInformation) ||
                              "-NOTHING PROVIDED-"}
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Form>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Accordion.Content>
          </Accordion>
        </Segment>
      </Fragment>
    );
  }
}

export default LabelOrderCartInfoReview;
