import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Container, Grid, Form, Segment } from "semantic-ui-react";
import { CustomHeader } from "../../../components/labels/labels";
import ChangePasswordModal from "../change-password/change-password-modal";
import { getProfile } from "./profile.action";

class Profile extends Component {
  state = { showChangePasswordModal: false };
  constructor() {
    super();
    this.handleSubmitChangePassword = this.handleSubmitChangePassword.bind(
      this
    );
  }

  componentDidMount() {
    this.props.getProfile();
  }

  onCloseChangePasswordModal = () => {
    this.setState({ showChangePasswordModal: false });
  };

  handleSubmitChangePassword() {
    this.setState({ showChangePasswordModal: false });
  }

  render() {
    const { data } = this.props.profile;
    const { showChangePasswordModal } = this.state;
    return (
      <div className="container-wrapper">
        <Container fluid>
          <Segment>
            <Grid>
              <Grid.Column width={7}>
                <CustomHeader message="User Profile" />
              </Grid.Column>
            </Grid>
            <Grid divided="vertically">
              <Grid.Row columns={2}>
                <Grid.Column>
                  <Form>
                    <Form.Group widths="equal">
                      <Form.Input
                        readOnly
                        fluid
                        label="Given Name"
                        placeholder="Given Name"
                        value={data.firstName || ""}
                      />
                      <Form.Input
                        readOnly
                        fluid
                        label="Last Name"
                        placeholder="Family Name"
                        value={data.lastName || ""}
                      />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <Form.Input
                        readOnly
                        fluid
                        label="Role"
                        placeholder="Role"
                        value={""}
                      />
                    </Form.Group>
                    <Form.Group widths="equal">
                      <Form.Input
                        readOnly
                        fluid
                        label="Email Address"
                        placeholder="Email Address"
                        value={data.email || ""}
                      />
                    </Form.Group>
                    <Form.Group>
                      <Form.Input
                        readOnly
                        width={14}
                        fluid
                        label="Current Password"
                        placeholder="Current Password"
                        value={data.password || ""}
                        type="password"
                      />
                      <Form.Button
                        basic
                        color="blue"
                        content="Change"
                        label="&nbsp;"
                        onClick={() =>
                          this.setState({ showChangePasswordModal: true })
                        }
                      />
                      <ChangePasswordModal
                        onSubmit={this.handleSubmitChangePassword}
                        open={showChangePasswordModal}
                        onClose={this.onCloseChangePasswordModal}
                      />
                    </Form.Group>
                    {/* <Form.Group>
                      <Form.Button primary>Save</Form.Button>
                    </Form.Group> */}
                  </Form>
                </Grid.Column>
                <Grid.Column>
                  The following profile details are drawn from the customer
                  service contract between your organization and UL. If the
                  infromation is incorrect or incomplete, please contact us at
                  +1.555.555.5555
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    profile: state.user.profile
  };
};

const mapDispatchToProps = dispatch => ({
  getProfile: () => dispatch(getProfile())
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Profile)
);
