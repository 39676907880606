import { requests } from '../request-factory';
import { mapDataToQueryString } from '../request-helpers';

const dashboardService = {
  getNotifications: (currentPage, pageSize) => {
    return requests.get(
      `order/notifications?${mapDataToQueryString({
        currentPage,
        pageSize,
      })}`
    );
  },

  updateNotificationView: userId =>
    requests.put('order/notifications/view/update', { userId: userId }),
};

export default dashboardService;
