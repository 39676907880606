import { Common } from '../../../../common';
import * as types from './label-cart.type';

const initialState = {
  hasError: false,
  message: '',
  isLoading: false,
  isLoadingCartItems: false,
  isLoadingCustomerDetails: false,
  isSuccessGetCustomerDetails: false,
  isLoadingCreateOrder: false,
  isSuccessCancelCart: false,
  isSuccessGetCartItems: false,
  createOrderResult: null,
  isSuccessCreateOrder: false,
  isSuccessDeleteCart: false,
  isSuccessUpdateCart: false,
  isLoadingLabelCenterCountryDetails: false,
  isSuccessGetLabelCenterCountryDetails: false,
  isSuccessGetExchangeRate: false,
  labelCenterCountryDetails: null,
  hasCustomerAccountNumbers: false,
  data: null,
  selectedLabels: [],
  total: 0,
  totalHandlingFee: 0,
  grantTotal: 0,
  allLabels: [],
  customerPoNumber: '',
  allShippingOptions: [],
  shippingOptions: [],
  shippingOptionId: 1,
  comments: '',
  customerDetails: {},
  labelFileNumber: '',
  fileNumbers: [],
  fileVolumeCcnDetails: null,
  form: {
    showErrors: false,
    validationErrors: {},
  },
  forms: [],
  labelOrderType: null,
  exchangeRate: null,
  newPoNumber: false,
  attachments: [],
  isServiceContractUp: false,
};

const getLabels = (selectedLabels, findChecked) => {
  var result = [];

  selectedLabels.forEach(item => {
    if (findChecked)
      item.cartItems
        .filter(d => d.isSelected)
        .forEach(cartItem => {
          result.push({
            id: cartItem.id,
            labelAccount: cartItem.labelAccount,
            ccn: cartItem.ccn,
            description: cartItem.description,
            itemNumber: cartItem.itemNumber,
            imageUrl: cartItem.imageUrl,
            quantity: cartItem.quantity,
            price: cartItem.price,
            netPrintingFee: cartItem.netPrintingFee,
            isSelected: cartItem.isSelected,
          });
        });
    else
      item.cartItems.forEach(cartItem => {
        result.push({
          id: cartItem.id,
          labelAccount: cartItem.labelAccount,
          ccn: cartItem.ccn,
          description: cartItem.description,
          itemNumber: cartItem.itemNumber,
          imageUrl: cartItem.imageUrl,
          quantity: cartItem.quantity,
          price: cartItem.price,
          netPrintingFee: cartItem.netPrintingFee,
          isSelected: cartItem.isSelected,
        });
      });
  });

  return result;
};

const computeTotalPrintingFee = labelItems => {
  var total = 0;

  labelItems.forEach(labelItem => {
    if (labelItem.isSelected) {
      // total +=
      //   labelItem.netPrintingFee *
      //   (isNaN(parseInt(labelItem.quantity)) ? 0 : labelItem.quantity);

      total += parseFloat((labelItem.newPrice * labelItem.quantity).toFixed(2));
    }
  });
  return total;
};

const computeTotalHandlingFee = (selectedLabels, handlingFee) => {
  var total = 0;

  selectedLabels.forEach(() => {
    total += handlingFee * 1;
  });

  return total;
};

const computeTotal = labelItems => {
  var total = 0;

  labelItems.forEach(labelItem => {
    if (labelItem.isSelected) {
      // total +=
      //   labelItem.netPrintingFee *
      //   (isNaN(parseInt(labelItem.quantity)) ? 0 : labelItem.quantity);
      total += labelItem.netPrintingFee;
    }
  });

  return total;
};

const labelCartReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CANCEL_ORDER_RESET:
      return {
        ...state,
        hasError: false,
        isLoading: false,
        isSuccessCancelCart: false,
      };
    case types.CANCEL_ORDER_REQUEST:
      return {
        ...state,
        hasError: false,
        isLoading: true,
      };
    case types.CANCEL_ORDER_SUCCESS:
      var newStateCancel = { ...state };
      newStateCancel.isSuccessCancelCart = action.payload;
      return {
        ...newStateCancel,
        hasError: false,
        isLoading: false,
      };
    case types.CANCEL_ORDER_FAILURE:
      return {
        ...state,
        message: action.payload,
        hasError: true,
        isLoading: false,
      };
    case types.RESET_CART_ITEMS_FORM:
      var newStateReset = { ...state };
      newStateReset.form = { showErrors: false, validationErrors: {} };
      newStateReset.isSuccessCreateOrder = false;
      return {
        ...newStateReset,
        hasError: false,
        isLoading: false,
      };
    case types.CREATE_ORDER_REQUEST:
      return {
        ...state,
        hasError: false,
        isLoadingCreateOrder: true,
        isSuccessCreateOrder: false,
      };
    case types.CREATE_ORDER_SUCCESS:
      return {
        ...state,
        hasError: false,
        isSuccessCreateOrder: action.payload != null,
        createOrderResult: action.payload,
        isLoadingCreateOrder: false,
      };
    case types.CREATE_ORDER_FAILURE:
      return {
        ...state,
        message: action.payload,
        hasError: true,
        isLoadingCreateOrder: false,
        isSuccessCreateOrder: false,
      };
    case types.MANUFACTURER_STANDARD_DELETE_CART_RESET:
      return {
        ...state,
        hasError: false,
        isLoading: false,
        isSuccessDeleteCart: false,
      };
    case types.MANUFACTURER_STANDARD_DELETE_CART_REQUEST:
      return {
        ...state,
        hasError: false,
        isLoading: true,
      };
    case types.MANUFACTURER_STANDARD_DELETE_CART_SUCCESS:
      return {
        ...state,
        hasError: false,
        isSuccessDeleteCart: action.payload,
        isLoading: false,
      };
    case types.MANUFACTURER_STANDARD_DELETE_CART_FAILURE:
      return {
        ...state,
        message: action.payload,
        hasError: true,
        isLoading: false,
      };
    case types.CART_ITEMS_REQUEST:
      return {
        ...state,
        hasError: false,
        isLoadingCartItems: true,
        isSuccessGetCartItems: false,
      };
    case types.CART_ITEMS_SUCCESS:
      let currentState = { ...state };

      currentState.total = 0;

      currentState.forms = action.payload.map(item => {
        item.isBillToDisable = false;
        item.showErrors = false;
        item.validationErrors = {};
        item.applicantAccountNumbers = item.applicantFileVolumeDetails.map(
          (cr, idx) => {
            return {
              key: idx,
              value: cr.accountNumber,
              text: cr.accountNumber,
            };
          }
        );
        item.manufacturerAccountNumbers =
          item.manufacturerFileVolumeDetails.map((cr, idx) => {
            return {
              key: idx,
              value: cr.accountNumber,
              text: cr.accountNumber,
            };
          });
        (item || []).carts.forEach(s => {
          s.cartItems.forEach(c => {
            c.netPrintingFee =
              c.newPrice * (isNaN(parseInt(c.quantity)) ? 0 : c.quantity);
            item.totalLabelPrintFee += c.netPrintingFee;
          });
        });
        //item.totalHandlingFee = computeTotalHandlingFee(item.carts, false);
        item.totalOrderFee = item.totalLabelPrintFee + item.totalHandlingFee;

        item.hideShippingDetails = item.shippingOptionId === 6 ? false : true;

        //new rule if LA or NA and Taiwan, always show shipping details

        if (
          item.labelCenterName?.trim().toLowerCase() === 'north america' ||
          item.labelCenterName?.trim().toLowerCase() === 'latin america' ||
          item.labelCenterName?.trim().toLowerCase() === 'taiwan'
        ) {
          item.hideShippingDetails = false;
        }

        return item;
      });

      currentState.hasCustomerAccountNumbers = true;

      if (action.payload && action.payload.length > 0) {
        currentState.selectedLabels = getLabels(
          (action.payload[0] || []).carts,
          true
        );

        currentState.allLabels = getLabels(
          (action.payload[0] || []).carts,
          false
        );
        currentState.fileNumbers = (action.payload[0] || []).carts.map(item => {
          return item.fileNumber;
        });
        currentState.fileVolumeCcnDetails =
          action.payload == null
            ? {}
            : (action.payload[0] || []).fileVolumeCcnDetails;
        for (var index = 0; index < action.payload.length; index++) {
          (action.payload[index] || []).carts.forEach(s => {
            s.cartItems.forEach(c => {
              c.netPrintingFee =
                c.newPrice * (isNaN(parseInt(c.quantity)) ? 0 : c.quantity);
              currentState.total += c.netPrintingFee;
            });
          });
        }

        currentState.hasServiceContractData =
          action.payload[0].hasServiceContractData;
        currentState.hasSameBillTo = action.payload[0].hasSameBillTo;
      } else {
        currentState.fileNumbers = [];
      }
      // currentState.totalHandlingFee = computeTotalHandlingFee(
      //   currentState.allLabels
      // );

      currentState.grantTotal =
        currentState.total + currentState.totalHandlingFee;

      return {
        ...currentState,
        hasError: false,
        data: action.payload,
        isLoadingCartItems: false,
        isSuccessGetCartItems: true,
      };
    case types.CART_ITEMS_FAILURE:
      return {
        ...state,
        message: action.payload,
        hasError: true,
        isLoadingCartItems: false,
        isSuccessGetCartItems: false,
      };
    case types.MANUFACTURER_STANDARD_CART_ITEMS_SELECT_LABEL:
      const newState = { ...state };

      newState.total = 0;

      newState.data[action.payload.activeIndex].carts = newState.data[
        action.payload.activeIndex
      ].carts.map(item => {
        item.cartItems.map(cartItem => {
          if (cartItem.id !== action.payload.data['id']) return cartItem;
          cartItem.isSelected = action.payload.isSelected;
        });
        return item;
      });

      newState.selectedLabels = getLabels(
        newState.data[action.payload.activeIndex].carts,
        true
      );
      newState.allLabels = getLabels(
        newState.data[action.payload.activeIndex].carts,
        false
      );
      newState.total = computeTotal(newState.allLabels);

      newState.totalHandlingFee = computeTotalHandlingFee(
        newState.selectedLabels
      );

      newState.grantTotal = newState.totalHandlingFee + newState.total;

      return newState;
    case types.MANUFACTURER_STANDARD_CART_ITEMS_SET_QUANTITY:
      const newStateQty = { ...state };

      newStateQty.total = 0;

      newStateQty.data.carts = newStateQty.data[action.payload.index].carts.map(
        item => {
          item.cartItems.map(cartItem => {
            if (cartItem.id !== action.payload.data['id']) return cartItem;
            cartItem.quantity = action.payload.quantity;
            cartItem.netPrintingFee =
              cartItem.newPrice *
              (isNaN(parseInt(cartItem.quantity)) ? 0 : cartItem.quantity);
            newStateQty.total += cartItem.netPrintingFee;
          });
          return item;
        }
      );

      var currentForm = newStateQty.forms[action.payload.index];

      var cartItems =
        newStateQty.forms[action.payload.index].carts[0].cartItems;

      var totalPrintingFee = computeTotalPrintingFee(cartItems);
      var totalHandlingFee = computeTotalHandlingFee(
        cartItems,
        currentForm.handlingFee
      );

      currentForm.totalPrintingFee = totalPrintingFee;
      currentForm.totalPrintingFeeConverted =
        totalPrintingFee * currentForm.conversionRate;

      currentForm.totalHandlingFee = totalHandlingFee;
      // currentForm.totalHandlingFeeConverted =
      //   totalHandlingFee * currentForm.conversionRate;
      currentForm.totalHandlingFeeConverted = totalHandlingFee;

      currentForm.grantTotal = totalPrintingFee + totalHandlingFee;
      currentForm.grantTotalConverted =
        currentForm.totalPrintingFeeConverted + currentForm.totalHandlingFee;

      newStateQty.forms[action.payload.index] = currentForm;
      newStateQty.total = computeTotal(newStateQty.allLabels);

      // newStateQty.allLabels = getLabels(newStateQty.data.carts, false);
      // newStateQty.total = computeTotal(newStateQty.allLabels);
      // newStateQty.totalHandlingFee = newStateQty.totalHandlingFee;
      // newStateQty.grantTotal = newStateQty.totalHandlingFee + newStateQty.total;

      return newStateQty;
    case types.MANUFACTURER_STANDARD_CART_ITEMS_REMOVE:
      let stateCartItems = { ...state };
      var result = [];

      stateCartItems.data[action.payload.activeIndex].carts.forEach(item => {
        var newCartItems = [];

        item.cartItems.forEach(cartItem => {
          var index = action.payload.cartItemIds.indexOf(cartItem.id);
          if (index === -1) newCartItems.push(cartItem);
        });

        result.push({
          fileNumber: item.fileNumber,
          cartItems: newCartItems,
          volume: item.volume,
        });
      });

      result = result.filter(r => r.cartItems.length > 0);

      if (result.length == 0) localStorage.setItem('cartLabelOrderType', null);
      stateCartItems.data[action.payload.activeIndex].carts = result;
      stateCartItems.total = 0;
      stateCartItems.allLabels = getLabels(
        stateCartItems.data[action.payload.activeIndex].carts,
        false
      );
      //stateCartItems.data[action.payload.activeIndex].totalPrintingFee = updateTotalPrintingFee(stateCartItems.allLabels);
      var currentForm = stateCartItems.forms[action.payload.activeIndex];

      var cartItems =
        stateCartItems.forms[action.payload.activeIndex].carts.length > 0
          ? stateCartItems.forms[action.payload.activeIndex].carts[0].cartItems
          : [];

      var totalPrintingFee = computeTotalPrintingFee(cartItems);
      var totalHandlingFee = computeTotalHandlingFee(
        cartItems,
        currentForm.handlingFee
      );

      currentForm.totalPrintingFee = totalPrintingFee;
      currentForm.totalPrintingFeeConverted =
        totalPrintingFee * currentForm.conversionRate;

      currentForm.totalHandlingFee = totalHandlingFee;
      // currentForm.totalHandlingFeeConverted =
      //   totalHandlingFee * currentForm.conversionRate;
      currentForm.totalHandlingFeeConverted = totalHandlingFee;

      currentForm.grantTotal = totalPrintingFee + totalHandlingFee;
      currentForm.grantTotalConverted =
        currentForm.totalPrintingFeeConverted + currentForm.totalHandlingFee;

      if (cartItems.length === 0) {
        stateCartItems.forms = stateCartItems.forms.filter(
          x => x !== stateCartItems.forms[action.payload.activeIndex]
        );

        stateCartItems.data = stateCartItems.data.filter(
          x => x !== stateCartItems.data[action.payload.activeIndex]
        );
        stateCartItems.total = 0;
        stateCartItems.allLabels =
          stateCartItems.data.length > 0
            ? getLabels(
                stateCartItems.data[action.payload.activeIndex].carts,
                false
              )
            : [];
      } else {
        stateCartItems.forms[action.payload.activeIndex] = currentForm;
      }

      stateCartItems.total = computeTotal(stateCartItems.allLabels);
      stateCartItems.selectedLabels = result.length == 0 ? [] : result;

      return stateCartItems;
    case types.MANUFACTURER_STANDARD_CART_UPDATE_MODEL:
      let newStateModel = {
        ...state,
        isSuccessful: false,
        hasError: false,
        isLoading: false,
      };

      newStateModel[action.payload.name] = action.payload.value;

      return newStateModel;
    case types.MANUFACTURER_STANDARD_UPDATE_CART_RESET:
      return {
        ...state,
        hasError: false,
        isLoading: false,
        isSuccessUpdateCart: false,
      };
    case types.MANUFACTURER_STANDARD_UPDATE_CART_REQUEST:
      return {
        ...state,
        hasError: false,
        isLoading: true,
      };
    case types.MANUFACTURER_STANDARD_UPDATE_CART_SUCCESS:
      return {
        ...state,
        hasError: false,
        isSuccessUpdateCart: action.payload,
        isLoading: false,
      };
    case types.MANUFACTURER_STANDARD_UPDATE_CART_FAILURE:
      return {
        ...state,
        message: action.payload,
        hasError: true,
        isLoading: false,
      };
    case types.FORM:
      return {
        ...state,
        isSuccessful: false,
        hasError: false,
        isLoading: false,
        form: action.payload || initialState.form,
      };
    case types.CLEAR_FORM:
      return {
        ...initialState,
        form: action.payload,
        hasError: false,
        isLoading: false,
        allShippingOptions: action.payload.map(item => {
          return {
            key: item.id,
            text: item.name,
            value: item.id,
          };
        }),
        shippingOptions: action.payload.map(item => {
          return {
            key: item.id,
            text: item.name,
            value: item.id,
          };
        }),
      };
    case types.SHIPPING_OPTIONS_REQUEST:
      return {
        ...state,
        hasError: false,
        isLoading: true,
      };
    case types.SHIPPING_OPTIONS_SUCCESS:
      return {
        ...state,
        hasError: false,
        allShippingOptions: action.payload.map(item => {
          return {
            key: item.id,
            text: item.name,
            value: item.id,
          };
        }),
        shippingOptions: action.payload.map(item => {
          return {
            key: item.id,
            text: item.name,
            value: item.id,
          };
        }),
        isLoading: false,
      };
    case types.SHIPPING_OPTIONS_FAILURE:
      return {
        ...state,
        message: action.payload,
        hasError: true,
        isLoading: false,
      };
    case types.SET_SHIPPING_OPTION:
      const newStateOption = { ...state };

      newStateOption.shippingOptionId = action.payload.optionId;

      return newStateOption;
    case types.CUSTOMER_DETAILS_REQUEST:
      return {
        ...state,
        hasError: false,
        isLoadingCustomerDetails: true,
        isSuccessGetCustomerDetails: false,
      };
    case types.CUSTOMER_DETAILS_SUCCESS:
      var newStateCustDetails = {
        ...state,
        customerDetails: action.payload,
        hasError: false,
        isLoadingCustomerDetails: false,
        isSuccessGetCustomerDetails: true,
      };
      if (
        newStateCustDetails.customerDetails &&
        newStateCustDetails.customerDetails.data
      ) {
        newStateCustDetails.customerDetails.data.combintationCustomerName = `${Common.getFirstName()} ${Common.getLastName()}`;
        newStateCustDetails.customerDetails.data.combintationCustomerEmail =
          Common.getEmail();
        newStateCustDetails.customerDetails.data.combintationCustomerPhone =
          Common.getPhoneNumber();
      }
      return newStateCustDetails;
    case types.CUSTOMER_DETAILS_FAILURE:
      return {
        ...state,
        message: action.payload,
        hasError: true,
        isLoadingCustomerDetails: false,
        isSuccessGetCustomerDetails: false,
      };
    // case types.UPDATE_CART_ITEMS_LIST:
    //   const newState = { ...state };
    //   return newState;
    case types.UPDATE_REVIEW_CART:
      const newStateReviewCart = { ...state };
      var oldData = newStateReviewCart.data.filter(
        x => x.fileNumber === action.payload.fileNumber
      );
      var currentData = newStateReviewCart.data.filter(
        x => x.fileNumber !== action.payload.fileNumber
      );
      var updateData = currentData.filter(
        x => x.billToAccountNo === action.payload.billToAccountNo
      );

      var updateDataTotalPrintingFee = updateData[0].totalPrintingFee;
      var oldDataTotalPrintingFee = oldData[0].totalPrintingFee;

      updateData[0].totalPrintingFee =
        updateDataTotalPrintingFee + oldDataTotalPrintingFee;
      updateData[0].totalPrintingFeeConverted =
        updateData[0].totalPrintingFee * oldData[0].conversionRate;

      // updateData[0].totalHandlingFee =
      //   updateData[0].totalHandlingFee + oldData[0].totalHandlingFee;

      updateData[0].totalHandlingFeeConverted =
        updateData[0].totalHandlingFee + oldData[0].totalHandlingFee;

      updateData[0].grantTotal =
        updateData[0].grantTotal + oldData[0].grantTotal;
      updateData[0].grantTotalConverted =
        updateData[0].totalPrintingFeeConverted +
        updateData[0].totalHandlingFeeConverted;

      newStateReviewCart.data = updateData;

      return newStateReviewCart;

    case types.UPDATE_SHIPPING_OPTIONS:
      var result = Object.assign({}, state);

      var shipOptions = result.allShippingOptions;

      if (
        action.payload === 'Japan' ||
        action.payload === 'South Korea' ||
        action.payload === 'Europe'
      ) {
        shipOptions = [];
      } else if (action.payload === 'China' || action.payload === 'Taiwan') {
        shipOptions = shipOptions.filter(s => s.key === 6);
      }
      result.shippingOptions = shipOptions;

      return result;

    case types.LABEL_CENTER_COUNTRY_REQUEST:
      return {
        ...state,
        hasError: false,
        isLoadingLabelCenterCountryDetails: true,
        isSuccessGetLabelCenterCountryDetails: false,
      };
    case types.LABEL_CENTER_COUNTRY_SUCCESS:
      return {
        ...state,
        labelCenterCountryDetails: action.payload,
        hasError: false,
        isLoadingLabelCenterCountryDetails: false,
        isSuccessGetLabelCenterCountryDetails: true,
      };
    case types.LABEL_CENTER_COUNTRY_FAILURE:
      return {
        ...state,
        message: action.payload,
        hasError: true,
        isLoadingLabelCenterCountryDetails: false,
        isSuccessGetLabelCenterCountryDetails: false,
      };
    case types.UPDATE_FORMS:
      return Object.assign({}, state, {
        forms: action.payload,
      });
    case types.UPDATE_LABEL_ORDER_TYPE:
      return {
        ...state,
        labelOrderType: action.payload,
      };
    case types.GET_EXCHANGE_RATE_REQUEST:
      return {
        ...state,
        isSuccessGetExchangeRate: false,
        exchangeRate: null,
      };
    case types.GET_EXCHANGE_RATE_SUCCESS:
      var currState = { ...state };
      var currentForm = currState.forms[action.payload.index];

      currentForm.conversionRate = action.payload.conversionRate;
      currentForm.currency = action.payload.currency;

      var totalPrintingFee = 0;
      var totalHandlingFee = 0;

      if (!!currState.forms[action.payload.index].carts.length) {
        if(currState.forms[action.payload.index].carts.length > 1)
        {
          var cartItems = [];

          for (let i = 0; i < currState.forms[action.payload.index].carts.length; i++) {
            currState.forms[action.payload.index].carts[i].cartItems.forEach(cart => {
              cartItems.push(cart)
            });          
          }          
            totalPrintingFee = computeTotalPrintingFee(cartItems);

            totalHandlingFee = computeTotalHandlingFee(
              cartItems,
              action.payload.handlingFee
            );
        }
        else
        {
          var cartItems =
            currState.forms[action.payload.index].carts[0].cartItems;

            totalPrintingFee = computeTotalPrintingFee(cartItems);

            totalHandlingFee = computeTotalHandlingFee(
              cartItems,
              action.payload.handlingFee
            );
        }

        currentForm.totalPrintingFee = totalPrintingFee;
        currentForm.totalPrintingFeeConverted =
          totalPrintingFee * action.payload.conversionRate;

        currentForm.totalHandlingFee = totalHandlingFee;
        // currentForm.totalHandlingFeeConverted =
        //   totalHandlingFee * action.payload.conversionRate;
        currentForm.totalHandlingFeeConverted = totalHandlingFee;

        currentForm.handlingFee = action.payload.handlingFee;

        currentForm.grantTotal = totalPrintingFee + totalHandlingFee;
        currentForm.grantTotalConverted =
          currentForm.totalPrintingFeeConverted + currentForm.totalHandlingFee;

        currState.forms[action.payload.index] = currentForm;
      }

      return {
        ...currState,
        isSuccessGetExchangeRate: true,
      };
    case types.GET_EXCHANGE_RATE_FAILURE:
      return {
        ...state,
        isSuccessGetExchangeRate: false,
        exchangeRate: null,
      };

    case types.GET_LABEL_ORDER_ATTACHMENT_TEMP_REQUEST:
      return {
        ...state,
        uploading: true,
      };
    case types.GET_LABEL_ORDER_ATTACHMENT_TEMP_SUCCESS:
      return {
        ...state,
        hasError: false,
        attachments: action.payload,
        uploading: false,
      };

    case types.GET_LABEL_ORDER_ATTACHMENT_TEMP_FAILURE:
      return {
        ...state,
        message: action.payload,
        hasError: true,
        uploading: false,
        isUploadAttachmentSuccess: false,
      };
    case types.DELETE_LABEL_ORDER_ATTACHMENT_TEMP_REQUEST:
      return {
        ...state,
        deleting: true,
      };
    case types.DELETE_LABEL_ORDER_ATTACHMENT_TEMP_SUCCESS:
      return {
        ...state,
        hasError: false,
        isDeleteuccess: action.payload.isSuccessful,
        deleting: false,
      };

    case types.DELETE_LABEL_ORDER_ATTACHMENT_TEMP_FAILURE:
      return {
        ...state,
        message: action.payload,
        hasError: true,
        deleting: false,
        isDeleteAttachmentSuccess: false,
      };

    case types.GET_EXISTING_PO_NUMBER_REQUEST:
      return {
        ...state,
      };
    case types.GET_EXISTING_PO_NUMBER_SUCCESS:
      return {
        ...state,
        hasError: false,
        newPoNumber: action.payload.isSuccessful,
      };

    case types.GET_EXISTING_PO_NUMBER_FAILURE:
      return {
        ...state,
        hasError: true,
      };

    case types.CHECK_FILEVOLCCN_REQEUST:
      return {
        ...state,
      };
    case types.CHECK_FILEVOLCCN_SUCCESS:
      return {
        ...state,
        hasError: false,
        newPoNumber: action.payload.isSuccessful,
      };

    case types.CHECK_FILEVOLCCN_FAILED:
      return {
        ...state,
        hasError: true,
      };
    case types.DELETE_CURRENT_TAB:
      var currState = { ...state };
      var currentData = currState.data[action.payload];
      currState.data = currState.data.filter(x => x != currentData);

      if (currState.data.length > 0) {
        currState.allLabels = getLabels((currState.data[0] || []).carts, false);
      }

      var form = currState.forms[action.payload];
      currState.forms = currState.forms.filter(x => x != form);
      return currState;
    case types.UPDATE_BILL_TO_DATA:
      var currState = { ...state };
      var data = action.payload;
      var form = currState.forms[0];
      form.billToAccountNo = data.accountNumber;
      form.billToCompany = data.accountName;
      form.billToAddress = data.address1;
      form.billToCountry = data.country.toUpperCase();
      form.billToCountryCode = data.countryCode;
      form.billToContactName =
        data.lastName + (data.lastName ? ', ' : '') + data.firstName;
      form.billToContactEmail = data.email;

      return currState;
    case types.CHECK_SERVICE_CONTRACT_REQUEST:
      return {
        ...state,
        message: action.payload,
        hasError: true,
        loading: true,
      };
    case types.CHECK_SERVICE_CONTRACT_SUCCESS:
      return {
        ...state,
        hasError: false,
        isServiceContractUp: action.payload,
        loading: false,
      };
    case types.CHECK_SERVICE_CONTRACT_FAILURE:
      return {
        ...state,
        isServiceContractUp: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default labelCartReducer;
