import * as types from "./view-order-details.type";
import _ from "lodash";
import update from "immutability-helper";

let initialState = {
  data: [],
  isCheckoutSuccess: false,
  isSuccessGetOrderDetails: false,
  siteCount: 0,
  forms: [],
  ppeSelections: [],
  trainingSelections: [],
  form: {
    otherDetails: {
      isReadyToScheduleNow: false,
      isCopyOrder: false,
      showErrors: false,
      validationErrors: {}
    },
    orderDetails: {
      data: {},
      showErrors: false,
      validationErrors: {}
    },
    applicantDetails: {
      applicantDetails: {
        data: null,
        locationType: 1,
        showErrors: false,
        validationErrors: {}
      },
      applicantBillToLocationDetails: {
        data: {},
        locationType: 2,
        showErrors: false,
        validationErrors: {}
      },
      quoteAcceptanceDetails: {
        data: {},
        showErrors: false,
        validationErrors: {}
      },
      showErrors: false,
      validationErrors: {}
    },
    siteDetails: {
      data: {},
      preliminarySiteLocationSection: {
        data: [],
        showErrors: false,
        validationErrors: {}
      },
      finalInstallationSiteSection: {
        data: [],
        showErrors: false,
        validationErrors: {}
      },
      authorityHavingJurisdictionDetails: {
        data: null,
        showErrors: false,
        validationErrors: {}
      },
      showErrors: false,
      validationErrors: {}
    },
    productDetails: {
      data: [],
      initialState: {
        orders: [],
        form: {
          showErrors: false,
          validationErrors: {}
        }
      },
      showErrors: false,
      validationErrors: {}
    },
    productItems: [],
    reservationDetails: {
      preliminarySiteLocationSection: {
        data: null,
        showErrors: false,
        validationErrors: {},
        ppeSelections: "",
        trainingSelections: ""
      },
      finalSiteLocationSection: {
        data: null,
        showErrors: false,
        validationErrors: {}
      },
      authorityHavingJurisdictionDetails: {
        data: null,
        showErrors: false,
        validationErrors: {}
      },
      ppeSelections: [
        {
          value: 1,
          text: "Safety Glasses",
          checked: false
        },
        {
          value: 2,
          text: "Fall Protection / Restraint Harness",
          checked: false
        },
        {
          value: 3,
          text: "Hard Hat",
          checked: false
        },
        {
          value: 4,
          text: "Hearing Protection",
          checked: false
        },
        {
          value: 5,
          text: "Protective Clothing",
          checked: false
        },
        {
          value: 6,
          text: "Face Shield",
          checked: false
        },
        {
          value: 7,
          text: "Protective Gloves",
          checked: false
        },
        {
          value: 8,
          text: "Hard Toe Shoes",
          checked: false
        },
        {
          value: 9,
          text: "High Visibility Vest",
          checked: false
        },
        {
          value: 10,
          text: "Breathing Respirator",
          checked: false
        }
      ],
      trainingSelections: [
        {
          value: 1,
          text: "On-site Training",
          checked: false,
          trainingTwoHours: null
        },
        {
          value: 2,
          text: "OSHA 10",
          checked: false
        },
        {
          value: 3,
          text: "Other",
          checked: false
        }
      ],
      isSiteDetails: false,
      showErrors: false,
      validationErrors: {}
    },
    attachment: null
  }
};

const viewOrderDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
    case types.ORDER_DETAILS_REQUEST:
      var result = Object.assign({}, state, {
        data: action.data || initialState.data
      });

      result.data.isLoading = true;

      return result;
    case types.ORDER_DETAILS_FAILURE:
      var result = Object.assign({}, state, {
        data: action.data || initialState.data
      });
      return result;
    case types.ORDER_DETAILS_SUCCESS:
      var result = Object.assign({}, state, {
        data: initialState.data,
        form: initialState.form
      });

      result.siteCount = action.data.data.orderDetails.siteCount;

      result.forms = [];

      for (let i = 0; i < action.data.data.orderDetails.siteCount; i++) {
        var newForm = new Object(null);

        var orderDetails = action.data.data.orderDetails;
        var applicants = action.data.data.applicants.filter(
          (a) => a.tabIndex == i
        );
        var ahjs = action.data.data.authorityJurisdictions.filter(
          (a) => a.tabIndex == i
        );
        var engineers = action.data.data.engineers.filter(
          (a) => a.tabIndex == i
        );
        var product = action.data.data.products.filter(
          (a) => a.tabIndex == i
        )[0];
        var productItems = action.data.data.productItems.filter(
          (a) => a.fieldEvaluationProductId == product.id
        );
        var quoteAcceptances = action.data.data.quoteAcceptances.filter(
          (a) => a.tabIndex == i
        )[0];
        var sites = action.data.data.sites.filter((a) => a.tabIndex == i);
        var attachment = action.data.data.attachments.filter(
          (a) => a.tabIndex == i
        )[0];

        newForm = {
          otherDetails: {
            customerReferenceNumber: quoteAcceptances.customerReferenceNumber,
            purchaseOrderNumber: quoteAcceptances.purchaseOrderNumber,
            isReadyToScheduleNow: quoteAcceptances.isReadyToScheduleNow,
            scheduleDate: quoteAcceptances.scheduleDate,
            scheduleTime: quoteAcceptances.scheduleTime
          },
          orderDetails: orderDetails,
          applicantDetails: {
            applicantDetails: applicants.filter((f) => f.locationType === 1)[0],
            applicantBillToLocationDetails: applicants.filter(
              (f) => f.locationType === 2
            )[0]
          },
          quoteAcceptanceDetails: quoteAcceptances,
          siteDetails: {
            preliminarySiteLocationSection: sites.filter(
              (f) => f.siteType === 1
            )[0],
            finalInstallationSiteSection: sites.filter(
              (f) => f.siteType === 2
            )[0]
          },
          authorityHavingJurisdictionDetails: ahjs,
          productDetails: product,
          productItems: productItems,
          reservationDetails: {
            preliminarySiteLocationSection: engineers.filter(
              (f) => f.siteType === 1
            )[0],
            finalSiteLocationSection: engineers.filter(
              (f) => f.siteType === 2
            )[0]
          },
          attachment: attachment
        };

        result.forms.push(newForm);
      }

      result.form.orderDetails = action.data.data.orderDetails;
      result.form.orderDetails.preferredScheduleDate =
        action.data.data.preferredScheduleDate;
      result.form.orderDetails.preferredScheduleTime =
        action.data.data.preferredScheduleTime;

      result.form.orderDetails = action.data.data.orderDetails;
      result.form.orderDetails.showErrors = false;
      result.form.orderDetails.validationErrors = {};

      result.form.otherDetails.isReadyToScheduleNow =
        action.data.data.orderDetails.isReadyToScheduleNow;

      result.form.otherDetails.customerReferenceNumber =
        action.data.data.orderDetails.clientReferenceNumber;

      result.form.applicantDetails.applicantDetails = action.data.data.applicants.filter(
        (f) => f.locationType === 1
      )[0];

      result.form.applicantDetails.applicantBillToLocationDetails = action.data.data.applicants.filter(
        (f) => f.locationType === 2
      )[0];

      result.form.applicantDetails.quoteAcceptanceDetails =
        action.data.data.quoteAcceptances;

      result.form.reservationDetails.preliminarySiteLocationSection = action.data.data.engineers.filter(
        (f) => f.siteType === 1
      )[0];

      result.form.reservationDetails.finalSiteLocationSection = action.data.data.engineers.filter(
        (f) => f.siteType === 2
      )[0];

      result.form.siteDetails.preliminarySiteLocationSection = action.data.data.sites.filter(
        (f) => f.siteType === 1
      )[0];

      result.form.siteDetails.finalInstallationSiteSection = action.data.data.sites.filter(
        (f) => f.siteType === 2
      )[0];

      var scheduleUnixTime =
        result.form.reservationDetails.preliminarySiteLocationSection
          .scheduleUnixTime;
      result.form.reservationDetails.preliminarySiteLocationSection.scheduleDate = scheduleUnixTime
        ? new Date(scheduleUnixTime * 1000)
        : null;

      var inspectionLocation =
        result.form.reservationDetails.preliminarySiteLocationSection
          .addressLine1 +
        ", " +
        result.form.reservationDetails.preliminarySiteLocationSection.city +
        ", " +
        result.form.reservationDetails.preliminarySiteLocationSection.state +
        ", " +
        result.form.reservationDetails.preliminarySiteLocationSection.country +
        ", " +
        result.form.reservationDetails.preliminarySiteLocationSection
          .postalCode;

      result.form.reservationDetails.preliminarySiteLocationSection.inspectionLocation = inspectionLocation;

      result.form.reservationDetails.authorityHavingJurisdictionDetails =
        action.data.data.authorityJurisdictions;

      result.form.productItems = action.data.data.productItems;

      result.form.attachment = action.data.data.attachments;

      if (result.form.productItems) {
        result.form.productItems.map((p) => {
          var temp = {};
          (p.others || []).map((o) => {
            if (o) {
              temp = update(temp, {
                $merge: _.set({}, o.code, o.info)
              });
            }
          });

          p.others = temp;
        });
      }

      result.form.reservationDetails.ppeSelections.forEach((item) => {
        item.checked = false;
      });

      result.form.reservationDetails.trainingSelections.forEach((item) => {
        item.checked = false;
      });

      result.isSuccessGetOrderDetails = true;

      return result;
    case types.ORDER_DETAILS_RESET:
      var result = Object.assign({}, state, {
        data: initialState.data
      });

      result.data.isLoading = false;

      return result;
    case types.FORM:
      var result = Object.assign({}, state, {
        form: action.data || initialState.form
      });
      return result;
    case types.CLEAR:
      return Object.assign({}, state, {
        data: initialState.data,
        form: {
          showErrors: false,
          validationErrors: {}
        }
      });
    case types.PPE_REQUEST:
      var result = Object.assign({}, state, {
        data: initialState.data,
        form: initialState.form
      });

      result.ppeSelections = [];

      return result;
    case types.PPE_SUCCESS:
      var result = Object.assign({}, state, {
        data: initialState.data,
        form: initialState.form
      });

      result.ppeSelections = action.data.map((c) => {
        return {
          value: c.id,
          text: c.description,
          checked: false
        };
      });

      return result;
    case types.PPE_FAILURE:
      var result = Object.assign({}, state, {
        data: initialState.data,
        form: initialState.form
      });

      result.ppeSelections = [];

      return result;
    case types.TRAINING_REQUEST:
      var result = Object.assign({}, state, {
        data: initialState.data,
        form: initialState.form
      });

      result.trainingSelections = [];

      return result;
    case types.TRAINING_SUCCESS:
      var result = Object.assign({}, state, {
        data: initialState.data,
        form: initialState.form
      });

      result.trainingSelections = action.data.map((c) => {
        return {
          value: c.id,
          text: c.description,
          checked: false
        };
      });

      return result;
    case types.TRAINING_FAILURE:
      var result = Object.assign({}, state, {
        data: initialState.data,
        form: initialState.form
      });

      result.trainingSelections = [];

      return result;

    case types.UPDATE_ISSUCCESS:
      var result = Object.assign({}, state, {
        data: initialState.data,
        form: initialState.form
      });

      result.isSuccessGetOrderDetails = false;

      return result;
  }
};

export default viewOrderDetailsReducer;
