import * as types from "./labels.type";
import api from "../../../../services/api";

const labelsRequest = () => {
  return { type: types.LABELS_REQUEST };
};

const labelsFailure = (error) => {
  return (dispatch) => dispatch({ type: types.LABELS_FAILURE, payload: error });
};

const labelsSuccess = (response) => {
  return (dispatch) =>
    dispatch({ type: types.LABELS_SUCCESS, payload: response });
};

export const resetLabelOrderForm = () => {
  return { type: types.RESET_LABEL_ORDER_FORM };
};

export const selectLabel = (data, isSelected) => {
  return (dispatch) =>
    dispatch({ type: types.SELECT_LABEL, payload: { data, isSelected } });
};

export const selectAllLabels = (isSelectedAll) => {
  return (dispatch) =>
    dispatch({ type: types.TOGGLE_ALL_LABELS, payload: { isSelectedAll } });
};

export const setQuantity = (data, quantity) => {
  return (dispatch) =>
    dispatch({ type: types.SET_QUANTITY, payload: { data, quantity } });
};

export const setUnitOfMeasurement = (data, uom) => {
  return (dispatch) =>
    dispatch({ type: types.SET_UNIT_OF_MEASUREMENT, payload: { data, uom } });
};

export const removeCartItems = (cartItemIds) => {
  return (dispatch) =>
    dispatch({
      type: types.MANUFACTURER_STANDARD_LABEL_ITEMS_REMOVE,
      payload: { cartItemIds }
    });
};

export const getLabels = (
  labelOrderCartId,
  ccn,
  manufacturerId,
  fileNumber,
  ccnList,
  fileNumberList,
  volume,
  volumeList,
  accountNumber
) => {
  return async (dispatch) => {
    dispatch(labelsRequest());
    const result = await api.Manufacturer.getLabels(
      labelOrderCartId,
      ccn,
      manufacturerId,
      fileNumber,
      ccnList,
      fileNumberList,
      volume,
      volumeList,
      accountNumber
    );

    if (result) {
      dispatch(labelsSuccess(result));
    } else {
      dispatch(labelsFailure("Something went wrong."));
    }
  };
};

const addToCartRequest = () => {
  return { type: types.ADD_TO_CART_REQUEST };
};

const addToCartFailure = (error) => {
  return (dispatch) =>
    dispatch({ type: types.ADD_TO_CART_FAILURE, payload: error });
};

const addToCartSuccess = (response) => {
  return (dispatch) =>
    dispatch({ type: types.ADD_TO_CART_SUCCESS, payload: response });
};

export const resetAddToCart = () => {
  return { type: types.ADD_TO_CART_RESET };
};

export const addToCart = (cartData) => {
  return async (dispatch) => {
    dispatch(addToCartRequest());
    const result = await api.Manufacturer.addToCart(cartData);

    if (result) {
      dispatch(addToCartSuccess(result));
      return result;
    } else {
      dispatch(addToCartFailure("Something went wrong."));
    }
  };
};
