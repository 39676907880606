import * as types from "./quote-sites.type";

const initialState = {
  sitesCount: null,
  isSameDayVisit: null
};
const quoteSitesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_SITES_COUNT:
      return {
        ...state,
        sitesCount: action.data
      };
    case types.UPDATE_SAMEDAY_VISIT:
      return {
        ...state,
        isSameDayVisit: action.data
      };
    case types.CLEAR_QUOTE_SITES:
      return initialState;
    default:
      return state;
  }
};

export default quoteSitesReducer;
