import { Common } from "../../common/common";
import Role from "../../enums/role";
import * as types from "./help-me.type";

let initialState = {
  data: [],
  loading: false,
  isSuccess: false,
  labelOrderType: 0,
  psn: null,
  userId: null,
  isUpdateIsViewedSuccess: false,
  cartOrders: [],
  cartOrdersOption: [],
  unreadMessageCount: 0,
  form: {
    showErrors: false,
    validationErrors: {}
  },
  labelCenterOption: []
};

const helpMeReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
    case types.GET_CART_ORDERS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.GET_CART_ORDERS_SUCCESS:
    case types.GET_CART_ORDERS_FAILURE:
      var result = Object.assign({}, state, {
        loading: false,
        cartOrders:
          (action.data && action.data.data.userLabelOrders) ||
          initialState.data,
        cartOrdersOption:
          action.data &&
          action.data.data.userLabelOrders.map((i) => {
            return {
              key: i.id,
              value: i.requestNumber,
              text: i.requestNumber
            };
          }),
        labelOrderType: action.data.data.labelOrderType,
        psn: action.data.data.partySiteNumber,
        userId: action.data.data.userId,
        isSuccess: action.data.isSuccessful
      });

      if (Common.getUserRole() != Role.printer) {
        let cartText =
          Common.getUserRole() != Role.labelCenter
            ? "My Cart"
            : "Customer's Cart";
        const cart = { key: 0, value: "cart", text: cartText };
        // if (result.cartOrdersOption.filter((s) => s.key == 0).length > 0)
        result.cartOrdersOption.unshift(cart);
      }

      return result;
    case types.CREATE_MESSAGE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.CREATE_MESSAGE_SUCCESS:
    case types.CREATE_MESSAGE_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        isSuccess: action.data.isSuccessful
      });

    case types.GET_UNREAD_MESSAGE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.GET_UNREAD_MESSAGE_SUCCESS:
    case types.GET_UNREAD_MESSAGE_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        unreadMessageCount: action.data
          ? action.data.data
          : initialState.unreadMessageCount,
        isSuccess: action.data ? action.data.isSuccessful : false
      });

    case types.UPDATE_IS_VIEWED_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.UPDATE_IS_VIEWED_SUCCESS:
    case types.UPDATE_IS_VIEWED_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        isUpdateIsViewedSuccess: action.data.isSuccessful,
        unreadMessageCount: action.data.isSuccessful
          ? state.unreadMessageCount - action.data.data
          : state.unreadMessageCount
      });

    case types.HELP_ME_FORM:
      return Object.assign({}, state, {
        form: action.data || initialState.form
      });
    case types.HELP_ME_CLEAR:
      return Object.assign({}, state, {
        cartOrders: [],
        cartOrdersOption: [],
        isSuccess: false,
        loading: false,
        form: {
          showErrors: false,
          validationErrors: {}
        }
      });
    case types.CART_MESSAGE_REQUEST:
    case types.CART_MESSAGE_SUCCESS:
    case types.CART_MESSAGE_FAILURE:
      return Object.assign({}, state, {
        data: action.data || initialState.data
      });
    case types.ADD_MESSAGE:
      return Object.assign({}, state, {
        data: Object.assign({}, state.data, {
          data: action.data || initialState.data
        })
      });
    case types.CLEAR_FORM_ONLY:
      return Object.assign({}, state, {
        form: Object.assign({}, state.form, {
          helpMeMessage: "",
          showErrors: false,
          validationErrors: {}
        })
      });

    case types.GET_LABAEL_CENTER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.GET_LABAEL_CENTER_SUCCESS:
    case types.GET_UNREAD_MESSAGE_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        labelCenterOption:
          action.data &&
          action.data.data.map((name) => {
            return {
              key: name,
              value: name,
              text: name
            };
          }),
        isSuccess: action.data.isSuccessful
      });

    case types.GET_HELPME_TOTAL_REQUEST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case types.GET_HELPME_TOTAL_REQUEST_SUCCESS:
    case types.GET_HELPME_TOTAL_REQUEST_FAILURE:
      return Object.assign({}, state, {
        loading: false,
        helpMeTotalRequest: action.data
          ? action.data.data
          : initialState.totalRequestCount,
        isSuccess: action.data ? action.data.isSuccessful : false
      });
  }
};

export default helpMeReducer;
