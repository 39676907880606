import * as types from "./view-draft-quote.type";
import api from "../../../services/api";

export const viewDraftQuoteResult = (obj, type) => {
  return {
    type: type,
    data: obj
  };
};

export const getFEDraftQoutes = (currentPage, pageSize) => {
  return async dispatch => {
    dispatch(viewDraftQuoteResult(null, types.FE_QUOTE_DRAFT_REQUEST));
    await api.FieldEvaluation.getOrders(currentPage, pageSize)
      .then(result => {
        dispatch(viewDraftQuoteResult(result, types.FE_QUOTE_DRAFT_SUCCESS));
      })
      .catch(result => {
        dispatch(
          viewDraftQuoteResult(
            result.response && result.response.data,
            types.FE_QUOTE_DRAFT_FAILURE
          )
        );
      });
  };
};

export const getFEDraftQoutesByFilter = (status, currentPage, pageSize) => {
  return async dispatch => {
    dispatch(
      viewDraftQuoteResult(null, types.FE_QUOTE_DRAFT_BY_FILTER_REQUEST)
    );
    await api.FieldEvaluation.getOrdersByFilter(status, currentPage, pageSize)
      .then(result => {
        dispatch(
          viewDraftQuoteResult(result, types.FE_QUOTE_DRAFT_BY_FILTER_SUCCESS)
        );
      })
      .catch(result => {
        dispatch(
          viewDraftQuoteResult(
            result.response && result.response.data,
            types.FE_QUOTE_DRAFT_BY_FILTER_FAILURE
          )
        );
      });
  };
};

export const getFEDraftQuotesByFilterByUser = (
  status,
  currentPage,
  pageSize
) => {
  return async dispatch => {
    dispatch(
      viewDraftQuoteResult(
        null,
        types.FE_ORDER_HISTORY_BY_FILTER_BY_USER_REQUEST
      )
    );
    await api.FieldEvaluation.getOrdersByFilterByUser(
      status,
      currentPage,
      pageSize
    )
      .then(result => {
        dispatch(
          viewDraftQuoteResult(
            result,
            types.FE_ORDER_HISTORY_BY_FILTER_BY_USER_SUCCESS
          )
        );
      })
      .catch(result => {
        dispatch(
          viewDraftQuoteResult(
            result.response && result.response.data,
            types.FE_ORDER_HISTORY_BY_FILTER_BY_USER_FAILURE
          )
        );
      });
  };
};

export const deleteDraftQuote = requestNumber => {
  return async dispatch => {
    dispatch(viewDraftQuoteResult(null, types.FE_QUOTE_DRAFT_DELETE_REQUEST));
    await api.FieldEvaluation.deleteOrder(requestNumber)
      .then(result => {
        dispatch(
          viewDraftQuoteResult(result, types.FE_QUOTE_DRAFT_DELETE_SUCCESS)
        );
      })
      .catch(result => {
        dispatch(
          viewDraftQuoteResult(
            result.response && result.response.data,
            types.FE_QUOTE_DRAFT_DELETE_FAILURE
          )
        );
      });
  };
};

export const changePage = (activePage, pageSize) => {
  return async dispatch => {
    dispatch(
      viewDraftQuoteResult({ activePage }, types.FE_QUOTE_DRAFT_REQUEST)
    );
    await api.Order.getOrders(activePage, pageSize)
      .then(result => {
        dispatch(viewDraftQuoteResult(result, types.FE_QUOTE_DRAFT_SUCCESS));
      })
      .catch(result => {
        dispatch(
          viewDraftQuoteResult(
            result.response && result.response.data,
            types.FE_QUOTE_DRAFT_FAILURE
          )
        );
      });
  };
};

export const updateForm = result => {
  return dispatch => dispatch(viewDraftQuoteResult(result, types.FORM));
};
