String.prototype.toCapitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

/**
 * @return {boolean}
 */
Object.IsEmpty = (obj) => {
  if (!obj || obj === null) return true;

  for (let prop in obj)
    if (Object.prototype.hasOwnProperty.call(obj, prop)) return false;
  return true;
};

/**
 * @return {boolean}
 */
Object.IsArray = (obj) => {
  return obj instanceof Array;
};

/**
 * @return {boolean}
 */
Object.IsString = (obj) => {
  return typeof obj === "string";
};

/**
 * @return {boolean}
 */
String.IsNullOrEmpty = (str) => {
  return str === null || str === "";
};

/**
 * String.prototype.toMoney(n, x)
 *
 * @param integer n: length of decimal
 * @param integer x: length of sections
 */
String.prototype.toMoney = (n, x) => {
  const val = parseFloat(this);

  return val.format(n, x);
};

/**
 * Number.prototype.format(n, x)
 *
 * @param integer n: length of decimal
 * @param integer x: length of sections
 */
Number.prototype.format = (n, x) => {
  const re = "\\d(?=(\\d{" + (x || 3) + "})+" + (n > 0 ? "\\." : "$") + ")";
  return this.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, "g"), "$&,");
};

/**
 * Number.format(v, n, x)
 *
 * @param number v: value
 * @param integer n: length of decimal
 * @param integer x: length of sections
 */
Number.format = (v, n, x) => {
  const re = "\\d(?=(\\d{" + (x || 3) + "})+" + (n > 0 ? "\\." : "$") + ")";
  return v.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, "g"), "$&,");
};

String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

Date.prototype.toDateStringFormat = function () {
  return (
    this.getFullYear() +
    "-" +
    (this.getMonth() + 1) +
    "-" +
    this.getDate() +
    " " +
    this.getHours() +
    ":" +
    this.getMinutes() +
    ":" +
    this.getSeconds()
  );
};

Date.prototype.toTimeOnlyStringFormat = function () {
  var hours = this.getHours();
  var minutes = this.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};

Date.prototype.toDateOnlyStringFormat = function () {
  return this.getMonth() + 1 + "/" + this.getDate() + "/" + this.getFullYear();
};

Date.prototype.toDateStringOnly = function() {
 var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

 return months[this.getMonth()] + ' ' + this.getDate() + ', ' + this.getFullYear();
};

Date.prototype.toDateTimeStringOnly = function() {
 return this.toDateStringOnly() + ' ' + this.toTimeOnlyStringFormat(); 
};
