import * as types from './combination-label-search.type';
import api from '../../services/api';

export const getCombinationLabelsByFilter = (
  applicantAccountNumber,
  manufacturerAccountNumber,
  fileNumber,
  itemNumber,
  customerPartNo,
  currentPage,
  pageSize,
  isForExport
) => {
  return async dispatch => {
    if (isForExport) {
      dispatch({ type: types.COMBINATION_LABEL_SEARCH_FOR_EXPORT_REQUEST });

      const result = await api.Order.getCombinationLabelsByFilter(
        applicantAccountNumber,
        manufacturerAccountNumber,
        fileNumber,
        itemNumber,
        customerPartNo,
        currentPage,
        pageSize
      );

      if (result) {
        dispatch({
          type: types.COMBINATION_LABEL_SEARCH_FOR_EXPORT_SUCCESS,
          payload: result,
        });
      } else {
        dispatch({
          type: types.COMBINATION_LABEL_SEARCH_FOR_EXPORT_FAILURE,
          payload: result,
        });
      }
      return result;
    } else {
      dispatch({ type: types.COMBINATION_LABEL_SEARCH_REQUEST });

      const result = await api.Order.getCombinationLabelsByFilter(
        applicantAccountNumber,
        manufacturerAccountNumber,
        fileNumber,
        itemNumber,
        customerPartNo,
        currentPage,
        pageSize
      );

      if (result) {
        dispatch({
          type: types.COMBINATION_LABEL_SEARCH_SUCCESS,
          payload: result,
        });
      } else {
        dispatch({
          type: types.COMBINATION_LABEL_SEARCH_FAILURE,
          payload: result,
        });
      }
      return result;
    }
  };
};

export const updateForm = form => {
  return dispatch =>
    dispatch({
      type: types.UPDATE_COMBINATION_LABEL_SEARCH_FORM,
      payload: form,
    });
};

export const getExistingCombinationLabels = (
  fileNumber,
  itemNumber,
  applicantAccount,
  manufacturerAccount,
  currentPage,
  pageSize,
  id,
  isCopied
) => {
  return async dispatch => {
    dispatch({
      type: types.GET_COMBINATION_LABELS_BY_FILTER_REQUEST,
    });
    const result = await api.Order.getExistingLabelOrders(
      fileNumber,
      itemNumber,
      applicantAccount,
      manufacturerAccount,
      currentPage,
      pageSize
    );

    if (result) {
      dispatch({
        type: types.GET_COMBINATION_LABELS_BY_FILTER_SUCCESS,
        payload: result,
      });
      return result;
    } else {
      dispatch({
        type: types.GET_COMBINATION_LABELS_BY_FILTER_FAILURE,
        payload: result,
      });
    }
  };
};

export const resetSearch = () => {
  return dispatch =>
    dispatch({
      type: types.COMBINATION_LABEL_SEARCH_RESET,
    });
};

export const updateFileNumberList = result => {
  return dispatch =>
    dispatch({
      type: types.UPDATE_FILE_NUMBER_LIST,
      payload: result,
    });
};
