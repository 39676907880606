import React, { Component } from "react";

import {
  Button,
  Grid,
  Divider,
  Icon,
  Menu,
  Sidebar,
  Accordion,
  List,
  Form,
  Radio,
  Dropdown,
  Input
} from "semantic-ui-react";
import { CustomHeaderInlineBold } from "../../../components/labels/labels";

class ColumnOptions extends Component {
  state = {};
  render() {
    const {
      isColumnOptVisible,
      activeIndex,
      columnOptions,
      columnSortable
    } = this.props;
    return (
      <Sidebar
        as={Menu}
        animation="overlay"
        vertical
        direction="left"
        visible={isColumnOptVisible}
        width="wide"
      >
        <div style={{ padding: "20px" }}>
          <Grid columns={2}>
            <Grid.Column verticalAlign="middle">
              <CustomHeaderInlineBold message="Column Options" />
            </Grid.Column>
            <Grid.Column textAlign="right">
              <Button
                basic
                icon="delete"
                onClick={this.props.handleToggleColumn}
              ></Button>
            </Grid.Column>
          </Grid>
          <Divider />
          <Accordion fluid>
            <Accordion.Title
              active={activeIndex === 0}
              index={0}
              onClick={this.props.handleClick}
            >
              <Icon name="dropdown" />
              Columns
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 0}>
              <List>
                {columnOptions &&
                  columnOptions.map((column, idx) => {
                    return (
                      column &&
                      column.checked === 1 && (
                        <List.Item key={idx}>
                          <Grid>
                            <Grid.Row centered>
                              <Input
                                icon="dropdown"
                                iconPosition="left"
                                value={column.text}
                                disabled
                                style={{ marginRight: "10px" }}
                              />

                              <Button
                                color="red"
                                basic
                                icon="delete"
                                disabled={
                                  columnOptions.filter((i) => i.checked === 1)
                                    .length > 1
                                    ? false
                                    : true
                                }
                                onClick={() => {
                                  this.props.handleRemoveColumn(column.key);
                                }}
                              />
                            </Grid.Row>
                          </Grid>
                        </List.Item>
                      )
                    );
                  })}
                {columnOptions.filter((i) => i.checked === 0).length > 0 && (
                  <List.Item>
                    <Grid>
                      <Grid.Row centered>
                        <Dropdown
                          placeholder="Select column"
                          selection
                          onChange={this.props.handleDdColumnChange.bind(this)}
                          options={columnOptions.filter((i) => i.checked == 0)}
                        />

                        <Button
                          color="green"
                          basic
                          icon="add"
                          style={{ marginLeft: "20px" }}
                          onClick={() => {
                            this.props.handleAddColumn();
                          }}
                        />
                      </Grid.Row>
                    </Grid>
                  </List.Item>
                )}
              </List>
            </Accordion.Content>

            <Accordion.Title
              active={activeIndex === 1}
              index={1}
              onClick={this.props.handleClick}
            >
              <Icon name="dropdown" />
              Sorting
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 1}>
              <Form.Field
                control={Radio}
                toggle
                label="Enable table sorting."
                name="sort"
                onChange={(e) => {
                  this.props.handleColumnSortChange(e);
                }}
                value={columnSortable}
              />
            </Accordion.Content>
          </Accordion>
          <Divider />
          <Grid>
            <Grid.Column textAlign="right">
              <Button
                basic
                color="blue"
                content="Clear"
                onClick={this.props.handleClearFilter}
              ></Button>
              <Button
                basic
                color="green"
                content="Apply"
                onClick={this.props.handleApplyColumnOptions}
              ></Button>
            </Grid.Column>
          </Grid>
        </div>
      </Sidebar>
    );
  }
}

export default ColumnOptions;
