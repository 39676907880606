import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import * as actions from "../auth/auth.action";
import { connect } from "react-redux";
import { randomBytes, createHash } from "crypto-browserify";
import { Button } from "semantic-ui-react";
import { Common } from "../../common";
import IdentityProvider from "../../enums/identity-provider";

const API_BASE = `${process.env.REACT_APP_API_BASE}`;
const IDENTITY_PROVIDER = `${process.env.REACT_APP_IDENTITY_PROVIDER}`;

class Main extends Component {
  state = {
    redirect: false
  };
  componentDidMount() {
    //this.props.validateLogin();
  }

  render() {
    var userId = Common.getUserId();

    if (window) {
      localStorage.setItem("framesLength", window.parent.frames.length);
    }

    if (IDENTITY_PROVIDER == IdentityProvider.Iam) {
      var framesLength = localStorage.getItem("framesLength");
      if (framesLength > 0) {
        setTimeout(() => {
          var externalAuthURL = `${API_BASE}auth/external`;
          window.location = externalAuthURL;

          console.log("IAM challenge Login");
        }, 100);
      } else {
        if (!userId) {
          var externalAuthURL = `${API_BASE}auth/external`;
          window.location = externalAuthURL;

          console.log("IAM challenge Login");
        } else {
          console.log("IAM already logged Login");
          return <Redirect to="/home" />;
        }
      }
    } else if (IDENTITY_PROVIDER == IdentityProvider.B2c) {
      var framesLength = localStorage.getItem("framesLength");
      if (framesLength > 0) {
        Common.toggleSilentLogin();
        console.log("B2C challenge Login");
      } else {
        if (!userId) {
          Common.toggleLogin();
          console.log("B2C login");
        } else {
          console.log("B2C already logged in");
          return <Redirect to="/home" />;
        }
      }
    }
    return "";
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    validateLogin: () => dispatch(actions.validateLogin())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
