import React, { Component, Fragment } from "react";
import {
  Button,
  // Checkbox,
  Container,
  Divider,
  Form,
  Grid,
  Header,
  Icon,
  Image,
  Input,
  Label,
  Modal,
  Table
} from "semantic-ui-react";
import {
  CustomHeader,
  CustomHeaderLabel,
  CustomTitleLabel,
  SubLabel
} from "../../../components/labels/labels";
import OrderWorkflow from "./label-order-workflow";
import LabelCustomerInformation from "./label-customer-information";
import FooterHomeBlock from "../../../components/footer/footer-home-block";
import { Common } from "../../../common/common";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2
});

class LabelOrderConfirmation extends Component {
  render() {
    const translations = Common.getTranslations() || {};
    const data = [];
    return (
      <Fragment>
        <div style={{ width: "60%", padding: "20px" }}>
          <Container fluid>
            <Grid>
              <Grid.Column width={5}>
                <CustomHeader message="Cart order #9003487199" />
              </Grid.Column>
              <Grid.Column width={4}>
                <Button basic color="blue">
                  Print
                </Button>

                <Modal
                  size="mini"
                  trigger={<Button color="blue"> Email a copy</Button>}
                  closeIcon
                >
                  <Header content="Email a copy" />
                  <Modal.Content>
                    <p>
                      Send a record of your cart to yourself or another email
                      address.
                    </p>
                    <Form>
                      <Form.Field
                        fluid
                        id="form-input-control-email-address"
                        control={Input}
                        label="Email address"
                        placeholder="Email address"
                        name="email"
                        autoComplete="off"
                        maxLength={254}
                        required
                      />
                    </Form>
                  </Modal.Content>
                  <Modal.Actions>
                    <Button primary>
                      <Icon name="checkmark" /> Send
                    </Button>
                  </Modal.Actions>
                </Modal>
              </Grid.Column>
            </Grid>

            <OrderWorkflow />
            <LabelCustomerInformation model={{}} />
            {data &&
              data.map((item, idx) => {
                return (
                  <Table compact size="small" key={idx}>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>
                          File #: {item.fileNumber}
                        </Table.HeaderCell>
                        <Table.HeaderCell />
                        <Table.HeaderCell />
                        <Table.HeaderCell />
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {item.cartItems &&
                        item.cartItems.map((cartItem, index) => {
                          return (
                            <Table.Row key={index}>
                              <Table.Cell
                                style={{ textAlign: "right" }}
                                width={2}
                              >
                                {cartItem.ccn}
                                <Divider hidden fitted />
                                <SubLabel message="ITEM NUMBER" />
                                <Divider hidden fitted />
                                <SubLabel message="LABEL DESCRIPTION" />
                              </Table.Cell>
                              <Table.Cell
                                style={{ textAlign: "left" }}
                                width={8}
                              >
                                {cartItem.labelAccount}
                                <Divider hidden fitted />
                                <SubLabel message={cartItem.itemNumber} />
                                <Divider hidden fitted />
                                <SubLabel message={cartItem.description} />
                              </Table.Cell>
                              <Table.Cell
                                style={{ textAlign: "center" }}
                                width={2}
                              >
                                <Image size="small" src={cartItem.imageUrl} />
                              </Table.Cell>
                              <Table.HeaderCell
                                style={{ textAlign: "center" }}
                                width={1}
                              />
                              <Table.Cell style={{ textAlign: "right" }}>
                                <SubLabel message="QUANTITY" />
                                <Divider hidden fitted />
                                <Divider hidden fitted />
                                <Divider hidden fitted />
                                <Divider hidden fitted />
                                <SubLabel message="PRICE" />
                              </Table.Cell>
                              <Table.Cell width={3}>
                                <span>
                                  <SubLabel
                                    message={formatter.format(
                                      cartItem.quantity
                                    )}
                                  />
                                </span>
                                <Divider hidden fitted />
                                <SubLabel
                                  message={formatter.format(cartItem.price)}
                                />
                              </Table.Cell>
                            </Table.Row>
                          );
                        })}
                    </Table.Body>
                  </Table>
                );
              })}
            {data && data.length === 0 && (
              <Table>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>
                      <CustomTitleLabel message="NO DATA FOUND." />
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            )}

            <Grid>
              <Grid.Column style={{ textAlign: "right" }}>
                <CustomHeaderLabel message="Net total: " />
                <Label key="big" size="big" style={{ marginRight: "10px" }}>
                  <span style={{ color: "#00518a" }}>
                    USD {formatter.format(123434)}
                  </span>
                </Label>
                <span>
                  <Button basic primary compact onClick={this.handleCheckout}>
                    {translations["LexCheckout"]}
                  </Button>
                </span>
              </Grid.Column>
            </Grid>
            <FooterHomeBlock />
          </Container>
        </div>
      </Fragment>
    );
  }
}

export default LabelOrderConfirmation;
