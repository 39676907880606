import React, { Component, Fragment } from "react";

import { connect } from "react-redux";

import {
  Divider,
  Table,
  Grid,
  Button,
  Container,
  Card,
  Header,
  Modal,
  Popup,
  Menu,
  Checkbox,
  Dropdown,
  Form
} from "semantic-ui-react";

import {
  CustomHeaderBold,
  CustomSubLabel,
  CustomLabel,
  CustomTitle,
  CustomTitleLabel
} from "../../../components/labels/labels";
import { Link } from "react-router-dom";

import * as actions from "./view-order-details.action";
import { getAccountSettings } from "../account-settings/account-settings.action";
import OrderDetails from "./order-details";
import InspectionDetails from "./inspection-details";
import OrderItemInfo from "./order-item-info";
import SchedulingDetails from "./scheduling-details";
import TermsAndConditions from "./terms-and-conditions";
import ServiceTerms from "./service-terms";

// FES
import FesPrice from "../product-details/fes/price";
import FesAssumption from "../product-details/fes/assumption";
import FesTermsAndConditions from "../product-details/fes/t-and-c";
import FeServiceTerms from "../product-details/fes/service-terms";
import Acceptance from "../product-details/fe/acceptance";

// FE
import FeScope from "../product-details/fe/scope";
import FePrice from "../product-details/fe/price";
import FeAssumption from "../product-details/fe/assumption";
import FeCancellation from "../product-details/fe/cancellation";
import FeTermsAndConditions from "./fe/terms-and-conditions";

import * as vodActions from "../view-order-details/view-order-details.action";
import * as vhActions from "../view-history/view-history.action";
import * as feActions from "../product-details/fe/product-details.action";
import * as fActions from "../field-evaluation.action";
import * as multiActions from "../field-service/new-quote-details/new-quote-details.action";
import * as schedActions from "../field-service/scheduling-details/scheduling-details.action";
import * as projectActions from "../field-service/project-details/project-details.action";
import ToastHelper from "../../../helpers/toast.helper";

import Role from "../../../enums/role";
import { Common } from "../../../common";
import { Redirect } from "react-router-dom";
import CancellationCode from "../cancel-order/cancellation-code";

import {
  doCancelOrder,
  clearCancelOrder,
  resetCancelOrder
} from "../cancel-order/cancel-order.action";
import QuoteHeader from "../product-details/quote-header";
import { renderQuotationBody } from "../../../helpers/component-helper";
import ProjectDetails from "./project-details";
import QuoteAssumptions from "./quote-assumptions";
import GlobalServicesAgreement from "./fe/global-services-agreement";
import Status from "../../../enums/status";

import { validateField } from "../../../validator/validator";
import FeSummary from "../product-details/fe/summary";
import FeSummaryMulti from "../../field-evaluation/field-service/common/review-summary/fe-summary";

const rules = [];

class ViewOrderDetails extends Component {
  state = {
    openSummaryModal: false,
    isOpenTac: false,
    isCheckAgree: false,
    isOpenCancelModal: false,
    feOrderId: 0,
    isShowCancellationCodeModal: false,
    isOpenProjectHandlerModal: false,
    isOpenUnAssignProjectHandlerModal: false,
    projectHandlerName: null,
    openSummaryModalMulti: false
  };

  htmlBody = renderQuotationBody();

  handleRenderHtmlBody = (html) => {
    html = html.replace('class="container-wrapper" ', "");

    html = html.replace(
      '<div id="quotation"></div>',
      document.getElementById("quotation").outerHTML
    );

    html = html.replace(
      '<div id="quotation">',
      '<div style="padding: 20px;" id="quotation">'
    );

    html = html.replace(
      document.getElementById("checkbox-section").outerHTML,
      '<div id="checkbox-section"></div>'
    );
    html = html.replace(
      document.getElementById("acceptance-section").outerHTML,
      '<div id="acceptance-section"></div>'
    );

    return html;
  };

  componentDidMount() {
    this.props.getProjectHandlers();
    this.props.getPpes();
    this.props.getTrainings();
    var orderNumber = this.props.match.params.orderNumber;
    if (orderNumber) {
      this.props.getOrderDetails(orderNumber).then((s) => {
        const userId = s.data.orderDetails.createdForUserId;
        this.props.getAccountSettings(userId);
      });
      this.props.getFEOrderDetails(orderNumber);
    } else {
      this.props.getAccountSettings();
    }
  }

  handleOpenSummaryModal = () => {
    this.setState({ openSummaryModal: true });
  };

  // handleConfirmCompleteOrder = (isOpenTac, feOrderId, orderNumber) => {
  //   this.setState({ isOpenTac: isOpenTac, feOrderId: feOrderId });

  //   if (orderNumber) this.props.getOrderDetails(orderNumber);
  // };

  handleConfirmCompleteOrder = (
    openSummaryModal,
    feOrderId,
    orderNumber,
    siteCount
  ) => {
    let {
      getOrderDetailsMulti,
      renderProductDetails,
      renderSiteDetails,
      renderSchedulingDetails
    } = this.props;

    if (siteCount > 1) {
      if (orderNumber)
        getOrderDetailsMulti(orderNumber).then((result) => {
          if (result) {
            let count = result.data.orderDetails.siteCount;

            renderProductDetails(count, result.data);
            renderSiteDetails(count, result.data);
            renderSchedulingDetails(count, result.data);

            // handle offered to accepted quote
            let { otherDetails } = this.props.fieldService.form;
            otherDetails.isFromOffered = true;
            const newState = { ...this.props.fieldService.form };
            this.props.form(newState);

            this.setState({ openSummaryModalMulti: openSummaryModal });
          }
        });
    } else {
      if (orderNumber)
        this.props.getOrderDetails(orderNumber).then((s) => {
          if (s) this.setState({ openSummaryModal: openSummaryModal });

          // handle offered to accepted quote
          let { otherDetails } = this.props.fieldEvaluation.form;
          otherDetails.isFromOffered = true;
          const newState = { ...this.props.fieldEvaluation.form };
          this.props.form(newState);
        });
    }

    this.setState({ feOrderId: feOrderId });
  };

  handleCompleteOrder = () => {
    var { feOrderId } = this.state;

    var data = {
      fieldEvaluationOrderId: feOrderId,
      orderType: 2,
      quotation: this.handleRenderHtmlBody(this.htmlBody)
    };

    this.setState({ isOpenTac: false });

    return this.props.updateOrderType(data).then((s) => {
      if (s) {
        if (s.number != "") {
          this.setState({
            // isSuccess: true,
            refereceNmber: s.number,
            isShowButton: false,
            isLoading: ""
          });

          setTimeout(() => {
            ToastHelper.success({
              description: (
                <p>
                  {`Thank you for your business. Your request number is ${s.number}.
                  A Project Handler will be in touch with you as soon as
                  possible.`}
                </p>
              )
            });
          }, 100);
        } else {
          setTimeout(() => {
            ToastHelper.error({
              title: "Something went wrong",
              description: s.message
            });
          }, 100);
        }
      } else {
        this.setState({
          isLoading: ""
        });

        setTimeout(() => {
          ToastHelper.error({
            title: "Error",
            description: "Something went wrong"
          });
        }, 100);
      }
    });
  };

  handleConfirmCancelOrder = (isOpenCancelModal) => {
    this.setState({
      isOpenCancelModal: isOpenCancelModal
    });
  };

  handleCancellationCodeModal = (isShow, feOrderId) => {
    this.setState({
      isShowCancellationCodeModal: isShow,
      feOrderId: feOrderId
    });
  };

  handleCancelOrder = () => {
    var { feOrderId } = this.state;
    const { selectedCancellationCode } = this.props.cancelOrder;

    var data = {
      orderId: feOrderId,
      cancellationCodeId: selectedCancellationCode.id,
      cancellationOtherDesc: selectedCancellationCode.other
        ? selectedCancellationCode.other
        : null
    };

    this.props.doCancelOrder(data).then((s) => {
      if (s && s.isSuccessful) {
        let message = (
          <span>
            Quote # <b>{s.data.requestNumber}</b> has been cancelled
            <b>
              {" "}
              {s.data.cancellationCode.name}{" "}
              {s.data.cancellationOtherDesc
                ? " - " + s.data.cancellationOtherDesc
                : null}
            </b>
            . To reactivate: go to “View History” to copy and proceed with a new
            quote, or contact us at
            <a href="mailto:field@ul.com"> field@ul.com</a> or
            <a href="tel:877.854.3577"> 1-877-854-3577</a>, Ext 2
          </span>
        );

        setTimeout(() => {
          ToastHelper.success({
            description: <p>{message}</p>,
            time: 20000
          });
        }, 100);
      }
    });
    this.setState({
      isOpenCancelModal: false,
      isShowCancellationCodeModal: false
    });
  };

  onClickAgreeChecbox = () =>
    this.setState((prevState) => ({ isCheckAgree: !prevState.isCheckAgree }));

  handleAssignProjectHandler = (isOpenProjectHandlerModal, feOrderId) => {
    this.setState({
      isOpenProjectHandlerModal: isOpenProjectHandlerModal,
      feOrderId: feOrderId
    });
  };

  handleUnAssignProjectHandler = (
    isOpenUnAssignProjectHandlerModal,
    feOrderId,
    projectHandlerName
  ) => {
    this.setState({
      isOpenUnAssignProjectHandlerModal: isOpenUnAssignProjectHandlerModal,
      feOrderId: feOrderId,
      projectHandlerName: projectHandlerName
    });
  };

  handleConfirmAssignProjectHandler = () => {
    var { form } = this.props.viewHistory;
    var { feOrderId } = this.state;

    var data = {
      fieldEvaluationOrderId: feOrderId,
      projectHandlerId: form.projectHandler
    };

    this.props.assignProjectHandlers(data);

    this.setState({ isOpenProjectHandlerModal: false });
  };

  handleConfirmUnAssignProjectHandler = () => {
    var { feOrderId } = this.state;

    var data = {
      fieldEvaluationOrderId: feOrderId
    };

    this.props.unAssignProjectHandlers(data);

    this.setState({ isOpenUnAssignProjectHandlerModal: false });
  };

  handleDropdownChange = (dpName, dpValue) => {
    const { form } = this.props.viewHistory;
    validateField(rules, form, dpName, dpValue);
    const newState = { ...this.props.viewHistory.form };
    this.props.form(newState);
  };

  onCloseSummaryModal = () => {
    this.setState({ openSummaryModal: false });
  };

  onCloseSummaryModalMulti = () => {
    this.setState({ openSummaryModalMulti: false });
  };

  render() {
    const {
      forms,
      siteCount,
      ppeSelections,
      trainingSelections
    } = this.props.viewOrderDetails;

    const {
      orderDetails,
      productItems,
      siteDetails,
      reservationDetails,
      attachment,
      applicantDetails,
      otherDetails,
      productDetails
    } = this.props.viewOrderDetails.form;

    const {
      openSummaryModal,
      isOpenTac,
      isCheckAgree,
      isOpenCancelModal,
      isShowCancellationCodeModal,
      feOrderId,
      isOpenProjectHandlerModal,
      isOpenUnAssignProjectHandlerModal,
      projectHandlerName,
      openSummaryModalMulti
    } = this.state;

    const { form, projectHandlers } = this.props.viewHistory;

    const { cancelOrder } = this.props;

    const isSuccessCancelOrder = cancelOrder
      ? cancelOrder.cancelProcessResult.isSuccessful
      : false;

    const userRole = Common.getUserRole();

    let editLink =
      siteCount == 1
        ? `/home/view-order-details/edit/${
            orderDetails && orderDetails.orderNumber
          }`
        : `/home/quote-details/edit/${
            orderDetails && orderDetails.orderNumber
          }`;

    if (isSuccessCancelOrder) {
      this.props.resetCancelOrder();
      return userRole == Role.projectHandler ? (
        <Redirect to="/home/view-all-orders" />
      ) : (
        <Redirect to="/home/view-history" />
      );
    }

    if (
      this.props.isSuccessAssignProjectHandler ||
      isOpenUnAssignProjectHandlerModal
    ) {
      if (userRole == Role.projectHandler) {
        setTimeout(() => {
          var orderNumber = this.props.match.params.orderNumber;
          if (orderNumber) {
            this.props.getOrderDetails(orderNumber).then((s) => {
              const userId = s.data.orderDetails.createdForUserId;
              this.props.getAccountSettings(userId);
            });
          } else {
            this.props.getAccountSettings();
          }
          this.props.assignProjectHandlerReset();
        }, 3000);
      }
    }

    if (
      productItems?.length > 0 &&
      this.props.viewOrderDetails.isSuccessGetOrderDetails
    ) {
      this.props.getProductDetails(productItems[0]);
      this.props.updateIsSuccess();
    }

    let accountSettings = this.props.accountSettings;
    let primaryDetails = accountSettings.applicantDetails.applicantDetails;
    let billToDetails =
      accountSettings.applicantDetails.applicantBillToLocationDetails;

    const fe = this.props.feProductItem.form;

    // MULTI SITES
    let { fieldService, projectDetails, schedulingDetails } = this.props;
    let siteDetailsMulti = projectDetails.siteDetails;
    let orderDetailsMulti = (fieldService.form || {}).orderDetails;

    return !orderDetails ? (
      <div style={{ width: "80%", padding: "20px" }}>
        <Container fluid>
          <Card fluid>
            <Card.Content>
              <CustomLabel message="NO DATA FOUND." />
            </Card.Content>
          </Card>
          <Button basic primary compact as={Link} to={`/home/view-history`}>
            Back
          </Button>
        </Container>
      </div>
    ) : (
      <Fragment>
        <div style={{ width: "80%", padding: "20px" }}>
          {userRole == Role.fieldEngineer &&
          (orderDetails.orderNumber || orderDetailsMulti) ? (
            openSummaryModal ? (
              <FeSummary
                openSummaryModal={openSummaryModal}
                onCloseSummaryModal={this.onCloseSummaryModal}
                primaryDetails={primaryDetails}
                billToDetails={billToDetails}
                reservationDetails={reservationDetails}
                siteDetails={siteDetails}
                otherDetails={otherDetails}
                accountSettings={accountSettings}
                fe={fe}
                isFromOffered={true}
              />
            ) : openSummaryModalMulti ? (
              <FeSummaryMulti
                openSummaryModal={openSummaryModalMulti}
                handleCloseSummaryModal={this.onCloseSummaryModalMulti}
                forms={projectDetails.forms}
                schedulingForms={schedulingDetails.schedulingForms}
                primaryDetails={primaryDetails}
                billToDetails={billToDetails}
                siteDetails={siteDetailsMulti}
                isFromOffered={true} // always true, accepting from offered
              />
            ) : null
          ) : null}
          <Modal open={isOpenCancelModal} size="mini">
            <Modal.Header className="ui center aligned">
              Confirm Cancellation
            </Modal.Header>
            <Modal.Content scrolling>
              Are you sure you want to cancel? This cannot be undone.
            </Modal.Content>
            <Modal.Actions>
              <Button
                primary
                onClick={() => this.setState({ isOpenCancelModal: false })}
              >
                No
              </Button>
              <Button
                secondary
                basic
                onClick={this.handleCancelOrder.bind(this)}
              >
                Yes
              </Button>
            </Modal.Actions>
          </Modal>
          <Modal open={isOpenProjectHandlerModal} size="mini">
            <Modal.Header className="ui left aligned">
              Assign Project Handler
            </Modal.Header>
            <Modal.Content>
              <Modal.Description>
                <Form.Field
                  control={Dropdown}
                  selection
                  placeholder="Select project handler"
                  options={projectHandlers}
                  onChange={(e, properties) => {
                    this.handleDropdownChange(
                      "projectHandler",
                      properties.value
                    );
                  }}
                  value={form.projectHandler || ""}
                />
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <Button
                secondary
                basic
                onClick={() =>
                  this.setState({ isOpenProjectHandlerModal: false })
                }
              >
                Cancel
              </Button>
              <Button
                primary
                onClick={this.handleConfirmAssignProjectHandler.bind(this)}
              >
                Assign
              </Button>
            </Modal.Actions>
          </Modal>
          <Modal open={isOpenUnAssignProjectHandlerModal} size="mini">
            <Modal.Header className="ui left aligned">
              Un-Assign Project Handler
            </Modal.Header>
            <Modal.Content>
              <Modal.Description>
                <CustomTitleLabel message={projectHandlerName} />
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <Button
                secondary
                basic
                onClick={() =>
                  this.setState({ isOpenUnAssignProjectHandlerModal: false })
                }
              >
                Cancel
              </Button>
              <Button
                primary
                onClick={this.handleConfirmUnAssignProjectHandler.bind(this)}
              >
                Un-Assign
              </Button>
            </Modal.Actions>
          </Modal>
          <Container fluid>
            <Grid verticalAlign="middle">
              <Grid.Column width={4}>
                {/* <CustomHeaderBold
                  message={"QUOTE #" + orderDetails.orderNumber}
                /> */}
              </Grid.Column>
              <Grid.Column
                width={4}
                style={{ textAlign: "right" }}
                verticalAlign="middle"
              >
                {/* <Button basic primary compact>
                  Print PDF
                </Button>

                <Button basic primary compact>
                  Email a copy
                </Button> */}
              </Grid.Column>
              <Grid.Column width={3} floated="right">
                <Popup
                  on="click"
                  position="left center"
                  trigger={
                    <Button
                      color="blue"
                      icon="ellipsis vertical"
                      content="Quote Actions"
                      primary
                    />
                  }
                >
                  <Menu text vertical>
                    {/* Generic in all status */}
                    {siteCount == 1 && userRole == Role.fieldEngineer && (
                      <Menu.Item
                        name="Copy quote"
                        style={{ textAlign: "left" }}
                        as={Link}
                        to={`/home/view-order-details/copy/${orderDetails.orderNumber}`}
                      />
                    )}

                    {/* Draft Status */}
                    {orderDetails.status == Status.draft && (
                      <span>
                        {userRole == Role.fieldEngineer && (
                          <span>
                            <Menu.Item
                              name="Edit quote"
                              style={{ textAlign: "left" }}
                              as={Link}
                              to={editLink}
                            />
                            <Menu.Item
                              name="Cancel quote"
                              style={{ textAlign: "left" }}
                              as={Button}
                              onClick={() => {
                                this.handleCancellationCodeModal(
                                  true,
                                  orderDetails.id
                                );
                              }}
                            />
                          </span>
                        )}
                      </span>
                    )}
                    {orderDetails.status == Status.offered && (
                      <span>
                        {userRole == Role.fieldEngineer && (
                          <span>
                            {orderDetails.isEditedByPh ? null : (
                              <Menu.Item
                                name="Edit quote"
                                style={{ textAlign: "left" }}
                                as={Link}
                                to={editLink}
                              />
                            )}

                            <Menu.Item
                              name="Cancel quote"
                              style={{ textAlign: "left" }}
                              as={Button}
                              onClick={() => {
                                this.handleCancellationCodeModal(
                                  true,
                                  orderDetails.id
                                );
                              }}
                            />
                            <Menu.Item
                              name="Accept quote"
                              style={{ textAlign: "left" }}
                              as={Button}
                              onClick={() => {
                                this.handleConfirmCompleteOrder(
                                  true,
                                  orderDetails.id,
                                  orderDetails.orderNumber,
                                  orderDetails.siteCount
                                );
                              }}
                            />
                          </span>
                        )}
                      </span>
                    )}
                    {userRole == Role.projectHandler &&
                      (!orderDetails.assignedToId ? (
                        <Menu.Item
                          name="Assign Project Handler"
                          style={{ textAlign: "left" }}
                          as={Button}
                          onClick={() => {
                            this.handleAssignProjectHandler(
                              true,
                              orderDetails.id
                            );
                          }}
                        />
                      ) : (
                        <Menu.Item
                          name="Un-Assign Project Handler"
                          style={{ textAlign: "left" }}
                          as={Button}
                          onClick={() => {
                            this.handleUnAssignProjectHandler(
                              true,
                              orderDetails.id,
                              orderDetails.assignedToName
                            );
                          }}
                        />
                      ))}
                    {/* {orderDetails.status == Status.accepted &&
                      userRole == Role.projectHandler && (
                        <Menu.Item
                          name="Edit quote"
                          style={{ textAlign: "left" }}
                          as={Link}
                          to={`/home/view-order-details/edit/${orderDetails.orderNumber}`}
                        />
                      )} */}
                  </Menu>
                  {/* {orderDetails.status != 6 ? (
                    <Menu text vertical>
                    
                      <Menu.Item
                        name="Accept quote"
                        as={Button}
                        onClick={() => {
                          this.props.handleConfirmCompleteOrder(
                            true,
                            orderDetails.id
                          );
                        }}
                      />
                      <Menu.Item
                        name="Copy quote"
                        style={{ textAlign: "left" }}
                        as={Link}
                        to={`/home/view-order-details/${orderDetails.orderNumber}`}
                      />
                      <Menu.Item
                        name="Edit quote"
                        style={{ textAlign: "left" }}
                        as={Link}
                        to={`/home/view-order-details/${orderDetails.orderNumber}`}
                      />

                    /> 
                      <Menu.Item
                        name="Cancel quote"
                        as={Button}
                        onClick={() => {
                          this.props.handleConfirmCancelOrder(
                            true,
                            orderDetails.id
                          );
                        }}
                      />
                    </Menu>
                  ) : (
                    "No action available"
                  )} */}
                </Popup>
              </Grid.Column>
            </Grid>
            <Divider hidden />
            <OrderDetails
              data={orderDetails}
              accountSettings={accountSettings}
              applicant={applicantDetails}
            />
            <ProjectDetails
              orderDetails={orderDetails}
              productItems={productItems}
              reservationDetails={reservationDetails}
              siteDetails={siteDetails}
              attachment={attachment}
              forms={forms}
              siteCount={siteCount}
              ppeSelections={ppeSelections}
              trainingSelections={trainingSelections}
            />
            {/* <OrderItemInfo
              orderDetails={orderDetails}
              productItems={productItems}
              reservationDetails={reservationDetails}
            /> */}
            {/* {productItems &&
              productItems.map((item, idx) => {
                return (
                  <OrderItemInfo
                    key={idx}
                    data={item}
                    siteDetails={siteDetails}
                  />
                );
              })} */}
            {/* <OrderItemInfo /> */}
            <InspectionDetails data={productItems} />

            {orderDetails.status == Status.draft ||
            orderDetails.status == Status.pendingAssessment ? (
              userRole == Role.projectHandler ? (
                <QuoteAssumptions data={productItems} />
              ) : null
            ) : (
              <QuoteAssumptions data={productItems} />
            )}

            {/* <SchedulingDetails
              reservationDetails={reservationDetails}
              ppeSelections={ppeSelections}
              trainingSelections={trainingSelections}
            /> */}
            {/* For FES */}
            {/* <ServiceTerms />
            <TermsAndConditions />
             */}

            <FeTermsAndConditions />
            <GlobalServicesAgreement />
            <Grid>
              <Grid.Column width={3}>
                <Button
                  basic
                  primary
                  compact
                  as={Link}
                  to={`/home/view-history`}
                >
                  Back
                </Button>
              </Grid.Column>
              <Grid.Column width={3} floated="right">
                <Popup
                  on="click"
                  position="left center"
                  trigger={
                    <Button
                      color="blue"
                      icon="ellipsis vertical"
                      content="Quote Actions"
                      primary
                    />
                  }
                >
                  <Menu text vertical>
                    {/* Generic in all status */}
                    {siteCount == 1 && userRole == Role.fieldEngineer && (
                      <Menu.Item
                        name="Copy quote"
                        style={{ textAlign: "left" }}
                        as={Link}
                        to={`/home/view-order-details/copy/${orderDetails.orderNumber}`}
                      />
                    )}

                    {/* Draft Status */}
                    {orderDetails.status == Status.draft && (
                      <span>
                        <Menu.Item
                          name="Edit quote"
                          style={{ textAlign: "left" }}
                          as={Link}
                          to={editLink}
                        />

                        <Menu.Item
                          name="Cancel quote"
                          style={{ textAlign: "left" }}
                          as={Button}
                          onClick={() => {
                            this.handleCancellationCodeModal(
                              true,
                              orderDetails.id
                            );
                          }}
                        />
                      </span>
                    )}
                    {orderDetails.status == Status.offered && (
                      <span>
                        <Menu.Item
                          name="Edit quote"
                          style={{ textAlign: "left" }}
                          as={Link}
                          to={editLink}
                        />

                        <Menu.Item
                          name="Cancel quote"
                          style={{ textAlign: "left" }}
                          as={Button}
                          onClick={() => {
                            this.handleCancellationCodeModal(
                              true,
                              orderDetails.id
                            );
                          }}
                        />
                        <Menu.Item
                          name="Accept quote"
                          style={{ textAlign: "left" }}
                          as={Button}
                          onClick={() => {
                            this.handleConfirmCompleteOrder(
                              true,
                              orderDetails.id,
                              orderDetails.orderNumber,
                              orderDetails.siteCount
                            );
                          }}
                        />
                      </span>
                    )}
                    {orderDetails.status == Status.accepted &&
                      userRole == Role.projectHandler && (
                        <Menu.Item
                          name="Edit quote"
                          style={{ textAlign: "left" }}
                          as={Link}
                          to={`/home/view-order-details/edit/${orderDetails.orderNumber}`}
                        />
                      )}
                  </Menu>
                  {/* {orderDetails.status != 6 ? (
                    <Menu text vertical>
                   
                      <Menu.Item
                        name="Accept quote"
                        as={Button}
                        onClick={() => {
                          this.props.handleConfirmCompleteOrder(
                            true,
                            orderDetails.id
                          );
                        }}
                      />
                      <Menu.Item
                        name="Copy quote"
                        style={{ textAlign: "left" }}
                        as={Link}
                        to={`/home/view-order-details/${orderDetails.orderNumber}`}
                      />
                      <Menu.Item
                        name="Edit quote"
                        style={{ textAlign: "left" }}
                        as={Link}
                        to={`/home/view-order-details/${orderDetails.orderNumber}`}
                      />

                    
                      <Menu.Item
                        name="Cancel quote"
                        as={Button}
                        onClick={() => {
                          this.props.handleConfirmCancelOrder(
                            true,
                            orderDetails.id
                          );
                        }}
                      />
                    </Menu>
                  ) : (
                    "No action available"
                  )} */}
                </Popup>
              </Grid.Column>
            </Grid>
          </Container>

          {isShowCancellationCodeModal ? (
            <CancellationCode
              orderId={feOrderId}
              isShowCancellationCodeModal={isShowCancellationCodeModal}
              handleCancellationCodeModal={this.handleCancellationCodeModal}
              handleConfirmCancelOrder={this.handleConfirmCancelOrder}
            />
          ) : null}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    viewOrderDetails: state.viewOrderDetails,
    accountSettings: state.accountSettings.form,
    fieldEvaluation: state.fieldEvaluation,
    cancelOrder: state.feCancelOrder,
    isSuccessAssignProjectHandler:
      state.viewHistory.isSuccessAssignProjectHandler,
    viewHistory: state.viewHistory,
    feProductItem: state.feProductDetails,
    fieldService: state.fieldService,
    projectDetails: state.feProjectDetails,
    schedulingDetails: state.schedulingDetails
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAccountSettings: (userId) => dispatch(getAccountSettings(userId)),
    getOrderDetails: (orderNumber) =>
      dispatch(vodActions.getOrderDetails(orderNumber)),
    getFEOrderDetails: (orderNumber) =>
      dispatch(fActions.getOrderDetails(orderNumber)),
    updateOrderType: (data) => dispatch(vhActions.updateOrderType(data)),
    doCancelOrder: (data) => dispatch(doCancelOrder(data)),
    resetCancelOrder: () => dispatch(resetCancelOrder()),
    getProjectHandlers: () => dispatch(vhActions.getProjectHandlers()),
    assignProjectHandlers: (data) =>
      dispatch(vhActions.assignProjectHandlers(data)),
    assignProjectHandlerReset: () =>
      dispatch(vhActions.assignProjectHandlerReset()),
    unAssignProjectHandlers: (data) =>
      dispatch(vhActions.unAssignProjectHandlers(data)),
    form: (result) => dispatch(vhActions.updateForm(result)),
    getTrainings: () => dispatch(vodActions.getTrainings()),
    getPpes: () => dispatch(vodActions.getPpes()),
    getProductDetails: (orderDetails) =>
      dispatch(feActions.getProductDetails(orderDetails)),
    updateIsSuccess: () => dispatch(actions.updateIsSuccess()),
    getOrderDetailsMulti: (orderNumber) =>
      dispatch(multiActions.getOrderDetails(orderNumber)),
    renderProductDetails: (siteCount, data) =>
      dispatch(projectActions.renderProductDetails(siteCount, data)),
    renderSiteDetails: (siteCount, data) =>
      dispatch(projectActions.renderSiteDetails(siteCount, data)),
    renderSchedulingDetails: (siteCount, data) =>
      dispatch(schedActions.renderSchedulingDetails(siteCount, data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewOrderDetails);
