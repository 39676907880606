import React, { Component } from "react";
import {
  Form,
  Grid,
  Dropdown,
  Divider,
  Input,
  Checkbox,
} from "semantic-ui-react";

import { CustomLabel } from "../../../../components/labels/labels";

class AHJDetails extends Component {
  state = {};
  render() {
    var {
      siteDetails,
      ahjDetails,
      countryOptions,
      stateOptions,
      cityOptions,
      formType,
    } = this.props;

    return (
      <Form>
        <Divider hidden />
        <label style={{ marginLeft: "0px", fontWeight: "bold" }}>
          AUTHORITY HAVING JURISDICTION (AHJ) DETAILS
        </label>
        <Divider fitted />
        <br />
        <CustomLabel
          message={`This is typically the Local City, County, or State Electrical Inspector, Building Inspector, 
              or Fire Marshall over the final installation site:`}
        />
        <br />
        <br />
        <CustomLabel
          message={`The below details are Mandatory for scheduling, but are not required for the quoting process. 
          Therefore, if you do not yet know this information, you can skip this section of the application and it will be requested again at the time of project scheduling.`}
        />
        <Divider hidden />

        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Form.Field
                id="form-input-control-lcn"
                control={Input}
                label="Name of Jurisdiction"
                placeholder="Enter name of jurisdiction"
                name="jurisdictionName"
                onChange={(e) => {
                  this.props.handleChange(e, formType);
                }}
                value={ahjDetails.jurisdictionName || ""}
                error={this.props.getErrorFor("jurisdictionName", formType)}
                autoComplete="new-password"
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row columns={3}>
            <Grid.Column>
              <Form.Field>
                <Form.Field
                  id="form-input-control-lcn"
                  control={Input}
                  label="Street Address Line 1"
                  placeholder="Enter street address line 1"
                  name="addressLine1"
                  onChange={(e) => {
                    this.props.handleChange(e, formType);
                  }}
                  value={ahjDetails.addressLine1 || ""}
                  error={this.props.getErrorFor("addressLine1", formType)}
                  autoComplete="new-password"
                />
              </Form.Field>
            </Grid.Column>
            <Grid.Column>
              <Form.Field
                id="form-input-control-lcn"
                control={Input}
                label="Street Address Line 2"
                placeholder="Enter street address line 2"
                name="addressLine2"
                onChange={(e) => {
                  this.props.handleChange(e, formType);
                }}
                value={ahjDetails.addressLine2 || ""}
                error={this.props.getErrorFor("addressLine2", formType)}
                autoComplete="new-password"
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Field
                id="form-input-control-lcn"
                control={Input}
                label="Street Address Line 3"
                placeholder="Enter street address line 3"
                name="addressLine3"
                onChange={(e) => {
                  this.props.handleChange(e, formType);
                }}
                value={ahjDetails.addressLine3 || ""}
                error={this.props.getErrorFor("addressLine3", formType)}
                autoComplete="new-password"
              />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row columns={4}>
            <Grid.Column>
              <Form.Field>
                <label>Country</label>
                <Dropdown
                  compact
                  selection
                  placeholder="Select Country"
                  options={countryOptions}
                  onChange={(e, properties) => {
                    this.props.handleDropdownChange(
                      "country",
                      properties.value,
                      formType
                    );
                    this.props.handleGetProjectStates(properties.value, 3);
                  }}
                  value={ahjDetails.country || ""}
                  error={this.props.getErrorFor("country", formType)}
                />
              </Form.Field>
            </Grid.Column>
            <Grid.Column>
              <Form.Field>
                <label>State</label>
                <Dropdown
                  compact
                  selection
                  placeholder="Select State"
                  options={stateOptions}
                  onChange={(e, properties) => {
                    this.props.handleDropdownChange(
                      "state",
                      properties.value,
                      formType
                    );
                    this.props.handleGetProjectCities(properties.value, 3);
                  }}
                  value={ahjDetails.state || ""}
                  error={this.props.getErrorFor("state", formType)}
                />
              </Form.Field>
            </Grid.Column>
            <Grid.Column>
              <Form.Field>
                <label>City</label>
                <Dropdown
                  compact
                  selection
                  placeholder="Select City"
                  options={cityOptions}
                  onChange={(e, properties) => {
                    this.props.handleDropdownChange(
                      "city",
                      properties.value,
                      formType
                    );
                  }}
                  value={ahjDetails.city || ""}
                  error={this.props.getErrorFor("city", formType)}
                />
              </Form.Field>
            </Grid.Column>

            <Grid.Column>
              <Form.Field
                id="form-input-control-lcn"
                control={Input}
                label="Postal Code"
                placeholder="Enter postal code"
                name="postalCode"
                onChange={(e) => {
                  this.props.handleChange(e, formType);
                }}
                value={ahjDetails.postalCode || ""}
                error={this.props.getErrorFor("postalCode", formType)}
                autoComplete="new-password"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={3}>
            <Grid.Column>
              <Form.Field
                id="form-input-control-lcn"
                control={Input}
                label="Inspector Name"
                placeholder="Enter inspector name"
                name="inspectorName"
                onChange={(e) => {
                  this.props.handleChange(e, formType);
                }}
                value={ahjDetails.inspectorName || ""}
                error={this.props.getErrorFor("inspectorName", formType)}
                autoComplete="new-password"
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Field
                id="form-input-control-lcn"
                control={Input}
                label="Phone No."
                placeholder="Enter phone no"
                name="inspectorOfficeNumber"
                onChange={(e) => {
                  this.props.handleChange(e, formType);
                }}
                value={ahjDetails.inspectorOfficeNumber || ""}
                error={this.props.getErrorFor(
                  "inspectorOfficeNumber",
                  formType
                )}
                autoComplete="new-password"
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Field
                id="form-input-control-lcn"
                control={Input}
                label="Mobile No."
                placeholder="Enter mobile no"
                name="inspectorMobileNumber"
                onChange={(e) => {
                  this.props.handleChange(e, formType);
                }}
                value={ahjDetails.inspectorMobileNumber || ""}
                error={this.props.getErrorFor(
                  "inspectorMobileNumber",
                  formType
                )}
                autoComplete="new-password"
              />
            </Grid.Column>
            {/* <Grid.Column>
              <Form.Field
                id="form-input-control-lcn"
                control={Input}
                label="Last Name of Inspector"
                placeholder="Enter last name of inspector"
                name="inspectorLastName"
                onChange={(e) => {
                  this.props.handleChange(e, formType);
                }}
                value={ahjDetails.inspectorLastName || ""}
                error={this.props.getErrorFor("inspectorLastName", formType)}
                autoComplete="new-password"
              />
            </Grid.Column> */}
          </Grid.Row>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Form.Field
                id="form-input-control-lcn"
                control={Input}
                label="Email"
                placeholder="Enter email"
                name="inspectorEmailAddress"
                onChange={(e) => {
                  this.props.handleChange(e, formType);
                }}
                value={ahjDetails.inspectorEmailAddress || ""}
                error={this.props.getErrorFor(
                  "inspectorEmailAddress",
                  formType
                )}
                autoComplete="new-password"
              />
            </Grid.Column>
            <Grid.Column>
              <Form.Field
                id="form-input-control-lcn"
                control={Input}
                label="Permit #"
                placeholder="Enter permit #"
                name="permitNo"
                onChange={(e) => {
                  this.props.handleChange(e, formType);
                }}
                value={ahjDetails.permitNo || ""}
                error={this.props.getErrorFor("permitNo", formType)}
                autoComplete="new-password"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    );
  }
}

export default AHJDetails;
