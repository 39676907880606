import { requests } from '../request-factory';
import { mapDataToQueryString } from '../request-helpers';

const labelSupplierService = {
  getLabelSuppliers: (supplierDetails, currentPage, pageSize) =>
    requests.get(
      `label-supplier?${mapDataToQueryString({
        supplierDetails,
        currentPage,
        pageSize,
      })}`
    ),

  getLabelSupplierPrinterEmail: (printerFileNumber, printerPsn) =>
    requests.get(
      `label-supplier/label-supplier-email?${mapDataToQueryString({
        printerFileNumber,
        printerPsn,
      })}`
    ),
  upsertLabelSupplierEmail: data =>
    requests.post(`label-supplier/upsertLabelSupplierEmail`, data),
  upsertCombinationAllowedCcn: data =>
    requests.post(`label-supplier/upsert-combination-allowed-ccn`, data),

  getCombinationAllowedCcn: () =>
    requests.get(`label-supplier/combination-allowed-ccn`),

  createLabelSupplierEmail: data => requests.post(`label-supplier/email`, data),
};

export default labelSupplierService;
