// Separated to re-use in summary, quotation scope and saving

const scopeValue = (form) => {
  return form.quantity
    ? `Field Evaluation of (${form.quantity})${
        form.isExistingUlEquipment ? " UL Listed" : ""
      } ${
        form.isPanelboard
          ? form.serviceSubProductName
          : form.serviceSubProductName
          ? form.serviceSubProductName.substr(
              0,
              form.serviceSubProductName.lastIndexOf("/")
            )
          : ""
      }, Modified for ${form.serviceProductName}, Single Site Visit`
    : null;
};

const scopeValueMulti = (forms) => {
  const idx = forms.findIndex((form) => form.tabIndex === 0);
  if (idx === -1) return null;

  let pnl = forms.filter((f) => f.serviceSubProductCode === "PNL");
  let pnlQty =
    pnl.length > 0
      ? pnl.reduce((c, n) => c + (parseInt(n.quantity) || 0), 0)
      : 0;

  let swb = forms.filter((f) => f.serviceSubProductCode === "SWBOARD-SWGEAR");
  let swbQty =
    swb.length > 0
      ? swb.reduce((c, n) => c + (parseInt(n.quantity) || 0), 0)
      : 0;

  let hasUlListed = forms.some(
    (form) => form["isExistingUlEquipment"] === true
  );

  let hasPnl = pnl.length
    ? `(${pnlQty}) ${pnl[0].serviceSubProductName}`
    : null;
  let hasSwb = swb.length
    ? `(${swbQty}) ${swb[0].serviceSubProductName.substr(
        0,
        swb[0].serviceSubProductName.lastIndexOf("/")
      )}`
    : null;

  let subProduct =
    pnl.length && swb.length
      ? `${hasPnl} & ${hasSwb}`
      : pnl.length
      ? hasPnl
      : swb.length
      ? hasSwb
      : null;

  let productName = forms[idx].serviceProductName;
  let productCode = forms[idx].serviceProductCode;
  if (productCode === "AESI-LST") {
    let idx = productName.lastIndexOf("(");
    if (idx > 0) productName = productName.substring(0, idx).trim();
  }

  return forms.length
    ? `Field Evaluation of ${subProduct}, ${
        hasUlListed ? "UL Listed /" : ""
      } Modified for ${productName} (${forms.length} sites in a Single Visit)`
    : null;
};

export default { scopeValue, scopeValueMulti };
