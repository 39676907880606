export const LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const LOGIN_FAILURE = "USER_LOGIN_FAILURE";
export const LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS";
export const LOGIN_UPDATE_MODEL = "LOGIN_UPDATE_MODEL";
export const ROLEPAGES_REQUEST = "ROLEPAGES_REQUEST";
export const ROLEPAGES_FAILURE = "ROLEPAGES_FAILURE";
export const ROLEPAGES_SUCCESS = "ROLEPAGES_SUCCESS";
export const CLEAR = "CLEAR";

export const EXTERNAL_LOGIN_REQUEST = "USER_EXTERNAL_LOGIN_REQUEST";
export const EXTERNAL_LOGIN_FAILURE = "USER_EXTERNAL_LOGIN_FAILURE";
export const EXTERNAL_LOGIN_SUCCESS = "USER_EXTERNAL_LOGIN_SUCCESS";

export const UPDATE_ORDERPAGE_SUCCESS = "UPDATE_ORDERPAGE_SUCCESS";
