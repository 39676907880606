import React, { Component, Fragment } from "react";
import {
  Form,
  Grid,
  Segment,
  Divider,
  Checkbox,
  Button,
  Input,
  Dropdown,
  Table,
  Popup,
  Icon
} from "semantic-ui-react";
import {
  CustomHeader,
  CustomLabel,
  CustomTitle,
  CustomHeaderLabel,
  CustomHeaderInlineBold,
  CustomTitleLabel,
  CustomHeaderBold
} from "../../../components/labels/labels";
import FooterHomeBlock from "../../../components/footer/footer-home-block";
import { connect } from "react-redux";
import * as actions from "./account-settings.action";
import { NotificationSuccess } from "../../../components/notification/notifications";
import { getCustomerDetails } from "../../manufacturer/standard/label-cart/label-cart.action";

import { Redirect } from "react-router-dom";

import {
  getError,
  validateField,
  createValidation
} from "../../../validator/validator";

import {
  ADADrules,
  ADABTLDrules
} from "../../field-evaluation/validation-rules";

import { GetFormData } from "../field-evaluation-helper";

import _ from "lodash";
import { Common } from "../../../common";
import Role from "../../../enums/role";
import { toPascalCase } from "../../../helpers/component-helper";
import ToastHelper from "../../../helpers/toast.helper";

class AccountSettings extends Component {
  state = {
    isSuccessGetExistingPrimaryCountry: false,
    isSuccessGetExistingPrimaryState: false,
    isSuccessGetExistingBillToCountry: false,
    isSuccessGetExistingBillToState: false,
    isSuccessGetAccountDetails: false,
    showErrors: false,
    customerRefTextInfo: null,
    isSuccess: false,
    partySiteNumber: null,
    accountNumber: null,
    isSuccessReset: false,
    isSucessCustomer: false,
    isAccountNumbersEmpty: true,
    isAccountNumbersReadOnly: true,
    isSearchTrigger: false,
    oldAccountNumber: null,
    isCompanyDisable: false,
    isAddress1Disable: false,
    isAddress2Disable: false,
    isAddress3Disable: false,
    isCountryDisable: false,
    isStateDisable: false,
    isCityDisable: false,
    isPostalCodeDisable: false,
    isTaxIdDisable: false,
    isContactNameDisable: false,
    isPhoneNoDisable: false,
    isEmailDisable: false,
    isLoadCustomerRelations: false,
    oldBillToCityOptions: null,
    isFirstLoad: true,
    isBillToAccountNumberEmpty: false,
    countryData: "",
    stateData: "",
    cityData: "",
    isApplicantContactEmpty: true,
    isApplicantEmailDisable: false,
    isApplicantPhoneNoDisable: false,
    isLoadProjectHandler: true,
    isSelectedContact: false,
    isVerifyButtonHidden: true,
    isCountryLoad: true,
    isContactNameLoad: true,
    isSameAccount: true,
    isFeSelectedContact: true
  };

  rules = [];

  // handleCheckboxChange = (cbName, cbValue) => {
  //   let { form } = this.props;
  //   const newState = validateField(this.rules, form, cbName, cbValue);
  //   this.props.updateForm(newState);
  // };

  handleTextboxChange = (e, formType) => {
    let { form } = this.props;

    let result = GetFormData(form, formType);

    const newState = validateField(
      result.rules,
      result.formData,
      e.target.name,
      e.target.value
    );

    this.props.updateForm(newState);
  };

  handleDropdownChange = (ddlName, ddlValue, formType) => {
    let { form } = this.props;
    let result = GetFormData(form, formType);
    const newState = validateField(
      result.rules,
      result.formData,
      ddlName,
      ddlValue
    );
    this.props.updateForm(newState);
  };

  handleSaveAccountSettings = () => {
    let { form, customerDetails } = this.props;
    let accountDetails = customerDetails.data;

    let { showErrors } = this.state;
    let {
      isCompanyDisable,
      isAddress1Disable,
      isAddress2Disable,
      isAddress3Disable,
      isCountryDisable,
      isStateDisable,
      isCityDisable,
      isPostalCodeDisable,
      isTaxIdDisable,
      isContactNameDisable,
      isPhoneNoDisable,
      isEmailDisable
    } = this.state;

    var accountSettingsUserId = null;
    var roleId = Common.getUserRole();

    if (roleId == Role.projectHandler) {
      accountSettingsUserId = form.accountSettings.accountSettingsUserId
        ? form.accountSettings.accountSettingsUserId
        : 0;
    }

    form.applicantDetails.applicantDetails.validationErrors = createValidation(
      form.applicantDetails.applicantDetails,
      ADADrules
    );

    form.applicantDetails.applicantBillToLocationDetails.validationErrors =
      createValidation(
        form.applicantDetails.applicantBillToLocationDetails,
        ADABTLDrules
      );

    let primaryDetails = form.applicantDetails.applicantDetails;
    let billToDetails = form.applicantDetails.applicantBillToLocationDetails;
    let billToSettings = [];

    //Convert ULIO city to Pascal case before saving
    if (primaryDetails.city)
      primaryDetails.city = toPascalCase(primaryDetails.city);
    if (billToDetails.city)
      billToDetails.city = toPascalCase(billToDetails.city);

    //concat fields enable property
    billToDetails.isFromOracle =
      isCompanyDisable +
      "|" +
      isAddress1Disable +
      "|" +
      isAddress2Disable +
      "|" +
      isAddress3Disable +
      "|" +
      isCountryDisable +
      "|" +
      isStateDisable +
      "|" +
      isCityDisable +
      "|" +
      isPostalCodeDisable +
      "|" +
      isTaxIdDisable +
      "|" +
      isContactNameDisable +
      "|" +
      isPhoneNoDisable +
      "|" +
      isEmailDisable;

    primaryDetails.locationType = 1;
    primaryDetails.accountNumber = accountDetails.accountNumber;
    if (billToDetails.accountNumberBillTo) {
      primaryDetails.accountNumberBillTo = billToDetails.accountNumberBillTo;
    }

    billToDetails.locationType = 2;
    billToDetails.accountNumber = accountDetails.accountNumber;
    billToDetails.primaryEmailAddress = primaryDetails.emailAddress;
    billToDetails.accountNumberBillTo = billToDetails.accountNumberBillTo
      ? billToDetails.accountNumberBillTo
      : null;

    billToSettings.push(primaryDetails);
    billToSettings.push(billToDetails);

    var model = {
      ...form,
      isSentByEmail: form.accountSettings.isSentByEmail,
      isReferenceNoUsed: form.accountSettings.isReferenceNoUsed,
      billToSettings: billToSettings,
      accountSettingsUserId: accountSettingsUserId,
      partySiteNumber: accountDetails.psn
    };

    if (
      Object.IsEmpty(form.applicantDetails.applicantDetails.validationErrors) &&
      Object.IsEmpty(
        form.applicantDetails.applicantBillToLocationDetails.validationErrors
      )
    ) {
      this.setState({ showErrors: false });
      return this.props.createAccountSettings(model);
    } else {
      this.setState({ showErrors: true });
    }
  };

  handleGetStates = (countryCode, locationType) => {
    this.props.getStates(countryCode, locationType);
  };

  handleGetCities = (stateCode, locationType) => {
    this.props.getCities(stateCode, locationType);
  };

  handleCheckExistingCityState = (form) => {
    const {
      isSuccessGetExistingPrimaryCountry,
      isSuccessGetExistingPrimaryState,
      isSuccessGetExistingBillToCountry,
      isSuccessGetExistingBillToState
    } = this.state;

    if (
      form.applicantDetails.applicantDetails &&
      form.applicantDetails.applicantDetails.country &&
      !isSuccessGetExistingPrimaryCountry
    ) {
      this.handleGetStates(form.applicantDetails.applicantDetails.country, 1);
      this.setState({ isSuccessGetExistingPrimaryCountry: true });
    }

    if (
      form.applicantDetails.applicantDetails &&
      form.applicantDetails.applicantDetails.state &&
      !isSuccessGetExistingPrimaryState
    ) {
      this.handleGetCities(form.applicantDetails.applicantDetails.state, 1);
      this.setState({ isSuccessGetExistingPrimaryState: true });
    }

    if (
      form.applicantDetails.applicantBillToLocationDetails &&
      form.applicantDetails.applicantBillToLocationDetails.country &&
      !isSuccessGetExistingBillToCountry
    ) {
      this.handleGetStates(
        form.applicantDetails.applicantBillToLocationDetails.country,
        2
      );
      this.setState({ isSuccessGetExistingBillToCountry: true });
    }

    if (
      form.applicantDetails.applicantBillToLocationDetails &&
      form.applicantDetails.applicantBillToLocationDetails.state &&
      !isSuccessGetExistingBillToState
    ) {
      this.handleGetCities(
        form.applicantDetails.applicantBillToLocationDetails.state,
        2
      );
      this.setState({ isSuccessGetExistingBillToState: true });
    }
  };

  componentDidMount() {
    const {
      getAccountSettings,
      getCustomerDetails,
      getCountries,
      getAccountSettingsByPsn,
      getCustomerRelations,
      getCustomerRelationInformations,
      resetContactInformations
    } = this.props;
    var roleId = Common.getUserRole();

    if (roleId == Role.fieldEngineer) {
      getAccountSettings();
      let psn = Common.getPartySiteNumber();
      getCustomerDetails(psn);
    } else if (roleId == Role.projectHandler) {
      this.setCustomerInformationEmpty();
      this.setState({ isVerifyButtonHidden: false });
      var psn = this.props.match.params.psn;
      if (psn) {
        getAccountSettingsByPsn(psn);
        getCustomerDetails(psn);
      } else {
        let isSuccessSetAccountSettings =
          localStorage.getItem("isSuccessSetAccountSettings") == "true";
        let accountSettingsPsn = localStorage.getItem("accountSettingsPsn");
        if (isSuccessSetAccountSettings) {
          getAccountSettingsByPsn(accountSettingsPsn);
          getCustomerDetails(accountSettingsPsn);
        }
      }
    }

    getCountries(1);
    getCountries(2);
    var customerRefTextInfo =
      roleId == Role.fieldEngineer
        ? "Internal reference such as a Job # or Project #"
        : "Internal reference such as fire department name or truck number";

    this.setState({ customerRefTextInfo: customerRefTextInfo });
  }

  getErrorFor(field, formType) {
    const { form, data } = this.props;
    let results = GetFormData(form, formType);
    let errors = getError(field, results.formData, data);
    return errors;
  }

  handleCheckboxChange = (cbName, cbValue, formType) => {
    const { form, customerDetails } = this.props;
    let accountDetails = customerDetails.data;

    let result = GetFormData(form, formType);

    validateField(result.rules, result.formData, cbName, cbValue);

    if (result.formData && result.formData.isSameAsAbove) {
      var _appDetails = _.omit(result.formData.applicantDetails, [
        "locationType"
      ]);

      _appDetails.legalCompanyName = accountDetails.partyName;
      _appDetails.addressLine1 = accountDetails.addressLine1;
      _appDetails.country = accountDetails.countryCode;
      _appDetails.state = accountDetails.state;
      _appDetails.city = accountDetails.city;
      _appDetails.postalCode = accountDetails.postalCode;
      _appDetails.accountNumber = accountDetails.accountNumber;

      result.formData.applicantBillToLocationDetails = _appDetails;

      result.formData.applicantBillToLocationDetails.locationType = 2;

      this.handleGetCities(accountDetails.state, 2);
    }
    const newState = { ...this.props.form };
    this.props.updateForm(newState);
  };

  handleSettingsCheckboxChange = (cbName, cbValue, formType) => {
    const { form } = this.props;
    let result = GetFormData(form, formType);

    validateField(result.rules, result.formData, cbName, cbValue);

    const newState = { ...this.props.form };

    this.props.updateForm(newState);
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSearch = () => {
    const { partySiteNumber } = this.state;
    const { getCustomerDetails, getAccountSettingsByPsn, form } = this.props;

    if (partySiteNumber) {
      this.setState({ isSuccessGetAccountDetails: false });
      this.setState({ isSucessCustomer: false });
      this.setState({ isSearchTrigger: true });
      this.setState({ isCountryLoad: true });
      this.setState({ isContactNameLoad: true });
      getAccountSettingsByPsn(partySiteNumber);
      getCustomerDetails(partySiteNumber);
    }
  };

  lowerCaseAllWordsExceptFirstLetters(string) {
    return string.replace(/\S*/g, function (word) {
      return word.charAt(0) + word.slice(1).toLowerCase();
    });
  }
  handleAccntNumDorpDownChange = (accntNumber) => {
    var accountRecord = this.props.customerRelationsData.find(function (item) {
      return item.accountNumber == accntNumber;
    });
    if (accountRecord) {
      if (accountRecord.accountName) {
        this.setState({ isCompanyDisable: true });
      } else {
        this.setState({ isCompanyDisable: false });
      }

      if (accountRecord.address1) {
        this.setState({ isAddress1Disable: true });
      } else {
        this.setState({ isAddress1Disable: false });
      }

      if (accountRecord.address2) {
        this.setState({ isAddress2Disable: true });
      } else {
        this.setState({ isAddress2Disable: false });
      }

      if (accountRecord.address3) {
        this.setState({ isAddress3Disable: true });
      } else {
        this.setState({ isAddress3Disable: false });
      }

      if (accountRecord.country) {
        this.setState({ isCountryDisable: true });
      } else {
        this.setState({ isCountryDisable: false });
      }

      if (accountRecord.state) {
        this.setState({ isStateDisable: true });
      } else {
        this.setState({ isStateDisable: false });
      }

      if (accountRecord.postalCode) {
        this.setState({ isPostalCodeDisable: true });
      } else {
        this.setState({ isPostalCodeDisable: false });
      }

      if (accountRecord.taxPayerId) {
        this.setState({ isTaxIdDisable: true });
      } else {
        this.setState({ isTaxIdDisable: false });
      }
      this.props.form.applicantDetails.applicantBillToLocationDetails.legalCompanyName =
        accountRecord.accountName;
      this.props.form.applicantDetails.applicantBillToLocationDetails.addressLine1 =
        accountRecord.address1;
      this.props.form.applicantDetails.applicantBillToLocationDetails.addressLine2 =
        accountRecord.address2;
      this.props.form.applicantDetails.applicantBillToLocationDetails.addressLine3 =
        accountRecord.address3;
      this.props.form.applicantDetails.applicantBillToLocationDetails.city =
        accountRecord.city;
      //  this.form.applicantDetails.applicantDetails.state =
      //    accountRecord.state;
      // this.form.form.applicantDetails.applicantDetails.country =
      //   accountRecord.city;
      this.handleDropdownChange("country", accountRecord.country, "AD-ABTLD");
      this.handleCountryValue(accountRecord.country);
      this.handleGetStates(accountRecord.country, 2);
      this.handleDropdownChange("state", accountRecord.state, "AD-ABTLD");
      this.handleGetCities(accountRecord.state, 2);
      this.handleStateValue(accountRecord.state);

      this.props.form.applicantDetails.applicantBillToLocationDetails.postalCode =
        accountRecord.postalCode;
      this.props.form.applicantDetails.applicantBillToLocationDetails.taxpayerId =
        accountRecord.taxPayerId;

      this.props.getCustomerRelationInformations(accntNumber);
    }
  };

  setCustomerInformationEmpty = () => {
    //applicant
    this.props.form.applicantDetails.applicantDetails.contactName = "";
    this.props.form.applicantDetails.applicantDetails.phoneNumber = "";
    this.props.form.applicantDetails.applicantDetails.emailAddress = "";
    this.setState({ isApplicantPhoneNoDisable: false });
    this.setState({ isApplicantEmailDisable: false });

    //billto
    this.props.form.applicantDetails.applicantBillToLocationDetails.legalCompanyName =
      "";
    this.props.form.applicantDetails.applicantBillToLocationDetails.addressLine1 =
      "";
    this.props.form.applicantDetails.applicantBillToLocationDetails.addressLine2 =
      "";
    this.props.form.applicantDetails.applicantBillToLocationDetails.addressLine3 =
      "";
    this.props.form.applicantDetails.applicantBillToLocationDetails.country =
      "";
    this.props.form.applicantDetails.applicantBillToLocationDetails.state = "";
    this.props.form.applicantDetails.applicantBillToLocationDetails.city = "";

    this.props.form.applicantDetails.applicantBillToLocationDetails.postalCode =
      "";
    this.props.form.applicantDetails.applicantBillToLocationDetails.taxpayerId =
      "";
    this.props.form.applicantDetails.applicantBillToLocationDetails.accountNumberBillTo =
      "";
    this.props.form.applicantDetails.applicantBillToLocationDetails.contactName =
      "";
    this.props.form.applicantDetails.applicantBillToLocationDetails.phoneNumber =
      "";
    this.props.form.applicantDetails.applicantBillToLocationDetails.emailAddress =
      "";
    this.setState({ isCompanyDisable: false });
    this.setState({ isAddress1Disable: false });
    this.setState({ isAddress2Disable: false });
    this.setState({ isAddress3Disable: false });
    this.setState({ isCountryDisable: false });
    this.setState({ isStateDisable: false });
    this.setState({ isCityDisable: false });
    this.setState({ isPostalCodeDisable: false });
    this.setState({ isTaxIdDisable: false });
    this.setState({ isContactNameDisable: false });
    this.setState({ isPhoneNoDisable: false });
    this.setState({ isEmailDisable: false });
  };

  handleCustomerInfoDorpDownChange = (contactName) => {
    if (this.props.customerRelationInformationsData) {
      var customerInfos = this.props.customerRelationInformationsData.find(
        function (item) {
          return item.contactName == contactName;
        }
      );
      if (customerInfos) {
        if (customerInfos.phoneNumber) {
          this.setState({ isPhoneNoDisable: true });
        } else {
          this.setState({ isPhoneNoDisable: false });
        }
        if (customerInfos.emailAdd) {
          this.setState({ isEmailDisable: true });
        } else {
          this.setState({ isEmailDisable: false });
        }
        // this.props.form.applicantDetails.applicantBillToLocationDetails.contactName =
        //   "N/A";
        this.props.form.applicantDetails.applicantBillToLocationDetails.phoneNumber =
          customerInfos.phoneNumber;
        // this.props.form.applicantDetails.applicantBillToLocationDetails.mobileNumber =
        //   "N/A";
        this.props.form.applicantDetails.applicantBillToLocationDetails.emailAddress =
          customerInfos.emailAdd;
      }
    }
  };

  handleLoadAccountDetails = (accountNumber) => {
    this.props.getCustomerRelations(accountNumber, "true").then((s) => {
      if (
        this.props.accountNumbersOptions &&
        this.props.form.applicantDetails.applicantBillToLocationDetails
          .accountNumberBillTo
      ) {
        this.handleDropdownChange(
          "accountNumberBillTo",
          this.props.form.applicantDetails.applicantBillToLocationDetails
            .accountNumberBillTo,
          "AD-ABTLD"
        );

        if (this.props.customerRelationInformationsOptions) {
          this.props.getCustomerRelationInformations(
            this.props.form.applicantDetails.applicantBillToLocationDetails
              .accountNumberBillTo
          );
        }

        if (
          this.props.form.applicantDetails.applicantBillToLocationDetails
            .contactName
        ) {
          this.handleDropdownChange(
            "contactName",
            this.props.form.applicantDetails.applicantBillToLocationDetails
              .contactName,
            "AD-ABTLD"
          );
          this.handleCustomerInfoDorpDownChange(
            this.props.form.applicantDetails.applicantBillToLocationDetails
              .contactName
          );
        }
      }
    });
  };

  handleFieldsEnable = () => {
    if (
      this.props.form.applicantDetails.applicantBillToLocationDetails
        .isFromOracle
    ) {
      var fieldsEnable =
        this.props.form.applicantDetails.applicantBillToLocationDetails.isFromOracle.split(
          "|"
        );
      this.setState({ isCompanyDisable: fieldsEnable[0] });
      this.setState({ isAddress1Disable: fieldsEnable[1] });
      this.setState({ isAddress2Disable: fieldsEnable[2] });
      this.setState({ isAddress3Disable: fieldsEnable[3] });
      this.setState({ isCountryDisable: fieldsEnable[4] });
      this.setState({ isStateDisable: fieldsEnable[5] });
      this.setState({ isCityDisable: fieldsEnable[6] });
      this.setState({ isPostalCodeDisable: fieldsEnable[7] });
      this.setState({ isTaxIdDisable: fieldsEnable[8] });
      this.setState({ isContactNameDisable: fieldsEnable[9] });
      this.setState({ isPhoneNoDisable: fieldsEnable[10] });
      this.setState({ isEmailDisable: fieldsEnable[11] });
    } else {
      this.setState({ isCompanyDisable: false });
      this.setState({ isAddress1Disable: false });
      this.setState({ isAddress2Disable: false });
      this.setState({ isAddress3Disable: false });
      this.setState({ isCountryDisable: false });
      this.setState({ isStateDisable: false });
      this.setState({ isCityDisable: false });
      this.setState({ isPostalCodeDisable: false });
      this.setState({ isTaxIdDisable: false });
      this.setState({ isContactNameDisable: false });
      this.setState({ isPhoneNoDisable: false });
      this.setState({ isEmailDisable: false });
    }
  };

  handleCityPopulate = () => {
    if (this.props.form.applicantDetails.applicantBillToLocationDetails.city) {
      var newCity = this.lowerCaseAllWordsExceptFirstLetters(
        this.props.form.applicantDetails.applicantBillToLocationDetails.city
      );
      var city = this.props.cityOptionsBillTo.find(function (item) {
        return item.value.includes(newCity);
      });
      if (city) {
        //this.setState({ isCityDisable: true });
        this.handleDropdownChange(
          "city",
          this.lowerCaseAllWordsExceptFirstLetters(city.value),
          "AD-ABTLD"
        );
      } else {
        //this.setState({ isCityDisable: false });
      }
    }
  };

  handleCountryValue = (countryCode) => {
    var country = this.props.countryOptionsBillTo.find(function (item) {
      return item.value.includes(countryCode);
    });
    if (country) {
      this.setState({ countryData: country.text });
    }
  };

  handleStateValue = (stateCode) => {
    var stateVal = this.props.stateOptions.find(function (item) {
      return item.value.includes(stateCode);
    });
    if (stateVal) {
      this.setState({ stateData: stateVal.text });
    }
  };

  handleApplicantContactInformations = (applicantName) => {
    if (this.props.applicantContactInformations) {
      var applicantContactInfo = this.props.applicantContactInformations.find(
        function (item) {
          return item.contactName == applicantName;
        }
      );
      if (applicantContactInfo) {
        if (applicantContactInfo.phoneNumber) {
          this.setState({ isApplicantPhoneNoDisable: true });
        } else {
          this.setState({ isApplicantPhoneNoDisable: false });
        }
        if (applicantContactInfo.emailAdd) {
          this.setState({ isApplicantEmailDisable: true });
        } else {
          this.setState({ isApplicantEmailDisable: false });
        }

        this.props.form.applicantDetails.applicantDetails.phoneNumber =
          applicantContactInfo.phoneNumber;
        this.props.form.applicantDetails.applicantDetails.emailAddress =
          applicantContactInfo.emailAdd;
      }
      this.props
        .isAccountContactExist(
          this.props.form.applicantDetails.applicantDetails.psn,
          applicantContactInfo.emailAdd
        )
        .then((s) => {
          //this.handleToastMessage(s);
          //alert("exist");

          this.setState({ isSelectedContact: true });

          if (s.accountNumberBillTo) {
            //console.log(s.accountNumberBillTo);
            this.props.form.applicantDetails.applicantBillToLocationDetails.accountNumberBillTo =
              s.accountNumberBillTo;
            this.handleAccntNumDorpDownChange(s.accountNumberBillTo);

            this.props.form.applicantDetails.applicantBillToLocationDetails.contactName =
              s.contactName;
            // this.props.form.applicantDetails.applicantBillToLocationDetails.mobileNumber =
            //   "N/A";
            //this.props.form.applicantDetails.applicantBillToLocationDetails.emailAddress =
            //customerInfos.emailAdd;
          } else if (s.billToSettingsData) {
            this.handleDropdownChange(
              "country",
              s.billToSettingsData.country,
              "AD-ABTLD"
            );
            this.handleCountryValue(s.billToSettingsData.country);
            this.handleGetStates(s.billToSettingsData.country, 2);
            this.handleDropdownChange(
              "state",
              s.billToSettingsData.state,
              "AD-ABTLD"
            );
            this.handleGetCities(s.billToSettingsData.state, 2);
            this.handleStateValue(s.billToSettingsData.state);

            //this.props.form.applicantDetails.applicantBillToLocationDetails.accountNumber = this.props.form.applicantDetails.applicantDetails.accountNumber;
            //console.log("NotNull");
            this.props.form.applicantDetails.applicantBillToLocationDetails.legalCompanyName =
              s.billToSettingsData.legalCompanyName;
            this.props.form.applicantDetails.applicantBillToLocationDetails.addressLine1 =
              s.billToSettingsData.addressLine1;
            this.props.form.applicantDetails.applicantBillToLocationDetails.addressLine2 =
              s.billToSettingsData.addressLine2;
            this.props.form.applicantDetails.applicantBillToLocationDetails.addressLine3 =
              s.billToSettingsData.addressLine3;
            this.props.form.applicantDetails.applicantBillToLocationDetails.country =
              s.billToSettingsData.country;
            this.props.form.applicantDetails.applicantBillToLocationDetails.state =
              s.billToSettingsData.state;
            this.props.form.applicantDetails.applicantBillToLocationDetails.city =
              s.billToSettingsData.city;
            this.props.form.applicantDetails.applicantBillToLocationDetails.postalCode =
              s.billToSettingsData.postalCode;
            this.props.form.applicantDetails.applicantBillToLocationDetails.taxpayerId =
              s.billToSettingsData.taxPayerId;
            this.props.form.applicantDetails.applicantBillToLocationDetails.contactName =
              s.billToSettingsData.contactName;
            this.props.form.applicantDetails.applicantBillToLocationDetails.phoneNumber =
              s.billToSettingsData.phoneNumber;
            this.props.form.applicantDetails.applicantBillToLocationDetails.mobileNumber =
              s.billToSettingsData.mobileNumber;
            this.props.form.applicantDetails.applicantBillToLocationDetails.emailAddress =
              s.billToSettingsData.emailAddress;
            this.props.form.applicantDetails.applicantBillToLocationDetails.accountNumberBillTo =
              s.billToSettingsData.accountNumberBillTo;

            this.setState({ isCompanyDisable: false });
            this.setState({ isAddress1Disable: false });
            this.setState({ isAddress2Disable: false });
            this.setState({ isAddress3Disable: false });
            this.setState({ isCountryDisable: false });
            this.setState({ isStateDisable: false });
            this.setState({ isCityDisable: false });
            this.setState({ isPostalCodeDisable: false });
            this.setState({ isTaxIdDisable: false });
            this.setState({ isContactNameDisable: false });
            this.setState({ isPhoneNoDisable: false });
            this.setState({ isEmailDisable: false });
          } else {
            //console.log("null");
            if (this.props.accountNumbersOptions) {
              this.props.form.applicantDetails.applicantBillToLocationDetails.legalCompanyName =
                "";
              this.props.form.applicantDetails.applicantBillToLocationDetails.addressLine1 =
                "";
              this.props.form.applicantDetails.applicantBillToLocationDetails.addressLine2 =
                "";
              this.props.form.applicantDetails.applicantBillToLocationDetails.addressLine3 =
                "";
              this.props.form.applicantDetails.applicantBillToLocationDetails.country =
                "";
              this.props.form.applicantDetails.applicantBillToLocationDetails.state =
                "";
              this.props.form.applicantDetails.applicantBillToLocationDetails.city =
                "";
              this.props.form.applicantDetails.applicantBillToLocationDetails.postalCode =
                "";
              this.props.form.applicantDetails.applicantBillToLocationDetails.taxpayerId =
                "";
              this.props.form.applicantDetails.applicantBillToLocationDetails.accountNumberBillTo =
                "";
              this.props.form.applicantDetails.applicantBillToLocationDetails.contactName =
                "";
              this.props.form.applicantDetails.applicantBillToLocationDetails.phoneNumber =
                "";
              this.props.form.applicantDetails.applicantBillToLocationDetails.emailAddress =
                "";
              this.setState({ isCompanyDisable: false });
              this.setState({ isAddress1Disable: false });
              this.setState({ isAddress2Disable: false });
              this.setState({ isAddress3Disable: false });
              this.setState({ isCountryDisable: false });
              this.setState({ isStateDisable: false });
              this.setState({ isCityDisable: false });
              this.setState({ isPostalCodeDisable: false });
              this.setState({ isTaxIdDisable: false });
              this.setState({ isContactNameDisable: false });
              this.setState({ isPhoneNoDisable: false });
              this.setState({ isEmailDisable: false });
            }
          }

          if (s.contactName) {
            setTimeout(() => {
              ToastHelper.success({
                description: (
                  <p>{`Contact ${s.contactName} already exist in lexington`}</p>
                )
              });
            }, 100);
          }
        });
    }
  };

  handleVerify = () => {
    if (this.props.form.applicantDetails.applicantDetails.emailAddress) {
      this.props
        .isEmailExist(
          this.props.form.applicantDetails.applicantDetails.emailAddress
        )
        .then((s) => {
          if (s) {
            setTimeout(() => {
              ToastHelper.success({
                description: (
                  <p>{`Email ${this.props.form.applicantDetails.applicantDetails.emailAddress} is exist in My UL Portal`}</p>
                )
              });
            }, 100);
          } else {
            setTimeout(() => {
              ToastHelper.error({
                description: (
                  <p>{`Email ${this.props.form.applicantDetails.applicantDetails.emailAddress} doesn't exist in My UL Portal`}</p>
                )
              });
            }, 100);
          }
        });
    }
  };

  handleTextboxAccountNumberChange = (accountNumber) => {
    this.props.getCustomerRelationInformations(accountNumber);
  };

  render() {
    const {
      form,
      isLoading,
      isLoadingGetSettings,
      isSuccess,
      isSuccessGetSettings,
      customerDetails,
      countryOptions,
      countryOptionsBillTo,
      cityOptions,
      cityOptionsBillTo,
      stateOptions,
      stateOptionsBillTo,
      accountSettings,
      accountNumbersOptions,
      customerRelationsData,
      customerRelationInformationsOptions,
      customerRelationInformationsData,
      applicantContactInformations,
      applicantContactInformationsOptions
    } = this.props;
    const {
      isSuccessGetAccountDetails,
      customerRefTextInfo,
      partySiteNumber,
      accountNumber,
      isSucessCustomer,
      isAccountNumbersEmpty,
      isSearchTrigger,
      oldAccountNumber,
      isCompanyDisable,
      isAddress1Disable,
      isAddress2Disable,
      isAddress3Disable,
      isCountryDisable,
      isStateDisable,
      isCityDisable,
      isPostalCodeDisable,
      isTaxIdDisable,
      isContactNameDisable,
      isPhoneNoDisable,
      isEmailDisable,
      isLoadCustomerRelations,
      oldBillToCityOptions,
      isFirstLoad,
      isBillToAccountNumberEmpty,
      countryData,
      stateData,
      cityData,
      isApplicantContactEmpty,
      isApplicantPhoneNoDisable,
      isApplicantEmailDisable,
      isLoadProjectHandler,
      isSelectedContact,
      isVerifyButtonHidden,
      isCountryLoad,
      isContactNameLoad,
      isSameAccount,
      isFeSelectedContact
    } = this.state;

    var roleId = Common.getUserRole();

    var isDisabled = roleId == Role.projectHandler && !isSuccessGetSettings;

    let accountDetails = customerDetails.data;

    // if (
    //   roleId == Role.projectHandler &&
    //   form.applicantDetails.applicantDetails.accountNumber &&
    //   isLoadProjectHandler
    // ) {
    //   this.setState({ isLoadProjectHandler: false });
    //   this.setCustomerInformationEmpty();
    // }

    if (accountDetails) {
      // form.applicantDetails.applicantDetails = accountDetails;
      form.applicantDetails.applicantDetails.psn = accountDetails.psn;
      form.applicantDetails.applicantDetails.legalCompanyName =
        accountDetails.partyName;
      form.applicantDetails.applicantDetails.addressLine1 =
        accountDetails.addressLine1;
      form.applicantDetails.applicantDetails.city = accountDetails.city;
      form.applicantDetails.applicantDetails.state = accountDetails.state;
      form.applicantDetails.applicantDetails.postalCode =
        accountDetails.postalCode;
      form.applicantDetails.applicantDetails.country =
        accountDetails.countryCode;
      form.applicantDetails.applicantDetails.showErrors = false;

      // if (roleId == Role.projectHandler) {
      //   form.applicantDetails.applicantDetails.validationErrors = {};
      // }

      if (
        this.props.countryOptionsBillTo &&
        this.props.countryOptionsBillTo.length > 0 &&
        roleId == Role.projectHandler &&
        this.props.form.applicantDetails.applicantBillToLocationDetails
          .country &&
        this.props.form.applicantDetails.applicantBillToLocationDetails.state &&
        this.props.form.applicantDetails.applicantBillToLocationDetails.city &&
        form.applicantDetails.applicantDetails.contactName &&
        isCountryLoad
      ) {
        this.setState({ isCountryLoad: false });
        this.handleCountryValue(
          this.props.form.applicantDetails.applicantBillToLocationDetails
            .country
        );
        this.handleStateValue(
          this.props.form.applicantDetails.applicantBillToLocationDetails.state
        );
      }

      // if (
      //   customerRelationInformationsOptions &&
      //   customerRelationInformationsOptions.length > 0 &&
      //   isContactNameLoad
      // ) {
      //   this.setState({ isContactNameLoad: false });
      //   this.handleDropdownChange(
      //     "contactName",
      //     this.props.form.applicantDetails.applicantBillToLocationDetails
      //       .contactName,
      //     "AD-ABTLD"
      //   );
      // }

      if (
        accountDetails.accountNumber &&
        !isSucessCustomer &&
        roleId != Role.projectHandler
      ) {
        this.setState({ isSucessCustomer: true });
        this.handleLoadAccountDetails(accountDetails.accountNumber, "true");
        this.handleFieldsEnable();
      }

      if (
        this.props.cityOptionsBillTo &&
        this.props.cityOptionsBillTo.length == 0 &&
        roleId != Role.projectHandler &&
        !isFirstLoad
      ) {
        this.setState({ isFirstLoad: true });
      }
      if (
        roleId != Role.projectHandler &&
        this.props.cityOptionsBillTo &&
        this.props.cityOptionsBillTo.length > 0
      ) {
        if (isFirstLoad) {
          this.setState({ isFirstLoad: false });
          this.handleCityPopulate();
        }
      }

      if (
        roleId != Role.projectHandler &&
        this.props.customerRelationsData &&
        this.props.customerRelationsData.length > 0 &&
        !isLoadCustomerRelations
      ) {
        this.setState({ isLoadCustomerRelations: true });
        this.handleAccntNumDorpDownChange(
          this.props.form.applicantDetails.applicantBillToLocationDetails
            .accountNumberBillTo
        );
        this.handleCustomerInfoDorpDownChange(
          this.props.form.applicantDetails.applicantBillToLocationDetails
            .contactName
        );
      }

      if (
        oldAccountNumber != accountDetails.accountNumber &&
        roleId == Role.projectHandler
      ) {
        this.setState({ isSearchTrigger: true });
        this.setState({ isSameAccount: true });
      }

      if (
        this.props.accountNumbersOptions == null &&
        isBillToAccountNumberEmpty
      ) {
        this.setState({ isBillToAccountNumberEmpty: false });
      }

      if (
        this.props.customerRelationInformationsOptions &&
        this.props.customerRelationInformationsOptions.length > 0 &&
        roleId != Role.projectHandler &&
        isFeSelectedContact
      ) {
        this.setState({ isFeSelectedContact: false });
        var contactBillTo =
          this.props.form.applicantDetails.applicantBillToLocationDetails
            .contactName;
        var contact = this.props.customerRelationInformationsOptions.find(
          function (item) {
            return item.value == contactBillTo;
          }
        );
        if (contact) {
        } else {
          this.props.form.applicantDetails.applicantBillToLocationDetails.contactName =
            "";
        }
      }
      // comment for dev envi (enable to work in local)
      // if (
      //   form.applicantDetails.applicantBillToLocationDetails.accountNumber &&
      //   this.props.accountNumbersOptions &&
      //   this.props.accountNumbersOptions.length > 0 &&
      //   this.props.customerRelationInformationsOptions &&
      //   customerRelationInformationsOptions.length > 0 &&
      //   !isBillToAccountNumberEmpty &&
      //   roleId == Role.projectHandler
      // ) {
      //   this.setState({ isBillToAccountNumberEmpty: true });
      //   this.setCustomerInformationEmpty();
      //   console.log("trigger927");
      // }

      // if (
      //   this.state.oldBillToCityOptions == null &&
      //   this.props.cityOptionsBillTo &&
      //   this.props.cityOptionsBillTo.length > 0 &&
      //   roleId == Role.projectHandler
      // ) {
      //   this.setState({ oldBillToCityOptions: this.props.cityOptionsBillTo });
      //   this.handleCityPopulate();
      // }
      // if (
      //   this.state.oldBillToCityOptions &&
      //   this.state.oldBillToCityOptions.length > 0 &&
      //   this.props.cityOptionsBillTo &&
      //   this.props.cityOptionsBillTo.length > 0 &&
      //   roleId == Role.projectHandler
      // ) {
      //   if (
      //     JSON.stringify(this.props.cityOptionsBillTo) !=
      //     JSON.stringify(this.state.oldBillToCityOptions)
      //   ) {
      //     this.setState({ oldBillToCityOptions: this.props.cityOptionsBillTo });
      //     this.handleCityPopulate();
      //   }
      // }

      if (
        this.props.cityOptionsBillTo &&
        this.props.cityOptionsBillTo.length == 0 &&
        roleId == Role.projectHandler &&
        !isFirstLoad
      ) {
        this.setState({ isFirstLoad: true });
      }
      if (
        roleId == Role.projectHandler &&
        this.props.cityOptionsBillTo &&
        this.props.cityOptionsBillTo.length > 0
      ) {
        if (isFirstLoad) {
          this.setState({ isFirstLoad: false });
          this.handleCityPopulate();
        }
      }

      if (
        this.props.applicantContactInformationsOptions &&
        this.props.applicantContactInformationsOptions.length > 0 &&
        roleId == Role.projectHandler &&
        isApplicantContactEmpty
      ) {
        this.setState({ isApplicantContactEmpty: false });
      }

      if (
        this.props.customerRelationInformationsOptions &&
        this.props.customerRelationInformationsOptions.length > 0 &&
        roleId == Role.projectHandler &&
        this.props.form.applicantDetails.applicantBillToLocationDetails &&
        this.props.form.applicantDetails.applicantBillToLocationDetails
          .contactName &&
        isSelectedContact
      ) {
        this.setState({ isSelectedContact: false });
        this.handleCustomerInfoDorpDownChange(
          this.props.form.applicantDetails.applicantBillToLocationDetails
            .contactName
        );
      }

      //for no account number list purposes
      if (
        !isApplicantContactEmpty &&
        accountDetails.accountNumber &&
        this.props.accountNumbersOptions == null &&
        isSameAccount &&
        roleId == Role.projectHandler
      ) {
        this.setState({ isSameAccount: false });

        if (accountDetails.partyName) {
          this.setState({ isCompanyDisable: "true" });
        } else {
          this.setState({ isCompanyDisable: "false" });
        }

        if (accountDetails.addressLine1) {
          this.setState({ isAddress1Disable: "true" });
        } else {
          this.setState({ isAddress1Disable: "false" });
        }

        if (accountDetails.postalCode) {
          this.setState({ isPostalCodeDisable: "true" });
        } else {
          this.setState({ isPostalCodeDisable: "false" });
        }

        if (accountDetails.countryCode) {
          this.setState({ isCountryDisable: "true" });
        } else {
          this.setState({ isCountryDisable: "false" });
        }
        if (accountDetails.state) {
          this.setState({ isStateDisable: "true" });
        } else {
          this.setState({ isStateDisable: "false" });
        }
        if (accountDetails.city) {
          this.setState({ isCityDisable: "true" });
        } else {
          this.setState({ isCityDisable: "false" });
        }

        form.applicantDetails.applicantBillToLocationDetails.accountNumber =
          accountDetails.accountNumber;

        form.applicantDetails.applicantBillToLocationDetails.legalCompanyName =
          accountDetails.partyName;

        form.applicantDetails.applicantBillToLocationDetails.addressLine1 =
          accountDetails.addressLine1;
        form.applicantDetails.applicantBillToLocationDetails.postalCode =
          accountDetails.postalCode;

        this.handleDropdownChange(
          "country",
          accountDetails.countryCode,
          "AD-ABTLD"
        );
        this.handleCountryValue(accountDetails.countryCode);
        this.handleGetStates(accountDetails.countryCode, 2);

        this.handleDropdownChange("state", accountDetails.state, "AD-ABTLD");
        this.handleGetCities(accountDetails.state, 2);
        this.handleStateValue(accountDetails.state);

        form.applicantDetails.applicantBillToLocationDetails.city =
          accountDetails.city;

        this.handleCityPopulate();

        this.handleTextboxAccountNumberChange(accountDetails.accountNumber);
      }

      if (isSearchTrigger) {
        this.setState({ isSearchTrigger: false });
        this.props.getApplicantContactInformations(
          accountDetails.accountNumber
        );
        this.handleLoadAccountDetails(accountDetails.accountNumber, "true");
        this.setState({ oldAccountNumber: accountDetails.accountNumber });
        //this.setCustomerInformationEmpty();
        this.handleFieldsEnable();
      }
    }

    if (
      accountNumbersOptions &&
      accountNumbersOptions.length > 0 &&
      isAccountNumbersEmpty
    ) {
      this.setState({ isAccountNumbersEmpty: false });
    }
    if (accountNumbersOptions == null && !isAccountNumbersEmpty) {
      this.setState({ isAccountNumbersEmpty: true });
    }

    this.handleCheckExistingCityState(form);

    if (isSuccess == true) {
      this.props.resetAccountSettingsForm();
      localStorage.setItem("isSuccessSetAccountSettings", isSuccess);
      localStorage.setItem(
        "accountSettingsPsn",
        accountSettings.user.partySiteNumber
      );
      if (roleId == Role.projectHandler) {
        var redirect = this.props.match.params.redirect;
        var orderNumber = this.props.match.params.orderNumber;

        if (redirect) {
          if (orderNumber) {
            return (
              <Redirect to={`/home/view-order-details/edit/${orderNumber}`} />
            );
          } else {
            return <Redirect to="/home/new-quote" />;
          }
        } else return <Redirect to="/home/notifications" />;
      } else {
        this.props.getAccountSettings();
        return <Redirect to="/home/notifications" />;
      }
    }

    return (
      <Fragment>
        <div style={{ width: "80%", padding: "20px" }}>
          <Form loading={isLoadingGetSettings}>
            <span hidden={!isSuccess}>
              <NotificationSuccess message="Account settings saved successfully." />
            </span>
            {roleId == Role.projectHandler && (
              <Grid>
                <Grid.Row columns="3">
                  <Grid.Column>
                    <Form.Field
                      control={Input}
                      label="Party Site #"
                      placeholder="Enter Party Site #"
                      name="partySiteNumber"
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                      value={partySiteNumber}
                      autoComplete="off"
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Field
                      control={Input}
                      label="Account Number"
                      placeholder="Enter Account Number"
                      name="accountNumber"
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                      value={accountNumber}
                      autoComplete="off"
                      disabled
                    />
                  </Grid.Column>
                  <Grid.Column verticalAlign="bottom">
                    <Button primary onClick={this.handleSearch}>
                      Search
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            )}
            <Segment>
              <Grid>
                <Grid.Row>
                  <Grid.Column>
                    <CustomHeaderBold message="Company Applicant Details" />
                    <p>
                      This is the Company contracting UL to provide the service,
                      assuming all financial obligation for the cost of the
                      project:
                    </p>
                    <p>
                      If you need to make any changes to the Company Account
                      details, please contact us at 877.854.3577, prompt # 2 or
                      field@ul.com
                    </p>
                    <Divider fitted />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                  <Grid.Column>
                    <CustomTitleLabel message={"Account Number:"} />
                    <br />
                    <CustomLabel
                      message={accountDetails && accountDetails.accountNumber}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <CustomTitleLabel message={"Party Site #:"} />
                    <br />
                    <CustomLabel
                      message={accountDetails && accountDetails.psn}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                  <Grid.Column>
                    <CustomTitleLabel message={"Legal Company Name:"} />
                    <br />
                    <CustomLabel
                      message={accountDetails && accountDetails.partyName}
                    />
                  </Grid.Column>

                  <Grid.Column>
                    <CustomTitleLabel message={"Address:"} />
                    <br />
                    <CustomLabel
                      message={
                        accountDetails &&
                        `${accountDetails.addressLine1} ${accountDetails.city} ${accountDetails.state} ${accountDetails.countryCode} ${accountDetails.postalCode}`
                      }
                    />
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row columns={4}>
                  <Grid.Column>
                    <span hidden={!isApplicantContactEmpty}>
                      <Form.Field
                        id="form-input-control-lcn"
                        control={Input}
                        label="* Contact Name"
                        placeholder="Enter contact name"
                        name="contactName"
                        onChange={(e) => {
                          this.handleTextboxChange(e, "AD-AD");
                        }}
                        value={
                          form.applicantDetails.applicantDetails.contactName ||
                          ""
                        }
                        error={this.getErrorFor("contactName", "AD-AD")}
                        autoComplete="off"
                      />
                    </span>
                    <span hidden={isApplicantContactEmpty}>
                      <Form.Field
                        control={Dropdown}
                        disabled={isLoading}
                        compact
                        selection
                        label="* Contact Name"
                        selectOnBlur={false}
                        placeholder="Select Contact Name"
                        options={applicantContactInformationsOptions}
                        onChange={(e, properties) => {
                          this.handleDropdownChange(
                            "contactName",
                            properties.value,
                            "AD-AD"
                          );
                          this.handleApplicantContactInformations(
                            properties.value
                          );
                        }}
                        value={
                          (form.applicantDetails.applicantDetails &&
                            form.applicantDetails.applicantDetails
                              .contactName) ||
                          ""
                        }
                        error={this.getErrorFor("contactName", "AD-AD")}
                      />
                    </span>
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Field
                      id="form-input-control-lcn"
                      control={Input}
                      readOnly={isApplicantEmailDisable}
                      label="* Email Address"
                      placeholder="Enter email address"
                      name="emailAddress"
                      onChange={(e) => {
                        this.handleTextboxChange(e, "AD-AD");
                      }}
                      value={
                        form.applicantDetails.applicantDetails.emailAddress ||
                        ""
                      }
                      error={this.getErrorFor("emailAddress", "AD-AD")}
                      autoComplete="off"
                    />
                    <span hidden={isVerifyButtonHidden}>
                      <Form.Field>
                        <Button
                          color="blue"
                          onClick={this.handleVerify.bind(this)}
                        >
                          Verify
                        </Button>
                      </Form.Field>
                    </span>
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Field
                      id="form-input-control-lcn"
                      control={Input}
                      readOnly={isApplicantPhoneNoDisable}
                      label="* Phone No"
                      placeholder="Enter phone no"
                      name="phoneNumber"
                      onChange={(e) => {
                        this.handleTextboxChange(e, "AD-AD");
                      }}
                      value={
                        form.applicantDetails.applicantDetails.phoneNumber || ""
                      }
                      error={this.getErrorFor("phoneNumber", "AD-AD")}
                      autoComplete="off"
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Field
                      id="form-input-control-lcn"
                      control={Input}
                      label="Cell No"
                      placeholder="Enter cell no"
                      name="mobileNumber"
                      onChange={(e) => {
                        this.handleTextboxChange(e, "AD-AD");
                      }}
                      value={
                        form.applicantDetails.applicantDetails.mobileNumber ||
                        ""
                      }
                      error={this.getErrorFor("mobileNumber", "AD-AD")}
                      autoComplete="off"
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <Form.Field
                      id="form-input-control-lcn"
                      control={Input}
                      label="Taxpayer ID # (TIN/VAT)"
                      placeholder="Enter taxpayer id # (tin/vat)"
                      name="taxpayerId"
                      onChange={(e) => {
                        this.handleTextboxChange(e, "AD-AD");
                      }}
                      value={
                        form.applicantDetails.applicantDetails.taxpayerId || ""
                      }
                      error={this.getErrorFor("taxpayerId", "AD-AD")}
                      autoComplete="off"
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <CustomHeaderBold message="Notification Settings" />
                    <Divider fitted />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <Checkbox
                      label="Send also via E-mail"
                      checked={
                        form.accountSettings &&
                        form.accountSettings.isSentByEmail
                      }
                      onChange={(e, { checked }) => {
                        this.handleSettingsCheckboxChange(
                          "isSentByEmail",
                          checked,
                          "AS"
                        );
                      }}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <CustomHeaderInlineBold message="Customer Reference Settings " />
                    <Popup
                      content={customerRefTextInfo}
                      trigger={
                        <Icon
                          style={{ marginLeft: "5px" }}
                          circular
                          size="small"
                          name="question"
                        />
                      }
                    />

                    <Divider fitted />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <Checkbox
                      label="You use a Project or Job # that you would like referenced?"
                      checked={
                        form.accountSettings &&
                        form.accountSettings.isReferenceNoUsed
                      }
                      onChange={(e, { checked }) => {
                        this.handleSettingsCheckboxChange(
                          "isReferenceNoUsed",
                          checked,
                          "AS"
                        );
                      }}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <CustomHeaderBold message="Bill To Settings" />
                    <Divider fitted />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={4}>
                  <Grid.Column>
                    <Form.Field>
                      <span hidden={isAccountNumbersEmpty}>
                        <Form.Field
                          control={Dropdown}
                          compact
                          selection
                          label="* Account Number"
                          selectOnBlur={false}
                          disabled={isLoading}
                          placeholder="Select Account Number"
                          options={accountNumbersOptions}
                          onChange={(e, properties) => {
                            this.handleDropdownChange(
                              "accountNumberBillTo",
                              properties.value,
                              "AD-ABTLD"
                            );
                            this.handleAccntNumDorpDownChange(properties.value);
                          }}
                          value={
                            (form.applicantDetails
                              .applicantBillToLocationDetails &&
                              form.applicantDetails
                                .applicantBillToLocationDetails
                                .accountNumberBillTo) ||
                            ""
                          }
                          error={this.getErrorFor(
                            "accountNumberBillTo",
                            "AD-ABTLD"
                          )}
                        />
                      </span>
                      <span hidden={!isAccountNumbersEmpty}>
                        <Form.Field
                          control={Input}
                          label="* Account Number"
                          placeholder="Enter account number"
                          name="accountNumber"
                          value={
                            (form.applicantDetails
                              .applicantBillToLocationDetails &&
                              form.applicantDetails
                                .applicantBillToLocationDetails
                                .accountNumber) ||
                            ""
                          }
                          error={this.getErrorFor("accountNumber", "AD-ABTLD")}
                          autoComplete="off"
                        />
                      </span>
                    </Form.Field>
                  </Grid.Column>
                  <Grid.Column />
                  <Grid.Column />
                  <Grid.Column />
                </Grid.Row>
                {/* //hide request by user */}
                <span hidden="true">
                  <Grid.Row>
                    <Grid.Column>
                      {/* <CustomLabel message="APPLICANT BILL TO LOCATION DETAILS" />
                    <Divider fitted /> */}
                      <Divider fitted hidden />
                      <Divider fitted hidden />
                      <Checkbox
                        label="Same as the Company Applicant information"
                        checked={form.applicantDetails.isSameAsAbove}
                        onChange={(e, { checked }) => {
                          this.handleCheckboxChange(
                            "isSameAsAbove",
                            checked,
                            "AD"
                          );
                        }}
                        // disabled={preview}
                      />
                    </Grid.Column>
                  </Grid.Row>
                </span>
                <Grid.Row>
                  <Grid.Column>
                    <Form.Input
                      label="* Legal Company Name"
                      placeholder="Enter legal company name"
                      readOnly={isCompanyDisable == "true" ? "1" : ""}
                      name="legalCompanyName"
                      onChange={(e) => {
                        this.handleTextboxChange(e, "AD-ABTLD");
                      }}
                      value={
                        form.applicantDetails.applicantBillToLocationDetails
                          .legalCompanyName || ""
                      }
                      error={this.getErrorFor("legalCompanyName", "AD-ABTLD")}
                      autoComplete="off"
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                  <Grid.Column>
                    <Form.Input
                      label="* Street Address Line 1"
                      placeholder="Enter street address line 1"
                      readOnly={isAddress1Disable == "true" ? "1" : ""}
                      name="addressLine1"
                      onChange={(e) => {
                        this.handleTextboxChange(e, "AD-ABTLD");
                      }}
                      value={
                        form.applicantDetails.applicantBillToLocationDetails
                          .addressLine1 || ""
                      }
                      error={this.getErrorFor("addressLine1", "AD-ABTLD")}
                      autoComplete="off"
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Input
                      label="Street Address Line 2"
                      placeholder="Enter street address line 2"
                      readOnly={isAddress2Disable == "true" ? "1" : ""}
                      name="addressLine2"
                      onChange={(e) => {
                        this.handleTextboxChange(e, "AD-ABTLD");
                      }}
                      value={
                        form.applicantDetails.applicantBillToLocationDetails
                          .addressLine2 || ""
                      }
                      error={this.getErrorFor("addressLine2", "AD-ABTLD")}
                      autoComplete="off"
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Input
                      label="Street Address Line 3"
                      placeholder="Enter street address line 3"
                      readOnly={isAddress3Disable == "true" ? "1" : ""}
                      name="addressLine3"
                      onChange={(e) => {
                        this.handleTextboxChange(e, "AD-ABTLD");
                      }}
                      value={
                        form.applicantDetails.applicantBillToLocationDetails
                          .addressLine3 || ""
                      }
                      error={this.getErrorFor("addressLine3", "AD-ABTLD")}
                      autoComplete="off"
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={4}>
                  <Grid.Column>
                    <span hidden={isCountryDisable == "true" ? "1" : ""}>
                      <Form.Field
                        control={Dropdown}
                        compact
                        selectOnBlur={false}
                        selection
                        label="* Country"
                        placeholder="Select Country"
                        options={countryOptionsBillTo}
                        onChange={(e, properties) => {
                          this.handleDropdownChange(
                            "country",
                            properties.value,
                            "AD-ABTLD"
                          );
                          this.handleGetStates(properties.value, 2);
                        }}
                        value={
                          (form.applicantDetails
                            .applicantBillToLocationDetails &&
                            form.applicantDetails.applicantBillToLocationDetails
                              .country) ||
                          ""
                        }
                        error={this.getErrorFor("country", "AD-ABTLD")}
                      />
                    </span>
                    <span hidden={isCountryDisable == "true" ? "" : "1"}>
                      <Form.Input
                        label="* Country"
                        readOnly="1"
                        name="country"
                        value={this.state.countryData}
                        autoComplete="off"
                      />
                    </span>
                    {/* <Form.Field>
                      <label>* Country</label>
                      <Dropdown
                        compact
                        selection
                        placeholder="Select Country"
                        options={countryOptionsBillTo}
                        search={true}
                        onChange={(e, properties) => {
                          this.handleDropdownChange(
                            "billTo.country",
                            properties.value
                          );
                          this.handleGetStates(properties.value, 2);
                        }}
                        value={form.billTo && form.billTo.country}
                      />
                    </Form.Field> */}
                  </Grid.Column>
                  <Grid.Column>
                    <span hidden={isStateDisable == "true" ? "1" : ""}>
                      <Form.Field
                        control={Dropdown}
                        compact
                        selection
                        selectOnBlur={false}
                        label="* State"
                        placeholder="Select State"
                        options={stateOptionsBillTo}
                        onChange={(e, properties) => {
                          this.handleDropdownChange(
                            "state",
                            properties.value,
                            "AD-ABTLD"
                          );
                          this.handleGetCities(properties.value, 2);
                        }}
                        value={
                          (form.applicantDetails
                            .applicantBillToLocationDetails &&
                            form.applicantDetails.applicantBillToLocationDetails
                              .state) ||
                          ""
                        }
                        error={this.getErrorFor("state", "AD-ABTLD")}
                      />
                    </span>
                    <span hidden={isStateDisable == "true" ? "" : "1"}>
                      <Form.Input
                        label="* State"
                        readOnly="1"
                        name="state"
                        value={this.state.stateData}
                        autoComplete="off"
                      />
                    </span>
                    {/* <Form.Field>
                      <label>* State</label>
                      <Dropdown
                        compact
                        selection
                        placeholder="Select State"
                        options={stateOptionsBillTo}
                        search={true}
                        onChange={(e, properties) => {
                          this.handleDropdownChange(
                            "billTo.state",
                            properties.value
                          );
                          this.handleGetCities(properties.value, 2);
                        }}
                        value={form.billTo && form.billTo.state}
                      />
                    </Form.Field> */}
                  </Grid.Column>
                  <Grid.Column>
                    <span hidden={isCityDisable == "true" ? "1" : ""}>
                      <Form.Field
                        control={Dropdown}
                        compact
                        selectOnBlur={false}
                        selection
                        label="* City"
                        placeholder="Select City"
                        options={cityOptionsBillTo}
                        onChange={(e, properties) => {
                          this.handleDropdownChange(
                            "city",
                            properties.value,
                            "AD-ABTLD"
                          );
                        }}
                        value={
                          (form.applicantDetails
                            .applicantBillToLocationDetails &&
                            form.applicantDetails.applicantBillToLocationDetails
                              .city) ||
                          ""
                        }
                        error={this.getErrorFor("city", "AD-ABTLD")}
                      />
                    </span>
                    <span hidden={isCityDisable == "true" ? "" : "1"}>
                      <Form.Input
                        label="* City"
                        readOnly="1"
                        name="city"
                        value={
                          form.applicantDetails.applicantBillToLocationDetails
                            .city
                        }
                        autoComplete="off"
                      />
                    </span>
                    {/* <Form.Field>
                      <label>* City</label>
                      <Dropdown
                        compact
                        selection
                        placeholder="Select City"
                        options={cityOptionsBillTo}
                        search={true}
                        onChange={(e, properties) => {
                          this.handleDropdownChange(
                            "billTo.city",
                            properties.value
                          );
                        }}
                        value={form.billTo && form.billTo.city}
                      />
                    </Form.Field> */}
                  </Grid.Column>
                  {/* <Grid.Column>
                    <Form.Field
                      id="form-input-control-lcn"
                      control={Input}
                      label="* Province"
                      placeholder="Enter Province"
                      name="province"
                      onChange={e => {
                        this.handleTextboxChange(e, "AD-ABTLD");
                      }}
                      value={
                        form.applicantDetails.applicantBillToLocationDetails &&
                        form.applicantDetails.applicantBillToLocationDetails
                          .province
                      }
                      error={this.getErrorFor("province", "AD-ABTLD")}
                      disabled={form.applicantDetails.isSameAsAbove}
                      autoComplete="off"
                    />
                  </Grid.Column> */}
                  <Grid.Column>
                    <Form.Field
                      id="form-input-control-lcn"
                      control={Input}
                      label="* Postal Code"
                      placeholder="Enter postal code"
                      readOnly={isPostalCodeDisable == "true" ? "1" : ""}
                      name="postalCode"
                      onChange={(e) => {
                        this.handleTextboxChange(e, "AD-ABTLD");
                      }}
                      value={
                        form.applicantDetails.applicantBillToLocationDetails
                          .postalCode || ""
                      }
                      error={this.getErrorFor("postalCode", "AD-ABTLD")}
                      autoComplete="off"
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={4}>
                  <Grid.Column>
                    <Form.Field
                      id="form-input-control-lcn"
                      control={Input}
                      label="Taxpayer ID # (TIN/VAT)"
                      placeholder="Enter taxpayer id # (tin/vat)"
                      readOnly={isTaxIdDisable == "true" ? "1" : ""}
                      name="taxpayerId"
                      onChange={(e) => {
                        this.handleTextboxChange(e, "AD-ABTLD");
                      }}
                      value={
                        form.applicantDetails.applicantBillToLocationDetails
                          .taxpayerId || ""
                      }
                      error={this.getErrorFor("taxpayerId", "AD-ABTLD")}
                      autoComplete="off"
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <span
                      hidden={
                        customerRelationInformationsOptions &&
                        customerRelationInformationsOptions.length > 0
                      }
                    >
                      <Form.Field
                        id="form-input-control-lcn"
                        control={Input}
                        label="* Contact Name"
                        placeholder="Enter contact name"
                        name="contactName"
                        onChange={(e) => {
                          this.handleTextboxChange(e, "AD-ABTLD");
                        }}
                        value={
                          form.applicantDetails.applicantBillToLocationDetails
                            .contactName || ""
                        }
                        error={this.getErrorFor("contactName", "AD-ABTLD")}
                        autoComplete="off"
                      />
                    </span>
                    <span
                      hidden={
                        customerRelationInformationsOptions &&
                        customerRelationInformationsOptions.length == 0
                      }
                    >
                      <Form.Field
                        control={Dropdown}
                        compact
                        selection
                        label="* Contact Name"
                        selectOnBlur={false}
                        disabled={
                          !form.applicantDetails.applicantBillToLocationDetails
                            .accountNumberBillTo &&
                          customerRelationInformationsOptions &&
                          customerRelationInformationsOptions.length == 0
                        }
                        placeholder="Select Contact Name"
                        options={customerRelationInformationsOptions}
                        onChange={(e, properties) => {
                          this.handleDropdownChange(
                            "contactName",
                            properties.value,
                            "AD-ABTLD"
                          );
                          this.handleCustomerInfoDorpDownChange(
                            properties.value
                          );
                        }}
                        value={
                          (form.applicantDetails
                            .applicantBillToLocationDetails &&
                            form.applicantDetails.applicantBillToLocationDetails
                              .contactName) ||
                          ""
                        }
                        error={this.getErrorFor("contactName", "AD-ABTLD")}
                      />
                    </span>
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Field
                      id="form-input-control-lcn"
                      control={Input}
                      label="* Phone No"
                      placeholder="Enter phone no"
                      readOnly={isPhoneNoDisable == "true" ? "1" : ""}
                      name="phoneNumber"
                      onChange={(e) => {
                        this.handleTextboxChange(e, "AD-ABTLD");
                      }}
                      value={
                        form.applicantDetails.applicantBillToLocationDetails
                          .phoneNumber || ""
                      }
                      error={this.getErrorFor("phoneNumber", "AD-ABTLD")}
                      autoComplete="off"
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Field
                      id="form-input-control-lcn"
                      control={Input}
                      label="Cell No"
                      placeholder="Enter cell no"
                      name="mobileNumber"
                      onChange={(e) => {
                        this.handleTextboxChange(e, "AD-ABTLD");
                      }}
                      value={
                        form.applicantDetails.applicantBillToLocationDetails
                          .mobileNumber || ""
                      }
                      error={this.getErrorFor("mobileNumber", "AD-ABTLD")}
                      autoComplete="off"
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <Form.Field
                      id="form-input-control-lcn"
                      control={Input}
                      label="* Email Address"
                      placeholder="Enter email address"
                      readOnly={isEmailDisable == "true" ? "1" : ""}
                      name="emailAddress"
                      onChange={(e) => {
                        this.handleTextboxChange(e, "AD-ABTLD");
                      }}
                      value={
                        form.applicantDetails.applicantBillToLocationDetails
                          .emailAddress || ""
                      }
                      error={this.getErrorFor("emailAddress", "AD-ABTLD")}
                      autoComplete="off"
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <Grid>
                <Grid.Column>
                  <Button
                    color="blue"
                    onClick={this.handleSaveAccountSettings.bind(this)}
                    disabled={isDisabled}
                  >
                    Save and Exit
                  </Button>
                </Grid.Column>
              </Grid>
            </Segment>
            <FooterHomeBlock />
          </Form>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  form: state.accountSettings.form,
  isLoading: state.accountSettings.isLoading,
  isLoadingGetSettings: state.accountSettings.isLoadingGetSettings,
  isSuccess: state.accountSettings.isSuccess,
  customerDetails: state.labelCart.customerDetails,
  isSuccessGetCustomerDetails: state.labelCart.isSuccessGetCustomerDetails,
  isSuccessGetSettings: state.accountSettings.isSuccessGetSettings,
  countryOptions: state.accountSettings.countryOptions,
  countryOptionsBillTo: state.accountSettings.countryOptionsBillTo,
  stateOptions: state.accountSettings.stateOptions,
  stateOptionsBillTo: state.accountSettings.stateOptionsBillTo,
  cityOptions: state.accountSettings.cityOptions,
  cityOptionsBillTo: state.accountSettings.cityOptionsBillTo,
  data: state.accountSettings.data,
  accountSettings: state.accountSettings,
  customerRelationsData: state.accountSettings.customerRelationsData,
  accountNumbersOptions: state.accountSettings.accountNumbersOptions,
  customerRelationInformationsOptions:
    state.accountSettings.customerRelationInformationsOptions,
  customerRelationInformationsData:
    state.accountSettings.customerRelationInformationsData,
  applicantContactInformations:
    state.accountSettings.applicantContactInformations,
  applicantContactInformationsOptions:
    state.accountSettings.applicantContactInformationsOptions
});

const mapDispatchToProps = (dispatch) => ({
  updateForm: (result) => dispatch(actions.updateForm(result)),
  createAccountSettings: (data) =>
    dispatch(actions.createAccountSettings(data)),
  getAccountSettings: () => dispatch(actions.getAccountSettings()),
  getAccountSettingsByPsn: (partySiteNumber) =>
    dispatch(actions.getAccountSettingsByPsn(partySiteNumber)),
  getCustomerDetails: (psn) => dispatch(getCustomerDetails(psn)),
  getCountries: (locationType) => dispatch(actions.getCountries(locationType)),
  getStates: (countryCode, locationType) =>
    dispatch(actions.getStates(countryCode, locationType)),
  getCities: (stateCode, locationType) =>
    dispatch(actions.getCities(stateCode, locationType)),
  resetAccountSettingsForm: () => dispatch(actions.resetAccountSettingsForm()),
  getCustomerRelations: (accountNumber, isBillTo) =>
    dispatch(actions.getCustomerRelations(accountNumber, isBillTo)),
  getCustomerRelationInformations: (accountNumber) =>
    dispatch(actions.getCustomerRelationInformations(accountNumber)),
  resetContactInformations: () => dispatch(actions.resetContactInformations()),
  getApplicantContactInformations: (accountNumber) =>
    dispatch(actions.getApplicantContactInformations(accountNumber)),
  isAccountContactExist: (partyName, emailAdd) =>
    dispatch(actions.isAccountContactExist(partyName, emailAdd)),
  isEmailExist: (emailAdd) => dispatch(actions.isEmailExist(emailAdd))
});

export default connect(mapStateToProps, mapDispatchToProps)(AccountSettings);
