import React, { Component } from "react";

import {
  Button,
  Grid,
  Divider,
  Icon,
  Menu,
  Sidebar,
  Accordion,
  List,
  Checkbox,
  Form,
  Radio,
  Dropdown,
  Input,
  Search,
  Header,
  Segment
} from "semantic-ui-react";

import { CustomHeaderInlineBold } from "../../../components/labels/labels";
import { connect } from "react-redux";
import { validateField } from "../../../validator/validator";
import DatePicker from "react-datepicker";

import * as actions from "../role-assignment.action";

import _ from "lodash";

const userStatusOptions = [
  {
    key: "1",
    value: false,
    text: "Active",
    checked: false
  },
  {
    key: "2",
    value: true,
    text: "Deactivate/Suspended",
    checked: false
  }
];

const rules = [];

class RoleAssignmentFilterOption extends Component {
  state = {
    selectedEmails: [],
    selectedFirstNames: [],
    selectedLastNames: [],
    selectedRoles: [],
    selectedLegalEntities: [],
    selectedPartySiteNumbers: [],
    userStatusOptions: userStatusOptions,
    startDate: new Date(),
    endDate: new Date(),
    isLoading: false,
    results: [],
    psnResults: [],
    emailResults: [],
    firstNameResults: [],
    lastNameResults: [],
    psnValue: "",
    emailValue: "",
    firstNameValue: "",
    lastNameValue: ""
  };

  componentDidMount() {
    this.props.getFOEmails();
    this.props.getFOFirstNames();
    this.props.getFOLastNames();
    this.props.getFOLegalEntities();
    this.props.getFOPartySiteNumbers();
    this.props.getFORoles();
  }

  handleDropdownChange = (dpName, dpValue) => {
    const { form } = this.props.roleAssignment;
    validateField(rules, form, dpName, dpValue);
    const newState = { ...this.props.roleAssignment.form };
    this.props.updateForm(newState);
  };

  handleUserStatusChange = (properties) => {
    var { userStatusOptions } = this.state;

    userStatusOptions.map((i) => (i.checked = false));
    const _newState = { ...userStatusOptions };
    this.setState(_newState);

    var selectedUserStatus = userStatusOptions.filter(
      (i) => i.text == properties.label
    );
    selectedUserStatus[0].checked =
      selectedUserStatus[0].checked == false ? true : false;
    const newState = { ...selectedUserStatus };
    this.setState(newState);
  };

  handleAddEmail = (email) => {
    var { selectedEmails } = this.state;

    var newselectedEmails = selectedEmails;
    if (email) {
      if (!newselectedEmails.includes(email)) newselectedEmails.push(email);
    }

    this.props.clearForm();
    this.setState({ isLoading: false, results: [], emailValue: "" });
    this.setState({ selectedEmails: newselectedEmails });
  };

  handleRemoveEmail = (email) => {
    var { selectedEmails } = this.state;
    var newselectedEmails = selectedEmails;
    if (email) {
      const index = newselectedEmails.indexOf(email);
      if (index > -1) {
        newselectedEmails.splice(index, 1);
      }
    }

    this.setState({ selectedEmails: newselectedEmails });
  };

  handleResultSelectEmail = (e, { result }) => {
    this.setState({ emailValue: result.title });

    const { form } = this.props.roleAssignment;
    validateField(rules, form, "email", result.title);
    const newState = { ...this.props.roleAssignment.form };
    this.props.updateForm(newState);
  };

  handleSearchChangeEmail = (e, { value }) => {
    const { foEmailsOption } = this.props.roleAssignment;
    this.setState({ isLoading: true, emailValue: value });

    setTimeout(() => {
      if (this.state.emailValue.length < 1)
        return this.setState({
          isLoading: false,
          emailResults: [],
          emailValue: ""
        });

      const re = new RegExp(_.escapeRegExp(this.state.emailValue), "i");
      const isMatch = (result) => re.test(result.title);

      this.setState({
        isLoading: false,
        emailResults: _.take(_.filter(foEmailsOption, isMatch), 10)
      });
    }, 300);
  };

  handleAddFirstName = (firstName) => {
    var { selectedFirstNames } = this.state;

    var newSelectedFirstNames = selectedFirstNames;
    if (firstName) {
      if (!newSelectedFirstNames.includes(firstName))
        newSelectedFirstNames.push(firstName);
    }

    this.props.clearForm();
    this.setState({ isLoading: false, results: [], firstNameValue: "" });
    this.setState({ selectedFirstNames: newSelectedFirstNames });
  };

  handleRemoveFirstName = (firstName) => {
    var { selectedFirstNames } = this.state;
    var newSelectedFirstNames = selectedFirstNames;
    if (firstName) {
      const index = newSelectedFirstNames.indexOf(firstName);
      if (index > -1) {
        newSelectedFirstNames.splice(index, 1);
      }
    }

    this.setState({ selectedFirstNames: newSelectedFirstNames });
  };

  handleResultSelectFirstName = (e, { result }) => {
    this.setState({ firstNameValue: result.title });

    const { form } = this.props.roleAssignment;
    validateField(rules, form, "firstName", result.title);
    const newState = { ...this.props.roleAssignment.form };
    this.props.updateForm(newState);
  };

  handleSearchChangeFirstName = (e, { value }) => {
    const { foFirstNamesOption } = this.props.roleAssignment;
    this.setState({ isLoading: true, firstNameValue: value });

    setTimeout(() => {
      if (this.state.firstNameValue.length < 1)
        return this.setState({
          isLoading: false,
          firstNameResults: [],
          firstNameValue: ""
        });

      const re = new RegExp(_.escapeRegExp(this.state.firstNameValue), "i");
      const isMatch = (result) => re.test(result.title);

      this.setState({
        isLoading: false,
        firstNameResults: _.take(_.filter(foFirstNamesOption, isMatch), 10)
      });
    }, 300);
  };

  handleAddLastName = (lastName) => {
    var { selectedLastNames } = this.state;

    var newSelectedLastNames = selectedLastNames;
    if (lastName) {
      if (!newSelectedLastNames.includes(lastName))
        newSelectedLastNames.push(lastName);
    }

    this.props.clearForm();
    this.setState({ isLoading: false, results: [], lastNameValue: "" });
    this.setState({ selectedLastNames: newSelectedLastNames });
  };

  handleRemoveLastName = (lastName) => {
    var { selectedLastNames } = this.state;
    var newSelectedLastNames = selectedLastNames;
    if (lastName) {
      const index = newSelectedLastNames.indexOf(lastName);
      if (index > -1) {
        newSelectedLastNames.splice(index, 1);
      }
    }

    this.setState({ selectedLastNames: newSelectedLastNames });
  };

  handleResultSelectLastName = (e, { result }) => {
    this.setState({ lastNameValue: result.title });

    const { form } = this.props.roleAssignment;
    validateField(rules, form, "lastName", result.title);
    const newState = { ...this.props.roleAssignment.form };
    this.props.updateForm(newState);
  };

  handleSearchChangeLastName = (e, { value }) => {
    const { foLastNamesOption } = this.props.roleAssignment;
    this.setState({ isLoading: true, lastNameValue: value });

    setTimeout(() => {
      if (this.state.lastNameValue.length < 1)
        return this.setState({
          isLoading: false,
          lastNameResults: [],
          lastNameValue: ""
        });

      const re = new RegExp(_.escapeRegExp(this.state.lastNameValue), "i");
      const isMatch = (result) => re.test(result.title);

      this.setState({
        isLoading: false,
        lastNameResults: _.take(_.filter(foLastNamesOption, isMatch), 10)
      });
    }, 300);
  };

  handleAddRole = (role) => {
    var { selectedRoles } = this.state;

    var newSelectedRoles = selectedRoles;
    if (role) {
      if (!newSelectedRoles.includes(role)) newSelectedRoles.push(role);
    }

    this.setState({ selectedRoles: newSelectedRoles });
  };

  handleRemoveRole = (role) => {
    var { selectedRoles } = this.state;
    var newSelectedRoles = selectedRoles;
    if (role) {
      const index = newSelectedRoles.indexOf(role);
      if (index > -1) {
        newSelectedRoles.splice(index, 1);
      }
    }

    this.setState({ selectedRoles: newSelectedRoles });
  };

  handleAddLegalEntity = (legalEntity) => {
    var { selectedLegalEntities } = this.state;

    var newSelectedLegalEntities = selectedLegalEntities;
    if (legalEntity) {
      if (!newSelectedLegalEntities.includes(legalEntity))
        newSelectedLegalEntities.push(legalEntity);
    }

    this.setState({ selectedLegalEntities: newSelectedLegalEntities });
  };

  handleRemoveLegalEntity = (legalEntity) => {
    var { selectedLegalEntities } = this.state;
    var newSelectedLegalEntities = selectedLegalEntities;
    if (legalEntity) {
      const index = newSelectedLegalEntities.indexOf(legalEntity);
      if (index > -1) {
        newSelectedLegalEntities.splice(index, 1);
      }
    }

    this.setState({ selectedLegalEntities: newSelectedLegalEntities });
  };

  handleAddPSN = (psn) => {
    var { selectedPartySiteNumbers } = this.state;

    var newSelectedPartySiteNumbers = selectedPartySiteNumbers;
    if (psn) {
      if (!newSelectedPartySiteNumbers.includes(psn))
        newSelectedPartySiteNumbers.push(psn);
    }

    this.props.clearForm();
    this.setState({ isLoading: false, results: [], psnValue: "" });
    this.setState({ selectedPartySiteNumbers: newSelectedPartySiteNumbers });
  };

  handleRemovePSN = (psn) => {
    var { selectedPartySiteNumbers } = this.state;
    var newSelectedPartySiteNumbers = selectedPartySiteNumbers;
    if (psn) {
      const index = newSelectedPartySiteNumbers.indexOf(psn);
      if (index > -1) {
        newSelectedPartySiteNumbers.splice(index, 1);
      }
    }

    this.setState({ selectedPartySiteNumbers: newSelectedPartySiteNumbers });
  };

  handleResultSelectPSN = (e, { result }) => {
    this.setState({ psnValue: result.title });

    const { form } = this.props.roleAssignment;
    validateField(rules, form, "partySiteNumber", result.title);
    const newState = { ...this.props.roleAssignment.form };
    this.props.updateForm(newState);
  };

  handleSearchChangePSN = (e, { value }) => {
    const { foPartySiteNumbersOption } = this.props.roleAssignment;
    this.setState({ isLoading: true, psnValue: value });

    setTimeout(() => {
      if (this.state.psnValue.length < 1) {
        return this.setState({
          isLoading: false,
          psnResults: [],
          psnValue: ""
        });
      }
      const re = new RegExp(_.escapeRegExp(this.state.psnValue), "i");
      const isMatch = (result) => re.test(result.title);

      this.setState({
        isLoading: false,
        psnResults: _.take(_.filter(foPartySiteNumbersOption, isMatch), 10)
      });
    }, 300);
  };

  handleApplyFilter = () => {
    const {
      userStatusOptions,
      selectedEmails,
      selectedFirstNames,
      selectedLastNames,
      selectedRoles,
      selectedLegalEntities,
      selectedPartySiteNumbers
    } = this.state;

    const { form } = this.props.roleAssignment;

    var selectedCreatedDates = [];

    if (form.startDate && form.endDate) {
      selectedCreatedDates.push(form.startDate.toISOString());
      selectedCreatedDates.push(form.endDate.toISOString());
    }

    var selectedUserStatus = null;

    var _selectedUserStatus = userStatusOptions.filter(
      (i) => i.checked == true
    )[0];

    if (_selectedUserStatus) selectedUserStatus = _selectedUserStatus.value;

    var _filterDate = selectedCreatedDates.join("|");
    var _selectedEmails = selectedEmails.join("|");
    var _selectedFirstNames = selectedFirstNames.join("|");
    var _selectedLastNames = selectedLastNames.join("|");
    var _selectedRoles = selectedRoles.join("|");
    var _selectedLegalEntities = selectedLegalEntities.join("|");
    var _selectedPartySiteNumbers = selectedPartySiteNumbers.join("|");

    var data = {
      suspended: selectedUserStatus,
      filterDate: _filterDate,
      userEmails: _selectedEmails,
      userFirstNames: _selectedFirstNames,
      userLastNames: _selectedLastNames,
      userRoles: _selectedRoles,
      legalEntities: _selectedLegalEntities,
      partySiteNumbers: _selectedPartySiteNumbers,
      currentPage: this.props.currentPage,
      pageSize: this.props.pageSize
    };

    this.props.setUserFilter(data);
    this.props.handleToggleFilter();
  };

  handleClearFilter = () => {
    var { userStatusOptions } = this.state;

    var userStatusOptions = userStatusOptions.map(function (item) {
      item.checked = 0;
      return item;
    });

    this.setState({
      userStatusOptions: userStatusOptions,
      selectedEmails: [],
      selectedFirstNames: [],
      selectedLastNames: [],
      selectedRoles: [],
      selectedLegalEntities: [],
      selectedPartySiteNumbers: []
    });

    var selectedEmails = [];
    var selectedFirstNames = [];
    var selectedLastNames = [];
    var selectedRoles = [];
    var selectedLegalEntities = [];
    var selectedPartySiteNumbers = [];
    var selectedCreatedDates = [];

    var selectedUserStatus = null;

    var _selectedUserStatus = userStatusOptions.filter(
      (i) => i.checked == true
    )[0];

    if (_selectedUserStatus) selectedUserStatus = _selectedUserStatus.value;

    var _filterDate = selectedCreatedDates.join("|");
    var _selectedEmails = selectedEmails.join("|");
    var _selectedFirstNames = selectedFirstNames.join("|");
    var _selectedLastNames = selectedLastNames.join("|");
    var _selectedRoles = selectedRoles.join("|");
    var _selectedLegalEntities = selectedLegalEntities.join("|");
    var _selectedPartySiteNumbers = selectedPartySiteNumbers.join("|");

    var data = {
      suspended: selectedUserStatus,
      filterDate: _filterDate,
      userEmails: _selectedEmails,
      userFirstNames: _selectedFirstNames,
      userLastNames: _selectedLastNames,
      userRoles: _selectedRoles,
      legalEntities: _selectedLegalEntities,
      partySiteNumbers: _selectedPartySiteNumbers,
      currentPage: this.props.currentPage,
      pageSize: this.props.pageSize
    };

    this.props.setUserFilter(data);
    this.props.handleToggleFilter();

    this.props.clearForm();
  };

  handleDateChange = (name, value) => {
    const { form } = this.props.roleAssignment;
    const newState = validateField(rules, form, name, value);
    this.props.updateForm(newState);
  };

  render() {
    const {
      isFilterVisible,
      activeIndex,
      filter,
      isGetUserFilterSuccess
    } = this.props;

    const {
      userStatusOptions,
      selectedEmails,
      selectedFirstNames,
      selectedLastNames,
      selectedLegalEntities,
      selectedPartySiteNumbers,
      selectedRoles,
      startDate,
      endDate,
      isLoading,
      results,
      psnResults,
      emailResults,
      firstNameResults,
      lastNameResults,
      psnValue,
      emailValue,
      firstNameValue,
      lastNameValue
    } = this.state;

    const {
      foEmailsOption,
      foFirstNamesOption,
      foLastNamesOption,
      foRolesOption,
      foLegalEntitiesOption,
      foPartySiteNumbersOption,
      form
    } = this.props.roleAssignment;

    if (!_.isEmpty(filter) && isGetUserFilterSuccess) {
      var _suspended = filter.suspended !== null ? filter.suspended : null;
      var _filterDate = filter.filterDate ? filter.filterDate.split("|") : [];
      var _userEmails = filter.userEmails ? filter.userEmails.split("|") : [];
      var _userFirstNames = filter.userFirstNames
        ? filter.userFirstNames.split("|")
        : [];
      var _userLastNames = filter.userLastNames
        ? filter.userLastNames.split("|")
        : [];
      var _userRoles = filter.userRoles ? filter.userRoles.split("|") : [];
      var _legalEntities = filter.legalEntities
        ? filter.legalEntities.split("|")
        : [];
      var _partySiteNumbers = filter.partySiteNumbers
        ? filter.partySiteNumbers.split("|")
        : [];

      form.startDate = _filterDate[0] && new Date(Date.parse(_filterDate[0]));
      form.endDate = _filterDate[1] && new Date(Date.parse(_filterDate[1]));

      userStatusOptions &&
        userStatusOptions.map((item) => {
          if (_suspended == item.value) item.checked = true;
        });

      _userEmails &&
        _userEmails.map((item) => {
          if (!selectedEmails.includes(item)) selectedEmails.push(item);
        });

      _userFirstNames &&
        _userFirstNames.map((item) => {
          if (!selectedFirstNames.includes(item)) selectedFirstNames.push(item);
        });

      _userLastNames &&
        _userLastNames.map((item) => {
          if (!selectedLastNames.includes(item)) selectedLastNames.push(item);
        });

      _userRoles &&
        _userRoles.map((item) => {
          if (!selectedRoles.includes(item)) selectedRoles.push(item);
        });

      _legalEntities &&
        _legalEntities.map((item) => {
          if (!selectedLegalEntities.includes(item))
            selectedLegalEntities.push(item);
        });

      _partySiteNumbers &&
        _partySiteNumbers.map((item) => {
          if (!selectedPartySiteNumbers.includes(item))
            selectedPartySiteNumbers.push(item);
        });
    }

    return (
      <Sidebar
        as={Menu}
        animation="overlay"
        vertical
        direction="left"
        visible={isFilterVisible}
        width="wide"
      >
        <div style={{ padding: "20px" }}>
          <Grid columns={2}>
            <Grid.Column verticalAlign="middle">
              <CustomHeaderInlineBold message="Filters" />
            </Grid.Column>
            <Grid.Column textAlign="right">
              <Button
                basic
                icon="delete"
                onClick={this.props.handleToggleFilter}
              ></Button>
            </Grid.Column>
          </Grid>
          <Divider />
          <Accordion fluid>
            <Accordion.Title
              active={activeIndex === 1}
              index={1}
              onClick={this.props.handleClick}
            >
              <Icon name="dropdown" />
              Created Date
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 1}>
              <Form style={{ marginLeft: "50px" }}>
                <Form.Field>
                  <DatePicker
                    selected={form?.startDate}
                    onChange={(date) =>
                      this.handleDateChange("startDate", date)
                    }
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    maxDate={form?.endDate}
                    dateFormat="MM/dd/yyyy"
                    placeholderText="Start Date"
                  />
                </Form.Field>
                <Form.Field>
                  <DatePicker
                    selected={form?.endDate}
                    onChange={(date) => this.handleDateChange("endDate", date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={form?.startDate}
                    dateFormat="MM/dd/yyyy"
                    placeholderText="End Date"
                  />
                </Form.Field>
              </Form>
            </Accordion.Content>

            <Accordion.Title
              active={activeIndex === 8}
              index={8}
              onClick={this.props.handleClick}
            >
              <Icon name="dropdown" />
              Party Site Number
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 8}>
              <List>
                {selectedPartySiteNumbers &&
                  selectedPartySiteNumbers.map((item, idx) => {
                    return (
                      item &&
                      selectedPartySiteNumbers.includes(item) && (
                        <List.Item key={idx}>
                          <Grid>
                            <Grid.Row centered>
                              <Input
                                value={item}
                                disabled
                                style={{ marginRight: "10px" }}
                              />

                              <Button
                                color="red"
                                basic
                                icon="delete"
                                onClick={() => {
                                  this.handleRemovePSN(item);
                                }}
                              />
                            </Grid.Row>
                          </Grid>
                        </List.Item>
                      )
                    );
                  })}
                {selectedPartySiteNumbers && (
                  <List.Item>
                    <Grid>
                      <Grid.Row centered>
                        {/* <Form.Field
                          control={Dropdown}
                          selection
                          search
                          placeholder="Select party site number"
                          style={{
                            marginLeft: "10px"
                          }}
                          options={foPartySiteNumbersOption}
                          onChange={(e, properties) => {
                            this.handleDropdownChange(
                              "partySiteNumber",
                              properties.value
                            );
                          }}
                          value={form?.partySiteNumber || ""}
                        /> */}

                        <Form.Field
                          style={{
                            marginLeft: "10px"
                          }}
                        >
                          <Search
                            icon="angle down"
                            loading={isLoading}
                            onResultSelect={this.handleResultSelectPSN}
                            onSearchChange={_.debounce(
                              this.handleSearchChangePSN,
                              500,
                              {
                                leading: true
                              }
                            )}
                            minCharacters={3}
                            results={psnResults}
                            value={psnValue}
                          />
                        </Form.Field>

                        <Button
                          color="green"
                          basic
                          icon="add"
                          style={{ marginLeft: "20px" }}
                          onClick={() => {
                            this.handleAddPSN(form?.partySiteNumber);
                          }}
                        />
                      </Grid.Row>
                    </Grid>
                  </List.Item>
                )}
              </List>
            </Accordion.Content>

            <Accordion.Title
              active={activeIndex === 2}
              index={2}
              onClick={this.props.handleClick}
            >
              <Icon name="dropdown" />
              Email
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 2}>
              <List>
                {selectedEmails &&
                  selectedEmails.map((item, idx) => {
                    return (
                      item &&
                      selectedEmails.includes(item) && (
                        <List.Item key={idx}>
                          <Grid>
                            <Grid.Row centered>
                              <Input
                                value={item}
                                disabled
                                style={{ marginRight: "10px" }}
                              />

                              <Button
                                color="red"
                                basic
                                icon="delete"
                                onClick={() => {
                                  this.handleRemoveEmail(item);
                                }}
                              />
                            </Grid.Row>
                          </Grid>
                        </List.Item>
                      )
                    );
                  })}
                {selectedEmails && (
                  <List.Item>
                    <Grid>
                      <Grid.Row centered>
                        {/* <Form.Field
                          control={Dropdown}
                          selection
                          search
                          placeholder="Select email"
                          style={{
                            marginLeft: "10px"
                          }}
                          options={foEmailsOption}
                          onChange={(e, properties) => {
                            this.handleDropdownChange(
                              "email",
                              properties.value
                            );
                          }}
                          value={form?.email || ""}
                        /> */}

                        <Form.Field
                          style={{
                            marginLeft: "10px"
                          }}
                        >
                          <Search
                            icon="angle down"
                            loading={isLoading}
                            onResultSelect={this.handleResultSelectEmail}
                            onSearchChange={_.debounce(
                              this.handleSearchChangeEmail,
                              500,
                              {
                                leading: true
                              }
                            )}
                            minCharacters={3}
                            results={emailResults}
                            value={emailValue}
                          />
                        </Form.Field>

                        <Button
                          color="green"
                          basic
                          icon="add"
                          style={{ marginLeft: "20px" }}
                          onClick={() => {
                            this.handleAddEmail(form?.email);
                          }}
                        />
                      </Grid.Row>
                    </Grid>
                  </List.Item>
                )}
              </List>
            </Accordion.Content>

            <Accordion.Title
              active={activeIndex === 3}
              index={3}
              onClick={this.props.handleClick}
            >
              <Icon name="dropdown" />
              First Name
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 3}>
              <List>
                {selectedFirstNames &&
                  selectedFirstNames.map((item, idx) => {
                    return (
                      item &&
                      selectedFirstNames.includes(item) && (
                        <List.Item key={idx}>
                          <Grid>
                            <Grid.Row centered>
                              <Input
                                value={item}
                                disabled
                                style={{ marginRight: "10px" }}
                              />

                              <Button
                                color="red"
                                basic
                                icon="delete"
                                onClick={() => {
                                  this.handleRemoveFirstName(item);
                                }}
                              />
                            </Grid.Row>
                          </Grid>
                        </List.Item>
                      )
                    );
                  })}
                {selectedFirstNames && (
                  <List.Item>
                    <Grid>
                      <Grid.Row centered>
                        {/* <Form.Field
                          control={Dropdown}
                          selection
                          search
                          placeholder="Select first name"
                          style={{
                            marginLeft: "10px"
                          }}
                          options={foFirstNamesOption}
                          onChange={(e, properties) => {
                            this.handleDropdownChange(
                              "firstName",
                              properties.value
                            );
                          }}
                          value={form?.firstName || ""}
                        /> */}

                        <Form.Field
                          style={{
                            marginLeft: "10px"
                          }}
                        >
                          <Search
                            icon="angle down"
                            loading={isLoading}
                            onResultSelect={this.handleResultSelectFirstName}
                            onSearchChange={_.debounce(
                              this.handleSearchChangeFirstName,
                              500,
                              {
                                leading: true
                              }
                            )}
                            minCharacters={3}
                            results={firstNameResults}
                            value={firstNameValue}
                          />
                        </Form.Field>

                        <Button
                          color="green"
                          basic
                          icon="add"
                          style={{ marginLeft: "20px" }}
                          onClick={() => {
                            this.handleAddFirstName(form?.firstName);
                          }}
                        />
                      </Grid.Row>
                    </Grid>
                  </List.Item>
                )}
              </List>
            </Accordion.Content>

            <Accordion.Title
              active={activeIndex === 4}
              index={4}
              onClick={this.props.handleClick}
            >
              <Icon name="dropdown" />
              Last Name
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 4}>
              <List>
                {selectedLastNames &&
                  selectedLastNames.map((item, idx) => {
                    return (
                      item &&
                      selectedLastNames.includes(item) && (
                        <List.Item key={idx}>
                          <Grid>
                            <Grid.Row centered>
                              <Input
                                value={item}
                                disabled
                                style={{ marginRight: "10px" }}
                              />

                              <Button
                                color="red"
                                basic
                                icon="delete"
                                onClick={() => {
                                  this.handleRemoveLastName(item);
                                }}
                              />
                            </Grid.Row>
                          </Grid>
                        </List.Item>
                      )
                    );
                  })}
                {selectedLastNames && (
                  <List.Item>
                    <Grid>
                      <Grid.Row centered>
                        {/* <Form.Field
                          control={Dropdown}
                          selection
                          search
                          placeholder="Select last name"
                          style={{
                            marginLeft: "10px"
                          }}
                          options={foLastNamesOption}
                          onChange={(e, properties) => {
                            this.handleDropdownChange(
                              "lastName",
                              properties.value
                            );
                          }}
                          value={form?.lastName || ""}
                        /> */}

                        <Form.Field
                          style={{
                            marginLeft: "10px"
                          }}
                        >
                          <Search
                            icon="angle down"
                            loading={isLoading}
                            onResultSelect={this.handleResultSelectLastName}
                            onSearchChange={_.debounce(
                              this.handleSearchChangeLastName,
                              500,
                              {
                                leading: true
                              }
                            )}
                            minCharacters={3}
                            results={lastNameResults}
                            value={lastNameValue}
                          />
                        </Form.Field>

                        <Button
                          color="green"
                          basic
                          icon="add"
                          style={{ marginLeft: "20px" }}
                          onClick={() => {
                            this.handleAddLastName(form?.lastName);
                          }}
                        />
                      </Grid.Row>
                    </Grid>
                  </List.Item>
                )}
              </List>
            </Accordion.Content>

            <Accordion.Title
              active={activeIndex === 5}
              index={5}
              onClick={this.props.handleClick}
            >
              <Icon name="dropdown" />
              Role
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 5}>
              <List>
                {selectedRoles &&
                  selectedRoles.map((item, idx) => {
                    return (
                      item &&
                      selectedRoles.includes(item) && (
                        <List.Item key={idx}>
                          <Grid>
                            <Grid.Row centered>
                              <Input
                                value={item}
                                disabled
                                style={{ marginRight: "10px" }}
                              />

                              <Button
                                color="red"
                                basic
                                icon="delete"
                                onClick={() => {
                                  this.handleRemoveRole(item);
                                }}
                              />
                            </Grid.Row>
                          </Grid>
                        </List.Item>
                      )
                    );
                  })}
                {selectedRoles && (
                  <List.Item>
                    <Grid>
                      <Grid.Row centered>
                        <Form.Field
                          control={Dropdown}
                          selection
                          search
                          placeholder="Select role"
                          style={{
                            marginLeft: "10px"
                          }}
                          options={foRolesOption}
                          onChange={(e, properties) => {
                            this.handleDropdownChange("role", properties.value);
                          }}
                          value={form?.role || ""}
                        />

                        <Button
                          color="green"
                          basic
                          icon="add"
                          style={{ marginLeft: "20px" }}
                          onClick={() => {
                            this.handleAddRole(form?.role);
                          }}
                        />
                      </Grid.Row>
                    </Grid>
                  </List.Item>
                )}
              </List>
            </Accordion.Content>

            <Accordion.Title
              active={activeIndex === 6}
              index={6}
              onClick={this.props.handleClick}
            >
              <Icon name="dropdown" />
              Legal Entity
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 6}>
              <List>
                {selectedLegalEntities &&
                  selectedLegalEntities.map((item, idx) => {
                    return (
                      item &&
                      selectedLegalEntities.includes(item) && (
                        <List.Item key={idx}>
                          <Grid>
                            <Grid.Row centered>
                              <Input
                                value={item}
                                disabled
                                style={{ marginRight: "10px" }}
                              />

                              <Button
                                color="red"
                                basic
                                icon="delete"
                                onClick={() => {
                                  this.handleRemoveLegalEntity(item);
                                }}
                              />
                            </Grid.Row>
                          </Grid>
                        </List.Item>
                      )
                    );
                  })}
                {selectedLegalEntities && (
                  <List.Item>
                    <Grid>
                      <Grid.Row centered>
                        <Form.Field
                          control={Dropdown}
                          selection
                          search
                          placeholder="Select legal entity"
                          style={{
                            marginLeft: "10px"
                          }}
                          options={foLegalEntitiesOption}
                          onChange={(e, properties) => {
                            this.handleDropdownChange(
                              "legalEntity",
                              properties.value
                            );
                          }}
                          value={form?.legalEntity || ""}
                        />

                        <Button
                          color="green"
                          basic
                          icon="add"
                          style={{ marginLeft: "20px" }}
                          onClick={() => {
                            this.handleAddLegalEntity(form?.legalEntity);
                          }}
                        />
                      </Grid.Row>
                    </Grid>
                  </List.Item>
                )}
              </List>
            </Accordion.Content>

            <Accordion.Title
              active={activeIndex === 7}
              index={7}
              onClick={this.props.handleClick}
            >
              <Icon name="dropdown" />
              Suspended/Deactivate
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 7}>
              <List>
                {userStatusOptions &&
                  userStatusOptions.map((item, idx) => {
                    return (
                      <List.Item key={idx}>
                        <Grid>
                          <Grid.Row>
                            <Radio
                              style={{
                                marginLeft: "50px",
                                marginTop: "10px"
                              }}
                              label={item.text}
                              name="radioGroupLabelType"
                              value={item.value}
                              checked={item.checked}
                              onClick={(e, properties) => {
                                this.handleUserStatusChange(properties);
                              }}
                            />
                          </Grid.Row>
                        </Grid>
                      </List.Item>
                    );
                  })}
              </List>
            </Accordion.Content>
          </Accordion>
          <Divider />
          <Grid>
            <Grid.Column textAlign="right">
              <Button
                basic
                color="blue"
                content="Clear"
                onClick={this.handleClearFilter}
              ></Button>
              <Button
                basic
                color="green"
                content="Apply"
                onClick={this.handleApplyFilter}
              ></Button>
            </Grid.Column>
          </Grid>
        </div>
      </Sidebar>
    );
  }
}

const mapStateToProps = (state) => ({
  roleAssignment: state.roleAssignment
});

const mapDispatchToProps = (dispatch) => {
  return {
    getFOEmails: () => dispatch(actions.getFOEmails()),
    getFOFirstNames: () => dispatch(actions.getFOFirstNames()),
    getFOLastNames: () => dispatch(actions.getFOLastNames()),
    getFOLegalEntities: () => dispatch(actions.getFOLegalEntities()),
    getFOPartySiteNumbers: () => dispatch(actions.getFOPartySiteNumbers()),
    getFORoles: () => dispatch(actions.getFORoles()),
    setUserFilter: (data) => dispatch(actions.setUserFilter(data)),
    updateForm: (result) => dispatch(actions.updateForm(result)),
    clearForm: () => dispatch(actions.clearForm())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RoleAssignmentFilterOption);
